import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement53Square62Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#5-3_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M137.001 203C138.105 203 139 202.105 139 201.001C139 199.896 138.105 199.001 137.001 199.001L134.833 199.001L134.833 199.001L124 199.001L124 199L120.999 199C119.895 199 119 199.895 119 200.999C119 202.104 119.895 202.999 120.999 202.999L123.167 202.999L123.167 203L134 203L134 203L137.001 203Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 134.833 203)"
          fill="#ABABAB"
        />
        <path
          d="M138.06 203C138.579 203 139 202.579 139 202.06L139 199.941C139 199.422 138.579 199.001 138.06 199.001L134 199.001L134 203L138.06 203Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M119.94 199C119.421 199 119 199.421 119 199.94L119 202.059C119 202.578 119.421 202.999 119.94 202.999L124 202.999L124 199L119.94 199Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 133.167 203)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#5-3_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M182.001 203C183.105 203 184 202.105 184 201.001C184 199.896 183.105 199.001 182.001 199.001L179.833 199.001L179.833 199.001L169 199.001L169 199L165.999 199C164.895 199 164 199.895 164 200.999C164 202.104 164.895 202.999 165.999 202.999L168.167 202.999L168.167 203L179 203L179 203L182.001 203Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 179.833 203)"
          fill="#ABABAB"
        />
        <path
          d="M183.06 203C183.579 203 184 202.579 184 202.06L184 199.941C184 199.422 183.579 199.001 183.06 199.001L179 199.001L179 203L183.06 203Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M164.94 199C164.421 199 164 199.421 164 199.94L164 202.059C164 202.578 164.421 202.999 164.94 202.999L169 202.999L169 199L164.94 199Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 178.167 203)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#5-3_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 121H115V199H188V121ZM177 132H126V188H177V132Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 121H115V199H116V122H187V199H188V121Z"
          fill="#FBFBFB"
        />
        <g filter="url(#5-3_filter3_i)">
          <rect
            x="126.47"
            y="132.47"
            width="50.0601"
            height="55.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="119.53"
          y="125.53"
          width="63.9399"
          height="68.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="158.528"
          y="123"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 158.528 123)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#5-3_filter4_d)">
          <rect
            x="157.884"
            y="123.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 157.884 123.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M157.766 125.695C157.42 124.542 156.35 123.702 155.084 123.702L148.444 123.702C146.898 123.702 145.644 124.955 145.644 126.502C145.644 126.537 145.645 126.572 145.646 126.607C145.993 127.76 147.062 128.6 148.328 128.6L154.968 128.6C156.515 128.6 157.768 127.346 157.768 125.8C157.768 125.765 157.768 125.73 157.766 125.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M156.409 124.034C156.266 124.012 156.118 124 155.968 124L149.328 124C147.782 124 146.528 125.253 146.528 126.8C146.528 127.867 147.125 128.795 148.003 129.267C146.666 129.056 145.644 127.898 145.644 126.502C145.644 124.955 146.898 123.702 148.444 123.702L155.084 123.702C155.564 123.702 156.015 123.822 156.409 124.034Z"
          fill="#FBFBFB"
        />
        <g filter="url(#5-3_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M171.528 126.5C171.528 125.672 170.857 125 170.028 125L169.814 125L168.528 125L150.028 125C149.2 125 148.528 125.672 148.528 126.5V126.5C148.528 127.328 149.2 128 150.028 128L166.528 128L170.028 128L170.243 128C170.953 128 171.528 127.424 171.528 126.714V126.714C171.528 126.677 171.527 126.64 171.525 126.604C171.527 126.569 171.528 126.535 171.528 126.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M127.776 186.5L151.5 134.21L175.224 186.5L127.776 186.5Z" stroke="white" />
        <defs>
          <filter
            id="5-3_filter0_d"
            x="117.12"
            y="197.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-3_filter1_d"
            x="162.12"
            y="197.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-3_filter2_d"
            x="110.3"
            y="116.3"
            width="84.2793"
            height="89.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-3_filter3_i"
            x="126"
            y="132"
            width="51.9399"
            height="58.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-3_filter4_d"
            x="143.764"
            y="121.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-3_filter5_d"
            x="147.649"
            y="123.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="121.5"
            y1="205.8"
            x2="121.5"
            y2="198.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="136.5"
            y1="196.202"
            x2="136.5"
            y2="203.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="121.5"
            y1="205.798"
            x2="121.5"
            y2="198.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="166.5"
            y1="205.8"
            x2="166.5"
            y2="198.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="181.5"
            y1="196.202"
            x2="181.5"
            y2="203.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="166.5"
            y1="205.798"
            x2="166.5"
            y2="198.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
