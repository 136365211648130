import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement61Square82Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#7filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M182.001 165C183.105 165 184 164.105 184 163.001C184 161.896 183.105 161.001 182.001 161.001L179.833 161.001L179.833 161.001L169 161.001L169 161L165.999 161C164.895 161 164 161.895 164 162.999C164 164.104 164.895 164.999 165.999 164.999L168.167 164.999L168.167 165L179 165L179 165L182.001 165Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 179.833 165)"
          fill="#ABABAB"
        />
        <path
          d="M183.06 165C183.579 165 184 164.579 184 164.06L184 161.941C184 161.422 183.579 161.001 183.06 161.001L179 161.001L179 165L183.06 165Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M164.94 161C164.421 161 164 161.421 164 161.94L164 164.059C164 164.578 164.421 164.999 164.94 164.999L169 164.999L169 161L164.94 161Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 178.167 165)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#7filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M137.001 165C138.105 165 139 164.105 139 163.001C139 161.896 138.105 161.001 137.001 161.001L134.833 161.001L134.833 161.001L124 161.001L124 161L120.999 161C119.895 161 119 161.895 119 162.999C119 164.104 119.895 164.999 120.999 164.999L123.167 164.999L123.167 165L134 165L134 165L137.001 165Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.833 165)"
          fill="#ABABAB"
        />
        <path
          d="M138.06 165C138.579 165 139 164.579 139 164.06L139 161.941C139 161.422 138.579 161.001 138.06 161.001L134 161.001L134 165L138.06 165Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M119.94 161C119.421 161 119 161.421 119 161.94L119 164.059C119 164.578 119.421 164.999 119.94 164.999L124 164.999L124 161L119.94 161Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 133.167 165)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#7filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 165H115V220H188V165ZM178 175H125V210H178V175Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 165H115V220H116V166H187V220H188V165Z"
          fill="#FBFBFB"
        />
        <g filter="url(#7filter3_i)">
          <rect
            x="125.47"
            y="175.47"
            width="52.0601"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="119.53"
          y="169.53"
          width="63.9399"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M127.018 176.5L151.5 208.182L175.982 176.5L127.018 176.5Z" stroke="white" />
        <rect
          x="158"
          y="211"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 158 211)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="157.356"
          y="211.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 157.356 211.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M157.238 213.695C156.892 212.542 155.822 211.702 154.556 211.702L147.916 211.702C146.37 211.702 145.116 212.956 145.116 214.502C145.116 214.537 145.117 214.572 145.118 214.607C145.464 215.76 146.534 216.6 147.8 216.6L154.44 216.6C155.986 216.6 157.24 215.346 157.24 213.8C157.24 213.765 157.239 213.73 157.238 213.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M155.881 212.035C155.737 212.012 155.59 212 155.44 212L148.8 212C147.254 212 146 213.254 146 214.8C146 215.867 146.597 216.795 147.475 217.267C146.138 217.056 145.116 215.898 145.116 214.502C145.116 212.956 146.37 211.702 147.916 211.702L154.556 211.702C155.035 211.702 155.486 211.822 155.881 212.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#7filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M154 214.5C154 213.672 153.328 213 152.5 213L152.286 213L151 213L132.5 213C131.672 213 131 213.672 131 214.5V214.5C131 215.328 131.672 216 132.5 216L149 216L152.5 216L152.714 216C153.424 216 154 215.424 154 214.714V214.714C154 214.677 153.999 214.64 153.996 214.604C153.999 214.569 154 214.535 154 214.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="7filter0_d"
            x="162.12"
            y="159.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7filter1_d"
            x="117.12"
            y="159.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7filter2_d"
            x="110.3"
            y="160.3"
            width="84.2793"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7filter3_i"
            x="125"
            y="175"
            width="53.9399"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="7filter4_d"
            x="129.28"
            y="211.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="166.5"
            y1="167.8"
            x2="166.5"
            y2="160.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="181.5"
            y1="158.202"
            x2="181.5"
            y2="165.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="166.5"
            y1="167.798"
            x2="166.5"
            y2="160.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="121.5"
            y1="167.8"
            x2="121.5"
            y2="160.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="136.5"
            y1="158.202"
            x2="136.5"
            y2="165.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="121.5"
            y1="167.798"
            x2="121.5"
            y2="160.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
