import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement33Square4TopBottomCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#3-3_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M254.001 220C255.105 220 256 219.105 256 218.001C256 216.896 255.105 216.001 254.001 216.001L251.833 216.001L251.833 216.001L241 216.001L241 216L237.999 216C236.895 216 236 216.895 236 217.999C236 219.104 236.895 219.999 237.999 219.999L240.167 219.999L240.167 220L251 220L251 220L254.001 220Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 251.834 220)"
          fill="#ABABAB"
        />
        <path
          d="M255.06 220C255.579 220 256 219.579 256 219.06L256 216.941C256 216.422 255.579 216.001 255.06 216.001L251 216.001L251 220L255.06 220Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M236.94 216C236.421 216 236 216.421 236 216.94L236 219.059C236 219.578 236.421 219.999 236.94 219.999L241 219.999L241 216L236.94 216Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 250.167 220)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#3-3_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 104H230V216H417V104ZM406 115H241V205H406V115Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#3-3_filter2_i)">
          <rect
            x="241.47"
            y="115.47"
            width="164.06"
            height="89.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="235.5" y="109.5" width="176" height="101" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 104H230V105H416V216H417V104Z"
          fill="#FBFBFB"
        />
        <path d="M243.145 203.5L403.855 203.5L323.5 116.736L243.145 203.5Z" stroke="white" />
        <g filter="url(#3-3_filter3_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M409.001 220C410.105 220 411 219.105 411 218.001C411 216.896 410.105 216.001 409.001 216.001L406.833 216.001L406.833 216.001L396 216.001L396 216L392.999 216C391.895 216 391 216.895 391 217.999C391 219.104 391.895 219.999 392.999 219.999L395.167 219.999L395.167 220L406 220L406 220L409.001 220Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 406.834 220)"
          fill="#ABABAB"
        />
        <path
          d="M410.06 220C410.579 220 411 219.579 411 219.06L411 216.941C411 216.422 410.579 216.001 410.06 216.001L406 216.001L406 220L410.06 220Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M391.94 216C391.421 216 391 216.421 391 216.94L391 219.059C391 219.578 391.421 219.999 391.94 219.999L396 219.999L396 216L391.94 216Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 405.167 220)"
          fill="url(#paint7_linear)"
        />
        <rect
          x="330.528"
          y="106"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 330.528 106)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#3-3_filter4_d)">
          <rect
            x="329.884"
            y="106.701"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 329.884 106.701)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M329.767 108.694C329.42 107.541 328.351 106.701 327.085 106.701L320.445 106.701C318.898 106.701 317.645 107.955 317.645 109.501C317.645 109.536 317.645 109.571 317.647 109.606C317.993 110.759 319.063 111.599 320.329 111.599L326.969 111.599C328.515 111.599 329.769 110.346 329.769 108.799C329.769 108.764 329.768 108.729 329.767 108.694Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M328.409 107.034C328.266 107.011 328.118 106.999 327.968 106.999L321.328 106.999C319.782 106.999 318.528 108.253 318.528 109.799C318.528 110.866 319.125 111.794 320.003 112.267C318.666 112.055 317.644 110.898 317.644 109.501C317.644 107.955 318.898 106.701 320.444 106.701L327.084 106.701C327.564 106.701 328.015 106.822 328.409 107.034Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-3_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M343.528 109.5C343.528 108.672 342.857 108 342.028 108L341.814 108L340.528 108L322.028 108C321.2 108 320.528 108.672 320.528 109.5V109.5C320.528 110.328 321.2 111 322.028 111L338.528 111L342.028 111L342.243 111C342.953 111 343.528 110.424 343.528 109.714V109.714C343.528 109.677 343.527 109.64 343.525 109.604C343.527 109.569 343.528 109.535 343.528 109.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="3-3_filter0_d"
            x="234.12"
            y="214.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-3_filter1_d"
            x="225.3"
            y="99.3003"
            width="198.279"
            height="123.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-3_filter2_i"
            x="241"
            y="115"
            width="165.94"
            height="92.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-3_filter3_d"
            x="389.12"
            y="214.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-3_filter4_d"
            x="315.764"
            y="104.821"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-3_filter5_d"
            x="319.649"
            y="106.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="238.5"
            y1="222.8"
            x2="238.5"
            y2="215.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="253.5"
            y1="213.202"
            x2="253.5"
            y2="220.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="238.5"
            y1="222.798"
            x2="238.5"
            y2="215.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="393.5"
            y1="222.8"
            x2="393.5"
            y2="215.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="408.5"
            y1="213.202"
            x2="408.5"
            y2="220.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="393.5"
            y1="222.798"
            x2="393.5"
            y2="215.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
