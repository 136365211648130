import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement83Square3Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#8-3_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M171.999 287C170.895 287 170 287.895 170 288.999C170 290.104 170.895 290.999 171.999 290.999L174.167 290.999L174.167 290.999L185 290.999L185 291L188.001 291C189.105 291 190 290.105 190 289.001C190 287.896 189.105 287.001 188.001 287.001L185.833 287.001L185.833 287L175 287L175 287L171.999 287Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 174.166 287)"
          fill="#ABABAB"
        />
        <path
          d="M170.94 287C170.421 287 170 287.421 170 287.94L170 290.059C170 290.578 170.421 290.999 170.94 290.999L175 290.999L175 287L170.94 287Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M189.06 291C189.579 291 190 290.579 190 290.06L190 287.941C190 287.422 189.579 287.001 189.06 287.001L185 287.001L185 291L189.06 291Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 175.833 287)"
          fill="url(#paint8-3_linear)"
        />
        <g filter="url(#8-3_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M259.999 287C258.895 287 258 287.895 258 288.999C258 290.104 258.895 290.999 259.999 290.999L262.167 290.999L262.167 290.999L273 290.999L273 291L276.001 291C277.105 291 278 290.105 278 289.001C278 287.896 277.105 287.001 276.001 287.001L273.833 287.001L273.833 287L263 287L263 287L259.999 287Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 262.166 287)"
          fill="#ABABAB"
        />
        <path
          d="M258.94 287C258.421 287 258 287.421 258 287.94L258 290.059C258 290.578 258.421 290.999 258.94 290.999L263 290.999L263 287L258.94 287Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M277.06 291C277.579 291 278 290.579 278 290.06L278 287.941C278 287.422 277.579 287.001 277.06 287.001L273 287.001L273 291L277.06 291Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 263.833 287)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#8-3_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M284 210H164V287H284V210ZM273 221H175V276H273V221Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#8-3_filter8-3_i)">
          <rect
            x="175.47"
            y="221.47"
            width="97.0601"
            height="54.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="169.5" y="215.5" width="109" height="66" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M284 210H164V287H165V211H283V287H284V210Z"
          fill="#FBFBFB"
        />
        <path d="M270.873 274.5H177.127L224 222.745L270.873 274.5Z" stroke="white" />
        <rect
          x="231.528"
          y="212"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 231.528 212)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#8-3_filter4_d)">
          <rect
            x="230.884"
            y="212.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 230.884 212.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M230.767 214.695C230.42 213.542 229.351 212.702 228.085 212.702L221.445 212.702C219.898 212.702 218.645 213.956 218.645 215.502C218.645 215.537 218.645 215.572 218.647 215.607C218.993 216.76 220.063 217.6 221.329 217.6L227.969 217.6C229.515 217.6 230.769 216.347 230.769 214.8C230.769 214.765 230.768 214.73 230.767 214.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M229.409 213.035C229.266 213.012 229.118 213 228.968 213L222.328 213C220.782 213 219.528 214.254 219.528 215.8C219.528 216.867 220.125 217.795 221.003 218.268C219.666 218.056 218.644 216.898 218.644 215.502C218.644 213.956 219.898 212.702 221.444 212.702L228.084 212.702C228.564 212.702 229.015 212.823 229.409 213.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#8-3_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M244.528 215.5C244.528 214.672 243.857 214 243.028 214L242.814 214L241.528 214L223.028 214C222.2 214 221.528 214.672 221.528 215.5C221.528 216.328 222.2 217 223.028 217L239.528 217L243.028 217L243.243 217C243.953 217 244.528 216.424 244.528 215.714C244.528 215.677 244.527 215.64 244.525 215.604C244.527 215.569 244.528 215.535 244.528 215.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="8-3_filter0_d"
            x="168.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8-3_filter1_d"
            x="256.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8-3_filter2_d"
            x="159.3"
            y="205.3"
            width="131.279"
            height="88.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8-3_filter8-3_i"
            x="175"
            y="221"
            width="98.9399"
            height="57.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="8-3_filter4_d"
            x="216.764"
            y="210.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8-3_filter5_d"
            x="220.649"
            y="212.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="187.5"
            y1="284.2"
            x2="187.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="172.5"
            y1="293.798"
            x2="172.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="187.5"
            y1="284.202"
            x2="187.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint8-3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="275.5"
            y1="284.2"
            x2="275.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="260.5"
            y1="293.798"
            x2="260.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="275.5"
            y1="284.202"
            x2="275.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
