import React from 'react'
import { StageDescriptionBlockContentItemIcon } from 'src/react-app/views/calculator/styles'

export const Soundproofing3 = () => {
  return (
    <StageDescriptionBlockContentItemIcon title="Для окон выходящих на шумный двор">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.6"
          d="M32.5 40H7.5C3.3575 40 0 36.6425 0 32.5V7.5C0 3.3575 3.3575 0 7.5 0H32.5C36.6425 0 40 3.3575 40 7.5V32.5C40 36.6425 36.6425 40 32.5 40Z"
          fill="#E0E3E9"
        />
        <path
          d="M30.7416 21.8422L27.6171 16.5119C27.4797 16.3098 27.204 16.2566 27.0019 16.394C26.8761 16.48 26.8158 16.6191 26.8158 16.761H23.0025C23.0025 16.6191 22.9423 16.48 22.8164 16.394C22.6134 16.2566 22.3377 16.3098 22.2012 16.5119L19.0767 21.8422C18.9845 21.9778 18.9748 22.1542 19.051 22.2987C19.1281 22.444 19.2788 22.5345 19.4428 22.5345H30.3747C30.5386 22.5345 30.6893 22.444 30.7664 22.2987C30.8436 22.1533 30.8338 21.9778 30.7416 21.8422Z"
          fill="#1C3775"
        />
        <path
          d="M29.5584 16.2024L25.2744 10.1795C25.1096 9.94017 24.7098 9.94017 24.5449 10.1795L20.261 16.2024C20.1679 16.338 20.1564 16.5144 20.2335 16.6607C20.3107 16.8061 20.4613 16.8982 20.6262 16.8982H29.195C29.3598 16.8982 29.5105 16.8069 29.5876 16.6607C29.663 16.5144 29.6514 16.338 29.5584 16.2024Z"
          fill="#1C3775"
        />
        <path
          d="M24.9086 20.6247C24.5824 20.6247 24.3174 20.8898 24.3174 21.216V26.3072C24.3174 26.6334 24.5815 26.8985 24.9086 26.8985C25.2348 26.8985 25.4998 26.6334 25.4998 26.3072V21.216C25.4998 20.8898 25.2348 20.6247 24.9086 20.6247Z"
          fill="#1C3775"
        />
        <path
          d="M24.1183 22.5097L24.1184 22.5096L24.1103 22.5023L17.6351 16.7391L17.6351 16.7391C17.2768 16.4203 16.7232 16.4203 16.3649 16.7391L16.3649 16.7391L9.88289 22.5089L9.88282 22.5088L9.8751 22.516C9.64161 22.7328 9.5 23.0399 9.5 23.3642C9.5 24.0487 10.0871 24.541 10.7289 24.541H11.25V29.1875C11.25 29.9464 11.9014 30.5 12.625 30.5H15.1039C15.5859 30.5 16.0414 30.1283 16.0414 29.5937V26.4374H17.9586V29.5937C17.9586 30.1283 18.4141 30.5 18.8961 30.5H21.375C22.0986 30.5 22.75 29.9464 22.75 29.1875V24.5418H23.2711C23.9146 24.5418 24.5 24.0479 24.5 23.365C24.5 23.0366 24.3559 22.7306 24.1183 22.5097Z"
          fill="#1C3775"
          stroke="#ECEEF2"
        />
      </svg>
    </StageDescriptionBlockContentItemIcon>
  )
}
