import React, { FC, ReactElement, useCallback, useEffect, useState, useMemo } from 'react'

import { CheckIcon } from 'src/react-app/assets/icons'
import { Tooltip } from 'src/react-app/components'
import { TooltipDirection } from 'src/react-app/components/tooltip'

import { IGalleryItem, IGalleryProps } from './interface'
import {
  GalleryItem,
  GalleryItemImage,
  GallerySelected,
  GalleryContent,
  GalleryWrapper,
  GalleryGroupTitle,
  GalleryItemWrapper,
  GalleryItemText2,
  GalleryClearItems,
  GalleryGroup,
  GalleryGroupTitleButton,
} from './styles'
import { ComponentShadow } from '../component-shadow'

const showHideLimit = 3

const groupItems = (items: IGalleryItem[]) => {
  const result: any = {}

  const parseResult = () => {
    const resultKeys = Object.keys(result)
    const innerResult: Record<string, any>[] = []

    if (resultKeys.length > 0) {
      resultKeys.forEach(key => {
        innerResult.push({
          title: key,
          items: result[key],
        })
      })
    }

    return innerResult
  }

  if (items.length > 0) {
    items.forEach(item => {
      if (item && item['group']) {
        result[item['group']] = [...(result[item['group']] || []), item]
      } else {
        result['ungrouped'] = [...(result['ungrouped'] || []), item]
      }
    })
  }

  return parseResult()
}

export const Gallery: FC<IGalleryProps> = props => {
  const {
    items = [] as IGalleryItem[],
    onSelect,
    selected,
    view = 1,
    show = true,
    disabled = false,
    isLastElement,
  } = props

  const [selectedId, setSelectedId] = useState<string | null>(null)
  const [hideAll, setHideAll] = useState<boolean[]>([])
  const [groupedItems, setGroupedItems] = useState<any[]>([])

  useEffect(() => {
    if (items.length > 0) {
      const grouped = groupItems(items)

      setGroupedItems(grouped)
    } else {
      setGroupedItems([])
    }
  }, [items, items.length])

  useEffect(() => {
    if (selected) {
      if (selected.constructor === Array) {
        selected.forEach(item => {
          if (item[props.id] && item[props.id]['id']) {
            setSelectedId(item[props.id]['id'])
          }
        })
      } else if (selected.constructor === Object) {
        if (selected['id']) {
          setSelectedId(selected['id'])
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  const handleSelect = useCallback(
    (item: IGalleryItem) => () => {
      setSelectedId(item.id)
      onSelect(item)
    },
    [onSelect],
  )

  const toggleHideAll = useCallback(
    groupIndex => () => {
      setHideAll(prevState => {
        const currentState =
          typeof prevState[groupIndex] !== 'undefined' ? !prevState[groupIndex] : true
        const newState = [...prevState.slice(0, prevState.length)]

        newState[groupIndex] = currentState

        return newState
      })
    },
    [],
  )

  const isLimitView = useCallback(
    (groupIndex, index) => {
      let result = true
      const isHidden = typeof hideAll[groupIndex] !== 'undefined' && hideAll[groupIndex]

      if (isHidden) {
        result = index < showHideLimit
      }

      return result
    },
    [hideAll],
  )

  const getGroupTitle = useCallback(
    (group, groupIndex) => {
      let result: null | ReactElement = null
      const canShowHideButton = group.items.length > showHideLimit
      const isHidden = typeof hideAll[groupIndex] !== 'undefined' && hideAll[groupIndex]

      if (group.title !== 'ungrouped') {
        result = (
          <GalleryGroupTitle>
            Коллекция “{group.title}”{' '}
            {canShowHideButton && (
              <GalleryGroupTitleButton onClick={toggleHideAll(groupIndex)}>
                {isHidden ? 'Показать все' : 'Свернуть'}
              </GalleryGroupTitleButton>
            )}
          </GalleryGroupTitle>
        )
      } else {
        result = canShowHideButton ? (
          <GalleryGroupTitle>
            <GalleryGroupTitleButton onClick={toggleHideAll(groupIndex)}>
              {isHidden ? 'Показать все' : 'Свернуть'}
            </GalleryGroupTitleButton>
          </GalleryGroupTitle>
        ) : null
      }

      return result
    },
    [hideAll, toggleHideAll],
  )

  const getImage = useCallback(item => {
    let result: null | ReactElement = null

    if (item.image) {
      result = <GalleryItemImage src={item.image || ''} />
    }

    return result
  }, [])

  const getView2 = useCallback(
    item => {
      let result: null | ReactElement = null

      if (view === 2) {
        result = (
          <GalleryItemText2>
            <span>{item.text}</span>
            {item['price'] && <span>{item['price']}</span>}
          </GalleryItemText2>
        )
      }

      return result
    },
    [view],
  )

  const checkSelected = useCallback(
    item => {
      return item.id === selectedId
    },
    [selectedId],
  )

  const getSelectedCheck = useCallback(
    item => {
      let result: null | ReactElement = null

      if (checkSelected(item)) {
        result = (
          <GallerySelected>
            <CheckIcon color="#d69a2d" checked />
          </GallerySelected>
        )
      }
      return result
    },
    [checkSelected],
  )

  const getTooltipDirection = useCallback(index => {
    return (index + 1) % 3 === 1 ? TooltipDirection.RIGHT : TooltipDirection.TOP
  }, [])

  const getTooltipCoord = useCallback((index: number, count = 3) => {
    return (index + 1) % count === 1 ? { x: `${count * 70}%`, y: '20px' } : { x: 42, y: '120px' }
  }, [])

  const tooltipCoordCount = useMemo(() => {
    return view === 2 ? 2 : 3
  }, [view])

  return (
    <>
      {show && (
        <GalleryWrapper>
          {disabled && (
            <ComponentShadow coord={{ top: '-14px', bottom: isLastElement ? '-104px' : '-30px' }} />
          )}
          {groupedItems.length > 0 &&
            groupedItems.map((group, groupIndex) => (
              <GalleryGroup key={group.title}>
                {getGroupTitle(group, groupIndex)}
                {group.items?.length > 0 && (
                  <GalleryContent view={view}>
                    {group.items.map(
                      (item, index) =>
                        isLimitView(groupIndex, index) && (
                          <GalleryItemWrapper key={item.id}>
                            <GalleryItem
                              view={view}
                              onClick={handleSelect(item)}
                              hideBefore={checkSelected(item)}
                            >
                              <Tooltip
                                text={item['text']}
                                coord={getTooltipCoord(index, tooltipCoordCount)}
                                direction={getTooltipDirection(index)}
                              >
                                {getImage(item)}
                              </Tooltip>
                              {getSelectedCheck(item)}
                            </GalleryItem>
                            {getView2(item)}
                          </GalleryItemWrapper>
                        ),
                    )}
                  </GalleryContent>
                )}
              </GalleryGroup>
            ))}
          {groupedItems.length === 0 && (
            <GalleryClearItems>Нет вариантов для выбора</GalleryClearItems>
          )}
        </GalleryWrapper>
      )}
    </>
  )
}
