import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement23Square4Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="315"
        height="333"
        viewBox="0 0 315 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#232filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M280 40.9995C280 39.8952 280.895 39 281.999 39C283.104 39 283.999 39.8952 283.999 40.9995V43.1665H283.999V54H284V57.0005C284 58.1048 283.105 59 282.001 59C280.896 59 280.001 58.1048 280.001 57.0005V54.8332H280V44H280V40.9995Z"
            fill="url(#232paint0_linear)"
          />
        </g>
        <rect x="280" y="43.1665" width="3.99896" height="11.6667" fill="#ABABAB" />
        <path
          d="M280 39.9399C280 39.4208 280.421 39 280.94 39H283.059C283.578 39 283.999 39.4208 283.999 39.9399V44H280V39.9399Z"
          fill="url(#232paint1_linear)"
        />
        <path
          d="M284 58.0601C284 58.5792 283.579 59 283.06 59H280.941C280.422 59 280.001 58.5792 280.001 58.0601V54H284V58.0601Z"
          fill="url(#232paint2_linear)"
        />
        <rect x="280" y="44.8335" width="3.99896" height="8.33334" fill="url(#232paint3_linear)" />
        <g filter="url(#232filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M280 80C280 78.8954 280.895 78 282 78C283.105 78 284 78.8954 284 80V94.5599H280V80ZM280 94.5602H284V96.4H284.06C284.579 96.4 285 96.8209 285 97.34V99.1201C285 100.158 284.158 101 283.12 101H281.88C280.842 101 280 100.158 280 99.1201V96.4002V96.4V94.5602Z"
            fill="white"
          />
        </g>
        <rect x="280" y="95.25" width="3.99998" height="0.95833" fill="#ABABAB" />
        <path
          d="M280 78.9399C280 78.4208 280.421 78 280.94 78H283.06C283.579 78 284 78.4208 284 78.9399V95.2499H280V78.9399Z"
          fill="url(#232paint4_linear)"
        />
        <path
          d="M280 100.06C280 100.579 280.421 101 280.94 101H284.06C284.579 101 285 100.579 285 100.06V97.1483C285 96.6292 284.579 96.2083 284.06 96.2083H280V100.06Z"
          fill="url(#232paint5_linear)"
        />
        <g filter="url(#232filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M280 34H165V105H280V34ZM269 45H176V94H269V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M280 34H165V105H166V35H279V105H280V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="169.487"
          y="38.53"
          width="106.026"
          height="61.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#232filter3_i)">
          <rect
            x="176.47"
            y="45.47"
            width="92.0601"
            height="48.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="167"
          y="63"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#232filter4_d)">
          <rect x="167.702" y="63.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M168.286 74.7956C168.798 75.4577 169.6 75.8841 170.502 75.8841C172.048 75.8841 173.302 74.6305 173.302 73.0841V66.4442C173.302 65.4557 172.79 64.5869 172.016 64.0886C172.382 64.5619 172.6 65.1556 172.6 65.8001V72.44C172.6 73.9864 171.347 75.24 169.8 75.24C169.242 75.24 168.722 75.0768 168.286 74.7956Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M170.502 63.644C168.956 63.644 167.702 64.8976 167.702 66.444V72.4397C167.702 72.4601 167.702 72.4805 167.703 72.5007V67.0236C167.703 65.4772 168.956 64.2236 170.503 64.2236C172.029 64.2236 173.269 65.4444 173.302 66.9627V66.444C173.302 64.8976 172.048 63.644 170.502 63.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#232filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M170.5 67C169.672 67 169 67.6716 169 68.5V68.7143V70V88.5C169 89.3284 169.672 90 170.5 90C171.328 90 172 89.3284 172 88.5V72V68.5L172 68.2857C172 67.5756 171.424 67 170.714 67C170.677 67 170.64 67.0012 170.604 67.0035C170.569 67.0012 170.535 67 170.5 67Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M267.5 92.3545L267.5 46.6455L179 69.5L267.5 92.3545Z" stroke="white" />
        <path d="M266.82 92.5L178.18 92.5L222.5 46.7189L266.82 92.5Z" stroke="white" />
        <defs>
          <filter
            id="232filter0_d"
            x="278.12"
            y="37.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="232filter1_d"
            x="278.12"
            y="76.1201"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="232filter2_d"
            x="160.3"
            y="29.3003"
            width="126.279"
            height="82.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="232filter3_i"
            x="176"
            y="45"
            width="93.9399"
            height="51.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="232filter4_d"
            x="165.822"
            y="61.7642"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="232filter5_d"
            x="167.12"
            y="65.1201"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="232paint0_linear"
            x1="277.2"
            y1="56.5"
            x2="284.8"
            y2="56.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="232paint1_linear"
            x1="286.798"
            y1="41.5"
            x2="279.2"
            y2="41.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="232paint2_linear"
            x1="277.202"
            y1="56.5"
            x2="284.8"
            y2="56.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="232paint3_linear"
            x1="286.798"
            y1="49.0002"
            x2="279.2"
            y2="49.0002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="232paint4_linear"
            x1="286.8"
            y1="86.625"
            x2="279.2"
            y2="86.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="232paint5_linear"
            x1="288.5"
            y1="98.6042"
            x2="279"
            y2="98.6042"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
