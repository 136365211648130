import React from 'react'

export const NoneIcon = ({ color = '#1C3775' }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="11" stroke={color} strokeWidth="2" />
      <line x1="19.7071" y1="4.70711" x2="3.70711" y2="20.7071" stroke={color} strokeWidth="2" />
    </svg>
  )
}
