import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement13Square4VerticalCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#1-3_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.954 38.9765C31.954 37.8849 31.0691 37 29.9775 37C28.886 37 28.0011 37.8849 28.0011 38.9765L28.0011 41.1665L28.001 41.1665L28.001 52L27.9999 52L27.9999 55.0235C27.9999 56.1151 28.8848 57 29.9764 57C31.068 57 31.9529 56.1151 31.9529 55.0235L31.9529 52.8332L31.954 52.8332L31.954 42L31.954 42L31.954 38.9765Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 31.954 41.1665)"
          fill="#ABABAB"
        />
        <path
          d="M31.954 37.9399C31.954 37.4208 31.5332 37 31.0141 37L28.941 37C28.4219 37 28.0011 37.4208 28.0011 37.9399L28.0011 42L31.954 42L31.954 37.9399Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M27.9999 56.0601C27.9999 56.5792 28.4208 57 28.9399 57L31.013 57C31.5321 57 31.9529 56.5792 31.9529 56.0601L31.9529 52L27.9999 52L27.9999 56.0601Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 31.954 42.8334)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#1-3_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.954 65.9765C31.954 64.8849 31.0691 64 29.9775 64C28.886 64 28.0011 64.8849 28.0011 65.9765L28.0011 68.1665L28.001 68.1665L28.001 79L27.9999 79L27.9999 82.0235C27.9999 83.1151 28.8848 84 29.9764 84C31.068 84 31.9529 83.1151 31.9529 82.0235L31.9529 79.8332L31.954 79.8332L31.954 69L31.954 69L31.954 65.9765Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 31.954 68.1665)"
          fill="#ABABAB"
        />
        <path
          d="M31.954 64.9399C31.954 64.4208 31.5332 64 31.0141 64L28.941 64C28.4219 64 28.0011 64.4208 28.0011 64.9399L28.0011 69L31.954 69L31.954 64.9399Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M27.9999 83.0601C27.9999 83.5792 28.4208 84 28.9399 84L31.013 84C31.5321 84 31.9529 83.5792 31.9529 83.0601L31.9529 79L27.9999 79L27.9999 83.0601Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 31.954 69.8334)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#1-3_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 33H32.0001V88H188V33ZM178 43H42.0001V78H178V43Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 33H32.0001V88H33.0001V34H187V88H188V33Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-3_filter3_i)">
          <rect
            x="42.47"
            y="43.47"
            width="135.06"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.5301"
          y="37.53"
          width="146.94"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M43.5001 44.5653L172.909 60.5L43.5001 76.4347L43.5001 44.5653Z" stroke="white" />
        <rect
          width="6.91954"
          height="13.5283"
          rx="3.45977"
          transform="matrix(-1 0 0 1 185.92 54)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#1-3_filter4_d)">
          <rect
            width="5.53563"
            height="12.2399"
            rx="2.76782"
            transform="matrix(-1 0 0 1 185.226 54.644)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M184.661 65.7924C184.155 66.4558 183.356 66.884 182.458 66.884C180.929 66.884 179.69 65.6448 179.69 64.1161V57.4119C179.69 56.4401 180.191 55.5854 180.948 55.0916C180.594 55.5568 180.383 56.1377 180.383 56.7678V63.4721C180.383 65.0007 181.622 66.2399 183.151 66.2399C183.708 66.2399 184.226 66.0754 184.661 65.7924Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M182.458 54.644C183.986 54.644 185.226 55.8832 185.226 57.4119V63.4719C185.226 63.4922 185.225 63.5124 185.225 63.5326V57.9914C185.225 56.4628 183.986 55.2236 182.457 55.2236C180.949 55.2236 179.722 56.4302 179.69 57.9308V57.4119C179.69 55.8832 180.929 54.644 182.458 54.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-3_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M182.46 58C183.279 58 183.943 58.6639 183.943 59.4828V61C183.943 61.0136 183.943 61.0271 183.942 61.0406L183.942 79.5172C183.942 80.3361 183.278 81 182.46 81V81C181.641 81 180.977 80.3361 180.977 79.5172L180.977 59.2513C180.977 58.5602 181.537 58 182.228 58V58C182.231 58 182.234 58 182.237 58C182.311 58.0004 182.386 58 182.46 58V58Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="1-3_filter0_d"
            x="26.1201"
            y="35.1201"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-3_filter1_d"
            x="26.1201"
            y="62.1201"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-3_filter2_d"
            x="27.3004"
            y="28.3003"
            width="167.279"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-3_filter3_i"
            x="42.0001"
            y="43"
            width="136.94"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="1-3_filter4_d"
            x="177.81"
            y="52.7642"
            width="11.1753"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-3_filter5_d"
            x="179.097"
            y="56.1201"
            width="8.60556"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="34.7218"
            y1="54.5"
            x2="27.2092"
            y2="54.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="25.234"
            y1="39.5"
            x2="32.7446"
            y2="39.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="34.72"
            y1="54.5"
            x2="27.2093"
            y2="54.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="34.7218"
            y1="81.5"
            x2="27.2092"
            y2="81.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="25.234"
            y1="66.5"
            x2="32.7446"
            y2="66.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="34.72"
            y1="81.5"
            x2="27.2093"
            y2="81.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
