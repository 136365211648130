import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement11Square4Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="315"
        height="333"
        viewBox="0 0 315 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#233filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35 40.9995C35 39.8952 34.1048 39 33.0005 39C31.8962 39 31.001 39.8952 31.001 40.9995V43.1665H31.001V54H31V57.0005C31 58.1048 31.8952 59 32.9995 59C34.1038 59 34.999 58.1048 34.999 57.0005V54.8332H35V44H35V40.9995Z"
            fill="url(#233paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 35 43.1665)"
          fill="#ABABAB"
        />
        <path
          d="M35 39.9399C35 39.4208 34.5792 39 34.0601 39H31.941C31.4219 39 31.001 39.4208 31.001 39.9399V44H35V39.9399Z"
          fill="url(#233paint1_linear)"
        />
        <path
          d="M31 58.0601C31 58.5792 31.4208 59 31.9399 59H34.059C34.5781 59 34.999 58.5792 34.999 58.0601V54H31V58.0601Z"
          fill="url(#233paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 35 44.8335)"
          fill="url(#233paint3_linear)"
        />
        <g filter="url(#233filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35 80C35 78.8954 34.1046 78 33 78C31.8954 78 31 78.8954 31 80V94.5599H35V80ZM35 94.5602H31V96.4H30.9399C30.4208 96.4 30 96.8209 30 97.34V99.1201C30 100.158 30.8417 101 31.8799 101H33.1201C34.1583 101 35 100.158 35 99.1201V96.4002V96.4V94.5602Z"
            fill="white"
          />
        </g>
        <rect
          width="3.99998"
          height="0.95833"
          transform="matrix(-1 0 0 1 35 95.25)"
          fill="#ABABAB"
        />
        <path
          d="M35 78.9399C35 78.4208 34.5792 78 34.0601 78H31.94C31.4208 78 31 78.4208 31 78.9399V95.2499H35V78.9399Z"
          fill="url(#233paint4_linear)"
        />
        <path
          d="M35 100.06C35 100.579 34.5792 101 34.0601 101H30.94C30.4208 101 30 100.579 30 100.06V97.1483C30 96.6292 30.4208 96.2083 30.94 96.2083H35V100.06Z"
          fill="url(#233paint5_linear)"
        />
        <g filter="url(#233filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M150 34H35V105H150V34ZM139 45H46V94H139V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M150 34H35V105H36V35H149V105H150V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="39.4868"
          y="38.53"
          width="106.026"
          height="61.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#233filter3_i)">
          <rect
            x="46.47"
            y="45.47"
            width="92.0601"
            height="48.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="141"
          y="63"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#233filter4_d)">
          <rect x="141.702" y="63.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M142.286 74.7956C142.798 75.4577 143.6 75.8841 144.502 75.8841C146.048 75.8841 147.302 74.6305 147.302 73.0841V66.4442C147.302 65.4557 146.79 64.5869 146.016 64.0886C146.382 64.5619 146.6 65.1556 146.6 65.8001V72.44C146.6 73.9864 145.347 75.24 143.8 75.24C143.242 75.24 142.722 75.0768 142.286 74.7956Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M144.502 63.644C142.956 63.644 141.702 64.8976 141.702 66.444V72.4397C141.702 72.4601 141.702 72.4805 141.703 72.5007V67.0236C141.703 65.4772 142.956 64.2236 144.503 64.2236C146.029 64.2236 147.269 65.4444 147.302 66.9627V66.444C147.302 64.8976 146.048 63.644 144.502 63.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#233filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M144.5 67C143.672 67 143 67.6716 143 68.5V68.7143V70V88.5C143 89.3284 143.672 90 144.5 90C145.328 90 146 89.3284 146 88.5V72V68.5L146 68.2857C146 67.5756 145.424 67 144.714 67C144.677 67 144.64 67.0012 144.604 67.0035C144.569 67.0012 144.535 67 144.5 67Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M47.5 92.3545L47.5 46.6455L136 69.5L47.5 92.3545Z" stroke="white" />
        <path d="M136.82 92.5L48.18 92.5L92.5 46.7189L136.82 92.5Z" stroke="white" />
        <defs>
          <filter
            id="233filter0_d"
            x="29.1201"
            y="37.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="233filter1_d"
            x="28.1201"
            y="76.1201"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="233filter2_d"
            x="30.3003"
            y="29.3003"
            width="126.279"
            height="82.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="233filter3_i"
            x="46"
            y="45"
            width="93.9399"
            height="51.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="233filter4_d"
            x="139.822"
            y="61.7642"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="233filter5_d"
            x="141.12"
            y="65.1201"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="233paint0_linear"
            x1="37.8"
            y1="56.5"
            x2="30.2"
            y2="56.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="233paint1_linear"
            x1="28.2018"
            y1="41.5"
            x2="35.7998"
            y2="41.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="233paint2_linear"
            x1="37.7982"
            y1="56.5"
            x2="30.2002"
            y2="56.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="233paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="233paint4_linear"
            x1="28.2"
            y1="86.625"
            x2="35.8"
            y2="86.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="233paint5_linear"
            x1="26.5"
            y1="98.6042"
            x2="36"
            y2="98.6042"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
