import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement91Square3Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#9-1_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M304.999 111C303.895 111 303 111.895 303 112.999C303 114.104 303.895 114.999 304.999 114.999L307.167 114.999L307.167 114.999L318 114.999L318 115L321.001 115C322.105 115 323 114.105 323 113.001C323 111.896 322.105 111.001 321.001 111.001L318.833 111.001L318.833 111L308 111L308 111L304.999 111Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 307.167 111)"
          fill="#ABABAB"
        />
        <path
          d="M303.94 111C303.421 111 303 111.421 303 111.94L303 114.059C303 114.578 303.421 114.999 303.94 114.999L308 114.999L308 111L303.94 111Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M322.06 115C322.579 115 323 114.579 323 114.06L323 111.941C323 111.422 322.579 111.001 322.06 111.001L318 111.001L318 115L322.06 115Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 308.833 111)"
          fill="url(#paint9-1_linear)"
        />
        <g filter="url(#9-1_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M392.999 111C391.895 111 391 111.895 391 112.999C391 114.104 391.895 114.999 392.999 114.999L395.167 114.999L395.167 114.999L406 114.999L406 115L409.001 115C410.105 115 411 114.105 411 113.001C411 111.896 410.105 111.001 409.001 111.001L406.833 111.001L406.833 111L396 111L396 111L392.999 111Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 395.167 111)"
          fill="#ABABAB"
        />
        <path
          d="M391.94 111C391.421 111 391 111.421 391 111.94L391 114.059C391 114.578 391.421 114.999 391.94 114.999L396 114.999L396 111L391.94 111Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M410.06 115C410.579 115 411 114.579 411 114.06L411 111.941C411 111.422 410.579 111.001 410.06 111.001L406 111.001L406 115L410.06 115Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 396.833 111)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#9-1_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 34H297V111H417V34ZM406 45H308V100H406V45Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#9-1_filter9-1_i)">
          <rect
            x="308.47"
            y="45.47"
            width="97.0601"
            height="54.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="302.5" y="39.5" width="109" height="66" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 34H297V111H298V35H416V111H417V34Z"
          fill="#FBFBFB"
        />
        <path d="M403.873 98.5L310.127 98.5L357 46.7448L403.873 98.5Z" stroke="white" />
        <rect
          x="364.528"
          y="36"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 364.528 36)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#9-1_filter4_d)">
          <rect
            x="363.884"
            y="36.7021"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 363.884 36.7021)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M363.766 38.6953C363.42 37.5422 362.35 36.7021 361.084 36.7021L354.444 36.7021C352.898 36.7021 351.644 37.9558 351.644 39.5021C351.644 39.5373 351.645 39.5723 351.646 39.6072C351.993 40.7602 353.062 41.6003 354.328 41.6003L360.968 41.6003C362.514 41.6003 363.768 40.3467 363.768 38.8003C363.768 38.7652 363.767 38.7302 363.766 38.6953Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M362.409 37.0349C362.266 37.0121 362.118 37.0003 361.968 37.0003L355.328 37.0003C353.782 37.0003 352.528 38.2539 352.528 39.8003C352.528 40.8674 353.125 41.795 354.003 42.2676C352.666 42.0561 351.644 40.8985 351.644 39.5021C351.644 37.9558 352.898 36.7021 354.444 36.7021L361.084 36.7021C361.564 36.7021 362.015 36.8226 362.409 37.0349Z"
          fill="#FBFBFB"
        />
        <g filter="url(#9-1_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M377.528 39.5C377.528 38.6716 376.857 38 376.028 38L375.814 38L374.528 38L356.028 38C355.2 38 354.528 38.6716 354.528 39.5C354.528 40.3284 355.2 41 356.028 41L372.528 41L376.028 41L376.243 41C376.953 41 377.528 40.4244 377.528 39.7143C377.528 39.6771 377.527 39.6401 377.525 39.6035C377.527 39.5693 377.528 39.5348 377.528 39.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="9-1_filter0_d"
            x="301.12"
            y="109.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="9-1_filter1_d"
            x="389.12"
            y="109.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="9-1_filter2_d"
            x="292.3"
            y="29.3003"
            width="131.279"
            height="88.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="9-1_filter9-1_i"
            x="308"
            y="45"
            width="98.9399"
            height="57.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="9-1_filter4_d"
            x="349.765"
            y="34.8223"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="9-1_filter5_d"
            x="353.649"
            y="36.1201"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="320.5"
            y1="108.2"
            x2="320.5"
            y2="115.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="305.5"
            y1="117.798"
            x2="305.5"
            y2="110.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="320.5"
            y1="108.202"
            x2="320.5"
            y2="115.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint9-1_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="408.5"
            y1="108.2"
            x2="408.5"
            y2="115.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="393.5"
            y1="117.798"
            x2="393.5"
            y2="110.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="408.5"
            y1="108.202"
            x2="408.5"
            y2="115.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
