import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement61Square3Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#6-1_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M304.999 199C303.895 199 303 199.895 303 200.999C303 202.104 303.895 202.999 304.999 202.999L307.167 202.999L307.167 202.999L318 202.999L318 203L321.001 203C322.105 203 323 202.105 323 201.001C323 199.896 322.105 199.001 321.001 199.001L318.833 199.001L318.833 199L308 199L308 199L304.999 199Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 307.167 199)"
          fill="#ABABAB"
        />
        <path
          d="M303.94 199C303.421 199 303 199.421 303 199.94L303 202.059C303 202.578 303.421 202.999 303.94 202.999L308 202.999L308 199L303.94 199Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M322.06 203C322.579 203 323 202.579 323 202.06L323 199.941C323 199.422 322.579 199.001 322.06 199.001L318 199.001L318 203L322.06 203Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 308.833 199)"
          fill="url(#paint6-1_linear)"
        />
        <g filter="url(#6-1_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M392.999 199C391.895 199 391 199.895 391 200.999C391 202.104 391.895 202.999 392.999 202.999L395.167 202.999L395.167 202.999L406 202.999L406 203L409.001 203C410.105 203 411 202.105 411 201.001C411 199.896 410.105 199.001 409.001 199.001L406.833 199.001L406.833 199L396 199L396 199L392.999 199Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 395.167 199)"
          fill="#ABABAB"
        />
        <path
          d="M391.94 199C391.421 199 391 199.421 391 199.94L391 202.059C391 202.578 391.421 202.999 391.94 202.999L396 202.999L396 199L391.94 199Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M410.06 203C410.579 203 411 202.579 411 202.06L411 199.941C411 199.422 410.579 199.001 410.06 199.001L406 199.001L406 203L410.06 203Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 396.833 199)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#6-1_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 122H297V199H417V122ZM406 133H308V188H406V133Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#6-1_filter6-1_i)">
          <rect
            x="308.47"
            y="133.47"
            width="97.0601"
            height="54.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="302.5" y="127.5" width="109" height="66" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 122H297V199H298V123H416V199H417V122Z"
          fill="#FBFBFB"
        />
        <path d="M403.873 186.5H310.127L357 134.745L403.873 186.5Z" stroke="white" />
        <rect
          x="364.528"
          y="124"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 364.528 124)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#6-1_filter4_d)">
          <rect
            x="363.884"
            y="124.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 363.884 124.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M363.766 126.695C363.42 125.542 362.35 124.702 361.084 124.702L354.444 124.702C352.898 124.702 351.644 125.956 351.644 127.502C351.644 127.537 351.645 127.572 351.646 127.607C351.993 128.76 353.062 129.6 354.328 129.6L360.968 129.6C362.514 129.6 363.768 128.347 363.768 126.8C363.768 126.765 363.767 126.73 363.766 126.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M362.409 125.035C362.266 125.012 362.118 125 361.968 125L355.328 125C353.782 125 352.528 126.254 352.528 127.8C352.528 128.867 353.125 129.795 354.003 130.268C352.666 130.056 351.644 128.898 351.644 127.502C351.644 125.956 352.898 124.702 354.444 124.702L361.084 124.702C361.564 124.702 362.015 124.823 362.409 125.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#6-1_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M377.528 127.5C377.528 126.672 376.857 126 376.028 126L375.814 126L374.528 126L356.028 126C355.2 126 354.528 126.672 354.528 127.5C354.528 128.328 355.2 129 356.028 129L372.528 129L376.028 129L376.243 129C376.953 129 377.528 128.424 377.528 127.714C377.528 127.677 377.527 127.64 377.525 127.604C377.527 127.569 377.528 127.535 377.528 127.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="6-1_filter0_d"
            x="301.12"
            y="197.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-1_filter1_d"
            x="389.12"
            y="197.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-1_filter2_d"
            x="292.3"
            y="117.3"
            width="131.279"
            height="88.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-1_filter6-1_i"
            x="308"
            y="133"
            width="98.9399"
            height="57.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="6-1_filter4_d"
            x="349.765"
            y="122.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-1_filter5_d"
            x="353.649"
            y="124.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="320.5"
            y1="196.2"
            x2="320.5"
            y2="203.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="305.5"
            y1="205.798"
            x2="305.5"
            y2="198.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="320.5"
            y1="196.202"
            x2="320.5"
            y2="203.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6-1_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="408.5"
            y1="196.2"
            x2="408.5"
            y2="203.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="393.5"
            y1="205.798"
            x2="393.5"
            y2="198.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="408.5"
            y1="196.202"
            x2="408.5"
            y2="203.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
