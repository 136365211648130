import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement44Square4TopCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-top-4-4_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M326.001 291C327.105 291 328 290.105 328 289.001C328 287.896 327.105 287.001 326.001 287.001L323.833 287.001L323.833 287.001L313 287.001L313 287L309.999 287C308.895 287 308 287.895 308 288.999C308 290.104 308.895 290.999 309.999 290.999L312.167 290.999L312.167 291L323 291L323 291L326.001 291Z"
            fill="url(#4-top-4-4_paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 323.834 291)"
          fill="#ABABAB"
        />
        <path
          d="M327.06 291C327.579 291 328 290.579 328 290.06L328 287.941C328 287.422 327.579 287.001 327.06 287.001L323 287.001L323 291L327.06 291Z"
          fill="url(#4-top-4-4_paint1_linear)"
        />
        <path
          d="M308.94 287C308.421 287 308 287.421 308 287.94L308 290.059C308 290.578 308.421 290.999 308.94 290.999L313 290.999L313 287L308.94 287Z"
          fill="url(#4-top-4-4_paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 322.167 291)"
          fill="url(#4-top-4-4_paint3_linear)"
        />
        <g filter="url(#4-top-4-4_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M404.001 291C405.105 291 406 290.105 406 289.001C406 287.896 405.105 287.001 404.001 287.001L401.833 287.001L401.833 287.001L391 287.001L391 287L387.999 287C386.895 287 386 287.895 386 288.999C386 290.104 386.895 290.999 387.999 290.999L390.167 290.999L390.167 291L401 291L401 291L404.001 291Z"
            fill="url(#4-top-4-4_paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 401.834 291)"
          fill="#ABABAB"
        />
        <path
          d="M405.06 291C405.579 291 406 290.579 406 290.06L406 287.941C406 287.422 405.579 287.001 405.06 287.001L401 287.001L401 291L405.06 291Z"
          fill="url(#4-top-4-4_paint5_linear)"
        />
        <path
          d="M386.94 287C386.421 287 386 287.421 386 287.94L386 290.059C386 290.578 386.421 290.999 386.94 290.999L391 290.999L391 287L386.94 287Z"
          fill="url(#4-top-4-4_paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 400.167 291)"
          fill="url(#4-top-4-4_paint7_linear)"
        />
        <g filter="url(#4-top-4-4_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 128H297V287H417V128ZM406 139H308V276H406V139Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 128H297V287H298V129H416V287H417V128Z"
          fill="#FBFBFB"
        />
        <rect
          x="301.53"
          y="132.53"
          width="110.94"
          height="149.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#4-top-4-4_filter3_i)">
          <rect
            x="308.47"
            y="139.47"
            width="97.0601"
            height="136.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <path d="M404.295 277.5H309.705L357 142.512L404.295 277.5Z" stroke="white" />
        <rect
          x="363.528"
          y="130"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 363.528 130)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#4-top-4-4_filter4_d)">
          <rect
            x="362.884"
            y="130.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 362.884 130.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M362.767 132.695C362.42 131.542 361.351 130.702 360.085 130.702L353.445 130.702C351.898 130.702 350.645 131.955 350.645 133.502C350.645 133.537 350.645 133.572 350.647 133.607C350.993 134.76 352.063 135.6 353.329 135.6L359.969 135.6C361.515 135.6 362.769 134.346 362.769 132.8C362.769 132.765 362.768 132.73 362.767 132.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M361.409 131.035C361.266 131.012 361.118 131 360.968 131L354.328 131C352.782 131 351.528 132.254 351.528 133.8C351.528 134.867 352.125 135.795 353.003 136.267C351.666 136.056 350.644 134.898 350.644 133.502C350.644 131.955 351.898 130.702 353.444 130.702L360.084 130.702C360.564 130.702 361.015 130.822 361.409 131.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-top-4-4_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M376.528 133.5C376.528 132.672 375.857 132 375.028 132L374.814 132L373.528 132L355.028 132C354.2 132 353.528 132.672 353.528 133.5C353.528 134.328 354.2 135 355.028 135L371.528 135L375.028 135L375.243 135C375.953 135 376.528 134.424 376.528 133.714C376.528 133.677 376.527 133.64 376.525 133.604C376.527 133.569 376.528 133.535 376.528 133.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="4-top-4-4_filter0_d"
            x="306.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-4-4_filter1_d"
            x="384.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-4-4_filter2_d"
            x="292.3"
            y="123.3"
            width="131.279"
            height="170.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-4-4_filter3_i"
            x="308"
            y="139"
            width="98.9399"
            height="139.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-top-4-4_filter4_d"
            x="348.764"
            y="128.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-4-4_filter5_d"
            x="352.649"
            y="130.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="4-top-4-4_paint0_linear"
            x1="310.5"
            y1="293.8"
            x2="310.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="4-top-4-4_paint1_linear"
            x1="325.5"
            y1="284.202"
            x2="325.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-4-4_paint2_linear"
            x1="310.5"
            y1="293.798"
            x2="310.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-4-4_paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-4-4_paint4_linear"
            x1="388.5"
            y1="293.8"
            x2="388.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="4-top-4-4_paint5_linear"
            x1="403.5"
            y1="284.202"
            x2="403.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-4-4_paint6_linear"
            x1="388.5"
            y1="293.798"
            x2="388.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-4-4_paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
