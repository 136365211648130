import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement44Square41Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-4-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M350.001 291C351.105 291 352 290.105 352 289.001C352 287.896 351.105 287.001 350.001 287.001L347.833 287.001L347.833 287.001L337 287.001L337 287L333.999 287C332.895 287 332 287.895 332 288.999C332 290.104 332.895 290.999 333.999 290.999L336.167 290.999L336.167 291L347 291L347 291L350.001 291Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 347.833 291)"
          fill="#ABABAB"
        />
        <path
          d="M351.06 291C351.579 291 352 290.579 352 290.06L352 287.941C352 287.422 351.579 287.001 351.06 287.001L347 287.001L347 291L351.06 291Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M332.94 287C332.421 287 332 287.421 332 287.94L332 290.059C332 290.578 332.421 290.999 332.94 290.999L337 290.999L337 287L332.94 287Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 346.167 291)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#4-4-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M411.001 291C412.105 291 413 290.105 413 289.001C413 287.896 412.105 287.001 411.001 287.001L408.833 287.001L408.833 287.001L398 287.001L398 287L394.999 287C393.895 287 393 287.895 393 288.999C393 290.104 393.895 290.999 394.999 290.999L397.167 290.999L397.167 291L408 291L408 291L411.001 291Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 408.833 291)"
          fill="#ABABAB"
        />
        <path
          d="M412.06 291C412.579 291 413 290.579 413 290.06L413 287.941C413 287.422 412.579 287.001 412.06 287.001L408 287.001L408 291L412.06 291Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M393.94 287C393.421 287 393 287.421 393 287.94L393 290.059C393 290.578 393.421 290.999 393.94 290.999L398 290.999L398 287L393.94 287Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 407.167 291)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#4-4-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 34H328V287H417V34ZM406 45H339V276H406V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 34H328V287H329.219V35H415.781V287H417V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-4-filter3_i)">
          <rect
            x="339.47"
            y="45.47"
            width="66.0601"
            height="230.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="332.53"
          y="38.53"
          width="79.9399"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="379.528"
          y="36"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 379.528 36)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#4-4-filter4_d)">
          <rect
            x="378.884"
            y="36.7018"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 378.884 36.7018)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M378.766 38.695C378.42 37.5419 377.35 36.7018 376.084 36.7018L369.444 36.7018C367.898 36.7018 366.644 37.9554 366.644 39.5018C366.644 39.537 366.645 39.572 366.646 39.6068C366.993 40.7599 368.062 41.6 369.328 41.6L375.968 41.6C377.515 41.6 378.768 40.3464 378.768 38.8C378.768 38.7649 378.768 38.7299 378.766 38.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M377.409 37.0346C377.266 37.0118 377.118 37 376.968 37L370.328 37C368.782 37 367.528 38.2536 367.528 39.8C367.528 40.8671 368.125 41.7947 369.003 42.2673C367.666 42.0558 366.644 40.8982 366.644 39.5018C366.644 37.9554 367.898 36.7018 369.444 36.7018L376.084 36.7018C376.564 36.7018 377.015 36.8223 377.409 37.0346Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-4-filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M392.528 39.5C392.528 38.6716 391.857 38 391.028 38L390.814 38L389.528 38L371.028 38C370.2 38 369.528 38.6716 369.528 39.5V39.5C369.528 40.3284 370.2 41 371.028 41L387.528 41L391.028 41L391.243 41C391.953 41 392.528 40.4244 392.528 39.7143V39.7143C392.528 39.6771 392.527 39.6401 392.525 39.6035C392.527 39.5693 392.528 39.5348 392.528 39.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M404.424 274.5L372.5 49.5584L340.576 274.5L404.424 274.5Z" stroke="white" />
        <defs>
          <filter
            id="4-4-filter0_d"
            x="330.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-4-filter1_d"
            x="391.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-4-filter2_d"
            x="323.3"
            y="29.3003"
            width="100.279"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-4-filter3_i"
            x="339"
            y="45"
            width="67.9399"
            height="233.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-4-filter4_d"
            x="364.764"
            y="34.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-4-filter5_d"
            x="368.649"
            y="36.1201"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="334.5"
            y1="293.8"
            x2="334.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="349.5"
            y1="284.202"
            x2="349.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="334.5"
            y1="293.798"
            x2="334.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="395.5"
            y1="293.8"
            x2="395.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="410.5"
            y1="284.202"
            x2="410.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="395.5"
            y1="293.798"
            x2="395.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
