import fetchAPI from '../../../../lib/utils/fetch-api'

export async function getIsOldCase() {
  const response = await fetchAPI('web-hooks/get-old-order-products-price-current-user').catch(console.error)
  return response?.['data']?.['status'] || false
}


export async function updatePriceOldCase() {
  const response = await fetchAPI('web-hooks/update-order-products-price-current-user', {
    method: 'POST',
    body: JSON.stringify({}),
  }).catch(console.error)
  return response
}
