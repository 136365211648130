import React from 'react'

export const SettingIcon = ({ color = '#ffffff' }) => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5312 6.699H14.4963C14.3431 6.10703 14.1123 5.54068 13.8072 5.00787L14.5407 4.25963C14.6287 4.16995 14.6781 4.04834 14.6781 3.92149C14.6781 3.79465 14.6287 3.67307 14.5407 3.5834L12.773 1.78012C12.59 1.59341 12.2932 1.59337 12.1101 1.78012L11.3765 2.52839C10.8542 2.21716 10.299 1.98174 9.71875 1.82545V0.769675C9.71875 0.505597 9.50887 0.291504 9.25 0.291504H6.75C6.49113 0.291504 6.28125 0.505597 6.28125 0.769675V1.82545C5.70097 1.98174 5.14578 2.21716 4.62347 2.52839L3.88994 1.78012C3.70691 1.59341 3.41009 1.59337 3.22703 1.78012L1.45928 3.5834C1.37137 3.67307 1.32197 3.79468 1.32197 3.92149C1.32197 4.04831 1.37137 4.16992 1.45928 4.25963L2.19281 5.00787C1.88769 5.54071 1.65694 6.10703 1.50369 6.699H0.46875C0.209875 6.699 0 6.91309 0 7.17717V9.72742C0 9.9915 0.209875 10.2056 0.46875 10.2056H1.50369C1.65691 10.7976 1.88769 11.3639 2.19278 11.8967L1.45925 12.6449C1.37134 12.7346 1.32194 12.8562 1.32194 12.9831C1.32194 13.1099 1.37131 13.2315 1.45925 13.3212L3.227 15.1245C3.31491 15.2142 3.43413 15.2646 3.55844 15.2646C3.68275 15.2646 3.80197 15.2142 3.88987 15.1245L4.62341 14.3762C5.14572 14.6874 5.70088 14.9228 6.28119 15.0791V16.1349C6.28119 16.399 6.49106 16.6131 6.74994 16.6131H9.24994C9.50881 16.6131 9.71869 16.399 9.71869 16.1349V15.0792C10.299 14.9229 10.8542 14.6875 11.3765 14.3762L12.11 15.1245C12.1979 15.2142 12.3171 15.2646 12.4414 15.2646C12.5658 15.2646 12.685 15.2142 12.7729 15.1245L14.5406 13.3212C14.6285 13.2315 14.6779 13.1099 14.6779 12.9831C14.6779 12.8563 14.6285 12.7347 14.5406 12.645L13.8071 11.8967C14.1122 11.3639 14.3429 10.7976 14.4962 10.2056H15.5312C15.7901 10.2056 16 9.99153 16 9.72745V7.17721C16 6.9131 15.7901 6.699 15.5312 6.699ZM8 12.9152C5.58759 12.9152 3.625 10.9132 3.625 8.4523C3.625 5.99144 5.58763 3.98936 8 3.98936C10.4124 3.98936 12.375 5.99144 12.375 8.4523C12.375 10.9132 10.4124 12.9152 8 12.9152Z"
        fill={color}
      />
    </svg>
  )
}
