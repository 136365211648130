import styled from 'styled-components'

export const HRWrapper = styled.div`
  width: 100%;
  border-top: 1px dashed #d4d4d4;

  position: relative;

  margin: 28px 0;
`
