import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement11Square4TopCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-top-1-1_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M404.217 34C405.322 34 406.217 33.1048 406.217 32.0005C406.217 30.8962 405.322 30.001 404.217 30.001L401.797 30.001L401.797 30.001L390.304 30.001L390.304 30L386.999 30C385.895 30 385 30.8952 385 31.9995C385 33.1038 385.895 33.999 386.999 33.999L389.42 33.999L389.42 34L400.913 34L400.913 34L404.217 34Z"
            fill="url(#4-top-1-1_paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="12.3767"
          transform="matrix(1.26506e-08 -1 -1 -1.12408e-08 401.797 34)"
          fill="#ABABAB"
        />
        <path
          d="M405.277 34C405.796 34 406.217 33.5792 406.217 33.0601L406.217 30.941C406.217 30.4219 405.796 30.001 405.277 30.001L400.913 30.001L400.913 34L405.277 34Z"
          fill="url(#4-top-1-1_paint1_linear)"
        />
        <path
          d="M385.94 30C385.421 30 385 30.4208 385 30.9399L385 33.059C385 33.5781 385.421 33.999 385.94 33.999L390.304 33.999L390.304 30L385.94 30Z"
          fill="url(#4-top-1-1_paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.84048"
          transform="matrix(1.26506e-08 -1 -1 -1.12408e-08 400.028 34)"
          fill="url(#4-top-1-1_paint3_linear)"
        />
        <g filter="url(#4-top-1-1_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M62.8872 34C63.9915 34 64.8867 33.1048 64.8867 32.0005C64.8867 30.8962 63.9915 30.001 62.8872 30.001L60.4666 30.001L60.4666 30.001L48.9739 30.001L48.9739 30L45.669 30C44.5648 30 43.6696 30.8952 43.6696 31.9995C43.6696 33.1038 44.5648 33.999 45.669 33.999L48.0899 33.999L48.0899 34L59.5824 34L59.5824 34L62.8872 34Z"
            fill="url(#4-top-1-1_paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="12.3767"
          transform="matrix(1.26506e-08 -1 -1 -1.12408e-08 60.4668 34)"
          fill="#ABABAB"
        />
        <path
          d="M63.9468 34C64.4659 34 64.8867 33.5792 64.8867 33.0601L64.8867 30.941C64.8867 30.4219 64.4659 30.001 63.9468 30.001L59.5824 30.001L59.5824 34L63.9468 34Z"
          fill="url(#4-top-1-1_paint5_linear)"
        />
        <path
          d="M44.6099 30C44.0907 30 43.6699 30.4208 43.6699 30.9399L43.6699 33.059C43.6699 33.5781 44.0907 33.999 44.6099 33.999L48.9742 33.999L48.9742 30L44.6099 30Z"
          fill="url(#4-top-1-1_paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.84048"
          transform="matrix(1.26506e-08 -1 -1 -1.12408e-08 58.6982 34)"
          fill="url(#4-top-1-1_paint7_linear)"
        />
        <g filter="url(#4-top-1-1_filter2_i)">
          <rect
            x="43.47"
            y="45.47"
            width="362.06"
            height="62.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <g filter="url(#4-top-1-1_filter3_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 34H32V119H417V34ZM406 45H43V108H406V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 34H32V119H33V35H416V119H417V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="36.53"
          y="38.53"
          width="375.94"
          height="75.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M47.0332 46.5L224 106.472L401.951 46.5H47.0332Z" stroke="white" />
        <rect
          x="237.897"
          y="110"
          width="7"
          height="14.3516"
          rx="3.5"
          transform="rotate(90 237.897 110)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="237.214"
          y="110.702"
          width="5.6"
          height="12.9848"
          rx="2.8"
          transform="rotate(90 237.214 110.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M237.088 112.668C236.733 111.529 235.67 110.702 234.414 110.702L227.029 110.702C225.483 110.702 224.229 111.955 224.229 113.502C224.229 113.546 224.23 113.59 224.232 113.634C224.587 114.773 225.65 115.6 226.906 115.6L234.291 115.6C235.837 115.6 237.091 114.346 237.091 112.8C237.091 112.756 237.09 112.712 237.088 112.668Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M235.719 111.024C235.599 111.008 235.476 111 235.352 111L227.967 111C226.42 111 225.167 112.254 225.167 113.8C225.167 114.875 225.773 115.809 226.662 116.278C225.289 116.098 224.229 114.924 224.229 113.502C224.229 111.955 225.483 110.702 227.029 110.702L234.414 110.702C234.885 110.702 235.329 110.818 235.719 111.024Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-top-1-1_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M233.654 113.5C233.654 112.672 232.983 112 232.154 112L231.94 112L230.35 112L210.755 112C209.926 112 209.255 112.672 209.255 113.5C209.255 114.328 209.926 115 210.755 115L228.35 115L232.154 115L232.369 115C233.079 115 233.654 114.424 233.654 113.714C233.654 113.677 233.653 113.64 233.651 113.604C233.653 113.569 233.654 113.535 233.654 113.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="4-top-1-1_filter0_d"
            x="383.12"
            y="28.1201"
            width="26.8568"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-1-1_filter1_d"
            x="41.79"
            y="28.1201"
            width="26.8568"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-1-1_filter2_i"
            x="43"
            y="45"
            width="363.94"
            height="65.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-top-1-1_filter3_d"
            x="27.3003"
            y="29.3003"
            width="396.279"
            height="96.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-1-1_filter4_d"
            x="207.535"
            y="110.18"
            width="30.0397"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="4-top-1-1_paint0_linear"
            x1="387.652"
            y1="36.8"
            x2="387.652"
            y2="29.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="4-top-1-1_paint1_linear"
            x1="403.565"
            y1="27.2018"
            x2="403.565"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-1-1_paint2_linear"
            x1="387.652"
            y1="36.7982"
            x2="387.652"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-1-1_paint3_linear"
            x1="6.79822"
            y1="4.42024"
            x2="-0.799791"
            y2="4.42024"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-1-1_paint4_linear"
            x1="46.3217"
            y1="36.8"
            x2="46.3217"
            y2="29.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="4-top-1-1_paint5_linear"
            x1="62.2346"
            y1="27.2018"
            x2="62.2346"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-1-1_paint6_linear"
            x1="46.3221"
            y1="36.7982"
            x2="46.3221"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-1-1_paint7_linear"
            x1="6.79822"
            y1="4.42024"
            x2="-0.799791"
            y2="4.42024"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
