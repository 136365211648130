import React, { FC } from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'
import { IContainerSquareProps } from '../../interface'
import { ContainerBackground } from '../container-background'

export const ContainerSquare52Casement: FC<IContainerSquareProps> = props => {
  const { onCasementClick, withoutBackground } = props

  return (
    <ProductImageWrapper isClickable>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <rect width="449" height="333" fill="white" />
        <rect x="24" y="28" width="401" height="264" fill="#E8EAEE" />
        <rect x="24" y="28" width="401" height="264" fill="url(#5-2-pattern0)" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M439 10H10V292H24V29H425V292H439V10Z"
          fill="#C4C4C4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M439 292H10V308H0V316H10H439H449V308H439V292Z"
          fill="url(#5-2-paint0_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M425 28H23V292H425V28ZM214 129H36V279H214V129ZM36 42H112V104H36V42ZM317 41H131V104H317V41ZM336 41H412V104H336V41ZM412 130H234V279H412V130Z"
          fill="#E8EAEE"
        />
        <path d="M439 10L425 28.0571V292.075L439 308V10Z" fill="#E9EBEF" />
        <path d="M439 10L425 28.0571V292.075L439 308V10Z" fill="#020202" fillOpacity="0.06" />
        <path d="M10 10L24 28.1525V291.982L10 308V10Z" fill="#E9EBEF" />
        <path d="M10 10L24 28.1525V291.982L10 308V10Z" fill="#020202" fillOpacity="0.06" />
        <rect y="316" width="449" height="17" fill="white" />
        <g
          filter="url(#5-2-filter0_i)"
          className="casement"
          data-index="1"
          onClick={onCasementClick(1)}
        >
          <rect x="131" y="41" width="186" height="63" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="131.47"
          y="41.47"
          width="185.06"
          height="62.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#5-2-filter1_i)"
          className="casement"
          data-index="2"
          onClick={onCasementClick(2)}
        >
          <rect x="336" y="41" width="76" height="63" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="336.47"
          y="41.47"
          width="75.0601"
          height="62.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#5-2-filter2_i)"
          className="casement"
          data-index="0"
          onClick={onCasementClick(0)}
        >
          <rect x="36" y="41" width="76" height="63" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="36.47"
          y="41.47"
          width="75.0601"
          height="62.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#5-2-filter3_i)"
          className="casement"
          data-index="3"
          onClick={onCasementClick(3)}
        >
          <rect x="36" y="129" width="178" height="150" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="36.47"
          y="129.47"
          width="177.06"
          height="149.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#5-2-filter4_i)"
          className="casement"
          data-index="4"
          onClick={onCasementClick(4)}
        >
          <rect x="234" y="129" width="178" height="150" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="234.47"
          y="129.47"
          width="177.06"
          height="149.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <path
          d="M232.985 65.7403H230.447C229.8 65.7403 229.253 65.2336 229.253 64.6347V64.4044C229.253 63.8056 229.8 63.2988 230.447 63.2988H232.985C233.632 63.2988 234.18 63.8056 234.18 64.4044V64.6347C234.18 65.2566 233.657 65.7403 232.985 65.7403Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M215.426 68.5652C215.725 68.5652 215.799 68.5652 216.197 68.5652C216.943 68.5652 217.49 69.1052 217.49 69.833C217.49 70.5843 216.918 71.1008 216.147 71.1008C214.705 71.1008 213.81 71.1008 212.367 71.1008C211.82 71.1008 211.373 70.913 211.149 70.4199C210.95 70.1147 210.826 69.2695 211.621 68.5652C212.144 67.9782 217.913 61.7565 218.933 60.6765C218.982 60.6061 218.933 60.5122 218.858 60.5122C218.833 60.5122 218.833 60.5122 218.808 60.5122C216.645 60.5122 214.481 60.5122 212.318 60.5122C211.671 60.5122 211.174 60.1365 211.049 59.573C210.9 58.9156 211.199 58.3522 211.771 58.0939C211.945 58.0235 212.119 58 212.293 58C215.501 58 218.684 58 221.892 58C222.514 58 222.937 58.1878 223.185 58.7278C223.509 59.4087 223.16 59.8783 222.937 60.1835C222.489 60.7235 217.938 65.56 215.426 68.2834C215.401 68.3069 215.376 68.3539 215.327 68.3773C215.277 68.4478 215.327 68.5652 215.426 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M222.185 71.1007H219.647C219.001 71.1007 218.453 70.5816 218.453 69.9681V69.7322C218.453 69.1187 219.001 68.5996 219.647 68.5996H222.185C222.832 68.5996 223.38 69.1187 223.38 69.7322V69.9681C223.38 70.6052 222.832 71.1007 222.185 71.1007Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M225.716 64.5152C225.716 62.7341 225.716 60.9764 225.716 59.1952C225.716 58.4922 226.238 58 226.984 58C230.215 58 233.447 58 236.678 58C237.424 58 237.946 58.5156 237.946 59.2421C237.946 59.9686 237.424 60.5077 236.678 60.5077C233.994 60.5077 231.334 60.5077 228.649 60.5077C228.45 60.5077 228.401 60.5545 228.401 60.742C228.401 63.2731 228.401 65.8042 228.401 68.3353C228.401 68.5463 228.475 68.5697 228.649 68.5697C231.309 68.5697 233.994 68.5697 236.654 68.5697C237.25 68.5697 237.723 68.8978 237.897 69.39C238.095 69.9759 237.872 70.5618 237.399 70.8899C237.176 71.0305 236.902 71.1008 236.629 71.1008C233.447 71.1008 230.24 71.1008 227.058 71.1008C226.238 71.1008 225.741 70.5852 225.741 69.8118C225.716 68.0307 225.716 66.273 225.716 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M212.705 76.0413V76.6981H211V76.0413H211.426V74.2584H211.05V73.6016H212.68V74.2584H212.304V76.0413H212.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M216.535 76.7586H215.774L215.651 76.5458C215.381 76.6877 215.062 76.7586 214.768 76.7586C213.934 76.735 213.148 76.1675 213.148 75.1508C213.148 74.1814 213.811 73.543 214.817 73.543C215.651 73.543 216.461 74.0868 216.461 75.1508C216.461 75.5291 216.363 75.8128 216.216 76.0729L216.559 76.6404V76.7586H216.535ZM214.792 76.002C215.185 76.002 215.602 75.7183 215.602 75.1508C215.602 74.5833 215.185 74.2996 214.792 74.2996C214.375 74.2996 213.983 74.5833 213.983 75.1508C213.983 75.7183 214.375 76.002 214.792 76.002Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M218.784 75.7493C218.784 75.9606 219.029 76.0779 219.3 76.0779C219.545 76.0779 219.791 75.9606 219.791 75.7963C219.791 75.4911 219.422 75.4911 219.201 75.4677C218.612 75.4207 218.023 75.2095 218.023 74.5523C218.023 73.8716 218.612 73.543 219.275 73.543C219.889 73.543 220.527 73.8716 220.527 74.5523H219.717C219.717 74.341 219.496 74.2471 219.275 74.2471C219.079 74.2471 218.833 74.2941 218.833 74.5288C218.833 74.74 219.029 74.787 219.324 74.8105C220.012 74.8574 220.601 75.0217 220.601 75.7728C220.601 76.43 219.938 76.7586 219.275 76.7586C218.587 76.7586 217.924 76.43 217.949 75.7493H218.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M223.228 74.3107H222.037V74.7598H223.129V75.4689H222.037V75.9653H223.253V76.6981H221.169V73.6016H223.204V74.3107H223.228Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M226.603 76.5799V76.6981H225.722L225.159 75.729H224.719V76.6981H223.887V73.6016C224.376 73.6016 224.841 73.6016 225.306 73.6016C226.089 73.6016 226.456 74.1689 226.456 74.7125C226.456 75.0671 226.285 75.4217 225.966 75.6108L226.603 76.5799ZM225.306 75.0671C225.551 75.0671 225.648 74.878 225.648 74.6653C225.648 74.4762 225.526 74.2871 225.306 74.2871H224.719V75.0671H225.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M228.276 75.3339L228.552 74.527L228.955 73.6016H229.886V73.7202L228.452 76.7577H228.099L226.665 73.7202V73.6016H227.596L228.024 74.527L228.276 75.3339Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M231.782 76.0413V76.6981H230.076V76.0413H230.503V74.2584H230.126V73.6016H231.756V74.2584H231.38V76.0413H231.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M233.835 73.6016C234.509 73.6016 235.258 73.9549 235.383 74.7792H234.559C234.434 74.4495 234.185 74.3317 233.835 74.3317C233.336 74.3317 233.011 74.6614 233.011 75.1796C233.011 75.6271 233.336 76.0275 233.835 76.0275C234.185 76.0275 234.484 75.8626 234.559 75.5329H235.383C235.258 76.4044 234.534 76.7577 233.835 76.7577C232.986 76.7577 232.162 76.2159 232.162 75.1796C232.162 74.1197 232.986 73.6016 233.835 73.6016Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M237.944 74.3107H236.753V74.7598H237.845V75.4689H236.753V75.9653H237.969V76.6981H235.885V73.6016H237.919V74.3107H237.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M382.985 65.7403H380.447C379.8 65.7403 379.253 65.2336 379.253 64.6347V64.4044C379.253 63.8056 379.8 63.2988 380.447 63.2988H382.985C383.632 63.2988 384.18 63.8056 384.18 64.4044V64.6347C384.18 65.2566 383.657 65.7403 382.985 65.7403Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M365.426 68.5652C365.725 68.5652 365.799 68.5652 366.197 68.5652C366.943 68.5652 367.49 69.1052 367.49 69.833C367.49 70.5843 366.918 71.1008 366.147 71.1008C364.705 71.1008 363.81 71.1008 362.367 71.1008C361.82 71.1008 361.373 70.913 361.149 70.4199C360.95 70.1147 360.826 69.2695 361.621 68.5652C362.144 67.9782 367.913 61.7565 368.933 60.6765C368.982 60.6061 368.933 60.5122 368.858 60.5122C368.833 60.5122 368.833 60.5122 368.808 60.5122C366.645 60.5122 364.481 60.5122 362.318 60.5122C361.671 60.5122 361.174 60.1365 361.049 59.573C360.9 58.9156 361.199 58.3522 361.771 58.0939C361.945 58.0235 362.119 58 362.293 58C365.501 58 368.684 58 371.892 58C372.514 58 372.937 58.1878 373.185 58.7278C373.509 59.4087 373.16 59.8783 372.937 60.1835C372.489 60.7235 367.938 65.56 365.426 68.2834C365.401 68.3069 365.376 68.3539 365.327 68.3773C365.277 68.4478 365.327 68.5652 365.426 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M372.185 71.1007H369.647C369.001 71.1007 368.453 70.5816 368.453 69.9681V69.7322C368.453 69.1187 369.001 68.5996 369.647 68.5996H372.185C372.832 68.5996 373.38 69.1187 373.38 69.7322V69.9681C373.38 70.6052 372.832 71.1007 372.185 71.1007Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M375.716 64.5152C375.716 62.7341 375.716 60.9764 375.716 59.1952C375.716 58.4922 376.238 58 376.984 58C380.215 58 383.447 58 386.678 58C387.424 58 387.946 58.5156 387.946 59.2421C387.946 59.9686 387.424 60.5077 386.678 60.5077C383.994 60.5077 381.334 60.5077 378.649 60.5077C378.45 60.5077 378.401 60.5545 378.401 60.742C378.401 63.2731 378.401 65.8042 378.401 68.3353C378.401 68.5463 378.475 68.5697 378.649 68.5697C381.309 68.5697 383.994 68.5697 386.654 68.5697C387.25 68.5697 387.723 68.8978 387.897 69.39C388.095 69.9759 387.872 70.5618 387.399 70.8899C387.176 71.0305 386.902 71.1008 386.629 71.1008C383.447 71.1008 380.24 71.1008 377.058 71.1008C376.238 71.1008 375.741 70.5852 375.741 69.8118C375.716 68.0307 375.716 66.273 375.716 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M362.705 76.0413V76.6981H361V76.0413H361.426V74.2584H361.05V73.6016H362.68V74.2584H362.304V76.0413H362.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M366.535 76.7586H365.774L365.651 76.5458C365.381 76.6877 365.062 76.7586 364.768 76.7586C363.934 76.735 363.148 76.1675 363.148 75.1508C363.148 74.1814 363.811 73.543 364.817 73.543C365.651 73.543 366.461 74.0868 366.461 75.1508C366.461 75.5291 366.363 75.8128 366.216 76.0729L366.559 76.6404V76.7586H366.535ZM364.792 76.002C365.185 76.002 365.602 75.7183 365.602 75.1508C365.602 74.5833 365.185 74.2996 364.792 74.2996C364.375 74.2996 363.983 74.5833 363.983 75.1508C363.983 75.7183 364.375 76.002 364.792 76.002Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M368.784 75.7493C368.784 75.9606 369.029 76.0779 369.3 76.0779C369.545 76.0779 369.791 75.9606 369.791 75.7963C369.791 75.4911 369.422 75.4911 369.201 75.4677C368.612 75.4207 368.023 75.2095 368.023 74.5523C368.023 73.8716 368.612 73.543 369.275 73.543C369.889 73.543 370.527 73.8716 370.527 74.5523H369.717C369.717 74.341 369.496 74.2471 369.275 74.2471C369.079 74.2471 368.833 74.2941 368.833 74.5288C368.833 74.74 369.029 74.787 369.324 74.8105C370.012 74.8574 370.601 75.0217 370.601 75.7728C370.601 76.43 369.938 76.7586 369.275 76.7586C368.587 76.7586 367.924 76.43 367.949 75.7493H368.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M373.228 74.3107H372.037V74.7598H373.129V75.4689H372.037V75.9653H373.253V76.6981H371.169V73.6016H373.204V74.3107H373.228Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M376.603 76.5799V76.6981H375.722L375.159 75.729H374.719V76.6981H373.887V73.6016C374.376 73.6016 374.841 73.6016 375.306 73.6016C376.089 73.6016 376.456 74.1689 376.456 74.7125C376.456 75.0671 376.285 75.4217 375.966 75.6108L376.603 76.5799ZM375.306 75.0671C375.551 75.0671 375.648 74.878 375.648 74.6653C375.648 74.4762 375.526 74.2871 375.306 74.2871H374.719V75.0671H375.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M378.276 75.3339L378.552 74.527L378.955 73.6016H379.886V73.7202L378.452 76.7577H378.099L376.665 73.7202V73.6016H377.596L378.024 74.527L378.276 75.3339Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M381.782 76.0413V76.6981H380.076V76.0413H380.503V74.2584H380.126V73.6016H381.756V74.2584H381.38V76.0413H381.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M383.835 73.6016C384.509 73.6016 385.258 73.9549 385.383 74.7792H384.559C384.434 74.4495 384.185 74.3317 383.835 74.3317C383.336 74.3317 383.011 74.6614 383.011 75.1796C383.011 75.6271 383.336 76.0275 383.835 76.0275C384.185 76.0275 384.484 75.8626 384.559 75.5329H385.383C385.258 76.4044 384.534 76.7577 383.835 76.7577C382.986 76.7577 382.162 76.2159 382.162 75.1796C382.162 74.1197 382.986 73.6016 383.835 73.6016Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M387.944 74.3107H386.753V74.7598H387.845V75.4689H386.753V75.9653H387.969V76.6981H385.885V73.6016H387.919V74.3107H387.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M82.9852 65.7403H80.4473C79.8003 65.7403 79.2529 65.2336 79.2529 64.6347V64.4044C79.2529 63.8056 79.8003 63.2988 80.4473 63.2988H82.9852C83.6321 63.2988 84.1795 63.8056 84.1795 64.4044V64.6347C84.1795 65.2566 83.657 65.7403 82.9852 65.7403Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M65.4262 68.5652C65.7247 68.5652 65.7993 68.5652 66.1972 68.5652C66.9432 68.5652 67.4903 69.1052 67.4903 69.833C67.4903 70.5843 66.9184 71.1008 66.1474 71.1008C64.705 71.1008 63.8098 71.1008 62.3674 71.1008C61.8203 71.1008 61.3727 70.913 61.1488 70.4199C60.9499 70.1147 60.8255 69.2695 61.6213 68.5652C62.1436 67.9782 67.9131 61.7565 68.9327 60.6765C68.9824 60.6061 68.9327 60.5122 68.8581 60.5122C68.8332 60.5122 68.8332 60.5122 68.8084 60.5122C66.6448 60.5122 64.4812 60.5122 62.3177 60.5122C61.6711 60.5122 61.1737 60.1365 61.0494 59.573C60.9002 58.9156 61.1986 58.3522 61.7706 58.0939C61.9446 58.0235 62.1187 58 62.2928 58C65.5008 58 68.684 58 71.8921 58C72.5138 58 72.9366 58.1878 73.1852 58.7278C73.5085 59.4087 73.1604 59.8783 72.9366 60.1835C72.4889 60.7235 67.938 65.56 65.4262 68.2834C65.4014 68.3069 65.3765 68.3539 65.3268 68.3773C65.277 68.4478 65.3268 68.5652 65.4262 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M72.1854 71.1007H69.6475C69.0005 71.1007 68.4531 70.5816 68.4531 69.9681V69.7322C68.4531 69.1187 69.0005 68.5996 69.6475 68.5996H72.1854C72.8323 68.5996 73.3797 69.1187 73.3797 69.7322V69.9681C73.3797 70.6052 72.8323 71.1007 72.1854 71.1007Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M75.7158 64.5152C75.7158 62.7341 75.7158 60.9764 75.7158 59.1952C75.7158 58.4922 76.2379 58 76.9836 58C80.2152 58 83.4468 58 86.6785 58C87.4242 58 87.9462 58.5156 87.9462 59.2421C87.9462 59.9686 87.4242 60.5077 86.6785 60.5077C83.9937 60.5077 81.3339 60.5077 78.6491 60.5077C78.4503 60.5077 78.4005 60.5545 78.4005 60.742C78.4005 63.2731 78.4005 65.8042 78.4005 68.3353C78.4005 68.5463 78.4751 68.5697 78.6491 68.5697C81.309 68.5697 83.9937 68.5697 86.6536 68.5697C87.2502 68.5697 87.7225 68.8978 87.8965 69.39C88.0954 69.9759 87.8717 70.5618 87.3994 70.8899C87.1756 71.0305 86.9022 71.1008 86.6287 71.1008C83.4468 71.1008 80.2401 71.1008 77.0582 71.1008C76.2378 71.1008 75.7407 70.5852 75.7407 69.8118C75.7158 68.0307 75.7158 66.273 75.7158 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M62.7054 76.0413V76.6981H61V76.0413H61.4263V74.2584H61.0502V73.6016H62.6803V74.2584H62.3041V76.0413H62.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M66.5346 76.7586H65.774L65.6513 76.5458C65.3814 76.6877 65.0624 76.7586 64.7679 76.7586C63.9336 76.735 63.1484 76.1675 63.1484 75.1508C63.1484 74.1814 63.811 73.543 64.817 73.543C65.6513 73.543 66.461 74.0868 66.461 75.1508C66.461 75.5291 66.3629 75.8128 66.2156 76.0729L66.5592 76.6404V76.7586H66.5346ZM64.7925 76.002C65.1851 76.002 65.6022 75.7183 65.6022 75.1508C65.6022 74.5833 65.1851 74.2996 64.7925 74.2996C64.3753 74.2996 63.9827 74.5833 63.9827 75.1508C63.9827 75.7183 64.3753 76.002 64.7925 76.002Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M68.7838 75.7493C68.7838 75.9606 69.0294 76.0779 69.2995 76.0779C69.5451 76.0779 69.7907 75.9606 69.7907 75.7963C69.7907 75.4911 69.4223 75.4911 69.2013 75.4677C68.6119 75.4207 68.0226 75.2095 68.0226 74.5523C68.0226 73.8716 68.6119 73.543 69.275 73.543C69.8889 73.543 70.5274 73.8716 70.5274 74.5523H69.717C69.717 74.341 69.496 74.2471 69.275 74.2471C69.0785 74.2471 68.8329 74.2941 68.8329 74.5288C68.8329 74.74 69.0294 74.787 69.3241 74.8105C70.0117 74.8574 70.601 75.0217 70.601 75.7728C70.601 76.43 69.938 76.7586 69.275 76.7586C68.5874 76.7586 67.9243 76.43 67.9489 75.7493H68.7838Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M73.2285 74.3107H72.0374V74.7598H73.1292V75.4689H72.0374V75.9653H73.2533V76.6981H71.1689V73.6016H73.2036V74.3107H73.2285Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M76.6027 76.5799V76.6981H75.7218L75.159 75.729H74.7186V76.6981H73.8867V73.6016C74.3761 73.6016 74.841 73.6016 75.3059 73.6016C76.0888 73.6016 76.4559 74.1689 76.4559 74.7125C76.4559 75.0671 76.2846 75.4217 75.9665 75.6108L76.6027 76.5799ZM75.3059 75.0671C75.5505 75.0671 75.6484 74.878 75.6484 74.6653C75.6484 74.4762 75.5261 74.2871 75.3059 74.2871H74.7186V75.0671H75.3059Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M78.2757 75.3339L78.5525 74.527L78.9551 73.6016H79.8863V73.7202L78.4518 76.7577H78.0995L76.665 73.7202V73.6016H77.5962L78.024 74.527L78.2757 75.3339Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M81.7815 76.0413V76.6981H80.0762V76.0413H80.5025V74.2584H80.1263V73.6016H81.7565V74.2584H81.3803V76.0413H81.7815Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M83.8352 73.6016C84.5094 73.6016 85.2585 73.9549 85.3833 74.7792H84.5593C84.4345 74.4495 84.1848 74.3317 83.8352 74.3317C83.3357 74.3317 83.0111 74.6614 83.0111 75.1796C83.0111 75.6271 83.3357 76.0275 83.8352 76.0275C84.1848 76.0275 84.4844 75.8626 84.5593 75.5329H85.3833C85.2585 76.4044 84.5343 76.7577 83.8352 76.7577C82.9862 76.7577 82.1621 76.2159 82.1621 75.1796C82.1621 74.1197 82.9862 73.6016 83.8352 73.6016Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M87.9443 74.3107H86.7532V74.7598H87.845V75.4689H86.7532V75.9653H87.9691V76.6981H85.8848V73.6016H87.9195V74.3107H87.9443Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M133.985 154.74H131.447C130.8 154.74 130.253 154.234 130.253 153.635V153.404C130.253 152.806 130.8 152.299 131.447 152.299H133.985C134.632 152.299 135.18 152.806 135.18 153.404V153.635C135.18 154.257 134.657 154.74 133.985 154.74Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M116.426 157.565C116.725 157.565 116.799 157.565 117.197 157.565C117.943 157.565 118.49 158.105 118.49 158.833C118.49 159.584 117.918 160.101 117.147 160.101C115.705 160.101 114.81 160.101 113.367 160.101C112.82 160.101 112.373 159.913 112.149 159.42C111.95 159.115 111.826 158.27 112.621 157.565C113.144 156.978 118.913 150.757 119.933 149.677C119.982 149.606 119.933 149.512 119.858 149.512C119.833 149.512 119.833 149.512 119.808 149.512C117.645 149.512 115.481 149.512 113.318 149.512C112.671 149.512 112.174 149.137 112.049 148.573C111.9 147.916 112.199 147.352 112.771 147.094C112.945 147.023 113.119 147 113.293 147C116.501 147 119.684 147 122.892 147C123.514 147 123.937 147.188 124.185 147.728C124.509 148.409 124.16 148.878 123.937 149.183C123.489 149.723 118.938 154.56 116.426 157.283C116.401 157.307 116.376 157.354 116.327 157.377C116.277 157.448 116.327 157.565 116.426 157.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M123.185 160.101H120.647C120.001 160.101 119.453 159.582 119.453 158.968V158.732C119.453 158.119 120.001 157.6 120.647 157.6H123.185C123.832 157.6 124.38 158.119 124.38 158.732V158.968C124.38 159.605 123.832 160.101 123.185 160.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M126.716 153.515C126.716 151.734 126.716 149.976 126.716 148.195C126.716 147.492 127.238 147 127.984 147C131.215 147 134.447 147 137.678 147C138.424 147 138.946 147.516 138.946 148.242C138.946 148.969 138.424 149.508 137.678 149.508C134.994 149.508 132.334 149.508 129.649 149.508C129.45 149.508 129.401 149.555 129.401 149.742C129.401 152.273 129.401 154.804 129.401 157.335C129.401 157.546 129.475 157.57 129.649 157.57C132.309 157.57 134.994 157.57 137.654 157.57C138.25 157.57 138.723 157.898 138.897 158.39C139.095 158.976 138.872 159.562 138.399 159.89C138.176 160.03 137.902 160.101 137.629 160.101C134.447 160.101 131.24 160.101 128.058 160.101C127.238 160.101 126.741 159.585 126.741 158.812C126.716 157.031 126.716 155.273 126.716 153.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M113.705 165.041V165.698H112V165.041H112.426V163.258H112.05V162.602H113.68V163.258H113.304V165.041H113.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M117.535 165.759H116.774L116.651 165.546C116.381 165.688 116.062 165.759 115.768 165.759C114.934 165.735 114.148 165.168 114.148 164.151C114.148 163.181 114.811 162.543 115.817 162.543C116.651 162.543 117.461 163.087 117.461 164.151C117.461 164.529 117.363 164.813 117.216 165.073L117.559 165.64V165.759H117.535ZM115.792 165.002C116.185 165.002 116.602 164.718 116.602 164.151C116.602 163.583 116.185 163.3 115.792 163.3C115.375 163.3 114.983 163.583 114.983 164.151C114.983 164.718 115.375 165.002 115.792 165.002Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M119.784 164.749C119.784 164.961 120.029 165.078 120.3 165.078C120.545 165.078 120.791 164.961 120.791 164.796C120.791 164.491 120.422 164.491 120.201 164.468C119.612 164.421 119.023 164.209 119.023 163.552C119.023 162.872 119.612 162.543 120.275 162.543C120.889 162.543 121.527 162.872 121.527 163.552H120.717C120.717 163.341 120.496 163.247 120.275 163.247C120.079 163.247 119.833 163.294 119.833 163.529C119.833 163.74 120.029 163.787 120.324 163.81C121.012 163.857 121.601 164.022 121.601 164.773C121.601 165.43 120.938 165.759 120.275 165.759C119.587 165.759 118.924 165.43 118.949 164.749H119.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M124.228 163.311H123.037V163.76H124.129V164.469H123.037V164.965H124.253V165.698H122.169V162.602H124.204V163.311H124.228Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M127.603 165.58V165.698H126.722L126.159 164.729H125.719V165.698H124.887V162.602C125.376 162.602 125.841 162.602 126.306 162.602C127.089 162.602 127.456 163.169 127.456 163.713C127.456 164.067 127.285 164.422 126.966 164.611L127.603 165.58ZM126.306 164.067C126.551 164.067 126.648 163.878 126.648 163.665C126.648 163.476 126.526 163.287 126.306 163.287H125.719V164.067H126.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M129.276 164.334L129.552 163.527L129.955 162.602H130.886V162.72L129.452 165.758H129.099L127.665 162.72V162.602H128.596L129.024 163.527L129.276 164.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M132.782 165.041V165.698H131.076V165.041H131.503V163.258H131.126V162.602H132.756V163.258H132.38V165.041H132.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M134.835 162.602C135.509 162.602 136.258 162.955 136.383 163.779H135.559C135.434 163.449 135.185 163.332 134.835 163.332C134.336 163.332 134.011 163.661 134.011 164.18C134.011 164.627 134.336 165.028 134.835 165.028C135.185 165.028 135.484 164.863 135.559 164.533H136.383C136.258 165.404 135.534 165.758 134.835 165.758C133.986 165.758 133.162 165.216 133.162 164.18C133.162 163.12 133.986 162.602 134.835 162.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M138.944 163.311H137.753V163.76H138.845V164.469H137.753V164.965H138.969V165.698H136.885V162.602H138.919V163.311H138.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M331.985 154.74H329.447C328.8 154.74 328.253 154.234 328.253 153.635V153.404C328.253 152.806 328.8 152.299 329.447 152.299H331.985C332.632 152.299 333.18 152.806 333.18 153.404V153.635C333.18 154.257 332.657 154.74 331.985 154.74Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M314.426 157.565C314.725 157.565 314.799 157.565 315.197 157.565C315.943 157.565 316.49 158.105 316.49 158.833C316.49 159.584 315.918 160.101 315.147 160.101C313.705 160.101 312.81 160.101 311.367 160.101C310.82 160.101 310.373 159.913 310.149 159.42C309.95 159.115 309.826 158.27 310.621 157.565C311.144 156.978 316.913 150.757 317.933 149.677C317.982 149.606 317.933 149.512 317.858 149.512C317.833 149.512 317.833 149.512 317.808 149.512C315.645 149.512 313.481 149.512 311.318 149.512C310.671 149.512 310.174 149.137 310.049 148.573C309.9 147.916 310.199 147.352 310.771 147.094C310.945 147.023 311.119 147 311.293 147C314.501 147 317.684 147 320.892 147C321.514 147 321.937 147.188 322.185 147.728C322.509 148.409 322.16 148.878 321.937 149.183C321.489 149.723 316.938 154.56 314.426 157.283C314.401 157.307 314.376 157.354 314.327 157.377C314.277 157.448 314.327 157.565 314.426 157.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M321.185 160.101H318.647C318.001 160.101 317.453 159.582 317.453 158.968V158.732C317.453 158.119 318.001 157.6 318.647 157.6H321.185C321.832 157.6 322.38 158.119 322.38 158.732V158.968C322.38 159.605 321.832 160.101 321.185 160.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M324.716 153.515C324.716 151.734 324.716 149.976 324.716 148.195C324.716 147.492 325.238 147 325.984 147C329.215 147 332.447 147 335.678 147C336.424 147 336.946 147.516 336.946 148.242C336.946 148.969 336.424 149.508 335.678 149.508C332.994 149.508 330.334 149.508 327.649 149.508C327.45 149.508 327.401 149.555 327.401 149.742C327.401 152.273 327.401 154.804 327.401 157.335C327.401 157.546 327.475 157.57 327.649 157.57C330.309 157.57 332.994 157.57 335.654 157.57C336.25 157.57 336.723 157.898 336.897 158.39C337.095 158.976 336.872 159.562 336.399 159.89C336.176 160.03 335.902 160.101 335.629 160.101C332.447 160.101 329.24 160.101 326.058 160.101C325.238 160.101 324.741 159.585 324.741 158.812C324.716 157.031 324.716 155.273 324.716 153.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M311.705 165.041V165.698H310V165.041H310.426V163.258H310.05V162.602H311.68V163.258H311.304V165.041H311.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M315.535 165.759H314.774L314.651 165.546C314.381 165.688 314.062 165.759 313.768 165.759C312.934 165.735 312.148 165.168 312.148 164.151C312.148 163.181 312.811 162.543 313.817 162.543C314.651 162.543 315.461 163.087 315.461 164.151C315.461 164.529 315.363 164.813 315.216 165.073L315.559 165.64V165.759H315.535ZM313.792 165.002C314.185 165.002 314.602 164.718 314.602 164.151C314.602 163.583 314.185 163.3 313.792 163.3C313.375 163.3 312.983 163.583 312.983 164.151C312.983 164.718 313.375 165.002 313.792 165.002Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M317.784 164.749C317.784 164.961 318.029 165.078 318.3 165.078C318.545 165.078 318.791 164.961 318.791 164.796C318.791 164.491 318.422 164.491 318.201 164.468C317.612 164.421 317.023 164.209 317.023 163.552C317.023 162.872 317.612 162.543 318.275 162.543C318.889 162.543 319.527 162.872 319.527 163.552H318.717C318.717 163.341 318.496 163.247 318.275 163.247C318.079 163.247 317.833 163.294 317.833 163.529C317.833 163.74 318.029 163.787 318.324 163.81C319.012 163.857 319.601 164.022 319.601 164.773C319.601 165.43 318.938 165.759 318.275 165.759C317.587 165.759 316.924 165.43 316.949 164.749H317.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M322.228 163.311H321.037V163.76H322.129V164.469H321.037V164.965H322.253V165.698H320.169V162.602H322.204V163.311H322.228Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M325.603 165.58V165.698H324.722L324.159 164.729H323.719V165.698H322.887V162.602C323.376 162.602 323.841 162.602 324.306 162.602C325.089 162.602 325.456 163.169 325.456 163.713C325.456 164.067 325.285 164.422 324.966 164.611L325.603 165.58ZM324.306 164.067C324.551 164.067 324.648 163.878 324.648 163.665C324.648 163.476 324.526 163.287 324.306 163.287H323.719V164.067H324.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M327.276 164.334L327.552 163.527L327.955 162.602H328.886V162.72L327.452 165.758H327.099L325.665 162.72V162.602H326.596L327.024 163.527L327.276 164.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M330.782 165.041V165.698H329.076V165.041H329.503V163.258H329.126V162.602H330.756V163.258H330.38V165.041H330.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M332.835 162.602C333.509 162.602 334.258 162.955 334.383 163.779H333.559C333.434 163.449 333.185 163.332 332.835 163.332C332.336 163.332 332.011 163.661 332.011 164.18C332.011 164.627 332.336 165.028 332.835 165.028C333.185 165.028 333.484 164.863 333.559 164.533H334.383C334.258 165.404 333.534 165.758 332.835 165.758C331.986 165.758 331.162 165.216 331.162 164.18C331.162 163.12 331.986 162.602 332.835 162.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M336.944 163.311H335.753V163.76H336.845V164.469H335.753V164.965H336.969V165.698H334.885V162.602H336.919V163.311H336.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <defs>
          <pattern id="5-2-pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use
              xlinkHref="#image0"
              transform="translate(-0.00768784) scale(0.00100915 0.00153332)"
            />
          </pattern>
          <filter
            id="5-2-filter0_i"
            x="131"
            y="41"
            width="186.94"
            height="65.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-2-filter1_i"
            x="336"
            y="41"
            width="76.9399"
            height="65.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-2-filter2_i"
            x="36"
            y="41"
            width="76.9399"
            height="65.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-2-filter3_i"
            x="36"
            y="129"
            width="178.94"
            height="152.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-2-filter4_i"
            x="234"
            y="129"
            width="178.94"
            height="152.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <linearGradient
            id="5-2-paint0_linear"
            x1="301.5"
            y1="291"
            x2="301.5"
            y2="316"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DBDDE1" />
            <stop offset="1" stopColor="#F9F9F9" />
          </linearGradient>
        </defs>
      </svg>
      {!withoutBackground && <ContainerBackground />}
    </ProductImageWrapper>
  )
}
