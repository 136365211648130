import { useCallback, useRef, useState } from 'react'
import { getSquare } from '../views/calculator/stage-calculator/consts'
import { IFilterItem, ISizes } from '../views/calculator/stage-calculator/interface'
import {
  clearCasements,
  getPrefix,
  getCollectionsObjectsRequest,
  getRequestData,
  prepareDataFromSchema,
  priceReducer,
  replaceHandlebars,
  splitString,
  getCasementsValues,
  calculateSelfPrice,
  getColorMargin,
  getCount,
  getElementRequest,
  getSize,
  getTargetAdditionalElementPriceData,
  getTargetKey,
} from '../utilits'
import { CalculatorType, IUsePriceProps, TProduct, TSizes } from '../views/calculator/interface'
import fetchAPI, {createHeaders} from '../../lib/utils/fetch-api'
import {API_URL} from "../services/environment";

export const productMainPrice = {
  window: ['window', 'casements', 'impost-horizontal', 'impost-vertical', 'glass-unit-2'],
}

const getIsCasements = filters => {
	const casements = filters.find(item => item?.['key'] === 'casements-index')?.['value'] || []
	return casements.filter(Number.isInteger).length !== 0
}

export const usePrice: () => {
  inProcess: boolean;
  calculatePriceBitrix: (id, count) => Promise<void>;
  totalPriceList: Record<string, any>[];
  fetchCalculate: (type, products, sizes, filters) => void;
  finalOrderPriceCalculate: (targetId: number, source: Record<string, any>) => void;
  calculateTotalPrice: (type, products, sizes, filters) => void;
  calculateAdditionalElementPrice: (priceListKey: string, type: (CalculatorType | null), product: Record<string, any>, sizes: TSizes, filters?: IFilterItem[], colorKeys?: string[], count?: number) => number
} = () => {
  const [inProcess, setInProcess] = useState(false)
  const [totalPriceList, setTotalPriceList] = useState<Record<string, any>[]>([])
  const priceListRef = useRef({})
  const priceColorListRef = useRef({})
  const schemasRef = useRef<any[]>([])
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const currentFilters = useRef([])

  const getSchemas = useCallback(async () => {
    const rawSchemas = await fetchAPI('/api/schemas?access_key=axioma&fields=properties')

    if (rawSchemas && rawSchemas['data'] && rawSchemas['data']['data']) {
      schemasRef.current = rawSchemas['data']['data']
    }

    return schemasRef.current
  }, [])

  /** Accumulated data from schemas & collections data */
  const prepareAdditionalDataPrice = useCallback(
    async (
      additionalElementsSchema,
      colorSchema,
      casementsSchema,
      glassUnitSchema,
      rawData,
      type,
    ) => {
      const priceAdditionalData: Record<string, any[]> = {}
      const priceAdditionalColorsData: Record<string, any[]> = {}
      const rawPriceListKeys = Object.keys(rawData)

      if (
        rawPriceListKeys.length > 0 &&
        (additionalElementsSchema || colorSchema || casementsSchema)
      ) {
        rawPriceListKeys.forEach(rawPriceListKey => {
          if (rawPriceListKey !== type) {
            const objectDataList = rawData[rawPriceListKey]
            const isColor = rawPriceListKey.indexOf('color') >= 0
            const isCurrentProductTypeColor =
              isColor &&
              ['model-inside-color', 'model-outside-color', 'model-profile-color'].includes(
                rawPriceListKey,
              )

            priceAdditionalData[rawPriceListKey] = []
            priceAdditionalColorsData[rawPriceListKey] = []

            for (const objectData of objectDataList) {
              let currentSchema = additionalElementsSchema

              if (rawPriceListKey === 'casements') {
                currentSchema = casementsSchema
              } else if (rawPriceListKey === 'glass-unit-2') {
                currentSchema = glassUnitSchema
              }

              if (isCurrentProductTypeColor || isColor) {
                priceAdditionalColorsData[rawPriceListKey].push(
                  prepareDataFromSchema(colorSchema, objectData),
                )
              } else {
                priceAdditionalData[rawPriceListKey].push(
                  prepareDataFromSchema(currentSchema, objectData),
                )
              }
            }
          }
        })
      }

      return { priceAdditionalData, priceAdditionalColorsData }
    },
    [],
  )

  const getPreparedPriceListData = useCallback(
    async (rawData, type) => {
      const modelPriceData: any[] = []

      /** Prepare product price data */
      if (schemasRef.current.length > 0) {
        const colorSchema = schemasRef.current.find(s => s.name === `additional-elements-colors`)
        const additionalElementsSchema = schemasRef.current.find(
          s => s.name === `additional-elements`,
        )
        const casementsSchema = schemasRef.current.find(s => s.name === 'casements')
        const glassUnitSchema = schemasRef.current.find(s => s.name === 'glass-unit-2')

        if (type && rawData[type] && rawData[type].length > 0) {
          const schema = schemasRef.current.find(s => s.name === `${type}-price`)

          if (schema) {
            for (const objectData of rawData[type]) {
              modelPriceData.push(prepareDataFromSchema(schema, objectData))
            }
          }
        }

        /** Prepare additional elements price data */
        const { priceAdditionalData, priceAdditionalColorsData } = await prepareAdditionalDataPrice(
          additionalElementsSchema,
          colorSchema,
          casementsSchema,
          glassUnitSchema,
          rawData,
          type,
        )

        if (modelPriceData.length > 0) {
          priceListRef.current = {
            ...priceListRef.current,
            [type]: modelPriceData,
            ...priceAdditionalData,
          }

          priceColorListRef.current = {
            ...priceColorListRef.current,
            ...priceAdditionalColorsData,
          }
        }
      }

      return true
    },
    [prepareAdditionalDataPrice],
  )

  const calculateMainPrice = useCallback(
    (
      type,
      product: TProduct = {},
      sizes: TSizes = {},
      calculateOnlyColor?: boolean,
      colorKeys?: string[],
    ) => {
      let result = 0
      let unitSize = 1
      const modelData = product[`${type}-model`]

      if (modelData) {
        const modelKey = getTargetKey(product, `${type}-model`)
        const targetProductPriceData = priceListRef.current[type].find(
          item => item.model === modelData[modelKey],
        )

        if (targetProductPriceData) {
          const selfPrice = calculateSelfPrice(targetProductPriceData)

          if (targetProductPriceData['unit'] === 'square') {
            if (sizes['model-width'] > 0 && sizes['model-height']) {
              unitSize = getSquare(sizes['model-width'], sizes['model-height'])
            }
          } else if (targetProductPriceData['unit'] === 'perimeter') {
            unitSize = sizes['model-width'] * 2 + sizes['model-height'] * 2
          }

          result = unitSize * selfPrice

          if (targetProductPriceData['color-keys']) {
            const keys = colorKeys || splitString(targetProductPriceData['color-keys'], ',')
            const colorMargin = getColorMargin(keys, product, priceColorListRef.current)

            if (colorMargin > 0 || calculateOnlyColor) {
              result = calculateSelfPrice(
                { price: result, margin: colorMargin },
                !calculateOnlyColor,
              )
            }
          }
        }
      }

      return result
    },
    [],
  )

  const calculateColorPrice = useCallback(
    (key: string, currentPrice: number, product: TProduct, totalPrice?: boolean) => {
      let result = 0
      const colorData = priceColorListRef.current[key]?.find(
        item => product[key] && item.code === product[key]['code'],
      )

      if (colorData) {
        result = calculateSelfPrice(
          { price: currentPrice, margin: colorData['margin'] },
          totalPrice,
        )
      }

      return result
    },
    [],
  )

  const calculateCurrentPrice = useCallback(
    (
      priceData,
      priceListKey,
      product: TProduct,
      sizes: TSizes,
      itemIndex?: number,
      innerCount?: number,
    ) => {
      const result: number[] = []
      const isColor = priceListKey.indexOf('color') >= 0
      const isCasements = priceListKey === 'casements'
      const isImpost = ['impost-vertical', 'impost-horizontal'].includes(priceListKey)
      const isMosquito = priceListKey === 'mosquito-type'
      let count: null | number

      if (typeof innerCount !== 'undefined') {
        count = innerCount
      } else {
        count = getCount(priceListKey, product, currentFilters.current)
      }

      if (priceData) {
        const haveColorKeys = priceData['color-keys']

        if (!isColor && priceData['unit']) {
          let size = getSize(priceData['unit'], priceListKey, undefined, product, sizes)

          if ((isCasements || isImpost || isMosquito) && typeof itemIndex !== 'undefined') {
            size = getSize(priceData['unit'], priceListKey, itemIndex, product, sizes)
          }

          let tempPrice = calculateSelfPrice(priceData, true, size) * size

          if (count !== null) {
            tempPrice *= count
          }

          result.push(tempPrice)

          if (priceColorListRef.current && priceColorListRef.current[`${priceListKey}-color`]) {
            const priceByColor = calculateColorPrice(
              `${priceListKey}-color`,
              result.reduce(priceReducer, 0),
              product,
            )

            result.push(priceByColor)
          } else if (haveColorKeys) {
            const keys = splitString(haveColorKeys, ',')

            keys.forEach(key => {
              result.push(calculateColorPrice(key, result.reduce(priceReducer, 0), product, false))
            })
          }
        }
      }

      return result.reduce(priceReducer, 0)
    },
    [calculateColorPrice],
  )

  const calculateGlassUnitPrice = useCallback(
    (priceData, priceListKey, product: TProduct, sizes: TSizes) => {
      let result = 0
      const glassUnit = product[priceListKey] ? product[priceListKey]['value'] : null

      if (glassUnit && priceData['price'] && Array.isArray(priceData['price'])) {
        const currentPriceData = priceData['price'].find(item => item['name'] === glassUnit)

        if (currentPriceData) {
          const size = getSize(priceData['unit'], priceListKey, undefined, product, sizes)
          const squareRange = Object.keys(currentPriceData).reduce((acc, key) => {
            if (key.indexOf('square') !== -1) {
              acc.push({ key, value: currentPriceData[key] })
            }

            return acc
          }, [] as Record<string, any>[])
          const square = squareRange.find(range => size < range['value'])
          const squareIndex = square ? square['key'].substr(-1) : null
          const price = currentPriceData[`price_${squareIndex}`]
          const shift = currentPriceData['coefficient'] ? currentPriceData['coefficient'] : 1

          result = price * shift * size
        }
      }

      return result
    },
    [],
  )

  const calculateCasementsPrice = useCallback(
    (
      product: Record<string, {}>,
      priceListKey: string,
      sizes: TSizes,
      type: CalculatorType | null,
      filters,
    ): number[] => {
      const result: number[] = []
      const currentFilter = filters.find(filter => filter.key === 'casements-index')

      const casementsData = clearCasements(
        splitString(product['configuration']['casements-value'], '],'),
      )

      if (currentFilter && currentFilter.value && Array.isArray(currentFilter.value)) {
        currentFilter.value.forEach((casementsIndex, groupIndex) => {
          if (casementsData[groupIndex] && casementsData[groupIndex][casementsIndex]) {
            const currentCasement = casementsData[groupIndex][casementsIndex]

            if (currentCasement) {
              const targetAdditionalElementPriceData = getTargetAdditionalElementPriceData(
                product,
                priceListRef.current,
                priceListKey,
                type,
                currentCasement,
              )

              if (targetAdditionalElementPriceData) {
                const p = calculateCurrentPrice(
                  targetAdditionalElementPriceData,
                  priceListKey,
                  product,
                  sizes,
                  groupIndex,
                )

                if (p) {
                  result.push(p)
                }
              }
            }
          }
        })
      }

      return result
    },
    [calculateCurrentPrice],
  )

  const calculateImpostsPrice = useCallback(
    (
      product: Record<string, {}>,
      priceListKey: string,
      sizes: TSizes,
      type: CalculatorType | null,
    ): number[] => {
      const result: number[] = []

      const impostConfigurations = Number(product['configuration'][`${priceListKey}-value`])
      const impostConfigurationsArr = new Array(impostConfigurations).fill(impostConfigurations)

      impostConfigurationsArr.forEach((key, index) => {
        const targetAdditionalElementPriceData = getTargetAdditionalElementPriceData(
          product,
          priceListRef.current,
          priceListKey,
          type,
        )

        if (targetAdditionalElementPriceData) {
          result.push(
            calculateCurrentPrice(
              targetAdditionalElementPriceData,
              priceListKey,
              product,
              sizes,
              index,
            ),
          )
        }
      })

      return result
    },
    [calculateCurrentPrice],
  )

  const calculateMosquitoPrice = useCallback(
    (
      product: Record<string, {}>,
      priceListKey: string,
      sizes: TSizes,
      type: CalculatorType | null,
      filters,
    ): number[] => {
      const result: number[] = []
      const currentFilter = filters && filters.find(filter => filter.key === 'mosquito-index')

      if (currentFilter && currentFilter.value && Array.isArray(currentFilter.value)) {
        currentFilter.value.forEach(casementsGroupIndex => {
          const targetAdditionalElementPriceData = getTargetAdditionalElementPriceData(
            product,
            priceListRef.current,
            priceListKey,
            type,
          )

          if (targetAdditionalElementPriceData) {
            const p = calculateCurrentPrice(
              targetAdditionalElementPriceData,
              priceListKey,
              product,
              sizes,
              casementsGroupIndex,
              1,
            )

            if (p) {
              result.push(p)
            }
          }
        })
      }

      return result
    },
    [calculateCurrentPrice],
  )

  const calculateAdditionalElementPrice = useCallback(
    (
      priceListKey: string,
      type: CalculatorType | null,
      product: Record<string, any>,
      sizes: TSizes,
      filters?: IFilterItem[],
      colorKeys?: string[],
      count?: number,
    ) => {
      const result: number[] = []
      const isColor = priceListKey.indexOf('color') >= 0
      const isImpost = ['impost-vertical', 'impost-horizontal'].includes(priceListKey)
      const isCasements = priceListKey === 'casements'
      const isMosquito = priceListKey === 'mosquito-type'
      const isAeration = priceListKey === 'aeration'
      const isGlassUnit = priceListKey === 'glass-unit-2'




      if (!isColor && priceListRef.current[priceListKey]) {
        const targetAdditionalElementPriceData = getTargetAdditionalElementPriceData(
          product,
          priceListRef.current,
          priceListKey,
          type,
        )

        if (isCasements && product['configuration']) {
          result.push(...calculateCasementsPrice(product, priceListKey, sizes, type, filters))
        } else if (isImpost && product['configuration']) {
          result.push(...calculateImpostsPrice(product, priceListKey, sizes, type))
        } else if (isMosquito) {
          let mosquitoPriceList = calculateMosquitoPrice(
            product,
            priceListKey,
            sizes,
            type,
            filters,
          )

          if (count && count === 1) {
            mosquitoPriceList = [mosquitoPriceList[0]]
          }

          result.push(...mosquitoPriceList)
        } else if (isGlassUnit) {
          const priceData =
            priceListRef.current && priceListRef.current[priceListKey]
              ? priceListRef.current[priceListKey][0]
              : null

          if (priceData) {
            const p = calculateGlassUnitPrice(priceData, priceListKey, product, sizes)

            if (p) {
              result.push(p)
            }
          }
        } else {
          let countItems = count

          if (isAeration) {
            const casementsValue = getCasementsValues(product, filters)

            if (casementsValue) {
              countItems = count || casementsValue.filter(item => item === 'swing-out').length
            }
          }

          const p = calculateCurrentPrice(
            targetAdditionalElementPriceData,
            priceListKey,
            product,
            sizes,
            undefined,
            countItems,
          )

          if (p) {
            result.push(p)
          }
        }
      } else if (isColor && priceColorListRef.current[priceListKey]) {
        const p = calculateMainPrice(type, product, sizes, true, colorKeys || [priceListKey])
        result.push(p)
      }

      return Math.floor(result.reduce(priceReducer, 0))
    },
    [
      calculateCasementsPrice,
      calculateCurrentPrice,
      calculateGlassUnitPrice,
      calculateImpostsPrice,
      calculateMainPrice,
      calculateMosquitoPrice,
    ],
  )

  const handleCalculateTotalPrice = useCallback(
    async (type, product: TProduct, sizes: TSizes, filters) => {
      let result = 0
      let additionalResult = 0
      const additionalList: Record<string, {}> = {}
      const checkIsMain = key => productMainPrice[type] && productMainPrice[type].includes(key)
      const checkIsOrder = key => (product[key] ? product[key]['is-order'] : false)

//console.log(priceListRef.current)

      /** Calculating product price */
      for (const priceListKey of Object.keys(priceListRef.current)) {


        if (checkIsMain(priceListKey)) {
          if (priceListKey === type) {
            /** calculate frame price */
            result += calculateMainPrice(type, product, sizes)
          } else {
            /** calculate another elements - glass-unit-2, casements, imposts */
            result += calculateAdditionalElementPrice(priceListKey, type, product, sizes, filters)
          }
        } else if (
          priceListRef.current[priceListKey] &&
          priceListRef.current[priceListKey].length > 0
        ) {


          /** calculate additional elements price */
          const additionalElementsNotNeeded = filters.find(
            filter => filter['key'] === `${priceListKey}-not-needed`,
          )
          const needCalculate = additionalElementsNotNeeded
            ? !additionalElementsNotNeeded['value']
            : true
          const count: null | number = getCount(priceListKey, product, filters)
          const additionalPrice = calculateAdditionalElementPrice(
            priceListKey,
            type,
            product,
            sizes,
            filters,
          )
          const title = replaceHandlebars(priceListKey, 'title', product, sizes)
          const description = replaceHandlebars(priceListKey, 'description', product, sizes)

          // Есть ли створки у окна?
          const IsCasements = getIsCasements(filters)
          /*
             window-hand - цвет ручки для окна
             Условие отбрасывает параметры с ценой ноль, если ключ паараметра не ходит в список
          */
            // console.log(priceListKey, 2)
          const showAdditional = (['window-hand'].includes(priceListKey) && IsCasements) ||  additionalPrice > 0
          //const showAdditional =  true



          if (needCalculate && showAdditional) {
            additionalResult += additionalPrice
            if (checkIsOrder(priceListKey)) {
              additionalList[priceListKey] = {
                title,
                description,
                price: additionalPrice,
                count,
              }
            }
          }
        }
        // Выводим цвета на панель с права
        if (['model-outside-color', 'model-inside-color', 'model-profile-color'].includes(priceListKey)) {
          const additionalPrice = calculateAdditionalElementPrice(
            priceListKey,
            type,
            product,
            sizes,
            filters,
          )
          const title = replaceHandlebars(priceListKey, 'title', product, sizes)
          const description = replaceHandlebars(priceListKey, 'description', product, sizes)
          if (additionalPrice) {
            additionalResult += additionalPrice
            additionalList[priceListKey] = {
              title,
              description,
              price: additionalPrice,
              count: 1,
            }
          }
        }
      }

      return {
        id: product.id,
        itemPrice: Math.floor(result),
        additionalPrice: additionalList,
        total: Math.floor(result + additionalResult),
        count: 1,
      }
    },
    [calculateAdditionalElementPrice, calculateMainPrice],
  )

  const getProductPriceCollections = useCallback(async type => {
    const inCalcProcess: string[] = []
    const requestList: any[] = []

    requestList.push(getCollectionsObjectsRequest(`${type}-price`))
    inCalcProcess.push(type)

    const requestsResult = await Promise.all(requestList)

    return getRequestData(requestsResult, inCalcProcess)
  }, [])

  const getAdditionalElementsPriceCollections = useCallback(async (product, rawMainData) => {
    const productKeys = Object.keys(product)
    const rawPriceListKeys = Object.keys(rawMainData)
    const inCalcProcess: string[] = []
    const requestList: any[] = []

    for (const key of productKeys) {
      if (key && product[key]) {
        const productElementKeys = Object.keys(product[key])

        if (product[key]['price']) {
          const request = getElementRequest(rawMainData, key, rawPriceListKeys, product)

          if (request) {
            inCalcProcess.push(key)
            requestList.push(request)
          }
        }

        for (const elementKey of productElementKeys) {
          const priceKey = getPrefix(elementKey, '-price')

          if (priceKey) {
            const request = getElementRequest(rawMainData, priceKey, rawPriceListKeys, product)

            if (request) {
              inCalcProcess.push(priceKey)
              requestList.push(request)
            }
          }
        }
      }
    }

    const requestsResult = await Promise.all(requestList)

    return getRequestData(requestsResult, inCalcProcess)
  }, [])

  const handlePriceCalculation = useCallback(
    async (type, product: TProduct, sizes: ISizes, filters) => {
      await getSchemas() // fetch schemas & set in schemasRef
      const rawMainData = await getProductPriceCollections(type) // fetch collections of main price data
      const rawAdditionalData = await getAdditionalElementsPriceCollections(product, rawMainData) // fetch collections of additional elements price data
      await getPreparedPriceListData({ ...rawMainData, ...rawAdditionalData }, type) // prepare & set priceListRef && priceColorListRef

      return handleCalculateTotalPrice(type, product, sizes, filters)
    },
    [
      getAdditionalElementsPriceCollections,
      getPreparedPriceListData,
      getProductPriceCollections,
      getSchemas,
      handleCalculateTotalPrice,
    ],
  )

  const fetchProductPrice = useCallback(async (type, products, sizes, data) => {
    const url = '/api/web-hooks/calculate-products-price'

    try {
      setInProcess(true)
      const priceResponse = await fetchAPI(url, {
        method: 'POST',
        body: JSON.stringify({
          type,
          products,
          sizes,
          data,
        }),
      })

      if (priceResponse && priceResponse['data']) {
        setTotalPriceList(priceResponse['data'])
      }
      setInProcess(false)
    } catch (e) {
      console.error(e)
    }
  }, [])

  const fetchOrderPrice = useCallback(async (targetId, source) => {
    const url = '/api/web-hooks/calculate-order-products-price'

    try {
      setInProcess(true)
      const priceResponse = await fetchAPI(url, {
        method: 'POST',
        body: JSON.stringify({
          'target-id': targetId,
          source,
        }),
      })

      if (priceResponse && priceResponse['data']) {
        setTotalPriceList(priceResponse['data'])
      }

      setInProcess(false)
    } catch (e) {
      console.error(e)
    }
  }, [])

  const calculateTotalPrice = useCallback(
    (type, products, sizes, filters) => {
      const processCalculate = () => {
        const calculateList: Promise<any>[] = []

        setInProcess(true)
        /** Join all products calculations form Promise */
        products.forEach((product, index) => {
          currentFilters.current = filters[index] // set current filter for calculations
          calculateList.push(
            handlePriceCalculation(type, product, sizes[index] || {}, filters[index]), // accumulate all calculations
          )
        })

        Promise.all(calculateList).then(result => {
          setTotalPriceList(result)
          setInProcess(false)
          timerRef.current = null
        })
      }

      if (products.length > 0 && sizes.length > 0) {
        if (timerRef.current !== null) {
          clearTimeout(timerRef.current)
        }

        timerRef.current = setTimeout(processCalculate, 1000)
      }
    },
    [handlePriceCalculation],
  )
  const calculatePriceBitrix = useCallback(
    async (id, count) => {
      const calculateList: Promise<any>[] = []
      setInProcess(true)

      const priceResponse = await fetchAPI('https://ze-okna.ru/api/price/', {
        method: 'POST',
        body: JSON.stringify({
          id,
        }),
      })

      if (priceResponse) {
        setTotalPriceList([
          {
            id: id,
            additionalPrice: {},
            itemPrice: parseFloat(priceResponse['price']),
            total: parseFloat(priceResponse['price']) * count,
            count: count,
          }
        ])
      }

      setInProcess(false)
      /*Promise.all(calculateList).then(result => {
        setTotalPriceList(result)
        setInProcess(false)
        timerRef.current = null
      })*/
    },
    [handlePriceCalculation],
  )

  const fetchCalculate = useCallback(
    (type, products, sizes, filters) => {
      fetchProductPrice(type, products, sizes, filters)
    },
    [fetchProductPrice],
  )

  const finalOrderPriceCalculate = useCallback(
    (targetId: number, source: Record<string, any>) => {
      fetchOrderPrice(targetId, source)
    },
    [fetchOrderPrice],
  )

  return {
    inProcess,
    totalPriceList,
    calculateTotalPrice,
    calculatePriceBitrix,
    calculateAdditionalElementPrice,
    fetchCalculate,
    finalOrderPriceCalculate,
  }
}

export default usePrice
