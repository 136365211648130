import React, { forwardRef, useCallback } from 'react'
import ReactDOM from 'react-dom'
import { WrapperButton, Button } from './styles'
//import ZoomInSvg from 'public/static/svg/delivery/zoom-in.svg'
//import ZoomInOut from 'public/static/svg/delivery/zoom-out.svg'

function ButtonBack({ onClick }, ref) {
  const zoomIn = useCallback(() => onClick(1), [onClick])
  const zoomOut = useCallback(() => onClick(-1), [onClick])

  return ref.current ? ReactDOM.createPortal(
    <WrapperButton>
      <Button onClick={zoomIn}>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 0C9.62132 0 10.125 0.50368 10.125 1.125V7.875H16.875C17.4963 7.875 18 8.37868 18 9C18 9.62132 17.4963 10.125 16.875 10.125H10.125V16.875C10.125 17.4963 9.62132 18 9 18C8.37868 18 7.875 17.4963 7.875 16.875V10.125H1.125C0.50368 10.125 0 9.62132 0 9C0 8.37868 0.50368 7.875 1.125 7.875H7.875V1.125C7.875 0.50368 8.37868 0 9 0Z" fill="#212121"/>
      </svg>
      </Button>
      <Button onClick={zoomOut}>
      <svg width="18" height="3" viewBox="0 0 18 3" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.875 0H16.875C17.4963 0 18 0.50368 18 1.125C18 1.74632 17.4963 2.25 16.875 2.25H7.875H7.55357H1.125C0.50368 2.25 0 1.74632 0 1.125C0 0.50368 0.50368 0 1.125 0H7.55357H7.875Z" fill="#212121"/>
      </svg>
      </Button>
    </WrapperButton>,
    ref.current
  ) : null
}


export default forwardRef(ButtonBack as any)


