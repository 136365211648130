import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement33Square4BottomCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#3-3_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M326.001 34C327.105 34 328 33.1048 328 32.0005C328 30.8962 327.105 30.001 326.001 30.001L323.833 30.001L323.833 30.001L313 30.001L313 30L309.999 30C308.895 30 308 30.8952 308 31.9995C308 33.1038 308.895 33.999 309.999 33.999L312.167 33.999L312.167 34L323 34L323 34L326.001 34Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 323.834 34)"
          fill="#ABABAB"
        />
        <path
          d="M327.06 34C327.579 34 328 33.5792 328 33.0601L328 30.941C328 30.4219 327.579 30.001 327.06 30.001L323 30.001L323 34L327.06 34Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M308.94 30C308.421 30 308 30.4208 308 30.9399L308 33.059C308 33.5781 308.421 33.999 308.94 33.999L313 33.999L313 30L308.94 30Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 322.167 34)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#3-3_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M404.001 34C405.105 34 406 33.1048 406 32.0005C406 30.8962 405.105 30.001 404.001 30.001L401.833 30.001L401.833 30.001L391 30.001L391 30L387.999 30C386.895 30 386 30.8952 386 31.9995C386 33.1038 386.895 33.999 387.999 33.999L390.167 33.999L390.167 34L401 34L401 34L404.001 34Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 401.834 34)"
          fill="#ABABAB"
        />
        <path
          d="M405.06 34C405.579 34 406 33.5792 406 33.0601L406 30.941C406 30.4219 405.579 30.001 405.06 30.001L401 30.001L401 34L405.06 34Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M386.94 30C386.421 30 386 30.4208 386 30.9399L386 33.059C386 33.5781 386.421 33.999 386.94 33.999L391 33.999L391 30L386.94 30Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 400.167 34)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#3-3_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 34H297V193H417V34ZM406 45H308V182H406V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 34H297V193H298V35H416V193H417V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="301.53"
          y="38.53"
          width="110.94"
          height="149.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#3-3_filter3_i)">
          <rect
            x="308.47"
            y="45.47"
            width="97.0601"
            height="136.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="365"
          y="184"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 365 184)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="364.355"
          y="184.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 364.355 184.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M364.238 186.695C363.892 185.542 362.822 184.702 361.556 184.702L354.916 184.702C353.37 184.702 352.116 185.955 352.116 187.502C352.116 187.537 352.117 187.572 352.118 187.607C352.465 188.76 353.534 189.6 354.8 189.6L361.44 189.6C362.987 189.6 364.24 188.346 364.24 186.8C364.24 186.765 364.24 186.73 364.238 186.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M362.881 185.034C362.737 185.012 362.59 185 362.44 185L355.8 185C354.254 185 353 186.253 353 187.8C353 188.867 353.597 189.795 354.475 190.267C353.138 190.056 352.116 188.898 352.116 187.502C352.116 185.955 353.37 184.702 354.916 184.702L361.556 184.702C362.035 184.702 362.486 184.822 362.881 185.034Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-3_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M361 187.5C361 186.672 360.328 186 359.5 186L359.286 186L358 186L339.5 186C338.672 186 338 186.672 338 187.5C338 188.328 338.672 189 339.5 189L356 189L359.5 189L359.714 189C360.424 189 361 188.424 361 187.714C361 187.677 360.999 187.64 360.996 187.604C360.999 187.569 361 187.535 361 187.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M309.708 46.5L404.292 46.5L357 179.508L309.708 46.5Z" stroke="white" />
        <defs>
          <filter
            id="3-3_filter0_d"
            x="306.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-3_filter1_d"
            x="384.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-3_filter2_d"
            x="292.3"
            y="29.3003"
            width="131.279"
            height="170.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-3_filter3_i"
            x="308"
            y="45"
            width="98.9399"
            height="139.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-3_filter4_d"
            x="336.28"
            y="184.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="310.5"
            y1="36.8"
            x2="310.5"
            y2="29.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="325.5"
            y1="27.2018"
            x2="325.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="310.5"
            y1="36.7982"
            x2="310.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="388.5"
            y1="36.8"
            x2="388.5"
            y2="29.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="403.5"
            y1="27.2018"
            x2="403.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="388.5"
            y1="36.7982"
            x2="388.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
