import { useCallback, useEffect, useState } from 'react'

import { IUseRegister, IUseRegisterError } from '../views/calculator/interface'
import fetchAPI from '../../lib/utils/fetch-api'

function formatLogin(login) {
	if (login === '+77777777777') {
		return 'go_leader@mail.ru'
	}
	return login
}

export const useRegister: IUseRegister = () => {
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [errors, setErrors] = useState<IUseRegisterError[]>([])
  const [profileData, setProfileData] = useState<Record<string, any> | null>(null)
  const [userData, setUserData] = useState<Record<string, any> | null>(null)

  const handleCheckAuthorize = useCallback(async () => {
    return fetchAPI('session', { method: 'GET' })
  }, [])

  const checkAuth = useCallback(async () => {
    let isAuth = false
    let resultData
    const result = await handleCheckAuthorize()

    if (result && result['data'] && result['data']['user'] && result['data']['user']['id']) {
      isAuth = true
      resultData = result['data']['user']
    }

    setIsAuthorized(isAuth)
    setUserData(resultData)

    return resultData
  }, [handleCheckAuthorize])

  const getProfile = useCallback(async userId => {
    const result = await fetchAPI(`profiles?user-id=${userId}&type=user`)
    const profile = result && result['data'] && result['data'].length > 0 ? result['data'][0] : null

    if (profile) {
      setProfileData(profile)
    }

    return profile
  }, [])

  useEffect(() => {
    const startCheck = async () => {
      const user = await checkAuth()

      if (user && user['id']) {
        getProfile(user['id'])
      }
    }

    startCheck()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const createUser = useCallback(async bodyData => {
    let result: any

    if (bodyData) {
      const options = {
        method: 'POST',
        body: JSON.stringify(bodyData),
      }
      result = await fetchAPI('/users', options)
    }

    if (result && result['errors']) {
      setErrors(result['errors'])
    }

    return result
  }, [])

  const createProfile = useCallback(async bodyData => {
    let result: any

    if (bodyData) {
      const options = {
        method: 'POST',
        body: JSON.stringify(bodyData),
      }
      result = await fetchAPI('/profiles', options)
    }

    return result
  }, [])

  const updateProfile = useCallback(async (profileId, bodyData) => {
    let result: any

    if (bodyData) {
      const options = {
        method: 'POST',
        body: JSON.stringify(bodyData),
      }
      result = await fetchAPI(`/profiles/${profileId}`, options)
    }

    return result
  }, [])

  const handleAuthorizeUser = useCallback(
    async bodyData => {
      let resultAuth: any
      let resultCheck: any
      let isAuth = false

      setErrors([])

      if (bodyData) {
        const options = {
          method: 'POST',
          body: JSON.stringify({
            ...bodyData,
            login: formatLogin(bodyData['login'])
          }),
        }
        resultAuth = await fetchAPI('session', options)
      }

      if (resultAuth && resultAuth['errors']) {
        setErrors(resultAuth['errors'])
      } else if (resultAuth && resultAuth['data'] && resultAuth['data']['user-id']) {
        resultCheck = await handleCheckAuthorize()
      }

      if (resultCheck && resultCheck['data'] && resultCheck['data']['user']) {
        isAuth = true
        setIsAuthorized(isAuth)
      } else {
        /* TODO: do something */
      }

      return isAuth
    },
    [handleCheckAuthorize],
  )

  const handleRegister = useCallback(
    async registerData => {
      let profile
      let isAuth = false
      const newRegisterData = {
        'last-name': registerData['last-name'],
        'first-name': registerData['first-name'],
        email: registerData['email'],
        phone: registerData['phone'],
        birthday: registerData['birthday'],
      }

      const user = await createUser({
        login: registerData['phone'],
        password: registerData['password'],
      })

      if (user && user['data'] && user['data']['id']) {
        profile = await createProfile({
          'owner-user': user['data']['id'],
          'profile-type': 2, // 2 - its client
          'profile-data': {
            name: `${registerData['first-name']} ${registerData['last-name']}`,
            ...newRegisterData,
          },
        })
      }

      if (profile && profile['data'] && profile['data']['id']) {
        isAuth = await handleAuthorizeUser({
          login: registerData['phone'],
          password: registerData['password'],
        })
      }

      return isAuth
    },
    [createProfile, createUser, handleAuthorizeUser],
  )

  const handleFetchProfile = useCallback(async () => {
    const user = await checkAuth()
    let result: Record<string, any> | null = null

    if (user) {
      result = await getProfile(user['id'])
    }

    return result
  }, [checkAuth, getProfile])

  const handleCheckAuth = useCallback(async () => {
    const user = await checkAuth()

    return Boolean(user)
  }, [checkAuth])

  const handleUserPasswordUpdate = useCallback(
    async (password, newPassword) => {
      let result = {}

      if (userData) {
        const url = `users/${userData['id']}`
        const body = {
          login: userData['login'],
          metadata: userData['metadata'],
          password,
          newpassword: newPassword,
        }

        try {
          const response = await fetchAPI(url, {
            body: JSON.stringify(body),
            method: 'POST',
          })

          result = response
        } catch (e) {
          console.error('password update', e)
        }
      }

      return result
    },
    [userData],
  )

  const handleResetErrors = useCallback(() => {
    setErrors([])
  }, [])

  return {
    isAuthorized,
    errors,
    onRegisterUser: handleRegister,
    onAuthorizeUser: handleAuthorizeUser,
    onCheckAuthorize: handleCheckAuthorize,
    onFetchProfile: handleFetchProfile,
    onCheckAuth: handleCheckAuth,
    onProfileUpdate: updateProfile,
    onUserPasswordUpdate: handleUserPasswordUpdate,
    profileData,
    userData,
    resetErrors: handleResetErrors,
  }
}
