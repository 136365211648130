import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement25Square6Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-5_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M288 94.9995C288 93.8952 287.105 93 286.001 93C284.896 93 284.001 93.8952 284.001 94.9995V97.1665H284.001V108H284V111.001C284 112.105 284.895 113 285.999 113C287.104 113 287.999 112.105 287.999 111.001V108.833H288V98H288V94.9995Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 288 97.166)"
          fill="#ABABAB"
        />
        <path
          d="M288 93.9399C288 93.4208 287.579 93 287.06 93H284.941C284.422 93 284.001 93.4208 284.001 93.9399V98H288V93.9399Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M284 112.06C284 112.579 284.421 113 284.94 113H287.059C287.578 113 287.999 112.579 287.999 112.06V108H284V112.06Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 288 98.833)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#2-5_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M288 40.9995C288 39.8952 287.105 39 286.001 39C284.896 39 284.001 39.8952 284.001 40.9995V43.1665H284.001V54H284V57.0005C284 58.1048 284.895 59 285.999 59C287.104 59 287.999 58.1048 287.999 57.0005V54.8332H288V44H288V40.9995Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 288 43.166)"
          fill="#ABABAB"
        />
        <path
          d="M288 39.9399C288 39.4208 287.579 39 287.06 39H284.941C284.422 39 284.001 39.4208 284.001 39.9399V44H288V39.9399Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M284 58.0601C284 58.5792 284.421 59 284.94 59H287.059C287.578 59 287.999 58.5792 287.999 58.0601V54H284V58.0601Z"
          fill="url(#paint2-5_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 288 44.833)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#2-5_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M284 34H164V118H284V34ZM273 45H175V107H273V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M284 34H164V118H165V35H283V118H284V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="168.53"
          y="38.53"
          width="110.94"
          height="74.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#2-5_filter3_i)">
          <rect
            x="175.47"
            y="45.47"
            width="97.0601"
            height="61.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="166"
          y="70"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#2-5_filter4_d)">
          <rect x="166.702" y="70.6445" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M167.286 81.7958C167.798 82.458 168.6 82.8843 169.502 82.8843C171.049 82.8843 172.302 81.6307 172.302 80.0843V73.4444C172.302 72.456 171.79 71.5871 171.017 71.0889C171.383 71.5621 171.601 72.1558 171.601 72.8004V79.4403C171.601 80.9867 170.347 82.2403 168.801 82.2403C168.243 82.2403 167.723 82.0771 167.286 81.7958Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M169.502 70.6445C167.956 70.6445 166.702 71.8981 166.702 73.4445V79.4402C166.702 79.4606 166.702 79.4809 166.703 79.5012V74.0241C166.703 72.4777 167.956 71.2241 169.503 71.2241C171.029 71.2241 172.27 72.4449 172.302 73.9631V73.4445C172.302 71.8981 171.049 70.6445 169.502 70.6445Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-5_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M169.5 74C168.672 74 168 74.6716 168 75.5V75.7143V77V95.5C168 96.3284 168.672 97 169.5 97C170.328 97 171 96.3284 171 95.5V79V75.5L171 75.2857C171 74.5756 170.424 74 169.714 74C169.677 74 169.64 74.0012 169.604 74.0035C169.569 74.0012 169.535 74 169.5 74Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M271.5 105.32L271.5 46.6801L177.676 76L271.5 105.32Z" stroke="white" />
        <defs>
          <filter
            id="2-5_filter0_d"
            x="282.12"
            y="91.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-5_filter1_d"
            x="282.12"
            y="37.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-5_filter2_d"
            x="159.3"
            y="29.3003"
            width="131.279"
            height="95.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-5_filter3_i"
            x="175"
            y="45"
            width="98.9399"
            height="64.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-5_filter4_d"
            x="164.822"
            y="68.7647"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-5_filter5_d"
            x="166.12"
            y="72.1201"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="290.8"
            y1="110.5"
            x2="283.2"
            y2="110.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="281.202"
            y1="95.5"
            x2="288.8"
            y2="95.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="290.798"
            y1="110.5"
            x2="283.2"
            y2="110.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="290.8"
            y1="56.5"
            x2="283.2"
            y2="56.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="281.202"
            y1="41.5"
            x2="288.8"
            y2="41.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2-5_linear"
            x1="290.798"
            y1="56.5"
            x2="283.2"
            y2="56.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
