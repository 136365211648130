import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement1Door1RCasement2 = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="451"
        height="451"
        viewBox="0 0 451 451"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#door-1-casement-2-1-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 269C32 267.895 31.1046 267 30 267C28.8954 267 28 267.895 28 269V283.56H32V269ZM32 283.56H28V285.4H27.9399C27.4208 285.4 27 285.821 27 286.34V288.12C27 289.158 27.8417 290 28.8799 290H30.1201C31.1583 290 32 289.158 32 288.12V285.4V285.4V283.56Z"
            fill="white"
          />
        </g>
        <rect
          width="3.99998"
          height="0.95833"
          transform="matrix(-1 0 0 1 32 284.25)"
          fill="#ABABAB"
        />
        <path
          d="M32 267.94C32 267.421 31.5792 267 31.0601 267H28.94C28.4208 267 28 267.421 28 267.94V284.25H32V267.94Z"
          fill="url(#door-1-casement-2-1-paint0_linear)"
        />
        <path
          d="M32 289.06C32 289.579 31.5792 290 31.0601 290H27.94C27.4208 290 27 289.579 27 289.06V286.148C27 285.629 27.4208 285.208 27.94 285.208H32V289.06Z"
          fill="url(#door-1-casement-2-1-paint1_linear)"
        />
        <g filter="url(#door-1-casement-2-1-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 46.9995C32 45.8952 31.1048 45 30.0005 45C28.8962 45 28.001 45.8952 28.001 46.9995V49.1665H28.001V60H28V63.0005C28 64.1048 28.8952 65 29.9995 65C31.1038 65 31.999 64.1048 31.999 63.0005V60.8332H32V50H32V46.9995Z"
            fill="url(#door-1-casement-2-1-paint2_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 32 49.1665)"
          fill="#ABABAB"
        />
        <path
          d="M32 45.9399C32 45.4208 31.5792 45 31.0601 45H28.941C28.4219 45 28.001 45.4208 28.001 45.9399V50H32V45.9399Z"
          fill="url(#door-1-casement-2-1-paint3_linear)"
        />
        <path
          d="M28 64.0601C28 64.5792 28.4208 65 28.9399 65H31.059C31.5781 65 31.999 64.5792 31.999 64.0601V60H28V64.0601Z"
          fill="url(#door-1-casement-2-1-paint4_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 32 50.8335)"
          fill="url(#door-1-casement-2-1-paint5_linear)"
        />
        <g filter="url(#door-1-casement-2-1-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M143 34H32V301H143V34ZM132 45H43V290H132V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M143 34H32V301H33V35H142V301H143V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="36.53"
          y="38.53"
          width="101.94"
          height="257.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#door-1-casement-2-1-filter3_i)">
          <rect
            x="43.47"
            y="45.47"
            width="88.0601"
            height="244.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <path d="M44.5 287.443V47.5571L130.385 167.5L44.5 287.443Z" stroke="white" />
        <path d="M87.5 48.8375L130.403 288.5H44.5975L87.5 48.8375Z" stroke="white" />
        <rect
          x="134"
          y="161"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#door-1-casement-2-1-filter4_d)">
          <rect x="134.702" y="161.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M135.286 172.796C135.798 173.458 136.6 173.884 137.502 173.884C139.049 173.884 140.302 172.63 140.302 171.084V164.444C140.302 163.456 139.79 162.587 139.017 162.089C139.383 162.562 139.6 163.156 139.6 163.8V170.44C139.6 171.986 138.347 173.24 136.8 173.24C136.242 173.24 135.723 173.077 135.286 172.796Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M137.502 161.644C135.955 161.644 134.702 162.898 134.702 164.444V170.44C134.702 170.46 134.702 170.48 134.702 170.501V165.024C134.702 163.477 135.956 162.224 137.502 162.224C139.029 162.224 140.269 163.444 140.302 164.963V164.444C140.302 162.898 139.048 161.644 137.502 161.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#door-1-casement-2-1-filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M137.5 165C136.672 165 136 165.672 136 166.5V166.714V168V186.5C136 187.328 136.672 188 137.5 188V188C138.328 188 139 187.328 139 186.5V170V166.5L139 166.286C139 165.576 138.424 165 137.714 165V165C137.677 165 137.64 165.001 137.604 165.004C137.569 165.001 137.535 165 137.5 165Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="door-1-casement-2-1-filter0_d"
            x="25.1201"
            y="265.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-2-1-filter1_d"
            x="26.1201"
            y="43.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-2-1-filter2_d"
            x="27.3003"
            y="29.3003"
            width="122.279"
            height="278.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-2-1-filter3_i"
            x="43"
            y="45"
            width="89.9399"
            height="247.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="door-1-casement-2-1-filter4_d"
            x="132.822"
            y="159.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-2-1-filter5_d"
            x="134.12"
            y="163.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="door-1-casement-2-1-paint0_linear"
            x1="25.2"
            y1="275.625"
            x2="32.8"
            y2="275.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-2-1-paint1_linear"
            x1="23.5"
            y1="287.604"
            x2="33"
            y2="287.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-2-1-paint2_linear"
            x1="34.8"
            y1="62.5"
            x2="27.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-2-1-paint3_linear"
            x1="25.2018"
            y1="47.5"
            x2="32.7998"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-2-1-paint4_linear"
            x1="34.7982"
            y1="62.5"
            x2="27.2002"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-2-1-paint5_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
