import React from 'react'
import { ArrowLeftWrapper } from '../../views/calculator/styles'

export const ArrowLeftIcon = () => {
  return (
    <ArrowLeftWrapper>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.6"
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="#E0E3E9"
        />
        <path
          d="M19.4081 11H5.59088C4.98871 11 4.5 11.336 4.5 11.7499V12.2501C4.5 12.664 4.98871 13 5.59088 13H19.4091C20.0113 13 20.5 12.664 20.5 12.2501V11.7499C20.4989 11.3352 20.0113 11 19.4081 11Z"
          fill="#141B4D"
        />
        <line
          x1="9.5"
          y1="7.41421"
          x2="4.91421"
          y2="12"
          stroke="#141B4D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="1"
          y1="-1"
          x2="7.48528"
          y2="-1"
          transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 9.5 18)"
          stroke="#141B4D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </ArrowLeftWrapper>
  )
}
