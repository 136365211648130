import React from 'react'
import { StageDescriptionBlockContentItemIcon } from 'src/react-app/views/calculator/styles'

export const Energy2 = () => {
  return (
    <StageDescriptionBlockContentItemIcon>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.6"
          d="M32.5 40H7.5C3.3575 40 0 36.6425 0 32.5V7.5C0 3.3575 3.3575 0 7.5 0H32.5C36.6425 0 40 3.3575 40 7.5V32.5C40 36.6425 36.6425 40 32.5 40Z"
          fill="#E0E3E9"
        />
        <path
          d="M20.9998 20.8462V23.5C20.9998 23.9599 20.6271 24.3337 20.166 24.3337C19.7061 24.3337 19.3323 23.9599 19.3323 23.5V20.8462C18.3649 20.5013 17.666 19.5849 17.666 18.5C17.666 17.1212 18.7872 16 20.166 16C21.5448 16 22.666 17.1212 22.666 18.5C22.666 19.5849 21.9672 20.5013 20.9998 20.8462Z"
          fill="#ECEEF2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 27.334C11 28.2529 11.748 29 12.667 29H25.333C26.252 29 27 28.2529 27 27.334V18.281V14.667C27 13.748 26.252 13 25.334 13H12.667C11.748 13 11 13.748 11 14.667V18.281V27.334ZM25.667 17.6141H12.334V14.667C12.334 14.4839 12.4829 14.334 12.667 14.334H25.334C25.5181 14.334 25.667 14.4829 25.667 14.667V17.6141ZM12.334 18.948V27.334C12.334 27.5181 12.4829 27.667 12.667 27.667H18.333V18.948H12.334ZM19.667 27.667H25.334C25.5181 27.667 25.667 27.5171 25.667 27.334V18.948H19.667V27.667Z"
          fill="#1C3775"
        />
        <path
          d="M25.8769 11.6714L25.5137 11.2549L25.1355 11.6578C25.0792 11.7177 25.0224 11.7781 24.9652 11.8389C24.2103 12.6418 23.3856 13.5188 22.7381 14.4203C22.0458 15.3841 21.5 16.4498 21.5 17.5588C21.5 19.7434 23.2989 21.5 25.5 21.5C27.7011 21.5 29.5 19.7434 29.5 17.5588C29.5 16.4463 28.9508 15.4121 28.2594 14.4665C27.7545 13.776 27.1333 13.0817 26.5286 12.4059C26.3069 12.1583 26.0875 11.913 25.8769 11.6714Z"
          fill="#1C3775"
          stroke="#ECEEF2"
        />
      </svg>
    </StageDescriptionBlockContentItemIcon>
  )
}
