import React, { FC, useCallback } from 'react'
import {
  AllStep,
  CurrentStep,
  QuizBottomBlockStyle,
  QuizBottomLeftBlock,
  QuizBottomRightBlock,
  StepWrapper,
} from '../../styles'
import { Button } from '../../../../components/button/button'
import { ButtonViews, IQuizBottomBlockProps } from '../../interface'

export const QuizBottomBlock: FC<IQuizBottomBlockProps> = props => {
  const { onChangeType, onPrevStep, steps, currentStep, handleOnlineConsult, isActiveNextStep = true } = props

  const handleNextStep = useCallback(() => {
    onChangeType()
  }, [onChangeType])
  return (
    <QuizBottomBlockStyle style={{ marginTop: (currentStep === steps) ? 180 : 0 }}>
      <QuizBottomRightBlock>
        {currentStep !== 1 && (
          <Button typeView={ButtonViews.LINK} onClick={onPrevStep}>
            Назад
          </Button>
        )}
      </QuizBottomRightBlock>
      {steps && steps > 0 && (
          <StepWrapper>
            <CurrentStep>{currentStep}</CurrentStep>
            <AllStep>&nbsp;/&nbsp;{steps}</AllStep>
          </StepWrapper>
        )}
      <QuizBottomLeftBlock>
        <Button
          typeView={ButtonViews.DEFAULT}
          onClick={currentStep === steps ? handleOnlineConsult : handleNextStep}
          disabled={!isActiveNextStep}
        >
          {currentStep === steps && <span>Заказать остекление</span>}
          {currentStep !== steps && <span>Далее</span>}
        </Button>
      </QuizBottomLeftBlock>
    </QuizBottomBlockStyle>
  )
}
