import React, { useState, useMemo, useCallback, useContext } from 'react'
import ModalContext from './context'
import { scrollUnlock } from './index'

function Modal({ guid: guidCurrent, node: Node, ...props }) {
  const { setModals } = useContext(ModalContext)
  const resolve = useCallback((result) => {
    // @ts-ignore
    setModals((modals) => {
      const modal = modals.find(({ guid }) => guid === guidCurrent)
      modal?.resolve?.(result)
      return modals
    })
  }, [guidCurrent, setModals])
  const destroy = useCallback(() => {
    // @ts-ignore
    setModals((modals) => {
      const nextModals = modals.filter(({ guid }) => guid !== guidCurrent)
      if (nextModals.length === 0) {
        scrollUnlock()
      }
      return nextModals
    })
  }, [guidCurrent, setModals])
  return <Node destroy={destroy} resolve={resolve} {...props.props} />
}

function Modals({ children }: { children: React.ReactNode }) {
  const [modals, setModals] = useState([] as any[])
  const value = useMemo(() => ({ modals, setModals }), [modals])
  return (
    // @ts-ignore
    <ModalContext.Provider value={value}>
      {children}
      {modals.map((props) => <Modal key={props.guid} {...props} />)}
    </ModalContext.Provider>
  )
}

export default Modals