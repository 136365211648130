import React, { useCallback, useState } from 'react'

import { IOrder, IUseOrders } from '../views/calculator/stage-calculator/interface'
import fetchAPI from '../../lib/utils/fetch-api'
import { IOrderForecastItem } from '../views/calculator/interface'

const sortMethod = (itemA, itemB) => itemB['id'] - itemA['id']

export const useOrders = (): IUseOrders => {
  const [orders, setOrders] = useState<IOrder[]>([])
  const [order, setOrder] = useState<Record<string, any> | null>(null)
  const [cases, setCases] = useState<IOrder[]>([])
  const [forecast, setForecast] = useState<IOrderForecastItem[] | null>(null)
  const [inProcess, setInProcess] = useState(false)

  const handleFetchOrders = useCallback(async () => {
    setInProcess(true)
    const url = 'web-hooks/cases'

    try {
      const response = await fetchAPI(url)

      if (response && response['data']) {
        const ordersList: IOrder[] = []
        const casesList: IOrder[] = []

        response['data'].forEach(item => {
          if (item['stage-code'] && item['stage-code'] !== 'new') {
            ordersList.push(item)
          } else {
            casesList.push(item)
          }
        })

        ordersList.sort(sortMethod)
        setOrders(ordersList)

        casesList.sort(sortMethod)
        setCases(casesList)
      }
    } catch (e) {
      console.error('Fetch cases', e)
    }

    setInProcess(false)
  }, [])

  const handleFetchOrderById = useCallback(
    async id => {
      setInProcess(true)
      const url = `web-hooks/case/${id}`
      let result: IOrder[] | null = null

      try {
        const response = await fetchAPI(url)

        // console.log({ url, response })

        if (response?.['data']?.['id']) {
          const orderIndex = orders.findIndex(({ id }) => id === response['data']['id'])
          const caseIndex = cases.findIndex(({ id }) => id === response['data']['id'])
          const getResultData = (index, prevState, newData): IOrder[] => [
            ...prevState.slice(0, index),
            newData,
            ...prevState.slice(index + 1, prevState.length),
          ]

          setOrder(response['data'])
          result = response['data']

          if (orderIndex !== -1) {
            setOrders(prevState => getResultData(orderIndex, prevState, response['data']))
          } else if (caseIndex !== -1) {
            setCases(prevState => getResultData(caseIndex, prevState, response['data']))
          }
        }
      } catch (e) {
        console.error('Fetch cases', e)
      }

      setInProcess(false)

      return result
    },
    [cases, orders],
  )

  const handleFetchForecast = useCallback(async () => {
    const data = await fetchAPI('order-forecast')

    if (data?.['data']) {
      setForecast(data['data'])
    }
  }, [])

  return {
    order,
    orders,
    cases,
    forecast,
    fetchOrderById: handleFetchOrderById,
    fetchOrders: handleFetchOrders,
    fetchForecast: handleFetchForecast,
    inProcess,
  }
}
