import React from 'react'
import { HRWrapper } from './styles'
import { ComponentShadow } from '../component-shadow'

export const HR = props => {
  const { disabled = false } = props

  return (
    <HRWrapper>
      {disabled && <ComponentShadow coord={{ top: '-29px', bottom: '-28px' }} />}
    </HRWrapper>
  )
}
