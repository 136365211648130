import { createGlobalStyle } from 'styled-components'

export const Fonts = createGlobalStyle`
  @font-face {
    font-family: 'PT Sans Caption';
    src: url('PTSans-CaptionBold.woff2') format('woff2'),
        url('PTSans-CaptionBold.woff') format('woff'),
        url('PTSans-CaptionBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'PT Sans Caption';
    src: url('PTSans-Caption.woff2') format('woff2'),
        url('PTSans-Caption.woff') format('woff'),
        url('PTSans-Caption.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

`
