import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement13Square3VerticalCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#1filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.9765 110C36.8849 110 36 110.885 36 111.976C36 113.068 36.8849 113.953 37.9765 113.953L40.1665 113.953L40.1665 113.953L51 113.953L51 113.954L54.0235 113.954C55.1151 113.954 56 113.069 56 111.978C56 110.886 55.1151 110.001 54.0235 110.001L51.8332 110.001L51.8332 110L41 110L41 110L37.9765 110Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 40.1665 110)"
          fill="#ABABAB"
        />
        <path
          d="M36.9399 110C36.4208 110 36 110.421 36 110.94L36 113.013C36 113.532 36.4208 113.953 36.9399 113.953L41 113.953L41 110L36.9399 110Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M55.0601 113.954C55.5792 113.954 56 113.533 56 113.014L56 110.941C56 110.422 55.5792 110.001 55.0601 110.001L51 110.001L51 113.954L55.0601 113.954Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 41.8334 110)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#1filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M165.976 110C164.885 110 164 110.885 164 111.976C164 113.068 164.885 113.953 165.976 113.953L168.167 113.953L168.167 113.953L179 113.953L179 113.954L182.024 113.954C183.115 113.954 184 113.069 184 111.978C184 110.886 183.115 110.001 182.024 110.001L179.833 110.001L179.833 110L169 110L169 110L165.976 110Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 168.167 110)"
          fill="#ABABAB"
        />
        <path
          d="M164.94 110C164.421 110 164 110.421 164 110.94L164 113.013C164 113.532 164.421 113.953 164.94 113.953L169 113.953L169 110L164.94 110Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M183.06 113.954C183.579 113.954 184 113.533 184 113.014L184 110.941C184 110.422 183.579 110.001 183.06 110.001L179 110.001L179 113.954L183.06 113.954Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 169.833 110)"
          fill="url(#paint7_linear)"
        />
        <path d="M174.599 97.5L110 44.646L45.4007 97.5L174.599 97.5Z" stroke="white" />
        <g filter="url(#1filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 32H32V110H188V32ZM177 43H43V99H177V43Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 32H32V110H33V33H187V110H188V32Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1filter3_i)">
          <rect
            x="43.47"
            y="43.47"
            width="133.06"
            height="55.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="36.53"
          width="146.94"
          height="68.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="116.528"
          y="34"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 116.528 34)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#1filter4_d)">
          <rect
            x="115.884"
            y="34.7018"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 115.884 34.7018)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115.766 36.695C115.42 35.5419 114.35 34.7018 113.084 34.7018L106.444 34.7018C104.898 34.7018 103.644 35.9554 103.644 37.5018C103.644 37.5369 103.645 37.5719 103.646 37.6068C103.993 38.7599 105.062 39.6 106.328 39.6L112.968 39.6C114.515 39.6 115.768 38.3464 115.768 36.8C115.768 36.7648 115.768 36.7298 115.766 36.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M114.409 35.0345C114.266 35.0118 114.118 35 113.968 35L107.328 35C105.782 35 104.528 36.2536 104.528 37.8C104.528 38.867 105.125 39.7947 106.003 40.2672C104.667 40.0557 103.644 38.8981 103.644 37.5018C103.644 35.9554 104.898 34.7018 106.444 34.7018L113.084 34.7018C113.564 34.7018 114.015 34.8222 114.409 35.0345Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M129.528 37.5C129.528 36.6716 128.857 36 128.028 36L127.814 36L126.528 36L108.028 36C107.2 36 106.528 36.6716 106.528 37.5V37.5C106.528 38.3284 107.2 39 108.028 39L124.528 39L128.028 39L128.243 39C128.953 39 129.528 38.4244 129.528 37.7143V37.7143C129.528 37.6771 129.527 37.6401 129.525 37.6035C129.527 37.5693 129.528 37.5348 129.528 37.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="1filter0_d"
            x="34.1201"
            y="108.12"
            width="25.6396"
            height="9.59374"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1filter1_d"
            x="162.12"
            y="108.12"
            width="25.6396"
            height="9.59374"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1filter2_d"
            x="27.3003"
            y="27.3003"
            width="167.279"
            height="89.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1filter3_i"
            x="43"
            y="43"
            width="134.94"
            height="58.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="1filter4_d"
            x="101.764"
            y="32.8219"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1filter5_d"
            x="105.649"
            y="34.1201"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="53.5"
            y1="107.232"
            x2="53.5"
            y2="114.745"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="38.5"
            y1="116.72"
            x2="38.5"
            y2="109.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="53.5"
            y1="107.234"
            x2="53.5"
            y2="114.745"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="181.5"
            y1="107.232"
            x2="181.5"
            y2="114.745"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="166.5"
            y1="116.72"
            x2="166.5"
            y2="109.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="181.5"
            y1="107.234"
            x2="181.5"
            y2="114.745"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
