import React, { FC, useCallback, useMemo, useEffect } from 'react'

import {
  HorizontalSizeWrapper,
  HorizontalThumbWrapper,
  StyledHorizontalSizeWrapper,
  StyledSizesTrack,
  HorizontalErrorsWrapper,
  HorizontalErrorsText,
} from './styles'
import { IHorizontalSizeProps } from './interface'
import { getSliderSizes } from '../../../../../utilits'
import { ThumbSizeContainer } from './thumb-size-container'
import { Tooltip, TooltipDirection } from 'src/react-app/components'

export const HorizontalSize: FC<IHorizontalSizeProps> = props => {
  const { value, type, count, imageWidth, maxWidth = 3000, onChange, disabled, errors } = props

  const isMainSize = useMemo(() => {
    return count === 1
  }, [count])

  const handleInputSize = useCallback(
    (index: number | null) => (val: number) => {
      const result = index !== null ? getSliderSizes(value, maxWidth, val, count, index) : [val]

      onChange(isMainSize ? result[0] : result)
    },
    [count, isMainSize, maxWidth, onChange, value],
  )

  const getSizeBlock = useCallback(
    (size, index) => (
      <StyledHorizontalSizeWrapper
        width={imageWidth / count}
        key={`horizontal-${maxWidth}-${index}`}
      >
        <StyledSizesTrack />
        <HorizontalThumbWrapper>
          <ThumbSizeContainer
            index={index}
            onChange={handleInputSize(index)}
            maxSize={maxWidth}
            currentSize={size}
            disabled={disabled}
          />
        </HorizontalThumbWrapper>
        <HorizontalErrorsWrapper>
          {errors && errors[index] ? (
            <Tooltip
              direction={TooltipDirection.LEFT}
              text={errors[index]['description']}
              coord={{ x: 50, y: -21 }}
            >
              <HorizontalErrorsText>{errors[index]['title']}</HorizontalErrorsText>
            </Tooltip>
          ) : null}
        </HorizontalErrorsWrapper>
      </StyledHorizontalSizeWrapper>
    ),
    [count, disabled, errors, handleInputSize, imageWidth, maxWidth],
  )

  return (
    <HorizontalSizeWrapper width={imageWidth} type={type}>
      {count > 0 && imageWidth > 0 && value.map(getSizeBlock)}
    </HorizontalSizeWrapper>
  )
}
