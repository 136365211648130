import styled, {css} from 'styled-components'

export const FloatBlockWrapper = styled.div<{ shift: number; needShift: boolean; stopAtBottom: boolean }>`
  position: relative;

  @media only screen and (max-width: 991px) {
    position: unset;
    width: 100%;
  }

  ${({shift = 0, needShift, stopAtBottom}) =>
          needShift &&
          !stopAtBottom &&
          css`
            top: ${shift}px;
            position: fixed;
          `}

  ${({stopAtBottom}) =>
          stopAtBottom &&
          css`
            position: absolute;
            bottom: 50px;
            top: unset;
          `}
`
