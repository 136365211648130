import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement54Square8Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#5-4_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 177.999C32 176.895 31.1048 176 30.0005 176C28.8962 176 28.001 176.895 28.001 177.999V180.167H28.001V191H28V194.001C28 195.105 28.8952 196 29.9995 196C31.1038 196 31.999 195.105 31.999 194.001V191.833H32V181H32V177.999Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 32 180.167)"
          fill="#ABABAB"
        />
        <path
          d="M32 176.94C32 176.421 31.5792 176 31.0601 176H28.941C28.4219 176 28.001 176.421 28.001 176.94V181H32V176.94Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M28 195.06C28 195.579 28.4208 196 28.9399 196H31.059C31.5781 196 31.999 195.579 31.999 195.06V191H28V195.06Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 32 181.833)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#5-4_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 254C32 252.895 31.1046 252 30 252C28.8954 252 28 252.895 28 254V268.56H32V254ZM32 268.56H28V270.4H27.9399C27.4208 270.4 27 270.821 27 271.34V273.12C27 274.158 27.8417 275 28.8799 275H30.1201C31.1583 275 32 274.158 32 273.12V270.4V270.4V268.56Z"
            fill="white"
          />
        </g>
        <rect
          width="3.99998"
          height="0.95833"
          transform="matrix(-1 0 0 1 32 269.25)"
          fill="#ABABAB"
        />
        <path
          d="M32 252.94C32 252.421 31.5792 252 31.0601 252H28.94C28.4208 252 28 252.421 28 252.94V269.25H32V252.94Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M32 274.06C32 274.579 31.5792 275 31.0601 275H27.94C27.4208 275 27 274.579 27 274.06V271.148C27 270.629 27.4208 270.208 27.94 270.208H32V274.06Z"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#5-4_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M121 165H32V286H121V165ZM110 176H43V275H110V176Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M121 165H32V286H33.2192V166H119.781V286H121V165Z"
          fill="#FBFBFB"
        />
        <g filter="url(#5-4_filter3_i)">
          <rect
            x="43.47"
            y="176.47"
            width="66.0601"
            height="98.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="169.53"
          width="79.9399"
          height="111.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="112"
          y="218"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#5-4_filter4_d)">
          <rect x="112.702" y="218.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.286 229.796C113.798 230.458 114.6 230.884 115.502 230.884C117.049 230.884 118.302 229.63 118.302 228.084V221.444C118.302 220.456 117.79 219.587 117.017 219.089C117.383 219.562 117.6 220.156 117.6 220.8V227.44C117.6 228.986 116.347 230.24 114.8 230.24C114.242 230.24 113.723 230.077 113.286 229.796Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115.502 218.644C113.955 218.644 112.702 219.898 112.702 221.444V227.44C112.702 227.46 112.702 227.48 112.702 227.501V222.024C112.702 220.477 113.956 219.224 115.502 219.224C117.028 219.224 118.269 220.444 118.302 221.963V221.444C118.302 219.898 117.048 218.644 115.502 218.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#5-4_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M115.5 222C114.672 222 114 222.672 114 223.5V223.714V225V243.5C114 244.328 114.672 245 115.5 245V245C116.328 245 117 244.328 117 243.5V227V223.5L117 223.286C117 222.576 116.424 222 115.714 222V222C115.677 222 115.64 222.001 115.604 222.004C115.569 222.001 115.535 222 115.5 222Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M44.5001 177.997L108.164 225.5L44.5001 273.003L44.5001 177.997Z" stroke="white" />
        <path d="M44.6948 273.5L76.5 178.574L108.305 273.5L44.6948 273.5Z" stroke="white" />
        <defs>
          <filter
            id="5-4_filter0_d"
            x="26.1201"
            y="174.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-4_filter1_d"
            x="25.1201"
            y="250.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-4_filter2_d"
            x="27.3003"
            y="160.3"
            width="100.279"
            height="132.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-4_filter3_i"
            x="43"
            y="176"
            width="67.9399"
            height="101.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-4_filter4_d"
            x="110.822"
            y="216.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-4_filter5_d"
            x="112.12"
            y="220.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="34.8"
            y1="193.5"
            x2="27.2"
            y2="193.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="25.2018"
            y1="178.5"
            x2="32.7998"
            y2="178.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="34.7982"
            y1="193.5"
            x2="27.2002"
            y2="193.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="25.2"
            y1="260.625"
            x2="32.8"
            y2="260.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="23.5"
            y1="272.604"
            x2="33"
            y2="272.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
