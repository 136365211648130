import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement24Square2Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="315"
        height="333"
        viewBox="0 0 315 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-4_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M175.999 287C174.895 287 174 287.895 174 288.999C174 290.104 174.895 290.999 175.999 290.999L178.167 290.999L178.167 290.999L189 290.999L189 291L192.001 291C193.105 291 194 290.105 194 289.001C194 287.896 193.105 287.001 192.001 287.001L189.833 287.001L189.833 287L179 287L179 287L175.999 287Z"
            fill="url(#2-4_paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 178.167 287)"
          fill="#ABABAB"
        />
        <path
          d="M174.94 287C174.421 287 174 287.421 174 287.94L174 290.059C174 290.578 174.421 290.999 174.94 290.999L179 290.999L179 287L174.94 287Z"
          fill="url(#2-4_paint1_linear)"
        />
        <path
          d="M193.06 291C193.579 291 194 290.579 194 290.06L194 287.941C194 287.422 193.579 287.001 193.06 287.001L189 287.001L189 291L193.06 291Z"
          fill="url(#2-4_paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 179.833 287)"
          fill="url(#2-4_paint3_linear)"
        />
        <g filter="url(#2-4_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M253.999 287C252.895 287 252 287.895 252 288.999C252 290.104 252.895 290.999 253.999 290.999L256.167 290.999L256.167 290.999L267 290.999L267 291L270.001 291C271.105 291 272 290.105 272 289.001C272 287.896 271.105 287.001 270.001 287.001L267.833 287.001L267.833 287L257 287L257 287L253.999 287Z"
            fill="url(#2-4_paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 256.167 287)"
          fill="#ABABAB"
        />
        <path
          d="M252.94 287C252.421 287 252 287.421 252 287.94L252 290.059C252 290.578 252.421 290.999 252.94 290.999L257 290.999L257 287L252.94 287Z"
          fill="url(#2-4_paint5_linear)"
        />
        <path
          d="M271.06 291C271.579 291 272 290.579 272 290.06L272 287.941C272 287.422 271.579 287.001 271.06 287.001L267 287.001L267 291L271.06 291Z"
          fill="url(#2-4_paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 257.833 287)"
          fill="url(#2-4_paint7_linear)"
        />
        <g filter="url(#2-4_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M283 34H163V287H283V34ZM272 45H174V276H272V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M283 34H163V287L163.887 287V34.937H282.109V287L283 287V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="167.53"
          y="38.53"
          width="110.94"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#2-4_filter3_i)">
          <rect
            x="174.47"
            y="45.47"
            width="97.0601"
            height="230.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M223 46L175 275H271L223 46ZM223 50.3351L176.107 274.055H269.893L223 50.3351Z"
          fill="white"
        />
        <rect
          x="229.528"
          y="36"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 229.528 36)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#2-4_filter4_d)">
          <rect
            x="228.884"
            y="36.7018"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 228.884 36.7018)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M228.766 38.695C228.42 37.5419 227.35 36.7018 226.084 36.7018L219.444 36.7018C217.898 36.7018 216.644 37.9554 216.644 39.5018C216.644 39.537 216.645 39.572 216.646 39.6068C216.993 40.7599 218.062 41.6 219.328 41.6L225.968 41.6C227.515 41.6 228.768 40.3464 228.768 38.8C228.768 38.7648 228.768 38.7298 228.766 38.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M227.409 37.0345C227.266 37.0118 227.118 37 226.968 37L220.328 37C218.782 37 217.528 38.2536 217.528 39.8C217.528 40.8671 218.125 41.7947 219.003 42.2673C217.667 42.0557 216.644 40.8981 216.644 39.5018C216.644 37.9554 217.898 36.7018 219.444 36.7018L226.084 36.7018C226.564 36.7018 227.015 36.8223 227.409 37.0345Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-4_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M242.528 39.5C242.528 38.6716 241.857 38 241.028 38L240.814 38L239.528 38L221.028 38C220.2 38 219.528 38.6716 219.528 39.5C219.528 40.3284 220.2 41 221.028 41L237.528 41L241.028 41L241.243 41C241.953 41 242.528 40.4244 242.528 39.7143C242.528 39.6771 242.527 39.6401 242.525 39.6035C242.527 39.5693 242.528 39.5348 242.528 39.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="2-4_filter0_d"
            x="172.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-4_filter1_d"
            x="250.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-4_filter2_d"
            x="158.3"
            y="29.3003"
            width="131.279"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-4_filter3_i"
            x="174"
            y="45"
            width="98.9399"
            height="233.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-4_filter4_d"
            x="214.765"
            y="34.8219"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-4_filter5_d"
            x="218.649"
            y="36.1201"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="2-4_paint0_linear"
            x1="191.5"
            y1="284.2"
            x2="191.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="2-4_paint1_linear"
            x1="176.5"
            y1="293.798"
            x2="176.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="2-4_paint2_linear"
            x1="191.5"
            y1="284.202"
            x2="191.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="2-4_paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="2-4_paint4_linear"
            x1="269.5"
            y1="284.2"
            x2="269.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="2-4_paint5_linear"
            x1="254.5"
            y1="293.798"
            x2="254.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="2-4_paint6_linear"
            x1="269.5"
            y1="284.202"
            x2="269.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="2-4_paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
