import React, { FC, useMemo } from 'react'

// import { CloseIcon } from 'src/react-app/assets/icons'
import { Tooltip } from '../../../../../components'
import { BadgeWrapper, BadgeContent, BadgeText } from './styles'
import { IBadgeProps } from './interface'
import { ComponentShadow } from '../component-shadow'

const getIsCasements = filters => {
	const casements = filters?.['casements-index'] || []
	return casements.includes(1)
}

export const Badge: FC<IBadgeProps> = props => {
  const { value, data = { id: '' }, selected, disabled = false, show } = props
  const { id } = data

  // const { menuItemsData } = useContext(ProductsContext)

  const canShow = useMemo(() => {
    const selectedData = id && selected !== null ? selected[id] : {}

    return show && selectedData && selectedData['value']
  }, [id, selected, show])

  // const canClose = useMemo(() => {
  //   return Boolean(id)
  // }, [id])

  // const handleClose = useCallback(() => {
  //   if (id && onSelect) {
  //     const items = menuItemsData[id]
  //     const closeItem = items.find(item => item['value'] === false)
  //     onSelect(id, closeItem, false)
  //   }
  // }, [id, menuItemsData, onSelect])

  const aerationIsCasements = useMemo(() => {
    if (id !== 'aeration') {
      return true
    }
    if (getIsCasements(selected)) {
      return true
    }
    return false
  }, [selected, id])


  return (
    <>
      {canShow && aerationIsCasements && (
        <BadgeWrapper>
          {disabled && <ComponentShadow coord={{ top: '-12px', bottom: '-6px' }} />}
          <BadgeContent>
            <Tooltip text={value} coord={{ y: '30px', x: '50%' }}>
              <BadgeText>{value}</BadgeText>
            </Tooltip>
            {/* {canClose && ( */}
            {/*  <BadgeClose onClick={handleClose}> */}
            {/*    <CloseIcon color="#959595" /> */}
            {/*  </BadgeClose> */}
            {/* )} */}
          </BadgeContent>
        </BadgeWrapper>
      )}
    </>
  )
}
