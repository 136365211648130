import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement33Square82Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M99.0005 99C100.105 99 101 98.1048 101 97.0005C101 95.8962 100.105 95.001 99.0005 95.001L96.8335 95.001L96.8335 95.001L86 95.001L86 95L82.9995 95C81.8952 95 81 95.8952 81 96.9995C81 98.1038 81.8952 98.999 82.9995 98.999L85.1668 98.999L85.1668 99L96 99L96 99L99.0005 99Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 96.8335 99)"
          fill="#ABABAB"
        />
        <path
          d="M100.06 99C100.579 99 101 98.5792 101 98.0601L101 95.941C101 95.4219 100.579 95.001 100.06 95.001L96 95.001L96 99L100.06 99Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M81.9399 95C81.4208 95 81 95.4208 81 95.9399L81 98.059C81 98.5781 81.4208 98.999 81.9399 98.999L86 98.999L86 95L81.9399 95Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 95.1666 99)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#2filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.0005 99C55.1048 99 56 98.1048 56 97.0005C56 95.8962 55.1048 95.001 54.0005 95.001L51.8335 95.001L51.8335 95.001L41 95.001L41 95L37.9995 95C36.8952 95 36 95.8952 36 96.9995C36 98.1038 36.8952 98.999 37.9995 98.999L40.1668 98.999L40.1668 99L51 99L51 99L54.0005 99Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 51.8335 99)"
          fill="#ABABAB"
        />
        <path
          d="M55.0601 99C55.5792 99 56 98.5792 56 98.0601L56 95.941C56 95.4219 55.5792 95.001 55.0601 95.001L51 95.001L51 99L55.0601 99Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M36.9399 95C36.4208 95 36 95.4208 36 95.9399L36 98.059C36 98.5781 36.4208 98.999 36.9399 98.999L41 98.999L41 95L36.9399 95Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 50.1666 99)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#2filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M105 99H32V154H105V99ZM95 109H42V144H95V109Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105 99H32V154H33V100H104V154H105V99Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2filter3_i)">
          <rect
            x="42.47"
            y="109.47"
            width="52.0601"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="103.53"
          width="63.9399"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M44.0182 110.5L68.5 142.182L92.9818 110.5L44.0182 110.5Z" stroke="white" />
        <rect
          x="75"
          y="145"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 75 145)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="74.356"
          y="145.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 74.356 145.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74.2379 147.695C73.8915 146.542 72.8218 145.702 71.5559 145.702L64.916 145.702C63.3696 145.702 62.116 146.956 62.116 148.502C62.116 148.537 62.1167 148.572 62.118 148.607C62.4643 149.76 63.534 150.6 64.8 150.6L71.4399 150.6C72.9863 150.6 74.2399 149.346 74.2399 147.8C74.2399 147.765 74.2392 147.73 74.2379 147.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72.881 146.035C72.7373 146.012 72.59 146 72.4399 146L65.8 146C64.2536 146 63 147.254 63 148.8C63 149.867 63.5969 150.795 64.475 151.267C63.1382 151.056 62.1161 149.898 62.1161 148.502C62.1161 146.956 63.3697 145.702 64.9161 145.702L71.556 145.702C72.0353 145.702 72.4865 145.822 72.881 146.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M71 148.5C71 147.672 70.3284 147 69.5 147L69.2857 147L68 147L49.5 147C48.6716 147 48 147.672 48 148.5V148.5C48 149.328 48.6716 150 49.5 150L66 150L69.5 150L69.7143 150C70.4244 150 71 149.424 71 148.714V148.714C71 148.677 70.9988 148.64 70.9965 148.604C70.9988 148.569 71 148.535 71 148.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="2filter0_d"
            x="79.1201"
            y="93.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2filter1_d"
            x="34.1201"
            y="93.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2filter2_d"
            x="27.3003"
            y="94.3003"
            width="84.2793"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2filter3_i"
            x="42"
            y="109"
            width="53.9399"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2filter4_d"
            x="46.28"
            y="145.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="83.5"
            y1="101.8"
            x2="83.5"
            y2="94.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="98.5"
            y1="92.2018"
            x2="98.5"
            y2="99.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="83.5"
            y1="101.798"
            x2="83.5"
            y2="94.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="38.5"
            y1="101.8"
            x2="38.5"
            y2="94.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="53.5"
            y1="92.2018"
            x2="53.5"
            y2="99.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="38.5"
            y1="101.798"
            x2="38.5"
            y2="94.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
