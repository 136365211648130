import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement15Square1Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M61.0235 32.954C62.1151 32.954 63 32.0691 63 30.9775C63 29.8859 62.1151 29.001 61.0235 29.001L58.8335 29.001L58.8335 29.001L48 29.001L48 28.9999L44.9765 28.9999C43.8849 28.9999 43 29.8848 43 30.9764C43 32.068 43.8849 32.9529 44.9765 32.9529L47.1668 32.9529L47.1668 32.954L58 32.954L58 32.954L61.0235 32.954Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 58.8335 32.954)"
          fill="#ABABAB"
        />
        <path
          d="M62.0601 32.954C62.5792 32.954 63 32.5332 63 32.014L63 29.9409C63 29.4218 62.5792 29.001 62.0601 29.001L58 29.001L58 32.954L62.0601 32.954Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M43.9399 28.9999C43.4208 28.9999 43 29.4207 43 29.9398L43 32.0129C43 32.532 43.4208 32.9529 43.9399 32.9529L48 32.9529L48 28.9999L43.9399 28.9999Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 57.1666 32.954)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M175.024 32.954C176.115 32.954 177 32.0691 177 30.9775C177 29.8859 176.115 29.001 175.024 29.001L172.833 29.001L172.833 29.001L162 29.001L162 28.9999L158.976 28.9999C157.885 28.9999 157 29.8848 157 30.9764C157 32.068 157.885 32.9529 158.976 32.9529L161.167 32.9529L161.167 32.954L172 32.954L172 32.954L175.024 32.954Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 172.833 32.954)"
          fill="#ABABAB"
        />
        <path
          d="M176.06 32.954C176.579 32.954 177 32.5332 177 32.014L177 29.9409C177 29.4218 176.579 29.001 176.06 29.001L172 29.001L172 32.954L176.06 32.954Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M157.94 28.9999C157.421 28.9999 157 29.4207 157 29.9398L157 32.0129C157 32.532 157.421 32.9529 157.94 32.9529L162 32.9529L162 28.9999L157.94 28.9999Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 171.167 32.954)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 33H32V286H188V33ZM177 44H43V275H177V44Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 33H32V286H33V34H187V286H188V33Z"
          fill="#FBFBFB"
        />
        <rect
          x="36.53"
          y="37.53"
          width="146.94"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#filter3_i)">
          <rect
            x="43.47"
            y="44.47"
            width="133.06"
            height="230.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="116"
          y="277"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 116 277)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="115.356"
          y="277.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 115.356 277.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115.238 279.695C114.892 278.542 113.822 277.702 112.556 277.702L105.916 277.702C104.37 277.702 103.116 278.955 103.116 280.502C103.116 280.537 103.117 280.572 103.118 280.607C103.464 281.76 104.534 282.6 105.8 282.6L112.44 282.6C113.986 282.6 115.24 281.346 115.24 279.8C115.24 279.765 115.239 279.73 115.238 279.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.881 278.035C113.737 278.012 113.59 278 113.44 278L106.8 278C105.254 278 104 279.254 104 280.8C104 281.867 104.597 282.795 105.475 283.267C104.138 283.056 103.116 281.898 103.116 280.502C103.116 278.955 104.37 277.702 105.916 277.702L112.556 277.702C113.035 277.702 113.487 277.822 113.881 278.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M112 280.5C112 279.672 111.328 279 110.5 279L110.286 279L109 279L90.5 279C89.6716 279 89 279.672 89 280.5V280.5C89 281.328 89.6716 282 90.5 282L107 282L110.5 282L110.714 282C111.424 282 112 281.424 112 280.714V280.714C112 280.677 111.999 280.64 111.996 280.604C111.999 280.569 112 280.535 112 280.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M175.336 45.5L110.182 272.195L44.665 45.5L175.336 45.5Z" stroke="white" />
        <defs>
          <filter
            id="filter0_d"
            x="41.1201"
            y="27.12"
            width="25.6396"
            height="9.59374"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter1_d"
            x="155.12"
            y="27.12"
            width="25.6396"
            height="9.59374"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter2_d"
            x="27.3003"
            y="28.3003"
            width="167.279"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter3_i"
            x="43"
            y="44"
            width="134.94"
            height="233.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter4_d"
            x="87.28"
            y="277.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="45.5"
            y1="35.7218"
            x2="45.5"
            y2="28.2092"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="60.5"
            y1="26.2339"
            x2="60.5"
            y2="33.7446"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="45.5"
            y1="35.72"
            x2="45.5"
            y2="28.2093"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="159.5"
            y1="35.7218"
            x2="159.5"
            y2="28.2092"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="174.5"
            y1="26.2339"
            x2="174.5"
            y2="33.7446"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="159.5"
            y1="35.72"
            x2="159.5"
            y2="28.2093"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
