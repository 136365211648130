import styled from 'styled-components'

import { SubMenuAttributeTitle } from '../sub-menu-attribute'
import { ComponentShadowWrapper } from '../component-shadow'

export const CheckboxWrapper = styled.div`
  margin-bottom: 16px;

  position: relative;

  & + ${SubMenuAttributeTitle} {
    ${ComponentShadowWrapper} {
      top: -15px;
    }
  }
`

export const CheckboxBlock = styled.div``

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;

  margin-bottom: 14px;

  cursor: pointer;
`

export const CheckboxInput = styled.input`
  margin-right: 13px;

  cursor: pointer;
`
