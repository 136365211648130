import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement32Square5VerticalCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#3-2_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M50.0005 142C51.1048 142 52 141.105 52 140.001C52 138.896 51.1048 138.001 50.0005 138.001L47.8335 138.001L47.8335 138.001L37 138.001L37 138L33.9995 138C32.8952 138 32 138.895 32 139.999C32 141.104 32.8952 141.999 33.9995 141.999L36.1668 141.999L36.1668 142L47 142L47 142L50.0005 142Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 47.8335 142)"
          fill="#ABABAB"
        />
        <path
          d="M51.0601 142C51.5792 142 52 141.579 52 141.06L52 138.941C52 138.422 51.5792 138.001 51.0601 138.001L47 138.001L47 142L51.0601 142Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M32.9399 138C32.4208 138 32 138.421 32 138.94L32 141.059C32 141.578 32.4208 141.999 32.9399 141.999L37 141.999L37 138L32.9399 138Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 46.1666 142)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#3-2_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M186.001 142C187.105 142 188 141.105 188 140.001C188 138.896 187.105 138.001 186.001 138.001L183.833 138.001L183.833 138.001L173 138.001L173 138L169.999 138C168.895 138 168 138.895 168 139.999C168 141.104 168.895 141.999 169.999 141.999L172.167 141.999L172.167 142L183 142L183 142L186.001 142Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 183.833 142)"
          fill="#ABABAB"
        />
        <path
          d="M187.06 142C187.579 142 188 141.579 188 141.06L188 138.941C188 138.422 187.579 138.001 187.06 138.001L183 138.001L183 142L187.06 142Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M168.94 138C168.421 138 168 138.421 168 138.94L168 141.059C168 141.578 168.421 141.999 168.94 141.999L173 141.999L173 138L168.94 138Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 182.167 142)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#3-2_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M192 142H28V188H192V142ZM183 151H37V179H183V151Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M192 142H28V188H29V143H191V188H192V142Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-2_filter3_i)">
          <rect
            x="37.47"
            y="151.47"
            width="145.06"
            height="27.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="32.53"
          y="146.53"
          width="154.94"
          height="36.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="117"
          y="180"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 117 180)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="116.356"
          y="180.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 116.356 180.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M116.238 182.695C115.892 181.542 114.822 180.702 113.556 180.702L106.916 180.702C105.37 180.702 104.116 181.955 104.116 183.502C104.116 183.537 104.117 183.572 104.118 183.607C104.464 184.76 105.534 185.6 106.8 185.6L113.44 185.6C114.986 185.6 116.24 184.346 116.24 182.8C116.24 182.765 116.239 182.73 116.238 182.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M114.881 181.034C114.737 181.012 114.59 181 114.44 181L107.8 181C106.254 181 105 182.253 105 183.8C105 184.867 105.597 185.795 106.475 186.267C105.138 186.056 104.116 184.898 104.116 183.502C104.116 181.955 105.37 180.702 106.916 180.702L113.556 180.702C114.035 180.702 114.487 180.822 114.881 181.034Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-2_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M113 183.5C113 182.672 112.328 182 111.5 182L111.286 182L110 182L91.5 182C90.6716 182 90 182.672 90 183.5V183.5C90 184.328 90.6716 185 91.5 185L108 185L111.5 185L111.714 185C112.424 185 113 184.424 113 183.714V183.714C113 183.677 112.999 183.64 112.996 183.604C112.999 183.569 113 183.535 113 183.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M179.143 152.5L110 177.468L40.8567 152.5H179.143Z" stroke="white" />
        <defs>
          <filter
            id="3-2_filter0_d"
            x="30.1201"
            y="136.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-2_filter1_d"
            x="166.12"
            y="136.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-2_filter2_d"
            x="23.3003"
            y="137.3"
            width="175.279"
            height="57.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-2_filter3_i"
            x="37"
            y="151"
            width="146.94"
            height="30.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-2_filter4_d"
            x="88.28"
            y="180.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="34.5"
            y1="144.8"
            x2="34.5"
            y2="137.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="49.5"
            y1="135.202"
            x2="49.5"
            y2="142.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="34.5"
            y1="144.798"
            x2="34.5"
            y2="137.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="170.5"
            y1="144.8"
            x2="170.5"
            y2="137.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="185.5"
            y1="135.202"
            x2="185.5"
            y2="142.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="170.5"
            y1="144.798"
            x2="170.5"
            y2="137.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
