import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement44Square3Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-4_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.9995 118C38.8952 118 38 118.895 38 119.999C38 121.104 38.8952 121.999 39.9995 121.999L42.1665 121.999L42.1665 121.999L53 121.999L53 122L56.0005 122C57.1048 122 58 121.105 58 120.001C58 118.896 57.1048 118.001 56.0005 118.001L53.8332 118.001L53.8332 118L43 118L43 118L39.9995 118Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 42.166 118)"
          fill="#ABABAB"
        />
        <path
          d="M38.9399 118C38.4208 118 38 118.421 38 118.94L38 121.059C38 121.578 38.4208 121.999 38.9399 121.999L43 121.999L43 118L38.9399 118Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M57.0601 122C57.5792 122 58 121.579 58 121.06L58 118.941C58 118.422 57.5792 118.001 57.0601 118.001L53 118.001L53 122L57.0601 122Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 43.833 118)"
          fill="url(#paint4-4_linear)"
        />
        <g filter="url(#4-4_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M127.999 118C126.895 118 126 118.895 126 119.999C126 121.104 126.895 121.999 127.999 121.999L130.167 121.999L130.167 121.999L141 121.999L141 122L144.001 122C145.105 122 146 121.105 146 120.001C146 118.896 145.105 118.001 144.001 118.001L141.833 118.001L141.833 118L131 118L131 118L127.999 118Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 130.166 118)"
          fill="#ABABAB"
        />
        <path
          d="M126.94 118C126.421 118 126 118.421 126 118.94L126 121.059C126 121.578 126.421 121.999 126.94 121.999L131 121.999L131 118L126.94 118Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M145.06 122C145.579 122 146 121.579 146 121.06L146 118.941C146 118.422 145.579 118.001 145.06 118.001L141 118.001L141 122L145.06 122Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 131.833 118)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#4-4_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M152 122H32V199H152V122ZM141 133H43V188H141V133Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#4-4_filter4-4_i)">
          <rect
            x="43.47"
            y="133.47"
            width="97.0601"
            height="54.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="37.5" y="127.5" width="109" height="66" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M152 122H32V199H33V123H151V199H152V122Z"
          fill="#FBFBFB"
        />
        <path d="M45.1274 134.5H138.873L92 186.255L45.1274 134.5Z" stroke="white" />
        <rect
          x="98"
          y="190"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 98 190)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#4-4_filter4_d)">
          <rect
            x="97.3555"
            y="190.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 97.3555 190.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M97.2383 192.695C96.8919 191.542 95.8222 190.702 94.5563 190.702L87.9164 190.702C86.37 190.702 85.1164 191.956 85.1164 193.502C85.1164 193.537 85.117 193.572 85.1183 193.607C85.4647 194.76 86.5344 195.6 87.8003 195.6L94.4402 195.6C95.9866 195.6 97.2402 194.347 97.2402 192.8C97.2402 192.765 97.2396 192.73 97.2383 192.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M95.8809 191.035C95.7372 191.012 95.5899 191 95.4398 191L88.7999 191C87.2535 191 85.9999 192.254 85.9999 193.8C85.9999 194.867 86.5968 195.795 87.4749 196.268C86.1381 196.056 85.116 194.898 85.116 193.502C85.116 191.956 86.3696 190.702 87.916 190.702L94.5559 190.702C95.0352 190.702 95.4864 190.823 95.8809 191.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-4_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M94 193.5C94 192.672 93.3284 192 92.5 192L92.2857 192L91 192L72.5 192C71.6716 192 71 192.672 71 193.5C71 194.328 71.6716 195 72.5 195L89 195L92.5 195L92.7143 195C93.4244 195 94 194.424 94 193.714C94 193.677 93.9988 193.64 93.9965 193.604C93.9988 193.569 94 193.535 94 193.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="4-4_filter0_d"
            x="36.1201"
            y="116.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-4_filter1_d"
            x="124.12"
            y="116.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-4_filter2_d"
            x="27.3003"
            y="117.3"
            width="131.279"
            height="88.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-4_filter4-4_i"
            x="43"
            y="133"
            width="98.9399"
            height="57.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-4_filter4_d"
            x="83.2354"
            y="188.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-4_filter5_d"
            x="69.1201"
            y="190.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="55.5"
            y1="115.2"
            x2="55.5"
            y2="122.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="40.5"
            y1="124.798"
            x2="40.5"
            y2="117.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="55.5"
            y1="115.202"
            x2="55.5"
            y2="122.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4-4_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="143.5"
            y1="115.2"
            x2="143.5"
            y2="122.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="128.5"
            y1="124.798"
            x2="128.5"
            y2="117.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="143.5"
            y1="115.202"
            x2="143.5"
            y2="122.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
