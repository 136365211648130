import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement45Square41Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-5-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 255C417 253.895 417.895 253 419 253C420.105 253 421 253.895 421 255V269.56H417V255ZM417 269.56H421V271.4H421.06C421.579 271.4 422 271.821 422 272.34V274.12C422 275.158 421.158 276 420.12 276H418.88C417.842 276 417 275.158 417 274.12V271.4V271.4V269.56Z"
            fill="white"
          />
        </g>
        <rect x="417" y="270.25" width="3.99998" height="0.95833" fill="#ABABAB" />
        <path
          d="M417 253.94C417 253.421 417.421 253 417.94 253H420.06C420.579 253 421 253.421 421 253.94V270.25H417V253.94Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M417 275.06C417 275.579 417.421 276 417.94 276H421.06C421.579 276 422 275.579 422 275.06V272.148C422 271.629 421.579 271.208 421.06 271.208H417V275.06Z"
          fill="url(#paint1_linear)"
        />
        <g filter="url(#4-5-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M421 46.9995C421 45.8952 420.105 45 419.001 45C417.896 45 417.001 45.8952 417.001 46.9995V49.1665H417.001V60H417V63.0005C417 64.1048 417.895 65 418.999 65C420.104 65 420.999 64.1048 420.999 63.0005V60.8332H421V50H421V46.9995Z"
            fill="url(#paint2_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 421 49.1665)"
          fill="#ABABAB"
        />
        <path
          d="M421 45.9399C421 45.4208 420.579 45 420.06 45H417.941C417.422 45 417.001 45.4208 417.001 45.9399V50H421V45.9399Z"
          fill="url(#paint3_linear)"
        />
        <path
          d="M417 64.0601C417 64.5792 417.421 65 417.94 65H420.059C420.578 65 420.999 64.5792 420.999 64.0601V60H417V64.0601Z"
          fill="url(#paint4_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 421 50.8334)"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#4-5-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 34H328V287H417V34ZM406 45H339V276H406V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 34H328V287H329.219V35H415.781V287H417V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-5-filter3_i)">
          <rect
            x="339.47"
            y="45.47"
            width="66.0601"
            height="230.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="332.53"
          y="38.53"
          width="79.9399"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="330"
          y="155"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#4-5-filter4_d)">
          <rect x="330.702" y="155.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M331.286 166.795C331.798 167.458 332.6 167.884 333.502 167.884C335.048 167.884 336.302 166.63 336.302 165.084V158.444C336.302 157.456 335.79 156.587 335.016 156.089C335.382 156.562 335.6 157.155 335.6 157.8V164.44C335.6 165.986 334.347 167.24 332.8 167.24C332.242 167.24 331.722 167.077 331.286 166.795Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M333.502 155.644C331.956 155.644 330.702 156.898 330.702 158.444V164.44C330.702 164.46 330.702 164.48 330.703 164.501V159.024C330.703 157.477 331.956 156.224 333.503 156.224C335.029 156.224 336.269 157.444 336.302 158.963V158.444C336.302 156.898 335.048 155.644 333.502 155.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-5-filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M333.5 159C332.672 159 332 159.672 332 160.5V160.714V162V180.5C332 181.328 332.672 182 333.5 182V182C334.328 182 335 181.328 335 180.5V164V160.5L335 160.286C335 159.576 334.424 159 333.714 159V159C333.677 159 333.64 159.001 333.604 159.004C333.569 159.001 333.535 159 333.5 159Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M404.5 273.114L340.575 160.999L404.5 47.9008L404.5 273.114Z" stroke="white" />
        <path d="M404.424 274.5L372.5 49.5584L340.576 274.5L404.424 274.5Z" stroke="white" />
        <defs>
          <filter
            id="4-5-filter0_d"
            x="415.12"
            y="251.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-5-filter1_d"
            x="415.12"
            y="43.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-5-filter2_d"
            x="323.3"
            y="29.3003"
            width="100.279"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-5-filter3_i"
            x="339"
            y="45"
            width="67.9399"
            height="233.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-5-filter4_d"
            x="328.822"
            y="153.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-5-filter5_d"
            x="330.12"
            y="157.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="423.8"
            y1="261.625"
            x2="416.2"
            y2="261.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="425.5"
            y1="273.604"
            x2="416"
            y2="273.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="423.8"
            y1="62.5"
            x2="416.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="414.202"
            y1="47.5"
            x2="421.8"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="423.798"
            y1="62.5"
            x2="416.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
