import React from 'react'
import styled from 'styled-components'

export const InfoTooltipWrapper = styled.div`
  font-size: 0;
  line-height: 0;
`

export const InfoTooltip = ({ color = '#D69A2D' }) => {
  return (
    <InfoTooltipWrapper>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="8" cy="8" r="8" fill={color} />
        <path
          d="M7.16269 5.67386C6.91653 5.46053 6.79346 5.16514 6.79346 4.78771C6.79346 4.41027 6.91243 4.11899 7.15038 3.91386C7.39653 3.70053 7.68782 3.59796 8.02423 3.60617C8.35243 3.61437 8.63141 3.72104 8.86115 3.92617C9.0991 4.1313 9.21807 4.41848 9.21807 4.78771C9.21807 5.15694 9.0991 5.44822 8.86115 5.66155C8.63141 5.86668 8.35243 5.97335 8.02423 5.98155C7.69602 5.98976 7.40884 5.88719 7.16269 5.67386ZM9.04577 7V12.3077H6.95346L6.96576 7H9.04577Z"
          fill="white"
        />
      </svg>
    </InfoTooltipWrapper>
  )
}
