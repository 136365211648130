import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement12Square53Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#1-2_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.0005 115C57.1048 115 58 114.105 58 113.001C58 111.896 57.1048 111.001 56.0005 111.001L53.8335 111.001L53.8335 111.001L43 111.001L43 111L39.9995 111C38.8952 111 38 111.895 38 112.999C38 114.104 38.8952 114.999 39.9995 114.999L42.1668 114.999L42.1668 115L53 115L53 115L56.0005 115Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 53.834 115)"
          fill="#ABABAB"
        />
        <path
          d="M57.0601 115C57.5792 115 58 114.579 58 114.06L58 111.941C58 111.422 57.5792 111.001 57.0601 111.001L53 111.001L53 115L57.0601 115Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M38.9399 111C38.4208 111 38 111.421 38 111.94L38 114.059C38 114.578 38.4208 114.999 38.9399 114.999L43 114.999L43 111L38.9399 111Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 52.167 115)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#1-2_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M211.001 115C212.105 115 213 114.105 213 113.001C213 111.896 212.105 111.001 211.001 111.001L208.833 111.001L208.833 111.001L198 111.001L198 111L194.999 111C193.895 111 193 111.895 193 112.999C193 114.104 193.895 114.999 194.999 114.999L197.167 114.999L197.167 115L208 115L208 115L211.001 115Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 208.834 115)"
          fill="#ABABAB"
        />
        <path
          d="M212.06 115C212.579 115 213 114.579 213 114.06L213 111.941C213 111.422 212.579 111.001 212.06 111.001L208 111.001L208 115L212.06 115Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M193.94 111C193.421 111 193 111.421 193 111.94L193 114.059C193 114.578 193.421 114.999 193.94 114.999L198 114.999L198 111L193.94 111Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 207.167 115)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#1-2_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M219 34H32V111H219V34ZM208 45H43V100H208V45Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#1-2_filter3_i)">
          <rect
            x="43.47"
            y="45.47"
            width="164.06"
            height="54.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="37.5" y="39.5" width="176" height="66" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M219 34H32V111H33V35H218V111H219V34Z"
          fill="#FBFBFB"
        />
        <path d="M125.5 46.5964L45.686 98.5L205.314 98.5L125.5 46.5964Z" stroke="white" />
        <rect
          x="132.528"
          y="36"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 132.528 36)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#1-2_filter4_d)">
          <rect
            x="131.884"
            y="36.7012"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 131.884 36.7012)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M131.767 38.6944C131.42 37.5413 130.351 36.7012 129.085 36.7012L122.445 36.7012C120.898 36.7012 119.645 37.9548 119.645 39.5012C119.645 39.5363 119.645 39.5713 119.647 39.6062C119.993 40.7593 121.063 41.5994 122.329 41.5994L128.969 41.5994C130.515 41.5994 131.769 40.3458 131.769 38.7994C131.769 38.7642 131.768 38.7292 131.767 38.6944Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M130.409 37.0339C130.266 37.0112 130.118 36.9994 129.968 36.9994L123.328 36.9994C121.782 36.9994 120.528 38.253 120.528 39.7994C120.528 40.8664 121.125 41.7941 122.003 42.2666C120.666 42.0551 119.644 40.8975 119.644 39.5012C119.644 37.9548 120.898 36.7012 122.444 36.7012L129.084 36.7012C129.564 36.7012 130.015 36.8216 130.409 37.0339Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-2_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M145.528 39.5C145.528 38.6716 144.857 38 144.028 38L143.814 38L142.528 38L124.028 38C123.2 38 122.528 38.6716 122.528 39.5V39.5C122.528 40.3284 123.2 41 124.028 41L140.528 41L144.028 41L144.243 41C144.953 41 145.528 40.4244 145.528 39.7143V39.7143C145.528 39.6771 145.527 39.6401 145.525 39.6035C145.527 39.5693 145.528 39.5348 145.528 39.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="1-2_filter0_d"
            x="36.1201"
            y="109.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-2_filter1_d"
            x="191.12"
            y="109.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-2_filter2_d"
            x="27.3003"
            y="29.3003"
            width="198.279"
            height="88.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-2_filter3_i"
            x="43"
            y="45"
            width="165.94"
            height="57.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="1-2_filter4_d"
            x="117.764"
            y="34.8213"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-2_filter5_d"
            x="121.649"
            y="36.1201"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="40.5"
            y1="117.8"
            x2="40.5"
            y2="110.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="55.5"
            y1="108.202"
            x2="55.5"
            y2="115.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="40.5"
            y1="117.798"
            x2="40.5"
            y2="110.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="195.5"
            y1="117.8"
            x2="195.5"
            y2="110.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="210.5"
            y1="108.202"
            x2="210.5"
            y2="115.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="195.5"
            y1="117.798"
            x2="195.5"
            y2="110.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
