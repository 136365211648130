import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement63Square3Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#6-3_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M304.999 118C303.895 118 303 118.895 303 119.999C303 121.104 303.895 121.999 304.999 121.999L307.167 121.999L307.167 121.999L318 121.999L318 122L321.001 122C322.105 122 323 121.105 323 120.001C323 118.896 322.105 118.001 321.001 118.001L318.833 118.001L318.833 118L308 118L308 118L304.999 118Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 307.167 118)"
          fill="#ABABAB"
        />
        <path
          d="M303.94 118C303.421 118 303 118.421 303 118.94L303 121.059C303 121.578 303.421 121.999 303.94 121.999L308 121.999L308 118L303.94 118Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M322.06 122C322.579 122 323 121.579 323 121.06L323 118.941C323 118.422 322.579 118.001 322.06 118.001L318 118.001L318 122L322.06 122Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 308.833 118)"
          fill="url(#paint6-3_linear)"
        />
        <g filter="url(#6-3_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M392.999 118C391.895 118 391 118.895 391 119.999C391 121.104 391.895 121.999 392.999 121.999L395.167 121.999L395.167 121.999L406 121.999L406 122L409.001 122C410.105 122 411 121.105 411 120.001C411 118.896 410.105 118.001 409.001 118.001L406.833 118.001L406.833 118L396 118L396 118L392.999 118Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 395.167 118)"
          fill="#ABABAB"
        />
        <path
          d="M391.94 118C391.421 118 391 118.421 391 118.94L391 121.059C391 121.578 391.421 121.999 391.94 121.999L396 121.999L396 118L391.94 118Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M410.06 122C410.579 122 411 121.579 411 121.06L411 118.941C411 118.422 410.579 118.001 410.06 118.001L406 118.001L406 122L410.06 122Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 396.833 118)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#6-3_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 122H297V199H417V122ZM406 133H308V188H406V133Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#6-3_filter6-3_i)">
          <rect
            x="308.47"
            y="133.47"
            width="97.0601"
            height="54.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="302.5" y="127.5" width="109" height="66" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 122H297V199H298V123H416V199H417V122Z"
          fill="#FBFBFB"
        />
        <path d="M310.127 134.5H403.873L357 186.255L310.127 134.5Z" stroke="white" />
        <rect
          x="363"
          y="190"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 363 190)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#6-3_filter4_d)">
          <rect
            x="362.356"
            y="190.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 362.356 190.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M362.238 192.695C361.891 191.542 360.822 190.702 359.556 190.702L352.916 190.702C351.369 190.702 350.116 191.956 350.116 193.502C350.116 193.537 350.117 193.572 350.118 193.607C350.464 194.76 351.534 195.6 352.8 195.6L359.44 195.6C360.986 195.6 362.24 194.347 362.24 192.8C362.24 192.765 362.239 192.73 362.238 192.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M360.881 191.035C360.737 191.012 360.59 191 360.44 191L353.8 191C352.254 191 351 192.254 351 193.8C351 194.867 351.597 195.795 352.475 196.268C351.138 196.056 350.116 194.898 350.116 193.502C350.116 191.956 351.37 190.702 352.916 190.702L359.556 190.702C360.035 190.702 360.486 190.823 360.881 191.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#6-3_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M359 193.5C359 192.672 358.328 192 357.5 192L357.286 192L356 192L337.5 192C336.672 192 336 192.672 336 193.5C336 194.328 336.672 195 337.5 195L354 195L357.5 195L357.714 195C358.424 195 359 194.424 359 193.714C359 193.677 358.999 193.64 358.996 193.604C358.999 193.569 359 193.535 359 193.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="6-3_filter0_d"
            x="301.12"
            y="116.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-3_filter1_d"
            x="389.12"
            y="116.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-3_filter2_d"
            x="292.3"
            y="117.3"
            width="131.279"
            height="88.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-3_filter6-3_i"
            x="308"
            y="133"
            width="98.9399"
            height="57.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="6-3_filter4_d"
            x="348.236"
            y="188.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-3_filter5_d"
            x="334.12"
            y="190.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="320.5"
            y1="115.2"
            x2="320.5"
            y2="122.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="305.5"
            y1="124.798"
            x2="305.5"
            y2="117.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="320.5"
            y1="115.202"
            x2="320.5"
            y2="122.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6-3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="408.5"
            y1="115.2"
            x2="408.5"
            y2="122.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="393.5"
            y1="124.798"
            x2="393.5"
            y2="117.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="408.5"
            y1="115.202"
            x2="408.5"
            y2="122.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
