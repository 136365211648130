import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement72Square8Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#7-2_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M236.999 286C235.895 286 235 286.895 235 287.999C235 289.104 235.895 289.999 236.999 289.999L239.167 289.999L239.167 289.999L250 289.999L250 290L253.001 290C254.105 290 255 289.105 255 288.001C255 286.896 254.105 286.001 253.001 286.001L250.833 286.001L250.833 286L240 286L240 286L236.999 286Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 239.167 286)"
          fill="#ABABAB"
        />
        <path
          d="M235.94 286C235.421 286 235 286.421 235 286.94L235 289.059C235 289.578 235.421 289.999 235.94 289.999L240 289.999L240 286L235.94 286Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M254.06 290C254.579 290 255 289.579 255 289.06L255 286.941C255 286.422 254.579 286.001 254.06 286.001L250 286.001L250 290L254.06 290Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 240.833 286)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#7-2_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M296.999 286C295.895 286 295 286.895 295 287.999C295 289.104 295.895 289.999 296.999 289.999L299.167 289.999L299.167 289.999L310 289.999L310 290L313.001 290C314.105 290 315 289.105 315 288.001C315 286.896 314.105 286.001 313.001 286.001L310.833 286.001L310.833 286L300 286L300 286L296.999 286Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 299.167 286)"
          fill="#ABABAB"
        />
        <path
          d="M295.94 286C295.421 286 295 286.421 295 286.94L295 289.059C295 289.578 295.421 289.999 295.94 289.999L300 289.999L300 286L295.94 286Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M314.06 290C314.579 290 315 289.579 315 289.06L315 286.941C315 286.422 314.579 286.001 314.06 286.001L310 286.001L310 290L314.06 290Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 300.833 286)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#7-2_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M319 165H230V286H319V165ZM308 176H241V275H308V176Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M319 165H230V286H231.219V166H317.781V286H319V165Z"
          fill="#FBFBFB"
        />
        <g filter="url(#7-2_filter3_i)">
          <rect
            x="241.47"
            y="176.47"
            width="66.0601"
            height="98.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="234.53"
          y="169.53"
          width="79.9399"
          height="111.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="281.528"
          y="167"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 281.528 167)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#7-2_filter4_d)">
          <rect
            x="280.884"
            y="167.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 280.884 167.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M280.766 169.695C280.42 168.542 279.35 167.702 278.084 167.702L271.444 167.702C269.898 167.702 268.644 168.956 268.644 170.502C268.644 170.537 268.645 170.572 268.646 170.607C268.993 171.76 270.062 172.6 271.328 172.6L277.968 172.6C279.515 172.6 280.768 171.346 280.768 169.8C280.768 169.765 280.768 169.73 280.766 169.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M279.409 168.035C279.266 168.012 279.118 168 278.968 168L272.328 168C270.782 168 269.528 169.254 269.528 170.8C269.528 171.867 270.125 172.795 271.003 173.267C269.666 173.056 268.644 171.898 268.644 170.502C268.644 168.956 269.898 167.702 271.444 167.702L278.084 167.702C278.564 167.702 279.015 167.822 279.409 168.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#7-2_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M294.528 170.5C294.528 169.672 293.857 169 293.028 169L292.814 169L291.528 169L273.028 169C272.2 169 271.528 169.672 271.528 170.5V170.5C271.528 171.328 272.2 172 273.028 172L289.528 172L293.028 172L293.243 172C293.953 172 294.528 171.424 294.528 170.714V170.714C294.528 170.677 294.527 170.64 294.525 170.604C294.527 170.569 294.528 170.535 294.528 170.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M242.695 273.5L274.5 178.574L306.305 273.5L242.695 273.5Z" stroke="white" />
        <defs>
          <filter
            id="7-2_filter0_d"
            x="233.12"
            y="284.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7-2_filter1_d"
            x="293.12"
            y="284.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7-2_filter2_d"
            x="225.3"
            y="160.3"
            width="100.279"
            height="132.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7-2_filter3_i"
            x="241"
            y="176"
            width="67.9399"
            height="101.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="7-2_filter4_d"
            x="266.764"
            y="165.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7-2_filter5_d"
            x="270.649"
            y="167.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="252.5"
            y1="283.2"
            x2="252.5"
            y2="290.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="237.5"
            y1="292.798"
            x2="237.5"
            y2="285.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="252.5"
            y1="283.202"
            x2="252.5"
            y2="290.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="312.5"
            y1="283.2"
            x2="312.5"
            y2="290.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="297.5"
            y1="292.798"
            x2="297.5"
            y2="285.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="312.5"
            y1="283.202"
            x2="312.5"
            y2="290.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
