import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement52Square5VerticalCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#5-2_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M50.0005 254C51.1048 254 52 253.105 52 252.001C52 250.896 51.1048 250.001 50.0005 250.001L47.8335 250.001L47.8335 250.001L37 250.001L37 250L33.9995 250C32.8952 250 32 250.895 32 251.999C32 253.104 32.8952 253.999 33.9995 253.999L36.1668 253.999L36.1668 254L47 254L47 254L50.0005 254Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 47.8335 254)"
          fill="#ABABAB"
        />
        <path
          d="M51.0601 254C51.5792 254 52 253.579 52 253.06L52 250.941C52 250.422 51.5792 250.001 51.0601 250.001L47 250.001L47 254L51.0601 254Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M32.9399 250C32.4208 250 32 250.421 32 250.94L32 253.059C32 253.578 32.4208 253.999 32.9399 253.999L37 253.999L37 250L32.9399 250Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 46.1666 254)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#5-2_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M186.001 254C187.105 254 188 253.105 188 252.001C188 250.896 187.105 250.001 186.001 250.001L183.833 250.001L183.833 250.001L173 250.001L173 250L169.999 250C168.895 250 168 250.895 168 251.999C168 253.104 168.895 253.999 169.999 253.999L172.167 253.999L172.167 254L183 254L183 254L186.001 254Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 183.833 254)"
          fill="#ABABAB"
        />
        <path
          d="M187.06 254C187.579 254 188 253.579 188 253.06L188 250.941C188 250.422 187.579 250.001 187.06 250.001L183 250.001L183 254L187.06 254Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M168.94 250C168.421 250 168 250.421 168 250.94L168 253.059C168 253.578 168.421 253.999 168.94 253.999L173 253.999L173 250L168.94 250Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 182.167 254)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#5-2_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M192 254H28V300H192V254ZM183 263H37V291H183V263Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M192 254H28V300H29V255H191V300H192V254Z"
          fill="#FBFBFB"
        />
        <g filter="url(#5-2_filter3_i)">
          <rect
            x="37.47"
            y="263.47"
            width="145.06"
            height="27.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="32.53"
          y="258.53"
          width="154.94"
          height="36.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="117"
          y="292"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 117 292)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="116.356"
          y="292.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 116.356 292.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M116.238 294.695C115.892 293.542 114.822 292.702 113.556 292.702L106.916 292.702C105.37 292.702 104.116 293.955 104.116 295.502C104.116 295.537 104.117 295.572 104.118 295.607C104.464 296.76 105.534 297.6 106.8 297.6L113.44 297.6C114.986 297.6 116.24 296.346 116.24 294.8C116.24 294.765 116.239 294.73 116.238 294.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M114.881 293.034C114.737 293.012 114.59 293 114.44 293L107.8 293C106.254 293 105 294.253 105 295.8C105 296.867 105.597 297.795 106.475 298.267C105.138 298.056 104.116 296.898 104.116 295.502C104.116 293.955 105.37 292.702 106.916 292.702L113.556 292.702C114.035 292.702 114.487 292.822 114.881 293.034Z"
          fill="#FBFBFB"
        />
        <g filter="url(#5-2_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M113 295.5C113 294.672 112.328 294 111.5 294L111.286 294L110 294L91.5 294C90.6716 294 90 294.672 90 295.5V295.5C90 296.328 90.6716 297 91.5 297L108 297L111.5 297L111.714 297C112.424 297 113 296.424 113 295.714V295.714C113 295.677 112.999 295.64 112.996 295.604C112.999 295.569 113 295.535 113 295.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M179.143 264.5L110 289.468L40.8567 264.5H179.143Z" stroke="white" />
        <defs>
          <filter
            id="5-2_filter0_d"
            x="30.1201"
            y="248.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-2_filter1_d"
            x="166.12"
            y="248.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-2_filter2_d"
            x="23.3003"
            y="249.3"
            width="175.279"
            height="57.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-2_filter3_i"
            x="37"
            y="263"
            width="146.94"
            height="30.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-2_filter4_d"
            x="88.28"
            y="292.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="34.5"
            y1="256.8"
            x2="34.5"
            y2="249.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="49.5"
            y1="247.202"
            x2="49.5"
            y2="254.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="34.5"
            y1="256.798"
            x2="34.5"
            y2="249.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="170.5"
            y1="256.8"
            x2="170.5"
            y2="249.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="185.5"
            y1="247.202"
            x2="185.5"
            y2="254.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="170.5"
            y1="256.798"
            x2="170.5"
            y2="249.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
