import React, { FC, useState, useCallback, useEffect, useMemo } from 'react'

import { Input, Checkbox, PhoneInput, Button } from '../../../components'
import PassportInput from '../../../components/passport-input/index'
import {
  InputTitle,
  StageOrderBlockItems,
  StageOrderBlockTitle,
  StageOrderBlockWrapper,
  StageOrderGroup,
  StageOrderRecipientDescription,
} from './styles'
import { IRecipientProps } from '../interface'
import { InfoTooltip } from '../../../assets/icons'
// import fetchAPI from 'src/lib/utils/fetch-api'

export const Recipient: FC<IRecipientProps> = props => {
  const { onChange, profileData, recipient } = props

  const [recipientData, setRecipientData] = useState<Record<string, any>>({})
  const [otherRecipient, setOtherRecipient] = useState(false)
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [profile, setProfile] = useState<Record<string, string>>({})
  const [isValidEmailCode, setValidEmailCode] = useState(true) // set false
  const [localeEmail, setLocaleEmail] = useState('')
  const [valuePassportNumber, setPassportNumber] = useState('')
  const [passportIssued, setPassportIssued] = useState('')

  const isValidEmail = useMemo(() => {
    if (isValidEmailCode) {
      return true
    }
    if(profile?.['email']) {
      return true
    }
    return false
  }, [profile, isValidEmailCode])

  useEffect(() => {
    const nextData = { ...recipientData, ...profileData, localeEmail, valuePassportNumber, passportIssued, isValidEmail }
    
    if (isFirstRender) {
      setIsFirstRender(false)
    } else {
      onChange(nextData)
    }
    //console.log({ recipientData })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipientData, localeEmail, valuePassportNumber, passportIssued, isValidEmail])

  useEffect(() => {
    if (profileData && profileData['profile-data']) {
      setProfile(profileData['profile-data'])
      const passportNumber = profileData?.['profile-data']?.['passport-number']
      passportNumber && setPassportNumber(passportNumber)
    }
  }, [profileData])

  useEffect(() => {
    if (recipient && Object.keys(recipient).length > 0 && Object.keys(recipientData).length === 0) {
      setRecipientData(recipient)
      setOtherRecipient(true)
    }
  }, [recipient, recipientData])

  const handleChange = useCallback((value, key) => {
    setRecipientData(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }, [])

  const handleSelect = useCallback(value => {
    setOtherRecipient(value.value)
  }, [])

  const otherRecipientSelected = useMemo(() => {
    return otherRecipient
      ? {
          name: 'other-recipient',
          value: true,
          title: 'Заказ будет принимать другой человек',
          attributes: [],
        }
      : null
  }, [otherRecipient])


  return (
    <StageOrderBlockWrapper>
      <StageOrderBlockTitle>Получатель</StageOrderBlockTitle>
      <div style={{ display: 'flex', flexDirection: 'row', 'gap': 10, marginBottom: 15 }}>
        <InfoTooltip />
        <span>
          Поля, отмеченные звёздочками, обязательны к заполнению
        </span>
      </div>
      <StageOrderBlockItems>
        <StageOrderGroup>
          <Input
            req={true}
            onChange={handleChange}
            id="last-name"
            name="last-name"
            placeholder="Введите фамилию"
            value={profile['last-name']}
          />
          <InputTitle>Фамилия</InputTitle>
        </StageOrderGroup>
        <StageOrderGroup>
          <Input
            req={true}
            onChange={handleChange}
            id="first-name"
            name="first-name"
            placeholder="Введите имя"
            value={profile['first-name']}
          />
          <InputTitle>Имя</InputTitle>
        </StageOrderGroup>
        <StageOrderGroup>
          <PhoneInput
            id="phone"
            name="phone"
            placeholder="Введите номер телефона"
            value={profile['phone']}
            disabled
          />
          <InputTitle>Номер телефона</InputTitle>
        </StageOrderGroup>
        <StageOrderGroup>
          <Input
            req={true}
            id="recipient"
            name="email"
            placeholder="Введите email"
            value={profile['email'] || localeEmail}
            onChange={(value) => setLocaleEmail(value as string)}
          />
          <InputTitle>Email</InputTitle>
        </StageOrderGroup>

        <StageOrderGroup>
           <PassportInput
              req={true}
              value={valuePassportNumber}
              onChange={(value) => setPassportNumber(value as string)}
              id="passport-number"
              name="passport-number"
          />
          <InputTitle>Серия и номер паспорта</InputTitle>
        </StageOrderGroup>
        <StageOrderGroup>
          <Input
              req={true}
              value={profile['passport-issued']}
              onChange={(value) => setPassportIssued(value as string)}
              id="passport-issued"
              name="passport-issued"
              placeholder="Кем выдан паспорт"
          />
          <InputTitle>Кем выдан паспорт</InputTitle>
        </StageOrderGroup>
      </StageOrderBlockItems>
      <StageOrderRecipientDescription>
        <InfoTooltip />
        <span>
          При оплате покупки на сайте, пожалуйста, укажите ФИО получателя. Если товар будет забирать
          другой человек (не заказчик) – укажите его ФИО. Заказы выдаются при предъявлении любого
          документа, удостоверяющего личность, или банковской карты, с которой оплачен заказ.
        </span>
      </StageOrderRecipientDescription>
      <Checkbox
        items={[
          {
            name: 'other-recipient',
            value: true,
            title: 'Заказ будет принимать другой человек',
            attributes: [],
          },
        ]}
        onSelect={handleSelect}
        selected={otherRecipientSelected}
        id="other-recipient"
      />
      {otherRecipient && (
        <StageOrderBlockItems>
          <StageOrderGroup>
            <Input
              onChange={handleChange}
              id="other-recipient-last-name"
              name="other-recipient-last-name"
              placeholder="Введите фамилию"
              value={recipientData['other-recipient-last-name']}
            />
          </StageOrderGroup>
          <StageOrderGroup>
            <Input
              onChange={handleChange}
              id="other-recipient-first-name"
              name="other-recipient-first-name"
              placeholder="Введите имя"
              value={recipientData['other-recipient-first-name']}
            />
          </StageOrderGroup>
          <StageOrderGroup>
            <PhoneInput
              id="other-recipient-phone"
              name="other-recipient-phone"
              placeholder="Введите номер телефона"
              onChange={handleChange}
              value={recipientData['other-recipient-phone']}
            />
          </StageOrderGroup>
        </StageOrderBlockItems>
      )}
    </StageOrderBlockWrapper>
  )
}

export default Recipient

/*
  const onSendEmailCode = useCallback(async () => {
    const phone = '+' + profile?.['phone']?.replace?.(/[\D]/g, '')
    const data = await fetchAPI('web-hooks/send-code-by-email', {
      method: 'POST',
      body: JSON.stringify({ phone, email: localeEmail })
    })
    console.log(data)
  }, [profile, localeEmail])

  const validateEmailCode = useCallback(async (value) => {
    const isValid = Boolean(localeEmail)
    if (value.length === 4 && isValid) {
      const phone = '+' + profile?.['phone']?.replace?.(/[\D]/g, '')
      const data: any = await fetchAPI('web-hooks/is-valid-code-by-email', {
        method: 'POST',
        body: JSON.stringify({ phone, email: localeEmail, code: value })
      })
      .catch(console.warn)
      if (data.status) {
        setValidEmailCode(true)
      }
    }
  }, [profile, localeEmail])
  */

/*
        {isValidEmail || <>
          <StageOrderGroup>
          </StageOrderGroup>
          <StageOrderGroup>
            <Input
              req={true}
              id="email-code"
              name="email-code"
              placeholder="Введите код из e-mail"
              onChange={(a) => validateEmailCode(a)}
            />
          </StageOrderGroup>
          <StageOrderGroup>
          </StageOrderGroup>
          <StageOrderGroup>
            <Button  onClick={onSendEmailCode} disabled={false}>
              {'Получить код'}
            </Button>
          </StageOrderGroup>
        </>}
*/