import React from 'react'

export const TrashIcon = ({ onClick = Promise.reject }: {onClick?: any }) => {
  return (
    <svg style={{
      shapeRendering: 'geometricPrecision',
      textRendering: 'geometricPrecision',
      pointerEvents: 'all'
    }} onClick={onClick} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.4998 6.5V12.6252C3.4998 13.3849 4.17079 14 4.9996 14H10.9996C11.8292 14 12.5002 13.3849 12.5002 12.6252V6.5H13.5909C13.8142 6.5 14 6.32971 14 6.125V4.87476C14 4.11505 13.329 3.5 12.5002 3.5H10.25V2.99976C10.25 2.45007 9.75899 2 9.15931 2H6.875C6.27532 2 5.78431 2.45007 5.78431 2.99976V3.5H3.4998C2.67099 3.5 2 4.11505 2 4.87476V6.125C2 6.32971 2.18578 6.5 2.40911 6.5H3.4998ZM6.875 2.75C6.66789 2.75 6.5 2.91789 6.5 3.125V3.5H9.5V3.125C9.5 2.91789 9.33211 2.75 9.125 2.75H6.875Z"
        fill="#1C3775"
      />
    </svg>
  )
}
