import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement23Square5Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-3_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M164 46.9995C164 45.8952 163.105 45 162.001 45C160.896 45 160.001 45.8952 160.001 46.9995V49.1665H160.001V60H160V63.0005C160 64.1048 160.895 65 161.999 65C163.104 65 163.999 64.1048 163.999 63.0005V60.8332H164V50H164V46.9995Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 164 49.166)"
          fill="#ABABAB"
        />
        <path
          d="M164 45.9399C164 45.4208 163.579 45 163.06 45H160.941C160.422 45 160.001 45.4208 160.001 45.9399V50H164V45.9399Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M160 64.0601C160 64.5792 160.421 65 160.94 65H163.059C163.578 65 163.999 64.5792 163.999 64.0601V60H160V64.0601Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 164 50.833)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#2-3_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M164 255C164 253.895 163.105 253 162 253C160.895 253 160 253.895 160 255V269.56H164V255ZM164 269.56H160V271.4H159.94C159.421 271.4 159 271.821 159 272.34V274.12C159 275.158 159.842 276 160.88 276H162.12C163.158 276 164 275.158 164 274.12V271.4V271.4V269.56Z"
            fill="white"
          />
        </g>
        <rect
          width="3.99998"
          height="0.95833"
          transform="matrix(-1 0 0 1 164 270.25)"
          fill="#ABABAB"
        />
        <path
          d="M164 253.94C164 253.421 163.579 253 163.06 253H160.94C160.421 253 160 253.421 160 253.94V270.25H164V253.94Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M164 275.06C164 275.579 163.579 276 163.06 276H159.94C159.421 276 159 275.579 159 275.06V272.148C159 271.629 159.421 271.208 159.94 271.208H164V275.06Z"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#2-3_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M284 34H164V287H284V34ZM273 45H175V276H273V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M284 34H164V287L164.887 287V34.937H283.109V287L284 287V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="168.53"
          y="38.53"
          width="110.94"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#2-3_filter3_i)">
          <rect
            x="175.47"
            y="45.47"
            width="97.0601"
            height="230.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M270.801 160.5L176.895 48.4975L176.895 272.503L270.801 160.5ZM272 160.5L176 46L176 275L272 160.5Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M224 46L176 275H272L224 46ZM224 50.3351L177.107 274.055H270.893L224 50.3351Z"
          fill="white"
        />
        <rect
          width="7"
          height="13.5283"
          rx="3.5"
          transform="matrix(-1 0 0 1 282 154)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#2-3_filter4_d)">
          <rect
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="matrix(-1 0 0 1 281.298 154.645)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M280.714 165.796C280.202 166.458 279.4 166.884 278.498 166.884C276.951 166.884 275.698 165.631 275.698 164.084V157.444C275.698 156.456 276.21 155.587 276.983 155.089C276.617 155.562 276.399 156.156 276.399 156.8V163.44C276.399 164.987 277.653 166.24 279.199 166.24C279.757 166.24 280.277 166.077 280.714 165.796Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M278.498 154.645C280.044 154.645 281.298 155.898 281.298 157.445V163.44C281.298 163.461 281.298 163.481 281.297 163.501V158.024C281.297 156.478 280.044 155.224 278.497 155.224C276.971 155.224 275.73 156.445 275.698 157.963V157.445C275.698 155.898 276.951 154.645 278.498 154.645Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-3_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M278.5 158C279.328 158 280 158.672 280 159.5V159.714V161V179.5C280 180.328 279.328 181 278.5 181C277.672 181 277 180.328 277 179.5V163V159.5L277 159.286C277 158.576 277.576 158 278.286 158C278.323 158 278.36 158.001 278.396 158.004C278.431 158.001 278.465 158 278.5 158Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="2-3_filter0_d"
            x="158.12"
            y="43.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-3_filter1_d"
            x="157.12"
            y="251.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-3_filter2_d"
            x="159.3"
            y="29.3003"
            width="131.279"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-3_filter3_i"
            x="175"
            y="45"
            width="98.9399"
            height="233.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-3_filter4_d"
            x="273.818"
            y="152.765"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-3_filter5_d"
            x="275.12"
            y="156.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="166.8"
            y1="62.5"
            x2="159.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="157.202"
            y1="47.5"
            x2="164.8"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="166.798"
            y1="62.5"
            x2="159.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="157.2"
            y1="261.625"
            x2="164.8"
            y2="261.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="155.5"
            y1="273.604"
            x2="165"
            y2="273.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
