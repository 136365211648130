import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement51Square54Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#5-1_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M348 256C348 254.895 347.105 254 346 254C344.895 254 344 254.895 344 256V270.56H348V256ZM348 270.56H344V272.4H343.94C343.421 272.4 343 272.821 343 273.34V275.12C343 276.158 343.842 277 344.88 277H346.12C347.158 277 348 276.158 348 275.12V272.4V272.4V270.56Z"
            fill="white"
          />
        </g>
        <rect
          width="3.99998"
          height="0.95833"
          transform="matrix(-1 0 0 1 348 271.25)"
          fill="#ABABAB"
        />
        <path
          d="M348 254.94C348 254.421 347.579 254 347.06 254H344.94C344.421 254 344 254.421 344 254.94V271.25H348V254.94Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M348 276.06C348 276.579 347.579 277 347.06 277H343.94C343.421 277 343 276.579 343 276.06V273.148C343 272.629 343.421 272.208 343.94 272.208H348V276.06Z"
          fill="url(#paint1_linear)"
        />
        <g filter="url(#5-1_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M348 45.9995C348 44.8952 347.105 44 346.001 44C344.896 44 344.001 44.8952 344.001 45.9995V48.1665H344.001V59H344V62.0005C344 63.1048 344.895 64 345.999 64C347.104 64 347.999 63.1048 347.999 62.0005V59.8332H348V49H348V45.9995Z"
            fill="url(#paint2_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 348 48.1665)"
          fill="#ABABAB"
        />
        <path
          d="M348 44.9399C348 44.4208 347.579 44 347.06 44H344.941C344.422 44 344.001 44.4208 344.001 44.9399V49H348V44.9399Z"
          fill="url(#paint3_linear)"
        />
        <path
          d="M344 63.0601C344 63.5792 344.421 64 344.94 64H347.059C347.578 64 347.999 63.5792 347.999 63.0601V59H344V63.0601Z"
          fill="url(#paint4_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 348 49.8335)"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#5-1_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M416 34H348V287H416V34ZM406 44H358V277H406V44Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M416 34H348V287H348.932V35H415.068V287H416V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#5-1_filter3_i)">
          <rect
            x="358.47"
            y="44.47"
            width="47.0601"
            height="232.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="352.53"
          y="38.53"
          width="58.9399"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="407"
          y="154"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#5-1_filter4_d)">
          <rect x="407.702" y="154.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M408.286 165.795C408.798 166.457 409.6 166.884 410.502 166.884C412.048 166.884 413.302 165.63 413.302 164.084V157.444C413.302 156.455 412.79 155.587 412.016 155.088C412.382 155.562 412.6 156.155 412.6 156.8V163.44C412.6 164.986 411.347 166.24 409.8 166.24C409.242 166.24 408.722 166.077 408.286 165.795Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M410.502 154.644C408.956 154.644 407.702 155.898 407.702 157.444V163.44C407.702 163.46 407.702 163.48 407.703 163.501V158.024C407.703 156.477 408.956 155.224 410.503 155.224C412.029 155.224 413.269 156.444 413.302 157.963V157.444C413.302 155.898 412.048 154.644 410.502 154.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#5-1_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M410.5 158C409.672 158 409 158.672 409 159.5V159.714V161V179.5C409 180.328 409.672 181 410.5 181V181C411.328 181 412 180.328 412 179.5V163V159.5L412 159.286C412 158.576 411.424 158 410.714 158V158C410.677 158 410.64 158.001 410.604 158.004C410.569 158.001 410.535 158 410.5 158Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M359.5 273.393L404.462 160.5L359.5 47.6068L359.5 273.393Z" stroke="white" />
        <path d="M404.448 275.5L382 50.0466L359.552 275.5L404.448 275.5Z" stroke="white" />
        <defs>
          <filter
            id="5-1_filter0_d"
            x="341.12"
            y="252.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-1_filter1_d"
            x="342.12"
            y="42.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-1_filter2_d"
            x="343.3"
            y="29.3003"
            width="79.2793"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-1_filter3_i"
            x="358"
            y="44"
            width="48.9399"
            height="235.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-1_filter4_d"
            x="405.822"
            y="152.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-1_filter5_d"
            x="407.12"
            y="156.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="341.2"
            y1="262.625"
            x2="348.8"
            y2="262.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="339.5"
            y1="274.604"
            x2="349"
            y2="274.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="350.8"
            y1="61.5"
            x2="343.2"
            y2="61.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="341.202"
            y1="46.5"
            x2="348.8"
            y2="46.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="350.798"
            y1="61.5"
            x2="343.2"
            y2="61.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
