import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement1Door1LCasement1 = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="451"
        height="451"
        viewBox="0 0 451 451"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#door-1-l-casement-1-1-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M422.954 46.9765C422.954 45.8849 422.069 45 420.978 45C419.886 45 419.001 45.8849 419.001 46.9765V49.1665H419.001V60H419V63.0235C419 64.1151 419.885 65 420.976 65C422.068 65 422.953 64.1151 422.953 63.0235V60.8332H422.954V50H422.954V46.9765Z"
            fill="url(#door-1-l-casement-1-1-paint0_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-1 0 0 1 422.954 49.1665)"
          fill="#ABABAB"
        />
        <path
          d="M422.954 45.9399C422.954 45.4208 422.533 45 422.014 45H419.941C419.422 45 419.001 45.4208 419.001 45.9399V50H422.954V45.9399Z"
          fill="url(#door-1-l-casement-1-1-paint1_linear)"
        />
        <path
          d="M419 64.0601C419 64.5792 419.421 65 419.94 65H422.013C422.532 65 422.953 64.5792 422.953 64.0601V60H419V64.0601Z"
          fill="url(#door-1-l-casement-1-1-paint2_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-1 0 0 1 422.954 50.8334)"
          fill="url(#door-1-l-casement-1-1-paint3_linear)"
        />
        <g filter="url(#door-1-l-casement-1-1-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M422.954 271.976C422.954 270.885 422.069 270 420.977 270C419.886 270 419.001 270.885 419.001 271.976V274.167H419.001V285H419V288.024C419 289.115 419.885 290 420.976 290C422.068 290 422.953 289.115 422.953 288.024V285.833H422.954V275H422.954V271.976Z"
            fill="url(#door-1-l-casement-1-1-paint4_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-1 0 0 1 422.954 274.166)"
          fill="#ABABAB"
        />
        <path
          d="M422.954 270.94C422.954 270.421 422.533 270 422.014 270H419.941C419.422 270 419.001 270.421 419.001 270.94V275H422.954V270.94Z"
          fill="url(#door-1-l-casement-1-1-paint5_linear)"
        />
        <path
          d="M419 289.06C419 289.579 419.421 290 419.94 290H422.013C422.532 290 422.953 289.579 422.953 289.06V285H419V289.06Z"
          fill="url(#door-1-l-casement-1-1-paint6_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-1 0 0 1 422.954 275.833)"
          fill="url(#door-1-l-casement-1-1-paint7_linear)"
        />
        <g filter="url(#door-1-l-casement-1-1-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M419 34H186V301H419V34ZM408 45H197V290H408V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M419 34H186V301H187V35H418V301H419V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="190.53"
          y="38.53"
          width="223.94"
          height="257.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#door-1-l-casement-1-1-filter3_i)">
          <rect
            x="197.47"
            y="45.47"
            width="210.06"
            height="244.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          width="6.91954"
          height="13.5283"
          rx="3.45977"
          transform="matrix(-1 0 0 1 194.92 161)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#door-1-l-casement-1-1-filter4_d)">
          <rect
            width="5.53563"
            height="12.2399"
            rx="2.76782"
            transform="matrix(-1 0 0 1 194.225 161.644)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M193.661 172.792C193.155 173.456 192.356 173.884 191.458 173.884C189.929 173.884 188.69 172.645 188.69 171.116V164.412C188.69 163.44 189.191 162.585 189.948 162.092C189.594 162.557 189.383 163.138 189.383 163.768V170.472C189.383 172.001 190.622 173.24 192.151 173.24C192.708 173.24 193.226 173.075 193.661 172.792Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M191.458 161.644C192.986 161.644 194.225 162.883 194.225 164.412V170.472C194.225 170.492 194.225 170.512 194.225 170.533V164.991C194.225 163.463 192.986 162.224 191.457 162.224C189.949 162.224 188.722 163.43 188.69 164.931V164.412C188.69 162.883 189.929 161.644 191.458 161.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#door-1-l-casement-1-1-filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M191.46 165C192.279 165 192.943 165.664 192.943 166.483V168C192.943 168.014 192.942 168.027 192.942 168.041L192.942 186.517C192.942 187.336 192.278 188 191.459 188C190.641 188 189.977 187.336 189.977 186.517L189.977 166.251C189.977 165.56 190.537 165 191.228 165C191.231 165 191.234 165 191.236 165C191.311 165 191.385 165 191.46 165Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M406.5 288.131V46.869L198.995 167.5L406.5 288.131Z" stroke="white" />
        <defs>
          <filter
            id="door-1-l-casement-1-1-filter0_d"
            x="417.12"
            y="43.1201"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-1-1-filter1_d"
            x="417.12"
            y="268.12"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-1-1-filter2_d"
            x="181.3"
            y="29.3003"
            width="244.279"
            height="278.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-1-1-filter3_i"
            x="197"
            y="45"
            width="211.94"
            height="247.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="door-1-l-casement-1-1-filter4_d"
            x="186.81"
            y="159.764"
            width="11.1753"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-1-1-filter5_d"
            x="188.097"
            y="163.12"
            width="8.60556"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="door-1-l-casement-1-1-paint0_linear"
            x1="425.722"
            y1="62.5"
            x2="418.209"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-1-1-paint1_linear"
            x1="416.234"
            y1="47.5"
            x2="423.745"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-1-1-paint2_linear"
            x1="425.72"
            y1="62.5"
            x2="418.209"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-1-1-paint3_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-1-1-paint4_linear"
            x1="425.722"
            y1="287.5"
            x2="418.209"
            y2="287.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-1-1-paint5_linear"
            x1="416.234"
            y1="272.5"
            x2="423.745"
            y2="272.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-1-1-paint6_linear"
            x1="425.72"
            y1="287.5"
            x2="418.209"
            y2="287.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-1-1-paint7_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
