import styled from 'styled-components'

export const TopBlockWrapper = styled.div`
  
  width: 100%;
  max-width: 1200px;

  padding: 0px 0 0;
`

export const SpreedBlock = styled.div`
  display: flex;
  justify-content: space-between;

  padding-bottom: 10px;
`

export const PageTitle = styled.h3`
  color: #202124;
  font-weight: bold;
  font-size: 30px;
  line-height: 55.2px;
  font-family: 'PT Sans Caption', sans-serif;

  margin: 0;
`

export const YoutubeButtonWrapper = styled.div`
  margin-right: 20px;

  cursor: pointer;

  iframe {
    pointer-events: none;
  }
`

export const DescAlert = styled.p`
font-size: 16px;
color: #000;
`

export const AlertTitle = styled.div`
  font-size: 16px;
  color: #ffffff;

  margin-right: 300px;
  margin-top: 18px;

  span {
    margin-bottom: 20px;

    display: inline-block;
  }
`
