import * as React from 'react'

import { SpinIcon } from '../../assets/icons'

export default function AppLoader() {
  return (
    <div style={{ minHeight: '100vh', background: 'white', padding: 100, textAlign: 'center' }}>
      <SpinIcon />
    </div>
  )
}
