import React, { FC } from 'react'

import { ICheckProps } from '../../views/calculator/interface'
import { CheckWrapper } from '../../views/calculator/styles'

export const CheckIcon: FC<ICheckProps> = props => {
  const { checked, color = '#949494' } = props

  return (
    <CheckWrapper>
      {checked ? (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
            fill={color}
          />
          <path
            d="M7.74686 4.37922C7.91916 4.20693 8.19849 4.20693 8.37078 4.37922C8.54307 4.55151 8.54307 4.83085 8.37078 5.00314L5.72372 7.65019C5.55143 7.82248 5.2721 7.82248 5.09981 7.65019L3.62922 6.17961C3.45693 6.00732 3.45693 5.72798 3.62922 5.55569C3.80151 5.3834 4.08085 5.3834 4.25314 5.55569L5.41176 6.71432L7.74686 4.37922Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
            fill={color}
          />
        </svg>
      )}
    </CheckWrapper>
  )
}
