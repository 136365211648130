import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement13Square54Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#1-3_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M55.0005 291C56.1048 291 57 290.105 57 289.001C57 287.896 56.1048 287.001 55.0005 287.001L52.8335 287.001L52.8335 287.001L42 287.001L42 287L38.9995 287C37.8952 287 37 287.895 37 288.999C37 290.104 37.8952 290.999 38.9995 290.999L41.1668 290.999L41.1668 291L52 291L52 291L55.0005 291Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 52.8335 291)"
          fill="#ABABAB"
        />
        <path
          d="M56.0601 291C56.5792 291 57 290.579 57 290.06L57 287.941C57 287.422 56.5792 287.001 56.0601 287.001L52 287.001L52 291L56.0601 291Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M37.9399 287C37.4208 287 37 287.421 37 287.94L37 290.059C37 290.578 37.4208 290.999 37.9399 290.999L42 290.999L42 287L37.9399 287Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 51.1666 291)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#1-3_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M95.0005 291C96.1048 291 97 290.105 97 289.001C97 287.896 96.1048 287.001 95.0005 287.001L92.8335 287.001L92.8335 287.001L82 287.001L82 287L78.9995 287C77.8952 287 77 287.895 77 288.999C77 290.104 77.8952 290.999 78.9995 290.999L81.1668 290.999L81.1668 291L92 291L92 291L95.0005 291Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 92.8335 291)"
          fill="#ABABAB"
        />
        <path
          d="M96.0601 291C96.5792 291 97 290.579 97 290.06L97 287.941C97 287.422 96.5792 287.001 96.0601 287.001L92 287.001L92 291L96.0601 291Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M77.9399 287C77.4208 287 77 287.421 77 287.94L77 290.059C77 290.578 77.4208 290.999 77.9399 290.999L82 290.999L82 287L77.9399 287Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 91.1666 291)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#1-3_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M101 34H33V287H101V34ZM91 44H43V277H91V44Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M101 34H33V287H33.9315V35H100.068V287H101V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-3_filter3_i)">
          <rect
            x="43.47"
            y="44.47"
            width="47.0601"
            height="232.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="37.53"
          y="38.53"
          width="58.9399"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M89.4477 275.5L67 50.0466L44.5523 275.5L89.4477 275.5Z" stroke="white" />
        <rect
          x="73.5283"
          y="36"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 73.5283 36)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#1-3_filter4_d)">
          <rect
            x="72.8843"
            y="36.7017"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 72.8843 36.7017)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72.7663 38.6948C72.4199 37.5418 71.3502 36.7017 70.0843 36.7017L63.4444 36.7017C61.898 36.7017 60.6444 37.9553 60.6444 39.5017C60.6444 39.5368 60.645 39.5718 60.6463 39.6067C60.9927 40.7598 62.0624 41.5998 63.3283 41.5998L69.9682 41.5998C71.5146 41.5998 72.7682 40.3462 72.7682 38.7998C72.7682 38.7647 72.7676 38.7297 72.7663 38.6948Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.4093 37.0344C71.2656 37.0117 71.1183 36.9998 70.9682 36.9998L64.3283 36.9998C62.7819 36.9998 61.5283 38.2534 61.5283 39.7998C61.5283 40.8669 62.1252 41.7946 63.0033 42.2671C61.6665 42.0556 60.6444 40.898 60.6444 39.5017C60.6444 37.9553 61.898 36.7017 63.4444 36.7017L70.0843 36.7017C70.5636 36.7017 71.0148 36.8221 71.4093 37.0344Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-3_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M86.5283 39.5C86.5283 38.6716 85.8567 38 85.0283 38L84.8141 38L83.5283 38L65.0283 38C64.1999 38 63.5283 38.6716 63.5283 39.5V39.5C63.5283 40.3284 64.1999 41 65.0283 41L81.5283 41L85.0283 41L85.2426 41C85.9527 41 86.5283 40.4244 86.5283 39.7143V39.7143C86.5283 39.6771 86.5271 39.6401 86.5248 39.6035C86.5271 39.5693 86.5283 39.5348 86.5283 39.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="1-3_filter0_d"
            x="35.1201"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-3_filter1_d"
            x="75.1201"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-3_filter2_d"
            x="28.3003"
            y="29.3003"
            width="79.2793"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-3_filter3_i"
            x="43"
            y="44"
            width="48.9399"
            height="235.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="1-3_filter4_d"
            x="58.7645"
            y="34.8218"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-3_filter5_d"
            x="62.6485"
            y="36.1201"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="39.5"
            y1="293.8"
            x2="39.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="54.5"
            y1="284.202"
            x2="54.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="39.5"
            y1="293.798"
            x2="39.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="79.5"
            y1="293.8"
            x2="79.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="94.5"
            y1="284.202"
            x2="94.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="79.5"
            y1="293.798"
            x2="79.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
