import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement22Square2Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="315"
        height="333"
        viewBox="0 0 315 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-2_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M286 46.9995C286 45.8952 285.105 45 284.001 45C282.896 45 282.001 45.8952 282.001 46.9995V49.1665H282.001V60H282V63.0005C282 64.1048 282.895 65 283.999 65C285.104 65 285.999 64.1048 285.999 63.0005V60.8332H286V50H286V46.9995Z"
            fill="url(#2-2_paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 286 49.1665)"
          fill="#ABABAB"
        />
        <path
          d="M286 45.9399C286 45.4208 285.579 45 285.06 45H282.941C282.422 45 282.001 45.4208 282.001 45.9399V50H286V45.9399Z"
          fill="url(#2-2_paint1_linear)"
        />
        <path
          d="M282 64.0601C282 64.5792 282.421 65 282.94 65H285.059C285.578 65 285.999 64.5792 285.999 64.0601V60H282V64.0601Z"
          fill="url(#2-2_paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 286 50.8334)"
          fill="url(#2-2_paint3_linear)"
        />
        <g filter="url(#2-2_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M282 255C282 253.895 282.895 253 284 253C285.105 253 286 253.895 286 255V269.56H282V255ZM282 269.56H286V271.4H286.06C286.579 271.4 287 271.821 287 272.34V274.12C287 275.158 286.158 276 285.12 276H283.88C282.842 276 282 275.158 282 274.12V271.4V271.4V269.56Z"
            fill="white"
          />
        </g>
        <rect x="282" y="270.25" width="3.99998" height="0.95833" fill="#ABABAB" />
        <path
          d="M282 253.94C282 253.421 282.421 253 282.94 253H285.06C285.579 253 286 253.421 286 253.94V270.25H282V253.94Z"
          fill="url(#2-2_paint4_linear)"
        />
        <path
          d="M282 275.06C282 275.579 282.421 276 282.94 276H286.06C286.579 276 287 275.579 287 275.06V272.148C287 271.629 286.579 271.208 286.06 271.208H282V275.06Z"
          fill="url(#2-2_paint5_linear)"
        />
        <g filter="url(#2-2_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M282 34H162V287H282V34ZM271 45H173V276H271V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M282 34H162V287L162.887 287V34.937H281.109V287L282 287V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="166.53"
          y="38.53"
          width="110.94"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#2-2_filter3_i)">
          <rect
            x="173.47"
            y="45.47"
            width="97.0601"
            height="230.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          width="7"
          height="13.5283"
          rx="3.5"
          transform="matrix(-1 0 0 1 171 154)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#2-2_filter4_d)">
          <rect
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="matrix(-1 0 0 1 170.298 154.644)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M169.714 165.795C169.202 166.458 168.4 166.884 167.498 166.884C165.951 166.884 164.698 165.63 164.698 164.084V157.444C164.698 156.456 165.21 155.587 165.983 155.089C165.617 155.562 165.4 156.155 165.4 156.8V163.44C165.4 164.986 166.653 166.24 168.2 166.24C168.758 166.24 169.277 166.077 169.714 165.795Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M167.498 154.644C169.045 154.644 170.298 155.898 170.298 157.444V163.44C170.298 163.46 170.298 163.48 170.298 163.501V158.024C170.298 156.477 169.044 155.224 167.498 155.224C165.972 155.224 164.731 156.444 164.698 157.963V157.444C164.698 155.898 165.952 154.644 167.498 154.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-2_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M167.5 158C168.328 158 169 158.672 169 159.5V159.714V161V179.5C169 180.328 168.328 181 167.5 181C166.672 181 166 180.328 166 179.5V163V159.5L166 159.286C166 158.576 166.576 158 167.286 158C167.323 158 167.36 158.001 167.396 158.004C167.431 158.001 167.465 158 167.5 158Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M175.199 160.5L269.105 272.503L269.105 48.4975L175.199 160.5ZM174 160.5L270 275L270 46L174 160.5Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M222 46L174 275H270L222 46ZM222 50.3351L175.107 274.055H268.893L222 50.3351Z"
          fill="white"
        />
        <defs>
          <filter
            id="2-2_filter0_d"
            x="280.12"
            y="43.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-2_filter1_d"
            x="280.12"
            y="251.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-2_filter2_d"
            x="157.3"
            y="29.3003"
            width="131.279"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-2_filter3_i"
            x="173"
            y="45"
            width="98.9399"
            height="233.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-2_filter4_d"
            x="162.818"
            y="152.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-2_filter5_d"
            x="164.12"
            y="156.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="2-2_paint0_linear"
            x1="288.8"
            y1="62.5"
            x2="281.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="2-2_paint1_linear"
            x1="279.202"
            y1="47.5"
            x2="286.8"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="2-2_paint2_linear"
            x1="288.798"
            y1="62.5"
            x2="281.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="2-2_paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="2-2_paint4_linear"
            x1="288.8"
            y1="261.625"
            x2="281.2"
            y2="261.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="2-2_paint5_linear"
            x1="290.5"
            y1="273.604"
            x2="281"
            y2="273.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
