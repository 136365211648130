import styled from 'styled-components'

export const ButtonWrapper = styled.div`
  margin-bottom: 16px;
`

export const PhoneBlockWrapper = styled.div`
  margin-top: 12px;

  display: flex;
  flex-direction: column;
`

export const PhoneBlockText = styled.span`
  display: block;

  font-size: 16px;
  color: #333333;
  line-height: 22.4px;
  text-align: center;

  margin-bottom: 32px;
`

export const PhoneBlockTitle = styled.h5`
  text-align: center;
  font-size: 18px;
  line-height: 25.2px;
  font-weight: 700;

  margin-bottom: 20px;
`

export const LinkListWrapper = styled.div`
  margin-bottom: 32px;
`

export const LinkWrapper = styled.div`
  text-align: center;
`

export const LinkMessage = styled.div<{ isError?: boolean; textAlign?: string }>`
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: ${({ textAlign = 'center' }) => textAlign};
  color: ${({ isError }) => (isError ? '#ff453a' : '#333333')};

  padding-bottom: 10px;
`

export const LinkButton = styled.span`
  color: #1c3775;
  font-size: 16px;
  line-height: 22.4px;

  cursor: pointer;
`

export const RightConfirmWrapper = styled.div`
  padding-right: 60px;
  margin-bottom: 20px;
  display: flex;

  input {
    margin-right: 10px;
  }
  
  label {
    span {
      color: red;
    }
  }
`