import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { IDateInputProps } from '../../views/calculator/interface'
import { InputPlaceholder, InputStyle, InputWrapper, InputError } from './styles'
import { CloseIcon } from '../../assets/icons'

export const DateInput: FC<IDateInputProps> = props => {
  const { value, onChange, id, name, placeholder, errorMessage, disabled } = props
  const inputRef = useRef<HTMLInputElement>(null)
  const [active, setActive] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    if (errorMessage && !value) {
      setError(errorMessage)
    }
  }, [errorMessage, value])

  const inputProps = useMemo(() => {
    const result = {}

    if (id) {
      result['id'] = id
    }
    if (name) {
      result['name'] = name
    }
    if (placeholder) {
      result['placeholder'] = placeholder
    }
    if (disabled) {
      result['disabled'] = disabled
    }

    return result
  }, [disabled, id, name, placeholder])

  const handleChange = useCallback(
    ({ target }) => {
      setError('')
      if (onChange) {
        onChange(target.value, name)
      }
    },
    [name, onChange],
  )

  const handleClickPlaceholder = useCallback(() => {
    if (inputRef !== null && inputRef.current) {
      inputRef.current.focus()
      setActive(true)
    }
  }, [])

  const handleBlurInput = useCallback(() => {
    setActive(false)
    if (errorMessage && !value) {
      setError(errorMessage)
    }
  }, [errorMessage, value])

  const handleFocusInput = useCallback(() => {
    setActive(true)
    setError('')
  }, [])

  return (
    <InputWrapper disabled={disabled}>
      <InputStyle
        {...inputProps}
        ref={inputRef}
        type="date"
        onChange={handleChange}
        onFocus={handleFocusInput}
        onBlur={handleBlurInput}
        value={value}
        isError={Boolean(error)}
      />
      {placeholder && !value && !active && (
        <InputPlaceholder onClick={handleClickPlaceholder}>{placeholder}</InputPlaceholder>
      )}
      {error && (
        <InputError title={error}>
          <CloseIcon color="#ff453a" />
        </InputError>
      )}
    </InputWrapper>
  )
}
