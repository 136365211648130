import React, { FC } from 'react'
import { ProductImageWrapper } from '../../../components/product-images-generator/styles'
import { IContainerSquareProps } from '../../../interface'
import { ContainerBackground } from '../../container-background'

export const ContainerLoggia10Casement: FC<IContainerSquareProps> = props => {
  const { onCasementClick, withoutBackground } = props

  return (
    <ProductImageWrapper isClickable>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g clipPath="url(#clip0)">
          <path
            d="M98.5 3H349L433 35.5L429 294.5L349 262H93.5L11 294.5V35.5L98.5 3Z"
            fill="url(#pattern0)"
          />
          <rect x="96.3203" y="4.02344" width="255.89" height="255.89" fill="#E8EAEE" />
          <rect x="96.3203" y="4.02344" width="255.89" height="255.89" fill="url(#pattern1)" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M352.211 4.02344H96.3206V8.04706H96.3203V259.914H96.3206V263.937H352.211V4.02344ZM96.3206 259.914H352.211V8.04706H96.3206V259.914Z"
            fill="#C4C4C4"
          />
          <rect x="96.3203" y="263.938" width="255.89" height="4.02343" fill="white" />
          <rect x="96.3203" width="255.89" height="4.02343" fill="white" />
          <rect
            x="99.1367"
            y="10.8633"
            width="79.664"
            height="119.898"
            stroke="#F3F4F8"
            strokeWidth="5.63281"
          />
          <rect
            x="184.434"
            y="10.8633"
            width="79.664"
            height="119.898"
            stroke="#F3F4F8"
            strokeWidth="5.63281"
          />
          <rect
            x="269.731"
            y="10.8633"
            width="79.664"
            height="119.898"
            stroke="#F3F4F8"
            strokeWidth="5.63281"
          />
          <rect
            x="99.1367"
            y="136.394"
            width="79.664"
            height="120.703"
            stroke="#F3F4F8"
            strokeWidth="5.63281"
          />
          <rect
            x="184.434"
            y="136.394"
            width="79.664"
            height="120.703"
            stroke="#F3F4F8"
            strokeWidth="5.63281"
          />
          <rect
            x="269.731"
            y="136.394"
            width="79.664"
            height="120.703"
            stroke="#F3F4F8"
            strokeWidth="5.63281"
          />
          <g
            filter="url(#filter0_i)"
            className="casement"
            data-index="0"
            onClick={onCasementClick(1)}
          >
            <rect
              x="101.953"
              y="13.6787"
              width="74.0312"
              height="114.266"
              fill="#B8C1FD"
              fillOpacity="0.4"
            />
          </g>
          <rect
            x="102.355"
            y="14.0811"
            width="73.2265"
            height="113.461"
            stroke="#979797"
            strokeWidth="0.804687"
          />
          <g filter="url(#filter1_i)">
            <rect
              x="187.25"
              y="13.6787"
              width="74.0312"
              height="114.266"
              fill="#B8C1FD"
              fillOpacity="0.4"
            />
          </g>
          <rect
            x="187.652"
            y="14.0811"
            width="73.2265"
            height="113.461"
            stroke="#979797"
            strokeWidth="0.804687"
          />
          <g filter="url(#filter2_i)">
            <rect
              x="272.547"
              y="13.6787"
              width="74.0312"
              height="114.266"
              fill="#B8C1FD"
              fillOpacity="0.4"
            />
          </g>
          <rect
            x="272.95"
            y="14.0811"
            width="73.2265"
            height="113.461"
            stroke="#979797"
            strokeWidth="0.804687"
          />
          <g filter="url(#filter3_i)">
            <rect
              x="101.953"
              y="139.211"
              width="74.0312"
              height="115.07"
              fill="#B8C1FD"
              fillOpacity="0.4"
            />
          </g>
          <rect
            x="102.355"
            y="139.613"
            width="73.2265"
            height="114.266"
            stroke="#979797"
            strokeWidth="0.804687"
          />
          <g filter="url(#filter4_i)">
            <rect
              x="187.25"
              y="139.211"
              width="74.0312"
              height="115.07"
              fill="#B8C1FD"
              fillOpacity="0.4"
            />
          </g>
          <rect
            x="187.652"
            y="139.613"
            width="73.2265"
            height="114.266"
            stroke="#979797"
            strokeWidth="0.804687"
          />
          <g filter="url(#filter5_i)">
            <rect
              x="272.547"
              y="139.211"
              width="74.0312"
              height="115.07"
              fill="#B8C1FD"
              fillOpacity="0.4"
            />
          </g>
          <rect
            x="272.95"
            y="139.613"
            width="73.2265"
            height="114.266"
            stroke="#979797"
            strokeWidth="0.804687"
          />
          <path
            d="M146.981 37.4129H144.477C143.839 37.4129 143.299 36.9129 143.299 36.322V36.0948C143.299 35.5039 143.839 35.0039 144.477 35.0039H146.981C147.62 35.0039 148.16 35.5039 148.16 36.0948V36.322C148.16 36.9356 147.644 37.4129 146.981 37.4129Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M129.657 40.1987C129.952 40.1987 130.025 40.1987 130.418 40.1987C131.154 40.1987 131.694 40.7315 131.694 41.4496C131.694 42.1908 131.129 42.7005 130.369 42.7005C128.946 42.7005 128.062 42.7005 126.639 42.7005C126.099 42.7005 125.658 42.5152 125.437 42.0287C125.241 41.7275 125.118 40.8936 125.903 40.1987C126.418 39.6195 132.111 33.4808 133.117 32.4152C133.166 32.3457 133.117 32.2531 133.043 32.2531C133.019 32.2531 133.019 32.2531 132.994 32.2531C130.86 32.2531 128.725 32.2531 126.59 32.2531C125.952 32.2531 125.461 31.8824 125.339 31.3265C125.192 30.6778 125.486 30.1219 126.05 29.8671C126.222 29.7976 126.394 29.7744 126.566 29.7744C129.731 29.7744 132.872 29.7744 136.037 29.7744C136.65 29.7744 137.067 29.9597 137.313 30.4925C137.632 31.1643 137.288 31.6276 137.067 31.9288C136.626 32.4616 132.135 37.2335 129.657 39.9207C129.633 39.9438 129.608 39.9902 129.559 40.0133C129.51 40.0828 129.559 40.1987 129.657 40.1987Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M136.326 42.7011H133.822C133.184 42.7011 132.644 42.1889 132.644 41.5837V41.3508C132.644 40.7456 133.184 40.2334 133.822 40.2334H136.326C136.964 40.2334 137.504 40.7456 137.504 41.3508V41.5837C137.504 42.2122 136.964 42.7011 136.326 42.7011Z"
            fill="#141B4D"
            fillOpacity="0.7"
            stroke="#767676"
            strokeWidth="0.0801231"
            strokeMiterlimit="10"
          />
          <path
            d="M139.809 36.2028C139.809 34.4454 139.809 32.7111 139.809 30.9537C139.809 30.26 140.324 29.7744 141.06 29.7744C144.248 29.7744 147.437 29.7744 150.626 29.7744C151.361 29.7744 151.876 30.2831 151.876 31C151.876 31.7168 151.361 32.2486 150.626 32.2486C147.977 32.2486 145.352 32.2486 142.703 32.2486C142.507 32.2486 142.458 32.2949 142.458 32.4799C142.458 34.9772 142.458 37.4746 142.458 39.9719C142.458 40.18 142.532 40.2031 142.703 40.2031C145.328 40.2031 147.977 40.2031 150.601 40.2031C151.19 40.2031 151.656 40.5269 151.827 41.0125C152.024 41.5906 151.803 42.1686 151.337 42.4924C151.116 42.6311 150.846 42.7005 150.576 42.7005C147.437 42.7005 144.273 42.7005 141.134 42.7005C140.324 42.7005 139.834 42.1918 139.834 41.4287C139.809 39.6713 139.809 37.937 139.809 36.2028Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M126.972 47.5761V48.2242H125.29V47.5761H125.71V45.817H125.339V45.1689H126.947V45.817H126.576V47.5761H126.972Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M130.75 48.2821H130L129.879 48.0722C129.612 48.2121 129.298 48.2821 129.007 48.2821C128.184 48.2588 127.409 47.6989 127.409 46.6958C127.409 45.7393 128.063 45.1094 129.055 45.1094C129.879 45.1094 130.678 45.6459 130.678 46.6958C130.678 47.069 130.581 47.349 130.435 47.6056L130.774 48.1655V48.2821H130.75ZM129.031 47.5356C129.419 47.5356 129.83 47.2557 129.83 46.6958C129.83 46.1359 129.419 45.8559 129.031 45.8559C128.62 45.8559 128.232 46.1359 128.232 46.6958C128.232 47.2557 128.62 47.5356 129.031 47.5356Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M132.969 47.2863C132.969 47.4947 133.212 47.6105 133.478 47.6105C133.721 47.6105 133.963 47.4947 133.963 47.3326C133.963 47.0316 133.599 47.0316 133.381 47.0084C132.8 46.9621 132.218 46.7537 132.218 46.1052C132.218 45.4336 132.8 45.1094 133.454 45.1094C134.06 45.1094 134.69 45.4336 134.69 46.1052H133.89C133.89 45.8968 133.672 45.8041 133.454 45.8041C133.26 45.8041 133.018 45.8505 133.018 46.082C133.018 46.2905 133.212 46.3368 133.503 46.36C134.181 46.4063 134.762 46.5684 134.762 47.3095C134.762 47.9579 134.108 48.2821 133.454 48.2821C132.776 48.2821 132.121 47.9579 132.146 47.2863H132.969Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M137.355 45.8686H136.18V46.3117H137.257V47.0114H136.18V47.5012H137.38V48.2242H135.323V45.1689H137.331V45.8686H137.355Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M140.685 48.1076V48.2242H139.816L139.26 47.268H138.826V48.2242H138.005V45.1689C138.488 45.1689 138.946 45.1689 139.405 45.1689C140.178 45.1689 140.54 45.7287 140.54 46.2651C140.54 46.6149 140.371 46.9648 140.057 47.1514L140.685 48.1076ZM139.405 46.6149C139.647 46.6149 139.743 46.4284 139.743 46.2185C139.743 46.0319 139.622 45.8453 139.405 45.8453H138.826V46.6149H139.405Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M142.336 46.8781L142.609 46.0821L143.006 45.1689H143.925V45.286L142.51 48.283H142.162L140.747 45.286V45.1689H141.665L142.087 46.0821L142.336 46.8781Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M145.793 47.5761V48.2242H144.111V47.5761H144.531V45.817H144.16V45.1689H145.769V45.817H145.398V47.5761H145.793Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M147.82 45.1689C148.485 45.1689 149.224 45.5175 149.347 46.3309H148.534C148.411 46.0055 148.165 45.8894 147.82 45.8894C147.327 45.8894 147.007 46.2147 147.007 46.7259C147.007 47.1675 147.327 47.5625 147.82 47.5625C148.165 47.5625 148.46 47.3999 148.534 47.0745H149.347C149.224 47.9344 148.51 48.283 147.82 48.283C146.982 48.283 146.169 47.7485 146.169 46.7259C146.169 45.6802 146.982 45.1689 147.82 45.1689Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M151.874 45.8686H150.699V46.3117H151.776V47.0114H150.699V47.5012H151.899V48.2242H149.842V45.1689H151.85V45.8686H151.874Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M232.277 37.4129H229.773C229.135 37.4129 228.595 36.9129 228.595 36.322V36.0948C228.595 35.5039 229.135 35.0039 229.773 35.0039H232.277C232.916 35.0039 233.456 35.5039 233.456 36.0948V36.322C233.456 36.9356 232.94 37.4129 232.277 37.4129Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M214.954 40.1987C215.248 40.1987 215.322 40.1987 215.714 40.1987C216.45 40.1987 216.99 40.7315 216.99 41.4496C216.99 42.1908 216.426 42.7005 215.665 42.7005C214.242 42.7005 213.359 42.7005 211.936 42.7005C211.396 42.7005 210.954 42.5152 210.733 42.0287C210.537 41.7275 210.414 40.8936 211.199 40.1987C211.715 39.6195 217.407 33.4808 218.413 32.4152C218.462 32.3457 218.413 32.2531 218.34 32.2531C218.315 32.2531 218.315 32.2531 218.291 32.2531C216.156 32.2531 214.021 32.2531 211.887 32.2531C211.249 32.2531 210.758 31.8824 210.635 31.3265C210.488 30.6778 210.782 30.1219 211.347 29.8671C211.518 29.7976 211.69 29.7744 211.862 29.7744C215.027 29.7744 218.168 29.7744 221.333 29.7744C221.947 29.7744 222.364 29.9597 222.609 30.4925C222.928 31.1643 222.585 31.6276 222.364 31.9288C221.922 32.4616 217.432 37.2335 214.954 39.9207C214.929 39.9438 214.905 39.9902 214.855 40.0133C214.806 40.0828 214.855 40.1987 214.954 40.1987Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M221.622 42.7011H219.118C218.48 42.7011 217.94 42.1889 217.94 41.5837V41.3508C217.94 40.7456 218.48 40.2334 219.118 40.2334H221.622C222.261 40.2334 222.801 40.7456 222.801 41.3508V41.5837C222.801 42.2122 222.261 42.7011 221.622 42.7011Z"
            fill="#141B4D"
            fillOpacity="0.7"
            stroke="#767676"
            strokeWidth="0.0801231"
            strokeMiterlimit="10"
          />
          <path
            d="M225.105 36.2028C225.105 34.4454 225.105 32.7111 225.105 30.9537C225.105 30.26 225.62 29.7744 226.356 29.7744C229.544 29.7744 232.733 29.7744 235.921 29.7744C236.657 29.7744 237.172 30.2831 237.172 31C237.172 31.7168 236.657 32.2486 235.921 32.2486C233.272 32.2486 230.648 32.2486 227.999 32.2486C227.803 32.2486 227.754 32.2949 227.754 32.4799C227.754 34.9772 227.754 37.4746 227.754 39.9719C227.754 40.18 227.827 40.2031 227.999 40.2031C230.624 40.2031 233.272 40.2031 235.897 40.2031C236.486 40.2031 236.952 40.5269 237.123 41.0125C237.319 41.5906 237.099 42.1686 236.633 42.4924C236.412 42.6311 236.142 42.7005 235.872 42.7005C232.733 42.7005 229.569 42.7005 226.429 42.7005C225.62 42.7005 225.13 42.1918 225.13 41.4287C225.105 39.6713 225.105 37.937 225.105 36.2028Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M212.269 47.5761V48.2242H210.586V47.5761H211.007V45.817H210.635V45.1689H212.244V45.817H211.873V47.5761H212.269Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M216.046 48.2821H215.296L215.175 48.0722C214.908 48.2121 214.593 48.2821 214.303 48.2821C213.48 48.2588 212.705 47.6989 212.705 46.6958C212.705 45.7393 213.359 45.1094 214.351 45.1094C215.175 45.1094 215.973 45.6459 215.973 46.6958C215.973 47.069 215.877 47.349 215.731 47.6056L216.07 48.1655V48.2821H216.046ZM214.327 47.5356C214.715 47.5356 215.126 47.2557 215.126 46.6958C215.126 46.1359 214.715 45.8559 214.327 45.8559C213.916 45.8559 213.528 46.1359 213.528 46.6958C213.528 47.2557 213.916 47.5356 214.327 47.5356Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M218.265 47.2863C218.265 47.4947 218.508 47.6105 218.774 47.6105C219.016 47.6105 219.259 47.4947 219.259 47.3326C219.259 47.0316 218.895 47.0316 218.677 47.0084C218.096 46.9621 217.514 46.7537 217.514 46.1052C217.514 45.4336 218.096 45.1094 218.75 45.1094C219.356 45.1094 219.986 45.4336 219.986 46.1052H219.186C219.186 45.8968 218.968 45.8041 218.75 45.8041C218.556 45.8041 218.314 45.8505 218.314 46.082C218.314 46.2905 218.508 46.3368 218.798 46.36C219.477 46.4063 220.058 46.5684 220.058 47.3095C220.058 47.9579 219.404 48.2821 218.75 48.2821C218.072 48.2821 217.417 47.9579 217.442 47.2863H218.265Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M222.652 45.8686H221.477V46.3117H222.554V47.0114H221.477V47.5012H222.676V48.2242H220.62V45.1689H222.627V45.8686H222.652Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M225.981 48.1076V48.2242H225.111L224.556 47.268H224.122V48.2242H223.301V45.1689C223.784 45.1689 224.242 45.1689 224.701 45.1689C225.474 45.1689 225.836 45.7287 225.836 46.2651C225.836 46.6149 225.667 46.9648 225.353 47.1514L225.981 48.1076ZM224.701 46.6149C224.942 46.6149 225.039 46.4284 225.039 46.2185C225.039 46.0319 224.918 45.8453 224.701 45.8453H224.122V46.6149H224.701Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M227.632 46.8781L227.905 46.0821L228.302 45.1689H229.221V45.286L227.805 48.283H227.458L226.042 45.286V45.1689H226.961L227.383 46.0821L227.632 46.8781Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M231.09 47.5761V48.2242H229.407V47.5761H229.828V45.817H229.457V45.1689H231.065V45.817H230.694V47.5761H231.09Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M233.116 45.1689C233.781 45.1689 234.52 45.5175 234.644 46.3309H233.831C233.707 46.0055 233.461 45.8894 233.116 45.8894C232.623 45.8894 232.303 46.2147 232.303 46.7259C232.303 47.1675 232.623 47.5625 233.116 47.5625C233.461 47.5625 233.757 47.3999 233.831 47.0745H234.644C234.52 47.9344 233.806 48.283 233.116 48.283C232.278 48.283 231.465 47.7485 231.465 46.7259C231.465 45.6802 232.278 45.1689 233.116 45.1689Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M237.17 45.8686H235.995V46.3117H237.072V47.0114H235.995V47.5012H237.195V48.2242H235.138V45.1689H237.146V45.8686H237.17Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M317.574 37.4129H315.07C314.431 37.4129 313.891 36.9129 313.891 36.322V36.0948C313.891 35.5039 314.431 35.0039 315.07 35.0039H317.574C318.212 35.0039 318.752 35.5039 318.752 36.0948V36.322C318.752 36.9356 318.236 37.4129 317.574 37.4129Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M300.25 40.1987C300.544 40.1987 300.618 40.1987 301.01 40.1987C301.746 40.1987 302.286 40.7315 302.286 41.4496C302.286 42.1908 301.722 42.7005 300.961 42.7005C299.538 42.7005 298.655 42.7005 297.231 42.7005C296.692 42.7005 296.25 42.5152 296.029 42.0287C295.833 41.7275 295.71 40.8936 296.495 40.1987C297.011 39.6195 302.703 33.4808 303.709 32.4152C303.758 32.3457 303.709 32.2531 303.636 32.2531C303.611 32.2531 303.611 32.2531 303.587 32.2531C301.452 32.2531 299.317 32.2531 297.182 32.2531C296.544 32.2531 296.054 31.8824 295.931 31.3265C295.784 30.6778 296.078 30.1219 296.643 29.8671C296.814 29.7976 296.986 29.7744 297.158 29.7744C300.323 29.7744 303.464 29.7744 306.629 29.7744C307.243 29.7744 307.66 29.9597 307.905 30.4925C308.224 31.1643 307.881 31.6276 307.66 31.9288C307.218 32.4616 302.728 37.2335 300.25 39.9207C300.225 39.9438 300.2 39.9902 300.151 40.0133C300.102 40.0828 300.151 40.1987 300.25 40.1987Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M306.919 42.7011H304.415C303.776 42.7011 303.236 42.1889 303.236 41.5837V41.3508C303.236 40.7456 303.776 40.2334 304.415 40.2334H306.919C307.557 40.2334 308.097 40.7456 308.097 41.3508V41.5837C308.097 42.2122 307.557 42.7011 306.919 42.7011Z"
            fill="#141B4D"
            fillOpacity="0.7"
            stroke="#767676"
            strokeWidth="0.0801231"
            strokeMiterlimit="10"
          />
          <path
            d="M310.401 36.2028C310.401 34.4454 310.401 32.7111 310.401 30.9537C310.401 30.26 310.916 29.7744 311.652 29.7744C314.841 29.7744 318.029 29.7744 321.218 29.7744C321.954 29.7744 322.469 30.2831 322.469 31C322.469 31.7168 321.954 32.2486 321.218 32.2486C318.569 32.2486 315.944 32.2486 313.296 32.2486C313.099 32.2486 313.05 32.2949 313.05 32.4799C313.05 34.9772 313.05 37.4746 313.05 39.9719C313.05 40.18 313.124 40.2031 313.296 40.2031C315.92 40.2031 318.569 40.2031 321.193 40.2031C321.782 40.2031 322.248 40.5269 322.42 41.0125C322.616 41.5906 322.395 42.1686 321.929 42.4924C321.708 42.6311 321.439 42.7005 321.169 42.7005C318.029 42.7005 314.865 42.7005 311.726 42.7005C310.916 42.7005 310.426 42.1918 310.426 41.4287C310.401 39.6713 310.401 37.937 310.401 36.2028Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M297.565 47.5761V48.2242H295.882V47.5761H296.303V45.817H295.932V45.1689H297.54V45.817H297.169V47.5761H297.565Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M301.342 48.2821H300.592L300.471 48.0722C300.205 48.2121 299.89 48.2821 299.599 48.2821C298.776 48.2588 298.001 47.6989 298.001 46.6958C298.001 45.7393 298.655 45.1094 299.648 45.1094C300.471 45.1094 301.27 45.6459 301.27 46.6958C301.27 47.069 301.173 47.349 301.028 47.6056L301.367 48.1655V48.2821H301.342ZM299.624 47.5356C300.011 47.5356 300.422 47.2557 300.422 46.6958C300.422 46.1359 300.011 45.8559 299.624 45.8559C299.212 45.8559 298.825 46.1359 298.825 46.6958C298.825 47.2557 299.212 47.5356 299.624 47.5356Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M303.562 47.2863C303.562 47.4947 303.805 47.6105 304.071 47.6105C304.313 47.6105 304.556 47.4947 304.556 47.3326C304.556 47.0316 304.192 47.0316 303.974 47.0084C303.393 46.9621 302.811 46.7537 302.811 46.1052C302.811 45.4336 303.393 45.1094 304.047 45.1094C304.653 45.1094 305.283 45.4336 305.283 46.1052H304.483C304.483 45.8968 304.265 45.8041 304.047 45.8041C303.853 45.8041 303.611 45.8505 303.611 46.082C303.611 46.2905 303.805 46.3368 304.095 46.36C304.774 46.4063 305.355 46.5684 305.355 47.3095C305.355 47.9579 304.701 48.2821 304.047 48.2821C303.368 48.2821 302.714 47.9579 302.738 47.2863H303.562Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M307.948 45.8686H306.773V46.3117H307.85V47.0114H306.773V47.5012H307.973V48.2242H305.916V45.1689H307.924V45.8686H307.948Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M311.277 48.1076V48.2242H310.408L309.853 47.268H309.418V48.2242H308.598V45.1689C309.08 45.1689 309.539 45.1689 309.998 45.1689C310.77 45.1689 311.133 45.7287 311.133 46.2651C311.133 46.6149 310.964 46.9648 310.65 47.1514L311.277 48.1076ZM309.998 46.6149C310.239 46.6149 310.336 46.4284 310.336 46.2185C310.336 46.0319 310.215 45.8453 309.998 45.8453H309.418V46.6149H309.998Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M312.928 46.8781L313.201 46.0821L313.598 45.1689H314.517V45.286L313.102 48.283H312.754L311.339 45.286V45.1689H312.258L312.68 46.0821L312.928 46.8781Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M316.387 47.5761V48.2242H314.704V47.5761H315.125V45.817H314.754V45.1689H316.362V45.817H315.991V47.5761H316.387Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M318.413 45.1689C319.078 45.1689 319.817 45.5175 319.94 46.3309H319.127C319.004 46.0055 318.758 45.8894 318.413 45.8894C317.92 45.8894 317.6 46.2147 317.6 46.7259C317.6 47.1675 317.92 47.5625 318.413 47.5625C318.758 47.5625 319.054 47.3999 319.127 47.0745H319.94C319.817 47.9344 319.103 48.283 318.413 48.283C317.575 48.283 316.762 47.7485 316.762 46.7259C316.762 45.6802 317.575 45.1689 318.413 45.1689Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M322.467 45.8686H321.292V46.3117H322.369V47.0114H321.292V47.5012H322.492V48.2242H320.435V45.1689H322.443V45.8686H322.467Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M146.981 162.943H144.477C143.839 162.943 143.299 162.443 143.299 161.852V161.625C143.299 161.034 143.839 160.534 144.477 160.534H146.981C147.62 160.534 148.16 161.034 148.16 161.625V161.852C148.16 162.466 147.644 162.943 146.981 162.943Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M129.658 165.729C129.952 165.729 130.026 165.729 130.418 165.729C131.154 165.729 131.694 166.262 131.694 166.98C131.694 167.721 131.13 168.231 130.369 168.231C128.946 168.231 128.063 168.231 126.64 168.231C126.1 168.231 125.658 168.045 125.437 167.559C125.241 167.258 125.118 166.424 125.904 165.729C126.419 165.15 132.111 159.011 133.117 157.945C133.167 157.876 133.117 157.783 133.044 157.783C133.019 157.783 133.019 157.783 132.995 157.783C130.86 157.783 128.725 157.783 126.591 157.783C125.953 157.783 125.462 157.413 125.339 156.857C125.192 156.208 125.486 155.652 126.051 155.397C126.223 155.328 126.394 155.305 126.566 155.305C129.731 155.305 132.872 155.305 136.037 155.305C136.651 155.305 137.068 155.49 137.313 156.023C137.632 156.695 137.289 157.158 137.068 157.459C136.626 157.992 132.136 162.764 129.658 165.451C129.633 165.474 129.609 165.52 129.56 165.544C129.511 165.613 129.56 165.729 129.658 165.729Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M136.326 168.231H133.822C133.184 168.231 132.644 167.719 132.644 167.114V166.881C132.644 166.276 133.184 165.764 133.822 165.764H136.326C136.964 165.764 137.504 166.276 137.504 166.881V167.114C137.504 167.742 136.964 168.231 136.326 168.231Z"
            fill="#141B4D"
            fillOpacity="0.7"
            stroke="#767676"
            strokeWidth="0.0801231"
            strokeMiterlimit="10"
          />
          <path
            d="M139.809 161.733C139.809 159.976 139.809 158.241 139.809 156.484C139.809 155.79 140.324 155.305 141.06 155.305C144.248 155.305 147.437 155.305 150.626 155.305C151.361 155.305 151.876 155.813 151.876 156.53C151.876 157.247 151.361 157.779 150.626 157.779C147.977 157.779 145.352 157.779 142.703 157.779C142.507 157.779 142.458 157.825 142.458 158.01C142.458 160.507 142.458 163.005 142.458 165.502C142.458 165.71 142.532 165.733 142.703 165.733C145.328 165.733 147.977 165.733 150.601 165.733C151.19 165.733 151.656 166.057 151.827 166.543C152.024 167.121 151.803 167.699 151.337 168.023C151.116 168.161 150.846 168.231 150.576 168.231C147.437 168.231 144.273 168.231 141.134 168.231C140.324 168.231 139.834 167.722 139.834 166.959C139.809 165.202 139.809 163.467 139.809 161.733Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M126.972 173.106V173.754H125.29V173.106H125.71V171.347H125.339V170.699H126.947V171.347H126.576V173.106H126.972Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M130.75 173.812H130L129.879 173.602C129.612 173.742 129.298 173.812 129.007 173.812C128.184 173.789 127.409 173.229 127.409 172.226C127.409 171.27 128.063 170.64 129.055 170.64C129.879 170.64 130.678 171.176 130.678 172.226C130.678 172.599 130.581 172.879 130.435 173.136L130.774 173.696V173.812H130.75ZM129.031 173.066C129.419 173.066 129.83 172.786 129.83 172.226C129.83 171.666 129.419 171.386 129.031 171.386C128.62 171.386 128.232 171.666 128.232 172.226C128.232 172.786 128.62 173.066 129.031 173.066Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M132.969 172.817C132.969 173.025 133.212 173.141 133.478 173.141C133.721 173.141 133.963 173.025 133.963 172.863C133.963 172.562 133.599 172.562 133.381 172.539C132.8 172.492 132.218 172.284 132.218 171.635C132.218 170.964 132.8 170.64 133.454 170.64C134.06 170.64 134.69 170.964 134.69 171.635H133.89C133.89 171.427 133.672 171.334 133.454 171.334C133.26 171.334 133.018 171.381 133.018 171.612C133.018 171.821 133.212 171.867 133.503 171.89C134.181 171.937 134.762 172.099 134.762 172.84C134.762 173.488 134.108 173.812 133.454 173.812C132.776 173.812 132.121 173.488 132.146 172.817H132.969Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M137.355 171.399H136.18V171.842H137.257V172.542H136.18V173.031H137.38V173.754H135.323V170.699H137.331V171.399H137.355Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M140.685 173.638V173.754H139.816L139.26 172.798H138.826V173.754H138.005V170.699C138.488 170.699 138.946 170.699 139.405 170.699C140.178 170.699 140.54 171.259 140.54 171.795C140.54 172.145 140.371 172.495 140.057 172.682L140.685 173.638ZM139.405 172.145C139.647 172.145 139.743 171.959 139.743 171.749C139.743 171.562 139.622 171.376 139.405 171.376H138.826V172.145H139.405Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M142.336 172.408L142.609 171.612L143.006 170.699H143.925V170.816L142.51 173.813H142.162L140.747 170.816V170.699H141.665L142.087 171.612L142.336 172.408Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M145.793 173.106V173.754H144.111V173.106H144.531V171.347H144.16V170.699H145.769V171.347H145.398V173.106H145.793Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M147.82 170.699C148.485 170.699 149.224 171.048 149.347 171.861H148.534C148.411 171.536 148.165 171.42 147.82 171.42C147.327 171.42 147.007 171.745 147.007 172.256C147.007 172.698 147.327 173.093 147.82 173.093C148.165 173.093 148.46 172.93 148.534 172.605H149.347C149.224 173.465 148.51 173.813 147.82 173.813C146.982 173.813 146.169 173.279 146.169 172.256C146.169 171.21 146.982 170.699 147.82 170.699Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M151.874 171.399H150.699V171.842H151.776V172.542H150.699V173.031H151.899V173.754H149.842V170.699H151.85V171.399H151.874Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M232.277 162.943H229.773C229.135 162.943 228.595 162.443 228.595 161.852V161.625C228.595 161.034 229.135 160.534 229.773 160.534H232.277C232.916 160.534 233.456 161.034 233.456 161.625V161.852C233.456 162.466 232.94 162.943 232.277 162.943Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M214.953 165.729C215.248 165.729 215.321 165.729 215.714 165.729C216.45 165.729 216.99 166.262 216.99 166.98C216.99 167.721 216.425 168.231 215.665 168.231C214.242 168.231 213.358 168.231 211.935 168.231C211.395 168.231 210.954 168.045 210.733 167.559C210.536 167.258 210.414 166.424 211.199 165.729C211.714 165.15 217.407 159.011 218.413 157.945C218.462 157.876 218.413 157.783 218.339 157.783C218.315 157.783 218.315 157.783 218.29 157.783C216.155 157.783 214.021 157.783 211.886 157.783C211.248 157.783 210.757 157.413 210.635 156.857C210.487 156.208 210.782 155.652 211.346 155.397C211.518 155.328 211.69 155.305 211.861 155.305C215.027 155.305 218.167 155.305 221.333 155.305C221.946 155.305 222.363 155.49 222.609 156.023C222.928 156.695 222.584 157.158 222.363 157.459C221.922 157.992 217.431 162.764 214.953 165.451C214.929 165.474 214.904 165.52 214.855 165.544C214.806 165.613 214.855 165.729 214.953 165.729Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M221.622 168.231H219.118C218.48 168.231 217.94 167.719 217.94 167.114V166.881C217.94 166.276 218.48 165.764 219.118 165.764H221.622C222.261 165.764 222.801 166.276 222.801 166.881V167.114C222.801 167.742 222.261 168.231 221.622 168.231Z"
            fill="#141B4D"
            fillOpacity="0.7"
            stroke="#767676"
            strokeWidth="0.0801231"
            strokeMiterlimit="10"
          />
          <path
            d="M225.105 161.733C225.105 159.976 225.105 158.241 225.105 156.484C225.105 155.79 225.62 155.305 226.356 155.305C229.544 155.305 232.733 155.305 235.921 155.305C236.657 155.305 237.172 155.813 237.172 156.53C237.172 157.247 236.657 157.779 235.921 157.779C233.272 157.779 230.648 157.779 227.999 157.779C227.803 157.779 227.754 157.825 227.754 158.01C227.754 160.507 227.754 163.005 227.754 165.502C227.754 165.71 227.827 165.733 227.999 165.733C230.624 165.733 233.272 165.733 235.897 165.733C236.486 165.733 236.952 166.057 237.123 166.543C237.319 167.121 237.099 167.699 236.633 168.023C236.412 168.161 236.142 168.231 235.872 168.231C232.733 168.231 229.569 168.231 226.429 168.231C225.62 168.231 225.13 167.722 225.13 166.959C225.105 165.202 225.105 163.467 225.105 161.733Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M212.269 173.106V173.754H210.586V173.106H211.007V171.347H210.635V170.699H212.244V171.347H211.873V173.106H212.269Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M216.046 173.812H215.296L215.175 173.602C214.908 173.742 214.593 173.812 214.303 173.812C213.48 173.789 212.705 173.229 212.705 172.226C212.705 171.27 213.359 170.64 214.351 170.64C215.175 170.64 215.973 171.176 215.973 172.226C215.973 172.599 215.877 172.879 215.731 173.136L216.07 173.696V173.812H216.046ZM214.327 173.066C214.715 173.066 215.126 172.786 215.126 172.226C215.126 171.666 214.715 171.386 214.327 171.386C213.916 171.386 213.528 171.666 213.528 172.226C213.528 172.786 213.916 173.066 214.327 173.066Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M218.265 172.817C218.265 173.025 218.508 173.141 218.774 173.141C219.016 173.141 219.259 173.025 219.259 172.863C219.259 172.562 218.895 172.562 218.677 172.539C218.096 172.492 217.514 172.284 217.514 171.635C217.514 170.964 218.096 170.64 218.75 170.64C219.356 170.64 219.986 170.964 219.986 171.635H219.186C219.186 171.427 218.968 171.334 218.75 171.334C218.556 171.334 218.314 171.381 218.314 171.612C218.314 171.821 218.508 171.867 218.798 171.89C219.477 171.937 220.058 172.099 220.058 172.84C220.058 173.488 219.404 173.812 218.75 173.812C218.072 173.812 217.417 173.488 217.442 172.817H218.265Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M222.652 171.399H221.477V171.842H222.554V172.542H221.477V173.031H222.676V173.754H220.62V170.699H222.627V171.399H222.652Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M225.981 173.638V173.754H225.111L224.556 172.798H224.122V173.754H223.301V170.699C223.784 170.699 224.242 170.699 224.701 170.699C225.474 170.699 225.836 171.259 225.836 171.795C225.836 172.145 225.667 172.495 225.353 172.682L225.981 173.638ZM224.701 172.145C224.942 172.145 225.039 171.959 225.039 171.749C225.039 171.562 224.918 171.376 224.701 171.376H224.122V172.145H224.701Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M227.632 172.408L227.905 171.612L228.302 170.699H229.221V170.816L227.805 173.813H227.458L226.042 170.816V170.699H226.961L227.383 171.612L227.632 172.408Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M231.09 173.106V173.754H229.407V173.106H229.828V171.347H229.457V170.699H231.065V171.347H230.694V173.106H231.09Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M233.116 170.699C233.781 170.699 234.52 171.048 234.644 171.861H233.831C233.707 171.536 233.461 171.42 233.116 171.42C232.623 171.42 232.303 171.745 232.303 172.256C232.303 172.698 232.623 173.093 233.116 173.093C233.461 173.093 233.757 172.93 233.831 172.605H234.644C234.52 173.465 233.806 173.813 233.116 173.813C232.278 173.813 231.465 173.279 231.465 172.256C231.465 171.21 232.278 170.699 233.116 170.699Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M237.17 171.399H235.995V171.842H237.072V172.542H235.995V173.031H237.195V173.754H235.138V170.699H237.146V171.399H237.17Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M317.574 162.943H315.07C314.431 162.943 313.891 162.443 313.891 161.852V161.625C313.891 161.034 314.431 160.534 315.07 160.534H317.574C318.212 160.534 318.752 161.034 318.752 161.625V161.852C318.752 162.466 318.236 162.943 317.574 162.943Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M300.25 165.729C300.544 165.729 300.618 165.729 301.011 165.729C301.747 165.729 302.287 166.262 302.287 166.98C302.287 167.721 301.722 168.231 300.962 168.231C299.538 168.231 298.655 168.231 297.232 168.231C296.692 168.231 296.251 168.045 296.03 167.559C295.833 167.258 295.711 166.424 296.496 165.729C297.011 165.15 302.704 159.011 303.71 157.945C303.759 157.876 303.71 157.783 303.636 157.783C303.612 157.783 303.612 157.783 303.587 157.783C301.452 157.783 299.318 157.783 297.183 157.783C296.545 157.783 296.054 157.413 295.932 156.857C295.784 156.208 296.079 155.652 296.643 155.397C296.815 155.328 296.987 155.305 297.158 155.305C300.324 155.305 303.464 155.305 306.63 155.305C307.243 155.305 307.66 155.49 307.906 156.023C308.225 156.695 307.881 157.158 307.66 157.459C307.218 157.992 302.728 162.764 300.25 165.451C300.225 165.474 300.201 165.52 300.152 165.544C300.103 165.613 300.152 165.729 300.25 165.729Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M306.919 168.231H304.415C303.776 168.231 303.236 167.719 303.236 167.114V166.881C303.236 166.276 303.776 165.764 304.415 165.764H306.919C307.557 165.764 308.097 166.276 308.097 166.881V167.114C308.097 167.742 307.557 168.231 306.919 168.231Z"
            fill="#141B4D"
            fillOpacity="0.7"
            stroke="#767676"
            strokeWidth="0.0801231"
            strokeMiterlimit="10"
          />
          <path
            d="M310.401 161.733C310.401 159.976 310.401 158.241 310.401 156.484C310.401 155.79 310.916 155.305 311.652 155.305C314.84 155.305 318.029 155.305 321.217 155.305C321.953 155.305 322.468 155.813 322.468 156.53C322.468 157.247 321.953 157.779 321.217 157.779C318.568 157.779 315.944 157.779 313.295 157.779C313.099 157.779 313.05 157.825 313.05 158.01C313.05 160.507 313.05 163.005 313.05 165.502C313.05 165.71 313.123 165.733 313.295 165.733C315.919 165.733 318.568 165.733 321.193 165.733C321.781 165.733 322.247 166.057 322.419 166.543C322.615 167.121 322.395 167.699 321.929 168.023C321.708 168.161 321.438 168.231 321.168 168.231C318.029 168.231 314.865 168.231 311.725 168.231C310.916 168.231 310.425 167.722 310.425 166.959C310.401 165.202 310.401 163.467 310.401 161.733Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M297.565 173.106V173.754H295.882V173.106H296.303V171.347H295.932V170.699H297.54V171.347H297.169V173.106H297.565Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M301.342 173.812H300.592L300.471 173.602C300.205 173.742 299.89 173.812 299.599 173.812C298.776 173.789 298.001 173.229 298.001 172.226C298.001 171.27 298.655 170.64 299.648 170.64C300.471 170.64 301.27 171.176 301.27 172.226C301.27 172.599 301.173 172.879 301.028 173.136L301.367 173.696V173.812H301.342ZM299.624 173.066C300.011 173.066 300.422 172.786 300.422 172.226C300.422 171.666 300.011 171.386 299.624 171.386C299.212 171.386 298.825 171.666 298.825 172.226C298.825 172.786 299.212 173.066 299.624 173.066Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M303.562 172.817C303.562 173.025 303.804 173.141 304.071 173.141C304.313 173.141 304.555 173.025 304.555 172.863C304.555 172.562 304.192 172.562 303.974 172.539C303.392 172.492 302.811 172.284 302.811 171.635C302.811 170.964 303.392 170.64 304.046 170.64C304.652 170.64 305.282 170.964 305.282 171.635H304.482C304.482 171.427 304.264 171.334 304.046 171.334C303.853 171.334 303.61 171.381 303.61 171.612C303.61 171.821 303.804 171.867 304.095 171.89C304.773 171.937 305.355 172.099 305.355 172.84C305.355 173.488 304.701 173.812 304.046 173.812C303.368 173.812 302.714 173.488 302.738 172.817H303.562Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M307.948 171.399H306.773V171.842H307.85V172.542H306.773V173.031H307.973V173.754H305.916V170.699H307.924V171.399H307.948Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M311.277 173.638V173.754H310.408L309.853 172.798H309.418V173.754H308.597V170.699C309.08 170.699 309.539 170.699 309.997 170.699C310.77 170.699 311.132 171.259 311.132 171.795C311.132 172.145 310.963 172.495 310.649 172.682L311.277 173.638ZM309.997 172.145C310.239 172.145 310.335 171.959 310.335 171.749C310.335 171.562 310.215 171.376 309.997 171.376H309.418V172.145H309.997Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M312.928 172.408L313.201 171.612L313.598 170.699H314.517V170.816L313.102 173.813H312.754L311.339 170.816V170.699H312.258L312.68 171.612L312.928 172.408Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M316.386 173.106V173.754H314.704V173.106H315.124V171.347H314.753V170.699H316.361V171.347H315.99V173.106H316.386Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M318.412 170.699C319.078 170.699 319.817 171.048 319.94 171.861H319.127C319.004 171.536 318.757 171.42 318.412 171.42C317.92 171.42 317.599 171.745 317.599 172.256C317.599 172.698 317.92 173.093 318.412 173.093C318.757 173.093 319.053 172.93 319.127 172.605H319.94C319.817 173.465 319.102 173.813 318.412 173.813C317.575 173.813 316.762 173.279 316.762 172.256C316.762 171.21 317.575 170.699 318.412 170.699Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M322.467 171.399H321.291V171.842H322.369V172.542H321.291V173.031H322.491V173.754H320.435V170.699H322.442V171.399H322.467Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <rect
            width="94.9531"
            height="4.02343"
            transform="matrix(0.938191 0.346117 0 1 352.211 263.938)"
            fill="white"
          />
          <g filter="url(#filter6_i)">
            <rect
              width="74.0312"
              height="115.07"
              transform="matrix(0.938191 0.346117 0 1 357.496 141.16)"
              fill="#B8C1FD"
              fillOpacity="0.4"
            />
          </g>
          <rect
            x="0.377475"
            y="0.541601"
            width="73.2265"
            height="114.266"
            transform="matrix(0.938191 0.346117 0 1 357.519 141.03)"
            stroke="#979797"
            strokeWidth="0.804687"
          />
          <g filter="url(#filter7_i)">
            <rect
              width="74.0312"
              height="114.266"
              transform="matrix(0.938191 0.346117 0 1 357.496 15.6289)"
              fill="#B8C1FD"
              fillOpacity="0.4"
            />
          </g>
          <rect
            x="0.377475"
            y="0.541601"
            width="73.2265"
            height="113.461"
            transform="matrix(0.938191 0.346117 0 1 357.519 15.4983)"
            stroke="#979797"
            strokeWidth="0.804687"
          />
          <path
            d="M399.74 54.9163L397.391 53.9903C396.792 53.7543 396.285 53.0784 396.285 52.5158V52.2994C396.285 51.7368 396.792 51.4605 397.391 51.6965L399.74 52.6226C400.339 52.8586 400.846 53.5344 400.846 54.097V54.3134C400.846 54.8977 400.362 55.1615 399.74 54.9163Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M383.486 51.5674C383.763 51.6784 383.832 51.7062 384.2 51.8542C384.891 52.1318 385.397 52.8526 385.397 53.5499C385.397 54.2696 384.868 54.5516 384.154 54.2647C382.819 53.7281 381.99 53.395 380.655 52.8584C380.148 52.6548 379.734 52.3084 379.527 51.7528C379.343 51.3864 379.228 50.5305 379.964 50.1518C380.448 49.7838 385.788 45.9703 386.732 45.3151C386.778 45.2661 386.732 45.1577 386.663 45.1299C386.64 45.1206 386.64 45.1206 386.617 45.1114C384.614 44.3064 382.612 43.5015 380.609 42.6966C380.01 42.456 379.55 41.9111 379.435 41.3251C379.297 40.6398 379.573 40.211 380.102 40.1765C380.264 40.1737 380.425 40.216 380.586 40.2808C383.555 41.4743 386.502 42.6586 389.472 43.8522C390.047 44.0835 390.439 44.4207 390.669 45.0305C390.968 45.803 390.646 46.1233 390.439 46.3324C390.024 46.6831 385.811 49.6231 383.486 51.2975C383.463 51.3108 383.44 51.3465 383.394 51.3505C383.348 51.3995 383.394 51.5304 383.486 51.5674Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M389.744 56.5178L387.395 55.5911C386.796 55.3549 386.289 54.667 386.289 54.0903V53.8685C386.289 53.2917 386.796 53.0036 387.395 53.2398L389.744 54.1665C390.343 54.4027 390.85 55.0905 390.85 55.6673V55.8891C390.85 56.488 390.343 56.754 389.744 56.5178Z"
            fill="#141B4D"
            fillOpacity="0.7"
            stroke="#767676"
            strokeWidth="0.0801231"
            strokeMiterlimit="10"
          />
          <path
            d="M393.011 51.0662C393.011 49.3597 393.011 47.6758 393.011 45.9693C393.011 45.2957 393.494 45.0185 394.185 45.2959C397.176 46.4984 400.168 47.7008 403.159 48.9033C403.849 49.1808 404.333 49.869 404.333 50.565C404.333 51.2611 403.849 51.5833 403.159 51.3058C400.674 50.3068 398.212 49.3171 395.727 48.3181C395.542 48.2442 395.496 48.2706 395.496 48.4502C395.496 50.8751 395.496 53.3 395.496 55.725C395.496 55.9271 395.565 55.9773 395.727 56.042C398.189 57.0317 400.674 58.0307 403.136 59.0204C403.688 59.2424 404.126 59.7324 404.287 60.2687C404.471 60.904 404.264 61.3821 403.826 61.5207C403.619 61.5722 403.366 61.5378 403.113 61.4361C400.168 60.2521 397.199 59.0589 394.254 57.875C393.494 57.5697 393.034 56.8908 393.034 56.1498C393.011 54.4341 393.011 52.7502 393.011 51.0662Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M380.968 58.1944V58.83L379.389 58.1891V57.5534L379.784 57.7136V55.9883L379.436 55.8469V55.2112L380.945 55.8239V56.4596L380.596 56.3182V58.0435L380.968 58.1944Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M384.512 60.1966L383.808 59.9144L383.694 59.6655C383.444 59.7009 383.149 59.6503 382.877 59.5411C382.104 59.2089 381.377 58.3755 381.377 57.4042C381.377 56.4781 381.991 56.114 382.922 56.4873C383.694 56.7968 384.444 57.6168 384.444 58.6333C384.444 58.9947 384.353 59.2293 384.217 59.4232L384.535 60.0927V60.2057L384.512 60.1966ZM382.899 58.8274C383.263 58.973 383.649 58.8567 383.649 58.3146C383.649 57.7725 383.263 57.3466 382.899 57.201C382.513 57.0462 382.15 57.1716 382.15 57.7137C382.15 58.2558 382.513 58.6726 382.899 58.8274Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M386.594 59.938C386.594 60.141 386.822 60.3453 387.072 60.4461C387.299 60.5377 387.526 60.5166 387.526 60.3587C387.526 60.0656 387.185 59.9282 386.981 59.8232C386.435 59.5582 385.89 59.1354 385.89 58.5041C385.89 57.8502 386.435 57.7544 387.049 58.0018C387.617 58.2309 388.208 58.7847 388.208 59.4386L387.458 59.1363C387.458 58.9333 387.254 58.7607 387.049 58.6782C386.867 58.6049 386.64 58.5584 386.64 58.7839C386.64 58.9868 386.822 59.1052 387.094 59.2377C387.731 59.5393 388.276 59.917 388.276 60.6385C388.276 61.2699 387.663 61.3382 387.049 61.0908C386.412 60.8343 385.799 60.2712 385.821 59.6265L386.594 59.938Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M390.71 60.1213L389.607 59.6752V60.1083L390.618 60.5173V61.2012L389.607 60.7922V61.271L390.733 61.7264V62.4331L388.803 61.6524V58.666L390.687 59.4281V60.112L390.71 60.1213Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M393.832 63.4631V63.5765L393.017 63.2483L392.496 62.1088L392.088 61.9447V62.8745L391.318 62.5645V59.5938C391.771 59.7761 392.201 59.9493 392.632 60.1225C393.356 60.4143 393.696 61.0953 393.696 61.6169C393.696 61.957 393.538 62.2334 393.243 62.2963L393.832 63.4631ZM392.632 61.5285C392.858 61.6197 392.949 61.4748 392.949 61.2707C392.949 61.0892 392.835 60.8622 392.632 60.7802L392.088 60.5614V61.3097L392.632 61.5285Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M395.382 62.7975L395.638 62.1288L396.011 61.3934L396.872 61.7392V61.8527L395.545 64.2243L395.218 64.0935L393.891 60.6564V60.543L394.753 60.8888L395.149 61.9326L395.382 62.7975Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M398.626 64.709V65.3447L397.048 64.7037V64.068L397.443 64.2283V62.5029L397.094 62.3615V61.7259L398.603 62.3386V62.9742L398.255 62.8328V64.5582L398.626 64.709Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M400.527 63.0412C401.151 63.2916 401.845 63.9076 401.96 64.7422L401.198 64.4362C401.082 64.0745 400.851 63.8692 400.527 63.7394C400.065 63.5539 399.764 63.7487 399.764 64.2441C399.764 64.672 400.065 65.1754 400.527 65.3609C400.851 65.4907 401.128 65.4443 401.198 65.1569L401.96 65.4629C401.845 66.2498 401.174 66.3187 400.527 66.059C399.741 65.7438 398.979 64.9198 398.979 63.9288C398.979 62.9154 399.741 62.7259 400.527 63.0412Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M404.331 65.1467L403.228 64.7006V65.1337L404.239 65.5427V66.2266L403.228 65.8176V66.2964L404.354 66.7518V67.4585L402.424 66.6778V63.6914L404.308 64.4535V65.1374L404.331 65.1467Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M399.74 180.448L397.391 179.522C396.792 179.285 396.285 178.61 396.285 178.047V177.831C396.285 177.268 396.792 176.992 397.391 177.228L399.74 178.154C400.339 178.39 400.846 179.066 400.846 179.628V179.845C400.846 180.429 400.362 180.693 399.74 180.448Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M383.486 177.099C383.763 177.21 383.832 177.237 384.2 177.385C384.891 177.663 385.397 178.384 385.397 179.081C385.397 179.801 384.868 180.083 384.154 179.796C382.819 179.259 381.99 178.926 380.655 178.39C380.148 178.186 379.734 177.84 379.527 177.284C379.343 176.918 379.228 176.062 379.964 175.683C380.448 175.315 385.788 171.502 386.732 170.846C386.778 170.797 386.732 170.689 386.663 170.661C386.64 170.652 386.64 170.652 386.617 170.643C384.614 169.838 382.612 169.033 380.609 168.228C380.01 167.987 379.55 167.442 379.435 166.856C379.297 166.171 379.573 165.742 380.102 165.708C380.264 165.705 380.425 165.747 380.586 165.812C383.555 167.006 386.502 168.19 389.472 169.383C390.047 169.615 390.439 169.952 390.669 170.562C390.968 171.334 390.646 171.655 390.439 171.864C390.024 172.214 385.811 175.154 383.486 176.829C383.463 176.842 383.44 176.878 383.394 176.882C383.348 176.931 383.394 177.062 383.486 177.099Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M389.744 182.049L387.395 181.122C386.796 180.886 386.289 180.198 386.289 179.622V179.4C386.289 178.823 386.796 178.535 387.395 178.771L389.744 179.698C390.343 179.934 390.85 180.622 390.85 181.199V181.42C390.85 182.019 390.343 182.285 389.744 182.049Z"
            fill="#141B4D"
            fillOpacity="0.7"
            stroke="#767676"
            strokeWidth="0.0801231"
            strokeMiterlimit="10"
          />
          <path
            d="M393.011 176.597C393.011 174.891 393.011 173.207 393.011 171.501C393.011 170.827 393.494 170.55 394.185 170.827C397.176 172.03 400.168 173.232 403.159 174.435C403.849 174.712 404.333 175.4 404.333 176.096C404.333 176.792 403.849 177.115 403.159 176.837C400.674 175.838 398.212 174.848 395.727 173.849C395.542 173.775 395.496 173.802 395.496 173.981C395.496 176.406 395.496 178.831 395.496 181.256C395.496 181.458 395.565 181.509 395.727 181.573C398.189 182.563 400.674 183.562 403.136 184.552C403.688 184.774 404.126 185.264 404.287 185.8C404.471 186.435 404.264 186.913 403.826 187.052C403.619 187.103 403.366 187.069 403.113 186.967C400.168 185.783 397.199 184.59 394.254 183.406C393.494 183.101 393.034 182.422 393.034 181.681C393.011 179.965 393.011 178.281 393.011 176.597Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M380.968 183.726V184.361L379.389 183.72V183.085L379.784 183.245V181.52L379.436 181.378V180.742L380.945 181.355V181.991L380.596 181.849V183.575L380.968 183.726Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M384.512 185.728L383.808 185.446L383.694 185.197C383.444 185.232 383.149 185.182 382.877 185.072C382.104 184.74 381.377 183.907 381.377 182.935C381.377 182.009 381.991 181.645 382.922 182.019C383.694 182.328 384.444 183.148 384.444 184.165C384.444 184.526 384.353 184.761 384.217 184.954L384.535 185.624V185.737L384.512 185.728ZM382.899 184.359C383.263 184.504 383.649 184.388 383.649 183.846C383.649 183.304 383.263 182.878 382.899 182.732C382.513 182.577 382.15 182.703 382.15 183.245C382.15 183.787 382.513 184.204 382.899 184.359Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M386.594 185.469C386.594 185.672 386.822 185.877 387.072 185.977C387.299 186.069 387.526 186.048 387.526 185.89C387.526 185.597 387.185 185.459 386.981 185.354C386.435 185.089 385.89 184.667 385.89 184.035C385.89 183.381 386.435 183.286 387.049 183.533C387.617 183.762 388.208 184.316 388.208 184.97L387.458 184.668C387.458 184.465 387.254 184.292 387.049 184.209C386.867 184.136 386.64 184.09 386.64 184.315C386.64 184.518 386.822 184.636 387.094 184.769C387.731 185.071 388.276 185.448 388.276 186.17C388.276 186.801 387.663 186.869 387.049 186.622C386.412 186.366 385.799 185.803 385.821 185.158L386.594 185.469Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M390.71 185.653L389.607 185.206V185.64L390.618 186.049V186.732L389.607 186.323V186.802L390.733 187.258V187.964L388.803 187.184V184.197L390.687 184.959V185.643L390.71 185.653Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M393.832 188.994V189.108L393.017 188.78L392.496 187.64L392.088 187.476V188.406L391.318 188.096V185.125C391.771 185.307 392.201 185.481 392.632 185.654C393.356 185.946 393.696 186.627 393.696 187.148C393.696 187.488 393.538 187.765 393.243 187.828L393.832 188.994ZM392.632 187.06C392.858 187.151 392.949 187.006 392.949 186.802C392.949 186.62 392.835 186.393 392.632 186.311L392.088 186.093V186.841L392.632 187.06Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M395.382 188.329L395.638 187.66L396.011 186.925L396.872 187.27V187.384L395.545 189.756L395.218 189.625L393.891 186.188V186.074L394.753 186.42L395.149 187.464L395.382 188.329Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M398.626 190.24V190.876L397.048 190.235V189.599L397.443 189.76V188.034L397.094 187.893V187.257L398.603 187.87V188.505L398.255 188.364V190.089L398.626 190.24Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M400.527 188.572C401.151 188.823 401.845 189.439 401.96 190.273L401.198 189.967C401.082 189.606 400.851 189.4 400.527 189.271C400.065 189.085 399.764 189.28 399.764 189.775C399.764 190.203 400.065 190.707 400.527 190.892C400.851 191.022 401.128 190.976 401.198 190.688L401.96 190.994C401.845 191.781 401.174 191.85 400.527 191.59C399.741 191.275 398.979 190.451 398.979 189.46C398.979 188.447 399.741 188.257 400.527 188.572Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M404.331 190.678L403.228 190.232V190.665L404.239 191.074V191.758L403.228 191.349V191.828L404.354 192.283V192.99L402.424 192.209V189.223L404.308 189.985V190.669L404.331 190.678Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <rect
            x="2.64233"
            y="3.79121"
            width="79.664"
            height="119.898"
            transform="matrix(0.938191 0.346117 0 1 352.374 7.13134)"
            stroke="#F3F4F8"
            strokeWidth="5.63281"
          />
          <rect
            x="2.64233"
            y="3.79121"
            width="79.664"
            height="120.703"
            transform="matrix(0.938191 0.346117 0 1 352.374 132.663)"
            stroke="#F3F4F8"
            strokeWidth="5.63281"
          />
          <rect
            width="90.9296"
            height="4.02343"
            transform="matrix(0.938191 0.346117 0 1 352.211 4.02344)"
            fill="#C4C4C4"
          />
          <rect
            width="90.9296"
            height="4.02343"
            transform="matrix(0.938191 0.346117 0 1 352.211 259.914)"
            fill="#C4C4C4"
          />
          <path d="M437.52 35.4961L432.235 37.5699V289.437L437.52 295.41V35.4961Z" fill="#E9EBEF" />
          <path d="M437.52 35.4961L432.235 37.5699V289.437L437.52 295.41V35.4961Z" fill="#CED0D4" />
          <rect
            width="4.02343"
            height="259.914"
            transform="matrix(0.938191 0.346117 0 1 437.52 35.4961)"
            fill="white"
          />
          <rect
            width="94.9531"
            height="4.02343"
            transform="matrix(0.938191 0.346117 0 1 352.211 0)"
            fill="white"
          />
          <path
            d="M7.00049 296.929L96.3207 264.024L96.3208 268.047L7.00049 300.952V296.929Z"
            fill="white"
          />
          <g filter="url(#filter8_i)">
            <path
              d="M21.3442 166.871L91.0901 141.172V256.38L21.3442 281.941V166.871Z"
              fill="#B8C1FD"
              fillOpacity="0.4"
            />
          </g>
          <path
            d="M21.7217 281.401V167.134L90.7126 141.714V256.116L21.7217 281.401Z"
            stroke="#979797"
            strokeWidth="0.804687"
          />
          <g filter="url(#filter9_i)">
            <path
              d="M21.3442 41.3398L91.0901 15.6713V130.044L21.3442 155.605V41.3398Z"
              fill="#B8C1FD"
              fillOpacity="0.4"
            />
          </g>
          <path
            d="M21.7217 155.065V41.6033L90.7126 16.2126V129.78L21.7217 155.065Z"
            stroke="#979797"
            strokeWidth="0.804687"
          />
          <path
            d="M63.5882 49.3997L61.2389 50.3258C60.64 50.5618 60.1333 50.2855 60.1333 49.7229V49.5065C60.1333 48.9438 60.64 48.268 61.2389 48.032L63.5882 47.1059C64.187 46.8699 64.6937 47.1462 64.6937 47.7089V47.9252C64.6937 48.5095 64.2101 49.1546 63.5882 49.3997Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M47.3356 58.2075C47.6118 58.0965 47.6809 58.0687 48.0492 57.9207C48.7398 57.6431 49.2462 57.9568 49.2462 58.654C49.2462 59.3737 48.7168 60.0813 48.0031 60.3682C46.668 60.9048 45.8392 61.2379 44.5041 61.7745C43.9976 61.9781 43.5832 61.9647 43.3761 61.5756C43.1919 61.3573 43.0768 60.5939 43.8134 59.6231C44.2969 58.8665 49.6376 50.7599 50.5814 49.346C50.6275 49.26 50.5814 49.1886 50.5124 49.2163C50.4893 49.2256 50.4893 49.2256 50.4663 49.2348C48.4635 50.0398 46.4608 50.8447 44.458 51.6497C43.8595 51.8902 43.3991 51.7154 43.284 51.2219C43.1459 50.6477 43.4221 49.9969 43.9516 49.5367C44.1127 49.4044 44.2739 49.3172 44.435 49.2524C47.4046 48.0589 50.3512 46.8746 53.3208 45.681C53.8963 45.4497 54.2877 45.4724 54.5179 45.8971C54.8172 46.4291 54.4949 47.0084 54.2877 47.3841C53.8733 48.0679 49.6606 54.3942 47.3356 57.9376C47.3125 57.9693 47.2895 58.0236 47.2435 58.0646C47.1974 58.1505 47.2435 58.2445 47.3356 58.2075Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M53.5921 58.3743L51.2428 59.301C50.6439 59.5372 50.1372 59.249 50.1372 58.6723V58.4505C50.1372 57.8737 50.6439 57.1859 51.2428 56.9497L53.5921 56.023C54.1909 55.7868 54.6977 56.0749 54.6977 56.6517V56.8735C54.6977 57.4724 54.1909 58.1381 53.5921 58.3743Z"
            fill="#141B4D"
            fillOpacity="0.7"
            stroke="#767676"
            strokeWidth="0.0801231"
            strokeMiterlimit="10"
          />
          <path
            d="M56.8604 50.949C56.8604 49.2426 56.8604 47.5586 56.8604 45.8521C56.8604 45.1785 57.3436 44.5128 58.0339 44.2353C61.0254 43.0329 64.0168 41.8304 67.0082 40.628C67.6986 40.3505 68.1818 40.6502 68.1818 41.3462C68.1818 42.0423 67.6986 42.753 67.0082 43.0304C64.523 44.0294 62.0608 45.0191 59.5757 46.0181C59.3916 46.0921 59.3455 46.1555 59.3455 46.3351C59.3455 48.76 59.3455 51.1849 59.3455 53.6099C59.3455 53.812 59.4146 53.8067 59.5757 53.7419C62.0378 52.7522 64.523 51.7533 66.9852 50.7635C67.5375 50.5416 67.9747 50.6802 68.1358 51.0869C68.3199 51.5743 68.1128 52.2188 67.6755 52.7089C67.4684 52.9269 67.2153 53.096 66.9622 53.1977C64.0168 54.3817 61.0484 55.5749 58.1029 56.7588C57.3436 57.0641 56.8834 56.7551 56.8834 56.0141C56.8604 54.3169 56.8604 52.633 56.8604 50.949Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M44.8164 66.5296V67.1653L43.2378 67.8062V67.1706L43.6324 67.0103V65.285L43.2842 65.4264V64.7907L44.7932 64.178V64.8137L44.445 64.9551V66.6804L44.8164 66.5296Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M48.3606 65.8752L47.6565 66.1574L47.5429 65.9997C47.293 66.2353 46.9978 66.4215 46.7252 66.5307C45.9529 66.8177 45.2261 66.5669 45.2261 65.5956C45.2261 64.6695 45.8394 63.8138 46.7706 63.4405C47.5429 63.1309 48.2925 63.35 48.2925 64.3665C48.2925 64.7279 48.2016 65.0354 48.0653 65.3385L48.3833 65.7532V65.8661L48.3606 65.8752ZM46.7479 65.7988C47.1113 65.6531 47.4975 65.2273 47.4975 64.6852C47.4975 64.143 47.1113 64.0267 46.7479 64.1724C46.3618 64.3272 45.9983 64.7439 45.9983 65.286C45.9983 65.8282 46.3618 65.9536 46.7479 65.7988Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M50.4424 64.21C50.4424 64.413 50.6697 64.4341 50.9198 64.3333C51.1471 64.2417 51.3744 64.0373 51.3744 63.8795C51.3744 63.5864 51.0335 63.7238 50.8289 63.7837C50.2833 63.9585 49.7378 63.9755 49.7378 63.3441C49.7378 62.6903 50.2833 62.1547 50.8971 61.9074C51.4654 61.6783 52.0564 61.7558 52.0564 62.4097L51.3062 62.712C51.3062 62.5091 51.1017 62.5013 50.8971 62.5838C50.7152 62.6571 50.4879 62.7938 50.4879 63.0193C50.4879 63.2222 50.6697 63.194 50.9425 63.1066C51.579 62.8952 52.1246 62.8331 52.1246 63.5546C52.1246 64.186 51.5108 64.749 50.8971 64.9964C50.2606 65.2529 49.6468 65.1846 49.6696 64.5215L50.4424 64.21Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M54.5578 61.2592L53.4553 61.7053V62.1384L54.4659 61.7295V62.4134L53.4553 62.8223V63.301L54.5808 62.8456V63.5523L52.6514 64.333V61.3467L54.5348 60.5846V61.2685L54.5578 61.2592Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M57.6806 62.28V62.3934L56.8652 62.7216L56.3443 62.0015L55.9366 62.1656V63.0954L55.1665 63.4053V60.4346C55.6195 60.2522 56.0498 60.079 56.4802 59.9058C57.205 59.6141 57.5447 60.0216 57.5447 60.5431C57.5447 60.8833 57.3862 61.2873 57.0917 61.5872L57.6806 62.28ZM56.4802 61.3118C56.7067 61.2206 56.7973 61.0028 56.7973 60.7987C56.7973 60.6172 56.684 60.4814 56.4802 60.5634L55.9366 60.7822V61.5306L56.4802 61.3118Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M59.2302 60.5553L59.4864 59.6811L59.8592 58.6466L60.7211 58.3008V58.4143L59.3932 61.8513L59.0671 61.9822L57.7393 59.6105V59.4971L58.6012 59.1513L58.9972 59.8773L59.2302 60.5553Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M62.4746 60.014V60.6497L60.896 61.2906V60.655L61.2907 60.4947V58.7694L60.9424 58.9107V58.2751L62.4514 57.6624V58.2981L62.1032 58.4395V60.1648L62.4746 60.014Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M64.3759 56.9998C65 56.7494 65.6934 56.809 65.809 57.5509L65.0462 57.8569C64.9306 57.588 64.6995 57.5681 64.3759 57.6979C63.9136 57.8834 63.6131 58.3192 63.6131 58.8147C63.6131 59.2426 63.9136 59.5049 64.3759 59.3194C64.6995 59.1896 64.9768 58.9207 65.0462 58.5776L65.809 58.2716C65.6934 59.1512 65.0231 59.7579 64.3759 60.0176C63.5899 60.3329 62.8271 60.1209 62.8271 59.13C62.8271 58.1166 63.5899 57.3151 64.3759 56.9998Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M68.1794 56.2348L67.0769 56.6809V57.114L68.0875 56.7051V57.389L67.0769 57.7979V58.2766L68.2024 57.8212V58.5279L66.2729 59.3086V56.3223L68.1564 55.5602V56.244L68.1794 56.2348Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M63.5887 174.93L61.2393 175.856C60.6405 176.092 60.1338 175.816 60.1338 175.253V175.037C60.1338 174.474 60.6405 173.798 61.2393 173.562L63.5887 172.636C64.1875 172.4 64.6942 172.677 64.6942 173.239V173.456C64.6942 174.04 64.2106 174.685 63.5887 174.93Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M47.336 183.737C47.6123 183.626 47.6813 183.598 48.0497 183.45C48.7403 183.172 49.2467 183.486 49.2467 184.183C49.2467 184.903 48.7173 185.611 48.0036 185.897C46.6685 186.434 45.8397 186.767 44.5045 187.304C43.9981 187.507 43.5837 187.494 43.3766 187.105C43.1924 186.887 43.0773 186.123 43.8139 185.152C44.2974 184.396 49.6381 176.289 50.5819 174.875C50.6279 174.789 50.5819 174.718 50.5128 174.746C50.4898 174.755 50.4898 174.755 50.4668 174.764C48.464 175.569 46.4613 176.374 44.4585 177.179C43.86 177.42 43.3996 177.245 43.2845 176.751C43.1463 176.177 43.4226 175.526 43.9521 175.066C44.1132 174.934 44.2743 174.846 44.4355 174.782C47.4051 173.588 50.3517 172.404 53.3213 171.21C53.8968 170.979 54.2882 171.002 54.5184 171.426C54.8176 171.958 54.4954 172.538 54.2882 172.913C53.8738 173.597 49.6611 179.923 47.336 183.467C47.313 183.499 47.29 183.553 47.244 183.594C47.1979 183.68 47.244 183.774 47.336 183.737Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M53.5926 183.904L51.2433 184.83C50.6444 185.066 50.1377 184.778 50.1377 184.202V183.98C50.1377 183.403 50.6444 182.715 51.2433 182.479L53.5926 181.552C54.1914 181.316 54.6981 181.604 54.6981 182.181V182.403C54.6981 183.002 54.1914 183.667 53.5926 183.904Z"
            fill="#141B4D"
            fillOpacity="0.7"
            stroke="#767676"
            strokeWidth="0.0801231"
            strokeMiterlimit="10"
          />
          <path
            d="M56.8608 176.479C56.8608 174.773 56.8608 173.089 56.8608 171.382C56.8608 170.709 57.3441 170.043 58.0344 169.766C61.0258 168.563 64.0173 167.361 67.0087 166.158C67.699 165.881 68.1823 166.18 68.1823 166.877C68.1823 167.573 67.699 168.283 67.0087 168.561C64.5235 169.56 62.0613 170.549 59.5761 171.548C59.3921 171.622 59.346 171.686 59.346 171.865C59.346 174.29 59.346 176.715 59.346 179.14C59.346 179.342 59.4151 179.337 59.5761 179.272C62.0383 178.282 64.5235 177.284 66.9857 176.294C67.538 176.072 67.9752 176.21 68.1363 176.617C68.3203 177.105 68.1132 177.749 67.676 178.239C67.4689 178.457 67.2158 178.626 66.9627 178.728C64.0173 179.912 61.0488 181.105 58.1034 182.289C57.3441 182.594 56.8839 182.285 56.8839 181.544C56.8608 179.847 56.8608 178.163 56.8608 176.479Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M44.8169 192.059V192.695L43.2383 193.336V192.7L43.6329 192.54V190.814L43.2847 190.956V190.32L44.7937 189.707V190.343L44.4455 190.484V192.21L44.8169 192.059Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M48.3611 191.405L47.657 191.687L47.5434 191.529C47.2935 191.765 46.9982 191.951 46.7257 192.06C45.9534 192.347 45.2266 192.096 45.2266 191.125C45.2266 190.199 45.8398 189.343 46.7711 188.97C47.5434 188.66 48.2929 188.879 48.2929 189.896C48.2929 190.257 48.2021 190.565 48.0658 190.868L48.3838 191.282V191.395L48.3611 191.405ZM46.7484 191.328C47.1118 191.182 47.498 190.757 47.498 190.214C47.498 189.672 47.1118 189.556 46.7484 189.702C46.3623 189.856 45.9988 190.273 45.9988 190.815C45.9988 191.357 46.3623 191.483 46.7484 191.328Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M50.4429 189.74C50.4429 189.943 50.6702 189.964 50.9203 189.864C51.1476 189.772 51.3749 189.568 51.3749 189.41C51.3749 189.117 51.0339 189.254 50.8294 189.314C50.2838 189.489 49.7382 189.506 49.7382 188.874C49.7382 188.221 50.2838 187.685 50.8976 187.438C51.4658 187.209 52.0569 187.286 52.0569 187.94L51.3067 188.242C51.3067 188.039 51.1021 188.032 50.8976 188.114C50.7157 188.187 50.4884 188.324 50.4884 188.55C50.4884 188.752 50.6702 188.724 50.943 188.637C51.5795 188.425 52.1251 188.363 52.1251 189.085C52.1251 189.716 51.5113 190.279 50.8976 190.527C50.2611 190.783 49.6473 190.715 49.67 190.052L50.4429 189.74Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M54.5583 186.789L53.4558 187.236V187.669L54.4664 187.26V187.944L53.4558 188.353V188.831L54.5813 188.376V189.083L52.6519 189.863V186.877L54.5353 186.115V186.799L54.5583 186.789Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M57.6811 187.809V187.923L56.8657 188.251L56.3448 187.531L55.9371 187.695V188.625L55.167 188.935V185.964C55.62 185.782 56.0503 185.608 56.4807 185.435C57.2054 185.143 57.5452 185.551 57.5452 186.072C57.5452 186.413 57.3866 186.817 57.0922 187.117L57.6811 187.809ZM56.4807 186.841C56.7072 186.75 56.7978 186.532 56.7978 186.328C56.7978 186.147 56.6845 186.011 56.4807 186.093L55.9371 186.312V187.06L56.4807 186.841Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M59.2307 186.086L59.4869 185.211L59.8596 184.177L60.7216 183.831V183.945L59.3937 187.382L59.0676 187.512L57.7397 185.141V185.027L58.6017 184.682L58.9977 185.408L59.2307 186.086Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M62.4751 185.545V186.181L60.8965 186.822V186.186L61.2911 186.026V184.301L60.9429 184.442V183.806L62.4519 183.194V183.829L62.1037 183.971V185.696L62.4751 185.545Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M64.3763 182.529C65.0004 182.279 65.6939 182.338 65.8095 183.08L65.0467 183.386C64.9311 183.117 64.7 183.097 64.3763 183.227C63.914 183.413 63.6135 183.849 63.6135 184.344C63.6135 184.772 63.914 185.034 64.3763 184.849C64.7 184.719 64.9773 184.45 65.0467 184.107L65.8095 183.801C65.6939 184.681 65.0236 185.287 64.3763 185.547C63.5904 185.862 62.8276 185.65 62.8276 184.659C62.8276 183.646 63.5904 182.844 64.3763 182.529Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M68.1799 181.762L67.0774 182.208V182.641L68.088 182.232V182.916L67.0774 183.325V183.804L68.2029 183.349V184.055L66.2734 184.836V181.85L68.1569 181.087V181.771L68.1799 181.762Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M18.7024 159.399V39.5007L93.6786 11.9219V131.82L18.7024 159.399Z"
            stroke="#F3F4F8"
            strokeWidth="5.63281"
          />
          <path
            d="M18.7024 285.735V165.032L93.6786 137.453V258.156L18.7024 285.735Z"
            stroke="#F3F4F8"
            strokeWidth="5.63281"
          />
          <path
            d="M10.7749 35.583L96.3204 4.11063V8.13407L10.7749 39.6064V35.583Z"
            fill="#C4C4C4"
          />
          <path
            d="M10.7749 291.474L96.3204 260.001V264.025L10.7749 295.55V291.474Z"
            fill="#C4C4C4"
          />
          <path
            d="M10.7749 35.583L16.0596 37.6568V289.524L10.7749 295.497V35.583Z"
            fill="#E9EBEF"
          />
          <path
            d="M10.7749 35.583L16.0596 37.6568V289.524L10.7749 295.497V35.583Z"
            fill="#CED0D4"
          />
          <path d="M7 37.0479L10.7748 35.6553V295.569L7 296.962V37.0479Z" fill="white" />
          <path d="M7 32.9521L96.3202 0.0872899V4.11072L7 36.9756V32.9521Z" fill="white" />
        </g>
        <defs>
          <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use
              xlinkHref="#image0"
              transform="translate(-0.0689422 -0.0621392) scale(0.00112263 0.00162521)"
            />
          </pattern>
          <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlinkHref="#image0" transform="translate(-0.0316294) scale(0.00147604)" />
          </pattern>
          <filter
            id="filter0_i"
            x="101.953"
            y="13.6787"
            width="75.4167"
            height="118.422"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1.38545" dy="4.15636" />
            <feGaussianBlur stdDeviation="2.77091" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter1_i"
            x="187.25"
            y="13.6787"
            width="75.4167"
            height="118.422"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1.38545" dy="4.15636" />
            <feGaussianBlur stdDeviation="2.77091" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter2_i"
            x="272.547"
            y="13.6787"
            width="75.4167"
            height="118.422"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1.38545" dy="4.15636" />
            <feGaussianBlur stdDeviation="2.77091" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter3_i"
            x="101.953"
            y="139.211"
            width="75.4167"
            height="119.227"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1.38545" dy="4.15636" />
            <feGaussianBlur stdDeviation="2.77091" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter4_i"
            x="187.25"
            y="139.211"
            width="75.4167"
            height="119.227"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1.38545" dy="4.15636" />
            <feGaussianBlur stdDeviation="2.77091" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter5_i"
            x="272.547"
            y="139.211"
            width="75.4167"
            height="119.227"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1.38545" dy="4.15636" />
            <feGaussianBlur stdDeviation="2.77091" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter6_i"
            x="357.496"
            y="141.16"
            width="70.8409"
            height="144.85"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1.38545" dy="4.15636" />
            <feGaussianBlur stdDeviation="2.77091" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter7_i"
            x="357.496"
            y="15.6289"
            width="70.8409"
            height="144.045"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1.38545" dy="4.15636" />
            <feGaussianBlur stdDeviation="2.77091" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter8_i"
            x="21.3442"
            y="141.173"
            width="71.1313"
            height="144.925"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1.38545" dy="4.15636" />
            <feGaussianBlur stdDeviation="2.77091" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter9_i"
            x="21.3442"
            y="15.6709"
            width="71.1313"
            height="144.09"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1.38545" dy="4.15636" />
            <feGaussianBlur stdDeviation="2.77091" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <clipPath id="clip0">
            <rect width="449" height="333" fill="white" />
          </clipPath>
        </defs>
      </svg>

      {!withoutBackground && <ContainerBackground />}
    </ProductImageWrapper>
  )
}
