import React, { useCallback, useState } from 'react'
import fetchAPI from '../../lib/utils/fetch-api'
import { CalculatorType } from '../views/calculator/interface'

const typesProduct = {
  window: 1,
}
export const useFilteredProducts = () => {
  const [filteredProducts, setFilteredProducts] = useState([])

  const handleFetchFilteredProducts = useCallback(
    async (type = CalculatorType.WINDOW, filters = []) => {
      // const url = `pim/${
      //   typesProduct[type]
      // }/products?filter[attrs]=1,2,3,4,5&filter[selected]=${JSON.stringify(filters)}`
      // const response = await fetchAPI(url)
    },
    [],
  )

  return { filteredProducts, fetchFilteredProducts: handleFetchFilteredProducts }
}
