import styled, { css } from 'styled-components'
import ReactSlider from 'react-slider'
import { colorCode } from '../../consts'

export const SliderWrapper = styled.div`
  margin-bottom: 34px;

  position: relative;
`

export const SliderContent = styled.div<{ left: number; right: number }>`
  ${({ left = 0, right = 0 }) => css`
    padding-left: ${left}%;
    padding-right: ${right}%;
  `};

  position: relative;

  &:before {
    content: '';
    display: block;
    background-color: #959595;

    position: absolute;

    top: 0;
    left: 0;

    width: ${({ left = 0 }) => left}%;
    height: 2px;
  }

  &:after {
    content: '';
    display: block;
    background-color: #1c3775;

    position: absolute;

    top: 0;
    right: 0;

    width: ${({ right = 0 }) => right}%;
    height: 2px;
  }
`

export const SliderTitle = styled.span`
  margin-bottom: 16px;

  font-size: 14px;
  font-weight: 400;

  display: flex;
  align-items: center;

  & > span {
    margin-right: 4px;
  }
`

export const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 2px;

  margin-bottom: 11px;
`

export const ThumbText = styled.span`
  position: relative;
  top: 24px;
  left: -80%;

  font-size: 13px;
  color: #333333;
`

export const StyledThumb = styled.div<{ type: number }>`
  ${({ type = 0 }) =>
    type === 0 &&
    css`
      background-color: #525252;
      height: 8px;
      width: 8px;
      top: -3px;
    `}
  ${({ type = 0 }) =>
    type === 1 &&
    css`
      background-color: #141b4d;
      box-shadow: 0 5px 10px rgb(20 27 77 / 30%), 0 0 0 3px rgb(20 27 77 / 30%);
      //border: 3px solid rgba(20, 27, 77, 0.3);
      //box-sizing: content-box;

      height: 12px;
      width: 12px;

      top: -5px;
    `}
  
  border-radius: 50%;

  line-height: 12px;
  text-align: center;
  color: #252525;

  cursor: grab;

  &:last-child {
    ${ThumbText} {
      font-weight: bold;
      color: #1c3775;
    }
  }
`

export const StyledTrack = styled.div<{ index: number }>`
  top: 0;
  bottom: 0;
  background: ${({ index }) => colorCode[index] || '#252525'};
`
