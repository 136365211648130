import React, { FC } from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'
import { IContainerSquareProps } from '../../interface'
import { ContainerBackground } from '../container-background'

export const ContainerSquare5VerticalCasement: FC<IContainerSquareProps> = props => {
  const { onCasementClick, withoutBackground } = props

  return (
    <ProductImageWrapper isClickable>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <rect width="220" height="333" fill="white" />
        <rect x="14" y="13" width="186" height="286" fill="#E8EAEE" />
        <rect x="14" y="13" width="186" height="286" fill="url(#5-v-pattern0)" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M214 6H6V288H24V24H196V288H214V6Z"
          fill="#C4C4C4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M214 306H6V322H0V330H6H214H220V322H214V306Z"
          fill="url(#5-v-paint0_linear)"
        />
        <path d="M6 6.5L20 23.5V306L6 322V6.5Z" fill="#E9EBEF" />
        <path d="M6 6.5L20 23.5V306L6 322V6.5Z" fill="#020202" fillOpacity="0.06" />
        <path d="M214 6L200 23V306L214 322V6Z" fill="#E9EBEF" />
        <path d="M214 6L200 23V306L214 322V6Z" fill="#020202" fillOpacity="0.06" />
        <rect y="327" width="220" height="6" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M200 24H20V306H200V24ZM187 257H33V297H187V257ZM33 201H187V241H33V201ZM187 145H33V185H187V145ZM33 90H187V128.826H33V90ZM187 33H33V73H187V33Z"
          fill="#E8EAEE"
        />
        <g
          filter="url(#5-v-filter0_i)"
          className="casement"
          data-index="4"
          onClick={onCasementClick(4)}
        >
          <rect x="33" y="257" width="154" height="40" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="33.47"
          y="257.47"
          width="153.06"
          height="39.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#5-v-filter1_i)"
          className="casement"
          data-index="3"
          onClick={onCasementClick(3)}
        >
          <rect x="33" y="201" width="154" height="40" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="33.47"
          y="201.47"
          width="153.06"
          height="39.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#5-v-filter2_i)"
          className="casement"
          data-index="2"
          onClick={onCasementClick(2)}
        >
          <rect x="33" y="145" width="154" height="40" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="33.47"
          y="145.47"
          width="153.06"
          height="39.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#5-v-filter3_i)"
          className="casement"
          data-index="1"
          onClick={onCasementClick(1)}
        >
          <rect x="33" y="89" width="154" height="40" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="33.47"
          y="89.47"
          width="153.06"
          height="39.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#5-v-filter4_i)"
          className="casement"
          data-index="0"
          onClick={onCasementClick(0)}
        >
          <rect x="33" y="33" width="154" height="40" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="33.47"
          y="33.47"
          width="153.06"
          height="39.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <path
          d="M118.985 274.741H116.447C115.8 274.741 115.253 274.235 115.253 273.636V273.405C115.253 272.807 115.8 272.3 116.447 272.3H118.985C119.632 272.3 120.179 272.807 120.179 273.405V273.636C120.179 274.258 119.657 274.741 118.985 274.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M101.426 277.565C101.725 277.565 101.799 277.565 102.197 277.565C102.943 277.565 103.49 278.105 103.49 278.833C103.49 279.584 102.918 280.101 102.147 280.101C100.705 280.101 99.8098 280.101 98.3674 280.101C97.8203 280.101 97.3727 279.913 97.1488 279.42C96.9499 279.115 96.8255 278.27 97.6213 277.565C98.1436 276.978 103.913 270.757 104.933 269.677C104.982 269.606 104.933 269.512 104.858 269.512C104.833 269.512 104.833 269.512 104.808 269.512C102.645 269.512 100.481 269.512 98.3177 269.512C97.6711 269.512 97.1737 269.137 97.0494 268.573C96.9002 267.916 97.1986 267.352 97.7706 267.094C97.9446 267.023 98.1187 267 98.2928 267C101.501 267 104.684 267 107.892 267C108.514 267 108.937 267.188 109.185 267.728C109.509 268.409 109.16 268.878 108.937 269.183C108.489 269.723 103.938 274.56 101.426 277.283C101.401 277.307 101.376 277.354 101.327 277.377C101.277 277.448 101.327 277.565 101.426 277.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M108.186 280.101H105.648C105.001 280.101 104.453 279.582 104.453 278.968V278.732C104.453 278.119 105.001 277.6 105.648 277.6H108.186C108.832 277.6 109.38 278.119 109.38 278.732V278.968C109.38 279.605 108.832 280.101 108.186 280.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M111.715 273.515C111.715 271.734 111.715 269.976 111.715 268.195C111.715 267.492 112.237 267 112.983 267C116.215 267 119.446 267 122.678 267C123.424 267 123.946 267.516 123.946 268.242C123.946 268.969 123.424 269.508 122.678 269.508C119.993 269.508 117.333 269.508 114.649 269.508C114.45 269.508 114.4 269.555 114.4 269.742C114.4 272.273 114.4 274.804 114.4 277.335C114.4 277.546 114.475 277.57 114.649 277.57C117.309 277.57 119.993 277.57 122.653 277.57C123.25 277.57 123.722 277.898 123.896 278.39C124.095 278.976 123.871 279.562 123.399 279.89C123.175 280.03 122.902 280.101 122.628 280.101C119.446 280.101 116.24 280.101 113.058 280.101C112.237 280.101 111.74 279.585 111.74 278.812C111.715 277.031 111.715 275.273 111.715 273.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M98.7054 285.042V285.699H97V285.042H97.4263V283.259H97.0502V282.602H98.6803V283.259H98.3041V285.042H98.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M102.534 285.758H101.774L101.651 285.545C101.381 285.687 101.062 285.758 100.768 285.758C99.9334 285.734 99.1482 285.167 99.1482 284.15C99.1482 283.181 99.8107 282.542 100.817 282.542C101.651 282.542 102.461 283.086 102.461 284.15C102.461 284.529 102.363 284.812 102.215 285.072L102.559 285.64V285.758H102.534ZM100.792 285.002C101.185 285.002 101.602 284.718 101.602 284.15C101.602 283.583 101.185 283.299 100.792 283.299C100.375 283.299 99.9824 283.583 99.9824 284.15C99.9824 284.718 100.375 285.002 100.792 285.002Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M104.784 284.749C104.784 284.96 105.029 285.077 105.299 285.077C105.545 285.077 105.79 284.96 105.79 284.796C105.79 284.491 105.422 284.491 105.201 284.467C104.612 284.42 104.022 284.209 104.022 283.552C104.022 282.871 104.612 282.542 105.275 282.542C105.889 282.542 106.527 282.871 106.527 283.552H105.717C105.717 283.341 105.496 283.247 105.275 283.247C105.078 283.247 104.833 283.294 104.833 283.528C104.833 283.74 105.029 283.786 105.324 283.81C106.011 283.857 106.601 284.021 106.601 284.772C106.601 285.43 105.938 285.758 105.275 285.758C104.587 285.758 103.924 285.43 103.949 284.749H104.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M109.229 283.311H108.038V283.76H109.13V284.469H108.038V284.966H109.254V285.699H107.169V282.602H109.204V283.311H109.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M112.603 285.58V285.699H111.722L111.159 284.729H110.719V285.699H109.887V282.602C110.376 282.602 110.841 282.602 111.306 282.602C112.089 282.602 112.456 283.169 112.456 283.713C112.456 284.068 112.285 284.422 111.967 284.611L112.603 285.58ZM111.306 284.068C111.551 284.068 111.649 283.878 111.649 283.666C111.649 283.477 111.526 283.288 111.306 283.288H110.719V284.068H111.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M114.276 284.334L114.553 283.528L114.956 282.602H115.887V282.721L114.452 285.758H114.1L112.666 282.721V282.602H113.597L114.024 283.528L114.276 284.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M117.781 285.042V285.699H116.076V285.042H116.502V283.259H116.126V282.602H117.756V283.259H117.38V285.042H117.781Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M119.835 282.602C120.509 282.602 121.258 282.955 121.383 283.78H120.559C120.434 283.45 120.184 283.332 119.835 283.332C119.335 283.332 119.011 283.662 119.011 284.18C119.011 284.628 119.335 285.028 119.835 285.028C120.184 285.028 120.484 284.863 120.559 284.533H121.383C121.258 285.405 120.534 285.758 119.835 285.758C118.986 285.758 118.162 285.216 118.162 284.18C118.162 283.12 118.986 282.602 119.835 282.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M123.944 283.311H122.753V283.76H123.845V284.469H122.753V284.966H123.969V285.699H121.885V282.602H123.919V283.311H123.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M118.985 218.741H116.447C115.8 218.741 115.253 218.235 115.253 217.636V217.405C115.253 216.807 115.8 216.3 116.447 216.3H118.985C119.632 216.3 120.179 216.807 120.179 217.405V217.636C120.179 218.258 119.657 218.741 118.985 218.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M101.426 221.565C101.725 221.565 101.799 221.565 102.197 221.565C102.943 221.565 103.49 222.105 103.49 222.833C103.49 223.584 102.918 224.101 102.147 224.101C100.705 224.101 99.8098 224.101 98.3674 224.101C97.8203 224.101 97.3727 223.913 97.1488 223.42C96.9499 223.115 96.8255 222.27 97.6213 221.565C98.1436 220.978 103.913 214.757 104.933 213.677C104.982 213.606 104.933 213.512 104.858 213.512C104.833 213.512 104.833 213.512 104.808 213.512C102.645 213.512 100.481 213.512 98.3177 213.512C97.6711 213.512 97.1737 213.137 97.0494 212.573C96.9002 211.916 97.1986 211.352 97.7706 211.094C97.9446 211.023 98.1187 211 98.2928 211C101.501 211 104.684 211 107.892 211C108.514 211 108.937 211.188 109.185 211.728C109.509 212.409 109.16 212.878 108.937 213.183C108.489 213.723 103.938 218.56 101.426 221.283C101.401 221.307 101.376 221.354 101.327 221.377C101.277 221.448 101.327 221.565 101.426 221.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M108.186 224.101H105.648C105.001 224.101 104.453 223.582 104.453 222.968V222.732C104.453 222.119 105.001 221.6 105.648 221.6H108.186C108.832 221.6 109.38 222.119 109.38 222.732V222.968C109.38 223.605 108.832 224.101 108.186 224.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M111.715 217.515C111.715 215.734 111.715 213.976 111.715 212.195C111.715 211.492 112.237 211 112.983 211C116.215 211 119.446 211 122.678 211C123.424 211 123.946 211.516 123.946 212.242C123.946 212.969 123.424 213.508 122.678 213.508C119.993 213.508 117.333 213.508 114.649 213.508C114.45 213.508 114.4 213.555 114.4 213.742C114.4 216.273 114.4 218.804 114.4 221.335C114.4 221.546 114.475 221.57 114.649 221.57C117.309 221.57 119.993 221.57 122.653 221.57C123.25 221.57 123.722 221.898 123.896 222.39C124.095 222.976 123.871 223.562 123.399 223.89C123.175 224.03 122.902 224.101 122.628 224.101C119.446 224.101 116.24 224.101 113.058 224.101C112.237 224.101 111.74 223.585 111.74 222.812C111.715 221.031 111.715 219.273 111.715 217.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M98.7054 229.042V229.699H97V229.042H97.4263V227.259H97.0502V226.602H98.6803V227.259H98.3041V229.042H98.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M102.534 229.758H101.774L101.651 229.545C101.381 229.687 101.062 229.758 100.768 229.758C99.9334 229.734 99.1482 229.167 99.1482 228.15C99.1482 227.181 99.8107 226.542 100.817 226.542C101.651 226.542 102.461 227.086 102.461 228.15C102.461 228.529 102.363 228.812 102.215 229.072L102.559 229.64V229.758H102.534ZM100.792 229.002C101.185 229.002 101.602 228.718 101.602 228.15C101.602 227.583 101.185 227.299 100.792 227.299C100.375 227.299 99.9824 227.583 99.9824 228.15C99.9824 228.718 100.375 229.002 100.792 229.002Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M104.784 228.749C104.784 228.96 105.029 229.077 105.299 229.077C105.545 229.077 105.79 228.96 105.79 228.796C105.79 228.491 105.422 228.491 105.201 228.467C104.612 228.42 104.022 228.209 104.022 227.552C104.022 226.871 104.612 226.542 105.275 226.542C105.889 226.542 106.527 226.871 106.527 227.552H105.717C105.717 227.341 105.496 227.247 105.275 227.247C105.078 227.247 104.833 227.294 104.833 227.528C104.833 227.74 105.029 227.786 105.324 227.81C106.011 227.857 106.601 228.021 106.601 228.772C106.601 229.43 105.938 229.758 105.275 229.758C104.587 229.758 103.924 229.43 103.949 228.749H104.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M109.229 227.311H108.038V227.76H109.13V228.469H108.038V228.966H109.254V229.699H107.169V226.602H109.204V227.311H109.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M112.603 229.58V229.699H111.722L111.159 228.729H110.719V229.699H109.887V226.602C110.376 226.602 110.841 226.602 111.306 226.602C112.089 226.602 112.456 227.169 112.456 227.713C112.456 228.068 112.285 228.422 111.967 228.611L112.603 229.58ZM111.306 228.068C111.551 228.068 111.649 227.878 111.649 227.666C111.649 227.477 111.526 227.288 111.306 227.288H110.719V228.068H111.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M114.276 228.334L114.553 227.528L114.956 226.602H115.887V226.721L114.452 229.758H114.1L112.666 226.721V226.602H113.597L114.024 227.528L114.276 228.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M117.781 229.042V229.699H116.076V229.042H116.502V227.259H116.126V226.602H117.756V227.259H117.38V229.042H117.781Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M119.835 226.602C120.509 226.602 121.258 226.955 121.383 227.78H120.559C120.434 227.45 120.184 227.332 119.835 227.332C119.335 227.332 119.011 227.662 119.011 228.18C119.011 228.628 119.335 229.028 119.835 229.028C120.184 229.028 120.484 228.863 120.559 228.533H121.383C121.258 229.405 120.534 229.758 119.835 229.758C118.986 229.758 118.162 229.216 118.162 228.18C118.162 227.12 118.986 226.602 119.835 226.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M123.944 227.311H122.753V227.76H123.845V228.469H122.753V228.966H123.969V229.699H121.885V226.602H123.919V227.311H123.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M118.985 162.741H116.447C115.8 162.741 115.253 162.235 115.253 161.636V161.405C115.253 160.807 115.8 160.3 116.447 160.3H118.985C119.632 160.3 120.179 160.807 120.179 161.405V161.636C120.179 162.258 119.657 162.741 118.985 162.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M101.426 165.565C101.725 165.565 101.799 165.565 102.197 165.565C102.943 165.565 103.49 166.105 103.49 166.833C103.49 167.584 102.918 168.101 102.147 168.101C100.705 168.101 99.8098 168.101 98.3674 168.101C97.8203 168.101 97.3727 167.913 97.1488 167.42C96.9499 167.115 96.8255 166.27 97.6213 165.565C98.1436 164.978 103.913 158.757 104.933 157.677C104.982 157.606 104.933 157.512 104.858 157.512C104.833 157.512 104.833 157.512 104.808 157.512C102.645 157.512 100.481 157.512 98.3177 157.512C97.6711 157.512 97.1737 157.137 97.0494 156.573C96.9002 155.916 97.1986 155.352 97.7706 155.094C97.9446 155.023 98.1187 155 98.2928 155C101.501 155 104.684 155 107.892 155C108.514 155 108.937 155.188 109.185 155.728C109.509 156.409 109.16 156.878 108.937 157.183C108.489 157.723 103.938 162.56 101.426 165.283C101.401 165.307 101.376 165.354 101.327 165.377C101.277 165.448 101.327 165.565 101.426 165.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M108.186 168.101H105.648C105.001 168.101 104.453 167.582 104.453 166.968V166.732C104.453 166.119 105.001 165.6 105.648 165.6H108.186C108.832 165.6 109.38 166.119 109.38 166.732V166.968C109.38 167.605 108.832 168.101 108.186 168.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M111.715 161.515C111.715 159.734 111.715 157.976 111.715 156.195C111.715 155.492 112.237 155 112.983 155C116.215 155 119.446 155 122.678 155C123.424 155 123.946 155.516 123.946 156.242C123.946 156.969 123.424 157.508 122.678 157.508C119.993 157.508 117.333 157.508 114.649 157.508C114.45 157.508 114.4 157.555 114.4 157.742C114.4 160.273 114.4 162.804 114.4 165.335C114.4 165.546 114.475 165.57 114.649 165.57C117.309 165.57 119.993 165.57 122.653 165.57C123.25 165.57 123.722 165.898 123.896 166.39C124.095 166.976 123.871 167.562 123.399 167.89C123.175 168.03 122.902 168.101 122.628 168.101C119.446 168.101 116.24 168.101 113.058 168.101C112.237 168.101 111.74 167.585 111.74 166.812C111.715 165.031 111.715 163.273 111.715 161.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M98.7054 173.042V173.699H97V173.042H97.4263V171.259H97.0502V170.602H98.6803V171.259H98.3041V173.042H98.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M102.534 173.758H101.774L101.651 173.545C101.381 173.687 101.062 173.758 100.768 173.758C99.9334 173.734 99.1482 173.167 99.1482 172.15C99.1482 171.181 99.8107 170.542 100.817 170.542C101.651 170.542 102.461 171.086 102.461 172.15C102.461 172.529 102.363 172.812 102.215 173.072L102.559 173.64V173.758H102.534ZM100.792 173.002C101.185 173.002 101.602 172.718 101.602 172.15C101.602 171.583 101.185 171.299 100.792 171.299C100.375 171.299 99.9824 171.583 99.9824 172.15C99.9824 172.718 100.375 173.002 100.792 173.002Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M104.784 172.749C104.784 172.96 105.029 173.077 105.299 173.077C105.545 173.077 105.79 172.96 105.79 172.796C105.79 172.491 105.422 172.491 105.201 172.467C104.612 172.42 104.022 172.209 104.022 171.552C104.022 170.871 104.612 170.542 105.275 170.542C105.889 170.542 106.527 170.871 106.527 171.552H105.717C105.717 171.341 105.496 171.247 105.275 171.247C105.078 171.247 104.833 171.294 104.833 171.528C104.833 171.74 105.029 171.786 105.324 171.81C106.011 171.857 106.601 172.021 106.601 172.772C106.601 173.43 105.938 173.758 105.275 173.758C104.587 173.758 103.924 173.43 103.949 172.749H104.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M109.229 171.311H108.038V171.76H109.13V172.469H108.038V172.966H109.254V173.699H107.169V170.602H109.204V171.311H109.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M112.603 173.58V173.699H111.722L111.159 172.729H110.719V173.699H109.887V170.602C110.376 170.602 110.841 170.602 111.306 170.602C112.089 170.602 112.456 171.169 112.456 171.713C112.456 172.068 112.285 172.422 111.967 172.611L112.603 173.58ZM111.306 172.068C111.551 172.068 111.649 171.878 111.649 171.666C111.649 171.477 111.526 171.288 111.306 171.288H110.719V172.068H111.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M114.276 172.334L114.553 171.528L114.956 170.602H115.887V170.721L114.452 173.758H114.1L112.666 170.721V170.602H113.597L114.024 171.528L114.276 172.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M117.781 173.042V173.699H116.076V173.042H116.502V171.259H116.126V170.602H117.756V171.259H117.38V173.042H117.781Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M119.835 170.602C120.509 170.602 121.258 170.955 121.383 171.78H120.559C120.434 171.45 120.184 171.332 119.835 171.332C119.335 171.332 119.011 171.662 119.011 172.18C119.011 172.628 119.335 173.028 119.835 173.028C120.184 173.028 120.484 172.863 120.559 172.533H121.383C121.258 173.405 120.534 173.758 119.835 173.758C118.986 173.758 118.162 173.216 118.162 172.18C118.162 171.12 118.986 170.602 119.835 170.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M123.944 171.311H122.753V171.76H123.845V172.469H122.753V172.966H123.969V173.699H121.885V170.602H123.919V171.311H123.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M118.985 106.741H116.447C115.8 106.741 115.253 106.235 115.253 105.636V105.405C115.253 104.807 115.8 104.3 116.447 104.3H118.985C119.632 104.3 120.179 104.807 120.179 105.405V105.636C120.179 106.258 119.657 106.741 118.985 106.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M101.426 109.565C101.725 109.565 101.799 109.565 102.197 109.565C102.943 109.565 103.49 110.105 103.49 110.833C103.49 111.584 102.918 112.101 102.147 112.101C100.705 112.101 99.8098 112.101 98.3674 112.101C97.8203 112.101 97.3727 111.913 97.1488 111.42C96.9499 111.115 96.8255 110.27 97.6213 109.565C98.1436 108.978 103.913 102.757 104.933 101.677C104.982 101.606 104.933 101.512 104.858 101.512C104.833 101.512 104.833 101.512 104.808 101.512C102.645 101.512 100.481 101.512 98.3177 101.512C97.6711 101.512 97.1737 101.137 97.0494 100.573C96.9002 99.9156 97.1986 99.3522 97.7706 99.0939C97.9446 99.0235 98.1187 99 98.2928 99C101.501 99 104.684 99 107.892 99C108.514 99 108.937 99.1878 109.185 99.7278C109.509 100.409 109.16 100.878 108.937 101.183C108.489 101.723 103.938 106.56 101.426 109.283C101.401 109.307 101.376 109.354 101.327 109.377C101.277 109.448 101.327 109.565 101.426 109.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M108.186 112.101H105.648C105.001 112.101 104.453 111.582 104.453 110.968V110.732C104.453 110.119 105.001 109.6 105.648 109.6H108.186C108.832 109.6 109.38 110.119 109.38 110.732V110.968C109.38 111.605 108.832 112.101 108.186 112.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M111.715 105.515C111.715 103.734 111.715 101.976 111.715 100.195C111.715 99.4922 112.237 99 112.983 99C116.215 99 119.446 99 122.678 99C123.424 99 123.946 99.5156 123.946 100.242C123.946 100.969 123.424 101.508 122.678 101.508C119.993 101.508 117.333 101.508 114.649 101.508C114.45 101.508 114.4 101.555 114.4 101.742C114.4 104.273 114.4 106.804 114.4 109.335C114.4 109.546 114.475 109.57 114.649 109.57C117.309 109.57 119.993 109.57 122.653 109.57C123.25 109.57 123.722 109.898 123.896 110.39C124.095 110.976 123.871 111.562 123.399 111.89C123.175 112.03 122.902 112.101 122.628 112.101C119.446 112.101 116.24 112.101 113.058 112.101C112.237 112.101 111.74 111.585 111.74 110.812C111.715 109.031 111.715 107.273 111.715 105.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M98.7054 117.042V117.699H97V117.042H97.4263V115.259H97.0502V114.602H98.6803V115.259H98.3041V117.042H98.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M102.534 117.758H101.774L101.651 117.545C101.381 117.687 101.062 117.758 100.768 117.758C99.9334 117.734 99.1482 117.167 99.1482 116.15C99.1482 115.181 99.8107 114.542 100.817 114.542C101.651 114.542 102.461 115.086 102.461 116.15C102.461 116.529 102.363 116.812 102.215 117.072L102.559 117.64V117.758H102.534ZM100.792 117.002C101.185 117.002 101.602 116.718 101.602 116.15C101.602 115.583 101.185 115.299 100.792 115.299C100.375 115.299 99.9824 115.583 99.9824 116.15C99.9824 116.718 100.375 117.002 100.792 117.002Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M104.784 116.749C104.784 116.96 105.029 117.077 105.299 117.077C105.545 117.077 105.79 116.96 105.79 116.796C105.79 116.491 105.422 116.491 105.201 116.467C104.612 116.42 104.022 116.209 104.022 115.552C104.022 114.871 104.612 114.542 105.275 114.542C105.889 114.542 106.527 114.871 106.527 115.552H105.717C105.717 115.341 105.496 115.247 105.275 115.247C105.078 115.247 104.833 115.294 104.833 115.528C104.833 115.74 105.029 115.786 105.324 115.81C106.011 115.857 106.601 116.021 106.601 116.772C106.601 117.43 105.938 117.758 105.275 117.758C104.587 117.758 103.924 117.43 103.949 116.749H104.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M109.229 115.311H108.038V115.76H109.13V116.469H108.038V116.966H109.254V117.699H107.169V114.602H109.204V115.311H109.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M112.603 117.58V117.699H111.722L111.159 116.729H110.719V117.699H109.887V114.602C110.376 114.602 110.841 114.602 111.306 114.602C112.089 114.602 112.456 115.169 112.456 115.713C112.456 116.068 112.285 116.422 111.967 116.611L112.603 117.58ZM111.306 116.068C111.551 116.068 111.649 115.878 111.649 115.666C111.649 115.477 111.526 115.288 111.306 115.288H110.719V116.068H111.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M114.276 116.334L114.553 115.528L114.956 114.602H115.887V114.721L114.452 117.758H114.1L112.666 114.721V114.602H113.597L114.024 115.528L114.276 116.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M117.781 117.042V117.699H116.076V117.042H116.502V115.259H116.126V114.602H117.756V115.259H117.38V117.042H117.781Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M119.835 114.602C120.509 114.602 121.258 114.955 121.383 115.78H120.559C120.434 115.45 120.184 115.332 119.835 115.332C119.335 115.332 119.011 115.662 119.011 116.18C119.011 116.628 119.335 117.028 119.835 117.028C120.184 117.028 120.484 116.863 120.559 116.533H121.383C121.258 117.405 120.534 117.758 119.835 117.758C118.986 117.758 118.162 117.216 118.162 116.18C118.162 115.12 118.986 114.602 119.835 114.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M123.944 115.311H122.753V115.76H123.845V116.469H122.753V116.966H123.969V117.699H121.885V114.602H123.919V115.311H123.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M118.985 50.7413H116.447C115.8 50.7413 115.253 50.2346 115.253 49.6357V49.4054C115.253 48.8065 115.8 48.2998 116.447 48.2998H118.985C119.632 48.2998 120.179 48.8065 120.179 49.4054V49.6357C120.179 50.2576 119.657 50.7413 118.985 50.7413Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M101.426 53.5652C101.725 53.5652 101.799 53.5652 102.197 53.5652C102.943 53.5652 103.49 54.1052 103.49 54.833C103.49 55.5843 102.918 56.1008 102.147 56.1008C100.705 56.1008 99.8098 56.1008 98.3674 56.1008C97.8203 56.1008 97.3727 55.913 97.1488 55.4199C96.9499 55.1147 96.8255 54.2695 97.6213 53.5652C98.1436 52.9782 103.913 46.7565 104.933 45.6765C104.982 45.6061 104.933 45.5122 104.858 45.5122C104.833 45.5122 104.833 45.5122 104.808 45.5122C102.645 45.5122 100.481 45.5122 98.3177 45.5122C97.6711 45.5122 97.1737 45.1365 97.0494 44.573C96.9002 43.9156 97.1986 43.3522 97.7706 43.0939C97.9446 43.0235 98.1187 43 98.2928 43C101.501 43 104.684 43 107.892 43C108.514 43 108.937 43.1878 109.185 43.7278C109.509 44.4087 109.16 44.8783 108.937 45.1835C108.489 45.7235 103.938 50.56 101.426 53.2834C101.401 53.3069 101.376 53.3539 101.327 53.3773C101.277 53.4478 101.327 53.5652 101.426 53.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M108.186 56.1007H105.648C105.001 56.1007 104.453 55.5816 104.453 54.9681V54.7322C104.453 54.1187 105.001 53.5996 105.648 53.5996H108.186C108.832 53.5996 109.38 54.1187 109.38 54.7322V54.9681C109.38 55.6052 108.832 56.1007 108.186 56.1007Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M111.715 49.5152C111.715 47.7341 111.715 45.9764 111.715 44.1952C111.715 43.4922 112.237 43 112.983 43C116.215 43 119.446 43 122.678 43C123.424 43 123.946 43.5156 123.946 44.2421C123.946 44.9686 123.424 45.5077 122.678 45.5077C119.993 45.5077 117.333 45.5077 114.649 45.5077C114.45 45.5077 114.4 45.5545 114.4 45.742C114.4 48.2731 114.4 50.8042 114.4 53.3353C114.4 53.5463 114.475 53.5697 114.649 53.5697C117.309 53.5697 119.993 53.5697 122.653 53.5697C123.25 53.5697 123.722 53.8978 123.896 54.39C124.095 54.9759 123.871 55.5618 123.399 55.8899C123.175 56.0305 122.902 56.1008 122.628 56.1008C119.446 56.1008 116.24 56.1008 113.058 56.1008C112.237 56.1008 111.74 55.5852 111.74 54.8118C111.715 53.0307 111.715 51.273 111.715 49.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M98.7054 61.0418V61.6986H97V61.0418H97.4263V59.2589H97.0502V58.6021H98.6803V59.2589H98.3041V61.0418H98.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M102.534 61.7581H101.774L101.651 61.5453C101.381 61.6872 101.062 61.7581 100.768 61.7581C99.9334 61.7345 99.1482 61.167 99.1482 60.1503C99.1482 59.1809 99.8107 58.5425 100.817 58.5425C101.651 58.5425 102.461 59.0863 102.461 60.1503C102.461 60.5286 102.363 60.8124 102.215 61.0724L102.559 61.6399V61.7581H102.534ZM100.792 61.0015C101.185 61.0015 101.602 60.7178 101.602 60.1503C101.602 59.5828 101.185 59.2991 100.792 59.2991C100.375 59.2991 99.9824 59.5828 99.9824 60.1503C99.9824 60.7178 100.375 61.0015 100.792 61.0015Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M104.784 60.7488C104.784 60.9601 105.029 61.0774 105.299 61.0774C105.545 61.0774 105.79 60.9601 105.79 60.7958C105.79 60.4906 105.422 60.4906 105.201 60.4672C104.612 60.4202 104.022 60.209 104.022 59.5518C104.022 58.8711 104.612 58.5425 105.275 58.5425C105.889 58.5425 106.527 58.8711 106.527 59.5518H105.717C105.717 59.3405 105.496 59.2466 105.275 59.2466C105.078 59.2466 104.833 59.2936 104.833 59.5283C104.833 59.7395 105.029 59.7865 105.324 59.81C106.011 59.8569 106.601 60.0212 106.601 60.7723C106.601 61.4295 105.938 61.7581 105.275 61.7581C104.587 61.7581 103.924 61.4295 103.949 60.7488H104.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M109.229 59.3112H108.038V59.7603H109.13V60.4694H108.038V60.9658H109.254V61.6986H107.169V58.6021H109.204V59.3112H109.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M112.603 61.5804V61.6986H111.722L111.159 60.7295H110.719V61.6986H109.887V58.6021C110.376 58.6021 110.841 58.6021 111.306 58.6021C112.089 58.6021 112.456 59.1694 112.456 59.713C112.456 60.0676 112.285 60.4222 111.967 60.6113L112.603 61.5804ZM111.306 60.0676C111.551 60.0676 111.649 59.8785 111.649 59.6658C111.649 59.4766 111.526 59.2875 111.306 59.2875H110.719V60.0676H111.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M114.276 60.3343L114.553 59.5275L114.956 58.6021H115.887V58.7207L114.452 61.7582H114.1L112.666 58.7207V58.6021H113.597L114.024 59.5275L114.276 60.3343Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M117.781 61.0418V61.6986H116.076V61.0418H116.502V59.2589H116.126V58.6021H117.756V59.2589H117.38V61.0418H117.781Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M119.835 58.6021C120.509 58.6021 121.258 58.9553 121.383 59.7797H120.559C120.434 59.45 120.184 59.3322 119.835 59.3322C119.335 59.3322 119.011 59.6619 119.011 60.1801C119.011 60.6276 119.335 61.028 119.835 61.028C120.184 61.028 120.484 60.8631 120.559 60.5334H121.383C121.258 61.4049 120.534 61.7582 119.835 61.7582C118.986 61.7582 118.162 61.2164 118.162 60.1801C118.162 59.1202 118.986 58.6021 119.835 58.6021Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M123.944 59.3112H122.753V59.7603H123.845V60.4694H122.753V60.9658H123.969V61.6986H121.885V58.6021H123.919V59.3112H123.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <defs>
          <pattern id="5-v-pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use
              xlinkHref="#image0"
              transform="translate(-2.07767 -0.027972) scale(0.00351032 0.0022839)"
            />
          </pattern>
          <filter
            id="5-v-filter0_i"
            x="33"
            y="257"
            width="154.94"
            height="42.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-v-filter1_i"
            x="33"
            y="201"
            width="154.94"
            height="42.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-v-filter2_i"
            x="33"
            y="145"
            width="154.94"
            height="42.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-v-filter3_i"
            x="33"
            y="89"
            width="154.94"
            height="42.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-v-filter4_i"
            x="33"
            y="33"
            width="154.94"
            height="42.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <linearGradient
            id="5-v-paint0_linear"
            x1="301.5"
            y1="305"
            x2="301.5"
            y2="330"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DBDDE1" />
            <stop offset="1" stopColor="#F9F9F9" />
          </linearGradient>
        </defs>
      </svg>
      {!withoutBackground && <ContainerBackground />}
    </ProductImageWrapper>
  )
}
