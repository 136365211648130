import React, { FC, useState, useCallback, useRef } from 'react'
import {
  StageDescriptionBlockWrapper,
  StageDescriptionBlockContent,
  StageDescriptionBlockTitleBlock,
  StageDescriptionBlockTitle,
  StageDescriptionBlockSubTitle,
  StageDescriptionBlockContentItem,
  StageDescriptionBlockContentItemMain,
  StageDescriptionBlockContentItemDescription,
  ArrowWrapper,
  StageDescriptionBlockContentItemIconsWrapper,
  StageDescriptionBlockContainer,
  IconWrapper,
} from '../../../styles'
import { Tooltip, ICoord } from '../../../../../components'
import { IDescriptionRowProps } from '../../../interface'
import { ValueView } from './value-view'
import { ArrowDownIcon, InfoTooltip } from '../../../../../assets/icons'
import { totalsIcons } from './consts'

export const DescriptionRow: FC<IDescriptionRowProps> = props => {
  const { code, title, description, baseModel, currentModel, initCollapsed = true } = props

  const [collapsed, setCollapsed] = useState(initCollapsed)

  const [tooltipDelta, setTooltipDelta] = useState<ICoord>({ x: 0, y: 0 })
  const [isTooltipShow, setIsTooltipShow] = useState(false)
  const containerRef = useRef<HTMLDivElement | null>(null)

  const handleToggleCollapse = useCallback(() => {
    setCollapsed(prevState => !prevState)
  }, [])

  const renderComponent = useCallback((Component, key: string) => {
    return <Component key={key} />
  }, [])

  const handleAdditionalElementOver = useCallback(event => {
    let wrapperTop = 0
    let wrapperLeft = 0
    let deltaX = 0
    let deltaY = 0

    if (containerRef?.current) {
      const wrapperCoord = containerRef.current.getBoundingClientRect()

      wrapperTop = wrapperCoord.top
      wrapperLeft = wrapperCoord.left
    }

    if (event && event.currentTarget) {
      const elementCoord = event.currentTarget.getBoundingClientRect()
      deltaX = elementCoord.left - wrapperLeft + 8
      deltaY = elementCoord.top - wrapperTop

      setTooltipDelta({ y: -deltaY, x: deltaX })
      setIsTooltipShow(true)
    }
  }, [])

  const handleAdditionalElementLeave = useCallback(() => {
    setIsTooltipShow(false)
  }, [])

  return (
    <StageDescriptionBlockContainer ref={containerRef}>
      <Tooltip coord={tooltipDelta} text={description} importantShow={isTooltipShow} />
      <StageDescriptionBlockWrapper collapsed={ collapsed}>
        <StageDescriptionBlockTitleBlock onClick={handleToggleCollapse}>
          <StageDescriptionBlockTitle>{title}</StageDescriptionBlockTitle>
          <IconWrapper
            onMouseOver={handleAdditionalElementOver}
            onMouseLeave={handleAdditionalElementLeave}
          >
            <InfoTooltip color="#bbc3d6" />
          </IconWrapper>
          <ArrowWrapper collapsed={ collapsed}>
            <ArrowDownIcon up={!collapsed} />
          </ArrowWrapper>
        </StageDescriptionBlockTitleBlock>
        <StageDescriptionBlockContent>
          <StageDescriptionBlockContentItem>
            <div>
              <StageDescriptionBlockSubTitle>Базовая комплектация</StageDescriptionBlockSubTitle>
              <StageDescriptionBlockContentItemMain>
                {baseModel && (
                  <>
                    <ValueView value={baseModel['value']} totalCount={7} />
                    <StageDescriptionBlockContentItemDescription>
                      {baseModel['title']}
                    </StageDescriptionBlockContentItemDescription>
                  </>
                )}
              </StageDescriptionBlockContentItemMain>
            </div>
            <StageDescriptionBlockContentItemIconsWrapper>
              {baseModel['icons'].map(iconIndex =>
                renderComponent(totalsIcons[code][iconIndex], `basic-${code}-${iconIndex}`),
              )}
            </StageDescriptionBlockContentItemIconsWrapper>
          </StageDescriptionBlockContentItem>
          <StageDescriptionBlockContentItem>
            <StageDescriptionBlockSubTitle>Выбранная для Вас модель</StageDescriptionBlockSubTitle>
            <StageDescriptionBlockContentItemMain>
              {currentModel && (
                <>
                  <ValueView value={currentModel['value']} view="current" totalCount={7} />
                  <StageDescriptionBlockContentItemDescription>
                    {currentModel['title']}
                  </StageDescriptionBlockContentItemDescription>
                </>
              )}
            </StageDescriptionBlockContentItemMain>
            <StageDescriptionBlockContentItemIconsWrapper>
              {currentModel &&
                currentModel['icons'].map(iconIndex =>
                  renderComponent(totalsIcons[code][iconIndex], `current-${code}-${iconIndex}`),
                )}
            </StageDescriptionBlockContentItemIconsWrapper>
          </StageDescriptionBlockContentItem>
        </StageDescriptionBlockContent>
      </StageDescriptionBlockWrapper>
    </StageDescriptionBlockContainer>
  )
}
