import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement5Door1LCasement2 = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="451"
        height="451"
        viewBox="0 0 451 451"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#door-1-l-casement-2-5-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M308 269C308 267.895 307.105 267 306 267C304.895 267 304 267.895 304 269V283.56H308V269ZM308 283.56H304V285.4H303.94C303.421 285.4 303 285.821 303 286.34V288.12C303 289.158 303.842 290 304.88 290H306.12C307.158 290 308 289.158 308 288.12V285.4V285.4V283.56Z"
            fill="white"
          />
        </g>
        <rect
          width="3.99998"
          height="0.95833"
          transform="matrix(-1 0 0 1 308 284.25)"
          fill="#ABABAB"
        />
        <path
          d="M308 267.94C308 267.421 307.579 267 307.06 267H304.94C304.421 267 304 267.421 304 267.94V284.25H308V267.94Z"
          fill="url(#door-1-l-casement-2-5-paint0_linear)"
        />
        <path
          d="M308 289.06C308 289.579 307.579 290 307.06 290H303.94C303.421 290 303 289.579 303 289.06V286.148C303 285.629 303.421 285.208 303.94 285.208H308V289.06Z"
          fill="url(#door-1-l-casement-2-5-paint1_linear)"
        />
        <g filter="url(#door-1-l-casement-2-5-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M308 46.9995C308 45.8952 307.105 45 306.001 45C304.896 45 304.001 45.8952 304.001 46.9995V49.1665H304.001V60H304V63.0005C304 64.1048 304.895 65 305.999 65C307.104 65 307.999 64.1048 307.999 63.0005V60.8332H308V50H308V46.9995Z"
            fill="url(#door-1-l-casement-2-5-paint2_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 308 49.1665)"
          fill="#ABABAB"
        />
        <path
          d="M308 45.9399C308 45.4208 307.579 45 307.06 45H304.941C304.422 45 304.001 45.4208 304.001 45.9399V50H308V45.9399Z"
          fill="url(#door-1-l-casement-2-5-paint3_linear)"
        />
        <path
          d="M304 64.0601C304 64.5792 304.421 65 304.94 65H307.059C307.578 65 307.999 64.5792 307.999 64.0601V60H304V64.0601Z"
          fill="url(#door-1-l-casement-2-5-paint4_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 308 50.8335)"
          fill="url(#door-1-l-casement-2-5-paint5_linear)"
        />
        <g filter="url(#door-1-l-casement-2-5-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M419 34H308V301H419V34ZM408 45H319V290H408V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M419 34H308V301H309V35H418V301H419V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="312.53"
          y="38.53"
          width="101.94"
          height="257.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#door-1-l-casement-2-5-filter3_i)">
          <rect
            x="319.47"
            y="45.47"
            width="88.0601"
            height="244.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <path d="M320.5 287.443V47.5571L406.385 167.5L320.5 287.443Z" stroke="white" />
        <path d="M363.5 48.8375L406.403 288.5H320.597L363.5 48.8375Z" stroke="white" />
        <rect
          x="410"
          y="161"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#door-1-l-casement-2-5-filter4_d)">
          <rect x="410.702" y="161.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M411.286 172.796C411.798 173.458 412.6 173.884 413.502 173.884C415.049 173.884 416.302 172.63 416.302 171.084V164.444C416.302 163.456 415.79 162.587 415.017 162.089C415.383 162.562 415.6 163.156 415.6 163.8V170.44C415.6 171.986 414.347 173.24 412.8 173.24C412.242 173.24 411.723 173.077 411.286 172.796Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M413.502 161.644C411.955 161.644 410.702 162.898 410.702 164.444V170.44C410.702 170.46 410.702 170.48 410.702 170.501V165.024C410.702 163.477 411.956 162.224 413.502 162.224C415.028 162.224 416.269 163.444 416.302 164.963V164.444C416.302 162.898 415.048 161.644 413.502 161.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#door-1-l-casement-2-5-filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M413.5 165C412.672 165 412 165.672 412 166.5V166.714V168V186.5C412 187.328 412.672 188 413.5 188V188C414.328 188 415 187.328 415 186.5V170V166.5L415 166.286C415 165.576 414.424 165 413.714 165V165C413.677 165 413.64 165.001 413.604 165.004C413.569 165.001 413.535 165 413.5 165Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="door-1-l-casement-2-5-filter0_d"
            x="301.12"
            y="265.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-2-5-filter1_d"
            x="302.12"
            y="43.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-2-5-filter2_d"
            x="303.3"
            y="29.3003"
            width="122.279"
            height="278.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-2-5-filter3_i"
            x="319"
            y="45"
            width="89.9399"
            height="247.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="door-1-l-casement-2-5-filter4_d"
            x="408.822"
            y="159.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-2-5-filter5_d"
            x="410.12"
            y="163.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="door-1-l-casement-2-5-paint0_linear"
            x1="301.2"
            y1="275.625"
            x2="308.8"
            y2="275.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-2-5-paint1_linear"
            x1="299.5"
            y1="287.604"
            x2="309"
            y2="287.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-2-5-paint2_linear"
            x1="310.8"
            y1="62.5"
            x2="303.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-2-5-paint3_linear"
            x1="301.202"
            y1="47.5"
            x2="308.8"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-2-5-paint4_linear"
            x1="310.798"
            y1="62.5"
            x2="303.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-2-5-paint5_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
