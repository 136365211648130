import React, { FC, useCallback, useEffect, useState } from 'react'

import { ITabsItem, ITabsProps } from './interface'
import { TabsContent, TabsTitle, TabsTitleWrapper, TabsWrapper } from './styles'
import { SubMenuAttribute } from '../sub-menu-attribute'
import { ComponentShadow } from '../component-shadow'

export const Tabs: FC<ITabsProps> = props => {
  const { items = [] as ITabsItem[], disabled = false } = props

  const [selectedTab, setSelectedTab] = useState<ITabsItem | null>(null)
  const [selectedItem, setSelectedItem] = useState<Record<string, any> | null>(null)

  useEffect(() => {
    if (!selectedTab && items.length > 0) {
      setSelectedTab(items[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items.length])

  const handleSelectTab = useCallback(
    (item: ITabsItem) => () => {
      setSelectedTab(item)
    },
    [],
  )

  const handleSelectItem = useCallback((key, item) => {
    setSelectedItem(prevState => ({
      ...prevState,
      [key]: item,
    }))
  }, [])

  return (
    <TabsWrapper>
      {disabled && <ComponentShadow />}
      <TabsTitleWrapper>
        {items.length > 0 &&
          items.map(item => (
            <TabsTitle onClick={handleSelectTab(item)} active={item.id === selectedTab?.id}>
              {item.title}
            </TabsTitle>
          ))}
      </TabsTitleWrapper>
      {selectedItem?.price && <div>{selectedItem.price}</div>}
      {/* TODO: show price */}
      <TabsContent>
        {selectedTab &&
          selectedTab.attributes &&
          selectedTab.attributes.length > 0 &&
          selectedTab.attributes.map(attribute => (
            <div key={attribute.id}>
              <SubMenuAttribute attribute={attribute} onSelect={handleSelectItem} />
            </div>
          ))}
      </TabsContent>
    </TabsWrapper>
  )
}
