import React, { FC } from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'
import { IContainerSquareProps } from '../../interface'
import { ContainerBackground } from '../container-background'

export const ContainerDoor1LCasement2: FC<IContainerSquareProps> = props => {
  const { onCasementClick, withoutBackground } = props

  return (
    <ProductImageWrapper isClickable>
      <svg
        width="451"
        height="451"
        viewBox="0 0 451 451"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g clipPath="url(#door-1-l-casement-2-container-clip0)">
          <rect width="451" height="451" fill="white" />
          <rect x="-81" y="-1" width="647" height="426" fill="#E8EAEE" />
          <rect
            x="-81"
            y="-1"
            width="647"
            height="426"
            fill="url(#door-1-l-casement-2-container-pattern0)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M451 -1H0V451H451V-1ZM186 11H441V323.274H186V441H10V323.274V11H186Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 10H441V28H188V441H10V10ZM24 28H186V425H24V28Z"
            fill="#C4C4C4"
          />
          <path d="M10 10L24 28.1525V424.982L10 441V10Z" fill="#E9EBEF" />
          <path d="M10 10L24 28.1525V424.982L10 441V10Z" fill="#020202" fillOpacity="0.06" />
          <rect x="198" y="343" width="255" height="108" fill="#E8EAEE" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 28H24V425H188V28ZM166 41H37V412H166V41Z"
            fill="#E8EAEE"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M186 28H427V325H186V28ZM191 41H293V294H191V41ZM414 41H312V294H414V41Z"
            fill="#E8EAEE"
          />
          <g
            filter="url(#door-1-l-casement-2-container-filter0_i)"
            className="casement"
            data-index="0"
            onClick={onCasementClick(0)}
          >
            <rect x="37" y="41" width="129" height="371" fill="#B8C1FD" fillOpacity="0.4" />
          </g>
          <rect
            x="37.47"
            y="41.47"
            width="128.06"
            height="370.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
          <path
            d="M109.985 115.741H107.447C106.8 115.741 106.253 115.234 106.253 114.635V114.405C106.253 113.806 106.8 113.3 107.447 113.3H109.985C110.632 113.3 111.179 113.806 111.179 114.405V114.635C111.179 115.257 110.657 115.741 109.985 115.741Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M92.4262 118.565C92.7247 118.565 92.7993 118.565 93.1972 118.565C93.9432 118.565 94.4903 119.105 94.4903 119.833C94.4903 120.584 93.9184 121.101 93.1474 121.101C91.705 121.101 90.8098 121.101 89.3674 121.101C88.8203 121.101 88.3727 120.913 88.1488 120.42C87.9499 120.115 87.8255 119.27 88.6213 118.565C89.1436 117.978 94.9131 111.757 95.9327 110.677C95.9824 110.606 95.9327 110.512 95.8581 110.512C95.8332 110.512 95.8332 110.512 95.8084 110.512C93.6448 110.512 91.4812 110.512 89.3177 110.512C88.6711 110.512 88.1737 110.137 88.0494 109.573C87.9002 108.916 88.1986 108.352 88.7706 108.094C88.9446 108.023 89.1187 108 89.2928 108C92.5008 108 95.684 108 98.8921 108C99.5138 108 99.9366 108.188 100.185 108.728C100.509 109.409 100.16 109.878 99.9366 110.183C99.4889 110.723 94.938 115.56 92.4262 118.283C92.4014 118.307 92.3765 118.354 92.3268 118.377C92.277 118.448 92.3268 118.565 92.4262 118.565Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M99.1855 121.101H96.6476C96.0007 121.101 95.4533 120.582 95.4533 119.968V119.732C95.4533 119.119 96.0007 118.6 96.6476 118.6H99.1855C99.8325 118.6 100.38 119.119 100.38 119.732V119.968C100.38 120.605 99.8325 121.101 99.1855 121.101Z"
            fill="#141B4D"
            fillOpacity="0.63"
            stroke="#767676"
            strokeWidth="0.0812062"
            strokeMiterlimit="10"
          />
          <path
            d="M102.715 114.515C102.715 112.734 102.715 110.976 102.715 109.195C102.715 108.492 103.237 108 103.983 108C107.215 108 110.446 108 113.678 108C114.424 108 114.946 108.516 114.946 109.242C114.946 109.969 114.424 110.508 113.678 110.508C110.993 110.508 108.333 110.508 105.649 110.508C105.45 110.508 105.4 110.555 105.4 110.742C105.4 113.273 105.4 115.804 105.4 118.335C105.4 118.546 105.475 118.57 105.649 118.57C108.309 118.57 110.993 118.57 113.653 118.57C114.25 118.57 114.722 118.898 114.896 119.39C115.095 119.976 114.871 120.562 114.399 120.89C114.175 121.03 113.902 121.101 113.628 121.101C110.446 121.101 107.24 121.101 104.058 121.101C103.237 121.101 102.74 120.585 102.74 119.812C102.715 118.031 102.715 116.273 102.715 114.515Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M89.7054 126.042V126.698H88V126.042H88.4263V124.259H88.0502V123.602H89.6803V124.259H89.3041V126.042H89.7054Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M93.5344 126.758H92.7737L92.651 126.545C92.3811 126.687 92.0621 126.758 91.7676 126.758C90.9334 126.734 90.1482 126.167 90.1482 125.15C90.1482 124.181 90.8107 123.542 91.8167 123.542C92.651 123.542 93.4607 124.086 93.4607 125.15C93.4607 125.528 93.3626 125.812 93.2154 126.072L93.5589 126.64V126.758H93.5344ZM91.7922 126.001C92.1848 126.001 92.6019 125.718 92.6019 125.15C92.6019 124.583 92.1848 124.299 91.7922 124.299C91.375 124.299 90.9824 124.583 90.9824 125.15C90.9824 125.718 91.375 126.001 91.7922 126.001Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M95.7835 125.749C95.7835 125.96 96.0291 126.077 96.2992 126.077C96.5448 126.077 96.7903 125.96 96.7903 125.796C96.7903 125.49 96.422 125.49 96.201 125.467C95.6116 125.42 95.0223 125.209 95.0223 124.552C95.0223 123.871 95.6116 123.542 96.2747 123.542C96.8886 123.542 97.527 123.871 97.527 124.552H96.7167C96.7167 124.34 96.4957 124.246 96.2747 124.246C96.0782 124.246 95.8326 124.293 95.8326 124.528C95.8326 124.739 96.0291 124.786 96.3238 124.81C97.0114 124.857 97.6007 125.021 97.6007 125.772C97.6007 126.429 96.9377 126.758 96.2747 126.758C95.5871 126.758 94.924 126.429 94.9486 125.749H95.7835Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M100.229 124.311H99.0377V124.76H100.13V125.469H99.0377V125.966H100.254V126.698H98.1693V123.602H100.204V124.311H100.229Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M103.603 126.58V126.698H102.722L102.159 125.729H101.719V126.698H100.887V123.602C101.376 123.602 101.841 123.602 102.306 123.602C103.089 123.602 103.456 124.169 103.456 124.713C103.456 125.067 103.285 125.422 102.967 125.611L103.603 126.58ZM102.306 125.067C102.551 125.067 102.649 124.878 102.649 124.666C102.649 124.476 102.526 124.287 102.306 124.287H101.719V125.067H102.306Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M105.276 125.334L105.553 124.527L105.956 123.602H106.887V123.72L105.452 126.758H105.1L103.666 123.72V123.602H104.597L105.024 124.527L105.276 125.334Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M108.781 126.042V126.698H107.076V126.042H107.502V124.259H107.126V123.602H108.756V124.259H108.38V126.042H108.781Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M110.835 123.602C111.509 123.602 112.258 123.955 112.383 124.779H111.559C111.434 124.45 111.184 124.332 110.835 124.332C110.335 124.332 110.011 124.662 110.011 125.18C110.011 125.627 110.335 126.028 110.835 126.028C111.184 126.028 111.484 125.863 111.559 125.533H112.383C112.258 126.405 111.534 126.758 110.835 126.758C109.986 126.758 109.162 126.216 109.162 125.18C109.162 124.12 109.986 123.602 110.835 123.602Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M114.944 124.311H113.753V124.76H114.845V125.469H113.753V125.966H114.969V126.698H112.885V123.602H114.919V124.311H114.944Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <g
            filter="url(#door-1-l-casement-2-container-filter1_i)"
            className="casement"
            data-index="1"
            onClick={onCasementClick(1)}
          >
            <rect x="191" y="41" width="102" height="253" fill="#B8C1FD" fillOpacity="0.4" />
          </g>
          <rect
            x="191.47"
            y="41.47"
            width="101.06"
            height="252.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
          <g
            filter="url(#door-1-l-casement-2-container-filter2_i)"
            className="casement"
            data-index="2"
            onClick={onCasementClick(2)}
          >
            <rect x="312" y="41" width="102" height="253" fill="#B8C1FD" fillOpacity="0.4" />
          </g>
          <rect
            x="312.47"
            y="41.47"
            width="101.06"
            height="252.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
          <path
            d="M250.985 115.741H248.447C247.8 115.741 247.253 115.234 247.253 114.635V114.405C247.253 113.806 247.8 113.3 248.447 113.3H250.985C251.632 113.3 252.179 113.806 252.179 114.405V114.635C252.179 115.257 251.657 115.741 250.985 115.741Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M233.426 118.565C233.725 118.565 233.799 118.565 234.197 118.565C234.943 118.565 235.49 119.105 235.49 119.833C235.49 120.584 234.918 121.101 234.147 121.101C232.705 121.101 231.81 121.101 230.367 121.101C229.82 121.101 229.373 120.913 229.149 120.42C228.95 120.115 228.826 119.27 229.621 118.565C230.144 117.978 235.913 111.757 236.933 110.677C236.982 110.606 236.933 110.512 236.858 110.512C236.833 110.512 236.833 110.512 236.808 110.512C234.645 110.512 232.481 110.512 230.318 110.512C229.671 110.512 229.174 110.137 229.049 109.573C228.9 108.916 229.199 108.352 229.771 108.094C229.945 108.023 230.119 108 230.293 108C233.501 108 236.684 108 239.892 108C240.514 108 240.937 108.188 241.185 108.728C241.509 109.409 241.16 109.878 240.937 110.183C240.489 110.723 235.938 115.56 233.426 118.283C233.401 118.307 233.376 118.354 233.327 118.377C233.277 118.448 233.327 118.565 233.426 118.565Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M240.186 121.101H237.648C237.001 121.101 236.453 120.582 236.453 119.968V119.732C236.453 119.119 237.001 118.6 237.648 118.6H240.186C240.832 118.6 241.38 119.119 241.38 119.732V119.968C241.38 120.605 240.832 121.101 240.186 121.101Z"
            fill="#141B4D"
            fillOpacity="0.63"
            stroke="#767676"
            strokeWidth="0.0812062"
            strokeMiterlimit="10"
          />
          <path
            d="M243.715 114.515C243.715 112.734 243.715 110.976 243.715 109.195C243.715 108.492 244.237 108 244.983 108C248.215 108 251.446 108 254.678 108C255.424 108 255.946 108.516 255.946 109.242C255.946 109.969 255.424 110.508 254.678 110.508C251.993 110.508 249.333 110.508 246.649 110.508C246.45 110.508 246.4 110.555 246.4 110.742C246.4 113.273 246.4 115.804 246.4 118.335C246.4 118.546 246.475 118.57 246.649 118.57C249.309 118.57 251.993 118.57 254.653 118.57C255.25 118.57 255.722 118.898 255.896 119.39C256.095 119.976 255.871 120.562 255.399 120.89C255.175 121.03 254.902 121.101 254.628 121.101C251.446 121.101 248.24 121.101 245.058 121.101C244.237 121.101 243.74 120.585 243.74 119.812C243.715 118.031 243.715 116.273 243.715 114.515Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M230.705 126.042V126.698H229V126.042H229.426V124.259H229.05V123.602H230.68V124.259H230.304V126.042H230.705Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M234.534 126.758H233.774L233.651 126.545C233.381 126.687 233.062 126.758 232.768 126.758C231.933 126.734 231.148 126.167 231.148 125.15C231.148 124.181 231.811 123.542 232.817 123.542C233.651 123.542 234.461 124.086 234.461 125.15C234.461 125.528 234.363 125.812 234.215 126.072L234.559 126.64V126.758H234.534ZM232.792 126.001C233.185 126.001 233.602 125.718 233.602 125.15C233.602 124.583 233.185 124.299 232.792 124.299C232.375 124.299 231.982 124.583 231.982 125.15C231.982 125.718 232.375 126.001 232.792 126.001Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M236.784 125.749C236.784 125.96 237.029 126.077 237.299 126.077C237.545 126.077 237.79 125.96 237.79 125.796C237.79 125.49 237.422 125.49 237.201 125.467C236.612 125.42 236.022 125.209 236.022 124.552C236.022 123.871 236.612 123.542 237.275 123.542C237.889 123.542 238.527 123.871 238.527 124.552H237.717C237.717 124.34 237.496 124.246 237.275 124.246C237.078 124.246 236.833 124.293 236.833 124.528C236.833 124.739 237.029 124.786 237.324 124.81C238.011 124.857 238.601 125.021 238.601 125.772C238.601 126.429 237.938 126.758 237.275 126.758C236.587 126.758 235.924 126.429 235.949 125.749H236.784Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M241.229 124.311H240.038V124.76H241.13V125.469H240.038V125.966H241.254V126.698H239.169V123.602H241.204V124.311H241.229Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M244.603 126.58V126.698H243.722L243.159 125.729H242.719V126.698H241.887V123.602C242.376 123.602 242.841 123.602 243.306 123.602C244.089 123.602 244.456 124.169 244.456 124.713C244.456 125.067 244.285 125.422 243.967 125.611L244.603 126.58ZM243.306 125.067C243.551 125.067 243.649 124.878 243.649 124.666C243.649 124.476 243.526 124.287 243.306 124.287H242.719V125.067H243.306Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M246.276 125.334L246.553 124.527L246.956 123.602H247.887V123.72L246.452 126.758H246.1L244.666 123.72V123.602H245.597L246.024 124.527L246.276 125.334Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M249.781 126.042V126.698H248.076V126.042H248.502V124.259H248.126V123.602H249.756V124.259H249.38V126.042H249.781Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M251.835 123.602C252.509 123.602 253.258 123.955 253.383 124.779H252.559C252.434 124.45 252.184 124.332 251.835 124.332C251.335 124.332 251.011 124.662 251.011 125.18C251.011 125.627 251.335 126.028 251.835 126.028C252.184 126.028 252.484 125.863 252.559 125.533H253.383C253.258 126.405 252.534 126.758 251.835 126.758C250.986 126.758 250.162 126.216 250.162 125.18C250.162 124.12 250.986 123.602 251.835 123.602Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M255.944 124.311H254.753V124.76H255.845V125.469H254.753V125.966H255.969V126.698H253.885V123.602H255.919V124.311H255.944Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M371.985 114.741H369.447C368.8 114.741 368.253 114.234 368.253 113.635V113.405C368.253 112.806 368.8 112.3 369.447 112.3H371.985C372.632 112.3 373.179 112.806 373.179 113.405V113.635C373.179 114.257 372.657 114.741 371.985 114.741Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M354.426 117.565C354.725 117.565 354.799 117.565 355.197 117.565C355.943 117.565 356.49 118.105 356.49 118.833C356.49 119.584 355.918 120.101 355.147 120.101C353.705 120.101 352.81 120.101 351.367 120.101C350.82 120.101 350.373 119.913 350.149 119.42C349.95 119.115 349.826 118.27 350.621 117.565C351.144 116.978 356.913 110.757 357.933 109.677C357.982 109.606 357.933 109.512 357.858 109.512C357.833 109.512 357.833 109.512 357.808 109.512C355.645 109.512 353.481 109.512 351.318 109.512C350.671 109.512 350.174 109.137 350.049 108.573C349.9 107.916 350.199 107.352 350.771 107.094C350.945 107.023 351.119 107 351.293 107C354.501 107 357.684 107 360.892 107C361.514 107 361.937 107.188 362.185 107.728C362.509 108.409 362.16 108.878 361.937 109.183C361.489 109.723 356.938 114.56 354.426 117.283C354.401 117.307 354.376 117.354 354.327 117.377C354.277 117.448 354.327 117.565 354.426 117.565Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M361.186 120.101H358.648C358.001 120.101 357.453 119.582 357.453 118.968V118.732C357.453 118.119 358.001 117.6 358.648 117.6H361.186C361.832 117.6 362.38 118.119 362.38 118.732V118.968C362.38 119.605 361.832 120.101 361.186 120.101Z"
            fill="#141B4D"
            fillOpacity="0.63"
            stroke="#767676"
            strokeWidth="0.0812062"
            strokeMiterlimit="10"
          />
          <path
            d="M364.715 113.515C364.715 111.734 364.715 109.976 364.715 108.195C364.715 107.492 365.237 107 365.983 107C369.215 107 372.446 107 375.678 107C376.424 107 376.946 107.516 376.946 108.242C376.946 108.969 376.424 109.508 375.678 109.508C372.993 109.508 370.333 109.508 367.649 109.508C367.45 109.508 367.4 109.555 367.4 109.742C367.4 112.273 367.4 114.804 367.4 117.335C367.4 117.546 367.475 117.57 367.649 117.57C370.309 117.57 372.993 117.57 375.653 117.57C376.25 117.57 376.722 117.898 376.896 118.39C377.095 118.976 376.871 119.562 376.399 119.89C376.175 120.03 375.902 120.101 375.628 120.101C372.446 120.101 369.24 120.101 366.058 120.101C365.237 120.101 364.74 119.585 364.74 118.812C364.715 117.031 364.715 115.273 364.715 113.515Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M351.705 125.042V125.698H350V125.042H350.426V123.259H350.05V122.602H351.68V123.259H351.304V125.042H351.705Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M355.534 125.758H354.774L354.651 125.545C354.381 125.687 354.062 125.758 353.768 125.758C352.933 125.734 352.148 125.167 352.148 124.15C352.148 123.181 352.811 122.542 353.817 122.542C354.651 122.542 355.461 123.086 355.461 124.15C355.461 124.528 355.363 124.812 355.215 125.072L355.559 125.64V125.758H355.534ZM353.792 125.001C354.185 125.001 354.602 124.718 354.602 124.15C354.602 123.583 354.185 123.299 353.792 123.299C353.375 123.299 352.982 123.583 352.982 124.15C352.982 124.718 353.375 125.001 353.792 125.001Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M357.784 124.749C357.784 124.96 358.029 125.077 358.299 125.077C358.545 125.077 358.79 124.96 358.79 124.796C358.79 124.49 358.422 124.49 358.201 124.467C357.612 124.42 357.022 124.209 357.022 123.552C357.022 122.871 357.612 122.542 358.275 122.542C358.889 122.542 359.527 122.871 359.527 123.552H358.717C358.717 123.34 358.496 123.246 358.275 123.246C358.078 123.246 357.833 123.293 357.833 123.528C357.833 123.739 358.029 123.786 358.324 123.81C359.011 123.857 359.601 124.021 359.601 124.772C359.601 125.429 358.938 125.758 358.275 125.758C357.587 125.758 356.924 125.429 356.949 124.749H357.784Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M362.229 123.311H361.038V123.76H362.13V124.469H361.038V124.966H362.254V125.698H360.169V122.602H362.204V123.311H362.229Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M365.603 125.58V125.698H364.722L364.159 124.729H363.719V125.698H362.887V122.602C363.376 122.602 363.841 122.602 364.306 122.602C365.089 122.602 365.456 123.169 365.456 123.713C365.456 124.067 365.285 124.422 364.967 124.611L365.603 125.58ZM364.306 124.067C364.551 124.067 364.649 123.878 364.649 123.666C364.649 123.476 364.526 123.287 364.306 123.287H363.719V124.067H364.306Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M367.276 124.334L367.553 123.527L367.956 122.602H368.887V122.72L367.452 125.758H367.1L365.666 122.72V122.602H366.597L367.024 123.527L367.276 124.334Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M370.781 125.042V125.698H369.076V125.042H369.502V123.259H369.126V122.602H370.756V123.259H370.38V125.042H370.781Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M372.835 122.602C373.509 122.602 374.258 122.955 374.383 123.779H373.559C373.434 123.45 373.184 123.332 372.835 123.332C372.335 123.332 372.011 123.662 372.011 124.18C372.011 124.627 372.335 125.028 372.835 125.028C373.184 125.028 373.484 124.863 373.559 124.533H374.383C374.258 125.405 373.534 125.758 372.835 125.758C371.986 125.758 371.162 125.216 371.162 124.18C371.162 123.12 371.986 122.602 372.835 122.602Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M376.944 123.311H375.753V123.76H376.845V124.469H375.753V124.966H376.969V125.698H374.885V122.602H376.919V123.311H376.944Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M440.838 307H185L184 331H215.75H440.838H451V323H440.838V307Z"
            fill="url(#door-1-l-casement-2-container-paint0_linear)"
          />
          <path d="M441 10L427 28.0571V307.075L441 323V10Z" fill="#E9EBEF" />
          <path d="M441 10L427 28.0571V307.075L441 323V10Z" fill="#020202" fillOpacity="0.06" />
          <g filter="url(#door-1-l-casement-2-container-filter3_d)">
            <rect x="184" y="331" width="267" height="12" fill="white" />
          </g>
        </g>
        <defs>
          <pattern
            id="door-1-l-casement-2-container-pattern0"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
          >
            <use
              xlinkHref="#image0"
              transform="translate(-0.00768784) scale(0.00100915 0.00153332)"
            />
          </pattern>
          <filter
            id="door-1-l-casement-2-container-filter0_i"
            x="37"
            y="41"
            width="129.94"
            height="373.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="door-1-l-casement-2-container-filter1_i"
            x="191"
            y="41"
            width="102.94"
            height="255.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="door-1-l-casement-2-container-filter2_i"
            x="312"
            y="41"
            width="102.94"
            height="255.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="door-1-l-casement-2-container-filter3_d"
            x="178.94"
            y="328.94"
            width="279"
            height="24"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="3.94" />
            <feGaussianBlur stdDeviation="3" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="door-1-l-casement-2-container-paint0_linear"
            x1="276.741"
            y1="306"
            x2="276.741"
            y2="331"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DBDDE1" />
            <stop offset="1" stopColor="#F9F9F9" />
          </linearGradient>
          <clipPath id="door-1-l-casement-2-container-clip0">
            <rect width="451" height="451" fill="white" />
          </clipPath>
        </defs>
      </svg>

      {!withoutBackground && <ContainerBackground />}
    </ProductImageWrapper>
  )
}
