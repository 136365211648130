import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement12Square32Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#1-2_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M44.9995 30C43.8952 30 43 30.8952 43 31.9995C43 33.1038 43.8952 33.999 44.9995 33.999L47.1665 33.999L47.1665 33.999L58 33.999L58 34L61.0005 34C62.1048 34 63 33.1048 63 32.0005C63 30.8962 62.1048 30.001 61.0005 30.001L58.8332 30.001L58.8332 30L48 30L48 30L44.9995 30Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 47.166 30)"
          fill="#ABABAB"
        />
        <path
          d="M43.9399 30C43.4208 30 43 30.4208 43 30.9399L43 33.059C43 33.5781 43.4208 33.999 43.9399 33.999L48 33.999L48 30L43.9399 30Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M62.0601 34C62.5792 34 63 33.5792 63 33.0601L63 30.941C63 30.4219 62.5792 30.001 62.0601 30.001L58 30.001L58 34L62.0601 34Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 48.833 30)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#1-2_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M122.999 30C121.895 30 121 30.8952 121 31.9995C121 33.1038 121.895 33.999 122.999 33.999L125.167 33.999L125.167 33.999L136 33.999L136 34L139.001 34C140.105 34 141 33.1048 141 32.0005C141 30.8962 140.105 30.001 139.001 30.001L136.833 30.001L136.833 30L126 30L126 30L122.999 30Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 125.166 30)"
          fill="#ABABAB"
        />
        <path
          d="M121.94 30C121.421 30 121 30.4208 121 30.9399L121 33.059C121 33.5781 121.421 33.999 121.94 33.999L126 33.999L126 30L121.94 30Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M140.06 34C140.579 34 141 33.5792 141 33.0601L141 30.941C141 30.4219 140.579 30.001 140.06 30.001L136 30.001L136 34L140.06 34Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 126.833 30)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#1-2_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M152 34H32V287H152V34ZM141 45H43V276H141V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M152 34H32V287L32.8872 287V34.937H151.109V287L152 287V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="36.53"
          y="38.53"
          width="110.94"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#1-2_filter3_i)">
          <rect
            x="43.47"
            y="45.47"
            width="97.0601"
            height="230.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M92 275L140 46H44L92 275ZM92 270.665L138.893 46.9453H45.1068L92 270.665Z"
          fill="white"
        />
        <rect
          x="100"
          y="278"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 100 278)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="99.3555"
          y="278.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 99.3555 278.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99.2383 280.695C98.8919 279.542 97.8222 278.702 96.5563 278.702L89.9164 278.702C88.37 278.702 87.1164 279.955 87.1164 281.502C87.1164 281.537 87.117 281.572 87.1183 281.607C87.4647 282.76 88.5344 283.6 89.8003 283.6L96.4402 283.6C97.9866 283.6 99.2402 282.346 99.2402 280.8C99.2402 280.765 99.2396 280.73 99.2383 280.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M97.8809 279.035C97.7372 279.012 97.5899 279 97.4398 279L90.7999 279C89.2535 279 87.9999 280.254 87.9999 281.8C87.9999 282.867 88.5968 283.795 89.4749 284.267C88.1381 284.056 87.116 282.898 87.116 281.502C87.116 279.955 88.3696 278.702 89.916 278.702L96.5559 278.702C97.0352 278.702 97.4864 278.822 97.8809 279.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-2_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M96 281.5C96 280.672 95.3284 280 94.5 280L94.2857 280L93 280L74.5 280C73.6716 280 73 280.672 73 281.5C73 282.328 73.6716 283 74.5 283L91 283L94.5 283L94.7143 283C95.4244 283 96 282.424 96 281.714C96 281.677 95.9988 281.64 95.9965 281.604C95.9988 281.569 96 281.535 96 281.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="1-2_filter0_d"
            x="41.1201"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-2_filter1_d"
            x="119.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-2_filter2_d"
            x="27.3003"
            y="29.3003"
            width="131.279"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-2_filter3_i"
            x="43"
            y="45"
            width="98.9399"
            height="233.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="1-2_filter4_d"
            x="71.28"
            y="278.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="60.5"
            y1="27.2"
            x2="60.5"
            y2="34.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="45.5"
            y1="36.7982"
            x2="45.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="60.5"
            y1="27.2018"
            x2="60.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="138.5"
            y1="27.2"
            x2="138.5"
            y2="34.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="123.5"
            y1="36.7982"
            x2="123.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="138.5"
            y1="27.2018"
            x2="138.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
