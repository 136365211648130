import styled from 'styled-components'

import { HRWrapper } from '../hr'
import { RadiobuttonWrapper } from '../radiobutton'
import { ComponentShadowWrapper } from '../component-shadow'

export const PriceViewWrapper = styled.div`
  margin-top: 6px;
  margin-bottom: 10px;

  position: relative;

  & + ${RadiobuttonWrapper} {
    margin-top: 10px;
  }

  & + ${HRWrapper} {
    margin-top: 18px;

    ${ComponentShadowWrapper} {
      top: -18px;
    }
  }
`

export const PriceViewItem = styled.div`
  font-size: 14px;
  line-height: 140%;
  font-weight: bold;
  color: #d69a2d;
`
