import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement11Square4TopBottomCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#1-1_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.0005 97C57.1048 97 58 96.1048 58 95.0005C58 93.8962 57.1048 93.001 56.0005 93.001L53.8335 93.001L53.8335 93.001L43 93.001L43 93L39.9995 93C38.8952 93 38 93.8952 38 94.9995C38 96.1038 38.8952 96.999 39.9995 96.999L42.1668 96.999L42.1668 97L53 97L53 97L56.0005 97Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 53.834 97)"
          fill="#ABABAB"
        />
        <path
          d="M57.0601 97C57.5792 97 58 96.5792 58 96.0601L58 93.941C58 93.4219 57.5792 93.001 57.0601 93.001L53 93.001L53 97L57.0601 97Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M38.9399 93C38.4208 93 38 93.4208 38 93.9399L38 96.059C38 96.5781 38.4208 96.999 38.9399 96.999L43 96.999L43 93L38.9399 93Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 52.167 97)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#1-1_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M409.001 97C410.105 97 411 96.1048 411 95.0005C411 93.8962 410.105 93.001 409.001 93.001L406.833 93.001L406.833 93.001L396 93.001L396 93L392.999 93C391.895 93 391 93.8952 391 94.9995C391 96.1038 391.895 96.999 392.999 96.999L395.167 96.999L395.167 97L406 97L406 97L409.001 97Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 406.834 97)"
          fill="#ABABAB"
        />
        <path
          d="M410.06 97C410.579 97 411 96.5792 411 96.0601L411 93.941C411 93.4219 410.579 93.001 410.06 93.001L406 93.001L406 97L410.06 97Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M391.94 93C391.421 93 391 93.4208 391 93.9399L391 96.059C391 96.5781 391.421 96.999 391.94 96.999L396 96.999L396 93L391.94 93Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 405.167 97)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#1-1_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 33H32V93H417V33ZM406 44H43V82H406V44Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#1-1_filter3_i)">
          <rect
            x="43.47"
            y="44.47"
            width="362.06"
            height="37.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="37.5" y="38.5" width="374" height="49" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 33H32V93H33V34H417V33Z"
          fill="#FBFBFB"
        />
        <rect
          x="231.528"
          y="35"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 231.528 35)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#1-1_filter4_d)">
          <rect
            x="230.884"
            y="35.7012"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 230.884 35.7012)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M230.767 37.6944C230.42 36.5413 229.351 35.7012 228.085 35.7012L221.445 35.7012C219.898 35.7012 218.645 36.9548 218.645 38.5012C218.645 38.5363 218.645 38.5713 218.647 38.6062C218.993 39.7593 220.063 40.5994 221.329 40.5994L227.969 40.5994C229.515 40.5994 230.769 39.3458 230.769 37.7994C230.769 37.7642 230.768 37.7292 230.767 37.6944Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M229.409 36.0339C229.266 36.0112 229.118 35.9994 228.968 35.9994L222.328 35.9994C220.782 35.9994 219.528 37.253 219.528 38.7994C219.528 39.8664 220.125 40.7941 221.003 41.2666C219.666 41.0551 218.644 39.8975 218.644 38.5012C218.644 36.9548 219.898 35.7012 221.444 35.7012L228.084 35.7012C228.564 35.7012 229.015 35.8216 229.409 36.0339Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-1_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M244.528 38.5C244.528 37.6716 243.857 37 243.028 37L242.814 37L241.528 37L223.028 37C222.2 37 221.528 37.6716 221.528 38.5V38.5C221.528 39.3284 222.2 40 223.028 40L239.528 40L243.028 40L243.243 40C243.953 40 244.528 39.4244 244.528 38.7143V38.7143C244.528 38.6771 244.527 38.6401 244.525 38.6035C244.527 38.5693 244.528 38.5348 244.528 38.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M224.5 45.5099L49.0633 80.5L399.937 80.5L224.5 45.5099Z" stroke="white" />
        <defs>
          <filter
            id="1-1_filter0_d"
            x="36.1201"
            y="91.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-1_filter1_d"
            x="389.12"
            y="91.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-1_filter2_d"
            x="27.3003"
            y="28.3003"
            width="396.279"
            height="71.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-1_filter3_i"
            x="43"
            y="44"
            width="363.94"
            height="40.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="1-1_filter4_d"
            x="216.764"
            y="33.8213"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-1_filter5_d"
            x="220.649"
            y="35.1201"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="40.5"
            y1="99.8"
            x2="40.5"
            y2="92.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="55.5"
            y1="90.2018"
            x2="55.5"
            y2="97.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="40.5"
            y1="99.7982"
            x2="40.5"
            y2="92.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="393.5"
            y1="99.8"
            x2="393.5"
            y2="92.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="408.5"
            y1="90.2018"
            x2="408.5"
            y2="97.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="393.5"
            y1="99.7982"
            x2="393.5"
            y2="92.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
