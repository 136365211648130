import React, { FC } from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'
import { IContainerSquareProps } from '../../interface'
import { ContainerBackground } from '../container-background'

export const ContainerSquare4TopCasement: FC<IContainerSquareProps> = props => {
  const { onCasementClick, withoutBackground } = props

  return (
    <ProductImageWrapper isClickable>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <rect width="449" height="333" fill="white" />
        <rect x="24" y="28" width="401" height="264" fill="#E8EAEE" />
        <rect x="24" y="28" width="401" height="264" fill="url(#4-t-pattern0)" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M439 10H10V292H24V29H425V292H439V10Z"
          fill="#C4C4C4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M439 292H10V308H0V316H10H439H449V308H439V292Z"
          fill="url(#4-t-paint0_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23 28H425V292H23V28ZM36 133H148V279H36V133ZM412 41H36V113H412V41ZM168 133H280V279H168V133ZM412 133H300V279H412V133Z"
          fill="#E8EAEE"
        />
        <path d="M439 10L425 28.0571V292.075L439 308V10Z" fill="#E9EBEF" />
        <path d="M439 10L425 28.0571V292.075L439 308V10Z" fill="#020202" fillOpacity="0.06" />
        <path d="M10 10L24 28.1525V291.982L10 308V10Z" fill="#E9EBEF" />
        <path d="M10 10L24 28.1525V291.982L10 308V10Z" fill="#020202" fillOpacity="0.06" />
        <g
          filter="url(#4-t-filter0_i)"
          className="casement"
          data-index="3"
          onClick={onCasementClick(3)}
        >
          <rect x="300" y="133" width="112" height="146" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="300.47"
          y="133.47"
          width="111.06"
          height="145.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#4-t-filter1_i)"
          className="casement"
          data-index="2"
          onClick={onCasementClick(2)}
        >
          <rect x="168" y="133" width="112" height="146" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="168.47"
          y="133.47"
          width="111.06"
          height="145.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#4-t-filter2_i)"
          className="casement"
          data-index="1"
          onClick={onCasementClick(1)}
        >
          <rect x="36" y="133" width="113" height="146" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="36.47"
          y="133.47"
          width="112.06"
          height="145.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <path
          d="M100.985 157.741H98.4473C97.8003 157.741 97.2529 157.234 97.2529 156.636V156.405C97.2529 155.806 97.8003 155.3 98.4473 155.3H100.985C101.632 155.3 102.18 155.806 102.18 156.405V156.636C102.18 157.257 101.657 157.741 100.985 157.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M83.4262 160.565C83.7247 160.565 83.7993 160.565 84.1972 160.565C84.9432 160.565 85.4903 161.105 85.4903 161.833C85.4903 162.584 84.9184 163.101 84.1474 163.101C82.705 163.101 81.8098 163.101 80.3674 163.101C79.8203 163.101 79.3727 162.913 79.1488 162.42C78.9499 162.115 78.8255 161.27 79.6213 160.565C80.1436 159.978 85.9131 153.757 86.9327 152.677C86.9824 152.606 86.9327 152.512 86.8581 152.512C86.8332 152.512 86.8332 152.512 86.8084 152.512C84.6448 152.512 82.4812 152.512 80.3177 152.512C79.6711 152.512 79.1737 152.137 79.0494 151.573C78.9002 150.916 79.1986 150.352 79.7706 150.094C79.9446 150.023 80.1187 150 80.2928 150C83.5008 150 86.684 150 89.8921 150C90.5138 150 90.9366 150.188 91.1852 150.728C91.5085 151.409 91.1604 151.878 90.9366 152.183C90.4889 152.723 85.938 157.56 83.4262 160.283C83.4014 160.307 83.3765 160.354 83.3268 160.377C83.277 160.448 83.3268 160.565 83.4262 160.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M90.1854 163.101H87.6475C87.0005 163.101 86.4531 162.582 86.4531 161.968V161.732C86.4531 161.119 87.0005 160.6 87.6475 160.6H90.1854C90.8323 160.6 91.3797 161.119 91.3797 161.732V161.968C91.3797 162.605 90.8323 163.101 90.1854 163.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M93.7158 156.515C93.7158 154.734 93.7158 152.976 93.7158 151.195C93.7158 150.492 94.2379 150 94.9836 150C98.2152 150 101.447 150 104.678 150C105.424 150 105.946 150.516 105.946 151.242C105.946 151.969 105.424 152.508 104.678 152.508C101.994 152.508 99.3339 152.508 96.6491 152.508C96.4503 152.508 96.4005 152.555 96.4005 152.742C96.4005 155.273 96.4005 157.804 96.4005 160.335C96.4005 160.546 96.4751 160.57 96.6491 160.57C99.309 160.57 101.994 160.57 104.654 160.57C105.25 160.57 105.723 160.898 105.897 161.39C106.095 161.976 105.872 162.562 105.399 162.89C105.176 163.03 104.902 163.101 104.629 163.101C101.447 163.101 98.2401 163.101 95.0582 163.101C94.2378 163.101 93.7407 162.585 93.7407 161.812C93.7158 160.031 93.7158 158.273 93.7158 156.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M80.7054 168.042V168.698H79V168.042H79.4263V166.259H79.0502V165.602H80.6803V166.259H80.3041V168.042H80.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M84.5346 168.758H83.774L83.6513 168.545C83.3814 168.687 83.0624 168.758 82.7679 168.758C81.9336 168.734 81.1484 168.167 81.1484 167.15C81.1484 166.181 81.811 165.542 82.817 165.542C83.6513 165.542 84.461 166.086 84.461 167.15C84.461 167.528 84.3629 167.812 84.2156 168.072L84.5592 168.64V168.758H84.5346ZM82.7925 168.001C83.1851 168.001 83.6022 167.718 83.6022 167.15C83.6022 166.583 83.1851 166.299 82.7925 166.299C82.3753 166.299 81.9827 166.583 81.9827 167.15C81.9827 167.718 82.3753 168.001 82.7925 168.001Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M86.7838 167.749C86.7838 167.96 87.0294 168.077 87.2995 168.077C87.5451 168.077 87.7907 167.96 87.7907 167.796C87.7907 167.49 87.4223 167.49 87.2013 167.467C86.6119 167.42 86.0226 167.209 86.0226 166.552C86.0226 165.871 86.6119 165.542 87.275 165.542C87.8889 165.542 88.5274 165.871 88.5274 166.552H87.717C87.717 166.34 87.496 166.246 87.275 166.246C87.0785 166.246 86.8329 166.293 86.8329 166.528C86.8329 166.739 87.0294 166.786 87.3241 166.81C88.0117 166.857 88.601 167.021 88.601 167.772C88.601 168.429 87.938 168.758 87.275 168.758C86.5874 168.758 85.9243 168.429 85.9489 167.749H86.7838Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M91.2285 166.311H90.0374V166.76H91.1292V167.469H90.0374V167.966H91.2533V168.698H89.1689V165.602H91.2036V166.311H91.2285Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M94.6027 168.58V168.698H93.7218L93.159 167.729H92.7186V168.698H91.8867V165.602C92.3761 165.602 92.841 165.602 93.3059 165.602C94.0888 165.602 94.4559 166.169 94.4559 166.713C94.4559 167.067 94.2846 167.422 93.9665 167.611L94.6027 168.58ZM93.3059 167.067C93.5505 167.067 93.6484 166.878 93.6484 166.666C93.6484 166.476 93.5261 166.287 93.3059 166.287H92.7186V167.067H93.3059Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M96.2757 167.334L96.5525 166.527L96.9551 165.602H97.8863V165.721L96.4518 168.758H96.0995L94.665 165.721V165.602H95.5962L96.024 166.527L96.2757 167.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M99.7815 168.042V168.698H98.0762V168.042H98.5025V166.259H98.1263V165.602H99.7565V166.259H99.3803V168.042H99.7815Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M101.835 165.602C102.509 165.602 103.258 165.955 103.383 166.78H102.559C102.434 166.45 102.185 166.332 101.835 166.332C101.336 166.332 101.011 166.662 101.011 167.18C101.011 167.627 101.336 168.028 101.835 168.028C102.185 168.028 102.484 167.863 102.559 167.533H103.383C103.258 168.405 102.534 168.758 101.835 168.758C100.986 168.758 100.162 168.216 100.162 167.18C100.162 166.12 100.986 165.602 101.835 165.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M105.944 166.311H104.753V166.76H105.845V167.469H104.753V167.966H105.969V168.698H103.885V165.602H105.919V166.311H105.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M232.985 157.741H230.447C229.8 157.741 229.253 157.234 229.253 156.636V156.405C229.253 155.806 229.8 155.3 230.447 155.3H232.985C233.632 155.3 234.18 155.806 234.18 156.405V156.636C234.18 157.257 233.657 157.741 232.985 157.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M215.426 160.565C215.725 160.565 215.799 160.565 216.197 160.565C216.943 160.565 217.49 161.105 217.49 161.833C217.49 162.584 216.918 163.101 216.147 163.101C214.705 163.101 213.81 163.101 212.367 163.101C211.82 163.101 211.373 162.913 211.149 162.42C210.95 162.115 210.826 161.27 211.621 160.565C212.144 159.978 217.913 153.757 218.933 152.677C218.982 152.606 218.933 152.512 218.858 152.512C218.833 152.512 218.833 152.512 218.808 152.512C216.645 152.512 214.481 152.512 212.318 152.512C211.671 152.512 211.174 152.137 211.049 151.573C210.9 150.916 211.199 150.352 211.771 150.094C211.945 150.023 212.119 150 212.293 150C215.501 150 218.684 150 221.892 150C222.514 150 222.937 150.188 223.185 150.728C223.509 151.409 223.16 151.878 222.937 152.183C222.489 152.723 217.938 157.56 215.426 160.283C215.401 160.307 215.376 160.354 215.327 160.377C215.277 160.448 215.327 160.565 215.426 160.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M222.185 163.101H219.647C219.001 163.101 218.453 162.582 218.453 161.968V161.732C218.453 161.119 219.001 160.6 219.647 160.6H222.185C222.832 160.6 223.38 161.119 223.38 161.732V161.968C223.38 162.605 222.832 163.101 222.185 163.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M225.716 156.515C225.716 154.734 225.716 152.976 225.716 151.195C225.716 150.492 226.238 150 226.984 150C230.215 150 233.447 150 236.678 150C237.424 150 237.946 150.516 237.946 151.242C237.946 151.969 237.424 152.508 236.678 152.508C233.994 152.508 231.334 152.508 228.649 152.508C228.45 152.508 228.401 152.555 228.401 152.742C228.401 155.273 228.401 157.804 228.401 160.335C228.401 160.546 228.475 160.57 228.649 160.57C231.309 160.57 233.994 160.57 236.654 160.57C237.25 160.57 237.723 160.898 237.897 161.39C238.095 161.976 237.872 162.562 237.399 162.89C237.176 163.03 236.902 163.101 236.629 163.101C233.447 163.101 230.24 163.101 227.058 163.101C226.238 163.101 225.741 162.585 225.741 161.812C225.716 160.031 225.716 158.273 225.716 156.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M212.705 168.042V168.698H211V168.042H211.426V166.259H211.05V165.602H212.68V166.259H212.304V168.042H212.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M216.535 168.758H215.774L215.651 168.545C215.381 168.687 215.062 168.758 214.768 168.758C213.934 168.734 213.148 168.167 213.148 167.15C213.148 166.181 213.811 165.542 214.817 165.542C215.651 165.542 216.461 166.086 216.461 167.15C216.461 167.528 216.363 167.812 216.216 168.072L216.559 168.64V168.758H216.535ZM214.792 168.001C215.185 168.001 215.602 167.718 215.602 167.15C215.602 166.583 215.185 166.299 214.792 166.299C214.375 166.299 213.983 166.583 213.983 167.15C213.983 167.718 214.375 168.001 214.792 168.001Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M218.784 167.749C218.784 167.96 219.029 168.077 219.3 168.077C219.545 168.077 219.791 167.96 219.791 167.796C219.791 167.49 219.422 167.49 219.201 167.467C218.612 167.42 218.023 167.209 218.023 166.552C218.023 165.871 218.612 165.542 219.275 165.542C219.889 165.542 220.527 165.871 220.527 166.552H219.717C219.717 166.34 219.496 166.246 219.275 166.246C219.079 166.246 218.833 166.293 218.833 166.528C218.833 166.739 219.029 166.786 219.324 166.81C220.012 166.857 220.601 167.021 220.601 167.772C220.601 168.429 219.938 168.758 219.275 168.758C218.587 168.758 217.924 168.429 217.949 167.749H218.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M223.228 166.311H222.037V166.76H223.129V167.469H222.037V167.966H223.253V168.698H221.169V165.602H223.204V166.311H223.228Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M226.603 168.58V168.698H225.722L225.159 167.729H224.719V168.698H223.887V165.602C224.376 165.602 224.841 165.602 225.306 165.602C226.089 165.602 226.456 166.169 226.456 166.713C226.456 167.067 226.285 167.422 225.966 167.611L226.603 168.58ZM225.306 167.067C225.551 167.067 225.648 166.878 225.648 166.666C225.648 166.476 225.526 166.287 225.306 166.287H224.719V167.067H225.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M228.276 167.334L228.552 166.527L228.955 165.602H229.886V165.721L228.452 168.758H228.099L226.665 165.721V165.602H227.596L228.024 166.527L228.276 167.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M231.782 168.042V168.698H230.076V168.042H230.503V166.259H230.126V165.602H231.756V166.259H231.38V168.042H231.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M233.835 165.602C234.509 165.602 235.258 165.955 235.383 166.78H234.559C234.434 166.45 234.185 166.332 233.835 166.332C233.336 166.332 233.011 166.662 233.011 167.18C233.011 167.627 233.336 168.028 233.835 168.028C234.185 168.028 234.484 167.863 234.559 167.533H235.383C235.258 168.405 234.534 168.758 233.835 168.758C232.986 168.758 232.162 168.216 232.162 167.18C232.162 166.12 232.986 165.602 233.835 165.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M237.944 166.311H236.753V166.76H237.845V167.469H236.753V167.966H237.969V168.698H235.885V165.602H237.919V166.311H237.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M364.985 157.741H362.447C361.8 157.741 361.253 157.234 361.253 156.636V156.405C361.253 155.806 361.8 155.3 362.447 155.3H364.985C365.632 155.3 366.18 155.806 366.18 156.405V156.636C366.18 157.257 365.657 157.741 364.985 157.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M347.426 160.565C347.725 160.565 347.799 160.565 348.197 160.565C348.943 160.565 349.49 161.105 349.49 161.833C349.49 162.584 348.918 163.101 348.147 163.101C346.705 163.101 345.81 163.101 344.367 163.101C343.82 163.101 343.373 162.913 343.149 162.42C342.95 162.115 342.826 161.27 343.621 160.565C344.144 159.978 349.913 153.757 350.933 152.677C350.982 152.606 350.933 152.512 350.858 152.512C350.833 152.512 350.833 152.512 350.808 152.512C348.645 152.512 346.481 152.512 344.318 152.512C343.671 152.512 343.174 152.137 343.049 151.573C342.9 150.916 343.199 150.352 343.771 150.094C343.945 150.023 344.119 150 344.293 150C347.501 150 350.684 150 353.892 150C354.514 150 354.937 150.188 355.185 150.728C355.509 151.409 355.16 151.878 354.937 152.183C354.489 152.723 349.938 157.56 347.426 160.283C347.401 160.307 347.376 160.354 347.327 160.377C347.277 160.448 347.327 160.565 347.426 160.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M354.185 163.101H351.647C351.001 163.101 350.453 162.582 350.453 161.968V161.732C350.453 161.119 351.001 160.6 351.647 160.6H354.185C354.832 160.6 355.38 161.119 355.38 161.732V161.968C355.38 162.605 354.832 163.101 354.185 163.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M357.716 156.515C357.716 154.734 357.716 152.976 357.716 151.195C357.716 150.492 358.238 150 358.984 150C362.215 150 365.447 150 368.678 150C369.424 150 369.946 150.516 369.946 151.242C369.946 151.969 369.424 152.508 368.678 152.508C365.994 152.508 363.334 152.508 360.649 152.508C360.45 152.508 360.401 152.555 360.401 152.742C360.401 155.273 360.401 157.804 360.401 160.335C360.401 160.546 360.475 160.57 360.649 160.57C363.309 160.57 365.994 160.57 368.654 160.57C369.25 160.57 369.723 160.898 369.897 161.39C370.095 161.976 369.872 162.562 369.399 162.89C369.176 163.03 368.902 163.101 368.629 163.101C365.447 163.101 362.24 163.101 359.058 163.101C358.238 163.101 357.741 162.585 357.741 161.812C357.716 160.031 357.716 158.273 357.716 156.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M344.705 168.042V168.698H343V168.042H343.426V166.259H343.05V165.602H344.68V166.259H344.304V168.042H344.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M348.535 168.758H347.774L347.651 168.545C347.381 168.687 347.062 168.758 346.768 168.758C345.934 168.734 345.148 168.167 345.148 167.15C345.148 166.181 345.811 165.542 346.817 165.542C347.651 165.542 348.461 166.086 348.461 167.15C348.461 167.528 348.363 167.812 348.216 168.072L348.559 168.64V168.758H348.535ZM346.792 168.001C347.185 168.001 347.602 167.718 347.602 167.15C347.602 166.583 347.185 166.299 346.792 166.299C346.375 166.299 345.983 166.583 345.983 167.15C345.983 167.718 346.375 168.001 346.792 168.001Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M350.784 167.749C350.784 167.96 351.029 168.077 351.3 168.077C351.545 168.077 351.791 167.96 351.791 167.796C351.791 167.49 351.422 167.49 351.201 167.467C350.612 167.42 350.023 167.209 350.023 166.552C350.023 165.871 350.612 165.542 351.275 165.542C351.889 165.542 352.527 165.871 352.527 166.552H351.717C351.717 166.34 351.496 166.246 351.275 166.246C351.079 166.246 350.833 166.293 350.833 166.528C350.833 166.739 351.029 166.786 351.324 166.81C352.012 166.857 352.601 167.021 352.601 167.772C352.601 168.429 351.938 168.758 351.275 168.758C350.587 168.758 349.924 168.429 349.949 167.749H350.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M355.228 166.311H354.037V166.76H355.129V167.469H354.037V167.966H355.253V168.698H353.169V165.602H355.204V166.311H355.228Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M358.603 168.58V168.698H357.722L357.159 167.729H356.719V168.698H355.887V165.602C356.376 165.602 356.841 165.602 357.306 165.602C358.089 165.602 358.456 166.169 358.456 166.713C358.456 167.067 358.285 167.422 357.966 167.611L358.603 168.58ZM357.306 167.067C357.551 167.067 357.648 166.878 357.648 166.666C357.648 166.476 357.526 166.287 357.306 166.287H356.719V167.067H357.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M360.276 167.334L360.552 166.527L360.955 165.602H361.886V165.721L360.452 168.758H360.099L358.665 165.721V165.602H359.596L360.024 166.527L360.276 167.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M363.782 168.042V168.698H362.076V168.042H362.503V166.259H362.126V165.602H363.756V166.259H363.38V168.042H363.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M365.835 165.602C366.509 165.602 367.258 165.955 367.383 166.78H366.559C366.434 166.45 366.185 166.332 365.835 166.332C365.336 166.332 365.011 166.662 365.011 167.18C365.011 167.627 365.336 168.028 365.835 168.028C366.185 168.028 366.484 167.863 366.559 167.533H367.383C367.258 168.405 366.534 168.758 365.835 168.758C364.986 168.758 364.162 168.216 364.162 167.18C364.162 166.12 364.986 165.602 365.835 165.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M369.944 166.311H368.753V166.76H369.845V167.469H368.753V167.966H369.969V168.698H367.885V165.602H369.919V166.311H369.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <rect y="316" width="449" height="17" fill="white" />
        <g
          filter="url(#4-t-filter3_i)"
          className="casement"
          data-index="0"
          onClick={onCasementClick(0)}
        >
          <rect x="36" y="41" width="376" height="72" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="36.47"
          y="41.47"
          width="375.06"
          height="71.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <path
          d="M232.985 65.7412H230.447C229.8 65.7412 229.253 65.2345 229.253 64.6356V64.4053C229.253 63.8064 229.8 63.2997 230.447 63.2997H232.985C233.632 63.2997 234.18 63.8064 234.18 64.4053V64.6356C234.18 65.2575 233.657 65.7412 232.985 65.7412Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M215.426 68.5652C215.725 68.5652 215.799 68.5652 216.197 68.5652C216.943 68.5652 217.49 69.1052 217.49 69.833C217.49 70.5843 216.918 71.1008 216.147 71.1008C214.705 71.1008 213.81 71.1008 212.367 71.1008C211.82 71.1008 211.373 70.913 211.149 70.4199C210.95 70.1147 210.826 69.2695 211.621 68.5652C212.144 67.9782 217.913 61.7565 218.933 60.6765C218.982 60.6061 218.933 60.5122 218.858 60.5122C218.833 60.5122 218.833 60.5122 218.808 60.5122C216.645 60.5122 214.481 60.5122 212.318 60.5122C211.671 60.5122 211.174 60.1365 211.049 59.573C210.9 58.9156 211.199 58.3522 211.771 58.0939C211.945 58.0235 212.119 58 212.293 58C215.501 58 218.684 58 221.892 58C222.514 58 222.937 58.1878 223.185 58.7278C223.509 59.4087 223.16 59.8783 222.937 60.1835C222.489 60.7235 217.938 65.56 215.426 68.2834C215.401 68.3069 215.376 68.3539 215.327 68.3773C215.277 68.4478 215.327 68.5652 215.426 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M222.185 71.1009H219.647C219.001 71.1009 218.453 70.5818 218.453 69.9683V69.7324C218.453 69.1189 219.001 68.5998 219.647 68.5998H222.185C222.832 68.5998 223.38 69.1189 223.38 69.7324V69.9683C223.38 70.6054 222.832 71.1009 222.185 71.1009Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M225.716 64.5152C225.716 62.7341 225.716 60.9764 225.716 59.1952C225.716 58.4922 226.238 58 226.984 58C230.215 58 233.447 58 236.678 58C237.424 58 237.946 58.5156 237.946 59.2421C237.946 59.9686 237.424 60.5077 236.678 60.5077C233.994 60.5077 231.334 60.5077 228.649 60.5077C228.45 60.5077 228.401 60.5545 228.401 60.742C228.401 63.2731 228.401 65.8042 228.401 68.3353C228.401 68.5463 228.475 68.5697 228.649 68.5697C231.309 68.5697 233.994 68.5697 236.654 68.5697C237.25 68.5697 237.723 68.8978 237.897 69.39C238.095 69.9759 237.872 70.5618 237.399 70.8899C237.176 71.0305 236.902 71.1008 236.629 71.1008C233.447 71.1008 230.24 71.1008 227.058 71.1008C226.238 71.1008 225.741 70.5852 225.741 69.8118C225.716 68.0307 225.716 66.273 225.716 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M212.705 76.0416V76.6984H211V76.0416H211.426V74.2587H211.05V73.6019H212.68V74.2587H212.304V76.0416H212.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M216.535 76.7579H215.774L215.651 76.5451C215.381 76.687 215.062 76.7579 214.768 76.7579C213.934 76.7343 213.148 76.1668 213.148 75.1501C213.148 74.1807 213.811 73.5423 214.817 73.5423C215.651 73.5423 216.461 74.0861 216.461 75.1501C216.461 75.5284 216.363 75.8121 216.216 76.0722L216.559 76.6397V76.7579H216.535ZM214.792 76.0013C215.185 76.0013 215.602 75.7176 215.602 75.1501C215.602 74.5826 215.185 74.2989 214.792 74.2989C214.375 74.2989 213.983 74.5826 213.983 75.1501C213.983 75.7176 214.375 76.0013 214.792 76.0013Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M218.784 75.7486C218.784 75.9599 219.029 76.0772 219.3 76.0772C219.545 76.0772 219.791 75.9599 219.791 75.7956C219.791 75.4904 219.422 75.4904 219.201 75.467C218.612 75.42 218.023 75.2088 218.023 74.5516C218.023 73.8709 218.612 73.5423 219.275 73.5423C219.889 73.5423 220.527 73.8709 220.527 74.5516H219.717C219.717 74.3403 219.496 74.2464 219.275 74.2464C219.079 74.2464 218.833 74.2934 218.833 74.5281C218.833 74.7393 219.029 74.7863 219.324 74.8097C220.012 74.8567 220.601 75.021 220.601 75.7721C220.601 76.4293 219.938 76.7579 219.275 76.7579C218.587 76.7579 217.924 76.4293 217.949 75.7486H218.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M223.228 74.311H222.037V74.7601H223.129V75.4693H222.037V75.9656H223.253V76.6984H221.169V73.6019H223.204V74.311H223.228Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M226.603 76.5802V76.6984H225.722L225.159 75.7293H224.719V76.6984H223.887V73.6019C224.376 73.6019 224.841 73.6019 225.306 73.6019C226.089 73.6019 226.456 74.1692 226.456 74.7128C226.456 75.0674 226.285 75.422 225.966 75.6111L226.603 76.5802ZM225.306 75.0674C225.551 75.0674 225.648 74.8783 225.648 74.6656C225.648 74.4765 225.526 74.2874 225.306 74.2874H224.719V75.0674H225.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M228.276 75.3342L228.552 74.5273L228.955 73.6019H229.886V73.7205L228.452 76.758H228.099L226.665 73.7205V73.6019H227.596L228.024 74.5273L228.276 75.3342Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M231.782 76.0416V76.6984H230.076V76.0416H230.503V74.2587H230.126V73.6019H231.756V74.2587H231.38V76.0416H231.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M233.835 73.6019C234.509 73.6019 235.258 73.9552 235.383 74.7795H234.559C234.434 74.4498 234.185 74.332 233.835 74.332C233.336 74.332 233.011 74.6618 233.011 75.1799C233.011 75.6274 233.336 76.0278 233.835 76.0278C234.185 76.0278 234.484 75.863 234.559 75.5332H235.383C235.258 76.4047 234.534 76.758 233.835 76.758C232.986 76.758 232.162 76.2162 232.162 75.1799C232.162 74.12 232.986 73.6019 233.835 73.6019Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M237.944 74.311H236.753V74.7601H237.845V75.4693H236.753V75.9656H237.969V76.6984H235.885V73.6019H237.919V74.311H237.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <defs>
          <pattern id="4-t-pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use
              xlinkHref="#image0"
              transform="translate(-0.00768784) scale(0.00100915 0.00153332)"
            />
          </pattern>
          <filter
            id="4-t-filter0_i"
            x="300"
            y="133"
            width="112.94"
            height="148.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-t-filter1_i"
            x="168"
            y="133"
            width="112.94"
            height="148.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-t-filter2_i"
            x="36"
            y="133"
            width="113.94"
            height="148.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-t-filter3_i"
            x="36"
            y="41"
            width="376.94"
            height="74.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <linearGradient
            id="4-t-paint0_linear"
            x1="301.5"
            y1="291"
            x2="301.5"
            y2="316"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DBDDE1" />
            <stop offset="1" stopColor="#F9F9F9" />
          </linearGradient>
        </defs>
      </svg>
      {!withoutBackground && <ContainerBackground />}
    </ProductImageWrapper>
  )
}
