import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement43Square41Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-3-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M350.001 34C351.105 34 352 33.1048 352 32.0005C352 30.8962 351.105 30.001 350.001 30.001L347.833 30.001L347.833 30.001L337 30.001L337 30L333.999 30C332.895 30 332 30.8952 332 31.9995C332 33.1038 332.895 33.999 333.999 33.999L336.167 33.999L336.167 34L347 34L347 34L350.001 34Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 347.833 34)"
          fill="#ABABAB"
        />
        <path
          d="M351.06 34C351.579 34 352 33.5792 352 33.0601L352 30.941C352 30.4219 351.579 30.001 351.06 30.001L347 30.001L347 34L351.06 34Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M332.94 30C332.421 30 332 30.4208 332 30.9399L332 33.059C332 33.5781 332.421 33.999 332.94 33.999L337 33.999L337 30L332.94 30Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 346.167 34)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#4-3-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M411.001 34C412.105 34 413 33.1048 413 32.0005C413 30.8962 412.105 30.001 411.001 30.001L408.833 30.001L408.833 30.001L398 30.001L398 30L394.999 30C393.895 30 393 30.8952 393 31.9995C393 33.1038 393.895 33.999 394.999 33.999L397.167 33.999L397.167 34L408 34L408 34L411.001 34Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 408.833 34)"
          fill="#ABABAB"
        />
        <path
          d="M412.06 34C412.579 34 413 33.5792 413 33.0601L413 30.941C413 30.4219 412.579 30.001 412.06 30.001L408 30.001L408 34L412.06 34Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M393.94 30C393.421 30 393 30.4208 393 30.9399L393 33.059C393 33.5781 393.421 33.999 393.94 33.999L398 33.999L398 30L393.94 30Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 407.167 34)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#4-3-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 34H328V287H417V34ZM406 45H339V276H406V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 34H328V287H329.219V35H415.781V287H417V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-3-filter3_i)">
          <rect
            x="339.47"
            y="45.47"
            width="66.0601"
            height="230.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="332.53"
          y="38.53"
          width="79.9399"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="379"
          y="278"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 379 278)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="378.356"
          y="278.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 378.356 278.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M378.238 280.695C377.892 279.542 376.822 278.702 375.556 278.702L368.916 278.702C367.37 278.702 366.116 279.955 366.116 281.502C366.116 281.537 366.117 281.572 366.118 281.607C366.464 282.76 367.534 283.6 368.8 283.6L375.44 283.6C376.986 283.6 378.24 282.346 378.24 280.8C378.24 280.765 378.239 280.73 378.238 280.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M376.881 279.035C376.737 279.012 376.59 279 376.44 279L369.8 279C368.254 279 367 280.254 367 281.8C367 282.867 367.597 283.795 368.475 284.267C367.138 284.056 366.116 282.898 366.116 281.502C366.116 279.955 367.37 278.702 368.916 278.702L375.556 278.702C376.035 278.702 376.486 278.822 376.881 279.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-3-filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M375 281.5C375 280.672 374.328 280 373.5 280L373.286 280L372 280L353.5 280C352.672 280 352 280.672 352 281.5V281.5C352 282.328 352.672 283 353.5 283L370 283L373.5 283L373.714 283C374.424 283 375 282.424 375 281.714V281.714C375 281.677 374.999 281.64 374.996 281.604C374.999 281.569 375 281.535 375 281.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M404.424 46.5L372.5 271.442L340.576 46.5L404.424 46.5Z" stroke="white" />
        <defs>
          <filter
            id="4-3-filter0_d"
            x="330.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-3-filter1_d"
            x="391.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-3-filter2_d"
            x="323.3"
            y="29.3003"
            width="100.279"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-3-filter3_i"
            x="339"
            y="45"
            width="67.9399"
            height="233.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-3-filter4_d"
            x="350.28"
            y="278.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="334.5"
            y1="36.8"
            x2="334.5"
            y2="29.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="349.5"
            y1="27.2018"
            x2="349.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="334.5"
            y1="36.7982"
            x2="334.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="395.5"
            y1="36.8"
            x2="395.5"
            y2="29.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="410.5"
            y1="27.2018"
            x2="410.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="395.5"
            y1="36.7982"
            x2="395.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
