import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement73Square82Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M99.0005 231C100.105 231 101 230.105 101 229.001C101 227.896 100.105 227.001 99.0005 227.001L96.8335 227.001L96.8335 227.001L86 227.001L86 227L82.9995 227C81.8952 227 81 227.895 81 228.999C81 230.104 81.8952 230.999 82.9995 230.999L85.1668 230.999L85.1668 231L96 231L96 231L99.0005 231Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 96.8335 231)"
          fill="#ABABAB"
        />
        <path
          d="M100.06 231C100.579 231 101 230.579 101 230.06L101 227.941C101 227.422 100.579 227.001 100.06 227.001L96 227.001L96 231L100.06 231Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M81.9399 227C81.4208 227 81 227.421 81 227.94L81 230.059C81 230.578 81.4208 230.999 81.9399 230.999L86 230.999L86 227L81.9399 227Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 95.1666 231)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#4filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.0005 231C55.1048 231 56 230.105 56 229.001C56 227.896 55.1048 227.001 54.0005 227.001L51.8335 227.001L51.8335 227.001L41 227.001L41 227L37.9995 227C36.8952 227 36 227.895 36 228.999C36 230.104 36.8952 230.999 37.9995 230.999L40.1668 230.999L40.1668 231L51 231L51 231L54.0005 231Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 51.8335 231)"
          fill="#ABABAB"
        />
        <path
          d="M55.0601 231C55.5792 231 56 230.579 56 230.06L56 227.941C56 227.422 55.5792 227.001 55.0601 227.001L51 227.001L51 231L55.0601 231Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M36.9399 227C36.4208 227 36 227.421 36 227.94L36 230.059C36 230.578 36.4208 230.999 36.9399 230.999L41 230.999L41 227L36.9399 227Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 50.1666 231)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#4filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M105 231H32V286H105V231ZM95 241H42V276H95V241Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105 231H32V286H33V232H104V286H105V231Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4filter3_i)">
          <rect
            x="42.47"
            y="241.47"
            width="52.0601"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="235.53"
          width="63.9399"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M44.0182 242.5L68.5 274.182L92.9818 242.5L44.0182 242.5Z" stroke="white" />
        <rect
          x="75"
          y="277"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 75 277)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="74.356"
          y="277.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 74.356 277.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74.2379 279.695C73.8915 278.542 72.8218 277.702 71.5559 277.702L64.916 277.702C63.3696 277.702 62.116 278.956 62.116 280.502C62.116 280.537 62.1167 280.572 62.118 280.607C62.4643 281.76 63.534 282.6 64.8 282.6L71.4399 282.6C72.9863 282.6 74.2399 281.346 74.2399 279.8C74.2399 279.765 74.2392 279.73 74.2379 279.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72.881 278.035C72.7373 278.012 72.59 278 72.4399 278L65.8 278C64.2536 278 63 279.254 63 280.8C63 281.867 63.5969 282.795 64.475 283.267C63.1382 283.056 62.1161 281.898 62.1161 280.502C62.1161 278.956 63.3697 277.702 64.9161 277.702L71.556 277.702C72.0353 277.702 72.4865 277.822 72.881 278.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M71 280.5C71 279.672 70.3284 279 69.5 279L69.2857 279L68 279L49.5 279C48.6716 279 48 279.672 48 280.5V280.5C48 281.328 48.6716 282 49.5 282L66 282L69.5 282L69.7143 282C70.4244 282 71 281.424 71 280.714V280.714C71 280.677 70.9988 280.64 70.9965 280.604C70.9988 280.569 71 280.535 71 280.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="4filter0_d"
            x="79.1201"
            y="225.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4filter1_d"
            x="34.1201"
            y="225.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4filter2_d"
            x="27.3003"
            y="226.3"
            width="84.2793"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4filter3_i"
            x="42"
            y="241"
            width="53.9399"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4filter4_d"
            x="46.28"
            y="277.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="83.5"
            y1="233.8"
            x2="83.5"
            y2="226.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="98.5"
            y1="224.202"
            x2="98.5"
            y2="231.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="83.5"
            y1="233.798"
            x2="83.5"
            y2="226.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="38.5"
            y1="233.8"
            x2="38.5"
            y2="226.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="53.5"
            y1="224.202"
            x2="53.5"
            y2="231.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="38.5"
            y1="233.798"
            x2="38.5"
            y2="226.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
