import React from 'react'
import { StageDescriptionBlockContentItemIcon } from 'src/react-app/views/calculator/styles'

export const Energy1 = () => {
  return (
    <StageDescriptionBlockContentItemIcon>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.6"
          d="M32.5 40H7.5C3.3575 40 0 36.6425 0 32.5V7.5C0 3.3575 3.3575 0 7.5 0H32.5C36.6425 0 40 3.3575 40 7.5V32.5C40 36.6425 36.6425 40 32.5 40Z"
          fill="#E0E3E9"
        />
        <mask id="path-3-inside-1" fill="white">
          <rect x="11" y="11" width="18" height="18" rx="1" />
        </mask>
        <rect
          x="11"
          y="11"
          width="18"
          height="18"
          rx="1"
          stroke="#1C3775"
          strokeWidth="3"
          mask="url(#path-3-inside-1)"
        />
        <rect x="11" y="20" width="18" height="9" rx="1" fill="#1C3775" />
        <path
          d="M22 17.0882C22 18.1441 21.1046 19 20 19C18.8954 19 18 18.1441 18 17.0882C18 16.0324 19.0769 14.9559 20 14C20.9231 15.0294 22 16.0324 22 17.0882Z"
          fill="#1C3775"
        />
        <path
          d="M24 17.8529C24 18.4864 23.5523 19 23 19C22.4477 19 22 18.4864 22 17.8529C22 17.2194 22.5385 16.5735 23 16C23.4615 16.6176 24 17.2194 24 17.8529Z"
          fill="#1C3775"
        />
        <path
          d="M18 17.8529C18 18.4864 17.5523 19 17 19C16.4477 19 16 18.4864 16 17.8529C16 17.2194 16.5385 16.5735 17 16C17.4615 16.6176 18 17.2194 18 17.8529Z"
          fill="#1C3775"
        />
        <rect x="15" y="24" width="10" height="3" rx="1" fill="#ECEEF2" />
      </svg>
    </StageDescriptionBlockContentItemIcon>
  )
}
