import React, { FC, useCallback, useContext } from 'react'
import { IOrderInfoBlockProps } from '../stage-calculator/interface'
import { orderInfoList } from './consts'
import {
  OrderInfoBlockWrapper,
  OrderInfoBlockItem,
  OrderInfoBlockItemTitle,
  OrderInfoBlockItemValue,
} from './styles'
import { PersonalAreaContext } from './stage-personal-area'
import { dateFNS } from '../../../utilits'

const parseDate = date => (date ? dateFNS.format(new Date(date), 'dd.MM.yyyy') : '')

function getDateByStage(dates, stage) {
  const result = dates.find(item => item?.code === stage)
  if (result?.['date-fact']) {
    return parseDate(result?.['date-fact'])
  }
  if (result?.['date-adjusted']) {
    return parseDate(result?.['date-adjusted'])
  }
  if (result?.['date-plan']) {
    return parseDate(result?.['date-plan'])
  }
  if (result?.['date']) {
    return parseDate(result?.['date'])
  }
  return '-'
}

function getTitleByCode(title, code, data) {
 if (['delivery', 'mounting', 'manufacture'].includes(code)) {
  const result = (data?.['dates'] || []).find(item => item?.code === code)
  if (result?.['date-fact']) {
    const word = title.split(' ').pop()
    return `Дата ${word}`
  }
 }
 return title
}


export const OrderInfoBlock: FC<IOrderInfoBlockProps> = props => {
  const { data } = props
  const { profile } = useContext(PersonalAreaContext)

  const getValue = useCallback(
    code => {
      let result: any = 'Нет данных'

      switch (code) {
        case 'recipient':
          if (profile) {
            result = profile['profile-data']
              ? `${profile['profile-data']['name']}
          ${profile['profile-data']['phone']}`
              : ''
          }
          break
        case 'other-recipient':
          if (
            data['specification-products'] &&
            data['specification-products']['source'] &&
            data['specification-products']['source']['orderAdditionalData'] &&
            data['specification-products']['source']['orderAdditionalData']['recipient']
          ) {
            const recipient =
              data['specification-products']['source']['orderAdditionalData']['recipient']

            if (recipient && recipient['other-recipient-first-name']) {
              result = `${recipient['other-recipient-first-name']} ${recipient['other-recipient-last-name']}
            ${recipient['other-recipient-phone']}`
            }
          }

          break
        case 'address':
          if (data['services'].length > 0) {
            const addressInfo = data['services'].find(service => service['code'] === 'delivery')

            result = addressInfo && addressInfo['description'] ? addressInfo['description'] : ''
          }
          break
        case 'delivery':
          result = getDateByStage(data?.['dates'] || [], code)
          break
        case 'mounting':
          result = getDateByStage(data?.['dates'] || [], code)
          /*
          if (data['services'].length > 0) {
            const date = getDate(code)

            result = date || result
          }
          */
          break
        case 'manufacture':
          result = getDateByStage(data?.['dates'] || [], code)
          /*
          if (data['specification-products'] && data['specification-products']['source']) {
            const { source } = data['specification-products']
            result =
              (source && source['forecast'] && parseDate(source['forecast']['date-avg'])) || result
          }
          */
          break
        case 'pay-type':
          if (data['owner-organization'] === 1) {
            result = 'оплата проведена, ожидает подтверждения'
          }
          if (['new', 'work', 'measurement', null].includes(data['stage-code'] || null) === false) {
            result = 'оплата подтверждена'
          }
          break
        case 'scan-spec':{
            const docs = data?.['scan-spec-products']
            const isStage = ['new', 'work', 'measurement', 'prepayment', null].includes(data['stage-code'] || null) === false
            if (Array.isArray(docs) && docs.length && isStage) {
              result = <>{docs.map(item => (<a href={item?.url}>{item.name}</a>))}</>
            }
            break
        }
        case 'file-accepted-client':{
          const docs = data?.['file-accepted-client']
          
          if (Array.isArray(docs) && docs.length) {
            result = <>{docs.map(item => (<a href={item?.url}>{item.name}</a>))}</>
          }
          break
        }
        case 'file-mounting-accepted': {
          const docs = data?.['file-mounting-accepted']
          
          if (Array.isArray(docs) && docs.length) {
            result = <>{docs.map(item => (<a href={item?.url}>{item.name}</a>))}</>
          }
          break
        }
        default:
          break
      }

      return result
    },
    [data, profile],
  )

  return (
    <OrderInfoBlockWrapper>
      {orderInfoList.map(info => (
        <OrderInfoBlockItem key={`order-info-${info.code}`}>
          <OrderInfoBlockItemTitle>{getTitleByCode(info.title, info.code, data)}</OrderInfoBlockItemTitle>
          <OrderInfoBlockItemValue>{getValue(info.code)}</OrderInfoBlockItemValue>
        </OrderInfoBlockItem>
      ))}
    </OrderInfoBlockWrapper>
  )
}
