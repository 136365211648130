import styled from 'styled-components'

export const SwitcherWrapper = styled.div`
  background-color: #f3f4f6;
  border-radius: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 24px;
  width: 100%;
`

export const SwitcherItem = styled.div<{ active: boolean }>`
  border-radius: 50px;

  padding: 10px 32px;

  text-align: center;

  background-color: ${({ active = false }) => (active ? '#1c3775' : '#f3f4f6')};
  color: ${({ active = false }) => (active ? '#ffffff' : '#333333')};

  cursor: pointer;
`
