import React, { FC } from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'
import { IContainerSquareProps } from '../../interface'
import { ContainerBackground } from '../container-background'

export const ContainerSquare1Casement: FC<IContainerSquareProps> = props => {
  const { onCasementClick, withoutBackground } = props

  return (
    <ProductImageWrapper isClickable>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <rect width="220" height="333" fill="white" />
        <rect x="10" y="18" width="186" height="286" fill="#E8EAEE" />
        <rect x="10" y="18" width="186" height="286" fill="url(#1-pattern-1-0)" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M210 10H10V292H24V28H196V292H210V10Z"
          fill="#C4C4C4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M210 292H10V308H0V316H10H210H220V308H210V292Z"
          fill="url(#1-paint0_linear)"
        />
        <path d="M10 10L24 28.1525V291.982L10 308V10Z" fill="#E9EBEF" />
        <path d="M10 10L24 28.1525V291.982L10 308V10Z" fill="#020202" fillOpacity="0.06" />
        <path d="M210 10L196 28.1525V291.982L210 308V10Z" fill="#E9EBEF" />
        <path d="M210 10L196 28.1525V291.982L210 308V10Z" fill="#020202" fillOpacity="0.06" />
        <rect y="316" width="220" height="17" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M196 27H24V292H196V27ZM183 40H37V279H183V40Z"
          fill="#E8EAEE"
        />
        <g
          filter="url(#1-filter0_i)"
          className="casement"
          data-index="0"
          onClick={onCasementClick(0)}
        >
          <rect x="37" y="40" width="146" height="239" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="37.47"
          y="40.47"
          width="145.06"
          height="238.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <path
          d="M118.985 66.7412H116.447C115.8 66.7412 115.253 66.2345 115.253 65.6356V65.4053C115.253 64.8064 115.8 64.2997 116.447 64.2997H118.985C119.632 64.2997 120.179 64.8064 120.179 65.4053V65.6356C120.179 66.2575 119.657 66.7412 118.985 66.7412Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M101.426 69.5652C101.725 69.5652 101.799 69.5652 102.197 69.5652C102.943 69.5652 103.49 70.1052 103.49 70.833C103.49 71.5843 102.918 72.1008 102.147 72.1008C100.705 72.1008 99.8098 72.1008 98.3674 72.1008C97.8203 72.1008 97.3727 71.913 97.1488 71.4199C96.9499 71.1147 96.8255 70.2695 97.6213 69.5652C98.1436 68.9782 103.913 62.7565 104.933 61.6765C104.982 61.6061 104.933 61.5122 104.858 61.5122C104.833 61.5122 104.833 61.5122 104.808 61.5122C102.645 61.5122 100.481 61.5122 98.3177 61.5122C97.6711 61.5122 97.1737 61.1365 97.0494 60.573C96.9002 59.9156 97.1986 59.3522 97.7706 59.0939C97.9446 59.0235 98.1187 59 98.2928 59C101.501 59 104.684 59 107.892 59C108.514 59 108.937 59.1878 109.185 59.7278C109.509 60.4087 109.16 60.8783 108.937 61.1835C108.489 61.7235 103.938 66.56 101.426 69.2834C101.401 69.3069 101.376 69.3539 101.327 69.3773C101.277 69.4478 101.327 69.5652 101.426 69.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M108.186 72.1009H105.648C105.001 72.1009 104.453 71.5818 104.453 70.9684V70.7324C104.453 70.1189 105.001 69.5999 105.648 69.5999H108.186C108.832 69.5999 109.38 70.1189 109.38 70.7324V70.9684C109.38 71.6054 108.832 72.1009 108.186 72.1009Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M111.715 65.5152C111.715 63.7341 111.715 61.9764 111.715 60.1952C111.715 59.4922 112.237 59 112.983 59C116.215 59 119.446 59 122.678 59C123.424 59 123.946 59.5156 123.946 60.2421C123.946 60.9686 123.424 61.5077 122.678 61.5077C119.993 61.5077 117.333 61.5077 114.649 61.5077C114.45 61.5077 114.4 61.5545 114.4 61.742C114.4 64.2731 114.4 66.8042 114.4 69.3353C114.4 69.5463 114.475 69.5697 114.649 69.5697C117.309 69.5697 119.993 69.5697 122.653 69.5697C123.25 69.5697 123.722 69.8978 123.896 70.39C124.095 70.9759 123.871 71.5618 123.399 71.8899C123.175 72.0305 122.902 72.1008 122.628 72.1008C119.446 72.1008 116.24 72.1008 113.058 72.1008C112.237 72.1008 111.74 71.5852 111.74 70.8118C111.715 69.0307 111.715 67.273 111.715 65.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M98.7054 77.0416V77.6985H97V77.0416H97.4263V75.2588H97.0502V74.6019H98.6803V75.2588H98.3041V77.0416H98.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M102.534 77.7579H101.774L101.651 77.5451C101.381 77.687 101.062 77.7579 100.768 77.7579C99.9334 77.7342 99.1482 77.1668 99.1482 76.1501C99.1482 75.1806 99.8107 74.5422 100.817 74.5422C101.651 74.5422 102.461 75.0861 102.461 76.1501C102.461 76.5284 102.363 76.8121 102.215 77.0722L102.559 77.6397V77.7579H102.534ZM100.792 77.0013C101.185 77.0013 101.602 76.7175 101.602 76.1501C101.602 75.5826 101.185 75.2989 100.792 75.2989C100.375 75.2989 99.9824 75.5826 99.9824 76.1501C99.9824 76.7175 100.375 77.0013 100.792 77.0013Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M104.784 76.7486C104.784 76.9598 105.029 77.0772 105.299 77.0772C105.545 77.0772 105.79 76.9598 105.79 76.7955C105.79 76.4904 105.422 76.4904 105.201 76.4669C104.612 76.42 104.022 76.2087 104.022 75.5515C104.022 74.8708 104.612 74.5422 105.275 74.5422C105.889 74.5422 106.527 74.8708 106.527 75.5515H105.717C105.717 75.3403 105.496 75.2464 105.275 75.2464C105.078 75.2464 104.833 75.2933 104.833 75.5281C104.833 75.7393 105.029 75.7862 105.324 75.8097C106.011 75.8567 106.601 76.021 106.601 76.7721C106.601 77.4293 105.938 77.7579 105.275 77.7579C104.587 77.7579 103.924 77.4293 103.949 76.7486H104.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M109.229 75.3111H108.038V75.7602H109.13V76.4693H108.038V76.9657H109.254V77.6985H107.169V74.6019H109.204V75.3111H109.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M112.603 77.5803V77.6985H111.722L111.159 76.7293H110.719V77.6985H109.887V74.6019C110.376 74.6019 110.841 74.6019 111.306 74.6019C112.089 74.6019 112.456 75.1692 112.456 75.7129C112.456 76.0675 112.285 76.422 111.967 76.6111L112.603 77.5803ZM111.306 76.0675C111.551 76.0675 111.649 75.8784 111.649 75.6656C111.649 75.4765 111.526 75.2874 111.306 75.2874H110.719V76.0675H111.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M114.276 76.3342L114.553 75.5274L114.956 74.6019H115.887V74.7206L114.452 77.758H114.1L112.666 74.7206V74.6019H113.597L114.024 75.5274L114.276 76.3342Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M117.781 77.0416V77.6985H116.076V77.0416H116.502V75.2588H116.126V74.6019H117.756V75.2588H117.38V77.0416H117.781Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M119.835 74.6019C120.509 74.6019 121.258 74.9552 121.383 75.7796H120.559C120.434 75.4498 120.184 75.3321 119.835 75.3321C119.335 75.3321 119.011 75.6618 119.011 76.18C119.011 76.6275 119.335 77.0279 119.835 77.0279C120.184 77.0279 120.484 76.863 120.559 76.5333H121.383C121.258 77.4047 120.534 77.758 119.835 77.758C118.986 77.758 118.162 77.2163 118.162 76.18C118.162 75.1201 118.986 74.6019 119.835 74.6019Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M123.944 75.3111H122.753V75.7602H123.845V76.4693H122.753V76.9657H123.969V77.6985H121.885V74.6019H123.919V75.3111H123.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <defs>
          <pattern id="1-pattern-1-0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use
              xlinkHref="#image0"
              transform="translate(-2.07767 -0.027972) scale(0.00351032 0.0022839)"
            />
          </pattern>
          <filter
            id="1-filter0_i"
            x="37"
            y="40"
            width="146.94"
            height="241.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <linearGradient
            id="1-paint0_linear"
            x1="301.5"
            y1="291"
            x2="301.5"
            y2="316"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DBDDE1" />
            <stop offset="1" stopColor="#F9F9F9" />
          </linearGradient>
        </defs>
      </svg>
      {!withoutBackground && <ContainerBackground />}
    </ProductImageWrapper>
  )
}
