import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement64Square8Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#6-4_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M131 177.999C131 176.895 130.105 176 129.001 176C127.896 176 127.001 176.895 127.001 177.999V180.167H127.001V191H127V194.001C127 195.105 127.895 196 128.999 196C130.104 196 130.999 195.105 130.999 194.001V191.833H131V181H131V177.999Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 131 180.167)"
          fill="#ABABAB"
        />
        <path
          d="M131 176.94C131 176.421 130.579 176 130.06 176H127.941C127.422 176 127.001 176.421 127.001 176.94V181H131V176.94Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M127 195.06C127 195.579 127.421 196 127.94 196H130.059C130.578 196 130.999 195.579 130.999 195.06V191H127V195.06Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 131 181.833)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#6-4_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M131 254C131 252.895 130.105 252 129 252C127.895 252 127 252.895 127 254V268.56H131V254ZM131 268.56H127V270.4H126.94C126.421 270.4 126 270.821 126 271.34V273.12C126 274.158 126.842 275 127.88 275H129.12C130.158 275 131 274.158 131 273.12V270.4V270.4V268.56Z"
            fill="white"
          />
        </g>
        <rect
          width="3.99998"
          height="0.95833"
          transform="matrix(-1 0 0 1 131 269.25)"
          fill="#ABABAB"
        />
        <path
          d="M131 252.94C131 252.421 130.579 252 130.06 252H127.94C127.421 252 127 252.421 127 252.94V269.25H131V252.94Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M131 274.06C131 274.579 130.579 275 130.06 275H126.94C126.421 275 126 274.579 126 274.06V271.148C126 270.629 126.421 270.208 126.94 270.208H131V274.06Z"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#6-4_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M220 165H131V286H220V165ZM209 176H142V275H209V176Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M220 165H131V286H132.219V166H218.781V286H220V165Z"
          fill="#FBFBFB"
        />
        <g filter="url(#6-4_filter3_i)">
          <rect
            x="142.47"
            y="176.47"
            width="66.0601"
            height="98.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="135.53"
          y="169.53"
          width="79.9399"
          height="111.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="211"
          y="218"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#6-4_filter4_d)">
          <rect x="211.702" y="218.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M212.286 229.796C212.798 230.458 213.6 230.884 214.502 230.884C216.048 230.884 217.302 229.63 217.302 228.084V221.444C217.302 220.456 216.79 219.587 216.016 219.089C216.382 219.562 216.6 220.156 216.6 220.8V227.44C216.6 228.986 215.347 230.24 213.8 230.24C213.242 230.24 212.722 230.077 212.286 229.796Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M214.502 218.644C212.956 218.644 211.702 219.898 211.702 221.444V227.44C211.702 227.46 211.702 227.48 211.703 227.501V222.024C211.703 220.477 212.956 219.224 214.503 219.224C216.029 219.224 217.269 220.444 217.302 221.963V221.444C217.302 219.898 216.048 218.644 214.502 218.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#6-4_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M214.5 222C213.672 222 213 222.672 213 223.5V223.714V225V243.5C213 244.328 213.672 245 214.5 245V245C215.328 245 216 244.328 216 243.5V227V223.5L216 223.286C216 222.576 215.424 222 214.714 222V222C214.677 222 214.64 222.001 214.604 222.004C214.569 222.001 214.535 222 214.5 222Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M143.5 177.997L207.164 225.5L143.5 273.003L143.5 177.997Z" stroke="white" />
        <path d="M143.695 273.5L175.5 178.574L207.305 273.5L143.695 273.5Z" stroke="white" />
        <defs>
          <filter
            id="6-4_filter0_d"
            x="125.12"
            y="174.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-4_filter1_d"
            x="124.12"
            y="250.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-4_filter2_d"
            x="126.3"
            y="160.3"
            width="100.279"
            height="132.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-4_filter3_i"
            x="142"
            y="176"
            width="67.9399"
            height="101.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="6-4_filter4_d"
            x="209.822"
            y="216.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-4_filter5_d"
            x="211.12"
            y="220.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="133.8"
            y1="193.5"
            x2="126.2"
            y2="193.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="124.202"
            y1="178.5"
            x2="131.8"
            y2="178.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="133.798"
            y1="193.5"
            x2="126.2"
            y2="193.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="124.2"
            y1="260.625"
            x2="131.8"
            y2="260.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="122.5"
            y1="272.604"
            x2="132"
            y2="272.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
