import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement12Square4TopCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-top-1-2_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M404.217 123C405.322 123 406.217 122.105 406.217 121.001C406.217 119.896 405.322 119.001 404.217 119.001L401.797 119.001L401.797 119.001L390.304 119.001L390.304 119L386.999 119C385.895 119 385 119.895 385 120.999C385 122.104 385.895 122.999 386.999 122.999L389.42 122.999L389.42 123L400.913 123L400.913 123L404.217 123Z"
            fill="url(#4-top-1-2_paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="12.3767"
          transform="matrix(1.26506e-08 -1 -1 -1.12408e-08 401.797 123)"
          fill="#ABABAB"
        />
        <path
          d="M405.277 123C405.796 123 406.217 122.579 406.217 122.06L406.217 119.941C406.217 119.422 405.796 119.001 405.277 119.001L400.913 119.001L400.913 123L405.277 123Z"
          fill="url(#4-top-1-2_paint1_linear)"
        />
        <path
          d="M385.94 119C385.421 119 385 119.421 385 119.94L385 122.059C385 122.578 385.421 122.999 385.94 122.999L390.304 122.999L390.304 119L385.94 119Z"
          fill="url(#4-top-1-2_paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.84048"
          transform="matrix(1.26506e-08 -1 -1 -1.12408e-08 400.028 123)"
          fill="url(#4-top-1-2_paint3_linear)"
        />
        <g filter="url(#4-top-1-2_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M62.8872 123C63.9915 123 64.8867 122.105 64.8867 121.001C64.8867 119.896 63.9915 119.001 62.8872 119.001L60.4666 119.001L60.4666 119.001L48.9739 119.001L48.9739 119L45.669 119C44.5648 119 43.6696 119.895 43.6696 120.999C43.6696 122.104 44.5648 122.999 45.669 122.999L48.0899 122.999L48.0899 123L59.5824 123L59.5824 123L62.8872 123Z"
            fill="url(#4-top-1-2_paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="12.3767"
          transform="matrix(1.26506e-08 -1 -1 -1.12408e-08 60.4668 123)"
          fill="#ABABAB"
        />
        <path
          d="M63.9468 123C64.4659 123 64.8867 122.579 64.8867 122.06L64.8867 119.941C64.8867 119.422 64.4659 119.001 63.9468 119.001L59.5824 119.001L59.5824 123L63.9468 123Z"
          fill="url(#4-top-1-2_paint5_linear)"
        />
        <path
          d="M44.6099 119C44.0907 119 43.6699 119.421 43.6699 119.94L43.6699 122.059C43.6699 122.578 44.0907 122.999 44.6099 122.999L48.9742 122.999L48.9742 119L44.6099 119Z"
          fill="url(#4-top-1-2_paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.84048"
          transform="matrix(1.26506e-08 -1 -1 -1.12408e-08 58.6982 123)"
          fill="url(#4-top-1-2_paint7_linear)"
        />
        <g filter="url(#4-top-1-2_filter2_i)">
          <rect
            x="43.47"
            y="45.47"
            width="362.06"
            height="62.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <g filter="url(#4-top-1-2_filter3_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 34H32V119H417V34ZM406 45H43V108H406V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 34H32V119H33V35H416V119H417V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="36.53"
          y="38.53"
          width="375.94"
          height="75.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M47.0332 106.5L224 46.5278L401.951 106.5H47.0332Z" stroke="white" />
        <rect
          x="237.528"
          y="36"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 237.528 36)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#4-top-1-2_filter4_d)">
          <rect
            x="236.884"
            y="36.7018"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 236.884 36.7018)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M236.767 38.695C236.42 37.5419 235.351 36.7018 234.085 36.7018L227.445 36.7018C225.898 36.7018 224.645 37.9554 224.645 39.5018C224.645 39.537 224.645 39.572 224.647 39.6068C224.993 40.7599 226.063 41.6 227.329 41.6L233.969 41.6C235.515 41.6 236.769 40.3464 236.769 38.8C236.769 38.7649 236.768 38.7299 236.767 38.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M235.409 37.0346C235.266 37.0118 235.118 37 234.968 37L228.328 37C226.782 37 225.528 38.2536 225.528 39.8C225.528 40.8671 226.125 41.7947 227.003 42.2673C225.666 42.0558 224.644 40.8982 224.644 39.5018C224.644 37.9554 225.898 36.7018 227.444 36.7018L234.084 36.7018C234.564 36.7018 235.015 36.8223 235.409 37.0346Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-top-1-2_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M250.528 39.5C250.528 38.6716 249.857 38 249.028 38L248.814 38L247.528 38L229.028 38C228.2 38 227.528 38.6716 227.528 39.5C227.528 40.3284 228.2 41 229.028 41L245.528 41L249.028 41L249.243 41C249.953 41 250.528 40.4244 250.528 39.7143C250.528 39.6771 250.527 39.6401 250.525 39.6035C250.527 39.5693 250.528 39.5348 250.528 39.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="4-top-1-2_filter0_d"
            x="383.12"
            y="117.12"
            width="26.8568"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-1-2_filter1_d"
            x="41.79"
            y="117.12"
            width="26.8568"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-1-2_filter2_i"
            x="43"
            y="45"
            width="363.94"
            height="65.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-top-1-2_filter3_d"
            x="27.3003"
            y="29.3003"
            width="396.279"
            height="96.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-1-2_filter4_d"
            x="222.764"
            y="34.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-1-2_filter5_d"
            x="226.649"
            y="36.1201"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="4-top-1-2_paint0_linear"
            x1="387.652"
            y1="125.8"
            x2="387.652"
            y2="118.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="4-top-1-2_paint1_linear"
            x1="403.565"
            y1="116.202"
            x2="403.565"
            y2="123.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-1-2_paint2_linear"
            x1="387.652"
            y1="125.798"
            x2="387.652"
            y2="118.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-1-2_paint3_linear"
            x1="6.79822"
            y1="4.42024"
            x2="-0.799791"
            y2="4.42024"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-1-2_paint4_linear"
            x1="46.3217"
            y1="125.8"
            x2="46.3217"
            y2="118.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="4-top-1-2_paint5_linear"
            x1="62.2346"
            y1="116.202"
            x2="62.2346"
            y2="123.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-1-2_paint6_linear"
            x1="46.3221"
            y1="125.798"
            x2="46.3221"
            y2="118.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-1-2_paint7_linear"
            x1="6.79822"
            y1="4.42024"
            x2="-0.799791"
            y2="4.42024"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
