import React, { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react'

import { INumberProps } from './interface'
import { NumberError, NumberStyle, NumberWrapper, InputReq } from './styles'
import { InputTypes } from '../../../interface'


export const NumberInput: FC<INumberProps> = props => {
  const {
    placeholder,
    data = { min: 0, max: 0 },
    selected,
    onSelect,
    onEnter = () => null,
    onBlur = () => null,
    selectOnFocus,
    noError = false,
    disabled = false,
    type = InputTypes.number,
    title,
    req
  } = props
  const inputRef = useRef<HTMLInputElement>(null)
  const { min, max } = data
  const [range, setRange] = useState({ min: 0, max: 10000 })
  const [value, setValue] = useState<string | number | undefined>()
  const [error, setError] = useState('')
  const [isFocused, setIsFocused] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    /**
     *  Set range data
     *  @returns {void}
     *  */
    const setData = (): void => {
      const getNewMin = (): number => {
        let result: number | null = typeof min === 'undefined' ? 0 : null

        if (typeof min !== 'undefined' && min !== null) {
          if (min.constructor === Array) {
            min.forEach(item => {
              if (typeof item.value !== 'undefined') {
                if (result === null) {
                  result = item.value
                } else if (item.value < result) {
                  result = item.value
                }
              }
            })
          } else {
            result = min.constructor === Number ? (min as number) : 0
          }
        }

        return result as number
      }
      const getNewMax = (): number => {
        let result = 0

        if (typeof max !== 'undefined' && max !== null) {
          if (max.constructor === Array) {
            max.forEach(item => {
              if (typeof item.value !== 'undefined' && item.value > result) {
                result = item.value
              }
            })
          } else {
            result = max.constructor === Number ? (max as number) : 0
          }
        }

        return result
      }

      const superMin = getNewMin()
      const superMax = getNewMax()

      if (range['min'] !== superMin || range['max'] !== superMax) {
        setRange({
          min: superMin,
          max: superMax,
        })
      }
    }

    if (selected) {
      if (selected !== value) {
        setValue(selected)
      }
    } else {
      setValue('')
    }

    setData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, data])

  useEffect(() => {
    if (disabled) {
      setIsDisabled(true)
    } else if (isDisabled && isFocused) {
      if (inputRef.current) {
        inputRef.current.focus()
      }

      setIsFocused(false)
      setIsDisabled(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled])

  const submitInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      let val = Number(event?.target?.value || 0)
      let validError: string | undefined

      if (!noError) {
        if (val < range.min) {
          validError = `Минимальный размер ${range.min}мм`
          val = range.min
        } else if (val > range.max) {
          validError = `Максимальный размер ${range.max}мм`
          val = range.max
        }
      }

      if (validError) {
        setError(validError)
        onSelect(selected)
      } else {
        onSelect(val)
      }
    },
    [noError, onSelect, range.max, range.min, selected],
  )

  /** Validate inserted data */
  const handleBlur = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      submitInput(event)
      setIsFocused(false)
      onBlur()
    },
    [onBlur, submitInput],
  )

  const handleKeypress = useCallback(
    event => {
      if (event.key === 'Enter') {
        submitInput(event)
        onEnter()
      }
    },
    [onEnter, submitInput],
  )

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      // eslint-disable-next-line no-shadow
      let value = event?.target?.value

      if (/^0/.test(value)) {
        value = value.replace(/^0/, '')
      }

      const result = Number(value || range.min)
      // Фикс поднятия на этаж
      if (title === 'stage') {
        onSelect(result)
      }
      setError('')
      setValue(result)
    },
    [range.min, title, onSelect],
  )

  const handleFocus = useCallback(() => {
    if (inputRef && inputRef.current && selectOnFocus) {
      setIsFocused(true)
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.select()
        }
      }, 1)
    }
  }, [selectOnFocus])

  return (
    <NumberWrapper>
      { req && <InputReq >*</InputReq> }
      <NumberStyle
        ref={inputRef}
        placeholder={placeholder}
        type={type}
        min={range.min}
        max={range.max}
        onInput={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyPress={handleKeypress}
        value={value}
        disabled={disabled}
      />
      {error && <NumberError>{error}</NumberError>}
    </NumberWrapper>
  )
}

export default NumberInput
