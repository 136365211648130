import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement22Square62Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-2_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 176.999C32 175.895 31.1048 175 30.0005 175C28.8962 175 28.001 175.895 28.001 176.999V179.167H28.001V190H28V193.001C28 194.105 28.8952 195 29.9995 195C31.1038 195 31.999 194.105 31.999 193.001V190.833H32V180H32V176.999Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 32 179.167)"
          fill="#ABABAB"
        />
        <path
          d="M32 175.94C32 175.421 31.5792 175 31.0601 175H28.941C28.4219 175 28.001 175.421 28.001 175.94V180H32V175.94Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M28 194.06C28 194.579 28.4208 195 28.9399 195H31.059C31.5781 195 31.999 194.579 31.999 194.06V190H28V194.06Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 32 180.833)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#2-2_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 126.999C32 125.895 31.1048 125 30.0005 125C28.8962 125 28.001 125.895 28.001 126.999V129.167H28.001V140H28V143.001C28 144.105 28.8952 145 29.9995 145C31.1038 145 31.999 144.105 31.999 143.001V140.833H32V130H32V126.999Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 32 129.167)"
          fill="#ABABAB"
        />
        <path
          d="M32 125.94C32 125.421 31.5792 125 31.0601 125H28.941C28.4219 125 28.001 125.421 28.001 125.94V130H32V125.94Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M28 144.06C28 144.579 28.4208 145 28.9399 145H31.059C31.5781 145 31.999 144.579 31.999 144.06V140H28V144.06Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 32 130.833)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#2-2_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M105 121H32V199H105V121ZM94 132H43V188H94V132Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105 121H32V199H33V122H104V199H105V121Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-2_filter3_i)">
          <rect
            x="43.47"
            y="132.47"
            width="50.0601"
            height="55.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="125.53"
          width="63.9399"
          height="68.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="96"
          y="152"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#2-2_filter4_d)">
          <rect x="96.7018" y="152.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M97.2859 163.795C97.7981 164.457 98.6003 164.884 99.5021 164.884C101.049 164.884 102.302 163.63 102.302 162.084V155.444C102.302 154.455 101.79 153.587 101.017 153.088C101.383 153.562 101.6 154.155 101.6 154.8V161.44C101.6 162.986 100.347 164.24 98.8003 164.24C98.2424 164.24 97.7225 164.077 97.2859 163.795Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99.5018 152.644C97.9554 152.644 96.7018 153.898 96.7018 155.444V161.44C96.7018 161.46 96.7021 161.48 96.7025 161.501V156.024C96.7025 154.477 97.9561 153.224 99.5025 153.224C101.029 153.224 102.269 154.444 102.302 155.963V155.444C102.302 153.898 101.048 152.644 99.5018 152.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-2_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M99.5 156C98.6716 156 98 156.672 98 157.5V157.714V159V177.5C98 178.328 98.6716 179 99.5 179V179C100.328 179 101 178.328 101 177.5V161V157.5L101 157.286C101 156.576 100.424 156 99.7143 156V156C99.6771 156 99.6401 156.001 99.6035 156.004C99.5693 156.001 99.5348 156 99.5 156Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M44.5 186.138L91.9638 159.016L44.5 133.831L44.5 186.138Z" stroke="white" />
        <defs>
          <filter
            id="2-2_filter0_d"
            x="26.1201"
            y="173.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-2_filter1_d"
            x="26.1201"
            y="123.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-2_filter2_d"
            x="27.3003"
            y="116.3"
            width="84.2793"
            height="89.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-2_filter3_i"
            x="43"
            y="132"
            width="51.9399"
            height="58.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-2_filter4_d"
            x="94.822"
            y="150.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-2_filter5_d"
            x="96.1201"
            y="154.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="34.8"
            y1="192.5"
            x2="27.2"
            y2="192.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="25.2018"
            y1="177.5"
            x2="32.7998"
            y2="177.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="34.7982"
            y1="192.5"
            x2="27.2002"
            y2="192.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="34.8"
            y1="142.5"
            x2="27.2"
            y2="142.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="25.2018"
            y1="127.5"
            x2="32.7998"
            y2="127.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="34.7982"
            y1="142.5"
            x2="27.2002"
            y2="142.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
