import React, { FC, useState, useCallback, useEffect } from 'react'

import { ITooltipProps } from './interface'
import { TooltipWrapper, TooltipBlock } from './styles'

export const Tooltip: FC<ITooltipProps> = props => {
  const { text, importantShow, coord, direction, children = true } = props
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (typeof importantShow !== 'undefined') {
      setShow(importantShow)
    }
  }, [importantShow])

  const handleMouseOver = useCallback(() => {
    if (!importantShow) {
      setShow(true)
    }
  }, [importantShow])

  const handleMouseLeave = useCallback(() => {
    if (!importantShow) {
      setShow(false)
    }
  }, [importantShow])

  return (
    <TooltipWrapper onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
      <>{children}</>
      <TooltipBlock show={show} importantShow={importantShow} coord={coord} direction={direction}>
        {text}
      </TooltipBlock>
    </TooltipWrapper>
  )
}
