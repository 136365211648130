import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement76Square8Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#7-6_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M323 177.999C323 176.895 322.105 176 321.001 176C319.896 176 319.001 176.895 319.001 177.999V180.167H319.001V191H319V194.001C319 195.105 319.895 196 320.999 196C322.104 196 322.999 195.105 322.999 194.001V191.833H323V181H323V177.999Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 323 180.167)"
          fill="#ABABAB"
        />
        <path
          d="M323 176.94C323 176.421 322.579 176 322.06 176H319.941C319.422 176 319.001 176.421 319.001 176.94V181H323V176.94Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M319 195.06C319 195.579 319.421 196 319.94 196H322.059C322.578 196 322.999 195.579 322.999 195.06V191H319V195.06Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 323 181.833)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#7-6_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M319 254C319 252.895 319.895 252 321 252C322.105 252 323 252.895 323 254V268.56H319V254ZM319 268.56H323V270.4H323.06C323.579 270.4 324 270.821 324 271.34V273.12C324 274.158 323.158 275 322.12 275H320.88C319.842 275 319 274.158 319 273.12V270.4V270.4V268.56Z"
            fill="white"
          />
        </g>
        <rect x="319" y="269.25" width="3.99998" height="0.95833" fill="#ABABAB" />
        <path
          d="M319 252.94C319 252.421 319.421 252 319.94 252H322.06C322.579 252 323 252.421 323 252.94V269.25H319V252.94Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M319 274.06C319 274.579 319.421 275 319.94 275H323.06C323.579 275 324 274.579 324 274.06V271.148C324 270.629 323.579 270.208 323.06 270.208H319V274.06Z"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#7-6_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M319 165H230V286H319V165ZM308 176H241V275H308V176Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M319 165H230V286H231.219V166H317.781V286H319V165Z"
          fill="#FBFBFB"
        />
        <g filter="url(#7-6_filter3_i)">
          <rect
            x="241.47"
            y="176.47"
            width="66.0601"
            height="98.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="234.53"
          y="169.53"
          width="79.9399"
          height="111.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="232"
          y="218"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#7-6_filter4_d)">
          <rect x="232.702" y="218.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M233.286 229.795C233.798 230.457 234.6 230.884 235.502 230.884C237.048 230.884 238.302 229.63 238.302 228.084V221.444C238.302 220.455 237.79 219.587 237.016 219.088C237.382 219.562 237.6 220.155 237.6 220.8V227.44C237.6 228.986 236.347 230.24 234.8 230.24C234.242 230.24 233.722 230.077 233.286 229.795Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M235.502 218.644C233.956 218.644 232.702 219.898 232.702 221.444V227.44C232.702 227.46 232.702 227.48 232.703 227.501V222.024C232.703 220.477 233.956 219.224 235.503 219.224C237.029 219.224 238.269 220.444 238.302 221.963V221.444C238.302 219.898 237.048 218.644 235.502 218.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#7-6_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M235.5 222C234.672 222 234 222.672 234 223.5V223.714V225V243.5C234 244.328 234.672 245 235.5 245V245C236.328 245 237 244.328 237 243.5V227V223.5L237 223.286C237 222.576 236.424 222 235.714 222V222C235.677 222 235.64 222.001 235.604 222.004C235.569 222.001 235.535 222 235.5 222Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M306.5 177.997L242.836 225.5L306.5 273.003L306.5 177.997Z" stroke="white" />
        <path d="M242.695 273.5L274.5 178.574L306.305 273.5L242.695 273.5Z" stroke="white" />
        <defs>
          <filter
            id="7-6_filter0_d"
            x="317.12"
            y="174.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7-6_filter1_d"
            x="317.12"
            y="250.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7-6_filter2_d"
            x="225.3"
            y="160.3"
            width="100.279"
            height="132.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7-6_filter3_i"
            x="241"
            y="176"
            width="67.9399"
            height="101.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="7-6_filter4_d"
            x="230.822"
            y="216.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7-6_filter5_d"
            x="232.12"
            y="220.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="325.8"
            y1="193.5"
            x2="318.2"
            y2="193.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="316.202"
            y1="178.5"
            x2="323.8"
            y2="178.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="325.798"
            y1="193.5"
            x2="318.2"
            y2="193.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="325.8"
            y1="260.625"
            x2="318.2"
            y2="260.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="327.5"
            y1="272.604"
            x2="318"
            y2="272.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
