import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement52Square6Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#5-2_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M193.001 291C194.105 291 195 290.105 195 289.001C195 287.896 194.105 287.001 193.001 287.001L190.833 287.001L190.833 287.001L180 287.001L180 287L176.999 287C175.895 287 175 287.895 175 288.999C175 290.104 175.895 290.999 176.999 290.999L179.167 290.999L179.167 291L190 291L190 291L193.001 291Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 190.834 291)"
          fill="#ABABAB"
        />
        <path
          d="M194.06 291C194.579 291 195 290.579 195 290.06L195 287.941C195 287.422 194.579 287.001 194.06 287.001L190 287.001L190 291L194.06 291Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M175.94 287C175.421 287 175 287.421 175 287.94L175 290.059C175 290.578 175.421 290.999 175.94 290.999L180 290.999L180 287L175.94 287Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 189.167 291)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#5-2_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M271.001 291C272.105 291 273 290.105 273 289.001C273 287.896 272.105 287.001 271.001 287.001L268.833 287.001L268.833 287.001L258 287.001L258 287L254.999 287C253.895 287 253 287.895 253 288.999C253 290.104 253.895 290.999 254.999 290.999L257.167 290.999L257.167 291L268 291L268 291L271.001 291Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 268.834 291)"
          fill="#ABABAB"
        />
        <path
          d="M272.06 291C272.579 291 273 290.579 273 290.06L273 287.941C273 287.422 272.579 287.001 272.06 287.001L268 287.001L268 291L272.06 291Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M253.94 287C253.421 287 253 287.421 253 287.94L253 290.059C253 290.578 253.421 290.999 253.94 290.999L258 290.999L258 287L253.94 287Z"
          fill="url(#paint5-2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 267.167 291)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#5-2_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M284 128H164V287H284V128ZM273 139H175V276H273V139Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M284 128H164V287H165V129H283V287H284V128Z"
          fill="#FBFBFB"
        />
        <rect
          x="168.53"
          y="132.53"
          width="110.94"
          height="149.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#5-2_filter3_i)">
          <rect
            x="175.47"
            y="139.47"
            width="97.0601"
            height="136.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <path d="M271.295 277.5H176.705L224 142.512L271.295 277.5Z" stroke="white" />
        <rect
          x="230.528"
          y="130"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 230.528 130)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#5-2_filter4_d)">
          <rect
            x="229.884"
            y="130.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 229.884 130.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M229.767 132.695C229.42 131.542 228.351 130.702 227.085 130.702L220.445 130.702C218.898 130.702 217.645 131.956 217.645 133.502C217.645 133.537 217.645 133.572 217.647 133.607C217.993 134.76 219.063 135.6 220.329 135.6L226.969 135.6C228.515 135.6 229.769 134.347 229.769 132.8C229.769 132.765 229.768 132.73 229.767 132.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M228.409 131.035C228.266 131.012 228.118 131 227.968 131L221.328 131C219.782 131 218.528 132.254 218.528 133.8C218.528 134.867 219.125 135.795 220.003 136.268C218.666 136.056 217.644 134.898 217.644 133.502C217.644 131.956 218.898 130.702 220.444 130.702L227.084 130.702C227.564 130.702 228.015 130.823 228.409 131.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#5-2_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M243.528 133.5C243.528 132.672 242.857 132 242.028 132L241.814 132L240.528 132L222.028 132C221.2 132 220.528 132.672 220.528 133.5C220.528 134.328 221.2 135 222.028 135L238.528 135L242.028 135L242.243 135C242.953 135 243.528 134.424 243.528 133.714C243.528 133.677 243.527 133.64 243.525 133.604C243.527 133.569 243.528 133.535 243.528 133.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="5-2_filter0_d"
            x="173.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-2_filter1_d"
            x="251.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-2_filter2_d"
            x="159.3"
            y="123.3"
            width="131.279"
            height="170.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-2_filter3_i"
            x="175"
            y="139"
            width="98.9399"
            height="139.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-2_filter4_d"
            x="215.764"
            y="128.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-2_filter5_d"
            x="219.649"
            y="130.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="177.5"
            y1="293.8"
            x2="177.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="192.5"
            y1="284.202"
            x2="192.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="177.5"
            y1="293.798"
            x2="177.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="255.5"
            y1="293.8"
            x2="255.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="270.5"
            y1="284.202"
            x2="270.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5-2_linear"
            x1="255.5"
            y1="293.798"
            x2="255.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
