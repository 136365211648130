import React, { FC, useMemo, Fragment } from 'react'
import {
  StageOrderShowMoreBlock,
  StageOrderShowMoreBlockRowItem,
  StageOrderShowMoreBlockTitle,
} from './styles'
import { IDescriptionTypeWindowProps } from '../stage-calculator/interface'
import { replaceHandlebars } from '../../../utilits'

export const DescriptionTypeWindow: FC<IDescriptionTypeWindowProps> = props => {
  const { product, sizes, count, itemPrice, elementsData, filters } = props
  const elementsDataKeys = useMemo(() => {
    return Object.keys(elementsData)
  }, [elementsData])
  const productDescription = useMemo(
    () => replaceHandlebars('configuration', 'description', product, sizes, filters),
    [filters, product, sizes],
  )

  return (
    <StageOrderShowMoreBlock>
      <StageOrderShowMoreBlockRowItem noLeftGap>
        <StageOrderShowMoreBlockTitle>Окно</StageOrderShowMoreBlockTitle>
        <div dangerouslySetInnerHTML={{ __html: productDescription }} />
      </StageOrderShowMoreBlockRowItem>
      <StageOrderShowMoreBlockRowItem>{count}</StageOrderShowMoreBlockRowItem>
      <StageOrderShowMoreBlockRowItem noRightBorder>
        <b>{itemPrice?.toLocaleString('ru-RU') || '0'} ₽</b>
      </StageOrderShowMoreBlockRowItem>
      {elementsDataKeys.map(key => (
        <Fragment key={key}>
          <StageOrderShowMoreBlockRowItem noLeftGap>
            <div>
              <b>{elementsData[key]['description']}</b>
            </div>
            <div>{elementsData[key]['name'] || elementsData[key]['title']}</div>
          </StageOrderShowMoreBlockRowItem>
          <StageOrderShowMoreBlockRowItem>
            {elementsData[key]['count']}
          </StageOrderShowMoreBlockRowItem>
          <StageOrderShowMoreBlockRowItem noRightBorder>
            <b>{Math.trunc(Number(elementsData[key]['price']))} ₽</b>
          </StageOrderShowMoreBlockRowItem>
        </Fragment>
      ))}
    </StageOrderShowMoreBlock>
  )
}
