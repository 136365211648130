import React from 'react'
import { StageDescriptionBlockContentItemIcon } from 'src/react-app/views/calculator/styles'

export const Security2 = () => {
  return (
    <StageDescriptionBlockContentItemIcon title="Для квартир и коттеджей">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.6"
          d="M32.5 40H7.5C3.3575 40 0 36.6425 0 32.5V7.5C0 3.3575 3.3575 0 7.5 0H32.5C36.6425 0 40 3.3575 40 7.5V32.5C40 36.6425 36.6425 40 32.5 40Z"
          fill="#E0E3E9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 26.334C12 27.2529 12.748 28 13.667 28H26.333C27.252 28 28 27.2529 28 26.334V17.281V13.667C28 12.748 27.252 12 26.334 12H13.667C12.748 12 12 12.748 12 13.667V17.281V26.334ZM26.667 16.6141H13.334V13.667C13.334 13.4839 13.4829 13.334 13.667 13.334H26.334C26.5181 13.334 26.667 13.4829 26.667 13.667V16.6141ZM13.334 17.948V26.334C13.334 26.5181 13.4829 26.667 13.667 26.667H19.333V17.948H13.334ZM20.667 26.667H26.334C26.5181 26.667 26.667 26.5171 26.667 26.334V17.948H20.667V26.667Z"
          fill="#1C3775"
        />
        <path
          d="M25.3084 22.7154C25.8447 22.4111 26.539 22.4865 26.9967 22.9434L26.9975 22.9442L29.1188 25.0675C29.1188 25.0675 29.1188 25.0675 29.1189 25.0676C29.525 25.474 29.75 26.0182 29.75 26.5927C29.75 27.1677 29.5247 27.7111 29.1193 28.1176L29.1186 28.1183C28.7124 28.5246 28.1684 28.75 27.5937 28.75C27.019 28.75 26.475 28.5246 26.0687 28.1183L26.0686 28.1182L23.9468 25.9955L23.9467 25.9954C23.5664 25.6149 23.4496 25.0698 23.5967 24.5883C23.5852 24.6006 23.5734 24.6127 23.5614 24.6248C23.2498 24.9374 22.8397 25.0922 22.4335 25.0922C22.0273 25.0922 21.6175 24.9374 21.306 24.6251C21.3058 24.6248 21.3056 24.6246 21.3054 24.6244L19.7154 23.0335L19.714 23.0321C19.0952 22.4099 19.0946 21.4009 19.7164 20.7786C20.0626 20.4322 20.5243 20.2832 20.9707 20.32L22.6343 18.6554L22.6356 18.6541C23.1766 18.1154 24.0816 18.1148 24.6218 18.6554L24.0913 19.1855L24.6218 18.6554L25.6833 19.7175C26.2325 20.2671 26.2325 21.1588 25.6833 21.7084L24.993 22.3991L25.3084 22.7154ZM24.4767 24.5361L24.2094 24.8027L23.7721 24.3641L24.4767 24.5361Z"
          fill="#1C3775"
          stroke="#ECEEF2"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M28.7234 11.7889C29.2427 11.254 29.2301 10.3993 28.6952 9.87993C28.1602 9.36057 27.3055 9.37321 26.7862 9.90816L10.1299 27.0644C9.61057 27.5994 9.62321 28.454 10.1582 28.9734C10.6931 29.4927 11.5478 29.4801 12.0671 28.9452L28.7234 11.7889Z"
          fill="#1C3775"
          stroke="#ECEEF2"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </StageDescriptionBlockContentItemIcon>
  )
}
