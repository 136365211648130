import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement86Square8Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#8-6_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M421 177.999C421 176.895 420.105 176 419.001 176C417.896 176 417.001 176.895 417.001 177.999V180.167H417.001V191H417V194.001C417 195.105 417.895 196 418.999 196C420.104 196 420.999 195.105 420.999 194.001V191.833H421V181H421V177.999Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 421 180.167)"
          fill="#ABABAB"
        />
        <path
          d="M421 176.94C421 176.421 420.579 176 420.06 176H417.941C417.422 176 417.001 176.421 417.001 176.94V181H421V176.94Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M417 195.06C417 195.579 417.421 196 417.94 196H420.059C420.578 196 420.999 195.579 420.999 195.06V191H417V195.06Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 421 181.833)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#8-6_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 254C417 252.895 417.895 252 419 252C420.105 252 421 252.895 421 254V268.56H417V254ZM417 268.56H421V270.4H421.06C421.579 270.4 422 270.821 422 271.34V273.12C422 274.158 421.158 275 420.12 275H418.88C417.842 275 417 274.158 417 273.12V270.4V270.4V268.56Z"
            fill="white"
          />
        </g>
        <rect x="417" y="269.25" width="3.99998" height="0.95833" fill="#ABABAB" />
        <path
          d="M417 252.94C417 252.421 417.421 252 417.94 252H420.06C420.579 252 421 252.421 421 252.94V269.25H417V252.94Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M417 274.06C417 274.579 417.421 275 417.94 275H421.06C421.579 275 422 274.579 422 274.06V271.148C422 270.629 421.579 270.208 421.06 270.208H417V274.06Z"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#8-6_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 165H328V286H417V165ZM406 176H339V275H406V176Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 165H328V286H329.219V166H415.781V286H417V165Z"
          fill="#FBFBFB"
        />
        <g filter="url(#8-6_filter3_i)">
          <rect
            x="339.47"
            y="176.47"
            width="66.0601"
            height="98.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="332.53"
          y="169.53"
          width="79.9399"
          height="111.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="330"
          y="218"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#8-6_filter4_d)">
          <rect x="330.702" y="218.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M331.286 229.795C331.798 230.457 332.6 230.884 333.502 230.884C335.049 230.884 336.302 229.63 336.302 228.084V221.444C336.302 220.455 335.79 219.587 335.017 219.088C335.383 219.562 335.601 220.155 335.601 220.8V227.44C335.601 228.986 334.347 230.24 332.801 230.24C332.243 230.24 331.723 230.077 331.286 229.795Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M333.502 218.644C331.955 218.644 330.702 219.898 330.702 221.444V227.44C330.702 227.46 330.702 227.48 330.702 227.501V222.024C330.702 220.477 331.956 219.224 333.502 219.224C335.028 219.224 336.269 220.444 336.302 221.963V221.444C336.302 219.898 335.048 218.644 333.502 218.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#8-6_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M333.5 222C332.672 222 332 222.672 332 223.5V223.714V225V243.5C332 244.328 332.672 245 333.5 245V245C334.328 245 335 244.328 335 243.5V227V223.5L335 223.286C335 222.576 334.424 222 333.714 222V222C333.677 222 333.64 222.001 333.604 222.004C333.569 222.001 333.535 222 333.5 222Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M404.5 177.997L340.836 225.5L404.5 273.003L404.5 177.997Z" stroke="white" />
        <path d="M340.695 273.5L372.5 178.574L404.305 273.5L340.695 273.5Z" stroke="white" />
        <defs>
          <filter
            id="8-6_filter0_d"
            x="415.12"
            y="174.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8-6_filter1_d"
            x="415.12"
            y="250.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8-6_filter2_d"
            x="323.3"
            y="160.3"
            width="100.279"
            height="132.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8-6_filter3_i"
            x="339"
            y="176"
            width="67.9399"
            height="101.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="8-6_filter4_d"
            x="328.822"
            y="216.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8-6_filter5_d"
            x="330.12"
            y="220.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="423.8"
            y1="193.5"
            x2="416.2"
            y2="193.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="414.202"
            y1="178.5"
            x2="421.8"
            y2="178.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="423.798"
            y1="193.5"
            x2="416.2"
            y2="193.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="423.8"
            y1="260.625"
            x2="416.2"
            y2="260.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="425.5"
            y1="272.604"
            x2="416"
            y2="272.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
