import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement44Square54Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-4_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M291.001 34C292.105 34 293 33.1048 293 32.0005C293 30.8962 292.105 30.001 291.001 30.001L288.833 30.001L288.833 30.001L278 30.001L278 30L274.999 30C273.895 30 273 30.8952 273 31.9995C273 33.1038 273.895 33.999 274.999 33.999L277.167 33.999L277.167 34L288 34L288 34L291.001 34Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 288.833 34)"
          fill="#ABABAB"
        />
        <path
          d="M292.06 34C292.579 34 293 33.5792 293 33.0601L293 30.941C293 30.4219 292.579 30.001 292.06 30.001L288 30.001L288 34L292.06 34Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M273.94 30C273.421 30 273 30.4208 273 30.9399L273 33.059C273 33.5781 273.421 33.999 273.94 33.999L278 33.999L278 30L273.94 30Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 287.167 34)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#4-4_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M331.001 34C332.105 34 333 33.1048 333 32.0005C333 30.8962 332.105 30.001 331.001 30.001L328.833 30.001L328.833 30.001L318 30.001L318 30L314.999 30C313.895 30 313 30.8952 313 31.9995C313 33.1038 313.895 33.999 314.999 33.999L317.167 33.999L317.167 34L328 34L328 34L331.001 34Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 328.833 34)"
          fill="#ABABAB"
        />
        <path
          d="M332.06 34C332.579 34 333 33.5792 333 33.0601L333 30.941C333 30.4219 332.579 30.001 332.06 30.001L328 30.001L328 34L332.06 34Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M313.94 30C313.421 30 313 30.4208 313 30.9399L313 33.059C313 33.5781 313.421 33.999 313.94 33.999L318 33.999L318 30L313.94 30Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 327.167 34)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#4-4_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M337 34H269V287H337V34ZM327 44H279V277H327V44Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M337 34H269V287H269.932V35H336.068V287H337V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-4_filter3_i)">
          <rect
            x="279.47"
            y="44.47"
            width="47.0601"
            height="232.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="273.53"
          y="38.53"
          width="58.9399"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M280.552 45.5L303 270.953L325.448 45.5L280.552 45.5Z" stroke="white" />
        <rect
          x="310"
          y="278"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 310 278)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="309.356"
          y="278.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 309.356 278.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M309.238 280.695C308.892 279.542 307.822 278.702 306.556 278.702L299.916 278.702C298.37 278.702 297.116 279.955 297.116 281.502C297.116 281.537 297.117 281.572 297.118 281.607C297.464 282.76 298.534 283.6 299.8 283.6L306.44 283.6C307.986 283.6 309.24 282.346 309.24 280.8C309.24 280.765 309.239 280.73 309.238 280.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M307.881 279.034C307.737 279.012 307.59 279 307.44 279L300.8 279C299.254 279 298 280.253 298 281.8C298 282.867 298.597 283.795 299.475 284.267C298.138 284.056 297.116 282.898 297.116 281.502C297.116 279.955 298.37 278.702 299.916 278.702L306.556 278.702C307.035 278.702 307.486 278.822 307.881 279.034Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-4_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M306 281.5C306 280.672 305.328 280 304.5 280L304.286 280L303 280L284.5 280C283.672 280 283 280.672 283 281.5V281.5C283 282.328 283.672 283 284.5 283L301 283L304.5 283L304.714 283C305.424 283 306 282.424 306 281.714V281.714C306 281.677 305.999 281.64 305.996 281.604C305.999 281.569 306 281.535 306 281.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="4-4_filter0_d"
            x="271.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-4_filter1_d"
            x="311.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-4_filter2_d"
            x="264.3"
            y="29.3003"
            width="79.2793"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-4_filter3_i"
            x="279"
            y="44"
            width="48.9399"
            height="235.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-4_filter4_d"
            x="281.28"
            y="278.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="275.5"
            y1="36.8"
            x2="275.5"
            y2="29.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="290.5"
            y1="27.2018"
            x2="290.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="275.5"
            y1="36.7982"
            x2="275.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="315.5"
            y1="36.8"
            x2="315.5"
            y2="29.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="330.5"
            y1="27.2018"
            x2="330.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="315.5"
            y1="36.7982"
            x2="315.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
