export interface ICoord {
  x: number | string
  y: number | string
}

export enum TooltipDirection {
  TOP = 'top',
  RIGHT = 'RIGHT',
  BOTTOM = 'bottom',
  LEFT = 'left',
}

export interface ITooltipProps {
  text: string
  importantShow?: boolean
  coord?: ICoord
  direction?: TooltipDirection
  children?: any
}
