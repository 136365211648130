import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement13Square82Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#1filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M99.0005 33C100.105 33 101 32.1048 101 31.0005C101 29.8962 100.105 29.001 99.0005 29.001L96.8335 29.001L96.8335 29.001L86 29.001L86 29L82.9995 29C81.8952 29 81 29.8952 81 30.9995C81 32.1038 81.8952 32.999 82.9995 32.999L85.1668 32.999L85.1668 33L96 33L96 33L99.0005 33Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 96.8335 33)"
          fill="#ABABAB"
        />
        <path
          d="M100.06 33C100.579 33 101 32.5792 101 32.0601L101 29.941C101 29.4219 100.579 29.001 100.06 29.001L96 29.001L96 33L100.06 33Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M81.9399 29C81.4208 29 81 29.4208 81 29.9399L81 32.059C81 32.5781 81.4208 32.999 81.9399 32.999L86 32.999L86 29L81.9399 29Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 95.1666 33)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#1filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.0005 33C55.1048 33 56 32.1048 56 31.0005C56 29.8962 55.1048 29.001 54.0005 29.001L51.8335 29.001L51.8335 29.001L41 29.001L41 29L37.9995 29C36.8952 29 36 29.8952 36 30.9995C36 32.1038 36.8952 32.999 37.9995 32.999L40.1668 32.999L40.1668 33L51 33L51 33L54.0005 33Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 51.8335 33)"
          fill="#ABABAB"
        />
        <path
          d="M55.0601 33C55.5792 33 56 32.5792 56 32.0601L56 29.941C56 29.4219 55.5792 29.001 55.0601 29.001L51 29.001L51 33L55.0601 33Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M36.9399 29C36.4208 29 36 29.4208 36 29.9399L36 32.059C36 32.5781 36.4208 32.999 36.9399 32.999L41 32.999L41 29L36.9399 29Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 50.1666 33)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#1filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M105 33H32V88H105V33ZM95 43H42V78H95V43Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105 33H32V88H33V34H104V88H105V33Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1filter3_i)">
          <rect
            x="42.47"
            y="43.47"
            width="52.0601"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="37.53"
          width="63.9399"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M44.0182 44.5L68.5 76.1823L92.9818 44.5L44.0182 44.5Z" stroke="white" />
        <rect
          x="75"
          y="79"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 75 79)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="74.356"
          y="79.7019"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 74.356 79.7019)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74.2379 81.6951C73.8915 80.542 72.8218 79.7019 71.5559 79.7019L64.916 79.7019C63.3696 79.7019 62.116 80.9555 62.116 82.5019C62.116 82.5371 62.1167 82.5721 62.118 82.6069C62.4643 83.76 63.534 84.6001 64.8 84.6001L71.4399 84.6001C72.9863 84.6001 74.2399 83.3465 74.2399 81.8001C74.2399 81.7649 74.2392 81.7299 74.2379 81.6951Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72.881 80.0346C72.7373 80.0119 72.59 80.0001 72.4399 80.0001L65.8 80.0001C64.2536 80.0001 63 81.2537 63 82.8001C63 83.8672 63.5969 84.7948 64.475 85.2674C63.1382 85.0558 62.1161 83.8982 62.1161 82.5019C62.1161 80.9555 63.3697 79.7019 64.9161 79.7019L71.556 79.7019C72.0353 79.7019 72.4865 79.8224 72.881 80.0346Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M71 82.5C71 81.6716 70.3284 81 69.5 81L69.2857 81L68 81L49.5 81C48.6716 81 48 81.6716 48 82.5V82.5C48 83.3284 48.6716 84 49.5 84L66 84L69.5 84L69.7143 84C70.4244 84 71 83.4244 71 82.7143V82.7143C71 82.6771 70.9988 82.6401 70.9965 82.6035C70.9988 82.5693 71 82.5348 71 82.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="1filter0_d"
            x="79.1201"
            y="27.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1filter1_d"
            x="34.1201"
            y="27.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1filter2_d"
            x="27.3003"
            y="28.3003"
            width="84.2793"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1filter3_i"
            x="42"
            y="43"
            width="53.9399"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="1filter4_d"
            x="46.28"
            y="79.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="83.5"
            y1="35.8"
            x2="83.5"
            y2="28.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="98.5"
            y1="26.2018"
            x2="98.5"
            y2="33.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="83.5"
            y1="35.7982"
            x2="83.5"
            y2="28.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="38.5"
            y1="35.8"
            x2="38.5"
            y2="28.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="53.5"
            y1="26.2018"
            x2="53.5"
            y2="33.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="38.5"
            y1="35.7982"
            x2="38.5"
            y2="28.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
