import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement13Square41Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#1-3-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.0005 34C55.1048 34 56 33.1048 56 32.0005C56 30.8962 55.1048 30.001 54.0005 30.001L51.8335 30.001L51.8335 30.001L41 30.001L41 30L37.9995 30C36.8952 30 36 30.8952 36 31.9995C36 33.1038 36.8952 33.999 37.9995 33.999L40.1668 33.999L40.1668 34L51 34L51 34L54.0005 34Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 51.8335 34)"
          fill="#ABABAB"
        />
        <path
          d="M55.0601 34C55.5792 34 56 33.5792 56 33.0601L56 30.941C56 30.4219 55.5792 30.001 55.0601 30.001L51 30.001L51 34L55.0601 34Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M36.9399 30C36.4208 30 36 30.4208 36 30.9399L36 33.059C36 33.5781 36.4208 33.999 36.9399 33.999L41 33.999L41 30L36.9399 30Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 50.1666 34)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#1-3-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M115.001 34C116.105 34 117 33.1048 117 32.0005C117 30.8962 116.105 30.001 115.001 30.001L112.833 30.001L112.833 30.001L102 30.001L102 30L98.9995 30C97.8952 30 97 30.8952 97 31.9995C97 33.1038 97.8952 33.999 98.9995 33.999L101.167 33.999L101.167 34L112 34L112 34L115.001 34Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 112.833 34)"
          fill="#ABABAB"
        />
        <path
          d="M116.06 34C116.579 34 117 33.5792 117 33.0601L117 30.941C117 30.4219 116.579 30.001 116.06 30.001L112 30.001L112 34L116.06 34Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M97.9399 30C97.4208 30 97 30.4208 97 30.9399L97 33.059C97 33.5781 97.4208 33.999 97.9399 33.999L102 33.999L102 30L97.9399 30Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 111.167 34)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#1-3-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M121 34H32V287H121V34ZM110 45H43V276H110V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M121 34H32V287H33.2192V35H119.781V287H121V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-3-filter3_i)">
          <rect
            x="43.47"
            y="45.47"
            width="66.0601"
            height="230.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="38.53"
          width="79.9399"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="83"
          y="278"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 83 278)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="82.356"
          y="278.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 82.356 278.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.238 280.695C81.8916 279.542 80.8219 278.702 79.556 278.702L72.9161 278.702C71.3697 278.702 70.1161 279.955 70.1161 281.502C70.1161 281.537 70.1167 281.572 70.118 281.607C70.4644 282.76 71.5341 283.6 72.8 283.6L79.4399 283.6C80.9863 283.6 82.2399 282.346 82.2399 280.8C82.2399 280.765 82.2393 280.73 82.238 280.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M80.881 279.035C80.7373 279.012 80.59 279 80.4399 279L73.8 279C72.2536 279 71 280.254 71 281.8C71 282.867 71.5969 283.795 72.475 284.267C71.1382 284.056 70.1161 282.898 70.1161 281.502C70.1161 279.955 71.3697 278.702 72.9161 278.702L79.556 278.702C80.0353 278.702 80.4865 278.822 80.881 279.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-3-filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M79 281.5C79 280.672 78.3284 280 77.5 280L77.2857 280L76 280L57.5 280C56.6716 280 56 280.672 56 281.5V281.5C56 282.328 56.6716 283 57.5 283L74 283L77.5 283L77.7143 283C78.4244 283 79 282.424 79 281.714V281.714C79 281.677 78.9988 281.64 78.9965 281.604C78.9988 281.569 79 281.535 79 281.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M108.424 46.5L76.5 271.442L44.576 46.5L108.424 46.5Z" stroke="white" />
        <defs>
          <filter
            id="1-3-filter0_d"
            x="34.1201"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-3-filter1_d"
            x="95.1201"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-3-filter2_d"
            x="27.3003"
            y="29.3003"
            width="100.279"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-3-filter3_i"
            x="43"
            y="45"
            width="67.9399"
            height="233.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="1-3-filter4_d"
            x="54.28"
            y="278.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="38.5"
            y1="36.8"
            x2="38.5"
            y2="29.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="53.5"
            y1="27.2018"
            x2="53.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="38.5"
            y1="36.7982"
            x2="38.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="99.5"
            y1="36.8"
            x2="99.5"
            y2="29.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="114.5"
            y1="27.2018"
            x2="114.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="99.5"
            y1="36.7982"
            x2="99.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
