import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement32Square4Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="315"
        height="333"
        viewBox="0 0 315 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#26filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.0005 291C57.1048 291 58 290.105 58 289.001C58 287.896 57.1048 287.001 56.0005 287.001L53.8335 287.001L53.8335 287.001L43 287.001L43 287L39.9995 287C38.8952 287 38 287.895 38 288.999C38 290.104 38.8952 290.999 39.9995 290.999L42.1668 290.999L42.1668 291L53 291L53 291L56.0005 291Z"
            fill="url(#26paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 53.8335 291)"
          fill="#ABABAB"
        />
        <path
          d="M57.0601 291C57.5792 291 58 290.579 58 290.06L58 287.941C58 287.422 57.5792 287.001 57.0601 287.001L53 287.001L53 291L57.0601 291Z"
          fill="url(#26paint1_linear)"
        />
        <path
          d="M38.9399 287C38.4208 287 38 287.421 38 287.94L38 290.059C38 290.578 38.4208 290.999 38.9399 290.999L43 290.999L43 287L38.9399 287Z"
          fill="url(#26paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 52.1665 291)"
          fill="url(#26paint3_linear)"
        />
        <g filter="url(#26filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M143.001 291C144.105 291 145 290.105 145 289.001C145 287.896 144.105 287.001 143.001 287.001L140.833 287.001L140.833 287.001L130 287.001L130 287L126.999 287C125.895 287 125 287.895 125 288.999C125 290.104 125.895 290.999 126.999 290.999L129.167 290.999L129.167 291L140 291L140 291L143.001 291Z"
            fill="url(#26paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 140.833 291)"
          fill="#ABABAB"
        />
        <path
          d="M144.06 291C144.579 291 145 290.579 145 290.06L145 287.941C145 287.422 144.579 287.001 144.06 287.001L140 287.001L140 291L144.06 291Z"
          fill="url(#26paint5_linear)"
        />
        <path
          d="M125.94 287C125.421 287 125 287.421 125 287.94L125 290.059C125 290.578 125.421 290.999 125.94 290.999L130 290.999L130 287L125.94 287Z"
          fill="url(#26paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 139.167 291)"
          fill="url(#26paint7_linear)"
        />
        <g filter="url(#26filter2_i)">
          <rect
            x="45.47"
            y="127.47"
            width="92.0601"
            height="148.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <g filter="url(#26filter3_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M149 116H34V287H149V116ZM138 127H45V276H138V127Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M149 116H34V287H35V117H148V287H149V116Z"
          fill="#FBFBFB"
        />
        <rect
          x="38.53"
          y="122.53"
          width="105.94"
          height="159.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M136.322 274.5L46.6782 274.5L91.5 129.691L136.322 274.5Z" stroke="white" />
        <rect
          x="98.5283"
          y="118"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 98.5283 118)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#26filter4_d)">
          <rect
            x="97.8843"
            y="118.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 97.8843 118.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M97.7664 120.695C97.42 119.542 96.3503 118.702 95.0844 118.702L88.4445 118.702C86.8981 118.702 85.6445 119.956 85.6445 121.502C85.6445 121.537 85.6451 121.572 85.6464 121.607C85.9928 122.76 87.0625 123.6 88.3284 123.6L94.9683 123.6C96.5147 123.6 97.7683 122.346 97.7683 120.8C97.7683 120.765 97.7677 120.73 97.7664 120.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M96.4092 119.035C96.2655 119.012 96.1182 119 95.9681 119L89.3282 119C87.7818 119 86.5282 120.254 86.5282 121.8C86.5282 122.867 87.1251 123.795 88.0032 124.267C86.6664 124.056 85.6443 122.898 85.6443 121.502C85.6443 119.956 86.8979 118.702 88.4443 118.702L95.0842 118.702C95.5635 118.702 96.0147 118.822 96.4092 119.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#26filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M111.528 121.5C111.528 120.672 110.857 120 110.028 120L109.814 120L108.528 120L90.0283 120C89.1999 120 88.5283 120.672 88.5283 121.5C88.5283 122.328 89.1999 123 90.0283 123L106.528 123L110.028 123L110.243 123C110.953 123 111.528 122.424 111.528 121.714C111.528 121.677 111.527 121.64 111.525 121.604C111.527 121.569 111.528 121.535 111.528 121.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="26filter0_d"
            x="36.1201"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="26filter1_d"
            x="123.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="26filter2_i"
            x="45"
            y="127"
            width="93.9399"
            height="151.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="26filter3_d"
            x="29.3003"
            y="111.3"
            width="126.279"
            height="182.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="26filter4_d"
            x="83.7644"
            y="116.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="26filter5_d"
            x="87.6485"
            y="118.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="26paint0_linear"
            x1="40.5"
            y1="293.8"
            x2="40.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="26paint1_linear"
            x1="55.5"
            y1="284.202"
            x2="55.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="26paint2_linear"
            x1="40.5"
            y1="293.798"
            x2="40.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="26paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="26paint4_linear"
            x1="127.5"
            y1="293.8"
            x2="127.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="26paint5_linear"
            x1="142.5"
            y1="284.202"
            x2="142.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="26paint6_linear"
            x1="127.5"
            y1="293.798"
            x2="127.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="26paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
