import styled, { css } from 'styled-components'

export const TextareaWrapper = styled.div`
  display: inline-block;

  border-radius: 10px;

  position: relative;
  width: 100%;
`

export const TextareaStyle = styled.textarea<{ isError?: boolean; disabled?: boolean }>`
  background-color: ${({ disabled }) => (disabled ? '#e1e1e1' : '#ffffff')};
  background-image: none;
  border: 1px solid ${({ isError }) => (isError ? '#ff453a' : '#e1e1e1')};
  border-radius: 10px;

  box-sizing: border-box;

  margin: 0;
  padding: 15px 23px;

  font-variant: tabular-nums;
  font-feature-settings: 'tnum';
  color: #333333;
  font-size: 16px;
  line-height: 1.5;

  list-style: none;

  position: relative;

  display: inline-block;

  min-width: 0;
  max-width: 100%;
  width: 100%;

  transition: all 0.3s;
`

export const TextareaStatus = styled.span`
  position: absolute;

  top: calc(50% + 1px);
  right: 10px;

  font-size: 11px;
  line-height: 1.2;

  color: #ff453a;

  width: 21px;
  height: 20px;

  transform: translateY(-50%);

  svg {
    width: 14px;
    height: 14px;
  }
`

export const TextareaReq= styled.span`
  position: absolute;
  color: red;
  padding: 0px 0px 0px 8px;
  z-index: 4;
  font-size: 32px;
`
