import styled, { css } from 'styled-components'

import { ICoord } from '../tooltip'

export const InfoWrapper = styled.div`
  position: relative;

  height: 16px;
`

export const InfoTooltipBlock = styled.div<{ show; coord?: ICoord }>`
  background-color: #333333;
  border-radius: 10px;
  box-shadow: 0 8px 40px rgb(0 0 0 / 10%);

  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  color: #ffffff;

  ${({ coord }) =>
    coord
      ? css`
          bottom: ${typeof coord['y'] === 'number' ? `-${coord['y']}px` : coord['y']};
          left: ${typeof coord['x'] === 'number' ? `${coord['x']}px` : coord['x']};
        `
      : css`
          bottom: 16px;
          left: 0;
        `}

  min-width: 160px;

  padding: 10px;

  position: absolute;

  opacity: 0;

  transform: translateX(-45%) translateY(0);
  transition: opacity 0.3s, transform 0.3s;

  pointer-events: none;

  z-index: 10;

  &:before,
  &:after {
    content: '';

    width: 0;
    height: 0;

    position: absolute;
  }
  &:before {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;

    bottom: -8px;
    left: 50%;

    margin-left: -10px;
  }
  &:after {
    border-top-color: #333333;
    border-top-style: solid;
    border-top-width: 6px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;

    bottom: -6px;
    left: 50%;

    margin-left: -8px;
  }

  ${({ show }) =>
    show &&
    css`
      opacity: 1;

      pointer-events: all;

      transform: translateX(-45%) translateY(-10px);
    `}
`
