import React, { FC, useCallback, useState, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { IViewPreferences1Props } from '../../../interface'
import {
  SelectBlock,
  StageTypeWrapper,
  VerticalList,
  VerticalListItem,
} from '../../../styles'
import {ControlTitle, SimpleSlider} from '../../../../../components'
import { QuizBottomBlock } from '../../components'
import { sendCt } from 'src/lib/utils/ct'
import { useModal } from 'react-modal-hook'
import { ButtonViews, InputTypes } from '../../../interface'
import {
ModalWrapper,
ModalBody,
CloseButton,
ModalContent,
ModalTitle,
ModalDescription,
ModalRow,
ModalCol,
} from '../../../styles'
import { CloseIcon } from '../../../../../assets/icons'
import {fixBody, validatePhone} from '../../../../../utilits'
import { Button, ControlGroup, Input, PhoneInput, Textarea, FileInput } from '../../../../../components'
import { ButtonWrapper, RightConfirmWrapper } from '../../../registr/styles'
import { useRegister, useSendOrder } from '../../../../../hooks'
import { saveUserDataFromLocalStore, getUserDataFromLocalStore } from 'src/react-app/utilits/get-user-data'
import { validateEmail } from '../../../../../../react-app/utilits'

declare global {
  interface Window {
    onCalculatorModal: any
  }
}

export const ViewPreferences1: FC<IViewPreferences1Props> = props => {
  const {
    onChangeType,
    onPrevStep,
    onChange,
    currentStep,
    steps,
    selected,
    selectedAnswers,
    currentGlassUnit,
    type,
    modelTitle,
    price,
    calculateInProgress,
    onFinish,
    products
  } = props


  const data2 = props.data

  const checkDisabled = useCallback(
    preference => {
      let result = false

      if (preference['access-list'] && Array.isArray(preference['access-list'])) {
        const model = selected[`${type}-model`]

        result = !preference['access-list'].some(item => item === model)
      }

      return result
    },
    [selected, type],
  )

  const [data, setData] = useState<Record<string, any>>({
    'first-name': '',
    phone: '',
    'right-confirm': true,
  })
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const { createOrder } = useSendOrder()
  const { isAuthorized, onFetchProfile } = useRegister()
  const [orderData, setOrderData] = useState<Record<string, any>>({})
  const { toggleFixBody } = fixBody()

  useEffect(() => {
    const getProfileData = async () => {
      //if (isAuthorized) {
        const profileData = await onFetchProfile()
        const localData = getUserDataFromLocalStore()
        const name = profileData?.['profile-data']?.['name'] || localData?.['name'] || ''
        const phone = profileData?.['profile-data']?.['phone'] || localData?.['phone'] || ''

        if (name || phone) {
          setData(() => ({
            'first-name': name,
            //phone: phone,
            'right-confirm': true,
          }))
        }
      //}
    }

    getProfileData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized])

  const handleCloseModal = useCallback(
    hideModal => () => {
      toggleFixBody()
      hideModal()
      setShowSuccessMessage(false)
    },
    [toggleFixBody],
  )

  const handleCall = useCallback(async () => {
    // @ts-ignore
    window?.ym?.(82697449,'reachGoal','size_selection_call_measurer')

    /*const { newProducts, transitionProducts } = prepareOrderProducts(products, [price], type)
    console.log('transitionProducts', transitionProducts)
    console.log('data', data)

    const glassUnit =
      products.length > 0 && products[0]['product'] && products[0]['product']['glass-unit-2']
        ? products[0]['product']['glass-unit-2']['description']
        : ''
    const print = [
      {
        ...transitionProducts[0],
        name: `${transitionProducts[0]['name']} ${glassUnit}`,
      },
    ]
    const totalCost = price ? price['total'] : 0*/

    const orderData = {
      stage: 'new',
      source: 'Вызов замерщика',
      // @ts-ignore
// eslint-disable-next-line
      location: window.location.href,
      /*'specification-products': {
        'transition-products': print,
        print,
        source: {
          type,
          products: newProducts,
          totalCost,
        },
      },*/
      quiz: selectedAnswers
    }

    saveUserDataFromLocalStore({ 
      phone: data['phone'],
      name: data['first-name'],
    })

    /*console.log('selectedAnswers', selectedAnswers)
    console.log('props', props)
    return;*/

    const caseOrder = await createOrder(orderData, {
      phone: data['phone'],
      name: data['first-name'],
      email: data['email'],
      description: data['description'],
      time: data['call-time'],
      files: data['files'],
    })

    sendCt({ name: data['first-name'], phone: data['phone'] })
    window?.roistatGoal?.reach?.({
      leadName: `Форма в калькуляторе "Замер"`,
      text: `Ссылка на страницу: ${window.location.href}`,
      name: data['first-name'],
      phone: data['phone'],
    });
    /*
    const targetId = caseOrder?.['id']
    if (typeof targetId === 'number') {
      await fetchAPI('web-hooks/set-case-to-dealer', {method: 'POST', body: JSON.stringify({ 'target-id': targetId })} )
    }
    */
    if (caseOrder) {
      setOrderData(caseOrder)
    }
    setShowSuccessMessage(true)
  }, [createOrder, data, price, products, type])


  const handleChange = useCallback((value, key) => {
    setData(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }, [])

  const handleConfirm = useCallback(() => {
    setData(prevState => {
      return {
        ...prevState,
        'right-confirm': !prevState['right-confirm'],
      }
    })
  }, [])

  const isDisabledButton = useMemo(() => {
    return !data['right-confirm'] ||
      !data['phone'] ||
      !data['first-name'] ||
      !data['email'] ||
      !data['description'] ||
      validateEmail(data['email']) !== '' ||
      validatePhone(data['phone']) !== ''
  }, [data])


  const [showModal, hideModal] = useModal(
    ({ in: open }) => {
      return (
        <ModalWrapper show={open}>
          <ModalBody width={800}>
            <CloseButton onClick={handleCloseModal(hideModal)}>
              <CloseIcon />
            </CloseButton>
            <ModalTitle>Подбор остекления</ModalTitle>
            <ModalContent>
              {showSuccessMessage && (
                <ModalDescription>
                  Благодарим за обращение №{orderData['id']}. В течение 30 минут менеджер заказа свяжется с Вами для
                  назначения времени встречи
                </ModalDescription>
              )}
              {!showSuccessMessage && (
                <>
                  <ModalRow columnCount={2}>
                    <ModalCol>
                      <ControlGroup>
                        <ControlTitle required={true}>
                          Имя
                        </ControlTitle>
                        <Input
                          placeholder=""
                          name="first-name"
                          type={InputTypes.text}
                          onChange={handleChange}
                          value={data['first-name']}
                          req={true}
                        />
                      </ControlGroup>
                    </ModalCol>
                    <ModalCol>
                      <ControlGroup>
                        <ControlTitle required={true}>
                          E-mail
                        </ControlTitle>
                        <Input
                          placeholder=""
                          name="email"
                          type={InputTypes.email}
                          onChange={handleChange}
                          value={data['email']}
                          req={true}
                        />
                      </ControlGroup>
                    </ModalCol>
                  </ModalRow>
                  <ControlGroup>
                    <ControlTitle required={true}>
                      Расскажите о задаче
                    </ControlTitle>
                    <Textarea
                      placeholder=""
                      name="description"
                      onChange={handleChange}
                      value={data['description']}
                    />
                  </ControlGroup>
                  <ControlGroup>
                    <ControlTitle>
                      Прикрепите файлы
                    </ControlTitle>
                    <FileInput
                      onChange={handleChange}
                      name="files"
                      multiple={true}
                    />
                  </ControlGroup>
                  <ModalRow columnCount={2}>
                    <ModalCol>
                      <ControlGroup>
                        <ControlTitle required={true}>
                          Контактный телефон
                        </ControlTitle>
                        <PhoneInput
                          id="phone"
                          name="phone"
                          onChange={handleChange}
                          value={data['phone']}
                        />
                      </ControlGroup>
                    </ModalCol>
                    <ModalCol>
                      <ControlGroup>
                        <ControlTitle>
                          Удобное время для звонка
                        </ControlTitle>
                        <Input
                          placeholder=""
                          name="call-time"
                          type={InputTypes.text}
                          onChange={handleChange}
                          value={data['call-time']}
                        />
                      </ControlGroup>
                    </ModalCol>
                  </ModalRow>
                  <RightConfirmWrapper>
                    <Input
                      id="right-confirm"
                      type={InputTypes.checkbox}
                      name="right-confirm"
                      onChange={handleConfirm}
                    />
                    <label htmlFor="right-confirm">
                      Я согласен с правилами обработки{' '}
                      <Link to="/privacy-policy/" target="_blank">
                        персональных данных
                      </Link>
                    </label>
                  </RightConfirmWrapper>
                  <RightConfirmWrapper>
                    <label htmlFor="right-confirm">
                      <span>*</span> - обязательные поля для заполнения
                    </label>
                  </RightConfirmWrapper>
                  <ButtonWrapper>
                    <Button
                      typeView={ButtonViews.ORANGE}
                      onClick={handleCall}
                      disabled={isDisabledButton}
                    >
                      Оформить заявку
                    </Button>
                  </ButtonWrapper>
                </>
              )}
            </ModalContent>
          </ModalBody>
        </ModalWrapper>
      )
    },
    [data, showSuccessMessage, isDisabledButton],
  )

  const handleOnlineConsult = useCallback(() => {
    showModal()
    toggleFixBody(true)
  }, [showModal, toggleFixBody])

  window.onCalculatorModal = showModal

  return (
    <StageTypeWrapper>
      <SelectBlock style={{ marginTop: currentStep === steps ? 100 : 0 }}>        
        { /*<SubDescription></SubDescription>
        <SubTitle></SubTitle>*/ }
        <VerticalList>
          {data2.map(preference => (
            <VerticalListItem key={preference['code']} disabled={checkDisabled(preference)}>
              <SimpleSlider
                data={preference['enum']}
                title={preference['title']}
                description={preference['description']}
                name={preference['code']}
                onSelect={onChange}
                selected={selected[preference['code']]}
                disabled={checkDisabled(preference)}
              />
            </VerticalListItem>
          ))}
        </VerticalList>
        <QuizBottomBlock
          currentStep={currentStep}
          steps={steps}
          onPrevStep={onPrevStep}
          onChangeType={onChangeType}
          handleOnlineConsult={handleOnlineConsult}
        />
      </SelectBlock>
      {/*<PreviewBlock
        currentStep={currentStep}
        glassUnit={currentGlassUnit}
        title={modelTitle}
        type={type}
        price={price}
        calculateInProgress={calculateInProgress}
        onFinish={onFinish}
        handleOnlineConsult={handleOnlineConsult}
      />*/}
    </StageTypeWrapper>
  )
}
