import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement2Door1RCasement1 = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="451"
        height="451"
        viewBox="0 0 451 451"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#door-1-casement-1-2-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M279 382C279 380.895 278.105 380 277 380C275.895 380 275 380.895 275 382V396.56H279V382ZM279 396.56H275V398.4H274.94C274.421 398.4 274 398.821 274 399.34V401.12C274 402.158 274.842 403 275.88 403H277.12C278.158 403 279 402.158 279 401.12V398.4V398.4V396.56Z"
            fill="white"
          />
        </g>
        <rect
          width="3.99998"
          height="0.95833"
          transform="matrix(-1 0 0 1 279 397.25)"
          fill="#ABABAB"
        />
        <path
          d="M279 380.94C279 380.421 278.579 380 278.06 380H275.94C275.421 380 275 380.421 275 380.94V397.25H279V380.94Z"
          fill="url(#door-1-casement-1-2-paint0_linear)"
        />
        <path
          d="M279 402.06C279 402.579 278.579 403 278.06 403H274.94C274.421 403 274 402.579 274 402.06V399.148C274 398.629 274.421 398.208 274.94 398.208H279V402.06Z"
          fill="url(#door-1-casement-1-2-paint1_linear)"
        />
        <g filter="url(#door-1-casement-1-2-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M278.954 50.9765C278.954 49.8849 278.069 49 276.977 49C275.886 49 275.001 49.8849 275.001 50.9765V53.1665H275.001V64H275V67.0235C275 68.1151 275.885 69 276.976 69C278.068 69 278.953 68.1151 278.953 67.0235V64.8332H278.954V54H278.954V50.9765Z"
            fill="url(#door-1-casement-1-2-paint2_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-1 0 0 1 278.954 53.1665)"
          fill="#ABABAB"
        />
        <path
          d="M278.954 49.9399C278.954 49.4208 278.533 49 278.014 49H275.941C275.422 49 275.001 49.4208 275.001 49.9399V54H278.954V49.9399Z"
          fill="url(#door-1-casement-1-2-paint3_linear)"
        />
        <path
          d="M275 68.0601C275 68.5792 275.421 69 275.94 69H278.013C278.532 69 278.953 68.5792 278.953 68.0601V64H275V68.0601Z"
          fill="url(#door-1-casement-1-2-paint4_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-1 0 0 1 278.954 54.8334)"
          fill="url(#door-1-casement-1-2-paint5_linear)"
        />
        <g filter="url(#door-1-casement-1-2-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M279 34H419V419H279V34ZM295 50H403V293H295V50ZM404 312H295V403H404V312Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M419 34H279V419H280V35H418V419H419V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="283.53"
          y="38.53"
          width="130.94"
          height="375.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="295.47"
          y="312.47"
          width="108.06"
          height="90.0601"
          fill="#E8EAEE"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#door-1-casement-1-2-filter3_i)">
          <rect
            x="295.469"
            y="50.47"
            width="107.06"
            height="242.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          width="6.91954"
          height="13.5283"
          rx="3.45977"
          transform="matrix(-1 0 0 1 413.92 209)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#door-1-casement-1-2-filter4_d)">
          <rect
            width="5.53563"
            height="12.2399"
            rx="2.76782"
            transform="matrix(-1 0 0 1 413.225 209.644)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M412.661 220.792C412.155 221.456 411.356 221.884 410.458 221.884C408.929 221.884 407.69 220.645 407.69 219.116V212.412C407.69 211.44 408.191 210.585 408.948 210.092C408.594 210.557 408.383 211.138 408.383 211.768V218.472C408.383 220.001 409.622 221.24 411.151 221.24C411.708 221.24 412.226 221.075 412.661 220.792Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M410.458 209.644C411.986 209.644 413.225 210.883 413.225 212.412V218.472C413.225 218.492 413.225 218.512 413.225 218.533V212.991C413.225 211.463 411.986 210.224 410.457 210.224C408.949 210.224 407.722 211.43 407.69 212.931V212.412C407.69 210.883 408.929 209.644 410.458 209.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#door-1-casement-1-2-filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M410.46 213C411.279 213 411.943 213.664 411.943 214.483V216C411.943 216.014 411.942 216.027 411.942 216.041L411.942 234.517C411.942 235.336 411.278 236 410.459 236C409.641 236 408.977 235.336 408.977 234.517L408.977 214.251C408.977 213.56 409.537 213 410.228 213C410.231 213 410.234 213 410.236 213C410.311 213 410.385 213 410.46 213Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M401.378 291.5H296.622L349 53.3279L401.378 291.5Z" stroke="white" />
        <path d="M401.334 171.999L296.5 290.679V52.3295L401.334 171.999Z" stroke="white" />
        <defs>
          <filter
            id="door-1-casement-1-2-filter0_d"
            x="272.12"
            y="378.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-1-2-filter1_d"
            x="273.12"
            y="47.1201"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-1-2-filter2_d"
            x="274.3"
            y="29.3003"
            width="151.279"
            height="396.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-1-2-filter3_i"
            x="294.999"
            y="50"
            width="108.94"
            height="245.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="door-1-casement-1-2-filter4_d"
            x="405.81"
            y="207.764"
            width="11.1753"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-1-2-filter5_d"
            x="407.097"
            y="211.12"
            width="8.60556"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="door-1-casement-1-2-paint0_linear"
            x1="272.2"
            y1="388.625"
            x2="279.8"
            y2="388.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-2-paint1_linear"
            x1="270.5"
            y1="400.604"
            x2="280"
            y2="400.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-2-paint2_linear"
            x1="281.722"
            y1="66.5"
            x2="274.209"
            y2="66.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-2-paint3_linear"
            x1="272.234"
            y1="51.5"
            x2="279.745"
            y2="51.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-2-paint4_linear"
            x1="281.72"
            y1="66.5"
            x2="274.209"
            y2="66.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-2-paint5_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
