import styled, { css } from 'styled-components'
import {CounterContainer, LinkButton} from "../styles";

export const StageCartWrapper = styled.div`
  display: flex;

  width: 100%;
  max-width: 1200px;
  
  @media only screen and (max-width: 991px) {
    flex-direction: column;
  }
`

export const StageCartProductsList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  padding: 0 20px 10px 0;

  @media only screen and (max-width: 991px) {
    padding-right: 0;
  }
`

export const StageCartEmptyWrapper = styled.div`
  margin: 0 auto;
`

export const StageCartEmptyTitle = styled.h3`
  font-size: 28px;
`
export const StageCartEmptyDescription = styled.span`
  font-size: 18px;

  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;

  a {
    display: flex;
    gap: 5px;
  }
`

export const StageCartProductsListItemContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 576px) {
    flex-wrap: wrap;

    ${CounterContainer} {
      position: absolute;
      right: 0;
    }
    ${LinkButton} {
      position: absolute;
      right: 15px;
      top: 50px;
    }
  }
`

export const StageCartProductsListItem = styled.div`
  border: 1px dashed #8f8f8f;

  padding: 40px;
  margin-bottom: 20px;
  
  @media only screen and (max-width: 1200px) {
    font-size: 16px;
  }
  
  @media only screen and (max-width: 768px) {
    padding: 12px;
  }
`

export const StageCartAdditionalBlockWrapper = styled.div`
  position: relative;

  min-width: 344px;
`

export const StageCartAdditionalBlock = styled.div`
  display: flex;
  flex-direction: column;

  background-color: #ffffff;
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.1);

  padding: 32px;

  min-width: 344px;
  max-width: 344px;

  margin-bottom: 24px;

  @media only screen and (max-width: 991px) {
    max-width: 100%;
  }
`

export const StageCartAdditionalBlockRow = styled.div`
  display: flex;
  justify-content: space-between;

  font-size: 16px;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 20px;
  }
`

export const StageCartAdditionalBlockTotal = styled.div`
  border-top: 1px dashed #8f8f8f;

  padding-top: 24px;
`

export const StageCartAdditionalBlockDescription = styled.div`
  font-size: 14px;
  text-align: center;
`

export const StageCartProductsListItemImageWrapper = styled.div`
  max-width: 222px;
  min-width: 222px;

  @media only screen and (max-width: 576px) {
    min-width: 180px;
  }
  
  @media only screen and (max-width: 420px) {
    min-width: 140px;
    max-width: 140px;
  }
`

export const StageCartProductsListItemDescBlock = styled.div`
  flex: 1;

  padding-left: 32px;
  padding-right: 30px;

  @media only screen and (max-width: 991px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`

export const StageCartProductsListItemPriceWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 576px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    margin-top: 20px;
  }
`

export const StageCartProductsListItemPriceTitle = styled.span`
  font-size: 12px;
  line-height: 16.8px;

  margin-bottom: 16px;

  @media only screen and (max-width: 576px) {
    margin-top: 12px;
    margin-bottom: 8px;
  }
`

export const StageCartProductsListItemPriceValue = styled.span`
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;

  margin-bottom: 13px;

  @media only screen and (max-width: 576px) {
    width: 100%;
  }
`

export const StageCartProductsListItemDescBlockTitle = styled.div`
  display: flex;

  font-size: 18px;
  font-weight: bold;

  margin-bottom: 12px;

  @media only screen and (max-width: 576px) {
    font-size: 16px;
  }
  
  @media only screen and (max-width: 420px) {
    font-size: 14px;
  }
`

export const StageCartProductsListItemDescBlockSubTitle = styled.div`
  font-size: 14px;

  margin-bottom: 8px;

  @media only screen and (max-width: 576px) {
    font-size: 12px;
  }

  @media only screen and (max-width: 420px) {
    font-size: 11px;
  }
`

export const StageCartProductsListItemDescBlockDesc = styled.div`
  font-size: 16px;

  margin-bottom: 12px;
`

export const StageCartToggleButton = styled.div`
  font-size: 14px;
  color: #1c3775;

  cursor: pointer;
`

export const StageCartShowMoreBlockWrapper = styled.div`
  margin-top: 43px;
  padding-top: 32px;
  margin-left: 254px;

  border-top: 1px dashed #8f8f8f;

  @media only screen and (max-width: 991px) {
    margin-left: 0;
  }
`

export const StageCartCharacteristicBlockWrapper = styled.div`
  margin-top: 44px;
`

export const StageCartShowMoreBlockButtonsWrapper = styled.div`
  display: flex;
`

export const StageCartShowMoreBlock = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;

  width: 100%;

  margin-top: 44px;
`

export const StageCartShowMoreBlockTitle = styled.div`
  padding-bottom: 12px;

  font-weight: bold;
  white-space: nowrap;
`

export const StageCartShowMoreBlockRowItem = styled.div<{
  noRightBorder?: boolean
  noLeftGap?: boolean
}>`
  border-right: 1px dashed #8f8f8f;

  padding: 0 28px 20px;

  @media only screen and (max-width: 576px) {
    font-size: 14px;
    line-height: 16px;
    padding: 0 12px 20px;
  }
  
  @media only screen and (max-width: 375px) {
    padding: 0 8px 20px;
  }

  ${({ noRightBorder = false }) =>
    noRightBorder &&
    css`
      border-right: none;
    `}

  ${({ noLeftGap = false }) =>
    noLeftGap &&
    css`
      padding-left: 0;
    `}
`

export const StageCartBackButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  font-weight: bold;
  color: #141b4d;
  text-decoration: none;

  cursor: pointer;
`
