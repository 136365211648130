/** Paddings values in percent(%) */
export const modelPaddings = {
  'model-1': {
    'heat-saving': {
      left: 15,
      right: 35,
    },
    soundproofing: {
      left: 15,
      right: 35,
    },
    security: {
      left: 15,
      right: 35,
    },
  },
  'model-2': {
    'heat-saving': {
      left: 25,
      right: 25,
    },
    soundproofing: {
      left: 25,
      right: 25,
    },
    security: {
      left: 25,
      right: 25,
    },
  },
  'model-3': {
    'heat-saving': {
      left: 25,
      right: 25,
    },
    soundproofing: {
      left: 35,
      right: 15,
    },
    security: {
      left: 35,
      right: 15,
    },
  },
}

export const valueMap = {
  'heat-saving': {
    1: 25,
    2: 50,
    3: 100,
  },
  soundproofing: {
    1: 25,
    2: 50,
    3: 100,
  },
  security: {
    1: 50,
    2: 100,
  },
}
