import React, { FC, memo, useMemo, useCallback } from 'react'
import { Alert } from '../../../components'
import { ITopBlockProps } from '../interface'
import { calculatorPool, pageTitles, pageVideos, selectionPool } from './consts'
import { TopBlockWrapper, SpreedBlock, AlertTitle, PageTitle, YoutubeButtonWrapper } from './styles'
// import dayjs from 'dayjs'
// import { useOrders } from 'src/react-app/hooks'

const TopBlockComponent: FC<ITopBlockProps> = props => {
  const { stage, order } = props
  //const { order } = useOrders()
  const title = useMemo(() => pageTitles[stage], [stage])
  // console.log({ order, title })
  // handleFetchOrderById
  /*const date = useMemo(() => {
    const date: any = order?.['updated-at'] || Date.now()
    return date ? dayjs(date).add(5, 'day').format('DD.MM.YYYY') : null
  }, [order])
  */

  const handleOpenVideo = useCallback(
    (videoUrl: string) => () => {
      // @ts-ignore
      // eslint-disable-next-line
      window.open(videoUrl, 'blank')
    },
    [],
  )
  return (
    <TopBlockWrapper>
      <SpreedBlock>{title && <PageTitle>{title}</PageTitle>}</SpreedBlock>
      {/*{date && <DescAlert>{`Стоимость вашего предварительного расчета действует до ${date}`}</DescAlert>}*/}
      {pageVideos[stage] && (
        <Alert initHideState={true} style={{ display: 'none' }}>
          <YoutubeButtonWrapper onClick={handleOpenVideo(pageVideos[stage] || '')}>
            {selectionPool.includes(stage) && (
              <iframe
                width="196"
                height="106"
                src={pageVideos[stage]}
                title="Подбор"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            )}
            {calculatorPool.includes(stage) && (
              <iframe
                width="196"
                height="106"
                src={pageVideos[stage]}
                title="Подбор"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            )}
          </YoutubeButtonWrapper>
          <AlertTitle>
            <span>
              Первый российский умный онлайн сервис подбора окон и дверей, учитывающий при подборе
              индивидуальные потребности и сферу применения изделий
            </span>
          </AlertTitle>
        </Alert>
      )}
    </TopBlockWrapper>
  )
}

export const TopBlock = memo(TopBlockComponent)
