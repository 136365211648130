import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement63Square62Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#6-3_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M137.001 291C138.105 291 139 290.105 139 289.001C139 287.896 138.105 287.001 137.001 287.001L134.833 287.001L134.833 287.001L124 287.001L124 287L120.999 287C119.895 287 119 287.895 119 288.999C119 290.104 119.895 290.999 120.999 290.999L123.167 290.999L123.167 291L134 291L134 291L137.001 291Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 134.833 291)"
          fill="#ABABAB"
        />
        <path
          d="M138.06 291C138.579 291 139 290.579 139 290.06L139 287.941C139 287.422 138.579 287.001 138.06 287.001L134 287.001L134 291L138.06 291Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M119.94 287C119.421 287 119 287.421 119 287.94L119 290.059C119 290.578 119.421 290.999 119.94 290.999L124 290.999L124 287L119.94 287Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 133.167 291)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#6-3_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M182.001 291C183.105 291 184 290.105 184 289.001C184 287.896 183.105 287.001 182.001 287.001L179.833 287.001L179.833 287.001L169 287.001L169 287L165.999 287C164.895 287 164 287.895 164 288.999C164 290.104 164.895 290.999 165.999 290.999L168.167 290.999L168.167 291L179 291L179 291L182.001 291Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 179.833 291)"
          fill="#ABABAB"
        />
        <path
          d="M183.06 291C183.579 291 184 290.579 184 290.06L184 287.941C184 287.422 183.579 287.001 183.06 287.001L179 287.001L179 291L183.06 291Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M164.94 287C164.421 287 164 287.421 164 287.94L164 290.059C164 290.578 164.421 290.999 164.94 290.999L169 290.999L169 287L164.94 287Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 178.167 291)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#6-3_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 209H115V287H188V209ZM177 220H126V276H177V220Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 209H115V287H116V210H187V287H188V209Z"
          fill="#FBFBFB"
        />
        <g filter="url(#6-3_filter3_i)">
          <rect
            x="126.47"
            y="220.47"
            width="50.0601"
            height="55.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="119.53"
          y="213.53"
          width="63.9399"
          height="68.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="158.528"
          y="211"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 158.528 211)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#6-3_filter4_d)">
          <rect
            x="157.884"
            y="211.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 157.884 211.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M157.766 213.695C157.42 212.542 156.35 211.702 155.084 211.702L148.444 211.702C146.898 211.702 145.644 212.955 145.644 214.502C145.644 214.537 145.645 214.572 145.646 214.607C145.993 215.76 147.062 216.6 148.328 216.6L154.968 216.6C156.515 216.6 157.768 215.346 157.768 213.8C157.768 213.765 157.768 213.73 157.766 213.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M156.409 212.034C156.266 212.012 156.118 212 155.968 212L149.328 212C147.782 212 146.528 213.253 146.528 214.8C146.528 215.867 147.125 216.795 148.003 217.267C146.666 217.056 145.644 215.898 145.644 214.502C145.644 212.955 146.898 211.702 148.444 211.702L155.084 211.702C155.564 211.702 156.015 211.822 156.409 212.034Z"
          fill="#FBFBFB"
        />
        <g filter="url(#6-3_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M171.528 214.5C171.528 213.672 170.857 213 170.028 213L169.814 213L168.528 213L150.028 213C149.2 213 148.528 213.672 148.528 214.5V214.5C148.528 215.328 149.2 216 150.028 216L166.528 216L170.028 216L170.243 216C170.953 216 171.528 215.424 171.528 214.714V214.714C171.528 214.677 171.527 214.64 171.525 214.604C171.527 214.569 171.528 214.535 171.528 214.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M127.776 274.5L151.5 222.21L175.224 274.5L127.776 274.5Z" stroke="white" />
        <defs>
          <filter
            id="6-3_filter0_d"
            x="117.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-3_filter1_d"
            x="162.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-3_filter2_d"
            x="110.3"
            y="204.3"
            width="84.2793"
            height="89.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-3_filter3_i"
            x="126"
            y="220"
            width="51.9399"
            height="58.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="6-3_filter4_d"
            x="143.764"
            y="209.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-3_filter5_d"
            x="147.649"
            y="211.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="121.5"
            y1="293.8"
            x2="121.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="136.5"
            y1="284.202"
            x2="136.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="121.5"
            y1="293.798"
            x2="121.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="166.5"
            y1="293.8"
            x2="166.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="181.5"
            y1="284.202"
            x2="181.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="166.5"
            y1="293.798"
            x2="166.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
