import styled, { css } from 'styled-components'
import { CheckboxInput, CheckboxLabel } from '../checkbox/styles'

export const ElementsListWrapper = styled.div`
  position: relative;
`

export const ElementsListContent = styled.div<{ showAfter: boolean; disabled: boolean }>`
  margin-bottom: 18px;
  padding-bottom: 20px;

  max-height: 300px;

  overflow-x: auto;

  ${({ disabled = false }) =>
    disabled &&
    css`
      pointer-events: none;
    `}

  &:before {
    content: '';
    background-color: #f4f4f4;

    display: ${({ disabled = false }) => (disabled ? 'block' : 'none')};

    position: absolute;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    opacity: 0.5;
  }

  &:after {
    content: '';

    position: absolute;

    bottom: 0;
    left: 0;

    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);

    display: ${({ showAfter }) => (showAfter ? 'block' : 'none')};

    width: 100%;
    height: 36px;

    pointer-events: none;
  }
`

export const ElementsListItem = styled.div`
  ${CheckboxLabel} {
    align-items: flex-start;

    margin-bottom: 0;

    font-size: 14px;
    line-height: 140%;
    color: #000000;
  }

  ${CheckboxInput} {
    margin-top: 4px;
  }
`

export const ElementsListItemContent = styled.div`
  display: flex;
`

export const ElementsListPrice = styled.span`
  font-size: 12px;
  font-weight: 700;

  margin-left: 26px;
`
