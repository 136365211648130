import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { ButtonViews, InputTypes } from '../interface'
import {
  ButtonWrapper,
  LinkButton,
  LinkMessage,
  LinkWrapper,
  PhoneBlockText,
  PhoneBlockTitle,
  PhoneBlockWrapper,
  RightConfirmWrapper,
  LinkListWrapper,
} from './styles'
import { usePhoneConfirmation } from '../../../hooks'
import { Button, Input, ControlGroup } from '../../../components'
import { IRegisterFormProps, RegisterFormKeys } from './interface'
import { validateEmail, validatePassword, validateRequiredText } from '../../../utilits'
import { ModalDescription, ModalTitle, ModalFooter } from '../styles'
import { errorTitles, formParams, formTitles } from './consts'
import fetchAPI from '../../../../lib/utils/fetch-api'
import { saveUserDataFromLocalStore, getUserDataFromLocalStore } from 'src/react-app/utilits/get-user-data'

export const RegisterForm: FC<IRegisterFormProps> = props => {
  const { onRegister, onOpenAuthForm, errors, resetErrors } = props
  const [phoneSent, setPhoneSent] = useState(false)
  const [canSendPhoneAgain, setCanSendPhoneAgain] = useState(false)
  const [registerData, setRegisterData] = useState<Record<string, any>>({})
  const { confirmed, confirmError, confirmPhone, confirmCode } = usePhoneConfirmation()
  const [validError, setValidError] = useState<Record<string, any>>({})
  const [needRegister, setNeedRegister] = useState(false)
  const [isNotRegPhone, setNotRegPhone] = useState(true)

  useEffect(() => {
    if (needRegister) {
      const haveErrors = Object.keys(validError).some(key => validError[key])

      if (!haveErrors) {
        onRegister(registerData)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needRegister])

  const validateForm = useCallback(
    (withPassword = false) => {
      const validateErrors = {}

      for (const param of formParams) {
        let error = ''

        if (param.required) {
          if (param.type === InputTypes.email) {
            error = validateEmail(registerData[param.name])
          } else {
            error = validateRequiredText(registerData[param.name])
          }
        }

        validateErrors[param.name] = error
      }

      if (withPassword) {
        validateErrors['password'] = validatePassword(registerData['password'])
      }

      return validateErrors
    },
    [registerData],
  )

  const handleSendPhone = useCallback(async () => {
    const isNotPhone = true /*await fetchAPI('users-test-login', {
      method: 'POST',
      body: JSON.stringify({
        login: registerData['phone'],
      }),
    })
      .then(item => Boolean(item?.['data']?.['is-login']))
      .catch(() => false)*/

    setNotRegPhone(isNotPhone)
   /* if (isNotPhone === false) {
      return
    }*/
    const validateErrors = validateForm()
    const haveErrors = Object.keys(validateErrors).some(key => validateErrors[key])

    setValidError(prevState => ({
      ...prevState,
      ...validateErrors,
    }))

    if (!haveErrors) {
      setCanSendPhoneAgain(false)
      setPhoneSent(true)
      confirmPhone(registerData['phone'])

      const response = await fetchAPI('web-hooks/send-sms-code-from-user', {
        method: 'POST',
        body: JSON.stringify({
          phone: registerData['phone'],
        }),
      }).catch(console.error)

      if (response) {
        /* TODO: do something */
      }

      setTimeout(() => setCanSendPhoneAgain(true), 10000)
    }
  }, [confirmPhone, registerData, validateForm])

  const handleConfirmPhone = useCallback(() => {
    confirmCode({ code: registerData['code'], phone: registerData['phone'] })
  }, [confirmCode, registerData])

  const handleRegisterDataChange = useCallback(
    (value: string | number | boolean, key: string) => {
      if (needRegister) {
        setNeedRegister(false)
      }

      setRegisterData(prevState => ({
        ...prevState,
        [key]: value,
      }))
      setValidError(prevState => ({
        ...prevState,
        [key]: '',
      }))
      resetErrors()
    },
    [needRegister, resetErrors],
  )

  const handleRegister = useCallback(() => {
    if (registerData['right-confirm']) {
      const validateErrors = validateForm(true)

      setValidError(prevState => ({
        ...prevState,
        ...validateErrors,
      }))
      setNeedRegister(true)
      const pathname = new URL(location.href)['pathname']
      if (['/personal/', '/personal'].includes(pathname)) {
        // @ts-ignore
// eslint-disable-next-line
        Object.assign(window.location, { href: '/uspeshnaya-registraciya' })
      }
    } else if (registerData['password'] !== registerData[RegisterFormKeys.PASSWORD_CONFIRM]) {
      setValidError(prevState => ({
        ...prevState,
        [RegisterFormKeys.PASSWORD_CONFIRM]: 'Пароли не совпадают',
      }))
    } else {
      setValidError(prevState => ({
        ...prevState,
        'right-confirm': 'Необходимо согласиться с правилами персональных данных!',
      }))
    }
  }, [registerData, validateForm])

  const canRegister = useMemo(() => {
    return registerData['password'] && registerData[RegisterFormKeys.PASSWORD_CONFIRM]
  }, [registerData])

  const getErrorsView = useCallback(() => {
    const result: Record<string, string>[] = []

    if (errors && errors.length > 0) {
      errors.forEach(({ detail }) => {
        result.push({
          detail: errorTitles[detail] || detail,
        })
      })
    }

    if (validError) {
      Object.keys(validError).forEach(key => {
        if (validError[key]) {
          let detail = validError[key]

          if (formTitles[key]) {
            detail = `${formTitles[key]}: ${detail}`
          }

          result.push({ source: key, detail })
        }
      })
    }

    return (
      <LinkListWrapper>
        {result.map(({ detail }, index) => (
          <>
            <LinkWrapper key={`${detail}-${index}`}>
              <LinkMessage isError textAlign="left">
                {detail}
              </LinkMessage>
            </LinkWrapper>
          </>
        ))}
      </LinkListWrapper>
    )
  }, [errors, validError])

  return (
    <>
      <ModalTitle>Регистрация</ModalTitle>
      <ModalDescription>
        Для оформления заказа необходимо быть зарегистрированным пользователем
      </ModalDescription>
      {formParams.map(({ Component, name, placeholder, type, required }) => (
        <ControlGroup key={name}>
          <Component
            req={required}
            placeholder={placeholder}
            name={name}
            onChange={handleRegisterDataChange}
            type={type}
            value={registerData[name]}
            errorMessage={validError[name]}
            success={name === 'phone' && confirmed}
          />
        </ControlGroup>
      ))}

      {!phoneSent && !confirmed && (
        <>
          <ButtonWrapper>
            <Button
              typeView={ButtonViews.ORANGE}
              onClick={handleSendPhone}
              disabled={!registerData['phone']}
            >
              Подтвердить номер телефона
            </Button>
          </ButtonWrapper>
          {isNotRegPhone || (
            <PhoneBlockText>
              <div style={{ color: 'red' }}>Номер телефона уже зарегистрирован!</div>
            </PhoneBlockText>
          )}
          {getErrorsView()}
        </>
      )}
      {phoneSent && !confirmed && (
        <PhoneBlockWrapper>
          <ControlGroup>
            <Input
              req={true}
              placeholder="Введите код подтверждения"
              name="code"
              type={InputTypes.text}
              onChange={handleRegisterDataChange}
            />
          </ControlGroup>
          <ButtonWrapper>
            <Button
              typeView={ButtonViews.ORANGE}
              onClick={handleConfirmPhone}
              disabled={!registerData['code']}
            >
              Подтвердить
            </Button>
          </ButtonWrapper>
          <LinkWrapper>
            {confirmError && <LinkMessage isError>{confirmError}</LinkMessage>}
            {!canSendPhoneAgain && (
              <LinkMessage>Мы отправим смс с кодом на указанный номер</LinkMessage>
            )}
            {canSendPhoneAgain && (
              <LinkButton onClick={handleSendPhone}>Отправить код еще раз</LinkButton>
            )}
          </LinkWrapper>
        </PhoneBlockWrapper>
      )}
      {confirmed && (
        <PhoneBlockWrapper>
          <PhoneBlockText>Номер телефона подтвержден!</PhoneBlockText>
          <PhoneBlockTitle>Пароль для входа в личный кабинет</PhoneBlockTitle>
          <ControlGroup>
            <Input
              req={true}
              placeholder="Введите пароль"
              type={InputTypes.password}
              name={RegisterFormKeys.PASSWORD}
              onChange={handleRegisterDataChange}
              errorMessage={validError[RegisterFormKeys.PASSWORD]}
            />
          </ControlGroup>
          <ControlGroup>
            <Input
              req={true}
              placeholder="Подтвердите пароль"
              type={InputTypes.password}
              name={RegisterFormKeys.PASSWORD_CONFIRM}
              onChange={handleRegisterDataChange}
            />
          </ControlGroup>
          <RightConfirmWrapper>
            <Input
              id="right-confirm"
              type={InputTypes.checkbox}
              name={RegisterFormKeys.RIGHT_CONFIRM}
              onChange={handleRegisterDataChange}
            />
            <label htmlFor="right-confirm">
              Я согласен с правилами обработки{' '}
              <Link to="/privacy-policy/" target="_blank">
                персональных данных
              </Link>
            </label>
          </RightConfirmWrapper>
          <ButtonWrapper>
            <Button typeView={ButtonViews.ORANGE} onClick={handleRegister} disabled={!canRegister}>
              Сохранить
            </Button>
          </ButtonWrapper>
          {getErrorsView()}
        </PhoneBlockWrapper>
      )}
      <ModalFooter>
        <span>Есть личный кабинет?</span>
        <LinkButton onClick={onOpenAuthForm}>Авторизуйтесь</LinkButton>
      </ModalFooter>
    </>
  )
}

export default RegisterForm
