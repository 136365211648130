import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement44Square4VerticalCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-4_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.9541 236.976C31.9541 235.885 31.0692 235 29.9776 235C28.886 235 28.0011 235.885 28.0011 236.976L28.0011 239.167L28.0011 239.167L28.0011 250L28 250L28 253.024C28 254.115 28.8849 255 29.9765 255C31.0681 255 31.953 254.115 31.953 253.024L31.953 250.833L31.9541 250.833L31.9541 240L31.9541 240L31.9541 236.976Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 31.9541 239.167)"
          fill="#ABABAB"
        />
        <path
          d="M31.9541 235.94C31.9541 235.421 31.5333 235 31.0142 235L28.9411 235C28.4219 235 28.0011 235.421 28.0011 235.94L28.0011 240L31.9541 240L31.9541 235.94Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M28 254.06C28 254.579 28.4208 255 28.9399 255L31.013 255C31.5322 255 31.953 254.579 31.953 254.06L31.953 250L28 250L28 254.06Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 31.9541 240.833)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#4-4_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 261C32 259.895 31.1046 259 30 259C28.8954 259 28 259.895 28 261L28 275.56L32 275.56L32 261ZM32 275.56L28 275.56L28 277.4L27.9399 277.4C27.4208 277.4 27 277.821 27 278.34L27 280.12C27 281.158 27.8417 282 28.8799 282L30.1201 282C31.1583 282 32 281.158 32 280.12L32 277.4L32 277.4L32 275.56Z"
            fill="white"
          />
        </g>
        <rect
          width="3.99998"
          height="0.95833"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 32 276.25)"
          fill="#ABABAB"
        />
        <path
          d="M32 259.94C32 259.421 31.5792 259 31.0601 259L28.94 259C28.4208 259 28 259.421 28 259.94L28 276.25L32 276.25L32 259.94Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M32 281.06C32 281.579 31.5792 282 31.0601 282L27.94 282C27.4208 282 27 281.579 27 281.06L27 278.148C27 277.629 27.4208 277.208 27.94 277.208L32 277.208L32 281.06Z"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#4-4_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 231H32V286H188V231ZM178 241H42V276H178V241Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 231H32V286H33V232H187V286H188V231Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-4_filter3_i)">
          <rect
            x="42.47"
            y="241.47"
            width="135.06"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="235.53"
          width="146.94"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          width="6.91954"
          height="13.5283"
          rx="3.45977"
          transform="matrix(-1 0 0 1 185.92 252)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#4-4_filter4_d)">
          <rect
            width="5.53563"
            height="12.2399"
            rx="2.76782"
            transform="matrix(-1 0 0 1 185.225 252.644)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M184.661 263.792C184.155 264.456 183.356 264.884 182.458 264.884C180.929 264.884 179.69 263.645 179.69 262.116V255.412C179.69 254.44 180.191 253.585 180.948 253.092C180.594 253.557 180.383 254.138 180.383 254.768V261.472C180.383 263.001 181.622 264.24 183.151 264.24C183.708 264.24 184.226 264.075 184.661 263.792Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M182.458 252.644C183.986 252.644 185.225 253.883 185.225 255.412V261.472C185.225 261.492 185.225 261.512 185.225 261.533V255.991C185.225 254.463 183.986 253.224 182.457 253.224C180.949 253.224 179.722 254.43 179.69 255.931V255.412C179.69 253.883 180.929 252.644 182.458 252.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-4_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M182.46 256C183.279 256 183.943 256.664 183.943 257.483V259C183.943 259.014 183.942 259.027 183.942 259.041L183.942 277.517C183.942 278.336 183.278 279 182.459 279V279C181.641 279 180.977 278.336 180.977 277.517L180.977 257.251C180.977 256.56 181.537 256 182.228 256V256C182.231 256 182.234 256 182.236 256C182.311 256 182.385 256 182.46 256V256Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M45.1468 274.5L110 242.557L174.853 274.5H45.1468Z" stroke="white" />
        <path d="M43.5 242.565L172.909 258.5L43.5 274.435L43.5 242.565Z" stroke="white" />
        <defs>
          <filter
            id="4-4_filter0_d"
            x="26.1201"
            y="233.12"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-4_filter1_d"
            x="25.1201"
            y="257.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-4_filter2_d"
            x="27.3003"
            y="226.3"
            width="167.279"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-4_filter3_i"
            x="42"
            y="241"
            width="136.94"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-4_filter4_d"
            x="177.81"
            y="250.764"
            width="11.1753"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-4_filter5_d"
            x="179.097"
            y="254.12"
            width="8.60556"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="34.7219"
            y1="252.5"
            x2="27.2093"
            y2="252.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="25.234"
            y1="237.5"
            x2="32.7447"
            y2="237.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="34.7201"
            y1="252.5"
            x2="27.2094"
            y2="252.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="25.2"
            y1="267.625"
            x2="32.8"
            y2="267.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="23.5"
            y1="279.604"
            x2="33"
            y2="279.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
