import React from 'react'

import { SpinIcon } from '../../assets/icons'
import { LoaderWrapper } from './styles'

export const Loader = () => {
  return (
    <LoaderWrapper>
      <SpinIcon color="#000" />
    </LoaderWrapper>
  )
}
