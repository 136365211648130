import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement13Square1Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 254C188 252.895 188.895 252 190 252C191.105 252 192 252.895 192 254V268.56H188V254ZM188 268.56H192V270.4H192.06C192.579 270.4 193 270.821 193 271.34V273.12C193 274.158 192.158 275 191.12 275H189.88C188.842 275 188 274.158 188 273.12V270.4V270.4V268.56Z"
            fill="white"
          />
        </g>
        <rect x="188" y="269.25" width="3.99998" height="0.95833" fill="#ABABAB" />
        <path
          d="M188 252.94C188 252.421 188.421 252 188.94 252H191.06C191.579 252 192 252.421 192 252.94V269.25H188V252.94Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M188 274.06C188 274.579 188.421 275 188.94 275H192.06C192.579 275 193 274.579 193 274.06V271.148C193 270.629 192.579 270.208 192.06 270.208H188V274.06Z"
          fill="url(#paint1_linear)"
        />
        <g filter="url(#filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M191.954 45.9765C191.954 44.8849 191.069 44 189.977 44C188.886 44 188.001 44.8849 188.001 45.9765V48.1665H188.001V59H188V62.0235C188 63.1151 188.885 64 189.976 64C191.068 64 191.953 63.1151 191.953 62.0235V59.8332H191.954V49H191.954V45.9765Z"
            fill="url(#paint2_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-1 0 0 1 191.954 48.1665)"
          fill="#ABABAB"
        />
        <path
          d="M191.954 44.9399C191.954 44.4208 191.533 44 191.014 44H188.941C188.422 44 188.001 44.4208 188.001 44.9399V49H191.954V44.9399Z"
          fill="url(#paint3_linear)"
        />
        <path
          d="M188 63.0601C188 63.5792 188.421 64 188.94 64H191.013C191.532 64 191.953 63.5792 191.953 63.0601V59H188V63.0601Z"
          fill="url(#paint4_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-1 0 0 1 191.954 49.8334)"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 33H32V286H188V33ZM177 44H43V275H177V44Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 33H32V286H33V34H187V286H188V33Z"
          fill="#FBFBFB"
        />
        <rect
          x="36.53"
          y="37.53"
          width="146.94"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#filter3_i)">
          <rect
            x="43.47"
            y="44.47"
            width="133.06"
            height="230.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          width="6.91954"
          height="13.5283"
          rx="3.45977"
          transform="matrix(-1 0 0 1 40.9196 153)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#filter4_d)">
          <rect
            width="5.53563"
            height="12.2399"
            rx="2.76782"
            transform="matrix(-1 0 0 1 40.2255 153.644)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.6606 164.792C39.1549 165.456 38.3564 165.884 37.4579 165.884C35.9293 165.884 34.6901 164.645 34.6901 163.116V156.412C34.6901 155.44 35.1908 154.585 35.9483 154.092C35.5938 154.557 35.3832 155.138 35.3832 155.768V162.472C35.3832 164.001 36.6224 165.24 38.151 165.24C38.7079 165.24 39.2264 165.075 39.6606 164.792Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.4577 153.644C38.9863 153.644 40.2255 154.883 40.2255 156.412V162.472C40.2255 162.492 40.2253 162.512 40.2249 162.533V156.991C40.2249 155.463 38.9857 154.224 37.4571 154.224C35.9487 154.224 34.7221 155.43 34.6899 156.931V156.412C34.6899 154.883 35.9291 153.644 37.4577 153.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.4598 157C38.2787 157 38.9426 157.664 38.9426 158.483V160C38.9426 160.014 38.9424 160.027 38.9422 160.041L38.9422 178.517C38.9422 179.336 38.2783 180 37.4594 180V180C36.6405 180 35.9766 179.336 35.9766 178.517L35.9766 158.251C35.9766 157.56 36.5369 157 37.2279 157V157C37.2307 157 37.2335 157 37.2363 157C37.3108 157 37.3853 157 37.4598 157V157Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M175.5 272.906L44.7631 159.816L175.5 46.0976L175.5 272.906Z" stroke="white" />
        <path d="M175.336 273.5L110.181 46.8055L44.665 273.5L175.336 273.5Z" stroke="white" />
        <defs>
          <filter
            id="filter0_d"
            x="186.12"
            y="250.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter1_d"
            x="186.12"
            y="42.1201"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter2_d"
            x="27.3003"
            y="28.3003"
            width="167.279"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter3_i"
            x="43"
            y="44"
            width="134.94"
            height="233.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter4_d"
            x="32.81"
            y="151.764"
            width="11.1753"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter5_d"
            x="34.0967"
            y="155.12"
            width="8.60556"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="194.8"
            y1="260.625"
            x2="187.2"
            y2="260.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="196.5"
            y1="272.604"
            x2="187"
            y2="272.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="194.722"
            y1="61.5"
            x2="187.209"
            y2="61.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="185.234"
            y1="46.5"
            x2="192.745"
            y2="46.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="194.72"
            y1="61.5"
            x2="187.209"
            y2="61.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
