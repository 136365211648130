import styled, { keyframes } from 'styled-components'

export const ButtonWrapper = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  color: #ffffff;
  background: #ec4c41;
  border: none;
  border-radius: 14px;
  font-family: 'Suisse Intl';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  &:disabled,
  &[disabled] {
    background: #e5e5e5;
    color: #a8a8a8;
    font-size: 14px;
    line-height: 15px;
    font-weight: 500;
  }
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;

  :active {
    background: #e24641;
  }
`
