import React from 'react'
import { ProductImageWrapper } from '../../../components/product-images-generator/styles'

export const Casement22LoggiaR4Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <g filter="url(#filter0_d)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M147.779 135.042C147.779 134.152 147.102 133.68 146.267 133.988C145.433 134.296 144.756 135.268 144.756 136.158V147.888L147.779 146.773V135.042ZM147.779 146.773L144.756 147.888V149.371L144.71 149.387C144.318 149.532 144 149.988 144 150.407V151.841C144 152.677 144.636 153.121 145.421 152.831L146.358 152.485C147.143 152.196 147.779 151.283 147.779 150.447V148.256V148.255V146.773Z"
              fill="white"
            />
          </g>
          <rect
            width="3.22273"
            height="0.772112"
            transform="matrix(-0.938191 0.346117 0 1 147.779 147.328)"
            fill="#ABABAB"
          />
          <path
            d="M147.779 134.188C147.779 133.77 147.461 133.548 147.069 133.693L145.466 134.284C145.074 134.429 144.756 134.885 144.756 135.303V148.444L147.779 147.329V134.188Z"
            fill="url(#paint0_linear)"
          />
          <path
            d="M147.779 151.204C147.779 151.622 147.461 152.078 147.069 152.223L144.71 153.093C144.318 153.238 144 153.016 144 152.598V150.252C144 149.834 144.318 149.377 144.71 149.233L147.779 148.1V151.204Z"
            fill="url(#paint1_linear)"
          />
          <g filter="url(#filter1_d)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M147.779 49.6383C147.779 48.7486 147.103 48.277 146.268 48.585C145.433 48.8929 144.756 49.8638 144.756 50.7535V53.171L144.757 53.1709V61.2276L144.756 61.2279V63.6454C144.756 64.5351 145.432 65.0067 146.267 64.6987C147.102 64.3908 147.778 63.4199 147.778 62.5302V60.7842L147.779 60.7839V51.3842L147.779 51.3843V49.6383Z"
              fill="url(#paint2_linear)"
            />
          </g>
          <rect
            width="3.2219"
            height="9.39966"
            transform="matrix(-0.938191 0.346117 0 1 147.779 51.3838)"
            fill="#ABABAB"
          />
          <path
            d="M147.779 48.7846C147.779 48.3664 147.461 48.1447 147.069 48.2895L145.467 48.8804C145.075 49.0251 144.757 49.4815 144.757 49.8998V53.1709L147.779 52.0558V48.7846Z"
            fill="url(#paint3_linear)"
          />
          <path
            d="M144.756 64.4986C144.756 64.9168 145.074 65.1385 145.466 64.9937L147.068 64.4028C147.461 64.2581 147.779 63.8017 147.779 63.3834V60.1123L144.756 61.2274V64.4986Z"
            fill="url(#paint4_linear)"
          />
          <rect
            width="3.2219"
            height="6.71404"
            transform="matrix(-0.938191 0.346117 0 1 147.779 52.7266)"
            fill="url(#paint5_linear)"
          />
          <g filter="url(#filter2_d)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M220.344 12.3944L147.779 39.165V160.823L220.344 134.053V12.3944ZM212.03 24.3244L156.094 44.9601V148.893L212.03 128.258V24.3244Z"
              fill="#E8EAEE"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M220.344 12.3944L147.779 39.165V160.823L148.535 160.545V39.6925L219.588 13.4796V134.332L220.344 134.053V12.3944Z"
              fill="#FBFBFB"
            />
            <rect
              x="-0.355244"
              y="-0.247591"
              width="70.0462"
              height="114.359"
              transform="matrix(0.938191 -0.346117 0 1 151.537 41.6768)"
              stroke="#E2E4E8"
              strokeWidth="0.757295"
            />
            <g filter="url(#filter3_i)">
              <rect
                x="0.377943"
                y="0.263412"
                width="58.815"
                height="103.128"
                transform="matrix(0.938191 -0.346117 0 1 156.118 45.0908)"
                stroke="#979797"
                strokeWidth="0.805684"
              />
            </g>
          </g>
          <rect
            width="5.63979"
            height="10.8996"
            rx="2.8199"
            transform="matrix(-0.938191 0.346117 0 1 218.833 67.7383)"
            fill="#9B9DA2"
            fillOpacity="0.19"
          />
          <g filter="url(#filter4_d)">
            <rect
              width="4.51183"
              height="9.8615"
              rx="2.25592"
              transform="matrix(-0.938191 0.346117 0 1 218.302 68.4521)"
              fill="#E8EAEE"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M217.861 77.5999C217.474 78.2762 216.867 78.8434 216.186 79.0949C215.017 79.5261 214.069 78.8657 214.069 77.6198V72.2701C214.069 71.4739 214.456 70.6311 215.041 70.014C214.764 70.4974 214.599 71.0364 214.599 71.5557V76.9054C214.599 78.1513 215.547 78.8117 216.716 78.3805C217.138 78.2249 217.531 77.9483 217.861 77.5999Z"
            fill="#DFE0E2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M216.186 69.233C217.355 68.8017 218.302 69.4622 218.302 70.7081V75.5387C218.302 75.5511 218.302 75.5635 218.302 75.5759V71.1755C218.302 69.9296 217.354 69.2692 216.185 69.7004C215.028 70.1274 214.088 71.4644 214.069 72.7V72.2697C214.069 71.0238 215.017 69.6642 216.186 69.233Z"
            fill="#FBFBFB"
          />
          <g filter="url(#filter5_d)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M215.053 73.546V73.391C215.053 72.8189 215.488 72.1946 216.025 71.9966C216.054 71.986 216.082 71.9764 216.111 71.968C216.136 71.9569 216.161 71.9466 216.187 71.9372C216.813 71.7062 217.321 72.06 217.321 72.7274V72.8806C217.321 72.8869 217.321 72.8933 217.321 72.8997L217.321 88.8408C217.321 89.5082 216.813 90.2366 216.187 90.4676C215.561 90.6986 215.053 90.3448 215.053 89.6774L215.053 76.3839C215.053 76.3839 215.053 76.3839 215.053 76.3839C215.053 76.3839 215.053 76.3839 215.053 76.3838V73.564C215.053 73.558 215.053 73.552 215.053 73.546Z"
              fill="#E8EAEE"
            />
          </g>
          <path
            d="M157.228 146.777V46.2403L210.703 76.7811L157.228 146.777Z"
            stroke="white"
            strokeWidth="0.805684"
          />
          <path
            d="M184.062 36.9254L210.774 127.513L157.35 147.222L184.062 36.9254Z"
            stroke="white"
            strokeWidth="0.805684"
          />
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="142.485"
            y="132.38"
            width="8.32319"
            height="23.5695"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.757295" dy="0.757295" />
            <feGaussianBlur stdDeviation="1.13594" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter1_d"
            x="143.241"
            y="46.9766"
            width="7.56745"
            height="20.846"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.757295" dy="0.757295" />
            <feGaussianBlur stdDeviation="1.13594" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter2_d"
            x="146.168"
            y="10.7832"
            width="79.0106"
            height="154.874"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.61137" dy="1.61137" />
            <feGaussianBlur stdDeviation="1.61137" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter3_i"
            x="156.094"
            y="24.3242"
            width="56.6929"
            height="126.841"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.757295" dy="2.27188" />
            <feGaussianBlur stdDeviation="1.51459" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter4_d"
            x="212.555"
            y="67.586"
            width="8.77673"
            height="14.6692"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.757295" dy="0.757295" />
            <feGaussianBlur stdDeviation="1.13594" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter5_d"
            x="213.539"
            y="70.3516"
            width="6.81155"
            height="23.2156"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.757295" dy="0.757295" />
            <feGaussianBlur stdDeviation="1.13594" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="142.639"
            y1="142.276"
            x2="148.384"
            y2="142.276"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="141.354"
            y1="152.401"
            x2="148.535"
            y2="152.401"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="149.896"
            y1="61.3458"
            x2="144.151"
            y2="61.3458"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="142.641"
            y1="51.9373"
            x2="148.384"
            y2="51.9373"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="149.895"
            y1="61.3459"
            x2="144.151"
            y2="61.3459"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="5.47722"
            y1="3.35702"
            x2="-0.644379"
            y2="3.35702"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <clipPath id="clip0">
            <rect
              width="85.4025"
              height="127.298"
              fill="white"
              transform="matrix(0.938191 -0.346117 0 1 144 40.5596)"
            />
          </clipPath>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
