import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement26Square8Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-6_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M224 46.9995C224 45.8952 223.105 45 222.001 45C220.896 45 220.001 45.8952 220.001 46.9995V49.1665H220.001V60H220V63.0005C220 64.1048 220.895 65 221.999 65C223.104 65 223.999 64.1048 223.999 63.0005V60.8332H224V50H224V46.9995Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 224 49.1665)"
          fill="#ABABAB"
        />
        <path
          d="M224 45.9399C224 45.4208 223.579 45 223.06 45H220.941C220.422 45 220.001 45.4208 220.001 45.9399V50H224V45.9399Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M220 64.0601C220 64.5792 220.421 65 220.94 65H223.059C223.578 65 223.999 64.5792 223.999 64.0601V60H220V64.0601Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 224 50.8335)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#2-6_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M220 123C220 121.895 220.895 121 222 121C223.105 121 224 121.895 224 123V137.56H220V123ZM220 137.56H224V139.4H224.06C224.579 139.4 225 139.821 225 140.34V142.12C225 143.158 224.158 144 223.12 144H221.88C220.842 144 220 143.158 220 142.12V139.4V139.4V137.56Z"
            fill="white"
          />
        </g>
        <rect x="220" y="138.25" width="3.99998" height="0.95833" fill="#ABABAB" />
        <path
          d="M220 121.94C220 121.421 220.421 121 220.94 121H223.06C223.579 121 224 121.421 224 121.94V138.25H220V121.94Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M220 143.06C220 143.579 220.421 144 220.94 144H224.06C224.579 144 225 143.579 225 143.06V140.148C225 139.629 224.579 139.208 224.06 139.208H220V143.06Z"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#2-6_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M220 34H131V155H220V34ZM209 45H142V144H209V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M220 34H131V155H132.219V35H218.781V155H220V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-6_filter3_i)">
          <rect
            x="142.47"
            y="45.47"
            width="66.0601"
            height="98.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="135.53"
          y="38.53"
          width="79.9399"
          height="111.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="133"
          y="87"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#2-6_filter4_d)">
          <rect x="133.702" y="87.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M134.286 98.7956C134.798 99.4577 135.6 99.8841 136.502 99.8841C138.048 99.8841 139.302 98.6305 139.302 97.0841V90.4442C139.302 89.4557 138.79 88.5869 138.016 88.0886C138.382 88.5619 138.6 89.1556 138.6 89.8001V96.44C138.6 97.9864 137.347 99.24 135.8 99.24C135.242 99.24 134.722 99.0768 134.286 98.7956Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M136.502 87.644C134.956 87.644 133.702 88.8976 133.702 90.444V96.4397C133.702 96.4601 133.702 96.4805 133.703 96.5007V91.0236C133.703 89.4772 134.956 88.2236 136.503 88.2236C138.029 88.2236 139.269 89.4444 139.302 90.9627V90.444C139.302 88.8976 138.048 87.644 136.502 87.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-6_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M136.5 91C135.672 91 135 91.6716 135 92.5V92.7143V94V112.5C135 113.328 135.672 114 136.5 114V114C137.328 114 138 113.328 138 112.5V96V92.5L138 92.2857C138 91.5756 137.424 91 136.714 91V91C136.677 91 136.64 91.0012 136.604 91.0035C136.569 91.0012 136.535 91 136.5 91Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M207.5 46.9969L143.836 94.5L207.5 142.003L207.5 46.9969Z" stroke="white" />
        <path d="M143.695 142.5L175.5 47.5739L207.305 142.5L143.695 142.5Z" stroke="white" />
        <defs>
          <filter
            id="2-6_filter0_d"
            x="218.12"
            y="43.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-6_filter1_d"
            x="218.12"
            y="119.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-6_filter2_d"
            x="126.3"
            y="29.3003"
            width="100.279"
            height="132.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-6_filter3_i"
            x="142"
            y="45"
            width="67.9399"
            height="101.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-6_filter4_d"
            x="131.822"
            y="85.7642"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-6_filter5_d"
            x="133.12"
            y="89.1201"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="226.8"
            y1="62.5"
            x2="219.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="217.202"
            y1="47.5"
            x2="224.8"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="226.798"
            y1="62.5"
            x2="219.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="226.8"
            y1="129.625"
            x2="219.2"
            y2="129.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="228.5"
            y1="141.604"
            x2="219"
            y2="141.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
