import { getCasementsValues, getCollectionsObjectsRequest, splitString } from './utilits'
import { TProduct, TSizes } from '../views/calculator/interface'
import { getSquare } from '../views/calculator/stage-calculator/consts'
import { IFilterItem } from '../views/calculator/stage-calculator/interface'

/** Parsing and calculation perimeter by formulas array by sizes
 * @param {Array} formulas - array of strings
 * @param {Object} sizes - object with sizes
 * @returns {Array} with calculations */
export const parseAndCalculatePerimeterFormulas = (
  formulas: string[],
  sizes: Record<string, number>,
): number[] => {
  return formulas.map(formula => {
    let tempResult = 0
    const terms = splitString(formula, '+') // example: ['2*x1', '2*y1']

    terms.forEach(term => {
      const multipliers = splitString(term, '*') // example: ['2', 'x1']
      let tempMultiplierResult = 1

      multipliers.forEach((multiplier, multiplierIndex) => {
        const numberMultiplier = Number(multiplier)

        if (numberMultiplier) {
          tempMultiplierResult *= numberMultiplier
        } else {
          const [key, index] = multipliers[multiplierIndex]
          const numberIndex = Number(index) - 1
          const size = (sizes[`casements-${key}`] && sizes[`casements-${key}`][numberIndex]) || 0
          tempMultiplierResult *= size
        }
      })

      tempResult += tempMultiplierResult
    })

    return tempResult
  })
}

export const parseFormula = (formulas: string[]): string[][][] => {
  return formulas.map(formula => {
    const terms = splitString(formula, '+') // example: ['2*x1', '2*y1']

    return terms.map(term => {
      return splitString(term, '*') // example: ['2', 'x1']
    })
  })
}

export const calculateSquareByFormula = (
  formulaGroups: string[][],
  sizes: Record<string, number | number[]>,
) => {
  const sizeArr: number[] = []
  let result = 0

  formulaGroups.forEach(multipliers => {
    multipliers.forEach(multiplier => {
      const numberMultiplier = Number(multiplier)

      if (!numberMultiplier) {
        const [key, index] = multiplier
        const numberIndex = Number(index) - 1
        const size = (sizes[`casements-${key}`] && sizes[`casements-${key}`][numberIndex]) || 0

        sizeArr.push(size)
      }
    })
  })

  if (sizeArr.length > 0) {
    result = getSquare(sizeArr[0], sizeArr[1])
  }

  return result
}

/**
 *  Getting formula by product
 *  @param {object} product - object of product params
 *  @param {string} parentKey - root key from product
 *  @param {string} childKey - child key of product param
 *  @return {string} result formula */
export const getFormula = (
  product: Record<string, {}>,
  parentKey: string,
  childKey: string,
): string => {
  let result = ''

  if (product[parentKey] && product[parentKey][`${childKey}-formula`]) {
    result = product[parentKey][`${childKey}-formula`]
  }

  return result
}

/** Getting parentName by product */
export const getParentName = (product, childKey: string): string => {
  /** Cache childKey */
  let result = childKey

  Object.keys(product).forEach(parentKey => {
    const parentGroup = product[parentKey]

    if (parentGroup) {
      const parentGroupKeys = Object.keys(parentGroup)

      /** If parentGroup have childKey with price suffix - return parentKey this group */
      if (parentGroupKeys.includes(`${childKey}-price`)) {
        result = parentKey
      }
    }
  })

  return result
}

export const getProductTargetKey = (
  product: Record<string, {}>,
  parentKey: string,
  childKey: string,
): string => {
  let result = 'value'

  if (product[parentKey]) {
    if (product[parentKey][`${childKey}-value`]) {
      result = `${childKey}-value`
    } else if (product[parentKey]['target-key']) {
      result = product[parentKey]['target-key']
    }
  }

  return result
}

export const getElementRequest = (
  priceList: Record<string, {}>,
  parentKey: string,
  priceListKeys: string[],
  product: Record<string, {}>,
) => {
  const isColorKey = parentKey.indexOf('color') >= 0 && product[parentKey]['price']
  let result

  if (parentKey || isColorKey) {
    const haveAlreadyPrice = priceListKeys.includes(parentKey)

    if (!haveAlreadyPrice) {
      if (isColorKey) {
        result = getCollectionsObjectsRequest(parentKey)
      } else if (!priceList[parentKey]) {
        result = getCollectionsObjectsRequest(parentKey)
      }
    }
  }

  return result
}

export const checkEquivalent = (valueA, valueB) => {
  return `${valueA}` === `${valueB}`
}

export const getTargetAdditionalElementPriceData = (
  product: Record<string, any> = {},
  priceList: Record<string, any>,
  priceListKey,
  type,
  configuration?: string,
) => {
  return priceList[priceListKey].find(item => {
    const collectionModelKey = item['model-key'] || `${type}-model`
    const collectionConfigurationKey = item['configuration-key'] || 'configuration'
    const productModelKey = getProductTargetKey(product, collectionModelKey, priceListKey)
    const productConfigurationKey = getProductTargetKey(
      product,
      collectionConfigurationKey,
      priceListKey,
    )
    let result = false
    if (product[collectionConfigurationKey] && product[collectionModelKey]) {
      const isModelEquivalent = checkEquivalent(
        item.model,
        product[collectionModelKey][productModelKey],
      )
      const configurationValue = product[collectionConfigurationKey][productConfigurationKey]
      const isConfigurationEquivalent = checkEquivalent(
        item.configuration,
        configuration || configurationValue,
      )

      result = isModelEquivalent && isConfigurationEquivalent
    }

    return result
  })
}

export const getColorMargin = (keys: string[], product: TProduct = {}, priceColorList) => {
  let result = 0

  keys.forEach(key => {
    const targetColorData = priceColorList[key]?.find(
      item => product[key] && item.code === product[key]['code'],
    )
    // второй параметр в условии проверяет, что установлен не белый цвет, на белый цвет нет наценки
    if (targetColorData && targetColorData?.['margin'] > 0) {
      const targetMargin = Number(targetColorData['margin'])

      result += targetMargin
    }
  })

  return result
}

export const calculatePerimeterFormulaSize = (
  formulasString: string,
  sizes: Record<string, number>,
) => {
  const formulas = splitString(formulasString) // example: ['2*x1 + 2*y1']

  return parseAndCalculatePerimeterFormulas(formulas, sizes)
}

export const getSize = (
  unit: string,
  priceListKey: string,
  itemIndex?: number,
  product: TProduct = {},
  sizes: TSizes = {},
) => {
  let result = 0
  const parentKey = getParentName(product, priceListKey)

  switch (unit) {
    case 'square-self':
      if (sizes[`${priceListKey}-width`] && sizes[`${priceListKey}-length`]) {
        result = getSquare(sizes[`${priceListKey}-width`], sizes[`${priceListKey}-length`])
      }
      break
    case 'leaves-dependency':
      result = 1 // count calculate in calculateCurrentPrice by getCount
      break
    case 'perimeter':
      if (typeof itemIndex !== 'undefined') {
        const formulasString = getFormula(product, parentKey, priceListKey)
        const tempResult = calculatePerimeterFormulaSize(formulasString, sizes)

        result = tempResult[itemIndex] || 0
      }
      break
    case 'self':
      result = 1
      break
    case 'square-leaves':
      if (typeof itemIndex !== 'undefined') {
        const formulasString = getFormula(product, 'configuration', 'casements')
        const formulas = splitString(formulasString) // example: ['2*x1 + 2*y1']
        const parsedFormulas = parseFormula(formulas)

        result = calculateSquareByFormula(parsedFormulas[itemIndex], sizes)
      }
      break
    case 'square':
    default:
      if (sizes['model-width'] && sizes['model-height']) {
        result = getSquare(sizes['model-width'], sizes['model-height'])
      }
      break
  }

  return result
}

export const getCount = (key: string, product: TProduct, filters: IFilterItem[]): null | number => {
  let result: null | number = null
  const casementFilter = filters.find(filter => filter.key === 'casements-index')
  const mosquitoFilter = filters.find(filter => filter.key === 'mosquito-index')
  const isAeration = key === 'aeration'
  const isMosquito = key === 'mosquito-type'
  const isChildrenSecurity = key === 'children-security'

  if (
    product[key] &&
    product[key]['number-of-leaves-depends'] &&
    casementFilter &&
    Array.isArray(casementFilter['value'])
  ) {
    result = casementFilter['value'].filter(item => typeof item === 'number').length
  }

  if (isAeration) {
    const casementsValue = getCasementsValues(product, filters)

    if (casementsValue && Array.isArray(casementsValue)) {
      result = casementsValue.filter(item => item === 'swing-out').length
    }
  }

  if (isChildrenSecurity) {
    const casementsValue = getCasementsValues(product, filters)

    if (casementsValue && Array.isArray(casementsValue)) {
      result = casementsValue.filter(item => item !== 'transom').length
    }
  }

  if (isMosquito && mosquitoFilter && Array.isArray(mosquitoFilter['value'])) {
    result = mosquitoFilter['value'].length
  }

  return result
}

/**
 * @param {Object} props - data from price collection object
 * @param {Boolean} totalPrice - calculate total price or single
 * @param {Number} size - size value
 *  @returns {number}: if totalPrice = true, return price with shift and|or margin, if totalPrice = false, return only shift and|or margin price
 *  */
export const calculateSelfPrice = (
  { price: oldPrice, margin = 0, shift = 0, unit = '' },
  totalPrice = true,
  size = 0,
): number => {
  let truePrice: number = typeof oldPrice === 'number' ? oldPrice : 0

  if (oldPrice && oldPrice.constructor === Array) {
    const currentPrice: Record<string, number> | undefined = oldPrice
      .sort((a, b) => a[unit] - b[unit])
      .find(p => size <= p[unit])

    if (currentPrice) {
      truePrice = currentPrice['price']
    }
  }

  let result: number = totalPrice ? truePrice : 0

  if (shift) {
    result *= shift
  }
  if (margin) {
    result += truePrice * (margin / 100)
  }

  return result
}

export const getTotalCost = priceList => {
  let result = 0

  priceList.forEach(price => {
    result += price['total']
  })

  return result
}

export const getTargetKey = (product, parentKey) => {
  return product[parentKey]['target-key'] ? product[parentKey]['target-key'] : ''
}
