import styled, { keyframes } from 'styled-components'

export const MODAL_ANIMATION_DURATION = 300
export const MODAL_ANIMATION_FN = 'cubic-bezier(0.23, 1, 0.32, 1)'
export const animationModalMask = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
export const ModalMask = styled.div<{ isOpen: boolean }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  inset: 0;
  z-index: 500;
  height: 100%;
  background-color: #00000073;
  bottom: 0;
  animation-name: ${animationModalMask};
  animation-duration: ${MODAL_ANIMATION_DURATION}ms;
  animation-timing-function: ${MODAL_ANIMATION_FN};
  transition: all ${MODAL_ANIMATION_DURATION}ms;
  transition: all ${MODAL_ANIMATION_DURATION}ms;
  opacity: ${(props) => props.isOpen ? '1' : '0'};
`
export const animationOpenWrapperModal = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
`
 

 

export const YandexMapContainer= styled.div`
  height: calc(100% - 150px);
  width: 100vw;
  & .geolocation-control > ymaps > ymaps {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    & > ymaps:first-child {
      width: 40px; 
      height: 40px;
    }
  }
  & .copyrights-promo {
    display: none
  }
`

export const FooterWarapper = styled.div`
  height: 150px;
  width: 100vw;
  background: #FFFFFF;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.08);
  padding: 20px;
  padding-bottom: 10px;
  & > .button-confirm {
    margin-top: 20px;
  }
`
