import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement7Door1RCasement1 = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="451"
        height="451"
        viewBox="0 0 451 451"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#door-1-casement-1-7-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.954 46.9765C31.954 45.8849 31.0691 45 29.9775 45C28.8859 45 28.001 45.8849 28.001 46.9765V49.1665H28.001V60H27.9999V63.0235C27.9999 64.1151 28.8848 65 29.9764 65C31.068 65 31.9529 64.1151 31.9529 63.0235V60.8332H31.954V50H31.954V46.9765Z"
            fill="url(#door-1-casement-1-7-paint0_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-1 0 0 1 31.954 49.1665)"
          fill="#ABABAB"
        />
        <path
          d="M31.954 45.9399C31.954 45.4208 31.5332 45 31.014 45H28.9409C28.4218 45 28.001 45.4208 28.001 45.9399V50H31.954V45.9399Z"
          fill="url(#door-1-casement-1-7-paint1_linear)"
        />
        <path
          d="M27.9999 64.0601C27.9999 64.5792 28.4207 65 28.9398 65H31.0129C31.532 65 31.9529 64.5792 31.9529 64.0601V60H27.9999V64.0601Z"
          fill="url(#door-1-casement-1-7-paint2_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-1 0 0 1 31.954 50.8334)"
          fill="url(#door-1-casement-1-7-paint3_linear)"
        />
        <g filter="url(#door-1-casement-1-7-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.954 271.976C31.954 270.885 31.0691 270 29.9775 270C28.8859 270 28.001 270.885 28.001 271.976V274.167H28.001V285H27.9999V288.024C27.9999 289.115 28.8848 290 29.9764 290C31.068 290 31.9529 289.115 31.9529 288.024V285.833H31.954V275H31.954V271.976Z"
            fill="url(#door-1-casement-1-7-paint4_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-1 0 0 1 31.954 274.167)"
          fill="#ABABAB"
        />
        <path
          d="M31.954 270.94C31.954 270.421 31.5332 270 31.014 270H28.9409C28.4218 270 28.001 270.421 28.001 270.94V275H31.954V270.94Z"
          fill="url(#door-1-casement-1-7-paint5_linear)"
        />
        <path
          d="M27.9999 289.06C27.9999 289.579 28.4207 290 28.9398 290H31.0129C31.532 290 31.9529 289.579 31.9529 289.06V285H27.9999V289.06Z"
          fill="url(#door-1-casement-1-7-paint6_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-1 0 0 1 31.954 275.833)"
          fill="url(#door-1-casement-1-7-paint7_linear)"
        />
        <g filter="url(#door-1-casement-1-7-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M265 34H31.9999V301H265V34ZM254 45H42.9999V290H254V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M265 34H31.9999V301H32.9999V35H264V301H265V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="36.5299"
          y="38.53"
          width="223.94"
          height="257.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#door-1-casement-1-7-filter3_i)">
          <rect
            x="43.4698"
            y="45.47"
            width="210.06"
            height="244.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          width="6.91954"
          height="13.5283"
          rx="3.45977"
          transform="matrix(-1 0 0 1 262.919 161)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#door-1-casement-1-7-filter4_d)">
          <rect
            width="5.53563"
            height="12.2399"
            rx="2.76782"
            transform="matrix(-1 0 0 1 262.225 161.644)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M261.66 172.792C261.155 173.456 260.356 173.884 259.458 173.884C257.929 173.884 256.69 172.645 256.69 171.116V164.412C256.69 163.44 257.191 162.585 257.948 162.092C257.594 162.557 257.383 163.138 257.383 163.768V170.472C257.383 172.001 258.622 173.24 260.151 173.24C260.708 173.24 261.226 173.075 261.66 172.792Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M259.458 161.644C260.986 161.644 262.225 162.883 262.225 164.412V170.472C262.225 170.492 262.225 170.512 262.225 170.533V164.991C262.225 163.463 260.985 162.224 259.457 162.224C257.949 162.224 256.722 163.43 256.69 164.931V164.412C256.69 162.883 257.929 161.644 259.458 161.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#door-1-casement-1-7-filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M259.46 165C260.279 165 260.942 165.664 260.942 166.483V168C260.942 168.014 260.942 168.027 260.942 168.041L260.942 186.517C260.942 187.336 260.278 188 259.459 188C258.64 188 257.976 187.336 257.976 186.517L257.976 166.251C257.976 165.56 258.537 165 259.228 165C259.231 165 259.233 165 259.236 165C259.311 165 259.385 165 259.46 165Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M44.5 288.131V46.869L252.005 167.5L44.5 288.131Z" stroke="white" />
        <defs>
          <filter
            id="door-1-casement-1-7-filter0_d"
            x="26.12"
            y="43.1201"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-1-7-filter1_d"
            x="26.12"
            y="268.12"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-1-7-filter2_d"
            x="27.3002"
            y="29.3003"
            width="244.279"
            height="278.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-1-7-filter3_i"
            x="42.9999"
            y="45"
            width="211.94"
            height="247.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="door-1-casement-1-7-filter4_d"
            x="254.81"
            y="159.764"
            width="11.1753"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-1-7-filter5_d"
            x="256.097"
            y="163.12"
            width="8.60556"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="door-1-casement-1-7-paint0_linear"
            x1="34.7218"
            y1="62.5"
            x2="27.2092"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-7-paint1_linear"
            x1="25.2339"
            y1="47.5"
            x2="32.7446"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-7-paint2_linear"
            x1="34.72"
            y1="62.5"
            x2="27.2093"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-7-paint3_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-7-paint4_linear"
            x1="34.7218"
            y1="287.5"
            x2="27.2092"
            y2="287.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-7-paint5_linear"
            x1="25.2339"
            y1="272.5"
            x2="32.7446"
            y2="272.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-7-paint6_linear"
            x1="34.72"
            y1="287.5"
            x2="27.2093"
            y2="287.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-7-paint7_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
