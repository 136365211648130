import { Dispatch, ReactElement, SetStateAction } from 'react'

import { CalculatorType, IOrderForecastItem, TProduct, TSizes } from '../interface'

export enum AttributesTypes {
  SELECT = 'select',
  GALLERY = 'gallery',
  TEXT = 'text',
  COMPARE = 'compare',
  VIDEO_LINK = 'video-link',
  SLIDER = 'slider',
  CHECKBOX = 'checkbox',
  RADIOBUTTON = 'radiobutton',
  BOOLEAN = 'boolean',
  TEXT_RANGE = 'text-range',
  SIZES = 'sizes',
  TABS = 'tabs',
  NUMBER = 'number',
  BADGE = 'badge',
  PRICE = 'price',
  HR = 'hr',
  SWITCHER = 'switcher',
}

export enum AttributesLevelTypes {
  TOP_LEVEL = 'top-level',
  DEFAULT = 'default',
}

export enum GroupTargetTypes {
  HIDE = 'hide',
  DISABLED = 'disabled',
}

export interface IProductContext {
  type: CalculatorType | null
  menuItems: any[]
  menuItemsData: Record<string, any[]>
  filters: IFilterItem[]
  setFilters: Dispatch<SetStateAction<IFilterItem[]>>
  setItemSize: Dispatch<SetStateAction<Record<string, number | number[]>>>
  setSelectedSubAttributeData: Dispatch<SetStateAction<null | Record<string, any>>>
  onChangeStepByStepHelper: () => void
  product: Record<string, any>
  selectedSubAttributeData: null | Record<string, any>
  stepByStepHelper: string
  totalPriceList: Record<string, number>[]
  calculateTotalPrice: (
    type: CalculatorType | null,
    products: TProduct[],
    sizes: Record<string, any>[],
    filters: IFilterItem[][],
  ) => void
  calculateAdditionalElementPrice: (
    additionalElementKey: string,
    type: CalculatorType | null,
    product: Record<string, string>,
    sizes: TSizes,
    filters?: IFilterItem[],
    colorKeys?: string[],
    innerCount?: number,
  ) => number
  itemSize: Record<string, number | number[]>
  checkedMenu: string[]
  setCheckedMenu: (key: string) => void
  wallColor: string
  setWallColor: (color: string) => void
}

export interface IMenuProps {
  defaultData: Record<string, any>
  product: TProduct
  productType: CalculatorType | null
  onSetItemSize: (size: IItemSize[]) => void
  elementsPriceList: Record<string, any[]>
  setFilters: Dispatch<SetStateAction<IFilterItem[]>>
  setIsConfigurationEdit: Dispatch<SetStateAction<boolean>>
  setNeedUpdateItemSize: Dispatch<SetStateAction<boolean>>
}

export interface IItemSize {
  key: string
  value: number | number[]
}

export type TCasementsErrors = Record<string, { title: string; description: string }[]>

export interface IMainViewProps {
  itemConfiguration: Record<string, any>
  onSetItemSize: (size: IItemSize[]) => void
  onSetImageSize: (imageSize: { width: number; height: number }) => void
  onGetPreviewImages: (images: (ReactElement | null)[]) => void
  product: any
  productType: CalculatorType | null
  filters: IFilterItem[]
  inProcess: boolean
  isConfigurationEdit: boolean
  setIsConfigurationEdit: Dispatch<SetStateAction<boolean>>
  casementsErrors: TCasementsErrors
  setCasementsErrors: Dispatch<SetStateAction<TCasementsErrors>>
  initSize?: Record<string, number | number[]>
}

export interface IItemViewConstructorProps {
  productType: string
}

export interface IAdditionBlockProps {
  inProcess: boolean
  isEdit: boolean
  isCanSubmit: boolean
  price: number
  additionalPrice: Record<string, number>
  itemConfiguration: Record<string, any>
  onSetItemConfiguration: (config: { key: string; value: any }) => void
  product: any
  onSubmit: () => void
  onSetItemSize: (sizes: IItemSize[]) => void
}

export interface IMenuItemChildren {
  title: string
  'full-title': string
  code: string
  attributes: IAttribute[]
  onChange: (item: IFilterItem) => void
}

export interface ISubMenuBodyProps {
  data: IMenuItemChildren | null
  menuItemsData: Record<string, any[]>
  product: TProduct
  productType: CalculatorType | null
  filters: IFilterItem[]
  onSelect: (props: {
    dropFilter?: boolean
    attribute: IAttribute
  }) => (key, value, notFilter: boolean) => void
  elementsPriceList: Record<string, any[]>
  calculateAdditionalElementPrice: (
    additionalElementKey: string,
    type: CalculatorType | null,
    product: TProduct,
    sizes: TSizes,
    filters?: IFilterItem[],
    colorKeys?: string[],
  ) => number
}

export interface IRadiobuttonItem {
  id: string
  value: string
  text: string
  price: number
}

export interface IRadiobuttonProps {
  id: string
  data?: {}
  onSelect: (item: IRadiobuttonItem) => void
  selected?: IRadiobuttonItem | IRadiobuttonItem[] | null
  items: IRadiobuttonItem[]
  disabled?: boolean
  price?: number
}

export interface ICheckboxItem {
  name: string
  title: string
  value: boolean
  isEqually?: boolean
  attributes?: Record<string, any>[]
}

export interface ICheckboxProps {
  id: string
  data?: {}
  onSelect?: (item: ICheckboxItem) => void
  selected?: ICheckboxItem | ICheckboxItem[] | null
  items: ICheckboxItem[]
  disabled?: boolean
  show?: boolean
  price?: number
}

export interface IHandleFilterChangeProps {
  dropFilter?: boolean
}

export interface IProductionsMenuMapProps {
  type: CalculatorType | null
  products: any[]
  handleFilterChange?: (props?: IHandleFilterChangeProps) => (item: IFilterItem) => void
}

export interface IFilterItem {
  key: string
  value: string | boolean | number | any[] | null
}

export interface IMapParams {
  originalKey: string
  targetKey: string
}

export interface IAttribute {
  id: string
  title: string
  description?: string
  placeholder?: string
  icon?: string
  type: AttributesTypes
  levelType?: AttributesLevelTypes
  data?: {
    id?: string
    placeholder?: string
    attributes?: any[]
    min?: number | { value: number }[] | null
    max?: number | { value: number }[] | null
    items?: any[]
    value?: any
  }
  target?: string
  groupTargetKey?: string
  groupTargetValue?: any
  view?: number
  mapParams?: IMapParams[]
  notFilter?: boolean
}

export interface IAttributeViewProps extends IAttribute {
  onSelect?: (key: string, item: any, notFilter: boolean) => void
  menuData?: Record<string, any>[]
  importantSelected?: any | null
  price?: number
  selectedProductColor?: string
  data?: Record<string, any>
}

export interface IProductionsMenuMapChildren {
  title: string
  'full-title': string
  code: string
  attributes: IAttribute[]
}

export interface IProductionsMenuMap {
  title: string
  code: string
  children: IProductionsMenuMapChildren[]
}

export interface IMapParam {
  originalKey: string
  targetKey: string
}

export interface ISizes {
  width: number
  height: number
}

export interface IItemPriceCollection {
  configuration: string
  model: string
  price: string
  margin: string
  'color-1-margin': string
  'color-2-margin': string
  'color-3-margin': string
  'color-4-margin': string
  'ebb-type-color-margin': string
  'inner-slope-type-color-margin': string
  'layout-model-outside-color-margin': string
  'mosquito-type-color-margin': string
  'outer-slope-type-color-margin': string
  'windowsill-model-color-margin': string
  'window-hand-price': string
  'window-hand-margin': string
  'children-security': string
}

export interface ICartItemProps {
  productType: CalculatorType | null
  product: any
  count: number
  itemNumber: number
  sizes: TSizes
  onDeleteOrder: () => void
  onEditOrder: () => void
  filters: IFilterItem[]
  onSetPrice: (price: Record<string, any>) => void
  isSdk?: boolean 
  bitrixId?: number
  onQuantityChange: (id, number: number) => void
}

export interface IOrderItemProps {
  product: any
  count: number
  itemNumber: number
  sizes: TSizes
  onDeleteOrder: () => void
  totalPrice: Record<string, any>
  filters: IFilterItem[]
  isSdk?: boolean
  bitrixId?: number
}

export interface IDescriptionTypeWindowProps {
  product: TProduct
  count: number
  itemPrice: number
  sizes: TSizes
  elementsData: Record<string, any>
  filters: IFilterItem[]
}

export interface IContainerSquareProps {
  onCasementClick: (casementIndex: any) => () => void
  withoutBackground: boolean
}

export interface IOrder {
  id: number
  'specification-products': Record<string, any>
  services: []
}

export interface IUseOrders {
  order: Record<string, any> | null
  orders: IOrder[]
  cases: IOrder[]
  forecast: IOrderForecastItem[] | null
  fetchOrderById: (id: number) => Record<string, any> | null
  fetchOrders: () => void
  fetchForecast: () => void
  inProcess: boolean
}

export interface IOrderItemBlock {
  orderItemTitle?: string
  noStatuses?: boolean
  active: boolean
  data: IOrder
  setActiveItem: () => void
  canEdit?: boolean
  fetchOrderById?: (orderId: number) => void
  deleteOrder?: (orderId: number) => Promise<any>
}

export interface IOrderInfoBlockProps {
  data: IOrder
}

export interface IOrderDataBlockProps {
  data: IOrder
}

export interface IOrderNotificationsBlockProps {
  data: IOrder
  onChangeNotification: (notification: Record<string, any>) => void
}
