import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement32Square4TopBottomCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#3-2_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 128.001C417 129.105 417.895 130 418.999 130C420.104 130 420.999 129.105 420.999 128.001V125.833H420.999V115H421V111.999C421 110.895 420.105 110 419.001 110C417.896 110 417.001 110.895 417.001 111.999V114.167H417V125H417V128.001Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1 0 0 -1 417 125.834)"
          fill="#ABABAB"
        />
        <path
          d="M417 129.06C417 129.579 417.421 130 417.94 130H420.059C420.578 130 420.999 129.579 420.999 129.06V125H417V129.06Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M421 110.94C421 110.421 420.579 110 420.06 110H417.941C417.422 110 417.001 110.421 417.001 110.94V115H421V110.94Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1 0 0 -1 417 124.166)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#3-2_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 190.999C417 189.895 417.895 189 418.999 189C420.104 189 420.999 189.895 420.999 190.999L420.999 193.167L420.999 193.167L420.999 204L421 204L421 207.001C421 208.105 420.105 209 419.001 209C417.896 209 417.001 208.105 417.001 207.001L417.001 204.833L417 204.833L417 194L417 194L417 190.999Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect x="417" y="193.166" width="3.99896" height="11.6667" fill="#ABABAB" />
        <path
          d="M417 189.94C417 189.421 417.421 189 417.94 189L420.059 189C420.578 189 420.999 189.421 420.999 189.94L420.999 194L417 194L417 189.94Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M421 208.06C421 208.579 420.579 209 420.06 209L417.941 209C417.422 209 417.001 208.579 417.001 208.06L417.001 204L421 204L421 208.06Z"
          fill="url(#paint6_linear)"
        />
        <rect x="417" y="194.834" width="3.99896" height="8.33334" fill="url(#paint7_linear)" />
        <g filter="url(#3-2_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 104H230V216H417V104ZM406 115H241V205H406V115Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#3-2_filter3_i)">
          <rect
            x="241.47"
            y="115.47"
            width="164.06"
            height="89.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="235.5" y="109.5" width="176" height="101" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 104H230V105H416V216H417V104Z"
          fill="#FBFBFB"
        />
        <path d="M404.5 116.653L404.5 203.347L243.919 160L404.5 116.653Z" stroke="white" />
        <rect
          x="232"
          y="153"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#3-2_filter4_d)">
          <rect x="232.702" y="153.645" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M233.286 164.795C233.798 165.457 234.6 165.883 235.502 165.883C237.049 165.883 238.302 164.63 238.302 163.083V156.443C238.302 155.455 237.79 154.586 237.017 154.088C237.383 154.561 237.601 155.155 237.601 155.799V162.439C237.601 163.986 236.347 165.239 234.801 165.239C234.243 165.239 233.723 165.076 233.286 164.795Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M235.502 153.645C233.956 153.645 232.702 154.898 232.702 156.445V162.44C232.702 162.461 232.702 162.481 232.703 162.501V157.024C232.703 155.478 233.956 154.224 235.503 154.224C237.029 154.224 238.27 155.445 238.302 156.963V156.445C238.302 154.898 237.049 153.645 235.502 153.645Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-2_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M235.5 157C234.672 157 234 157.672 234 158.5V158.714V160V178.5C234 179.328 234.672 180 235.5 180V180C236.328 180 237 179.328 237 178.5V162V158.5L237 158.286C237 157.576 236.424 157 235.714 157V157C235.677 157 235.64 157.001 235.604 157.004C235.569 157.001 235.535 157 235.5 157Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="3-2_filter0_d"
            x="415.12"
            y="108.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-2_filter1_d"
            x="415.12"
            y="187.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-2_filter2_d"
            x="225.3"
            y="99.3003"
            width="198.279"
            height="123.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-2_filter3_i"
            x="241"
            y="115"
            width="165.94"
            height="92.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-2_filter4_d"
            x="230.822"
            y="151.765"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-2_filter5_d"
            x="232.12"
            y="155.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="414.2"
            y1="112.5"
            x2="421.8"
            y2="112.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="423.798"
            y1="127.5"
            x2="416.2"
            y2="127.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="414.202"
            y1="112.5"
            x2="421.8"
            y2="112.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="414.2"
            y1="206.5"
            x2="421.8"
            y2="206.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="423.798"
            y1="191.5"
            x2="416.2"
            y2="191.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="414.202"
            y1="206.5"
            x2="421.8"
            y2="206.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="423.798"
            y1="199.001"
            x2="416.2"
            y2="199.001"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
