import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement4Door1RCasement1 = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="451"
        height="451"
        viewBox="0 0 451 451"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#door-1-casement-1-4-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M419 382C419 380.895 419.895 380 421 380C422.105 380 423 380.895 423 382V396.56H419V382ZM419 396.56H423V398.4H423.06C423.579 398.4 424 398.821 424 399.34V401.12C424 402.158 423.158 403 422.12 403H420.88C419.842 403 419 402.158 419 401.12V398.4V398.4V396.56Z"
            fill="white"
          />
        </g>
        <rect x="419" y="397.25" width="3.99998" height="0.95833" fill="#ABABAB" />
        <path
          d="M419 380.94C419 380.421 419.421 380 419.94 380H422.06C422.579 380 423 380.421 423 380.94V397.25H419V380.94Z"
          fill="url(#door-1-casement-1-4-paint0_linear)"
        />
        <path
          d="M419 402.06C419 402.579 419.421 403 419.94 403H423.06C423.579 403 424 402.579 424 402.06V399.148C424 398.629 423.579 398.208 423.06 398.208H419V402.06Z"
          fill="url(#door-1-casement-1-4-paint1_linear)"
        />
        <g filter="url(#door-1-casement-1-4-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M422.954 50.9765C422.954 49.8849 422.069 49 420.978 49C419.886 49 419.001 49.8849 419.001 50.9765V53.1665H419.001V64H419V67.0235C419 68.1151 419.885 69 420.976 69C422.068 69 422.953 68.1151 422.953 67.0235V64.8332H422.954V54H422.954V50.9765Z"
            fill="url(#door-1-casement-1-4-paint2_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-1 0 0 1 422.954 53.1665)"
          fill="#ABABAB"
        />
        <path
          d="M422.954 49.9399C422.954 49.4208 422.533 49 422.014 49H419.941C419.422 49 419.001 49.4208 419.001 49.9399V54H422.954V49.9399Z"
          fill="url(#door-1-casement-1-4-paint3_linear)"
        />
        <path
          d="M419 68.0601C419 68.5792 419.421 69 419.94 69H422.013C422.532 69 422.953 68.5792 422.953 68.0601V64H419V68.0601Z"
          fill="url(#door-1-casement-1-4-paint4_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-1 0 0 1 422.954 54.8334)"
          fill="url(#door-1-casement-1-4-paint5_linear)"
        />
        <g filter="url(#door-1-casement-1-4-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M279 34H419V419H279V34ZM295 50H403V293H295V50ZM404 312H295V403H404V312Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M419 34H279V419H280V35H418V419H419V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="283.53"
          y="38.53"
          width="130.94"
          height="375.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="295.47"
          y="312.47"
          width="108.06"
          height="90.0601"
          fill="#E8EAEE"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#door-1-casement-1-4-filter3_i)">
          <rect
            x="295.469"
            y="50.47"
            width="107.06"
            height="242.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          width="6.91954"
          height="13.5283"
          rx="3.45977"
          transform="matrix(-1 0 0 1 290.919 209)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#door-1-casement-1-4-filter4_d)">
          <rect
            width="5.53563"
            height="12.2399"
            rx="2.76782"
            transform="matrix(-1 0 0 1 290.225 209.644)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M289.66 220.792C289.155 221.456 288.356 221.884 287.458 221.884C285.929 221.884 284.69 220.645 284.69 219.116V212.412C284.69 211.44 285.191 210.585 285.948 210.092C285.594 210.557 285.383 211.138 285.383 211.768V218.472C285.383 220.001 286.622 221.24 288.151 221.24C288.708 221.24 289.226 221.075 289.66 220.792Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M287.458 209.644C288.986 209.644 290.225 210.883 290.225 212.412V218.472C290.225 218.492 290.225 218.512 290.225 218.533V212.991C290.225 211.463 288.985 210.224 287.457 210.224C285.949 210.224 284.722 211.43 284.69 212.931V212.412C284.69 210.883 285.929 209.644 287.458 209.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#door-1-casement-1-4-filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M287.46 213C288.279 213 288.942 213.664 288.942 214.483V216C288.942 216.014 288.942 216.027 288.942 216.041L288.942 234.517C288.942 235.336 288.278 236 287.459 236C286.64 236 285.976 235.336 285.976 234.517L285.976 214.251C285.976 213.56 286.537 213 287.228 213C287.231 213 287.233 213 287.236 213C287.311 213 287.385 213 287.46 213Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M296.666 171.999L401.5 290.679V52.3295L296.666 171.999Z" stroke="white" />
        <path d="M401.378 291.5H296.622L349 53.3279L401.378 291.5Z" stroke="white" />
        <defs>
          <filter
            id="door-1-casement-1-4-filter0_d"
            x="417.12"
            y="378.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-1-4-filter1_d"
            x="417.12"
            y="47.1201"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-1-4-filter2_d"
            x="274.3"
            y="29.3003"
            width="151.279"
            height="396.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-1-4-filter3_i"
            x="294.999"
            y="50"
            width="108.94"
            height="245.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="door-1-casement-1-4-filter4_d"
            x="282.81"
            y="207.764"
            width="11.1753"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-1-4-filter5_d"
            x="284.097"
            y="211.12"
            width="8.60556"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="door-1-casement-1-4-paint0_linear"
            x1="425.8"
            y1="388.625"
            x2="418.2"
            y2="388.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-4-paint1_linear"
            x1="427.5"
            y1="400.604"
            x2="418"
            y2="400.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-4-paint2_linear"
            x1="425.722"
            y1="66.5"
            x2="418.209"
            y2="66.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-4-paint3_linear"
            x1="416.234"
            y1="51.5"
            x2="423.745"
            y2="51.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-4-paint4_linear"
            x1="425.72"
            y1="66.5"
            x2="418.209"
            y2="66.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-4-paint5_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
