import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement34Square3VerticalCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#3filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.9765 204C36.8849 204 36 204.885 36 205.976C36 207.068 36.8849 207.953 37.9765 207.953L40.1665 207.953L40.1665 207.953L51 207.953L51 207.954L54.0235 207.954C55.1151 207.954 56 207.069 56 205.978C56 204.886 55.1151 204.001 54.0235 204.001L51.8332 204.001L51.8332 204L41 204L41 204L37.9765 204Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 40.1665 204)"
          fill="#ABABAB"
        />
        <path
          d="M36.9399 204C36.4208 204 36 204.421 36 204.94L36 207.013C36 207.532 36.4208 207.953 36.9399 207.953L41 207.953L41 204L36.9399 204Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M55.0601 207.954C55.5792 207.954 56 207.533 56 207.014L56 204.941C56 204.422 55.5792 204.001 55.0601 204.001L51 204.001L51 207.954L55.0601 207.954Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 41.8334 204)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#3filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M165.976 204C164.885 204 164 204.885 164 205.976C164 207.068 164.885 207.953 165.976 207.953L168.167 207.953L168.167 207.953L179 207.953L179 207.954L182.024 207.954C183.115 207.954 184 207.069 184 205.978C184 204.886 183.115 204.001 182.024 204.001L179.833 204.001L179.833 204L169 204L169 204L165.976 204Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 168.167 204)"
          fill="#ABABAB"
        />
        <path
          d="M164.94 204C164.421 204 164 204.421 164 204.94L164 207.013C164 207.532 164.421 207.953 164.94 207.953L169 207.953L169 204L164.94 204Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M183.06 207.954C183.579 207.954 184 207.533 184 207.014L184 204.941C184 204.422 183.579 204.001 183.06 204.001L179 204.001L179 207.954L183.06 207.954Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 169.833 204)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#3filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 208H32V286H188V208ZM177 219H43V275H177V219Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 208H32V286H33V209H187V286H188V208Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3filter3_i)">
          <rect
            x="43.47"
            y="219.47"
            width="133.06"
            height="55.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="212.53"
          width="146.94"
          height="68.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M174.599 220.5L110 273.354L45.4007 220.5L174.599 220.5Z" stroke="white" />
        <rect
          x="116"
          y="277"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 116 277)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="115.356"
          y="277.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 115.356 277.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115.238 279.695C114.892 278.542 113.822 277.702 112.556 277.702L105.916 277.702C104.37 277.702 103.116 278.956 103.116 280.502C103.116 280.537 103.117 280.572 103.118 280.607C103.464 281.76 104.534 282.6 105.8 282.6L112.44 282.6C113.986 282.6 115.24 281.346 115.24 279.8C115.24 279.765 115.239 279.73 115.238 279.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.881 278.035C113.737 278.012 113.59 278 113.44 278L106.8 278C105.254 278 104 279.254 104 280.8C104 281.867 104.597 282.795 105.475 283.267C104.138 283.056 103.116 281.898 103.116 280.502C103.116 278.956 104.37 277.702 105.916 277.702L112.556 277.702C113.035 277.702 113.487 277.822 113.881 278.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M112 280.5C112 279.672 111.328 279 110.5 279L110.286 279L109 279L90.5 279C89.6716 279 89 279.672 89 280.5V280.5C89 281.328 89.6716 282 90.5 282L107 282L110.5 282L110.714 282C111.424 282 112 281.424 112 280.714V280.714C112 280.677 111.999 280.64 111.996 280.604C111.999 280.569 112 280.535 112 280.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="3filter0_d"
            x="34.1201"
            y="202.12"
            width="25.6396"
            height="9.59374"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3filter1_d"
            x="162.12"
            y="202.12"
            width="25.6396"
            height="9.59374"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3filter2_d"
            x="27.3003"
            y="203.3"
            width="167.279"
            height="89.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3filter3_i"
            x="43"
            y="219"
            width="134.94"
            height="58.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3filter4_d"
            x="87.28"
            y="277.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="53.5"
            y1="201.232"
            x2="53.5"
            y2="208.745"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="38.5"
            y1="210.72"
            x2="38.5"
            y2="203.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="53.5"
            y1="201.234"
            x2="53.5"
            y2="208.745"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="181.5"
            y1="201.232"
            x2="181.5"
            y2="208.745"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="166.5"
            y1="210.72"
            x2="166.5"
            y2="203.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="181.5"
            y1="201.234"
            x2="181.5"
            y2="208.745"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
