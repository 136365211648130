import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement13Square6Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#1-3_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M139.001 34C140.105 34 141 33.1048 141 32.0005C141 30.8962 140.105 30.001 139.001 30.001L136.833 30.001L136.833 30.001L126 30.001L126 30L122.999 30C121.895 30 121 30.8952 121 31.9995C121 33.1038 121.895 33.999 122.999 33.999L125.167 33.999L125.167 34L136 34L136 34L139.001 34Z"
            fill="url(#1-3_paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 136.834 34)"
          fill="#ABABAB"
        />
        <path
          d="M140.06 34C140.579 34 141 33.5792 141 33.0601L141 30.941C141 30.4219 140.579 30.001 140.06 30.001L136 30.001L136 34L140.06 34Z"
          fill="url(#1-3_paint1_linear)"
        />
        <path
          d="M121.94 30C121.421 30 121 30.4208 121 30.9399L121 33.059C121 33.5781 121.421 33.999 121.94 33.999L126 33.999L126 30L121.94 30Z"
          fill="url(#1-3_paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 135.167 34)"
          fill="url(#1-3_paint3_linear)"
        />
        <g filter="url(#1-3_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M61.0005 34C62.1048 34 63 33.1048 63 32.0005C63 30.8962 62.1048 30.001 61.0005 30.001L58.8335 30.001L58.8335 30.001L48 30.001L48 30L44.9995 30C43.8952 30 43 30.8952 43 31.9995C43 33.1038 43.8952 33.999 44.9995 33.999L47.1668 33.999L47.1668 34L58 34L58 34L61.0005 34Z"
            fill="url(#1-3_paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 58.834 34)"
          fill="#ABABAB"
        />
        <path
          d="M62.0601 34C62.5792 34 63 33.5792 63 33.0601L63 30.941C63 30.4219 62.5792 30.001 62.0601 30.001L58 30.001L58 34L62.0601 34Z"
          fill="url(#1-3_paint5_linear)"
        />
        <path
          d="M43.9399 30C43.4208 30 43 30.4208 43 30.9399L43 33.059C43 33.5781 43.4208 33.999 43.9399 33.999L48 33.999L48 30L43.9399 30Z"
          fill="url(#1-3_paint1-3_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 57.167 34)"
          fill="url(#1-3_paint7_linear)"
        />
        <g filter="url(#1-3_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M152 34H32V118H152V34ZM141 45H43V107H141V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M152 34H32V118H33V35H151V118H152V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="36.53"
          y="38.53"
          width="110.94"
          height="74.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#1-3_filter3_i)">
          <rect
            x="43.47"
            y="45.47"
            width="97.0601"
            height="61.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="99"
          y="109"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 99 109)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="98.3555"
          y="109.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 98.3555 109.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M98.2383 111.695C97.8919 110.542 96.8222 109.702 95.5563 109.702L88.9164 109.702C87.37 109.702 86.1164 110.955 86.1164 112.502C86.1164 112.537 86.117 112.572 86.1183 112.607C86.4647 113.76 87.5344 114.6 88.8003 114.6L95.4402 114.6C96.9866 114.6 98.2402 113.346 98.2402 111.8C98.2402 111.765 98.2396 111.73 98.2383 111.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M96.8809 110.034C96.7372 110.012 96.5899 110 96.4398 110L89.7999 110C88.2535 110 86.9999 111.253 86.9999 112.8C86.9999 113.867 87.5968 114.795 88.4749 115.267C87.1381 115.056 86.116 113.898 86.116 112.502C86.116 110.955 87.3696 109.702 88.916 109.702L95.5559 109.702C96.0352 109.702 96.4864 109.822 96.8809 110.034Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-3_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M95 112.5C95 111.672 94.3284 111 93.5 111L93.2857 111L92 111L73.5 111C72.6716 111 72 111.672 72 112.5C72 113.328 72.6716 114 73.5 114L90 114L93.5 114L93.7143 114C94.4244 114 95 113.424 95 112.714C95 112.677 94.9988 112.64 94.9965 112.604C94.9988 112.569 95 112.535 95 112.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M45.0403 46.5L138.96 46.5L92 105.2L45.0403 46.5Z" stroke="white" />
        <defs>
          <filter
            id="1-3_filter0_d"
            x="119.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-3_filter1_d"
            x="41.1201"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-3_filter2_d"
            x="27.3003"
            y="29.3003"
            width="131.279"
            height="95.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-3_filter3_i"
            x="43"
            y="45"
            width="98.9399"
            height="64.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="1-3_filter4_d"
            x="70.28"
            y="109.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="1-3_paint0_linear"
            x1="123.5"
            y1="36.8"
            x2="123.5"
            y2="29.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="1-3_paint1_linear"
            x1="138.5"
            y1="27.2018"
            x2="138.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="1-3_paint2_linear"
            x1="123.5"
            y1="36.7982"
            x2="123.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="1-3_paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="1-3_paint4_linear"
            x1="45.5"
            y1="36.8"
            x2="45.5"
            y2="29.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="1-3_paint5_linear"
            x1="60.5"
            y1="27.2018"
            x2="60.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="1-3_paint1-3_linear"
            x1="45.5"
            y1="36.7982"
            x2="45.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="1-3_paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
