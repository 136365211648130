import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement45Square4VerticalCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-5_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M191.954 236.976C191.954 235.885 191.069 235 189.977 235C188.886 235 188.001 235.885 188.001 236.976L188.001 239.167L188.001 239.167L188.001 250L188 250L188 253.024C188 254.115 188.885 255 189.976 255C191.068 255 191.953 254.115 191.953 253.024L191.953 250.833L191.954 250.833L191.954 240L191.954 240L191.954 236.976Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 191.954 239.167)"
          fill="#ABABAB"
        />
        <path
          d="M191.954 235.94C191.954 235.421 191.533 235 191.014 235L188.941 235C188.422 235 188.001 235.421 188.001 235.94L188.001 240L191.954 240L191.954 235.94Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M188 254.06C188 254.579 188.421 255 188.94 255L191.013 255C191.532 255 191.953 254.579 191.953 254.06L191.953 250L188 250L188 254.06Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 191.954 240.833)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#4-5_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 261C188 259.895 188.895 259 190 259C191.105 259 192 259.895 192 261L192 275.56L188 275.56L188 261ZM188 275.56L192 275.56L192 277.4L192.06 277.4C192.579 277.4 193 277.821 193 278.34L193 280.12C193 281.158 192.158 282 191.12 282L189.88 282C188.842 282 188 281.158 188 280.12L188 277.4L188 277.4L188 275.56Z"
            fill="white"
          />
        </g>
        <rect x="188" y="276.25" width="3.99998" height="0.95833" fill="#ABABAB" />
        <path
          d="M188 259.94C188 259.421 188.421 259 188.94 259L191.06 259C191.579 259 192 259.421 192 259.94L192 276.25L188 276.25L188 259.94Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M188 281.06C188 281.579 188.421 282 188.94 282L192.06 282C192.579 282 193 281.579 193 281.06L193 278.148C193 277.629 192.579 277.208 192.06 277.208L188 277.208L188 281.06Z"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#4-5_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 231H32V286H188V231ZM178 241H42V276H178V241Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 231H32V286H33V232H187V286H188V231Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-5_filter3_i)">
          <rect
            x="42.47"
            y="241.47"
            width="135.06"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="235.53"
          width="146.94"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M176.5 242.565L47.0913 258.5L176.5 274.435L176.5 242.565Z" stroke="white" />
        <rect
          width="6.91954"
          height="13.5283"
          rx="3.45977"
          transform="matrix(-1 0 0 1 40.9196 252)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#4-5_filter4_d)">
          <rect
            width="5.53563"
            height="12.2399"
            rx="2.76782"
            transform="matrix(-1 0 0 1 40.2255 252.644)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.6605 263.792C39.1549 264.456 38.3564 264.884 37.4578 264.884C35.9292 264.884 34.69 263.645 34.69 262.116V255.412C34.69 254.44 35.1908 253.585 35.9483 253.092C35.5937 253.557 35.3831 254.138 35.3831 254.768V261.472C35.3831 263.001 36.6223 264.24 38.151 264.24C38.7079 264.24 39.2264 264.075 39.6605 263.792Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.4576 252.644C38.9863 252.644 40.2255 253.883 40.2255 255.412V261.472C40.2255 261.492 40.2252 261.512 40.2248 261.533V255.991C40.2248 254.463 38.9856 253.224 37.457 253.224C35.9486 253.224 34.7221 254.43 34.6898 255.931V255.412C34.6898 253.883 35.929 252.644 37.4576 252.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-5_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.4599 256C38.2788 256 38.9426 256.664 38.9426 257.483V259C38.9426 259.014 38.9425 259.027 38.9422 259.041L38.9422 277.517C38.9422 278.336 38.2784 279 37.4595 279V279C36.6406 279 35.9767 278.336 35.9767 277.517L35.9767 257.251C35.9767 256.56 36.5369 256 37.228 256V256C37.2308 256 37.2336 256 37.2364 256C37.3109 256 37.3854 256 37.4599 256V256Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M45.1468 274.5L110 242.557L174.853 274.5H45.1468Z" stroke="white" />
        <defs>
          <filter
            id="4-5_filter0_d"
            x="186.12"
            y="233.12"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-5_filter1_d"
            x="186.12"
            y="257.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-5_filter2_d"
            x="27.3003"
            y="226.3"
            width="167.279"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-5_filter3_i"
            x="42"
            y="241"
            width="136.94"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-5_filter4_d"
            x="32.8099"
            y="250.764"
            width="11.1753"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-5_filter5_d"
            x="34.0968"
            y="254.12"
            width="8.60556"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="194.722"
            y1="252.5"
            x2="187.209"
            y2="252.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="185.234"
            y1="237.5"
            x2="192.745"
            y2="237.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="194.72"
            y1="252.5"
            x2="187.209"
            y2="252.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="194.8"
            y1="267.625"
            x2="187.2"
            y2="267.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="196.5"
            y1="279.604"
            x2="187"
            y2="279.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
