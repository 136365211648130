import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement22Square4Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="315"
        height="333"
        viewBox="0 0 315 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#29filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188.001 34C189.105 34 190 33.1048 190 32.0005C190 30.8962 189.105 30.001 188.001 30.001L185.833 30.001L185.833 30.001L175 30.001L175 30L171.999 30C170.895 30 170 30.8952 170 31.9995C170 33.1038 170.895 33.999 171.999 33.999L174.167 33.999L174.167 34L185 34L185 34L188.001 34Z"
            fill="url(#29paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 185.833 34)"
          fill="#ABABAB"
        />
        <path
          d="M189.06 34C189.579 34 190 33.5792 190 33.0601L190 30.941C190 30.4219 189.579 30.001 189.06 30.001L185 30.001L185 34L189.06 34Z"
          fill="url(#29paint1_linear)"
        />
        <path
          d="M170.94 30C170.421 30 170 30.4208 170 30.9399L170 33.059C170 33.5781 170.421 33.999 170.94 33.999L175 33.999L175 30L170.94 30Z"
          fill="url(#29paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 184.167 34)"
          fill="url(#29paint3_linear)"
        />
        <g filter="url(#29filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M274.001 34C275.105 34 276 33.1048 276 32.0005C276 30.8962 275.105 30.001 274.001 30.001L271.833 30.001L271.833 30.001L261 30.001L261 30L257.999 30C256.895 30 256 30.8952 256 31.9995C256 33.1038 256.895 33.999 257.999 33.999L260.167 33.999L260.167 34L271 34L271 34L274.001 34Z"
            fill="url(#29paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 271.833 34)"
          fill="#ABABAB"
        />
        <path
          d="M275.06 34C275.579 34 276 33.5792 276 33.0601L276 30.941C276 30.4219 275.579 30.001 275.06 30.001L271 30.001L271 34L275.06 34Z"
          fill="url(#29paint5_linear)"
        />
        <path
          d="M256.94 30C256.421 30 256 30.4208 256 30.9399L256 33.059C256 33.5781 256.421 33.999 256.94 33.999L261 33.999L261 30L256.94 30Z"
          fill="url(#29paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 270.167 34)"
          fill="url(#29paint7_linear)"
        />
        <g filter="url(#29filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M280 34H165V105H280V34ZM269 45H176V94H269V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M280 34H165V105H166V35H279V105H280V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="169.487"
          y="38.53"
          width="106.026"
          height="61.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#29filter3_i)">
          <rect
            x="176.47"
            y="45.47"
            width="92.0601"
            height="48.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="229"
          y="96"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 229 96)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#29filter4_d)">
          <rect
            x="228.356"
            y="96.7019"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 228.356 96.7019)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M228.238 98.6951C227.892 97.542 226.822 96.7019 225.556 96.7019L218.916 96.7019C217.37 96.7019 216.116 97.9555 216.116 99.5019C216.116 99.5371 216.117 99.5721 216.118 99.6069C216.464 100.76 217.534 101.6 218.8 101.6L225.44 101.6C226.986 101.6 228.24 100.346 228.24 98.8001C228.24 98.7649 228.239 98.7299 228.238 98.6951Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M226.881 97.0346C226.737 97.0119 226.59 97.0001 226.44 97.0001L219.8 97.0001C218.254 97.0001 217 98.2537 217 99.8001C217 100.867 217.597 101.795 218.475 102.267C217.138 102.056 216.116 100.898 216.116 99.5019C216.116 97.9555 217.37 96.7019 218.916 96.7019L225.556 96.7019C226.035 96.7019 226.487 96.8224 226.881 97.0346Z"
          fill="#FBFBFB"
        />
        <g filter="url(#29filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M225 99.5C225 98.6716 224.328 98 223.5 98L223.286 98L222 98L203.5 98C202.672 98 202 98.6716 202 99.5C202 100.328 202.672 101 203.5 101L220 101L223.5 101L223.714 101C224.424 101 225 100.424 225 99.7143C225 99.6771 224.999 99.6401 224.996 99.6035C224.999 99.5693 225 99.5348 225 99.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M178.18 46.5L266.82 46.5L222.5 92.2811L178.18 46.5Z" stroke="white" />
        <defs>
          <filter
            id="29filter0_d"
            x="168.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="29filter1_d"
            x="254.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="29filter2_d"
            x="160.3"
            y="29.3003"
            width="126.279"
            height="82.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="29filter3_i"
            x="176"
            y="45"
            width="93.9399"
            height="51.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="29filter4_d"
            x="214.236"
            y="94.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="29filter5_d"
            x="200.12"
            y="96.1201"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="29paint0_linear"
            x1="172.5"
            y1="36.8"
            x2="172.5"
            y2="29.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="29paint1_linear"
            x1="187.5"
            y1="27.2018"
            x2="187.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="29paint2_linear"
            x1="172.5"
            y1="36.7982"
            x2="172.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="29paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="29paint4_linear"
            x1="258.5"
            y1="36.8"
            x2="258.5"
            y2="29.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="29paint5_linear"
            x1="273.5"
            y1="27.2018"
            x2="273.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="29paint6_linear"
            x1="258.5"
            y1="36.7982"
            x2="258.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="29paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
