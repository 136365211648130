import React from 'react'
import { StageDescriptionBlockContentItemIcon } from 'src/react-app/views/calculator/styles'

export const Soundproofing1 = () => {
  return (
    <StageDescriptionBlockContentItemIcon title="Для окон выходящих в тихий двор">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32.5 40H7.5C3.3575 40 0 36.6425 0 32.5V7.5C0 3.3575 3.3575 0 7.5 0H32.5C36.6425 0 40 3.3575 40 7.5V32.5C40 36.6425 36.6425 40 32.5 40Z"
          fill="#ECEEF2"
        />
        <path
          d="M29.1663 28.3338H28.3325C28.3325 23.9837 28.3325 24.6175 28.3325 18.9588C28.3325 18.1538 27.68 17.5 26.8738 17.5H22.5V28.3338H21.6663V10.625C21.6663 10.1938 21.2388 9.89127 20.8313 10.0363L12.6338 12.9638C12.0538 13.1713 11.6663 13.7213 11.6663 14.3375C11.6663 20.802 11.6663 21.0297 11.6663 28.3338H10.8337C10.3725 28.3338 10 28.7063 10 29.1675C10 29.6288 10.3725 30 10.8337 30H29.1675C29.6275 30 30 29.6275 30 29.1675C30 28.7075 29.6275 28.3338 29.1663 28.3338Z"
          fill="#1C3775"
        />
      </svg>
    </StageDescriptionBlockContentItemIcon>
  )
}
