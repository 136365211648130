import React from 'react'

export const ColorBucketImage = ({ color = '#1c3775' }) => {
  return (
    <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.39023 15.5512C8.01773 15.5512 8.64524 15.3125 9.12274 14.835L15.4127 8.54499C15.7377 8.21999 15.7377 7.69249 15.4127 7.36624L8.55023 0.504981C8.22523 0.17998 7.69773 0.17998 7.37148 0.504981L1.08148 6.79499C0.126484 7.74999 0.126484 9.30499 1.08148 10.26L5.65648 14.835C6.13523 15.3125 6.76273 15.5512 7.39023 15.5512ZM7.96148 2.27248L13.644 7.95499L13.5477 8.05124H2.20898C2.22898
        8.02624 2.23773 7.99499 2.26148 7.97249L7.96148 2.27248Z"
        fill={color}
      />
      <path
        d="M13.9062 13.399C13.9062 14.5865 14.8412 15.5515 15.99 15.5515C17.1387 15.5515 18.0737 14.5852 18.0737 13.399C18.0737 12.4428 16.8787 10.5553 16.5125 10.0003C16.2812 9.649 15.7 9.649 15.4688 10.0003C15.1013 10.5553 13.9062 12.4428 13.9062 13.399Z"
        fill={color}
      />
    </svg>
  )
}
