import React, { FC, useMemo, useCallback } from 'react'

import { IValueViewProps } from '../../../interface'
import { ValueViewWrapper, ValueViewItem } from '../../../styles'

export const ValueView: FC<IValueViewProps> = props => {
  const { view, value, totalCount = 5 } = props

  const countList = useMemo(() => {
    return new Array(totalCount).fill(true)
  }, [totalCount])

  const isActive = useCallback(
    index => {
      return index < value
    },
    [value],
  )

  const activeColor = useMemo(() => {
    return view && view === 'current' ? '#9e2a2b' : '#d69a2d'
  }, [view])

  return (
    <ValueViewWrapper>
      {countList.map((i, index) => (
        <ValueViewItem
          key={`value-view-item-${index}`}
          color={activeColor}
          active={isActive(index)}
        />
      ))}
    </ValueViewWrapper>
  )
}
