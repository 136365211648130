import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement41Square5VerticalCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-1_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M50.0005 248C51.1048 248 52 247.105 52 246.001C52 244.896 51.1048 244.001 50.0005 244.001L47.8335 244.001L47.8335 244.001L37 244.001L37 244L33.9995 244C32.8952 244 32 244.895 32 245.999C32 247.104 32.8952 247.999 33.9995 247.999L36.1668 247.999L36.1668 248L47 248L47 248L50.0005 248Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 47.8335 248)"
          fill="#ABABAB"
        />
        <path
          d="M51.0601 248C51.5792 248 52 247.579 52 247.06L52 244.941C52 244.422 51.5792 244.001 51.0601 244.001L47 244.001L47 248L51.0601 248Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M32.9399 244C32.4208 244 32 244.421 32 244.94L32 247.059C32 247.578 32.4208 247.999 32.9399 247.999L37 247.999L37 244L32.9399 244Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 46.1666 248)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#4-1_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M186.001 248C187.105 248 188 247.105 188 246.001C188 244.896 187.105 244.001 186.001 244.001L183.833 244.001L183.833 244.001L173 244.001L173 244L169.999 244C168.895 244 168 244.895 168 245.999C168 247.104 168.895 247.999 169.999 247.999L172.167 247.999L172.167 248L183 248L183 248L186.001 248Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 183.833 248)"
          fill="#ABABAB"
        />
        <path
          d="M187.06 248C187.579 248 188 247.579 188 247.06L188 244.941C188 244.422 187.579 244.001 187.06 244.001L183 244.001L183 248L187.06 248Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M168.94 244C168.421 244 168 244.421 168 244.94L168 247.059C168 247.578 168.421 247.999 168.94 247.999L173 247.999L173 244L168.94 244Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 182.167 248)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#4-1_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M192 198H28V244H192V198ZM183 207H37V235H183V207Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M192 198H28V244H29V199H191V244H192V198Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-1_filter3_i)">
          <rect
            x="37.47"
            y="207.47"
            width="145.06"
            height="27.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="32.53"
          y="202.53"
          width="154.94"
          height="36.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M40.8567 233.5L110 208.532L179.143 233.5H40.8567Z" stroke="white" />
        <rect
          x="117.528"
          y="199"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 117.528 199)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#4-1_filter4_d)">
          <rect
            x="116.884"
            y="199.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 116.884 199.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M116.766 201.695C116.42 200.542 115.35 199.702 114.084 199.702L107.444 199.702C105.898 199.702 104.644 200.955 104.644 202.502C104.644 202.537 104.645 202.572 104.646 202.607C104.993 203.76 106.062 204.6 107.328 204.6L113.968 204.6C115.515 204.6 116.768 203.346 116.768 201.8C116.768 201.765 116.768 201.73 116.766 201.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115.409 200.034C115.266 200.012 115.118 200 114.968 200L108.328 200C106.782 200 105.528 201.253 105.528 202.8C105.528 203.867 106.125 204.795 107.003 205.267C105.666 205.056 104.644 203.898 104.644 202.502C104.644 200.955 105.898 199.702 107.444 199.702L114.084 199.702C114.564 199.702 115.015 199.822 115.409 200.034Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-1_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M130.528 202.5C130.528 201.672 129.857 201 129.028 201L128.814 201L127.528 201L109.028 201C108.2 201 107.528 201.672 107.528 202.5V202.5C107.528 203.328 108.2 204 109.028 204L125.528 204L129.028 204L129.243 204C129.953 204 130.528 203.424 130.528 202.714V202.714C130.528 202.677 130.527 202.64 130.525 202.604C130.527 202.569 130.528 202.535 130.528 202.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="4-1_filter0_d"
            x="30.1201"
            y="242.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-1_filter1_d"
            x="166.12"
            y="242.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-1_filter2_d"
            x="23.3003"
            y="193.3"
            width="175.279"
            height="57.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-1_filter3_i"
            x="37"
            y="207"
            width="146.94"
            height="30.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-1_filter4_d"
            x="102.764"
            y="197.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-1_filter5_d"
            x="106.649"
            y="199.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="34.5"
            y1="250.8"
            x2="34.5"
            y2="243.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="49.5"
            y1="241.202"
            x2="49.5"
            y2="248.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="34.5"
            y1="250.798"
            x2="34.5"
            y2="243.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="170.5"
            y1="250.8"
            x2="170.5"
            y2="243.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="185.5"
            y1="241.202"
            x2="185.5"
            y2="248.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="170.5"
            y1="250.798"
            x2="170.5"
            y2="243.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
