import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement82Square3Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#8-2_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M164 217.999C164 216.895 163.105 216 162.001 216C160.896 216 160.001 216.895 160.001 217.999V220.167H160.001V231H160V234.001C160 235.105 160.895 236 161.999 236C163.104 236 163.999 235.105 163.999 234.001V231.833H164V221H164V217.999Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 164 220.166)"
          fill="#ABABAB"
        />
        <path
          d="M164 216.94C164 216.421 163.579 216 163.06 216H160.941C160.422 216 160.001 216.421 160.001 216.94V221H164V216.94Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M160 235.06C160 235.579 160.421 236 160.94 236H163.059C163.578 236 163.999 235.579 163.999 235.06V231H160V235.06Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 164 221.833)"
          fill="url(#paint8-2_linear)"
        />
        <g filter="url(#8-2_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M164 261C164 259.895 163.105 259 162 259C160.895 259 160 259.895 160 261V275.56H164V261ZM164 275.56H160V277.4H159.94C159.421 277.4 159 277.821 159 278.34V280.12C159 281.158 159.842 282 160.88 282H162.12C163.158 282 164 281.158 164 280.12V277.4V277.4V275.56Z"
            fill="white"
          />
        </g>
        <rect
          width="3.99998"
          height="0.95833"
          transform="matrix(-1 0 0 1 164 276.25)"
          fill="#ABABAB"
        />
        <path
          d="M164 259.94C164 259.421 163.579 259 163.06 259H160.94C160.421 259 160 259.421 160 259.94V276.25H164V259.94Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M164 281.06C164 281.579 163.579 282 163.06 282H159.94C159.421 282 159 281.579 159 281.06V278.148C159 277.629 159.421 277.208 159.94 277.208H164V281.06Z"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#8-2_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M284 210H164V287H284V210ZM273 221H175V276H273V221Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#8-2_filter8-2_i)">
          <rect
            x="175.47"
            y="221.47"
            width="97.0601"
            height="54.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="169.5" y="215.5" width="109" height="66" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M284 210H164V287H165V211H283V287H284V210Z"
          fill="#FBFBFB"
        />
        <path d="M176.5 274.343L176.5 222.657L270.121 248.5L176.5 274.343Z" stroke="white" />
        <path d="M270.873 274.5H177.127L224 222.745L270.873 274.5Z" stroke="white" />
        <rect
          x="275"
          y="241"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#8-2_filter4_d)">
          <rect x="275.702" y="241.645" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M276.286 252.796C276.798 253.458 277.6 253.884 278.502 253.884C280.049 253.884 281.302 252.631 281.302 251.084V244.444C281.302 243.456 280.79 242.587 280.017 242.089C280.383 242.562 280.601 243.156 280.601 243.8V250.44C280.601 251.987 279.347 253.24 277.801 253.24C277.243 253.24 276.723 253.077 276.286 252.796Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M278.502 241.645C276.956 241.645 275.702 242.898 275.702 244.445V250.44C275.702 250.461 275.702 250.481 275.703 250.501V245.024C275.703 243.478 276.956 242.224 278.503 242.224C280.029 242.224 281.27 243.445 281.302 244.963V244.445C281.302 242.898 280.049 241.645 278.502 241.645Z"
          fill="#FBFBFB"
        />
        <g filter="url(#8-2_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M278.5 245C277.672 245 277 245.672 277 246.5V246.714V248V266.5C277 267.328 277.672 268 278.5 268C279.328 268 280 267.328 280 266.5V250V246.5L280 246.286C280 245.576 279.424 245 278.714 245C278.677 245 278.64 245.001 278.604 245.004C278.569 245.001 278.535 245 278.5 245Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="8-2_filter0_d"
            x="158.12"
            y="214.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8-2_filter1_d"
            x="157.12"
            y="257.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8-2_filter2_d"
            x="159.3"
            y="205.3"
            width="131.279"
            height="88.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8-2_filter8-2_i"
            x="175"
            y="221"
            width="98.9399"
            height="57.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="8-2_filter4_d"
            x="273.822"
            y="239.765"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8-2_filter5_d"
            x="275.12"
            y="243.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="166.8"
            y1="233.5"
            x2="159.2"
            y2="233.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="157.202"
            y1="218.5"
            x2="164.8"
            y2="218.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="166.798"
            y1="233.5"
            x2="159.2"
            y2="233.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint8-2_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="157.2"
            y1="267.625"
            x2="164.8"
            y2="267.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="155.5"
            y1="279.604"
            x2="165"
            y2="279.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
