import React, {
  createContext,
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { v4 as uuidv4 } from 'uuid'
import { useModal } from 'react-modal-hook'
import { useHistory, Link } from 'react-router-dom'

import { IStageCalculator } from '../interface'
import {
  CalculatorWrapper,
  CalculatorEmptyWrapper,
  CalculatorEmptyTitle,
  CalculatorEmptyDescription,
} from './styles'
import { Menu } from './menu'
import { MainView } from './main-view'
import { AdditionalBlock } from './additional-block'
import { IItemSize, IProductContext } from './interface'
import { usePrice } from '../../../hooks/use-price'
import { CloseButton, ModalBody, ModalDescription, ModalTitle, ModalWrapper } from '../styles'
import { CloseIcon } from '../../../assets/icons'
import { RenderSketch } from './components'
import { stepByStepHelperData } from '../../../components'
import { crossCheckSizeAccept, parseConfigurationSize } from '../../../utilits'
import { MainContext } from '../calculator'
import { CalcIcon } from '../../../assets/icons/calc'
import {getLocalStorageItem, setLocalStorageItem} from "../../../services/local-storage";

export const ProductsContext = createContext<IProductContext>({
  type: null,
  menuItems: [],
  menuItemsData: {},
  filters: [],
  setFilters: () => [],
  setItemSize: () => null,
  setSelectedSubAttributeData: () => null,
  onChangeStepByStepHelper: () => null,
  product: {},
  selectedSubAttributeData: null,
  stepByStepHelper: 'configuration-type',
  totalPriceList: [],
  calculateTotalPrice: () => null,
  calculateAdditionalElementPrice: () => 0,
  itemSize: {},
  checkedMenu: [],
  setCheckedMenu: () => null,
  wallColor: 'transparent',
  setWallColor: () => null,
})

export const StageCalculator: FC<IStageCalculator> = props => {
  const {
    type,
    filters,
    setFilters,
    onChangeStage,
    onNewCalculate,
    currentOrder,
    menuItems,
    menuItemsData,
    specification,
    defaultData,
    onDropSpecification,
  } = props

  const { initSize, isDraftEdit, resetCurrentOrder, onDropDraft } = useContext(MainContext)
  const history = useHistory()
  const [product, setProduct] = useState<Record<string, any>>({})
  const [itemConfiguration, setItemConfiguration] = useState<Record<string, any>>({})
  const [itemSize, setItemSize] = useState<Record<string, number | number[]>>({})
  const [casementsErrors, setCasementsErrors] = useState<
    Record<string, { title: string; description: string }[]>
  >({})
  const [haveNonStandard, setHaveNonStandard] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [previewImages, setPreviewImages] = useState<ReactElement[]>([])
  const [productImageSize, setProductImageSize] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  })
  const [selectedSubAttributeData, setSelectedSubAttributeData] = useState<null | Record<
    string,
    any
  >>(null)
  const [currentStepByStepHelper, setCurrentStepByStepHelper] = useState<string>(
    'configuration-type',
  )
  const [checkedStepByStepHelper, setCheckedStepByStepHelper] = useState<string[]>([
    'configuration-type',
  ])
  const [checkedMenu, setCheckedMenu] = useState<string[]>([])
  const [wallColor, setWallColor] = useState('transparent')
  const [isConfigurationEdit, setIsConfigurationEdit] = useState(true)
  const [needUpdateItemSize, setNeedUpdateItemSize] = useState(false)
  const {
    inProcess,
    totalPriceList,
    calculateTotalPrice,
    calculateAdditionalElementPrice,
  } = usePrice()

  const [showModal, hideModal] = useModal(({ in: open }) => (
    <ModalWrapper show={open}>
      <ModalBody>
        <CloseButton onClick={hideModal}>
          <CloseIcon />
        </CloseButton>
        <div>
          <ModalTitle>Обратите внимание!</ModalTitle>
          <ModalDescription>Нестандартное изделие</ModalDescription>
        </div>
      </ModalBody>
    </ModalWrapper>
  ))

  useEffect(() => {
    if (initSize) {
      setItemSize(initSize)
      setIsConfigurationEdit(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (Object.keys(product).length === 0 && currentOrder) {
      if (currentOrder) {
        setProduct(currentOrder['product'])
        setFilters(currentOrder['filters'])
        setItemSize(currentOrder['sizes'])
        setIsEdit(true)
        setIsConfigurationEdit(false)
        resetCurrentOrder()
      }
    } else if (specification) {
      if (
        needUpdateItemSize &&
        specification['configuration'] &&
        specification['configuration']['sizes']
      ) {
        const productSizes = parseConfigurationSize(specification['configuration']['sizes'])

        if (productSizes) {
          setItemSize(productSizes)
          setNeedUpdateItemSize(false)
        }
      }

      setProduct(specification)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrder, resetCurrentOrder, specification])

  useEffect(() => {
    const checkNonStandardProduct = () => {
      let result = false

      if ('configuration-standard' in product && product['configuration-standard'] === 'false') {
        result = true
      }

      setHaveNonStandard(result)
    }

    if (product) {
      checkNonStandardProduct()
    }
  }, [product])

  useEffect(() => {
    if (haveNonStandard) {
      showModal()
    }
  }, [haveNonStandard, showModal])

  useEffect(() => {
    if (
      product &&
      itemSize['model-width'] > 0 &&
      itemSize['model-height'] > 0 &&
      itemSize['casements-x'] &&
      itemSize['casements-y']
    ) {
      calculateTotalPrice(type, [product], [itemSize], [filters])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemSize, product, type, filters])

  const handleSetItemConfiguration = useCallback((config: { key: string; value: any }) => {
    setItemConfiguration(prevState => ({
      ...prevState,
      [config.key]: config.value,
    }))
  }, [])
  const handleSetItemSize = useCallback(
    (size: IItemSize[]) => {
      const newSizes = {}

      size.forEach(({ key, value }) => {
        newSizes[key] = value
      })
      const errors = crossCheckSizeAccept({ ...itemSize, ...newSizes }, product, filters)

      setItemSize(prevState => ({
        ...prevState,
        ...newSizes,
      }))
      setCasementsErrors(errors)
    },
    [filters, itemSize, product],
  )

  useEffect(() => {
    if (product && filters && filters.length > 0 && itemSize) {
      setLocalStorageItem(
        'calculationDrafts',
        JSON.stringify([{ product, filters, sizes: itemSize, type }])
      )
    }
  }, [filters, itemSize, product, type])

  const handleAddToCart = useCallback(async () => {
    if (product) {
      const productPreview = (
        <RenderSketch images={previewImages} sketchSizes={itemSize} imageSizes={productImageSize} />
      )
      const cartItems = JSON.parse(await getLocalStorageItem('cartItems') || '[]')
      const calculationDrafts = JSON.parse(await getLocalStorageItem('calculationDrafts') || '[]')
      const calculationsCount = calculationDrafts.length - 1
      const productId = uuidv4()
      const preview = renderToStaticMarkup(productPreview)

      cartItems.push({
        id: productId,
        product: {
          ...product,
          id: productId,
        },
        filters,
        sizes: itemSize,
        preview,
        type,
      })

      await setLocalStorageItem('cartItems', JSON.stringify(cartItems))
      await setLocalStorageItem('cartItemsCount', JSON.stringify(cartItems.length))
      await setLocalStorageItem('calculationsCount', JSON.stringify(calculationsCount))
      await setLocalStorageItem('calculationDrafts', JSON.stringify([]))

      onChangeStage(product, filters, itemSize, productId, preview)
      setItemSize({})
      onDropSpecification()
      history.push('/personal/basket')
    }
  }, [
    filters,
    history,
    itemSize,
    onChangeStage,
    onDropSpecification,
    previewImages,
    product,
    productImageSize,
    type,
  ])

  const handleGetPreviewImages = useCallback(images => {
    setPreviewImages(images)
  }, [])

  const handleSetImageSize = useCallback(imageSize => {
    setProductImageSize(imageSize)
  }, [])

  const totalPrice = useMemo(() => (totalPriceList[0] && totalPriceList[0]['total']) || 0, [
    totalPriceList,
  ])

  const additionalPrice = useMemo(
    () => (totalPriceList[0] && totalPriceList[0]['additionalPrice']) || {},
    [totalPriceList],
  )

  const handleChangeStepByStep = useCallback(() => {
    const index = stepByStepHelperData[currentStepByStepHelper]['value']
    const stepsCount = Object.keys(stepByStepHelperData).length
    const getNext = (current: string, currentIndex) => {
      const nextKey = Object.keys(stepByStepHelperData).find(
        key => stepByStepHelperData[key]['value'] === currentIndex + 1,
      )
      let result = ''

      if (nextKey && !checkedStepByStepHelper.includes(nextKey) && !checkedMenu.includes(nextKey)) {
        result = nextKey
      } else if (index < stepsCount) {
        result = getNext(current, currentIndex + 1)
      }

      return result
    }
    const next = getNext(currentStepByStepHelper, index)

    setCurrentStepByStepHelper(next)
    setCheckedStepByStepHelper(prevState => {
      return [...prevState, next]
    })
  }, [checkedMenu, checkedStepByStepHelper, currentStepByStepHelper])

  const handleSetCheckedMenu = useCallback(
    (key: string) => {
      const keyIndex = checkedMenu.findIndex(menuKey => menuKey === key)

      if (keyIndex === -1) {
        setCheckedMenu(prevState => [...prevState, key])
      }
    },
    [checkedMenu],
  )

  const isCanSubmit = useMemo(() => {
    let result = !casementsErrors['casements-x'] && !casementsErrors['casements-y']

    if (
      casementsErrors['casements-x'] &&
      casementsErrors['casements-x'].length === 0 &&
      casementsErrors['casements-y'] &&
      casementsErrors['casements-y'].length === 0
    ) {
      result = true
    }

    return result
  }, [casementsErrors])

  const isNoDraft = useMemo(() => {
    return isDraftEdit && Object.keys(defaultData).length === 0
  }, [defaultData, isDraftEdit])

  const isCanShow = useMemo(() => {
    return (isDraftEdit && Object.keys(defaultData).length > 0) || !isDraftEdit
  }, [defaultData, isDraftEdit])

  const handleBackToQuiz = useCallback(() => {
    onNewCalculate()
    onDropDraft()
  }, [onDropDraft, onNewCalculate])

  return (
    <ProductsContext.Provider
      value={{
        type,
        product,
        itemSize,
        filters,
        setFilters,
        setItemSize,
        menuItems,
        menuItemsData,
        selectedSubAttributeData,
        setSelectedSubAttributeData,
        stepByStepHelper: currentStepByStepHelper,
        onChangeStepByStepHelper: handleChangeStepByStep,
        totalPriceList,
        calculateTotalPrice,
        calculateAdditionalElementPrice,
        checkedMenu,
        setCheckedMenu: handleSetCheckedMenu,
        wallColor,
        setWallColor,
      }}
    >
      <CalculatorWrapper isNoDraft={isNoDraft}>
        {isNoDraft && (
          <CalculatorEmptyWrapper>
            <CalculatorEmptyTitle>Расчетов пока нет.</CalculatorEmptyTitle>
            <CalculatorEmptyDescription>
              Перейдите в{' '}
              <Link to="/podbor" onClick={handleBackToQuiz}>
                <CalcIcon /> Подбор
              </Link>{' '}
              для создания заказа
            </CalculatorEmptyDescription>
          </CalculatorEmptyWrapper>
        )}
        {isCanShow && (
          <>
            <Menu
              defaultData={defaultData}
              product={product}
              productType={type}
              onSetItemSize={handleSetItemSize}
              elementsPriceList={{}}
              setFilters={setFilters}
              setIsConfigurationEdit={setIsConfigurationEdit}
              setNeedUpdateItemSize={setNeedUpdateItemSize}
            />
            <MainView
              itemConfiguration={itemConfiguration}
              onSetItemSize={handleSetItemSize}
              product={product}
              productType={type}
              onGetPreviewImages={handleGetPreviewImages}
              onSetImageSize={handleSetImageSize}
              inProcess={inProcess}
              filters={filters}
              isConfigurationEdit={isConfigurationEdit}
              setIsConfigurationEdit={setIsConfigurationEdit}
              casementsErrors={casementsErrors}
              setCasementsErrors={setCasementsErrors}
              initSize={initSize}
            />
            <AdditionalBlock
              itemConfiguration={itemConfiguration}
              onSetItemConfiguration={handleSetItemConfiguration}
              product={product}
              onSubmit={handleAddToCart}
              onSetItemSize={handleSetItemSize}
              price={totalPrice}
              additionalPrice={additionalPrice}
              inProcess={inProcess}
              isEdit={isEdit}
              isCanSubmit={isCanSubmit}
            />
          </>
        )}
      </CalculatorWrapper>
    </ProductsContext.Provider>
  )
}
