import { css } from 'styled-components'
import theme from './theme'

export default css`
  :root {
    font-size: ${theme.fonts.size};
  }
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
  //html,
  //body,
  #app {
    height: 100%;
  }
  #app {
    transition: filter 0.3s;

    &.modal-open {
      filter: blur(3px);
    }
  }

  // body {
  //   color: ${theme.colors.black};
  //   font-family: ${theme.fonts.main};
  //   font-weight: 400;
  //   line-height: 20px;
  //
  //   background-color: ${theme.colors.grayForBody};
  //
  //   padding: 0;
  //   margin: 0;
  //
  //   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  //     'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  //   -webkit-font-smoothing: antialiased;
  //   -moz-osx-font-smoothing: grayscale;
  // }
  .calculator-wrapper {
    max-width: 1230px;
    margin: 0 auto;
    padding: 0;

    font-family: ${theme.fonts.main};
    font-weight: 400;
    line-height: 20px;
  }
  * {
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -moz-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  img {
    max-width: 100%;
    vertical-align: middle;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  .site-layout .site-layout-background {
    background: #fff;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  [data-theme='dark'] .site-layout .site-layout-background {
    background: #141414;
  }
`
