import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement34Square41Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#3-4-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M252.001 291C253.105 291 254 290.105 254 289.001C254 287.896 253.105 287.001 252.001 287.001L249.833 287.001L249.833 287.001L239 287.001L239 287L235.999 287C234.895 287 234 287.895 234 288.999C234 290.104 234.895 290.999 235.999 290.999L238.167 290.999L238.167 291L249 291L249 291L252.001 291Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 249.833 291)"
          fill="#ABABAB"
        />
        <path
          d="M253.06 291C253.579 291 254 290.579 254 290.06L254 287.941C254 287.422 253.579 287.001 253.06 287.001L249 287.001L249 291L253.06 291Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M234.94 287C234.421 287 234 287.421 234 287.94L234 290.059C234 290.578 234.421 290.999 234.94 290.999L239 290.999L239 287L234.94 287Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 248.167 291)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#3-4-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M313.001 291C314.105 291 315 290.105 315 289.001C315 287.896 314.105 287.001 313.001 287.001L310.833 287.001L310.833 287.001L300 287.001L300 287L296.999 287C295.895 287 295 287.895 295 288.999C295 290.104 295.895 290.999 296.999 290.999L299.167 290.999L299.167 291L310 291L310 291L313.001 291Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 310.833 291)"
          fill="#ABABAB"
        />
        <path
          d="M314.06 291C314.579 291 315 290.579 315 290.06L315 287.941C315 287.422 314.579 287.001 314.06 287.001L310 287.001L310 291L314.06 291Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M295.94 287C295.421 287 295 287.421 295 287.94L295 290.059C295 290.578 295.421 290.999 295.94 290.999L300 290.999L300 287L295.94 287Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 309.167 291)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#3-4-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M319 34H230V287H319V34ZM308 45H241V276H308V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M319 34H230V287H231.219V35H317.781V287H319V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-4-filter3_i)">
          <rect
            x="241.47"
            y="45.47"
            width="66.0601"
            height="230.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="234.53"
          y="38.53"
          width="79.9399"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="281.528"
          y="36"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 281.528 36)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#3-4-filter4_d)">
          <rect
            x="280.884"
            y="36.7018"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 280.884 36.7018)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M280.766 38.695C280.42 37.5419 279.35 36.7018 278.084 36.7018L271.444 36.7018C269.898 36.7018 268.644 37.9554 268.644 39.5018C268.644 39.537 268.645 39.572 268.646 39.6068C268.993 40.7599 270.062 41.6 271.328 41.6L277.968 41.6C279.515 41.6 280.768 40.3464 280.768 38.8C280.768 38.7649 280.768 38.7299 280.766 38.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M279.409 37.0346C279.266 37.0118 279.118 37 278.968 37L272.328 37C270.782 37 269.528 38.2536 269.528 39.8C269.528 40.8671 270.125 41.7947 271.003 42.2673C269.667 42.0558 268.644 40.8982 268.644 39.5018C268.644 37.9554 269.898 36.7018 271.444 36.7018L278.084 36.7018C278.564 36.7018 279.015 36.8223 279.409 37.0346Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-4-filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M294.528 39.5C294.528 38.6716 293.857 38 293.028 38L292.814 38L291.528 38L273.028 38C272.2 38 271.528 38.6716 271.528 39.5V39.5C271.528 40.3284 272.2 41 273.028 41L289.528 41L293.028 41L293.243 41C293.953 41 294.528 40.4244 294.528 39.7143V39.7143C294.528 39.6771 294.527 39.6401 294.525 39.6035C294.527 39.5693 294.528 39.5348 294.528 39.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M306.424 274.5L274.5 49.5584L242.576 274.5L306.424 274.5Z" stroke="white" />
        <defs>
          <filter
            id="3-4-filter0_d"
            x="232.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-4-filter1_d"
            x="293.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-4-filter2_d"
            x="225.3"
            y="29.3003"
            width="100.279"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-4-filter3_i"
            x="241"
            y="45"
            width="67.9399"
            height="233.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-4-filter4_d"
            x="266.765"
            y="34.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-4-filter5_d"
            x="270.649"
            y="36.1201"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="236.5"
            y1="293.8"
            x2="236.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="251.5"
            y1="284.202"
            x2="251.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="236.5"
            y1="293.798"
            x2="236.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="297.5"
            y1="293.8"
            x2="297.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="312.5"
            y1="284.202"
            x2="312.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="297.5"
            y1="293.798"
            x2="297.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
