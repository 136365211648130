import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement51Square5VerticalCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#5-1_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M50.0005 304C51.1048 304 52 303.105 52 302.001C52 300.896 51.1048 300.001 50.0005 300.001L47.8335 300.001L47.8335 300.001L37 300.001L37 300L33.9995 300C32.8952 300 32 300.895 32 301.999C32 303.104 32.8952 303.999 33.9995 303.999L36.1668 303.999L36.1668 304L47 304L47 304L50.0005 304Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 47.8335 304)"
          fill="#ABABAB"
        />
        <path
          d="M51.0601 304C51.5792 304 52 303.579 52 303.06L52 300.941C52 300.422 51.5792 300.001 51.0601 300.001L47 300.001L47 304L51.0601 304Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M32.9399 300C32.4208 300 32 300.421 32 300.94L32 303.059C32 303.578 32.4208 303.999 32.9399 303.999L37 303.999L37 300L32.9399 300Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 46.1666 304)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#5-1_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M186.001 304C187.105 304 188 303.105 188 302.001C188 300.896 187.105 300.001 186.001 300.001L183.833 300.001L183.833 300.001L173 300.001L173 300L169.999 300C168.895 300 168 300.895 168 301.999C168 303.104 168.895 303.999 169.999 303.999L172.167 303.999L172.167 304L183 304L183 304L186.001 304Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 183.833 304)"
          fill="#ABABAB"
        />
        <path
          d="M187.06 304C187.579 304 188 303.579 188 303.06L188 300.941C188 300.422 187.579 300.001 187.06 300.001L183 300.001L183 304L187.06 304Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M168.94 300C168.421 300 168 300.421 168 300.94L168 303.059C168 303.578 168.421 303.999 168.94 303.999L173 303.999L173 300L168.94 300Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 182.167 304)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#5-1_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M192 254H28V300H192V254ZM183 263H37V291H183V263Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M192 254H28V300H29V255H191V300H192V254Z"
          fill="#FBFBFB"
        />
        <g filter="url(#5-1_filter3_i)">
          <rect
            x="37.47"
            y="263.47"
            width="145.06"
            height="27.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="32.53"
          y="258.53"
          width="154.94"
          height="36.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M40.8567 289.5L110 264.532L179.143 289.5H40.8567Z" stroke="white" />
        <rect
          x="117.528"
          y="255"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 117.528 255)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#5-1_filter4_d)">
          <rect
            x="116.884"
            y="255.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 116.884 255.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M116.766 257.695C116.42 256.542 115.35 255.702 114.084 255.702L107.444 255.702C105.898 255.702 104.644 256.955 104.644 258.502C104.644 258.537 104.645 258.572 104.646 258.607C104.993 259.76 106.062 260.6 107.328 260.6L113.968 260.6C115.515 260.6 116.768 259.346 116.768 257.8C116.768 257.765 116.768 257.73 116.766 257.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115.409 256.034C115.266 256.012 115.118 256 114.968 256L108.328 256C106.782 256 105.528 257.253 105.528 258.8C105.528 259.867 106.125 260.795 107.003 261.267C105.666 261.056 104.644 259.898 104.644 258.502C104.644 256.955 105.898 255.702 107.444 255.702L114.084 255.702C114.564 255.702 115.015 255.822 115.409 256.034Z"
          fill="#FBFBFB"
        />
        <g filter="url(#5-1_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M130.528 258.5C130.528 257.672 129.857 257 129.028 257L128.814 257L127.528 257L109.028 257C108.2 257 107.528 257.672 107.528 258.5V258.5C107.528 259.328 108.2 260 109.028 260L125.528 260L129.028 260L129.243 260C129.953 260 130.528 259.424 130.528 258.714V258.714C130.528 258.677 130.527 258.64 130.525 258.604C130.527 258.569 130.528 258.535 130.528 258.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="5-1_filter0_d"
            x="30.1201"
            y="298.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-1_filter1_d"
            x="166.12"
            y="298.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-1_filter2_d"
            x="23.3003"
            y="249.3"
            width="175.279"
            height="57.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-1_filter3_i"
            x="37"
            y="263"
            width="146.94"
            height="30.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-1_filter4_d"
            x="102.764"
            y="253.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-1_filter5_d"
            x="106.649"
            y="255.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="34.5"
            y1="306.8"
            x2="34.5"
            y2="299.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="49.5"
            y1="297.202"
            x2="49.5"
            y2="304.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="34.5"
            y1="306.798"
            x2="34.5"
            y2="299.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="170.5"
            y1="306.8"
            x2="170.5"
            y2="299.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="185.5"
            y1="297.202"
            x2="185.5"
            y2="304.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="170.5"
            y1="306.798"
            x2="170.5"
            y2="299.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
