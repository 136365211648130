import React from 'react'
import { StageDescriptionBlockContentItemIcon } from 'src/react-app/views/calculator/styles'

export const SunProtection2 = () => {
  return (
    <StageDescriptionBlockContentItemIcon title="Защита мебели и интерьера от выгорания">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.6"
          d="M32.5 40H7.5C3.3575 40 0 36.6425 0 32.5V7.5C0 3.3575 3.3575 0 7.5 0H32.5C36.6425 0 40 3.3575 40 7.5V32.5C40 36.6425 36.6425 40 32.5 40Z"
          fill="#E0E3E9"
        />
        <path
          d="M26.002 26.25V22.0838C26.002 20.705 27.1232 19.5838 28.502 19.5838C28.962 19.5838 29.3357 19.9575 29.3357 20.4175C29.3357 20.8813 28.957 21.2563 28.492 21.2513C28.0345 21.245 27.6682 21.6263 27.6682 22.0838V24.5838C27.6682 25.5038 26.922 26.25 26.002 26.25Z"
          fill="#1C3775"
        />
        <path
          d="M14.3337 26.25V22.0838C14.3337 20.705 13.2125 19.5838 11.8337 19.5838C11.3737 19.5838 11 19.9575 11 20.4175C11 20.8813 11.3787 21.2563 11.8437 21.2513C12.3012 21.245 12.6675 21.6263 12.6675 22.0838V24.5838C12.6675 25.5038 13.4137 26.25 14.3337 26.25Z"
          fill="#1C3775"
        />
        <path
          d="M23.7085 30H16.6248C16.1648 30 15.791 29.6263 15.791 29.1663C15.791 28.7063 16.1648 28.3325 16.6248 28.3325H23.7085C24.1685 28.3325 24.5423 28.7063 24.5423 29.1663C24.5423 29.6263 24.1698 30 23.7085 30Z"
          fill="#1C3775"
        />
        <path
          d="M20.1677 30C19.7077 30 19.334 29.6263 19.334 29.1663V25.625C19.334 25.165 19.7077 24.7913 20.1677 24.7913C20.6277 24.7913 21.0015 25.165 21.0015 25.625V29.1663C21.0015 29.6275 20.6277 30 20.1677 30Z"
          fill="#1C3775"
        />
        <path
          d="M24.3853 20.8337H15.9503C15.519 20.8337 15.1115 20.6437 14.834 20.3137C14.5565 19.9837 14.439 19.55 14.5128 19.125L15.8903 11.2088C16.0115 10.5088 16.6165 10 17.3278 10H23.009C23.7203 10 24.324 10.5088 24.4453 11.2088L25.8228 19.125C25.8965 19.55 25.7803 19.9837 25.5015 20.3137C25.224 20.6437 24.8165 20.8337 24.3853 20.8337Z"
          fill="#1C3775"
        />
        <path
          d="M26.2087 26.25H14.125C13.78 26.25 13.5 25.97 13.5 25.625V23.9587C13.5 22.695 14.5275 21.6675 15.7912 21.6675H24.5412C25.805 21.6675 26.8325 22.695 26.8325 23.9587V25.625C26.8337 25.97 26.5537 26.25 26.2087 26.25Z"
          fill="#1C3775"
        />
      </svg>
    </StageDescriptionBlockContentItemIcon>
  )
}
