import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement54Square6Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#5-4_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M164 256C164 254.895 163.105 254 162 254C160.895 254 160 254.895 160 256V270.56H164V256ZM164 270.56H160V272.4H159.94C159.421 272.4 159 272.821 159 273.34V275.12C159 276.158 159.842 277 160.88 277H162.12C163.158 277 164 276.158 164 275.12V272.4V272.4V270.56Z"
            fill="white"
          />
        </g>
        <rect
          width="3.99998"
          height="0.95833"
          transform="matrix(-1 0 0 1 164 271.25)"
          fill="#ABABAB"
        />
        <path
          d="M164 254.94C164 254.421 163.579 254 163.06 254H160.94C160.421 254 160 254.421 160 254.94V271.25H164V254.94Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M164 276.06C164 276.579 163.579 277 163.06 277H159.94C159.421 277 159 276.579 159 276.06V273.148C159 272.629 159.421 272.208 159.94 272.208H164V276.06Z"
          fill="url(#paint1_linear)"
        />
        <g filter="url(#5-4_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M164 139.999C164 138.895 163.105 138 162.001 138C160.896 138 160.001 138.895 160.001 139.999V142.167H160.001V153H160V156.001C160 157.105 160.895 158 161.999 158C163.104 158 163.999 157.105 163.999 156.001V153.833H164V143H164V139.999Z"
            fill="url(#paint2_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 164 142.166)"
          fill="#ABABAB"
        />
        <path
          d="M164 138.94C164 138.421 163.579 138 163.06 138H160.941C160.422 138 160.001 138.421 160.001 138.94V143H164V138.94Z"
          fill="url(#paint3_linear)"
        />
        <path
          d="M160 157.06C160 157.579 160.421 158 160.94 158H163.059C163.578 158 163.999 157.579 163.999 157.06V153H160V157.06Z"
          fill="url(#paint4_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 164 143.833)"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#5-4_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M284 128H164V287H284V128ZM273 139H175V276H273V139Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M284 128H164V287H165V129H283V287H284V128Z"
          fill="#FBFBFB"
        />
        <rect
          x="168.53"
          y="132.53"
          width="110.94"
          height="149.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#5-4_filter3_i)">
          <rect
            x="175.47"
            y="139.47"
            width="97.0601"
            height="136.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="275"
          y="202"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#5-4_filter4_d)">
          <rect x="275.702" y="202.645" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M276.286 213.796C276.798 214.458 277.6 214.884 278.502 214.884C280.049 214.884 281.302 213.631 281.302 212.084V205.444C281.302 204.456 280.79 203.587 280.017 203.089C280.383 203.562 280.601 204.156 280.601 204.8V211.44C280.601 212.987 279.347 214.24 277.801 214.24C277.243 214.24 276.723 214.077 276.286 213.796Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M278.502 202.645C276.956 202.645 275.702 203.898 275.702 205.445V211.44C275.702 211.461 275.702 211.481 275.703 211.501V206.024C275.703 204.478 276.956 203.224 278.503 203.224C280.029 203.224 281.27 204.445 281.302 205.963V205.445C281.302 203.898 280.049 202.645 278.502 202.645Z"
          fill="#FBFBFB"
        />
        <g filter="url(#5-4_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M278.5 206C277.672 206 277 206.672 277 207.5V207.714V209V227.5C277 228.328 277.672 229 278.5 229C279.328 229 280 228.328 280 227.5V211V207.5L280 207.286C280 206.576 279.424 206 278.714 206C278.677 206 278.64 206.001 278.604 206.004C278.569 206.001 278.535 206 278.5 206Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M176.5 141.959L176.5 274.041L271.126 208L176.5 141.959Z" stroke="white" />
        <path d="M271.29 274.5H176.71L224 142.483L271.29 274.5Z" stroke="white" />
        <defs>
          <filter
            id="5-4_filter0_d"
            x="157.12"
            y="252.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-4_filter1_d"
            x="158.12"
            y="136.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-4_filter2_d"
            x="159.3"
            y="123.3"
            width="131.279"
            height="170.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-4_filter3_i"
            x="175"
            y="139"
            width="98.9399"
            height="139.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-4_filter4_d"
            x="273.822"
            y="200.765"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-4_filter5_d"
            x="275.12"
            y="204.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="157.2"
            y1="262.625"
            x2="164.8"
            y2="262.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="155.5"
            y1="274.604"
            x2="165"
            y2="274.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="166.8"
            y1="155.5"
            x2="159.2"
            y2="155.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="157.202"
            y1="140.5"
            x2="164.8"
            y2="140.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="166.798"
            y1="155.5"
            x2="159.2"
            y2="155.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
