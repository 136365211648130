import React from 'react'
import { ProductImageWrapper } from '../../../components/product-images-generator/styles'

export const Casement22Loggia10Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <g filter="url(#filter0_d)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M100.028 106.877C100.028 105.987 99.3068 105.266 98.4169 105.266C97.5269 105.266 96.8055 105.987 96.8055 106.877V118.608H100.028V106.877ZM100.028 118.609H96.8056V120.091H96.7572C96.339 120.091 95.9999 120.43 95.9999 120.848V122.282C95.9999 123.119 96.678 123.797 97.5145 123.797H98.5137C99.3502 123.797 100.028 123.119 100.028 122.282V120.091V120.091V118.609Z"
              fill="white"
            />
          </g>
          <rect
            width="3.22273"
            height="0.772112"
            transform="matrix(-1 0 0 1 100.028 119.164)"
            fill="#ABABAB"
          />
          <path
            d="M100.028 106.023C100.028 105.605 99.6893 105.266 99.271 105.266H97.5629C97.1446 105.266 96.8056 105.605 96.8056 106.023V119.164H100.028V106.023Z"
            fill="url(#paint0_linear)"
          />
          <path
            d="M100.028 123.04C100.028 123.458 99.6893 123.797 99.271 123.797H96.7572C96.339 123.797 95.9999 123.458 95.9999 123.04V120.694C95.9999 120.275 96.339 119.936 96.7572 119.936H100.028V123.04Z"
            fill="url(#paint1_linear)"
          />
          <g filter="url(#filter1_d)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M100.028 21.4733C100.028 20.5835 99.307 19.8623 98.4173 19.8623C97.5276 19.8623 96.8063 20.5836 96.8063 21.4733V23.8907H96.8064V31.9478H96.8056V34.3653C96.8056 35.255 97.5268 35.9762 98.4165 35.9762C99.3062 35.9762 100.027 35.255 100.027 34.3653V32.6195H100.028V23.2198H100.028V21.4733Z"
              fill="url(#paint2_linear)"
            />
          </g>
          <rect
            width="3.2219"
            height="9.39966"
            transform="matrix(-1 0 0 1 100.028 23.2188)"
            fill="#ABABAB"
          />
          <path
            d="M100.028 20.6196C100.028 20.2014 99.6893 19.8623 99.271 19.8623H97.5637C97.1455 19.8623 96.8064 20.2014 96.8064 20.6196V23.8907H100.028V20.6196Z"
            fill="url(#paint3_linear)"
          />
          <path
            d="M96.8057 35.2193C96.8057 35.6375 97.1447 35.9766 97.563 35.9766H99.2703C99.6885 35.9766 100.028 35.6375 100.028 35.2193V31.9481H96.8057V35.2193Z"
            fill="url(#paint4_linear)"
          />
          <rect
            width="3.2219"
            height="6.71404"
            transform="matrix(-1 0 0 1 100.028 24.5625)"
            fill="url(#paint5_linear)"
          />
          <g filter="url(#filter2_d)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M177.374 11H100.028V132.658H177.374V11ZM168.512 19.8625H108.891V123.796H168.512V19.8625Z"
              fill="#E8EAEE"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M177.374 11H100.028V132.658L100.834 132.658V11.8057H176.568V132.658L177.374 132.658V11Z"
              fill="#FBFBFB"
            />
            <rect
              x="103.678"
              y="14.6497"
              width="70.0462"
              height="114.359"
              stroke="#E2E4E8"
              strokeWidth="0.757295"
            />
            <g filter="url(#filter3_i)">
              <rect
                x="109.294"
                y="20.2651"
                width="58.815"
                height="103.128"
                stroke="#979797"
                strokeWidth="0.805684"
              />
            </g>
          </g>
          <rect
            width="5.63979"
            height="10.8996"
            rx="2.8199"
            transform="matrix(-1 0 0 1 175.763 65.7861)"
            fill="#9B9DA2"
            fillOpacity="0.19"
          />
          <g filter="url(#filter4_d)">
            <rect
              width="4.51183"
              height="9.8615"
              rx="2.25592"
              transform="matrix(-1 0 0 1 175.197 66.3057)"
              fill="#E8EAEE"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M174.727 75.2895C174.314 75.8234 173.668 76.1672 172.941 76.1672C171.695 76.1672 170.685 75.1572 170.685 73.9113V68.5616C170.685 67.7654 171.097 67.0656 171.72 66.6641C171.426 67.0452 171.251 67.5233 171.251 68.0423V73.392C171.251 74.6379 172.261 75.6479 173.506 75.6479C173.956 75.6479 174.375 75.5163 174.727 75.2895Z"
            fill="#DFE0E2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M172.941 66.3057C174.187 66.3057 175.197 67.3157 175.197 68.5616V73.3922C175.197 73.4099 175.197 73.4276 175.197 73.4452V69.0291C175.197 67.7832 174.187 66.7732 172.941 66.7732C171.713 66.7732 170.714 67.7547 170.685 68.9762V68.5616C170.685 67.3157 171.695 66.3057 172.941 66.3057Z"
            fill="#FBFBFB"
          />
          <g filter="url(#filter5_d)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M171.734 70.2101V70.0447C171.734 69.4726 172.198 69.0088 172.77 69.0088C172.801 69.0088 172.832 69.0098 172.863 69.0119C172.889 69.0101 172.916 69.0093 172.943 69.0093C173.61 69.0093 174.151 69.5504 174.151 70.2178V70.3816C174.151 70.3844 174.151 70.3872 174.151 70.3899L174.151 86.331C174.151 86.9985 173.61 87.5395 172.943 87.5395C172.275 87.5395 171.734 86.9985 171.734 86.331L171.734 73.0377C171.734 73.0377 171.734 73.0377 171.734 73.0377C171.734 73.0377 171.734 73.0377 171.734 73.0377V70.2178C171.734 70.2152 171.734 70.2127 171.734 70.2101Z"
              fill="#E8EAEE"
            />
          </g>
          <path
            d="M110.1 122.097V21.5604L167.097 71.8289L110.1 122.097Z"
            stroke="white"
            strokeWidth="0.805684"
          />
          <path
            d="M138.701 22.1451L167.173 122.587H110.23L138.701 22.1451Z"
            stroke="white"
            strokeWidth="0.805684"
          />
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="94.4854"
            y="103.751"
            width="8.57218"
            height="23.0749"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.757295" dy="0.757295" />
            <feGaussianBlur stdDeviation="1.13594" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter1_d"
            x="95.2911"
            y="18.3477"
            width="7.76651"
            height="20.6577"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.757295" dy="0.757295" />
            <feGaussianBlur stdDeviation="1.13594" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter2_d"
            x="98.417"
            y="9.38863"
            width="83.7912"
            height="128.104"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.61137" dy="1.61137" />
            <feGaussianBlur stdDeviation="1.61137" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter3_i"
            x="108.891"
            y="19.8623"
            width="60.3779"
            height="106.205"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.757295" dy="2.27188" />
            <feGaussianBlur stdDeviation="1.51459" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter4_d"
            x="169.171"
            y="64.7911"
            width="9.0556"
            height="14.4053"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.757295" dy="0.757295" />
            <feGaussianBlur stdDeviation="1.13594" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter5_d"
            x="170.22"
            y="67.4942"
            width="6.96085"
            height="23.0745"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.757295" dy="0.757295" />
            <feGaussianBlur stdDeviation="1.13594" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="94.5497"
            y1="112.215"
            x2="100.673"
            y2="112.215"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="93.18"
            y1="121.867"
            x2="100.834"
            y2="121.867"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="102.284"
            y1="33.962"
            x2="96.161"
            y2="33.962"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="94.5511"
            y1="21.8765"
            x2="100.673"
            y2="21.8765"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="102.283"
            y1="33.9624"
            x2="96.1613"
            y2="33.9624"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="5.47722"
            y1="3.35702"
            x2="-0.644379"
            y2="3.35702"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <clipPath id="clip0">
            <rect width="85.4025" height="127.298" fill="white" transform="translate(96 11)" />
          </clipPath>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
