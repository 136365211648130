import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement62Square8Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#6-2_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M137.999 286C136.895 286 136 286.895 136 287.999C136 289.104 136.895 289.999 137.999 289.999L140.167 289.999L140.167 289.999L151 289.999L151 290L154.001 290C155.105 290 156 289.105 156 288.001C156 286.896 155.105 286.001 154.001 286.001L151.833 286.001L151.833 286L141 286L141 286L137.999 286Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 140.167 286)"
          fill="#ABABAB"
        />
        <path
          d="M136.94 286C136.421 286 136 286.421 136 286.94L136 289.059C136 289.578 136.421 289.999 136.94 289.999L141 289.999L141 286L136.94 286Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M155.06 290C155.579 290 156 289.579 156 289.06L156 286.941C156 286.422 155.579 286.001 155.06 286.001L151 286.001L151 290L155.06 290Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 141.833 286)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#6-2_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M197.999 286C196.895 286 196 286.895 196 287.999C196 289.104 196.895 289.999 197.999 289.999L200.167 289.999L200.167 289.999L211 289.999L211 290L214.001 290C215.105 290 216 289.105 216 288.001C216 286.896 215.105 286.001 214.001 286.001L211.833 286.001L211.833 286L201 286L201 286L197.999 286Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 200.167 286)"
          fill="#ABABAB"
        />
        <path
          d="M196.94 286C196.421 286 196 286.421 196 286.94L196 289.059C196 289.578 196.421 289.999 196.94 289.999L201 289.999L201 286L196.94 286Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M215.06 290C215.579 290 216 289.579 216 289.06L216 286.941C216 286.422 215.579 286.001 215.06 286.001L211 286.001L211 290L215.06 290Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 201.833 286)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#6-2_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M220 165H131V286H220V165ZM209 176H142V275H209V176Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M220 165H131V286H132.219V166H218.781V286H220V165Z"
          fill="#FBFBFB"
        />
        <g filter="url(#6-2_filter3_i)">
          <rect
            x="142.47"
            y="176.47"
            width="66.0601"
            height="98.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="135.53"
          y="169.53"
          width="79.9399"
          height="111.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="182.528"
          y="167"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 182.528 167)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#6-2_filter4_d)">
          <rect
            x="181.884"
            y="167.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 181.884 167.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M181.766 169.695C181.42 168.542 180.35 167.702 179.084 167.702L172.444 167.702C170.898 167.702 169.644 168.956 169.644 170.502C169.644 170.537 169.645 170.572 169.646 170.607C169.993 171.76 171.062 172.6 172.328 172.6L178.968 172.6C180.515 172.6 181.768 171.346 181.768 169.8C181.768 169.765 181.768 169.73 181.766 169.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M180.409 168.035C180.266 168.012 180.118 168 179.968 168L173.328 168C171.782 168 170.528 169.254 170.528 170.8C170.528 171.867 171.125 172.795 172.003 173.267C170.667 173.056 169.644 171.898 169.644 170.502C169.644 168.956 170.898 167.702 172.444 167.702L179.084 167.702C179.564 167.702 180.015 167.822 180.409 168.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#6-2_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M195.528 170.5C195.528 169.672 194.857 169 194.028 169L193.814 169L192.528 169L174.028 169C173.2 169 172.528 169.672 172.528 170.5V170.5C172.528 171.328 173.2 172 174.028 172L190.528 172L194.028 172L194.243 172C194.953 172 195.528 171.424 195.528 170.714V170.714C195.528 170.677 195.527 170.64 195.525 170.604C195.527 170.569 195.528 170.535 195.528 170.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M143.695 273.5L175.5 178.574L207.305 273.5L143.695 273.5Z" stroke="white" />
        <defs>
          <filter
            id="6-2_filter0_d"
            x="134.12"
            y="284.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-2_filter1_d"
            x="194.12"
            y="284.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-2_filter2_d"
            x="126.3"
            y="160.3"
            width="100.279"
            height="132.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-2_filter3_i"
            x="142"
            y="176"
            width="67.9399"
            height="101.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="6-2_filter4_d"
            x="167.765"
            y="165.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-2_filter5_d"
            x="171.649"
            y="167.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="153.5"
            y1="283.2"
            x2="153.5"
            y2="290.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="138.5"
            y1="292.798"
            x2="138.5"
            y2="285.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="153.5"
            y1="283.202"
            x2="153.5"
            y2="290.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="213.5"
            y1="283.2"
            x2="213.5"
            y2="290.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="198.5"
            y1="292.798"
            x2="198.5"
            y2="285.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="213.5"
            y1="283.202"
            x2="213.5"
            y2="290.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
