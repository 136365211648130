import React, { FC } from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'
import { IContainerSquareProps } from '../../interface'
import { ContainerBackground } from '../container-background'

export const ContainerSquare8Casement: FC<IContainerSquareProps> = props => {
  const { onCasementClick, withoutBackground } = props

  return (
    <ProductImageWrapper isClickable>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <rect width="449" height="333" fill="white" />
        <rect x="24" y="28" width="401" height="264" fill="#E8EAEE" />
        <rect x="24" y="28" width="401" height="264" fill="url(#8-0-pattern0)" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M439 10H10V292H24V29H425V292H439V10Z"
          fill="#C4C4C4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M439 292H10V308H0V316H10H439H449V308H439V292Z"
          fill="url(#8-0-paint0_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 28H425V292H24V28ZM37 41H117V150H37V41ZM117 170H37V279H117V170ZM136 41H215V150H136V41ZM215 170H136V279H215V170ZM234 41H313V150H234V41ZM313 170H234V279H313V170ZM332 41H412V150H332V41ZM412 170H332V279H412V170Z"
          fill="#E8EAEE"
        />
        <path d="M439 10L425 28.0571V292.075L439 308V10Z" fill="#E9EBEF" />
        <path d="M439 10L425 28.0571V292.075L439 308V10Z" fill="#020202" fillOpacity="0.06" />
        <path d="M10 10L24 28.1525V291.982L10 308V10Z" fill="#E9EBEF" />
        <path d="M10 10L24 28.1525V291.982L10 308V10Z" fill="#020202" fillOpacity="0.06" />
        <rect y="316" width="449" height="17" fill="white" />
        <g
          filter="url(#8-0-filter0_i)"
          className="casement"
          data-index="0"
          onClick={onCasementClick(0)}
        >
          <rect x="37" y="41" width="80" height="109" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="37.47"
          y="41.47"
          width="79.0601"
          height="108.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#8-0-filter1_i)"
          className="casement"
          data-index="1"
          onClick={onCasementClick(1)}
        >
          <rect x="136" y="41" width="80" height="109" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="136.47"
          y="41.47"
          width="79.0601"
          height="108.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#8-0-filter2_i)"
          className="casement"
          data-index="2"
          onClick={onCasementClick(2)}
        >
          <rect x="234" y="41" width="80" height="109" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="234.47"
          y="41.47"
          width="79.0601"
          height="108.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#8-0-filter3_i)"
          className="casement"
          data-index="3"
          onClick={onCasementClick(3)}
        >
          <rect x="332" y="41" width="80" height="109" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="332.47"
          y="41.47"
          width="79.0601"
          height="108.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#8-0-filter4_i)"
          className="casement"
          data-index="7"
          onClick={onCasementClick(7)}
        >
          <rect x="332" y="170" width="80" height="109" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="332.47"
          y="170.47"
          width="79.0601"
          height="108.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#8-0-filter5_i)"
          className="casement"
          data-index="6"
          onClick={onCasementClick(6)}
        >
          <rect x="234" y="170" width="80" height="109" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="234.47"
          y="170.47"
          width="79.0601"
          height="108.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#8-0-filter6_i)"
          className="casement"
          data-index="5"
          onClick={onCasementClick(5)}
        >
          <rect x="136" y="170" width="80" height="109" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="136.47"
          y="170.47"
          width="79.0601"
          height="108.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#8-0-filter7_i)"
          className="casement"
          data-index="4"
          onClick={onCasementClick(4)}
        >
          <rect x="37" y="170" width="80" height="109" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="37.47"
          y="170.47"
          width="79.0601"
          height="108.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <path
          d="M85.9851 65.7411H83.4471C82.8002 65.7411 82.2528 65.2343 82.2528 64.6355V64.4052C82.2528 63.8063 82.8002 63.2996 83.4471 63.2996H85.9851C86.632 63.2996 87.1794 63.8063 87.1794 64.4052V64.6355C87.1794 65.2574 86.6569 65.7411 85.9851 65.7411Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M68.4262 68.5652C68.7247 68.5652 68.7993 68.5652 69.1972 68.5652C69.9432 68.5652 70.4903 69.1052 70.4903 69.833C70.4903 70.5843 69.9184 71.1008 69.1474 71.1008C67.705 71.1008 66.8098 71.1008 65.3674 71.1008C64.8203 71.1008 64.3727 70.913 64.1488 70.4199C63.9499 70.1147 63.8255 69.2695 64.6213 68.5652C65.1436 67.9782 70.9131 61.7565 71.9327 60.6765C71.9824 60.6061 71.9327 60.5122 71.8581 60.5122C71.8332 60.5122 71.8332 60.5122 71.8084 60.5122C69.6448 60.5122 67.4812 60.5122 65.3177 60.5122C64.6711 60.5122 64.1737 60.1365 64.0494 59.573C63.9002 58.9156 64.1986 58.3522 64.7706 58.0939C64.9446 58.0235 65.1187 58 65.2928 58C68.5008 58 71.684 58 74.8921 58C75.5138 58 75.9366 58.1878 76.1852 58.7278C76.5085 59.4087 76.1604 59.8783 75.9366 60.1835C75.4889 60.7235 70.938 65.56 68.4262 68.2834C68.4014 68.3069 68.3765 68.3539 68.3268 68.3773C68.277 68.4478 68.3268 68.5652 68.4262 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M75.1856 71.1009H72.6476C72.0007 71.1009 71.4533 70.5818 71.4533 69.9684V69.7324C71.4533 69.1189 72.0007 68.5999 72.6476 68.5999H75.1856C75.8325 68.5999 76.3799 69.1189 76.3799 69.7324V69.9684C76.3799 70.6054 75.8325 71.1009 75.1856 71.1009Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M78.7154 64.5152C78.7154 62.7341 78.7154 60.9764 78.7154 59.1952C78.7154 58.4922 79.2374 58 79.9832 58C83.2148 58 86.4464 58 89.678 58C90.4238 58 90.9458 58.5156 90.9458 59.2421C90.9458 59.9686 90.4238 60.5077 89.678 60.5077C86.9933 60.5077 84.3334 60.5077 81.6487 60.5077C81.4498 60.5077 81.4001 60.5545 81.4001 60.742C81.4001 63.2731 81.4001 65.8042 81.4001 68.3353C81.4001 68.5463 81.4747 68.5697 81.6487 68.5697C84.3086 68.5697 86.9933 68.5697 89.6532 68.5697C90.2498 68.5697 90.7221 68.8978 90.8961 69.39C91.095 69.9759 90.8712 70.5618 90.3989 70.8899C90.1752 71.0305 89.9018 71.1008 89.6283 71.1008C86.4464 71.1008 83.2397 71.1008 80.0578 71.1008C79.2374 71.1008 78.7403 70.5852 78.7403 69.8118C78.7154 68.0307 78.7154 66.273 78.7154 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M65.7054 76.0415V76.6984H64V76.0415H64.4263V74.2586H64.0502V73.6018H65.6803V74.2586H65.3041V76.0415H65.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M69.5344 76.7579H68.7737L68.651 76.5451C68.3811 76.687 68.0621 76.7579 67.7676 76.7579C66.9334 76.7342 66.1482 76.1668 66.1482 75.1501C66.1482 74.1806 66.8107 73.5422 67.8167 73.5422C68.651 73.5422 69.4607 74.0861 69.4607 75.1501C69.4607 75.5284 69.3626 75.8121 69.2154 76.0722L69.5589 76.6397V76.7579H69.5344ZM67.7922 76.0013C68.1848 76.0013 68.6019 75.7175 68.6019 75.1501C68.6019 74.5826 68.1848 74.2989 67.7922 74.2989C67.375 74.2989 66.9824 74.5826 66.9824 75.1501C66.9824 75.7175 67.375 76.0013 67.7922 76.0013Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M71.7835 75.7486C71.7835 75.9598 72.0291 76.0772 72.2992 76.0772C72.5448 76.0772 72.7904 75.9598 72.7904 75.7955C72.7904 75.4904 72.422 75.4904 72.201 75.4669C71.6116 75.42 71.0223 75.2087 71.0223 74.5515C71.0223 73.8708 71.6116 73.5422 72.2747 73.5422C72.8886 73.5422 73.5271 73.8708 73.5271 74.5515H72.7167C72.7167 74.3403 72.4957 74.2464 72.2747 74.2464C72.0782 74.2464 71.8326 74.2933 71.8326 74.5281C71.8326 74.7393 72.0291 74.7862 72.3238 74.8097C73.0114 74.8567 73.6007 75.021 73.6007 75.7721C73.6007 76.4293 72.9377 76.7579 72.2747 76.7579C71.5871 76.7579 70.924 76.4293 70.9486 75.7486H71.7835Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M76.2288 74.3109H75.0377V74.7601H76.1295V75.4692H75.0377V75.9656H76.2536V76.6984H74.1692V73.6018H76.2039V74.3109H76.2288Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M79.603 76.5802V76.6984H78.7222L78.1594 75.7292H77.719V76.6984H76.8871V73.6018C77.3765 73.6018 77.8413 73.6018 78.3062 73.6018C79.0892 73.6018 79.4562 74.1691 79.4562 74.7128C79.4562 75.0673 79.285 75.4219 78.9669 75.611L79.603 76.5802ZM78.3062 75.0673C78.5509 75.0673 78.6488 74.8782 78.6488 74.6655C78.6488 74.4764 78.5265 74.2873 78.3062 74.2873H77.719V75.0673H78.3062Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M81.2761 75.3341L81.553 74.5273L81.9556 73.6018H82.8868V73.7205L81.4523 76.7579H81.1L79.6655 73.7205V73.6018H80.5967L81.0245 74.5273L81.2761 75.3341Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M84.7814 76.0415V76.6984H83.0761V76.0415H83.5024V74.2586H83.1262V73.6018H84.7564V74.2586H84.3802V76.0415H84.7814Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M86.8348 73.6018C87.5091 73.6018 88.2582 73.9551 88.383 74.7795H87.559C87.4341 74.4497 87.1844 74.332 86.8348 74.332C86.3354 74.332 86.0108 74.6617 86.0108 75.1799C86.0108 75.6274 86.3354 76.0278 86.8348 76.0278C87.1844 76.0278 87.4841 75.8629 87.559 75.5332H88.383C88.2582 76.4046 87.534 76.7579 86.8348 76.7579C85.9858 76.7579 85.1618 76.2162 85.1618 75.1799C85.1618 74.12 85.9858 73.6018 86.8348 73.6018Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M90.9442 74.3109H89.7531V74.7601H90.8449V75.4692H89.7531V75.9656H90.969V76.6984H88.8847V73.6018H90.9194V74.3109H90.9442Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M184.985 65.7411H182.447C181.8 65.7411 181.253 65.2343 181.253 64.6355V64.4052C181.253 63.8063 181.8 63.2996 182.447 63.2996H184.985C185.632 63.2996 186.179 63.8063 186.179 64.4052V64.6355C186.179 65.2574 185.657 65.7411 184.985 65.7411Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M167.426 68.5652C167.725 68.5652 167.799 68.5652 168.197 68.5652C168.943 68.5652 169.49 69.1052 169.49 69.833C169.49 70.5843 168.918 71.1008 168.147 71.1008C166.705 71.1008 165.81 71.1008 164.367 71.1008C163.82 71.1008 163.373 70.913 163.149 70.4199C162.95 70.1147 162.826 69.2695 163.621 68.5652C164.144 67.9782 169.913 61.7565 170.933 60.6765C170.982 60.6061 170.933 60.5122 170.858 60.5122C170.833 60.5122 170.833 60.5122 170.808 60.5122C168.645 60.5122 166.481 60.5122 164.318 60.5122C163.671 60.5122 163.174 60.1365 163.049 59.573C162.9 58.9156 163.199 58.3522 163.771 58.0939C163.945 58.0235 164.119 58 164.293 58C167.501 58 170.684 58 173.892 58C174.514 58 174.937 58.1878 175.185 58.7278C175.509 59.4087 175.16 59.8783 174.937 60.1835C174.489 60.7235 169.938 65.56 167.426 68.2834C167.401 68.3069 167.376 68.3539 167.327 68.3773C167.277 68.4478 167.327 68.5652 167.426 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M174.186 71.1009H171.648C171.001 71.1009 170.453 70.5818 170.453 69.9684V69.7324C170.453 69.1189 171.001 68.5999 171.648 68.5999H174.186C174.832 68.5999 175.38 69.1189 175.38 69.7324V69.9684C175.38 70.6054 174.832 71.1009 174.186 71.1009Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M177.715 64.5152C177.715 62.7341 177.715 60.9764 177.715 59.1952C177.715 58.4922 178.237 58 178.983 58C182.215 58 185.446 58 188.678 58C189.424 58 189.946 58.5156 189.946 59.2421C189.946 59.9686 189.424 60.5077 188.678 60.5077C185.993 60.5077 183.333 60.5077 180.649 60.5077C180.45 60.5077 180.4 60.5545 180.4 60.742C180.4 63.2731 180.4 65.8042 180.4 68.3353C180.4 68.5463 180.475 68.5697 180.649 68.5697C183.309 68.5697 185.993 68.5697 188.653 68.5697C189.25 68.5697 189.722 68.8978 189.896 69.39C190.095 69.9759 189.871 70.5618 189.399 70.8899C189.175 71.0305 188.902 71.1008 188.628 71.1008C185.446 71.1008 182.24 71.1008 179.058 71.1008C178.237 71.1008 177.74 70.5852 177.74 69.8118C177.715 68.0307 177.715 66.273 177.715 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M164.705 76.0415V76.6984H163V76.0415H163.426V74.2586H163.05V73.6018H164.68V74.2586H164.304V76.0415H164.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M168.534 76.7579H167.774L167.651 76.5451C167.381 76.687 167.062 76.7579 166.768 76.7579C165.933 76.7342 165.148 76.1668 165.148 75.1501C165.148 74.1806 165.811 73.5422 166.817 73.5422C167.651 73.5422 168.461 74.0861 168.461 75.1501C168.461 75.5284 168.363 75.8121 168.215 76.0722L168.559 76.6397V76.7579H168.534ZM166.792 76.0013C167.185 76.0013 167.602 75.7175 167.602 75.1501C167.602 74.5826 167.185 74.2989 166.792 74.2989C166.375 74.2989 165.982 74.5826 165.982 75.1501C165.982 75.7175 166.375 76.0013 166.792 76.0013Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M170.784 75.7486C170.784 75.9598 171.029 76.0772 171.299 76.0772C171.545 76.0772 171.79 75.9598 171.79 75.7955C171.79 75.4904 171.422 75.4904 171.201 75.4669C170.612 75.42 170.022 75.2087 170.022 74.5515C170.022 73.8708 170.612 73.5422 171.275 73.5422C171.889 73.5422 172.527 73.8708 172.527 74.5515H171.717C171.717 74.3403 171.496 74.2464 171.275 74.2464C171.078 74.2464 170.833 74.2933 170.833 74.5281C170.833 74.7393 171.029 74.7862 171.324 74.8097C172.011 74.8567 172.601 75.021 172.601 75.7721C172.601 76.4293 171.938 76.7579 171.275 76.7579C170.587 76.7579 169.924 76.4293 169.949 75.7486H170.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M175.229 74.3109H174.038V74.7601H175.13V75.4692H174.038V75.9656H175.254V76.6984H173.169V73.6018H175.204V74.3109H175.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M178.603 76.5802V76.6984H177.722L177.159 75.7292H176.719V76.6984H175.887V73.6018C176.376 73.6018 176.841 73.6018 177.306 73.6018C178.089 73.6018 178.456 74.1691 178.456 74.7128C178.456 75.0673 178.285 75.4219 177.967 75.611L178.603 76.5802ZM177.306 75.0673C177.551 75.0673 177.649 74.8782 177.649 74.6655C177.649 74.4764 177.526 74.2873 177.306 74.2873H176.719V75.0673H177.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M180.276 75.3341L180.553 74.5273L180.956 73.6018H181.887V73.7205L180.452 76.7579H180.1L178.666 73.7205V73.6018H179.597L180.024 74.5273L180.276 75.3341Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M183.781 76.0415V76.6984H182.076V76.0415H182.502V74.2586H182.126V73.6018H183.756V74.2586H183.38V76.0415H183.781Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M185.835 73.6018C186.509 73.6018 187.258 73.9551 187.383 74.7795H186.559C186.434 74.4497 186.184 74.332 185.835 74.332C185.335 74.332 185.011 74.6617 185.011 75.1799C185.011 75.6274 185.335 76.0278 185.835 76.0278C186.184 76.0278 186.484 75.8629 186.559 75.5332H187.383C187.258 76.4046 186.534 76.7579 185.835 76.7579C184.986 76.7579 184.162 76.2162 184.162 75.1799C184.162 74.12 184.986 73.6018 185.835 73.6018Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M189.944 74.3109H188.753V74.7601H189.845V75.4692H188.753V75.9656H189.969V76.6984H187.885V73.6018H189.919V74.3109H189.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M282.985 65.7411H280.447C279.8 65.7411 279.253 65.2343 279.253 64.6355V64.4052C279.253 63.8063 279.8 63.2996 280.447 63.2996H282.985C283.632 63.2996 284.179 63.8063 284.179 64.4052V64.6355C284.179 65.2574 283.657 65.7411 282.985 65.7411Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M265.426 68.5652C265.725 68.5652 265.799 68.5652 266.197 68.5652C266.943 68.5652 267.49 69.1052 267.49 69.833C267.49 70.5843 266.918 71.1008 266.147 71.1008C264.705 71.1008 263.81 71.1008 262.367 71.1008C261.82 71.1008 261.373 70.913 261.149 70.4199C260.95 70.1147 260.826 69.2695 261.621 68.5652C262.144 67.9782 267.913 61.7565 268.933 60.6765C268.982 60.6061 268.933 60.5122 268.858 60.5122C268.833 60.5122 268.833 60.5122 268.808 60.5122C266.645 60.5122 264.481 60.5122 262.318 60.5122C261.671 60.5122 261.174 60.1365 261.049 59.573C260.9 58.9156 261.199 58.3522 261.771 58.0939C261.945 58.0235 262.119 58 262.293 58C265.501 58 268.684 58 271.892 58C272.514 58 272.937 58.1878 273.185 58.7278C273.509 59.4087 273.16 59.8783 272.937 60.1835C272.489 60.7235 267.938 65.56 265.426 68.2834C265.401 68.3069 265.376 68.3539 265.327 68.3773C265.277 68.4478 265.327 68.5652 265.426 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M272.186 71.1009H269.648C269.001 71.1009 268.453 70.5818 268.453 69.9684V69.7324C268.453 69.1189 269.001 68.5999 269.648 68.5999H272.186C272.832 68.5999 273.38 69.1189 273.38 69.7324V69.9684C273.38 70.6054 272.832 71.1009 272.186 71.1009Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M275.715 64.5152C275.715 62.7341 275.715 60.9764 275.715 59.1952C275.715 58.4922 276.237 58 276.983 58C280.215 58 283.446 58 286.678 58C287.424 58 287.946 58.5156 287.946 59.2421C287.946 59.9686 287.424 60.5077 286.678 60.5077C283.993 60.5077 281.333 60.5077 278.649 60.5077C278.45 60.5077 278.4 60.5545 278.4 60.742C278.4 63.2731 278.4 65.8042 278.4 68.3353C278.4 68.5463 278.475 68.5697 278.649 68.5697C281.309 68.5697 283.993 68.5697 286.653 68.5697C287.25 68.5697 287.722 68.8978 287.896 69.39C288.095 69.9759 287.871 70.5618 287.399 70.8899C287.175 71.0305 286.902 71.1008 286.628 71.1008C283.446 71.1008 280.24 71.1008 277.058 71.1008C276.237 71.1008 275.74 70.5852 275.74 69.8118C275.715 68.0307 275.715 66.273 275.715 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M262.705 76.0415V76.6984H261V76.0415H261.426V74.2586H261.05V73.6018H262.68V74.2586H262.304V76.0415H262.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M266.534 76.7579H265.774L265.651 76.5451C265.381 76.687 265.062 76.7579 264.768 76.7579C263.933 76.7342 263.148 76.1668 263.148 75.1501C263.148 74.1806 263.811 73.5422 264.817 73.5422C265.651 73.5422 266.461 74.0861 266.461 75.1501C266.461 75.5284 266.363 75.8121 266.215 76.0722L266.559 76.6397V76.7579H266.534ZM264.792 76.0013C265.185 76.0013 265.602 75.7175 265.602 75.1501C265.602 74.5826 265.185 74.2989 264.792 74.2989C264.375 74.2989 263.982 74.5826 263.982 75.1501C263.982 75.7175 264.375 76.0013 264.792 76.0013Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M268.784 75.7486C268.784 75.9598 269.029 76.0772 269.299 76.0772C269.545 76.0772 269.79 75.9598 269.79 75.7955C269.79 75.4904 269.422 75.4904 269.201 75.4669C268.612 75.42 268.022 75.2087 268.022 74.5515C268.022 73.8708 268.612 73.5422 269.275 73.5422C269.889 73.5422 270.527 73.8708 270.527 74.5515H269.717C269.717 74.3403 269.496 74.2464 269.275 74.2464C269.078 74.2464 268.833 74.2933 268.833 74.5281C268.833 74.7393 269.029 74.7862 269.324 74.8097C270.011 74.8567 270.601 75.021 270.601 75.7721C270.601 76.4293 269.938 76.7579 269.275 76.7579C268.587 76.7579 267.924 76.4293 267.949 75.7486H268.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M273.229 74.3109H272.038V74.7601H273.13V75.4692H272.038V75.9656H273.254V76.6984H271.169V73.6018H273.204V74.3109H273.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M276.603 76.5802V76.6984H275.722L275.159 75.7292H274.719V76.6984H273.887V73.6018C274.376 73.6018 274.841 73.6018 275.306 73.6018C276.089 73.6018 276.456 74.1691 276.456 74.7128C276.456 75.0673 276.285 75.4219 275.967 75.611L276.603 76.5802ZM275.306 75.0673C275.551 75.0673 275.649 74.8782 275.649 74.6655C275.649 74.4764 275.526 74.2873 275.306 74.2873H274.719V75.0673H275.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M278.276 75.3341L278.553 74.5273L278.956 73.6018H279.887V73.7205L278.452 76.7579H278.1L276.666 73.7205V73.6018H277.597L278.024 74.5273L278.276 75.3341Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M281.781 76.0415V76.6984H280.076V76.0415H280.502V74.2586H280.126V73.6018H281.756V74.2586H281.38V76.0415H281.781Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M283.835 73.6018C284.509 73.6018 285.258 73.9551 285.383 74.7795H284.559C284.434 74.4497 284.184 74.332 283.835 74.332C283.335 74.332 283.011 74.6617 283.011 75.1799C283.011 75.6274 283.335 76.0278 283.835 76.0278C284.184 76.0278 284.484 75.8629 284.559 75.5332H285.383C285.258 76.4046 284.534 76.7579 283.835 76.7579C282.986 76.7579 282.162 76.2162 282.162 75.1799C282.162 74.12 282.986 73.6018 283.835 73.6018Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M287.944 74.3109H286.753V74.7601H287.845V75.4692H286.753V75.9656H287.969V76.6984H285.885V73.6018H287.919V74.3109H287.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M380.985 65.7411H378.447C377.8 65.7411 377.253 65.2343 377.253 64.6355V64.4052C377.253 63.8063 377.8 63.2996 378.447 63.2996H380.985C381.632 63.2996 382.179 63.8063 382.179 64.4052V64.6355C382.179 65.2574 381.657 65.7411 380.985 65.7411Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M363.426 68.5652C363.725 68.5652 363.799 68.5652 364.197 68.5652C364.943 68.5652 365.49 69.1052 365.49 69.833C365.49 70.5843 364.918 71.1008 364.147 71.1008C362.705 71.1008 361.81 71.1008 360.367 71.1008C359.82 71.1008 359.373 70.913 359.149 70.4199C358.95 70.1147 358.826 69.2695 359.621 68.5652C360.144 67.9782 365.913 61.7565 366.933 60.6765C366.982 60.6061 366.933 60.5122 366.858 60.5122C366.833 60.5122 366.833 60.5122 366.808 60.5122C364.645 60.5122 362.481 60.5122 360.318 60.5122C359.671 60.5122 359.174 60.1365 359.049 59.573C358.9 58.9156 359.199 58.3522 359.771 58.0939C359.945 58.0235 360.119 58 360.293 58C363.501 58 366.684 58 369.892 58C370.514 58 370.937 58.1878 371.185 58.7278C371.509 59.4087 371.16 59.8783 370.937 60.1835C370.489 60.7235 365.938 65.56 363.426 68.2834C363.401 68.3069 363.376 68.3539 363.327 68.3773C363.277 68.4478 363.327 68.5652 363.426 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M370.186 71.1009H367.648C367.001 71.1009 366.453 70.5818 366.453 69.9684V69.7324C366.453 69.1189 367.001 68.5999 367.648 68.5999H370.186C370.832 68.5999 371.38 69.1189 371.38 69.7324V69.9684C371.38 70.6054 370.832 71.1009 370.186 71.1009Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M373.715 64.5152C373.715 62.7341 373.715 60.9764 373.715 59.1952C373.715 58.4922 374.237 58 374.983 58C378.215 58 381.446 58 384.678 58C385.424 58 385.946 58.5156 385.946 59.2421C385.946 59.9686 385.424 60.5077 384.678 60.5077C381.993 60.5077 379.333 60.5077 376.649 60.5077C376.45 60.5077 376.4 60.5545 376.4 60.742C376.4 63.2731 376.4 65.8042 376.4 68.3353C376.4 68.5463 376.475 68.5697 376.649 68.5697C379.309 68.5697 381.993 68.5697 384.653 68.5697C385.25 68.5697 385.722 68.8978 385.896 69.39C386.095 69.9759 385.871 70.5618 385.399 70.8899C385.175 71.0305 384.902 71.1008 384.628 71.1008C381.446 71.1008 378.24 71.1008 375.058 71.1008C374.237 71.1008 373.74 70.5852 373.74 69.8118C373.715 68.0307 373.715 66.273 373.715 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M360.705 76.0415V76.6984H359V76.0415H359.426V74.2586H359.05V73.6018H360.68V74.2586H360.304V76.0415H360.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M364.534 76.7579H363.774L363.651 76.5451C363.381 76.687 363.062 76.7579 362.768 76.7579C361.933 76.7342 361.148 76.1668 361.148 75.1501C361.148 74.1806 361.811 73.5422 362.817 73.5422C363.651 73.5422 364.461 74.0861 364.461 75.1501C364.461 75.5284 364.363 75.8121 364.215 76.0722L364.559 76.6397V76.7579H364.534ZM362.792 76.0013C363.185 76.0013 363.602 75.7175 363.602 75.1501C363.602 74.5826 363.185 74.2989 362.792 74.2989C362.375 74.2989 361.982 74.5826 361.982 75.1501C361.982 75.7175 362.375 76.0013 362.792 76.0013Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M366.784 75.7486C366.784 75.9598 367.029 76.0772 367.299 76.0772C367.545 76.0772 367.79 75.9598 367.79 75.7955C367.79 75.4904 367.422 75.4904 367.201 75.4669C366.612 75.42 366.022 75.2087 366.022 74.5515C366.022 73.8708 366.612 73.5422 367.275 73.5422C367.889 73.5422 368.527 73.8708 368.527 74.5515H367.717C367.717 74.3403 367.496 74.2464 367.275 74.2464C367.078 74.2464 366.833 74.2933 366.833 74.5281C366.833 74.7393 367.029 74.7862 367.324 74.8097C368.011 74.8567 368.601 75.021 368.601 75.7721C368.601 76.4293 367.938 76.7579 367.275 76.7579C366.587 76.7579 365.924 76.4293 365.949 75.7486H366.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M371.229 74.3109H370.038V74.7601H371.13V75.4692H370.038V75.9656H371.254V76.6984H369.169V73.6018H371.204V74.3109H371.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M374.603 76.5802V76.6984H373.722L373.159 75.7292H372.719V76.6984H371.887V73.6018C372.376 73.6018 372.841 73.6018 373.306 73.6018C374.089 73.6018 374.456 74.1691 374.456 74.7128C374.456 75.0673 374.285 75.4219 373.967 75.611L374.603 76.5802ZM373.306 75.0673C373.551 75.0673 373.649 74.8782 373.649 74.6655C373.649 74.4764 373.526 74.2873 373.306 74.2873H372.719V75.0673H373.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M376.276 75.3341L376.553 74.5273L376.956 73.6018H377.887V73.7205L376.452 76.7579H376.1L374.666 73.7205V73.6018H375.597L376.024 74.5273L376.276 75.3341Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M379.781 76.0415V76.6984H378.076V76.0415H378.502V74.2586H378.126V73.6018H379.756V74.2586H379.38V76.0415H379.781Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M381.835 73.6018C382.509 73.6018 383.258 73.9551 383.383 74.7795H382.559C382.434 74.4497 382.184 74.332 381.835 74.332C381.335 74.332 381.011 74.6617 381.011 75.1799C381.011 75.6274 381.335 76.0278 381.835 76.0278C382.184 76.0278 382.484 75.8629 382.559 75.5332H383.383C383.258 76.4046 382.534 76.7579 381.835 76.7579C380.986 76.7579 380.162 76.2162 380.162 75.1799C380.162 74.12 380.986 73.6018 381.835 73.6018Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M385.944 74.3109H384.753V74.7601H385.845V75.4692H384.753V75.9656H385.969V76.6984H383.885V73.6018H385.919V74.3109H385.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M380.985 194.741H378.447C377.8 194.741 377.253 194.234 377.253 193.635V193.405C377.253 192.806 377.8 192.3 378.447 192.3H380.985C381.632 192.3 382.179 192.806 382.179 193.405V193.635C382.179 194.257 381.657 194.741 380.985 194.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M363.426 197.565C363.725 197.565 363.799 197.565 364.197 197.565C364.943 197.565 365.49 198.105 365.49 198.833C365.49 199.584 364.918 200.101 364.147 200.101C362.705 200.101 361.81 200.101 360.367 200.101C359.82 200.101 359.373 199.913 359.149 199.42C358.95 199.115 358.826 198.27 359.621 197.565C360.144 196.978 365.913 190.757 366.933 189.677C366.982 189.606 366.933 189.512 366.858 189.512C366.833 189.512 366.833 189.512 366.808 189.512C364.645 189.512 362.481 189.512 360.318 189.512C359.671 189.512 359.174 189.137 359.049 188.573C358.9 187.916 359.199 187.352 359.771 187.094C359.945 187.023 360.119 187 360.293 187C363.501 187 366.684 187 369.892 187C370.514 187 370.937 187.188 371.185 187.728C371.509 188.409 371.16 188.878 370.937 189.183C370.489 189.723 365.938 194.56 363.426 197.283C363.401 197.307 363.376 197.354 363.327 197.377C363.277 197.448 363.327 197.565 363.426 197.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M370.186 200.101H367.648C367.001 200.101 366.453 199.582 366.453 198.968V198.732C366.453 198.119 367.001 197.6 367.648 197.6H370.186C370.832 197.6 371.38 198.119 371.38 198.732V198.968C371.38 199.605 370.832 200.101 370.186 200.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M373.715 193.515C373.715 191.734 373.715 189.976 373.715 188.195C373.715 187.492 374.237 187 374.983 187C378.215 187 381.446 187 384.678 187C385.424 187 385.946 187.516 385.946 188.242C385.946 188.969 385.424 189.508 384.678 189.508C381.993 189.508 379.333 189.508 376.649 189.508C376.45 189.508 376.4 189.555 376.4 189.742C376.4 192.273 376.4 194.804 376.4 197.335C376.4 197.546 376.475 197.57 376.649 197.57C379.309 197.57 381.993 197.57 384.653 197.57C385.25 197.57 385.722 197.898 385.896 198.39C386.095 198.976 385.871 199.562 385.399 199.89C385.175 200.03 384.902 200.101 384.628 200.101C381.446 200.101 378.24 200.101 375.058 200.101C374.237 200.101 373.74 199.585 373.74 198.812C373.715 197.031 373.715 195.273 373.715 193.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M360.705 205.042V205.698H359V205.042H359.426V203.259H359.05V202.602H360.68V203.259H360.304V205.042H360.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M364.534 205.758H363.774L363.651 205.545C363.381 205.687 363.062 205.758 362.768 205.758C361.933 205.734 361.148 205.167 361.148 204.15C361.148 203.181 361.811 202.542 362.817 202.542C363.651 202.542 364.461 203.086 364.461 204.15C364.461 204.528 364.363 204.812 364.215 205.072L364.559 205.64V205.758H364.534ZM362.792 205.001C363.185 205.001 363.602 204.718 363.602 204.15C363.602 203.583 363.185 203.299 362.792 203.299C362.375 203.299 361.982 203.583 361.982 204.15C361.982 204.718 362.375 205.001 362.792 205.001Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M366.784 204.749C366.784 204.96 367.029 205.077 367.299 205.077C367.545 205.077 367.79 204.96 367.79 204.796C367.79 204.49 367.422 204.49 367.201 204.467C366.612 204.42 366.022 204.209 366.022 203.552C366.022 202.871 366.612 202.542 367.275 202.542C367.889 202.542 368.527 202.871 368.527 203.552H367.717C367.717 203.34 367.496 203.246 367.275 203.246C367.078 203.246 366.833 203.293 366.833 203.528C366.833 203.739 367.029 203.786 367.324 203.81C368.011 203.857 368.601 204.021 368.601 204.772C368.601 205.429 367.938 205.758 367.275 205.758C366.587 205.758 365.924 205.429 365.949 204.749H366.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M371.229 203.311H370.038V203.76H371.13V204.469H370.038V204.966H371.254V205.698H369.169V202.602H371.204V203.311H371.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M374.603 205.58V205.698H373.722L373.159 204.729H372.719V205.698H371.887V202.602C372.376 202.602 372.841 202.602 373.306 202.602C374.089 202.602 374.456 203.169 374.456 203.713C374.456 204.067 374.285 204.422 373.967 204.611L374.603 205.58ZM373.306 204.067C373.551 204.067 373.649 203.878 373.649 203.666C373.649 203.476 373.526 203.287 373.306 203.287H372.719V204.067H373.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M376.276 204.334L376.553 203.527L376.956 202.602H377.887V202.72L376.452 205.758H376.1L374.666 202.72V202.602H375.597L376.024 203.527L376.276 204.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M379.781 205.042V205.698H378.076V205.042H378.502V203.259H378.126V202.602H379.756V203.259H379.38V205.042H379.781Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M381.835 202.602C382.509 202.602 383.258 202.955 383.383 203.779H382.559C382.434 203.45 382.184 203.332 381.835 203.332C381.335 203.332 381.011 203.662 381.011 204.18C381.011 204.627 381.335 205.028 381.835 205.028C382.184 205.028 382.484 204.863 382.559 204.533H383.383C383.258 205.405 382.534 205.758 381.835 205.758C380.986 205.758 380.162 205.216 380.162 204.18C380.162 203.12 380.986 202.602 381.835 202.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M385.944 203.311H384.753V203.76H385.845V204.469H384.753V204.966H385.969V205.698H383.885V202.602H385.919V203.311H385.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M282.985 194.741H280.447C279.8 194.741 279.253 194.234 279.253 193.635V193.405C279.253 192.806 279.8 192.3 280.447 192.3H282.985C283.632 192.3 284.179 192.806 284.179 193.405V193.635C284.179 194.257 283.657 194.741 282.985 194.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M265.426 197.565C265.725 197.565 265.799 197.565 266.197 197.565C266.943 197.565 267.49 198.105 267.49 198.833C267.49 199.584 266.918 200.101 266.147 200.101C264.705 200.101 263.81 200.101 262.367 200.101C261.82 200.101 261.373 199.913 261.149 199.42C260.95 199.115 260.826 198.27 261.621 197.565C262.144 196.978 267.913 190.757 268.933 189.677C268.982 189.606 268.933 189.512 268.858 189.512C268.833 189.512 268.833 189.512 268.808 189.512C266.645 189.512 264.481 189.512 262.318 189.512C261.671 189.512 261.174 189.137 261.049 188.573C260.9 187.916 261.199 187.352 261.771 187.094C261.945 187.023 262.119 187 262.293 187C265.501 187 268.684 187 271.892 187C272.514 187 272.937 187.188 273.185 187.728C273.509 188.409 273.16 188.878 272.937 189.183C272.489 189.723 267.938 194.56 265.426 197.283C265.401 197.307 265.376 197.354 265.327 197.377C265.277 197.448 265.327 197.565 265.426 197.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M272.186 200.101H269.648C269.001 200.101 268.453 199.582 268.453 198.968V198.732C268.453 198.119 269.001 197.6 269.648 197.6H272.186C272.832 197.6 273.38 198.119 273.38 198.732V198.968C273.38 199.605 272.832 200.101 272.186 200.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M275.715 193.515C275.715 191.734 275.715 189.976 275.715 188.195C275.715 187.492 276.237 187 276.983 187C280.215 187 283.446 187 286.678 187C287.424 187 287.946 187.516 287.946 188.242C287.946 188.969 287.424 189.508 286.678 189.508C283.993 189.508 281.333 189.508 278.649 189.508C278.45 189.508 278.4 189.555 278.4 189.742C278.4 192.273 278.4 194.804 278.4 197.335C278.4 197.546 278.475 197.57 278.649 197.57C281.309 197.57 283.993 197.57 286.653 197.57C287.25 197.57 287.722 197.898 287.896 198.39C288.095 198.976 287.871 199.562 287.399 199.89C287.175 200.03 286.902 200.101 286.628 200.101C283.446 200.101 280.24 200.101 277.058 200.101C276.237 200.101 275.74 199.585 275.74 198.812C275.715 197.031 275.715 195.273 275.715 193.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M262.705 205.042V205.698H261V205.042H261.426V203.259H261.05V202.602H262.68V203.259H262.304V205.042H262.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M266.534 205.758H265.774L265.651 205.545C265.381 205.687 265.062 205.758 264.768 205.758C263.933 205.734 263.148 205.167 263.148 204.15C263.148 203.181 263.811 202.542 264.817 202.542C265.651 202.542 266.461 203.086 266.461 204.15C266.461 204.528 266.363 204.812 266.215 205.072L266.559 205.64V205.758H266.534ZM264.792 205.001C265.185 205.001 265.602 204.718 265.602 204.15C265.602 203.583 265.185 203.299 264.792 203.299C264.375 203.299 263.982 203.583 263.982 204.15C263.982 204.718 264.375 205.001 264.792 205.001Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M268.784 204.749C268.784 204.96 269.029 205.077 269.299 205.077C269.545 205.077 269.79 204.96 269.79 204.796C269.79 204.49 269.422 204.49 269.201 204.467C268.612 204.42 268.022 204.209 268.022 203.552C268.022 202.871 268.612 202.542 269.275 202.542C269.889 202.542 270.527 202.871 270.527 203.552H269.717C269.717 203.34 269.496 203.246 269.275 203.246C269.078 203.246 268.833 203.293 268.833 203.528C268.833 203.739 269.029 203.786 269.324 203.81C270.011 203.857 270.601 204.021 270.601 204.772C270.601 205.429 269.938 205.758 269.275 205.758C268.587 205.758 267.924 205.429 267.949 204.749H268.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M273.229 203.311H272.038V203.76H273.13V204.469H272.038V204.966H273.254V205.698H271.169V202.602H273.204V203.311H273.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M276.603 205.58V205.698H275.722L275.159 204.729H274.719V205.698H273.887V202.602C274.376 202.602 274.841 202.602 275.306 202.602C276.089 202.602 276.456 203.169 276.456 203.713C276.456 204.067 276.285 204.422 275.967 204.611L276.603 205.58ZM275.306 204.067C275.551 204.067 275.649 203.878 275.649 203.666C275.649 203.476 275.526 203.287 275.306 203.287H274.719V204.067H275.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M278.276 204.334L278.553 203.527L278.956 202.602H279.887V202.72L278.452 205.758H278.1L276.666 202.72V202.602H277.597L278.024 203.527L278.276 204.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M281.781 205.042V205.698H280.076V205.042H280.502V203.259H280.126V202.602H281.756V203.259H281.38V205.042H281.781Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M283.835 202.602C284.509 202.602 285.258 202.955 285.383 203.779H284.559C284.434 203.45 284.184 203.332 283.835 203.332C283.335 203.332 283.011 203.662 283.011 204.18C283.011 204.627 283.335 205.028 283.835 205.028C284.184 205.028 284.484 204.863 284.559 204.533H285.383C285.258 205.405 284.534 205.758 283.835 205.758C282.986 205.758 282.162 205.216 282.162 204.18C282.162 203.12 282.986 202.602 283.835 202.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M287.944 203.311H286.753V203.76H287.845V204.469H286.753V204.966H287.969V205.698H285.885V202.602H287.919V203.311H287.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M184.985 194.741H182.447C181.8 194.741 181.253 194.234 181.253 193.635V193.405C181.253 192.806 181.8 192.3 182.447 192.3H184.985C185.632 192.3 186.179 192.806 186.179 193.405V193.635C186.179 194.257 185.657 194.741 184.985 194.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M167.426 197.565C167.725 197.565 167.799 197.565 168.197 197.565C168.943 197.565 169.49 198.105 169.49 198.833C169.49 199.584 168.918 200.101 168.147 200.101C166.705 200.101 165.81 200.101 164.367 200.101C163.82 200.101 163.373 199.913 163.149 199.42C162.95 199.115 162.826 198.27 163.621 197.565C164.144 196.978 169.913 190.757 170.933 189.677C170.982 189.606 170.933 189.512 170.858 189.512C170.833 189.512 170.833 189.512 170.808 189.512C168.645 189.512 166.481 189.512 164.318 189.512C163.671 189.512 163.174 189.137 163.049 188.573C162.9 187.916 163.199 187.352 163.771 187.094C163.945 187.023 164.119 187 164.293 187C167.501 187 170.684 187 173.892 187C174.514 187 174.937 187.188 175.185 187.728C175.509 188.409 175.16 188.878 174.937 189.183C174.489 189.723 169.938 194.56 167.426 197.283C167.401 197.307 167.376 197.354 167.327 197.377C167.277 197.448 167.327 197.565 167.426 197.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M174.186 200.101H171.648C171.001 200.101 170.453 199.582 170.453 198.968V198.732C170.453 198.119 171.001 197.6 171.648 197.6H174.186C174.832 197.6 175.38 198.119 175.38 198.732V198.968C175.38 199.605 174.832 200.101 174.186 200.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M177.715 193.515C177.715 191.734 177.715 189.976 177.715 188.195C177.715 187.492 178.237 187 178.983 187C182.215 187 185.446 187 188.678 187C189.424 187 189.946 187.516 189.946 188.242C189.946 188.969 189.424 189.508 188.678 189.508C185.993 189.508 183.333 189.508 180.649 189.508C180.45 189.508 180.4 189.555 180.4 189.742C180.4 192.273 180.4 194.804 180.4 197.335C180.4 197.546 180.475 197.57 180.649 197.57C183.309 197.57 185.993 197.57 188.653 197.57C189.25 197.57 189.722 197.898 189.896 198.39C190.095 198.976 189.871 199.562 189.399 199.89C189.175 200.03 188.902 200.101 188.628 200.101C185.446 200.101 182.24 200.101 179.058 200.101C178.237 200.101 177.74 199.585 177.74 198.812C177.715 197.031 177.715 195.273 177.715 193.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M164.705 205.042V205.698H163V205.042H163.426V203.259H163.05V202.602H164.68V203.259H164.304V205.042H164.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M168.534 205.758H167.774L167.651 205.545C167.381 205.687 167.062 205.758 166.768 205.758C165.933 205.734 165.148 205.167 165.148 204.15C165.148 203.181 165.811 202.542 166.817 202.542C167.651 202.542 168.461 203.086 168.461 204.15C168.461 204.528 168.363 204.812 168.215 205.072L168.559 205.64V205.758H168.534ZM166.792 205.001C167.185 205.001 167.602 204.718 167.602 204.15C167.602 203.583 167.185 203.299 166.792 203.299C166.375 203.299 165.982 203.583 165.982 204.15C165.982 204.718 166.375 205.001 166.792 205.001Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M170.784 204.749C170.784 204.96 171.029 205.077 171.299 205.077C171.545 205.077 171.79 204.96 171.79 204.796C171.79 204.49 171.422 204.49 171.201 204.467C170.612 204.42 170.022 204.209 170.022 203.552C170.022 202.871 170.612 202.542 171.275 202.542C171.889 202.542 172.527 202.871 172.527 203.552H171.717C171.717 203.34 171.496 203.246 171.275 203.246C171.078 203.246 170.833 203.293 170.833 203.528C170.833 203.739 171.029 203.786 171.324 203.81C172.011 203.857 172.601 204.021 172.601 204.772C172.601 205.429 171.938 205.758 171.275 205.758C170.587 205.758 169.924 205.429 169.949 204.749H170.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M175.229 203.311H174.038V203.76H175.13V204.469H174.038V204.966H175.254V205.698H173.169V202.602H175.204V203.311H175.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M178.603 205.58V205.698H177.722L177.159 204.729H176.719V205.698H175.887V202.602C176.376 202.602 176.841 202.602 177.306 202.602C178.089 202.602 178.456 203.169 178.456 203.713C178.456 204.067 178.285 204.422 177.967 204.611L178.603 205.58ZM177.306 204.067C177.551 204.067 177.649 203.878 177.649 203.666C177.649 203.476 177.526 203.287 177.306 203.287H176.719V204.067H177.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M180.276 204.334L180.553 203.527L180.956 202.602H181.887V202.72L180.452 205.758H180.1L178.666 202.72V202.602H179.597L180.024 203.527L180.276 204.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M183.781 205.042V205.698H182.076V205.042H182.502V203.259H182.126V202.602H183.756V203.259H183.38V205.042H183.781Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M185.835 202.602C186.509 202.602 187.258 202.955 187.383 203.779H186.559C186.434 203.45 186.184 203.332 185.835 203.332C185.335 203.332 185.011 203.662 185.011 204.18C185.011 204.627 185.335 205.028 185.835 205.028C186.184 205.028 186.484 204.863 186.559 204.533H187.383C187.258 205.405 186.534 205.758 185.835 205.758C184.986 205.758 184.162 205.216 184.162 204.18C184.162 203.12 184.986 202.602 185.835 202.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M189.944 203.311H188.753V203.76H189.845V204.469H188.753V204.966H189.969V205.698H187.885V202.602H189.919V203.311H189.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M85.9851 194.741H83.4471C82.8002 194.741 82.2528 194.234 82.2528 193.635V193.405C82.2528 192.806 82.8002 192.3 83.4471 192.3H85.9851C86.632 192.3 87.1794 192.806 87.1794 193.405V193.635C87.1794 194.257 86.6569 194.741 85.9851 194.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M68.4262 197.565C68.7247 197.565 68.7993 197.565 69.1972 197.565C69.9432 197.565 70.4903 198.105 70.4903 198.833C70.4903 199.584 69.9184 200.101 69.1474 200.101C67.705 200.101 66.8098 200.101 65.3674 200.101C64.8203 200.101 64.3727 199.913 64.1488 199.42C63.9499 199.115 63.8255 198.27 64.6213 197.565C65.1436 196.978 70.9131 190.757 71.9327 189.677C71.9824 189.606 71.9327 189.512 71.8581 189.512C71.8332 189.512 71.8332 189.512 71.8084 189.512C69.6448 189.512 67.4812 189.512 65.3177 189.512C64.6711 189.512 64.1737 189.137 64.0494 188.573C63.9002 187.916 64.1986 187.352 64.7706 187.094C64.9446 187.023 65.1187 187 65.2928 187C68.5008 187 71.684 187 74.8921 187C75.5138 187 75.9366 187.188 76.1852 187.728C76.5085 188.409 76.1604 188.878 75.9366 189.183C75.4889 189.723 70.938 194.56 68.4262 197.283C68.4014 197.307 68.3765 197.354 68.3268 197.377C68.277 197.448 68.3268 197.565 68.4262 197.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M75.1856 200.101H72.6476C72.0007 200.101 71.4533 199.582 71.4533 198.968V198.732C71.4533 198.119 72.0007 197.6 72.6476 197.6H75.1856C75.8325 197.6 76.3799 198.119 76.3799 198.732V198.968C76.3799 199.605 75.8325 200.101 75.1856 200.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M78.7154 193.515C78.7154 191.734 78.7154 189.976 78.7154 188.195C78.7154 187.492 79.2374 187 79.9832 187C83.2148 187 86.4464 187 89.678 187C90.4238 187 90.9458 187.516 90.9458 188.242C90.9458 188.969 90.4238 189.508 89.678 189.508C86.9933 189.508 84.3334 189.508 81.6487 189.508C81.4498 189.508 81.4001 189.555 81.4001 189.742C81.4001 192.273 81.4001 194.804 81.4001 197.335C81.4001 197.546 81.4747 197.57 81.6487 197.57C84.3086 197.57 86.9933 197.57 89.6532 197.57C90.2498 197.57 90.7221 197.898 90.8961 198.39C91.095 198.976 90.8712 199.562 90.3989 199.89C90.1752 200.03 89.9018 200.101 89.6283 200.101C86.4464 200.101 83.2397 200.101 80.0578 200.101C79.2374 200.101 78.7403 199.585 78.7403 198.812C78.7154 197.031 78.7154 195.273 78.7154 193.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M65.7054 205.042V205.698H64V205.042H64.4263V203.259H64.0502V202.602H65.6803V203.259H65.3041V205.042H65.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M69.5344 205.758H68.7737L68.651 205.545C68.3811 205.687 68.0621 205.758 67.7676 205.758C66.9334 205.734 66.1482 205.167 66.1482 204.15C66.1482 203.181 66.8107 202.542 67.8167 202.542C68.651 202.542 69.4607 203.086 69.4607 204.15C69.4607 204.528 69.3626 204.812 69.2154 205.072L69.5589 205.64V205.758H69.5344ZM67.7922 205.001C68.1848 205.001 68.6019 204.718 68.6019 204.15C68.6019 203.583 68.1848 203.299 67.7922 203.299C67.375 203.299 66.9824 203.583 66.9824 204.15C66.9824 204.718 67.375 205.001 67.7922 205.001Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M71.7835 204.749C71.7835 204.96 72.0291 205.077 72.2992 205.077C72.5448 205.077 72.7904 204.96 72.7904 204.796C72.7904 204.49 72.422 204.49 72.201 204.467C71.6116 204.42 71.0223 204.209 71.0223 203.552C71.0223 202.871 71.6116 202.542 72.2747 202.542C72.8886 202.542 73.5271 202.871 73.5271 203.552H72.7167C72.7167 203.34 72.4957 203.246 72.2747 203.246C72.0782 203.246 71.8326 203.293 71.8326 203.528C71.8326 203.739 72.0291 203.786 72.3238 203.81C73.0114 203.857 73.6007 204.021 73.6007 204.772C73.6007 205.429 72.9377 205.758 72.2747 205.758C71.5871 205.758 70.924 205.429 70.9486 204.749H71.7835Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M76.2288 203.311H75.0377V203.76H76.1295V204.469H75.0377V204.966H76.2536V205.698H74.1692V202.602H76.2039V203.311H76.2288Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M79.603 205.58V205.698H78.7222L78.1594 204.729H77.719V205.698H76.8871V202.602C77.3765 202.602 77.8413 202.602 78.3062 202.602C79.0892 202.602 79.4562 203.169 79.4562 203.713C79.4562 204.067 79.285 204.422 78.9669 204.611L79.603 205.58ZM78.3062 204.067C78.5509 204.067 78.6488 203.878 78.6488 203.666C78.6488 203.476 78.5265 203.287 78.3062 203.287H77.719V204.067H78.3062Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M81.2761 204.334L81.553 203.527L81.9556 202.602H82.8868V202.72L81.4523 205.758H81.1L79.6655 202.72V202.602H80.5967L81.0245 203.527L81.2761 204.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M84.7814 205.042V205.698H83.0761V205.042H83.5024V203.259H83.1262V202.602H84.7564V203.259H84.3802V205.042H84.7814Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M86.8348 202.602C87.5091 202.602 88.2582 202.955 88.383 203.779H87.559C87.4341 203.45 87.1844 203.332 86.8348 203.332C86.3354 203.332 86.0108 203.662 86.0108 204.18C86.0108 204.627 86.3354 205.028 86.8348 205.028C87.1844 205.028 87.4841 204.863 87.559 204.533H88.383C88.2582 205.405 87.534 205.758 86.8348 205.758C85.9858 205.758 85.1618 205.216 85.1618 204.18C85.1618 203.12 85.9858 202.602 86.8348 202.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M90.9442 203.311H89.7531V203.76H90.8449V204.469H89.7531V204.966H90.969V205.698H88.8847V202.602H90.9194V203.311H90.9442Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <defs>
          <pattern id="8-0-pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use
              xlinkHref="#image0"
              transform="translate(-0.00768784) scale(0.00100915 0.00153332)"
            />
          </pattern>
          <filter
            id="8-0-filter0_i"
            x="37"
            y="41"
            width="80.9399"
            height="111.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="8-0-filter1_i"
            x="136"
            y="41"
            width="80.9399"
            height="111.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="8-0-filter2_i"
            x="234"
            y="41"
            width="80.9399"
            height="111.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="8-0-filter3_i"
            x="332"
            y="41"
            width="80.9399"
            height="111.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="8-0-filter4_i"
            x="332"
            y="170"
            width="80.9399"
            height="111.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="8-0-filter5_i"
            x="234"
            y="170"
            width="80.9399"
            height="111.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="8-0-filter6_i"
            x="136"
            y="170"
            width="80.9399"
            height="111.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="8-0-filter7_i"
            x="37"
            y="170"
            width="80.9399"
            height="111.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <linearGradient
            id="8-0-paint0_linear"
            x1="301.5"
            y1="291"
            x2="301.5"
            y2="316"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DBDDE1" />
            <stop offset="1" stopColor="#F9F9F9" />
          </linearGradient>
        </defs>
      </svg>
      {!withoutBackground && <ContainerBackground />}
    </ProductImageWrapper>
  )
}
