function createHeaders(headers) {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...headers,
  }
}

/**
 * @todo Add OAuth Bearer authenficiation support
 *
 * @param {String} path Example: `/api/session` or `/session`
 * @param {Object} options RequestInit
 * @return {Object} response
 */
export const fetchURL = async (
  path: string,
  options: RequestInit = {},
): Promise<Record<string, any>> => {
  const response = await fetch(path, {
    ...options,
    // mode: 'cors', credentials: 'include',
    headers: createHeaders(options.headers),
  })

  return response.json()
}

export default fetchURL
