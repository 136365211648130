import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement23Square3VerticalCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.9765 198C36.8849 198 36 198.885 36 199.976C36 201.068 36.8849 201.953 37.9765 201.953L40.1665 201.953L40.1665 201.953L51 201.953L51 201.954L54.0235 201.954C55.1151 201.954 56 201.069 56 199.978C56 198.886 55.1151 198.001 54.0235 198.001L51.8332 198.001L51.8332 198L41 198L41 198L37.9765 198Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 40.1665 198)"
          fill="#ABABAB"
        />
        <path
          d="M36.9399 198C36.4208 198 36 198.421 36 198.94L36 201.013C36 201.532 36.4208 201.953 36.9399 201.953L41 201.953L41 198L36.9399 198Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M55.0601 201.954C55.5792 201.954 56 201.533 56 201.014L56 198.941C56 198.422 55.5792 198.001 55.0601 198.001L51 198.001L51 201.954L55.0601 201.954Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 41.8334 198)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#2filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M165.976 198C164.885 198 164 198.885 164 199.976C164 201.068 164.885 201.953 165.976 201.953L168.167 201.953L168.167 201.953L179 201.953L179 201.954L182.024 201.954C183.115 201.954 184 201.069 184 199.978C184 198.886 183.115 198.001 182.024 198.001L179.833 198.001L179.833 198L169 198L169 198L165.976 198Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 168.167 198)"
          fill="#ABABAB"
        />
        <path
          d="M164.94 198C164.421 198 164 198.421 164 198.94L164 201.013C164 201.532 164.421 201.953 164.94 201.953L169 201.953L169 198L164.94 198Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M183.06 201.954C183.579 201.954 184 201.533 184 201.014L184 198.941C184 198.422 183.579 198.001 183.06 198.001L179 198.001L179 201.954L183.06 201.954Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 169.833 198)"
          fill="url(#paint7_linear)"
        />
        <path d="M174.599 185.5L110 132.646L45.4007 185.5L174.599 185.5Z" stroke="white" />
        <g filter="url(#2filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 120H32V198H188V120ZM177 131H43V187H177V131Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 120H32V198H33V121H187V198H188V120Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2filter3_i)">
          <rect
            x="43.47"
            y="131.47"
            width="133.06"
            height="55.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="124.53"
          width="146.94"
          height="68.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="116.528"
          y="122"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 116.528 122)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#2filter4_d)">
          <rect
            x="115.884"
            y="122.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 115.884 122.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115.766 124.695C115.42 123.542 114.35 122.702 113.084 122.702L106.444 122.702C104.898 122.702 103.644 123.955 103.644 125.502C103.644 125.537 103.645 125.572 103.646 125.607C103.993 126.76 105.062 127.6 106.328 127.6L112.968 127.6C114.515 127.6 115.768 126.346 115.768 124.8C115.768 124.765 115.768 124.73 115.766 124.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M114.409 123.035C114.266 123.012 114.118 123 113.968 123L107.328 123C105.782 123 104.528 124.254 104.528 125.8C104.528 126.867 105.125 127.795 106.003 128.267C104.667 128.056 103.644 126.898 103.644 125.502C103.644 123.955 104.898 122.702 106.444 122.702L113.084 122.702C113.564 122.702 114.015 122.822 114.409 123.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M129.528 125.5C129.528 124.672 128.857 124 128.028 124L127.814 124L126.528 124L108.028 124C107.2 124 106.528 124.672 106.528 125.5V125.5C106.528 126.328 107.2 127 108.028 127L124.528 127L128.028 127L128.243 127C128.953 127 129.528 126.424 129.528 125.714V125.714C129.528 125.677 129.527 125.64 129.525 125.604C129.527 125.569 129.528 125.535 129.528 125.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="2filter0_d"
            x="34.1201"
            y="196.12"
            width="25.6396"
            height="9.59374"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2filter1_d"
            x="162.12"
            y="196.12"
            width="25.6396"
            height="9.59374"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2filter2_d"
            x="27.3003"
            y="115.3"
            width="167.279"
            height="89.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2filter3_i"
            x="43"
            y="131"
            width="134.94"
            height="58.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2filter4_d"
            x="101.764"
            y="120.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2filter5_d"
            x="105.649"
            y="122.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="53.5"
            y1="195.232"
            x2="53.5"
            y2="202.745"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="38.5"
            y1="204.72"
            x2="38.5"
            y2="197.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="53.5"
            y1="195.234"
            x2="53.5"
            y2="202.745"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="181.5"
            y1="195.232"
            x2="181.5"
            y2="202.745"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="166.5"
            y1="204.72"
            x2="166.5"
            y2="197.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="181.5"
            y1="195.234"
            x2="181.5"
            y2="202.745"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
