import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { TextareaStatus, TextareaStyle, TextareaWrapper, TextareaReq } from './styles'
import { ITextareaProps } from '../../views/calculator/interface'
import { validateRequiredText } from '../../utilits'
import { CloseIcon } from '../../assets/icons'
import {InputStatus} from "../input";

export const Textarea: FC<ITextareaProps> = props => {
  const {
    id = '',
    value: initValue = '',
    defaultValue = '',
    placeholder,
    name = '',
    req = false,
    disabled = false,
    errorMessage,
    onChange,
    onFocus,
    onBlur
  } = props
  const [value, setValue] = useState<string | boolean>(defaultValue)
  const [error, setError] = useState('')
  const [firstLoad, setFirstLoad] = useState(true)
  const timerRef = useRef<any>(0)

  useEffect(() => {
    //onChange && onChange(value, name)
    if (onChange && timerRef && !firstLoad) {
      //console.log({ value, timerRef,  })
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }

      timerRef.current = setTimeout(() => {
        onChange(value, name)
        timerRef.current = 0
      }, 300)
    }
    
    setFirstLoad(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, firstLoad])

  useEffect(() => {
    setValue(initValue)
  }, [initValue])

  /*useEffect(() => {
    if (errorMessage) {
      setError(errorMessage)
    }
  }, [errorMessage])*/

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      
      let result: string | boolean = event?.target?.value || ''

      if (result !== value) {
        setValue(result)
      }
    },
    [value],
  )

  const TextareaProps = useMemo(() => {
    const result = {}

    if (id) {
      result['id'] = id
    }
    if (name) {
      result['name'] = name
    }
    if (placeholder) {
      result['placeholder'] = placeholder
    }

    return result
  }, [id, name, placeholder])

  const handleFocus = useCallback(() => {
    setError('')

    if (onFocus) {
      onFocus()
    }
  }, [onFocus])

  const handleBlur = useCallback(() => {
    let newError: string

    if (onBlur) {
      onBlur()
    }

    newError = validateRequiredText(`${value}`)

    if (newError) {
      setError(newError)
    }
  }, [errorMessage, onBlur, value])

  return (
    <TextareaWrapper>
      { req && <TextareaReq >*</TextareaReq> }
      <TextareaStyle
        {...TextareaProps}
        value={`${value}`}
        defaultValue={defaultValue}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        isError={Boolean(error)}
        autoComplete="off"
        disabled={disabled}
      />
      {error && (
        <InputStatus title={error}>
          <span>{error}</span>
        </InputStatus>
      )}

    </TextareaWrapper>
  )
}
