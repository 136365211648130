import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement52Square5Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#5-2_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M421 209.999C421 208.895 420.105 208 419.001 208C417.896 208 417.001 208.895 417.001 209.999V212.167H417.001V223H417V226.001C417 227.105 417.895 228 418.999 228C420.104 228 420.999 227.105 420.999 226.001V223.833H421V213H421V209.999Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 421 212.166)"
          fill="#ABABAB"
        />
        <path
          d="M421 208.94C421 208.421 420.579 208 420.06 208H417.941C417.422 208 417.001 208.421 417.001 208.94V213H421V208.94Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M417 227.06C417 227.579 417.421 228 417.94 228H420.059C420.578 228 420.999 227.579 420.999 227.06V223H417V227.06Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 421 213.833)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#5-2_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 261C417 259.895 417.895 259 419 259C420.105 259 421 259.895 421 261V275.56H417V261ZM417 275.56H421V277.4H421.06C421.579 277.4 422 277.821 422 278.34V280.12C422 281.158 421.158 282 420.12 282H418.88C417.842 282 417 281.158 417 280.12V277.4V277.4V275.56Z"
            fill="white"
          />
        </g>
        <rect x="417" y="276.25" width="3.99998" height="0.95833" fill="#ABABAB" />
        <path
          d="M417 259.94C417 259.421 417.421 259 417.94 259H420.06C420.579 259 421 259.421 421 259.94V276.25H417V259.94Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M417 281.06C417 281.579 417.421 282 417.94 282H421.06C421.579 282 422 281.579 422 281.06V278.148C422 277.629 421.579 277.208 421.06 277.208H417V281.06Z"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#5-2_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 203H297V287H417V203ZM406 214H308V276H406V214Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 203H297V287H298V204H416V287H417V203Z"
          fill="#FBFBFB"
        />
        <rect
          x="301.53"
          y="207.53"
          width="110.94"
          height="74.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#5-2_filter3_i)">
          <rect
            x="308.47"
            y="214.47"
            width="97.0601"
            height="61.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="299"
          y="239"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#5-2_filter4_d)">
          <rect x="299.702" y="239.645" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M300.286 250.796C300.798 251.458 301.6 251.884 302.502 251.884C304.049 251.884 305.302 250.631 305.302 249.084V242.444C305.302 241.456 304.79 240.587 304.017 240.089C304.383 240.562 304.601 241.156 304.601 241.8V248.44C304.601 249.987 303.347 251.24 301.801 251.24C301.243 251.24 300.723 251.077 300.286 250.796Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M302.502 239.645C300.955 239.645 299.702 240.898 299.702 242.445V248.44C299.702 248.461 299.702 248.481 299.702 248.501V243.024C299.702 241.478 300.956 240.224 302.502 240.224C304.028 240.224 305.269 241.445 305.302 242.963V242.445C305.302 240.898 304.048 239.645 302.502 239.645Z"
          fill="#FBFBFB"
        />
        <g filter="url(#5-2_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M302.5 243C301.672 243 301 243.672 301 244.5V244.714V246V264.5C301 265.328 301.672 266 302.5 266C303.328 266 304 265.328 304 264.5V248V244.5L304 244.286C304 243.576 303.424 243 302.714 243C302.677 243 302.64 243.001 302.604 243.004C302.569 243.001 302.535 243 302.5 243Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M404.5 274.32L404.5 215.68L310.676 245L404.5 274.32Z" stroke="white" />
        <path d="M403.96 274.5L310.04 274.5L357 215.8L403.96 274.5Z" stroke="white" />
        <defs>
          <filter
            id="5-2_filter0_d"
            x="415.12"
            y="206.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-2_filter1_d"
            x="415.12"
            y="257.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-2_filter2_d"
            x="292.3"
            y="198.3"
            width="131.279"
            height="95.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-2_filter3_i"
            x="308"
            y="214"
            width="98.9399"
            height="64.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-2_filter4_d"
            x="297.822"
            y="237.765"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-2_filter5_d"
            x="299.12"
            y="241.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="423.8"
            y1="225.5"
            x2="416.2"
            y2="225.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="414.202"
            y1="210.5"
            x2="421.8"
            y2="210.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="423.798"
            y1="225.5"
            x2="416.2"
            y2="225.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="423.8"
            y1="267.625"
            x2="416.2"
            y2="267.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="425.5"
            y1="279.604"
            x2="416"
            y2="279.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
