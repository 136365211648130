import React, { FC, useState, useCallback } from 'react'

import { IInfoProps } from './interface'
import { InfoTooltip } from '../../assets/icons'
import { InfoWrapper, InfoTooltipBlock } from './styles'

export const Info: FC<IInfoProps> = props => {
  const { color, children, showIcon = true, coord } = props
  const [show, setShow] = useState(false)

  const handleMouseOver = useCallback(() => {
    setShow(true)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setShow(false)
  }, [])

  return (
    <InfoWrapper onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
      {showIcon && <InfoTooltip color={color} />}
      <InfoTooltipBlock show={show} coord={coord}>
        {children}
      </InfoTooltipBlock>
    </InfoWrapper>
  )
}
