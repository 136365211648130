import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement24Square41Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-4-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M154.001 291C155.105 291 156 290.105 156 289.001C156 287.896 155.105 287.001 154.001 287.001L151.833 287.001L151.833 287.001L141 287.001L141 287L137.999 287C136.895 287 136 287.895 136 288.999C136 290.104 136.895 290.999 137.999 290.999L140.167 290.999L140.167 291L151 291L151 291L154.001 291Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 151.833 291)"
          fill="#ABABAB"
        />
        <path
          d="M155.06 291C155.579 291 156 290.579 156 290.06L156 287.941C156 287.422 155.579 287.001 155.06 287.001L151 287.001L151 291L155.06 291Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M136.94 287C136.421 287 136 287.421 136 287.94L136 290.059C136 290.578 136.421 290.999 136.94 290.999L141 290.999L141 287L136.94 287Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 150.167 291)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#2-4-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M215.001 291C216.105 291 217 290.105 217 289.001C217 287.896 216.105 287.001 215.001 287.001L212.833 287.001L212.833 287.001L202 287.001L202 287L198.999 287C197.895 287 197 287.895 197 288.999C197 290.104 197.895 290.999 198.999 290.999L201.167 290.999L201.167 291L212 291L212 291L215.001 291Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 212.833 291)"
          fill="#ABABAB"
        />
        <path
          d="M216.06 291C216.579 291 217 290.579 217 290.06L217 287.941C217 287.422 216.579 287.001 216.06 287.001L212 287.001L212 291L216.06 291Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M197.94 287C197.421 287 197 287.421 197 287.94L197 290.059C197 290.578 197.421 290.999 197.94 290.999L202 290.999L202 287L197.94 287Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 211.167 291)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#2-4-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M221 34H132V287H221V34ZM210 45H143V276H210V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M221 34H132V287H133.219V35H219.781V287H221V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-4-filter3_i)">
          <rect
            x="143.47"
            y="45.47"
            width="66.0601"
            height="230.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="136.53"
          y="38.53"
          width="79.9399"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="183.528"
          y="36"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 183.528 36)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#2-4-filter4_d)">
          <rect
            x="182.884"
            y="36.7018"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 182.884 36.7018)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M182.766 38.695C182.42 37.5419 181.35 36.7018 180.084 36.7018L173.444 36.7018C171.898 36.7018 170.644 37.9554 170.644 39.5018C170.644 39.537 170.645 39.572 170.646 39.6068C170.993 40.7599 172.062 41.6 173.328 41.6L179.968 41.6C181.515 41.6 182.768 40.3464 182.768 38.8C182.768 38.7649 182.768 38.7299 182.766 38.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M181.409 37.0346C181.266 37.0118 181.118 37 180.968 37L174.328 37C172.782 37 171.528 38.2536 171.528 39.8C171.528 40.8671 172.125 41.7947 173.003 42.2673C171.667 42.0558 170.644 40.8982 170.644 39.5018C170.644 37.9554 171.898 36.7018 173.444 36.7018L180.084 36.7018C180.564 36.7018 181.015 36.8223 181.409 37.0346Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-4-filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M196.528 39.5C196.528 38.6716 195.857 38 195.028 38L194.814 38L193.528 38L175.028 38C174.2 38 173.528 38.6716 173.528 39.5V39.5C173.528 40.3284 174.2 41 175.028 41L191.528 41L195.028 41L195.243 41C195.953 41 196.528 40.4244 196.528 39.7143V39.7143C196.528 39.6771 196.527 39.6401 196.525 39.6035C196.527 39.5693 196.528 39.5348 196.528 39.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M208.424 274.5L176.5 49.5584L144.576 274.5L208.424 274.5Z" stroke="white" />
        <defs>
          <filter
            id="2-4-filter0_d"
            x="134.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-4-filter1_d"
            x="195.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-4-filter2_d"
            x="127.3"
            y="29.3003"
            width="100.279"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-4-filter3_i"
            x="143"
            y="45"
            width="67.9399"
            height="233.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-4-filter4_d"
            x="168.765"
            y="34.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-4-filter5_d"
            x="172.649"
            y="36.1201"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="138.5"
            y1="293.8"
            x2="138.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="153.5"
            y1="284.202"
            x2="153.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="138.5"
            y1="293.798"
            x2="138.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="199.5"
            y1="293.8"
            x2="199.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="214.5"
            y1="284.202"
            x2="214.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="199.5"
            y1="293.798"
            x2="199.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
