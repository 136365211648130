import React, { FC, useMemo, useCallback, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Tooltip } from 'src/react-app/components'
import { IOrderItemBlock } from '../stage-calculator/interface'
import { Statuses } from './statuses'
import {
  OrderItemBlockWrapper,
  OrderItemBlockTitle,
  StatusesItemIconWrapper,
  OrderItemBlockStatusWrapper,
  OrderItemBlockStatusTitle,
  OrderItemBlockToggle,
  OrderItemBlockPriceWrapper,
  OrderItemBlockPriceTitle,
  OrderItemBlockPriceValue,
} from './styles'
import { OrderInfoBlock } from './order-info-block'
import { ArrowDownIcon, EditIcon } from '../../../assets/icons'
import { OrderDataBlock } from './order-data-block'
import { OrderNotificationsBlock } from './order-notifications-block'
import { statusTitles } from './consts'
import { ORDER_STATUSES } from './interface'
import { useSendOrder } from '../../../hooks'
//import { useRegister } from '../../../hooks'
import { TrashIcon } from '../../../assets/icons'
import { parseDate } from '../../../utilits'

export const OrderItemBlock: FC<IOrderItemBlock> = props => {
  const {
    orderItemTitle = 'Заказ',
    data,
    active,
    setActiveItem,
    noStatuses = false,
    canEdit = false,
    fetchOrderById = () => null,
    deleteOrder = Promise.reject,
  } = props
  const history = useHistory()
  const { updateOrder } = useSendOrder()
 // const { isAuthorized, userData } = useRegister()

  const status = useMemo(() => statusTitles[data['stage-code']] || 'Заявка принята', [data])
  const elements = useMemo(() => {
    const groupProducts = (acc, item) => acc.concat(item)
    return data?.['specification-products']?.['transition-products']?.reduce?.(groupProducts, []) || []
  }, [data])

  const totalPrice = useMemo(() => {
    const servicesSum = data?.['services']?.reduce?.((acc, service) => acc + (service?.['price'] || 0), 0)
    const elementsSum = elements.reduce?.((acc, element) => acc + (element?.['price'] || 0), 0)

    return servicesSum + elementsSum
  }, [data, elements])

  const isHaveMounting = useMemo(() => {
    let result = true

    if (data && data['services']) {
      const mounting = data['services'].find(service => service['code'] === ORDER_STATUSES.MOUNTING)

      if (!mounting) {
        result = false
      }
    }

    return result
  }, [data])

  const handleEditOrder = useCallback(() => {
    history.push(`/order?stage=ORDER&id=${data['id']}`)
  }, [data, history])

  const startUpdate = useCallback(
    async notification => {
      try {
        const newOrderData = {
          ...data,
          notification,
          'specification-products': {
            ...data['specification-products'],
            source: {
              ...data['specification-products']['source'],
              orderAdditionalData: {
                ...data['specification-products']['source']['orderAdditionalData'],
                notifications: notification,
              },
            },
          },
        }
        const res = await updateOrder(data['id'], newOrderData)

        if (res && res['id']) {
          fetchOrderById(res['id'])
        }
      } catch (e) {
        console.error('change notification error', e)
      }
    },
    [data, fetchOrderById, updateOrder],
  )

  const isDelete = useMemo(()=> {
    // console.log({ data }, 'memo')
    if (data?.['owner-organization'] === 23) {
      return canEdit
    }
    return false
  }, [data, canEdit])
  // console.log({ isDelete })
  const handleDeleteOrder = useCallback(async () => {
    await deleteOrder(data['id'])
  }, [data, deleteOrder])

  const date = useMemo(() => {
    const appeal = (data?.['dates'] || []).find(item => item?.code === 'case')
    return parseDate(appeal?.['date-fact'])
  }, [data])

  return (
    <OrderItemBlockWrapper active={active}>
      <OrderItemBlockToggle active={active} onClick={setActiveItem}>
        <ArrowDownIcon up={active} />
      </OrderItemBlockToggle>
      <OrderItemBlockTitle onClick={setActiveItem}>
        <span>
          {orderItemTitle} от {date} №{data['id']}
        </span>
        {canEdit && (
          <Tooltip text="Редактировать" coord={{ x: 10, y: 15 }}>
            <EditIcon color="#000000" onClick={handleEditOrder} />
          </Tooltip>
        )}
        {isDelete && (
          <Tooltip text="Удалить" coord={{ x: 10, y: 13 }}>
               <TrashIcon  onClick={handleDeleteOrder}/>
          </Tooltip>
        )}
      </OrderItemBlockTitle>
      {!active && (
        <OrderItemBlockStatusWrapper>
          <StatusesItemIconWrapper active small />
          <OrderItemBlockStatusTitle>{status}</OrderItemBlockStatusTitle>
        </OrderItemBlockStatusWrapper>
      )}
      {active && (
        <>
          {!noStatuses && <Statuses status={data['stage-code']} isHaveMounting={isHaveMounting} />}
          <OrderInfoBlock data={data} />
          <OrderNotificationsBlock data={data} onChangeNotification={startUpdate} />
          <OrderDataBlock data={data} />
          <OrderItemBlockPriceWrapper>
            <OrderItemBlockPriceTitle>Cтоимость:</OrderItemBlockPriceTitle>
            <OrderItemBlockPriceValue>{totalPrice} ₽</OrderItemBlockPriceValue>
          </OrderItemBlockPriceWrapper>
        </>
      )}
    </OrderItemBlockWrapper>
  )
}
