import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement8Door1LCasement2 = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="451"
        height="451"
        viewBox="0 0 451 451"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#door-1-l-casement-2-8-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M423 271.999C423 270.895 422.105 270 421.001 270C419.896 270 419.001 270.895 419.001 271.999V274.167H419.001V285H419V288.001C419 289.105 419.895 290 420.999 290C422.104 290 422.999 289.105 422.999 288.001V285.833H423V275H423V271.999Z"
            fill="url(#door-1-l-casement-2-8-paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 423 274.167)"
          fill="#ABABAB"
        />
        <path
          d="M423 270.94C423 270.421 422.579 270 422.06 270H419.941C419.422 270 419.001 270.421 419.001 270.94V275H423V270.94Z"
          fill="url(#door-1-l-casement-2-8-paint1_linear)"
        />
        <path
          d="M419 289.06C419 289.579 419.421 290 419.94 290H422.059C422.578 290 422.999 289.579 422.999 289.06V285H419V289.06Z"
          fill="url(#door-1-l-casement-2-8-paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 423 275.833)"
          fill="url(#door-1-l-casement-2-8-paint3_linear)"
        />
        <g filter="url(#door-1-l-casement-2-8-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M423 46.9995C423 45.8952 422.105 45 421.001 45C419.896 45 419.001 45.8952 419.001 46.9995V49.1665H419.001V60H419V63.0005C419 64.1048 419.895 65 420.999 65C422.104 65 422.999 64.1048 422.999 63.0005V60.8332H423V50H423V46.9995Z"
            fill="url(#door-1-l-casement-2-8-paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 423 49.1665)"
          fill="#ABABAB"
        />
        <path
          d="M423 45.9399C423 45.4208 422.579 45 422.06 45H419.941C419.422 45 419.001 45.4208 419.001 45.9399V50H423V45.9399Z"
          fill="url(#door-1-l-casement-2-8-paint5_linear)"
        />
        <path
          d="M419 64.0601C419 64.5792 419.421 65 419.94 65H422.059C422.578 65 422.999 64.5792 422.999 64.0601V60H419V64.0601Z"
          fill="url(#door-1-l-casement-2-8-paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 423 50.8335)"
          fill="url(#door-1-l-casement-2-8-paint7_linear)"
        />
        <g filter="url(#door-1-l-casement-2-8-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M419 34H308V301H419V34ZM408 45H319V290H408V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M419 34H308V301H309V35H418V301H419V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="312.53"
          y="38.53"
          width="101.94"
          height="257.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#door-1-l-casement-2-8-filter3_i)">
          <rect
            x="319.47"
            y="45.47"
            width="88.0601"
            height="244.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <path d="M406.5 287.443V47.5571L320.615 167.5L406.5 287.443Z" stroke="white" />
        <rect
          x="310"
          y="161"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#door-1-l-casement-2-8-filter4_d)">
          <rect x="310.702" y="161.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M311.286 172.796C311.798 173.458 312.6 173.884 313.502 173.884C315.048 173.884 316.302 172.63 316.302 171.084V164.444C316.302 163.456 315.79 162.587 315.016 162.089C315.382 162.562 315.6 163.156 315.6 163.8V170.44C315.6 171.986 314.347 173.24 312.8 173.24C312.242 173.24 311.722 173.077 311.286 172.796Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M313.502 161.644C311.956 161.644 310.702 162.898 310.702 164.444V170.44C310.702 170.46 310.702 170.48 310.703 170.501V165.024C310.703 163.477 311.956 162.224 313.503 162.224C315.029 162.224 316.269 163.444 316.302 164.963V164.444C316.302 162.898 315.048 161.644 313.502 161.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#door-1-l-casement-2-8-filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M313.5 165C312.672 165 312 165.672 312 166.5V166.714V168V186.5C312 187.328 312.672 188 313.5 188V188C314.328 188 315 187.328 315 186.5V170V166.5L315 166.286C315 165.576 314.424 165 313.714 165V165C313.677 165 313.64 165.001 313.604 165.004C313.569 165.001 313.535 165 313.5 165Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="door-1-l-casement-2-8-filter0_d"
            x="417.12"
            y="268.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-2-8-filter1_d"
            x="417.12"
            y="43.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-2-8-filter2_d"
            x="303.3"
            y="29.3003"
            width="122.279"
            height="278.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-2-8-filter3_i"
            x="319"
            y="45"
            width="89.9399"
            height="247.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="door-1-l-casement-2-8-filter4_d"
            x="308.822"
            y="159.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-2-8-filter5_d"
            x="310.12"
            y="163.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="door-1-l-casement-2-8-paint0_linear"
            x1="425.8"
            y1="287.5"
            x2="418.2"
            y2="287.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-2-8-paint1_linear"
            x1="416.202"
            y1="272.5"
            x2="423.8"
            y2="272.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-2-8-paint2_linear"
            x1="425.798"
            y1="287.5"
            x2="418.2"
            y2="287.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-2-8-paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-2-8-paint4_linear"
            x1="425.8"
            y1="62.5"
            x2="418.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-2-8-paint5_linear"
            x1="416.202"
            y1="47.5"
            x2="423.8"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-2-8-paint6_linear"
            x1="425.798"
            y1="62.5"
            x2="418.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-2-8-paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
