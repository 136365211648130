import {CT_SITE_ID} from "../../react-app/services/environment";

export async function sendCt({name, phone}) {
  // @ts-ignore
  const ctData = window?.ct?.('calltracking_params', 'mod_id')?.find(item => item?.siteId === CT_SITE_ID)
  console.log('ctData', ctData)
  if (ctData) {
    const data = {
      fio: name,
      phoneNumber: phone?.replace?.(/\D/, ''),
      // email: '', subject: '', tags: '', comment: '',
      requestUrl: location.href,
      sessionId: ctData.sessionId
    }
    const url = `https://api.calltouch.ru/calls-service/RestAPI/requests/${ctData.siteId}/register/`
    return fetch(url, {
      method: 'POST',
      mode: 'cors',
      credentials: 'omit',
      headers: {
        'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'accept': 'application/json'
      },
      body: new URLSearchParams(data).toString()
    })
  }
}

