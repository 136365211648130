import React from 'react'
import { StageDescriptionBlockContentItemIcon } from 'src/react-app/views/calculator/styles'

export const HeatSaving7 = () => {
  return (
    <StageDescriptionBlockContentItemIcon title="Для помещений с хорошим отоплением">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.6"
          d="M32.5 40H7.5C3.3575 40 0 36.6425 0 32.5V7.5C0 3.3575 3.3575 0 7.5 0H32.5C36.6425 0 40 3.3575 40 7.5V32.5C40 36.6425 36.6425 40 32.5 40Z"
          fill="#E0E3E9"
        />
        <path
          d="M24.1662 14.9656V11.6669C24.1662 11.2069 23.7925 10.8331 23.3325 10.8331C22.8725 10.8331 22.5 11.2069 22.5 11.6669V13.3356L19.3325 10.2381C19.0087 9.92062 18.4913 9.92062 18.1675 10.2381L10.3663 17.8644C10.1338 18.0994 10 18.4231 10 18.7506C10 19.4394 10.5612 20.0006 11.25 20.0006H12.5V24.5844C12.5 25.7331 13.435 26.6681 14.5837 26.6681H16.6675C17.1275 26.6681 17.5012 26.2956 17.5012 25.8344C17.5012 25.3731 17.1262 25.0006 16.6662 25.0006H14.5825C14.3525 25.0006 14.1662 24.8131 14.1662 24.5844V19.1681C14.1662 18.7081 13.7925 18.3344 13.3325 18.3344H12.2687L18.75 11.9994L24.2087 17.3381C24.5387 17.6606 25.0662 17.6544 25.3875 17.3256C25.7087 16.9969 25.7037 16.4681 25.375 16.1469L24.1662 14.9656Z"
          fill="#1C3775"
        />
        <circle
          cx="24.499"
          cy="24.5005"
          r="1.20019"
          fill="#1C3775"
          stroke="#1C3775"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <rect
          x="18.95"
          y="23.9004"
          width="2.30019"
          height="1.2001"
          rx="0.600048"
          fill="#1C3775"
          stroke="#1C3775"
          strokeWidth="0.1"
          strokeLinecap="round"
        />
        <rect
          x="27.7498"
          y="23.9004"
          width="2.30019"
          height="1.2001"
          rx="0.600048"
          fill="#1C3775"
          stroke="#1C3775"
          strokeWidth="0.1"
          strokeLinecap="round"
        />
        <rect
          x="20.999"
          y="20.1514"
          width="2.30019"
          height="1.2001"
          rx="0.600048"
          transform="rotate(45 20.999 20.1514)"
          fill="#1C3775"
          stroke="#1C3775"
          strokeWidth="0.1"
          strokeLinecap="round"
        />
        <rect
          x="27.2236"
          y="26.3745"
          width="2.30019"
          height="1.2001"
          rx="0.600048"
          transform="rotate(45 27.2236 26.3745)"
          fill="#1C3775"
          stroke="#1C3775"
          strokeWidth="0.1"
          strokeLinecap="round"
        />
        <rect
          x="28.851"
          y="21.0001"
          width="2.30019"
          height="1.2001"
          rx="0.600048"
          transform="rotate(135 28.851 21.0001)"
          fill="#1C3775"
          stroke="#1C3775"
          strokeWidth="0.1"
          strokeLinecap="round"
        />
        <rect
          x="22.6264"
          y="27.2225"
          width="2.30019"
          height="1.2001"
          rx="0.600048"
          transform="rotate(135 22.6264 27.2225)"
          fill="#1C3775"
          stroke="#1C3775"
          strokeWidth="0.1"
          strokeLinecap="round"
        />
        <rect
          x="25.0988"
          y="18.95"
          width="2.30019"
          height="1.2001"
          rx="0.600048"
          transform="rotate(90 25.0988 18.95)"
          fill="#1C3775"
          stroke="#1C3775"
          strokeWidth="0.1"
          strokeLinecap="round"
        />
        <rect
          x="25.0988"
          y="27.7508"
          width="2.30019"
          height="1.2001"
          rx="0.600048"
          transform="rotate(90 25.0988 27.7508)"
          fill="#1C3775"
          stroke="#1C3775"
          strokeWidth="0.1"
          strokeLinecap="round"
        />
      </svg>
    </StageDescriptionBlockContentItemIcon>
  )
}
