import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement82Square82Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#8filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M183.001 290C184.105 290 185 289.105 185 288.001C185 286.896 184.105 286.001 183.001 286.001L180.833 286.001L180.833 286.001L170 286.001L170 286L166.999 286C165.895 286 165 286.895 165 287.999C165 289.104 165.895 289.999 166.999 289.999L169.167 289.999L169.167 290L180 290L180 290L183.001 290Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 180.833 290)"
          fill="#ABABAB"
        />
        <path
          d="M184.06 290C184.579 290 185 289.579 185 289.06L185 286.941C185 286.422 184.579 286.001 184.06 286.001L180 286.001L180 290L184.06 290Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M165.94 286C165.421 286 165 286.421 165 286.94L165 289.059C165 289.578 165.421 289.999 165.94 289.999L170 289.999L170 286L165.94 286Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 179.167 290)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#8filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M138.001 290C139.105 290 140 289.105 140 288.001C140 286.896 139.105 286.001 138.001 286.001L135.833 286.001L135.833 286.001L125 286.001L125 286L121.999 286C120.895 286 120 286.895 120 287.999C120 289.104 120.895 289.999 121.999 289.999L124.167 289.999L124.167 290L135 290L135 290L138.001 290Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 135.833 290)"
          fill="#ABABAB"
        />
        <path
          d="M139.06 290C139.579 290 140 289.579 140 289.06L140 286.941C140 286.422 139.579 286.001 139.06 286.001L135 286.001L135 290L139.06 290Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M120.94 286C120.421 286 120 286.421 120 286.94L120 289.059C120 289.578 120.421 289.999 120.94 289.999L125 289.999L125 286L120.94 286Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.167 290)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#8filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 231H115V286H188V231ZM178 241H125V276H178V241Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 231H115V286H116V232H187V286H188V231Z"
          fill="#FBFBFB"
        />
        <g filter="url(#8filter3_i)">
          <rect
            x="125.47"
            y="241.47"
            width="52.0601"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="119.53"
          y="235.53"
          width="63.9399"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="158.528"
          y="233"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 158.528 233)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#8filter4_d)">
          <rect
            x="157.884"
            y="233.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 157.884 233.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M157.766 235.695C157.42 234.542 156.35 233.702 155.084 233.702L148.444 233.702C146.898 233.702 145.644 234.956 145.644 236.502C145.644 236.537 145.645 236.572 145.646 236.607C145.993 237.76 147.062 238.6 148.328 238.6L154.968 238.6C156.515 238.6 157.768 237.346 157.768 235.8C157.768 235.765 157.768 235.73 157.766 235.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M156.409 234.035C156.266 234.012 156.118 234 155.968 234L149.328 234C147.782 234 146.528 235.254 146.528 236.8C146.528 237.867 147.125 238.795 148.003 239.267C146.666 239.056 145.644 237.898 145.644 236.502C145.644 234.956 146.898 233.702 148.444 233.702L155.084 233.702C155.564 233.702 156.015 233.822 156.409 234.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#8filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M171.528 236.5C171.528 235.672 170.857 235 170.028 235L169.814 235L168.528 235L150.028 235C149.2 235 148.528 235.672 148.528 236.5V236.5C148.528 237.328 149.2 238 150.028 238L166.528 238L170.028 238L170.243 238C170.953 238 171.528 237.424 171.528 236.714V236.714C171.528 236.677 171.527 236.64 171.525 236.604C171.527 236.569 171.528 236.535 171.528 236.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M127.018 274.5L151.5 242.818L175.982 274.5L127.018 274.5Z" stroke="white" />
        <defs>
          <filter
            id="8filter0_d"
            x="163.12"
            y="284.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8filter1_d"
            x="118.12"
            y="284.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8filter2_d"
            x="110.3"
            y="226.3"
            width="84.2793"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8filter3_i"
            x="125"
            y="241"
            width="53.9399"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="8filter4_d"
            x="143.764"
            y="231.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8filter5_d"
            x="147.649"
            y="233.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="167.5"
            y1="292.8"
            x2="167.5"
            y2="285.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="182.5"
            y1="283.202"
            x2="182.5"
            y2="290.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="167.5"
            y1="292.798"
            x2="167.5"
            y2="285.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="122.5"
            y1="292.8"
            x2="122.5"
            y2="285.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="137.5"
            y1="283.202"
            x2="137.5"
            y2="290.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="122.5"
            y1="292.798"
            x2="122.5"
            y2="285.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
