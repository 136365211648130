import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement32Square4VerticalCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#3-2_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M60.0235 164.954C61.1151 164.954 62 164.069 62 162.978C62 161.886 61.1151 161.001 60.0235 161.001L57.8335 161.001L57.8335 161.001L47 161.001L47 161L43.9765 161C42.8849 161 42 161.885 42 162.976C42 164.068 42.8849 164.953 43.9765 164.953L46.1668 164.953L46.1668 164.954L57 164.954L57 164.954L60.0235 164.954Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 57.8335 164.954)"
          fill="#ABABAB"
        />
        <path
          d="M61.0601 164.954C61.5792 164.954 62 164.533 62 164.014L62 161.941C62 161.422 61.5792 161.001 61.0601 161.001L57 161.001L57 164.954L61.0601 164.954Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M42.9399 161C42.4208 161 42 161.421 42 161.94L42 164.013C42 164.532 42.4208 164.953 42.9399 164.953L47 164.953L47 161L42.9399 161Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 56.1666 164.954)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#3-2_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M176.024 164.954C177.115 164.954 178 164.069 178 162.978C178 161.886 177.115 161.001 176.024 161.001L173.833 161.001L173.833 161.001L163 161.001L163 161L159.976 161C158.885 161 158 161.885 158 162.976C158 164.068 158.885 164.953 159.976 164.953L162.167 164.953L162.167 164.954L173 164.954L173 164.954L176.024 164.954Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 173.833 164.954)"
          fill="#ABABAB"
        />
        <path
          d="M177.06 164.954C177.579 164.954 178 164.533 178 164.014L178 161.941C178 161.422 177.579 161.001 177.06 161.001L173 161.001L173 164.954L177.06 164.954Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M158.94 161C158.421 161 158 161.421 158 161.94L158 164.013C158 164.532 158.421 164.953 158.94 164.953L163 164.953L163 161L158.94 161Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 172.167 164.954)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#3-2_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 165H32V220H188V165ZM178 175H42V210H178V175Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 165H32V220H33V166H187V220H188V165Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-2_filter3_i)">
          <rect
            x="42.47"
            y="175.47"
            width="135.06"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="169.53"
          width="146.94"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="116"
          y="212"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 116 212)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="115.356"
          y="212.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 115.356 212.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115.238 214.695C114.892 213.542 113.822 212.702 112.556 212.702L105.916 212.702C104.37 212.702 103.116 213.955 103.116 215.502C103.116 215.537 103.117 215.572 103.118 215.607C103.464 216.76 104.534 217.6 105.8 217.6L112.44 217.6C113.986 217.6 115.24 216.346 115.24 214.8C115.24 214.765 115.239 214.73 115.238 214.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.881 213.035C113.737 213.012 113.59 213 113.44 213L106.8 213C105.254 213 104 214.254 104 215.8C104 216.867 104.597 217.795 105.475 218.267C104.138 218.056 103.116 216.898 103.116 215.502C103.116 213.955 104.37 212.702 105.916 212.702L112.556 212.702C113.035 212.702 113.487 212.822 113.881 213.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-2_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M112 215.5C112 214.672 111.328 214 110.5 214L110.286 214L109 214L90.5 214C89.6716 214 89 214.672 89 215.5V215.5C89 216.328 89.6716 217 90.5 217L107 217L110.5 217L110.714 217C111.424 217 112 216.424 112 215.714V215.714C112 215.677 111.999 215.64 111.996 215.604C111.999 215.569 112 215.535 112 215.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M174.853 176.5L110 208.443L45.1468 176.5L174.853 176.5Z" stroke="white" />
        <defs>
          <filter
            id="3-2_filter0_d"
            x="40.1201"
            y="159.12"
            width="25.6396"
            height="9.59374"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-2_filter1_d"
            x="156.12"
            y="159.12"
            width="25.6396"
            height="9.59374"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-2_filter2_d"
            x="27.3003"
            y="160.3"
            width="167.279"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-2_filter3_i"
            x="42"
            y="175"
            width="136.94"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-2_filter4_d"
            x="87.28"
            y="212.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="44.5"
            y1="167.722"
            x2="44.5"
            y2="160.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="59.5"
            y1="158.234"
            x2="59.5"
            y2="165.745"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="44.5"
            y1="167.72"
            x2="44.5"
            y2="160.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="160.5"
            y1="167.722"
            x2="160.5"
            y2="160.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="175.5"
            y1="158.234"
            x2="175.5"
            y2="165.745"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="160.5"
            y1="167.72"
            x2="160.5"
            y2="160.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
