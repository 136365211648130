import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement14Square3VerticalCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#1filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.9765 28C36.8849 28 36 28.8849 36 29.9765C36 31.0681 36.8849 31.953 37.9765 31.953L40.1665 31.953L40.1665 31.953L51 31.953L51 31.9541L54.0235 31.9541C55.1151 31.9541 56 31.0692 56 29.9776C56 28.886 55.1151 28.0011 54.0235 28.0011L51.8332 28.0011L51.8332 28L41 28L41 28L37.9765 28Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 40.1665 28)"
          fill="#ABABAB"
        />
        <path
          d="M36.9399 28C36.4208 28 36 28.4208 36 28.9399L36 31.013C36 31.5322 36.4208 31.953 36.9399 31.953L41 31.953L41 28L36.9399 28Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M55.0601 31.9541C55.5792 31.9541 56 31.5333 56 31.0142L56 28.9411C56 28.4219 55.5792 28.0011 55.0601 28.0011L51 28.0011L51 31.9541L55.0601 31.9541Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 41.8334 28)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#1filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M165.976 28C164.885 28 164 28.8849 164 29.9765C164 31.0681 164.885 31.953 165.976 31.953L168.167 31.953L168.167 31.953L179 31.953L179 31.9541L182.024 31.9541C183.115 31.9541 184 31.0692 184 29.9776C184 28.886 183.115 28.0011 182.024 28.0011L179.833 28.0011L179.833 28L169 28L169 28L165.976 28Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 168.167 28)"
          fill="#ABABAB"
        />
        <path
          d="M164.94 28C164.421 28 164 28.4208 164 28.9399L164 31.013C164 31.5322 164.421 31.953 164.94 31.953L169 31.953L169 28L164.94 28Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M183.06 31.9541C183.579 31.9541 184 31.5333 184 31.0142L184 28.9411C184 28.4219 183.579 28.0011 183.06 28.0011L179 28.0011L179 31.9541L183.06 31.9541Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 169.833 28)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#1filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 32H32V110H188V32ZM177 43H43V99H177V43Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 32H32V110H33V33H187V110H188V32Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1filter3_i)">
          <rect
            x="43.47"
            y="43.47"
            width="133.06"
            height="55.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="36.53"
          width="146.94"
          height="68.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M174.599 44.5L110 97.354L45.4007 44.5L174.599 44.5Z" stroke="white" />
        <rect
          x="116"
          y="101"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 116 101)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="115.356"
          y="101.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 115.356 101.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115.238 103.695C114.892 102.542 113.822 101.702 112.556 101.702L105.916 101.702C104.37 101.702 103.116 102.955 103.116 104.502C103.116 104.537 103.117 104.572 103.118 104.607C103.464 105.76 104.534 106.6 105.8 106.6L112.44 106.6C113.986 106.6 115.24 105.346 115.24 103.8C115.24 103.765 115.239 103.73 115.238 103.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.881 102.035C113.737 102.012 113.59 102 113.44 102L106.8 102C105.254 102 104 103.254 104 104.8C104 105.867 104.597 106.795 105.475 107.267C104.138 107.056 103.116 105.898 103.116 104.502C103.116 102.955 104.37 101.702 105.916 101.702L112.556 101.702C113.035 101.702 113.487 101.822 113.881 102.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M112 104.5C112 103.672 111.328 103 110.5 103L110.286 103L109 103L90.5 103C89.6716 103 89 103.672 89 104.5V104.5C89 105.328 89.6716 106 90.5 106L107 106L110.5 106L110.714 106C111.424 106 112 105.424 112 104.714V104.714C112 104.677 111.999 104.64 111.996 104.604C111.999 104.569 112 104.535 112 104.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="1filter0_d"
            x="34.1201"
            y="26.1201"
            width="25.6396"
            height="9.59374"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1filter1_d"
            x="162.12"
            y="26.1201"
            width="25.6396"
            height="9.59374"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1filter2_d"
            x="27.3003"
            y="27.3003"
            width="167.279"
            height="89.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1filter3_i"
            x="43"
            y="43"
            width="134.94"
            height="58.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="1filter4_d"
            x="87.28"
            y="101.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="53.5"
            y1="25.2322"
            x2="53.5"
            y2="32.7448"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="38.5"
            y1="34.7201"
            x2="38.5"
            y2="27.2094"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="53.5"
            y1="25.234"
            x2="53.5"
            y2="32.7447"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="181.5"
            y1="25.2322"
            x2="181.5"
            y2="32.7448"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="166.5"
            y1="34.7201"
            x2="166.5"
            y2="27.2094"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="181.5"
            y1="25.234"
            x2="181.5"
            y2="32.7447"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
