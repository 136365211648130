import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement65Square8Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#6-5_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M137.999 161C136.895 161 136 161.895 136 162.999C136 164.104 136.895 164.999 137.999 164.999L140.167 164.999L140.167 164.999L151 164.999L151 165L154.001 165C155.105 165 156 164.105 156 163.001C156 161.896 155.105 161.001 154.001 161.001L151.833 161.001L151.833 161L141 161L141 161L137.999 161Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 140.167 161)"
          fill="#ABABAB"
        />
        <path
          d="M136.94 161C136.421 161 136 161.421 136 161.94L136 164.059C136 164.578 136.421 164.999 136.94 164.999L141 164.999L141 161L136.94 161Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M155.06 165C155.579 165 156 164.579 156 164.06L156 161.941C156 161.422 155.579 161.001 155.06 161.001L151 161.001L151 165L155.06 165Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 141.833 161)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#6-5_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M197.999 161C196.895 161 196 161.895 196 162.999C196 164.104 196.895 164.999 197.999 164.999L200.167 164.999L200.167 164.999L211 164.999L211 165L214.001 165C215.105 165 216 164.105 216 163.001C216 161.896 215.105 161.001 214.001 161.001L211.833 161.001L211.833 161L201 161L201 161L197.999 161Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 200.167 161)"
          fill="#ABABAB"
        />
        <path
          d="M196.94 161C196.421 161 196 161.421 196 161.94L196 164.059C196 164.578 196.421 164.999 196.94 164.999L201 164.999L201 161L196.94 161Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M215.06 165C215.579 165 216 164.579 216 164.06L216 161.941C216 161.422 215.579 161.001 215.06 161.001L211 161.001L211 165L215.06 165Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 201.833 161)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#6-5_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M220 165H131V286H220V165ZM209 176H142V275H209V176Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M220 165H131V286H132.219V166H218.781V286H220V165Z"
          fill="#FBFBFB"
        />
        <g filter="url(#6-5_filter3_i)">
          <rect
            x="142.47"
            y="176.47"
            width="66.0601"
            height="98.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="135.53"
          y="169.53"
          width="79.9399"
          height="111.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="182"
          y="277"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 182 277)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="181.356"
          y="277.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 181.356 277.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M181.238 279.695C180.892 278.542 179.822 277.702 178.556 277.702L171.916 277.702C170.37 277.702 169.116 278.955 169.116 280.502C169.116 280.537 169.117 280.572 169.118 280.607C169.464 281.76 170.534 282.6 171.8 282.6L178.44 282.6C179.986 282.6 181.24 281.346 181.24 279.8C181.24 279.765 181.239 279.73 181.238 279.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M179.881 278.034C179.737 278.012 179.59 278 179.44 278L172.8 278C171.254 278 170 279.253 170 280.8C170 281.867 170.597 282.795 171.475 283.267C170.138 283.056 169.116 281.898 169.116 280.502C169.116 278.955 170.37 277.702 171.916 277.702L178.556 277.702C179.035 277.702 179.486 277.822 179.881 278.034Z"
          fill="#FBFBFB"
        />
        <g filter="url(#6-5_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M178 280.5C178 279.672 177.328 279 176.5 279L176.286 279L175 279L156.5 279C155.672 279 155 279.672 155 280.5V280.5C155 281.328 155.672 282 156.5 282L173 282L176.5 282L176.714 282C177.424 282 178 281.424 178 280.714V280.714C178 280.677 177.999 280.64 177.996 280.604C177.999 280.569 178 280.535 178 280.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M207.305 177.5L175.5 272.426L143.695 177.5L207.305 177.5Z" stroke="white" />
        <defs>
          <filter
            id="6-5_filter0_d"
            x="134.12"
            y="159.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-5_filter1_d"
            x="194.12"
            y="159.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-5_filter2_d"
            x="126.3"
            y="160.3"
            width="100.279"
            height="132.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-5_filter3_i"
            x="142"
            y="176"
            width="67.9399"
            height="101.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="6-5_filter4_d"
            x="153.28"
            y="277.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="153.5"
            y1="158.2"
            x2="153.5"
            y2="165.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="138.5"
            y1="167.798"
            x2="138.5"
            y2="160.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="153.5"
            y1="158.202"
            x2="153.5"
            y2="165.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="213.5"
            y1="158.2"
            x2="213.5"
            y2="165.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="198.5"
            y1="167.798"
            x2="198.5"
            y2="160.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="213.5"
            y1="158.202"
            x2="213.5"
            y2="165.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
