import React from 'react'
import { ArrowRightWrapper } from '../../views/calculator/styles'

export const ArrowRightIcon = ({ color = '#000000', arrowDown = false }) => {
  return (
    <ArrowRightWrapper arrowDown={arrowDown}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 40" fill="none" x="0px" y="0px">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 16C10 15.4477 10.4477 15 11 15L19.5323 15L17.1789 12.7179C16.7824 12.3334 16.7726 11.7003 17.1571 11.3039C17.5416 10.9074 18.1747 10.8976 18.5711 11.2821L22.6961 15.2821C22.8904 15.4704 23 15.7294 23 16C23 16.2706 22.8904 16.5296 22.6961 16.7179L18.5711 20.7179C18.1747 21.1024 17.5416 21.0926 17.1571 20.6961C16.7726 20.2997 16.7824 19.6666 17.1789 19.2821L19.5323 17L11 17C10.4477 17 10 16.5523 10 16Z"
          fill={color}
        />
      </svg>
    </ArrowRightWrapper>
  )
}
