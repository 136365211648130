import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement22Square4VerticalCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-2_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M60.0235 98.954C61.1151 98.954 62 98.0691 62 96.9775C62 95.8859 61.1151 95.001 60.0235 95.001L57.8335 95.001L57.8335 95.001L47 95.001L47 94.9999L43.9765 94.9999C42.8849 94.9999 42 95.8848 42 96.9764C42 98.068 42.8849 98.9529 43.9765 98.9529L46.1668 98.9529L46.1668 98.954L57 98.954L57 98.954L60.0235 98.954Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 57.8335 98.954)"
          fill="#ABABAB"
        />
        <path
          d="M61.0601 98.954C61.5792 98.954 62 98.5332 62 98.0141L62 95.941C62 95.4218 61.5792 95.001 61.0601 95.001L57 95.001L57 98.954L61.0601 98.954Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M42.9399 94.9999C42.4208 94.9999 42 95.4207 42 95.9398L42 98.013C42 98.5321 42.4208 98.9529 42.9399 98.9529L47 98.9529L47 94.9999L42.9399 94.9999Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 56.1666 98.954)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#2-2_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M176.024 98.954C177.115 98.954 178 98.0691 178 96.9775C178 95.8859 177.115 95.001 176.024 95.001L173.833 95.001L173.833 95.001L163 95.001L163 94.9999L159.976 94.9999C158.885 94.9999 158 95.8848 158 96.9764C158 98.068 158.885 98.9529 159.976 98.9529L162.167 98.9529L162.167 98.954L173 98.954L173 98.954L176.024 98.954Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 173.833 98.954)"
          fill="#ABABAB"
        />
        <path
          d="M177.06 98.954C177.579 98.954 178 98.5332 178 98.0141L178 95.941C178 95.4218 177.579 95.001 177.06 95.001L173 95.001L173 98.954L177.06 98.954Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M158.94 94.9999C158.421 94.9999 158 95.4207 158 95.9398L158 98.013C158 98.5321 158.421 98.9529 158.94 98.9529L163 98.9529L163 94.9999L158.94 94.9999Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 172.167 98.954)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#2-2_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 99H32V154H188V99ZM178 109H42V144H178V109Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 99H32V154H33V100H187V154H188V99Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-2_filter3_i)">
          <rect
            x="42.47"
            y="109.47"
            width="135.06"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="103.53"
          width="146.94"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="116"
          y="146"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 116 146)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="115.356"
          y="146.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 115.356 146.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115.238 148.695C114.892 147.542 113.822 146.702 112.556 146.702L105.916 146.702C104.37 146.702 103.116 147.955 103.116 149.502C103.116 149.537 103.117 149.572 103.118 149.607C103.464 150.76 104.534 151.6 105.8 151.6L112.44 151.6C113.986 151.6 115.24 150.346 115.24 148.8C115.24 148.765 115.239 148.73 115.238 148.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.881 147.035C113.737 147.012 113.59 147 113.44 147L106.8 147C105.254 147 104 148.254 104 149.8C104 150.867 104.597 151.795 105.475 152.267C104.138 152.056 103.116 150.898 103.116 149.502C103.116 147.955 104.37 146.702 105.916 146.702L112.556 146.702C113.035 146.702 113.487 146.822 113.881 147.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-2_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M112 149.5C112 148.672 111.328 148 110.5 148L110.286 148L109 148L90.5 148C89.6716 148 89 148.672 89 149.5V149.5C89 150.328 89.6716 151 90.5 151L107 151L110.5 151L110.714 151C111.424 151 112 150.424 112 149.714V149.714C112 149.677 111.999 149.64 111.996 149.604C111.999 149.569 112 149.535 112 149.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M174.853 110.5L110 142.443L45.1468 110.5L174.853 110.5Z" stroke="white" />
        <defs>
          <filter
            id="2-2_filter0_d"
            x="40.1201"
            y="93.12"
            width="25.6396"
            height="9.59374"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-2_filter1_d"
            x="156.12"
            y="93.12"
            width="25.6396"
            height="9.59374"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-2_filter2_d"
            x="27.3003"
            y="94.3003"
            width="167.279"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-2_filter3_i"
            x="42"
            y="109"
            width="136.94"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-2_filter4_d"
            x="87.28"
            y="146.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="44.5"
            y1="101.722"
            x2="44.5"
            y2="94.2092"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="59.5"
            y1="92.2339"
            x2="59.5"
            y2="99.7446"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="44.5"
            y1="101.72"
            x2="44.5"
            y2="94.2093"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="160.5"
            y1="101.722"
            x2="160.5"
            y2="94.2092"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="175.5"
            y1="92.2339"
            x2="175.5"
            y2="99.7446"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="160.5"
            y1="101.72"
            x2="160.5"
            y2="94.2093"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
