import { FC } from 'react'
import { IUseRegisterError, InputTypes } from '../interface'

export enum FormTypes {
  AUTH = 'auth',
  REGISTER = 'register',
  REPAIR = 'repair',
  REPAIR_MESSAGE = 'repair-message',
}

export enum RegisterFormKeys {
  LAST_NAME = 'last-name',
  FIRST_NAME = 'first-name',
  BIRTHDAY = 'birthday',
  EMAIL = 'email',
  PHONE = 'phone',
  PASSWORD_CONFIRM = 'password-confirm',
  PASSWORD = 'password',
  RIGHT_CONFIRM = 'right-confirm',
}

export interface IRegisterButtonProps {
  onSubmit: () => void
  buttonText: string
  disabled?: boolean
}

export interface IRegisterUserProps {
  startSubmit?: boolean
  onEndSubmit?: (profile: Record<string, any> | null) => void
  onSubmit?: () => void
  isAutoRegister?: boolean
  children?: any
}

export interface IAuthFormProps {
  errors: IUseRegisterError[]
  onAuth: (data: Record<string, any>) => void
  onOpenRepairFrom: () => void
  onOpenRegisterFrom: () => void
  resetErrors: () => void
}

export interface IRegisterFormProps {
  errors: IUseRegisterError[]
  onRegister: (data: Record<string, any>) => void
  onOpenAuthForm: () => void
  resetErrors: () => void
}

export interface IRepairFormProps {
  onRepair: (email: string) => void
}

export interface IRepairMessageFormProps {
  onClose: () => void
}

export interface IFormParam {
  name: string
  placeholder: string
  type: InputTypes
  Component: FC<any>
  required: boolean
}
