import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import {
  StageCartProductsListItem,
  StageCartProductsListItemContainer,
  StageCartProductsListItemDescBlock,
  StageCartProductsListItemDescBlockDesc,
  StageCartProductsListItemDescBlockTitle,
  StageCartProductsListItemImageWrapper,
  StageCartProductsListItemPriceTitle,
  StageCartProductsListItemPriceValue,
  StageCartProductsListItemPriceWrapper,
  StageCartShowMoreBlockButtonsWrapper,
  StageCartShowMoreBlockWrapper,
  StageCartToggleButton,
  StageCartCharacteristicBlockWrapper,
  StageCartProductsListItemDescBlockSubTitle,
} from './styles'
import { Button, Info, Loader, ModelComparison } from '../../../components'
import { DescriptionTypeWindow } from './description-type-window'
import { ICartItemProps } from '../stage-calculator/interface'
import { ButtonViews } from '../interface'
import { StageOrderTitleWrapper } from '../stage-order/styles'
import {SubTitleText, LinkButton, CounterContainer, CounterButton, CounterValue} from '../styles'
import { TrashIcon, EditIcon } from '../../../assets/icons'
import { ProductImagesGenerator } from '../stage-calculator/components/product-images-generator' // don't simplify it! Its brooke build
import { getElementsData } from '../../../utilits'
import usePrice from '../../../hooks/use-price'

const Counter = ({ count, onIncrement, onDecrement }) => (
  <CounterContainer>
    <CounterButton onClick={onDecrement}>-</CounterButton>
    <CounterValue>{count}</CounterValue>
    <CounterButton onClick={onIncrement}>+</CounterButton>
  </CounterContainer>
)

export const CartItem: FC<ICartItemProps> = props => {
  const {
    productType,
    product,
    count = 1,
    itemNumber,
    sizes = { 'model-width': 300, 'model-height': 300 },
    onDeleteOrder,
    onEditOrder,
    filters,
    onSetPrice,
    isSdk,
    bitrixId,
    onQuantityChange // добавляем пропс для обработки изменения количества
  } = props
  const [showMore, setShowMore] = useState(false)
  const [showMoreType, setShowMoreType] = useState<'spec' | 'char'>('spec')
  const [elementsData, setElementsData] = useState<Record<string, any>>({})
  const { totalPriceList, calculateTotalPrice, calculatePriceBitrix, inProcess } = usePrice()
  //console.log('bitrixId', bitrixId)
  useEffect(() => {
    let result = {}

    if (totalPriceList.length > 0) {
      if (totalPriceList[0]['additionalPrice']) {
        result = getElementsData(product, totalPriceList[0]['additionalPrice'], sizes, filters)
        // console.log({result})
      }
      if (totalPriceList[0]['total']) {
        onSetPrice(totalPriceList[0])
      }
    }

    setElementsData(result)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPriceList])

  useEffect(() => {
    if(bitrixId) {
      calculatePriceBitrix(bitrixId, count)
    } else {
      calculateTotalPrice(productType, [product], [sizes], [filters])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleSHowMore = useCallback(() => {
    setShowMore(prevState => !prevState)
  }, [])

  const handleChangeType = useCallback(
    type => () => {
      setShowMoreType(type)
    },
    [],
  )

  const currentPrice = useMemo(() => (totalPriceList.length > 0 ? totalPriceList[0] : null), [
    totalPriceList,
  ])

  const itemPrice = useMemo(() => (currentPrice ? currentPrice['itemPrice'] : 0), [currentPrice])
  const totalPrice = useMemo(() => (currentPrice ? (currentPrice['itemPrice'] * count) : 0), [currentPrice, count])

  return (
    <StageCartProductsListItem key={product.id}>
      <StageCartProductsListItemContainer>
        <StageCartProductsListItemImageWrapper>
          {bitrixId && product['image'] ? (
            <img src={product['image']} alt="" />
          ) : (
            <ProductImagesGenerator
              onlyView
              productType={productType}
              product={product}
              filters={filters}
            />
          )}
        </StageCartProductsListItemImageWrapper>
        <StageCartProductsListItemDescBlock>
          {product['vendor-code'] && (
            <StageCartProductsListItemDescBlockSubTitle>Артикул: {product['vendor-code']}</StageCartProductsListItemDescBlockSubTitle>
          )}
          <StageCartProductsListItemDescBlockTitle>
            <span>
              {bitrixId && product?.['name']
                ? product['name']
                : `${isSdk ? 'Готовое изделие' : 'Изделие'} №${itemNumber}`}
            </span>
            {product['standard'] === 'false' && (
              <StageOrderTitleWrapper>
                <SubTitleText>Нестандартное изделие</SubTitleText>
                <Info color="#bbc3d6">Нестандартное изделие</Info>
              </StageOrderTitleWrapper>
            )}
          </StageCartProductsListItemDescBlockTitle>
          <StageCartProductsListItemDescBlockDesc>
            {product['item-description']}
          </StageCartProductsListItemDescBlockDesc>
          {!showMore && (
            <StageCartToggleButton onClick={toggleSHowMore}>Подробнее</StageCartToggleButton>
          )}
          {showMore && (
            <StageCartToggleButton onClick={toggleSHowMore}>Скрыть</StageCartToggleButton>
          )}
        </StageCartProductsListItemDescBlock>
        <StageCartProductsListItemPriceWrapper>
          <StageCartProductsListItemPriceTitle>
            Cтоимость:
          </StageCartProductsListItemPriceTitle>
          {inProcess ? (
            <Loader />
          ) : (
           <>
             <StageCartProductsListItemPriceValue>
               {totalPrice?.toLocaleString('ru-RU') || '0'} ₽
             </StageCartProductsListItemPriceValue>
             <Counter
               count={count}
               onDecrement={() => onQuantityChange(product['id'] || bitrixId, count - 1)}
               onIncrement={() => onQuantityChange(product['id'] || bitrixId, count + 1)}
             />
           </>
          )}
          <LinkButton onClick={onDeleteOrder} style={{ marginBottom: 8 }}>
            <TrashIcon /> Удалить
          </LinkButton>
            {isSdk || <LinkButton onClick={onEditOrder}>
          <EditIcon /> Редактировать
        </LinkButton>}
        </StageCartProductsListItemPriceWrapper>
      </StageCartProductsListItemContainer>
      {showMore && (
        <div>
          <StageCartShowMoreBlockWrapper>
            <StageCartShowMoreBlockButtonsWrapper>
              <Button
                typeView={showMoreType === 'spec' ? ButtonViews.BLUE : null}
                onClick={handleChangeType('spec')}
              >
                Спецификация
              </Button>
              {/*<Button
                typeView={showMoreType === 'char' ? ButtonViews.BLUE : null}
                onClick={handleChangeType('char')}
              >
                Характеристики
              </Button>*/}
            </StageCartShowMoreBlockButtonsWrapper>
            {showMoreType === 'spec' && (
              <DescriptionTypeWindow
                product={product}
                sizes={sizes}
                count={count}
                itemPrice={itemPrice}
                elementsData={elementsData}
                filters={filters}
              />
            )}
            {showMoreType === 'char' && (
              <StageCartCharacteristicBlockWrapper>
                <ModelComparison filters={filters} type={productType} />
              </StageCartCharacteristicBlockWrapper>
            )}
          </StageCartShowMoreBlockWrapper>
        </div>
      )}
    </StageCartProductsListItem>
  )
}

export default CartItem
