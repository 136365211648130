import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement21Square4VerticalCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-1_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M60.0235 157.954C61.1151 157.954 62 157.069 62 155.978C62 154.886 61.1151 154.001 60.0235 154.001L57.8335 154.001L57.8335 154.001L47 154.001L47 154L43.9765 154C42.8849 154 42 154.885 42 155.976C42 157.068 42.8849 157.953 43.9765 157.953L46.1668 157.953L46.1668 157.954L57 157.954L57 157.954L60.0235 157.954Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 57.8335 157.954)"
          fill="#ABABAB"
        />
        <path
          d="M61.0601 157.954C61.5792 157.954 62 157.533 62 157.014L62 154.941C62 154.422 61.5792 154.001 61.0601 154.001L57 154.001L57 157.954L61.0601 157.954Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M42.9399 154C42.4208 154 42 154.421 42 154.94L42 157.013C42 157.532 42.4208 157.953 42.9399 157.953L47 157.953L47 154L42.9399 154Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 56.1666 157.954)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#2-1_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M176.024 157.954C177.115 157.954 178 157.069 178 155.978C178 154.886 177.115 154.001 176.024 154.001L173.833 154.001L173.833 154.001L163 154.001L163 154L159.976 154C158.885 154 158 154.885 158 155.976C158 157.068 158.885 157.953 159.976 157.953L162.167 157.953L162.167 157.954L173 157.954L173 157.954L176.024 157.954Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 173.834 157.954)"
          fill="#ABABAB"
        />
        <path
          d="M177.06 157.954C177.579 157.954 178 157.533 178 157.014L178 154.941C178 154.422 177.579 154.001 177.06 154.001L173 154.001L173 157.954L177.06 157.954Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M158.94 154C158.421 154 158 154.421 158 154.94L158 157.013C158 157.532 158.421 157.953 158.94 157.953L163 157.953L163 154L158.94 154Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 172.167 157.954)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#2-1_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 99H32V154H188V99ZM178 109H42V144H178V109Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 99H32V154H33V100H187V154H188V99Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-1_filter3_i)">
          <rect
            x="42.47"
            y="109.47"
            width="135.06"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="117.528"
          y="101"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 117.528 101)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#2-1_filter4_d)">
          <rect
            x="116.884"
            y="101.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 116.884 101.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M116.766 103.695C116.42 102.542 115.35 101.702 114.084 101.702L107.444 101.702C105.898 101.702 104.644 102.955 104.644 104.502C104.644 104.537 104.645 104.572 104.646 104.607C104.993 105.76 106.062 106.6 107.328 106.6L113.968 106.6C115.515 106.6 116.768 105.346 116.768 103.8C116.768 103.765 116.768 103.73 116.766 103.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115.409 102.035C115.266 102.012 115.118 102 114.968 102L108.328 102C106.782 102 105.528 103.254 105.528 104.8C105.528 105.867 106.125 106.795 107.003 107.267C105.666 107.056 104.644 105.898 104.644 104.502C104.644 102.955 105.898 101.702 107.444 101.702L114.084 101.702C114.564 101.702 115.015 101.822 115.409 102.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-1_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M130.528 104.5C130.528 103.672 129.857 103 129.028 103L128.814 103L127.528 103L109.028 103C108.2 103 107.528 103.672 107.528 104.5V104.5C107.528 105.328 108.2 106 109.028 106L125.528 106L129.028 106L129.243 106C129.953 106 130.528 105.424 130.528 104.714V104.714C130.528 104.677 130.527 104.64 130.525 104.604C130.527 104.569 130.528 104.535 130.528 104.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M174.853 142.5L110 110.557L45.1468 142.5L174.853 142.5Z" stroke="white" />
        <defs>
          <filter
            id="2-1_filter0_d"
            x="40.1201"
            y="152.12"
            width="25.6396"
            height="9.59374"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-1_filter1_d"
            x="156.12"
            y="152.12"
            width="25.6396"
            height="9.59374"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-1_filter2_d"
            x="27.3003"
            y="94.3003"
            width="167.279"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-1_filter3_i"
            x="42"
            y="109"
            width="136.94"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-1_filter4_d"
            x="102.764"
            y="99.8219"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-1_filter5_d"
            x="106.649"
            y="101.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="44.5"
            y1="160.722"
            x2="44.5"
            y2="153.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="59.5"
            y1="151.234"
            x2="59.5"
            y2="158.745"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="44.5"
            y1="160.72"
            x2="44.5"
            y2="153.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="160.5"
            y1="160.722"
            x2="160.5"
            y2="153.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="175.5"
            y1="151.234"
            x2="175.5"
            y2="158.745"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="160.5"
            y1="160.72"
            x2="160.5"
            y2="153.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
