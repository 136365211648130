import React from 'react'

export const QuestionImage = () => {
  return (
    <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.78668 5.344C1.76268 5.044 1.78368 4.786 1.84968 4.57C1.92168 4.354 2.01768 4.162 2.13768 3.994C2.25768 3.826 2.38968 3.676 2.53368 3.544C2.68368 3.412 2.82168 3.28 2.94768 3.148C3.07968 3.016 3.18768 2.875 3.27168 2.725C3.36168 2.575 3.40668 2.401 3.40668 2.203C3.40668 1.939 3.32568 1.723 3.16368 1.555C3.00768 1.387 2.73168 1.303 2.33568 1.303C2.09568 1.303 1.84968 1.348 1.59768 1.438C1.35168 1.528 1.14168 1.624 0.96768 1.726L0.67968 1.078C0.93168 0.922 1.19868 0.802 1.48068 0.718C1.76268 0.628 2.11068 0.582999 2.52468 0.582999C2.81868 0.582999 3.07668 0.622 3.29868 0.7C3.52068 0.778 3.70668 0.886 3.85668 1.024C4.00668 1.156 4.11768 1.315 4.18968 1.501C4.26768 1.681 4.30668 1.876 4.30668 2.086C4.30668 2.368 4.26168 2.611 4.17168 2.815C4.08168 3.013 3.97068 3.19 3.83868 3.346C3.70668 3.502 3.56268 3.646 3.40668 3.778C3.25068 3.91 3.10668 4.051 2.97468 4.201C2.84268 4.345 2.73168 4.507 2.64168 4.687C2.55168 4.867 2.50668 5.086 2.50668 5.344H1.78668ZM1.58868 6.541C1.58868 6.367 1.64268 6.229 1.75068 6.127C1.86468 6.025 2.01168 5.974 2.19168 5.974C2.37168 5.974 2.51568 6.025 2.62368 6.127C2.73768 6.229 2.79468 6.367 2.79468 6.541C2.79468 6.703 2.73768 6.838 2.62368 6.946C2.51568 7.054 2.37168 7.108 2.19168 7.108C2.01168 7.108 1.86468 7.054 1.75068 6.946C1.64268 6.838 1.58868 6.703 1.58868 6.541Z"
        fill="white"
      />
    </svg>
  )
}
