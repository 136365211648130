import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement54Square54Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#5-4_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M370.001 34C371.105 34 372 33.1048 372 32.0005C372 30.8962 371.105 30.001 370.001 30.001L367.833 30.001L367.833 30.001L357 30.001L357 30L353.999 30C352.895 30 352 30.8952 352 31.9995C352 33.1038 352.895 33.999 353.999 33.999L356.167 33.999L356.167 34L367 34L367 34L370.001 34Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 367.833 34)"
          fill="#ABABAB"
        />
        <path
          d="M371.06 34C371.579 34 372 33.5792 372 33.0601L372 30.941C372 30.4219 371.579 30.001 371.06 30.001L367 30.001L367 34L371.06 34Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M352.94 30C352.421 30 352 30.4208 352 30.9399L352 33.059C352 33.5781 352.421 33.999 352.94 33.999L357 33.999L357 30L352.94 30Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 366.167 34)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#5-4_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M410.001 34C411.105 34 412 33.1048 412 32.0005C412 30.8962 411.105 30.001 410.001 30.001L407.833 30.001L407.833 30.001L397 30.001L397 30L393.999 30C392.895 30 392 30.8952 392 31.9995C392 33.1038 392.895 33.999 393.999 33.999L396.167 33.999L396.167 34L407 34L407 34L410.001 34Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 407.833 34)"
          fill="#ABABAB"
        />
        <path
          d="M411.06 34C411.579 34 412 33.5792 412 33.0601L412 30.941C412 30.4219 411.579 30.001 411.06 30.001L407 30.001L407 34L411.06 34Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M392.94 30C392.421 30 392 30.4208 392 30.9399L392 33.059C392 33.5781 392.421 33.999 392.94 33.999L397 33.999L397 30L392.94 30Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 406.167 34)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#5-4_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M416 34H348V287H416V34ZM406 44H358V277H406V44Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M416 34H348V287H348.932V35H415.068V287H416V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#5-4_filter3_i)">
          <rect
            x="358.47"
            y="44.47"
            width="47.0601"
            height="232.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="352.53"
          y="38.53"
          width="58.9399"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M359.552 45.5L382 270.953L404.448 45.5L359.552 45.5Z" stroke="white" />
        <rect
          x="389"
          y="278"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 389 278)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="388.356"
          y="278.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 388.356 278.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M388.238 280.695C387.892 279.542 386.822 278.702 385.556 278.702L378.916 278.702C377.37 278.702 376.116 279.955 376.116 281.502C376.116 281.537 376.117 281.572 376.118 281.607C376.464 282.76 377.534 283.6 378.8 283.6L385.44 283.6C386.986 283.6 388.24 282.346 388.24 280.8C388.24 280.765 388.239 280.73 388.238 280.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M386.881 279.034C386.737 279.012 386.59 279 386.44 279L379.8 279C378.254 279 377 280.253 377 281.8C377 282.867 377.597 283.795 378.475 284.267C377.138 284.056 376.116 282.898 376.116 281.502C376.116 279.955 377.37 278.702 378.916 278.702L385.556 278.702C386.035 278.702 386.486 278.822 386.881 279.034Z"
          fill="#FBFBFB"
        />
        <g filter="url(#5-4_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M385 281.5C385 280.672 384.328 280 383.5 280L383.286 280L382 280L363.5 280C362.672 280 362 280.672 362 281.5V281.5C362 282.328 362.672 283 363.5 283L380 283L383.5 283L383.714 283C384.424 283 385 282.424 385 281.714V281.714C385 281.677 384.999 281.64 384.996 281.604C384.999 281.569 385 281.535 385 281.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="5-4_filter0_d"
            x="350.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-4_filter1_d"
            x="390.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-4_filter2_d"
            x="343.3"
            y="29.3003"
            width="79.2793"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-4_filter3_i"
            x="358"
            y="44"
            width="48.9399"
            height="235.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-4_filter4_d"
            x="360.28"
            y="278.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="354.5"
            y1="36.8"
            x2="354.5"
            y2="29.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="369.5"
            y1="27.2018"
            x2="369.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="354.5"
            y1="36.7982"
            x2="354.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="394.5"
            y1="36.8"
            x2="394.5"
            y2="29.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="409.5"
            y1="27.2018"
            x2="409.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="394.5"
            y1="36.7982"
            x2="394.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
