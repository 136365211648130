import React, { FC } from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'
import { IContainerSquareProps } from '../../interface'
import { ContainerBackground } from '../container-background'

export const ContainerSquare3Casement: FC<IContainerSquareProps> = props => {
  const { onCasementClick, withoutBackground } = props

  return (
    <ProductImageWrapper isClickable>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/1999/xlink"
      >
        <rect width="449" height="333" fill="white" />
        <rect x="24" y="28" width="401" height="264" fill="#E8EAEE" />
        <rect x="24" y="28" width="401" height="264" fill="url(#3-1_pattern0)" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M439 10H10V292H24V29H425V292H439V10Z"
          fill="#C4C4C4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M439 292H10V308H0V316H10H439H449V308H439V292Z"
          fill="url(#3-1_paint0_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M425 28H23V292H425V28ZM150 127H37V193H150V127ZM37 41H150V107H37V41ZM149 213H37V279H149V213ZM168 41H281V107H168V41ZM281 127H168V193H281V127ZM168 213H281V279H168V213ZM413 213H300V279H413V213ZM300 127H413V193H300V127ZM412 41H300V107H412V41Z"
          fill="#E8EAEE"
        />
        <path d="M439 10L425 28.0571V292.075L439 308V10Z" fill="#E9EBEF" />
        <path d="M439 10L425 28.0571V292.075L439 308V10Z" fill="#020202" fillOpacity="0.06" />
        <path d="M10 10L24 28.1525V291.982L10 308V10Z" fill="#E9EBEF" />
        <path d="M10 10L24 28.1525V291.982L10 308V10Z" fill="#020202" fillOpacity="0.06" />
        <g
          filter="url(#3-1_filter0_i)"
          className="casement"
          data-index="2"
          onClick={onCasementClick(2)}
        >
          <rect x="300" y="41" width="112" height="66" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="300.47"
          y="41.47"
          width="111.06"
          height="65.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#3-1_filter1_i)"
          className="casement"
          data-index="0"
          onClick={onCasementClick(0)}
        >
          <rect x="37" y="41" width="113" height="66" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="37.47"
          y="41.47"
          width="112.06"
          height="65.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#3-1_filter2_i)"
          className="casement"
          data-index="3"
          onClick={onCasementClick(3)}
        >
          <rect x="37" y="127" width="113" height="66" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="37.47"
          y="127.47"
          width="112.06"
          height="65.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <path
          d="M100.985 65.7413H98.4473C97.8003 65.7413 97.2529 65.2346 97.2529 64.6357V64.4054C97.2529 63.8065 97.8003 63.2998 98.4473 63.2998H100.985C101.632 63.2998 102.18 63.8065 102.18 64.4054V64.6357C102.18 65.2576 101.657 65.7413 100.985 65.7413Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M83.4262 68.5652C83.7247 68.5652 83.7993 68.5652 84.1972 68.5652C84.9432 68.5652 85.4903 69.1052 85.4903 69.833C85.4903 70.5843 84.9184 71.1008 84.1474 71.1008C82.705 71.1008 81.8098 71.1008 80.3674 71.1008C79.8203 71.1008 79.3727 70.913 79.1488 70.4199C78.9499 70.1147 78.8255 69.2695 79.6213 68.5652C80.1436 67.9782 85.9131 61.7565 86.9327 60.6765C86.9824 60.6061 86.9327 60.5122 86.8581 60.5122C86.8332 60.5122 86.8332 60.5122 86.8084 60.5122C84.6448 60.5122 82.4812 60.5122 80.3177 60.5122C79.6711 60.5122 79.1737 60.1365 79.0494 59.573C78.9002 58.9156 79.1986 58.3522 79.7706 58.0939C79.9446 58.0235 80.1187 58 80.2928 58C83.5008 58 86.684 58 89.8921 58C90.5138 58 90.9366 58.1878 91.1852 58.7278C91.5085 59.4087 91.1604 59.8783 90.9366 60.1835C90.4889 60.7235 85.938 65.56 83.4262 68.2834C83.4014 68.3069 83.3765 68.3539 83.3268 68.3773C83.277 68.4478 83.3268 68.5652 83.4262 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M90.1854 71.1007H87.6475C87.0005 71.1007 86.4531 70.5816 86.4531 69.9681V69.7322C86.4531 69.1187 87.0005 68.5996 87.6475 68.5996H90.1854C90.8323 68.5996 91.3797 69.1187 91.3797 69.7322V69.9681C91.3797 70.6052 90.8323 71.1007 90.1854 71.1007Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M93.7158 64.5152C93.7158 62.7341 93.7158 60.9764 93.7158 59.1952C93.7158 58.4922 94.2379 58 94.9836 58C98.2152 58 101.447 58 104.678 58C105.424 58 105.946 58.5156 105.946 59.2421C105.946 59.9686 105.424 60.5077 104.678 60.5077C101.994 60.5077 99.3339 60.5077 96.6491 60.5077C96.4503 60.5077 96.4005 60.5545 96.4005 60.742C96.4005 63.2731 96.4005 65.8042 96.4005 68.3353C96.4005 68.5463 96.4751 68.5697 96.6491 68.5697C99.309 68.5697 101.994 68.5697 104.654 68.5697C105.25 68.5697 105.723 68.8978 105.897 69.39C106.095 69.9759 105.872 70.5618 105.399 70.8899C105.176 71.0305 104.902 71.1008 104.629 71.1008C101.447 71.1008 98.2401 71.1008 95.0582 71.1008C94.2378 71.1008 93.7407 70.5852 93.7407 69.8118C93.7158 68.0307 93.7158 66.273 93.7158 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M80.7054 76.0413V76.6981H79V76.0413H79.4263V74.2584H79.0502V73.6016H80.6803V74.2584H80.3041V76.0413H80.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M84.5346 76.7576H83.774L83.6513 76.5448C83.3814 76.6867 83.0624 76.7576 82.7679 76.7576C81.9336 76.734 81.1484 76.1665 81.1484 75.1498C81.1484 74.1804 81.811 73.542 82.817 73.542C83.6513 73.542 84.461 74.0858 84.461 75.1498C84.461 75.5281 84.3629 75.8119 84.2156 76.072L84.5592 76.6394V76.7576H84.5346ZM82.7925 76.001C83.1851 76.001 83.6022 75.7173 83.6022 75.1498C83.6022 74.5824 83.1851 74.2986 82.7925 74.2986C82.3753 74.2986 81.9827 74.5824 81.9827 75.1498C81.9827 75.7173 82.3753 76.001 82.7925 76.001Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M86.7838 75.7484C86.7838 75.9596 87.0294 76.077 87.2995 76.077C87.5451 76.077 87.7907 75.9596 87.7907 75.7953C87.7907 75.4902 87.4223 75.4902 87.2013 75.4667C86.6119 75.4197 86.0226 75.2085 86.0226 74.5513C86.0226 73.8706 86.6119 73.542 87.275 73.542C87.8889 73.542 88.5274 73.8706 88.5274 74.5513H87.717C87.717 74.34 87.496 74.2461 87.275 74.2461C87.0785 74.2461 86.8329 74.2931 86.8329 74.5278C86.8329 74.7391 87.0294 74.786 87.3241 74.8095C88.0117 74.8564 88.601 75.0207 88.601 75.7718C88.601 76.429 87.938 76.7576 87.275 76.7576C86.5874 76.7576 85.9243 76.429 85.9489 75.7484H86.7838Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M91.2285 74.3107H90.0374V74.7598H91.1292V75.4689H90.0374V75.9653H91.2533V76.6981H89.1689V73.6016H91.2036V74.3107H91.2285Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M94.6027 76.5799V76.6981H93.7218L93.159 75.729H92.7186V76.6981H91.8867V73.6016C92.3761 73.6016 92.841 73.6016 93.3059 73.6016C94.0888 73.6016 94.4559 74.1689 94.4559 74.7125C94.4559 75.0671 94.2846 75.4217 93.9665 75.6108L94.6027 76.5799ZM93.3059 75.0671C93.5505 75.0671 93.6484 74.878 93.6484 74.6653C93.6484 74.4762 93.5261 74.2871 93.3059 74.2871H92.7186V75.0671H93.3059Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M96.2757 75.3339L96.5525 74.527L96.9551 73.6016H97.8863V73.7202L96.4518 76.7577H96.0995L94.665 73.7202V73.6016H95.5962L96.024 74.527L96.2757 75.3339Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M99.7815 76.0413V76.6981H98.0762V76.0413H98.5025V74.2584H98.1263V73.6016H99.7565V74.2584H99.3803V76.0413H99.7815Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M101.835 73.6016C102.509 73.6016 103.258 73.9549 103.383 74.7792H102.559C102.434 74.4495 102.185 74.3317 101.835 74.3317C101.336 74.3317 101.011 74.6614 101.011 75.1796C101.011 75.6271 101.336 76.0275 101.835 76.0275C102.185 76.0275 102.484 75.8626 102.559 75.5329H103.383C103.258 76.4044 102.534 76.7577 101.835 76.7577C100.986 76.7577 100.162 76.2159 100.162 75.1796C100.162 74.1197 100.986 73.6016 101.835 73.6016Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M105.944 74.3107H104.753V74.7598H105.845V75.4689H104.753V75.9653H105.969V76.6981H103.885V73.6016H105.919V74.3107H105.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M100.985 151.741H98.4473C97.8003 151.741 97.2529 151.235 97.2529 150.636V150.405C97.2529 149.807 97.8003 149.3 98.4473 149.3H100.985C101.632 149.3 102.18 149.807 102.18 150.405V150.636C102.18 151.258 101.657 151.741 100.985 151.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M83.4262 154.565C83.7247 154.565 83.7993 154.565 84.1972 154.565C84.9432 154.565 85.4903 155.105 85.4903 155.833C85.4903 156.584 84.9184 157.101 84.1474 157.101C82.705 157.101 81.8098 157.101 80.3674 157.101C79.8203 157.101 79.3727 156.913 79.1488 156.42C78.9499 156.115 78.8255 155.27 79.6213 154.565C80.1436 153.978 85.9131 147.757 86.9327 146.677C86.9824 146.606 86.9327 146.512 86.8581 146.512C86.8332 146.512 86.8332 146.512 86.8084 146.512C84.6448 146.512 82.4812 146.512 80.3177 146.512C79.6711 146.512 79.1737 146.137 79.0494 145.573C78.9002 144.916 79.1986 144.352 79.7706 144.094C79.9446 144.023 80.1187 144 80.2928 144C83.5008 144 86.684 144 89.8921 144C90.5138 144 90.9366 144.188 91.1852 144.728C91.5085 145.409 91.1604 145.878 90.9366 146.183C90.4889 146.723 85.938 151.56 83.4262 154.283C83.4014 154.307 83.3765 154.354 83.3268 154.377C83.277 154.448 83.3268 154.565 83.4262 154.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M90.1854 157.101H87.6475C87.0005 157.101 86.4531 156.582 86.4531 155.968V155.732C86.4531 155.119 87.0005 154.6 87.6475 154.6H90.1854C90.8323 154.6 91.3797 155.119 91.3797 155.732V155.968C91.3797 156.605 90.8323 157.101 90.1854 157.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M93.7158 150.515C93.7158 148.734 93.7158 146.976 93.7158 145.195C93.7158 144.492 94.2379 144 94.9836 144C98.2152 144 101.447 144 104.678 144C105.424 144 105.946 144.516 105.946 145.242C105.946 145.969 105.424 146.508 104.678 146.508C101.994 146.508 99.3339 146.508 96.6491 146.508C96.4503 146.508 96.4005 146.555 96.4005 146.742C96.4005 149.273 96.4005 151.804 96.4005 154.335C96.4005 154.546 96.4751 154.57 96.6491 154.57C99.309 154.57 101.994 154.57 104.654 154.57C105.25 154.57 105.723 154.898 105.897 155.39C106.095 155.976 105.872 156.562 105.399 156.89C105.176 157.03 104.902 157.101 104.629 157.101C101.447 157.101 98.2401 157.101 95.0582 157.101C94.2378 157.101 93.7407 156.585 93.7407 155.812C93.7158 154.031 93.7158 152.273 93.7158 150.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M80.7054 162.041V162.698H79V162.041H79.4263V160.258H79.0502V159.602H80.6803V160.258H80.3041V162.041H80.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M84.5346 162.758H83.774L83.6513 162.545C83.3814 162.687 83.0624 162.758 82.7679 162.758C81.9336 162.734 81.1484 162.167 81.1484 161.15C81.1484 160.18 81.811 159.542 82.817 159.542C83.6513 159.542 84.461 160.086 84.461 161.15C84.461 161.528 84.3629 161.812 84.2156 162.072L84.5592 162.639V162.758H84.5346ZM82.7925 162.001C83.1851 162.001 83.6022 161.717 83.6022 161.15C83.6022 160.582 83.1851 160.299 82.7925 160.299C82.3753 160.299 81.9827 160.582 81.9827 161.15C81.9827 161.717 82.3753 162.001 82.7925 162.001Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M86.7838 161.748C86.7838 161.96 87.0294 162.077 87.2995 162.077C87.5451 162.077 87.7907 161.96 87.7907 161.795C87.7907 161.49 87.4223 161.49 87.2013 161.467C86.6119 161.42 86.0226 161.208 86.0226 160.551C86.0226 159.871 86.6119 159.542 87.275 159.542C87.8889 159.542 88.5274 159.871 88.5274 160.551H87.717C87.717 160.34 87.496 160.246 87.275 160.246C87.0785 160.246 86.8329 160.293 86.8329 160.528C86.8329 160.739 87.0294 160.786 87.3241 160.809C88.0117 160.856 88.601 161.021 88.601 161.772C88.601 162.429 87.938 162.758 87.275 162.758C86.5874 162.758 85.9243 162.429 85.9489 161.748H86.7838Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M91.2285 160.311H90.0374V160.76H91.1292V161.469H90.0374V161.965H91.2533V162.698H89.1689V159.602H91.2036V160.311H91.2285Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M94.6027 162.58V162.698H93.7218L93.159 161.729H92.7186V162.698H91.8867V159.602C92.3761 159.602 92.841 159.602 93.3059 159.602C94.0888 159.602 94.4559 160.169 94.4559 160.713C94.4559 161.067 94.2846 161.422 93.9665 161.611L94.6027 162.58ZM93.3059 161.067C93.5505 161.067 93.6484 160.878 93.6484 160.665C93.6484 160.476 93.5261 160.287 93.3059 160.287H92.7186V161.067H93.3059Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M96.2757 161.334L96.5525 160.527L96.9551 159.602H97.8863V159.72L96.4518 162.758H96.0995L94.665 159.72V159.602H95.5962L96.024 160.527L96.2757 161.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M99.7815 162.041V162.698H98.0762V162.041H98.5025V160.258H98.1263V159.602H99.7565V160.258H99.3803V162.041H99.7815Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M101.835 159.602C102.509 159.602 103.258 159.955 103.383 160.779H102.559C102.434 160.449 102.185 160.332 101.835 160.332C101.336 160.332 101.011 160.661 101.011 161.18C101.011 161.627 101.336 162.028 101.835 162.028C102.185 162.028 102.484 161.863 102.559 161.533H103.383C103.258 162.404 102.534 162.758 101.835 162.758C100.986 162.758 100.162 162.216 100.162 161.18C100.162 160.12 100.986 159.602 101.835 159.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M105.944 160.311H104.753V160.76H105.845V161.469H104.753V161.965H105.969V162.698H103.885V159.602H105.919V160.311H105.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M364.985 65.7413H362.447C361.8 65.7413 361.253 65.2346 361.253 64.6357V64.4054C361.253 63.8065 361.8 63.2998 362.447 63.2998H364.985C365.632 63.2998 366.18 63.8065 366.18 64.4054V64.6357C366.18 65.2576 365.657 65.7413 364.985 65.7413Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M347.426 68.5652C347.725 68.5652 347.799 68.5652 348.197 68.5652C348.943 68.5652 349.49 69.1052 349.49 69.833C349.49 70.5843 348.918 71.1008 348.147 71.1008C346.705 71.1008 345.81 71.1008 344.367 71.1008C343.82 71.1008 343.373 70.913 343.149 70.4199C342.95 70.1147 342.826 69.2695 343.621 68.5652C344.144 67.9782 349.913 61.7565 350.933 60.6765C350.982 60.6061 350.933 60.5122 350.858 60.5122C350.833 60.5122 350.833 60.5122 350.808 60.5122C348.645 60.5122 346.481 60.5122 344.318 60.5122C343.671 60.5122 343.174 60.1365 343.049 59.573C342.9 58.9156 343.199 58.3522 343.771 58.0939C343.945 58.0235 344.119 58 344.293 58C347.501 58 350.684 58 353.892 58C354.514 58 354.937 58.1878 355.185 58.7278C355.509 59.4087 355.16 59.8783 354.937 60.1835C354.489 60.7235 349.938 65.56 347.426 68.2834C347.401 68.3069 347.376 68.3539 347.327 68.3773C347.277 68.4478 347.327 68.5652 347.426 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M354.185 71.1007H351.647C351.001 71.1007 350.453 70.5816 350.453 69.9681V69.7322C350.453 69.1187 351.001 68.5996 351.647 68.5996H354.185C354.832 68.5996 355.38 69.1187 355.38 69.7322V69.9681C355.38 70.6052 354.832 71.1007 354.185 71.1007Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M357.716 64.5152C357.716 62.7341 357.716 60.9764 357.716 59.1952C357.716 58.4922 358.238 58 358.984 58C362.215 58 365.447 58 368.678 58C369.424 58 369.946 58.5156 369.946 59.2421C369.946 59.9686 369.424 60.5077 368.678 60.5077C365.994 60.5077 363.334 60.5077 360.649 60.5077C360.45 60.5077 360.401 60.5545 360.401 60.742C360.401 63.2731 360.401 65.8042 360.401 68.3353C360.401 68.5463 360.475 68.5697 360.649 68.5697C363.309 68.5697 365.994 68.5697 368.654 68.5697C369.25 68.5697 369.723 68.8978 369.897 69.39C370.095 69.9759 369.872 70.5618 369.399 70.8899C369.176 71.0305 368.902 71.1008 368.629 71.1008C365.447 71.1008 362.24 71.1008 359.058 71.1008C358.238 71.1008 357.741 70.5852 357.741 69.8118C357.716 68.0307 357.716 66.273 357.716 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M344.705 76.0413V76.6981H343V76.0413H343.426V74.2584H343.05V73.6016H344.68V74.2584H344.304V76.0413H344.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M348.535 76.7576H347.774L347.651 76.5448C347.381 76.6867 347.062 76.7576 346.768 76.7576C345.934 76.734 345.148 76.1665 345.148 75.1498C345.148 74.1804 345.811 73.542 346.817 73.542C347.651 73.542 348.461 74.0858 348.461 75.1498C348.461 75.5281 348.363 75.8119 348.216 76.072L348.559 76.6394V76.7576H348.535ZM346.792 76.001C347.185 76.001 347.602 75.7173 347.602 75.1498C347.602 74.5824 347.185 74.2986 346.792 74.2986C346.375 74.2986 345.983 74.5824 345.983 75.1498C345.983 75.7173 346.375 76.001 346.792 76.001Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M350.784 75.7484C350.784 75.9596 351.029 76.077 351.3 76.077C351.545 76.077 351.791 75.9596 351.791 75.7953C351.791 75.4902 351.422 75.4902 351.201 75.4667C350.612 75.4197 350.023 75.2085 350.023 74.5513C350.023 73.8706 350.612 73.542 351.275 73.542C351.889 73.542 352.527 73.8706 352.527 74.5513H351.717C351.717 74.34 351.496 74.2461 351.275 74.2461C351.079 74.2461 350.833 74.2931 350.833 74.5278C350.833 74.7391 351.029 74.786 351.324 74.8095C352.012 74.8564 352.601 75.0207 352.601 75.7718C352.601 76.429 351.938 76.7576 351.275 76.7576C350.587 76.7576 349.924 76.429 349.949 75.7484H350.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M355.228 74.3107H354.037V74.7598H355.129V75.4689H354.037V75.9653H355.253V76.6981H353.169V73.6016H355.204V74.3107H355.228Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M358.603 76.5799V76.6981H357.722L357.159 75.729H356.719V76.6981H355.887V73.6016C356.376 73.6016 356.841 73.6016 357.306 73.6016C358.089 73.6016 358.456 74.1689 358.456 74.7125C358.456 75.0671 358.285 75.4217 357.966 75.6108L358.603 76.5799ZM357.306 75.0671C357.551 75.0671 357.648 74.878 357.648 74.6653C357.648 74.4762 357.526 74.2871 357.306 74.2871H356.719V75.0671H357.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M360.276 75.3339L360.552 74.527L360.955 73.6016H361.886V73.7202L360.452 76.7577H360.099L358.665 73.7202V73.6016H359.596L360.024 74.527L360.276 75.3339Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M363.782 76.0413V76.6981H362.076V76.0413H362.503V74.2584H362.126V73.6016H363.756V74.2584H363.38V76.0413H363.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M365.835 73.6016C366.509 73.6016 367.258 73.9549 367.383 74.7792H366.559C366.434 74.4495 366.185 74.3317 365.835 74.3317C365.336 74.3317 365.011 74.6614 365.011 75.1796C365.011 75.6271 365.336 76.0275 365.835 76.0275C366.185 76.0275 366.484 75.8626 366.559 75.5329H367.383C367.258 76.4044 366.534 76.7577 365.835 76.7577C364.986 76.7577 364.162 76.2159 364.162 75.1796C364.162 74.1197 364.986 73.6016 365.835 73.6016Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M369.944 74.3107H368.753V74.7598H369.845V75.4689H368.753V75.9653H369.969V76.6981H367.885V73.6016H369.919V74.3107H369.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <rect y="316" width="449" height="17" fill="white" />
        <g
          filter="url(#3-1_filter3_i)"
          className="casement"
          data-index="6"
          onClick={onCasementClick(6)}
        >
          <rect x="37" y="213" width="113" height="66" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="37.47"
          y="213.47"
          width="112.06"
          height="65.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#3-1_filter4_i)"
          className="casement"
          data-index="8"
          onClick={onCasementClick(8)}
        >
          <rect x="300" y="213" width="113" height="66" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="300.47"
          y="213.47"
          width="112.06"
          height="65.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#3-1_filter5_i)"
          className="casement"
          data-index="5"
          onClick={onCasementClick(5)}
        >
          <rect x="300" y="127" width="113" height="66" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="300.47"
          y="127.47"
          width="112.06"
          height="65.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#3-1_filter6_i)"
          className="casement"
          data-index="4"
          onClick={onCasementClick(4)}
        >
          <rect x="168" y="127" width="113" height="66" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="168.47"
          y="127.47"
          width="112.06"
          height="65.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#3-1_filter7_i)"
          className="casement"
          data-index="7"
          onClick={onCasementClick(7)}
        >
          <rect x="168" y="213" width="113" height="66" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="168.47"
          y="213.47"
          width="112.06"
          height="65.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#3-1_filter8_i)"
          className="casement"
          data-index="1"
          onClick={onCasementClick(1)}
        >
          <rect x="168" y="41" width="113" height="66" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="168.47"
          y="41.47"
          width="112.06"
          height="65.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <path
          d="M101.985 237.741H99.4473C98.8003 237.741 98.2529 237.235 98.2529 236.636V236.405C98.2529 235.807 98.8003 235.3 99.4473 235.3H101.985C102.632 235.3 103.18 235.807 103.18 236.405V236.636C103.18 237.258 102.657 237.741 101.985 237.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M84.4262 240.565C84.7247 240.565 84.7993 240.565 85.1972 240.565C85.9432 240.565 86.4903 241.105 86.4903 241.833C86.4903 242.584 85.9184 243.101 85.1474 243.101C83.705 243.101 82.8098 243.101 81.3674 243.101C80.8203 243.101 80.3727 242.913 80.1488 242.42C79.9499 242.115 79.8255 241.27 80.6213 240.565C81.1436 239.978 86.9131 233.757 87.9327 232.677C87.9824 232.606 87.9327 232.512 87.8581 232.512C87.8332 232.512 87.8332 232.512 87.8084 232.512C85.6448 232.512 83.4812 232.512 81.3177 232.512C80.6711 232.512 80.1737 232.137 80.0494 231.573C79.9002 230.916 80.1986 230.352 80.7706 230.094C80.9446 230.023 81.1187 230 81.2928 230C84.5008 230 87.684 230 90.8921 230C91.5138 230 91.9366 230.188 92.1852 230.728C92.5085 231.409 92.1604 231.878 91.9366 232.183C91.4889 232.723 86.938 237.56 84.4262 240.283C84.4014 240.307 84.3765 240.354 84.3268 240.377C84.277 240.448 84.3268 240.565 84.4262 240.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M91.1854 243.101H88.6475C88.0005 243.101 87.4531 242.582 87.4531 241.968V241.732C87.4531 241.119 88.0005 240.6 88.6475 240.6H91.1854C91.8323 240.6 92.3797 241.119 92.3797 241.732V241.968C92.3797 242.605 91.8323 243.101 91.1854 243.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M94.7158 236.515C94.7158 234.734 94.7158 232.976 94.7158 231.195C94.7158 230.492 95.2379 230 95.9836 230C99.2152 230 102.447 230 105.678 230C106.424 230 106.946 230.516 106.946 231.242C106.946 231.969 106.424 232.508 105.678 232.508C102.994 232.508 100.334 232.508 97.6491 232.508C97.4503 232.508 97.4005 232.555 97.4005 232.742C97.4005 235.273 97.4005 237.804 97.4005 240.335C97.4005 240.546 97.4751 240.57 97.6491 240.57C100.309 240.57 102.994 240.57 105.654 240.57C106.25 240.57 106.723 240.898 106.897 241.39C107.095 241.976 106.872 242.562 106.399 242.89C106.176 243.03 105.902 243.101 105.629 243.101C102.447 243.101 99.2401 243.101 96.0582 243.101C95.2378 243.101 94.7407 242.585 94.7407 241.812C94.7158 240.031 94.7158 238.273 94.7158 236.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M81.7054 248.041V248.698H80V248.041H80.4263V246.258H80.0502V245.602H81.6803V246.258H81.3041V248.041H81.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M85.5346 248.758H84.774L84.6513 248.545C84.3814 248.687 84.0624 248.758 83.7679 248.758C82.9336 248.734 82.1484 248.167 82.1484 247.15C82.1484 246.18 82.811 245.542 83.817 245.542C84.6513 245.542 85.461 246.086 85.461 247.15C85.461 247.528 85.3629 247.812 85.2156 248.072L85.5592 248.639V248.758H85.5346ZM83.7925 248.001C84.1851 248.001 84.6022 247.717 84.6022 247.15C84.6022 246.582 84.1851 246.299 83.7925 246.299C83.3753 246.299 82.9827 246.582 82.9827 247.15C82.9827 247.717 83.3753 248.001 83.7925 248.001Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M87.7838 247.748C87.7838 247.96 88.0294 248.077 88.2995 248.077C88.5451 248.077 88.7907 247.96 88.7907 247.795C88.7907 247.49 88.4223 247.49 88.2013 247.467C87.6119 247.42 87.0226 247.208 87.0226 246.551C87.0226 245.871 87.6119 245.542 88.275 245.542C88.8889 245.542 89.5274 245.871 89.5274 246.551H88.717C88.717 246.34 88.496 246.246 88.275 246.246C88.0785 246.246 87.8329 246.293 87.8329 246.528C87.8329 246.739 88.0294 246.786 88.3241 246.809C89.0117 246.856 89.601 247.021 89.601 247.772C89.601 248.429 88.938 248.758 88.275 248.758C87.5874 248.758 86.9243 248.429 86.9489 247.748H87.7838Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M92.2285 246.311H91.0374V246.76H92.1292V247.469H91.0374V247.965H92.2533V248.698H90.1689V245.602H92.2036V246.311H92.2285Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M95.6027 248.58V248.698H94.7218L94.159 247.729H93.7186V248.698H92.8867V245.602C93.3761 245.602 93.841 245.602 94.3059 245.602C95.0888 245.602 95.4559 246.169 95.4559 246.713C95.4559 247.067 95.2846 247.422 94.9665 247.611L95.6027 248.58ZM94.3059 247.067C94.5505 247.067 94.6484 246.878 94.6484 246.665C94.6484 246.476 94.5261 246.287 94.3059 246.287H93.7186V247.067H94.3059Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M97.2757 247.334L97.5525 246.527L97.9551 245.602H98.8863V245.72L97.4518 248.758H97.0995L95.665 245.72V245.602H96.5962L97.024 246.527L97.2757 247.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M100.782 248.041V248.698H99.0762V248.041H99.5025V246.258H99.1263V245.602H100.756V246.258H100.38V248.041H100.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M102.835 245.602C103.509 245.602 104.258 245.955 104.383 246.779H103.559C103.434 246.449 103.185 246.332 102.835 246.332C102.336 246.332 102.011 246.661 102.011 247.18C102.011 247.627 102.336 248.028 102.835 248.028C103.185 248.028 103.484 247.863 103.559 247.533H104.383C104.258 248.404 103.534 248.758 102.835 248.758C101.986 248.758 101.162 248.216 101.162 247.18C101.162 246.12 101.986 245.602 102.835 245.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M106.944 246.311H105.753V246.76H106.845V247.469H105.753V247.965H106.969V248.698H104.885V245.602H106.919V246.311H106.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M365.985 237.741H363.447C362.8 237.741 362.253 237.235 362.253 236.636V236.405C362.253 235.807 362.8 235.3 363.447 235.3H365.985C366.632 235.3 367.18 235.807 367.18 236.405V236.636C367.18 237.258 366.657 237.741 365.985 237.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M348.426 240.565C348.725 240.565 348.799 240.565 349.197 240.565C349.943 240.565 350.49 241.105 350.49 241.833C350.49 242.584 349.918 243.101 349.147 243.101C347.705 243.101 346.81 243.101 345.367 243.101C344.82 243.101 344.373 242.913 344.149 242.42C343.95 242.115 343.826 241.27 344.621 240.565C345.144 239.978 350.913 233.757 351.933 232.677C351.982 232.606 351.933 232.512 351.858 232.512C351.833 232.512 351.833 232.512 351.808 232.512C349.645 232.512 347.481 232.512 345.318 232.512C344.671 232.512 344.174 232.137 344.049 231.573C343.9 230.916 344.199 230.352 344.771 230.094C344.945 230.023 345.119 230 345.293 230C348.501 230 351.684 230 354.892 230C355.514 230 355.937 230.188 356.185 230.728C356.509 231.409 356.16 231.878 355.937 232.183C355.489 232.723 350.938 237.56 348.426 240.283C348.401 240.307 348.376 240.354 348.327 240.377C348.277 240.448 348.327 240.565 348.426 240.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M355.185 243.101H352.647C352.001 243.101 351.453 242.582 351.453 241.968V241.732C351.453 241.119 352.001 240.6 352.647 240.6H355.185C355.832 240.6 356.38 241.119 356.38 241.732V241.968C356.38 242.605 355.832 243.101 355.185 243.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M358.716 236.515C358.716 234.734 358.716 232.976 358.716 231.195C358.716 230.492 359.238 230 359.984 230C363.215 230 366.447 230 369.678 230C370.424 230 370.946 230.516 370.946 231.242C370.946 231.969 370.424 232.508 369.678 232.508C366.994 232.508 364.334 232.508 361.649 232.508C361.45 232.508 361.401 232.555 361.401 232.742C361.401 235.273 361.401 237.804 361.401 240.335C361.401 240.546 361.475 240.57 361.649 240.57C364.309 240.57 366.994 240.57 369.654 240.57C370.25 240.57 370.723 240.898 370.897 241.39C371.095 241.976 370.872 242.562 370.399 242.89C370.176 243.03 369.902 243.101 369.629 243.101C366.447 243.101 363.24 243.101 360.058 243.101C359.238 243.101 358.741 242.585 358.741 241.812C358.716 240.031 358.716 238.273 358.716 236.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M345.705 248.041V248.698H344V248.041H344.426V246.258H344.05V245.602H345.68V246.258H345.304V248.041H345.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M349.535 248.758H348.774L348.651 248.545C348.381 248.687 348.062 248.758 347.768 248.758C346.934 248.734 346.148 248.167 346.148 247.15C346.148 246.18 346.811 245.542 347.817 245.542C348.651 245.542 349.461 246.086 349.461 247.15C349.461 247.528 349.363 247.812 349.216 248.072L349.559 248.639V248.758H349.535ZM347.792 248.001C348.185 248.001 348.602 247.717 348.602 247.15C348.602 246.582 348.185 246.299 347.792 246.299C347.375 246.299 346.983 246.582 346.983 247.15C346.983 247.717 347.375 248.001 347.792 248.001Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M351.784 247.748C351.784 247.96 352.029 248.077 352.3 248.077C352.545 248.077 352.791 247.96 352.791 247.795C352.791 247.49 352.422 247.49 352.201 247.467C351.612 247.42 351.023 247.208 351.023 246.551C351.023 245.871 351.612 245.542 352.275 245.542C352.889 245.542 353.527 245.871 353.527 246.551H352.717C352.717 246.34 352.496 246.246 352.275 246.246C352.079 246.246 351.833 246.293 351.833 246.528C351.833 246.739 352.029 246.786 352.324 246.809C353.012 246.856 353.601 247.021 353.601 247.772C353.601 248.429 352.938 248.758 352.275 248.758C351.587 248.758 350.924 248.429 350.949 247.748H351.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M356.228 246.311H355.037V246.76H356.129V247.469H355.037V247.965H356.253V248.698H354.169V245.602H356.204V246.311H356.228Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M359.603 248.58V248.698H358.722L358.159 247.729H357.719V248.698H356.887V245.602C357.376 245.602 357.841 245.602 358.306 245.602C359.089 245.602 359.456 246.169 359.456 246.713C359.456 247.067 359.285 247.422 358.966 247.611L359.603 248.58ZM358.306 247.067C358.551 247.067 358.648 246.878 358.648 246.665C358.648 246.476 358.526 246.287 358.306 246.287H357.719V247.067H358.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M361.276 247.334L361.552 246.527L361.955 245.602H362.886V245.72L361.452 248.758H361.099L359.665 245.72V245.602H360.596L361.024 246.527L361.276 247.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M364.782 248.041V248.698H363.076V248.041H363.503V246.258H363.126V245.602H364.756V246.258H364.38V248.041H364.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M366.835 245.602C367.509 245.602 368.258 245.955 368.383 246.779H367.559C367.434 246.449 367.185 246.332 366.835 246.332C366.336 246.332 366.011 246.661 366.011 247.18C366.011 247.627 366.336 248.028 366.835 248.028C367.185 248.028 367.484 247.863 367.559 247.533H368.383C368.258 248.404 367.534 248.758 366.835 248.758C365.986 248.758 365.162 248.216 365.162 247.18C365.162 246.12 365.986 245.602 366.835 245.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M370.944 246.311H369.753V246.76H370.845V247.469H369.753V247.965H370.969V248.698H368.885V245.602H370.919V246.311H370.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M365.985 151.741H363.447C362.8 151.741 362.253 151.235 362.253 150.636V150.405C362.253 149.807 362.8 149.3 363.447 149.3H365.985C366.632 149.3 367.18 149.807 367.18 150.405V150.636C367.18 151.258 366.657 151.741 365.985 151.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M348.426 154.565C348.725 154.565 348.799 154.565 349.197 154.565C349.943 154.565 350.49 155.105 350.49 155.833C350.49 156.584 349.918 157.101 349.147 157.101C347.705 157.101 346.81 157.101 345.367 157.101C344.82 157.101 344.373 156.913 344.149 156.42C343.95 156.115 343.826 155.27 344.621 154.565C345.144 153.978 350.913 147.757 351.933 146.677C351.982 146.606 351.933 146.512 351.858 146.512C351.833 146.512 351.833 146.512 351.808 146.512C349.645 146.512 347.481 146.512 345.318 146.512C344.671 146.512 344.174 146.137 344.049 145.573C343.9 144.916 344.199 144.352 344.771 144.094C344.945 144.023 345.119 144 345.293 144C348.501 144 351.684 144 354.892 144C355.514 144 355.937 144.188 356.185 144.728C356.509 145.409 356.16 145.878 355.937 146.183C355.489 146.723 350.938 151.56 348.426 154.283C348.401 154.307 348.376 154.354 348.327 154.377C348.277 154.448 348.327 154.565 348.426 154.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M355.185 157.101H352.647C352.001 157.101 351.453 156.582 351.453 155.968V155.732C351.453 155.119 352.001 154.6 352.647 154.6H355.185C355.832 154.6 356.38 155.119 356.38 155.732V155.968C356.38 156.605 355.832 157.101 355.185 157.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M358.716 150.515C358.716 148.734 358.716 146.976 358.716 145.195C358.716 144.492 359.238 144 359.984 144C363.215 144 366.447 144 369.678 144C370.424 144 370.946 144.516 370.946 145.242C370.946 145.969 370.424 146.508 369.678 146.508C366.994 146.508 364.334 146.508 361.649 146.508C361.45 146.508 361.401 146.555 361.401 146.742C361.401 149.273 361.401 151.804 361.401 154.335C361.401 154.546 361.475 154.57 361.649 154.57C364.309 154.57 366.994 154.57 369.654 154.57C370.25 154.57 370.723 154.898 370.897 155.39C371.095 155.976 370.872 156.562 370.399 156.89C370.176 157.03 369.902 157.101 369.629 157.101C366.447 157.101 363.24 157.101 360.058 157.101C359.238 157.101 358.741 156.585 358.741 155.812C358.716 154.031 358.716 152.273 358.716 150.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M345.705 162.041V162.698H344V162.041H344.426V160.258H344.05V159.602H345.68V160.258H345.304V162.041H345.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M349.535 162.758H348.774L348.651 162.545C348.381 162.687 348.062 162.758 347.768 162.758C346.934 162.734 346.148 162.167 346.148 161.15C346.148 160.18 346.811 159.542 347.817 159.542C348.651 159.542 349.461 160.086 349.461 161.15C349.461 161.528 349.363 161.812 349.216 162.072L349.559 162.639V162.758H349.535ZM347.792 162.001C348.185 162.001 348.602 161.717 348.602 161.15C348.602 160.582 348.185 160.299 347.792 160.299C347.375 160.299 346.983 160.582 346.983 161.15C346.983 161.717 347.375 162.001 347.792 162.001Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M351.784 161.748C351.784 161.96 352.029 162.077 352.3 162.077C352.545 162.077 352.791 161.96 352.791 161.795C352.791 161.49 352.422 161.49 352.201 161.467C351.612 161.42 351.023 161.208 351.023 160.551C351.023 159.871 351.612 159.542 352.275 159.542C352.889 159.542 353.527 159.871 353.527 160.551H352.717C352.717 160.34 352.496 160.246 352.275 160.246C352.079 160.246 351.833 160.293 351.833 160.528C351.833 160.739 352.029 160.786 352.324 160.809C353.012 160.856 353.601 161.021 353.601 161.772C353.601 162.429 352.938 162.758 352.275 162.758C351.587 162.758 350.924 162.429 350.949 161.748H351.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M356.228 160.311H355.037V160.76H356.129V161.469H355.037V161.965H356.253V162.698H354.169V159.602H356.204V160.311H356.228Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M359.603 162.58V162.698H358.722L358.159 161.729H357.719V162.698H356.887V159.602C357.376 159.602 357.841 159.602 358.306 159.602C359.089 159.602 359.456 160.169 359.456 160.713C359.456 161.067 359.285 161.422 358.966 161.611L359.603 162.58ZM358.306 161.067C358.551 161.067 358.648 160.878 358.648 160.665C358.648 160.476 358.526 160.287 358.306 160.287H357.719V161.067H358.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M361.276 161.334L361.552 160.527L361.955 159.602H362.886V159.72L361.452 162.758H361.099L359.665 159.72V159.602H360.596L361.024 160.527L361.276 161.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M364.782 162.041V162.698H363.076V162.041H363.503V160.258H363.126V159.602H364.756V160.258H364.38V162.041H364.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M366.835 159.602C367.509 159.602 368.258 159.955 368.383 160.779H367.559C367.434 160.449 367.185 160.332 366.835 160.332C366.336 160.332 366.011 160.661 366.011 161.18C366.011 161.627 366.336 162.028 366.835 162.028C367.185 162.028 367.484 161.863 367.559 161.533H368.383C368.258 162.404 367.534 162.758 366.835 162.758C365.986 162.758 365.162 162.216 365.162 161.18C365.162 160.12 365.986 159.602 366.835 159.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M370.944 160.311H369.753V160.76H370.845V161.469H369.753V161.965H370.969V162.698H368.885V159.602H370.919V160.311H370.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M233.985 151.741H231.447C230.8 151.741 230.253 151.235 230.253 150.636V150.405C230.253 149.807 230.8 149.3 231.447 149.3H233.985C234.632 149.3 235.18 149.807 235.18 150.405V150.636C235.18 151.258 234.657 151.741 233.985 151.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M216.426 154.565C216.725 154.565 216.799 154.565 217.197 154.565C217.943 154.565 218.49 155.105 218.49 155.833C218.49 156.584 217.918 157.101 217.147 157.101C215.705 157.101 214.81 157.101 213.367 157.101C212.82 157.101 212.373 156.913 212.149 156.42C211.95 156.115 211.826 155.27 212.621 154.565C213.144 153.978 218.913 147.757 219.933 146.677C219.982 146.606 219.933 146.512 219.858 146.512C219.833 146.512 219.833 146.512 219.808 146.512C217.645 146.512 215.481 146.512 213.318 146.512C212.671 146.512 212.174 146.137 212.049 145.573C211.9 144.916 212.199 144.352 212.771 144.094C212.945 144.023 213.119 144 213.293 144C216.501 144 219.684 144 222.892 144C223.514 144 223.937 144.188 224.185 144.728C224.509 145.409 224.16 145.878 223.937 146.183C223.489 146.723 218.938 151.56 216.426 154.283C216.401 154.307 216.376 154.354 216.327 154.377C216.277 154.448 216.327 154.565 216.426 154.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M223.185 157.101H220.647C220.001 157.101 219.453 156.582 219.453 155.968V155.732C219.453 155.119 220.001 154.6 220.647 154.6H223.185C223.832 154.6 224.38 155.119 224.38 155.732V155.968C224.38 156.605 223.832 157.101 223.185 157.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M226.716 150.515C226.716 148.734 226.716 146.976 226.716 145.195C226.716 144.492 227.238 144 227.984 144C231.215 144 234.447 144 237.678 144C238.424 144 238.946 144.516 238.946 145.242C238.946 145.969 238.424 146.508 237.678 146.508C234.994 146.508 232.334 146.508 229.649 146.508C229.45 146.508 229.401 146.555 229.401 146.742C229.401 149.273 229.401 151.804 229.401 154.335C229.401 154.546 229.475 154.57 229.649 154.57C232.309 154.57 234.994 154.57 237.654 154.57C238.25 154.57 238.723 154.898 238.897 155.39C239.095 155.976 238.872 156.562 238.399 156.89C238.176 157.03 237.902 157.101 237.629 157.101C234.447 157.101 231.24 157.101 228.058 157.101C227.238 157.101 226.741 156.585 226.741 155.812C226.716 154.031 226.716 152.273 226.716 150.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M213.705 162.041V162.698H212V162.041H212.426V160.258H212.05V159.602H213.68V160.258H213.304V162.041H213.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M217.535 162.758H216.774L216.651 162.545C216.381 162.687 216.062 162.758 215.768 162.758C214.934 162.734 214.148 162.167 214.148 161.15C214.148 160.18 214.811 159.542 215.817 159.542C216.651 159.542 217.461 160.086 217.461 161.15C217.461 161.528 217.363 161.812 217.216 162.072L217.559 162.639V162.758H217.535ZM215.792 162.001C216.185 162.001 216.602 161.717 216.602 161.15C216.602 160.582 216.185 160.299 215.792 160.299C215.375 160.299 214.983 160.582 214.983 161.15C214.983 161.717 215.375 162.001 215.792 162.001Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M219.784 161.748C219.784 161.96 220.029 162.077 220.3 162.077C220.545 162.077 220.791 161.96 220.791 161.795C220.791 161.49 220.422 161.49 220.201 161.467C219.612 161.42 219.023 161.208 219.023 160.551C219.023 159.871 219.612 159.542 220.275 159.542C220.889 159.542 221.527 159.871 221.527 160.551H220.717C220.717 160.34 220.496 160.246 220.275 160.246C220.079 160.246 219.833 160.293 219.833 160.528C219.833 160.739 220.029 160.786 220.324 160.809C221.012 160.856 221.601 161.021 221.601 161.772C221.601 162.429 220.938 162.758 220.275 162.758C219.587 162.758 218.924 162.429 218.949 161.748H219.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M224.228 160.311H223.037V160.76H224.129V161.469H223.037V161.965H224.253V162.698H222.169V159.602H224.204V160.311H224.228Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M227.603 162.58V162.698H226.722L226.159 161.729H225.719V162.698H224.887V159.602C225.376 159.602 225.841 159.602 226.306 159.602C227.089 159.602 227.456 160.169 227.456 160.713C227.456 161.067 227.285 161.422 226.966 161.611L227.603 162.58ZM226.306 161.067C226.551 161.067 226.648 160.878 226.648 160.665C226.648 160.476 226.526 160.287 226.306 160.287H225.719V161.067H226.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M229.276 161.334L229.552 160.527L229.955 159.602H230.886V159.72L229.452 162.758H229.099L227.665 159.72V159.602H228.596L229.024 160.527L229.276 161.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M232.782 162.041V162.698H231.076V162.041H231.503V160.258H231.126V159.602H232.756V160.258H232.38V162.041H232.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M234.835 159.602C235.509 159.602 236.258 159.955 236.383 160.779H235.559C235.434 160.449 235.185 160.332 234.835 160.332C234.336 160.332 234.011 160.661 234.011 161.18C234.011 161.627 234.336 162.028 234.835 162.028C235.185 162.028 235.484 161.863 235.559 161.533H236.383C236.258 162.404 235.534 162.758 234.835 162.758C233.986 162.758 233.162 162.216 233.162 161.18C233.162 160.12 233.986 159.602 234.835 159.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M238.944 160.311H237.753V160.76H238.845V161.469H237.753V161.965H238.969V162.698H236.885V159.602H238.919V160.311H238.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M233.985 237.741H231.447C230.8 237.741 230.253 237.235 230.253 236.636V236.405C230.253 235.807 230.8 235.3 231.447 235.3H233.985C234.632 235.3 235.18 235.807 235.18 236.405V236.636C235.18 237.258 234.657 237.741 233.985 237.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M216.426 240.565C216.725 240.565 216.799 240.565 217.197 240.565C217.943 240.565 218.49 241.105 218.49 241.833C218.49 242.584 217.918 243.101 217.147 243.101C215.705 243.101 214.81 243.101 213.367 243.101C212.82 243.101 212.373 242.913 212.149 242.42C211.95 242.115 211.826 241.27 212.621 240.565C213.144 239.978 218.913 233.757 219.933 232.677C219.982 232.606 219.933 232.512 219.858 232.512C219.833 232.512 219.833 232.512 219.808 232.512C217.645 232.512 215.481 232.512 213.318 232.512C212.671 232.512 212.174 232.137 212.049 231.573C211.9 230.916 212.199 230.352 212.771 230.094C212.945 230.023 213.119 230 213.293 230C216.501 230 219.684 230 222.892 230C223.514 230 223.937 230.188 224.185 230.728C224.509 231.409 224.16 231.878 223.937 232.183C223.489 232.723 218.938 237.56 216.426 240.283C216.401 240.307 216.376 240.354 216.327 240.377C216.277 240.448 216.327 240.565 216.426 240.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M223.185 243.101H220.647C220.001 243.101 219.453 242.582 219.453 241.968V241.732C219.453 241.119 220.001 240.6 220.647 240.6H223.185C223.832 240.6 224.38 241.119 224.38 241.732V241.968C224.38 242.605 223.832 243.101 223.185 243.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M226.716 236.515C226.716 234.734 226.716 232.976 226.716 231.195C226.716 230.492 227.238 230 227.984 230C231.215 230 234.447 230 237.678 230C238.424 230 238.946 230.516 238.946 231.242C238.946 231.969 238.424 232.508 237.678 232.508C234.994 232.508 232.334 232.508 229.649 232.508C229.45 232.508 229.401 232.555 229.401 232.742C229.401 235.273 229.401 237.804 229.401 240.335C229.401 240.546 229.475 240.57 229.649 240.57C232.309 240.57 234.994 240.57 237.654 240.57C238.25 240.57 238.723 240.898 238.897 241.39C239.095 241.976 238.872 242.562 238.399 242.89C238.176 243.03 237.902 243.101 237.629 243.101C234.447 243.101 231.24 243.101 228.058 243.101C227.238 243.101 226.741 242.585 226.741 241.812C226.716 240.031 226.716 238.273 226.716 236.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M213.705 248.041V248.698H212V248.041H212.426V246.258H212.05V245.602H213.68V246.258H213.304V248.041H213.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M217.535 248.758H216.774L216.651 248.545C216.381 248.687 216.062 248.758 215.768 248.758C214.934 248.734 214.148 248.167 214.148 247.15C214.148 246.18 214.811 245.542 215.817 245.542C216.651 245.542 217.461 246.086 217.461 247.15C217.461 247.528 217.363 247.812 217.216 248.072L217.559 248.639V248.758H217.535ZM215.792 248.001C216.185 248.001 216.602 247.717 216.602 247.15C216.602 246.582 216.185 246.299 215.792 246.299C215.375 246.299 214.983 246.582 214.983 247.15C214.983 247.717 215.375 248.001 215.792 248.001Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M219.784 247.748C219.784 247.96 220.029 248.077 220.3 248.077C220.545 248.077 220.791 247.96 220.791 247.795C220.791 247.49 220.422 247.49 220.201 247.467C219.612 247.42 219.023 247.208 219.023 246.551C219.023 245.871 219.612 245.542 220.275 245.542C220.889 245.542 221.527 245.871 221.527 246.551H220.717C220.717 246.34 220.496 246.246 220.275 246.246C220.079 246.246 219.833 246.293 219.833 246.528C219.833 246.739 220.029 246.786 220.324 246.809C221.012 246.856 221.601 247.021 221.601 247.772C221.601 248.429 220.938 248.758 220.275 248.758C219.587 248.758 218.924 248.429 218.949 247.748H219.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M224.228 246.311H223.037V246.76H224.129V247.469H223.037V247.965H224.253V248.698H222.169V245.602H224.204V246.311H224.228Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M227.603 248.58V248.698H226.722L226.159 247.729H225.719V248.698H224.887V245.602C225.376 245.602 225.841 245.602 226.306 245.602C227.089 245.602 227.456 246.169 227.456 246.713C227.456 247.067 227.285 247.422 226.966 247.611L227.603 248.58ZM226.306 247.067C226.551 247.067 226.648 246.878 226.648 246.665C226.648 246.476 226.526 246.287 226.306 246.287H225.719V247.067H226.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M229.276 247.334L229.552 246.527L229.955 245.602H230.886V245.72L229.452 248.758H229.099L227.665 245.72V245.602H228.596L229.024 246.527L229.276 247.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M232.782 248.041V248.698H231.076V248.041H231.503V246.258H231.126V245.602H232.756V246.258H232.38V248.041H232.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M234.835 245.602C235.509 245.602 236.258 245.955 236.383 246.779H235.559C235.434 246.449 235.185 246.332 234.835 246.332C234.336 246.332 234.011 246.661 234.011 247.18C234.011 247.627 234.336 248.028 234.835 248.028C235.185 248.028 235.484 247.863 235.559 247.533H236.383C236.258 248.404 235.534 248.758 234.835 248.758C233.986 248.758 233.162 248.216 233.162 247.18C233.162 246.12 233.986 245.602 234.835 245.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M238.944 246.311H237.753V246.76H238.845V247.469H237.753V247.965H238.969V248.698H236.885V245.602H238.919V246.311H238.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M233.985 65.7413H231.447C230.8 65.7413 230.253 65.2346 230.253 64.6357V64.4054C230.253 63.8065 230.8 63.2998 231.447 63.2998H233.985C234.632 63.2998 235.18 63.8065 235.18 64.4054V64.6357C235.18 65.2576 234.657 65.7413 233.985 65.7413Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M216.426 68.5652C216.725 68.5652 216.799 68.5652 217.197 68.5652C217.943 68.5652 218.49 69.1052 218.49 69.833C218.49 70.5843 217.918 71.1008 217.147 71.1008C215.705 71.1008 214.81 71.1008 213.367 71.1008C212.82 71.1008 212.373 70.913 212.149 70.4199C211.95 70.1147 211.826 69.2695 212.621 68.5652C213.144 67.9782 218.913 61.7565 219.933 60.6765C219.982 60.6061 219.933 60.5122 219.858 60.5122C219.833 60.5122 219.833 60.5122 219.808 60.5122C217.645 60.5122 215.481 60.5122 213.318 60.5122C212.671 60.5122 212.174 60.1365 212.049 59.573C211.9 58.9156 212.199 58.3522 212.771 58.0939C212.945 58.0235 213.119 58 213.293 58C216.501 58 219.684 58 222.892 58C223.514 58 223.937 58.1878 224.185 58.7278C224.509 59.4087 224.16 59.8783 223.937 60.1835C223.489 60.7235 218.938 65.56 216.426 68.2834C216.401 68.3069 216.376 68.3539 216.327 68.3773C216.277 68.4478 216.327 68.5652 216.426 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M223.185 71.1007H220.647C220.001 71.1007 219.453 70.5816 219.453 69.9681V69.7322C219.453 69.1187 220.001 68.5996 220.647 68.5996H223.185C223.832 68.5996 224.38 69.1187 224.38 69.7322V69.9681C224.38 70.6052 223.832 71.1007 223.185 71.1007Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M226.716 64.5152C226.716 62.7341 226.716 60.9764 226.716 59.1952C226.716 58.4922 227.238 58 227.984 58C231.215 58 234.447 58 237.678 58C238.424 58 238.946 58.5156 238.946 59.2421C238.946 59.9686 238.424 60.5077 237.678 60.5077C234.994 60.5077 232.334 60.5077 229.649 60.5077C229.45 60.5077 229.401 60.5545 229.401 60.742C229.401 63.2731 229.401 65.8042 229.401 68.3353C229.401 68.5463 229.475 68.5697 229.649 68.5697C232.309 68.5697 234.994 68.5697 237.654 68.5697C238.25 68.5697 238.723 68.8978 238.897 69.39C239.095 69.9759 238.872 70.5618 238.399 70.8899C238.176 71.0305 237.902 71.1008 237.629 71.1008C234.447 71.1008 231.24 71.1008 228.058 71.1008C227.238 71.1008 226.741 70.5852 226.741 69.8118C226.716 68.0307 226.716 66.273 226.716 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M213.705 76.0413V76.6981H212V76.0413H212.426V74.2584H212.05V73.6016H213.68V74.2584H213.304V76.0413H213.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M217.535 76.7576H216.774L216.651 76.5448C216.381 76.6867 216.062 76.7576 215.768 76.7576C214.934 76.734 214.148 76.1665 214.148 75.1498C214.148 74.1804 214.811 73.542 215.817 73.542C216.651 73.542 217.461 74.0858 217.461 75.1498C217.461 75.5281 217.363 75.8119 217.216 76.072L217.559 76.6394V76.7576H217.535ZM215.792 76.001C216.185 76.001 216.602 75.7173 216.602 75.1498C216.602 74.5824 216.185 74.2986 215.792 74.2986C215.375 74.2986 214.983 74.5824 214.983 75.1498C214.983 75.7173 215.375 76.001 215.792 76.001Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M219.784 75.7484C219.784 75.9596 220.029 76.077 220.3 76.077C220.545 76.077 220.791 75.9596 220.791 75.7953C220.791 75.4902 220.422 75.4902 220.201 75.4667C219.612 75.4197 219.023 75.2085 219.023 74.5513C219.023 73.8706 219.612 73.542 220.275 73.542C220.889 73.542 221.527 73.8706 221.527 74.5513H220.717C220.717 74.34 220.496 74.2461 220.275 74.2461C220.079 74.2461 219.833 74.2931 219.833 74.5278C219.833 74.7391 220.029 74.786 220.324 74.8095C221.012 74.8564 221.601 75.0207 221.601 75.7718C221.601 76.429 220.938 76.7576 220.275 76.7576C219.587 76.7576 218.924 76.429 218.949 75.7484H219.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M224.228 74.3107H223.037V74.7598H224.129V75.4689H223.037V75.9653H224.253V76.6981H222.169V73.6016H224.204V74.3107H224.228Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M227.603 76.5799V76.6981H226.722L226.159 75.729H225.719V76.6981H224.887V73.6016C225.376 73.6016 225.841 73.6016 226.306 73.6016C227.089 73.6016 227.456 74.1689 227.456 74.7125C227.456 75.0671 227.285 75.4217 226.966 75.6108L227.603 76.5799ZM226.306 75.0671C226.551 75.0671 226.648 74.878 226.648 74.6653C226.648 74.4762 226.526 74.2871 226.306 74.2871H225.719V75.0671H226.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M229.276 75.3339L229.552 74.527L229.955 73.6016H230.886V73.7202L229.452 76.7577H229.099L227.665 73.7202V73.6016H228.596L229.024 74.527L229.276 75.3339Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M232.782 76.0413V76.6981H231.076V76.0413H231.503V74.2584H231.126V73.6016H232.756V74.2584H232.38V76.0413H232.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M234.835 73.6016C235.509 73.6016 236.258 73.9549 236.383 74.7792H235.559C235.434 74.4495 235.185 74.3317 234.835 74.3317C234.336 74.3317 234.011 74.6614 234.011 75.1796C234.011 75.6271 234.336 76.0275 234.835 76.0275C235.185 76.0275 235.484 75.8626 235.559 75.5329H236.383C236.258 76.4044 235.534 76.7577 234.835 76.7577C233.986 76.7577 233.162 76.2159 233.162 75.1796C233.162 74.1197 233.986 73.6016 234.835 73.6016Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M238.944 74.3107H237.753V74.7598H238.845V75.4689H237.753V75.9653H238.969V76.6981H236.885V73.6016H238.919V74.3107H238.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <defs>
          <pattern id="3-1_pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use href="#image0" transform="translate(-0.00768784) scale(0.00100915 0.00153332)" />
          </pattern>
          <filter
            id="3-1_filter0_i"
            x="300"
            y="41"
            width="112.94"
            height="68.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-1_filter1_i"
            x="37"
            y="41"
            width="113.94"
            height="68.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-1_filter2_i"
            x="37"
            y="127"
            width="113.94"
            height="68.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-1_filter3_i"
            x="37"
            y="213"
            width="113.94"
            height="68.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-1_filter4_i"
            x="300"
            y="213"
            width="113.94"
            height="68.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-1_filter5_i"
            x="300"
            y="127"
            width="113.94"
            height="68.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-1_filter6_i"
            x="168"
            y="127"
            width="113.94"
            height="68.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-1_filter7_i"
            x="168"
            y="213"
            width="113.94"
            height="68.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-1_filter8_i"
            x="168"
            y="41"
            width="113.94"
            height="68.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <linearGradient
            id="3-1_paint0_linear"
            x1="301.5"
            y1="291"
            x2="301.5"
            y2="316"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DBDDE1" />
            <stop offset="1" stopColor="#F9F9F9" />
          </linearGradient>
        </defs>
      </svg>
      {!withoutBackground && <ContainerBackground />}
    </ProductImageWrapper>
  )
}
