import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement25Square4BottomCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-5_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M193.001 34C194.105 34 195 33.1048 195 32.0005C195 30.8962 194.105 30.001 193.001 30.001L190.833 30.001L190.833 30.001L180 30.001L180 30L176.999 30C175.895 30 175 30.8952 175 31.9995C175 33.1038 175.895 33.999 176.999 33.999L179.167 33.999L179.167 34L190 34L190 34L193.001 34Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 190.834 34)"
          fill="#ABABAB"
        />
        <path
          d="M194.06 34C194.579 34 195 33.5792 195 33.0601L195 30.941C195 30.4219 194.579 30.001 194.06 30.001L190 30.001L190 34L194.06 34Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M175.94 30C175.421 30 175 30.4208 175 30.9399L175 33.059C175 33.5781 175.421 33.999 175.94 33.999L180 33.999L180 30L175.94 30Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 189.167 34)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#2-5_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M271.001 34C272.105 34 273 33.1048 273 32.0005C273 30.8962 272.105 30.001 271.001 30.001L268.833 30.001L268.833 30.001L258 30.001L258 30L254.999 30C253.895 30 253 30.8952 253 31.9995C253 33.1038 253.895 33.999 254.999 33.999L257.167 33.999L257.167 34L268 34L268 34L271.001 34Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 268.834 34)"
          fill="#ABABAB"
        />
        <path
          d="M272.06 34C272.579 34 273 33.5792 273 33.0601L273 30.941C273 30.4219 272.579 30.001 272.06 30.001L268 30.001L268 34L272.06 34Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M253.94 30C253.421 30 253 30.4208 253 30.9399L253 33.059C253 33.5781 253.421 33.999 253.94 33.999L258 33.999L258 30L253.94 30Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 267.167 34)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#2-5_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M284 34H164V193H284V34ZM273 45H175V182H273V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M284 34H164V193H165V35H283V193H284V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="168.53"
          y="38.53"
          width="110.94"
          height="149.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#2-5_filter3_i)">
          <rect
            x="175.47"
            y="45.47"
            width="97.0601"
            height="136.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="232"
          y="184"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 232 184)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="231.355"
          y="184.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 231.355 184.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M231.238 186.695C230.892 185.542 229.822 184.702 228.556 184.702L221.916 184.702C220.37 184.702 219.116 185.955 219.116 187.502C219.116 187.537 219.117 187.572 219.118 187.607C219.465 188.76 220.534 189.6 221.8 189.6L228.44 189.6C229.987 189.6 231.24 188.346 231.24 186.8C231.24 186.765 231.24 186.73 231.238 186.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M229.881 185.034C229.737 185.012 229.59 185 229.44 185L222.8 185C221.254 185 220 186.253 220 187.8C220 188.867 220.597 189.795 221.475 190.267C220.138 190.056 219.116 188.898 219.116 187.502C219.116 185.955 220.37 184.702 221.916 184.702L228.556 184.702C229.035 184.702 229.486 184.822 229.881 185.034Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-5_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M228 187.5C228 186.672 227.328 186 226.5 186L226.286 186L225 186L206.5 186C205.672 186 205 186.672 205 187.5C205 188.328 205.672 189 206.5 189L223 189L226.5 189L226.714 189C227.424 189 228 188.424 228 187.714C228 187.677 227.999 187.64 227.996 187.604C227.999 187.569 228 187.535 228 187.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M176.708 46.5L271.292 46.5L224 179.508L176.708 46.5Z" stroke="white" />
        <defs>
          <filter
            id="2-5_filter0_d"
            x="173.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-5_filter1_d"
            x="251.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-5_filter2_d"
            x="159.3"
            y="29.3003"
            width="131.279"
            height="170.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-5_filter3_i"
            x="175"
            y="45"
            width="98.9399"
            height="139.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-5_filter4_d"
            x="203.28"
            y="184.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="177.5"
            y1="36.8"
            x2="177.5"
            y2="29.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="192.5"
            y1="27.2018"
            x2="192.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="177.5"
            y1="36.7982"
            x2="177.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="255.5"
            y1="36.8"
            x2="255.5"
            y2="29.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="270.5"
            y1="27.2018"
            x2="270.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="255.5"
            y1="36.7982"
            x2="255.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
