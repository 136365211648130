import React, { FC } from 'react'

import { IButtonProps } from '../../views/calculator/interface'
import { StyledButton } from './styles'

export const Button: FC<IButtonProps> = props => {
  const { children, onClick = () => null, typeView, disabled } = props

  return (
    <StyledButton typeView={typeView} onClick={onClick} disabled={disabled}>
      {children}
    </StyledButton>
  )
}

export default Button
