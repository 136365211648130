import { getRegionFromHost } from '../utilits'

class EnvironmentBuilder {
  HOST_API = ''
  PORT_API = ''
  PREFIX_API = ''

  readonly isProductionBuild =
    process.env.NODE_ENV === 'production' && process.env.ENV === 'production'

  private isTestBuild = process.env.NODE_ENV === 'production' && process.env.ENV === 'test'

  readonly isDevMode = process.env.NODE_ENV !== 'production'

  private SERVER_PORT_DEV = ''

  private SERVER_PREFIX_DEV = ''

  private SERVER_PORT_PROD = process.env.SERVER_PORT_PROD ? process.env.SERVER_PORT_PROD : ''

  private INNER_DADATA_TOKEN = process.env.DADATA_TOKEN
    ? process.env.DADATA_TOKEN
    : '202f07e5711c4f92e5167758fa392229901215ac'

  private SERVER_PREFIX_PROD = process.env.SERVER_PREFIX_PROD ? process.env.SERVER_PREFIX_PROD : ''

  private CT_SITE_ID = location?.href?.includes('kaluga.ze-okna.ru') ? 58586 : 51606

  GetServerHostDev = (region = ''): string => {
    console.log({ SERVER_HOST_DEV: process.env.SERVER_HOST_DEV })
    //process.exit()
    return process.env.SERVER_HOST_DEV
      ? process.env.SERVER_HOST_DEV
     // : 'http://localhost:7070'
       : 'https://platform.in.ze-okna.ru'
      //: 'https://platform.in.ze-okna.ru'
    // : `https://zeokna.web.axioma.axept.com`
    //: `https://develop.web.axioma.axept.com`
  }

  GetServerHostProd = (region = ''): string => {
    console.log({ SERVER_HOST_PROD: process.env.SERVER_HOST_PROD })
    //process.exit()
    return process.env.SERVER_HOST_PROD
      ? process.env.SERVER_HOST_PROD
      // :  'http://localhost:7070'
      : 'https://platform.in.ze-okna.ru'
    //: 'https://demo.web.axioma.axept.com'
    //: `https://zeokna.web.axioma.axept.com`
    // : `https://develop.web.axioma.axept.com`
  }

  ApiURL(): string {
    const region = getRegionFromHost()
    this.HOST_API = this.GetServerHostProd(region)

    if (this.isProductionBuild || this.isTestBuild) {
      this.PORT_API = this.SERVER_PORT_PROD
      this.PREFIX_API = this.SERVER_PREFIX_PROD
    } else if (this.isDevMode) {
      this.HOST_API = this.GetServerHostDev(region)
      this.PORT_API = this.SERVER_PORT_DEV
      this.PREFIX_API = this.SERVER_PREFIX_DEV
    }

    if (this.PORT_API) {
      return `${this.HOST_API}:${this.PORT_API}${this.PREFIX_API}`
    }

    return `${this.HOST_API}${this.PREFIX_API}`
  }

  DadataToken(): string {
    return this.INNER_DADATA_TOKEN
  }
  CtSiteId(): number {
    return this.CT_SITE_ID
  }
}

const env = new EnvironmentBuilder()

export const API_URL = env.ApiURL()
export const DADATA_TOKEN = env.DadataToken()
export const CT_SITE_ID = env.CtSiteId()