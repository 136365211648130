import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement33Square53Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#3-3_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M55.0005 291C56.1048 291 57 290.105 57 289.001C57 287.896 56.1048 287.001 55.0005 287.001L52.8335 287.001L52.8335 287.001L42 287.001L42 287L38.9995 287C37.8952 287 37 287.895 37 288.999C37 290.104 37.8952 290.999 38.9995 290.999L41.1668 290.999L41.1668 291L52 291L52 291L55.0005 291Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 52.834 291)"
          fill="#ABABAB"
        />
        <path
          d="M56.0601 291C56.5792 291 57 290.579 57 290.06L57 287.941C57 287.422 56.5792 287.001 56.0601 287.001L52 287.001L52 291L56.0601 291Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M37.9399 287C37.4208 287 37 287.421 37 287.94L37 290.059C37 290.578 37.4208 290.999 37.9399 290.999L42 290.999L42 287L37.9399 287Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 51.167 291)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#3-3_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M111.001 291C112.105 291 113 290.105 113 289.001C113 287.896 112.105 287.001 111.001 287.001L108.833 287.001L108.833 287.001L98 287.001L98 287L94.9995 287C93.8952 287 93 287.895 93 288.999C93 290.104 93.8952 290.999 94.9995 290.999L97.1668 290.999L97.1668 291L108 291L108 291L111.001 291Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 108.834 291)"
          fill="#ABABAB"
        />
        <path
          d="M112.06 291C112.579 291 113 290.579 113 290.06L113 287.941C113 287.422 112.579 287.001 112.06 287.001L108 287.001L108 291L112.06 291Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M93.9399 287C93.4208 287 93 287.421 93 287.94L93 290.059C93 290.578 93.4208 290.999 93.9399 290.999L98 290.999L98 287L93.9399 287Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 107.167 291)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#3-3_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M119 121H31V287H119V121ZM108 132H42V276H108V132Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#3-3_filter3_i)">
          <rect
            x="42.47"
            y="132.47"
            width="65.0601"
            height="143.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="36.5" y="126.5" width="77" height="155" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M119 121H31V287H32V122H118V287H119V121Z"
          fill="#FBFBFB"
        />
        <path d="M43.6252 274.5L106.375 274.5L75 135.274L43.6252 274.5Z" stroke="white" />
        <rect
          x="82.5283"
          y="123"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 82.5283 123)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#3-3_filter4_d)">
          <rect
            x="81.8838"
            y="123.701"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 81.8838 123.701)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M81.7666 125.694C81.4202 124.541 80.3505 123.701 79.0846 123.701L72.4447 123.701C70.8983 123.701 69.6447 124.955 69.6447 126.501C69.6447 126.536 69.6454 126.571 69.6466 126.606C69.993 127.759 71.0627 128.599 72.3286 128.599L78.9686 128.599C80.515 128.599 81.7686 127.346 81.7686 125.799C81.7686 125.764 81.7679 125.729 81.7666 125.694Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M80.4092 124.034C80.2655 124.011 80.1182 123.999 79.9681 123.999L73.3282 123.999C71.7818 123.999 70.5282 125.253 70.5282 126.799C70.5282 127.866 71.1251 128.794 72.0032 129.267C70.6664 129.055 69.6443 127.898 69.6443 126.501C69.6443 124.955 70.8979 123.701 72.4443 123.701L79.0842 123.701C79.5635 123.701 80.0147 123.822 80.4092 124.034Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-3_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M95.5283 126.5C95.5283 125.672 94.8567 125 94.0283 125L93.8141 125L92.5283 125L74.0283 125C73.1999 125 72.5283 125.672 72.5283 126.5V126.5C72.5283 127.328 73.1999 128 74.0283 128L90.5283 128L94.0283 128L94.2426 128C94.9527 128 95.5283 127.424 95.5283 126.714V126.714C95.5283 126.677 95.5271 126.64 95.5248 126.604C95.5271 126.569 95.5283 126.535 95.5283 126.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="3-3_filter0_d"
            x="35.1201"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-3_filter1_d"
            x="91.1201"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-3_filter2_d"
            x="26.3003"
            y="116.3"
            width="99.2793"
            height="177.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-3_filter3_i"
            x="42"
            y="132"
            width="66.9399"
            height="146.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-3_filter4_d"
            x="67.7637"
            y="121.821"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-3_filter5_d"
            x="71.6485"
            y="123.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="39.5"
            y1="293.8"
            x2="39.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="54.5"
            y1="284.202"
            x2="54.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="39.5"
            y1="293.798"
            x2="39.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="95.5"
            y1="293.8"
            x2="95.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="110.5"
            y1="284.202"
            x2="110.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="95.5"
            y1="293.798"
            x2="95.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
