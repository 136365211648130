import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement24Square4TopCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-top-2-4filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M61.0005 291C62.1048 291 63 290.105 63 289.001C63 287.896 62.1048 287.001 61.0005 287.001L58.8335 287.001L58.8335 287.001L48 287.001L48 287L44.9995 287C43.8952 287 43 287.895 43 288.999C43 290.104 43.8952 290.999 44.9995 290.999L47.1668 290.999L47.1668 291L58 291L58 291L61.0005 291Z"
            fill="url(#4-top-2-4paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 58.834 291)"
          fill="#ABABAB"
        />
        <path
          d="M62.0601 291C62.5792 291 63 290.579 63 290.06L63 287.941C63 287.422 62.5792 287.001 62.0601 287.001L58 287.001L58 291L62.0601 291Z"
          fill="url(#4-top-2-4paint1_linear)"
        />
        <path
          d="M43.9399 287C43.4208 287 43 287.421 43 287.94L43 290.059C43 290.578 43.4208 290.999 43.9399 290.999L48 290.999L48 287L43.9399 287Z"
          fill="url(#4-top-2-4paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 57.167 291)"
          fill="url(#4-top-2-4paint3_linear)"
        />
        <g filter="url(#4-top-2-4filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M139.001 291C140.105 291 141 290.105 141 289.001C141 287.896 140.105 287.001 139.001 287.001L136.833 287.001L136.833 287.001L126 287.001L126 287L122.999 287C121.895 287 121 287.895 121 288.999C121 290.104 121.895 290.999 122.999 290.999L125.167 290.999L125.167 291L136 291L136 291L139.001 291Z"
            fill="url(#4-top-2-4paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 136.834 291)"
          fill="#ABABAB"
        />
        <path
          d="M140.06 291C140.579 291 141 290.579 141 290.06L141 287.941C141 287.422 140.579 287.001 140.06 287.001L136 287.001L136 291L140.06 291Z"
          fill="url(#4-top-2-4paint5_linear)"
        />
        <path
          d="M121.94 287C121.421 287 121 287.421 121 287.94L121 290.059C121 290.578 121.421 290.999 121.94 290.999L126 290.999L126 287L121.94 287Z"
          fill="url(#4-top-2-4paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 135.167 291)"
          fill="url(#4-top-2-4paint7_linear)"
        />
        <g filter="url(#4-top-2-4filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M152 128H32V287H152V128ZM141 139H43V276H141V139Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M152 128H32V287H33V129H151V287H152V128Z"
          fill="#FBFBFB"
        />
        <rect
          x="36.53"
          y="132.53"
          width="110.94"
          height="149.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#4-top-2-4filter3_i)">
          <rect
            x="43.47"
            y="139.47"
            width="97.0601"
            height="136.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <path d="M139.295 277.5H44.705L92 142.512L139.295 277.5Z" stroke="white" />
        <rect
          x="98.5283"
          y="130"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 98.5283 130)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#4-top-2-4filter4_d)">
          <rect
            x="97.8838"
            y="130.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 97.8838 130.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M97.7666 132.695C97.4202 131.542 96.3505 130.702 95.0846 130.702L88.4447 130.702C86.8983 130.702 85.6447 131.955 85.6447 133.502C85.6447 133.537 85.6454 133.572 85.6466 133.607C85.993 134.76 87.0627 135.6 88.3286 135.6L94.9686 135.6C96.515 135.6 97.7686 134.346 97.7686 132.8C97.7686 132.765 97.7679 132.73 97.7666 132.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M96.4092 131.035C96.2655 131.012 96.1182 131 95.9681 131L89.3282 131C87.7818 131 86.5282 132.254 86.5282 133.8C86.5282 134.867 87.1251 135.795 88.0032 136.267C86.6664 136.056 85.6443 134.898 85.6443 133.502C85.6443 131.955 86.8979 130.702 88.4443 130.702L95.0842 130.702C95.5635 130.702 96.0147 130.822 96.4092 131.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-top-2-4filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M111.528 133.5C111.528 132.672 110.857 132 110.028 132L109.814 132L108.528 132L90.0283 132C89.1999 132 88.5283 132.672 88.5283 133.5C88.5283 134.328 89.1999 135 90.0283 135L106.528 135L110.028 135L110.243 135C110.953 135 111.528 134.424 111.528 133.714C111.528 133.677 111.527 133.64 111.525 133.604C111.527 133.569 111.528 133.535 111.528 133.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="4-top-2-4filter0_d"
            x="41.1201"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-2-4filter1_d"
            x="119.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-2-4filter2_d"
            x="27.3003"
            y="123.3"
            width="131.279"
            height="170.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-2-4filter3_i"
            x="43"
            y="139"
            width="98.9399"
            height="139.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-top-2-4filter4_d"
            x="83.7637"
            y="128.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-2-4filter5_d"
            x="87.6485"
            y="130.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="4-top-2-4paint0_linear"
            x1="45.5"
            y1="293.8"
            x2="45.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="4-top-2-4paint1_linear"
            x1="60.5"
            y1="284.202"
            x2="60.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-2-4paint2_linear"
            x1="45.5"
            y1="293.798"
            x2="45.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-2-4paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-2-4paint4_linear"
            x1="123.5"
            y1="293.8"
            x2="123.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="4-top-2-4paint5_linear"
            x1="138.5"
            y1="284.202"
            x2="138.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-2-4paint6_linear"
            x1="123.5"
            y1="293.798"
            x2="123.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-2-4paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
