import styled from 'styled-components'

export const CheckboxWrapper = styled.div``

export const CheckboxBlock = styled.div``

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;

  margin-bottom: 14px;

  cursor: pointer;
`

export const CheckboxInput = styled.input`
  margin-right: 13px;

  cursor: pointer;
`
