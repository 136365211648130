import React, { FC, useCallback, useState } from 'react'

import { Button } from '../../../components'
import { IRegisterButtonProps } from './interface'
import { ButtonViews } from '../interface'
import { RegisterUser } from './register-user'

export const RegisterButton: FC<IRegisterButtonProps> = props => {
  const { onSubmit, buttonText, disabled = false } = props
  const [startSubmit, setStartSubmit] = useState(false)

  const handleStartSubmit = useCallback(() => {
    setStartSubmit(true)
  }, [])

  const handleEndSubmit = useCallback(() => {
    setStartSubmit(false)
  }, [])

  return (
    <RegisterUser startSubmit={startSubmit} onEndSubmit={handleEndSubmit} onSubmit={onSubmit}>
      <Button typeView={ButtonViews.ORANGE} onClick={handleStartSubmit} disabled={disabled}>
        {buttonText}
      </Button>
    </RegisterUser>
  )
}
