import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement6Door1LCasement2 = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="451"
        height="451"
        viewBox="0 0 451 451"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#door-1-l-casement-2-6-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M419 269C419 267.895 419.895 267 421 267C422.105 267 423 267.895 423 269V283.56H419V269ZM419 283.56H423V285.4H423.06C423.579 285.4 424 285.821 424 286.34V288.12C424 289.158 423.158 290 422.12 290H420.88C419.842 290 419 289.158 419 288.12V285.4V285.4V283.56Z"
            fill="white"
          />
        </g>
        <rect x="419" y="284.25" width="3.99998" height="0.95833" fill="#ABABAB" />
        <path
          d="M419 267.94C419 267.421 419.421 267 419.94 267H422.06C422.579 267 423 267.421 423 267.94V284.25H419V267.94Z"
          fill="url(#door-1-l-casement-2-6-paint0_linear)"
        />
        <path
          d="M419 289.06C419 289.579 419.421 290 419.94 290H423.06C423.579 290 424 289.579 424 289.06V286.148C424 285.629 423.579 285.208 423.06 285.208H419V289.06Z"
          fill="url(#door-1-l-casement-2-6-paint1_linear)"
        />
        <g filter="url(#door-1-l-casement-2-6-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M423 46.9995C423 45.8952 422.105 45 421.001 45C419.896 45 419.001 45.8952 419.001 46.9995V49.1665H419.001V60H419V63.0005C419 64.1048 419.895 65 420.999 65C422.104 65 422.999 64.1048 422.999 63.0005V60.8332H423V50H423V46.9995Z"
            fill="url(#door-1-l-casement-2-6-paint2_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 423 49.1665)"
          fill="#ABABAB"
        />
        <path
          d="M423 45.9399C423 45.4208 422.579 45 422.06 45H419.941C419.422 45 419.001 45.4208 419.001 45.9399V50H423V45.9399Z"
          fill="url(#door-1-l-casement-2-6-paint3_linear)"
        />
        <path
          d="M419 64.0601C419 64.5792 419.421 65 419.94 65H422.059C422.578 65 422.999 64.5792 422.999 64.0601V60H419V64.0601Z"
          fill="url(#door-1-l-casement-2-6-paint4_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 423 50.8335)"
          fill="url(#door-1-l-casement-2-6-paint5_linear)"
        />
        <g filter="url(#door-1-l-casement-2-6-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M419 34H308V301H419V34ZM408 45H319V290H408V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M419 34H308V301H309V35H418V301H419V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="312.53"
          y="38.53"
          width="101.94"
          height="257.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#door-1-l-casement-2-6-filter3_i)">
          <rect
            x="319.47"
            y="45.47"
            width="88.0601"
            height="244.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <path d="M406.5 287.443V47.5571L320.615 167.5L406.5 287.443Z" stroke="white" />
        <path d="M363.5 48.8375L406.403 288.5H320.597L363.5 48.8375Z" stroke="white" />
        <rect
          x="310"
          y="161"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#door-1-l-casement-2-6-filter4_d)">
          <rect x="310.702" y="161.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M311.286 172.796C311.798 173.458 312.6 173.884 313.502 173.884C315.048 173.884 316.302 172.63 316.302 171.084V164.444C316.302 163.456 315.79 162.587 315.016 162.089C315.382 162.562 315.6 163.156 315.6 163.8V170.44C315.6 171.986 314.347 173.24 312.8 173.24C312.242 173.24 311.722 173.077 311.286 172.796Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M313.502 161.644C311.956 161.644 310.702 162.898 310.702 164.444V170.44C310.702 170.46 310.702 170.48 310.703 170.501V165.024C310.703 163.477 311.956 162.224 313.503 162.224C315.029 162.224 316.269 163.444 316.302 164.963V164.444C316.302 162.898 315.048 161.644 313.502 161.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#door-1-l-casement-2-6-filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M313.5 165C312.672 165 312 165.672 312 166.5V166.714V168V186.5C312 187.328 312.672 188 313.5 188V188C314.328 188 315 187.328 315 186.5V170V166.5L315 166.286C315 165.576 314.424 165 313.714 165V165C313.677 165 313.64 165.001 313.604 165.004C313.569 165.001 313.535 165 313.5 165Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="door-1-l-casement-2-6-filter0_d"
            x="417.12"
            y="265.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-2-6-filter1_d"
            x="417.12"
            y="43.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-2-6-filter2_d"
            x="303.3"
            y="29.3003"
            width="122.279"
            height="278.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-2-6-filter3_i"
            x="319"
            y="45"
            width="89.9399"
            height="247.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="door-1-l-casement-2-6-filter4_d"
            x="308.822"
            y="159.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-2-6-filter5_d"
            x="310.12"
            y="163.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="door-1-l-casement-2-6-paint0_linear"
            x1="425.8"
            y1="275.625"
            x2="418.2"
            y2="275.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-2-6-paint1_linear"
            x1="427.5"
            y1="287.604"
            x2="418"
            y2="287.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-2-6-paint2_linear"
            x1="425.8"
            y1="62.5"
            x2="418.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-2-6-paint3_linear"
            x1="416.202"
            y1="47.5"
            x2="423.8"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-2-6-paint4_linear"
            x1="425.798"
            y1="62.5"
            x2="418.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-2-6-paint5_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
