import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement31Square41Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#3-1-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M230 255C230 253.895 229.105 253 228 253C226.895 253 226 253.895 226 255V269.56H230V255ZM230 269.56H226V271.4H225.94C225.421 271.4 225 271.821 225 272.34V274.12C225 275.158 225.842 276 226.88 276H228.12C229.158 276 230 275.158 230 274.12V271.4V271.4V269.56Z"
            fill="white"
          />
        </g>
        <rect
          width="3.99998"
          height="0.95833"
          transform="matrix(-1 0 0 1 230 270.25)"
          fill="#ABABAB"
        />
        <path
          d="M230 253.94C230 253.421 229.579 253 229.06 253H226.94C226.421 253 226 253.421 226 253.94V270.25H230V253.94Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M230 275.06C230 275.579 229.579 276 229.06 276H225.94C225.421 276 225 275.579 225 275.06V272.148C225 271.629 225.421 271.208 225.94 271.208H230V275.06Z"
          fill="url(#paint1_linear)"
        />
        <g filter="url(#3-1-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M230 46.9995C230 45.8952 229.105 45 228.001 45C226.896 45 226.001 45.8952 226.001 46.9995V49.1665H226.001V60H226V63.0005C226 64.1048 226.895 65 227.999 65C229.104 65 229.999 64.1048 229.999 63.0005V60.8332H230V50H230V46.9995Z"
            fill="url(#paint2_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 230 49.1665)"
          fill="#ABABAB"
        />
        <path
          d="M230 45.9399C230 45.4208 229.579 45 229.06 45H226.941C226.422 45 226.001 45.4208 226.001 45.9399V50H230V45.9399Z"
          fill="url(#paint3_linear)"
        />
        <path
          d="M226 64.0601C226 64.5792 226.421 65 226.94 65H229.059C229.578 65 229.999 64.5792 229.999 64.0601V60H226V64.0601Z"
          fill="url(#paint4_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 230 50.8334)"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#3-1-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M319 34H230V287H319V34ZM308 45H241V276H308V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M319 34H230V287H231.219V35H317.781V287H319V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-1-filter3_i)">
          <rect
            x="241.47"
            y="45.47"
            width="66.0601"
            height="230.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="234.53"
          y="38.53"
          width="79.9399"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="310"
          y="155"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#3-1-filter4_d)">
          <rect x="310.702" y="155.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M311.286 166.795C311.798 167.458 312.6 167.884 313.502 167.884C315.049 167.884 316.302 166.63 316.302 165.084V158.444C316.302 157.456 315.79 156.587 315.017 156.089C315.383 156.562 315.6 157.155 315.6 157.8V164.44C315.6 165.986 314.347 167.24 312.8 167.24C312.242 167.24 311.723 167.077 311.286 166.795Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M313.502 155.644C311.955 155.644 310.702 156.898 310.702 158.444V164.44C310.702 164.46 310.702 164.48 310.702 164.501V159.024C310.702 157.477 311.956 156.224 313.502 156.224C315.028 156.224 316.269 157.444 316.302 158.963V158.444C316.302 156.898 315.048 155.644 313.502 155.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-1-filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M313.5 159C312.672 159 312 159.672 312 160.5V160.714V162V180.5C312 181.328 312.672 182 313.5 182V182C314.328 182 315 181.328 315 180.5V164V160.5L315 160.286C315 159.576 314.424 159 313.714 159V159C313.677 159 313.64 159.001 313.604 159.004C313.569 159.001 313.535 159 313.5 159Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M242.5 273.114L306.425 160.999L242.5 47.9008L242.5 273.114Z" stroke="white" />
        <path d="M306.424 274.5L274.5 49.5584L242.576 274.5L306.424 274.5Z" stroke="white" />
        <defs>
          <filter
            id="3-1-filter0_d"
            x="223.12"
            y="251.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-1-filter1_d"
            x="224.12"
            y="43.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-1-filter2_d"
            x="225.3"
            y="29.3003"
            width="100.279"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-1-filter3_i"
            x="241"
            y="45"
            width="67.9399"
            height="233.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-1-filter4_d"
            x="308.822"
            y="153.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-1-filter5_d"
            x="310.12"
            y="157.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="223.2"
            y1="261.625"
            x2="230.8"
            y2="261.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="221.5"
            y1="273.604"
            x2="231"
            y2="273.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="232.8"
            y1="62.5"
            x2="225.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="223.202"
            y1="47.5"
            x2="230.8"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="232.798"
            y1="62.5"
            x2="225.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
