import {API_URL} from '../../react-app/services/environment'
import {getRegionFromHost} from '../../react-app/utilits'

export function createHeaders(headers) {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
// @ts-ignore
// eslint-disable-next-line
    Origin: window.location['origin'],
    ...headers,
  }
}

const arr = new Set()

/**
 * @todo Add OAuth Bearer authenficiation support
 *
 * @param {String} path Example: `/api/session` or `/session`
 * @param {Object} options RequestInit
 * @return {Object} response
 */
export const fetchAPI = async (
  path: string,
  options: RequestInit = {},
): Promise<Record<string, any>> => {
  if (!path.includes('http')) {
    const region = getRegionFromHost()
    const concatenateSymbol = path.includes('?') ? '&' : '?'
    const patchWithRegion = `${path}${concatenateSymbol}region=${region}`
    path = path.substr(0, 5) === '/api/' ? patchWithRegion : `/api/${patchWithRegion}`
    arr.add(path?.split?.('?')?.[0])
    path = `${API_URL}${path}`
  }


  const response = await fetch(path, {
    ...options,
    // mode: 'cors',
    credentials: 'include',
    headers: createHeaders(options.headers),
  })

  return response.json()
}

export default fetchAPI
