import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement75Square8Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#7-5_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M236.999 161C235.895 161 235 161.895 235 162.999C235 164.104 235.895 164.999 236.999 164.999L239.167 164.999L239.167 164.999L250 164.999L250 165L253.001 165C254.105 165 255 164.105 255 163.001C255 161.896 254.105 161.001 253.001 161.001L250.833 161.001L250.833 161L240 161L240 161L236.999 161Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 239.167 161)"
          fill="#ABABAB"
        />
        <path
          d="M235.94 161C235.421 161 235 161.421 235 161.94L235 164.059C235 164.578 235.421 164.999 235.94 164.999L240 164.999L240 161L235.94 161Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M254.06 165C254.579 165 255 164.579 255 164.06L255 161.941C255 161.422 254.579 161.001 254.06 161.001L250 161.001L250 165L254.06 165Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 240.833 161)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#7-5_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M296.999 161C295.895 161 295 161.895 295 162.999C295 164.104 295.895 164.999 296.999 164.999L299.167 164.999L299.167 164.999L310 164.999L310 165L313.001 165C314.105 165 315 164.105 315 163.001C315 161.896 314.105 161.001 313.001 161.001L310.833 161.001L310.833 161L300 161L300 161L296.999 161Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 299.167 161)"
          fill="#ABABAB"
        />
        <path
          d="M295.94 161C295.421 161 295 161.421 295 161.94L295 164.059C295 164.578 295.421 164.999 295.94 164.999L300 164.999L300 161L295.94 161Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M314.06 165C314.579 165 315 164.579 315 164.06L315 161.941C315 161.422 314.579 161.001 314.06 161.001L310 161.001L310 165L314.06 165Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 300.833 161)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#7-5_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M319 165H230V286H319V165ZM308 176H241V275H308V176Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M319 165H230V286H231.219V166H317.781V286H319V165Z"
          fill="#FBFBFB"
        />
        <g filter="url(#7-5_filter3_i)">
          <rect
            x="241.47"
            y="176.47"
            width="66.0601"
            height="98.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="234.53"
          y="169.53"
          width="79.9399"
          height="111.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="281"
          y="277"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 281 277)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="280.356"
          y="277.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 280.356 277.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M280.238 279.695C279.892 278.542 278.822 277.702 277.556 277.702L270.916 277.702C269.37 277.702 268.116 278.955 268.116 280.502C268.116 280.537 268.117 280.572 268.118 280.607C268.464 281.76 269.534 282.6 270.8 282.6L277.44 282.6C278.986 282.6 280.24 281.346 280.24 279.8C280.24 279.765 280.239 279.73 280.238 279.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M278.881 278.034C278.737 278.012 278.59 278 278.44 278L271.8 278C270.254 278 269 279.253 269 280.8C269 281.867 269.597 282.795 270.475 283.267C269.138 283.056 268.116 281.898 268.116 280.502C268.116 278.955 269.37 277.702 270.916 277.702L277.556 277.702C278.035 277.702 278.486 277.822 278.881 278.034Z"
          fill="#FBFBFB"
        />
        <g filter="url(#7-5_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M277 280.5C277 279.672 276.328 279 275.5 279L275.286 279L274 279L255.5 279C254.672 279 254 279.672 254 280.5V280.5C254 281.328 254.672 282 255.5 282L272 282L275.5 282L275.714 282C276.424 282 277 281.424 277 280.714V280.714C277 280.677 276.999 280.64 276.996 280.604C276.999 280.569 277 280.535 277 280.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M306.305 177.5L274.5 272.426L242.695 177.5L306.305 177.5Z" stroke="white" />
        <defs>
          <filter
            id="7-5_filter0_d"
            x="233.12"
            y="159.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7-5_filter1_d"
            x="293.12"
            y="159.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7-5_filter2_d"
            x="225.3"
            y="160.3"
            width="100.279"
            height="132.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7-5_filter3_i"
            x="241"
            y="176"
            width="67.9399"
            height="101.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="7-5_filter4_d"
            x="252.28"
            y="277.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="252.5"
            y1="158.2"
            x2="252.5"
            y2="165.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="237.5"
            y1="167.798"
            x2="237.5"
            y2="160.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="252.5"
            y1="158.202"
            x2="252.5"
            y2="165.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="312.5"
            y1="158.2"
            x2="312.5"
            y2="165.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="297.5"
            y1="167.798"
            x2="297.5"
            y2="160.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="312.5"
            y1="158.202"
            x2="312.5"
            y2="165.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
