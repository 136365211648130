import styled, { css } from 'styled-components'

export const RadiobuttonWrapper = styled.div`
  padding-top: 0;
  padding-bottom: 0;

  position: relative;
`

export const RadiobuttonTitle = styled.div`
  font-size: 14px;
  line-height: 140%;

  margin: 0 0 16px;

  display: flex;
  align-items: center;

  & > span {
    margin-right: 4px;
  }
`

export const RadiobuttonPrice = styled.span`
  font-size: 14px;
  font-weight: bold;
  line-height: 140%;
  color: #333333;
`

export const RadiobuttonBlock = styled.div<{ type: string }>`
  display: flex;

  flex-direction: ${({ type }) => (type === 'vertical' ? 'column' : 'row')};
`

export const RadiobuttonLabel = styled.label<{ isClearView?: boolean; type: string }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding-right: 34px;
  padding-bottom: ${({ isClearView = false }) => (isClearView ? 0 : '14px')};

  color: #333333;

  cursor: pointer;

  ${({ type }) =>
    type === 'vertical' &&
    css`
      &:last-child {
        padding-bottom: 0;
      }
    `}
`

export const RadiobuttonButton = styled.input`
  height: 18px;
  width: 18px;

  margin-right: 11px;

  cursor: pointer;
`

export const RadiobuttonText = styled.span`
  font-size: 13px;
`
