import React, { FC, useContext, useState, useCallback, useEffect } from 'react'
import { PersonalAreaMainWrapper, OrderItemBlockWrapper } from './styles'
import { OrderItemBlock } from './order-item-block'
import { PersonalAreaContext } from './stage-personal-area'
import { Loader } from '../../../components'
import { useRegister } from '../../../hooks'
import fetchAPI from 'src/lib/utils/fetch-api'

export const CasesList: FC = () => {
  const { cases: casesHook, inProcess, fetchOrderById } = useContext(PersonalAreaContext)
  const [activeItem, setActiveItem] = useState<number | null>(0)
  const [cases, setCases] = useState(casesHook)

  useEffect(() => {
    setCases(casesHook)
  }, [casesHook])

  // console.log({ cases })

  const { isAuthorized } = useRegister()

  const handleSetActiveIndex = useCallback(
    index => () => {
      setActiveItem(prevState => (prevState === index ? null : index))
    },
    [],
  )

  const deleteOrder = useCallback(async (id) => {
    setCases(items => items.filter(item => item['id'] !== id))
    // console.log(`web-hooks/delete-appeal-card/${id}`)
    fetchAPI(`web-hooks/delete-appeal-card/${id}`, { method: 'POST' })
  }, [])

  return (
    <PersonalAreaMainWrapper>
      {inProcess && (
        <OrderItemBlockWrapper active>
          <Loader />
        </OrderItemBlockWrapper>
      )}
      {!inProcess && (cases.length === 0 || !isAuthorized) && (
        <OrderItemBlockWrapper active>Нет обращений</OrderItemBlockWrapper>
      )}
      {!inProcess &&
        isAuthorized &&
        cases.length > 0 &&
        cases.map((item, index) => (
          <OrderItemBlock
            orderItemTitle="Обращение"
            noStatuses
            active={activeItem === index}
            key={`case-item-${item['id']}`}
            data={item}
            setActiveItem={handleSetActiveIndex(index)}
            canEdit
            fetchOrderById={fetchOrderById}
            deleteOrder={deleteOrder}
          />
        ))}
    </PersonalAreaMainWrapper>
  )
}
