import { MouseEvent } from 'react'
import { IProductionsMenuMap, IFilterItem } from './stage-calculator/interface'

export enum CalculatorStage {
  SELECT_TYPE = 'SELECT_TYPE',
  SELECT_TYPE_PREMISES = 'SELECT_TYPE_PREMISES',
  SELECT_VIEW = 'SELECT_VIEW',
  CALCULATE = 'CALCULATE',
  INDIVIDUAL = 'INDIVIDUAL',
  CART = 'CART',
  ORDER = 'ORDER',
  PERSONAL_AREA = 'PERSONAL_AREA',
  QUIZ = 'QUIZ',
}

export enum QuizView {
  SELECT_TYPE = 'SELECT_TYPE',
  SELECT_TYPE_PREMISES = 'SELECT_TYPE_PREMISES',
  SELECT_PREFERENCES_1 = 'SELECT_PREFERENCES_1',
  SELECT_PREFERENCES_2 = 'SELECT_PREFERENCES_2',
  SELECT_PREFERENCES_3 = 'SELECT_PREFERENCES_3',
  SELECT_PREFERENCES_4 = 'SELECT_PREFERENCES_4',
}

export enum CalculatorType {
  WINDOW = 'window',
  DOOR = 'door',
  LOGGIA = 'loggia',
}

export enum CalculatorTypePremises {
  APARTMENTS = 'apartments',
  COTTAGE = 'cottage',
  COUNTRY_HOUSE = 'country-house',
  OTHER = 'other',
}

export enum InputTypes {
  text = 'text',
  number = 'number',
  button = 'button',
  file = 'file',
  hidden = 'hidden',
  image = 'image',
  password = 'password',
  radio = 'radio',
  checkbox = 'checkbox',
  submit = 'submit',
  email = 'email',
}

export enum ButtonViews {
  DEFAULT = 'DEFAULT',
  BLUE = 'BLUE',
  ORANGE = 'ORANGE',
  LINK = 'LINK',
}

export enum PayTypes {
  CARD = 'card',
  INVOICE = 'invoice',
  TERMINAL = 'terminal',
}

export interface ICalculatorProps {
  clearView: boolean
  productType: CalculatorType | null
  premisesType: CalculatorTypePremises | null
  initGlassUnit: string
  initModel: string
  stage: CalculatorStage
  quizStep?: QuizView
  id?: number | null
  isDraft?: boolean
  dropSettings: () => void
}

export interface IStageCalculator {
  type: CalculatorType | null
  filters: IFilterItem[]
  setFilters: any
  menuItems: IProductionsMenuMap[]
  menuItemsData: Record<string, any[]>
  specification: Record<string, any>
  defaultData: Record<string, any>
  currentOrder: any[]
  onChangeStage: (
    product: any,
    filters: Record<string, any>[],
    sizes: Record<string, any>,
    productId: string,
    preview: string,
  ) => void
  onNewCalculate: () => void
  onDropSpecification: () => void
}

export interface IStageSelectType {
  onChangeType: (view?: string) => void
  onChangeTypePremises: (type?: string) => void
  data: Record<string, any>[]
  dataPremises: Record<string, string>[]
  currentType?: string
  currentPremises?: CalculatorTypePremises | null
  type: CalculatorType | null
  steps?: number
  currentStep?: number
}

export interface IViewPreferences1Props {
  onChangeType: () => void
  onPrevStep: () => void
  onChange: (item: { key: string; value: string | number }) => void
  data: Record<string, any>[]
  steps?: number
  currentStep?: number
  selected: Record<string, any>
  selectedAnswers: Record<string, any>
  currentGlassUnit: string
  type: CalculatorType | null
  modelTitle: string
  onFinish?: () => void
  price?: Record<string, number>
  calculateInProgress: boolean
  products?: any
}


export interface IViewPreferences2Props {
  onChangeType: () => void
  onPrevStep: () => void
  onChange: (key: string) => (value: string | number) => void
  data: Record<string, any>[]
  menuItemsData: Record<string, any[]>
  steps?: number
  currentStep?: number
  selected: Record<string, any>
  currentGlassUnit: string
  type: CalculatorType | null
  modelTitle: string
  onFinish: () => void
  price?: Record<string, number>
  calculateInProgress: boolean
}

export interface IViewPreferences3Props {
  onChangeType: () => void
  onChange: (key: string) => (value: string | number) => void
  selected: Record<string, any>
  currentGlassUnit: string
  type: CalculatorType | null
  modelTitle: string
  onFinish: () => void
  price?: Record<string, number>
  calculateInProgress: boolean
  products: Record<string, any>[]
}

export interface IQuizPreviewBlockProps {
  type: CalculatorType | null
  glassUnit: string
  title: string
  onFinish?: () => void
  handleOnlineConsult?: () => any
  currentStep?: number
  
  price?: Record<string, number>
  calculateInProgress: boolean
  nonBorder?: boolean
  bigView?: boolean
}

export interface IQuizBottomBlockProps {
  onChangeType: (type?: string) => void
  onPrevStep?: () => void
  handleOnlineConsult?: () => void
  steps?: number
  currentStep?: number
  isActiveNextStep?: boolean
}

export interface IStageSelectQuiz {
  onChange: (filters: IFilterItem[]) => void
  onChangeType: (type: CalculatorType) => void
  type: CalculatorType | null
  specification: Record<string, any>
  firstPast: boolean
}

export interface IStageSelectTypePremises {
  onChangeTypePremises: (premises: string) => void
  onChangeType: () => void
  currentPremises?: CalculatorTypePremises | null
  data: Record<string, string>[]
  steps?: number
  currentStep?: number
  onPrevStep?: () => void
}

export interface IStageSelectView {
  onChangeStage: (stage: CalculatorStage) => void
}

export interface IAlertProps {
  initHideState?: boolean,
  style?: any
  children?: any
}

export interface IButtonProps {
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
  typeView?: ButtonViews | null
  disabled?: boolean
  style?: any
  children?: any
}

export interface ITopBlockProps {
  stage: CalculatorStage
  order: any
}

export interface IStageSelectProps {
  onChangeStage: (stage: CalculatorStage) => void
  onChangeType: (type: CalculatorType) => void
  onAddOrder: (product: any) => void
  onEditOrder: (id: string) => void
  onSetPreFilters: (preFilter: any[]) => void
  onSetCurrentOrderId: (orderId: number) => void
  stage: CalculatorStage
  type: CalculatorType | null
  orders: any[]
  currentOrder: any | null
  preFilters: any[]
  onUpdateOrder: any
}

export interface ICheckProps {
  checked?: boolean
  color?: string
}

export interface ISuccessProps {
  color?: string
  width?: number
  height?: number
}

export interface IStageCartProps {
  type: CalculatorType | null
  orders: any[]
  onNewCalculate: () => void
  onChangeStage: (orderId: string) => void
  onEditOrder: (orderId: string) => void
  onSetCurrentOrderId: (orderId: number) => void
  onUpdateOrder: any
}

export interface IStageOrderProps {
  type: CalculatorType | null
  onChangeType: (type: CalculatorType) => void
  products: any[]
  onBackToCart: () => void
  onChangeStage: () => void
  currentOrder: any[]
  onNewCalculate: () => void
}

export interface IOrderForecastItem {
  title: string
  code: string
  'date-min': string
  'date-max': string
  'date-avg': string
}

export interface IDeliveryProps {
  onChange: (data: Record<string, any>) => void
  forecast: IOrderForecastItem | null | undefined
  data: Record<string, any>
}

export interface IMountingProps {
  onChange: (data: Record<string, any>) => void
  forecast: IOrderForecastItem | null | undefined
  data: Record<string, any>
}

export interface IPayTypeProps {
  onChange: (data: Record<string, any>) => void
}

export interface IRecipientProps {
  onChange: (data: Record<string, any>) => void
  profileData: Record<string, any> | null
  recipient: Record<string, any> | null
}

export interface INotificationsProps {
  onChange: (data: Record<string, any>) => void
  data: Record<string, any> | null
}

export interface IUsePriceProps {
  inProcess: boolean
  totalPriceList: Record<string, any>[]
  calculateTotalPrice: (
    type: CalculatorType | null,
    products: TProduct[],
    sizes: Record<string, any>[],
    filters: IFilterItem[][],
  ) => void
  calculateAdditionalElementPrice: (
    additionalElementKey: string,
    type: CalculatorType | null,
    product: Record<string, string>,
    sizes: TSizes,
    filters?: IFilterItem[],
    colorKeys?: string[],
  ) => number
  fetchCalculate: (
    type: CalculatorType | null,
    products: TProduct[],
    sizes: Record<string, any>[],
    filters: IFilterItem[][],
  ) => void
  finalOrderPriceCalculate: (targetId: number, source: Record<string, any>) => void
}

export type TProduct = Record<string, any>

export type TSizes = Record<string, any>

export interface IMountingType {
  id: string
  name: string
  text: string
}

export interface ICalculateMountingPriceParams {
  mounting?: boolean
  demounting?: boolean
  'garbage-removal'?: boolean
  'house-type'?: IMountingType
  'mounting-type'?: IMountingType
  itemsSquare?: number
}

export interface IUseMountingPrice {
  mountingPrice: number
  mountingPriceSeparately: Record<string, number>
  calculateMountingPrice: (params: ICalculateMountingPriceParams) => void
  calculateInProcess: boolean
}

export interface IUsePhoneConfirmationProps {
  confirmed: boolean
  confirmError: string
  confirmPhone: (phone: number) => void
  confirmCode: ({ code, phone }: { code: string, phone: string }) => Promise<boolean>
}

export interface IInputProps {
  id?: string
  type?: InputTypes
  defaultValue?: string
  value?: string
  placeholder?: string
  validated?: boolean
  disabled?: boolean
  name?: string
  errorMessage?: string
  onChange?: (value: string | boolean, name: string) => void
  onFocus?: () => void
  onBlur?: () => void
  onSelect?: any
  req?: boolean 
  onAffix?: any
}

export interface IDateInputProps {
  id?: string
  value?: string
  placeholder?: string
  validated?: boolean
  name: string
  errorMessage?: string
  onChange?: (value: string | boolean, name: string) => void
  disabled?: boolean
}

export interface IAuthorizeData {
  email: string
  password: string
}

export interface IUseRegisterError {
  source: string
  detail: string
}

export interface IUseRegisterParams {
  isAuthorized: boolean
  profileData: Record<string, any> | null
  userData: Record<string, any> | null
  onRegisterUser: (registerData: Record<string, any>) => Promise<boolean>
  onAuthorizeUser: (authorizeData: IAuthorizeData) => Promise<boolean>
  onCheckAuthorize: () => Promise<any>
  onFetchProfile: () => Promise<Record<string, any> | null>
  onCheckAuth: () => Promise<boolean>
  onProfileUpdate: (profileId: number, registerData: Record<string, any>) => Promise<any>
  onUserPasswordUpdate: (oldPassword: string, newPassword: string) => Promise<any>
  resetErrors: () => void
  errors: IUseRegisterError[]
}

export type IUseRegister = () => IUseRegisterParams

export interface IUseSendOrderParams {
  isSending: boolean
  errors: Record<string, any>[]
  createOrder: (
    orderData: Record<string, any>,
    consultationData?: Record<string, any>,
  ) => Promise<Record<string, any>>
  updateOrder: (orderId: number, orderData: Record<string, any>) => Promise<boolean>
}

export interface ISuggestItem {
  unrestricted_value: string
  value: string
  data: any
}

export interface IUseSuggestionsAddressParams {
  suggestData: ISuggestItem[]
  onSuggest: (query: string, props?: any) => Promise<Record<string, any>>
}

export type IUseSendOrder = () => IUseSendOrderParams

export type IUseSuggestionsAddress = (
  fromBound?: string,
  suggestKey?: string[],
) => IUseSuggestionsAddressParams

export interface ISuggestProps {
  onChange: (value: Record<string, any>, name: string) => void
  name: string
  defaultValue?: any
  value?: any
  title?: string
  placeholder: string
  suggestKey?: string[]
  valueKey?: string[]
  queryPrefix?: string
  fromBound?: string
  suggestFilter?: Record<string, any>
}

export interface IPhoneInputProps {
  value: string | number
  onChange?: (value: string | number, key: string) => void
  id: string
  name: string
  placeholder?: string
  success?: string
  disabled?: boolean
  req?: boolean
}

export interface IDescriptionRowProps {
  code: string
  title: string
  description: string
  baseModel: {
    value: number
    title: string
    icons: string[]
  }
  currentModel: {
    value: number
    title: string
    icons: string[]
  }
  initCollapsed: boolean
}

export interface IValueViewProps {
  value: number
  totalCount?: number
  view?: string
}

export interface IMainContext {
  clearView: boolean
  dropSettings: () => void
  initModel: string
  initGlassUnit: string
  isDraftEdit?: boolean
  initSize?: Record<string, number | number[]>
  quizStep?: QuizView
  premisesType?: CalculatorTypePremises | null
  onSetOrder: (order: any) => void
  onDeleteOrder: (id: string) => void
  resetCurrentOrder: () => void
  onDropDraft: () => void
  currentOrderId: null | number
}

export interface IInsetNoteToCollectionProps {
  date: string
  author: number
  content: string
  type?: string
}

export interface ITextareaProps {
  id?: string
  defaultValue?: string
  value?: string
  placeholder?: string
  validated?: boolean
  disabled?: boolean
  name?: string
  errorMessage?: string
  onChange?: (value: string | boolean, name: string) => void
  onFocus?: () => void
  onBlur?: () => void
  onSelect?: any
  req?: boolean
}

export interface IUploadProps {
  files?: any
  name?: string
  errorMessage?: string
  onChange?: (value: string | boolean, name: string) => void
  req?: boolean
  multiple?: boolean
}
