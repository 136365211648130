import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement5Door1RCasement2 = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="451"
        height="451"
        viewBox="0 0 451 451"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#door-1-casement-2-5-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M143 269C143 267.895 143.895 267 145 267C146.105 267 147 267.895 147 269V283.56H143V269ZM143 283.56H147V285.4H147.06C147.579 285.4 148 285.821 148 286.34V288.12C148 289.158 147.158 290 146.12 290H144.88C143.842 290 143 289.158 143 288.12V285.4V285.4V283.56Z"
            fill="white"
          />
        </g>
        <rect x="143" y="284.25" width="3.99998" height="0.95833" fill="#ABABAB" />
        <path
          d="M143 267.94C143 267.421 143.421 267 143.94 267H146.06C146.579 267 147 267.421 147 267.94V284.25H143V267.94Z"
          fill="url(#door-1-casement-2-5-paint0_linear)"
        />
        <path
          d="M143 289.06C143 289.579 143.421 290 143.94 290H147.06C147.579 290 148 289.579 148 289.06V286.148C148 285.629 147.579 285.208 147.06 285.208H143V289.06Z"
          fill="url(#door-1-casement-2-5-paint1_linear)"
        />
        <g filter="url(#door-1-casement-2-5-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M147 46.9995C147 45.8952 146.105 45 145.001 45C143.896 45 143.001 45.8952 143.001 46.9995V49.1665H143.001V60H143V63.0005C143 64.1048 143.895 65 144.999 65C146.104 65 146.999 64.1048 146.999 63.0005V60.8332H147V50H147V46.9995Z"
            fill="url(#door-1-casement-2-5-paint2_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 147 49.1665)"
          fill="#ABABAB"
        />
        <path
          d="M147 45.9399C147 45.4208 146.579 45 146.06 45H143.941C143.422 45 143.001 45.4208 143.001 45.9399V50H147V45.9399Z"
          fill="url(#door-1-casement-2-5-paint3_linear)"
        />
        <path
          d="M143 64.0601C143 64.5792 143.421 65 143.94 65H146.059C146.578 65 146.999 64.5792 146.999 64.0601V60H143V64.0601Z"
          fill="url(#door-1-casement-2-5-paint4_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 147 50.8335)"
          fill="url(#door-1-casement-2-5-paint5_linear)"
        />
        <g filter="url(#door-1-casement-2-5-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M143 34H32V301H143V34ZM132 45H43V290H132V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M143 34H32V301H33V35H142V301H143V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="36.53"
          y="38.53"
          width="101.94"
          height="257.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#door-1-casement-2-5-filter3_i)">
          <rect
            x="43.47"
            y="45.47"
            width="88.0601"
            height="244.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <path d="M130.5 287.443V47.5571L44.615 167.5L130.5 287.443Z" stroke="white" />
        <path d="M87.5 48.8375L130.403 288.5H44.5975L87.5 48.8375Z" stroke="white" />
        <rect
          x="34"
          y="161"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#door-1-casement-2-5-filter4_d)">
          <rect x="34.7018" y="161.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.286 172.796C35.7981 173.458 36.6004 173.884 37.5022 173.884C39.0486 173.884 40.3022 172.63 40.3022 171.084V164.444C40.3022 163.456 39.79 162.587 39.0166 162.089C39.3826 162.562 39.6004 163.156 39.6004 163.8V170.44C39.6004 171.986 38.3468 173.24 36.8004 173.24C36.2424 173.24 35.7226 173.077 35.286 172.796Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.5018 161.644C35.9554 161.644 34.7018 162.898 34.7018 164.444V170.44C34.7018 170.46 34.702 170.48 34.7024 170.501V165.024C34.7024 163.477 35.956 162.224 37.5024 162.224C39.0285 162.224 40.2693 163.444 40.3018 164.963V164.444C40.3018 162.898 39.0482 161.644 37.5018 161.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#door-1-casement-2-5-filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.5 165C36.6716 165 36 165.672 36 166.5V166.714V168V186.5C36 187.328 36.6716 188 37.5 188V188C38.3284 188 39 187.328 39 186.5V170V166.5L39 166.286C39 165.576 38.4244 165 37.7143 165V165C37.6771 165 37.6401 165.001 37.6035 165.004C37.5693 165.001 37.5348 165 37.5 165Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="door-1-casement-2-5-filter0_d"
            x="141.12"
            y="265.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-2-5-filter1_d"
            x="141.12"
            y="43.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-2-5-filter2_d"
            x="27.3003"
            y="29.3003"
            width="122.279"
            height="278.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-2-5-filter3_i"
            x="43"
            y="45"
            width="89.9399"
            height="247.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="door-1-casement-2-5-filter4_d"
            x="32.8219"
            y="159.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-2-5-filter5_d"
            x="34.1201"
            y="163.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="door-1-casement-2-5-paint0_linear"
            x1="149.8"
            y1="275.625"
            x2="142.2"
            y2="275.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-2-5-paint1_linear"
            x1="151.5"
            y1="287.604"
            x2="142"
            y2="287.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-2-5-paint2_linear"
            x1="149.8"
            y1="62.5"
            x2="142.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-2-5-paint3_linear"
            x1="140.202"
            y1="47.5"
            x2="147.8"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-2-5-paint4_linear"
            x1="149.798"
            y1="62.5"
            x2="142.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-2-5-paint5_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
