import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement34Square5Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#3-4_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M421 47.9995C421 46.8952 420.105 46 419.001 46C417.896 46 417.001 46.8952 417.001 47.9995V50.1665H417.001V61H417V64.0005C417 65.1048 417.895 66 418.999 66C420.104 66 420.999 65.1048 420.999 64.0005V61.8332H421V51H421V47.9995Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 421 50.166)"
          fill="#ABABAB"
        />
        <path
          d="M421 46.9399C421 46.4208 420.579 46 420.06 46H417.941C417.422 46 417.001 46.4208 417.001 46.9399V51H421V46.9399Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M417 65.0601C417 65.5792 417.421 66 417.94 66H420.059C420.578 66 420.999 65.5792 420.999 65.0601V61H417V65.0601Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 421 51.833)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#3-4_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 160C417 158.895 417.895 158 419 158C420.105 158 421 158.895 421 160V174.56H417V160ZM417 174.56H421V176.4H421.06C421.579 176.4 422 176.821 422 177.34V179.12C422 180.158 421.158 181 420.12 181H418.88C417.842 181 417 180.158 417 179.12V176.4V176.4V174.56Z"
            fill="white"
          />
        </g>
        <rect x="417" y="175.25" width="3.99998" height="0.95833" fill="#ABABAB" />
        <path
          d="M417 158.94C417 158.421 417.421 158 417.94 158H420.06C420.579 158 421 158.421 421 158.94V175.25H417V158.94Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M417 180.06C417 180.579 417.421 181 417.94 181H421.06C421.579 181 422 180.579 422 180.06V177.148C422 176.629 421.579 176.208 421.06 176.208H417V180.06Z"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#3-4_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 34H297V193H417V34ZM406 45H308V182H406V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 34H297V193H298V35H416V193H417V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="301.53"
          y="38.53"
          width="110.94"
          height="149.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#3-4_filter3_i)">
          <rect
            x="308.47"
            y="45.47"
            width="97.0601"
            height="136.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="299"
          y="108"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#3-4_filter4_d)">
          <rect x="299.702" y="108.645" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M300.286 119.796C300.798 120.458 301.6 120.884 302.502 120.884C304.049 120.884 305.302 119.631 305.302 118.084V111.444C305.302 110.456 304.79 109.587 304.017 109.089C304.383 109.562 304.601 110.156 304.601 110.8V117.44C304.601 118.987 303.347 120.24 301.801 120.24C301.243 120.24 300.723 120.077 300.286 119.796Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M302.502 108.645C300.955 108.645 299.702 109.898 299.702 111.445V117.44C299.702 117.461 299.702 117.481 299.702 117.501V112.024C299.702 110.478 300.956 109.224 302.502 109.224C304.028 109.224 305.269 110.445 305.302 111.963V111.445C305.302 109.898 304.048 108.645 302.502 108.645Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-4_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M302.5 112C301.672 112 301 112.672 301 113.5V113.714V115V133.5C301 134.328 301.672 135 302.5 135C303.328 135 304 134.328 304 133.5V117V113.5L304 113.286C304 112.576 303.424 112 302.714 112C302.677 112 302.64 112.001 302.604 112.004C302.569 112.001 302.535 112 302.5 112Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M404.29 180.5L309.71 180.5L357 48.4827L404.29 180.5Z" stroke="white" />
        <path d="M404.5 47.953L404.5 180.047L308.88 114L404.5 47.953Z" stroke="white" />
        <defs>
          <filter
            id="3-4_filter0_d"
            x="415.12"
            y="44.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-4_filter1_d"
            x="415.12"
            y="156.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-4_filter2_d"
            x="292.3"
            y="29.3003"
            width="131.279"
            height="170.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-4_filter3_i"
            x="308"
            y="45"
            width="98.9399"
            height="139.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-4_filter4_d"
            x="297.822"
            y="106.765"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-4_filter5_d"
            x="299.12"
            y="110.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="423.8"
            y1="63.5"
            x2="416.2"
            y2="63.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="414.202"
            y1="48.5"
            x2="421.8"
            y2="48.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="423.798"
            y1="63.5"
            x2="416.2"
            y2="63.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="423.8"
            y1="166.625"
            x2="416.2"
            y2="166.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="425.5"
            y1="178.604"
            x2="416"
            y2="178.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
