export enum VIEW_TYPES {
  DEFAULT = 'default',
  BLUE = 'blue',
}

export interface IStepByStepHelperItem {
  value: number
  text: string
}

export interface IStepByStepHelperProps {
  code: string
  coord: { x: number | string; y: number | string }
  view?: VIEW_TYPES
  arrowDown?: boolean
}
