import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement34Square53Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#3-4_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M55.0005 121C56.1048 121 57 120.105 57 119.001C57 117.896 56.1048 117.001 55.0005 117.001L52.8335 117.001L52.8335 117.001L42 117.001L42 117L38.9995 117C37.8952 117 37 117.895 37 118.999C37 120.104 37.8952 120.999 38.9995 120.999L41.1668 120.999L41.1668 121L52 121L52 121L55.0005 121Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 52.834 121)"
          fill="#ABABAB"
        />
        <path
          d="M56.0601 121C56.5792 121 57 120.579 57 120.06L57 117.941C57 117.422 56.5792 117.001 56.0601 117.001L52 117.001L52 121L56.0601 121Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M37.9399 117C37.4208 117 37 117.421 37 117.94L37 120.059C37 120.578 37.4208 120.999 37.9399 120.999L42 120.999L42 117L37.9399 117Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 51.167 121)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#3-4_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M111.001 121C112.105 121 113 120.105 113 119.001C113 117.896 112.105 117.001 111.001 117.001L108.833 117.001L108.833 117.001L98 117.001L98 117L94.9995 117C93.8952 117 93 117.895 93 118.999C93 120.104 93.8952 120.999 94.9995 120.999L97.1668 120.999L97.1668 121L108 121L108 121L111.001 121Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 108.834 121)"
          fill="#ABABAB"
        />
        <path
          d="M112.06 121C112.579 121 113 120.579 113 120.06L113 117.941C113 117.422 112.579 117.001 112.06 117.001L108 117.001L108 121L112.06 121Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M93.9399 117C93.4208 117 93 117.421 93 117.94L93 120.059C93 120.578 93.4208 120.999 93.9399 120.999L98 120.999L98 117L93.9399 117Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 107.167 121)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#3-4_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M119 121H31V287H119V121ZM108 132H42V276H108V132Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#3-4_filter3_i)">
          <rect
            x="42.47"
            y="132.47"
            width="65.0601"
            height="143.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="36.5" y="126.5" width="77" height="155" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M119 121H31V287H32V122H118V287H119V121Z"
          fill="#FBFBFB"
        />
        <path d="M43.6252 133.5L106.375 133.5L75 272.726L43.6252 133.5Z" stroke="white" />
        <rect
          x="82"
          y="278"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 82 278)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#3-4_filter4_d)">
          <rect
            x="81.3555"
            y="278.701"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 81.3555 278.701)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M81.2383 280.694C80.8919 279.541 79.8222 278.701 78.5563 278.701L71.9164 278.701C70.37 278.701 69.1164 279.955 69.1164 281.501C69.1164 281.536 69.117 281.571 69.1183 281.606C69.4647 282.759 70.5344 283.599 71.8003 283.599L78.4402 283.599C79.9866 283.599 81.2402 282.346 81.2402 280.799C81.2402 280.764 81.2396 280.729 81.2383 280.694Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.8809 279.034C79.7372 279.011 79.5899 278.999 79.4398 278.999L72.7999 278.999C71.2535 278.999 69.9999 280.253 69.9999 281.799C69.9999 282.866 70.5968 283.794 71.4749 284.267C70.1381 284.055 69.116 282.898 69.116 281.501C69.116 279.955 70.3696 278.701 71.916 278.701L78.5559 278.701C79.0352 278.701 79.4864 278.822 79.8809 279.034Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-4_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M78 281.5C78 280.672 77.3284 280 76.5 280L76.2857 280L75 280L56.5 280C55.6716 280 55 280.672 55 281.5V281.5C55 282.328 55.6716 283 56.5 283L73 283L76.5 283L76.7143 283C77.4244 283 78 282.424 78 281.714V281.714C78 281.677 77.9988 281.64 77.9965 281.604C77.9988 281.569 78 281.535 78 281.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="3-4_filter0_d"
            x="35.1201"
            y="115.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-4_filter1_d"
            x="91.1201"
            y="115.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-4_filter2_d"
            x="26.3003"
            y="116.3"
            width="99.2793"
            height="177.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-4_filter3_i"
            x="42"
            y="132"
            width="66.9399"
            height="146.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-4_filter4_d"
            x="67.2354"
            y="276.821"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-4_filter5_d"
            x="53.1201"
            y="278.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="39.5"
            y1="123.8"
            x2="39.5"
            y2="116.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="54.5"
            y1="114.202"
            x2="54.5"
            y2="121.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="39.5"
            y1="123.798"
            x2="39.5"
            y2="116.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="95.5"
            y1="123.8"
            x2="95.5"
            y2="116.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="110.5"
            y1="114.202"
            x2="110.5"
            y2="121.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="95.5"
            y1="123.798"
            x2="95.5"
            y2="116.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
