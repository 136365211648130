import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement25Square5Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-5_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M176.999 287C175.895 287 175 287.895 175 288.999C175 290.104 175.895 290.999 176.999 290.999L179.167 290.999L179.167 290.999L190 290.999L190 291L193.001 291C194.105 291 195 290.105 195 289.001C195 287.896 194.105 287.001 193.001 287.001L190.833 287.001L190.833 287L180 287L180 287L176.999 287Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 179.166 287)"
          fill="#ABABAB"
        />
        <path
          d="M175.94 287C175.421 287 175 287.421 175 287.94L175 290.059C175 290.578 175.421 290.999 175.94 290.999L180 290.999L180 287L175.94 287Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M194.06 291C194.579 291 195 290.579 195 290.06L195 287.941C195 287.422 194.579 287.001 194.06 287.001L190 287.001L190 291L194.06 291Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 180.833 287)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#2-5_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M254.999 287C253.895 287 253 287.895 253 288.999C253 290.104 253.895 290.999 254.999 290.999L257.167 290.999L257.167 290.999L268 290.999L268 291L271.001 291C272.105 291 273 290.105 273 289.001C273 287.896 272.105 287.001 271.001 287.001L268.833 287.001L268.833 287L258 287L258 287L254.999 287Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 257.166 287)"
          fill="#ABABAB"
        />
        <path
          d="M253.94 287C253.421 287 253 287.421 253 287.94L253 290.059C253 290.578 253.421 290.999 253.94 290.999L258 290.999L258 287L253.94 287Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M272.06 291C272.579 291 273 290.579 273 290.06L273 287.941C273 287.422 272.579 287.001 272.06 287.001L268 287.001L268 291L272.06 291Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 258.833 287)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#2-5_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M284 34H164V287H284V34ZM273 45H175V276H273V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M284 34H164V287L164.887 287V34.937H283.109V287L284 287V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="168.53"
          y="38.53"
          width="110.94"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#2-5_filter3_i)">
          <rect
            x="175.47"
            y="45.47"
            width="97.0601"
            height="230.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M224 46L176 275H272L224 46ZM224 50.3351L177.107 274.055H270.893L224 50.3351Z"
          fill="white"
        />
        <rect
          x="230.528"
          y="36"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 230.528 36)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#2-5_filter4_d)">
          <rect
            x="229.884"
            y="36.7021"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 229.884 36.7021)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M229.767 38.6953C229.42 37.5422 228.351 36.7021 227.085 36.7021L220.445 36.7021C218.898 36.7021 217.645 37.9558 217.645 39.5021C217.645 39.5373 217.645 39.5723 217.647 39.6072C217.993 40.7602 219.063 41.6003 220.329 41.6003L226.969 41.6003C228.515 41.6003 229.769 40.3467 229.769 38.8003C229.769 38.7652 229.768 38.7302 229.767 38.6953Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M228.409 37.0349C228.266 37.0121 228.118 37.0003 227.968 37.0003L221.328 37.0003C219.782 37.0003 218.528 38.2539 218.528 39.8003C218.528 40.8674 219.125 41.795 220.003 42.2676C218.666 42.0561 217.644 40.8985 217.644 39.5021C217.644 37.9558 218.898 36.7021 220.444 36.7021L227.084 36.7021C227.564 36.7021 228.015 36.8226 228.409 37.0349Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-5_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M243.528 39.5C243.528 38.6716 242.857 38 242.028 38L241.814 38L240.528 38L222.028 38C221.2 38 220.528 38.6716 220.528 39.5C220.528 40.3284 221.2 41 222.028 41L238.528 41L242.028 41L242.243 41C242.953 41 243.528 40.4244 243.528 39.7143C243.528 39.6771 243.527 39.6401 243.525 39.6035C243.527 39.5693 243.528 39.5348 243.528 39.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="2-5_filter0_d"
            x="173.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-5_filter1_d"
            x="251.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-5_filter2_d"
            x="159.3"
            y="29.3003"
            width="131.279"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-5_filter3_i"
            x="175"
            y="45"
            width="98.9399"
            height="233.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-5_filter4_d"
            x="215.764"
            y="34.8223"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-5_filter5_d"
            x="219.649"
            y="36.1201"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="192.5"
            y1="284.2"
            x2="192.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="177.5"
            y1="293.798"
            x2="177.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="192.5"
            y1="284.202"
            x2="192.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="270.5"
            y1="284.2"
            x2="270.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="255.5"
            y1="293.798"
            x2="255.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="270.5"
            y1="284.202"
            x2="270.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
