import styled, { css } from 'styled-components'

export const WallColorSelectWrapper = styled.div`
  position: relative;

  display: flex;
  align-items: center;

  cursor: pointer;

  & > span {
    margin-left: 16px;
  }
`

export const WallColorSelectTooltip = styled.div<{ show: boolean }>`
  background-color: #ffffff;

  position: absolute;

  top: calc(100% + 8px);
  left: 0;

  width: 216px;

  padding: 16px;

  display: grid;

  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 8px;
  grid-template-rows: repeat(4, 1fr);
  grid-row-gap: 8px;

  opacity: 0;
  transform: translateY(-10px);
  pointer-events: none;

  transition: opacity 0.3s, transform 0.3s;

  z-index: 10;

  ${({ show = false }) =>
    show &&
    css`
      opacity: 1;
      transform: translateY(0);
      pointer-events: all;
    `}
`

export const WallColorItem = styled.div<{ color?: string }>`
  background-color: ${({ color = 'transparent' }) => color};

  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;

  border-radius: 50px;

  cursor: pointer;
`
