import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement21Square3Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-1_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M171.999 118C170.895 118 170 118.895 170 119.999C170 121.104 170.895 121.999 171.999 121.999L174.167 121.999L174.167 121.999L185 121.999L185 122L188.001 122C189.105 122 190 121.105 190 120.001C190 118.896 189.105 118.001 188.001 118.001L185.833 118.001L185.833 118L175 118L175 118L171.999 118Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 174.166 118)"
          fill="#ABABAB"
        />
        <path
          d="M170.94 118C170.421 118 170 118.421 170 118.94L170 121.059C170 121.578 170.421 121.999 170.94 121.999L175 121.999L175 118L170.94 118Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M189.06 122C189.579 122 190 121.579 190 121.06L190 118.941C190 118.422 189.579 118.001 189.06 118.001L185 118.001L185 122L189.06 122Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 175.833 118)"
          fill="url(#paint2-1_linear)"
        />
        <g filter="url(#2-1_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M259.999 118C258.895 118 258 118.895 258 119.999C258 121.104 258.895 121.999 259.999 121.999L262.167 121.999L262.167 121.999L273 121.999L273 122L276.001 122C277.105 122 278 121.105 278 120.001C278 118.896 277.105 118.001 276.001 118.001L273.833 118.001L273.833 118L263 118L263 118L259.999 118Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 262.166 118)"
          fill="#ABABAB"
        />
        <path
          d="M258.94 118C258.421 118 258 118.421 258 118.94L258 121.059C258 121.578 258.421 121.999 258.94 121.999L263 121.999L263 118L258.94 118Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M277.06 122C277.579 122 278 121.579 278 121.06L278 118.941C278 118.422 277.579 118.001 277.06 118.001L273 118.001L273 122L277.06 122Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 263.833 118)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#2-1_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M284 122H164V199H284V122ZM273 133H175V188H273V133Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#2-1_filter2-1_i)">
          <rect
            x="175.47"
            y="133.47"
            width="97.0601"
            height="54.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="169.5" y="127.5" width="109" height="66" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M284 122H164V199H165V123H283V199H284V122Z"
          fill="#FBFBFB"
        />
        <path d="M177.127 134.5H270.873L224 186.255L177.127 134.5Z" stroke="white" />
        <rect
          x="230"
          y="190"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 230 190)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#2-1_filter4_d)">
          <rect
            x="229.355"
            y="190.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 229.355 190.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M229.238 192.695C228.892 191.542 227.822 190.702 226.556 190.702L219.916 190.702C218.37 190.702 217.116 191.956 217.116 193.502C217.116 193.537 217.117 193.572 217.118 193.607C217.465 194.76 218.534 195.6 219.8 195.6L226.44 195.6C227.987 195.6 229.24 194.347 229.24 192.8C229.24 192.765 229.24 192.73 229.238 192.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M227.881 191.035C227.737 191.012 227.59 191 227.44 191L220.8 191C219.254 191 218 192.254 218 193.8C218 194.867 218.597 195.795 219.475 196.268C218.138 196.056 217.116 194.898 217.116 193.502C217.116 191.956 218.37 190.702 219.916 190.702L226.556 190.702C227.035 190.702 227.486 190.823 227.881 191.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-1_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M226 193.5C226 192.672 225.328 192 224.5 192L224.286 192L223 192L204.5 192C203.672 192 203 192.672 203 193.5C203 194.328 203.672 195 204.5 195L221 195L224.5 195L224.714 195C225.424 195 226 194.424 226 193.714C226 193.677 225.999 193.64 225.996 193.604C225.999 193.569 226 193.535 226 193.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="2-1_filter0_d"
            x="168.12"
            y="116.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-1_filter1_d"
            x="256.12"
            y="116.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-1_filter2_d"
            x="159.3"
            y="117.3"
            width="131.279"
            height="88.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-1_filter2-1_i"
            x="175"
            y="133"
            width="98.9399"
            height="57.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-1_filter4_d"
            x="215.235"
            y="188.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-1_filter5_d"
            x="201.12"
            y="190.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="187.5"
            y1="115.2"
            x2="187.5"
            y2="122.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="172.5"
            y1="124.798"
            x2="172.5"
            y2="117.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="187.5"
            y1="115.202"
            x2="187.5"
            y2="122.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2-1_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="275.5"
            y1="115.2"
            x2="275.5"
            y2="122.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="260.5"
            y1="124.798"
            x2="260.5"
            y2="117.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="275.5"
            y1="115.202"
            x2="275.5"
            y2="122.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
