import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement41Square41Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-1-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M328 255C328 253.895 327.105 253 326 253C324.895 253 324 253.895 324 255V269.56H328V255ZM328 269.56H324V271.4H323.94C323.421 271.4 323 271.821 323 272.34V274.12C323 275.158 323.842 276 324.88 276H326.12C327.158 276 328 275.158 328 274.12V271.4V271.4V269.56Z"
            fill="white"
          />
        </g>
        <rect
          width="3.99998"
          height="0.95833"
          transform="matrix(-1 0 0 1 328 270.25)"
          fill="#ABABAB"
        />
        <path
          d="M328 253.94C328 253.421 327.579 253 327.06 253H324.94C324.421 253 324 253.421 324 253.94V270.25H328V253.94Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M328 275.06C328 275.579 327.579 276 327.06 276H323.94C323.421 276 323 275.579 323 275.06V272.148C323 271.629 323.421 271.208 323.94 271.208H328V275.06Z"
          fill="url(#paint1_linear)"
        />
        <g filter="url(#4-1-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M328 46.9995C328 45.8952 327.105 45 326.001 45C324.896 45 324.001 45.8952 324.001 46.9995V49.1665H324.001V60H324V63.0005C324 64.1048 324.895 65 325.999 65C327.104 65 327.999 64.1048 327.999 63.0005V60.8332H328V50H328V46.9995Z"
            fill="url(#paint2_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 328 49.1665)"
          fill="#ABABAB"
        />
        <path
          d="M328 45.9399C328 45.4208 327.579 45 327.06 45H324.941C324.422 45 324.001 45.4208 324.001 45.9399V50H328V45.9399Z"
          fill="url(#paint3_linear)"
        />
        <path
          d="M324 64.0601C324 64.5792 324.421 65 324.94 65H327.059C327.578 65 327.999 64.5792 327.999 64.0601V60H324V64.0601Z"
          fill="url(#paint4_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 328 50.8334)"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#4-1-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 34H328V287H417V34ZM406 45H339V276H406V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 34H328V287H329.219V35H415.781V287H417V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-1-filter3_i)">
          <rect
            x="339.47"
            y="45.47"
            width="66.0601"
            height="230.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="332.53"
          y="38.53"
          width="79.9399"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="408"
          y="155"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#4-1-filter4_d)">
          <rect x="408.702" y="155.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M409.286 166.795C409.798 167.458 410.6 167.884 411.502 167.884C413.048 167.884 414.302 166.63 414.302 165.084V158.444C414.302 157.456 413.79 156.587 413.016 156.089C413.382 156.562 413.6 157.155 413.6 157.8V164.44C413.6 165.986 412.347 167.24 410.8 167.24C410.242 167.24 409.722 167.077 409.286 166.795Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M411.502 155.644C409.956 155.644 408.702 156.898 408.702 158.444V164.44C408.702 164.46 408.702 164.48 408.703 164.501V159.024C408.703 157.477 409.956 156.224 411.503 156.224C413.029 156.224 414.269 157.444 414.302 158.963V158.444C414.302 156.898 413.048 155.644 411.502 155.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-1-filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M411.5 159C410.672 159 410 159.672 410 160.5V160.714V162V180.5C410 181.328 410.672 182 411.5 182V182C412.328 182 413 181.328 413 180.5V164V160.5L413 160.286C413 159.576 412.424 159 411.714 159V159C411.677 159 411.64 159.001 411.604 159.004C411.569 159.001 411.535 159 411.5 159Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M340.5 273.114L404.425 160.999L340.5 47.9008L340.5 273.114Z" stroke="white" />
        <path d="M404.424 274.5L372.5 49.5584L340.576 274.5L404.424 274.5Z" stroke="white" />
        <defs>
          <filter
            id="4-1-filter0_d"
            x="321.12"
            y="251.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-1-filter1_d"
            x="322.12"
            y="43.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-1-filter2_d"
            x="323.3"
            y="29.3003"
            width="100.279"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-1-filter3_i"
            x="339"
            y="45"
            width="67.9399"
            height="233.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-1-filter4_d"
            x="406.822"
            y="153.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-1-filter5_d"
            x="408.12"
            y="157.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="321.2"
            y1="261.625"
            x2="328.8"
            y2="261.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="319.5"
            y1="273.604"
            x2="329"
            y2="273.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="330.8"
            y1="62.5"
            x2="323.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="321.202"
            y1="47.5"
            x2="328.8"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="330.798"
            y1="62.5"
            x2="323.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
