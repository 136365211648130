const windowProps = {
  'window-model': {
    type: 'object',
    code: 'window-model',
    title: 'Модель окна',
    properties: {
      'target-key': { type: 'string' },
      id: { type: 'number' },
      'is-default': {
        type: 'boolean',
        description: 'Флаг того, что значение выбрано по умолчанию',
      },
      'is-order': { type: 'boolean' },
      type: { type: 'string' },
      title: { type: 'string' },
      description: { type: 'string' },
      name: { type: 'string' },
      'height-default': { type: 'number' },
      'height-range': { type: 'string' },
      'width-default': { type: 'number' },
      'width-range': { type: 'string' },
      sizes: { type: 'string' },
    },
    enum: [
      {
        id: 2,
        'target-key': 'value',
        'is-order': true,
        type: 'Окно',
        title: 'Модель 1.0',
        value: 'model-1',
        description:
          '<div>Модель 1.0 фурнитура Т</br>СП: {{glass-unit-2/description}}</br>Цвет изнутри: {{model-inside-color/title}}</br>Цвет снаружи: {{model-outside-color/title}}</br>Цвет профиля: {{model-profile-color/title}}</div>',
        name: 'Модель 1.0',
        'height-default': 1300,
        'height-range': '300~3000',
        'width-default': 1250,
        'width-range': '300~3000',
        sizes: '{{model-width}} x {{model-height}}',
        'window-model-text': 'Подходит для промышленных зданий, складов, остекления балконов, офисов, летних домов и веранд'
      },
      {
        id: 1,
        'target-key': 'value',
        'is-default': true,
        'is-order': true,
        type: 'Окно',
        title: 'Модель 2.0',
        value: 'model-2',
        description:
          '<div>Модель 2.0 фурнитура Н</br>СП: {{glass-unit-2/description}}</br>Цвет изнутри: {{model-inside-color/title}}</br>Цвет снаружи: {{model-outside-color/title}}</br>Цвет профиля: {{model-profile-color/title}}</div>',
        name: 'Модель 2.0',
        'height-default': 1300,
        'height-range': '300~3000',
        'width-default': 1250,
        'width-range': '300~3000',
        sizes: '{{model-width}} x {{model-height}}',
        'window-model-text': 'Подходит для квартир, загородных домов, дошкольных и медицинских учреждений'
      },
      {
        id: 3,
        'target-key': 'value',
        'is-order': true,
        type: 'Окно',
        title: 'Модель 3.0',
        value: 'model-3',
        description:
          '<div>Модель 3.0 фурнитура Н</br>СП: {{glass-unit-2/description}}</br>Цвет изнутри: {{model-inside-color/title}}</br>Цвет снаружи: {{model-outside-color/title}}</br>Цвет профиля: {{model-profile-color/title}}</div>',
        name: 'Модель 3.0',
        'height-default': 1300,
        'height-range': '300~3000',
        'width-default': 1250,
        'width-range': '300~3000',
        sizes: '{{model-width}} x {{model-height}}',
        'window-model-text': 'Подходит для остекления загородных домов, квартир с требованием повышенной тепло и звукоизоляции'
      },
    ],
  },
  'window-width': {
    type: 'number',
    code: 'window-width',
    title: 'Ширина',
    required: true,
  },
  'window-height': {
    type: 'number',
    code: 'window-height',
    title: 'Высота',
    required: true,
  },
  'window-shape': {
    type: 'object',
    code: 'window-shape',
    title: 'Форма окна',
    properties: {
      'target-key': { type: 'string' },
      id: { type: 'number' },
      title: { type: 'string' },
      code: { type: 'string' },
      image: { type: 'string' },
      'is-non-standard-shape': { type: 'boolean' },
      preview: { type: 'string' },
    },
    enum: [
      {
        id: 1,
        'target-key': 'code',
        title: 'Прямоугольное окно',
        code: 'rectangular',
        image:
          'https://storage.yandexcloud.net/axioma/files/d90c16da-5c8e-4138-b6be-ef1d9bd9f6d1.png',
        'is-non-standard-shape': false,
        preview:
          'https://storage.yandexcloud.net/axioma/files/d90c16da-5c8e-4138-b6be-ef1d9bd9f6d1.png',
      },
      // {
      //   id: 2,
      //   'target-key': 'code',
      //   title: 'Скошенное окно',
      //   code: 'beveled',
      //   image:
      //     'https://storage.yandexcloud.net/axioma/files/0fc01263-9aaf-453a-a5f1-d61b6017a6a8.png',
      //   'is-non-standard-shape': true,
      //   preview:
      //     'https://storage.yandexcloud.net/axioma/files/0fc01263-9aaf-453a-a5f1-d61b6017a6a8.png',
      // },
      // {
      //   id: 3,
      //   'target-key': 'code',
      //   title: 'Лоджия',
      //   code: 'loggia',
      //   image:
      //     'https://storage.yandexcloud.net/axioma/files/bfcf6b65-afc5-43a6-bc86-bc964882d2c5.png',
      //   'is-non-standard-shape': true,
      //   preview:
      //     'https://storage.yandexcloud.net/axioma/files/48aea5c0-1cf5-4821-8f5d-c1c0fd4b1352.png',
      // },
      {
        id: 4,
        'target-key': 'code',
        title: 'Балконный блок',
        code: 'doors',
        image:
          'https://storage.yandexcloud.net/axioma/files/7344c058-d184-4cd0-8e2b-0492d25c95f8.png',
        'is-non-standard-shape': true,
        preview:
          'https://storage.yandexcloud.net/axioma/files/7344c058-d184-4cd0-8e2b-0492d25c95f8.png',
      },
    ],
  },
  'product-type': {
    type: 'object',
    code: 'product-type',
    properties: {
      'target-key': { type: 'string' },
      value: { type: 'string' },
      title: { type: 'string' },
      description: { type: 'string' },
      'preview-url': { type: 'string' },
    },
    enum: [
      {
        'target-key': 'value',
        value: 'window',
        title: 'Окно',
        'preview-url': 'Здесь ссылка на картинку с окном',
      },
    ],
  },
  'window-container': {
    type: 'string',
    code: 'window-container',
    description: 'Идентификатор SVG-файлов запчастей окна в калькуляторе и его элементы',
    images: 'square4Casement',
  },
  configuration: {
    type: 'object',
    code: 'configuration',
    title: 'configuration',
    'reducer-filter': (props, state) => {
      return props.filter(propRow => propRow['target-code'] === state['window-shape'])
    },
    'reducer-specification': (props, state) => {
      return props.filter(
        propRow =>
          propRow[propRow['target-key']] === state['configuration'] &&
          propRow['target-code'] === state['window-shape'],
      )
    },
    properties: {
      'target-key': { type: 'string' },
      id: { type: 'number' },
      description: { type: 'string' },
      name: { type: 'string' },
      image: { type: 'string' },
      'mobile-image': { type: 'string' },
      'target-code': { type: 'string' },
      title: { type: 'string' },
      preview: { type: 'string' },
      images: { type: 'string' },
      'impost-horizontal-formula': { type: 'string' },
      'impost-horizontal-name': { type: 'string' },
      'impost-horizontal-price': { type: 'boolean' },
      'impost-horizontal-value': { type: 'number' },
      'impost-vertical-formula': { type: 'string' },
      'impost-vertical-name': { type: 'string' },
      'impost-vertical-price': { type: 'boolean' },
      'impost-vertical-value': { type: 'number' },
      'casements-name': { type: 'string' },
      'casements-sizes': { type: 'string' },
      'casements-formula': { type: 'string' },
      'casements-price': { type: 'boolean' },
      'casements-value': { type: 'string' },
      'casements-number-of-leaves': { type: 'number' },
      'is-mobile': { type: 'boolean' },
      sizes: { type: 'string' },
    },
    enum: [
      {
        id: 24,
        'target-key': 'code',
        title: 'Прямоугольное одностворчатое окно',
        'short-title': 'Одностворчатое окно',
        code: 'ПО-144/ЕВРО-15',
        'target-code': 'rectangular',
        description:
          '<div>Одностворчатое окно, Ширина: {{model-width}}, Высота: {{model-height}}</br>{{window-model/description}}{{casements}}</div>',
        name:
          '<div>Одностворчатое окно, Ширина: {{model-width}}, Высота: {{model-height}}</br>{{window-model/name}}</div>',
        image:
          'https://storage.yandexcloud.net/axioma/files/d90c16da-5c8e-4138-b6be-ef1d9bd9f6d1.png',
        'mobile-image':
          'https://storage.yandexcloud.net/axioma/files/73a871aa-fada-4336-a7ca-e10796281841.jpg',
        images: 'square1Casement',
        'impost-horizontal-formula': '',
        'impost-horizontal-name': '',
        'impost-horizontal-price': false,
        'impost-horizontal-value': 0,
        'impost-vertical-formula': '',
        'impost-vertical-name': '',
        'impost-vertical-price': false,
        'impost-vertical-value': 0,
        'casements-name': `[
          Поворотная створка {{casements-x/0}} / {{casements-y/0}}, Поворотно-откидная створка {{casements-x/0}} / {{casements-y/0}}, Поворотно-откидная створка {{casements-x/0}} / {{casements-y/0}}, Поворотная створка {{casements-x/0}} / {{casements-y/0}}, Фрамуга {{casements-x/0}} / {{casements-y/0}}, Фрамуга {{casements-x/0}} / {{casements-y/0}}
          ]`,
        'casements-sizes': `[
          {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}
          ]`,
        'casements-formula': '2*x1+2*y1',
        'casements-price': true,
        // 'casements-value': `[swivel, swing-out, swing-out, swivel, transom, transom]`,
        'casements-value': `[swivel, swing-out, swing-out, swivel]`,
        'casements-number-of-leaves': 0,
        'is-mobile': true,
        sizes:
          '{ "model-width": 900, "model-height": 1000, "casements-x": [900], "casements-y": [1000] }',
      },
      {
        id: 9,
        'target-key': 'code',
        title: 'Прямоугольное двухстворчатое окно',
        'short-title': 'Двухстворчатое окно',
        code: 'ПО-144/ЕВРО-3',
        'target-code': 'rectangular',
        description:
          '<div>Двухстворчатое окно, Ширина: {{model-width}}, Высота: {{model-height}}</br>{{window-model/description}}{{casements}}</div>',
        name:
          '<div>Двухстворчатое окно, Ширина: {{model-width}}, Высота: {{model-height}}</br>{{window-model/name}}</div>',
        image:
          'https://storage.yandexcloud.net/axioma/files/efecbfc3-9e33-4099-8017-caa9e4697e3b.png',
        'mobile-image':
          'https://storage.yandexcloud.net/axioma/files/a744a608-1df3-4102-b296-0021e704cb17.jpg',
        images: 'square2Casement',
        'impost-horizontal-formula': '',
        'impost-horizontal-name': '',
        'impost-horizontal-price': false,
        'impost-horizontal-value': 0,
        'impost-vertical-formula': 'y1',
        'impost-vertical-name': 'Вертикальный импост {{casements-y/0}}',
        'impost-vertical-price': true,
        'impost-vertical-value': 1,
        'casements-name':
          '[Поворотная створка {{casements-x/0}} / {{casements-y/0}}, Поворотно-откидная створка {{casements-x/0}} / {{casements-y/0}}, Фрамуга {{casements-x/0}} / {{casements-y/0}}, Фрамуга {{casements-x/0}} / {{casements-y/0}}], [Поворотная створка {{casements-x/1}} / {{casements-y/0}}, Поворотно-откидная створка {{casements-x/1}} / {{casements-y/0}}, Фрамуга {{casements-x/1}} / {{casements-y/0}}, Фрамуга {{casements-x/1}} / {{casements-y/0}}]',
        'casements-sizes':
          '[{{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}], [{{casements-x/1}} / {{casements-y/0}}, {{casements-x/1}} / {{casements-y/0}}, {{casements-x/1}} / {{casements-y/0}}, {{casements-x/1}} / {{casements-y/0}}]',
        'casements-formula': '2*x1+2*y1, 2*x2+2*y1',
        'casements-price': true,
        // 'casements-value': `[swivel, swing-out, transom, transom], [swivel, swing-out, transom, transom]`,
        'casements-value': `[swivel, swing-out], [swivel, swing-out]`,
        'casements-number-of-leaves': 0,
        'is-mobile': true,
        sizes:
          '{ "model-width": 1200, "model-height": 1300, "casements-x": [600, 600], "casements-y": [1300] }',
      },
      {
        id: 12,
        'target-key': 'code',
        title: 'Прямоугольное трёхстворчатое окно',
        'short-title': 'Трёхстворчатое окно',
        code: 'ПО-144/ЕВРО-6',
        'target-code': 'rectangular',
        description:
          '<div>Трёхстворчатое окно, Ширина: {{model-width}}, Высота: {{model-height}}</br>{{window-model/description}}{{casements}}</div>',
        name:
          '<div>Трёхстворчатое окно, Ширина: {{model-width}}, Высота: {{model-height}}</br>{{window-model/name}}</div>',
        image:
          'https://storage.yandexcloud.net/axioma/files/5b684138-ada7-4721-952d-9acb204167a5.png',
        'mobile-image':
          'https://storage.yandexcloud.net/axioma/files/a8e32cbe-19e2-4330-ac1f-1cc71a8fcb1f.jpg',
        images: 'square32Casement',
        'impost-horizontal-formula': 'x1+x2+x3',
        'impost-horizontal-name':
          'Горизонтальный импост {{casements-x/0}} + {{casements-x/1}} + {{casements-x/2}}',
        'impost-horizontal-price': false,
        'impost-horizontal-value': 0,
        'impost-vertical-formula': 'y1+y1',
        'impost-vertical-name': '2 вертикальных импоста {{casements-y/0}}',
        'impost-vertical-price': true,
        'impost-vertical-value': 2,
        'casements-name': `[
          Поворотная створка {{casements-x/0}} / {{casements-y/0}}, Поворотная створка {{casements-x/0}} / {{casements-y/0}}, Фрамуга {{casements-x/0}} / {{casements-y/0}}, Фрамуга {{casements-x/0}} / {{casements-y/0}}
          ], [
          Поворотная створка {{casements-x/1}} / {{casements-y/0}}, Поворотная створка {{casements-x/1}} / {{casements-y/0}}, Поворотно-откидная створка {{casements-x/1}} / {{casements-y/0}}, Поворотно-откидная створка {{casements-x/1}} / {{casements-y/0}}, Фрамуга {{casements-x/0}} / {{casements-y/0}}, Фрамуга {{casements-x/0}} / {{casements-y/0}}
          ], [
          Поворотная створка {{casements-x/2}} / {{casements-y/0}}, Поворотно-откидная створка {{casements-x/2}} / {{casements-y/0}}, Фрамуга {{casements-x/2}} / {{casements-y/0}}, Фрамуга {{casements-x/2}} / {{casements-y/0}}
          ]`,
        'casements-sizes': `[
          {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}},
          ], [
          {{casements-x/1}} / {{casements-y/0}}, {{casements-x/1}} / {{casements-y/0}}, {{casements-x/1}} / {{casements-y/0}}, {{casements-x/1}} / {{casements-y/0}}, {{casements-x/1}} / {{casements-y/0}}, {{casements-x/1}} / {{casements-y/0}}
          ], [
          {{casements-x/2}} / {{casements-y/0}}, {{casements-x/2}} / {{casements-y/0}}, {{casements-x/2}} / {{casements-y/0}}, {{casements-x/2}} / {{casements-y/0}}
          ]`,
        'casements-formula': '2*x1+2*y1, 2*x2+2*y1, 2*x3+2*y1',
        'casements-price': true,
        // 'casements-value': `[swivel, swing-out, transom, transom], [swivel, swivel, swing-out, swing-out, transom, transom], [swivel, swing-out, transom, transom]`,
        'casements-value': `[swivel, swing-out], [swivel, swivel, swing-out, swing-out], [swivel, swing-out]`,
        'casements-number-of-leaves': 0,
        'is-mobile': true,
        sizes:
          '{ "model-width": 1500, "model-height": 1300, "casements-x": [500, 500, 500], "casements-y": [1300] }',
      },
      {
        id: 28,
        'target-key': 'code',
        title: 'Прямоугольное трехстворчатое окно',
        'short-title': 'Трехстворчатое окно',
        code: 'ПО-144/ЕВРО-19',
        'target-code': 'rectangular',
        description:
          '<div>Трехстворчатое окно, Ширина: {{model-width}}, Высота: {{model-height}}</br>{{window-model/description}}{{casements}}</div>',
        name:
          '<div>Трехстворчатое окно, Ширина: {{model-width}}, Высота: {{model-height}}</br>{{window-model/name}}</div>',
        image:
          'https://storage.yandexcloud.net/axioma/files/3727d1ce-5c19-4dfa-9fc3-45d2b9c28401.png',
        images: 'square3VerticalCasement',
        'impost-horizontal-formula': 'x1+x1',
        'impost-horizontal-name': '2 горизонтальных импоста {{casements-x/0}} + {{casements-x/0}}',
        'impost-horizontal-price': true,
        'impost-horizontal-value': 2,
        'impost-vertical-formula': '',
        'impost-vertical-name': '',
        'impost-vertical-price': false,
        'impost-vertical-value': 0,
        'casements-name': `[
          Поворотная створка {{casements-x/0}} / {{casements-y/0}}, Поворотно-откидная створка {{casements-x/0}} / {{casements-y/0}}, Фрамуга {{casements-x/0}} / {{casements-y/0}}, Фрамуга {{casements-x/0}} / {{casements-y/0}}, Поворотно-откидная створка {{casements-x/0}} / {{casements-y/0}}, Поворотная створка {{casements-x/0}} / {{casements-y/0}}
          ], [
          Поворотная створка {{casements-x/0}} / {{casements-y/1}}, Поворотно-откидная створка {{casements-x/0}} / {{casements-y/1}}, Фрамуга {{casements-x/0}} / {{casements-y/1}}, Фрамуга {{casements-x/0}} / {{casements-y/1}}, Поворотная створка {{casements-x/0}} / {{casements-y/1}}, Поворотно-откидная створка {{casements-x/0}} / {{casements-y/1}}
          ], [
          Поворотная створка {{casements-x/0}} / {{casements-y/2}}, Поворотная створка {{casements-x/0}} / {{casements-y/2}}, Фрамуга {{casements-x/0}} / {{casements-y/2}}, Фрамуга {{casements-x/0}} / {{casements-y/2}}
          ]`,
        'casements-sizes': `[
          {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}
          ], [
          {{casements-x/0}} / {{casements-y/1}}, {{casements-x/0}} / {{casements-y/1}}, {{casements-x/0}} / {{casements-y/1}}, {{casements-x/0}} / {{casements-y/1}}, {{casements-x/0}} / {{casements-y/1}}, {{casements-x/0}} / {{casements-y/1}}
          ], [
          {{casements-x/0}} / {{casements-y/2}}, {{casements-x/0}} / {{casements-y/2}}, {{casements-x/0}} / {{casements-y/2}}, {{casements-x/0}} / {{casements-y/2}}
          ]`,
        'casements-formula': '2*x1+2*y1, 2*x1+2*y2, 2*x1+2*y3',
        'casements-price': true,
        'casements-value': `[swivel, swing-out, transom, transom, swing-out, swivel], [swivel, swing-out, transom, transom, swing-out, swivel], [swivel, swing-out, transom, transom, swing-out, swivel]`,
        'casements-number-of-leaves': 0,
        sizes:
          '{ "model-width": 900, "model-height": 1500, "casements-x": [900], "casements-y": [500, 500, 500] }',
      },
      {
        id: 25,
        'target-key': 'code',
        title: 'Прямоугольное четырёхстворчатое окно',
        'short-title': 'Четырёхстворчатое окно',
        code: 'ПО-144/ЕВРО-16',
        'target-code': 'rectangular',
        description:
          '<div>Четырёхстворчатое окно, Ширина: {{model-width}}, Высота: {{model-height}}</br>{{window-model/description}}{{casements}}</div>',
        name:
          '<div>Четырёхстворчатое окно, Ширина: {{model-width}}, Высота: {{model-height}}</br>{{window-model/name}}</div>',
        image:
          'https://storage.yandexcloud.net/axioma/files/e070594e-a960-46b8-86e0-39321636f5d7.png',
        images: 'square4VerticalCasement',
        'impost-horizontal-formula': 'x1+x1+x1',
        'impost-horizontal-name':
          '3 горизонтальных импостов {{casements-x/0}} + {{casements-x/0}} + {{casements-x/0}}',
        'impost-horizontal-price': true,
        'impost-horizontal-value': 3,
        'impost-vertical-formula': '',
        'impost-vertical-name': '',
        'impost-vertical-price': false,
        'impost-vertical-value': 0,
        'casements-name': `[
          Фрамуга {{casements-x/0}} / {{casements-y/0}}, Фрамуга {{casements-x/0}} / {{casements-y/0}}, Поворотно-откидная створка {{casements-x/0}} / {{casements-y/0}}, Поворотная створка {{casements-x/0}} / {{casements-y/0}}, Поворотно-откидная створка {{casements-x/0}} / {{casements-y/0}}, Поворотная створка {{casements-x/0}} / {{casements-y/0}}
          ], [
          Фрамуга {{casements-x/0}} / {{casements-y/1}}, Фрамуга {{casements-x/0}} / {{casements-y/1}}, Поворотно-откидная створка {{casements-x/0}} / {{casements-y/1}}, Поворотная створка {{casements-x/0}} / {{casements-y/1}}, Поворотно-откидная створка {{casements-x/0}} / {{casements-y/1}}, Поворотная створка {{casements-x/0}} / {{casements-y/1}}
          ], [
          Фрамуга {{casements-x/0}} / {{casements-y/2}}, Фрамуга {{casements-x/0}} / {{casements-y/2}}, Поворотно-откидная створка {{casements-x/0}} / {{casements-y/2}}, Поворотная створка {{casements-x/0}} / {{casements-y/2}}, Поворотно-откидная створка {{casements-x/0}} / {{casements-y/2}}, Поворотная створка {{casements-x/0}} / {{casements-y/2}}
          ], [
          Фрамуга {{casements-x/0}} / {{casements-y/3}}, Фрамуга {{casements-x/0}} / {{casements-y/3}}, Поворотно-откидная створка {{casements-x/0}} / {{casements-y/3}}, Поворотная створка {{casements-x/0}} / {{casements-y/3}}, Поворотно-откидная створка {{casements-x/0}} / {{casements-y/3}}, Поворотная створка {{casements-x/0}} / {{casements-y/3}}
          ]`,
        'casements-sizes': `[
          {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}
          ], [
          {{casements-x/0}} / {{casements-y/1}}, {{casements-x/0}} / {{casements-y/1}}, {{casements-x/0}} / {{casements-y/1}}, {{casements-x/0}} / {{casements-y/1}}, {{casements-x/0}} / {{casements-y/1}}, {{casements-x/0}} / {{casements-y/1}}
          ], [
          {{casements-x/0}} / {{casements-y/2}}, {{casements-x/0}} / {{casements-y/2}}, {{casements-x/0}} / {{casements-y/2}}, {{casements-x/0}} / {{casements-y/2}}, {{casements-x/0}} / {{casements-y/2}}, {{casements-x/0}} / {{casements-y/2}}
          ], [
          {{casements-x/0}} / {{casements-y/3}}, {{casements-x/0}} / {{casements-y/3}}, {{casements-x/0}} / {{casements-y/3}}, {{casements-x/0}} / {{casements-y/3}}, {{casements-x/0}} / {{casements-y/3}}, {{casements-x/0}} / {{casements-y/3}}
          ]`,
        'casements-formula': '2*x1+2*y1, 2*x1+2*y2, 2*x1+2*y3, 2*x1+2*y4',
        'casements-price': true,
        'casements-value': `[transom, transom, swivel, swing-out, swing-out, swivel], [transom, transom, swivel, swing-out, swing-out, swivel], [transom, transom, swivel, swing-out, swing-out, swivel], [transom, transom, swivel, swing-out, swing-out, swivel]`,
        'casements-number-of-leaves': 0,
        sizes:
          '{ "model-width": 900, "model-height": 2000, "casements-x": [900], "casements-y": [500, 500, 500, 500] }',
      },
      {
        id: 1,
        'target-key': 'code',
        title: 'Прямоугольное четырёхстворчатое окно',
        'short-title': 'Четырёхстворчатое окно',
        code: 'ПО-144/ЕВРО',
        'target-code': 'rectangular',
        description:
          '<div>Четырёхстворчатое окно, Ширина: {{model-width}}, Высота: {{model-height}}</br>{{window-model/description}}{{casements}}</div>',
        name:
          '<div>Четырёхстворчатое окно, Ширина: {{model-width}}, Высота: {{model-height}}</br>{{window-model/name}}</div>',
        image:
          'https://storage.yandexcloud.net/axioma/files/939358ba-36b2-4346-bbad-e0c2a0a109c7.png',
        images: 'square4Casement',
        'impost-horizontal-formula': 'x1+x2',
        'impost-horizontal-name': 'Горизонтальный импост {{casements-x/0}} + {{casements-x/1}}',
        'impost-horizontal-price': true,
        'impost-horizontal-value': 1,
        'impost-vertical-formula': 'y1+y2',
        'impost-vertical-name': 'Вертикальный импост {{casements-y/0}} + {{casements-y/1}}',
        'impost-vertical-price': true,
        'impost-vertical-value': 1,
        'casements-name': `[
          Фрамуга {{casements-x/0}} / {{casements-y/0}}, Фрамуга {{casements-x/0}} / {{casements-y/0}}
          ], [
          Фрамуга {{casements-x/1}} / {{casements-y/0}}, Фрамуга {{casements-x/1}} / {{casements-y/0}}
          ], [
          Поворотная створка {{casements-x/0}} / {{casements-y/1}}, Поворотно-откидная створка {{casements-x/0}} / {{casements-y/1}}, Фрамуга {{casements-x/0}} / {{casements-y/1}}, Фрамуга {{casements-x/0}} / {{casements-y/1}}
          ], [
          Поворотная створка {{casements-x/1}} / {{casements-y/1}}, Поворотно-откидная створка {{casements-x/1}} / {{casements-y/1}}, Фрамуга {{casements-x/1}} / {{casements-y/1}}
          ]`,
        'casements-sizes': `[
          {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}
          ], [
          {{casements-x/1}} / {{casements-y/0}}, {{casements-x/1}} / {{casements-y/0}}, {{casements-x/1}} / {{casements-y/0}}
          ], [
          {{casements-x/0}} / {{casements-y/1}}, {{casements-x/0}} / {{casements-y/1}}, {{casements-x/0}} / {{casements-y/1}}, {{casements-x/0}} / {{casements-y/1}}
          ], [
          {{casements-x/1}} / {{casements-y/1}}, {{casements-x/1}} / {{casements-y/1}}, {{casements-x/1}} / {{casements-y/1}}
          ]`,
        'casements-formula': '2*x1+2*y1, 2*x2+2*y1, 2*x1+2*y2, 2*x2+2*y2',
        'casements-price': true,
        'casements-value': `[transom], [transom], [swivel, swing-out], [swivel, swing-out]`,
        'casements-number-of-leaves': 0,
        sizes:
          '{ "model-width": 1200, "model-height": 1300, "casements-x": [600, 600], "casements-y": [400, 900] }',
      },
      {
        id: 27,
        'target-key': 'code',
        title: 'Прямоугольное шестистворчатое окно',
        'short-title': 'Шестистворчатое окно',
        code: 'ПО-144/ЕВРО-18',
        'target-code': 'rectangular',
        description:
          '<div>Шестистворчатое окно, Ширина: {{model-width}}, Высота: {{model-height}}</br>{{window-model/description}}{{casements}}</div>',
        name:
          '<div>Шестистворчатое окно, Ширина: {{model-width}}, Высота: {{model-height}}</br>{{window-model/name}}</div>',
        image:
          'https://storage.yandexcloud.net/axioma/files/443c045c-5af6-4742-8e8f-b1e91e85bc74.png',
        images: 'square62Casement',
        'impost-horizontal-formula': '2*x1+2*x2',
        'impost-horizontal-name':
          '2 горизонтальных импоста {{casements-x/0}} + {{casements-x/0}} + {{casements-x/1}} + {{casements-x/1}}',
        'impost-horizontal-price': true,
        'impost-horizontal-value': 2,
        'impost-vertical-formula': 'y1+y2+y3',
        'impost-vertical-name':
          'Вертикальный импост {{casements-y/0}} + {{casements-y/1}} + {{casements-y/2}}',
        'impost-vertical-price': true,
        'impost-vertical-value': 1,
        'casements-name': `[
          Фрамуга {{casements-x/0}} / {{casements-y/0}},
          ], [
          Поворотно-откидная створка {{casements-x/0}} / {{casements-y/1}}, Поворотная створка {{casements-x/0}} / {{casements-y/1}},
          ], [
          Поворотно-откидная створка {{casements-x/0}} / {{casements-y/2}}, Поворотная створка {{casements-x/0}} / {{casements-y/2}},
          ], [
          Фрамуга {{casements-x/1}} / {{casements-y/0}},
          ], [
          Поворотная створка {{casements-x/1}} / {{casements-y/1}}, Поворотно-откидная створка {{casements-x/1}} / {{casements-y/1}},
          ], [
          Поворотная створка {{casements-x/1}} / {{casements-y/2}}, Поворотно-откидная створка {{casements-x/1}} / {{casements-y/2}}
          ]`,
        'casements-sizes': `[
          {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}},
          ], [
          {{casements-x/0}} / {{casements-y/1}}, {{casements-x/0}} / {{casements-y/1}}, {{casements-x/0}} / {{casements-y/1}}, {{casements-x/0}} / {{casements-y/1}},
          ], [
          {{casements-x/0}} / {{casements-y/2}}, {{casements-x/0}} / {{casements-y/2}}, {{casements-x/0}} / {{casements-y/2}}, {{casements-x/0}} / {{casements-y/2}},
          ], [
          {{casements-x/1}} / {{casements-y/0}}, {{casements-x/1}} / {{casements-y/0}}, {{casements-x/1}} / {{casements-y/0}}, {{casements-x/1}} / {{casements-y/0}},
          ], [
          {{casements-x/1}} / {{casements-y/1}}, {{casements-x/1}} / {{casements-y/1}}, {{casements-x/1}} / {{casements-y/1}}, {{casements-x/1}} / {{casements-y/1}}
          ], [
          {{casements-x/1}} / {{casements-y/2}}, {{casements-x/1}} / {{casements-y/2}}, {{casements-x/1}} / {{casements-y/2}}, {{casements-x/1}} / {{casements-y/2}}
          ]`,
        'casements-formula': '2*x1+2*y1, 2*x1+2*y2, 2*x1+2*y3, 2*x2+2*y1, 2*x2+2*y2, 2*x2+2*y3',
        'casements-price': true,
        // 'casements-value': `[transom], [swing-out, swivel], [swing-out, swivel], [transom], [swivel, swing-out], [swivel, swing-out]`,
        'casements-value': `[swing-out, swivel], [swing-out, swivel], [swing-out, swivel], [swivel, swing-out], [swivel, swing-out], [swivel, swing-out]`,
        'casements-number-of-leaves': 0,
        sizes:
          '{ "model-width": 1200, "model-height": 1500, "casements-x": [600, 600], "casements-y": [500, 500, 500] }',
      },
      {
        id: 10,
        'target-key': 'code',
        title: 'Прямоугольное четырёхстворчатое окно',
        'short-title': 'Четырёхстворчатое окно',
        code: 'ПО-144/ЕВРО-4',
        'target-code': 'rectangular',
        description:
          '<div>Четырёхстворчатое окно, Ширина: {{model-width}}, Высота: {{model-height}}</br>{{window-model/description}}{{casements}}</div>',
        name:
          '<div>Четырёхстворчатое окно, Ширина: {{model-width}}, Высота: {{model-height}}</br>{{window-model/name}}</div>',
        image:
          'https://storage.yandexcloud.net/axioma/files/a3484b6d-732c-480f-86f2-704180ad7fab.png',
        images: 'square4TopCasement',
        'impost-horizontal-formula': 'x1+x2+x3',
        'impost-horizontal-name':
          'Горизонтальный импост {{casements-x/0}} + {{casements-x/1}} + {{casements-x/2}}',
        'impost-horizontal-price': true,
        'impost-horizontal-value': 1,
        'impost-vertical-formula': 'y2+y2',
        'impost-vertical-name': '2 вертикальных импоста {{casements-y/1}} + {{casements-y/1}}',
        'impost-vertical-price': true,
        'impost-vertical-value': 2,
        'casements-name': `[
          Фрамуга {{casements-x/0}} + {{casements-x/1}} + {{casements-x/2}} / {{casements-y/0}}, Фрамуга {{casements-x/0}} + {{casements-x/1}} + {{casements-x/2}} / {{casements-y/0}}
          ], [
          Поворотная створка {{casements-x/0}} / {{casements-y/1}}, Поворотно-откидная створка {{casements-x/0}} / {{casements-y/1}},
          ], [
          Поворотная створка {{casements-x/1}} / {{casements-y/1}}, Поворотная створка {{casements-x/1}} / {{casements-y/1}}, Поворотно-откидная створка {{casements-x/1}} / {{casements-y/1}}, Поворотно-откидная створка {{casements-x/1}} / {{casements-y/1}},
          ], [
          Поворотная створка {{casements-x/2}} / {{casements-y/1}}, Поворотно-откидная створка {{casements-x/2}} / {{casements-y/1}},
          ]`,
        'casements-sizes': `[
          {{casements-x/0}} + {{casements-x/1}} + {{casements-x/2}} / {{casements-y/0}}, {{casements-x/0}} + {{casements-x/1}} + {{casements-x/2}} / {{casements-y/0}}
          ], [
          {{casements-x/0}} / {{casements-y/1}}, {{casements-x/0}} / {{casements-y/1}}, {{casements-x/0}} / {{casements-y/1}}, {{casements-x/0}} / {{casements-y/1}}
          ], [
          {{casements-x/1}} / {{casements-y/1}}, {{casements-x/1}} / {{casements-y/1}}, {{casements-x/1}} / {{casements-y/1}}, {{casements-x/1}} / {{casements-y/1}}, {{casements-x/1}} / {{casements-y/1}}, {{casements-x/1}} / {{casements-y/1}}
          ], [
          {{casements-x/2}} / {{casements-y/1}}, {{casements-x/2}} / {{casements-y/1}}, {{casements-x/2}} / {{casements-y/1}}, {{casements-x/2}} / {{casements-y/1}}
          ]`,
        'casements-formula': '2*x1+2*x2+2*x3+2*y1, 2*x1+2*y2, 2*x2+2*y2, 2*x3+2*y2',
        'casements-price': true,
        // 'casements-value': `[transom, transom], [swivel, swing-out], [swivel, swivel, swing-out, swing-out], [swivel, swing-out]`,
        'casements-value': `[transom], [swivel, swing-out], [swivel, swivel, swing-out, swing-out], [swivel, swing-out]`,
        'casements-number-of-leaves': 0,
        sizes:
          '{ "model-width": 1500, "model-height": 1200, "casements-x": [500, 500, 500], "casements-y": [400, 800] }',
      },
      {
        id: 13,
        'target-key': 'code',
        title: 'Прямоугольное шестистворчатое окно',
        'short-title': 'Шестистворчатое окно',
        code: 'ПО-144/ЕВРО-7',
        'target-code': 'rectangular',
        description:
          '<div>Шестистворчатое окно, Ширина: {{model-width}}, Высота: {{model-height}}</br>{{window-model/description}}{{casements}}</div>',
        name:
          '<div>Шестистворчатое окно, Ширина: {{model-width}}, Высота: {{model-height}}</br>{{window-model/name}}</div>',
        image:
          'https://storage.yandexcloud.net/axioma/files/7874b617-14b2-4e07-8ed3-b48fccd1e607.png',
        'mobile-image':
          'https://storage.yandexcloud.net/axioma/files/665e3648-9c7e-49b8-81ea-510f9161b7d3.jpg',
        images: 'square6Casement',
        'impost-horizontal-formula': 'x1+x2+x3',
        'impost-horizontal-name':
          'Горизонтальный импост {{casements-x/0}} + {{casements-x/1}} + {{casements-x/2}}',
        'impost-horizontal-price': true,
        'impost-horizontal-value': 1,
        'impost-vertical-formula': 'y1+y1',
        'impost-vertical-name': '2 вертикальных импоста: {{casements-y/0}} и {{casements-y/0}}',
        'impost-vertical-price': true,
        'impost-vertical-value': 2,
        'casements-name': `[
          Фрамуга {{casements-x/0}} / {{casements-y/0}},
         ], [
          Фрамуга {{casements-x/1}} / {{casements-y/0}},
          ], [
          Фрамуга {{casements-x/2}} / {{casements-y/0}},
          ], [
          Поворотная створка {{casements-x/0}} / {{casements-y/1}}, Поворотно-откидная створка {{casements-x/0}} / {{casements-y/1}}
          ], [
          Поворотная створка {{casements-x/1}} / {{casements-y/1}}, Поворотно-откидная створка {{casements-x/1}} / {{casements-y/1}}, Поворотная створка {{casements-x/1}} / {{casements-y/1}}, Поворотно-откидная створка {{casements-x/1}} / {{casements-y/1}}
          ], [
          Поворотная створка {{casements-x/2}} / {{casements-y/1}}, Поворотно-откидная створка {{casements-x/2}} / {{casements-y/1}}
          ]`,
        'casements-sizes': `[
          {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}
          ], [
          {{casements-x/1}} / {{casements-y/0}}, {{casements-x/1}} / {{casements-y/0}}, {{casements-x/1}} / {{casements-y/0}}, {{casements-x/1}} / {{casements-y/0}}, {{casements-x/1}} / {{casements-y/0}}, {{casements-x/1}} / {{casements-y/0}}
          ], [
          {{casements-x/2}} / {{casements-y/0}}, {{casements-x/2}} / {{casements-y/0}}, {{casements-x/2}} / {{casements-y/0}}, {{casements-x/2}} / {{casements-y/0}}
          ], [
          {{casements-x/0}} / {{casements-y/1}}, {{casements-x/0}} / {{casements-y/1}}, {{casements-x/0}} / {{casements-y/1}}, {{casements-x/0}} / {{casements-y/1}}
          ], [
          {{casements-x/1}} / {{casements-y/1}}, {{casements-x/1}} / {{casements-y/1}}, {{casements-x/1}} / {{casements-y/1}}, {{casements-x/1}} / {{casements-y/1}}, {{casements-x/1}} / {{casements-y/1}}, {{casements-x/1}} / {{casements-y/1}}
          ], [
          {{casements-x/2}} / {{casements-y/1}}, {{casements-x/2}} / {{casements-y/1}}, {{casements-x/2}} / {{casements-y/1}}, {{casements-x/2}} / {{casements-y/1}}
          ]`,
        'casements-formula': '2*x1+2*y1, 2*x2+2*y1, 2*x3+2*y1, 2*x1+2*y2, 2*x2+2*y2, 2*x3+2*y2',
        'casements-price': true,
        'casements-value': `[transom], [transom], [transom], [swivel, swing-out], [swivel, swing-out, swivel, swing-out], [swivel, swing-out]`,
        'casements-number-of-leaves': 0,
        'is-mobile': true,
        sizes:
          '{ "model-width": 1500, "model-height": 1200, "casements-x": [500, 500, 500], "casements-y": [400, 800] }',
      },
      {
        id: 17,
        'target-key': 'code',
        title: 'Одностворчатое окно с дверью',
        'short-title': 'Одностворчатое окно с дверью',
        code: 'ПОД-1/ЕВРО-1',
        'target-code': 'doors',
        description:
          '<div>Балконный блок с 1 окном</br>{{window-model/description}}{{casements}}</div>',
        name:
          '<div>Балконный блок с 1 окном, Ширина: {{model-width}}, Высота: {{model-height}}</br>{{window-model/name}}</div>',
        image:
          'https://storage.yandexcloud.net/axioma/files/6af35187-41a9-48cf-bfef-970d0a116b36.png',
        'mobile-image':
          'https://storage.yandexcloud.net/axioma/files/72e80dfa-e78c-401d-b7c3-08b021f63406.jpg',
        images: 'door1RCasement1',
        'impost-horizontal-formula': '',
        'impost-horizontal-name': '',
        'impost-horizontal-price': false,
        'impost-horizontal-value': 1,
        'impost-vertical-formula': '',
        'impost-vertical-name': '',
        'impost-vertical-price': false,
        'impost-vertical-value': 1,
        'casements-name': `[
          Поворотная створка {{casements-x/0}} / {{casements-y/0}}, Поворотно-откидная створка {{casements-x/0}} / {{casements-y/0}}, Поворотная створка {{casements-x/0}} / {{casements-y/0}}, Поворотно-откидная створка {{casements-x/0}} / {{casements-y/0}}
          ], [
          Балконная дверь {{casements-x/1}} / {{casements-y/0}} + {{casements-y/1}}, Балконная дверь поворотно-откидная {{casements-x/1}} / {{casements-y/0}} + {{casements-y/1}}, Балконная дверь {{casements-x/1}} / {{casements-y/0}} + {{casements-y/1}}, Балконная дверь поворотно-откидная {{casements-x/1}} / {{casements-y/0}} + {{casements-y/1}}
          ]`,
        'casements-sizes': `[{{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}], [{{casements-x/1}} / {{casements-y/0}} + {{casements-y/1}}, {{casements-x/1}} / {{casements-y/0}} + {{casements-y/1}}, {{casements-x/1}} / {{casements-y/0}} + {{casements-y/1}}, {{casements-x/1}} / {{casements-y/0}} + {{casements-y/1}}]`,
        'casements-formula': '2*x1+2*y1, 2*x2+2*y1+2*y2',
        'casements-price': true,
        'casements-value':
          '[swivel, swing-out, swivel, swing-out], [swivel(Д), swing-out(Д), swivel(Д), swing-out(Д)]',
        'casements-number-of-leaves': 2,
        'is-mobile': true,
        sizes:
          '{ "model-width": 2100, "model-height": 2000, "casements-x": [1200, 900], "casements-y": [2000] }',
      },
      {
        id: 19,
        'target-key': 'code',
        title: 'Одностворчатое окно с дверью',
        'short-title': 'Одностворчатое окно с дверью',
        code: 'ПОД-1/ЕВРО-3',
        'target-code': 'doors',
        description:
          '<div>Одностворчатое окно с дверью</br>{{window-model/description}}{{casements}}</div>',
        name:
          '<div>Одностворчатое окно с дверью, Ширина: {{model-width}}, Высота: {{model-height}}</br>{{window-model/name}}</div>',
        image:
          'https://storage.yandexcloud.net/axioma/files/7344c058-d184-4cd0-8e2b-0492d25c95f8.png',
        'mobile-image':
          'https://storage.yandexcloud.net/axioma/files/8791e654-bb5d-43f9-b042-b0e50820a0eb.jpg',
        images: 'door1LCasement1',
        'impost-horizontal-formula': '',
        'impost-horizontal-name': '',
        'impost-horizontal-price': false,
        'impost-horizontal-value': 1,
        'impost-vertical-formula': '',
        'impost-vertical-name': '',
        'impost-vertical-price': false,
        'impost-vertical-value': 1,
        'casements-name': `[
          Балконная дверь {{casements-x/0}} / {{casements-y/0}} + {{casements-y/1}}, Балконная дверь поворотно-откидная {{casements-x/0}} / {{casements-y/0}} + {{casements-y/1}}, Балконная дверь {{casements-x/0}} / {{casements-y/0}} + {{casements-y/1}}, Балконная дверь поворотно-откидная {{casements-x/0}} / {{casements-y/0}} + {{casements-y/1}}
         ], [
          Поворотная створка {{casements-x/1}} / {{casements-y/0}}, Поворотно-откидная створка {{casements-x/1}} / {{casements-y/0}}, Поворотная створка {{casements-x/1}} / {{casements-y/0}}, Поворотно-откидная створка {{casements-x/1}} / {{casements-y/0}}
         ]`,
        'casements-sizes': `[{{casements-x/0}} / {{casements-y/0}} + {{casements-y/1}}, {{casements-x/0}} / {{casements-y/0}} + {{casements-y/1}}, {{casements-x/0}} / {{casements-y/0}} + {{casements-y/1}}, {{casements-x/0}} / {{casements-y/0}} + {{casements-y/1}}], [{{casements-x/1}} / {{casements-y/0}}, {{casements-x/1}} / {{casements-y/0}}, {{casements-x/1}} / {{casements-y/0}}, {{casements-x/1}} / {{casements-y/0}}]`,
        'casements-formula': '2*x1+2*y1+2*y2, 2*x2+2*y1',
        'casements-price': true,
        'casements-value': `[swivel(Д), swing-out(Д), swivel(Д), swing-out(Д)], [swivel, swing-out, swivel, swing-out]`,
        'casements-number-of-leaves': 2,
        'is-mobile': true,
        sizes:
          '{ "model-width": 2100, "model-height": 2000, "casements-x": [900, 1200], "casements-y": [2000] }',
      },
      {
        id: 18,
        'target-key': 'code',
        title: 'Двустворчатое окно с дверью',
        'short-title': 'Двустворчатое окно с дверью',
        code: 'ПОД-1/ЕВРО-2',
        'target-code': 'doors',
        description:
          '<div>Двустворчатое окно с дверью</br>{{window-model/description}}{{casements}}</div>',
        name:
          '<div>Двустворчатое окно с дверью, Ширина: {{model-width}}, Высота: {{model-height}}</br>{{window-model/name}}</div>',
        image:
          'https://storage.yandexcloud.net/axioma/files/9487f6fd-57af-4e05-a14f-5a783f26f7aa.png',
        'mobile-image':
          'https://storage.yandexcloud.net/axioma/files/35beb5c7-9c7d-404a-83b0-515a1c12ea76.jpg',
        images: 'door1RCasement2',
        'impost-horizontal-formula': '',
        'impost-horizontal-name': '',
        'impost-horizontal-price': false,
        'impost-horizontal-value': 1,
        'impost-vertical-formula': 'y1',
        'impost-vertical-name': 'Вертикальный импост {{casements-y/0}}',
        'impost-vertical-price': true,
        'impost-vertical-value': 2,
        'casements-name': `[
          Поворотно-откидная створка {{casements-x/0}} / {{casements-y/0}}, Поворотно-откидная створка {{casements-x/0}} / {{casements-y/0}}, Поворотная створка {{casements-x/0}} / {{casements-y/0}}, Поворотная створка {{casements-x/0}} / {{casements-y/0}}
          ], [
          Поворотно-откидная створка {{casements-x/1}} / {{casements-y/0}}, Поворотная створка {{casements-x/1}} / {{casements-y/0}}, Поворотно-откидная створка {{casements-x/1}} / {{casements-y/0}}, Поворотная створка {{casements-x/1}} / {{casements-y/0}}
          ], [
          Балконная дверь {{casements-x/2}} / {{casements-y/0}} + {{casements-y/1}}, Балконная дверь поворотно-откидная {{casements-x/2}} / {{casements-y/0}} + {{casements-y/1}}, Балконная дверь {{casements-x/2}} / {{casements-y/0}} + {{casements-y/1}}, Балконная дверь поворотно-откидная {{casements-x/2}} / {{casements-y/0}} + {{casements-y/1}}
          ]`,
        'casements-sizes': `[{{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}, {{casements-x/0}} / {{casements-y/0}}], [{{casements-x/1}} / {{casements-y/0}}, {{casements-x/1}} / {{casements-y/0}}, {{casements-x/1}} / {{casements-y/0}}, {{casements-x/1}} / {{casements-y/0}}], [{{casements-x/2}} / {{casements-y/0}} + {{casements-y/1}}, {{casements-x/2}} / {{casements-y/0}} + {{casements-y/1}}, {{casements-x/2}} / {{casements-y/0}} + {{casements-y/1}}, {{casements-x/2}} / {{casements-y/0}} + {{casements-y/1}}]`,
        'casements-formula': '2*x1+2*y1, 2*x2+2*y1, 2*x3+2*y1+2*y2',
        'casements-price': true,
        'casements-value': `[swing-out, swing-out, swivel, swivel], [swing-out, swivel, swing-out, swivel], [swivel(Д), swing-out(Д), swivel(Д), swing-out(Д)]`,
        'casements-number-of-leaves': 2,
        'is-mobile': true,
        sizes:
          '{ "model-width": 2100, "model-height": 2000, "casements-x": [600, 600, 900], "casements-y": [2000] }',
      },
      {
        id: 20,
        'target-key': 'code',
        title: 'Двустворчатое окно с дверью',
        'short-title': 'Двустворчатое окно с дверью',
        code: 'ПОД-1/ЕВРО-4',
        'target-code': 'doors',
        description:
          '<div>Двустворчатое окно с дверью</br>{{window-model/description}}{{casements}}</div>',
        name:
          '<div>Двустворчатое окно с дверью, Ширина: {{model-width}}, Высота: {{model-height}}</br>{{window-model/name}}</div>',
        image:
          'https://storage.yandexcloud.net/axioma/files/a1b06684-9fa5-437c-88ce-023950a350b0.png',
        'mobile-image':
          'https://storage.yandexcloud.net/axioma/files/6a7a5600-0f44-4892-b699-0c76c3e5af95.jpg',
        images: 'door1LCasement2',
        'impost-horizontal-formula': '',
        'impost-horizontal-name': '',
        'impost-horizontal-price': false,
        'impost-horizontal-value': 1,
        'impost-vertical-formula': 'y1',
        'impost-vertical-name': 'Вертикальный импост {{casements-y/0}}',
        'impost-vertical-price': true,
        'impost-vertical-value': 2,
        'casements-name': `[
          Балконная дверь {{casements-x/0}} / {{casements-y/0}} + {{casements-y/1}}, Балконная дверь поворотно-откидная {{casements-x/0}} / {{casements-y/0}} + {{casements-y/1}}, Балконная дверь {{casements-x/0}} / {{casements-y/0}} + {{casements-y/1}}, Балконная дверь поворотно-откидная {{casements-x/0}} / {{casements-y/0}} + {{casements-y/1}}
         ], [
          Поворотно-откидная створка {{casements-x/1}} / {{casements-y/0}}, Поворотно-откидная створка {{casements-x/1}} / {{casements-y/0}}, Поворотная створка {{casements-x/1}} / {{casements-y/0}}, Поворотная створка {{casements-x/1}} / {{casements-y/0}}, 
         ], [
          Поворотно-откидная створка {{casements-x/2}} / {{casements-y/0}}, Поворотная створка {{casements-x/2}} / {{casements-y/0}}, Поворотно-откидная створка {{casements-x/2}} / {{casements-y/0}}, Поворотная створка {{casements-x/2}} / {{casements-y/0}}
         ]`,
        'casements-sizes': `[{{casements-x/0}} / {{casements-y/0}} + {{casements-y/1}}, {{casements-x/0}} / {{casements-y/0}} + {{casements-y/1}}, {{casements-x/0}} / {{casements-y/0}} + {{casements-y/1}}, {{casements-x/0}} / {{casements-y/0}} + {{casements-y/1}}], [{{casements-x/1}} / {{casements-y/0}}, {{casements-x/1}} / {{casements-y/0}}, {{casements-x/1}} / {{casements-y/0}}, {{casements-x/1}} / {{casements-y/0}}], [{{casements-x/2}} / {{casements-y/0}}, {{casements-x/2}} / {{casements-y/0}}, {{casements-x/2}} / {{casements-y/0}}, {{casements-x/2}} / {{casements-y/0}}]`,
        'casements-formula': '2*x1+2*y1+2*y2, 2*x2+2*y1, 2*x3+2*y1',
        'casements-price': true,
        'casements-value': `[swivel(Д), swing-out(Д), swivel(Д), swing-out(Д)], [swing-out, swivel, swing-out, swivel], [swing-out, swing-out, swivel, swivel]`,
        'casements-number-of-leaves': 2,
        'is-mobile': true,
        sizes:
          '{ "model-width": 2100, "model-height": 2000, "casements-x": [900, 600, 600], "casements-y": [2000] }',
      },
    ],
  },
  aeration: {
    type: 'object',
    code: 'aeration',
    title: 'Проветривание',
    properties: {
      title: {
        type: 'string',
      },
      description: {
        type: 'string',
      },
      'is-order': {
        type: 'boolean',
      },
      price: {
        type: 'boolean',
      },
      value: {
        type: 'boolean',
      },
      'value-title': {
        type: 'string',
      },
      'number-of-leaves-depends': { type: 'boolean' },
    },
    enum: [
      {
        title: 'Проветривание',
        description: 'Функция микропроветривания для поворотно-откидных створок',
        name: 'Дополнительная возможность для неполного откидывания створки',
        'is-order': true,
        price: true,
        value: true,
        'value-title': 'Да',
        'number-of-leaves-depends': true,
      },
      {
        title: 'Проветривание',
        description: 'Функция микропроветривания для поворотно-откидных створок',
        name: 'Дополнительная возможность для неполного откидывания створки',
        'is-order': true,
        price: true,
        value: false,
        'value-title': 'Нет',
        'number-of-leaves-depends': false,
      },
    ],
  },
  security: {
    type: 'object',
    code: 'security',
    title: 'Комплект безопасности',
    properties: {
      title: {
        type: 'string',
      },
      description: {
        type: 'string',
      },
      'is-order': {
        type: 'boolean',
      },
      price: {
        type: 'boolean',
      },
      value: {
        type: 'boolean',
      },
      'value-title': {
        type: 'string',
      },
      'number-of-leaves-depends': { type: 'boolean' },
    },
    enum: [
      {
        title: 'Комплект безопасности',
        description: 'Комплект безопасности',
        'description-info': 'Дополнительный замок в ручку + особая фурнитура',
        name: 'Комплект безопасности',
        'is-order': false,
        price: true,
        value: 2,
        'value-title': 'Да',
        'number-of-leaves-depends': true,
      },
      {
        title: 'Комплект безопасности',
        description: 'Комплект безопасности',
        'description-info': 'Дополнительный замок в ручку + особая фурнитура',
        name: 'Комплект безопасности',
        'is-order': false,
        price: true,
        value: 1,
        'value-title': 'Нет',
        'number-of-leaves-depends': false,
      },
    ],
  },
  'children-security': {
    type: 'object',
    code: 'children-security',
    title: 'Детская безопасность',
    properties: {
      description: {
        type: 'string',
      },
      name: {
        type: 'string',
      },
      'number-of-leaves-depends': {
        type: 'boolean',
      },
      'is-order': {
        type: 'boolean',
      },
      price: {
        type: 'boolean',
      },
      title: {
        type: 'string',
      },
      value: {
        type: 'boolean',
      },
      'value-title': {
        type: 'string',
      },
    },
    enum: [
      {
        description: 'Дополнительная защита для детской безопасности',
        name: 'Детская безопасность',
        title: 'Детская безопасность',
        'number-of-leaves-depends': true,
        'is-order': true,
        price: true,
        value: true,
        'value-title': 'Да',
      },
      {
        description: 'Дополнительная защита для детской безопасности',
        name: 'Детская безопасность',
        title: 'Детская безопасность',
        'number-of-leaves-depends': false,
        'is-order': false,
        price: true,
        value: false,
        'value-title': 'Нет',
      },
    ],
  },
  'comfortable-floriculture': {
    type: 'object',
    code: 'comfortable-floriculture',
    title: 'Комфортное цветоводство',
    properties: {
      description: {
        type: 'string',
      },
      'is-order': {
        type: 'boolean',
      },
      price: {
        type: 'boolean',
      },
      title: {
        type: 'string',
      },
      value: {
        type: 'boolean',
      },
      'value-title': {
        type: 'string',
      },
    },
    enum: [
      {
        description: 'Модель с такими параметрами предустановлена в разделе подоконники',
        title: 'Комфортное цветоводство',
        'is-order': true,
        price: false,
        value: true,
        'value-title': 'Да',
      },
      {
        description: 'Модель с такими параметрами предустановлена в разделе подоконники',
        title: 'Комфортное цветоводство',
        'is-order': false,
        price: false,
        value: false,
        'value-title': 'Нет',
      },
    ],
  },
  'ebb-type-color': {
    type: 'object',
    code: 'ebb-type-color',
    title: 'ebb-type-color',
    'reducer-filter': (props, state) =>
      props.filter(propRow => {
        let result = true
        const models = propRow['window-models']
          ? propRow['window-models'].split(',').map(item => item.trim())
          : []

        if (models.length > 0) {
          result = models.includes(state['window-model'])
        }

        return result
      }),
    'reducer-specification': (props, state) =>
      props.filter(propRow => {
        let result = true
        const models = propRow['window-models']
          ? propRow['window-models'].split(',').map(item => item.trim())
          : []

        if (models.length > 0) {
          result = models.includes(state['window-model'])
        }
        if (result) {
          result = !state['ebb-type-not-needed']
        }
        if (result && propRow['target-key']) {
          result = propRow[propRow['target-key']] === state['ebb-type-color']
        }

        return result
      }),
    properties: {
      'window-models': { type: 'string' },
      'target-key': { type: 'string' },
      code: {
        type: 'string',
      },
      'is-order': {
        type: 'boolean',
      },
      price: {
        type: 'boolean',
      },
      title: {
        type: 'string',
      },
      value: {
        type: 'string',
      },
    },
    enum: [
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Белые тона',
        name: 'Белый - Белые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/cf372b66-53a9-4419-9b97-cbdea01b8ce2.jpg',
        code: 'RAL 9010',
        'target-key': 'code',
        title: 'Белый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Белые тона',
        name: 'Жемчужно-белый - Белые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/6cc06dc6-e3c2-4ad7-a6fb-9874b04a84a4.jpg',
        code: 'RAL 1013',
        'target-key': 'code',
        title: 'Жемчужно-белый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Белые тона',
        name: 'Кремово-белый - Белые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/92061f48-b682-4409-8194-8d3368a053a4.jpg',
        code: 'RAL 9001',
        'target-key': 'code',
        title: 'Кремово-белый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Белые тона',
        name: 'Серо-белый - Белые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/92061f48-b682-4409-8194-8d3368a053a4.jpg',
        code: 'RAL 9002',
        'target-key': 'code',
        title: 'Серо-белый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Белые тона',
        name: 'Бело-алюминиевый - Белые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/b57d4ccb-0572-4bfa-8208-313928fb3a36.jpg',
        code: 'RAL 9006',
        'target-key': 'code',
        title: 'Бело-алюминиевый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Белые тона',
        name: 'Белый 2 - Белые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/9a78f575-87ff-4f47-90b4-ac14c6e7561d.jpg',
        code: 'RAL 9010',
        'target-key': 'code',
        title: 'Белый 2',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Белые тона',
        name: 'Транспортный белый - Белые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/2cd969d7-9280-4bd3-8161-49759cd4e980.jpg',
        code: 'RAL 9016',
        'target-key': 'code',
        title: 'Транспортный белый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Бежевые тона',
        name: 'Бежевый - Бежевые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/1f514ad8-d550-48bc-af95-6fa1ad180e1c.jpg',
        code: 'RAL 1001',
        'target-key': 'code',
        title: 'Бежевый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Бежевые тона',
        name: 'Песочно-желтый - Бежевые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/d787162f-f4a1-494f-b34f-22ccd9643220.jpg',
        code: 'RAL 1002',
        'target-key': 'code',
        title: 'Песочно-желтый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Бежевые тона',
        name: 'Слоновая кость - Бежевые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/1d42fadb-3898-405b-9dbb-8e2e7d3017cb.jpg',
        code: 'RAL 1014',
        'target-key': 'code',
        title: 'Слоновая кость',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Бежевые тона',
        name: 'Светлая слоновая кость - Бежевые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/78c4fdbf-909d-4cef-ad05-4a683796fc76.jpg',
        code: 'RAL 1015',
        'target-key': 'code',
        title: 'Светлая слоновая кость',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Бежевые тона',
        name: 'Серо-бежевый - Бежевые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/56c44bbb-5c0a-47c9-97fb-33646f913285.jpg',
        code: 'RAL 1019',
        'target-key': 'code',
        title: 'Серо-бежевый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Бежевые тона',
        name: 'Оливково-серый - Бежевые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/6d377ed7-8fa1-4eaa-ab49-92d26acb8dce.jpg',
        code: 'RAL 7002',
        'target-key': 'code',
        title: 'Оливково-серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Бежевые тона',
        name: 'Бежево-серый - Бежевые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/c0f939e1-3307-4181-b059-c60841733e4b.jpg',
        code: 'RAL 7006',
        'target-key': 'code',
        title: 'Бежево-серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Желтые тона',
        name: 'Сигнальный желтый - Желтые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/6ff02ce1-17b4-4eb3-8039-f367dc7f593c.jpg',
        code: 'RAL 1003',
        'target-key': 'code',
        title: 'Сигнальный желтый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Желтые тона',
        name: 'Золотисто-желтый - Желтые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/2d1f0499-d7e8-4e5c-a9b1-b69047cd2ac8.jpg',
        code: 'RAL 1004',
        'target-key': 'code',
        title: 'Золотисто-желтый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Желтые тона',
        name: 'Транспортный желтый - Желтые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/780fe8d3-71dc-42d6-be6f-cf5c878a6c1f.jpg',
        code: 'RAL 1023',
        'target-key': 'code',
        title: 'Транспортный желтый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Зеленые тона',
        name: 'Пихтовый зеленый - Зеленые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/a4e57612-ad38-45c9-83be-a5376a5a7010.jpg',
        code: 'RAL 6009',
        'target-key': 'code',
        title: 'Пихтовый зеленый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Зеленые тона',
        name: 'Черно-зеленый - Зеленые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/2ad8f07e-8212-4d34-afb3-a9f6f468d38d.jpg',
        code: 'RAL 6012',
        'target-key': 'code',
        title: 'Черно-зеленый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Зеленые тона',
        name: 'Желто-зеленый - Зеленые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/94ae95b3-8224-4441-9a89-97bc286baff6.jpg',
        code: 'RAL 6018',
        'target-key': 'code',
        title: 'Желто-зеленый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Зеленые тона',
        name: 'Транспортный зеленый - Зеленые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/9400e5c3-4b28-470d-9a1c-1180e08fd021.jpg',
        code: 'RAL 6024',
        'target-key': 'code',
        title: 'Транспортный зеленый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Зеленые тона',
        name: 'Мятно-бирюзовый - Зеленые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/1185a02d-e358-47b1-87f1-fc71edf76f15.jpg',
        code: 'RAL 6033',
        'target-key': 'code',
        title: 'Мятно-бирюзовый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Зеленые тона',
        name: 'Перламутрово-опалый зеленый - Зеленые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/6283cf4d-8cf1-49d1-ae4a-46ad85b1b059.jpg',
        code: 'RAL 6036',
        'target-key': 'code',
        title: 'Перламутрово-опалый зеленый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Зеленые тона',
        name: 'Зеленый - Зеленые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/1d89da84-137a-4ba2-ade1-89ed60b62035.jpg',
        code: 'RAL 6037',
        'target-key': 'code',
        title: 'Зеленый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Коричневые тона',
        name: 'Коричнево-бежевый - Коричневые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/1d9325c2-d09a-45d2-833c-748ae7ce1dd7.jpg',
        code: 'RAL 1011',
        'target-key': 'code',
        title: 'Коричнево-бежевый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Коричневые тона',
        name: 'Клиняный коричневый - Коричневые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/1d9325c2-d09a-45d2-833c-748ae7ce1dd7.jpg',
        code: 'RAL 8003',
        'target-key': 'code',
        title: 'Клиняный коричневый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Коричневые тона',
        name: 'Орехово-коричневый - Коричневые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/149742a9-d5d4-4b93-b7b6-6a96776f4042.jpg',
        code: 'RAL 8011',
        'target-key': 'code',
        title: 'Орехово-коричневый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Коричневые тона',
        name: 'Сепия коричневый - Коричневые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/4b08adee-49d0-455c-b323-91de22cdfe16.jpg',
        code: 'RAL 8014',
        'target-key': 'code',
        title: 'Сепия коричневый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Коричневые тона',
        name: 'Махагон коричневый - Коричневые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/e52d85b7-253a-4ece-9a4b-82806b728570.jpg',
        code: 'RAL 8016',
        'target-key': 'code',
        title: 'Махагон коричневый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Коричневые тона',
        name: 'Земельно-коричневый - Коричневые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/d55e2a5f-f8bd-499b-9472-18b0f9e136b4.jpg',
        code: 'RAL 8020',
        'target-key': 'code',
        title: 'Земельно-коричневый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Коричневые тона',
        name: 'Оранжево-коричневый - Коричневые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/40c7ba94-17d6-47b7-9e35-b5ee22415588.jpg',
        code: 'RAL 8023',
        'target-key': 'code',
        title: 'Оранжево-коричневый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Коричневые тона',
        name: 'Белево-коричневый - Коричневые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/037d4796-dd0f-4fc5-aede-9054395a9981.jpg',
        code: 'RAL 8024',
        'target-key': 'code',
        title: 'Белево-коричневый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Коричневые тона',
        name: 'Бледно-коричневый - Коричневые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/3d812ef0-1ca6-4362-8d59-369612f4b7da.jpg',
        code: 'RAL 8025',
        'target-key': 'code',
        title: 'Бледно-коричневый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Коричневые тона',
        name: 'Шоколадно-коричневый - Коричневые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/4467e1e4-2146-4e36-a217-b96737136668.jpg',
        code: 'RAL 8017',
        'target-key': 'code',
        title: 'Шоколадно-коричневый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Коричневые тона',
        name: 'Серо-коричневый - Коричневые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/f2ae1ad6-0d6d-4231-8fcc-b3fab69a3786.jpg',
        code: 'RAL 8019',
        'target-key': 'code',
        title: 'Серо-коричневый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Красные тона',
        name: 'Огненно красный - Красные тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/4092f3a3-8285-4efa-9cdd-853e3d17ce30.jpg',
        code: 'RAL 3000',
        'target-key': 'code',
        title: 'Огненно красный',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Красные тона',
        name: 'Карминно-красный - Красные тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/fcd9d597-0c9b-4249-86a2-efd7d1d24d75.jpg',
        code: 'RAL 3002',
        'target-key': 'code',
        title: 'Карминно-красный',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Красные тона',
        name: 'Винно-красный - Красные тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/fcd9d597-0c9b-4249-86a2-efd7d1d24d75.jpg',
        code: 'RAL 3002',
        'target-key': 'code',
        title: 'Винно-красный',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Красные тона',
        name: 'Светло-розовый - Красные тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/dce526e5-2ea1-4864-a693-9ca3b2b26e3b.jpg',
        code: 'RAL 3015',
        'target-key': 'code',
        title: 'Светло-розовый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Красные тона',
        name: 'Транспортный красный - Красные тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/91df9529-cc50-44df-9cd9-3426c5860c2c.jpg',
        code: 'RAL 3020',
        'target-key': 'code',
        title: 'Транспортный красный',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Красные тона',
        name: 'Транспортный красный - Красные тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/91df9529-cc50-44df-9cd9-3426c5860c2c.jpg',
        code: 'RAL 3015',
        'target-key': 'code',
        title: 'Транспортный красный',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Красные тона',
        name: 'Малиновый - Красные тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/24c7153f-d1b5-45a5-87c2-d1369283b69b.jpg',
        code: 'RAL 3027',
        'target-key': 'code',
        title: 'Малиновый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Красные тона',
        name: 'Красный - Красные тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/57b8e4c6-b439-4433-8f14-1496f95bd97d.jpg',
        code: 'RAL 3028',
        'target-key': 'code',
        title: 'Красный',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые тона',
        name: 'Серо-оливковый - Серые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/96b65ae7-0763-4f5c-ab75-6f534baecafa.jpg',
        code: 'RAL 6006',
        'target-key': 'code',
        title: 'Серо-оливковый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые тона',
        name: 'Сигнальный серый - Серые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/8bc6cb3c-bd37-43e9-a063-e1e01a7e5209.jpg',
        code: 'RAL 7004',
        'target-key': 'code',
        title: 'Сигнальный серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые тона',
        name: 'Железно-серый - Серые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/eda9a0d5-dfaa-4bbc-b1a4-f8a14041712b.jpg',
        code: 'RAL 7011',
        'target-key': 'code',
        title: 'Железно-серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые тона',
        name: 'Базальтово-серый - Серые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/00bb6c95-2092-4a89-bceb-d8d619fc69d9.jpg',
        code: 'RAL 7012',
        'target-key': 'code',
        title: 'Базальтово-серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые тона',
        name: 'Сланцево-серый - Серые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/61da3dd6-b6b9-4dae-b2e6-38dd4bae205e.jpg',
        code: 'RAL 7015',
        'target-key': 'code',
        title: 'Сланцево-серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые тона',
        name: 'Серая умба - Серые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/29346b5c-44a2-446c-8499-ea7bde85751b.jpg',
        code: 'RAL 7022',
        'target-key': 'code',
        title: 'Серая умба',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые тона',
        name: 'Серый бетон - Серые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/e300a554-f0d0-4a74-96ac-2364a0656682.jpg',
        code: 'RAL 7023',
        'target-key': 'code',
        title: 'Серый бетон',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые тона',
        name: 'Каменно-серый - Серые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/22d8095c-2fce-4106-a4ba-4f767128f853.jpg',
        code: 'RAL 7030',
        'target-key': 'code',
        title: 'Каменно-серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые тона',
        name: 'Светло-серый - Серые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/61900997-2aa8-421b-8fd8-4a5b08bcdb9c.jpg',
        code: 'RAL 7035',
        'target-key': 'code',
        title: 'Светло-серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые тона',
        name: 'Кварцевый серый - Серые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/f4061cb3-7774-4ad3-95fc-fc29b272c4bc.jpg',
        code: 'RAL 7039',
        'target-key': 'code',
        title: 'Кварцевый серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые тона',
        name: 'Транспортный серый - Серые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/2104c205-18c1-415f-9372-7c771d202db6.jpg',
        code: 'RAL 7042',
        'target-key': 'code',
        title: 'Транспортный серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые тона',
        name: 'Телегрей 4 - Серые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/80ef243b-350c-4e83-a088-6c712471fb1a.jpg',
        code: 'RAL 7047',
        'target-key': 'code',
        title: 'Телегрей 4',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые тона',
        name: 'Серо-алюминиевый - Серые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/d461ac95-d688-44d9-acd5-3bb5f9d9b44b.jpg',
        code: 'RAL 9007',
        'target-key': 'code',
        title: 'Серо-алюминиевый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые тона',
        name: 'Графитовый серый - Серые тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/76066fb6-b111-4007-8b50-3ae7ba04fcd5.jpg',
        code: 'RAL 7024',
        'target-key': 'code',
        title: 'Графитовый серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Синие тона',
        name: 'Сине-сиреневый - Синие тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/40bc410e-f06a-4d61-9cb9-6480307e5e33.jpg',
        code: 'RAL 4005',
        'target-key': 'code',
        title: 'Сине-сиреневый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Синие тона',
        name: 'Сапфирово-синий - Синие тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/40bc410e-f06a-4d61-9cb9-6480307e5e33.jpg',
        code: 'RAL 5003',
        'target-key': 'code',
        title: 'Сапфирово-синий',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Синие тона',
        name: 'Сигнальный синий - Синие тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/46b2bfa6-dae4-49dc-a5a7-80c4c8b93058.jpg',
        code: 'RAL 5005',
        'target-key': 'code',
        title: 'Сигнальный синий',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Синие тона',
        name: 'Генцианово-синий - Синие тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/9abe031c-2271-4a1b-9e90-1bdd8fd19958.jpg',
        code: 'RAL 5010',
        'target-key': 'code',
        title: 'Генцианово-синий',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Синие тона',
        name: 'Кабальтово-синий - Синие тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/f5aec715-796a-489a-b52c-b9a04b262e53.jpg',
        code: 'RAL 5013',
        'target-key': 'code',
        title: 'Кабальтово-синий',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Синие тона',
        name: 'Небесно-синий - Синие тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/f5aec715-796a-489a-b52c-b9a04b262e53.jpg',
        code: 'RAL 5015',
        'target-key': 'code',
        title: 'Небесно-синий',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Синие тона',
        name: 'Транспртный синий - Синие тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/b8b3c071-d201-4463-88ef-9a6b4a13fa49.jpg',
        code: 'RAL 5017',
        'target-key': 'code',
        title: 'Транспртный синий',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Синие тона',
        name: 'Водная синь - Синие тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/d112d0ce-8d11-4f59-9ab2-949c4c26be76.jpg',
        code: 'RAL 5021',
        'target-key': 'code',
        title: 'Водная синь',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Синие тона',
        name: 'Пастельно-синий - Синие тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/2055af81-30e9-48ca-8c4f-f26d3e97fd99.jpg',
        code: 'RAL 5024',
        'target-key': 'code',
        title: 'Пастельно-синий',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Черные тона',
        name: 'Черно-серый - Черные тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/ec81225b-9adb-4e9c-a9f5-20ed7ae809a6.jpg',
        code: 'RAL 7021',
        'target-key': 'code',
        title: 'Черно-серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Черные тона',
        name: 'Сигнальный черный - Черные тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/12e7a2be-85b2-447e-9b65-8bd413e5814a.jpg',
        code: 'RAL 9004',
        'target-key': 'code',
        title: 'Сигнальный черный',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Черные тона',
        name: 'Глубокий черный - Черные тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/d2d90fbd-f602-49ab-8b0c-9ccf6127de7d.jpg',
        code: 'RAL 9005',
        'target-key': 'code',
        title: 'Глубокий черный',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Черные тона',
        name: 'Графитовый черный - Черные тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/74613760-624c-4aff-a257-106c09236d65.jpg',
        code: 'RAL 9011',
        'target-key': 'code',
        title: 'Графитовый черный',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Черные тона',
        name: 'Антрацитово-серый - Черные тона - Цвет отлива',
        description: 'Цвет отлива',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/d006ebb1-59d7-45c2-8136-f6d2bfc821a5.jpg',
        code: 'RAL 7016',
        'target-key': 'code',
        title: 'Антрацитово-серый',
      },
    ],
  },
  'ebb-type-width': {
    type: 'number',
    code: 'ebb-type-width',
    title: 'Модель отлива',
    properties: {
      value: { type: 'number' },
    },
    enum: [{ value: 200 }, { value: 250 }, { value: 300 }],
  },
  'ebb-type': {
    type: 'object',
    code: 'ebb-type',
    title: 'Тип отлива',
    'reducer-specification': (props, state) =>
    
      props.filter(propRow => {
        let result = true
        //console.log('reducer-specification', propRow)
        if (result && propRow['target-key']) {
          result = propRow[propRow['target-key']] === state['ebb-type']
        }
        if (result) {
          result = !state['ebb-type-not-needed']
        }

        return result
      }),
    properties: {
      'target-key': { type: 'string' },
      code: {
        type: 'string',
      },
      description: {
        type: 'string',
      },
      title: {
        type: 'string',
      },
      value: {
        type: 'string',
      },
      price: {
        type: 'boolean',
      },
      sizes: {
        type: 'string',
      },
    },
    enum: [
      {
        code: 'simple',
        'target-key': 'code',
        description: 'Тип отлива',
        name: 'Обычный отлив, {{ebb-type-width}} / {{ebb-type-length}}, {{ebb-type-color/title}}',
        title: 'Обычный',
        value:
          'https://storage.yandexcloud.net/axioma/files/f871aee4-a141-422b-8481-9e6090b1ed4a.jpg',
        'is-order': true,
        price: true,
        sizes: '{{ebb-type-width}} x {{ebb-type-length}}',
      },
      {
        code: 'visor',
        'target-key': 'code',
        description: 'Тип отлива',
        name: 'Отлив-козырек, {{ebb-type-width}} / {{ebb-type-length}}, {{ebb-type-color/title}}',
        title: 'Отлив-козырек',
        value:
          'https://storage.yandexcloud.net/axioma/files/8fbf03ef-be66-4f8b-b814-2e77cc9d043a.jpg',
          'is-order': true,
        price: true,
        sizes: '{{ebb-type-width}} x {{ebb-type-length}}',
      },
    ],
  },
  'glass-unit-2': {
    type: 'object',
    code: 'glass-unit-2',
    title: 'Стеклопакет',
    'reducer-filter': (props, state) => props.filter(propRow => true),
    'reducer-specification': (props, state) => {
      return props.filter(propRow => {
        let result = true

        if (result && state['glass-unit-2']) {
          result = propRow['value'] === state['glass-unit-2']
        }

        return result
      })
    },
    properties: {
      'window-models': {
        type: 'string',
      },
      description: {
        type: 'string',
      },
      price: {
        type: 'boolean',
      },
      value: {
        type: 'string',
      },
    },
    enum: [
      {
        description: '4-16-4(24)',
        price: true,
        value: '4-16-4',
      },
      {
        description: '4-10-4-10-4',
        price: true,
        value: '4-10-4-10-4',
      },
      {
        description: '4-10-4-10-4И',
        price: true,
        value: '4-10-4-10-4И',
      },
      {
        description: '6-10-4-8-4',
        price: true,
        value: '6-10-4-8-4',
      },
      {
        description: '6-10-4-8-4',
        price: true,
        value: '6-10-4-8-4',
      },
      {
        description: '6-10-4-8-4И',
        price: true,
        value: '6-10-4-8-4И',
      },
      {
        description: '4CGS-16-4',
        price: true,
        value: '4CGS-16-4',
      },
      {
        description: '4CGS-10-4-10-4',
        price: true,
        value: '4CGS-10-4-10-4',
      },
      {
        description: '4CGS-10-4-10-4И',
        price: true,
        value: '4CGS-10-4-10-4И',
      },
      {
        description: '6CGS-10-4-8-4',
        price: true,
        value: '6CGS-10-4-8-4',
      },
      {
        description: '6CGS-10-4-8-4И',
        price: true,
        value: '6CGS-10-4-8-4И',
      },
      {
        description: '6CGS-10-4-8-4И',
        price: true,
        value: '6CGS-10-4-8-4И',
      },
      {
        description: '4CGS-10-4-10-4',
        price: true,
        value: '4CGS-10-4-10-4',
      },
      {
        description: '4-10-4-10-4',
        price: true,
        value: '4-10-4-10-4',
      },
      {
        description: '4-10-4-10-4И',
        price: true,
        value: '4-10-4-10-4И',
      },
      {
        description: '4-14ПВХAr-4-14ПВХAr-4И',
        price: true,
        value: '4-14ПВХAr-4-14ПВХAr-4И',
      },
      {
        description: '6-10-4-8-4',
        price: true,
        value: '6-10-4-8-4',
      },
      {
        description: '6-12ПВХ-4-12ПВХ-3.3.1',
        price: true,
        value: '6-12ПВХ-4-12ПВХ-3.3.1',
      },
      {
        description: '6-10-4-8-4И',
        price: true,
        value: '6-10-4-8-4И',
      },
      {
        description: '6И-12ПВХAr-4-12ПВХAr-3.3.1',
        price: true,
        value: '6И-12ПВХAr-4-12ПВХAr-3.3.1',
      },
      {
        description: '4CGS-10-4-10-4 ',
        price: true,
        value: '4CGS-10-4-10-4 ',
      },
      {
        description: '4CGS-10-4-10-4И',
        price: true,
        value: '4CGS-10-4-10-4И',
      },
      {
        description: '4CGS-14ПВХAr-4-14ПВХAr-4И',
        price: true,
        value: '4CGS-14ПВХAr-4-14ПВХAr-4И',
      },
      {
        description: '6CGS-10-4-8-4',
        price: true,
        value: '6CGS-10-4-8-4',
      },
      {
        description: '6CGS-12ПВХAr-4-12ПВХAr-3.3.1',
        price: true,
        value: '6CGS-12ПВХAr-4-12ПВХAr-3.3.1',
      },
      {
        description: '6CGS-10-4-8-4И',
        price: true,
        value: '6CGS-10-4-8-4И',
      },
      {
        description: '6CGS-12ПВХAr-4-12ПВХAr-3.3.1',
        price: true,
        value: '6CGS-12ПВХAr-4-12ПВХAr-3.3.1',
      },
      {
        description: '4CGS-14ПВХAr-4-14ПВХAr-4',
        price: true,
        value: '4CGS-14ПВХAr-4-14ПВХAr-4',
      },
      {
        description: '4-10-4-10-4-WK1',
        price: true,
        value: '4-10-4-10-4-WK1',
      },
      {
        description: '4-10-4-10-4И-WK1',
        price: true,
        value: '4-10-4-10-4И-WK1',
      },
      {
        description: '4-14ПВХAr-4-14ПВХAr-4И-WK1',
        price: true,
        value: '4-14ПВХAr-4-14ПВХAr-4И-WK1',
      },
      {
        description: '6-10-4-8-4-WK1',
        price: true,
        value: '6-10-4-8-4-WK1',
      },
      {
        description: '6-12ПВХ-4-12ПВХ-3.3.1-WK1',
        price: true,
        value: '6-12ПВХ-4-12ПВХ-3.3.1-WK1',
      },
      {
        description: '6-10-4-8-4И-WK1',
        price: true,
        value: '6-10-4-8-4И-WK1',
      },
      {
        description: '6И-12ПВХAr-4-12ПВХAr-3.3.1-WK1',
        price: true,
        value: '6И-12ПВХAr-4-12ПВХAr-3.3.1-WK1',
      },
      {
        description: '4CGS-10-4-10-4-WK1',
        price: true,
        value: '4CGS-10-4-10-4-WK1',
      },
      {
        description: '4CGS-10-4-10-4И-WK1',
        price: true,
        value: '4CGS-10-4-10-4И-WK1',
      },
      {
        description: '4CGS-14ПВХAr-4-14ПВХAr-4И-WK1',
        price: true,
        value: '4CGS-14ПВХAr-4-14ПВХAr-4И-WK1',
      },
      {
        description: '6CGS-10-4-8-4-WK1',
        price: true,
        value: '6CGS-10-4-8-4-WK1',
      },
      {
        description: '6CGS-12ПВХAr-4-12ПВХAr-3.3.1-WK1',
        price: true,
        value: '6CGS-12ПВХAr-4-12ПВХAr-3.3.1-WK1',
      },
      {
        description: '6CGS-10-4-8-4И-WK1',
        price: true,
        value: '6CGS-10-4-8-4И-WK1',
      },
      {
        description: '6CGS-12ПВХAr-4-12ПВХAr-3.3.1-WK1',
        price: true,
        value: '6CGS-12ПВХAr-4-12ПВХAr-3.3.1-WK1',
      },
      {
        description: '4CGS-14ПВХAr-4-14ПВХAr-4-WK1',
        price: true,
        value: '4CGS-14ПВХAr-4-14ПВХAr-4-WK1',
      },
      {
        description: '4-14-4-16-4',
        price: true,
        value: '4-14-4-16-4',
      },
      {
        description: '4-14ПВХAr-4-16ПВХAr-4И',
        price: true,
        value: '4-14ПВХAr-4-16ПВХAr-4И',
      },
      {
        description: '6-12ПВХAr-4-12ПВХAr-4.4.1',
        price: true,
        value: '6-12ПВХAr-4-12ПВХAr-4.4.1',
      },
      {
        description: '6И-12ПВХAr-4-12ПВХAr-4.4.1',
        price: true,
        value: '6И-12ПВХAr-4-12ПВХAr-4.4.1',
      },
      {
        description: '4CGS-14ПВХAr-4-16ПВХAr-4',
        price: true,
        value: '4CGS-14ПВХAr-4-16ПВХAr-4',
      },
      {
        description: '4CGS-14ПВХAr-4-16ПВХAr-4И',
        price: true,
        value: '4CGS-14ПВХAr-4-16ПВХAr-4И',
      },
      {
        description: '6CGS-12ПВХAr-4-12ПВХAr-4.4.1',
        price: true,
        value: '6CGS-12ПВХAr-4-12ПВХAr-4.4.1',
      },
      {
        description: '6CGS-12ПВХAr-4-12ПВХAr-4.4.1',
        price: true,
        value: '6CGS-12ПВХAr-4-12ПВХAr-4.4.1',
      },
      {
        description: '4CGS-14ПВХAr-4-16ПВХAr-4',
        price: true,
        value: '4CGS-14ПВХAr-4-16ПВХAr-4',
      },
      {
        description: '4-14-4-16-4-WK1',
        price: true,
        value: '4-14-4-16-4-WK1',
      },
      {
        description: '4-14ПВХAr-4-16ПВХAr-4И-WK1',
        price: true,
        value: '4-14ПВХAr-4-16ПВХAr-4И-WK1',
      },
      {
        description: '6-12ПВХAr-4-12ПВХAr-4.4.1-WK1',
        price: true,
        value: '6-12ПВХAr-4-12ПВХAr-4.4.1-WK1',
      },
      {
        description: '6И-12ПВХAr-4-12ПВХAr-4.4.1-WK1',
        price: true,
        value: '6И-12ПВХAr-4-12ПВХAr-4.4.1-WK1',
      },
      {
        description: '4CGS-14ПВХAr-4-16ПВХAr-4-WK1',
        price: true,
        value: '4CGS-14ПВХAr-4-16ПВХAr-4-WK1',
      },
      {
        description: '4CGS-14ПВХAr-4-16ПВХAr-4И-WK1',
        price: true,
        value: '4CGS-14ПВХAr-4-16ПВХAr-4И-WK1',
      },
      {
        description: '6CGS-12ПВХAr-4-12ПВХAr-4.4.1-WK1',
        price: true,
        value: '6CGS-12ПВХAr-4-12ПВХAr-4.4.1-WK1',
      },
      {
        description: '6CGS-12ПВХAr-4-12ПВХAr-4.4.1-WK1',
        price: true,
        value: '6CGS-12ПВХAr-4-12ПВХAr-4.4.1-WK1',
      },
    ],
  },
  'heat-saving': {
    type: 'object',
    code: 'heat-saving',
    title: 'Теплосбережение',
    properties: {
      description: { type: 'string' },
      'description-info': { type: 'string' },
      title: { type: 'string' },
      value: { type: 'number' },
      'value-title': { type: 'string' },
    },
    enum: [
      {
        description: 'Батареи греют хорошо. Тепло всегда',
        'description-info': 'Настройка уровня теплосбережение',
        title: 'Теплосбережение',
        value: 1,
        'value-title': 'Без сбережения',
      },
      {
        description: 'Около окна зимой холодно. Хочу теплее',
        'description-info': 'Настройка уровня теплосбережение',
        title: 'Теплосбережение',
        value: 2,
        'value-title': 'Среднее',
      },
      {
        description: 'Дует, зимой холодно. Батареи работают плохо',
        'description-info': 'Настройка уровня теплосбережение',
        title: 'Теплосбережение',
        value: 3,
        'value-title': 'Максимальное',
      },
    ],
  },
  'inner-slope-type-width': {
    type: 'object',
    code: 'inner-slope-type-width',
    title: 'inner-slope-type-width',
    properties: {
      value: { type: 'number' },
    },
    enum: [{ value: 200 }, { value: 250 }, { value: 300 }],
  },
  'inner-slope-type': {
    type: 'object',
    code: 'inner-slope-type',
    title: 'inner-slope-type',
    'reducer-specification': (props, state) =>
      props.filter(propRow => {
        let result = true

        if (result && propRow['target-key']) {
          result = propRow[propRow['target-key']] === state['inner-slope-type']
        }
        if (result) {
          result = !state['inner-slope-type-not-needed']
        }

        return result
      }),
    properties: {
      'target-key': { type: 'string' },
      code: { type: 'string' },
      description: { type: 'string' },
      name: { type: 'string' },
      'length-range': { type: 'string' },
      'is-order': { type: 'boolean' },
      price: { type: 'boolean' },
      title: { type: 'string' },
      value: { type: 'string' },
      sizes: { type: 'string' },
    },
    enum: [
      {
        code: 'pvh',
        'target-key': 'code',
        description: 'Тип внутреннего откоса',
        name:
          'Откос внутренний, ПВХ {{inner-slope-type-color/title}} {{inner-slope-type-width}} / {{inner-slope-type-length}}',
        'length-range': '2000~6000',
        'is-order': true,
        price: true,
        title: 'ПВХ',
        value:
          'https://storage.yandexcloud.net/axioma/files/05cd3840-c4e3-4b02-bb58-d9ff7c4a430d.jpg',
        sizes: '{{inner-slope-type-width}} x {{inner-slope-type-length}}',
      },
      {
        code: 'plaster',
        'target-key': 'code',
        description: 'Тип внутреннего откоса',
        name:
          'Откос внутренний, Штукатурка {{inner-slope-type-color/title}} {{inner-slope-type-width}} / {{inner-slope-type-length}}',
        'length-range': '2000~99999',
        'is-order': true,
        price: true,
        title: 'Штукатурка',
        value:
          'https://storage.yandexcloud.net/axioma/files/7247ea75-5abd-4890-8fe4-1e3c84c8581d.jpg',
        sizes: '{{inner-slope-type-width}} x {{inner-slope-type-length}}',
      },
    ],
  },
  'inner-slope-model': {
    type: 'object',
    code: 'inner-slope-model',
    title: 'inner-slope-model',
    properties: {
      description: { type: 'string' },
      title: { type: 'string' },
      value: { type: 'string' },
    },
    enum: [
      {
        description: 'Модель внутреннего откоса',
        title: 'Модель 1',
        value: 'inner-model-1',
      },
      {
        description: 'Модель внутреннего откоса',
        title: 'Модель 2',
        value: 'inner-model-2',
      },
      {
        description: 'Модель внутреннего откоса',
        title: 'Модель 3',
        value: 'inner-model-3',
      },
      {
        description: 'Модель внутреннего откоса',
        title: 'Модель 4',
        value: 'inner-model-4',
      },
    ],
  },
  'inner-slope-type-color': {
    type: 'object',
    code: 'inner-slope-type-color',
    title: 'inner-slope-type-color',
    'reducer-filter': (props, state) =>
      props.filter(propRow => {
        let result = true
        const models = propRow['window-models']
          ? propRow['window-models'].split(',').map(item => item.trim())
          : []

        if (models.length > 0) {
          result = models.includes(state['window-model'])
        }
        if (state[propRow['target-group']]) {
          result = state[propRow['target-group']] === propRow['target-group-value']
        }

        return result
      }),
    'reducer-specification': (props, state) =>
      props.filter(propRow => {
        let result = true
        const models = propRow['window-models']
          ? propRow['window-models'].split(',').map(item => item.trim())
          : []

        if (models.length > 0) {
          result = models.includes(state['window-model'])
        }
        if (result && propRow['target-key']) {
          result = propRow[propRow['target-key']] === state['inner-slope-type-color']
        }
        if (state['inner-slope-type-not-needed']) {
          result = false
        }

        return result
      }),
    properties: {
      'window-models': { type: 'string' },
      'target-key': { type: 'string' },
      'target-group': { type: 'string' },
      'target-group-value': { type: 'string' },
      code: { type: 'string' },
      price: { type: 'boolean' },
      title: { type: 'string' },
      value: { type: 'string' },
    },
    enum: [
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089z4b',
        'target-key': 'code',
        'target-group': 'inner-slope-type',
        'target-group-value': 'pvh',
        price: true,
        title: 'Белый',
        value:
          'https://storage.yandexcloud.net/axioma/files/218b974b-3fea-4576-a815-7abab3ad0d9d.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089z4mg',
        'target-key': 'code',
        'target-group': 'inner-slope-type',
        'target-group-value': 'pvh',
        price: true,
        title: 'Мрамор глянец',
        value:
          'https://storage.yandexcloud.net/axioma/files/9b174e79-6182-4248-8ee3-55575a799c45.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089z4mm',
        'target-key': 'code',
        'target-group': 'inner-slope-type',
        'target-group-value': 'pvh',
        price: true,
        title: 'Мрамор матовый',
        value:
          'https://storage.yandexcloud.net/axioma/files/d133f894-14bf-4854-8e1d-1caed20db1a4.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089z4mam',
        'target-key': 'code',
        'target-group': 'inner-slope-type',
        'target-group-value': 'pvh',
        price: true,
        title: 'Антрацит матовый',
        value:
          'https://storage.yandexcloud.net/axioma/files/b86939ed-c20f-44e0-81e1-ad9e694575f0.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089z4bg',
        'target-key': 'code',
        'target-group': 'inner-slope-type',
        'target-group-value': 'pvh',
        price: true,
        title: 'Белый глянец',
        value:
          'https://storage.yandexcloud.net/axioma/files/1f0443fb-eb33-4b90-8591-4a58cea506ce.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089z3c',
        'target-key': 'code',
        'target-group': 'inner-slope-type',
        'target-group-value': 'pvh',
        price: true,
        title: 'Кремовый',
        value:
          'https://storage.yandexcloud.net/axioma/files/a332c94f-9c33-4ab8-8243-805a58cf1645.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089z3vg',
        'target-key': 'code',
        'target-group': 'inner-slope-type',
        'target-group-value': 'pvh',
        price: true,
        title: 'Венге глянец',
        value:
          'https://storage.yandexcloud.net/axioma/files/9e37eb84-efb3-4db5-bf8d-8c4faf4900c0.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089z3vg',
        'target-key': 'code',
        'target-group': 'inner-slope-type',
        'target-group-value': 'pvh',
        price: true,
        title: 'Венге глянец',
        value:
          'https://storage.yandexcloud.net/axioma/files/9b3ea549-d17a-4e33-813c-00da9f6e639e.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089z3vm',
        'target-key': 'code',
        'target-group': 'inner-slope-type',
        'target-group-value': 'pvh',
        price: true,
        title: 'Венге матовый',
        value:
          'https://storage.yandexcloud.net/axioma/files/175bf6ce-d75b-4ab4-aa7b-7713884fad8b.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089i1wdg',
        'target-key': 'code',
        'target-group': 'inner-slope-type',
        'target-group-value': 'pvh',
        price: false,
        title: 'Белый дуб глянцевый',
        value:
          'https://storage.yandexcloud.net/axioma/files/5855be15-0534-40d3-870b-f145c8117ff7.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089z2wdm',
        'target-key': 'code',
        'target-group': 'inner-slope-type',
        'target-group-value': 'pvh',
        price: true,
        title: 'Белый дуб матовый',
        value:
          'https://storage.yandexcloud.net/axioma/files/d1d8fed7-0a46-46b7-bf96-bae77d30ee87.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089z2dgg',
        'target-key': 'code',
        'target-group': 'inner-slope-type',
        'target-group-value': 'pvh',
        price: true,
        title: 'Дуб золотой глянец',
        value:
          'https://storage.yandexcloud.net/axioma/files/cc6b4100-d15f-4e1c-bda8-4f5fa2efe0a5.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089z2dgm',
        'target-key': 'code',
        'target-group': 'inner-slope-type',
        'target-group-value': 'pvh',
        price: true,
        title: 'Дуб золотой матовый',
        value:
          'https://storage.yandexcloud.net/axioma/files/9417b607-cf03-4d85-9411-e83a29b21b15.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089z2dng',
        'target-key': 'code',
        'target-group': 'inner-slope-type',
        'target-group-value': 'pvh',
        price: true,
        title: 'Дуб натуральный глянец',
        value:
          'https://storage.yandexcloud.net/axioma/files/b4ae4023-55f7-4db1-9bd0-c6343f2e0443.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089z2dnm',
        'target-key': 'code',
        'target-group': 'inner-slope-type',
        'target-group-value': 'pvh',
        price: true,
        title: 'Дуб натуральный матовый',
        value:
          'https://storage.yandexcloud.net/axioma/files/5e60acdd-5ef1-4c3c-9727-3dd88c7ec1ea.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089z2og',
        'target-key': 'code',
        'target-group': 'inner-slope-type',
        'target-group-value': 'pvh',
        price: true,
        title: 'Орех глянец',
        value:
          'https://storage.yandexcloud.net/axioma/files/d32fcedc-6996-471c-9787-541ed5128f1a.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089z2om',
        'target-key': 'code',
        'target-group': 'inner-slope-type',
        'target-group-value': 'pvh',
        price: true,
        title: 'Орех матовый',
        value:
          'https://storage.yandexcloud.net/axioma/files/ca109068-708e-43b7-850e-e94dd6d936ec.jpg',
      },
    ],
  },
  'model-inside-color': {
    type: 'object',
    code: 'model-inside-color',
    title: 'model-inside-color',
    'reducer-filter': (props, state) =>
      props.filter(propRow => {
        let result = true
        const models = propRow['window-models']
          ? propRow['window-models'].split(',').map(item => item.trim())
          : []

        if (models.length > 0) {
          result = models.includes(state['window-model'])
        }

        return result
      }),
    'reducer-specification': (props, state) =>
      props.filter(propRow => {
        let result = true
        const models = propRow['window-models']
          ? propRow['window-models'].split(',').map(item => item.trim())
          : []

        if (models.length > 0) {
          result = models.includes(state['window-model'])
        }
        if (result && propRow['target-key']) {
          result = propRow[propRow['target-key']] === state['model-inside-color']
        }

        return result
      }),
    properties: {
      'window-models': { type: 'string' },
      'target-key': { type: 'string' },
      value: { type: 'string' },
      code: { type: 'string' },
      collection: { type: 'string' },
      name: { type: 'string' },
      description: { type: 'string' },
      'is-order': { type: 'boolean' },
      price: { type: 'boolean' },
      title: { type: 'string' },
    },
    enum: [
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Белая',
        name: 'Белый базовый без ламинации - Белая - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/657bcde4-9ee0-42a0-bc36-4501aa36c6fa.jpg',
        code: '-',
        'target-key': 'code',
        title: 'Белый базовый без ламинации',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Белая',
        name: 'Белый ламинация - Белая - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/3e0b49fa-6fe6-44e1-9df4-8fb66ca5c2b0.jpg',
        code: '9152',
        'target-key': 'code',
        title: 'Белый ламинация',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Под дерево',
        name: 'Горная сосна - Под дерево - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/0a6affbf-2af9-4c55-b761-2c22085ecfd9.png',
        code: '9.3069041-116700',
        'target-key': 'code',
        title: 'Горная сосна',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Под дерево',
        name: 'Дуб ST-G - Под дерево - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/00f04639-eb63-4e8f-a987-d930e7cce609.png',
        code: '9.3156003-116700',
        'target-key': 'code',
        title: 'Дуб ST-G',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Под дерево',
        name: 'Золотой дуб - Под дерево - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/88b516db-ebbb-47f4-87a6-71eebea00f0f.png',
        code: '9.2178001-116700',
        'target-key': 'code',
        title: 'Золотой дуб',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Под дерево',
        name: 'Ирландский дуб - Под дерево - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/0537020c-81d3-48de-9b4f-b602dc18e596.png',
        code: '9.3211005-116700',
        'target-key': 'code',
        title: 'Ирландский дуб',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Под дерево',
        name: 'Натуральный дуб - Под дерево - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/7ebda78b-cee1-4615-9f01-e372abc5a689.png',
        code: '9.3118076-11',
        'target-key': 'code',
        title: 'Натуральный дуб',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Под дерево',
        name: 'Нежная вишня - Под дерево - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/0d6d0bc0-fa31-4288-94dd-f6c8798dedc8.png',
        code: '9.3214009-119500',
        'target-key': 'code',
        title: 'Нежная вишня',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Под дерево',
        name: 'Полосатый дуглас - Под дерево - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/894ba817-ec41-43e9-b516-a03fc4ebe524.png',
        code: '9.3152009-116700',
        'target-key': 'code',
        title: 'Полосатый дуглас',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Под дерево',
        name: 'Рустикальная вишня - Под дерево - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/85c2a280-ed67-425e-9574-d516f3f0d5b3.png',
        code: '9.32140007-119500',
        'target-key': 'code',
        title: 'Рустикальная вишня',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Под дерево',
        name: 'Светлый дуб - Под дерево - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/3da1a84c-4fef-44fe-948d-29128aac2294.png',
        code: '9.2052090-116700',
        'target-key': 'code',
        title: 'Светлый дуб',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые',
        name: 'Агатовый серый - Серые - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/a5f52d84-874e-4252-85f4-ff130534f419.png',
        code: '703805-116700',
        'target-key': 'code',
        title: 'Агатовый серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые',
        name: 'Антрацитово-серый - Серые - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/4191f2ed-a45c-4f1d-8a45-634b0f1a511c.png',
        code: '701605-116700',
        'target-key': 'code',
        title: 'Антрацитово-серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые',
        name: 'Кварцевый серый - Серые - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/2bdf5889-1c35-4fb0-8bc1-a725ed9ff1bf.png',
        code: '703905-116700',
        'target-key': 'code',
        title: 'Кварцевый серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые',
        name: 'Льтовый серый - Серые - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/35fb7ab3-4f33-419b-befb-dca3db8e888d.png',
        code: '701205-116700',
        'target-key': 'code',
        title: 'Льтовый серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые',
        name: 'Платиновое серебро - Серые - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/523d9e3b-4567-45ab-a577-2e9f450c8bc1.png',
        code: '9.1293003-119500',
        'target-key': 'code',
        title: 'Платиновое серебро',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые',
        name: 'Светло-серый - Серые - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/2bc2558a-748c-4984-83e8-e67e17a2518c.png',
        code: '725105-808300',
        'target-key': 'code',
        title: 'Светло-серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые',
        name: 'Серый - Серые - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/03f4101d-49d2-4301-90c8-f70c210585d2.png',
        code: '715505-116700',
        'target-key': 'code',
        title: 'Серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые',
        name: 'Сигнальный серый - Серые - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/d1c751d7-1368-4211-a5d8-de0329bcab2f.png',
        code: '700405-808300',
        'target-key': 'code',
        title: 'Сигнальный серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Темно-коричневые',
        name: 'Макоре - Темно-коричневые - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/a85b12a2-0693-40e8-859a-9ebaa7699ab0.png',
        code: '9.3162002-116700',
        'target-key': 'code',
        title: 'Макоре',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Темно-коричневые',
        name: 'Махагон - Темно-коричневые - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/eef61aa4-414f-4351-adaf-795aeab8eee2.png',
        code: '9.2097013-116700',
        'target-key': 'code',
        title: 'Махагон',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Темно-коричневые',
        name: 'Меорный дуб - Темно-коричневые - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/1764adf4-105f-4fa9-ba2e-bac0c1e04a51.png',
        code: '9.3167004-116700',
        'target-key': 'code',
        title: 'Меорный дуб',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Темно-коричневые',
        name: 'Орех - Темно-коричневые - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/54cd1bbf-a247-402d-8fb6-2458369d3f5b.png',
        code: '9.2178007-116700',
        'target-key': 'code',
        title: 'Орех',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Темно-коричневые',
        name: 'Рустикальный дуб - Темно-коричневые - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/93cd0eca-8b5e-4ed2-9002-0e48faf35669.png',
        code: '9.3149008-116700',
        'target-key': 'code',
        title: 'Рустикальный дуб',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Темно-коричневые',
        name: 'Сапели - Темно-коричневые - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/cb2d5b1a-db10-470a-93bf-9c05d4ffc764.png',
        code: '9.2065021-116700',
        'target-key': 'code',
        title: 'Сапели',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Темно-коричневые',
        name: 'Темный дуб - Темно-коричневые - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/cae600b4-877d-4b58-b461-c2aa6d917c89.png',
        code: '9.2052089-116700',
        'target-key': 'code',
        title: 'Темный дуб',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Темно-коричневые',
        name: 'Цветущая вишня - Темно-коричневые - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/028c5644-d187-4761-b332-22603ea7eeb8.png',
        code: '9.3214007-119500',
        'target-key': 'code',
        title: 'Цветущая вишня',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Темно-коричневые',
        name: 'Черная вишня - Темно-коричневые - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/add64da1-fd9c-4339-9c8a-434912366cda.png',
        code: '9.9202001-116700',
        'target-key': 'code',
        title: 'Черная вишня',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Черные',
        name: 'Черно-коричневый - Черные - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/d6124188-eccd-4a0b-8228-98ec891883b9.png',
        code: '851805-116700',
        'target-key': 'code',
        title: 'Черно-коричневый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Черные',
        name: 'Шоколадно-коричневый - Черные - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/01803e05-60e4-4f65-b4db-5a1f9a1d5daf.png',
        code: '887505-116700',
        'target-key': 'code',
        title: 'Шоколадно-коричневый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Яркая коллекция',
        name: 'Бриллиантово-синий - Яркая коллекция - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/d7219502-005e-4312-87d5-fb5ea3a21ad6.png',
        code: '500705-116700',
        'target-key': 'code',
        title: 'Бриллиантово-синий',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Яркая коллекция',
        name: 'Винно-красный - Яркая коллекция - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/eaa701f8-d74c-4592-949b-33fca8f73caf.png',
        code: '300505-116700',
        'target-key': 'code',
        title: 'Винно-красный',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Яркая коллекция',
        name: 'Зеленый мох - Яркая коллекция - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/b492a606-d299-4ae2-aa3a-89ebf711a2e8.png',
        code: '600505-116700',
        'target-key': 'code',
        title: 'Зеленый мох',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Яркая коллекция',
        name: 'Орегон - Яркая коллекция - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/ca3b0e58-cf3d-4af0-a55f-260bbfd05816.png',
        code: '9.1192001-116700',
        'target-key': 'code',
        title: 'Орегон',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Яркая коллекция',
        name: 'Стальной синий - Яркая коллекция - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/40b1224d-f716-4e04-8a0f-dd411ecdc940.png',
        code: '515005-116700',
        'target-key': 'code',
        title: 'Стальной синий',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Яркая коллекция',
        name: 'Темно-зеленый - Яркая коллекция - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/d0a2f125-594c-48da-b177-90897dd8a3be.png',
        code: '612505-116700',
        'target-key': 'code',
        title: 'Темно-зеленый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Яркая коллекция',
        name: 'Темно-красный - Яркая коллекция - Цвет окна с внутренней стороны',
        description: 'Цвет окна с внутренней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/49a3fbd3-d468-4a8a-aca6-3866bef8f27e.png',
        code: '308105-116700',
        'target-key': 'code',
        title: 'Темно-красный',
      },
    ],
  },
  'model-outside-color': {
    type: 'object',
    code: 'model-outside-color',
    title: 'model-outside-color',
    'reducer-filter': (props, state) =>
      props.filter(propRow => {
        let result = true
        const models = propRow['window-models']
          ? propRow['window-models'].split(',').map(item => item.trim())
          : []

        if (models.length > 0) {
          result = models.includes(state['window-model'])
        }

        return result
      }),
    'reducer-specification': (props, state) =>
      props.filter(propRow => {
        let result = true
        const models = propRow['window-models']
          ? propRow['window-models'].split(',').map(item => item.trim())
          : []

        if (models.length > 0) {
          result = models.includes(state['window-model'])
        }
        if (result && propRow['target-key']) {
          result = propRow[propRow['target-key']] === state['model-outside-color']
        }

        return result
      }),
    properties: {
      'window-models': { type: 'string' },
      'target-key': { type: 'string' },
      value: { type: 'string' },
      code: { type: 'string' },
      collection: { type: 'string' },
      name: { type: 'string' },
      description: { type: 'string' },
      'is-order': { type: 'boolean' },
      price: { type: 'boolean' },
      title: { type: 'string' },
    },
    enum: [
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Белая',
        name: 'Белый базовый без ламинации - Белая - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/657bcde4-9ee0-42a0-bc36-4501aa36c6fa.jpg',
        code: '-',
        'target-key': 'code',
        title: 'Белый базовый без ламинации',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Белая',
        name: 'Белая ламинация - Белая - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/3e0b49fa-6fe6-44e1-9df4-8fb66ca5c2b0.jpg',
        code: '9152',
        'target-key': 'code',
        title: 'Белая ламинация',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Под дерево',
        name: 'Горная сосна - Под дерево - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/0a6affbf-2af9-4c55-b761-2c22085ecfd9.png',
        code: '9.3069041-116700',
        'target-key': 'code',
        title: 'Горная сосна',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Под дерево',
        name: 'Дуб ST-G - Под дерево - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/00f04639-eb63-4e8f-a987-d930e7cce609.png',
        code: '9.3156003-116700',
        'target-key': 'code',
        title: 'Дуб ST-G',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Под дерево',
        name: 'Золотой дуб - Под дерево - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/88b516db-ebbb-47f4-87a6-71eebea00f0f.png',
        code: '9.2178001-116700',
        'target-key': 'code',
        title: 'Золотой дуб',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Под дерево',
        name: 'Ирландский дуб - Под дерево - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/0537020c-81d3-48de-9b4f-b602dc18e596.png',
        code: '9.3211005-116700',
        'target-key': 'code',
        title: 'Ирландский дуб',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Под дерево',
        name: 'Натуральный дуб - Под дерево - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/7ebda78b-cee1-4615-9f01-e372abc5a689.png',
        code: '9.3118076-11',
        'target-key': 'code',
        title: 'Натуральный дуб',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Под дерево',
        name: 'Нежная вишня - Под дерево - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/0d6d0bc0-fa31-4288-94dd-f6c8798dedc8.png',
        code: '9.3214009-119500',
        'target-key': 'code',
        title: 'Нежная вишня',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Под дерево',
        name: 'Полосатый дуглас - Под дерево - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/894ba817-ec41-43e9-b516-a03fc4ebe524.png',
        code: '9.3152009-116700',
        'target-key': 'code',
        title: 'Полосатый дуглас',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Под дерево',
        name: 'Рустикальная вишня - Под дерево - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/85c2a280-ed67-425e-9574-d516f3f0d5b3.png',
        code: '9.32140007-119500',
        'target-key': 'code',
        title: 'Рустикальная вишня',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Под дерево',
        name: 'Светлый дуб - Под дерево - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/3da1a84c-4fef-44fe-948d-29128aac2294.png',
        code: '9.2052090-116700',
        'target-key': 'code',
        title: 'Светлый дуб',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые',
        name: 'Агатовый серый - Серые - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/a5f52d84-874e-4252-85f4-ff130534f419.png',
        code: '703805-116700',
        'target-key': 'code',
        title: 'Агатовый серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые',
        name: 'Антрацитово-серый - Серые - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/4191f2ed-a45c-4f1d-8a45-634b0f1a511c.png',
        code: '701605-116700',
        'target-key': 'code',
        title: 'Антрацитово-серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые',
        name: 'Кварцевый серый - Серые - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/2bdf5889-1c35-4fb0-8bc1-a725ed9ff1bf.png',
        code: '703905-116700',
        'target-key': 'code',
        title: 'Кварцевый серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые',
        name: 'Льтовый серый - Серые - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/35fb7ab3-4f33-419b-befb-dca3db8e888d.png',
        code: '701205-116700',
        'target-key': 'code',
        title: 'Льтовый серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые',
        name: 'Платиновое серебро - Серые - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/523d9e3b-4567-45ab-a577-2e9f450c8bc1.png',
        code: '9.1293003-119500',
        'target-key': 'code',
        title: 'Платиновое серебро',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые',
        name: 'Светло-серый - Серые - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/2bc2558a-748c-4984-83e8-e67e17a2518c.png',
        code: '725105-808300',
        'target-key': 'code',
        title: 'Светло-серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые',
        name: 'Серый - Серые - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/03f4101d-49d2-4301-90c8-f70c210585d2.png',
        code: '715505-116700',
        'target-key': 'code',
        title: 'Серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые',
        name: 'Сигнальный серый - Серые - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/d1c751d7-1368-4211-a5d8-de0329bcab2f.png',
        code: '700405-808300',
        'target-key': 'code',
        title: 'Сигнальный серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Темно-коричневые',
        name: 'Макоре - Темно-коричневые - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/a85b12a2-0693-40e8-859a-9ebaa7699ab0.png',
        code: '9.3162002-116700',
        'target-key': 'code',
        title: 'Макоре',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Темно-коричневые',
        name: 'Махагон - Темно-коричневые - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/eef61aa4-414f-4351-adaf-795aeab8eee2.png',
        code: '9.2097013-116700',
        'target-key': 'code',
        title: 'Махагон',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Темно-коричневые',
        name: 'Меорный дуб - Темно-коричневые - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/1764adf4-105f-4fa9-ba2e-bac0c1e04a51.png',
        code: '9.3167004-116700',
        'target-key': 'code',
        title: 'Меорный дуб',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Темно-коричневые',
        name: 'Орех - Темно-коричневые - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/54cd1bbf-a247-402d-8fb6-2458369d3f5b.png',
        code: '9.2178007-116700',
        'target-key': 'code',
        title: 'Орех',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Темно-коричневые',
        name: 'Рустикальный дуб - Темно-коричневые - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/93cd0eca-8b5e-4ed2-9002-0e48faf35669.png',
        code: '9.3149008-116700',
        'target-key': 'code',
        title: 'Рустикальный дуб',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Темно-коричневые',
        name: 'Сапели - Темно-коричневые - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/cb2d5b1a-db10-470a-93bf-9c05d4ffc764.png',
        code: '9.2065021-116700',
        'target-key': 'code',
        title: 'Сапели',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Темно-коричневые',
        name: 'Темный дуб - Темно-коричневые - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/cae600b4-877d-4b58-b461-c2aa6d917c89.png',
        code: '9.2052089-116700',
        'target-key': 'code',
        title: 'Темный дуб',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Темно-коричневые',
        name: 'Цветущая вишня - Темно-коричневые - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/028c5644-d187-4761-b332-22603ea7eeb8.png',
        code: '9.3214007-119500',
        'target-key': 'code',
        title: 'Цветущая вишня',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Темно-коричневые',
        name: 'Черная вишня - Темно-коричневые - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/add64da1-fd9c-4339-9c8a-434912366cda.png',
        code: '9.9202001-116700',
        'target-key': 'code',
        title: 'Черная вишня',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Черные',
        name: 'Черно-коричневый - Черные - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/d6124188-eccd-4a0b-8228-98ec891883b9.png',
        code: '851805-116700',
        'target-key': 'code',
        title: 'Черно-коричневый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Черные',
        name: 'Шоколадно-коричневый - Черные - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/01803e05-60e4-4f65-b4db-5a1f9a1d5daf.png',
        code: '887505-116700',
        'target-key': 'code',
        title: 'Шоколадно-коричневый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Яркая коллекция',
        name: 'Бриллиантово-синий - Яркая коллекция - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/d7219502-005e-4312-87d5-fb5ea3a21ad6.png',
        code: '500705-116700',
        'target-key': 'code',
        title: 'Бриллиантово-синий',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Яркая коллекция',
        name: 'Винно-красный - Яркая коллекция - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/eaa701f8-d74c-4592-949b-33fca8f73caf.png',
        code: '300505-116700',
        'target-key': 'code',
        title: 'Винно-красный',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Яркая коллекция',
        name: 'Зеленый мох - Яркая коллекция - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/b492a606-d299-4ae2-aa3a-89ebf711a2e8.png',
        code: '600505-116700',
        'target-key': 'code',
        title: 'Зеленый мох',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Яркая коллекция',
        name: 'Орегон - Яркая коллекция - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/ca3b0e58-cf3d-4af0-a55f-260bbfd05816.png',
        code: '9.1192001-116700',
        'target-key': 'code',
        title: 'Орегон',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Яркая коллекция',
        name: 'Стальной синий - Яркая коллекция - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/40b1224d-f716-4e04-8a0f-dd411ecdc940.png',
        code: '515005-116700',
        'target-key': 'code',
        title: 'Стальной синий',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Яркая коллекция',
        name: 'Темно-зеленый - Яркая коллекция - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/d0a2f125-594c-48da-b177-90897dd8a3be.png',
        code: '612505-116700',
        'target-key': 'code',
        title: 'Темно-зеленый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Яркая коллекция',
        name: 'Темно-красный - Яркая коллекция - Цвет окна с внешней стороны',
        description: 'Цвет окна с внешней стороны',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/49a3fbd3-d468-4a8a-aca6-3866bef8f27e.png',
        code: '308105-116700',
        'target-key': 'code',
        title: 'Темно-красный',
      },
    ],
  },
  'model-profile-color': {
    type: 'object',
    code: 'model-profile-color',
    title: 'model-profile-color',
    'reducer-filter': (props, state) =>
      props.filter(propRow => {
        let result = true
        const models = propRow['window-models']
          ? propRow['window-models'].split(',').map(item => item.trim())
          : []

        if (models.length > 0) {
          result = models.includes(state['window-model'])
        }

        return result
      }),
    'reducer-specification': (props, state) =>
      props.filter(propRow => {
        let result = true
        const models = propRow['window-models']
          ? propRow['window-models'].split(',').map(item => item.trim())
          : []

        if (models.length > 0) {
          result = models.includes(state['window-model'])
        }
        if (result && propRow['target-key']) {
          result = propRow[propRow['target-key']] === state['model-profile-color']
        }

        return result
      }),
    properties: {
      'window-models': { type: 'string' },
      'target-key': { type: 'string' },
      value: { type: 'string' },
      code: { type: 'string' },
      collection: { type: 'string' },
      name: { type: 'string' },
      description: { type: 'string' },
      'is-order': { type: 'boolean' },
      price: { type: 'boolean' },
      title: { type: 'string' },
    },
    enum: [
      {
        'window-models': 'model-1, model-2, model-3',
        collection: '',
        name: 'Белый - Цвет профиля окна',
        description: 'Цвет профиля окна',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/53276f91-28c8-4a0f-ab34-1ba598f92fab.jpg',
        code: '2052089wop',
        'target-key': 'code',
        title: 'Белый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: '',
        name: 'Коричневый - Цвет профиля окна',
        description: 'Цвет профиля окна',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/a4a6158d-1e28-488d-85ad-bf89f92c9df5.jpg',
        code: '2052089gop',
        'target-key': 'code',
        title: 'Коричневый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: '',
        name: 'Серый - Цвет профиля окна',
        description: 'Цвет профиля окна',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/97215419-8319-4e9d-8638-177a15fb31dd.jpg',
        code: '2052089bop',
        'target-key': 'code',
        title: 'Серый',
      },
    ],
  },
  'mosquito-type-color': {
    type: 'object',
    code: 'mosquito-type-color',
    title: 'mosquito-type-color',
    'reducer-filter': (props, state) =>
      props.filter(propRow => {
        let result = true
        const models = propRow['window-models']
          ? propRow['window-models'].split(',').map(item => item.trim())
          : []

        if (models.length > 0) {
          result = models.includes(state['window-model'])
        }

        return result
      }),
    'reducer-specification': (props, state) =>
      props.filter(propRow => {
        let result = true
        const models = propRow['window-models']
          ? propRow['window-models'].split(',').map(item => item.trim())
          : []

        if (models.length > 0) {
          result = models.includes(state['window-model'])
        }
        if (result) {
          result = !state['mosquito-type-not-needed']
        }
        if (result && propRow['target-key']) {
          result = propRow[propRow['target-key']] === state['mosquito-type-color']
        }

        return result
      }),
    properties: {
      'window-models': { type: 'string' },
      'target-key': { type: 'string' },
      value: { type: 'string' },
      code: { type: 'string' },
      price: { type: 'boolean' },
      title: { type: 'string' },
    },
    enum: [
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Белые тона',
        name: 'Белый - Белые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/cf372b66-53a9-4419-9b97-cbdea01b8ce2.jpg',
        code: 'RAL 9010',
        'target-key': 'code',
        title: 'Белый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Белые тона',
        name: 'Жемчужно-белый - Белые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/6cc06dc6-e3c2-4ad7-a6fb-9874b04a84a4.jpg',
        code: 'RAL 1013',
        'target-key': 'code',
        title: 'Жемчужно-белый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Белые тона',
        name: 'Кремово-белый - Белые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/92061f48-b682-4409-8194-8d3368a053a4.jpg',
        code: 'RAL 9001',
        'target-key': 'code',
        title: 'Кремово-белый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Белые тона',
        name: 'Серо-белый - Белые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/92061f48-b682-4409-8194-8d3368a053a4.jpg',
        code: 'RAL 9002',
        'target-key': 'code',
        title: 'Серо-белый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Белые тона',
        name: 'Бело-алюминиевый - Белые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/b57d4ccb-0572-4bfa-8208-313928fb3a36.jpg',
        code: 'RAL 9006',
        'target-key': 'code',
        title: 'Бело-алюминиевый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Белые тона',
        name: 'Белый 2 - Белые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/9a78f575-87ff-4f47-90b4-ac14c6e7561d.jpg',
        code: 'RAL 9010',
        'target-key': 'code',
        title: 'Белый 2',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Белые тона',
        name: 'Транспортный белый - Белые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/2cd969d7-9280-4bd3-8161-49759cd4e980.jpg',
        code: 'RAL 9016',
        'target-key': 'code',
        title: 'Транспортный белый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Бежевые тона',
        name: 'Бежевый - Бежевые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/1f514ad8-d550-48bc-af95-6fa1ad180e1c.jpg',
        code: 'RAL 1001',
        'target-key': 'code',
        title: 'Бежевый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Бежевые тона',
        name: 'Песочно-желтый - Бежевые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/d787162f-f4a1-494f-b34f-22ccd9643220.jpg',
        code: 'RAL 1002',
        'target-key': 'code',
        title: 'Песочно-желтый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Бежевые тона',
        name: 'Слоновая кость - Бежевые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/1d42fadb-3898-405b-9dbb-8e2e7d3017cb.jpg',
        code: 'RAL 1014',
        'target-key': 'code',
        title: 'Слоновая кость',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Бежевые тона',
        name: 'Светлая слоновая кость - Бежевые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/78c4fdbf-909d-4cef-ad05-4a683796fc76.jpg',
        code: 'RAL 1015',
        'target-key': 'code',
        title: 'Светлая слоновая кость',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Бежевые тона',
        name: 'Серо-бежевый - Бежевые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/56c44bbb-5c0a-47c9-97fb-33646f913285.jpg',
        code: 'RAL 1019',
        'target-key': 'code',
        title: 'Серо-бежевый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Бежевые тона',
        name: 'Оливково-серый - Бежевые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/6d377ed7-8fa1-4eaa-ab49-92d26acb8dce.jpg',
        code: 'RAL 7002',
        'target-key': 'code',
        title: 'Оливково-серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Бежевые тона',
        name: 'Бежево-серый - Бежевые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/c0f939e1-3307-4181-b059-c60841733e4b.jpg',
        code: 'RAL 7006',
        'target-key': 'code',
        title: 'Бежево-серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Желтые тона',
        name: 'Сигнальный желтый - Желтые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/6ff02ce1-17b4-4eb3-8039-f367dc7f593c.jpg',
        code: 'RAL 1003',
        'target-key': 'code',
        title: 'Сигнальный желтый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Желтые тона',
        name: 'Золотисто-желтый - Желтые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/2d1f0499-d7e8-4e5c-a9b1-b69047cd2ac8.jpg',
        code: 'RAL 1004',
        'target-key': 'code',
        title: 'Золотисто-желтый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Желтые тона',
        name: 'Транспортный желтый - Желтые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/780fe8d3-71dc-42d6-be6f-cf5c878a6c1f.jpg',
        code: 'RAL 1023',
        'target-key': 'code',
        title: 'Транспортный желтый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Зеленые тона',
        name: 'Пихтовый зеленый - Зеленые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/a4e57612-ad38-45c9-83be-a5376a5a7010.jpg',
        code: 'RAL 6009',
        'target-key': 'code',
        title: 'Пихтовый зеленый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Зеленые тона',
        name: 'Черно-зеленый - Зеленые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/2ad8f07e-8212-4d34-afb3-a9f6f468d38d.jpg',
        code: 'RAL 6012',
        'target-key': 'code',
        title: 'Черно-зеленый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Зеленые тона',
        name: 'Желто-зеленый - Зеленые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/94ae95b3-8224-4441-9a89-97bc286baff6.jpg',
        code: 'RAL 6018',
        'target-key': 'code',
        title: 'Желто-зеленый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Зеленые тона',
        name: 'Транспортный зеленый - Зеленые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/9400e5c3-4b28-470d-9a1c-1180e08fd021.jpg',
        code: 'RAL 6024',
        'target-key': 'code',
        title: 'Транспортный зеленый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Зеленые тона',
        name: 'Мятно-бирюзовый - Зеленые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/1185a02d-e358-47b1-87f1-fc71edf76f15.jpg',
        code: 'RAL 6033',
        'target-key': 'code',
        title: 'Мятно-бирюзовый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Зеленые тона',
        name: 'Перламутрово-опалый зеленый - Зеленые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/6283cf4d-8cf1-49d1-ae4a-46ad85b1b059.jpg',
        code: 'RAL 6036',
        'target-key': 'code',
        title: 'Перламутрово-опалый зеленый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Зеленые тона',
        name: 'Зеленый - Зеленые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/1d89da84-137a-4ba2-ade1-89ed60b62035.jpg',
        code: 'RAL 6037',
        'target-key': 'code',
        title: 'Зеленый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Коричневые тона',
        name: 'Коричнево-бежевый - Коричневые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/1d9325c2-d09a-45d2-833c-748ae7ce1dd7.jpg',
        code: 'RAL 1011',
        'target-key': 'code',
        title: 'Коричнево-бежевый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Коричневые тона',
        name: 'Клиняный коричневый - Коричневые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/1d9325c2-d09a-45d2-833c-748ae7ce1dd7.jpg',
        code: 'RAL 8003',
        'target-key': 'code',
        title: 'Клиняный коричневый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Коричневые тона',
        name: 'Орехово-коричневый - Коричневые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/149742a9-d5d4-4b93-b7b6-6a96776f4042.jpg',
        code: 'RAL 8011',
        'target-key': 'code',
        title: 'Орехово-коричневый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Коричневые тона',
        name: 'Сепия коричневый - Коричневые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/4b08adee-49d0-455c-b323-91de22cdfe16.jpg',
        code: 'RAL 8014',
        'target-key': 'code',
        title: 'Сепия коричневый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Коричневые тона',
        name: 'Махагон коричневый - Коричневые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/e52d85b7-253a-4ece-9a4b-82806b728570.jpg',
        code: 'RAL 8016',
        'target-key': 'code',
        title: 'Махагон коричневый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Коричневые тона',
        name: 'Земельно-коричневый - Коричневые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/d55e2a5f-f8bd-499b-9472-18b0f9e136b4.jpg',
        code: 'RAL 8020',
        'target-key': 'code',
        title: 'Земельно-коричневый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Коричневые тона',
        name: 'Оранжево-коричневый - Коричневые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/40c7ba94-17d6-47b7-9e35-b5ee22415588.jpg',
        code: 'RAL 8023',
        'target-key': 'code',
        title: 'Оранжево-коричневый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Коричневые тона',
        name: 'Белево-коричневый - Коричневые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/037d4796-dd0f-4fc5-aede-9054395a9981.jpg',
        code: 'RAL 8024',
        'target-key': 'code',
        title: 'Белево-коричневый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Коричневые тона',
        name: 'Бледно-коричневый - Коричневые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/3d812ef0-1ca6-4362-8d59-369612f4b7da.jpg',
        code: 'RAL 8025',
        'target-key': 'code',
        title: 'Бледно-коричневый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Коричневые тона',
        name: 'Шоколадно-коричневый - Коричневые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/4467e1e4-2146-4e36-a217-b96737136668.jpg',
        code: 'RAL 8017',
        'target-key': 'code',
        title: 'Шоколадно-коричневый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Коричневые тона',
        name: 'Серо-коричневый - Коричневые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/f2ae1ad6-0d6d-4231-8fcc-b3fab69a3786.jpg',
        code: 'RAL 8019',
        'target-key': 'code',
        title: 'Серо-коричневый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Красные тона',
        name: 'Огненно красный - Красные тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/4092f3a3-8285-4efa-9cdd-853e3d17ce30.jpg',
        code: 'RAL 3000',
        'target-key': 'code',
        title: 'Огненно красный',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Красные тона',
        name: 'Карминно-красный - Красные тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/fcd9d597-0c9b-4249-86a2-efd7d1d24d75.jpg',
        code: 'RAL 3002',
        'target-key': 'code',
        title: 'Карминно-красный',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Красные тона',
        name: 'Винно-красный - Красные тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/fcd9d597-0c9b-4249-86a2-efd7d1d24d75.jpg',
        code: 'RAL 3002',
        'target-key': 'code',
        title: 'Винно-красный',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Красные тона',
        name: 'Светло-розовый - Красные тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/dce526e5-2ea1-4864-a693-9ca3b2b26e3b.jpg',
        code: 'RAL 3015',
        'target-key': 'code',
        title: 'Светло-розовый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Красные тона',
        name: 'Транспортный красный - Красные тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/91df9529-cc50-44df-9cd9-3426c5860c2c.jpg',
        code: 'RAL 3020',
        'target-key': 'code',
        title: 'Транспортный красный',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Красные тона',
        name: 'Транспортный красный - Красные тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/91df9529-cc50-44df-9cd9-3426c5860c2c.jpg',
        code: 'RAL 3015',
        'target-key': 'code',
        title: 'Транспортный красный',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Красные тона',
        name: 'Малиновый - Красные тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/24c7153f-d1b5-45a5-87c2-d1369283b69b.jpg',
        code: 'RAL 3027',
        'target-key': 'code',
        title: 'Малиновый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Красные тона',
        name: 'Красный - Красные тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/57b8e4c6-b439-4433-8f14-1496f95bd97d.jpg',
        code: 'RAL 3028',
        'target-key': 'code',
        title: 'Красный',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые тона',
        name: 'Серо-оливковый - Серые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/96b65ae7-0763-4f5c-ab75-6f534baecafa.jpg',
        code: 'RAL 6006',
        'target-key': 'code',
        title: 'Серо-оливковый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые тона',
        name: 'Сигнальный серый - Серые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/8bc6cb3c-bd37-43e9-a063-e1e01a7e5209.jpg',
        code: 'RAL 7004',
        'target-key': 'code',
        title: 'Сигнальный серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые тона',
        name: 'Железно-серый - Серые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/eda9a0d5-dfaa-4bbc-b1a4-f8a14041712b.jpg',
        code: 'RAL 7011',
        'target-key': 'code',
        title: 'Железно-серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые тона',
        name: 'Базальтово-серый - Серые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/00bb6c95-2092-4a89-bceb-d8d619fc69d9.jpg',
        code: 'RAL 7012',
        'target-key': 'code',
        title: 'Базальтово-серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые тона',
        name: 'Сланцево-серый - Серые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/61da3dd6-b6b9-4dae-b2e6-38dd4bae205e.jpg',
        code: 'RAL 7015',
        'target-key': 'code',
        title: 'Сланцево-серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые тона',
        name: 'Серая умба - Серые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/29346b5c-44a2-446c-8499-ea7bde85751b.jpg',
        code: 'RAL 7022',
        'target-key': 'code',
        title: 'Серая умба',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые тона',
        name: 'Серый бетон - Серые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/e300a554-f0d0-4a74-96ac-2364a0656682.jpg',
        code: 'RAL 7023',
        'target-key': 'code',
        title: 'Серый бетон',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые тона',
        name: 'Каменно-серый - Серые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/22d8095c-2fce-4106-a4ba-4f767128f853.jpg',
        code: 'RAL 7030',
        'target-key': 'code',
        title: 'Каменно-серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые тона',
        name: 'Светло-серый - Серые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/61900997-2aa8-421b-8fd8-4a5b08bcdb9c.jpg',
        code: 'RAL 7035',
        'target-key': 'code',
        title: 'Светло-серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые тона',
        name: 'Кварцевый серый - Серые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/f4061cb3-7774-4ad3-95fc-fc29b272c4bc.jpg',
        code: 'RAL 7039',
        'target-key': 'code',
        title: 'Кварцевый серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые тона',
        name: 'Транспортный серый - Серые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/2104c205-18c1-415f-9372-7c771d202db6.jpg',
        code: 'RAL 7042',
        'target-key': 'code',
        title: 'Транспортный серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые тона',
        name: 'Телегрей 4 - Серые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/80ef243b-350c-4e83-a088-6c712471fb1a.jpg',
        code: 'RAL 7047',
        'target-key': 'code',
        title: 'Телегрей 4',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые тона',
        name: 'Серо-алюминиевый - Серые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/d461ac95-d688-44d9-acd5-3bb5f9d9b44b.jpg',
        code: 'RAL 9007',
        'target-key': 'code',
        title: 'Серо-алюминиевый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Серые тона',
        name: 'Графитовый серый - Серые тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/76066fb6-b111-4007-8b50-3ae7ba04fcd5.jpg',
        code: 'RAL 7024',
        'target-key': 'code',
        title: 'Графитовый серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Синие тона',
        name: 'Сине-сиреневый - Синие тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/40bc410e-f06a-4d61-9cb9-6480307e5e33.jpg',
        code: 'RAL 4005',
        'target-key': 'code',
        title: 'Сине-сиреневый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Синие тона',
        name: 'Сапфирово-синий - Синие тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/40bc410e-f06a-4d61-9cb9-6480307e5e33.jpg',
        code: 'RAL 5003',
        'target-key': 'code',
        title: 'Сапфирово-синий',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Синие тона',
        name: 'Сигнальный синий - Синие тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/46b2bfa6-dae4-49dc-a5a7-80c4c8b93058.jpg',
        code: 'RAL 5005',
        'target-key': 'code',
        title: 'Сигнальный синий',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Синие тона',
        name: 'Генцианово-синий - Синие тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/9abe031c-2271-4a1b-9e90-1bdd8fd19958.jpg',
        code: 'RAL 5010',
        'target-key': 'code',
        title: 'Генцианово-синий',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Синие тона',
        name: 'Кабальтово-синий - Синие тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/f5aec715-796a-489a-b52c-b9a04b262e53.jpg',
        code: 'RAL 5013',
        'target-key': 'code',
        title: 'Кабальтово-синий',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Синие тона',
        name: 'Небесно-синий - Синие тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/f5aec715-796a-489a-b52c-b9a04b262e53.jpg',
        code: 'RAL 5015',
        'target-key': 'code',
        title: 'Небесно-синий',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Синие тона',
        name: 'Транспртный синий - Синие тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/b8b3c071-d201-4463-88ef-9a6b4a13fa49.jpg',
        code: 'RAL 5017',
        'target-key': 'code',
        title: 'Транспртный синий',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Синие тона',
        name: 'Водная синь - Синие тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/d112d0ce-8d11-4f59-9ab2-949c4c26be76.jpg',
        code: 'RAL 5021',
        'target-key': 'code',
        title: 'Водная синь',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Синие тона',
        name: 'Пастельно-синий - Синие тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/2055af81-30e9-48ca-8c4f-f26d3e97fd99.jpg',
        code: 'RAL 5024',
        'target-key': 'code',
        title: 'Пастельно-синий',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Черные тона',
        name: 'Черно-серый - Черные тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/ec81225b-9adb-4e9c-a9f5-20ed7ae809a6.jpg',
        code: 'RAL 7021',
        'target-key': 'code',
        title: 'Черно-серый',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Черные тона',
        name: 'Сигнальный черный - Черные тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/12e7a2be-85b2-447e-9b65-8bd413e5814a.jpg',
        code: 'RAL 9004',
        'target-key': 'code',
        title: 'Сигнальный черный',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Черные тона',
        name: 'Глубокий черный - Черные тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/d2d90fbd-f602-49ab-8b0c-9ccf6127de7d.jpg',
        code: 'RAL 9005',
        'target-key': 'code',
        title: 'Глубокий черный',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Черные тона',
        name: 'Графитовый черный - Черные тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/74613760-624c-4aff-a257-106c09236d65.jpg',
        code: 'RAL 9011',
        'target-key': 'code',
        title: 'Графитовый черный',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        collection: 'Черные тона',
        name: 'Антрацитово-серый - Черные тона - Цвет москинтной сетки',
        description: 'Цвет москинтной сетки',
        'is-order': true,
        price: true,
        value:
          'https://storage.yandexcloud.net/axioma/files/d006ebb1-59d7-45c2-8136-f6d2bfc821a5.jpg',
        code: 'RAL 7016',
        'target-key': 'code',
        title: 'Антрацитово-серый',
      },
    ],
  },
  'mosquito-type': {
    type: 'object',
    code: 'mosquito-type',
    title: 'mosquito-type',
    'reducer-filter': (props, state) =>
      props.filter(propRow => {
        let result = true
        const models = propRow['window-models']
          ? propRow['window-models'].split(',').map(item => item.trim())
          : []

        if (models.length > 0) {
          result = models.includes(state['window-model'])
        }

        return result
      }),
    'reducer-specification': (props, state) =>
      props.filter(propRow => {
        let result = true
        const models = propRow['window-models']
          ? propRow['window-models'].split(',').map(item => item.trim())
          : []

        if (models.length > 0) {
          result = models.includes(state['window-model'])
        }
        if (result) {
          result = !state['mosquito-type-not-needed']
        }
        if (result && propRow['target-key']) {
          result = propRow[propRow['target-key']] === state['mosquito-type']
        }

        return result
      }),
    properties: {
      'window-models': {
        type: 'string',
      },
      'target-key': { type: 'string' },
      info: { type: 'string' },
      description: { type: 'string' },
      name: { type: 'string' },
      code: { type: 'string' },
      title: { type: 'string' },
      value: { type: 'string' },
      'is-order': { type: 'boolean' },
      price: { type: 'boolean' },
      sizes: { type: 'string' },
    },
    enum: [
      {
        'window-models': 'model-1, model-2, model-3',
        info: 'Москитная сетка обычная',
        description: 'Москитная сетка Обычная',
        name:
          'Москитная сетка Обычная, {{mosquito-type-color/title}} / {{mosquito-type-color/title}}, {{mosquito-type-index}}',
        code: 'simple',
        'target-key': 'code',
        title: 'Обычная',
        value: 'https://cdn1.ozone.ru/s3/multimedia-4/wc1200/6013794736.jpg',
        'is-order': true,
        price: true,
        sizes: '{{mosquito-type-sizes}}',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        info: 'Москитная сетка Антикошка - с защитой от когтей кошки',
        description: 'Москитная сетка Антикошка',
        name:
          'Москитная сетка Антикошка, {{mosquito-type-color/title}} / {{mosquito-type-color/title}}, {{mosquito-type-index}}',
        code: 'anti-cat',
        'target-key': 'code',
        title: 'Антикошка',
        value: 'https://cdn1.ozone.ru/s3/multimedia-5/wc1200/6016296701.jpg',
        'is-order': true,
        price: true,
        sizes: '{{mosquito-type-sizes}}',
      },
    ],
  },
  'outer-slope-model': {
    type: 'object',
    code: 'outer-slope-model',
    title: 'outer-slope-model',
    properties: {
      description: { type: 'string' },
      title: { type: 'string' },
      value: { type: 'string' },
    },
    enum: [
      {
        description: 'Модель внешнего откоса',
        title: 'Модель 1',
        value: 'outer-model-1',
      },
      {
        description: 'Модель внешнего откоса',
        title: 'Модель 2',
        value: 'outer-model-2',
      },
      {
        description: 'Модель внешнего откоса',
        title: 'Модель 3',
        value: 'outer-model-3',
      },
      {
        description: 'Модель внешнего откоса',
        title: 'Модель 4',
        value: 'outer-model-4',
      },
    ],
  },
  'outer-slope-type-color': {
    type: 'object',
    code: 'outer-slope-type-color',
    title: 'outer-slope-type-color',
    'reducer-filter': (props, state) =>
      props.filter(propRow => {
        let result = true
        const models = propRow['window-models']
          ? propRow['window-models'].split(',').map(item => item.trim())
          : []

        if (models.length > 0) {
          result = models.includes(state['window-model'])
        }
        if (state[propRow['target-group']]) {
          result = state[propRow['target-group']] === propRow['target-group-value']
        }

        return result
      }),
    'reducer-specification': (props, state) =>
      props.filter(propRow => {
        let result = true
        const models = propRow['window-models']
          ? propRow['window-models'].split(',').map(item => item.trim())
          : []

        if (models.length > 0) {
          result = models.includes(state['window-model'])
        }
        if (result && propRow['target-key']) {
          result = propRow[propRow['target-key']] === state['outer-slope-type-color']
        }
        if (state['outer-slope-type-not-needed']) {
          result = false
        }

        return result
      }),
    properties: {
      'window-models': { type: 'string' },
      'target-key': { type: 'string' },
      value: { type: 'string' },
      code: { type: 'string' },
      price: { type: 'boolean' },
      title: { type: 'string' },
      'target-group': { type: 'string' },
      'target-group-value': { type: 'string' },
    },
    enum: [
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089z4b',
        'target-key': 'code',
        'target-group': 'outer-slope-type',
        'target-group-value': 'pvh',
        price: true,
        title: 'Белый',
        value:
          'https://storage.yandexcloud.net/axioma/files/218b974b-3fea-4576-a815-7abab3ad0d9d.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089z4mg',
        'target-key': 'code',
        'target-group': 'outer-slope-type',
        'target-group-value': 'pvh',
        price: true,
        title: 'Мрамор глянец',
        value:
          'https://storage.yandexcloud.net/axioma/files/9b174e79-6182-4248-8ee3-55575a799c45.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089z4mm',
        'target-key': 'code',
        'target-group': 'outer-slope-type',
        'target-group-value': 'pvh',
        price: true,
        title: 'Мрамор матовый',
        value:
          'https://storage.yandexcloud.net/axioma/files/d133f894-14bf-4854-8e1d-1caed20db1a4.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089z4mam',
        'target-key': 'code',
        'target-group': 'outer-slope-type',
        'target-group-value': 'pvh',
        price: true,
        title: 'Антрацит матовый',
        value:
          'https://storage.yandexcloud.net/axioma/files/b86939ed-c20f-44e0-81e1-ad9e694575f0.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089z4bg',
        'target-key': 'code',
        'target-group': 'outer-slope-type',
        'target-group-value': 'pvh',
        price: true,
        title: 'Белый глянец',
        value:
          'https://storage.yandexcloud.net/axioma/files/1f0443fb-eb33-4b90-8591-4a58cea506ce.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089z3c',
        'target-key': 'code',
        'target-group': 'outer-slope-type',
        'target-group-value': 'pvh',
        price: true,
        title: 'Кремовый',
        value:
          'https://storage.yandexcloud.net/axioma/files/a332c94f-9c33-4ab8-8243-805a58cf1645.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089z3vg',
        'target-key': 'code',
        'target-group': 'outer-slope-type',
        'target-group-value': 'pvh',
        price: true,
        title: 'Венге глянец',
        value:
          'https://storage.yandexcloud.net/axioma/files/9e37eb84-efb3-4db5-bf8d-8c4faf4900c0.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089z3vg',
        'target-key': 'code',
        'target-group': 'outer-slope-type',
        'target-group-value': 'pvh',
        price: true,
        title: 'Венге глянец',
        value:
          'https://storage.yandexcloud.net/axioma/files/9b3ea549-d17a-4e33-813c-00da9f6e639e.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089z3vm',
        'target-key': 'code',
        'target-group': 'outer-slope-type',
        'target-group-value': 'pvh',
        price: true,
        title: 'Венге матовый',
        value:
          'https://storage.yandexcloud.net/axioma/files/175bf6ce-d75b-4ab4-aa7b-7713884fad8b.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089i1wdg',
        'target-key': 'code',
        'target-group': 'outer-slope-type',
        'target-group-value': 'pvh',
        price: false,
        title: 'Белый дуб глянцевый',
        value:
          'https://storage.yandexcloud.net/axioma/files/5855be15-0534-40d3-870b-f145c8117ff7.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089z2wdm',
        'target-key': 'code',
        'target-group': 'outer-slope-type',
        'target-group-value': 'pvh',
        price: true,
        title: 'Белый дуб матовый',
        value:
          'https://storage.yandexcloud.net/axioma/files/d1d8fed7-0a46-46b7-bf96-bae77d30ee87.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089z2dgg',
        'target-key': 'code',
        'target-group': 'outer-slope-type',
        'target-group-value': 'pvh',
        price: true,
        title: 'Дуб золотой глянец',
        value:
          'https://storage.yandexcloud.net/axioma/files/cc6b4100-d15f-4e1c-bda8-4f5fa2efe0a5.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089z2dgm',
        'target-key': 'code',
        'target-group': 'outer-slope-type',
        'target-group-value': 'pvh',
        price: true,
        title: 'Дуб золотой матовый',
        value:
          'https://storage.yandexcloud.net/axioma/files/9417b607-cf03-4d85-9411-e83a29b21b15.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089z2dng',
        'target-key': 'code',
        'target-group': 'outer-slope-type',
        'target-group-value': 'pvh',
        price: true,
        title: 'Дуб натуральный глянец',
        value:
          'https://storage.yandexcloud.net/axioma/files/b4ae4023-55f7-4db1-9bd0-c6343f2e0443.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089z2dnm',
        'target-key': 'code',
        'target-group': 'outer-slope-type',
        'target-group-value': 'pvh',
        price: true,
        title: 'Дуб натуральный матовый',
        value:
          'https://storage.yandexcloud.net/axioma/files/5e60acdd-5ef1-4c3c-9727-3dd88c7ec1ea.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089z2og',
        'target-key': 'code',
        'target-group': 'outer-slope-type',
        'target-group-value': 'pvh',
        price: true,
        title: 'Орех глянец',
        value:
          'https://storage.yandexcloud.net/axioma/files/d32fcedc-6996-471c-9787-541ed5128f1a.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089z2om',
        'target-key': 'code',
        'target-group': 'outer-slope-type',
        'target-group-value': 'pvh',
        price: true,
        title: 'Орех матовый',
        value:
          'https://storage.yandexcloud.net/axioma/files/ca109068-708e-43b7-850e-e94dd6d936ec.jpg',
      },
    ],
  },
  'outer-slope-type-width': {
    type: 'object',
    code: 'outer-slope-type-width',
    title: 'outer-slope-type-width',
    properties: {
      value: { type: 'number' },
    },
    enum: [{ value: 200 }, { value: 250 }, { value: 300 }],
  },
  'outer-slope-type': {
    type: 'object',
    code: 'outer-slope-type',
    title: 'outer-slope-type',
    'reducer-specification': (props, state) =>
      props.filter(propRow => {
        let result = true

        if (result && propRow['target-key']) {
          result = propRow[propRow['target-key']] === state['outer-slope-type']
        }
        if (result) {
          result = !state['outer-slope-type-not-needed']
        }

        return result
      }),
    properties: {
      'target-key': { type: 'string' },
      code: { type: 'string' },
      description: { type: 'string' },
      'length-range': { type: 'string' },
      name: { type: 'string' },
      'is-order': { type: 'boolean' },
      price: { type: 'boolean' },
      title: { type: 'string' },
      value: { type: 'string' },
      sizes: { type: 'string' },
    },
    enum: [
      {
        description: 'Тип внешнего откоса',
        'length-range': '2000~6000',
        name:
          'Откос внешний, ПВХ {{outer-slope-type-color/title}} {{outer-slope-type-width}} / {{outer-slope-type-length}}',
        'is-order': true,
        price: true,
        code: 'pvh',
        'target-key': 'code',
        title: 'ПВХ',
        value:
          'https://storage.yandexcloud.net/axioma/files/05cd3840-c4e3-4b02-bb58-d9ff7c4a430d.jpg',
        sizes: '{{outer-slope-type-width}} x {{outer-slope-type-length}}',
      },
      {
        description: 'Тип внешнего откоса',
        'length-range': '2000~99999',
        name:
          'Откос внешний, Штукатурка {{outer-slope-type-color/title}} {{outer-slope-type-width}} / {{outer-slope-type-length}}',
        'is-order': true,
        price: true,
        code: 'plaster',
        'target-key': 'code',
        title: 'Штукатурка',
        value:
          'https://storage.yandexcloud.net/axioma/files/7247ea75-5abd-4890-8fe4-1e3c84c8581d.jpg',
        sizes: '{{outer-slope-type-width}} x {{outer-slope-type-length}}',
      },
    ],
  },
  'pet-safety': {
    type: 'object',
    code: 'pet-safety',
    title: 'pet-safety',
    properties: {
      description: { type: 'string' },
      title: { type: 'string' },
      name: { type: 'string' },
      price: { type: 'boolean' },
      value: { type: 'boolean' },
      'value-title': { type: 'string' },
    },
    enum: [
      {
        description: 'Дополнительная защита для безопасности домашних животных',
        title: 'Безопасность домашних животных',
        name: 'Безопасность домашних животных',
        price: true,
        value: true,
        'value-title': 'Да',
      },
      {
        description: 'Дополнительная защита для безопасности домашних животных',
        title: 'Безопасность домашних животных',
        name: 'Безопасность домашних животных',
        price: false,
        value: false,
        'value-title': 'Нет',
      },
    ],
  },
  'profile-color': {
    type: 'object',
    code: 'profile-color',
    title: 'Основа профиля',
    'reducer-filter': (props, state) =>
      props.filter(propRow => {
        let result = true
        const models = propRow['window-models']
          ? propRow['window-models'].split(',').map(item => item.trim())
          : []

        if (models.length > 0) {
          result = models.includes(state['window-model'])
        }

        return result
      }),
    'reducer-specification': (props, state) =>
      props.filter(propRow => {
        let result = true
        const models = propRow['window-models']
          ? propRow['window-models'].split(',').map(item => item.trim())
          : []

        if (models.length > 0) {
          result = models.includes(state['window-model'])
        }
        if (result && propRow['value-code']) {
          result = propRow['value-code'] === state['profile-color']
        }

        return result
      }),
    properties: {
      'window-models': { type: 'string' },
      title: { type: 'string' },
      value: { type: 'string' },
      'value-code': { type: 'string' },
      'value-collection': { type: 'string' },
      'value-description': { type: 'string' },
      'value-price': { type: 'boolean' },
      'value-title': { type: 'string' },
    },
    enum: [
      {
        'window-models': 'model-1, model-2, model-3',
        title: 'Основа профиля',
        value:
          'https://paritetcompany.com/library/media/DMK-vozmojnie-obrazci-dekoririvaniya-zolotoy-dub-uk101.jpg',
        'value-code': '2052089z',
        'value-collection': 'Коллекция Дерево',
        'value-description': 'Цвет профиля',
        'value-price': true,
        'value-title': 'Золотой дуб',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        title: 'Основа профиля',
        value: '',
        'value-code': '',
        'value-collection': 'Коллекция Дерево',
        'value-description': 'Цвет профиля',
        'value-price': false,
        'value-title': '',
      },
    ],
  },
  // 'reinforced-windows-for-high-rise-buildings': {
  //   type: 'object',
  //   code: 'reinforced-windows-for-high-rise-buildings',
  //   title: 'reinforced-windows-for-high-rise-buildings',
  //   properties: {
  //     description: { type: 'string' },
  //     price: { type: 'boolean' },
  //     'is-order': { type: 'boolean' },
  //     title: { type: 'string' },
  //     value: { type: 'boolean' },
  //     'value-title': { type: 'string' },
  //   },
  //   enum: [
  //     {
  //       description: 'Усиленные окна для высотных домов',
  //       title: 'Усиленные окна для высотных домов',
  //       price: true,
  //       'is-order': true,
  //       value: true,
  //       'value-title': 'Да',
  //     },
  //     {
  //       description: 'Усиленные окна для высотных домов',
  //       title: 'Усиленные окна для высотных домов',
  //       price: false,
  //       'is-order': false,
  //       value: false,
  //       'value-title': 'Нет',
  //     },
  //   ],
  // },
  soundproofing: {
    type: 'object',
    code: 'soundproofing',
    title: 'Звукоизоляция',
    'reducer-filter': (props, state) =>
      props.filter(propRow => {
        let result = true
        const models = propRow['window-models']
          ? propRow['window-models'].split(',').map(item => item.trim())
          : []

        if (models.length > 0) {
          result = models.includes(state['window-model'])
        }

        return result
      }),
    'reducer-specification': (props, state) =>
      props.filter(propRow => {
        let result = true
        const models = propRow['window-models']
          ? propRow['window-models'].split(',').map(item => item.trim())
          : []

        if (models.length > 0) {
          result = models.includes(state['window-model'])
        }
        if (result) {
          result = propRow['value'] === state['soundproofing']
        }

        return result
      }),
    properties: {
      'window-models': {
        type: 'string',
      },
      description: { type: 'string' },
      'description-info': { type: 'string' },
      title: { type: 'string' },
      value: { type: 'number' },
      'value-title': { type: 'string' },
    },
    enum: [
      {
        'window-models': 'model-1, model-2, model-3',
        description: 'Самая простая',
        'description-info': 'Настройка уровня звукаизоляции',
        title: 'Звукоизоляция',
        value: 1,
        'value-title': 'Без звукоизоляции',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        description: 'Дома шумно, окна выходят на шумный двор',
        'description-info': 'Настройка уровня звукаизоляции',
        title: 'Звукоизоляция',
        value: 2,
        'value-title': 'Слабая',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        description: 'Я выбираю всегда лучшие современные решения',
        'description-info': 'Настройка уровня звукаизоляции',
        title: 'Звукоизоляция',
        value: 3,
        'value-title': 'Максимальная',
      },
    ],
  },
  // 'sun-protection': {
  //   type: 'object',
  //   code: 'sun-protection',
  //   title: 'Солнцезащита',
  //   properties: {
  //     description: { type: 'string' },
  //     'is-order': { type: 'boolean' },
  //     price: { type: 'boolean' },
  //     title: { type: 'string' },
  //     value: { type: 'boolean' },
  //     'value-title': { type: 'string' },
  //   },
  //   enum: [
  //     {
  //       description: 'Солнцезащита',
  //       title: 'Солнцезащита',
  //       'is-order': true,
  //       price: true,
  //       value: true,
  //       'value-title': 'Да',
  //     },
  //     {
  //       description: 'Солнцезащита',
  //       title: 'Солнцезащита',
  //       'is-order': false,
  //       price: false,
  //       value: false,
  //       'value-title': 'Нет',
  //     },
  //   ],
  // },
  'window-hand': {
    type: 'object',
    code: 'window-hand',
    title: 'window-hand',
    'reducer-filter': (props, state) =>
      props.filter(propRow => {
        let result = true
        const models = propRow['window-models']
          ? propRow['window-models'].split(',').map(item => item.trim())
          : []

        if (models.length > 0) {
          result = models.includes(state['window-model'])
        }

        return result
      }),
    'reducer-specification': (props, state) =>
      props.filter(propRow => {
        let result = true
        const models = propRow['window-models']
          ? propRow['window-models'].split(',').map(item => item.trim())
          : []

        if (models.length > 0) {
          result = models.includes(state['window-model'])
        }
        if (result) {
          result = propRow['value'] === state['window-hand']
        }

        return result
      }),
    properties: {
      'window-models': {
        type: 'string',
      },
      code: { type: 'string' },
      description: { type: 'string' },
      image: { type: 'string' },
      name: { type: 'string' },
      'number-of-leaves-depends': { type: 'boolean' },
      'is-order': { type: 'boolean' },
      price: { type: 'boolean' },
      title: { type: 'string' },
      value: { type: 'string' },
    },
    enum: [
      {
        'window-models': 'model-1, model-2, model-3',
        'number-of-leaves-depends': true,
        'is-order': true,
        price: true,
        description: 'Ручки',
        code: '2052089w',
        image:
          'https://storage.yandexcloud.net/axioma/files/fe836b84-f579-481c-b925-0189785d1721.png',
        name: 'Белая ручка',
        title: 'Белая ручка',
        value: 'white',
      },
      {
        'window-models': 'model-1',
        'number-of-leaves-depends': true,
        'is-order': true,
        price: true,
        description: 'Ручки',
        code: '2052089g',
        image:
          'https://storage.yandexcloud.net/axioma/files/239114a4-c53d-4401-b17a-f1cfebe4c7a6.png',
        name: 'Золотая ручка',
        title: 'Золотая ручка',
        value: 'gold',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        'number-of-leaves-depends': true,
        'is-order': true,
        price: true,
        description: 'Ручки',
        code: '2052089b',
        image:
          'https://storage.yandexcloud.net/axioma/files/7c4ff014-9097-4232-8471-60863d9ced3b.png',
        name: 'Коричневая ручка',
        title: 'Коричневая ручка',
        value: 'brown',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        'number-of-leaves-depends': true,
        'is-order': true,
        price: true,
        description: 'Ручки',
        code: '2052089s',
        image:
          'https://storage.yandexcloud.net/axioma/files/32e2eb4c-d89c-4aea-a6c4-e6f7257eca06.png',
        name: 'Серебристая ручка',
        title: 'Серебристая ручка',
        value: 'silver',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        'number-of-leaves-depends': true,
        'is-order': true,
        price: true,
        description: 'Ручки',
        code: '2052089sm',
        image:
          'https://storage.yandexcloud.net/axioma/files/b89e39db-9c4f-42eb-8a81-b022bfd8c893.png',
        name: 'Серебристая матовая ручка',
        title: 'Серебристая матовая ручка',
        value: 'silver-matt',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        'number-of-leaves-depends': true,
        'is-order': true,
        price: true,
        description: 'Ручки',
        code: '2052010wd',
        image:
          'https://storage.yandexcloud.net/axioma/files/9e8c024a-460a-4960-a264-11ac9b0c5e19.png',
        name: 'Белая ручка делюкс',
        title: 'Белая ручка делюкс',
        value: 'white-delux',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        'number-of-leaves-depends': true,
        'is-order': true,
        price: true,
        description: 'Ручки',
        code: '2052010gd',
        image:
          'https://storage.yandexcloud.net/axioma/files/d3894320-3a60-4433-89ad-8f36ff62b862.png',
        name: 'Золотая ручка делюкс',
        title: 'Золотая ручка делюкс',
        value: 'gold-delux',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        'number-of-leaves-depends': true,
        'is-order': true,
        price: true,
        description: 'Ручки',
        code: '2052010bzd',
        image:
          'https://storage.yandexcloud.net/axioma/files/3bdde03d-b0c6-4076-b972-42788ae088d6.png',
        name: 'Бронзовая ручка делюкс',
        title: 'Бронзовая ручка делюкс',
        value: 'bronze-delux',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        'number-of-leaves-depends': true,
        'is-order': true,
        price: true,
        description: 'Ручки',
        code: '2052010bzd',
        image:
          'https://storage.yandexcloud.net/axioma/files/9d5b1909-4708-47ae-8b48-dba3a7191e57.png',
        name: 'Серебристая ручка делюкс',
        title: 'Серебристая ручка делюкс',
        value: 'silver-delux',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        'number-of-leaves-depends': true,
        'is-order': true,
        price: true,
        description: 'Ручки',
        code: '2052010bzd',
        image:
          'https://storage.yandexcloud.net/axioma/files/06b542f1-94f7-45bb-908b-35799fd2f9cb.png',
        name: 'Серая ручка делюкс',
        title: 'Серая ручка делюкс',
        value: 'grey-delux',
      },
    ],
  },
  'windowsill-model-color': {
    type: 'object',
    code: 'windowsill-model-color',
    title: 'windowsill-model-color',
    'reducer-filter': (props, state) =>
      props.filter(propRow => {
        let result = true
        const models = propRow['window-models']
          ? propRow['window-models'].split(',').map(item => item.trim())
          : []

        if (models.length > 0) {
          result = models.includes(state['window-model'])
        }

        return result
      }),
    'reducer-specification': (props, state) =>
      props.filter(propRow => {
        let result = true
        const models = propRow['window-models']
          ? propRow['window-models'].split(',').map(item => item.trim())
          : []

        if (models.length > 0) {
          result = models.includes(state['window-model'])
        }
        if (result) {
          result = !state['windowsill-model-not-needed']
        }
        if (result && propRow['target-key']) {
          result = propRow[propRow['target-key']] === state['windowsill-model-color']
        }

        return result
      }),
    properties: {
      'window-models': { type: 'string' },
      'target-key': { type: 'string' },
      code: { type: 'string' },
      price: { type: 'boolean' },
      title: { type: 'string' },
      value: { type: 'string' },
    },
    enum: [
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089sw',
        'target-key': 'code',
        price: true,
        title: 'Стандартный белый',
        value:
          'https://storage.yandexcloud.net/axioma/files/90420f29-a607-4630-b285-bcbf260cf006.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089wg',
        'target-key': 'code',
        price: true,
        title: 'Белый глянец',
        value:
          'https://storage.yandexcloud.net/axioma/files/7313fdbf-0f08-4eb3-9a5c-4afe83bc17d1.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089wdg',
        'target-key': 'code',
        price: true,
        title: 'Белый дуб глянец',
        value:
          'https://storage.yandexcloud.net/axioma/files/010de29a-44cd-44d1-a508-658447a92b71.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089bd',
        'target-key': 'code',
        price: true,
        title: 'Белый дуб',
        value:
          'https://storage.yandexcloud.net/axioma/files/024c2770-9fd3-4378-a4be-62225bf36ea0.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089dm',
        'target-key': 'code',
        price: true,
        title: 'Дуб матовый',
        value:
          'https://storage.yandexcloud.net/axioma/files/022479d0-c86d-4aca-9972-7f6d1cb82d5b.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089dn',
        'target-key': 'code',
        price: true,
        title: 'Дуб натуральный',
        value:
          'https://storage.yandexcloud.net/axioma/files/999fb9d1-5b02-436f-bcec-fd57b07e79e8.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089ds',
        'target-key': 'code',
        price: true,
        title: 'Дуб светлый',
        value:
          'https://storage.yandexcloud.net/axioma/files/ddf98570-0b64-4c56-b718-383eae95b034.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089dya',
        'target-key': 'code',
        price: true,
        title: 'Дуб янтарный',
        value:
          'https://storage.yandexcloud.net/axioma/files/7f54d43b-2962-444d-bbe4-a202bdc8eed5.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089gdg',
        'target-key': 'code',
        price: true,
        title: 'Золотой дуб глянец',
        value:
          'https://storage.yandexcloud.net/axioma/files/053e4c44-f4dc-4a9f-9119-20644381c6c5.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089mg',
        'target-key': 'code',
        price: true,
        title: 'Махагон глянцевый',
        value:
          'https://storage.yandexcloud.net/axioma/files/0ec22fab-928a-4eb2-bd2e-40300c326d1d.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089mm',
        'target-key': 'code',
        price: true,
        title: 'Махагон матовый',
        value:
          'https://storage.yandexcloud.net/axioma/files/43b6a076-c5ca-4fbe-a85b-4f3df3f56c61.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089og',
        'target-key': 'code',
        price: true,
        title: 'Орех глянец',
        value:
          'https://storage.yandexcloud.net/axioma/files/21f2782e-8e6c-41b2-98a1-53536cd187ef.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089bg',
        'target-key': 'code',
        price: true,
        title: 'Бристол глянцевый',
        value:
          'https://storage.yandexcloud.net/axioma/files/6c572d28-45fa-4f08-b554-785ad5669ce0.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089mrg',
        'target-key': 'code',
        price: true,
        title: 'Мрамор глянцевый',
        value:
          'https://storage.yandexcloud.net/axioma/files/10c84e01-8cad-4aab-99b4-d8674040c119.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089mrm',
        'target-key': 'code',
        price: true,
        title: 'Мрамор матовый',
        value:
          'https://storage.yandexcloud.net/axioma/files/bdbe93b7-e321-49f7-b478-1d3bac65e3b0.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089pg',
        'target-key': 'code',
        price: true,
        title: 'Палермо глянцевый',
        value:
          'https://storage.yandexcloud.net/axioma/files/29972028-7509-4bee-a221-cc2e3cb7424c.jpg',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        code: '2052089bm',
        'target-key': 'code',
        price: true,
        title: 'Бристол матовый',
        value:
          'https://storage.yandexcloud.net/axioma/files/9a15112b-c8da-41de-8de9-ee3e0eddabd8.jpg',
      },
    ],
  },
  'windowsill-model-width': {
    type: 'object',
    code: 'windowsill-model',
    title: 'Модель подоконника',
    properties: {
      value: { type: 'number' },
    },
    enum: [{ value: 200 }, { value: 250 }, { value: 300 }],
  },
  'windowsill-model': {
    type: 'object',
    code: 'windowsill-model',
    title: 'Модель подоконника',
    'reducer-filter': (props, state) =>
      props.filter(propRow => {
        let result = true
        const models = propRow['window-models']
          ? propRow['window-models'].split(',').map(item => item.trim())
          : []

        if (models.length > 0) {
          result = models.includes(state['window-model'])
        }

        if (state['comfortable-floriculture']) {
          /* TODO: filter WS models */
          result = propRow['comfortable-floriculture']
        }

        return result
      }),
    'reducer-specification': (props, state) =>
      props.filter(propRow => {
        let result = true
        const models = propRow['window-models']
          ? propRow['window-models'].split(',').map(item => item.trim())
          : []

        if (models.length > 0) {
          result = models.includes(state['window-model'])
        }
        if (result && state['windowsill-model']) {
          result = propRow['value'] === state['windowsill-model']
        }

        return result
      }),
    properties: {
      'window-models': {
        type: 'string',
      },
      description: { type: 'string' },
      'length-range': { type: 'string' },
      name: { type: 'string' },
      'is-order': { type: 'boolean' },
      price: { type: 'boolean' },
      title: { type: 'string' },
      value: { type: 'string' },
      'comfortable-floriculture': { type: 'boolean' },
      sizes: { type: 'string' },
    },
    enum: [
      {
        'window-models': 'model-1, model-2, model-3',
        description: 'Модель подоконника',
        'length-range': '2000~6000',
        name:
          'Подоконник Модель 1 {{windowsill-model-color/title}} {{windowsill-model-width}} / {{windowsill-model-length}}. Торцевая накладка = Есть',
        'is-order': true,
        price: true,
        title: 'Модель 1',
        value: 'model-1',
        'comfortable-floriculture': false,
        sizes: '{{windowsill-model-width}} x {{windowsill-model-length}}',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        description: 'Модель подоконника',
        'length-range': '2000~6000',
        name:
          'Подоконник Модель 2 {{windowsill-model-color/title}} {{windowsill-model-width}} / {{windowsill-model-length}}. Торцевая накладка = Есть',
        'is-order': true,
        price: true,
        title: 'Модель 2',
        value: 'model-2',
        'comfortable-floriculture': true,
        sizes: '{{windowsill-model-width}} x {{windowsill-model-length}}',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        description: 'Модель подоконника',
        'length-range': '2000~6000',
        name:
          'Подоконник Модель 3 {{windowsill-model-color/title}} {{windowsill-model-width}} / {{windowsill-model-length}}. Торцевая накладка = Есть',
        'is-order': true,
        price: true,
        title: 'Модель 3',
        value: 'model-3',
        'comfortable-floriculture': true,
        sizes: '{{windowsill-model-width}} x {{windowsill-model-length}}',
      },
      {
        'window-models': 'model-1, model-2, model-3',
        description: 'Модель подоконника',
        'length-range': '2000~6000',
        name:
          'Подоконник Модель 4 {{windowsill-model-color/title}} {{windowsill-model-width}} / {{windowsill-model-length}}. Торцевая накладка = Есть',
        'is-order': true,
        price: true,
        title: 'Модель 4',
        value: 'model-4',
        'comfortable-floriculture': true,
        sizes: '{{windowsill-model-width}} x {{windowsill-model-length}}',
      },
    ],
  },
  color: {
    type: 'object',
    code: 'color',
    title: 'color-price',
    properties: {
      price: {
        type: 'boolean',
      },
    },
    enum: [{ price: true }],
  },
  'outer-color': {
    type: 'object',
    code: 'outer-color',
    title: 'model-outside-color',
    properties: {
      title: {
        type: 'string',
      },
    },
    enum: [{ title: 'Снаружи' }],
  },
  'inner-color': {
    type: 'object',
    code: 'inner-color',
    title: 'model-inside-color',
    properties: {
      title: {
        type: 'string',
      },
    },
    enum: [{ title: 'Внутри' }],
  },
}

export default windowProps
