import styled from 'styled-components'

export const Button = styled.button`
  height: 50px;
  width: 50px;
  background-color: #fff;
  border: 1px solid #E5E5E5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border: 1px solid #E5E5E5;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
`

export const WrapperButton = styled.div`
  width: 50px;
  height: 110px;
  background-color: transparent;
  & > button:last-child {
    margin-top: 10px;
  }
`