import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement25Square8Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-5_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M137.999 30C136.895 30 136 30.8952 136 31.9995C136 33.1038 136.895 33.999 137.999 33.999L140.167 33.999L140.167 33.999L151 33.999L151 34L154.001 34C155.105 34 156 33.1048 156 32.0005C156 30.8962 155.105 30.001 154.001 30.001L151.833 30.001L151.833 30L141 30L141 30L137.999 30Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 140.167 30)"
          fill="#ABABAB"
        />
        <path
          d="M136.94 30C136.421 30 136 30.4208 136 30.9399L136 33.059C136 33.5781 136.421 33.999 136.94 33.999L141 33.999L141 30L136.94 30Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M155.06 34C155.579 34 156 33.5792 156 33.0601L156 30.941C156 30.4219 155.579 30.001 155.06 30.001L151 30.001L151 34L155.06 34Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 141.833 30)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#2-5_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M197.999 30C196.895 30 196 30.8952 196 31.9995C196 33.1038 196.895 33.999 197.999 33.999L200.167 33.999L200.167 33.999L211 33.999L211 34L214.001 34C215.105 34 216 33.1048 216 32.0005C216 30.8962 215.105 30.001 214.001 30.001L211.833 30.001L211.833 30L201 30L201 30L197.999 30Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 200.167 30)"
          fill="#ABABAB"
        />
        <path
          d="M196.94 30C196.421 30 196 30.4208 196 30.9399L196 33.059C196 33.5781 196.421 33.999 196.94 33.999L201 33.999L201 30L196.94 30Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M215.06 34C215.579 34 216 33.5792 216 33.0601L216 30.941C216 30.4219 215.579 30.001 215.06 30.001L211 30.001L211 34L215.06 34Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 201.833 30)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#2-5_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M220 34H131V155H220V34ZM209 45H142V144H209V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M220 34H131V155H132.219V35H218.781V155H220V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-5_filter3_i)">
          <rect
            x="142.47"
            y="45.47"
            width="66.0601"
            height="98.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="135.53"
          y="38.53"
          width="79.9399"
          height="111.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="182"
          y="146"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 182 146)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="181.356"
          y="146.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 181.356 146.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M181.238 148.695C180.892 147.542 179.822 146.702 178.556 146.702L171.916 146.702C170.37 146.702 169.116 147.956 169.116 149.502C169.116 149.537 169.117 149.572 169.118 149.607C169.464 150.76 170.534 151.6 171.8 151.6L178.44 151.6C179.986 151.6 181.24 150.346 181.24 148.8C181.24 148.765 181.239 148.73 181.238 148.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M179.881 147.035C179.737 147.012 179.59 147 179.44 147L172.8 147C171.254 147 170 148.254 170 149.8C170 150.867 170.597 151.795 171.475 152.267C170.138 152.056 169.116 150.898 169.116 149.502C169.116 147.956 170.37 146.702 171.916 146.702L178.556 146.702C179.035 146.702 179.486 146.822 179.881 147.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-5_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M178 149.5C178 148.672 177.328 148 176.5 148L176.286 148L175 148L156.5 148C155.672 148 155 148.672 155 149.5V149.5C155 150.328 155.672 151 156.5 151L173 151L176.5 151L176.714 151C177.424 151 178 150.424 178 149.714V149.714C178 149.677 177.999 149.64 177.996 149.604C177.999 149.569 178 149.535 178 149.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M207.305 46.5L175.5 141.426L143.695 46.4999L207.305 46.5Z" stroke="white" />
        <defs>
          <filter
            id="2-5_filter0_d"
            x="134.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-5_filter1_d"
            x="194.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-5_filter2_d"
            x="126.3"
            y="29.3003"
            width="100.279"
            height="132.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-5_filter3_i"
            x="142"
            y="45"
            width="67.9399"
            height="101.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-5_filter4_d"
            x="153.28"
            y="146.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="153.5"
            y1="27.2"
            x2="153.5"
            y2="34.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="138.5"
            y1="36.7982"
            x2="138.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="153.5"
            y1="27.2018"
            x2="153.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="213.5"
            y1="27.2"
            x2="213.5"
            y2="34.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="198.5"
            y1="36.7982"
            x2="198.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="213.5"
            y1="27.2018"
            x2="213.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
