import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement83Square82Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#8filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M192 236.999C192 235.895 191.105 235 190.001 235C188.896 235 188.001 235.895 188.001 236.999V239.167H188.001V250H188V253.001C188 254.105 188.895 255 189.999 255C191.104 255 191.999 254.105 191.999 253.001V250.833H192V240H192V236.999Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 192 239.167)"
          fill="#ABABAB"
        />
        <path
          d="M192 235.94C192 235.421 191.579 235 191.06 235H188.941C188.422 235 188.001 235.421 188.001 235.94V240H192V235.94Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M188 254.06C188 254.579 188.421 255 188.94 255H191.059C191.578 255 191.999 254.579 191.999 254.06V250H188V254.06Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 192 240.833)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#8filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M192 263.999C192 262.895 191.105 262 190.001 262C188.896 262 188.001 262.895 188.001 263.999V266.167H188.001V277H188V280.001C188 281.105 188.895 282 189.999 282C191.104 282 191.999 281.105 191.999 280.001V277.833H192V267H192V263.999Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 192 266.167)"
          fill="#ABABAB"
        />
        <path
          d="M192 262.94C192 262.421 191.579 262 191.06 262H188.941C188.422 262 188.001 262.421 188.001 262.94V267H192V262.94Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M188 281.06C188 281.579 188.421 282 188.94 282H191.059C191.578 282 191.999 281.579 191.999 281.06V277H188V281.06Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 192 267.833)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#8filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 231H115V286H188V231ZM178 241H125V276H178V241Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 231H115V286H116V232H187V286H188V231Z"
          fill="#FBFBFB"
        />
        <g filter="url(#8filter3_i)">
          <rect
            x="125.47"
            y="241.47"
            width="52.0601"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="119.53"
          y="235.53"
          width="63.9399"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="117"
          y="251"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#8filter4_d)">
          <rect x="117.702" y="251.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M118.286 262.796C118.798 263.458 119.6 263.884 120.502 263.884C122.049 263.884 123.302 262.63 123.302 261.084V254.444C123.302 253.456 122.79 252.587 122.017 252.089C122.383 252.562 122.6 253.156 122.6 253.8V260.44C122.6 261.986 121.347 263.24 119.8 263.24C119.242 263.24 118.723 263.077 118.286 262.796Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M120.502 251.644C118.955 251.644 117.702 252.898 117.702 254.444V260.44C117.702 260.46 117.702 260.48 117.702 260.501V255.024C117.702 253.477 118.956 252.224 120.502 252.224C122.028 252.224 123.269 253.444 123.302 254.963V254.444C123.302 252.898 122.048 251.644 120.502 251.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#8filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M120.5 255C119.672 255 119 255.672 119 256.5V256.714V258V276.5C119 277.328 119.672 278 120.5 278V278C121.328 278 122 277.328 122 276.5V260V256.5L122 256.286C122 255.576 121.424 255 120.714 255V255C120.677 255 120.64 255.001 120.604 255.004C120.569 255.001 120.535 255 120.5 255Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M176.5 274.306L127.624 258.014L176.5 242.681L176.5 274.306Z" stroke="white" />
        <defs>
          <filter
            id="8filter0_d"
            x="186.12"
            y="233.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8filter1_d"
            x="186.12"
            y="260.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8filter2_d"
            x="110.3"
            y="226.3"
            width="84.2793"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8filter3_i"
            x="125"
            y="241"
            width="53.9399"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="8filter4_d"
            x="115.822"
            y="249.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8filter5_d"
            x="117.12"
            y="253.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="194.8"
            y1="252.5"
            x2="187.2"
            y2="252.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="185.202"
            y1="237.5"
            x2="192.8"
            y2="237.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="194.798"
            y1="252.5"
            x2="187.2"
            y2="252.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="194.8"
            y1="279.5"
            x2="187.2"
            y2="279.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="185.202"
            y1="264.5"
            x2="192.8"
            y2="264.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="194.798"
            y1="279.5"
            x2="187.2"
            y2="279.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
