import styled, { css } from 'styled-components'
import { CheckboxBlock } from '../../../components/checkbox/styles'
import { StyledButton } from '../../../components/button/styles'
import { LinkMessage, LinkWrapper } from '../styles'

export const StagePersonalAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`

export const StagePersonalAreaTitle = styled.h2`
  font-size: 46px;
  font-weight: bold;
  line-height: 55.2px;
`

export const StagePersonalAreaContent = styled.div`
  display: flex;

  width: 100%;
`

export const StagePersonalAreaList = styled.div`
  min-width: 260px;
`

export const StagePersonalAreaListItem = styled.div<{ active?: boolean }>`
  font-size: 14px;
  font-weight: bold;
  line-height: 19.6px;
  color: #1c3775;

  padding-left: 0;
  margin: 10px 0;

  cursor: pointer;

  transition: color 0.3s, border 0.3s, padding-left 0.3s;

  ${({ active }) =>
    active &&
    css`
      color: #d69a2d;

      padding-left: 9px;

      border-left: 2px solid #d69a2d;
    `}
`

export const PersonalAreaMainWrapper = styled.main`
  width: 100%;
`

export const ProfileWrapper = styled.div`
  background-color: #ffffff;

  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.1);

  padding: 32px 40px;
`

export const ProfileTitle = styled.h4`
  font-size: 30px;
  font-weight: 700;
  line-height: 42px;

  margin-top: 0;
  margin-bottom: 32px;
`

export const ProfileBlockItems = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const ProfileBlockButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  ${StyledButton} {
    width: 295px;

    margin-right: 40px;
  }
`

export const ProfileBlockErrorsWrapper = styled.div`
  min-width: 200px;
  max-width: 500px;
  margin-top: 20px;

  ${LinkWrapper} {
    margin-bottom: 0;
    margin-top: 0;
  }

  ${LinkMessage} {
    text-align: left;
  }
`

export const ProfileBlockResetButton = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 140%;
  color: #141b4d;

  display: flex;
  align-items: center;

  cursor: pointer;

  svg {
    margin-right: 10px;
  }
`

export const ProfileGroup = styled.div<{ noBottomMargin?: boolean }>`
  margin-bottom: 20px;
  margin-right: 20px;

  min-width: 295px;

  ${({ noBottomMargin }) =>
    noBottomMargin &&
    css`
      margin-bottom: 0;
    `}
`

export const OrderItemBlockToggle = styled.div<{ active?: boolean }>`
  position: absolute;

  top: 59px;
  right: 30px;

  padding: 10px;

  cursor: pointer;

  ${({ active = false }) =>
    !active &&
    css`
      top: 39px;
    `}
`

export const OrderItemBlockTitle = styled.h2`
  display: flex;
  align-items: center;

  font-size: 20px;
  font-weight: bold;
  line-height: 1.4;

  margin-top: 0;
  margin-bottom: 20px;

  cursor: pointer;

  span {
    margin-right: 10px;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`

export const StatusesItemIconWrapper = styled.div<{ small?: boolean; active?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50px;
  border: 1px dashed #d4d4d4;

  background-color: #ffffff;

  position: relative;

  z-index: 1;

  ${({ small = false }) =>
    small
      ? css`
          width: 10px;
          height: 10px;

          margin-top: 10px;
        `
      : css`
          width: 30px;
          height: 30px;
        `}

  ${({ active }) =>
    active &&
    css`
      border: 1px solid #55a361;
      background-color: #55a361;
    `}
`

export const OrderItemBlockStatusWrapper = styled.div`
  display: flex;
  align-items: center;

  ${StatusesItemIconWrapper} {
    margin-top: 0;
  }
`

export const OrderItemBlockStatusTitle = styled.span`
  font-size: 16px;
  line-height: 140%;
  color: #333333;

  margin-left: 8px;
`

export const OrderItemBlockWrapper = styled.div<{ active?: boolean }>`
  background-color: #ffffff;

  border: 1px dashed transparent;
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.1);

  padding: 32px 40px;

  position: relative;
  overflow: hidden;

  max-height: 9999px;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);

  ${({ active }) =>
    !active &&
    css`
      border: 1px dashed #d4d4d4;
      box-shadow: none;

      background-color: transparent;

      max-height: 124px;

      transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);

      ${OrderItemBlockTitle} {
        margin-bottom: 10px;
      }
    `}
`

export const StatusesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;

  width: 100%;

  padding-right: 54px;
  padding-bottom: 40px;
`

export const StatusesItemTitle = styled.div<{ withIcon?: boolean }>`
  font-size: 12px;
  white-space: nowrap;

  margin-bottom: 10px;

  position: relative;

  transform: translateX(50%);

  ${({ withIcon = false }) =>
    withIcon &&
    css`
      left: -10px;
    `}
`

export const StatusesItem = styled.div<{
  active?: boolean
  showProgress?: boolean
  isFirst?: boolean
  isLast?: boolean
}>`
  width: 100%;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  flex: 1;

  ${({ isFirst = false }) =>
    isFirst &&
    css`
      width: 10px;
      flex: initial;
    `}

  ${({ isFirst = false }) =>
    isFirst &&
    css`
      ${StatusesItemTitle} {
        transform: translateX(90%);
      }
    `}

  ${({ isLast = false }) =>
    isLast &&
    css`
      ${StatusesItemTitle} {
        transform: translateX(0);
      }
    `}

  &:before {
    content: '';

    border-top: ${({ active = false }) => (active ? '1px solid #55a361' : '1px dashed #d4d4d4')} ;

    width: 100%;

    display: ${({ isFirst = false }) => (isFirst ? 'none' : 'block')};

    position: absolute;

    top: calc(100% - 15px);
    left: 0;    
  }
`

export const OrderInfoBlockWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const OrderInfoBlockItem = styled.div`
  padding-right: 20px;
  padding-bottom: 24px;

  width: 33.3%;
`

export const OrderInfoBlockItemTitle = styled.div`
  font-size: 14px;
  color: #666666;
  line-height: 140%;
`

export const OrderInfoBlockItemValue = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 140%;

  white-space: pre-line;
`

export const OrderDataBlockWrapper = styled.div`
  margin-bottom: 41px;
`

export const OrderDataBlockTitle = styled.h3`
  margin: 0 0 24px;

  font-size: 18px;
  line-height: 140%;
`

export const OrderDataBlockContent = styled.div`
  border: 1px dashed #d4d4d4;

  display: grid;
  grid-template-columns: auto auto auto;

  width: 100%;

  padding: 33px 32px;
`

export const OrderDataBlockContentItem = styled.div<{
  noLeftGap?: boolean
  noRightBorder?: boolean
  center?: boolean
}>`
  border-right: 1px dashed #8f8f8f;

  padding: 0 28px 21px;

  font-size: 14px;
  line-height: 160%;

  ${({ noRightBorder = false }) =>
    noRightBorder &&
    css`
      border-right: none;
    `}

  ${({ noLeftGap = false }) =>
    noLeftGap &&
    css`
      padding-left: 0;
    `}

  ${({ center = false }) =>
    center &&
    css`
      text-align: center;
    `}
`

export const OrderNotificationsBlockWrapper = styled.div`
  margin-bottom: 40px;
`

export const OrderNotificationsBlockTitle = styled.h3`
  margin: 0 0 24px;

  font-size: 18px;
  line-height: 140%;
`

export const OrderNotificationsBlockContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const OrderNotificationsBlockContentItem = styled.div`
  margin-right: 32px;

  ${CheckboxBlock} {
    span {
      font-size: 16px;
      font-weight: 400;
    }
  }
`

export const OrderItemBlockPriceWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  padding-right: 41px;
`

export const OrderItemBlockPriceTitle = styled.div`
  font-size: 14px;
  line-height: 140%;
  color: #333333;

  margin-right: 64px;
`

export const OrderItemBlockPriceValue = styled.div`
  font-size: 24px;
  font-weight: bold;
  line-height: 140%;
`
