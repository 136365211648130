import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement34Square4TopCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-top-3-4_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M288 141.999C288 140.895 287.105 140 286.001 140C284.896 140 284.001 140.895 284.001 141.999V144.167H284.001V155H284V158.001C284 159.105 284.895 160 285.999 160C287.104 160 287.999 159.105 287.999 158.001V155.833H288V145H288V141.999Z"
            fill="url(#4-top-3-4_paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 288 144.167)"
          fill="#ABABAB"
        />
        <path
          d="M288 140.94C288 140.421 287.579 140 287.06 140H284.941C284.422 140 284.001 140.421 284.001 140.94V145H288V140.94Z"
          fill="url(#4-top-3-4_paint1_linear)"
        />
        <path
          d="M284 159.06C284 159.579 284.421 160 284.94 160H287.059C287.578 160 287.999 159.579 287.999 159.06V155H284V159.06Z"
          fill="url(#4-top-3-4_paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 288 145.833)"
          fill="url(#4-top-3-4_paint3_linear)"
        />
        <g filter="url(#4-top-3-4_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M284 254C284 252.895 284.895 252 286 252C287.105 252 288 252.895 288 254V268.56H284V254ZM284 268.56H288V270.4H288.06C288.579 270.4 289 270.821 289 271.34V273.12C289 274.158 288.158 275 287.12 275H285.88C284.842 275 284 274.158 284 273.12V270.4V270.4V268.56Z"
            fill="white"
          />
        </g>
        <rect x="284" y="269.25" width="3.99998" height="0.95833" fill="#ABABAB" />
        <path
          d="M284 252.94C284 252.421 284.421 252 284.94 252H287.06C287.579 252 288 252.421 288 252.94V269.25H284V252.94Z"
          fill="url(#4-top-3-4_paint4_linear)"
        />
        <path
          d="M284 274.06C284 274.579 284.421 275 284.94 275H288.06C288.579 275 289 274.579 289 274.06V271.148C289 270.629 288.579 270.208 288.06 270.208H284V274.06Z"
          fill="url(#4-top-3-4_paint5_linear)"
        />
        <g filter="url(#4-top-3-4_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M284 128H164V287H284V128ZM273 139H175V276H273V139Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M284 128H164V287H165V129H283V287H284V128Z"
          fill="#FBFBFB"
        />
        <rect
          x="168.53"
          y="132.53"
          width="110.94"
          height="149.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#4-top-3-4_filter3_i)">
          <rect
            x="175.47"
            y="139.47"
            width="97.0601"
            height="136.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="166"
          y="202"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#4-top-3-4_filter4_d)">
          <rect x="166.702" y="202.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M167.286 213.796C167.798 214.458 168.6 214.884 169.502 214.884C171.049 214.884 172.302 213.63 172.302 212.084V205.444C172.302 204.456 171.79 203.587 171.017 203.089C171.383 203.562 171.601 204.156 171.601 204.8V211.44C171.601 212.986 170.347 214.24 168.801 214.24C168.243 214.24 167.723 214.077 167.286 213.796Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M169.502 202.644C167.956 202.644 166.702 203.898 166.702 205.444V211.44C166.702 211.46 166.702 211.48 166.703 211.501V206.024C166.703 204.477 167.956 203.224 169.503 203.224C171.029 203.224 172.27 204.444 172.302 205.963V205.444C172.302 203.898 171.049 202.644 169.502 202.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-top-3-4_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M169.5 206C168.672 206 168 206.672 168 207.5V207.714V209V227.5C168 228.328 168.672 229 169.5 229C170.328 229 171 228.328 171 227.5V211V207.5L171 207.286C171 206.576 170.424 206 169.714 206C169.677 206 169.64 206.001 169.604 206.004C169.569 206.001 169.535 206 169.5 206Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M271.29 274.5H176.71L224 142.483L271.29 274.5Z" stroke="white" />
        <path d="M271.5 141.953L271.5 274.047L175.88 208L271.5 141.953Z" stroke="white" />
        <defs>
          <filter
            id="4-top-3-4_filter0_d"
            x="282.12"
            y="138.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-3-4_filter1_d"
            x="282.12"
            y="250.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-3-4_filter2_d"
            x="159.3"
            y="123.3"
            width="131.279"
            height="170.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-3-4_filter3_i"
            x="175"
            y="139"
            width="98.9399"
            height="139.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-top-3-4_filter4_d"
            x="164.822"
            y="200.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-3-4_filter5_d"
            x="166.12"
            y="204.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="4-top-3-4_paint0_linear"
            x1="290.8"
            y1="157.5"
            x2="283.2"
            y2="157.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="4-top-3-4_paint1_linear"
            x1="281.202"
            y1="142.5"
            x2="288.8"
            y2="142.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-3-4_paint2_linear"
            x1="290.798"
            y1="157.5"
            x2="283.2"
            y2="157.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-3-4_paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-3-4_paint4_linear"
            x1="290.8"
            y1="260.625"
            x2="283.2"
            y2="260.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-3-4_paint5_linear"
            x1="292.5"
            y1="272.604"
            x2="283"
            y2="272.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
