import styled, { css } from 'styled-components'
import { StyledButton } from '../../components/button/styles'
import {
  RadiobuttonText,
  RadiobuttonWrapper,
  SimpleSliderContent,
  StyledMark,
} from '../../components'

export const RedErrorText = styled.p`
  color: red;
`

export const AppWrapper = styled.div<{ clearView: boolean }>`
  background-color: ${({ clearView }) => (clearView ? 'transparent' : '#ffffff')};

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  max-width: 1200px;
  margin: 0 auto;
`

export const StageBlock = styled.div`
  background-color: #ffffff;

  width: 100%;
  max-width: 1200px;
`

export const StageTypeWrapper = styled(StageBlock)`
  box-shadow: -5px 20px 70px -14px rgb(0 0 0 / 12%);
  border-radius: 0 0 0 140px;

  display: flex;

  padding: 0 0 0 80px;
`

export const StageContentBlock = styled.div`
  display: flex;
  flex-direction: column;
`

export const StageContentMain = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 10px;
`

export const StageContentCards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
`

export const StageContentCardsItem = styled.div`
  border: 1px dashed #d4d4d4;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 32px;

  &:first-child {
    border-radius: 0 0 0 40px;
  }
`

export const StageContentCardsItemTop = styled.div`
  display: flex;

  margin-bottom: 24px;
`

export const StageContentCardsItemTitle = styled.h5`
  font-size: 18px;
  font-weight: bold;

  padding: 0;
  margin: 0;
`

export const StageContentCardsItemDescription = styled.div`
  font-size: 14px;
  color: #333333;

  margin-bottom: 18px;
`

export const StageContentRowWrapper = styled.div`
  flex: 1;

  margin-bottom: 36px;
`

export const StageDescriptionBlockContainer = styled.div`
  border-style: dashed;
  border-color: #d4d4d4;
  border-width: 1px 1px 0 1px;

  &:last-child {
    border-width: 1px;
  }

  position: relative;
`

export const StageDescriptionBlockWrapper = styled.div<{ collapsed: boolean }>`
  margin: 0;
  padding: 18px 32px;

  flex: 1;

  overflow: hidden;

  ${({ collapsed }) =>
    collapsed
      ? css`
          max-height: 58px;
          transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
        `
      : css`
          max-height: 9999px;
          transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
        `}
`

export const StageDescriptionBlockTop = styled.div`
  display: flex;

  margin-bottom: 16px;
`

export const StageDescriptionBlockPoint = styled.span<{ view?: string }>`
  display: inline-block;

  width: 9px;
  height: 14px;

  border-radius: 6px;

  margin-right: 8px;

  background-color: ${({ view = 'base' }) => (view === 'base' ? '#d69a2d' : '#9e2a2b')};
`

export const StageDescriptionBlockPointText = styled.span`
  margin-right: 24px;
`

export const StageDescriptionBlockTitleBlock = styled.div`
  display: flex;
  align-items: center;

  position: relative;

  margin-bottom: 16px;

  cursor: pointer;
`

export const IconWrapper = styled.span`
  line-height: 0;
`

export const ArrowWrapper = styled.div<{ collapsed: boolean }>`
  position: absolute;
  display: none;

  ${({ collapsed }) =>
    collapsed
      ? css`
          top: 0;
          right: 5px;
        `
      : css`
          top: 13px;
          right: 0;
        `};
`

export const StageDescriptionBlockTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #000000;

  margin-right: 6px;
`

export const ValueViewWrapper = styled.div`
  display: flex;

  margin-right: 16px;
`

export const ValueViewItem = styled.div<{ active: boolean; color: string }>`
  border-radius: 6px;

  width: 12px;
  height: 28px;

  margin-right: 4px;

  background-color: ${({ active, color = '#d69a2d' }) => (active ? color : '#e0e3e9')};
`

export const StageDescriptionBlockSubTitle = styled.span`
  display: inline-block;

  font-size: 12px;
  font-weight: 400;
  color: #525252;

  margin-bottom: 12px;
`

export const StageDescriptionBlockContent = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StageDescriptionBlockContentItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 50%;
`

export const StageDescriptionBlockContentItemMain = styled.div`
  display: flex;

  align-items: flex-start;
`

export const StageDescriptionBlockContentItemIconsWrapper = styled.div`
  margin-top: 16px;
`

export const StageDescriptionBlockContentItemIcon = styled.div`
  display: inline-block;
  vertical-align: top;

  overflow: hidden;

  border-radius: 50px;

  height: 24px;
  width: 24px;

  margin-right: 8px;

  position: relative;

  svg {
    width: 100%;
    height: 100%;
  }
`

export const StageDescriptionBlockContentItemDescription = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;

  padding-right: 30px;
`

export const StageViewWrapper = styled(StageBlock)``
  /* margin-top: 60px; */
export const SelectBlock = styled.div`
  padding-top: 0;

  margin-bottom: 58px;
  margin-right: 80px;

  flex: 1;
`

export const PreviewBlockStyle = styled.div<{ noBG?: boolean; nonBorder?: boolean }>`
  display: flex;
  ${({ noBG = false }) =>
    !noBG &&
    css`
      background-image: url('https://storage.yandexcloud.net/axioma/files/8615e9a1-8baa-4c46-a85d-fe91e7a0cb56.png');
      background-position: center;
      align-items: center;
    `}

  ${({ nonBorder = false }) =>
    !nonBorder &&
    css`
      border-left: 1px dashed #d4d4d4;
    `}

  min-width: 340px;
  max-width: 340px;

  justify-content: center;
`

export const PreviewBlockContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 0 50px;
`

export const PreviewBlockDesc = styled.span`
  display: inline-block;
  margin-top: 20px;
  text-align: center;

  font-size: 16px;
  color: #252525;
`

export const PreviewBlockImage = styled.img<{ bigView: boolean }>`
  ${({ bigView }) =>
    bigView
      ? css`
          width: 150%;
          max-width: 150%;
        `
      : css`
          width: 100%;
        `}
`

export const PreviewBlockTitle = styled.h4`
  font-size: 20px;
  font-weight: bold;
  line-height: 140%;

  margin-top: 0;
  margin-bottom: 8px;
`

export const PreviewBlockPrice = styled.span`
  display: flex;
  align-items: center;

  font-size: 30px;
  font-weight: bold;
  line-height: 140%;

  color: #1c3775;

  margin-bottom: 32px;

  min-height: 42px;
`

export const PreviewBlockText = styled.span`
  display: inline-block;
  max-width: 140px;

  color: #959595;
  font-size: 16px;
  line-height: 22.4px;
  text-align: center;

  transform: translateY(80px);
`

export const QuizBottomBlockStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
`

export const QuizBottomLeftBlock = styled.div`
  display: flex;

  ${StyledButton} {
    min-width: 180px;

    margin-right: 40px;
  }
`

export const QuizBottomRightBlock = styled.div`
  max-width: 50px;
`

export const StepWrapper = styled.div`
  display: flex;
  align-items: center;

  min-width: 40px;
`

export const CurrentStep = styled.span`
  font-size: 24px;
  font-weight: 700;
  line-height: 140%;
`

export const AllStep = styled.span`
  font-size: 16px;
  color: #959595;
  line-height: 140%;
`

export const SubDescription = styled.span`
  display: inline-block;

  font-size: 18px;
  color: #333333;

  margin-bottom: 31px;
`

export const SubTitle = styled.h4`
  text-align: left;

  font-size: 28px;
  line-height: 42px;

  margin-top: 0;
  margin-bottom: 30px;
`

export const SelectItems = styled.div<{ countColumns?: number }>`
  display: grid;
  grid-template-columns: ${({ countColumns = 3 }) => css`repeat(${countColumns}, 1fr)`};
  column-gap: 30px;

  margin-bottom: 64px;
`

export const VerticalList = styled.div`
  margin-bottom: 81px;
`

export const HorizontalList = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin-bottom: 81px;
`

export const HorizontalListItemTitle = styled.h3`
  font-size: 16px;
  line-height: 22.4px;
  font-weight: 700;
  color: #252525;

  margin-top: 0;
  margin-bottom: 22px;
`

export const HorizontalListItem = styled.div`
  margin-right: 20px;

  min-width: 310px;
  max-width: 50%;

  ${RadiobuttonWrapper} {
    margin-bottom: 14px;

    input {
      height: 18px;
      width: 18px;
    }
  }
  ${RadiobuttonText} {
    font-size: 14px;
  }
`

export const VerticalListItem = styled.div<{ disabled?: boolean }>`
  margin-bottom: 24px;

  ${({ disabled }) =>
    disabled &&
    css`
      ${SimpleSliderContent} {
        opacity: 0.3;
      }

      ${StyledMark} {
        cursor: initial;
      }
    `}
`

export const SelectItem = styled.div<{ active?: boolean; disabled?: boolean }>`
  border: ${({ active }) => (active ? '4px solid #205f8f' : '1px solid #d4d4d4')};

  display: flex;

  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  padding: 0;

  position: relative;

  cursor: pointer;

  ${({ disabled = false }) =>
    disabled &&
    css`
      pointer-events: none;

      opacity: 0.5;

      &:after {
        content: 'СКОРО';

        display: inline-block;

        font-size: 34px;
        font-weight: 700;
        text-shadow: -2px 2px 10px black;
        color: #fff;

        position: absolute;
        top: 40%;
      }
    `}
`

export const SelectItemIcon = styled.div`
  background-color: #8f8f8f;
  border-radius: 10px;

  height: 105px;
  width: 105px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 42px;

  &:after {
    content: '+';

    font-size: 100px;
    color: #e1e1e1;

    transform: rotate(45deg);
  }
`

export const SelectItemText = styled.span`
  font-size: 14px;
  color: #333333;
  text-align: center;

  margin-bottom: 44px;
`

export const CheckWrapper = styled.div`
  margin-right: 11px;

  width: 100%;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
  }
`

export const ArrowLeftWrapper = styled.div`
  width: 24px;
  height: 24px;

  margin-right: 10px;
`

export const ArrowRightWrapper = styled.div<{ arrowDown: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 30px;
  height: 30px;

  svg {
    max-height: initial;

    ${({ arrowDown }) =>
      arrowDown
        ? css`
            transform: rotate(90deg) translate(0px, 4px);
          `
        : css`
            transform: rotate(0deg) translate(0px, 4px);
          `}
  }
`

export const ArrowDownWrapper = styled.div<{ up: boolean }>`
  width: 16px;
  height: 11px;

  ${({ up = false }) =>
    up &&
    css`
      transform: rotate(180deg);
    `}
`

export const CalculateIconWrapper = styled.div`
  width: 60px;
  height: 60px;

  margin-right: 24px;

  svg {
    max-width: initial;
    max-height: initial;
  }
`

export const SolutionsIconWrapper = styled.div`
  width: 60px;
  height: 60px;

  margin-right: 24px;
`

export const MeasurementIconWrapper = styled.div`
  width: 60px;
  height: 60px;

  margin-right: 24px;
`

export const LinkWrapper = styled.div`
  text-align: center;

  margin-top: 16px;
  margin-bottom: 32px;
`

export const LinkMessage = styled.div<{ isError?: boolean }>`
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: center;
  color: ${({ isError }) => (isError ? '#ff453a' : '#333333')};

  padding-bottom: 10px;
`

export const LinkButton = styled.span<{ centerText?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ centerText = false }) => (centerText ? 'center' : 'flex-start')};

  color: #1c3775;
  font-size: 16px;
  line-height: 22.4px;
  white-space: nowrap;

  cursor: pointer;

  svg {
    margin-right: 6px;
  }
`

export const LinkAnchor = styled.a<{ centerText?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ centerText = false }) => (centerText ? 'center' : 'flex-start')};

  color: #1c3775;
  font-size: 16px;
  line-height: 22.4px;
  white-space: nowrap;
  text-decoration: none;

  cursor: pointer;

  svg {
    margin-right: 6px;
  }
`

export const SubTitleText = styled.span`
  font-size: 14px;
  font-weight: 400;

  margin-left: 10px;
  margin-right: 10px;
`

export const ModalWrapper = styled.div<{ show: boolean }>`
  position: fixed;
  background: rgba(0, 0, 0, 0.3);

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  transition: opacity 0.3s;

  z-index: 100;

  ${({ show }) =>
    show
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
          pointer-events: none;
        `}
`

export const ModalBody = styled.div<{ width?: number, isMobile?: boolean }>`
  background: #ffffff;
  border-radius: 10px;

  position: relative;

  padding: 52px 80px;

  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: ${({ width = 600 }) => css`${width}px`};
  max-height: 90vh;

  overflow-y: auto;

  ${({ isMobile = false }) =>
    isMobile &&
    css`
      padding: 25px;
      margin: 0 10px;
    `}
`

export const ModalContent = styled.div<{ isCenter?: boolean }>`
  width: 100%;

  text-align: ${({ isCenter = false }) => (isCenter ? 'center' : 'left')};
`

export const CloseButton = styled.div<{ isMobile?: boolean }>`
  position: absolute;

  ${({ isMobile = false }) =>
    isMobile
      ? css`
          top: 20px;
          right: 20px;
        `
      : css`
          top: 40px;
          right: 40px;
        `}

  cursor: pointer;
`

export const ModalTitle = styled.h2<{ isLeft?: boolean; isMobile?: boolean }>`
  font-size: ${({ isMobile = false }) => (isMobile ? 20 : 30)}px;
  font-weight: 700;
  line-height: 140%;

  margin-bottom: 25px;

  ${({ isLeft = false }) =>
    isLeft
      ? css`
          text-align: left;

          margin-top: -25px;
        `
      : css`
          text-align: center;

          margin-top: 0;
        `}
`

export const ModalSubTitle = styled.h3`
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  text-align: center;

  margin-bottom: 40px;
`

export const ModalDescription = styled.div`
  font-size: 16px;
  line-height: 140%;
  text-align: center;

  margin-bottom: 20px;
  padding: 0 10px;
`

export const ModalFooterWrapper = styled.div`
  margin-top: 40px;
`

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-size: 16px;
  color: #333333;

  ${LinkButton} {
    margin-right: 20px;
  }
`

export const ModalRow = styled.div<{ columnCount?: number, isMobile?: boolean }>`
  display: grid;
  gap: 16px;
  grid-template-columns: ${({ columnCount = 1, isMobile }) => css`repeat(${isMobile ? 1 : columnCount}, 1fr)`};
  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(1,1fr);
    gap: 0;
  }
`

export const ModalCol = styled.div<{ isMobile?: boolean }>`
  width: 100%;
`

export const CounterContainer = styled.div`
  min-width: 110px;
  max-width: 110px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #141b4d;
  font-weight: 700;
  margin-bottom: 12px;
`

export const CounterButton = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  font-size: 18px;
  line-height: 34px;
  cursor: pointer;
`

export const CounterValue = styled.div`
  width: 35px;
  text-align: center;
`


 