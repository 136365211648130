import React from 'react'
import { StageDescriptionBlockContentItemIcon } from 'src/react-app/views/calculator/styles'

export const HeatSaving5 = () => {
  return (
    <StageDescriptionBlockContentItemIcon title="Экономия на доп обогреве">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.6"
          d="M32.5 40H7.5C3.3575 40 0 36.6425 0 32.5V7.5C0 3.3575 3.3575 0 7.5 0H32.5C36.6425 0 40 3.3575 40 7.5V32.5C40 36.6425 36.6425 40 32.5 40Z"
          fill="#E0E3E9"
        />
        <path
          d="M24.811 23.4697H25.1868C26.7374 23.4697 28 24.6269 28 26.048C28 27.1659 27.2146 28.1127 26.1253 28.4675V28.9687C26.1253 29.538 25.6212 30 25 30C24.3788 30 23.8747 29.538 23.8747 28.9687V28.6242H23.1253C22.5041 28.6242 22 28.1622 22 27.5929C22 27.0236 22.5041 26.5616 23.1253 26.5616H25.189C25.4996 26.5616 25.7517 26.3306 25.7517 26.0459C25.7517 25.7613 25.4996 25.5303 25.189 25.5303H24.8132C23.2626 25.5303 22 24.3731 22 22.952C22 21.8341 22.7854 20.8873 23.8747 20.5325V20.0313C23.8747 19.462 24.3788 19 25 19C25.6212 19 26.1253 19.462 26.1253 20.0313V20.3758H26.8747C27.4959 20.3758 28 20.8378 28 21.4071C28 21.9764 27.4959 22.4384 26.8747 22.4384H24.811C24.5004 22.4384 24.2483 22.6694 24.2483 22.9541C24.2483 23.2366 24.5026 23.4697 24.811 23.4697Z"
          fill="#1C3775"
        />
        <path
          d="M24.1662 14.9656V11.6669C24.1662 11.2069 23.7925 10.8331 23.3325 10.8331C22.8725 10.8331 22.5 11.2069 22.5 11.6669V13.3356L19.3325 10.2381C19.0087 9.92062 18.4912 9.92062 18.1675 10.2381L10.3663 17.8644C10.1338 18.0994 10 18.4231 10 18.7506C10 19.4394 10.5612 20.0006 11.25 20.0006H12.5V24.5844C12.5 25.7331 13.435 26.6681 14.5837 26.6681H19.6675C20.1275 26.6681 20.5012 26.2956 20.5012 25.8344C20.5012 25.3731 20.1263 25.0006 19.6663 25.0006H14.5825C14.3525 25.0006 14.1662 24.8131 14.1662 24.5844V19.1681C14.1662 18.7081 13.7925 18.3344 13.3325 18.3344H12.2687L18.75 11.9994L24.2087 17.3381C24.5387 17.6606 25.0662 17.6544 25.3875 17.3256C25.7087 16.9969 25.7037 16.4681 25.375 16.1469L24.1662 14.9656Z"
          fill="#1C3775"
        />
      </svg>
    </StageDescriptionBlockContentItemIcon>
  )
}
