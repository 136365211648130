import styled, { css } from 'styled-components'
import { InputTypes } from '../../views/calculator/interface'

const width100Types = [InputTypes.text, InputTypes.number, InputTypes.password, InputTypes.email]

export const ControlGroup = styled.div`
  width: 100%;

  margin-bottom: 24px;
`

export const ControlTitle = styled.div<{ required?: boolean }>`
  font-size: 16px;
  margin-bottom: 12px;
  position: relative;
  width: fit-content;
  
  ${({ required = false}) => {
    if (required) {
      return css`
          &:after {
            content: '*';
        
            position: absolute;
            right: -10px;
            top: 0;
            color: red;
          }
        `
    }
  }}
`

export const InputWrapper = styled.div<{ type: InputTypes }>`
  display: inline-block;

  border-radius: 10px;

  position: relative;

  ${({ type }) =>
    width100Types.includes(type) &&
    css`
      width: 100%;
    `};
`

export const InputStyle = styled.input<{ type: InputTypes; isError?: boolean; disabled?: boolean }>`
  background-color: ${({ disabled }) => (disabled ? '#e1e1e1' : '#ffffff')};
  background-image: none;
  border: 1px solid ${({ isError }) => (isError ? '#ff453a' : '#e1e1e1')};
  border-radius: 10px;

  box-sizing: border-box;

  margin: 0;
  padding: 15px 23px;

  font-variant: tabular-nums;
  font-feature-settings: 'tnum';
  color: #333333;
  font-size: 16px;
  line-height: 1.5;

  list-style: none;

  position: relative;

  display: inline-block;

  ${({ type }) =>
    width100Types.includes(type) &&
    css`
      width: 100%;
    `};
  min-width: 0;

  transition: all 0.3s;
`

export const InputStatus = styled.span`
  position: absolute;

  bottom: -24px;
  left: 10px;

  font-size: 12px;
  line-height: 1.2;

  color: #ff453a;

  transform: translateY(-50%);

  svg {
    width: 14px;
    height: 14px;
  }
`

export const InputStatus2 = styled.span`
  position: absolute;
  cursor: pointer;

  top: calc(50% + 1px);
  right: 10px;

  font-size: 11px;
  line-height: 1.2;

  color: #ff453a;

  width: 21px;
  height: 20px;

  transform: translateY(-50%);

  svg {
    width: 20px;
    height: 20px;
  }
`


export const InputStatus3 = styled.span`
  position: absolute;
  cursor: pointer;

  top: 20px;
  right: 20px;

  font-size: 11px;
  line-height: 1.2;

  color: #ff453a;

  width: 21px;
  height: 20px;

  transform: translateY(-50%);

  svg {
    width: 35px;
    height: 35px;
  }
`

export const InputReq= styled.span`
  position: absolute;
  color: red;
  padding: 0px 0px 0px 8px;
  z-index: 4;
  font-size: 32px;
`
