import styled, { css } from 'styled-components'
import { ButtonViews } from '../../views/calculator/interface'

export const StyledButton = styled.button<{ typeView?: ButtonViews | null; disabled?: boolean }>`
  border-radius: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 15px;

  border: none;

  font-size: 16px;
  font-weight: 700;
  line-height: 22.4px;

  max-height: 56px;
  width: 100%;

  cursor: pointer;

  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #f3f3f3;
    color: #000000;
  }

  ${({ typeView = ButtonViews.DEFAULT, disabled }) => {
    if (disabled) {
      return css`
        background-color: #f3f4f6;
        color: #202124;

        opacity: 0.3;

        cursor: initial;
      `
    }
    switch (typeView) {
      case ButtonViews.BLUE:
        return css`
          background-color: #1c3775;
          color: #ffffff;
        `
      case ButtonViews.ORANGE:
        return css`
          background-color: #d69a2d;
          color: #ffffff;
        `
      case ButtonViews.LINK:
        return css`
          background-color: transparent;
          color: #1c3775;

          &:hover {
            background-color: transparent;
          }
        `
      case ButtonViews.DEFAULT:
      default:
        return css`
          background-color: #f3f4f6;
          color: #1c3775;
        `
    }
  }}
`
