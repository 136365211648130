import React, { FC, useCallback, useEffect, useState, useMemo, useContext } from 'react'

import {
  RadiobuttonBlock,
  RadiobuttonButton,
  RadiobuttonLabel,
  RadiobuttonText,
  RadiobuttonWrapper,
  RadiobuttonPrice,
  RadiobuttonTitle,
} from './styles'
import { IRadiobuttonItem, IRadiobuttonProps } from './interface'
import { ProductsContext } from '../../stage-calculator'
import { Info } from '../../../../../components'
import { ComponentShadow } from '../component-shadow'

const getIsCasements = filters => {
	const casements = filters.find(item => item?.['key'] === 'casements-index')?.['value'] || []
	return casements.includes(1)
}

export const Radiobutton: FC<IRadiobuttonProps> = props => {
  const {
    items = [] as IRadiobuttonItem[],
    id,
    onSelect,
    selected,
    data = { type: 'simple' },
    disabled = false,
  } = props
  const { type: typeView } = data

  const { calculateAdditionalElementPrice, itemSize, product, type, filters } = useContext(
    ProductsContext,
  )

  const [selectedItem, setSelectedItem] = useState<null | IRadiobuttonItem>(null)
  const [priceItem, setPriceItem] = useState(0)

  useEffect(() => {
    const newProduct = {  
      ...product,
      [id]: {
        ...product[id],
        price: true,
        value: true,
      },
    }
    const result = calculateAdditionalElementPrice(
      id,
      type,
      newProduct,
      itemSize,
      filters,
      undefined,
      1,
    )

    setPriceItem(result)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, product])

  useEffect(() => {
    if (selected) {
      if (selected.constructor === Array) {
        selected.forEach(item => {
          if (item[id]) {
            setSelectedItem(item[id])
          }
        })
      } else if (selected.constructor === Object) {
        setSelectedItem(selected as IRadiobuttonItem)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  const handleSelect = useCallback(
    (item: IRadiobuttonItem) => () => {
      setSelectedItem(item)
      onSelect(item)
    },
    [onSelect],
  )

  const isShowPrice = useMemo(() => {
    return priceItem > 0 || typeView === 'vertical'
  }, [priceItem, typeView])



  const title = useMemo(() => {
    const result = items.filter(item => item['title'])

    return result.length > 0 ? result[0]['title'] : null
  }, [items])



  const description = useMemo(() => {
    const result = items.filter(item => item['description'])

    return result.length > 0 ? result[0]['description'] : null
  }, [items])

// Скрывает проветривание, если нет поворотнооткидной створки
  const aerationIsCasements = useMemo(() => {
    if (id !== 'aeration') {
      return true
    }
    if (getIsCasements(filters)) {
      return true
    }
    return false
  }, [filters, id])

  return (
    <RadiobuttonWrapper>
      {disabled && <ComponentShadow />}
      {items?.length > 0 && title && (
        <RadiobuttonTitle>
          <span>{title}</span>
          <Info color="#bbc3d6">{description}</Info>
        </RadiobuttonTitle>
      )}
      {aerationIsCasements ? <RadiobuttonBlock type={typeView}>
        {items?.length > 0 &&
          items.map(item => (
            <RadiobuttonLabel
              htmlFor={`${id}-${item['id']}`}
              onClick={handleSelect(item)}
              key={item['id']}
              isClearView={!isShowPrice}
              type={typeView}
            >
              <RadiobuttonButton
                type="radio"
                value={item['value']}
                name={id}
                id={`${id}-${item['id']}`}
                checked={item['id'] === selectedItem?.id}
              />
              <RadiobuttonText>{item['text']}</RadiobuttonText>
              {item['info'] && (
                <>
                  &nbsp;
                  <Info color="#BBC3D6" showIcon>
                    {item['info']}
                  </Info>
                </>
              )}
            </RadiobuttonLabel>
          ))}
      </RadiobuttonBlock> : null}
      {aerationIsCasements && isShowPrice && priceItem > 0 && <RadiobuttonPrice>Цена: {priceItem} ₽</RadiobuttonPrice>}
    </RadiobuttonWrapper>
  )
}
