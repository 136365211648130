import React, { FC } from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'
import { IContainerSquareProps } from '../../interface'
import { ContainerBackground } from '../container-background'

export const ContainerSquare62Casement: FC<IContainerSquareProps> = props => {
  const { onCasementClick, withoutBackground } = props

  return (
    <ProductImageWrapper isClickable>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <rect width="220" height="333" fill="white" />
        <rect x="10" y="18" width="186" height="286" fill="#E8EAEE" />
        <rect x="10" y="18" width="186" height="286" fill="url(#6-2-pattern0)" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M210 10H10V292H24V28H196V292H210V10Z"
          fill="#C4C4C4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M210 292H10V308H0V316H10H210H220V308H210V292Z"
          fill="url(#6-2-paint0_linear)"
        />
        <path d="M10 10L24 28.1525V291.982L10 308V10Z" fill="#E9EBEF" />
        <path d="M10 10L24 28.1525V291.982L10 308V10Z" fill="#020202" fillOpacity="0.06" />
        <path d="M210 10L196 28.1525V291.982L210 308V10Z" fill="#E9EBEF" />
        <path d="M210 10L196 28.1525V291.982L210 308V10Z" fill="#020202" fillOpacity="0.06" />
        <rect y="316" width="220" height="17" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 27H196V292H24V27ZM37 40H101V106H37V40ZM183 40H119V106H183V40ZM37 127H101V193H37V127ZM183 127H119V193H183V127ZM37 212H101V278H37V212ZM183 212H119V278H183V212Z"
          fill="#E8EAEE"
        />
        <g
          filter="url(#6-2-filter0_i)"
          className="casement"
          data-index="0"
          onClick={onCasementClick(0)}
        >
          <rect x="37" y="40" width="64" height="66" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="37.47"
          y="40.47"
          width="63.0601"
          height="65.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#6-2-filter1_i)"
          className="casement"
          data-index="1"
          onClick={onCasementClick(1)}
        >
          <rect x="37" y="127" width="64" height="66" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="37.47"
          y="127.47"
          width="63.0601"
          height="65.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#6-2-filter2_i)"
          className="casement"
          data-index="2"
          onClick={onCasementClick(2)}
        >
          <rect x="37" y="212" width="64" height="66" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="37.47"
          y="212.47"
          width="63.0601"
          height="65.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#6-2-filter3_i)"
          className="casement"
          data-index="3"
          onClick={onCasementClick(3)}
        >
          <rect x="119" y="40" width="64" height="66" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="119.47"
          y="40.47"
          width="63.0601"
          height="65.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#6-2-filter4_i)"
          className="casement"
          data-index="4"
          onClick={onCasementClick(4)}
        >
          <rect x="119" y="127" width="64" height="66" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="119.47"
          y="127.47"
          width="63.0601"
          height="65.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#6-2-filter5_i)"
          className="casement"
          data-index="5"
          onClick={onCasementClick(5)}
        >
          <rect x="119" y="212" width="64" height="66" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="119.47"
          y="212.47"
          width="63.0601"
          height="65.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <path
          d="M77.9851 66.7413H75.4471C74.8002 66.7413 74.2528 66.2346 74.2528 65.6357V65.4054C74.2528 64.8065 74.8002 64.2998 75.4471 64.2998H77.9851C78.632 64.2998 79.1794 64.8065 79.1794 65.4054V65.6357C79.1794 66.2576 78.6569 66.7413 77.9851 66.7413Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M60.4262 69.5652C60.7247 69.5652 60.7993 69.5652 61.1972 69.5652C61.9432 69.5652 62.4903 70.1052 62.4903 70.833C62.4903 71.5843 61.9184 72.1008 61.1474 72.1008C59.705 72.1008 58.8098 72.1008 57.3674 72.1008C56.8203 72.1008 56.3727 71.913 56.1488 71.4199C55.9499 71.1147 55.8255 70.2695 56.6213 69.5652C57.1436 68.9782 62.9131 62.7565 63.9327 61.6765C63.9824 61.6061 63.9327 61.5122 63.8581 61.5122C63.8332 61.5122 63.8332 61.5122 63.8084 61.5122C61.6448 61.5122 59.4812 61.5122 57.3177 61.5122C56.6711 61.5122 56.1737 61.1365 56.0494 60.573C55.9002 59.9156 56.1986 59.3522 56.7706 59.0939C56.9446 59.0235 57.1187 59 57.2928 59C60.5008 59 63.684 59 66.8921 59C67.5138 59 67.9366 59.1878 68.1852 59.7278C68.5085 60.4087 68.1604 60.8783 67.9366 61.1835C67.4889 61.7235 62.938 66.56 60.4262 69.2834C60.4014 69.3069 60.3765 69.3539 60.3268 69.3773C60.277 69.4478 60.3268 69.5652 60.4262 69.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M67.1856 72.1007H64.6476C64.0007 72.1007 63.4533 71.5816 63.4533 70.9681V70.7322C63.4533 70.1187 64.0007 69.5996 64.6476 69.5996H67.1856C67.8325 69.5996 68.3799 70.1187 68.3799 70.7322V70.9681C68.3799 71.6052 67.8325 72.1007 67.1856 72.1007Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M70.7154 65.5152C70.7154 63.7341 70.7154 61.9764 70.7154 60.1952C70.7154 59.4922 71.2374 59 71.9832 59C75.2148 59 78.4464 59 81.678 59C82.4238 59 82.9458 59.5156 82.9458 60.2421C82.9458 60.9686 82.4238 61.5077 81.678 61.5077C78.9933 61.5077 76.3334 61.5077 73.6487 61.5077C73.4498 61.5077 73.4001 61.5545 73.4001 61.742C73.4001 64.2731 73.4001 66.8042 73.4001 69.3353C73.4001 69.5463 73.4747 69.5697 73.6487 69.5697C76.3086 69.5697 78.9933 69.5697 81.6532 69.5697C82.2498 69.5697 82.7221 69.8978 82.8961 70.39C83.095 70.9759 82.8713 71.5618 82.3989 71.8899C82.1752 72.0305 81.9018 72.1008 81.6283 72.1008C78.4464 72.1008 75.2397 72.1008 72.0578 72.1008C71.2374 72.1008 70.7403 71.5852 70.7403 70.8118C70.7154 69.0307 70.7154 67.273 70.7154 65.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M57.7054 77.0418V77.6986H56V77.0418H56.4263V75.2589H56.0502V74.6021H57.6803V75.2589H57.3041V77.0418H57.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M61.5344 77.7581H60.7737L60.651 77.5453C60.3811 77.6872 60.0621 77.7581 59.7676 77.7581C58.9334 77.7345 58.1482 77.167 58.1482 76.1503C58.1482 75.1809 58.8107 74.5425 59.8167 74.5425C60.651 74.5425 61.4607 75.0863 61.4607 76.1503C61.4607 76.5286 61.3626 76.8124 61.2154 77.0724L61.5589 77.6399V77.7581H61.5344ZM59.7922 77.0015C60.1848 77.0015 60.6019 76.7178 60.6019 76.1503C60.6019 75.5828 60.1848 75.2991 59.7922 75.2991C59.375 75.2991 58.9824 75.5828 58.9824 76.1503C58.9824 76.7178 59.375 77.0015 59.7922 77.0015Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M63.7835 76.7488C63.7835 76.9601 64.0291 77.0774 64.2992 77.0774C64.5448 77.0774 64.7904 76.9601 64.7904 76.7958C64.7904 76.4906 64.422 76.4906 64.201 76.4672C63.6116 76.4202 63.0223 76.209 63.0223 75.5518C63.0223 74.8711 63.6116 74.5425 64.2747 74.5425C64.8886 74.5425 65.5271 74.8711 65.5271 75.5518H64.7167C64.7167 75.3405 64.4957 75.2466 64.2747 75.2466C64.0782 75.2466 63.8326 75.2936 63.8326 75.5283C63.8326 75.7395 64.0291 75.7865 64.3238 75.81C65.0114 75.8569 65.6007 76.0212 65.6007 76.7723C65.6007 77.4295 64.9377 77.7581 64.2747 77.7581C63.5871 77.7581 62.924 77.4295 62.9486 76.7488H63.7835Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M68.2288 75.3112H67.0377V75.7603H68.1295V76.4694H67.0377V76.9658H68.2536V77.6986H66.1692V74.6021H68.2039V75.3112H68.2288Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M71.603 77.5804V77.6986H70.7222L70.1594 76.7295H69.719V77.6986H68.8871V74.6021C69.3765 74.6021 69.8413 74.6021 70.3062 74.6021C71.0892 74.6021 71.4562 75.1694 71.4562 75.713C71.4562 76.0676 71.285 76.4222 70.9669 76.6113L71.603 77.5804ZM70.3062 76.0676C70.5509 76.0676 70.6488 75.8785 70.6488 75.6658C70.6488 75.4766 70.5265 75.2875 70.3062 75.2875H69.719V76.0676H70.3062Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M73.2761 76.3343L73.553 75.5275L73.9556 74.6021H74.8868V74.7207L73.4523 77.7582H73.1L71.6655 74.7207V74.6021H72.5967L73.0245 75.5275L73.2761 76.3343Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M76.7814 77.0418V77.6986H75.0761V77.0418H75.5024V75.2589H75.1262V74.6021H76.7564V75.2589H76.3802V77.0418H76.7814Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M78.8348 74.6021C79.5091 74.6021 80.2582 74.9553 80.383 75.7797H79.559C79.4341 75.45 79.1844 75.3322 78.8348 75.3322C78.3354 75.3322 78.0108 75.6619 78.0108 76.1801C78.0108 76.6276 78.3354 77.028 78.8348 77.028C79.1844 77.028 79.4841 76.8631 79.559 76.5334H80.383C80.2582 77.4049 79.534 77.7582 78.8348 77.7582C77.9858 77.7582 77.1618 77.2164 77.1618 76.1801C77.1618 75.1202 77.9858 74.6021 78.8348 74.6021Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M82.9442 75.3112H81.7531V75.7603H82.8449V76.4694H81.7531V76.9658H82.969V77.6986H80.8847V74.6021H82.9194V75.3112H82.9442Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M77.9851 153.741H75.4471C74.8002 153.741 74.2528 153.235 74.2528 152.636V152.405C74.2528 151.807 74.8002 151.3 75.4471 151.3H77.9851C78.632 151.3 79.1794 151.807 79.1794 152.405V152.636C79.1794 153.258 78.6569 153.741 77.9851 153.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M60.4262 156.565C60.7247 156.565 60.7993 156.565 61.1972 156.565C61.9432 156.565 62.4903 157.105 62.4903 157.833C62.4903 158.584 61.9184 159.101 61.1474 159.101C59.705 159.101 58.8098 159.101 57.3674 159.101C56.8203 159.101 56.3727 158.913 56.1488 158.42C55.9499 158.115 55.8255 157.27 56.6213 156.565C57.1436 155.978 62.9131 149.757 63.9327 148.677C63.9824 148.606 63.9327 148.512 63.8581 148.512C63.8332 148.512 63.8332 148.512 63.8084 148.512C61.6448 148.512 59.4812 148.512 57.3177 148.512C56.6711 148.512 56.1737 148.137 56.0494 147.573C55.9002 146.916 56.1986 146.352 56.7706 146.094C56.9446 146.023 57.1187 146 57.2928 146C60.5008 146 63.684 146 66.8921 146C67.5138 146 67.9366 146.188 68.1852 146.728C68.5085 147.409 68.1604 147.878 67.9366 148.183C67.4889 148.723 62.938 153.56 60.4262 156.283C60.4014 156.307 60.3765 156.354 60.3268 156.377C60.277 156.448 60.3268 156.565 60.4262 156.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M67.1856 159.101H64.6476C64.0007 159.101 63.4533 158.582 63.4533 157.968V157.732C63.4533 157.119 64.0007 156.6 64.6476 156.6H67.1856C67.8325 156.6 68.3799 157.119 68.3799 157.732V157.968C68.3799 158.605 67.8325 159.101 67.1856 159.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M70.7154 152.515C70.7154 150.734 70.7154 148.976 70.7154 147.195C70.7154 146.492 71.2374 146 71.9832 146C75.2148 146 78.4464 146 81.678 146C82.4238 146 82.9458 146.516 82.9458 147.242C82.9458 147.969 82.4238 148.508 81.678 148.508C78.9933 148.508 76.3334 148.508 73.6487 148.508C73.4498 148.508 73.4001 148.555 73.4001 148.742C73.4001 151.273 73.4001 153.804 73.4001 156.335C73.4001 156.546 73.4747 156.57 73.6487 156.57C76.3086 156.57 78.9933 156.57 81.6532 156.57C82.2498 156.57 82.7221 156.898 82.8961 157.39C83.095 157.976 82.8713 158.562 82.3989 158.89C82.1752 159.03 81.9018 159.101 81.6283 159.101C78.4464 159.101 75.2397 159.101 72.0578 159.101C71.2374 159.101 70.7403 158.585 70.7403 157.812C70.7154 156.031 70.7154 154.273 70.7154 152.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M57.7054 164.042V164.699H56V164.042H56.4263V162.259H56.0502V161.602H57.6803V162.259H57.3041V164.042H57.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M61.5344 164.758H60.7737L60.651 164.545C60.3811 164.687 60.0621 164.758 59.7676 164.758C58.9334 164.734 58.1482 164.167 58.1482 163.15C58.1482 162.181 58.8107 161.542 59.8167 161.542C60.651 161.542 61.4607 162.086 61.4607 163.15C61.4607 163.529 61.3626 163.812 61.2154 164.072L61.5589 164.64V164.758H61.5344ZM59.7922 164.002C60.1848 164.002 60.6019 163.718 60.6019 163.15C60.6019 162.583 60.1848 162.299 59.7922 162.299C59.375 162.299 58.9824 162.583 58.9824 163.15C58.9824 163.718 59.375 164.002 59.7922 164.002Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M63.7835 163.749C63.7835 163.96 64.0291 164.077 64.2992 164.077C64.5448 164.077 64.7904 163.96 64.7904 163.796C64.7904 163.491 64.422 163.491 64.201 163.467C63.6116 163.42 63.0223 163.209 63.0223 162.552C63.0223 161.871 63.6116 161.542 64.2747 161.542C64.8886 161.542 65.5271 161.871 65.5271 162.552H64.7167C64.7167 162.341 64.4957 162.247 64.2747 162.247C64.0782 162.247 63.8326 162.294 63.8326 162.528C63.8326 162.74 64.0291 162.786 64.3238 162.81C65.0114 162.857 65.6007 163.021 65.6007 163.772C65.6007 164.43 64.9377 164.758 64.2747 164.758C63.5871 164.758 62.924 164.43 62.9486 163.749H63.7835Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M68.2288 162.311H67.0377V162.76H68.1295V163.469H67.0377V163.966H68.2536V164.699H66.1692V161.602H68.2039V162.311H68.2288Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M71.603 164.58V164.699H70.7222L70.1594 163.729H69.719V164.699H68.8871V161.602C69.3765 161.602 69.8413 161.602 70.3062 161.602C71.0892 161.602 71.4562 162.169 71.4562 162.713C71.4562 163.068 71.285 163.422 70.9669 163.611L71.603 164.58ZM70.3062 163.068C70.5509 163.068 70.6488 162.878 70.6488 162.666C70.6488 162.477 70.5265 162.288 70.3062 162.288H69.719V163.068H70.3062Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M73.2761 163.334L73.553 162.528L73.9556 161.602H74.8868V161.721L73.4523 164.758H73.1L71.6655 161.721V161.602H72.5967L73.0245 162.528L73.2761 163.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M76.7814 164.042V164.699H75.0761V164.042H75.5024V162.259H75.1262V161.602H76.7564V162.259H76.3802V164.042H76.7814Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M78.8348 161.602C79.5091 161.602 80.2582 161.955 80.383 162.78H79.559C79.4341 162.45 79.1844 162.332 78.8348 162.332C78.3354 162.332 78.0108 162.662 78.0108 163.18C78.0108 163.628 78.3354 164.028 78.8348 164.028C79.1844 164.028 79.4841 163.863 79.559 163.533H80.383C80.2582 164.405 79.534 164.758 78.8348 164.758C77.9858 164.758 77.1618 164.216 77.1618 163.18C77.1618 162.12 77.9858 161.602 78.8348 161.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M82.9442 162.311H81.7531V162.76H82.8449V163.469H81.7531V163.966H82.969V164.699H80.8847V161.602H82.9194V162.311H82.9442Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M77.9851 238.741H75.4471C74.8002 238.741 74.2528 238.235 74.2528 237.636V237.405C74.2528 236.807 74.8002 236.3 75.4471 236.3H77.9851C78.632 236.3 79.1794 236.807 79.1794 237.405V237.636C79.1794 238.258 78.6569 238.741 77.9851 238.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M60.4262 241.565C60.7247 241.565 60.7993 241.565 61.1972 241.565C61.9432 241.565 62.4903 242.105 62.4903 242.833C62.4903 243.584 61.9184 244.101 61.1474 244.101C59.705 244.101 58.8098 244.101 57.3674 244.101C56.8203 244.101 56.3727 243.913 56.1488 243.42C55.9499 243.115 55.8255 242.27 56.6213 241.565C57.1436 240.978 62.9131 234.757 63.9327 233.677C63.9824 233.606 63.9327 233.512 63.8581 233.512C63.8332 233.512 63.8332 233.512 63.8084 233.512C61.6448 233.512 59.4812 233.512 57.3177 233.512C56.6711 233.512 56.1737 233.137 56.0494 232.573C55.9002 231.916 56.1986 231.352 56.7706 231.094C56.9446 231.023 57.1187 231 57.2928 231C60.5008 231 63.684 231 66.8921 231C67.5138 231 67.9366 231.188 68.1852 231.728C68.5085 232.409 68.1604 232.878 67.9366 233.183C67.4889 233.723 62.938 238.56 60.4262 241.283C60.4014 241.307 60.3765 241.354 60.3268 241.377C60.277 241.448 60.3268 241.565 60.4262 241.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M67.1856 244.101H64.6476C64.0007 244.101 63.4533 243.582 63.4533 242.968V242.732C63.4533 242.119 64.0007 241.6 64.6476 241.6H67.1856C67.8325 241.6 68.3799 242.119 68.3799 242.732V242.968C68.3799 243.605 67.8325 244.101 67.1856 244.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M70.7154 237.515C70.7154 235.734 70.7154 233.976 70.7154 232.195C70.7154 231.492 71.2374 231 71.9832 231C75.2148 231 78.4464 231 81.678 231C82.4238 231 82.9458 231.516 82.9458 232.242C82.9458 232.969 82.4238 233.508 81.678 233.508C78.9933 233.508 76.3334 233.508 73.6487 233.508C73.4498 233.508 73.4001 233.555 73.4001 233.742C73.4001 236.273 73.4001 238.804 73.4001 241.335C73.4001 241.546 73.4747 241.57 73.6487 241.57C76.3086 241.57 78.9933 241.57 81.6532 241.57C82.2498 241.57 82.7221 241.898 82.8961 242.39C83.095 242.976 82.8713 243.562 82.3989 243.89C82.1752 244.03 81.9018 244.101 81.6283 244.101C78.4464 244.101 75.2397 244.101 72.0578 244.101C71.2374 244.101 70.7403 243.585 70.7403 242.812C70.7154 241.031 70.7154 239.273 70.7154 237.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M57.7054 249.042V249.699H56V249.042H56.4263V247.259H56.0502V246.602H57.6803V247.259H57.3041V249.042H57.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M61.5344 249.758H60.7737L60.651 249.545C60.3811 249.687 60.0621 249.758 59.7676 249.758C58.9334 249.734 58.1482 249.167 58.1482 248.15C58.1482 247.181 58.8107 246.542 59.8167 246.542C60.651 246.542 61.4607 247.086 61.4607 248.15C61.4607 248.529 61.3626 248.812 61.2154 249.072L61.5589 249.64V249.758H61.5344ZM59.7922 249.002C60.1848 249.002 60.6019 248.718 60.6019 248.15C60.6019 247.583 60.1848 247.299 59.7922 247.299C59.375 247.299 58.9824 247.583 58.9824 248.15C58.9824 248.718 59.375 249.002 59.7922 249.002Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M63.7835 248.749C63.7835 248.96 64.0291 249.077 64.2992 249.077C64.5448 249.077 64.7904 248.96 64.7904 248.796C64.7904 248.491 64.422 248.491 64.201 248.467C63.6116 248.42 63.0223 248.209 63.0223 247.552C63.0223 246.871 63.6116 246.542 64.2747 246.542C64.8886 246.542 65.5271 246.871 65.5271 247.552H64.7167C64.7167 247.341 64.4957 247.247 64.2747 247.247C64.0782 247.247 63.8326 247.294 63.8326 247.528C63.8326 247.74 64.0291 247.786 64.3238 247.81C65.0114 247.857 65.6007 248.021 65.6007 248.772C65.6007 249.43 64.9377 249.758 64.2747 249.758C63.5871 249.758 62.924 249.43 62.9486 248.749H63.7835Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M68.2288 247.311H67.0377V247.76H68.1295V248.469H67.0377V248.966H68.2536V249.699H66.1692V246.602H68.2039V247.311H68.2288Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M71.603 249.58V249.699H70.7222L70.1594 248.729H69.719V249.699H68.8871V246.602C69.3765 246.602 69.8413 246.602 70.3062 246.602C71.0892 246.602 71.4562 247.169 71.4562 247.713C71.4562 248.068 71.285 248.422 70.9669 248.611L71.603 249.58ZM70.3062 248.068C70.5509 248.068 70.6488 247.878 70.6488 247.666C70.6488 247.477 70.5265 247.288 70.3062 247.288H69.719V248.068H70.3062Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M73.2761 248.334L73.553 247.528L73.9556 246.602H74.8868V246.721L73.4523 249.758H73.1L71.6655 246.721V246.602H72.5967L73.0245 247.528L73.2761 248.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M76.7814 249.042V249.699H75.0761V249.042H75.5024V247.259H75.1262V246.602H76.7564V247.259H76.3802V249.042H76.7814Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M78.8348 246.602C79.5091 246.602 80.2582 246.955 80.383 247.78H79.559C79.4341 247.45 79.1844 247.332 78.8348 247.332C78.3354 247.332 78.0108 247.662 78.0108 248.18C78.0108 248.628 78.3354 249.028 78.8348 249.028C79.1844 249.028 79.4841 248.863 79.559 248.533H80.383C80.2582 249.405 79.534 249.758 78.8348 249.758C77.9858 249.758 77.1618 249.216 77.1618 248.18C77.1618 247.12 77.9858 246.602 78.8348 246.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M82.9442 247.311H81.7531V247.76H82.8449V248.469H81.7531V248.966H82.969V249.699H80.8847V246.602H82.9194V247.311H82.9442Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M159.985 66.7413H157.447C156.8 66.7413 156.253 66.2346 156.253 65.6357V65.4054C156.253 64.8065 156.8 64.2998 157.447 64.2998H159.985C160.632 64.2998 161.179 64.8065 161.179 65.4054V65.6357C161.179 66.2576 160.657 66.7413 159.985 66.7413Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M142.426 69.5652C142.725 69.5652 142.799 69.5652 143.197 69.5652C143.943 69.5652 144.49 70.1052 144.49 70.833C144.49 71.5843 143.918 72.1008 143.147 72.1008C141.705 72.1008 140.81 72.1008 139.367 72.1008C138.82 72.1008 138.373 71.913 138.149 71.4199C137.95 71.1147 137.826 70.2695 138.621 69.5652C139.144 68.9782 144.913 62.7565 145.933 61.6765C145.982 61.6061 145.933 61.5122 145.858 61.5122C145.833 61.5122 145.833 61.5122 145.808 61.5122C143.645 61.5122 141.481 61.5122 139.318 61.5122C138.671 61.5122 138.174 61.1365 138.049 60.573C137.9 59.9156 138.199 59.3522 138.771 59.0939C138.945 59.0235 139.119 59 139.293 59C142.501 59 145.684 59 148.892 59C149.514 59 149.937 59.1878 150.185 59.7278C150.509 60.4087 150.16 60.8783 149.937 61.1835C149.489 61.7235 144.938 66.56 142.426 69.2834C142.401 69.3069 142.376 69.3539 142.327 69.3773C142.277 69.4478 142.327 69.5652 142.426 69.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M149.186 72.1007H146.648C146.001 72.1007 145.453 71.5816 145.453 70.9681V70.7322C145.453 70.1187 146.001 69.5996 146.648 69.5996H149.186C149.832 69.5996 150.38 70.1187 150.38 70.7322V70.9681C150.38 71.6052 149.832 72.1007 149.186 72.1007Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M152.715 65.5152C152.715 63.7341 152.715 61.9764 152.715 60.1952C152.715 59.4922 153.237 59 153.983 59C157.215 59 160.446 59 163.678 59C164.424 59 164.946 59.5156 164.946 60.2421C164.946 60.9686 164.424 61.5077 163.678 61.5077C160.993 61.5077 158.333 61.5077 155.649 61.5077C155.45 61.5077 155.4 61.5545 155.4 61.742C155.4 64.2731 155.4 66.8042 155.4 69.3353C155.4 69.5463 155.475 69.5697 155.649 69.5697C158.309 69.5697 160.993 69.5697 163.653 69.5697C164.25 69.5697 164.722 69.8978 164.896 70.39C165.095 70.9759 164.871 71.5618 164.399 71.8899C164.175 72.0305 163.902 72.1008 163.628 72.1008C160.446 72.1008 157.24 72.1008 154.058 72.1008C153.237 72.1008 152.74 71.5852 152.74 70.8118C152.715 69.0307 152.715 67.273 152.715 65.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M139.705 77.0418V77.6986H138V77.0418H138.426V75.2589H138.05V74.6021H139.68V75.2589H139.304V77.0418H139.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M143.534 77.7581H142.774L142.651 77.5453C142.381 77.6872 142.062 77.7581 141.768 77.7581C140.933 77.7345 140.148 77.167 140.148 76.1503C140.148 75.1809 140.811 74.5425 141.817 74.5425C142.651 74.5425 143.461 75.0863 143.461 76.1503C143.461 76.5286 143.363 76.8124 143.215 77.0724L143.559 77.6399V77.7581H143.534ZM141.792 77.0015C142.185 77.0015 142.602 76.7178 142.602 76.1503C142.602 75.5828 142.185 75.2991 141.792 75.2991C141.375 75.2991 140.982 75.5828 140.982 76.1503C140.982 76.7178 141.375 77.0015 141.792 77.0015Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M145.784 76.7488C145.784 76.9601 146.029 77.0774 146.299 77.0774C146.545 77.0774 146.79 76.9601 146.79 76.7958C146.79 76.4906 146.422 76.4906 146.201 76.4672C145.612 76.4202 145.022 76.209 145.022 75.5518C145.022 74.8711 145.612 74.5425 146.275 74.5425C146.889 74.5425 147.527 74.8711 147.527 75.5518H146.717C146.717 75.3405 146.496 75.2466 146.275 75.2466C146.078 75.2466 145.833 75.2936 145.833 75.5283C145.833 75.7395 146.029 75.7865 146.324 75.81C147.011 75.8569 147.601 76.0212 147.601 76.7723C147.601 77.4295 146.938 77.7581 146.275 77.7581C145.587 77.7581 144.924 77.4295 144.949 76.7488H145.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M150.229 75.3112H149.038V75.7603H150.13V76.4694H149.038V76.9658H150.254V77.6986H148.169V74.6021H150.204V75.3112H150.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M153.603 77.5804V77.6986H152.722L152.159 76.7295H151.719V77.6986H150.887V74.6021C151.376 74.6021 151.841 74.6021 152.306 74.6021C153.089 74.6021 153.456 75.1694 153.456 75.713C153.456 76.0676 153.285 76.4222 152.967 76.6113L153.603 77.5804ZM152.306 76.0676C152.551 76.0676 152.649 75.8785 152.649 75.6658C152.649 75.4766 152.526 75.2875 152.306 75.2875H151.719V76.0676H152.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M155.276 76.3343L155.553 75.5275L155.956 74.6021H156.887V74.7207L155.452 77.7582H155.1L153.666 74.7207V74.6021H154.597L155.024 75.5275L155.276 76.3343Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M158.781 77.0418V77.6986H157.076V77.0418H157.502V75.2589H157.126V74.6021H158.756V75.2589H158.38V77.0418H158.781Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M160.835 74.6021C161.509 74.6021 162.258 74.9553 162.383 75.7797H161.559C161.434 75.45 161.184 75.3322 160.835 75.3322C160.335 75.3322 160.011 75.6619 160.011 76.1801C160.011 76.6276 160.335 77.028 160.835 77.028C161.184 77.028 161.484 76.8631 161.559 76.5334H162.383C162.258 77.4049 161.534 77.7582 160.835 77.7582C159.986 77.7582 159.162 77.2164 159.162 76.1801C159.162 75.1202 159.986 74.6021 160.835 74.6021Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M164.944 75.3112H163.753V75.7603H164.845V76.4694H163.753V76.9658H164.969V77.6986H162.885V74.6021H164.919V75.3112H164.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M159.985 153.741H157.447C156.8 153.741 156.253 153.235 156.253 152.636V152.405C156.253 151.807 156.8 151.3 157.447 151.3H159.985C160.632 151.3 161.179 151.807 161.179 152.405V152.636C161.179 153.258 160.657 153.741 159.985 153.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M142.426 156.565C142.725 156.565 142.799 156.565 143.197 156.565C143.943 156.565 144.49 157.105 144.49 157.833C144.49 158.584 143.918 159.101 143.147 159.101C141.705 159.101 140.81 159.101 139.367 159.101C138.82 159.101 138.373 158.913 138.149 158.42C137.95 158.115 137.826 157.27 138.621 156.565C139.144 155.978 144.913 149.757 145.933 148.677C145.982 148.606 145.933 148.512 145.858 148.512C145.833 148.512 145.833 148.512 145.808 148.512C143.645 148.512 141.481 148.512 139.318 148.512C138.671 148.512 138.174 148.137 138.049 147.573C137.9 146.916 138.199 146.352 138.771 146.094C138.945 146.023 139.119 146 139.293 146C142.501 146 145.684 146 148.892 146C149.514 146 149.937 146.188 150.185 146.728C150.509 147.409 150.16 147.878 149.937 148.183C149.489 148.723 144.938 153.56 142.426 156.283C142.401 156.307 142.376 156.354 142.327 156.377C142.277 156.448 142.327 156.565 142.426 156.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M149.186 159.101H146.648C146.001 159.101 145.453 158.582 145.453 157.968V157.732C145.453 157.119 146.001 156.6 146.648 156.6H149.186C149.832 156.6 150.38 157.119 150.38 157.732V157.968C150.38 158.605 149.832 159.101 149.186 159.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M152.715 152.515C152.715 150.734 152.715 148.976 152.715 147.195C152.715 146.492 153.237 146 153.983 146C157.215 146 160.446 146 163.678 146C164.424 146 164.946 146.516 164.946 147.242C164.946 147.969 164.424 148.508 163.678 148.508C160.993 148.508 158.333 148.508 155.649 148.508C155.45 148.508 155.4 148.555 155.4 148.742C155.4 151.273 155.4 153.804 155.4 156.335C155.4 156.546 155.475 156.57 155.649 156.57C158.309 156.57 160.993 156.57 163.653 156.57C164.25 156.57 164.722 156.898 164.896 157.39C165.095 157.976 164.871 158.562 164.399 158.89C164.175 159.03 163.902 159.101 163.628 159.101C160.446 159.101 157.24 159.101 154.058 159.101C153.237 159.101 152.74 158.585 152.74 157.812C152.715 156.031 152.715 154.273 152.715 152.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M139.705 164.042V164.699H138V164.042H138.426V162.259H138.05V161.602H139.68V162.259H139.304V164.042H139.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M143.534 164.758H142.774L142.651 164.545C142.381 164.687 142.062 164.758 141.768 164.758C140.933 164.734 140.148 164.167 140.148 163.15C140.148 162.181 140.811 161.542 141.817 161.542C142.651 161.542 143.461 162.086 143.461 163.15C143.461 163.529 143.363 163.812 143.215 164.072L143.559 164.64V164.758H143.534ZM141.792 164.002C142.185 164.002 142.602 163.718 142.602 163.15C142.602 162.583 142.185 162.299 141.792 162.299C141.375 162.299 140.982 162.583 140.982 163.15C140.982 163.718 141.375 164.002 141.792 164.002Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M145.784 163.749C145.784 163.96 146.029 164.077 146.299 164.077C146.545 164.077 146.79 163.96 146.79 163.796C146.79 163.491 146.422 163.491 146.201 163.467C145.612 163.42 145.022 163.209 145.022 162.552C145.022 161.871 145.612 161.542 146.275 161.542C146.889 161.542 147.527 161.871 147.527 162.552H146.717C146.717 162.341 146.496 162.247 146.275 162.247C146.078 162.247 145.833 162.294 145.833 162.528C145.833 162.74 146.029 162.786 146.324 162.81C147.011 162.857 147.601 163.021 147.601 163.772C147.601 164.43 146.938 164.758 146.275 164.758C145.587 164.758 144.924 164.43 144.949 163.749H145.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M150.229 162.311H149.038V162.76H150.13V163.469H149.038V163.966H150.254V164.699H148.169V161.602H150.204V162.311H150.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M153.603 164.58V164.699H152.722L152.159 163.729H151.719V164.699H150.887V161.602C151.376 161.602 151.841 161.602 152.306 161.602C153.089 161.602 153.456 162.169 153.456 162.713C153.456 163.068 153.285 163.422 152.967 163.611L153.603 164.58ZM152.306 163.068C152.551 163.068 152.649 162.878 152.649 162.666C152.649 162.477 152.526 162.288 152.306 162.288H151.719V163.068H152.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M155.276 163.334L155.553 162.528L155.956 161.602H156.887V161.721L155.452 164.758H155.1L153.666 161.721V161.602H154.597L155.024 162.528L155.276 163.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M158.781 164.042V164.699H157.076V164.042H157.502V162.259H157.126V161.602H158.756V162.259H158.38V164.042H158.781Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M160.835 161.602C161.509 161.602 162.258 161.955 162.383 162.78H161.559C161.434 162.45 161.184 162.332 160.835 162.332C160.335 162.332 160.011 162.662 160.011 163.18C160.011 163.628 160.335 164.028 160.835 164.028C161.184 164.028 161.484 163.863 161.559 163.533H162.383C162.258 164.405 161.534 164.758 160.835 164.758C159.986 164.758 159.162 164.216 159.162 163.18C159.162 162.12 159.986 161.602 160.835 161.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M164.944 162.311H163.753V162.76H164.845V163.469H163.753V163.966H164.969V164.699H162.885V161.602H164.919V162.311H164.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M159.985 238.741H157.447C156.8 238.741 156.253 238.235 156.253 237.636V237.405C156.253 236.807 156.8 236.3 157.447 236.3H159.985C160.632 236.3 161.179 236.807 161.179 237.405V237.636C161.179 238.258 160.657 238.741 159.985 238.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M142.426 241.565C142.725 241.565 142.799 241.565 143.197 241.565C143.943 241.565 144.49 242.105 144.49 242.833C144.49 243.584 143.918 244.101 143.147 244.101C141.705 244.101 140.81 244.101 139.367 244.101C138.82 244.101 138.373 243.913 138.149 243.42C137.95 243.115 137.826 242.27 138.621 241.565C139.144 240.978 144.913 234.757 145.933 233.677C145.982 233.606 145.933 233.512 145.858 233.512C145.833 233.512 145.833 233.512 145.808 233.512C143.645 233.512 141.481 233.512 139.318 233.512C138.671 233.512 138.174 233.137 138.049 232.573C137.9 231.916 138.199 231.352 138.771 231.094C138.945 231.023 139.119 231 139.293 231C142.501 231 145.684 231 148.892 231C149.514 231 149.937 231.188 150.185 231.728C150.509 232.409 150.16 232.878 149.937 233.183C149.489 233.723 144.938 238.56 142.426 241.283C142.401 241.307 142.376 241.354 142.327 241.377C142.277 241.448 142.327 241.565 142.426 241.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M149.186 244.101H146.648C146.001 244.101 145.453 243.582 145.453 242.968V242.732C145.453 242.119 146.001 241.6 146.648 241.6H149.186C149.832 241.6 150.38 242.119 150.38 242.732V242.968C150.38 243.605 149.832 244.101 149.186 244.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M152.715 237.515C152.715 235.734 152.715 233.976 152.715 232.195C152.715 231.492 153.237 231 153.983 231C157.215 231 160.446 231 163.678 231C164.424 231 164.946 231.516 164.946 232.242C164.946 232.969 164.424 233.508 163.678 233.508C160.993 233.508 158.333 233.508 155.649 233.508C155.45 233.508 155.4 233.555 155.4 233.742C155.4 236.273 155.4 238.804 155.4 241.335C155.4 241.546 155.475 241.57 155.649 241.57C158.309 241.57 160.993 241.57 163.653 241.57C164.25 241.57 164.722 241.898 164.896 242.39C165.095 242.976 164.871 243.562 164.399 243.89C164.175 244.03 163.902 244.101 163.628 244.101C160.446 244.101 157.24 244.101 154.058 244.101C153.237 244.101 152.74 243.585 152.74 242.812C152.715 241.031 152.715 239.273 152.715 237.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M139.705 249.042V249.699H138V249.042H138.426V247.259H138.05V246.602H139.68V247.259H139.304V249.042H139.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M143.534 249.758H142.774L142.651 249.545C142.381 249.687 142.062 249.758 141.768 249.758C140.933 249.734 140.148 249.167 140.148 248.15C140.148 247.181 140.811 246.542 141.817 246.542C142.651 246.542 143.461 247.086 143.461 248.15C143.461 248.529 143.363 248.812 143.215 249.072L143.559 249.64V249.758H143.534ZM141.792 249.002C142.185 249.002 142.602 248.718 142.602 248.15C142.602 247.583 142.185 247.299 141.792 247.299C141.375 247.299 140.982 247.583 140.982 248.15C140.982 248.718 141.375 249.002 141.792 249.002Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M145.784 248.749C145.784 248.96 146.029 249.077 146.299 249.077C146.545 249.077 146.79 248.96 146.79 248.796C146.79 248.491 146.422 248.491 146.201 248.467C145.612 248.42 145.022 248.209 145.022 247.552C145.022 246.871 145.612 246.542 146.275 246.542C146.889 246.542 147.527 246.871 147.527 247.552H146.717C146.717 247.341 146.496 247.247 146.275 247.247C146.078 247.247 145.833 247.294 145.833 247.528C145.833 247.74 146.029 247.786 146.324 247.81C147.011 247.857 147.601 248.021 147.601 248.772C147.601 249.43 146.938 249.758 146.275 249.758C145.587 249.758 144.924 249.43 144.949 248.749H145.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M150.229 247.311H149.038V247.76H150.13V248.469H149.038V248.966H150.254V249.699H148.169V246.602H150.204V247.311H150.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M153.603 249.58V249.699H152.722L152.159 248.729H151.719V249.699H150.887V246.602C151.376 246.602 151.841 246.602 152.306 246.602C153.089 246.602 153.456 247.169 153.456 247.713C153.456 248.068 153.285 248.422 152.967 248.611L153.603 249.58ZM152.306 248.068C152.551 248.068 152.649 247.878 152.649 247.666C152.649 247.477 152.526 247.288 152.306 247.288H151.719V248.068H152.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M155.276 248.334L155.553 247.528L155.956 246.602H156.887V246.721L155.452 249.758H155.1L153.666 246.721V246.602H154.597L155.024 247.528L155.276 248.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M158.781 249.042V249.699H157.076V249.042H157.502V247.259H157.126V246.602H158.756V247.259H158.38V249.042H158.781Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M160.835 246.602C161.509 246.602 162.258 246.955 162.383 247.78H161.559C161.434 247.45 161.184 247.332 160.835 247.332C160.335 247.332 160.011 247.662 160.011 248.18C160.011 248.628 160.335 249.028 160.835 249.028C161.184 249.028 161.484 248.863 161.559 248.533H162.383C162.258 249.405 161.534 249.758 160.835 249.758C159.986 249.758 159.162 249.216 159.162 248.18C159.162 247.12 159.986 246.602 160.835 246.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M164.944 247.311H163.753V247.76H164.845V248.469H163.753V248.966H164.969V249.699H162.885V246.602H164.919V247.311H164.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <defs>
          <pattern id="6-2-pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use
              xlinkHref="#image0"
              transform="translate(-2.07767 -0.027972) scale(0.00351032 0.0022839)"
            />
          </pattern>
          <filter
            id="6-2-filter0_i"
            x="37"
            y="40"
            width="64.9399"
            height="68.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="6-2-filter1_i"
            x="37"
            y="127"
            width="64.9399"
            height="68.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="6-2-filter2_i"
            x="37"
            y="212"
            width="64.9399"
            height="68.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="6-2-filter3_i"
            x="119"
            y="40"
            width="64.9399"
            height="68.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="6-2-filter4_i"
            x="119"
            y="127"
            width="64.9399"
            height="68.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="6-2-filter5_i"
            x="119"
            y="212"
            width="64.9399"
            height="68.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <linearGradient
            id="6-2-paint0_linear"
            x1="301.5"
            y1="291"
            x2="301.5"
            y2="316"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DBDDE1" />
            <stop offset="1" stopColor="#F9F9F9" />
          </linearGradient>
        </defs>
      </svg>
      {!withoutBackground && <ContainerBackground />}
    </ProductImageWrapper>
  )
}
