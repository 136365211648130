import React from 'react'
import { StageDescriptionBlockContentItemIcon } from 'src/react-app/views/calculator/styles'

export const Security3 = () => {
  return (
    <StageDescriptionBlockContentItemIcon title="Для окон на 1-2, последних этажах">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.6"
          d="M32.5 40H7.5C3.3575 40 0 36.6425 0 32.5V7.5C0 3.3575 3.3575 0 7.5 0H32.5C36.6425 0 40 3.3575 40 7.5V32.5C40 36.6425 36.6425 40 32.5 40Z"
          fill="#E0E3E9"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.667 28C12.748 28 12 27.2529 12 26.334V17.281V13.667C12 12.748 12.748 12 13.667 12H26.334C27.0152 12 27.6029 12.412 27.8607 13H23.5V13.334H13.667C13.4829 13.334 13.334 13.4839 13.334 13.667V16.614H23.5V17.948H20.667V26.667H23.5V28H13.667ZM13.334 26.334V17.948H19.333V26.667H13.667C13.4829 26.667 13.334 26.5181 13.334 26.334Z"
          fill="#1C3775"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3833 14.0253C23.6462 14.138 23.8492 14.295 23.8802 14.5634V18.2853C24.5817 18.2853 27.2337 19.1521 28.0129 19.4288C29.2694 19.8749 30.2379 20.834 29.9485 22.0745C29.7949 22.7327 28.4577 27.5119 28.2222 27.792C28.1284 27.9034 27.9631 28.0164 27.8037 28.0386C27.1101 28.1351 26.7843 27.6999 26.9405 27.1642C27.2944 25.9508 27.6852 24.7454 28.0391 23.5319C28.1047 23.307 28.1899 23.0848 28.2365 22.8559C28.1378 23.1869 26.6989 26.1883 26.4697 26.5812C26.3342 26.8135 25.0242 29.269 25.0049 29.339C24.9714 29.4607 25.532 30.3098 25.6327 30.4825C25.9147 30.966 27.6076 33.6409 27.6467 33.913C27.7886 34.901 26.3776 35.4007 25.7112 34.6081C25.5647 34.4338 22.7518 29.7288 22.7032 29.5857C22.5576 29.157 22.9395 28.3942 23.0694 27.9489C23.1849 27.5528 23.3111 27.1479 23.4617 26.7606L21.6831 27.792L18 27.8158L19.2006 27.792C19.5186 27.5193 19.6683 27.7843 20 27.5C20.1172 27.3995 22.6876 24.5588 22.7817 24.5185C22.982 24.0032 24.0064 21.7285 24.2726 21.1777C24.34 21.0381 24.4818 20.7822 24.4818 20.6396C24.3424 20.5798 22.6024 19.4731 22.5463 19.4064C22.4855 19.3341 22.4065 19.2281 22.3893 19.1373C22.3759 19.0665 22.3806 14.4872 22.4155 14.4064C22.563 14.0645 22.9933 13.9417 23.3833 14.0253Z"
          fill="#1C3775"
        />
        <path
          d="M29 19C27.8954 19 27 18.1046 27 17C27 15.8954 27.8954 15 29 15C30.1046 15 31 15.8954 31 17C31 18.1046 30.1046 19 29 19Z"
          fill="#1C3775"
          stroke="#1C3775"
          strokeWidth="0.0762"
        />
        <path
          d="M28.7234 11.7889C29.2427 11.254 29.2301 10.3993 28.6952 9.87993C28.1602 9.36057 27.3055 9.37321 26.7862 9.90816L10.1299 27.0644C9.61057 27.5994 9.62321 28.454 10.1582 28.9734C10.6931 29.4927 11.5478 29.4801 12.0671 28.9452L28.7234 11.7889Z"
          fill="#1C3775"
          stroke="#ECEEF2"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </StageDescriptionBlockContentItemIcon>
  )
}
