import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ModalMask, MODAL_ANIMATION_DURATION, FooterWarapper, YandexMapContainer } from './styles'
import Button from '../../../components/ui-kit/button'
import ButtonBackControl from './button-back-control'
import ButtonZoomControl from './buttons-zoom-control'
import {
  getAddressBalacer,
  getControlZoomPosition,
  getControlGeolocationPosition,
  getGeolocationControl,
  getCenterMark,
  сreateYandexMapCustomControl,
  setCenterMark,
} from './utils'
import InputAddress from './input-address'
import useNextModal from '../../../modals'
// import DeliveryInputAddress from 'src/components/measurement-edit-date-modal/delivery-input-address'

export function initHammerPan(element, onEvent) {
  /*
  const Hammer = window.Hammer
  const manager = new Hammer(element)
  manager.on('pan', onEvent)
  */
  return () => {
    //manager.destroy()
  }
}

const stopPropagation = event => event.stopPropagation()
function Modal({ resolve, destroy, ...dataProps }) {
  const { createModal } = useNextModal()
  const mapRef = useRef<any>()
  const mapContainerRef = useRef<any>()
  const centerMarkRef = useRef<any>()
  const [controlBackNode, setControlBackNode] = useState<any>({ current: null })
  const [controlZoomNode, setControlZoomNode] = useState<any>({ current: null })
  const [isOpen, setOpen] = useState(true)
  const [data, setData] = useState<any>({ value: '' })
  const [placeholder, setPlaceholder] = useState('Укажите адрес')
  const [isLoading, setLoading] = useState(false)

  const onClose = useCallback(
    props => {
      const formData = props?.['formData'] || {}
      resolve({ stageCode: props?.['stageCode'] || 'cancel', formData })
      setOpen(false)
      setTimeout(destroy, MODAL_ANIMATION_DURATION)
    },
    [resolve, destroy],
  )

  const updateAddress = useCallback(async ({ center }) => {
    const result = await getAddressBalacer(center)
    const nextData = {
      value: result?.['value'] ?? '',
      lat: parseFloat(result?.['lat']) ?? 0,
      lng: parseFloat(result?.['lng']) ?? 0,
      city: result?.['city'],
      street: result?.['street'],
      dom: result?.['house'],
    }
    const centerMark = centerMarkRef.current
    setCenterMark({ centerMark, address: nextData })

    setData(nextData)
  }, [])

  useEffect(() => {
    const center = [dataProps.lat || 55.76, dataProps.lng || 37.64]
    updateAddress({ center })
  }, [])

  useEffect(() => {
    const center = [dataProps.lat || 55.76, dataProps.lng || 37.64]
    const zoom = 16
    const controlGeolocation = getGeolocationControl(mapContainerRef.current.offsetHeight)
    const centerMark = getCenterMark(center)
    Object.assign(centerMarkRef, { current: centerMark })
    // @ts-ignore
    const map = new ymaps.Map(
      mapContainerRef.current,
      { center, controls: [controlGeolocation], zoom },
      {
        yandexMapDisablePoiInteractivity: true,
        suppressMapOpenBlock: true,
      },
    )

    let oldGeoPosition = null
    
    controlGeolocation.events.add('click', function (event) {
      // @ts-ignore
      ymaps.geolocation.get({
        provider: 'browser',
        // Включим автоматическое геокодирование результата.
        autoReverseGeocode: false
      }).then(function (result) {
        map.geoObjects.add(result.geoObjects)
        map.panTo(result.geoObjects.position)
        // Удаляем предыдущую метку геопозизии
        if (oldGeoPosition) {
          map.geoObjects.remove(oldGeoPosition)
        }
        oldGeoPosition = result.geoObjects
      })
    })

    // @ts-ignore
    /*
    ymaps.option.presetStorage.add('islands#geolocationIcon', {
      iconLayout: 'default#image',
      iconImageHref: 'static/svg/delivery/mark-geolocation.svg',
    })
    */

    mapRef.current = map
    // Добавляем маркер
    map.geoObjects.add(centerMark)
    // Кнопка назад
    const controlBack = сreateYandexMapCustomControl(node => setControlBackNode({ current: node }))
    map.controls.add(controlBack, {
      float: 'none',
      position: { top: 20, left: 20 },
    })
    // Увеличение или уменьшение карты
    const controlZoom = сreateYandexMapCustomControl(node => setControlZoomNode({ current: node }))
    map.controls.add(controlZoom, {
      float: 'none',
      position: getControlZoomPosition(mapContainerRef.current.offsetHeight),
    })

    const updateControls = () => {
      const height = mapContainerRef.current?.offsetHeight
      if (Number.isFinite(height) === false) {
        return
      }
      controlZoom.options.set('position', getControlZoomPosition(height))
      controlGeolocation.options.set('position', getControlGeolocationPosition(height))
    }

    const updateCenterMark = () => {
      const { globalPixelCenter, zoom } = map.action.getCurrentState()
      const coordinates = map.options.get('projection').fromGlobalPixels(globalPixelCenter, zoom)
      centerMark.geometry.setCoordinates(coordinates)
    }

    const updateBoundschange = async event => {
      const center = event.get('newCenter')
      if (center === event.get('oldCenter')) {
        return
      }
      updateCenterMark()
      updateControls()
      await updateAddress({ center })
    }
    // Событие начала движения
    map.events.add('actiontick', updateCenterMark)
    // Ловим событие, после перемещения по карте
    map.events.add('boundschange', updateBoundschange)

    map.events.add('actiontick', function () {
      const timeout = setTimeout(() => {
        setPlaceholder('Ищем вас')
      }, 1000)
      map.events.add('actionend', () => {
        clearTimeout(timeout)
        setPlaceholder('Укажите адрес')
      })
    })

   const destroyHammer = initHammerPan(mapContainerRef.current, (event) => {
      if (event.isFinal) {
        setLoading(false)
        return
      }
      setLoading(true)
    })

    return () => {
      map.destroy()
      map.events.remove('actiontick', updateCenterMark)
      map.events.remove('boundschange', updateBoundschange)
      //ymaps.domEvent.manager.remove(mapContainerRef.current, events)
      destroyHammer()
    }
  }, [updateAddress])

  const onZoom = useCallback((zoom = 0) => {
    const map = mapRef.current
    if (map) {
      map.setZoom(map.getZoom() + zoom, {
        checkZoomRange: true,
      })
    }
  }, [])

  

  const disabledConfirm = useMemo(() => Boolean(data.value) === false, [data])

  const onRemove = useCallback(() => {
    const address = { value: '' }
    const centerMark = centerMarkRef.current
    setCenterMark({ centerMark, address })
    setData(address)
  }, [])

  const onClick: any = useCallback(async () => {
 
  }, [createModal, onClose])

  const onConfirm = useCallback(() => {
    onClose({ stageCode: 'ok', formData: data })
  }, [onClose, data])

  return (
    <ModalMask isOpen={isOpen} onClick={stopPropagation}>
      <YandexMapContainer ref={mapContainerRef}>
        {/* @ts-ignore */}
        <ButtonBackControl ref={controlBackNode} onClick={onClose} />
        {/* @ts-ignore */}
        <ButtonZoomControl ref={controlZoomNode} onClick={onZoom} />
      </YandexMapContainer>
      <FooterWarapper>
        <InputAddress
          value={data}
          onClick={onClick}
          onRemove={onRemove}
          placeholder={placeholder}
          isLoading={isLoading}
        />
        <Button onClick={onConfirm} disabled={disabledConfirm} className="button-confirm">
          Привезите сюда
        </Button>
      </FooterWarapper>
    </ModalMask>
  )
}

export default Modal
