import React from 'react'
import { ProductImageWrapper } from '../../../components/product-images-generator/styles'

export const Casement21Loggia10Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <g filter="url(#filter0_d)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M100.028 21.4733C100.028 20.5835 99.307 19.8623 98.4173 19.8623C97.5276 19.8623 96.8063 20.5836 96.8063 21.4733V23.8907H96.8064V31.9478H96.8056V34.3653C96.8056 35.255 97.5268 35.9762 98.4165 35.9762C99.3062 35.9762 100.027 35.255 100.027 34.3653V32.6195H100.028V23.2198H100.028V21.4733Z"
              fill="url(#paint0_linear)"
            />
          </g>
          <rect
            width="3.2219"
            height="9.39966"
            transform="matrix(-1 0 0 1 100.028 23.2188)"
            fill="#ABABAB"
          />
          <path
            d="M100.028 20.6196C100.028 20.2014 99.6893 19.8623 99.271 19.8623H97.5637C97.1455 19.8623 96.8064 20.2014 96.8064 20.6196V23.8907H100.028V20.6196Z"
            fill="url(#paint1_linear)"
          />
          <path
            d="M96.8057 35.2193C96.8057 35.6375 97.1447 35.9766 97.563 35.9766H99.2703C99.6885 35.9766 100.028 35.6375 100.028 35.2193V31.9481H96.8057V35.2193Z"
            fill="url(#paint2_linear)"
          />
          <rect
            width="3.2219"
            height="6.71404"
            transform="matrix(-1 0 0 1 100.028 24.5625)"
            fill="url(#paint3_linear)"
          />
          <g filter="url(#filter1_d)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M100.028 109.294C100.028 108.404 99.307 107.683 98.4173 107.683C97.5276 107.683 96.8063 108.404 96.8063 109.294V111.711H96.8064V119.767H96.8056V122.185C96.8056 123.075 97.5268 123.796 98.4165 123.796C99.3062 123.796 100.027 123.075 100.027 122.185V120.439H100.028V111.039H100.028V109.294Z"
              fill="url(#paint4_linear)"
            />
          </g>
          <rect
            width="3.2219"
            height="9.39966"
            transform="matrix(-1 0 0 1 100.028 111.039)"
            fill="#ABABAB"
          />
          <path
            d="M100.028 108.44C100.028 108.022 99.6893 107.683 99.271 107.683H97.5637C97.1455 107.683 96.8064 108.022 96.8064 108.44V111.711H100.028V108.44Z"
            fill="url(#paint5_linear)"
          />
          <path
            d="M96.8057 123.039C96.8057 123.457 97.1447 123.796 97.563 123.796H99.2703C99.6885 123.796 100.028 123.457 100.028 123.039V119.767H96.8057V123.039Z"
            fill="url(#paint6_linear)"
          />
          <rect
            width="3.2219"
            height="6.71404"
            transform="matrix(-1 0 0 1 100.028 112.382)"
            fill="url(#paint7_linear)"
          />
          <g filter="url(#filter2_d)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M177.374 11H100.028V132.658H177.374V11ZM168.512 19.8625H108.891V123.796H168.512V19.8625Z"
              fill="#E8EAEE"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M177.374 11H100.028V132.658L100.834 132.658V11.8057H176.568V132.658L177.374 132.658V11Z"
              fill="#FBFBFB"
            />
            <rect
              x="103.678"
              y="14.6497"
              width="70.0462"
              height="114.359"
              stroke="#E2E4E8"
              strokeWidth="0.757295"
            />
            <g filter="url(#filter3_i)">
              <rect
                x="109.294"
                y="20.2651"
                width="58.815"
                height="103.128"
                stroke="#979797"
                strokeWidth="0.805684"
              />
            </g>
          </g>
          <rect
            width="5.63979"
            height="10.8996"
            rx="2.8199"
            transform="matrix(-1 0 0 1 175.763 65.7861)"
            fill="#9B9DA2"
            fillOpacity="0.19"
          />
          <g filter="url(#filter4_d)">
            <rect
              width="4.51183"
              height="9.8615"
              rx="2.25592"
              transform="matrix(-1 0 0 1 175.197 66.3057)"
              fill="#E8EAEE"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M174.728 75.289C174.315 75.823 173.668 76.1669 172.941 76.1669C171.695 76.1669 170.685 75.1569 170.685 73.911V68.5613C170.685 67.7653 171.098 67.0656 171.72 66.6641C171.426 67.0452 171.251 67.5232 171.251 68.042V73.3917C171.251 74.6376 172.261 75.6476 173.507 75.6476C173.956 75.6476 174.376 75.5159 174.728 75.289Z"
            fill="#DFE0E2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M172.941 66.3057C174.187 66.3057 175.197 67.3157 175.197 68.5616V73.3922C175.197 73.4027 175.197 73.4132 175.197 73.4237V69.0291C175.197 67.7832 174.187 66.7732 172.941 66.7732C171.706 66.7732 170.702 67.7662 170.685 68.9976V68.5616C170.685 67.3157 171.695 66.3057 172.941 66.3057Z"
            fill="#FBFBFB"
          />
          <g filter="url(#filter5_d)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M171.734 70.2101V70.0447C171.734 69.4726 172.198 69.0088 172.77 69.0088C172.801 69.0088 172.832 69.0098 172.863 69.0119C172.889 69.0101 172.916 69.0093 172.943 69.0093C173.61 69.0093 174.151 69.5504 174.151 70.2178V70.3816C174.151 70.3844 174.151 70.3872 174.151 70.3899L174.151 86.331C174.151 86.9985 173.61 87.5395 172.943 87.5395C172.275 87.5395 171.734 86.9985 171.734 86.331L171.734 73.0377C171.734 73.0377 171.734 73.0377 171.734 73.0377C171.734 73.0377 171.734 73.0377 171.734 73.0377V70.2178C171.734 70.2152 171.734 70.2127 171.734 70.2101Z"
              fill="#E8EAEE"
            />
          </g>
          <path
            d="M110.1 122.097V21.5604L167.097 71.8289L110.1 122.097Z"
            stroke="white"
            strokeWidth="0.805684"
          />
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="95.2911"
            y="18.3477"
            width="7.76651"
            height="20.6577"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.757295" dy="0.757295" />
            <feGaussianBlur stdDeviation="1.13594" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter1_d"
            x="95.2911"
            y="106.168"
            width="7.76651"
            height="20.657"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.757295" dy="0.757295" />
            <feGaussianBlur stdDeviation="1.13594" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter2_d"
            x="98.417"
            y="9.38863"
            width="83.7912"
            height="128.104"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.61137" dy="1.61137" />
            <feGaussianBlur stdDeviation="1.61137" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter3_i"
            x="108.891"
            y="19.8623"
            width="60.3779"
            height="106.205"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.757295" dy="2.27188" />
            <feGaussianBlur stdDeviation="1.51459" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter4_d"
            x="169.171"
            y="64.7911"
            width="9.0556"
            height="14.4053"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.757295" dy="0.757295" />
            <feGaussianBlur stdDeviation="1.13594" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter5_d"
            x="170.22"
            y="67.4942"
            width="6.96085"
            height="23.0745"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.757295" dy="0.757295" />
            <feGaussianBlur stdDeviation="1.13594" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="102.284"
            y1="33.962"
            x2="96.161"
            y2="33.962"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="94.5511"
            y1="21.8765"
            x2="100.673"
            y2="21.8765"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="102.283"
            y1="33.9624"
            x2="96.1613"
            y2="33.9624"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="5.47722"
            y1="3.35702"
            x2="-0.644379"
            y2="3.35702"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="102.284"
            y1="121.782"
            x2="96.161"
            y2="121.782"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="94.5511"
            y1="109.697"
            x2="100.673"
            y2="109.697"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="102.283"
            y1="121.782"
            x2="96.1613"
            y2="121.782"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="5.47722"
            y1="3.35702"
            x2="-0.644379"
            y2="3.35702"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <clipPath id="clip0">
            <rect width="85.4025" height="127.298" fill="white" transform="translate(96 11)" />
          </clipPath>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
