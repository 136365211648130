import React, { FC } from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'
import { IContainerSquareProps } from '../../interface'
import { ContainerBackground } from '../container-background'

export const ContainerSquare2Casement: FC<IContainerSquareProps> = props => {
  const { onCasementClick, withoutBackground } = props

  return (
    <ProductImageWrapper isClickable>
      <svg
        width="315"
        height="333"
        viewBox="0 0 315 333"
        fill="none"
        xmlns="http://www.w3.org/1999/xlink"
      >
        <rect width="315" height="333" fill="white" />
        <rect x="12" y="19" width="286.682" height="263.183" fill="#E8EAEE" />
        <rect x="12" y="19" width="286.682" height="263.183" fill="url(#2-pattern0)" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M305 10H10V292H24V29H291V292H305V10Z"
          fill="#C4C4C4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 29H291V292H24V29ZM167 42H278V279H167V42ZM148 42H37V279H148V42Z"
          fill="#E8EAEE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M305 292H10V308H0V316H10H305H315V308H305V292Z"
          fill="url(#2-paint0_linear)"
        />
        <path d="M305 10L291 28.805V292.075L305 309V10Z" fill="#E9EBEF" />
        <path d="M305 10L291 28.805V292.075L305 309V10Z" fill="#020202" fillOpacity="0.06" />
        <path d="M10 10L24 28.7988V291.982L10 308.901V10Z" fill="#E9EBEF" />
        <path d="M10 10L24 28.7988V291.982L10 308.901V10Z" fill="#020202" fillOpacity="0.06" />
        <rect y="316" width="315" height="17" fill="white" />
        <g
          filter="url(#2-filter0_i)"
          className="casement"
          data-index="0"
          onClick={onCasementClick(0)}
        >
          <rect x="37" y="42" width="111" height="237" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="37.47"
          y="42.47"
          width="110.06"
          height="236.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#2-filter1_i)"
          className="casement"
          data-index="1"
          onClick={onCasementClick(1)}
        >
          <rect x="167" y="42" width="111" height="237" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="167.47"
          y="42.47"
          width="110.06"
          height="236.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <path
          d="M100.985 65.7412H98.447C97.8001 65.7412 97.2527 65.2345 97.2527 64.6356V64.4053C97.2527 63.8064 97.8001 63.2997 98.447 63.2997H100.985C101.632 63.2997 102.179 63.8064 102.179 64.4053V64.6356C102.179 65.2575 101.657 65.7412 100.985 65.7412Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M83.4262 68.5652C83.7247 68.5652 83.7993 68.5652 84.1972 68.5652C84.9432 68.5652 85.4903 69.1052 85.4903 69.833C85.4903 70.5843 84.9184 71.1008 84.1474 71.1008C82.705 71.1008 81.8098 71.1008 80.3674 71.1008C79.8203 71.1008 79.3727 70.913 79.1488 70.4199C78.9499 70.1147 78.8255 69.2695 79.6213 68.5652C80.1436 67.9782 85.9131 61.7565 86.9327 60.6765C86.9824 60.6061 86.9327 60.5122 86.8581 60.5122C86.8332 60.5122 86.8332 60.5122 86.8084 60.5122C84.6448 60.5122 82.4812 60.5122 80.3177 60.5122C79.6711 60.5122 79.1737 60.1365 79.0494 59.573C78.9002 58.9156 79.1986 58.3522 79.7706 58.0939C79.9446 58.0235 80.1187 58 80.2928 58C83.5008 58 86.684 58 89.8921 58C90.5138 58 90.9366 58.1878 91.1852 58.7278C91.5085 59.4087 91.1604 59.8783 90.9366 60.1835C90.4889 60.7235 85.938 65.56 83.4262 68.2834C83.4014 68.3069 83.3765 68.3539 83.3268 68.3773C83.277 68.4478 83.3268 68.5652 83.4262 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M90.1856 71.1009H87.6477C87.0008 71.1009 86.4534 70.5818 86.4534 69.9683V69.7324C86.4534 69.1189 87.0008 68.5998 87.6477 68.5998H90.1856C90.8326 68.5998 91.38 69.1189 91.38 69.7324V69.9683C91.38 70.6054 90.8326 71.1009 90.1856 71.1009Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M93.7153 64.5152C93.7153 62.7341 93.7153 60.9764 93.7153 59.1952C93.7153 58.4922 94.2374 58 94.9831 58C98.2147 58 101.446 58 104.678 58C105.424 58 105.946 58.5156 105.946 59.2421C105.946 59.9686 105.424 60.5077 104.678 60.5077C101.993 60.5077 99.3334 60.5077 96.6486 60.5077C96.4498 60.5077 96.4001 60.5545 96.4001 60.742C96.4001 63.2731 96.4001 65.8042 96.4001 68.3353C96.4001 68.5463 96.4746 68.5697 96.6486 68.5697C99.3085 68.5697 101.993 68.5697 104.653 68.5697C105.25 68.5697 105.722 68.8978 105.896 69.39C106.095 69.9759 105.871 70.5618 105.399 70.8899C105.175 71.0305 104.902 71.1008 104.628 71.1008C101.446 71.1008 98.2396 71.1008 95.0577 71.1008C94.2374 71.1008 93.7402 70.5852 93.7402 69.8118C93.7153 68.0307 93.7153 66.273 93.7153 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M80.7054 76.0416V76.6984H79V76.0416H79.4263V74.2587H79.0502V73.6019H80.6803V74.2587H80.3041V76.0416H80.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M84.5344 76.7579H83.7737L83.651 76.5451C83.3811 76.687 83.0621 76.7579 82.7677 76.7579C81.9334 76.7343 81.1482 76.1668 81.1482 75.1501C81.1482 74.1807 81.8107 73.5423 82.8168 73.5423C83.651 73.5423 84.4608 74.0861 84.4608 75.1501C84.4608 75.5284 84.3626 75.8121 84.2154 76.0722L84.5589 76.6397V76.7579H84.5344ZM82.7922 76.0013C83.1848 76.0013 83.602 75.7176 83.602 75.1501C83.602 74.5826 83.1848 74.2989 82.7922 74.2989C82.3751 74.2989 81.9825 74.5826 81.9825 75.1501C81.9825 75.7176 82.3751 76.0013 82.7922 76.0013Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M86.7836 75.7486C86.7836 75.9599 87.0292 76.0772 87.2993 76.0772C87.5448 76.0772 87.7904 75.9599 87.7904 75.7956C87.7904 75.4904 87.4221 75.4904 87.2011 75.467C86.6117 75.42 86.0223 75.2088 86.0223 74.5516C86.0223 73.8709 86.6117 73.5423 87.2747 73.5423C87.8886 73.5423 88.5271 73.8709 88.5271 74.5516H87.7167C87.7167 74.3403 87.4957 74.2464 87.2747 74.2464C87.0783 74.2464 86.8327 74.2934 86.8327 74.5281C86.8327 74.7393 87.0292 74.7863 87.3238 74.8097C88.0114 74.8567 88.6008 75.021 88.6008 75.7721C88.6008 76.4293 87.9378 76.7579 87.2747 76.7579C86.5871 76.7579 85.9241 76.4293 85.9487 75.7486H86.7836Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M91.2287 74.311H90.0377V74.7601H91.1295V75.4693H90.0377V75.9656H91.2535V76.6984H89.1692V73.6019H91.2039V74.311H91.2287Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M94.6032 76.5802V76.6984H93.7223L93.1595 75.7293H92.7191V76.6984H91.8872V73.6019C92.3766 73.6019 92.8415 73.6019 93.3063 73.6019C94.0893 73.6019 94.4563 74.1692 94.4563 74.7128C94.4563 75.0674 94.2851 75.422 93.967 75.6111L94.6032 76.5802ZM93.3063 75.0674C93.551 75.0674 93.6489 74.8783 93.6489 74.6656C93.6489 74.4765 93.5266 74.2874 93.3063 74.2874H92.7191V75.0674H93.3063Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M96.2761 75.3342L96.553 74.5273L96.9556 73.6019H97.8868V73.7205L96.4523 76.758H96.1L94.6655 73.7205V73.6019H95.5967L96.0245 74.5273L96.2761 75.3342Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M99.7815 76.0416V76.6984H98.0762V76.0416H98.5025V74.2587H98.1263V73.6019H99.7565V74.2587H99.3803V76.0416H99.7815Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M101.835 73.6019C102.509 73.6019 103.258 73.9552 103.383 74.7795H102.559C102.434 74.4498 102.185 74.332 101.835 74.332C101.335 74.332 101.011 74.6618 101.011 75.1799C101.011 75.6274 101.335 76.0278 101.835 76.0278C102.185 76.0278 102.484 75.863 102.559 75.5332H103.383C103.258 76.4047 102.534 76.758 101.835 76.758C100.986 76.758 100.162 76.2162 100.162 75.1799C100.162 74.12 100.986 73.6019 101.835 73.6019Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M105.944 74.311H104.753V74.7601H105.845V75.4693H104.753V75.9656H105.969V76.6984H103.885V73.6019H105.919V74.311H105.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M230.985 65.7412H228.447C227.8 65.7412 227.253 65.2345 227.253 64.6356V64.4053C227.253 63.8064 227.8 63.2997 228.447 63.2997H230.985C231.632 63.2997 232.179 63.8064 232.179 64.4053V64.6356C232.179 65.2575 231.657 65.7412 230.985 65.7412Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M213.426 68.5652C213.725 68.5652 213.799 68.5652 214.197 68.5652C214.943 68.5652 215.49 69.1052 215.49 69.833C215.49 70.5843 214.918 71.1008 214.147 71.1008C212.705 71.1008 211.81 71.1008 210.367 71.1008C209.82 71.1008 209.373 70.913 209.149 70.4199C208.95 70.1147 208.826 69.2695 209.621 68.5652C210.144 67.9782 215.913 61.7565 216.933 60.6765C216.982 60.6061 216.933 60.5122 216.858 60.5122C216.833 60.5122 216.833 60.5122 216.808 60.5122C214.645 60.5122 212.481 60.5122 210.318 60.5122C209.671 60.5122 209.174 60.1365 209.049 59.573C208.9 58.9156 209.199 58.3522 209.771 58.0939C209.945 58.0235 210.119 58 210.293 58C213.501 58 216.684 58 219.892 58C220.514 58 220.937 58.1878 221.185 58.7278C221.509 59.4087 221.16 59.8783 220.937 60.1835C220.489 60.7235 215.938 65.56 213.426 68.2834C213.401 68.3069 213.376 68.3539 213.327 68.3773C213.277 68.4478 213.327 68.5652 213.426 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M220.186 71.1009H217.648C217.001 71.1009 216.453 70.5818 216.453 69.9683V69.7324C216.453 69.1189 217.001 68.5998 217.648 68.5998H220.186C220.833 68.5998 221.38 69.1189 221.38 69.7324V69.9683C221.38 70.6054 220.833 71.1009 220.186 71.1009Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M223.715 64.5152C223.715 62.7341 223.715 60.9764 223.715 59.1952C223.715 58.4922 224.237 58 224.983 58C228.215 58 231.446 58 234.678 58C235.424 58 235.946 58.5156 235.946 59.2421C235.946 59.9686 235.424 60.5077 234.678 60.5077C231.993 60.5077 229.333 60.5077 226.649 60.5077C226.45 60.5077 226.4 60.5545 226.4 60.742C226.4 63.2731 226.4 65.8042 226.4 68.3353C226.4 68.5463 226.475 68.5697 226.649 68.5697C229.309 68.5697 231.993 68.5697 234.653 68.5697C235.25 68.5697 235.722 68.8978 235.896 69.39C236.095 69.9759 235.871 70.5618 235.399 70.8899C235.175 71.0305 234.902 71.1008 234.628 71.1008C231.446 71.1008 228.24 71.1008 225.058 71.1008C224.237 71.1008 223.74 70.5852 223.74 69.8118C223.715 68.0307 223.715 66.273 223.715 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M210.705 76.0416V76.6984H209V76.0416H209.426V74.2587H209.05V73.6019H210.68V74.2587H210.304V76.0416H210.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M214.534 76.7579H213.774L213.651 76.5451C213.381 76.687 213.062 76.7579 212.768 76.7579C211.933 76.7343 211.148 76.1668 211.148 75.1501C211.148 74.1807 211.811 73.5423 212.817 73.5423C213.651 73.5423 214.461 74.0861 214.461 75.1501C214.461 75.5284 214.363 75.8121 214.215 76.0722L214.559 76.6397V76.7579H214.534ZM212.792 76.0013C213.185 76.0013 213.602 75.7176 213.602 75.1501C213.602 74.5826 213.185 74.2989 212.792 74.2989C212.375 74.2989 211.982 74.5826 211.982 75.1501C211.982 75.7176 212.375 76.0013 212.792 76.0013Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M216.784 75.7486C216.784 75.9599 217.029 76.0772 217.299 76.0772C217.545 76.0772 217.79 75.9599 217.79 75.7956C217.79 75.4904 217.422 75.4904 217.201 75.467C216.612 75.42 216.022 75.2088 216.022 74.5516C216.022 73.8709 216.612 73.5423 217.275 73.5423C217.889 73.5423 218.527 73.8709 218.527 74.5516H217.717C217.717 74.3403 217.496 74.2464 217.275 74.2464C217.078 74.2464 216.833 74.2934 216.833 74.5281C216.833 74.7393 217.029 74.7863 217.324 74.8097C218.011 74.8567 218.601 75.021 218.601 75.7721C218.601 76.4293 217.938 76.7579 217.275 76.7579C216.587 76.7579 215.924 76.4293 215.949 75.7486H216.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M221.229 74.311H220.038V74.7601H221.129V75.4693H220.038V75.9656H221.254V76.6984H219.169V73.6019H221.204V74.311H221.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M224.603 76.5802V76.6984H223.722L223.16 75.7293H222.719V76.6984H221.887V73.6019C222.377 73.6019 222.841 73.6019 223.306 73.6019C224.089 73.6019 224.456 74.1692 224.456 74.7128C224.456 75.0674 224.285 75.422 223.967 75.6111L224.603 76.5802ZM223.306 75.0674C223.551 75.0674 223.649 74.8783 223.649 74.6656C223.649 74.4765 223.527 74.2874 223.306 74.2874H222.719V75.0674H223.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M226.276 75.3342L226.553 74.5273L226.956 73.6019H227.887V73.7205L226.452 76.758H226.1L224.666 73.7205V73.6019H225.597L226.024 74.5273L226.276 75.3342Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M229.782 76.0416V76.6984H228.076V76.0416H228.503V74.2587H228.126V73.6019H229.756V74.2587H229.38V76.0416H229.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M231.835 73.6019C232.509 73.6019 233.258 73.9552 233.383 74.7795H232.559C232.434 74.4498 232.185 74.332 231.835 74.332C231.335 74.332 231.011 74.6618 231.011 75.1799C231.011 75.6274 231.335 76.0278 231.835 76.0278C232.185 76.0278 232.484 75.863 232.559 75.5332H233.383C233.258 76.4047 232.534 76.758 231.835 76.758C230.986 76.758 230.162 76.2162 230.162 75.1799C230.162 74.12 230.986 73.6019 231.835 73.6019Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M235.944 74.311H234.753V74.7601H235.845V75.4693H234.753V75.9656H235.969V76.6984H233.885V73.6019H235.919V74.311H235.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <defs>
          <pattern id="2-pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlinkHref="#image0" transform="translate(-0.18818) scale(0.00137636 0.00149925)" />
          </pattern>
          <filter
            id="2-filter0_i"
            x="37"
            y="42"
            width="111.94"
            height="239.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-filter1_i"
            x="167"
            y="42"
            width="111.94"
            height="239.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <linearGradient
            id="2-paint0_linear"
            x1="167.5"
            y1="292"
            x2="167.5"
            y2="316"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DBDDE1" />
            <stop offset="1" stopColor="#F9F9F9" />
          </linearGradient>
        </defs>
      </svg>
      {!withoutBackground && <ContainerBackground />}
    </ProductImageWrapper>
  )
}
