import React from 'react'
import { StageDescriptionBlockContentItemIcon } from 'src/react-app/views/calculator/styles'

export const Security1 = () => {
  return (
    <StageDescriptionBlockContentItemIcon title="Для помещений не требующих противовзломности">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.6"
          d="M32.5 40H7.5C3.3575 40 0 36.6425 0 32.5V7.5C0 3.3575 3.3575 0 7.5 0H32.5C36.6425 0 40 3.3575 40 7.5V32.5C40 36.6425 36.6425 40 32.5 40Z"
          fill="#E0E3E9"
        />
        <path
          d="M20.9998 20.8462V23.5C20.9998 23.9599 20.6271 24.3337 20.166 24.3337C19.7061 24.3337 19.3323 23.9599 19.3323 23.5V20.8462C18.3649 20.5013 17.666 19.5849 17.666 18.5C17.666 17.1212 18.7872 16 20.166 16C21.5448 16 22.666 17.1212 22.666 18.5C22.666 19.5849 21.9672 20.5013 20.9998 20.8462Z"
          fill="#ECEEF2"
        />
        <path
          d="M20.8458 12.2779L12.5414 11.0165C12.1561 10.9564 11.7628 11.0618 11.4681 11.3112C11.1734 11.5684 11 11.9379 11 12.3233V28.244C11 28.66 11.34 29 11.756 29H14.212V25.0331C14.212 24.2998 14.8014 23.7105 15.5347 23.7105H17.4241C18.1574 23.7105 18.7467 24.2998 18.7467 25.0331V29H21.9576V13.5859C21.9565 12.9353 21.4873 12.3845 20.8458 12.2779Z"
          fill="#1C3775"
        />
        <path
          d="M28.0911 19.0579L22.7109 17.9313V29H27.8112C28.54 29 29.1339 28.4072 29.1339 27.6773V20.35C29.1339 19.7255 28.7077 19.1939 28.0911 19.0579Z"
          fill="#1C3775"
        />
      </svg>
    </StageDescriptionBlockContentItemIcon>
  )
}
