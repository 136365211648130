import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
} from 'react'
import { ModalProvider } from 'react-modal-hook'
import { TransitionGroup } from 'react-transition-group'

import {
  StagePersonalAreaContent,
  StagePersonalAreaList,
  StagePersonalAreaListItem,
  StagePersonalAreaTitle,
  StagePersonalAreaWrapper,
} from './styles'
import { IPersonalAreaContext, views, VIEWS_CODES } from './interface'
import { ProfileBlock } from './profile'
import { RegisterUser } from '../registr/register-user'
import { useOrders } from '../../../hooks'
import { OrdersList } from './orders-list'
import { CasesList } from './cases-list'
import fetchAPI from 'src/lib/utils/fetch-api'

export const personalAreaContextInitial = {
  profile: {},
  orders: [],
  cases: [],
  inProcess: false,
  fetchOrderById: () => null,
}

export const PersonalAreaContext = createContext<IPersonalAreaContext>(personalAreaContextInitial)

export const StagePersonalArea = () => {
  const [title, setTitle] = useState('Заказы')
  const [activeView, setActiveView] = useState<VIEWS_CODES>(VIEWS_CODES.CASES)
  const [profile, setProfile] = useState<Record<string, any> | null>(null)
  const [chekAuth, setChekAuth] = useState(false)
  const { orders, cases, fetchOrders, inProcess, fetchOrderById } = useOrders()

  useEffect(() => {
    setChekAuth(true)
    fetchOrders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangeView = useCallback(
    view => () => {
      setTitle(view.title)
      setActiveView(view.code)
    },
    [],
  )

  const contentView = useMemo(() => {
    let result: ReactElement | null
    switch (activeView) {
      case VIEWS_CODES.PROFILE:
        result = <ProfileBlock />
        break
      case VIEWS_CODES.CASES:
        result = <CasesList />
        break
      case VIEWS_CODES.EXIT:
          result = null
          break
      case VIEWS_CODES.ORDERS:
      default:
        result = <OrdersList />
        break
    }
    return result
  }, [activeView])

  useEffect(() => { 
    if (activeView === VIEWS_CODES.EXIT) {
       fetchAPI('/session', { method: 'DELETE' })
       // @ts-ignore
// eslint-disable-next-line
         .then(() => Object.assign(window.location, { href: '/' }))
         // @ts-ignore
// eslint-disable-next-line
         .then(window.localStorage.clear)
    }
  }, [activeView])

  const handleAuth = useCallback(() => {
    fetchOrders()
  }, [fetchOrders])

  const handleGetProfile = useCallback(profileData => {
    setProfile(profileData)
  }, [])

  return (
    <ModalProvider rootComponent={TransitionGroup}>
      <PersonalAreaContext.Provider value={{ profile, orders, cases, inProcess, fetchOrderById }}>
        <StagePersonalAreaWrapper>
          <StagePersonalAreaTitle>{title}</StagePersonalAreaTitle>
          <StagePersonalAreaContent>
            <StagePersonalAreaList>
              {views.map(view => (
                <StagePersonalAreaListItem
                  key={view.code}
                  active={view.code === activeView}
                  onClick={handleChangeView(view)}
                >
                  {view.title}
                </StagePersonalAreaListItem>
              ))}
            </StagePersonalAreaList>
            {contentView}
          </StagePersonalAreaContent>
        </StagePersonalAreaWrapper>
        <RegisterUser
          onSubmit={handleAuth}
          onEndSubmit={handleGetProfile}
          isAutoRegister={chekAuth}
        />
      </PersonalAreaContext.Provider>
    </ModalProvider>
  )
}
