import React, { FC, useState, useCallback, useEffect } from 'react'
import { SwitcherWrapper, SwitcherItem } from './styles'
import { ISwitcherProps } from './interface'

export const Switcher: FC<ISwitcherProps> = props => {
  const { id, menuData, onSelect, selected } = props

  const [activeKey, setActiveKey] = useState(() => {
    let result = ''

    if (menuData && Array.isArray(menuData) && menuData.length > 0) {
      result = menuData[0]['value']
    }

    return result
  })

  useEffect(() => {
    if (selected) {
      setActiveKey(selected['value'])
    }
  }, [selected])

  const isActive = useCallback(
    (key: string) => {
      return activeKey === key
    },
    [activeKey],
  )

  const handleChange = useCallback(
    item => () => {
      onSelect(item)
    },
    [onSelect],
  )

  return (
    <>
      {menuData && menuData.length > 0 && (
        <SwitcherWrapper>
          {menuData.map(data => (
            <SwitcherItem
              key={`${id}-${data['value']}`}
              active={isActive(data['value'])}
              onClick={handleChange(data)}
            >
              {data['title']}
            </SwitcherItem>
          ))}
        </SwitcherWrapper>
      )}
    </>
  )
}
