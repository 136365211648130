import React, { useCallback, useMemo, FC } from 'react'
import InputMask from 'react-input-mask'

import { InputWrapper, InputReq } from './styles'
import { InputStatus } from '../input'
import { IPhoneInputProps } from '../../views/calculator/interface'
import { SuccessIcon } from '../../assets/icons'

const normalizePhone = (value: string | number): string | number => {
  if (typeof value !== 'string') {
    return value
  }

  return value.replace(/[-_() ]/g, '')
}

const formatPhone = (value: string | number): string | number => {
  const formatString = '____ ______'

  if (typeof value !== 'string') {
    return value
  }

  return Array.prototype.reduce.call(
    normalizePhone(value),
    (acc: any, symbol) => acc.replace('_', symbol),
    formatString,
  ) as string
}

export const PhoneInput: FC<IPhoneInputProps> = props => {
  const { value, onChange, id, name, placeholder, success, disabled = false, req = false } = props
  const valueFormat = useMemo(() => formatPhone(value), [value])

  const inputProps = useMemo(() => {
    const result = {}

    if (id) {
      result['id'] = id
    }
    if (name) {
      result['name'] = name
    }
    if (placeholder) {
      result['placeholder'] = placeholder
    }

    return result
  }, [id, name, placeholder])

  const handleChange = useCallback(
    ({ target }) => {
      if (onChange && onChange.constructor === Function) {
        onChange(normalizePhone(target.value), name)
      }
    },
    [name, onChange],
  )

  return (
    <InputWrapper disabled={disabled}>
      { req && <InputReq >*</InputReq> }
      <InputMask
        {...inputProps}
        mask="9999 999999"
        onChange={handleChange}
        value={valueFormat}
      />
      {success && (
        <InputStatus title={success}>
          <SuccessIcon />
        </InputStatus>
      )}
    </InputWrapper>
  )
}

export default PhoneInput