export enum HorizontalSizeType {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export interface IHorizontalSizeProps {
  value: number[]
  count: number
  imageWidth: number
  maxWidth?: undefined | number
  onChange: (sizes: number | number[]) => void
  type: HorizontalSizeType
  disabled?: boolean
  errors?: { title: string; description: string }[]
}

export interface IThumbSizeProps {
  index: number
  onChange: (val: number) => void
  minSize?: number
  maxSize: number
  currentSize: number
  disabled?: boolean
}
