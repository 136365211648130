import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement25Square4VerticalCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-5_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M191.954 104.976C191.954 103.885 191.069 103 189.977 103C188.886 103 188.001 103.885 188.001 104.976L188.001 107.167L188.001 107.167L188.001 118L188 118L188 121.024C188 122.115 188.885 123 189.976 123C191.068 123 191.953 122.115 191.953 121.024L191.953 118.833L191.954 118.833L191.954 108L191.954 108L191.954 104.976Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 191.954 107.166)"
          fill="#ABABAB"
        />
        <path
          d="M191.954 103.94C191.954 103.421 191.533 103 191.014 103L188.941 103C188.422 103 188.001 103.421 188.001 103.94L188.001 108L191.954 108L191.954 103.94Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M188 122.06C188 122.579 188.421 123 188.94 123L191.013 123C191.532 123 191.953 122.579 191.953 122.06L191.953 118L188 118L188 122.06Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 191.954 108.833)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#2-5_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 129C188 127.895 188.895 127 190 127C191.105 127 192 127.895 192 129L192 143.56L188 143.56L188 129ZM188 143.56L192 143.56L192 145.4L192.06 145.4C192.579 145.4 193 145.821 193 146.34L193 148.12C193 149.158 192.158 150 191.12 150L189.88 150C188.842 150 188 149.158 188 148.12L188 145.4L188 145.4L188 143.56Z"
            fill="white"
          />
        </g>
        <rect x="188" y="144.25" width="3.99998" height="0.95833" fill="#ABABAB" />
        <path
          d="M188 127.94C188 127.421 188.421 127 188.94 127L191.06 127C191.579 127 192 127.421 192 127.94L192 144.25L188 144.25L188 127.94Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M188 149.06C188 149.579 188.421 150 188.94 150L192.06 150C192.579 150 193 149.579 193 149.06L193 146.148C193 145.629 192.579 145.208 192.06 145.208L188 145.208L188 149.06Z"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#2-5_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 99H32V154H188V99ZM178 109H42V144H178V109Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 99H32V154H33V100H187V154H188V99Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-5_filter3_i)">
          <rect
            x="42.47"
            y="109.47"
            width="135.06"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="103.53"
          width="146.94"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M176.5 110.565L47.0913 126.5L176.5 142.435L176.5 110.565Z" stroke="white" />
        <rect
          width="6.91954"
          height="13.5283"
          rx="3.45977"
          transform="matrix(-1 0 0 1 40.9196 120)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#2-5_filter4_d)">
          <rect
            width="5.53563"
            height="12.2399"
            rx="2.76782"
            transform="matrix(-1 0 0 1 40.2255 120.644)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.6605 131.792C39.1549 132.456 38.3564 132.884 37.4578 132.884C35.9292 132.884 34.69 131.645 34.69 130.116V123.412C34.69 122.44 35.1908 121.585 35.9483 121.092C35.5937 121.557 35.3831 122.138 35.3831 122.768V129.472C35.3831 131.001 36.6223 132.24 38.151 132.24C38.7079 132.24 39.2264 132.075 39.6605 131.792Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.4576 120.644C38.9863 120.644 40.2255 121.883 40.2255 123.412V129.472C40.2255 129.492 40.2252 129.512 40.2248 129.533V123.991C40.2248 122.463 38.9856 121.224 37.457 121.224C35.9486 121.224 34.7221 122.43 34.6898 123.931V123.412C34.6898 121.883 35.929 120.644 37.4576 120.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-5_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.4599 124C38.2788 124 38.9426 124.664 38.9426 125.483V127C38.9426 127.014 38.9425 127.027 38.9422 127.041L38.9422 145.517C38.9422 146.336 38.2784 147 37.4595 147V147C36.6406 147 35.9767 146.336 35.9767 145.517L35.9767 125.251C35.9767 124.56 36.5369 124 37.228 124V124C37.2308 124 37.2336 124 37.2364 124C37.3109 124 37.3854 124 37.4599 124V124Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M45.1468 142.5L110 110.557L174.853 142.5H45.1468Z" stroke="white" />
        <defs>
          <filter
            id="2-5_filter0_d"
            x="186.12"
            y="101.12"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-5_filter1_d"
            x="186.12"
            y="125.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-5_filter2_d"
            x="27.3003"
            y="94.3003"
            width="167.279"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-5_filter3_i"
            x="42"
            y="109"
            width="136.94"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-5_filter4_d"
            x="32.8099"
            y="118.764"
            width="11.1753"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-5_filter5_d"
            x="34.0968"
            y="122.12"
            width="8.60556"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="194.722"
            y1="120.5"
            x2="187.209"
            y2="120.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="185.234"
            y1="105.5"
            x2="192.745"
            y2="105.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="194.72"
            y1="120.5"
            x2="187.209"
            y2="120.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="194.8"
            y1="135.625"
            x2="187.2"
            y2="135.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="196.5"
            y1="147.604"
            x2="187"
            y2="147.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
