import React, { FC, useCallback, useMemo, useState, useEffect, useContext } from 'react'
import { useModal } from 'react-modal-hook'
import { useHistory } from 'react-router-dom'

import { ButtonViews, IStageSelectQuiz, QuizView } from '../interface'
import { ViewType } from './view/view-type'
import { IFilterItem } from '../stage-calculator/interface'
import {
  functionality,
  preferences1,
  preferences2,
  quizSchema,
  initSizes,
  initGlassUnitSettings,
  preferences,
} from './consts'
import { fixBody, getGlassUnit, jsonParse } from '../../../utilits'
import { ViewPreferences1 } from './view/view-preferences-1'
import { useMenuData, usePrice } from '../../../hooks'
import {
  CloseButton,
  ModalBody,
  ModalWrapper,
  ModalContent,
  ModalFooter,
  LinkButton,
  ModalFooterWrapper,
  ModalTitle,
} from '../styles'
import { CloseIcon } from '../../../assets/icons'
import { Button } from '../../../components'
import { ViewPreferences3 } from './view/view-preferences-3'
import { MainContext } from '../calculator'

export const StageQuiz: FC<IStageSelectQuiz> = props => {
  const { onChange, onChangeType, type, firstPast = true } = props

  const {
    dropSettings,
    initGlassUnit,
    quizStep,
    initModel,
    clearView,
    premisesType,
    isDraftEdit,
  } = useContext(MainContext)

  const history = useHistory()
  const [currentView, setCurrentView] = useState(quizStep)
  const [preFilters, setPreFilters] = useState<IFilterItem[]>([])
  const [glassUnitSettings, setGlassUnitSettings] = useState<Record<string, any>>(
    initGlassUnitSettings,
  )
  const [currentGlassUnit, setCurrentGlassUnit] = useState('')
  const [query, setQuery] = useState({})
  const { totalPriceList, calculateTotalPrice, inProcess } = usePrice()
  const { fetchMenuData, specification, menuItemsData } = useMenuData()
  const { toggleFixBody } = fixBody()
  const [selectedAnswers, setSelectedAnswers] = useState({})
  const [currentType, setCurrentType] = useState('')

  const handleChangePreFilter = useCallback(
    key => value => {
      const isModelKey = key === `${type}-model`
      setPreFilters(prevState => {
        const keyIndex = prevState.findIndex(({ key: prevKey }) => prevKey === key)
        const index = keyIndex !== -1 ? keyIndex : prevState.length

        return [
          ...prevState.slice(0, index),
          { key, value },
          ...prevState.slice(index + 1, prevState.length),
        ]
      })

      if (isModelKey) {
        setGlassUnitSettings(prevState => ({
          ...prevState,
          [`${type}-model`]: value,
        }))
        setQuery(prevState => ({
          ...prevState,
          [`${type}-model`]: value,
        }))
      } else if (value) {
        setQuery(prevState => ({
          ...prevState,
          [key]: value,
        }))
      }
    },
    [type],
  )

  const handleSetStep = useCallback((step: QuizView) => {
    if (step === 'SELECT_TYPE_PREMISES') {
      // @ts-ignore
      window?.ym?.(82697449,'reachGoal','calculator_window')
    }
    if (step === 'SELECT_PREFERENCES_2') {
      // @ts-ignore
      window?.ym?.(82697449,'reachGoal','choice_of_characteristics')
    }
    if (step === 'SELECT_PREFERENCES_4') {
      // @ts-ignore
      window?.ym?.(82697449,'reachGoal','enter_product_dimensions')
    }

    setCurrentView(step)
  }, [query])


  useEffect(() => {
    if (initGlassUnit) {
      setCurrentGlassUnit(initGlassUnit)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getNewData = useCallback((data, key, value) => {
    const itemIndex = data.findIndex(state => state['key'] === key)
    const itemData = { key, value }

    return itemIndex > -1
      ? [...data.slice(0, itemIndex), itemData, ...data.slice(itemIndex + 1, data.length)]
      : [...data, itemData]
  }, [])

  /* метод изменения параметров стеклопакета */
  const handleChangeUnitGlassSettings = useCallback(
    ({ key, value }) => {
      const productModelKey = `${type}-model`

      if (key === productModelKey) {
        setPreFilters(prevState => {
          return getNewData(prevState, productModelKey, value)
        })
        setQuery(prevState => ({
          ...prevState,
          [key]: value,
        }))
      }

      setGlassUnitSettings(prevState => ({
        ...prevState,
        [key]: value,
      }))
    },
    [getNewData, type],
  )

  useEffect(() => {
    if (initModel && type) {
      setPreFilters(preFilters => {
        const modelIndex = preFilters.findIndex(item => item['key'] === `${type}-model`)
        const typeIndex = preFilters.findIndex(item => item['key'] === 'product-type')
        const result = [...preFilters]
        const modelValue = { key: `${type}-model`, value: initModel }
        const typeValue = { key: 'product-type', value: type }
        const isHaveFloriculture = preFilters.find(
          item => item['key'] === 'comfortable-floriculture',
        )

        if (modelIndex >= 0) {
          result[modelIndex] = modelValue
        } else {
          result.push(modelValue)
        }

        if (typeIndex >= 0) {
          result[typeIndex] = typeValue
        } else {
          result.push(typeValue)
        }

        // проставление дефолтных настроек скрытой страницы №5
        if (!isHaveFloriculture) {
          functionality.forEach(item => {
            result.push({
              key: item['code'],
              value: item['enum'][1]['value'],
            })
          })
        }

        return result
      })
      handleChangeUnitGlassSettings({ key: `${type}-model`, value: initModel })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  useEffect(() => {
    if (quizStep) {
      handleSetStep(quizStep)
      handleChangePreFilter('product-type')(type)

      if (premisesType && type) {
        handleChangePreFilter(`${type}-model`)(premisesType)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizStep, type])

  useEffect(() => {
    if (type) {
      setQuery(prevState => ({
        ...prevState,
        'product-type': type,
      }))
      if (initModel) {
        setQuery(prevState => ({
          ...prevState,
          [`${type}-model`]: initModel,
        }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  const fillFilter = useCallback(() => {
    let result: IFilterItem[] = []
    const filtersReducer = quizSchema['filters']['reducer']
    const filterEnum = quizSchema['filters']['enum']
    const glassUnit = getGlassUnit(glassUnitSettings)
    /** Prepare preFilters array to filtersState view */
    const filtersState = preFilters.reduce(
      (accum, item) => ({
        ...accum,
        [item['key']]: item['value'],
      }),
      {},
    )
    // eslint-disable-next-line no-eval
    const reduceFilters = eval(filtersReducer)
    const [currentFilters] = reduceFilters(filterEnum, filtersState)
    const currentFiltersObj: any = jsonParse<IFilterItem[]>(
      (currentFilters && currentFilters['value']) || '[]',
    )

    if (glassUnit) {
      /** Getting current glass-unit-2 */
      Object.keys(glassUnitSettings).forEach(key => {
        result.push({
          key,
          value: glassUnitSettings[key],
        })
      })
    }

    if (result.length > 0 && currentFiltersObj && Array.isArray(currentFiltersObj)) {
      result = [...result, ...currentFiltersObj, ...preFilters]
    }

    return result
  }, [glassUnitSettings, preFilters])

  useEffect(() => {
    const glassKeys = Object.keys(glassUnitSettings).filter(
      key => !['product-type', 'window-model'].includes(key), // if not type & model
    )

    if (currentView === QuizView.SELECT_PREFERENCES_1 && glassKeys.length === 0) {
      const data = [...preferences1, ...preferences2]
      const result: Record<string, any> = {}

      data.forEach(item => {
        if (item['code'] !== `${type}-model`) {
          result[item['code']] = item['enum'][0]['value']
        }
      })

      setGlassUnitSettings(result)
    }

    setQuery(prevState => ({
      ...prevState,
      'quiz-step': currentView,
    }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentView])

  /*useEffect(() => {
    if (!clearView && !isDraftEdit && Object.keys(query).length > 0) {
      let url = `/podbor?${qs.stringify(query)}`

      if (query['quiz-step']) {
        const step = quizStepMap[query['quiz-step']]

        if (step) {
          url = `/podbor/${step}?${qs.stringify(query)}`
        }
      }

      //history.push(url)
    }
  }, [clearView, history, isDraftEdit, query])*/

  /* получение стеклопакета на основе изменённых параметров */
  useEffect(() => {
    const glassUnit = getGlassUnit(glassUnitSettings)

    if (glassUnit) {
      handleChangePreFilter('glass-unit-2')(glassUnit)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [glassUnitSettings])

  useEffect(() => {
    let result = ''

    if (preFilters) {
      const glassUnit = preFilters.find(item => item['key'] === 'glass-unit-2')

      if (glassUnit) {
        result = glassUnit['value'] as string
      }
    }

    setCurrentGlassUnit(result)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preFilters])

  useEffect(() => {
    const filledFilters = fillFilter()

    if (filledFilters.length > 0 && Object.keys(specification).length > 0) {
      calculateTotalPrice(type, [specification], [initSizes], [filledFilters])
    }
  }, [specification, preFilters, glassUnitSettings, fillFilter, calculateTotalPrice, type])

  useEffect(() => {
    if (preFilters) {
      const filters = fillFilter()

      if (filters.length > 0) {
        // @ts-ignore
        fetchMenuData(type, filters)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preFilters])

  const handleCloseModal = useCallback(
    hideModal => () => {
      toggleFixBody()
      hideModal()
      handleSetStep(QuizView.SELECT_TYPE_PREMISES)
    },
    [handleSetStep, toggleFixBody],
  )

  const handleOpenCalculator = useCallback(() => {
    const savedPrefilter: any = jsonParse(
      // eslint-disable-next-line no-undef
      localStorage.getItem('quiz-prefilter') || undefined,
    )

    setCurrentView(QuizView.SELECT_TYPE)
    onChange(savedPrefilter)
    toggleFixBody()
  }, [onChange, toggleFixBody])


  const [showModal, hideModal] = useModal(({ in: open }) => {
    return (
      <ModalWrapper show={open}>
        <ModalBody>
          <CloseButton onClick={handleCloseModal(hideModal)}>
            <CloseIcon />
          </CloseButton>
          <ModalTitle isLeft>Следующий шаг</ModalTitle>
          <ModalContent>
            Перейти в калькулятор с сохранением параметров предыдущего подбора или создать новый
            подбор
          </ModalContent>
          <ModalFooterWrapper>
            <ModalFooter>
              <LinkButton onClick={handleCloseModal(hideModal)}>Продолжить подбор</LinkButton>
              <Button typeView={ButtonViews.ORANGE} onClick={handleOpenCalculator}>
                Внести размеры изделия
              </Button>
            </ModalFooter>
          </ModalFooterWrapper>
        </ModalBody>
      </ModalWrapper>
    )
  }, [])


  const handleChangeView = useCallback(
    view => () => {
      handleSetStep(view)
    },
    [handleSetStep],
  )

  const handleChangeType = useCallback(
    newType => {
      const savedPrefilter: any = jsonParse(
        // eslint-disable-next-line no-undef
        localStorage.getItem('quiz-prefilter') || undefined,
      )

      //const data = getQuizSchemaEnums(`${type}-model`)
      //const isModelKey = key === `${type}-model`)
      if(quizSchema[`${newType}-model`]) {
        setCurrentType(newType)
        onChangeType(newType)
        setPreFilters(prevState => getNewData(prevState, 'product-type', newType))
      }
      else {
        handleChangeView(QuizView.SELECT_PREFERENCES_1)
        handleSetStep(QuizView.SELECT_PREFERENCES_1)
      }

      /*if (Object.keys(savedPrefilter).length > 0) {
        if (!firstPast) {
          showModal()
          toggleFixBody(true)
        } else {
          handleSetStep(QuizView.SELECT_TYPE_PREMISES)
        }
      } else {
        handleSetStep(QuizView.SELECT_TYPE_PREMISES)
      }*/
    },
    [firstPast, getNewData, handleSetStep, handleChangeView, onChangeType, showModal, toggleFixBody],
  )

  const handleFinishQuiz = useCallback(() => {
    const result = fillFilter()

    onChange(result)
    // eslint-disable-next-line no-undef
    localStorage.setItem('quiz-prefilter', JSON.stringify(result))
    dropSettings()
  }, [dropSettings, fillFilter, onChange])

  const currentPremises = useMemo(() => {
    const premises = preFilters.find(({ key }) => key === `${type}-model`)

    return premises ? (premises['value'] as any) : null
  }, [preFilters, type])

  const getQuizSchemaEnums = useCallback(key => {
    return (quizSchema[key] && quizSchema[key]['enum']) || []
  }, [])

  const getPreferences = useCallback(key => {
    return preferences[key] || []
  }, [])

  const selectedData = useMemo(() => {
    const result = { ...glassUnitSettings }
    // Значения по умолчанию для пятого шага
    Object.assign(result, {
      'comfortable-floriculture': false,
      'children-security': false,
      'mosquito-type': 'anti-cat',
      'windowsill-model-not-needed': true,
      'ebb-type-not-needed': true,
    })
    if (preFilters.length > 0) {
      preFilters.forEach(({ key, value }) => {
        result[key] = value
      })
    }

    const data = [quizSchema['product-type'], quizSchema['window-model'], quizSchema['door-model'], quizSchema['loggia-model'], ...getPreferences(`${type}-model`)]
    const quizResult: Record<string, any> = {}
    data.forEach(item => {
      if (result[item['code']]) {
        item['enum'].forEach(itemEnum => {
          if(itemEnum.value === result[item['code']]) {
            quizResult[item['title']] = itemEnum.title
          }
        });
      }
    })

    setSelectedAnswers(quizResult);

    return result
  }, [glassUnitSettings, preFilters])

  const modelTitle = useMemo(() => {
    let result = ''

    if (menuItemsData && type && menuItemsData[`${type}-model`]) {
      const models = menuItemsData[`${type}-model`]
      const modelValue = preFilters.find(({ key }) => key === `${type}-model`)
      const currentModel = models.find(
        model => modelValue && model['value'] === modelValue['value'],
      )

      if (currentModel) {
        result = currentModel['text']
      }
    }

    return result
  }, [menuItemsData, preFilters, type])

  const renderStage = useCallback(() => {
    let result

    switch (currentView) {
      /*case QuizView.SELECT_TYPE_PREMISES: // 2 page
        result = (
          <ViewTypePremises
            onChangeTypePremises={handleChangePreFilter(`${type}-model`)}
            onChangeType={handleChangeView(QuizView.SELECT_PREFERENCES_1)}
            onPrevStep={handleChangeView(QuizView.SELECT_TYPE)}
            currentPremises={currentPremises}
            data={getQuizSchemaEnums(`${type}-model`)}
            steps={3}
            currentStep={2}
          />
        )
        break*/
      case QuizView.SELECT_PREFERENCES_1: // 3 page
        result = (
          <ViewPreferences1
            data={getPreferences(`${type}-model`)}
            type={type}
            modelTitle={modelTitle}
            currentStep={2}
            steps={2}
            price={totalPriceList[0]}
            calculateInProgress={inProcess}
            selected={selectedData}
            selectedAnswers={selectedAnswers}
            currentGlassUnit={currentGlassUnit}
            onChangeType={handleChangeView(QuizView.SELECT_PREFERENCES_2)}
            onPrevStep={handleChangeView(QuizView.SELECT_TYPE_PREMISES)}
            onChange={handleChangeUnitGlassSettings}
            products={[{ product: specification, sizes: initSizes }]}
          />
        )
        break
      case QuizView.SELECT_PREFERENCES_2: // 4 page
        // result = (
        //   <ViewPreferences1
        //     data={preferences2}
        //     type={type}
        //     modelTitle={modelTitle}
        //     currentStep={4}
        //     steps={4}
        //     price={totalPriceList[0]}
        //     calculateInProgress={inProcess}
        //     selected={selectedData}
        //     selectedAnswers={selectedAnswers}
        //     currentGlassUnit={currentGlassUnit}
        //     onChangeType={handleChangeView(QuizView.SELECT_PREFERENCES_4)}
        //     onPrevStep={handleChangeView(QuizView.SELECT_PREFERENCES_1)}
        //     onChange={handleChangeUnitGlassSettings}
        //     products={[{ product: specification, sizes: initSizes }]}
        //   />
        // )
        // break
      case QuizView.SELECT_PREFERENCES_3: // 5 page
        // result = (
        //   <ViewPreferences2
        //     data={functionality}
        //     menuItemsData={menuItemsData}
        //     type={type}
        //     modelTitle={modelTitle}
        //     currentStep={5}
        //     steps={4}
        //     price={totalPriceList[0]}
        //     calculateInProgress={inProcess}
        //     selected={selectedData}
        //     currentGlassUnit={currentGlassUnit}
        //     onChangeType={handleChangeView(QuizView.SELECT_PREFERENCES_4)}
        //     onPrevStep={handleChangeView(QuizView.SELECT_PREFERENCES_2)}
        //     onChange={handleChangePreFilter}
        //     onFinish={handleFinishQuiz}
        //   />
        // )
        break
      case QuizView.SELECT_PREFERENCES_4: // 6 page
        result = (
          <ViewPreferences3
            type={type}
            modelTitle={modelTitle}
            price={totalPriceList[0]}
            calculateInProgress={inProcess}
            selected={selectedData}
            currentGlassUnit={currentGlassUnit}
            products={[{ product: specification, sizes: initSizes }]}
            onChangeType={handleFinishQuiz}
            onChange={handleChangePreFilter}
            onFinish={handleFinishQuiz}
          />
        )
        break
      case QuizView.SELECT_TYPE: // 1 page
      default:
        result = (
          <ViewType
            onChangeType={handleChangeType}
            onChangeTypePremises={handleChangePreFilter(`${type}-model`)}
            data={getQuizSchemaEnums('product-type')}
            dataPremises={getQuizSchemaEnums(`${type}-model`)}
            currentType={currentType}
            currentPremises={currentPremises}
            type={type}
            steps={2}
            currentStep={1}
          />
        )

        break
    }

    return result
  }, [
    currentGlassUnit,
    currentPremises,
    currentView,
    getQuizSchemaEnums,
    handleChangePreFilter,
    handleChangeType,
    handleChangeUnitGlassSettings,
    handleChangeView,
    handleFinishQuiz,
    inProcess,
    modelTitle,
    selectedData,
    specification,
    totalPriceList,
    type,
  ])

  return <>{renderStage()}</>
}
