import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement25Square41Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-5-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M221 255C221 253.895 221.895 253 223 253C224.105 253 225 253.895 225 255V269.56H221V255ZM221 269.56H225V271.4H225.06C225.579 271.4 226 271.821 226 272.34V274.12C226 275.158 225.158 276 224.12 276H222.88C221.842 276 221 275.158 221 274.12V271.4V271.4V269.56Z"
            fill="white"
          />
        </g>
        <rect x="221" y="270.25" width="3.99998" height="0.95833" fill="#ABABAB" />
        <path
          d="M221 253.94C221 253.421 221.421 253 221.94 253H224.06C224.579 253 225 253.421 225 253.94V270.25H221V253.94Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M221 275.06C221 275.579 221.421 276 221.94 276H225.06C225.579 276 226 275.579 226 275.06V272.148C226 271.629 225.579 271.208 225.06 271.208H221V275.06Z"
          fill="url(#paint1_linear)"
        />
        <g filter="url(#2-5-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M225 46.9995C225 45.8952 224.105 45 223.001 45C221.896 45 221.001 45.8952 221.001 46.9995V49.1665H221.001V60H221V63.0005C221 64.1048 221.895 65 222.999 65C224.104 65 224.999 64.1048 224.999 63.0005V60.8332H225V50H225V46.9995Z"
            fill="url(#paint2_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 225 49.1665)"
          fill="#ABABAB"
        />
        <path
          d="M225 45.9399C225 45.4208 224.579 45 224.06 45H221.941C221.422 45 221.001 45.4208 221.001 45.9399V50H225V45.9399Z"
          fill="url(#paint3_linear)"
        />
        <path
          d="M221 64.0601C221 64.5792 221.421 65 221.94 65H224.059C224.578 65 224.999 64.5792 224.999 64.0601V60H221V64.0601Z"
          fill="url(#paint4_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 225 50.8334)"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#2-5-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M221 34H132V287H221V34ZM210 45H143V276H210V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M221 34H132V287H133.219V35H219.781V287H221V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-5-filter3_i)">
          <rect
            x="143.47"
            y="45.47"
            width="66.0601"
            height="230.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="136.53"
          y="38.53"
          width="79.9399"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="134"
          y="155"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#2-5-filter4_d)">
          <rect x="134.702" y="155.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M135.286 166.795C135.798 167.458 136.6 167.884 137.502 167.884C139.049 167.884 140.302 166.63 140.302 165.084V158.444C140.302 157.456 139.79 156.587 139.017 156.089C139.383 156.562 139.6 157.155 139.6 157.8V164.44C139.6 165.986 138.347 167.24 136.8 167.24C136.242 167.24 135.723 167.077 135.286 166.795Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M137.502 155.644C135.955 155.644 134.702 156.898 134.702 158.444V164.44C134.702 164.46 134.702 164.48 134.702 164.501V159.024C134.702 157.477 135.956 156.224 137.502 156.224C139.028 156.224 140.269 157.444 140.302 158.963V158.444C140.302 156.898 139.048 155.644 137.502 155.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-5-filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M137.5 159C136.672 159 136 159.672 136 160.5V160.714V162V180.5C136 181.328 136.672 182 137.5 182V182C138.328 182 139 181.328 139 180.5V164V160.5L139 160.286C139 159.576 138.424 159 137.714 159V159C137.677 159 137.64 159.001 137.604 159.004C137.569 159.001 137.535 159 137.5 159Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M208.5 273.114L144.575 160.999L208.5 47.9008L208.5 273.114Z" stroke="white" />
        <path d="M208.424 274.5L176.5 49.5584L144.576 274.5L208.424 274.5Z" stroke="white" />
        <defs>
          <filter
            id="2-5-filter0_d"
            x="219.12"
            y="251.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-5-filter1_d"
            x="219.12"
            y="43.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-5-filter2_d"
            x="127.3"
            y="29.3003"
            width="100.279"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-5-filter3_i"
            x="143"
            y="45"
            width="67.9399"
            height="233.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-5-filter4_d"
            x="132.822"
            y="153.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-5-filter5_d"
            x="134.12"
            y="157.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="227.8"
            y1="261.625"
            x2="220.2"
            y2="261.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="229.5"
            y1="273.604"
            x2="220"
            y2="273.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="227.8"
            y1="62.5"
            x2="220.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="218.202"
            y1="47.5"
            x2="225.8"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="227.798"
            y1="62.5"
            x2="220.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
