import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement8Door1RCasement1 = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="451"
        height="451"
        viewBox="0 0 451 451"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#door-1-casement-1-8-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.9541 46.9765C31.9541 45.8849 31.0692 45 29.9776 45C28.886 45 28.0011 45.8849 28.0011 46.9765V49.1665H28.0011V60H28V63.0235C28 64.1151 28.8849 65 29.9765 65C31.0681 65 31.953 64.1151 31.953 63.0235V60.8332H31.9541V50H31.9541V46.9765Z"
            fill="url(#door-1-casement-1-8-paint0_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-1 0 0 1 31.9541 49.1665)"
          fill="#ABABAB"
        />
        <path
          d="M31.9541 45.9399C31.9541 45.4208 31.5333 45 31.0142 45H28.9411C28.4219 45 28.0011 45.4208 28.0011 45.9399V50H31.9541V45.9399Z"
          fill="url(#door-1-casement-1-8-paint1_linear)"
        />
        <path
          d="M28 64.0601C28 64.5792 28.4208 65 28.9399 65H31.013C31.5322 65 31.953 64.5792 31.953 64.0601V60H28V64.0601Z"
          fill="url(#door-1-casement-1-8-paint2_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-1 0 0 1 31.9541 50.8334)"
          fill="url(#door-1-casement-1-8-paint3_linear)"
        />
        <g filter="url(#door-1-casement-1-8-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 269C32 267.895 31.1046 267 30 267C28.8954 267 28 267.895 28 269V283.56H32V269ZM32 283.56H28V285.4H27.9399C27.4208 285.4 27 285.821 27 286.34V288.12C27 289.158 27.8417 290 28.8799 290H30.1201C31.1583 290 32 289.158 32 288.12V285.4V285.4V283.56Z"
            fill="white"
          />
        </g>
        <rect
          width="3.99998"
          height="0.95833"
          transform="matrix(-1 0 0 1 32 284.25)"
          fill="#ABABAB"
        />
        <path
          d="M32 267.94C32 267.421 31.5792 267 31.0601 267H28.94C28.4208 267 28 267.421 28 267.94V284.25H32V267.94Z"
          fill="url(#door-1-casement-1-8-paint4_linear)"
        />
        <path
          d="M32 289.06C32 289.579 31.5792 290 31.0601 290H27.94C27.4208 290 27 289.579 27 289.06V286.148C27 285.629 27.4208 285.208 27.94 285.208H32V289.06Z"
          fill="url(#door-1-casement-1-8-paint5_linear)"
        />
        <g filter="url(#door-1-casement-1-8-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M265 34H32V301H265V34ZM254 45H43V290H254V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M265 34H32V301H33V35H264V301H265V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="36.53"
          y="38.53"
          width="223.94"
          height="257.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#door-1-casement-1-8-filter3_i)">
          <rect
            x="43.47"
            y="45.47"
            width="210.06"
            height="244.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          width="6.91954"
          height="13.5283"
          rx="3.45977"
          transform="matrix(-1 0 0 1 262.919 161)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#door-1-casement-1-8-filter4_d)">
          <rect
            width="5.53563"
            height="12.2399"
            rx="2.76782"
            transform="matrix(-1 0 0 1 262.225 161.644)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M261.66 172.792C261.155 173.456 260.356 173.884 259.458 173.884C257.929 173.884 256.69 172.645 256.69 171.116V164.412C256.69 163.44 257.191 162.585 257.948 162.092C257.594 162.557 257.383 163.138 257.383 163.768V170.472C257.383 172.001 258.622 173.24 260.151 173.24C260.708 173.24 261.226 173.075 261.66 172.792Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M259.458 161.644C260.986 161.644 262.225 162.883 262.225 164.412V170.472C262.225 170.492 262.225 170.512 262.225 170.533V164.991C262.225 163.463 260.985 162.224 259.457 162.224C257.949 162.224 256.722 163.43 256.69 164.931V164.412C256.69 162.883 257.929 161.644 259.458 161.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#door-1-casement-1-8-filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M259.46 165C260.279 165 260.942 165.664 260.942 166.483V168C260.942 168.014 260.942 168.027 260.942 168.041L260.942 186.517C260.942 187.336 260.278 188 259.459 188C258.64 188 257.976 187.336 257.976 186.517L257.976 166.251C257.976 165.56 258.537 165 259.228 165C259.231 165 259.233 165 259.236 165C259.311 165 259.385 165 259.46 165Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M44.5 288.131V46.869L252.005 167.5L44.5 288.131Z" stroke="white" />
        <path d="M252.241 288.5H44.7593L148.5 47.2656L252.241 288.5Z" stroke="white" />
        <defs>
          <filter
            id="door-1-casement-1-8-filter0_d"
            x="26.1201"
            y="43.1201"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-1-8-filter1_d"
            x="25.1201"
            y="265.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-1-8-filter2_d"
            x="27.3003"
            y="29.3003"
            width="244.279"
            height="278.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-1-8-filter3_i"
            x="43"
            y="45"
            width="211.94"
            height="247.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="door-1-casement-1-8-filter4_d"
            x="254.81"
            y="159.764"
            width="11.1753"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-1-8-filter5_d"
            x="256.097"
            y="163.12"
            width="8.60556"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="door-1-casement-1-8-paint0_linear"
            x1="34.7219"
            y1="62.5"
            x2="27.2093"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-8-paint1_linear"
            x1="25.234"
            y1="47.5"
            x2="32.7447"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-8-paint2_linear"
            x1="34.7201"
            y1="62.5"
            x2="27.2094"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-8-paint3_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-8-paint4_linear"
            x1="25.2"
            y1="275.625"
            x2="32.8"
            y2="275.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-8-paint5_linear"
            x1="23.5"
            y1="287.604"
            x2="33"
            y2="287.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
