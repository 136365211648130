import * as React from 'react'
import { Link } from 'react-router-dom'

export default function Error404NotFound() {
  return (
    <div style={{ minHeight: '100vh', paddingTop: 100, textAlign: 'center' }}>
      <div>'Ошибка 404'</div>
      <h1 style={{ margin: 30 }}>Запрошенная страница не найдена</h1>
      <p>
        <Link to="/calculator">Вернуться на главную</Link>
      </p>
    </div>
  )
}
