import React, { FC } from 'react'
import styled from 'styled-components'
import { IProductImageWrapperProps } from './interface'

export const ProductImageWrapperStyle: any = (isClickable = false) => ({
  position: 'absolute',
  top: '0',
  bottom: '0',
  left: '0',
  right: '0',
  width: '100%',
  height: '95%',
  pointerEvents: isClickable ? 'inherit' : 'none',
})

export const NestingProductImageWrapper = styled.div<{ isClickable?: boolean }>`
  g {
    cursor: pointer;
  }

  svg {
    pointer-events: inherit;

    position: ${({ isClickable }) => (isClickable ? 'relative' : 'absolute')};

    max-width: 100%;
    max-height: 100%;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export const ProductImageWrapper: FC<IProductImageWrapperProps> = ({ children, isClickable }) => {
  return (
    <NestingProductImageWrapper
      style={ProductImageWrapperStyle(isClickable)}
      isClickable={isClickable}
    >
      {children}
    </NestingProductImageWrapper>
  )
}

export const ProductImagesWrapperStyle = (onlyView?: boolean): any => ({
  backgroundColor: `${onlyView ? 'transparent' : '#d8dde3'}`,
  position: 'relative',
  width: '100%',
  height: onlyView ? '220px' : '100%',
  maxHeight: '507px',
})

export const PreviewBody = styled.div``

export const MosquitoButtonItemStyle = (x = 0, y = 0, active = false, clearView = false): any => ({
  display: 'display: flex; display: -webkit-box; display: -webkit-flex; display: -ms-flexbox;',
  justifyContent: 'center',
  '-webkitBoxPack': 'center',
  alignItems: 'center',
  position: 'absolute',
  top: `${y}px`,
  left: `${x}px`,
  width: '30px',
  height: '40px',
  color: active ? '#000000' : '#ffffff',
  cursor: 'pointer',
  transition: 'opacity 0.3s',
  transform: clearView ? '' : 'translate(-50%, -50%)',
})

export const MosquitoButtonTextStyle = (size = 5) => ({
  fontSize: `${size}vh`,
})
