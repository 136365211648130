import React, { FC, useCallback, useEffect, useMemo, useState, useRef, useContext } from 'react'
import { useModal } from 'react-modal-hook'
import { useHistory, Link } from 'react-router-dom'

import { ButtonViews, IOrderForecastItem, IStageOrderProps, PayTypes } from '../interface'
import {
  StageOrderMain,
  StageOrderProductsList,
  StageOrderWrapper,
  StageOrderEmptyWrapper,
  StageOrderEmptyTitle,
  StageOrderEmptyDescription, StageOrderLeftBlock,
} from './styles'
import { OrderItem } from './order-item'
import { Button } from '../../../components'
import { Delivery } from './delivery'
import { Mounting } from './mounting'
import { Recipient } from './recipient'
import { Notifications } from './notifications'
import {
  CloseButton,
  ModalBody,
  ModalDescription,
  ModalTitle,
  ModalWrapper,
  ModalFooter,
} from '../styles'
import fetchAPI from '../../../../lib/utils/fetch-api'
import {
  usePrice,
  useRegister,
  useMountingPrice,
  useSendOrder,
  useOrders,
  useSuggestionsAddress,
} from '../../../hooks'
import { calculateProductsSquare } from '../stage-calculator/consts'
import { CloseIcon } from '../../../assets/icons'
import { fixBody, jsonParse } from '../../../utilits'
import { AdditionalBlock } from './additional-block'
import { IPayType } from './interface'
import { MainContext } from '../calculator'
import { CalcIcon } from '../../../assets/icons/calc'
import {getLocalStorageItem, setLocalStorageItem} from "../../../services/local-storage";

function throttle(func, ms) {
  let timerId: any
  function wrapper() {
    clearTimeout(timerId)
    timerId = setTimeout(func, ms)
  }
  return wrapper
}

export const StageOrder: FC<IStageOrderProps> = props => {
  const { onChangeType, products, onBackToCart, currentOrder, onNewCalculate } = props
  const { onSetOrder, onDeleteOrder, currentOrderId } = useContext(MainContext)
  const [totalSquare, setTotalSquare] = useState(0)
  const [deliveryCost, setDeliveryCost] = useState(0)
  const [mounting, setMounting] = useState<Record<string, any> | null>(null)
  const [delivery, setDelivery] = useState<Record<string, any>>({})
  const [notifications, setNotifications] = useState<Record<string, any>>({})
  const [recipient, setRecipient] = useState<Record<string, any>>({})
  const [payType] = useState<IPayType>({
    text: 'Карта',
    id: PayTypes.CARD,
  })
  const [totalPriceList, setTotalPriceList] = useState<Record<string, number>[]>([])
  const [deliveryCalculateProcess, setDeliveryCalculateProcess] = useState(false)
  const updateTimer = useRef<ReturnType<typeof setTimeout> | null>(null)
  const isErrorDelivery = useRef(false)

  const { profileData, onFetchProfile } = useRegister()

  const {
    calculateInProcess: mountingCalculateInProcess,
    mountingPrice,
    mountingPriceSeparately,
    calculateMountingPrice,
  } = useMountingPrice({ products })
  const { updateOrder } = useSendOrder()
  const { forecast: orderForecast, fetchForecast, order } = useOrders()
  const { finalOrderPriceCalculate } = usePrice()
  const history = useHistory()
  const { toggleFixBody } = fixBody()
  const { onSuggest } = useSuggestionsAddress()

  useEffect(() => {
    if (currentOrderId) {
      history.replace(`/order?stage=ORDER&id=${currentOrderId}`)
    }
  }, [currentOrderId, history])

  useEffect(() => {
    if (currentOrder && currentOrder['orderAdditionalData']) {
      if (
        currentOrder['orderAdditionalData']['notifications'] &&
        Object.keys(notifications).length === 0
      ) {
        setNotifications(currentOrder['orderAdditionalData']['notifications'])
      }
    }
  }, [currentOrder, notifications, order])

  const handleHideModal = useCallback(
    hideModal => () => {
      hideModal()
      toggleFixBody()
    },
    [toggleFixBody],
  )

  const handleOpenPersonalArea = useCallback(() => {
    history.push('/personal')
// @ts-ignore
// eslint-disable-next-line
    window.location.reload()
  }, [history])

  const handleNewCalculate = useCallback(() => {
    onSetOrder(null)
    onNewCalculate()
  }, [onNewCalculate, onSetOrder])

  const [showModal, hideModal] = useModal(({ in: open }) => (
    <ModalWrapper show={open}>
      <ModalBody>
        <CloseButton onClick={handleHideModal(hideModal)}>
          <CloseIcon />
        </CloseButton>
        <div>
          <ModalTitle>Заказ №{currentOrderId}</ModalTitle>
          <ModalDescription>
            Благодарим за обращение. Менеджер свяжется с вами в течение 15 минут
          </ModalDescription>
        </div>
        <ModalFooter>
          <Button typeView={ButtonViews.ORANGE} onClick={handleOpenPersonalArea}>
            Перейти в личный кабинет
          </Button>
        </ModalFooter>
      </ModalBody>
    </ModalWrapper>
  ))

  const handleOpenModal = useCallback(async () => {
    toggleFixBody(true)

    showModal()
    await fetchAPI('/api/web-hooks/set-case-to-dealer', {
      method: 'POST',
      body: JSON.stringify({
        'target-id': currentOrderId,
      }),
    })
  }, [showModal, toggleFixBody, currentOrderId])

  const handleOpenCallbackModal = useCallback(
    hideModal => async () => {
      toggleFixBody(true)
      hideModal()
      showModal()
      await fetchAPI('/api/web-hooks/set-case-to-dealer', {
        method: 'POST',
        body: JSON.stringify({
          'target-id': currentOrderId,
        }),
      })
    },
    [showModal, toggleFixBody, currentOrderId],
  )

  const [showDeliveryErrorModal, hideDeliveryErrorModal] = useModal(({ in: open }) => (
    <ModalWrapper show={open}>
      <ModalBody>
        <CloseButton onClick={handleHideModal(hideDeliveryErrorModal)}>
          <CloseIcon />
        </CloseButton>
        <div>
          <ModalDescription>
            Для правильного расчета доставки отправьте запрос менеджеру
          </ModalDescription>
        </div>
        <ModalFooter>
          <Button
            typeView={ButtonViews.ORANGE}
            onClick={handleOpenCallbackModal(hideDeliveryErrorModal)}
          >
            Связаться со специалистом
          </Button>
        </ModalFooter>
      </ModalBody>
    </ModalWrapper>
  ))

  const endShowDeliveryErrorModal = useCallback(() => {
    if (isErrorDelivery.current) {
      showDeliveryErrorModal()
    }
  }, [showDeliveryErrorModal])

  const throttleShowDeliveryErrorModal = useCallback(
    throttle(endShowDeliveryErrorModal, 15 * 1000),
    [endShowDeliveryErrorModal],
  )

  const requestDeliveryPrice = useCallback(
    (initDelivery?: Record<string, any>) => {
      const url = '/api/web-hooks/calc-delivery'
      const innerDelivery = initDelivery || delivery

      const startRequest = async () => {
        try {
          setDeliveryCalculateProcess(true)
          const deliveryData = await onSuggest(
            `${innerDelivery['city']} ${innerDelivery['street']}`,
          )
          const currentAddress =
            deliveryData &&
            deliveryData['suggestions'] &&
            Array.isArray(deliveryData['suggestions'])
              ? deliveryData['suggestions'][0]
              : null

          if (currentAddress) {
            const result = await fetchAPI(url, {
              method: 'POST',
              body: JSON.stringify({
                'target-id': currentOrderId,
                latitude: currentAddress['data']['geo_lat'],
                longitude: currentAddress['data']['geo_lon'],
                address: currentAddress['unrestricted_value'],
                stage: innerDelivery['stage'] || 0,
                'lifting-type': innerDelivery?.['lifting']?.['id'],
              }),
            })
            if (result?.['error'] ?? true) {
              isErrorDelivery.current = true
              throttleShowDeliveryErrorModal()
              setDeliveryCost(0)
              setDeliveryCalculateProcess(false)
              return
            }
            if (result?.['price']) {
              isErrorDelivery.current = false
              setDeliveryCost(Math.trunc(Number(result['price'])))
              setDeliveryCalculateProcess(false)
            }
          }
        } catch (e) {
          console.error('requestDeliveryPrice - error - ', e)
        }
      }

      if (innerDelivery['city'] && innerDelivery['street']) {
        startRequest()
      }
    },
    [delivery, onSuggest, currentOrderId, throttleShowDeliveryErrorModal],
  )

  const start = useCallback(async () => {
    // eslint-disable-next-line no-undef
    const localStorageDelivery: Record<string, any> = jsonParse(
      await getLocalStorageItem('delivery') || 'null',
    )
    // eslint-disable-next-line no-undef
    const localStorageMounting: Record<string, any> = jsonParse(
      await getLocalStorageItem('mounting') || '{}',
    )

    if (localStorageDelivery) {
      setDelivery(localStorageDelivery)
      requestDeliveryPrice(localStorageDelivery)
    }

    if (Boolean(localStorageDelivery?.value) === false) {
      await fetchAPI('web-hooks/find-case').then(result => {
        const nextDeli = result?.['data']?.['services']?.['table']
        const deliData =
          nextDeli?.find?.(data => data?.code === 'delivery')?.['descriptionData'] || {}

        setDelivery(deliData)
        requestDeliveryPrice(deliData)
      })
    }

    if (localStorageMounting) {
      setMounting(localStorageMounting)
    }

    // Значение по умолчанию
    if (Boolean(localStorageMounting) === false) {
      setMounting({
        mounting: false,
        'mounting-kit': false,
        'house-type': {
          id: 'panel',
          value: 'panel',
          name: 'house-type',
          text: 'Панель',
        },
        'mounting-type': {
          id: 'gost',
          value: 'gost',
          name: 'mounting-type',
          text: 'Технические условия (ТУ)',
        },
        demounting: false,
      })
    }

  }, [])


  useEffect(() => {
    start()
    onFetchProfile()
    fetchForecast()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (products && products.length > 0) {
      const square = calculateProductsSquare(products)

      setTotalSquare(square)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products])

  useEffect(() => {
    if (currentOrder) {
      if (currentOrder['totalPriceList']) {
        setTotalPriceList(currentOrder['totalPriceList'])
      }
      if (currentOrder['type']) {
        onChangeType(currentOrder['type'])
      }
      if (currentOrder['orderAdditionalData'] && currentOrder['orderAdditionalData']['recipient']) {
        setRecipient(currentOrder['orderAdditionalData']['recipient'])
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrder])

  const isNotStandard = useMemo(() => {
    return products.some(order => {
      let result = false
      const type = order['filters'].find(filter => filter['key'] === 'product-type')

      if (type && order['product'][`${type['value']}-shape`]) {
        result = order['product'][`${type['value']}-shape`]['is-non-standard-shape'] === true
      }

      return result
    })
  }, [products])

  const getCurrentForecast = useCallback(
    (serviceType = '') => {
      let result: IOrderForecastItem | null = null

      if (orderForecast && orderForecast.length > 0) {
        const forecast = orderForecast.find(cast => cast.code === serviceType)

        if (forecast) {
          result = forecast
        }
      }

      return result
    },
    [orderForecast],
  )

  const getDeliveryData = useCallback(
    (newDelivery: Record<string, any>) => {
      const deliveryList: string[] = []
      const deliveryKeys = Object.keys(newDelivery)
      const deliveryForecast = getCurrentForecast('delivery')

      deliveryKeys.forEach(deliveryKey => {
        switch (deliveryKey) {
          case 'apartment':
            deliveryList.push(`кв. ${newDelivery['apartment']}`)
            break
          case 'entrance':
            deliveryList.push(`под. ${newDelivery['entrance']}`)
            break
          case 'lifting':
            if (newDelivery['lifting'] && newDelivery['lifting']['text']) {
              let lifting = newDelivery['lifting']['text']

              if (newDelivery['stage']) {
                lifting += ` ${newDelivery['stage']} этаж`
              }

              deliveryList.push(lifting)
            }

            break
          case 'street':
          case 'city':
            deliveryList.push(newDelivery[deliveryKey])
            break
          case 'comment':
            deliveryList.push(newDelivery['comment'])
            break
          default:
            break
        }
      })

      return {
        name: 'Доставка',
        code: 'delivery',
        forecast: deliveryForecast,
        price: deliveryCost,
        description: deliveryList.join(', '),
        descriptionData: newDelivery,
      }
    },
    [deliveryCost, getCurrentForecast],
  )

  const getMounting = useCallback(
    (newMounting: Record<string, any>) => {
      const mountingForecast = getCurrentForecast('mounting')
      const mountingDescription: string[] = []

      if (newMounting['mounting']) {
        if (newMounting['house-type']) {
          mountingDescription.push(`Тип дома: ${newMounting['house-type']['text']}`)
        }

        if (newMounting['mounting-type']) {
          mountingDescription.push(`Тип монтажа: ${newMounting['mounting-type']['text']}`)
        }
      }

      return {
        name: 'Монтаж',
        code: 'mounting',
        forecast: mountingForecast,
        price: mountingPriceSeparately['mounting'],
        description: mountingDescription.join(', '),
      }
    },
    [getCurrentForecast, mountingPriceSeparately],
  )

  useEffect(() => {
    const startUpdate = async (orderId: number) => {
      const services: {}[] = []
      let newDelivery = {}
      const orderRecipient = { ...recipient }
      const calcMountingPriceSeparatelyByProducts = (price = 0) => {
        const specification = currentOrder ? currentOrder['specification-products'] : {}
        const count = new Set((specification?.['transition-products'] ?? []).map(item => item['number'] ?? 0)).size || 1
        return count * price
      }

      if (delivery) {
        newDelivery = getDeliveryData(delivery)
        // eslint-disable-next-line no-undef
        await setLocalStorageItem('delivery', JSON.stringify(delivery))
        services.push(newDelivery)
      }

      if (mounting) {
        const newMounting = getMounting(mounting)

        // eslint-disable-next-line no-undef
        await setLocalStorageItem('mounting', JSON.stringify(mounting))

        services.push(newMounting)

        if (mountingPriceSeparately['demounting']) {
          services.push({
            name: 'Демонтаж',
            price: mountingPriceSeparately['demounting'],
            code: 'demounting',
          })
        }

        if (mountingPriceSeparately['garbage-removal']) {
          services.push({
            name: 'Вывоз мусора',
            price: mountingPriceSeparately['garbage-removal'],
            code: 'garbage-remove',
          })
        }

        if (mountingPriceSeparately['mounting-kit']) {
          services.push({
            name: 'Комплект для монтажа',
            price: calcMountingPriceSeparatelyByProducts(mountingPriceSeparately['mounting-kit']),
            code: 'mounting-kit',
          })
        }
      }

      if (recipient) {
        if (recipient['other-recipient-first-name'] || recipient['other-recipient-last-name']) {
          orderRecipient['decision-maker'] = [
            recipient['other-recipient-first-name'],
            recipient['other-recipient-last-name'],
          ].join(' ')
          orderRecipient['additional-name'] = [
            recipient['other-recipient-first-name'],
            recipient['other-recipient-last-name'],
          ].join(' ')
        }
        if (recipient['other-recipient-phone']) {
          orderRecipient['additional-phone'] = recipient['other-recipient-phone']
        }
      }

      const specification = currentOrder ? currentOrder['specification-products'] : {}
      const source = {
        ...(specification ? specification['source'] : {}),
        forecast: getCurrentForecast('manufacture'),
        totalPriceList,
        orders: products,
        orderAdditionalData: {
          mounting,
          delivery,
          notifications,
          recipient,
        },
      }
      const cartOrder = {
        'specification-products': {
          ...(specification || {}),
          source,
        },
        services: services.filter(item => Number(item?.['price']) > 0),
        'delivery-address': delivery,
        ...orderRecipient,
        notification: notifications,
      }

      finalOrderPriceCalculate(orderId, source)
      if (Object.keys(specification).length > 0 && products.length > 0) {
        await updateOrder(orderId, cartOrder)
      }
    }

    if (
      currentOrderId !== null &&
      ((mounting && Object.keys(mounting).length) || Object.keys(delivery).length)
    ) {
      if (updateTimer.current) {
        clearTimeout(updateTimer.current)
      }

      updateTimer.current = setTimeout(() => startUpdate(currentOrderId), 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mounting,
    currentOrderId,
    delivery,
    recipient,
    products,
    notifications,
    getMounting,
    mountingPriceSeparately,
  ])

  const handleMountingChange = useCallback(value => {
    setMounting(prevState => {
      return {
        ...prevState,
        ...value,
      }
    })
  }, [])

  useEffect(() => {
    calculateMountingPrice({
      ...(mounting || {}),
      itemsSquare: totalSquare,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounting, totalSquare])

  const handleDeliveryChange = useCallback(
    async value => {
      let newDelivery: Record<string, any> = {}
      await setDelivery(prevState => {
        newDelivery = {
          ...prevState,
          ...value,
        }
        return newDelivery
      })
      requestDeliveryPrice(newDelivery)
    },
    [requestDeliveryPrice]
  )

  const handleNotificationsChange = useCallback(value => {
    setNotifications(prevState => ({
      ...prevState,
      ...value,
    }))
  }, [])

  const handleRecipientChange = useCallback(value => {
    // console.log('handleRecipientChange', {value})
    setRecipient(prevState => ({
      ...prevState,
      ...value,
    }))
  }, [])

  const handleDeleteOrder = useCallback(
    (id: string) => () => {
      onDeleteOrder(id)
    },
    [onDeleteOrder],
  )

  const totalCost = useMemo(() => {
    return currentOrder && Math.trunc(Number(currentOrder['totalCost']))
  }, [currentOrder])

  const resultPrice = useMemo(() => {
    return totalCost + mountingPrice + deliveryCost
  }, [deliveryCost, mountingPrice, totalCost])

  useEffect(() => {
    // console.log('delivery', { delivery })
  }, [delivery])

  const stageOrderWrapperRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <StageOrderWrapper ref={stageOrderWrapperRef}>
        <StageOrderLeftBlock>
          {products.length === 0 && (
            <StageOrderEmptyWrapper>
              <StageOrderEmptyTitle>Пусто</StageOrderEmptyTitle>
              <StageOrderEmptyDescription>
                Выберите{' '}
                <a href="/gotovye-okna-pvh/">
                  готовое окно
                </a>
                или отправьте нам свой
                <Link to="/podbor" onClick={handleNewCalculate}>
                  заказ
                </Link>
              </StageOrderEmptyDescription>
            </StageOrderEmptyWrapper>
          )}
          {products.length > 0 && (
            <>
              <StageOrderMain>
                <StageOrderProductsList>
                  {products.map((item, index) => (
                    <OrderItem
                      key={`order-list-item-${item['id']}`}
                      itemNumber={index + 1}
                      product={item['product']}
                      sizes={item['sizes']}
                      onDeleteOrder={handleDeleteOrder(item['id'])}
                      count={1}
                      filters={item['filters']}
                      totalPrice={totalPriceList[index]}
                      isSdk={ 'sdk-id' in item}
                      bitrixId={item?.['bitrix-id']}
                    />
                  ))}
                </StageOrderProductsList>
              </StageOrderMain>
              <Delivery
                onChange={handleDeliveryChange}
                forecast={getCurrentForecast('delivery')}
                data={delivery}
              />
              {mounting && (
                <Mounting
                  onChange={handleMountingChange}
                  forecast={getCurrentForecast('mounting')}
                  data={mounting}
                />
              )}
              <Recipient
                onChange={handleRecipientChange}
                profileData={profileData}
                recipient={recipient}
              />
              <Notifications onChange={handleNotificationsChange} data={notifications} />
            </>
          )}
        </StageOrderLeftBlock>
        <AdditionalBlock
          onBackToCart={onBackToCart}
          deliveryCalculateProcess={deliveryCalculateProcess}
          onOpenModal={handleOpenModal}
          totalCost={totalCost}
          products={products}
          deliveryCost={deliveryCost}
          isNotStandard={isNotStandard}
          mountingPrice={mountingPrice}
          delivery={delivery}
          payType={payType}
          resultPrice={resultPrice}
          mountingCalculateInProcess={mountingCalculateInProcess}
          mounting={mounting}
          recipient={recipient}
          stageWrapperRef={stageOrderWrapperRef}
        />
      </StageOrderWrapper>
    </>
  )
}

export default StageOrder
