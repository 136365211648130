import React, { FC } from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'
import { IContainerSquareProps } from '../../interface'
import { ContainerBackground } from '../container-background'

export const ContainerSquare32Casement: FC<IContainerSquareProps> = props => {
  const { onCasementClick, withoutBackground } = props

  return (
    <ProductImageWrapper isClickable>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <rect width="449" height="333" fill="white" />
        <rect x="24" y="28" width="401" height="264" fill="#E8EAEE" />
        <rect x="24" y="28" width="401" height="264" fill="url(#pattern0)" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M439 10H10V292H24V29H425V292H439V10Z"
          fill="#C4C4C4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M439 292H10V308H0V316H10H439H449V308H439V292Z"
          fill="url(#paint0_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M425 28H23V292H425V28ZM148.312 41H36.0324V279H148.312V41ZM168 41H280V279H168V41ZM412 41H300V279H412V41Z"
          fill="#E8EAEE"
        />
        <path d="M439 10L425 28.0571V292.075L439 308V10Z" fill="#E9EBEF" />
        <path d="M439 10L425 28.0571V292.075L439 308V10Z" fill="#020202" fillOpacity="0.06" />
        <path d="M10 10L24 28.1525V291.982L10 308V10Z" fill="#E9EBEF" />
        <path d="M10 10L24 28.1525V291.982L10 308V10Z" fill="#020202" fillOpacity="0.06" />
        <g
          filter="url(#3-2_filter0_i)"
          className="casement"
          data-index="2"
          onClick={onCasementClick(2)}
        >
          <rect x="300" y="41" width="112" height="238" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="300.47"
          y="41.47"
          width="111.06"
          height="237.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#3-2_filter1_i)"
          className="casement"
          data-index="1"
          onClick={onCasementClick(1)}
        >
          <rect x="168" y="41" width="112" height="238" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="168.47"
          y="41.47"
          width="111.06"
          height="237.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#3-2_filter2_i)"
          className="casement"
          data-index="0"
          onClick={onCasementClick(0)}
        >
          <rect x="36" y="41" width="113" height="238" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="36.47"
          y="41.47"
          width="112.06"
          height="237.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <path
          d="M100.985 65.7412H98.4473C97.8003 65.7412 97.2529 65.2345 97.2529 64.6356V64.4053C97.2529 63.8064 97.8003 63.2997 98.4473 63.2997H100.985C101.632 63.2997 102.18 63.8064 102.18 64.4053V64.6356C102.18 65.2575 101.657 65.7412 100.985 65.7412Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M83.4262 68.5652C83.7247 68.5652 83.7993 68.5652 84.1972 68.5652C84.9432 68.5652 85.4903 69.1052 85.4903 69.833C85.4903 70.5843 84.9184 71.1008 84.1474 71.1008C82.705 71.1008 81.8098 71.1008 80.3674 71.1008C79.8203 71.1008 79.3727 70.913 79.1488 70.4199C78.9499 70.1147 78.8255 69.2695 79.6213 68.5652C80.1436 67.9782 85.9131 61.7565 86.9327 60.6765C86.9824 60.6061 86.9327 60.5122 86.8581 60.5122C86.8332 60.5122 86.8332 60.5122 86.8084 60.5122C84.6448 60.5122 82.4812 60.5122 80.3177 60.5122C79.6711 60.5122 79.1737 60.1365 79.0494 59.573C78.9002 58.9156 79.1986 58.3522 79.7706 58.0939C79.9446 58.0235 80.1187 58 80.2928 58C83.5008 58 86.684 58 89.8921 58C90.5138 58 90.9366 58.1878 91.1852 58.7278C91.5085 59.4087 91.1604 59.8783 90.9366 60.1835C90.4889 60.7235 85.938 65.56 83.4262 68.2834C83.4014 68.3069 83.3765 68.3539 83.3268 68.3773C83.277 68.4478 83.3268 68.5652 83.4262 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M90.1854 71.1009H87.6475C87.0005 71.1009 86.4531 70.5818 86.4531 69.9684V69.7324C86.4531 69.1189 87.0005 68.5999 87.6475 68.5999H90.1854C90.8323 68.5999 91.3797 69.1189 91.3797 69.7324V69.9684C91.3797 70.6054 90.8323 71.1009 90.1854 71.1009Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M93.7158 64.5152C93.7158 62.7341 93.7158 60.9764 93.7158 59.1952C93.7158 58.4922 94.2379 58 94.9836 58C98.2152 58 101.447 58 104.678 58C105.424 58 105.946 58.5156 105.946 59.2421C105.946 59.9686 105.424 60.5077 104.678 60.5077C101.994 60.5077 99.3339 60.5077 96.6491 60.5077C96.4503 60.5077 96.4005 60.5545 96.4005 60.742C96.4005 63.2731 96.4005 65.8042 96.4005 68.3353C96.4005 68.5463 96.4751 68.5697 96.6491 68.5697C99.309 68.5697 101.994 68.5697 104.654 68.5697C105.25 68.5697 105.723 68.8978 105.897 69.39C106.095 69.9759 105.872 70.5618 105.399 70.8899C105.176 71.0305 104.902 71.1008 104.629 71.1008C101.447 71.1008 98.2401 71.1008 95.0582 71.1008C94.2378 71.1008 93.7407 70.5852 93.7407 69.8118C93.7158 68.0307 93.7158 66.273 93.7158 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M80.7054 76.0416V76.6985H79V76.0416H79.4263V74.2588H79.0502V73.6019H80.6803V74.2588H80.3041V76.0416H80.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M84.5346 76.7579H83.774L83.6513 76.5451C83.3814 76.687 83.0624 76.7579 82.7679 76.7579C81.9336 76.7342 81.1484 76.1668 81.1484 75.1501C81.1484 74.1806 81.811 73.5422 82.817 73.5422C83.6513 73.5422 84.461 74.0861 84.461 75.1501C84.461 75.5284 84.3629 75.8121 84.2156 76.0722L84.5592 76.6397V76.7579H84.5346ZM82.7925 76.0013C83.1851 76.0013 83.6022 75.7175 83.6022 75.1501C83.6022 74.5826 83.1851 74.2989 82.7925 74.2989C82.3753 74.2989 81.9827 74.5826 81.9827 75.1501C81.9827 75.7175 82.3753 76.0013 82.7925 76.0013Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M86.7838 75.7486C86.7838 75.9598 87.0294 76.0772 87.2995 76.0772C87.5451 76.0772 87.7907 75.9598 87.7907 75.7955C87.7907 75.4904 87.4223 75.4904 87.2013 75.4669C86.6119 75.42 86.0226 75.2087 86.0226 74.5515C86.0226 73.8708 86.6119 73.5422 87.275 73.5422C87.8889 73.5422 88.5274 73.8708 88.5274 74.5515H87.717C87.717 74.3403 87.496 74.2464 87.275 74.2464C87.0785 74.2464 86.8329 74.2933 86.8329 74.5281C86.8329 74.7393 87.0294 74.7862 87.3241 74.8097C88.0117 74.8567 88.601 75.021 88.601 75.7721C88.601 76.4293 87.938 76.7579 87.275 76.7579C86.5874 76.7579 85.9243 76.4293 85.9489 75.7486H86.7838Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M91.2285 74.3111H90.0374V74.7602H91.1292V75.4693H90.0374V75.9657H91.2533V76.6985H89.1689V73.6019H91.2036V74.3111H91.2285Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M94.6027 76.5803V76.6985H93.7218L93.159 75.7293H92.7186V76.6985H91.8867V73.6019C92.3761 73.6019 92.841 73.6019 93.3059 73.6019C94.0888 73.6019 94.4559 74.1692 94.4559 74.7129C94.4559 75.0675 94.2846 75.422 93.9665 75.6111L94.6027 76.5803ZM93.3059 75.0675C93.5505 75.0675 93.6484 74.8784 93.6484 74.6656C93.6484 74.4765 93.5261 74.2874 93.3059 74.2874H92.7186V75.0675H93.3059Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M96.2757 75.3342L96.5525 74.5274L96.9551 73.6019H97.8863V73.7206L96.4518 76.758H96.0995L94.665 73.7206V73.6019H95.5962L96.024 74.5274L96.2757 75.3342Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M99.7815 76.0416V76.6985H98.0762V76.0416H98.5025V74.2588H98.1263V73.6019H99.7565V74.2588H99.3803V76.0416H99.7815Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M101.835 73.6019C102.509 73.6019 103.258 73.9552 103.383 74.7796H102.559C102.434 74.4498 102.185 74.3321 101.835 74.3321C101.336 74.3321 101.011 74.6618 101.011 75.18C101.011 75.6275 101.336 76.0279 101.835 76.0279C102.185 76.0279 102.484 75.863 102.559 75.5333H103.383C103.258 76.4047 102.534 76.758 101.835 76.758C100.986 76.758 100.162 76.2163 100.162 75.18C100.162 74.1201 100.986 73.6019 101.835 73.6019Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M105.944 74.3111H104.753V74.7602H105.845V75.4693H104.753V75.9657H105.969V76.6985H103.885V73.6019H105.919V74.3111H105.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M232.985 65.7412H230.447C229.8 65.7412 229.253 65.2345 229.253 64.6356V64.4053C229.253 63.8064 229.8 63.2997 230.447 63.2997H232.985C233.632 63.2997 234.18 63.8064 234.18 64.4053V64.6356C234.18 65.2575 233.657 65.7412 232.985 65.7412Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M215.426 68.5652C215.725 68.5652 215.799 68.5652 216.197 68.5652C216.943 68.5652 217.49 69.1052 217.49 69.833C217.49 70.5843 216.918 71.1008 216.147 71.1008C214.705 71.1008 213.81 71.1008 212.367 71.1008C211.82 71.1008 211.373 70.913 211.149 70.4199C210.95 70.1147 210.826 69.2695 211.621 68.5652C212.144 67.9782 217.913 61.7565 218.933 60.6765C218.982 60.6061 218.933 60.5122 218.858 60.5122C218.833 60.5122 218.833 60.5122 218.808 60.5122C216.645 60.5122 214.481 60.5122 212.318 60.5122C211.671 60.5122 211.174 60.1365 211.049 59.573C210.9 58.9156 211.199 58.3522 211.771 58.0939C211.945 58.0235 212.119 58 212.293 58C215.501 58 218.684 58 221.892 58C222.514 58 222.937 58.1878 223.185 58.7278C223.509 59.4087 223.16 59.8783 222.937 60.1835C222.489 60.7235 217.938 65.56 215.426 68.2834C215.401 68.3069 215.376 68.3539 215.327 68.3773C215.277 68.4478 215.327 68.5652 215.426 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M222.185 71.1009H219.647C219.001 71.1009 218.453 70.5818 218.453 69.9684V69.7324C218.453 69.1189 219.001 68.5999 219.647 68.5999H222.185C222.832 68.5999 223.38 69.1189 223.38 69.7324V69.9684C223.38 70.6054 222.832 71.1009 222.185 71.1009Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M225.716 64.5152C225.716 62.7341 225.716 60.9764 225.716 59.1952C225.716 58.4922 226.238 58 226.984 58C230.215 58 233.447 58 236.678 58C237.424 58 237.946 58.5156 237.946 59.2421C237.946 59.9686 237.424 60.5077 236.678 60.5077C233.994 60.5077 231.334 60.5077 228.649 60.5077C228.45 60.5077 228.401 60.5545 228.401 60.742C228.401 63.2731 228.401 65.8042 228.401 68.3353C228.401 68.5463 228.475 68.5697 228.649 68.5697C231.309 68.5697 233.994 68.5697 236.654 68.5697C237.25 68.5697 237.723 68.8978 237.897 69.39C238.095 69.9759 237.872 70.5618 237.399 70.8899C237.176 71.0305 236.902 71.1008 236.629 71.1008C233.447 71.1008 230.24 71.1008 227.058 71.1008C226.238 71.1008 225.741 70.5852 225.741 69.8118C225.716 68.0307 225.716 66.273 225.716 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M212.705 76.0416V76.6985H211V76.0416H211.426V74.2588H211.05V73.6019H212.68V74.2588H212.304V76.0416H212.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M216.535 76.7579H215.774L215.651 76.5451C215.381 76.687 215.062 76.7579 214.768 76.7579C213.934 76.7342 213.148 76.1668 213.148 75.1501C213.148 74.1806 213.811 73.5422 214.817 73.5422C215.651 73.5422 216.461 74.0861 216.461 75.1501C216.461 75.5284 216.363 75.8121 216.216 76.0722L216.559 76.6397V76.7579H216.535ZM214.792 76.0013C215.185 76.0013 215.602 75.7175 215.602 75.1501C215.602 74.5826 215.185 74.2989 214.792 74.2989C214.375 74.2989 213.983 74.5826 213.983 75.1501C213.983 75.7175 214.375 76.0013 214.792 76.0013Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M218.784 75.7486C218.784 75.9598 219.029 76.0772 219.3 76.0772C219.545 76.0772 219.791 75.9598 219.791 75.7955C219.791 75.4904 219.422 75.4904 219.201 75.4669C218.612 75.42 218.023 75.2087 218.023 74.5515C218.023 73.8708 218.612 73.5422 219.275 73.5422C219.889 73.5422 220.527 73.8708 220.527 74.5515H219.717C219.717 74.3403 219.496 74.2464 219.275 74.2464C219.079 74.2464 218.833 74.2933 218.833 74.5281C218.833 74.7393 219.029 74.7862 219.324 74.8097C220.012 74.8567 220.601 75.021 220.601 75.7721C220.601 76.4293 219.938 76.7579 219.275 76.7579C218.587 76.7579 217.924 76.4293 217.949 75.7486H218.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M223.228 74.3111H222.037V74.7602H223.129V75.4693H222.037V75.9657H223.253V76.6985H221.169V73.6019H223.204V74.3111H223.228Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M226.603 76.5803V76.6985H225.722L225.159 75.7293H224.719V76.6985H223.887V73.6019C224.376 73.6019 224.841 73.6019 225.306 73.6019C226.089 73.6019 226.456 74.1692 226.456 74.7129C226.456 75.0675 226.285 75.422 225.966 75.6111L226.603 76.5803ZM225.306 75.0675C225.551 75.0675 225.648 74.8784 225.648 74.6656C225.648 74.4765 225.526 74.2874 225.306 74.2874H224.719V75.0675H225.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M228.276 75.3342L228.552 74.5274L228.955 73.6019H229.886V73.7206L228.452 76.758H228.099L226.665 73.7206V73.6019H227.596L228.024 74.5274L228.276 75.3342Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M231.782 76.0416V76.6985H230.076V76.0416H230.503V74.2588H230.126V73.6019H231.756V74.2588H231.38V76.0416H231.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M233.835 73.6019C234.509 73.6019 235.258 73.9552 235.383 74.7796H234.559C234.434 74.4498 234.185 74.3321 233.835 74.3321C233.336 74.3321 233.011 74.6618 233.011 75.18C233.011 75.6275 233.336 76.0279 233.835 76.0279C234.185 76.0279 234.484 75.863 234.559 75.5333H235.383C235.258 76.4047 234.534 76.758 233.835 76.758C232.986 76.758 232.162 76.2163 232.162 75.18C232.162 74.1201 232.986 73.6019 233.835 73.6019Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M237.944 74.3111H236.753V74.7602H237.845V75.4693H236.753V75.9657H237.969V76.6985H235.885V73.6019H237.919V74.3111H237.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M364.985 65.7412H362.447C361.8 65.7412 361.253 65.2345 361.253 64.6356V64.4053C361.253 63.8064 361.8 63.2997 362.447 63.2997H364.985C365.632 63.2997 366.18 63.8064 366.18 64.4053V64.6356C366.18 65.2575 365.657 65.7412 364.985 65.7412Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M347.426 68.5652C347.725 68.5652 347.799 68.5652 348.197 68.5652C348.943 68.5652 349.49 69.1052 349.49 69.833C349.49 70.5843 348.918 71.1008 348.147 71.1008C346.705 71.1008 345.81 71.1008 344.367 71.1008C343.82 71.1008 343.373 70.913 343.149 70.4199C342.95 70.1147 342.826 69.2695 343.621 68.5652C344.144 67.9782 349.913 61.7565 350.933 60.6765C350.982 60.6061 350.933 60.5122 350.858 60.5122C350.833 60.5122 350.833 60.5122 350.808 60.5122C348.645 60.5122 346.481 60.5122 344.318 60.5122C343.671 60.5122 343.174 60.1365 343.049 59.573C342.9 58.9156 343.199 58.3522 343.771 58.0939C343.945 58.0235 344.119 58 344.293 58C347.501 58 350.684 58 353.892 58C354.514 58 354.937 58.1878 355.185 58.7278C355.509 59.4087 355.16 59.8783 354.937 60.1835C354.489 60.7235 349.938 65.56 347.426 68.2834C347.401 68.3069 347.376 68.3539 347.327 68.3773C347.277 68.4478 347.327 68.5652 347.426 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M354.185 71.1009H351.647C351.001 71.1009 350.453 70.5818 350.453 69.9684V69.7324C350.453 69.1189 351.001 68.5999 351.647 68.5999H354.185C354.832 68.5999 355.38 69.1189 355.38 69.7324V69.9684C355.38 70.6054 354.832 71.1009 354.185 71.1009Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M357.716 64.5152C357.716 62.7341 357.716 60.9764 357.716 59.1952C357.716 58.4922 358.238 58 358.984 58C362.215 58 365.447 58 368.678 58C369.424 58 369.946 58.5156 369.946 59.2421C369.946 59.9686 369.424 60.5077 368.678 60.5077C365.994 60.5077 363.334 60.5077 360.649 60.5077C360.45 60.5077 360.401 60.5545 360.401 60.742C360.401 63.2731 360.401 65.8042 360.401 68.3353C360.401 68.5463 360.475 68.5697 360.649 68.5697C363.309 68.5697 365.994 68.5697 368.654 68.5697C369.25 68.5697 369.723 68.8978 369.897 69.39C370.095 69.9759 369.872 70.5618 369.399 70.8899C369.176 71.0305 368.902 71.1008 368.629 71.1008C365.447 71.1008 362.24 71.1008 359.058 71.1008C358.238 71.1008 357.741 70.5852 357.741 69.8118C357.716 68.0307 357.716 66.273 357.716 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M344.705 76.0416V76.6985H343V76.0416H343.426V74.2588H343.05V73.6019H344.68V74.2588H344.304V76.0416H344.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M348.535 76.7579H347.774L347.651 76.5451C347.381 76.687 347.062 76.7579 346.768 76.7579C345.934 76.7342 345.148 76.1668 345.148 75.1501C345.148 74.1806 345.811 73.5422 346.817 73.5422C347.651 73.5422 348.461 74.0861 348.461 75.1501C348.461 75.5284 348.363 75.8121 348.216 76.0722L348.559 76.6397V76.7579H348.535ZM346.792 76.0013C347.185 76.0013 347.602 75.7175 347.602 75.1501C347.602 74.5826 347.185 74.2989 346.792 74.2989C346.375 74.2989 345.983 74.5826 345.983 75.1501C345.983 75.7175 346.375 76.0013 346.792 76.0013Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M350.784 75.7486C350.784 75.9598 351.029 76.0772 351.3 76.0772C351.545 76.0772 351.791 75.9598 351.791 75.7955C351.791 75.4904 351.422 75.4904 351.201 75.4669C350.612 75.42 350.023 75.2087 350.023 74.5515C350.023 73.8708 350.612 73.5422 351.275 73.5422C351.889 73.5422 352.527 73.8708 352.527 74.5515H351.717C351.717 74.3403 351.496 74.2464 351.275 74.2464C351.079 74.2464 350.833 74.2933 350.833 74.5281C350.833 74.7393 351.029 74.7862 351.324 74.8097C352.012 74.8567 352.601 75.021 352.601 75.7721C352.601 76.4293 351.938 76.7579 351.275 76.7579C350.587 76.7579 349.924 76.4293 349.949 75.7486H350.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M355.228 74.3111H354.037V74.7602H355.129V75.4693H354.037V75.9657H355.253V76.6985H353.169V73.6019H355.204V74.3111H355.228Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M358.603 76.5803V76.6985H357.722L357.159 75.7293H356.719V76.6985H355.887V73.6019C356.376 73.6019 356.841 73.6019 357.306 73.6019C358.089 73.6019 358.456 74.1692 358.456 74.7129C358.456 75.0675 358.285 75.422 357.966 75.6111L358.603 76.5803ZM357.306 75.0675C357.551 75.0675 357.648 74.8784 357.648 74.6656C357.648 74.4765 357.526 74.2874 357.306 74.2874H356.719V75.0675H357.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M360.276 75.3342L360.552 74.5274L360.955 73.6019H361.886V73.7206L360.452 76.758H360.099L358.665 73.7206V73.6019H359.596L360.024 74.5274L360.276 75.3342Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M363.782 76.0416V76.6985H362.076V76.0416H362.503V74.2588H362.126V73.6019H363.756V74.2588H363.38V76.0416H363.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M365.835 73.6019C366.509 73.6019 367.258 73.9552 367.383 74.7796H366.559C366.434 74.4498 366.185 74.3321 365.835 74.3321C365.336 74.3321 365.011 74.6618 365.011 75.18C365.011 75.6275 365.336 76.0279 365.835 76.0279C366.185 76.0279 366.484 75.863 366.559 75.5333H367.383C367.258 76.4047 366.534 76.758 365.835 76.758C364.986 76.758 364.162 76.2163 364.162 75.18C364.162 74.1201 364.986 73.6019 365.835 73.6019Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M369.944 74.3111H368.753V74.7602H369.845V75.4693H368.753V75.9657H369.969V76.6985H367.885V73.6019H369.919V74.3111H369.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <rect y="316" width="449" height="17" fill="white" />
        <defs>
          <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use
              xlinkHref="#image0"
              transform="translate(-0.00768784) scale(0.00100915 0.00153332)"
            />
          </pattern>
          <filter
            id="3-2_filter0_i"
            x="300"
            y="41"
            width="112.94"
            height="240.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-2_filter1_i"
            x="168"
            y="41"
            width="112.94"
            height="240.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-2_filter2_i"
            x="36"
            y="41"
            width="113.94"
            height="240.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="301.5"
            y1="291"
            x2="301.5"
            y2="316"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DBDDE1" />
            <stop offset="1" stopColor="#F9F9F9" />
          </linearGradient>
        </defs>
      </svg>
      {!withoutBackground && <ContainerBackground />}
    </ProductImageWrapper>
  )
}
