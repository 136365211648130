import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement12Square4Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="315"
        height="333"
        viewBox="0 0 315 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#234filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M58.0005 34C59.1048 34 60 33.1048 60 32.0005C60 30.8962 59.1048 30.001 58.0005 30.001L55.8335 30.001L55.8335 30.001L45 30.001L45 30L41.9995 30C40.8952 30 40 30.8952 40 31.9995C40 33.1038 40.8952 33.999 41.9995 33.999L44.1668 33.999L44.1668 34L55 34L55 34L58.0005 34Z"
            fill="url(#234paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 55.8335 34)"
          fill="#ABABAB"
        />
        <path
          d="M59.0601 34C59.5792 34 60 33.5792 60 33.0601L60 30.941C60 30.4219 59.5792 30.001 59.0601 30.001L55 30.001L55 34L59.0601 34Z"
          fill="url(#234paint1_linear)"
        />
        <path
          d="M40.9399 30C40.4208 30 40 30.4208 40 30.9399L40 33.059C40 33.5781 40.4208 33.999 40.9399 33.999L45 33.999L45 30L40.9399 30Z"
          fill="url(#234paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 54.1666 34)"
          fill="url(#234paint3_linear)"
        />
        <g filter="url(#234filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M144.001 34C145.105 34 146 33.1048 146 32.0005C146 30.8962 145.105 30.001 144.001 30.001L141.833 30.001L141.833 30.001L131 30.001L131 30L127.999 30C126.895 30 126 30.8952 126 31.9995C126 33.1038 126.895 33.999 127.999 33.999L130.167 33.999L130.167 34L141 34L141 34L144.001 34Z"
            fill="url(#234paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 141.833 34)"
          fill="#ABABAB"
        />
        <path
          d="M145.06 34C145.579 34 146 33.5792 146 33.0601L146 30.941C146 30.4219 145.579 30.001 145.06 30.001L141 30.001L141 34L145.06 34Z"
          fill="url(#234paint5_linear)"
        />
        <path
          d="M126.94 30C126.421 30 126 30.4208 126 30.9399L126 33.059C126 33.5781 126.421 33.999 126.94 33.999L131 33.999L131 30L126.94 30Z"
          fill="url(#234paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 140.167 34)"
          fill="url(#234paint7_linear)"
        />
        <g filter="url(#234filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M150 34H35V105H150V34ZM139 45H46V94H139V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M150 34H35V105H36V35H149V105H150V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="39.4869"
          y="38.53"
          width="106.026"
          height="61.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#234filter3_i)">
          <rect
            x="46.47"
            y="45.47"
            width="92.0601"
            height="48.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="99"
          y="96"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 99 96)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#234filter4_d)">
          <rect
            x="98.356"
            y="96.7019"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 98.356 96.7019)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M98.2379 98.6951C97.8915 97.542 96.8218 96.7019 95.5559 96.7019L88.916 96.7019C87.3696 96.7019 86.116 97.9555 86.116 99.5019C86.116 99.5371 86.1167 99.5721 86.118 99.6069C86.4643 100.76 87.534 101.6 88.8 101.6L95.4399 101.6C96.9863 101.6 98.2399 100.346 98.2399 98.8001C98.2399 98.7649 98.2392 98.7299 98.2379 98.6951Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M96.881 97.0346C96.7373 97.0119 96.59 97.0001 96.4399 97.0001L89.8 97.0001C88.2536 97.0001 87 98.2537 87 99.8001C87 100.867 87.5969 101.795 88.475 102.267C87.1382 102.056 86.1161 100.898 86.1161 99.5019C86.1161 97.9555 87.3697 96.7019 88.9161 96.7019L95.556 96.7019C96.0353 96.7019 96.4865 96.8224 96.881 97.0346Z"
          fill="#FBFBFB"
        />
        <g filter="url(#234filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M95 99.5C95 98.6716 94.3284 98 93.5 98L93.2857 98L92 98L73.5 98C72.6716 98 72 98.6716 72 99.5C72 100.328 72.6716 101 73.5 101L90 101L93.5 101L93.7143 101C94.4244 101 95 100.424 95 99.7143C95 99.6771 94.9988 99.6401 94.9965 99.6035C94.9988 99.5693 95 99.5348 95 99.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M48.18 46.5L136.82 46.5L92.5 92.2811L48.18 46.5Z" stroke="white" />
        <defs>
          <filter
            id="234filter0_d"
            x="38.1201"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="234filter1_d"
            x="124.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="234filter2_d"
            x="30.3003"
            y="29.3003"
            width="126.279"
            height="82.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="234filter3_i"
            x="46"
            y="45"
            width="93.9399"
            height="51.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="234filter4_d"
            x="84.2362"
            y="94.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="234filter5_d"
            x="70.1201"
            y="96.1201"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="234paint0_linear"
            x1="42.5"
            y1="36.8"
            x2="42.5"
            y2="29.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="234paint1_linear"
            x1="57.5"
            y1="27.2018"
            x2="57.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="234paint2_linear"
            x1="42.5"
            y1="36.7982"
            x2="42.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="234paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="234paint4_linear"
            x1="128.5"
            y1="36.8"
            x2="128.5"
            y2="29.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="234paint5_linear"
            x1="143.5"
            y1="27.2018"
            x2="143.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="234paint6_linear"
            x1="128.5"
            y1="36.7982"
            x2="128.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="234paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
