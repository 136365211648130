import React, { FC, useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Input, ControlGroup, Button, PhoneInput } from '../../../components'
import { ButtonViews } from '../interface'
import { ButtonWrapper, LinkMessage, LinkWrapper, LinkListWrapper } from './styles'
import { IAuthFormProps } from './interface'
import { validatePhone } from '../../../utilits/utilits'
import { ModalDescription, ModalTitle } from '../styles'
import { errorTitles } from './consts'
import fetchAPI from '../../../../lib/utils/fetch-api'
import {
  RightConfirmWrapper,
} from './styles'
import { RegisterFormKeys } from './interface'
import { InputTypes } from '../interface'

export const AuthForm: FC<IAuthFormProps> = props => {
  const { errors, onAuth, resetErrors } = props
  const [authData, setAuthData] = useState<Record<string, any>>({})
  const [error, setError] = useState<Record<string, any>>({})
  const [stage, setStage] = useState('input')
  const [email, setEmail] = useState('')
  const [needRegister, setNeedRegister] = useState(false)

  const handleRegisterDataChange = useCallback((value: string | number | boolean, key: string) => {
      setNeedRegister(value => !value)
  }, [],)

  const handleAuthDataChange = useCallback(
    (value: string | number | boolean, key: string) => {
      if (key === 'phone') {
        setError(prevState => ({
          ...prevState,
          email: '',
        }))
      }
      setAuthData(prevState => ({
        ...prevState,
        [key]: value,
      }))
      resetErrors()
    },
    [resetErrors],
  )

  const handleAuth = useCallback(() => {
    const phoneValidError = validatePhone(authData['login'])
    // @ts-ignore
    // eslint-disable-next-line
    window.localStorage.clear()
    if (phoneValidError) {
      setError(prevState => ({ ...prevState, phone: phoneValidError }))
    } 
    if (Boolean(phoneValidError) === false) {
      onAuth(authData)
    }
  }, [authData, onAuth])

  const getErrorsView = useCallback(() => {
    const result: Record<string, string>[] = []

    if (errors && errors.length > 0) {
      errors.forEach(({ detail }) => {
        result.push({
          detail: errorTitles[detail] || detail,
        })
      })
    }

    return (
      <LinkListWrapper>
        {result.map(({ detail }, index) => (
          <>
            <LinkWrapper key={`${detail}-${index}`}>
              <LinkMessage isError>{detail}</LinkMessage>
            </LinkWrapper>
          </>
        ))}
      </LinkListWrapper>
    )
  }, [errors])

  const sendSMS = useCallback(async () => {
    const phone = authData.login
    if (Boolean(phone) === false) {
      return
    }
    const response = await fetchAPI('web-hooks/send-sms-code-from-user', {
      method: 'POST',
      body: JSON.stringify({ phone }),
    }).catch(console.error)

    const type = response?.['data']?.['type'] === 'email' ? 'email' : 'sms'
    setStage(type)
    setEmail(response?.['data']?.['email'] || '')
  }, [authData])

  const placeholder = useMemo(() => {
    if (stage === 'sms') {
      return 'Введите код из смс'
    }
    return 'Введите код из e-mail'
  }, [stage])

  const inputButtonOnClick = useMemo(() => {
    if (stage === 'input') {
      return sendSMS
    }
    return handleAuth
  }, [stage, sendSMS, handleAuth])

  const inputButtonText = useMemo(() => {
    if (stage === 'input') {
      return 'Получить код'
    }
    return 'Войти'
  }, [stage])

  return (
    <>
      <ModalTitle>Авторизация</ModalTitle>
      <ModalDescription>Перед заказом остекления, создайте учетную запись на сайте</ModalDescription>
      <ControlGroup>
        <PhoneInput
          id="login"
          onChange={handleAuthDataChange}
          name="login"
          placeholder="Телефон"
          value={authData['login']}
        />
      </ControlGroup>
      {stage === 'input' || <ControlGroup>
        <Input
          placeholder={placeholder}
          name="password"
          onChange={handleAuthDataChange}
        />
      </ControlGroup>}
      <LinkListWrapper>
        {email && <LinkWrapper>{`На почту ${email} был отпрален код`}</LinkWrapper>}
      </LinkListWrapper>
      <RightConfirmWrapper>
            <Input
              id="right-confirm"
              type={InputTypes.checkbox}
              name={RegisterFormKeys.RIGHT_CONFIRM}
              onChange={handleRegisterDataChange}
            />
            <label htmlFor="right-confirm">
              Я согласен с правилами обработки{' '}
              <Link to="/privacy-policy/" target="_blank">
                персональных данных
              </Link>
            </label>
      </RightConfirmWrapper>
      <ButtonWrapper>
        <Button typeView={ButtonViews.ORANGE} onClick={inputButtonOnClick} disabled={needRegister}>
          {inputButtonText}
        </Button>
      </ButtonWrapper>

      {getErrorsView()}
    </>
  )
}

/*
      <LinkListWrapper>
        <LinkWrapper>
          <LinkButton onClick={sendSMS}>Отправить СМС</LinkButton>
        </LinkWrapper>
      </LinkListWrapper>
*/
