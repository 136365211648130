import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement34Square82Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M100.001 158C101.105 158 102 157.105 102 156.001C102 154.896 101.105 154.001 100.001 154.001L97.8335 154.001L97.8335 154.001L87 154.001L87 154L83.9995 154C82.8952 154 82 154.895 82 155.999C82 157.104 82.8952 157.999 83.9995 157.999L86.1668 157.999L86.1668 158L97 158L97 158L100.001 158Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 97.8335 158)"
          fill="#ABABAB"
        />
        <path
          d="M101.06 158C101.579 158 102 157.579 102 157.06L102 154.941C102 154.422 101.579 154.001 101.06 154.001L97 154.001L97 158L101.06 158Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M82.9399 154C82.4208 154 82 154.421 82 154.94L82 157.059C82 157.578 82.4208 157.999 82.9399 157.999L87 157.999L87 154L82.9399 154Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 96.1666 158)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#2filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M55.0005 158C56.1048 158 57 157.105 57 156.001C57 154.896 56.1048 154.001 55.0005 154.001L52.8335 154.001L52.8335 154.001L42 154.001L42 154L38.9995 154C37.8952 154 37 154.895 37 155.999C37 157.104 37.8952 157.999 38.9995 157.999L41.1668 157.999L41.1668 158L52 158L52 158L55.0005 158Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 52.8335 158)"
          fill="#ABABAB"
        />
        <path
          d="M56.0601 158C56.5792 158 57 157.579 57 157.06L57 154.941C57 154.422 56.5792 154.001 56.0601 154.001L52 154.001L52 158L56.0601 158Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M37.9399 154C37.4208 154 37 154.421 37 154.94L37 157.059C37 157.578 37.4208 157.999 37.9399 157.999L42 157.999L42 154L37.9399 154Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 51.1666 158)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#2filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M105 99H32V154H105V99ZM95 109H42V144H95V109Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105 99H32V154H33V100H104V154H105V99Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2filter3_i)">
          <rect
            x="42.47"
            y="109.47"
            width="52.0601"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="103.53"
          width="63.9399"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="75.5283"
          y="101"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 75.5283 101)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#2filter4_d)">
          <rect
            x="74.8843"
            y="101.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 74.8843 101.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74.7663 103.695C74.4199 102.542 73.3502 101.702 72.0842 101.702L65.4443 101.702C63.8979 101.702 62.6443 102.956 62.6443 104.502C62.6443 104.537 62.645 104.572 62.6463 104.607C62.9927 105.76 64.0624 106.6 65.3283 106.6L71.9682 106.6C73.5146 106.6 74.7682 105.346 74.7682 103.8C74.7682 103.765 74.7675 103.73 74.7663 103.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.4093 102.035C73.2656 102.012 73.1183 102 72.9682 102L66.3283 102C64.7819 102 63.5283 103.254 63.5283 104.8C63.5283 105.867 64.1252 106.795 65.0033 107.267C63.6665 107.056 62.6444 105.898 62.6444 104.502C62.6444 102.956 63.898 101.702 65.4444 101.702L72.0843 101.702C72.5636 101.702 73.0148 101.822 73.4093 102.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M88.5283 104.5C88.5283 103.672 87.8567 103 87.0283 103L86.8141 103L85.5283 103L67.0283 103C66.1999 103 65.5283 103.672 65.5283 104.5V104.5C65.5283 105.328 66.1999 106 67.0283 106L83.5283 106L87.0283 106L87.2426 106C87.9527 106 88.5283 105.424 88.5283 104.714V104.714C88.5283 104.677 88.5271 104.64 88.5248 104.604C88.5271 104.569 88.5283 104.535 88.5283 104.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M44.0182 142.5L68.5 110.818L92.9818 142.5L44.0182 142.5Z" stroke="white" />
        <defs>
          <filter
            id="2filter0_d"
            x="80.1201"
            y="152.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2filter1_d"
            x="35.1201"
            y="152.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2filter2_d"
            x="27.3003"
            y="94.3003"
            width="84.2793"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2filter3_i"
            x="42"
            y="109"
            width="53.9399"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2filter4_d"
            x="60.7645"
            y="99.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2filter5_d"
            x="64.6485"
            y="101.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="84.5"
            y1="160.8"
            x2="84.5"
            y2="153.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="99.5"
            y1="151.202"
            x2="99.5"
            y2="158.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="84.5"
            y1="160.798"
            x2="84.5"
            y2="153.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="39.5"
            y1="160.8"
            x2="39.5"
            y2="153.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="54.5"
            y1="151.202"
            x2="54.5"
            y2="158.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="39.5"
            y1="160.798"
            x2="39.5"
            y2="153.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
