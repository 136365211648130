import styled, { keyframes } from 'styled-components'

 
export const InputWarapper = styled.div<{ isPlaceholder: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50px;
  width: 100%;
  border: 1px solid #E5E5E5;
  border-radius: 14px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 11px;
  & > input {
    border: none;
    background: inherit;
    width: 100%;
  }
  & > .placeholder {
    display: ${({ isPlaceholder }) => isPlaceholder ? 'flex' : 'none' };
    font-family: 'Suisse Intl';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #A8A8A8;
  }
  & .help-label {
    display: flex;
    font-family: 'Suisse Intl';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #A8A8A8;
  }
  & .address-label {
    font-family: 'Suisse Intl';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #212121;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  & > .address {
    display: ${({ isPlaceholder }) => isPlaceholder ? 'none' : 'flex' };
    flex-direction: row;
    align-items: center;
    width: 100%;
    & > div {
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: hidden;
    }
    & > button {
      margin-top: 6px;
      margin-left: 10px;
      border: none;
      background: inherit;
    }
  }
`
/*

      <span className='placeholder'>{placeholder}</span>
      <span className='help-label'>Адрес доставки</span>
      <div className='address'>
        <span>{value.value}</span>
        <button>
          <DeleteSvg />
        </button>
      </div>

*/