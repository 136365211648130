import styled from 'styled-components'

export const TextWrapper = styled.div`
  width: 100%;

  position: relative;

  margin: 28px 0 16px;

  font-size: 14px;
  line-height: 140%;
  color: #333333;
`
