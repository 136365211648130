import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement31Square4Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="315"
        height="333"
        viewBox="0 0 315 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#25filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M57.0005 116C58.1048 116 59 115.105 59 114.001C59 112.896 58.1048 112.001 57.0005 112.001L54.8335 112.001L54.8335 112.001L44 112.001L44 112L40.9995 112C39.8952 112 39 112.895 39 113.999C39 115.104 39.8952 115.999 40.9995 115.999L43.1668 115.999L43.1668 116L54 116L54 116L57.0005 116Z"
            fill="url(#25paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 54.8335 116)"
          fill="#ABABAB"
        />
        <path
          d="M58.0601 116C58.5792 116 59 115.579 59 115.06L59 112.941C59 112.422 58.5792 112.001 58.0601 112.001L54 112.001L54 116L58.0601 116Z"
          fill="url(#25paint1_linear)"
        />
        <path
          d="M39.9399 112C39.4208 112 39 112.421 39 112.94L39 115.059C39 115.578 39.4208 115.999 39.9399 115.999L44 115.999L44 112L39.9399 112Z"
          fill="url(#25paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 53.1665 116)"
          fill="url(#25paint3_linear)"
        />
        <g filter="url(#25filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M144.001 116C145.105 116 146 115.105 146 114.001C146 112.896 145.105 112.001 144.001 112.001L141.833 112.001L141.833 112.001L131 112.001L131 112L127.999 112C126.895 112 126 112.895 126 113.999C126 115.104 126.895 115.999 127.999 115.999L130.167 115.999L130.167 116L141 116L141 116L144.001 116Z"
            fill="url(#25paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 141.833 116)"
          fill="#ABABAB"
        />
        <path
          d="M145.06 116C145.579 116 146 115.579 146 115.06L146 112.941C146 112.422 145.579 112.001 145.06 112.001L141 112.001L141 116L145.06 116Z"
          fill="url(#25paint5_linear)"
        />
        <path
          d="M126.94 112C126.421 112 126 112.421 126 112.94L126 115.059C126 115.578 126.421 115.999 126.94 115.999L131 115.999L131 112L126.94 112Z"
          fill="url(#25paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 140.167 116)"
          fill="url(#25paint7_linear)"
        />
        <g filter="url(#25filter2_i)">
          <rect
            x="46.47"
            y="127.47"
            width="92.0601"
            height="148.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <g filter="url(#25filter3_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M150 116H35V287H150V116ZM139 127H46V276H139V127Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M150 116H35V287H36V117H149V287H150V116Z"
          fill="#FBFBFB"
        />
        <rect
          x="39.53"
          y="122.53"
          width="105.94"
          height="159.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M47.6782 128.5L137.322 128.5L92.5 273.309L47.6782 128.5Z" stroke="white" />
        <rect
          x="99"
          y="278"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 99 278)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#25filter4_d)">
          <rect
            x="98.356"
            y="278.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 98.356 278.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M98.2381 280.695C97.8917 279.542 96.822 278.702 95.556 278.702L88.9161 278.702C87.3697 278.702 86.1161 279.956 86.1161 281.502C86.1161 281.537 86.1168 281.572 86.1181 281.607C86.4645 282.76 87.5342 283.6 88.8001 283.6L95.44 283.6C96.9864 283.6 98.24 282.346 98.24 280.8C98.24 280.765 98.2393 280.73 98.2381 280.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M96.8809 279.035C96.7372 279.012 96.5899 279 96.4398 279L89.7999 279C88.2535 279 86.9999 280.254 86.9999 281.8C86.9999 282.867 87.5968 283.795 88.4749 284.267C87.1381 284.056 86.116 282.898 86.116 281.502C86.116 279.956 87.3696 278.702 88.916 278.702L95.5559 278.702C96.0352 278.702 96.4864 278.822 96.8809 279.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#25filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M95 281.5C95 280.672 94.3284 280 93.5 280L93.2857 280L92 280L73.5 280C72.6716 280 72 280.672 72 281.5C72 282.328 72.6716 283 73.5 283L90 283L93.5 283L93.7143 283C94.4244 283 95 282.424 95 281.714C95 281.677 94.9988 281.64 94.9965 281.604C94.9988 281.569 95 281.535 95 281.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="25filter0_d"
            x="37.1201"
            y="110.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="25filter1_d"
            x="124.12"
            y="110.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="25filter2_i"
            x="46"
            y="127"
            width="93.9399"
            height="151.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="25filter3_d"
            x="30.3003"
            y="111.3"
            width="126.279"
            height="182.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="25filter4_d"
            x="84.2361"
            y="276.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="25filter5_d"
            x="70.1201"
            y="278.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="25paint0_linear"
            x1="41.5"
            y1="118.8"
            x2="41.5"
            y2="111.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="25paint1_linear"
            x1="56.5"
            y1="109.202"
            x2="56.5"
            y2="116.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="25paint2_linear"
            x1="41.5"
            y1="118.798"
            x2="41.5"
            y2="111.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="25paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="25paint4_linear"
            x1="128.5"
            y1="118.8"
            x2="128.5"
            y2="111.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="25paint5_linear"
            x1="143.5"
            y1="109.202"
            x2="143.5"
            y2="116.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="25paint6_linear"
            x1="128.5"
            y1="118.798"
            x2="128.5"
            y2="111.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="25paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
