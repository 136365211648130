const isMainDomain = window.location.hostname === 'ze-okna.ru';
const isLocalhost = window.location.hostname === 'localhost';
const mainDomain = 'https://ze-okna.ru';

// Определяем тип данных для значений в LocalStorage
type LocalStorageValue = string | null;

// Функция для установки данных в LocalStorage
export function setLocalStorageItem(key: string, value: string): Promise<void> {
  return new Promise((resolve) => {
    if (isMainDomain || isLocalhost) {
      localStorage.setItem(key, value);
      resolve();
    } else {
      const iframe = document.getElementById('storageProxy') as HTMLIFrameElement;

      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage({ type: 'set', key, value }, mainDomain);

        window.addEventListener('message', function handleMessage(event: MessageEvent) {
          if (event.origin === mainDomain && event.data.type === 'set') {
            window.removeEventListener('message', handleMessage);
            resolve();
          }
        });
      } else {
        console.warn('Iframe for cross-domain storage is not available');
        resolve();
      }
    }
  });
}

// Функция для получения данных из LocalStorage
export function getLocalStorageItem(key: string): Promise<LocalStorageValue> {
  return new Promise((resolve) => {
    if (isMainDomain || isLocalhost) {
      resolve(localStorage.getItem(key));
    } else {
      const iframe = document.getElementById('storageProxy') as HTMLIFrameElement;

      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage({ type: 'get', key }, mainDomain);

        window.addEventListener('message', function handleMessage(event: MessageEvent) {
          if (event.origin === mainDomain && event.data.key === key) {
            window.removeEventListener('message', handleMessage);
            resolve(event.data.value);
          }
        });
      } else {
        console.warn('Iframe for cross-domain storage is not available');
        resolve(null);
      }
    }
  });
}
