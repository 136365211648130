import React from 'react'
import { StageDescriptionBlockContentItemIcon } from 'src/react-app/views/calculator/styles'

export const HeatSaving3 = () => {
  return (
    <StageDescriptionBlockContentItemIcon title="Для детских комнат">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.6"
          d="M32.5 40H7.5C3.3575 40 0 36.6425 0 32.5V7.5C0 3.3575 3.3575 0 7.5 0H32.5C36.6425 0 40 3.3575 40 7.5V32.5C40 36.6425 36.6425 40 32.5 40Z"
          fill="#E0E3E9"
        />
        <path
          d="M16.6663 21.6667C15.7475 21.6667 15 20.9192 15 20.0005C15 19.0817 15.7475 18.3342 16.6663 18.3342C17.585 18.3342 18.3325 19.0817 18.3325 20.0005C18.3325 20.9192 17.5863 21.6667 16.6663 21.6667Z"
          fill="#1C3775"
        />
        <path
          d="M23.3342 21.6667C22.4155 21.6667 21.668 20.9192 21.668 20.0005C21.668 19.0817 22.4155 18.3342 23.3342 18.3342C24.253 18.3342 25.0005 19.0817 25.0005 20.0005C25.0005 20.9192 24.253 21.6667 23.3342 21.6667Z"
          fill="#1C3775"
        />
        <path
          d="M12.2913 23.8005C11.0275 23.8005 10 22.773 10 21.5092C10 20.2455 11.0275 19.218 12.2913 19.218C12.6363 19.218 12.9163 19.498 12.9163 19.843V23.1767C12.9163 23.5205 12.6363 23.8005 12.2913 23.8005Z"
          fill="#1C3775"
        />
        <path
          d="M27.709 23.8005C27.364 23.8005 27.084 23.5205 27.084 23.1755V19.8417C27.084 19.4967 27.364 19.2167 27.709 19.2167C28.9727 19.2167 30.0002 20.2442 30.0002 21.508C30.0002 22.7717 28.9727 23.8005 27.709 23.8005Z"
          fill="#1C3775"
        />
        <path
          d="M19.9993 25.8342C19.1768 25.8342 18.3543 25.4855 17.5706 24.7905C17.2268 24.4842 17.1956 23.958 17.5018 23.6142C17.8068 23.2705 18.3343 23.2392 18.6781 23.5455C19.6168 24.3805 20.3818 24.3805 21.3206 23.5455C21.6643 23.2405 22.1918 23.2705 22.4968 23.6142C22.8018 23.958 22.7718 24.4855 22.4281 24.7905C21.6443 25.4867 20.8218 25.8342 19.9993 25.8342Z"
          fill="#1C3775"
        />
        <path
          d="M19.9998 30.0005C15.0148 30.0005 11.666 25.3292 11.666 20.9655V18.6642C11.666 15.823 13.3123 13.568 16.1823 12.4767C16.6135 12.3142 17.0935 12.5292 17.2573 12.9592C17.421 13.3892 17.2048 13.8705 16.7748 14.0342C15.2048 14.6317 13.3323 15.9192 13.3323 18.6642V20.9655C13.3323 24.4505 16.0698 28.3342 19.9985 28.3342C23.9273 28.3342 26.6648 24.4505 26.6648 20.9655V18.6642C26.6648 15.183 23.5598 14.0517 22.2248 13.7267C21.7773 13.618 21.5035 13.1667 21.6123 12.7205C21.7223 12.273 22.1748 11.9992 22.6185 12.108C26.196 12.9792 28.331 15.4305 28.331 18.6655V20.9667C28.3335 25.3292 24.9848 30.0005 19.9998 30.0005Z"
          fill="#1C3775"
        />
        <path
          d="M18.494 15.8342C17.849 15.8342 17.1977 15.6167 16.6977 15.218C16.1427 14.7742 15.8352 14.153 15.834 13.4692C15.8327 12.8567 16.0802 12.2705 16.5327 11.8167C17.0165 11.3317 17.679 11.053 18.3502 11.053H18.3515L18.694 11.0542C20.0902 11.0605 20.7252 11.063 22.394 10.0855C22.5915 9.97047 22.834 9.97172 23.029 10.088C23.2252 10.2042 23.3415 10.418 23.334 10.6455C23.249 13.318 21.704 15.1855 19.0965 15.7692C18.899 15.813 18.6965 15.8342 18.494 15.8342Z"
          fill="#1C3775"
        />
      </svg>
    </StageDescriptionBlockContentItemIcon>
  )
}
