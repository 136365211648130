import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement41Square4Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="315"
        height="333"
        viewBox="0 0 315 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#22filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M187.001 291C188.105 291 189 290.105 189 289.001C189 287.896 188.105 287.001 187.001 287.001L184.833 287.001L184.833 287.001L174 287.001L174 287L170.999 287C169.895 287 169 287.895 169 288.999C169 290.104 169.895 290.999 170.999 290.999L173.167 290.999L173.167 291L184 291L184 291L187.001 291Z"
            fill="url(#22paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 184.833 291)"
          fill="#ABABAB"
        />
        <path
          d="M188.06 291C188.579 291 189 290.579 189 290.06L189 287.941C189 287.422 188.579 287.001 188.06 287.001L184 287.001L184 291L188.06 291Z"
          fill="url(#22paint1_linear)"
        />
        <path
          d="M169.94 287C169.421 287 169 287.421 169 287.94L169 290.059C169 290.578 169.421 290.999 169.94 290.999L174 290.999L174 287L169.94 287Z"
          fill="url(#22paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 183.167 291)"
          fill="url(#22paint3_linear)"
        />
        <g filter="url(#22filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M274.001 291C275.105 291 276 290.105 276 289.001C276 287.896 275.105 287.001 274.001 287.001L271.833 287.001L271.833 287.001L261 287.001L261 287L257.999 287C256.895 287 256 287.895 256 288.999C256 290.104 256.895 290.999 257.999 290.999L260.167 290.999L260.167 291L271 291L271 291L274.001 291Z"
            fill="url(#22paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 271.833 291)"
          fill="#ABABAB"
        />
        <path
          d="M275.06 291C275.579 291 276 290.579 276 290.06L276 287.941C276 287.422 275.579 287.001 275.06 287.001L271 287.001L271 291L275.06 291Z"
          fill="url(#22paint5_linear)"
        />
        <path
          d="M256.94 287C256.421 287 256 287.421 256 287.94L256 290.059C256 290.578 256.421 290.999 256.94 290.999L261 290.999L261 287L256.94 287Z"
          fill="url(#22paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 270.167 291)"
          fill="url(#22paint7_linear)"
        />
        <g filter="url(#22filter2_i)">
          <rect
            x="176.47"
            y="127.47"
            width="92.0601"
            height="148.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <g filter="url(#22filter3_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M280 116H165V287H280V116ZM269 127H176V276H269V127Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M280 116H165V287H166V117H279V287H280V116Z"
          fill="#FBFBFB"
        />
        <rect
          x="169.53"
          y="122.53"
          width="105.94"
          height="159.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M267.322 274.5L177.678 274.5L222.5 129.691L267.322 274.5Z" stroke="white" />
        <rect
          x="229.528"
          y="118"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 229.528 118)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#22filter4_d)">
          <rect
            x="228.884"
            y="118.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 228.884 118.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M228.766 120.695C228.42 119.542 227.35 118.702 226.084 118.702L219.444 118.702C217.898 118.702 216.644 119.956 216.644 121.502C216.644 121.537 216.645 121.572 216.646 121.607C216.993 122.76 218.062 123.6 219.328 123.6L225.968 123.6C227.515 123.6 228.768 122.346 228.768 120.8C228.768 120.765 228.768 120.73 228.766 120.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M227.409 119.035C227.266 119.012 227.118 119 226.968 119L220.328 119C218.782 119 217.528 120.254 217.528 121.8C217.528 122.867 218.125 123.795 219.003 124.267C217.666 124.056 216.644 122.898 216.644 121.502C216.644 119.956 217.898 118.702 219.444 118.702L226.084 118.702C226.564 118.702 227.015 118.822 227.409 119.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#22filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M242.528 121.5C242.528 120.672 241.857 120 241.028 120L240.814 120L239.528 120L221.028 120C220.2 120 219.528 120.672 219.528 121.5C219.528 122.328 220.2 123 221.028 123L237.528 123L241.028 123L241.243 123C241.953 123 242.528 122.424 242.528 121.714C242.528 121.677 242.527 121.64 242.525 121.604C242.527 121.569 242.528 121.535 242.528 121.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="22filter0_d"
            x="167.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="22filter1_d"
            x="254.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="22filter2_i"
            x="176"
            y="127"
            width="93.9399"
            height="151.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="22filter3_d"
            x="160.3"
            y="111.3"
            width="126.279"
            height="182.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="22filter4_d"
            x="214.764"
            y="116.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="22filter5_d"
            x="218.649"
            y="118.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="22paint0_linear"
            x1="171.5"
            y1="293.8"
            x2="171.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="22paint1_linear"
            x1="186.5"
            y1="284.202"
            x2="186.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="22paint2_linear"
            x1="171.5"
            y1="293.798"
            x2="171.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="22paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="22paint4_linear"
            x1="258.5"
            y1="293.8"
            x2="258.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="22paint5_linear"
            x1="273.5"
            y1="284.202"
            x2="273.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="22paint6_linear"
            x1="258.5"
            y1="293.798"
            x2="258.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="22paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
