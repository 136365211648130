import fetchAPI from 'src/lib/utils/fetch-api'

import { IInsetNoteToCollectionProps } from '../../interface'

export async function insetNoteToCollection(
  note: IInsetNoteToCollectionProps,
  parentId: number
): Promise<null | number> {
  let result: number | null = null
  const name = 'note'
  const writeFile = {
    date: note['date'] || '',
    author: note['author'],
    content: note['content'],
    type: note['type']
  }

  const requestBody = {
    'parent_id': parentId || 0,
    'data': {
      'name': writeFile['name'],
      'attributes': writeFile,
      'metadata': {
        'schema': name,
      },
    },
  }

  const response = await fetchAPI(`/api/collections/${name}`, {
    method: 'POST',
    body: JSON.stringify(requestBody),
  })

  if (response['data'] && response['data']['id']){
    result = response['data']['id']
  }

  return result
}
