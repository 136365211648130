import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement21Square4TopBottomCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-1_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28 128.001C28 129.105 28.8952 130 29.9995 130C31.1038 130 31.999 129.105 31.999 128.001V125.833H31.999V115L32 115L32 111.999C32 110.895 31.1048 110 30.0005 110C28.8962 110 28.001 110.895 28.001 111.999L28.001 114.167H28V125H28V128.001Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1 0 0 -1 28 125.834)"
          fill="#ABABAB"
        />
        <path
          d="M28 129.06C28 129.579 28.4208 130 28.9399 130H31.059C31.5781 130 31.999 129.579 31.999 129.06V125H28V129.06Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M32 110.94C32 110.421 31.5792 110 31.0601 110H28.941C28.4219 110 28.001 110.421 28.001 110.94V115H32V110.94Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1 0 0 -1 28 124.166)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#2-1_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28 208.001C28 209.105 28.8952 210 29.9995 210C31.1038 210 31.999 209.105 31.999 208.001V205.833H31.999V195H32V191.999C32 190.895 31.1048 190 30.0005 190C28.8962 190 28.001 190.895 28.001 191.999V194.167H28V205H28V208.001Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1 0 0 -1 28 205.834)"
          fill="#ABABAB"
        />
        <path
          d="M28 209.06C28 209.579 28.4208 210 28.9399 210H31.059C31.5781 210 31.999 209.579 31.999 209.06V205H28V209.06Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M32 190.94C32 190.421 31.5792 190 31.0601 190H28.941C28.4219 190 28.001 190.421 28.001 190.94V195H32V190.94Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1 0 0 -1 28 204.166)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#2-1_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M219 104H32V216H219V104ZM208 115H43V205H208V115Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#2-1_filter3_i)">
          <rect
            x="43.47"
            y="115.47"
            width="164.06"
            height="89.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="37.5" y="109.5" width="176" height="101" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M219 104H32V105H218V216H219V104Z"
          fill="#FBFBFB"
        />
        <path d="M44.5 116.653L44.5 203.347L205.081 160L44.5 116.653Z" stroke="white" />
        <rect
          x="210"
          y="153"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#2-1_filter4_d)">
          <rect x="210.702" y="153.645" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M211.286 164.795C211.798 165.457 212.6 165.883 213.502 165.883C215.049 165.883 216.302 164.63 216.302 163.083V156.443C216.302 155.455 215.79 154.586 215.017 154.088C215.383 154.561 215.601 155.155 215.601 155.799V162.439C215.601 163.986 214.347 165.239 212.801 165.239C212.243 165.239 211.723 165.076 211.286 164.795Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M213.502 153.645C211.956 153.645 210.702 154.898 210.702 156.445V162.44C210.702 162.461 210.702 162.481 210.703 162.501V157.024C210.703 155.478 211.956 154.224 213.503 154.224C215.029 154.224 216.27 155.445 216.302 156.963V156.445C216.302 154.898 215.049 153.645 213.502 153.645Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-1_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M213.5 157C212.672 157 212 157.672 212 158.5V158.714V160V178.5C212 179.328 212.672 180 213.5 180V180C214.328 180 215 179.328 215 178.5V162V158.5L215 158.286C215 157.576 214.424 157 213.714 157V157C213.677 157 213.64 157.001 213.604 157.004C213.569 157.001 213.535 157 213.5 157Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="2-1_filter0_d"
            x="26.1201"
            y="108.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-1_filter1_d"
            x="26.1201"
            y="188.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-1_filter2_d"
            x="27.3003"
            y="99.3003"
            width="198.279"
            height="123.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-1_filter3_i"
            x="43"
            y="115"
            width="165.94"
            height="92.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-1_filter4_d"
            x="208.822"
            y="151.765"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-1_filter5_d"
            x="210.12"
            y="155.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="25.2"
            y1="112.5"
            x2="32.8"
            y2="112.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="34.7982"
            y1="127.5"
            x2="27.2002"
            y2="127.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="25.2018"
            y1="112.5"
            x2="32.7998"
            y2="112.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="25.2"
            y1="192.5"
            x2="32.8"
            y2="192.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="34.7982"
            y1="207.5"
            x2="27.2002"
            y2="207.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="25.2018"
            y1="192.5"
            x2="32.7998"
            y2="192.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
