import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement11Square6Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#1-1_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 40.9995C32 39.8952 31.1048 39 30.0005 39C28.8962 39 28.001 39.8952 28.001 40.9995V43.1665H28.001V54H28V57.0005C28 58.1048 28.8952 59 29.9995 59C31.1038 59 31.999 58.1048 31.999 57.0005V54.8332H32V44H32V40.9995Z"
            fill="url(#6paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 32 43.1665)"
          fill="#ABABAB"
        />
        <path
          d="M32 39.9399C32 39.4208 31.5792 39 31.0601 39H28.941C28.4219 39 28.001 39.4208 28.001 39.9399V44H32V39.9399Z"
          fill="url(#6paint1_linear)"
        />
        <path
          d="M28 58.0601C28 58.5792 28.4208 59 28.9399 59H31.059C31.5781 59 31.999 58.5792 31.999 58.0601V54H28V58.0601Z"
          fill="url(#6paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 32 44.8335)"
          fill="url(#6paint3_linear)"
        />
        <g filter="url(#1-1_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 94.9995C32 93.8952 31.1048 93 30.0005 93C28.8962 93 28.001 93.8952 28.001 94.9995V97.1665H28.001V108H28V111.001C28 112.105 28.8952 113 29.9995 113C31.1038 113 31.999 112.105 31.999 111.001V108.833H32V98H32V94.9995Z"
            fill="url(#6paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 32 97.1665)"
          fill="#ABABAB"
        />
        <path
          d="M32 93.9399C32 93.4208 31.5792 93 31.0601 93H28.941C28.4219 93 28.001 93.4208 28.001 93.9399V98H32V93.9399Z"
          fill="url(#6paint5_linear)"
        />
        <path
          d="M28 112.06C28 112.579 28.4208 113 28.9399 113H31.059C31.5781 113 31.999 112.579 31.999 112.06V108H28V112.06Z"
          fill="url(#6paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 32 98.8335)"
          fill="url(#6paint7_linear)"
        />
        <g filter="url(#1-1_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M152 34H32V118H152V34ZM141 45H43V107H141V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M152 34H32V118H33V35H151V118H152V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="36.53"
          y="38.53"
          width="110.94"
          height="74.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#1-1_filter3_i)">
          <rect
            x="43.47"
            y="45.47"
            width="97.0601"
            height="61.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="143"
          y="70"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#1-1_filter4_d)">
          <rect x="143.702" y="70.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M144.286 81.7953C144.798 82.4575 145.6 82.8838 146.502 82.8838C148.049 82.8838 149.302 81.6302 149.302 80.0838V73.4439C149.302 72.4555 148.79 71.5867 148.017 71.0884C148.383 71.5616 148.601 72.1553 148.601 72.7999V79.4398C148.601 80.9862 147.347 82.2398 145.801 82.2398C145.243 82.2398 144.723 82.0766 144.286 81.7953Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M146.502 70.644C144.956 70.644 143.702 71.8976 143.702 73.444V79.4397C143.702 79.4601 143.702 79.4805 143.703 79.5007V74.0236C143.703 72.4772 144.956 71.2236 146.503 71.2236C148.029 71.2236 149.27 72.4444 149.302 73.9627V73.444C149.302 71.8976 148.049 70.644 146.502 70.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-1_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M146.5 74C145.672 74 145 74.6716 145 75.5V75.7143V77V95.5C145 96.3284 145.672 97 146.5 97C147.328 97 148 96.3284 148 95.5V79V75.5L148 75.2857C148 74.5756 147.424 74 146.714 74C146.677 74 146.64 74.0012 146.604 74.0035C146.569 74.0012 146.535 74 146.5 74Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M44.5 105.32L44.5 46.6801L138.324 76L44.5 105.32Z" stroke="white" />
        <defs>
          <filter
            id="1-1_filter0_d"
            x="26.1201"
            y="37.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-1_filter1_d"
            x="26.1201"
            y="91.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-1_filter2_d"
            x="27.3003"
            y="29.3003"
            width="131.279"
            height="95.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-1_filter3_i"
            x="43"
            y="45"
            width="98.9399"
            height="64.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="1-1_filter4_d"
            x="141.822"
            y="68.7642"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-1_filter5_d"
            x="143.12"
            y="72.1201"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="6paint0_linear"
            x1="34.8"
            y1="56.5"
            x2="27.2"
            y2="56.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="6paint1_linear"
            x1="25.2018"
            y1="41.5"
            x2="32.7998"
            y2="41.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="6paint2_linear"
            x1="34.7982"
            y1="56.5"
            x2="27.2002"
            y2="56.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="6paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="6paint4_linear"
            x1="34.8"
            y1="110.5"
            x2="27.2"
            y2="110.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="6paint5_linear"
            x1="25.2018"
            y1="95.5"
            x2="32.7998"
            y2="95.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="6paint6_linear"
            x1="34.7982"
            y1="110.5"
            x2="27.2002"
            y2="110.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="6paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
