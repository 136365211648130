import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement2Door1RCasement2 = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="451"
        height="451"
        viewBox="0 0 451 451"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#door-1-casement-2-2-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M265 269C265 267.895 265.895 267 267 267C268.105 267 269 267.895 269 269V283.56H265V269ZM265 283.56H269V285.4H269.06C269.579 285.4 270 285.821 270 286.34V288.12C270 289.158 269.158 290 268.12 290H266.88C265.842 290 265 289.158 265 288.12V285.4V285.4V283.56Z"
            fill="white"
          />
        </g>
        <rect x="265" y="284.25" width="3.99998" height="0.95833" fill="#ABABAB" />
        <path
          d="M265 267.94C265 267.421 265.421 267 265.94 267H268.06C268.579 267 269 267.421 269 267.94V284.25H265V267.94Z"
          fill="url(#door-1-casement-2-2-paint0_linear)"
        />
        <path
          d="M265 289.06C265 289.579 265.421 290 265.94 290H269.06C269.579 290 270 289.579 270 289.06V286.148C270 285.629 269.579 285.208 269.06 285.208H265V289.06Z"
          fill="url(#door-1-casement-2-2-paint1_linear)"
        />
        <g filter="url(#door-1-casement-2-2-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M269 46.9995C269 45.8952 268.105 45 267.001 45C265.896 45 265.001 45.8952 265.001 46.9995V49.1665H265.001V60H265V63.0005C265 64.1048 265.895 65 266.999 65C268.104 65 268.999 64.1048 268.999 63.0005V60.8332H269V50H269V46.9995Z"
            fill="url(#door-1-casement-2-2-paint2_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 269 49.1665)"
          fill="#ABABAB"
        />
        <path
          d="M269 45.9399C269 45.4208 268.579 45 268.06 45H265.941C265.422 45 265.001 45.4208 265.001 45.9399V50H269V45.9399Z"
          fill="url(#door-1-casement-2-2-paint3_linear)"
        />
        <path
          d="M265 64.0601C265 64.5792 265.421 65 265.94 65H268.059C268.578 65 268.999 64.5792 268.999 64.0601V60H265V64.0601Z"
          fill="url(#door-1-casement-2-2-paint4_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 269 50.8335)"
          fill="url(#door-1-casement-2-2-paint5_linear)"
        />
        <g filter="url(#door-1-casement-2-2-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M265 34H154V301H265V34ZM254 45H165V290H254V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M265 34H154V301H155V35H264V301H265V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="158.53"
          y="38.53"
          width="101.94"
          height="257.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#door-1-casement-2-2-filter3_i)">
          <rect
            x="165.47"
            y="45.47"
            width="88.0601"
            height="244.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <path d="M252.5 287.443V47.5571L166.615 167.5L252.5 287.443Z" stroke="white" />
        <path d="M209.5 48.8375L252.403 288.5H166.597L209.5 48.8375Z" stroke="white" />
        <rect
          x="156"
          y="161"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#door-1-casement-2-2-filter4_d)">
          <rect x="156.702" y="161.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M157.286 172.795C157.798 173.457 158.6 173.884 159.502 173.884C161.049 173.884 162.302 172.63 162.302 171.084V164.444C162.302 163.455 161.79 162.587 161.017 162.088C161.383 162.562 161.6 163.155 161.6 163.8V170.44C161.6 171.986 160.347 173.24 158.8 173.24C158.242 173.24 157.723 173.077 157.286 172.795Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M159.502 161.644C157.955 161.644 156.702 162.898 156.702 164.444V170.44C156.702 170.46 156.702 170.48 156.702 170.501V165.024C156.702 163.477 157.956 162.224 159.502 162.224C161.029 162.224 162.269 163.444 162.302 164.963V164.444C162.302 162.898 161.048 161.644 159.502 161.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#door-1-casement-2-2-filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M159.5 165C158.672 165 158 165.672 158 166.5V166.714V168V186.5C158 187.328 158.672 188 159.5 188V188C160.328 188 161 187.328 161 186.5V170V166.5L161 166.286C161 165.576 160.424 165 159.714 165V165C159.677 165 159.64 165.001 159.604 165.004C159.569 165.001 159.535 165 159.5 165Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="door-1-casement-2-2-filter0_d"
            x="263.12"
            y="265.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-2-2-filter1_d"
            x="263.12"
            y="43.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-2-2-filter2_d"
            x="149.3"
            y="29.3003"
            width="122.279"
            height="278.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-2-2-filter3_i"
            x="165"
            y="45"
            width="89.9399"
            height="247.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="door-1-casement-2-2-filter4_d"
            x="154.822"
            y="159.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-2-2-filter5_d"
            x="156.12"
            y="163.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="door-1-casement-2-2-paint0_linear"
            x1="271.8"
            y1="275.625"
            x2="264.2"
            y2="275.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-2-2-paint1_linear"
            x1="273.5"
            y1="287.604"
            x2="264"
            y2="287.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-2-2-paint2_linear"
            x1="271.8"
            y1="62.5"
            x2="264.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-2-2-paint3_linear"
            x1="262.202"
            y1="47.5"
            x2="269.8"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-2-2-paint4_linear"
            x1="271.798"
            y1="62.5"
            x2="264.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-2-2-paint5_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
