import React, { FC, useCallback, useContext, useEffect, useState } from 'react'

import { SizeDivider, SizesBlock, SizesWrapper } from './styles'
import { ISizesProps } from './interface'
import { SubMenuAttribute } from '../sub-menu-attribute'
import { sizesCollection } from '../../consts'
import { ProductsContext } from '../../stage-calculator'
import { ComponentShadow } from '../component-shadow'

export const Sizes: FC<ISizesProps> = props => {
  const {
    data = {
      attributes: [],
    },
    onSelect,
    selected,
    show,
    disabled = false,
  } = props
  const { attributes = [] } = data
  const { type } = useContext(ProductsContext)
  const [parsedAttributes, setParsedAttributes] = useState<any[]>([])

  useEffect(() => {
    const parseAttributeData = attribute => {
      const result = {}

      const sizesType = (type && sizesCollection[type]) || {}

      result[attribute['id']] = sizesType[attribute['id']]

      return result
    }

    if (attributes.length > 0) {
      const tempAttribute: any[] = []
      for (const attribute of attributes) {
        const newData = parseAttributeData(attribute)
        const newAttribute = {
          ...attribute,
          data: newData[attribute['id']],
        }

        tempAttribute.push(newAttribute)
      }

      setParsedAttributes(tempAttribute)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributes.length])

  const handleSelectItem = useCallback(
    (key, item) => {
      onSelect({
        ...selected,
        [key]: item,
      })
    },
    [onSelect, selected],
  )

  return (
    <>
      {show && (
        <SizesWrapper>
          {disabled && <ComponentShadow />}
          {parsedAttributes &&
            parsedAttributes.length > 0 &&
            parsedAttributes.map((attribute, index) => (
              <SizesBlock key={`size-${attribute.id}`}>
                <SubMenuAttribute
                  attribute={attribute}
                  onSelect={handleSelectItem}
                  price={0}
                  selected={selected}
                />
                {attributes.length > 1 && attributes.length - 1 !== index && (
                  <SizeDivider>x</SizeDivider>
                )}
              </SizesBlock>
            ))}
        </SizesWrapper>
      )}
    </>
  )
}
