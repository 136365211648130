import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement33Square4VerticalCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#3-3_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.954 170.976C31.954 169.885 31.0691 169 29.9775 169C28.886 169 28.0011 169.885 28.0011 170.976L28.0011 173.167L28.001 173.167L28.001 184L27.9999 184L27.9999 187.024C27.9999 188.115 28.8848 189 29.9764 189C31.068 189 31.9529 188.115 31.9529 187.024L31.9529 184.833L31.954 184.833L31.954 174L31.954 174L31.954 170.976Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 31.954 173.166)"
          fill="#ABABAB"
        />
        <path
          d="M31.954 169.94C31.954 169.421 31.5332 169 31.0141 169L28.941 169C28.4219 169 28.0011 169.421 28.0011 169.94L28.0011 174L31.954 174L31.954 169.94Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M27.9999 188.06C27.9999 188.579 28.4208 189 28.9399 189L31.013 189C31.5321 189 31.9529 188.579 31.9529 188.06L31.9529 184L27.9999 184L27.9999 188.06Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 31.954 174.833)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#3-3_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.954 197.976C31.954 196.885 31.0691 196 29.9775 196C28.886 196 28.0011 196.885 28.0011 197.976L28.0011 200.167L28.001 200.167L28.001 211L27.9999 211L27.9999 214.024C27.9999 215.115 28.8848 216 29.9764 216C31.068 216 31.9529 215.115 31.9529 214.024L31.9529 211.833L31.954 211.833L31.954 201L31.954 201L31.954 197.976Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 31.954 200.166)"
          fill="#ABABAB"
        />
        <path
          d="M31.954 196.94C31.954 196.421 31.5332 196 31.0141 196L28.941 196C28.4219 196 28.0011 196.421 28.0011 196.94L28.0011 201L31.954 201L31.954 196.94Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M27.9999 215.06C27.9999 215.579 28.4208 216 28.9399 216L31.013 216C31.5321 216 31.9529 215.579 31.9529 215.06L31.9529 211L27.9999 211L27.9999 215.06Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 31.954 201.833)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#3-3_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 165H32.0001V220H188V165ZM178 175H42.0001V210H178V175Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 165H32.0001V220H33.0001V166H187V220H188V165Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-3_filter3_i)">
          <rect
            x="42.47"
            y="175.47"
            width="135.06"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.5301"
          y="169.53"
          width="146.94"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M43.5001 176.565L172.909 192.5L43.5001 208.435L43.5001 176.565Z" stroke="white" />
        <rect
          width="6.91954"
          height="13.5283"
          rx="3.45977"
          transform="matrix(-1 0 0 1 185.92 186)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#3-3_filter4_d)">
          <rect
            width="5.53563"
            height="12.2399"
            rx="2.76782"
            transform="matrix(-1 0 0 1 185.226 186.644)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M184.661 197.792C184.155 198.456 183.356 198.884 182.458 198.884C180.929 198.884 179.69 197.645 179.69 196.116V189.412C179.69 188.44 180.191 187.585 180.948 187.092C180.594 187.557 180.383 188.138 180.383 188.768V195.472C180.383 197.001 181.622 198.24 183.151 198.24C183.708 198.24 184.226 198.075 184.661 197.792Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M182.458 186.644C183.986 186.644 185.226 187.883 185.226 189.412V195.472C185.226 195.492 185.225 195.512 185.225 195.533V189.991C185.225 188.463 183.986 187.224 182.457 187.224C180.949 187.224 179.722 188.43 179.69 189.931V189.412C179.69 187.883 180.929 186.644 182.458 186.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-3_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M182.46 190C183.279 190 183.943 190.664 183.943 191.483V193C183.943 193.014 183.943 193.027 183.942 193.041L183.942 211.517C183.942 212.336 183.278 213 182.46 213V213C181.641 213 180.977 212.336 180.977 211.517L180.977 191.251C180.977 190.56 181.537 190 182.228 190V190C182.231 190 182.234 190 182.237 190C182.311 190 182.386 190 182.46 190V190Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="3-3_filter0_d"
            x="26.1201"
            y="167.12"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-3_filter1_d"
            x="26.1201"
            y="194.12"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-3_filter2_d"
            x="27.3004"
            y="160.3"
            width="167.279"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-3_filter3_i"
            x="42.0001"
            y="175"
            width="136.94"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-3_filter4_d"
            x="177.81"
            y="184.764"
            width="11.1753"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-3_filter5_d"
            x="179.097"
            y="188.12"
            width="8.60556"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="34.7218"
            y1="186.5"
            x2="27.2092"
            y2="186.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="25.234"
            y1="171.5"
            x2="32.7446"
            y2="171.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="34.72"
            y1="186.5"
            x2="27.2093"
            y2="186.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="34.7218"
            y1="213.5"
            x2="27.2092"
            y2="213.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="25.234"
            y1="198.5"
            x2="32.7446"
            y2="198.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="34.72"
            y1="213.5"
            x2="27.2093"
            y2="213.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
