import React, { FC } from 'react'

import { ISuccessProps } from '../../views/calculator/interface'

export const SuccessRadiusIcon: FC<ISuccessProps> = props => {
  const { color = '#D4D4D4', width = 16, height = 16 } = props

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill="transparent" />
      <path
        d="M8 0C3.58883 0 0 3.58872 0 7.99996C0 12.4112 3.58883 16 8 16C12.4112 16 16 12.4112 16 7.99996C16 3.58872 12.4113 0 8 0ZM12.5937 6.6487L7.56775 11.6747C7.35404 11.8884 7.06996 12.006 6.76777 12.006C6.46558 12.006 6.1815 11.8884 5.9678 11.6747L3.40626 9.11314C3.19255 8.89944 3.07485 8.61536 3.07485 8.31317C3.07485 8.01089 3.19255 7.72682 3.40626 7.51311C3.61988 7.29941 3.90396 7.18171 4.20623 7.18171C4.50843 7.18171 4.79259 7.29941 5.00621 7.5132L6.76769 9.27459L10.9936 5.04867C11.2073 4.83497 11.4914 4.71735 11.7936 4.71735C12.0958 4.71735 12.3799 4.83497 12.5936 5.04867C13.0348 5.48994 13.0348 6.2076 12.5937 6.6487Z"
        fill={color}
      />
    </svg>
  )
}
