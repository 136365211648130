import {
  AttributesLevelTypes,
  AttributesTypes,
  GroupTargetTypes,
  IFilterItem,
  IMapParam,
  IProductionsMenuMap,
} from './interface'

export const filterProduction: (products: any[], filters: IFilterItem[]) => any[] = (
  products,
  filters,
) => {
  let result = products.slice(0, products.length)

  if (filters.length > 0) {
    filters.forEach(({ key, value }) => {
      result = result.filter(production => production[key] === value)
    })
  }

  return result
}

export const prepareFilterData: (
  products: any[],
  filters: IFilterItem[],
  mapParams: IMapParam[],
  uniqueKey: string,
) => Promise<any[]> = async (products, filters, mapParams, uniqueKey) => {
  const olapProductions = await filterProduction(products, filters)
  const result: {}[] = []
  const finishResult: {}[] = []

  if (olapProductions.length > 0) {
    for (const product of olapProductions) {
      /* check repeat products */
      const checkValue = result.some(item => {
        let resultCheck = true

        if (uniqueKey) {
          resultCheck = resultCheck && product[uniqueKey] === item[uniqueKey]
        }

        return resultCheck
      })

      if (!checkValue) {
        result.push(product)
      }
    }
  }

  if (result.length > 0) {
    result.forEach(item => {
      const mappedResult: {} = {}

      for (const { originalKey, targetKey } of mapParams) {
        mappedResult[targetKey] = item[originalKey]
      }

      if (mappedResult['id']) {
        finishResult.push(mappedResult)
      }
    })
  }

  return finishResult
}

export const newProductsMenuMap: () => IProductionsMenuMap[] = () => [
  {
    title: 'Технические характеристики',
    code: 'technical',
    children: [
      {
        title: 'Конфигурация',
        description: 'Форма окна и деление на створки',
        'full-title': 'Выбор конфигурации',
        code: 'configuration-type',
        attributes: [
          {
            id: 'window-shape',
            title: '',
            icon: '',
            type: AttributesTypes.SELECT,
            levelType: AttributesLevelTypes.TOP_LEVEL,
            data: {},
            mapParams: [
              { originalKey: 'window-shape-id', targetKey: 'id' },
              { originalKey: 'window-shape-title', targetKey: 'text' },
              { originalKey: 'window-shape-code', targetKey: 'value' },
              { originalKey: 'window-shape-image', targetKey: 'icon' },
            ],
          },
          {
            id: 'configuration-badge',
            title: '',
            icon: '',
            type: AttributesTypes.BADGE,
            data: {
              id: 'configuration',
            },
            target: 'configuration/text',
          },
          {
            id: 'configuration',
            title: '',
            icon: '',
            type: AttributesTypes.GALLERY,
            data: {},
            target: 'configuration-value',
            mapParams: [
              { originalKey: 'configuration-id', targetKey: 'id' },
              { originalKey: 'configuration-title', targetKey: 'text' },
              { originalKey: 'configuration-short-title', targetKey: 'title' },
              { originalKey: 'configuration-code', targetKey: 'value' },
              { originalKey: 'configuration-image', targetKey: 'image' },
              { originalKey: 'configuration-mobile-image', targetKey: 'mobile-image' },
              { originalKey: 'configuration-images', targetKey: 'images' },
              { originalKey: 'configuration-is-mobile', targetKey: 'is-mobile' },
            ],
          },
        ],
      },
      {
        title: 'Модель',
        description: 'Готовое решение от ZE',
        'full-title': 'Выбор модели',
        code: 'window-model',
        attributes: [
          {
            id: 'window-model',
            title: '',
            icon: '',
            type: AttributesTypes.SELECT,
            data: {},
            mapParams: [
              { originalKey: 'window-model-id', targetKey: 'id' },
              { originalKey: 'window-model-title', targetKey: 'text' },
              { originalKey: 'window-model-value', targetKey: 'value' },
            ],
          },
          {
            id: 'window-model-text',
            title: '',
            type: AttributesTypes.TEXT,
            data: {
              value: 'Готовое решения для квартиры как лучшее соотношение цена/качество.',
            },
          },
          {
            id: 'heat-saving',
            title: '',
            description:
              'Теплосбережение окон зависит от нескольких факторов. Чем оно выше - тем лучше',
            icon: '',
            type: AttributesTypes.SLIDER,
            target: 'window-model-value',
            data: {},
            mapParams: [
              { originalKey: 'heat-saving-value', targetKey: 'id' },
              { originalKey: 'heat-saving-value', targetKey: 'value' },
              { originalKey: 'heat-saving-value-title', targetKey: 'text' },
              { originalKey: 'heat-saving-title', targetKey: 'title' },
              { originalKey: 'heat-saving-description-info', targetKey: 'description' },
            ],
          },
          {
            id: 'soundproofing',
            title: '',
            description: 'Звукоизоляция',
            icon: '',
            type: AttributesTypes.SLIDER,
            target: 'window-model-value',
            data: {},
            mapParams: [
              { originalKey: 'soundproofing-value', targetKey: 'id' },
              { originalKey: 'soundproofing-value', targetKey: 'value' },
              { originalKey: 'soundproofing-value-title', targetKey: 'text' },
              { originalKey: 'soundproofing-title', targetKey: 'title' },
              { originalKey: 'soundproofing-description-info', targetKey: 'description' },
            ],
          },
          {
            id: 'security',
            title: '',
            description: 'Безопасность',
            icon: '',
            type: AttributesTypes.SLIDER,
            target: 'window-model-value',
            groupTargetKey: 'window-model',
            groupTargetValue: 'model-2, model-3',
            data: {},
            mapParams: [
              { originalKey: 'security-value', targetKey: 'id' },
              { originalKey: 'security-value', targetKey: 'value' },
              { originalKey: 'security-value-title', targetKey: 'text' },
              { originalKey: 'security-title', targetKey: 'title' },
              { originalKey: 'security-description-info', targetKey: 'description' },
            ],
          },
        ],
      },
      {
        title: 'Функциональность',
        description: 'Удобства использования изделия',
        'full-title': 'Функциональность окна',
        code: 'functionality',
        attributes: [
          {
            id: 'children-security-badge',
            title: '',
            icon: '',
            type: AttributesTypes.BADGE,
            data: {
              id: 'children-security',
            },
            target: 'children-security/title',
            mapParams: [],
          },
          {
            id: 'comfortable-floriculture-badge',
            title: '',
            icon: '',
            type: AttributesTypes.BADGE,
            data: {
              id: 'comfortable-floriculture',
            },
            target: 'comfortable-floriculture/title',
            mapParams: [],
          },
          {
            id: 'aeration-badge',
            title: '',
            icon: '',
            type: AttributesTypes.BADGE,
            data: {
              id: 'aeration',
            },
            target: 'aeration/title',
            mapParams: [],
          },
          {
            id: 'functionality-price',
            title: '',
            description: '',
            icon: '',
            type: AttributesTypes.PRICE,
            data: {
              items: ['children-security', 'aeration'],
            },
            mapParams: [],
          },
          {
            id: 'children-security',
            title: '',
            description: 'Детская безопасность',
            icon: '',
            type: AttributesTypes.RADIOBUTTON,
            data: {},
            mapParams: [
              { originalKey: 'children-security-value', targetKey: 'id' },
              { originalKey: 'children-security-value', targetKey: 'value' },
              { originalKey: 'children-security-value-title', targetKey: 'text' },
              { originalKey: 'children-security-price', targetKey: 'price' },
              { originalKey: 'children-security-title', targetKey: 'title' },
              { originalKey: 'children-security-description', targetKey: 'description' },
            ],
          },
          {
            id: 'children-security-hr',
            title: '',
            icon: '',
            type: AttributesTypes.HR,
            mapParams: [],
            data: {},
          },
          // {
          //   id: 'pet-safety',
          //   title: 'Безопасность домашних животных',
          //   description: 'Безопасность домашних животных',
          //   icon: '',
          //   type: AttributesTypes.RADIOBUTTON,
          //   data: {},
          //   mapParams: [
          //     { originalKey: 'pet-safety-value', targetKey: 'id' },
          //     { originalKey: 'pet-safety-value', targetKey: 'value' },
          //     { originalKey: 'pet-safety-value-title', targetKey: 'text' },
          //     { originalKey: 'pet-safety-price', targetKey: 'price' },
          //   ],
          // },
          // {
          //   id: 'comfortable-floriculture',
          //   title: '',
          //   description: 'Комфортное цветоводство',
          //   icon: '',
          //   type: AttributesTypes.RADIOBUTTON,
          //   data: {},
          //   mapParams: [
          //     { originalKey: 'comfortable-floriculture-value', targetKey: 'id' },
          //     { originalKey: 'comfortable-floriculture-value', targetKey: 'value' },
          //     { originalKey: 'comfortable-floriculture-value-title', targetKey: 'text' },
          //     { originalKey: 'comfortable-floriculture-price', targetKey: 'price' },
          //     { originalKey: 'comfortable-floriculture-title', targetKey: 'title' },
          //     { originalKey: 'comfortable-floriculture-description', targetKey: 'description' },
          //   ],
          // },
          // {
          //   id: 'comfortable-floriculture-hr',
          //   title: '',
          //   icon: '',
          //   type: AttributesTypes.HR,
          //   mapParams: [],
          //   data: {},
          // },
          // {
          //   id: 'reinforced-windows-for-high-rise-buildings',
          //   title: 'Усиленные окна для высотных домов',
          //   description: 'Усиленные окна для высотных домов',
          //   icon: '',
          //   type: AttributesTypes.RADIOBUTTON,
          //   data: {},
          //   mapParams: [
          //     { originalKey: 'reinforced-windows-for-high-rise-buildings-value', targetKey: 'id' },
          //     {
          //       originalKey: 'reinforced-windows-for-high-rise-buildings-value',
          //       targetKey: 'value',
          //     },
          //     {
          //       originalKey: 'reinforced-windows-for-high-rise-buildings-value-title',
          //       targetKey: 'text',
          //     },
          //     {
          //       originalKey: 'reinforced-windows-for-high-rise-buildings-price',
          //       targetKey: 'price',
          //     },
          //   ],
          // },
          // {
          //   id: 'elderly',
          //   title: 'Пожилые люди',
          //   description: 'Пожилые люди',
          //   icon: '',
          //   type: AttributesTypes.RADIOBUTTON,
          //   data: {},
          //   mapParams: [
          //     { originalKey: 'elderly-value', targetKey: 'id' },
          //     { originalKey: 'elderly-value', targetKey: 'value' },
          //     { originalKey: 'elderly-value-title', targetKey: 'text' },
          //     { originalKey: 'elderly-price', targetKey: 'price' },
          //   ],
          // },
          // {
          //   id: 'sun-protection',
          //   title: 'Солнцезащита',
          //   description: 'Солнцезащита',
          //   icon: '',
          //   type: AttributesTypes.RADIOBUTTON,
          //   data: {},
          //   mapParams: [
          //     { originalKey: 'sun-protection-value', targetKey: 'id' },
          //     { originalKey: 'sun-protection-value', targetKey: 'value' },
          //     { originalKey: 'sun-protection-value-title', targetKey: 'text' },
          //     { originalKey: 'sun-protection-price', targetKey: 'price' },
          //   ],
          // },
          {
            id: 'aeration',
            title: '',
            description: 'Дополнительная возможность для неполного откидывания створки',
            icon: '',
            type: AttributesTypes.RADIOBUTTON,
            data: {},
            mapParams: [
              { originalKey: 'aeration-value', targetKey: 'id' },
              { originalKey: 'aeration-value', targetKey: 'value' },
              { originalKey: 'aeration-value-title', targetKey: 'text' },
              { originalKey: 'aeration-price', targetKey: 'price' },
              { originalKey: 'aeration-title', targetKey: 'title' },
              { originalKey: 'aeration-description', targetKey: 'description' },
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Внешний вид',
    code: 'appearance',
    children: [
      {
        title: 'Цвет',
        description: 'Цвет внутри, снаружи и самого профиля в массе',
        'full-title': 'Выбор цвета окна',
        code: 'window-color',
        attributes: [
          {
            id: 'window-color',
            title: '',
            description: '',
            icon: '',
            type: AttributesTypes.SELECT,
            mapParams: [],
            notFilter: true,
            data: {
              items: [
                {
                  id: 'model-outside-color',
                  text: 'Снаружи',
                  value: 'model-outside-color',
                },
                {
                  id: 'model-inside-color',
                  text: 'Внутри',
                  value: 'model-inside-color',
                },
                {
                  id: 'model-profile-color',
                  text: 'Профиль',
                  value: 'model-profile-color',
                },
              ],
            },
          },
          {
            id: 'model-outside-color-badge',
            title: '',
            icon: '',
            type: AttributesTypes.BADGE,
            data: {
              id: 'model-outside-color',
            },
            target: 'model-outside-color/text',
          },
          {
            id: 'model-outside-color-price',
            title: '',
            description: '',
            icon: '',
            type: AttributesTypes.PRICE,
            data: {
              items: ['model-outside-color'],
            },
            mapParams: [],
          },
          {
            id: 'model-inside-color-badge',
            title: '',
            icon: '',
            type: AttributesTypes.BADGE,
            data: {
              id: 'model-inside-color',
            },
            target: 'model-inside-color/text',
          },
          {
            id: 'model-inside-color-price',
            title: '',
            description: '',
            icon: '',
            type: AttributesTypes.PRICE,
            data: {
              items: ['model-inside-color'],
            },
            mapParams: [],
          },
          {
            id: 'model-profile-color-badge',
            title: '',
            icon: '',
            type: AttributesTypes.BADGE,
            data: {
              id: 'model-profile-color',
            },
            target: 'model-profile-color/text',
          },
          {
            id: 'model-profile-color-price',
            title: '',
            description: '',
            icon: '',
            type: AttributesTypes.PRICE,
            data: {
              items: ['model-profile-color'],
            },
            mapParams: [],
          },
          {
            id: 'model-outside-color',
            title: '',
            icon: '',
            type: AttributesTypes.GALLERY,
            groupTargetKey: 'window-color',
            groupTargetValue: 'model-outside-color',
            groupTargetType: GroupTargetTypes.HIDE,
            mapParams: [
              { originalKey: 'model-outside-color-code', targetKey: 'id' },
              { originalKey: 'model-outside-color-code', targetKey: 'value' },
              { originalKey: 'model-outside-color-title', targetKey: 'text' },
              { originalKey: 'model-outside-color-value', targetKey: 'image' },
              { originalKey: 'model-outside-color-code', targetKey: 'sub-text' },
              { originalKey: 'model-outside-color-collection', targetKey: 'group' },
              { originalKey: 'model-outside-color-price', targetKey: 'price' },
            ],
            data: {},
          },
          {
            id: 'model-inside-color',
            title: '',
            icon: '',
            type: AttributesTypes.GALLERY,
            groupTargetKey: 'window-color',
            groupTargetValue: 'model-inside-color',
            groupTargetType: GroupTargetTypes.HIDE,
            mapParams: [
              { originalKey: 'model-inside-color-code', targetKey: 'id' },
              { originalKey: 'model-inside-color-code', targetKey: 'value' },
              { originalKey: 'model-inside-color-title', targetKey: 'text' },
              { originalKey: 'model-inside-color-value', targetKey: 'image' },
              { originalKey: 'model-inside-color-code', targetKey: 'sub-text' },
              { originalKey: 'model-inside-color-collection', targetKey: 'group' },
              { originalKey: 'model-inside-color-price', targetKey: 'price' },
            ],
            data: {},
          },
          {
            id: 'model-profile-color',
            title: '',
            icon: '',
            type: AttributesTypes.GALLERY,
            groupTargetKey: 'window-color',
            groupTargetValue: 'model-profile-color',
            groupTargetType: GroupTargetTypes.HIDE,
            mapParams: [
              { originalKey: 'model-profile-color-code', targetKey: 'id' },
              { originalKey: 'model-profile-color-code', targetKey: 'value' },
              { originalKey: 'model-profile-color-title', targetKey: 'text' },
              { originalKey: 'model-profile-color-value', targetKey: 'image' },
              { originalKey: 'model-profile-color-code', targetKey: 'sub-text' },
              { originalKey: 'model-profile-color-collection', targetKey: 'group' },
              { originalKey: 'model-profile-color-price', targetKey: 'price' },
            ],
            data: {},
          },
        ],
      },
      {
        title: 'Ручки',
        description: 'Модель и цвет ручек/накладок на петли',
        'full-title': '',
        code: 'window-hand',
        attributes: [
          {
            id: 'window-hand-badge',
            title: 'Выбор ручки',
            icon: '',
            type: AttributesTypes.BADGE,
            data: {
              id: 'window-hand',
            },
            target: 'window-hand/text',
          },
          {
            id: 'window-hand-price',
            title: '',
            description: '',
            icon: '',
            type: AttributesTypes.PRICE,
            data: {
              items: ['window-hand'],
            },
            mapParams: [],
          },
          {
            id: 'window-hand',
            title: '',
            icon: '',
            type: AttributesTypes.GALLERY,
            mapParams: [
              { originalKey: 'window-hand-value', targetKey: 'id' },
              { originalKey: 'window-hand-value', targetKey: 'value' },
              { originalKey: 'window-hand-name', targetKey: 'text' },
              { originalKey: 'window-hand-code', targetKey: 'sub-text' },
              { originalKey: 'window-hand-image', targetKey: 'image' },
              { originalKey: 'window-hand-price', targetKey: 'price' },
            ],
            data: {},
          },
        ],
      },
      // {
      //   title: 'Раскладка окна',
      //   'full-title': '',
      //   code: 'layout',
      //   attributes: [
      //     {
      //       id: 'layout-width',
      //       title: 'Толщина раскладки',
      //       description: '',
      //       icon: '',
      //       type: AttributesTypes.SELECT,
      //       mapParams: [
      //         { originalKey: 'layout-width-value', targetKey: 'id' },
      //         { originalKey: 'layout-width-value', targetKey: 'value' },
      //         { originalKey: 'layout-width-value', targetKey: 'window-model-title' },
      //         { originalKey: 'layout-width-title', targetKey: 'text' },
      //       ],
      //       data: {},
      //     },
      //     {
      //       id: 'layout-model-value',
      //       title: 'Модель раскладки',
      //       icon: '',
      //       type: AttributesTypes.GALLERY,
      //       mapParams: [
      //         { originalKey: 'layout-model-value', targetKey: 'id' },
      //         { originalKey: 'layout-model-value', targetKey: 'value' },
      //         { originalKey: 'layout-model-name', targetKey: 'text' },
      //         { originalKey: 'layout-model-image', targetKey: 'image' },
      //         { originalKey: 'layout-model-price', targetKey: 'price' },
      //       ],
      //       data: {},
      //     },
      //     {
      //       id: 'layout-model-outside-color-value',
      //       title: 'Выбор цвета внешней стороны раскладки',
      //       icon: '',
      //       type: AttributesTypes.GALLERY,
      //       mapParams: [
      //         { originalKey: 'layout-model-outside-color-value', targetKey: 'id' },
      //         { originalKey: 'layout-model-outside-color-value', targetKey: 'value' },
      //         { originalKey: 'layout-model-outside-color-name', targetKey: 'text' },
      //         { originalKey: 'layout-model-outside-color-image', targetKey: 'image' },
      //         { originalKey: 'layout-model-outside-color-margin', targetKey: 'margin' },
      //       ],
      //       data: {},
      //     },
      //     {
      //       id: 'layout-model-inside-color-value',
      //       title: 'Выбор цвета внутренней стороны раскладки',
      //       icon: '',
      //       type: AttributesTypes.GALLERY,
      //       mapParams: [
      //         { originalKey: 'layout-model-inside-color-value', targetKey: 'id' },
      //         { originalKey: 'layout-model-inside-color-value', targetKey: 'value' },
      //         { originalKey: 'layout-model-inside-color-name', targetKey: 'text' },
      //         { originalKey: 'layout-model-inside-color-image', targetKey: 'image' },
      //         { originalKey: 'layout-model-inside-color-margin', targetKey: 'margin' },
      //       ],
      //       data: {},
      //     },
      //   ],
      // },
    ],
  },
  {
    title: 'Дополнительные элементы',
    code: 'additional',
    children: [
      {
        title: 'Подоконник',
        description: 'Декоративный подоконник',
        'full-title': '',
        code: 'windowsill',
        attributes: [
          {
            id: 'windowsill-model-not-needed',
            title: '',
            icon: '',
            type: AttributesTypes.CHECKBOX,
            mapParams: [],
            // notFilter: true,
            data: {
              items: [
                {
                  id: 'windowsill-model-not-needed',
                  name: 'windowsill-model-not-needed',
                  title: 'Подоконник не нужен',
                  value: false,
                  isEqually: false,
                  attributes: [],
                },
                {
                  id: 'windowsill-model-not-needed',
                  name: 'windowsill-model-not-needed',
                  title: 'Подоконник не нужен',
                  value: true,
                  attributes: [],
                },
              ],
            },
          },
          {
            id: 'windowsill-sizes',
            title: 'Размер подоконника, мм',
            icon: '',
            type: AttributesTypes.SIZES,
            groupTargetKey: 'windowsill-model-not-needed',
            groupTargetValue: false,
            disabledKey: 'windowsill-model-not-needed',
            data: {
              attributes: [
                {
                  id: 'windowsill-model-length',
                  title: '',
                  placeholder: 'Длина, мм',
                  icon: '',
                  type: AttributesTypes.NUMBER,
                  groupTargetKey: 'windowsill-model-not-needed',
                  groupTargetValue: false,
                  data: {
                    range: {
                      filters: [{ key: 'windowsill-model-not-needed', value: false }],
                      mapParams: [
                        { originalKey: 'windowsill-model-length-range', targetKey: 'value' },
                      ],
                      uniqueKeys: ['windowsill-model-length-range'],
                    },
                    min: {
                      filters: [{ key: 'windowsill-model-not-needed', value: false }],
                      mapParams: [
                        { originalKey: 'windowsill-model-length-range', targetKey: 'value' },
                      ],
                      uniqueKeys: ['windowsill-model-length-range'],
                    },
                    max: {
                      filters: [{ key: 'windowsill-model-not-needed', value: false }],
                      mapParams: [
                        { originalKey: 'windowsill-model-length-range', targetKey: 'value' },
                      ],
                      uniqueKeys: ['windowsill-model-length-range'],
                    },
                  },
                },
                {
                  id: 'windowsill-model-width',
                  placeholder: 'Ширина, мм',
                  title: '',
                  icon: '',
                  type: AttributesTypes.SELECT,
                  groupTargetKey: 'windowsill-model-not-needed',
                  groupTargetValue: false,
                  mapParams: [
                    { originalKey: 'windowsill-model-width-value', targetKey: 'id' },
                    { originalKey: 'windowsill-model-width-value', targetKey: 'value' },
                    { originalKey: 'windowsill-model-width-value', targetKey: 'text' },
                  ],
                },
              ],
            },
          },
          {
            id: 'windowsill-model-hr',
            title: '',
            icon: '',
            type: AttributesTypes.HR,
            groupTargetValue: false,
            disabledKey: 'windowsill-model-not-needed',
            mapParams: [],
            data: {},
          },
          {
            id: 'windowsill-model',
            title: '',
            icon: '',
            type: AttributesTypes.SELECT,
            groupTargetKey: 'windowsill-model-not-needed',
            groupTargetValue: false,
            disabledKey: 'windowsill-model-not-needed',
            mapParams: [
              { originalKey: 'windowsill-model-value', targetKey: 'id' },
              { originalKey: 'windowsill-model-value', targetKey: 'value' },
              { originalKey: 'windowsill-model-value', targetKey: 'model-value' },
              { originalKey: 'windowsill-model-title', targetKey: 'text' },
              { originalKey: 'windowsill-model-price', targetKey: 'price' },
            ],
            data: {},
          },
          {
            id: 'windowsill-model-badge',
            title: '',
            icon: '',
            type: AttributesTypes.BADGE,
            data: {
              id: 'windowsill-model',
            },
            target: 'windowsill-model/text',
            disabledKey: 'windowsill-model-not-needed',
          },
          {
            id: 'windowsill-model-price',
            title: '',
            description: '',
            icon: '',
            type: AttributesTypes.PRICE,
            data: {
              items: ['windowsill-model'],
            },
            mapParams: [],
            disabledKey: 'windowsill-model-not-needed',
          },
          {
            id: 'windowsill-model-color-hr',
            title: '',
            icon: '',
            type: AttributesTypes.HR,
            groupTargetValue: false,
            disabledKey: 'windowsill-model-not-needed',
            mapParams: [],
            data: {},
          },
          {
            id: 'windowsill-model-color-badge',
            title: 'Выбор цвета',
            icon: '',
            type: AttributesTypes.BADGE,
            data: {
              id: 'windowsill-model-color',
            },
            target: 'windowsill-model-color/text',
            disabledKey: 'windowsill-model-not-needed',
          },
          {
            id: 'windowsill-model-color',
            title: '',
            icon: '',
            type: AttributesTypes.GALLERY,
            groupTargetKey: 'windowsill-model-not-needed',
            groupTargetValue: false,
            isLastElement: true,
            mapParams: [
              { originalKey: 'windowsill-model-color-code', targetKey: 'id' },
              { originalKey: 'windowsill-model-color-code', targetKey: 'value' },
              { originalKey: 'windowsill-model-color-title', targetKey: 'text' },
              { originalKey: 'windowsill-model-color-value', targetKey: 'image' },
              { originalKey: 'windowsill-model-color-code', targetKey: 'sub-text' },
            ],
            data: {},
            disabledKey: 'windowsill-model-not-needed',
          },
        ],
      },
      {
        title: 'Откосы',
        description: 'Внутренняя /внешняя часть оконного проема, между рамой и стеной',
        'full-title': '',
        code: 'slopes',
        attributes: [
          {
            id: 'slope-switcher',
            title: '',
            type: AttributesTypes.SWITCHER,
            data: {
              items: [
                {
                  id: 'inner-slope-switcher',
                  name: 'inner-slope-switcher',
                  title: 'Внутренний',
                  value: 'inner-slope',
                },
                {
                  id: 'outer-slope-switcher',
                  name: 'outer-slope-switcher',
                  title: 'Внешний',
                  value: 'outer-slope',
                },
              ],
            },
          },
          {
            id: 'outer-slope-type-not-needed',
            title: '',
            icon: '',
            type: AttributesTypes.CHECKBOX,
            groupTargetKey: 'slope-switcher',
            groupTargetValue: 'outer-slope',
            groupTargetType: GroupTargetTypes.HIDE,
            data: {
              items: [
                {
                  id: 'outer-slope-type-not-needed',
                  name: 'outer-slope-type-not-needed',
                  title: 'Внешний откос не нужен',
                  value: false,
                  isEqually: false,
                  attributes: [],
                },
                {
                  id: 'outer-slope-type-not-needed',
                  name: 'outer-slope-type-not-needed',
                  title: 'Внешний откос не нужен',
                  value: true,
                  attributes: [],
                },
              ],
            },
          },
          {
            id: 'outer-slope-sizes',
            title: 'Размер внешнего откоса, мм',
            icon: '',
            type: AttributesTypes.SIZES,
            groupTargetKey: 'slope-switcher',
            groupTargetValue: 'outer-slope',
            groupTargetType: GroupTargetTypes.HIDE,
            disabledKey: 'outer-slope-type-not-needed',
            data: {
              attributes: [
                {
                  id: 'outer-slope-type-length',
                  title: '',
                  placeholder: 'Длина, мм',
                  icon: '',
                  type: AttributesTypes.NUMBER,
                  data: {
                    min: {
                      filters: [{ key: 'outer-slope-type-not-needed', value: false }],
                      mapParams: [
                        { originalKey: 'outer-slope-type-length-range', targetKey: 'value' },
                      ],
                      uniqueKeys: ['outer-slope-type-length-range'],
                    },
                    max: {
                      filters: [{ key: 'outer-slope-type-not-needed', value: false }],
                      mapParams: [
                        { originalKey: 'outer-slope-type-length-range', targetKey: 'value' },
                      ],
                      uniqueKeys: ['outer-slope-type-length-range'],
                    },
                  },
                },
                {
                  id: 'outer-slope-type-width',
                  placeholder: 'Ширина, мм',
                  title: '',
                  icon: '',
                  type: AttributesTypes.SELECT,
                  mapParams: [
                    { originalKey: 'outer-slope-type-width-value', targetKey: 'id' },
                    { originalKey: 'outer-slope-type-width-value', targetKey: 'value' },
                    { originalKey: 'outer-slope-type-width-value', targetKey: 'text' },
                  ],
                },
              ],
            },
          },
          {
            id: 'outer-slope-type-hr',
            title: '',
            icon: '',
            type: AttributesTypes.HR,
            mapParams: [],
            data: {},
            groupTargetKey: 'slope-switcher',
            groupTargetValue: 'outer-slope',
            groupTargetType: GroupTargetTypes.HIDE,
            disabledKey: 'outer-slope-type-not-needed',
          },
          {
            id: 'outer-slope-type-badge',
            title: 'Тип',
            icon: '',
            type: AttributesTypes.BADGE,
            data: {
              id: 'outer-slope-type',
            },
            target: 'outer-slope-type/text',
            groupTargetKey: 'slope-switcher',
            groupTargetValue: 'outer-slope',
            groupTargetType: GroupTargetTypes.HIDE,
            disabledKey: 'outer-slope-type-not-needed',
          },
          {
            id: 'outer-slope-type-price',
            title: '',
            description: '',
            icon: '',
            type: AttributesTypes.PRICE,
            data: {
              items: ['outer-slope-type'],
            },
            mapParams: [],
            groupTargetKey: 'slope-switcher',
            groupTargetValue: 'outer-slope',
            groupTargetType: GroupTargetTypes.HIDE,
            disabledKey: 'outer-slope-type-not-needed',
          },
          {
            id: 'outer-slope-type',
            title: '',
            icon: '',
            type: AttributesTypes.GALLERY,
            view: 2,
            groupTargetKey: 'slope-switcher',
            groupTargetValue: 'outer-slope',
            groupTargetType: GroupTargetTypes.HIDE,
            disabledKey: 'outer-slope-type-not-needed',
            mapParams: [
              { originalKey: 'outer-slope-type-code', targetKey: 'id' },
              { originalKey: 'outer-slope-type-code', targetKey: 'value' },
              { originalKey: 'outer-slope-type-title', targetKey: 'text' },
              { originalKey: 'outer-slope-type-value', targetKey: 'image' },
              { originalKey: 'outer-slope-type-price', targetKey: 'price' },
              { originalKey: 'outer-slope-type-length-range', targetKey: 'range' },
            ],
          },
          {
            id: 'outer-slope-type-color-badge',
            title: 'Цвет',
            icon: '',
            type: AttributesTypes.BADGE,
            data: {
              id: 'outer-slope-type-color',
            },
            target: 'outer-slope-type-color/text',
            groupTargetKey: 'slope-switcher',
            groupTargetValue: 'outer-slope',
            groupTargetType: GroupTargetTypes.HIDE,
            disabledKey: 'outer-slope-type-not-needed',
          },
          {
            id: 'outer-slope-type-color',
            title: '',
            icon: '',
            type: AttributesTypes.GALLERY,
            groupTargetKey: 'slope-switcher',
            groupTargetValue: 'outer-slope',
            groupTargetType: GroupTargetTypes.HIDE,
            disabledKey: 'outer-slope-type-not-needed',
            isLastElement: true,
            mapParams: [
              { originalKey: 'outer-slope-type-color-code', targetKey: 'id' },
              { originalKey: 'outer-slope-type-color-code', targetKey: 'value' },
              { originalKey: 'outer-slope-type-color-title', targetKey: 'text' },
              { originalKey: 'outer-slope-type-color-value', targetKey: 'image' },
              { originalKey: 'outer-slope-type-color-code', targetKey: 'sub-text' },
            ],
            data: {},
          },
          {
            id: 'inner-slope-type-not-needed',
            title: '',
            icon: '',
            type: AttributesTypes.CHECKBOX,
            data: {
              items: [
                {
                  id: 'inner-slope-type-not-needed',
                  name: 'inner-slope-type-not-needed',
                  title: 'Внутренний откос не нужен',
                  value: false,
                  isEqually: false,
                },
                {
                  id: 'inner-slope-type-not-needed',
                  name: 'inner-slope-type-not-needed',
                  title: 'Внутренний откос не нужен',
                  value: true,
                },
              ],
            },
            groupTargetKey: 'slope-switcher',
            groupTargetValue: 'inner-slope',
            groupTargetType: GroupTargetTypes.HIDE,
          },
          {
            id: 'inner-slope-sizes',
            title: 'Размер внутреннего откоса, мм',
            icon: '',
            type: AttributesTypes.SIZES,
            groupTargetKey: 'slope-switcher',
            groupTargetValue: 'inner-slope',
            groupTargetType: GroupTargetTypes.HIDE,
            disabledKey: 'inner-slope-type-not-needed',
            data: {
              attributes: [
                {
                  id: 'inner-slope-type-length',
                  title: '',
                  placeholder: 'Длина, мм',
                  icon: '',
                  type: AttributesTypes.NUMBER,
                  data: {
                    min: {
                      filters: [{ key: 'inner-slope-type-not-needed', value: false }],
                      mapParams: [
                        { originalKey: 'inner-slope-type-length-range', targetKey: 'value' },
                      ],
                      uniqueKeys: ['inner-slope-type-length-range'],
                    },
                    max: {
                      filters: [{ key: 'inner-slope-type-not-needed', value: false }],
                      mapParams: [
                        { originalKey: 'inner-slope-type-length-range', targetKey: 'value' },
                      ],
                      uniqueKeys: ['inner-slope-type-length-range'],
                    },
                  },
                },
                {
                  id: 'inner-slope-type-width',
                  title: '',
                  icon: '',
                  placeholder: 'Ширина, мм',
                  type: AttributesTypes.SELECT,
                  mapParams: [
                    { originalKey: 'inner-slope-type-width-value', targetKey: 'id' },
                    { originalKey: 'inner-slope-type-width-value', targetKey: 'value' },
                    { originalKey: 'inner-slope-type-width-value', targetKey: 'text' },
                  ],
                },
              ],
            },
          },
          {
            id: 'inner-slope-hr',
            title: '',
            type: AttributesTypes.HR,
            groupTargetKey: 'slope-switcher',
            groupTargetValue: 'inner-slope',
            groupTargetType: GroupTargetTypes.HIDE,
            disabledKey: 'inner-slope-type-not-needed',
          },
          {
            id: 'inner-slope-type-badge',
            title: 'Тип',
            icon: '',
            type: AttributesTypes.BADGE,
            groupTargetKey: 'slope-switcher',
            groupTargetValue: 'inner-slope',
            groupTargetType: GroupTargetTypes.HIDE,
            disabledKey: 'inner-slope-type-not-needed',
            data: {
              id: 'inner-slope-type',
            },
            target: 'inner-slope-type/text',
          },
          {
            id: 'inner-slope-type-price',
            title: '',
            description: '',
            icon: '',
            type: AttributesTypes.PRICE,
            groupTargetKey: 'slope-switcher',
            groupTargetValue: 'inner-slope',
            groupTargetType: GroupTargetTypes.HIDE,
            disabledKey: 'inner-slope-type-not-needed',
            data: {
              items: ['inner-slope-type'],
            },
            mapParams: [],
          },
          {
            id: 'inner-slope-type',
            title: '',
            icon: '',
            type: AttributesTypes.GALLERY,
            view: 2,
            groupTargetKey: 'slope-switcher',
            groupTargetValue: 'inner-slope',
            groupTargetType: GroupTargetTypes.HIDE,
            disabledKey: 'inner-slope-type-not-needed',
            mapParams: [
              { originalKey: 'inner-slope-type-code', targetKey: 'id' },
              { originalKey: 'inner-slope-type-code', targetKey: 'value' },
              { originalKey: 'inner-slope-type-title', targetKey: 'text' },
              { originalKey: 'inner-slope-type-value', targetKey: 'image' },
              { originalKey: 'inner-slope-type-price', targetKey: 'price' },
              { originalKey: 'inner-slope-type-length-range', targetKey: 'range' },
            ],
          },
          {
            id: 'inner-slope-type-color-badge',
            title: 'Цвет',
            icon: '',
            type: AttributesTypes.BADGE,
            groupTargetKey: 'slope-switcher',
            groupTargetValue: 'inner-slope',
            groupTargetType: GroupTargetTypes.HIDE,
            disabledKey: 'inner-slope-type-not-needed',
            data: {
              id: 'inner-slope-type-color',
            },
            target: 'inner-slope-type-color/text',
          },
          {
            id: 'inner-slope-type-color',
            title: '',
            icon: '',
            type: AttributesTypes.GALLERY,
            groupTargetKey: 'slope-switcher',
            groupTargetValue: 'inner-slope',
            groupTargetType: GroupTargetTypes.HIDE,
            disabledKey: 'inner-slope-type-not-needed',
            isLastElement: true,
            mapParams: [
              { originalKey: 'inner-slope-type-color-code', targetKey: 'id' },
              { originalKey: 'inner-slope-type-color-code', targetKey: 'value' },
              { originalKey: 'inner-slope-type-color-title', targetKey: 'text' },
              { originalKey: 'inner-slope-type-color-value', targetKey: 'image' },
              { originalKey: 'inner-slope-type-color-code', targetKey: 'sub-text' },
            ],
          },
        ],
      },
      {
        title: 'Отлив',
        description: 'Декоративное устройство отвода воды с окна',
        'full-title': '',
        code: 'low-tide',
        attributes: [
          {
            id: 'ebb-type-not-needed',
            title: '',
            icon: '',
            type: AttributesTypes.CHECKBOX,
            data: {
              items: [
                {
                  id: 'ebb-type-not-needed',
                  name: 'ebb-type-not-needed',
                  title: 'Отлив не нужен',
                  value: false,
                  isEqually: false,
                },
                {
                  id: 'ebb-type-not-needed',
                  name: 'ebb-type-not-needed',
                  title: 'Отлив не нужен',
                  value: true,
                },
              ],
            },
          },
          {
            id: 'ebb-sizes',
            title: 'Размеры отлива, мм',
            icon: '',
            type: AttributesTypes.SIZES,
            groupTargetKey: 'ebb-type-not-needed',
            groupTargetValue: false,
            disabledKey: 'ebb-type-not-needed',
            data: {
              attributes: [
                {
                  id: 'ebb-type-length',
                  title: '',
                  placeholder: 'Длина, мм',
                  icon: '',
                  type: AttributesTypes.NUMBER,
                  data: {
                    range: {
                      filters: [{ key: 'ebb-type-not-needed', value: false }],
                      mapParams: [{ originalKey: 'ebb-type-length-range', targetKey: 'value' }],
                      uniqueKeys: ['ebb-type-length-range'],
                    },
                    min: {
                      filters: [{ key: 'ebb-type-not-needed', value: false }],
                      mapParams: [{ originalKey: 'ebb-type-length-range', targetKey: 'value' }],
                      uniqueKeys: ['ebb-type-length-range'],
                    },
                    max: {
                      filters: [{ key: 'ebb-type-not-needed', value: false }],
                      mapParams: [{ originalKey: 'ebb-type-length-range', targetKey: 'value' }],
                      uniqueKeys: ['ebb-type-length-max'],
                    },
                  },
                },
                {
                  id: 'ebb-type-width',
                  placeholder: 'Ширина, мм',
                  title: '',
                  icon: '',
                  type: AttributesTypes.SELECT,
                  mapParams: [
                    { originalKey: 'ebb-type-width-value', targetKey: 'id' },
                    { originalKey: 'ebb-type-width-value', targetKey: 'value' },
                    { originalKey: 'ebb-type-width-value', targetKey: 'text' },
                  ],
                },
              ],
            },
          },
          {
            id: 'ebb-type-sizes-hr',
            title: '',
            icon: '',
            type: AttributesTypes.HR,
            groupTargetValue: false,
            disabledKey: 'ebb-type-not-needed',
            mapParams: [],
            data: {},
          },
          {
            id: 'ebb-type-badge',
            title: 'Тип',
            icon: '',
            type: AttributesTypes.BADGE,
            data: {
              id: 'ebb-type',
            },
            target: 'ebb-type/text',
            disabledKey: 'ebb-type-not-needed',
          },
          {
            id: 'ebb-type-price',
            title: '',
            description: '',
            icon: '',
            type: AttributesTypes.PRICE,
            data: {
              items: ['ebb-type'],
            },
            mapParams: [],
            disabledKey: 'ebb-type-not-needed',
          },
          {
            id: 'ebb-type',
            title: '',
            icon: '',
            type: AttributesTypes.GALLERY,
            view: 2,
            groupTargetKey: 'ebb-type-not-needed',
            groupTargetValue: false,
            disabledKey: 'ebb-type-not-needed',
            mapParams: [
              { originalKey: 'ebb-type-code', targetKey: 'id' },
              { originalKey: 'ebb-type-code', targetKey: 'value' },
              { originalKey: 'ebb-type-title', targetKey: 'text' },
              { originalKey: 'ebb-type-value', targetKey: 'image' },
              { originalKey: 'ebb-type-price', targetKey: 'price' },
            ],
          },
          {
            id: 'ebb-type-color-hr',
            title: '',
            icon: '',
            type: AttributesTypes.HR,
            mapParams: [],
            data: {},
            disabledKey: 'ebb-type-not-needed',
          },
          {
            id: 'ebb-type-color-badge',
            title: 'Цвет',
            icon: '',
            type: AttributesTypes.BADGE,
            data: {
              id: 'ebb-type-color',
            },
            target: 'ebb-type-color/text',
            disabledKey: 'ebb-type-not-needed',
          },
          {
            id: 'ebb-type-color',
            title: '',
            icon: '',
            type: AttributesTypes.GALLERY,
            groupTargetKey: 'ebb-type-not-needed',
            groupTargetValue: false,
            disabledKey: 'ebb-type-not-needed',
            isLastElement: true,
            mapParams: [
              { originalKey: 'ebb-type-color-code', targetKey: 'id' },
              { originalKey: 'ebb-type-color-code', targetKey: 'value' },
              { originalKey: 'ebb-type-color-title', targetKey: 'text' },
              { originalKey: 'ebb-type-color-value', targetKey: 'image' },
              { originalKey: 'ebb-type-color-code', targetKey: 'sub-text' },
              { originalKey: 'ebb-type-color-code', targetKey: 'sub-text' },
              { originalKey: 'ebb-type-color-collection', targetKey: 'group' },
              { originalKey: 'ebb-type-color-price', targetKey: 'price' },
            ],
          },
        ],
      },
      {
        title: 'Москитная сетка',
        description: 'Сетка защищающая от насекомых',
        'full-title': '',
        code: 'mosquito',
        attributes: [
          {
            id: 'mosquito-type-not-needed',
            title: '',
            icon: '',
            type: AttributesTypes.CHECKBOX,
            data: {
              items: [
                {
                  id: 'mosquito-type-not-needed',
                  name: 'mosquito-type-not-needed',
                  title: 'Москитная сетка не нужна',
                  value: false,
                  isEqually: false,
                },
                {
                  id: 'mosquito-type-not-needed',
                  name: 'mosquito-type-not-needed',
                  title: 'Москитная сетка не нужна',
                  value: true,
                },
              ],
            },
          },
          {
            id: 'mosquito-type-badge',
            title: 'Тип',
            icon: '',
            type: AttributesTypes.BADGE,
            data: {
              id: 'mosquito-type',
            },
            target: 'mosquito-type/text',
            disabledKey: 'mosquito-type-not-needed',
          },
          {
            id: 'mosquito-type-price',
            title: '',
            description: '',
            icon: '',
            type: AttributesTypes.PRICE,
            data: {
              items: ['mosquito-type'],
            },
            mapParams: [],
            disabledKey: 'mosquito-type-not-needed',
          },
          {
            id: 'mosquito-type',
            title: '',
            description: '',
            icon: '',
            type: AttributesTypes.RADIOBUTTON,
            data: {
              type: 'vertical',
            },
            groupTargetKey: 'mosquito-type-code',
            groupTargetValue: false,
            disabledKey: 'mosquito-type-not-needed',
            mapParams: [
              { originalKey: 'mosquito-type-code', targetKey: 'id' },
              { originalKey: 'mosquito-type-code', targetKey: 'value' },
              { originalKey: 'mosquito-type-title', targetKey: 'text' },
              { originalKey: 'mosquito-type-price', targetKey: 'price' },
              { originalKey: 'mosquito-type-info', targetKey: 'info' },
            ],
          },
          // {
          //   id: 'mosquito-type',
          //   title: '',
          //   icon: '',
          //   type: AttributesTypes.GALLERY,
          //   view: 2,
          //   groupTargetKey: 'mosquito-type-code',
          //   groupTargetValue: false,
          //   mapParams: [
          //     { originalKey: 'mosquito-type-code', targetKey: 'id' },
          //     { originalKey: 'mosquito-type-code', targetKey: 'value' },
          //     { originalKey: 'mosquito-type-title', targetKey: 'text' },
          //     { originalKey: 'mosquito-type-value', targetKey: 'image' },
          //     { originalKey: 'mosquito-type-price', targetKey: 'price' },
          //   ],
          // },
          {
            id: 'mosquito-type-color-hr',
            title: '',
            icon: '',
            type: AttributesTypes.HR,
            mapParams: [],
            data: {},
            disabledKey: 'mosquito-type-not-needed',
          },
          {
            id: 'mosquito-type-color-badge',
            title: 'Цвет',
            icon: '',
            type: AttributesTypes.BADGE,
            data: {
              id: 'mosquito-type-color',
            },
            target: 'mosquito-type-color/text',
            disabledKey: 'mosquito-type-not-needed',
          },
          {
            id: 'mosquito-type-color',
            title: '',
            icon: '',
            type: AttributesTypes.GALLERY,
            groupTargetKey: 'mosquito-type-not-needed',
            groupTargetValue: false,
            disabledKey: 'mosquito-type-not-needed',
            isLastElement: true,
            mapParams: [
              { originalKey: 'mosquito-type-color-code', targetKey: 'id' },
              { originalKey: 'mosquito-type-color-code', targetKey: 'value' },
              { originalKey: 'mosquito-type-color-title', targetKey: 'text' },
              { originalKey: 'mosquito-type-color-value', targetKey: 'image' },
              { originalKey: 'mosquito-type-color-code', targetKey: 'sub-text' },
              { originalKey: 'mosquito-type-color-collection', targetKey: 'group' },
              { originalKey: 'mosquito-type-color-price', targetKey: 'price' },
            ],
          },
        ],
      },
    ],
  },
]

export const sizesCollection = {
  window: {
    'windowsill-model-length': {
      min: 1000,
      max: 6000,
    },
    'outer-slope-type-length': {
      min: 100,
      max: 99999,
    },
    'inner-slope-type-length': {
      min: 100,
      max: 99999,
    },
    'ebb-type-length': {
      min: 100,
      max: 6000,
    },
  },
}

export const mmTranslation = 10 * 100

export const getSquare = (width, height) => (width / mmTranslation) * (height / mmTranslation)


export const calculateProductsSquare = productsData => {
  let result = 0
  if (productsData.length > 0) {
    productsData.forEach(({ sizes }) => {
      if (sizes && 'model-width' in sizes && 'model-height' in sizes) {
        result += getSquare(sizes['model-width'], sizes['model-height'])
      }
    })
  }
  return result
}

export const colorCode = {
  1: '#d69a2d',
  2: '#252525',
}

export const modelColorIds = ['model-inside-color', 'model-outside-color', 'model-profile-color']

export const filterValueSwitchOffMap = {
  'windowsill-model': {
    key: 'windowsill-model-not-needed',
    value: true,
  },
  'ebb-type': {
    key: 'ebb-type-not-needed',
    value: true,
  },
  'inner-slope-type': {
    key: 'inner-slope-type-not-needed',
    value: true,
  },
  'outer-slope-type': {
    key: 'outer-slope-type-not-needed',
    value: true,
  },
  'mosquito-type': {
    key: 'mosquito-type-not-needed',
    value: true,
  },
}

export const casementsSizeAcceptWidthList = [
  {
    width: '500~1100',
    height: '500~2100',
  },
  {
    width: '601~700',
    height: '600~2100',
  },
  {
    width: '701~800',
    height: '700~2100',
  },
  {
    width: '801~900',
    height: '800~2100',
  },
  {
    width: '901~1000',
    height: '800~2100',
  },
]

export const casementsSizeAcceptHeightList = [
  {
    width: '500~1100',
    height: '500~600',
  },
  {
    width: '500~700',
    height: '601~700',
  },
  {
    width: '500~800',
    height: '701~800',
  },
  {
    width: '500~1000',
    height: '801~900',
  },
  {
    width: '500~1100',
    height: '901~1000',
  },
  {
    width: '500~1200',
    height: '1001~1100',
  },
  {
    width: '500~1300',
    height: '1101~1200',
  },
  {
    width: '500~1500',
    height: '1201~1500',
  },
  {
    width: '500~1400',
    height: '1501~1700',
  },
  {
    width: '500~1300',
    height: '1701~1900',
  },
  {
    width: '500~1200',
    height: '1901~2100',
  },
]
