import React, { FC, useState, useRef, useEffect, useMemo, useCallback, ReactElement } from 'react'

import { ISelectProps, ISelectOption } from './interface'
import {
  SelectArrow,
  SelectBlock,
  SelectContentIcon,
  SelectDropdown,
  SelectOption,
  SelectOptionsWrapper,
  SelectSelector,
  SelectText,
  SelectWrapper,
  InputReq22
} from './styles'
import { SelectIcon } from '../../assets/icons'


export const Select: FC<ISelectProps> = props => {
  const {
    placeholder = '',
    onSelect = () => null,
    selected: selectedItem,
    items,
    name = '',
    req
  } = props
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [selectedId, setSelectedId] = useState('')
  const selectRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (selectedItem && 'id' in selectedItem && !selectedId) {
      setSelectedId(selectedItem['id'])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem])

  const selected = useMemo(() => {
    const option = items.find(item => item.id === selectedId)

    return option || null
  }, [items, selectedId])

  const toggleDropdown = useCallback(() => {
    setDropdownOpen(prevState => !prevState)
  }, [])

  const handleSelectOption = useCallback(
    (item: ISelectOption) => () => {
      setSelectedId(item.id)

      onSelect(item, name)
      setDropdownOpen(false)
    },
    [name, onSelect],
  )

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        !dropdownOpen &&
        selectRef?.current !== null &&
        event.target !== null &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false)
      }
    },
    [dropdownOpen],
  )

  useEffect(() => {
    // eslint-disable-next-line no-undef
    document.addEventListener('click', handleClickOutside)

    // eslint-disable-next-line no-undef
    return () => document.removeEventListener('click', handleClickOutside)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getOptionContent = useCallback((option: ISelectOption) => {
    const result: ReactElement[] = []
    const text = String(option?.text || '')

    if ('icon' in option && option.icon && option.icon.length > 0) {
      result.push(<SelectContentIcon src={option.icon} alt="icon" key={`icon-${option.id}`} />)
    }

    if ('text' in option && text.length > 0) {
      result.push(<span key={`text-${option.id}`}>{text}</span>)
    }

    return result
  }, [])

  return (
    <SelectWrapper>
      { req && <InputReq22 >*</InputReq22> }
      <SelectBlock ref={selectRef}>
        <SelectSelector onClick={toggleDropdown}>
          <SelectText>
            {selected ? getOptionContent(selected) : placeholder || 'Выберите значение'}
          </SelectText>
        </SelectSelector>
        <SelectArrow onClick={toggleDropdown}>
          <SelectIcon arrowDown={!dropdownOpen} />
        </SelectArrow>
        <SelectOptionsWrapper>
          <div>
            <SelectDropdown open={dropdownOpen}>
              {items?.length > 0 &&
                items.map(option => (
                  <SelectOption
                    id={option.id}
                    onClick={handleSelectOption(option)}
                    active={option.id === selectedId}
                    key={option.id}
                  >
                    {getOptionContent(option)}
                  </SelectOption>
                ))}
            </SelectDropdown>
          </div>
        </SelectOptionsWrapper>
      </SelectBlock>
    </SelectWrapper>
  )
}
