import React, { FC, useMemo, Fragment } from 'react'
import {
  StageCartShowMoreBlock,
  StageCartShowMoreBlockRowItem,
  StageCartShowMoreBlockTitle,
} from './styles'
import { IDescriptionTypeWindowProps } from '../stage-calculator/interface'
import { replaceHandlebars } from '../../../utilits'

export const DescriptionTypeWindow: FC<IDescriptionTypeWindowProps> = props => {
  const { product, sizes, count, itemPrice, elementsData, filters } = props
  const elementsDataKeys = useMemo(() => {
    return Object.keys(elementsData)
  }, [elementsData])
  const productDescription = useMemo(
    () => replaceHandlebars('configuration', 'description', product, sizes, filters),
    [filters, product, sizes],
  )

  return (
    <StageCartShowMoreBlock>
      <StageCartShowMoreBlockRowItem noLeftGap>
        <StageCartShowMoreBlockTitle>Характеристики</StageCartShowMoreBlockTitle>
        <div dangerouslySetInnerHTML={{ __html: productDescription }} />
      </StageCartShowMoreBlockRowItem>
      <StageCartShowMoreBlockRowItem>
        <StageCartShowMoreBlockTitle>Кол-во</StageCartShowMoreBlockTitle>
        <div>{count}</div>
      </StageCartShowMoreBlockRowItem>
      <StageCartShowMoreBlockRowItem noRightBorder>
        <StageCartShowMoreBlockTitle>Цена</StageCartShowMoreBlockTitle>
        <b>{itemPrice?.toLocaleString('ru-RU') || '0'} ₽</b>
      </StageCartShowMoreBlockRowItem>
      {elementsDataKeys.map(key => (
        <Fragment key={key}>
          <StageCartShowMoreBlockRowItem noLeftGap>
            <div>
              <b>{elementsData[key]['description']}</b>
            </div>
            <div>{elementsData[key]['name'] || elementsData[key]['title']}</div>
          </StageCartShowMoreBlockRowItem>
          <StageCartShowMoreBlockRowItem>
            {elementsData[key]['count']}
          </StageCartShowMoreBlockRowItem>
          <StageCartShowMoreBlockRowItem noRightBorder>
            <b>{elementsData[key]['price']?.toLocaleString('ru-RU') || '0'} ₽</b>
          </StageCartShowMoreBlockRowItem>
        </Fragment>
      ))}
    </StageCartShowMoreBlock>
  )
}
