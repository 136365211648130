import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement46Square8Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-6_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M421 46.9995C421 45.8952 420.105 45 419.001 45C417.896 45 417.001 45.8952 417.001 46.9995V49.1665H417.001V60H417V63.0005C417 64.1048 417.895 65 418.999 65C420.104 65 420.999 64.1048 420.999 63.0005V60.8332H421V50H421V46.9995Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 421 49.1665)"
          fill="#ABABAB"
        />
        <path
          d="M421 45.9399C421 45.4208 420.579 45 420.06 45H417.941C417.422 45 417.001 45.4208 417.001 45.9399V50H421V45.9399Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M417 64.0601C417 64.5792 417.421 65 417.94 65H420.059C420.578 65 420.999 64.5792 420.999 64.0601V60H417V64.0601Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 421 50.8335)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#4-6_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 123C417 121.895 417.895 121 419 121C420.105 121 421 121.895 421 123V137.56H417V123ZM417 137.56H421V139.4H421.06C421.579 139.4 422 139.821 422 140.34V142.12C422 143.158 421.158 144 420.12 144H418.88C417.842 144 417 143.158 417 142.12V139.4V139.4V137.56Z"
            fill="white"
          />
        </g>
        <rect x="417" y="138.25" width="3.99998" height="0.95833" fill="#ABABAB" />
        <path
          d="M417 121.94C417 121.421 417.421 121 417.94 121H420.06C420.579 121 421 121.421 421 121.94V138.25H417V121.94Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M417 143.06C417 143.579 417.421 144 417.94 144H421.06C421.579 144 422 143.579 422 143.06V140.148C422 139.629 421.579 139.208 421.06 139.208H417V143.06Z"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#4-6_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 34H328V155H417V34ZM406 45H339V144H406V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 34H328V155H329.219V35H415.781V155H417V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-6_filter3_i)">
          <rect
            x="339.47"
            y="45.47"
            width="66.0601"
            height="98.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="332.53"
          y="38.53"
          width="79.9399"
          height="111.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="330"
          y="87"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#4-6_filter4_d)">
          <rect x="330.702" y="87.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M331.286 98.7956C331.798 99.4577 332.6 99.8841 333.502 99.8841C335.049 99.8841 336.302 98.6305 336.302 97.0841V90.4442C336.302 89.4557 335.79 88.5869 335.017 88.0886C335.383 88.5619 335.601 89.1556 335.601 89.8001V96.44C335.601 97.9864 334.347 99.24 332.801 99.24C332.243 99.24 331.723 99.0768 331.286 98.7956Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M333.502 87.644C331.955 87.644 330.702 88.8976 330.702 90.444V96.4397C330.702 96.4601 330.702 96.4805 330.702 96.5007V91.0236C330.702 89.4772 331.956 88.2236 333.502 88.2236C335.028 88.2236 336.269 89.4444 336.302 90.9627V90.444C336.302 88.8976 335.048 87.644 333.502 87.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-6_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M333.5 91C332.672 91 332 91.6716 332 92.5V92.7143V94V112.5C332 113.328 332.672 114 333.5 114V114C334.328 114 335 113.328 335 112.5V96V92.5L335 92.2857C335 91.5756 334.424 91 333.714 91V91C333.677 91 333.64 91.0012 333.604 91.0035C333.569 91.0012 333.535 91 333.5 91Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M404.5 46.9969L340.836 94.5L404.5 142.003L404.5 46.9969Z" stroke="white" />
        <path d="M340.695 142.5L372.5 47.5739L404.305 142.5L340.695 142.5Z" stroke="white" />
        <defs>
          <filter
            id="4-6_filter0_d"
            x="415.12"
            y="43.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-6_filter1_d"
            x="415.12"
            y="119.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-6_filter2_d"
            x="323.3"
            y="29.3003"
            width="100.279"
            height="132.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-6_filter3_i"
            x="339"
            y="45"
            width="67.9399"
            height="101.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-6_filter4_d"
            x="328.822"
            y="85.7642"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-6_filter5_d"
            x="330.12"
            y="89.1201"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="423.8"
            y1="62.5"
            x2="416.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="414.202"
            y1="47.5"
            x2="421.8"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="423.798"
            y1="62.5"
            x2="416.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="423.8"
            y1="129.625"
            x2="416.2"
            y2="129.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="425.5"
            y1="141.604"
            x2="416"
            y2="141.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
