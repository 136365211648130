import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement16Square1Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M61.0235 289.954C62.1151 289.954 63 289.069 63 287.977C63 286.886 62.1151 286.001 61.0235 286.001L58.8335 286.001L58.8335 286.001L48 286.001L48 286L44.9765 286C43.8849 286 43 286.885 43 287.976C43 289.068 43.8849 289.953 44.9765 289.953L47.1668 289.953L47.1668 289.954L58 289.954L58 289.954L61.0235 289.954Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 58.8335 289.954)"
          fill="#ABABAB"
        />
        <path
          d="M62.0601 289.954C62.5792 289.954 63 289.533 63 289.014L63 286.941C63 286.422 62.5792 286.001 62.0601 286.001L58 286.001L58 289.954L62.0601 289.954Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M43.9399 286C43.4208 286 43 286.421 43 286.94L43 289.013C43 289.532 43.4208 289.953 43.9399 289.953L48 289.953L48 286L43.9399 286Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 57.1666 289.954)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M175.024 289.954C176.115 289.954 177 289.069 177 287.977C177 286.886 176.115 286.001 175.024 286.001L172.833 286.001L172.833 286.001L162 286.001L162 286L158.976 286C157.885 286 157 286.885 157 287.976C157 289.068 157.885 289.953 158.976 289.953L161.167 289.953L161.167 289.954L172 289.954L172 289.954L175.024 289.954Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 172.833 289.954)"
          fill="#ABABAB"
        />
        <path
          d="M176.06 289.954C176.579 289.954 177 289.533 177 289.014L177 286.941C177 286.422 176.579 286.001 176.06 286.001L172 286.001L172 289.954L176.06 289.954Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M157.94 286C157.421 286 157 286.421 157 286.94L157 289.013C157 289.532 157.421 289.953 157.94 289.953L162 289.953L162 286L157.94 286Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 171.167 289.954)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 33H32V286H188V33ZM177 44H43V275H177V44Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 33H32V286H33V34H187V286H188V33Z"
          fill="#FBFBFB"
        />
        <rect
          x="36.53"
          y="37.53"
          width="146.94"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#filter3_i)">
          <rect
            x="43.47"
            y="44.47"
            width="133.06"
            height="230.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <path d="M44.6645 273.5L110 46.8055L175.336 273.5L44.6645 273.5Z" stroke="white" />
        <rect
          x="117.528"
          y="35"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 117.528 35)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#filter4_d)">
          <rect
            x="116.884"
            y="35.7018"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 116.884 35.7018)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M116.766 37.695C116.42 36.5419 115.35 35.7018 114.084 35.7018L107.444 35.7018C105.898 35.7018 104.644 36.9554 104.644 38.5018C104.644 38.5369 104.645 38.5719 104.646 38.6068C104.993 39.7599 106.062 40.6 107.328 40.6L113.968 40.6C115.515 40.6 116.768 39.3464 116.768 37.8C116.768 37.7648 116.768 37.7298 116.766 37.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115.409 36.0345C115.266 36.0118 115.118 36 114.968 36L108.328 36C106.782 36 105.528 37.2536 105.528 38.8C105.528 39.867 106.125 40.7947 107.003 41.2672C105.667 41.0557 104.644 39.8981 104.644 38.5018C104.644 36.9554 105.898 35.7018 107.444 35.7018L114.084 35.7018C114.564 35.7018 115.015 35.8222 115.409 36.0345Z"
          fill="#FBFBFB"
        />
        <g filter="url(#filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M130.528 38.5C130.528 37.6716 129.857 37 129.028 37L128.814 37L127.528 37L109.028 37C108.2 37 107.528 37.6716 107.528 38.5V38.5C107.528 39.3284 108.2 40 109.028 40L125.528 40L129.028 40L129.243 40C129.953 40 130.528 39.4244 130.528 38.7143V38.7143C130.528 38.6771 130.527 38.6401 130.525 38.6035C130.527 38.5693 130.528 38.5348 130.528 38.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="41.1201"
            y="284.12"
            width="25.6396"
            height="9.59374"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter1_d"
            x="155.12"
            y="284.12"
            width="25.6396"
            height="9.59374"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter2_d"
            x="27.3003"
            y="28.3003"
            width="167.279"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter3_i"
            x="43"
            y="44"
            width="134.94"
            height="233.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter4_d"
            x="102.765"
            y="33.8219"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter5_d"
            x="106.649"
            y="35.1201"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="45.5"
            y1="292.722"
            x2="45.5"
            y2="285.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="60.5"
            y1="283.234"
            x2="60.5"
            y2="290.745"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="45.5"
            y1="292.72"
            x2="45.5"
            y2="285.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="159.5"
            y1="292.722"
            x2="159.5"
            y2="285.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="174.5"
            y1="283.234"
            x2="174.5"
            y2="290.745"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="159.5"
            y1="292.72"
            x2="159.5"
            y2="285.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
