import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement31Square3Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#3-1_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M304.999 287C303.895 287 303 287.895 303 288.999C303 290.104 303.895 290.999 304.999 290.999L307.167 290.999L307.167 290.999L318 290.999L318 291L321.001 291C322.105 291 323 290.105 323 289.001C323 287.896 322.105 287.001 321.001 287.001L318.833 287.001L318.833 287L308 287L308 287L304.999 287Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 307.167 287)"
          fill="#ABABAB"
        />
        <path
          d="M303.94 287C303.421 287 303 287.421 303 287.94L303 290.059C303 290.578 303.421 290.999 303.94 290.999L308 290.999L308 287L303.94 287Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M322.06 291C322.579 291 323 290.579 323 290.06L323 287.941C323 287.422 322.579 287.001 322.06 287.001L318 287.001L318 291L322.06 291Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 308.833 287)"
          fill="url(#paint3-1_linear)"
        />
        <g filter="url(#3-1_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M392.999 287C391.895 287 391 287.895 391 288.999C391 290.104 391.895 290.999 392.999 290.999L395.167 290.999L395.167 290.999L406 290.999L406 291L409.001 291C410.105 291 411 290.105 411 289.001C411 287.896 410.105 287.001 409.001 287.001L406.833 287.001L406.833 287L396 287L396 287L392.999 287Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 395.167 287)"
          fill="#ABABAB"
        />
        <path
          d="M391.94 287C391.421 287 391 287.421 391 287.94L391 290.059C391 290.578 391.421 290.999 391.94 290.999L396 290.999L396 287L391.94 287Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M410.06 291C410.579 291 411 290.579 411 290.06L411 287.941C411 287.422 410.579 287.001 410.06 287.001L406 287.001L406 291L410.06 291Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 396.833 287)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#3-1_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 210H297V287H417V210ZM406 221H308V276H406V221Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#3-1_filter3-1_i)">
          <rect
            x="308.47"
            y="221.47"
            width="97.0601"
            height="54.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="302.5" y="215.5" width="109" height="66" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 210H297V287H298V211H416V287H417V210Z"
          fill="#FBFBFB"
        />
        <path d="M403.873 274.5H310.127L357 222.745L403.873 274.5Z" stroke="white" />
        <rect
          x="364.528"
          y="212"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 364.528 212)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#3-1_filter4_d)">
          <rect
            x="363.884"
            y="212.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 363.884 212.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M363.766 214.695C363.42 213.542 362.35 212.702 361.084 212.702L354.444 212.702C352.898 212.702 351.644 213.956 351.644 215.502C351.644 215.537 351.645 215.572 351.646 215.607C351.993 216.76 353.062 217.6 354.328 217.6L360.968 217.6C362.514 217.6 363.768 216.347 363.768 214.8C363.768 214.765 363.767 214.73 363.766 214.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M362.409 213.035C362.266 213.012 362.118 213 361.968 213L355.328 213C353.782 213 352.528 214.254 352.528 215.8C352.528 216.867 353.125 217.795 354.003 218.268C352.666 218.056 351.644 216.898 351.644 215.502C351.644 213.956 352.898 212.702 354.444 212.702L361.084 212.702C361.564 212.702 362.015 212.823 362.409 213.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-1_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M377.528 215.5C377.528 214.672 376.857 214 376.028 214L375.814 214L374.528 214L356.028 214C355.2 214 354.528 214.672 354.528 215.5C354.528 216.328 355.2 217 356.028 217L372.528 217L376.028 217L376.243 217C376.953 217 377.528 216.424 377.528 215.714C377.528 215.677 377.527 215.64 377.525 215.604C377.527 215.569 377.528 215.535 377.528 215.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="3-1_filter0_d"
            x="301.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-1_filter1_d"
            x="389.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-1_filter2_d"
            x="292.3"
            y="205.3"
            width="131.279"
            height="88.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-1_filter3-1_i"
            x="308"
            y="221"
            width="98.9399"
            height="57.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-1_filter4_d"
            x="349.765"
            y="210.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-1_filter5_d"
            x="353.649"
            y="212.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="320.5"
            y1="284.2"
            x2="320.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="305.5"
            y1="293.798"
            x2="305.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="320.5"
            y1="284.202"
            x2="320.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3-1_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="408.5"
            y1="284.2"
            x2="408.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="393.5"
            y1="293.798"
            x2="393.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="408.5"
            y1="284.202"
            x2="408.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
