import React, { FC } from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'
import { IContainerSquareProps } from '../../interface'
import { ContainerBackground } from '../container-background'

export const ContainerSquare4VerticalCasement: FC<IContainerSquareProps> = props => {
  const { onCasementClick, withoutBackground } = props

  return (
    <ProductImageWrapper isClickable>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <rect width="220" height="333" fill="white" />
        <rect x="10" y="18" width="186" height="286" fill="#E8EAEE" />
        <rect x="10" y="18" width="186" height="286" fill="url(#4-v-pattern0)" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M210 10H10V292H24V28H196V292H210V10Z"
          fill="#C4C4C4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M210 292H10V308H0V316H10H210H220V308H210V292Z"
          fill="url(#4-v-paint0_linear)"
        />
        <path d="M10 10L24 28.1525V291.982L10 308V10Z" fill="#E9EBEF" />
        <path d="M10 10L24 28.1525V291.982L10 308V10Z" fill="#020202" fillOpacity="0.06" />
        <path d="M210 10L196 28.1525V291.982L210 308V10Z" fill="#E9EBEF" />
        <path d="M210 10L196 28.1525V291.982L210 308V10Z" fill="#020202" fillOpacity="0.06" />
        <rect y="316" width="220" height="17" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 27H196V292H24V27ZM37 40H183V85H37V40ZM183 104H37V149H183V104ZM37 168H183V213H37V168ZM183 233H37V278H183V233Z"
          fill="#E8EAEE"
        />
        <g
          filter="url(#4-v-filter0_i)"
          className="casement"
          data-index="0"
          onClick={onCasementClick(0)}
        >
          <rect x="37" y="40" width="146" height="45" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="37.47"
          y="40.47"
          width="145.06"
          height="44.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#4-v-filter1_i)"
          className="casement"
          data-index="1"
          onClick={onCasementClick(1)}
        >
          <rect x="37" y="104" width="146" height="45" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="37.47"
          y="104.47"
          width="145.06"
          height="44.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#4-v-filter2_i)"
          className="casement"
          data-index="2"
          onClick={onCasementClick(2)}
        >
          <rect x="37" y="168" width="146" height="45" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="37.47"
          y="168.47"
          width="145.06"
          height="44.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#4-v-filter3_i)"
          className="casement"
          data-index="3"
          onClick={onCasementClick(3)}
        >
          <rect x="37" y="233" width="146" height="45" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="37.47"
          y="233.47"
          width="145.06"
          height="44.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <path
          d="M118.985 56.7412H116.447C115.8 56.7412 115.253 56.2345 115.253 55.6356V55.4053C115.253 54.8064 115.8 54.2997 116.447 54.2997H118.985C119.632 54.2997 120.179 54.8064 120.179 55.4053V55.6356C120.179 56.2575 119.657 56.7412 118.985 56.7412Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M101.426 59.5652C101.725 59.5652 101.799 59.5652 102.197 59.5652C102.943 59.5652 103.49 60.1052 103.49 60.833C103.49 61.5843 102.918 62.1008 102.147 62.1008C100.705 62.1008 99.8098 62.1008 98.3674 62.1008C97.8203 62.1008 97.3727 61.913 97.1488 61.4199C96.9499 61.1147 96.8255 60.2695 97.6213 59.5652C98.1436 58.9782 103.913 52.7565 104.933 51.6765C104.982 51.6061 104.933 51.5122 104.858 51.5122C104.833 51.5122 104.833 51.5122 104.808 51.5122C102.645 51.5122 100.481 51.5122 98.3177 51.5122C97.6711 51.5122 97.1737 51.1365 97.0494 50.573C96.9002 49.9156 97.1986 49.3522 97.7706 49.0939C97.9446 49.0235 98.1187 49 98.2928 49C101.501 49 104.684 49 107.892 49C108.514 49 108.937 49.1878 109.185 49.7278C109.509 50.4087 109.16 50.8783 108.937 51.1835C108.489 51.7235 103.938 56.56 101.426 59.2834C101.401 59.3069 101.376 59.3539 101.327 59.3773C101.277 59.4478 101.327 59.5652 101.426 59.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M108.186 62.1009H105.648C105.001 62.1009 104.453 61.5818 104.453 60.9684V60.7324C104.453 60.1189 105.001 59.5999 105.648 59.5999H108.186C108.832 59.5999 109.38 60.1189 109.38 60.7324V60.9684C109.38 61.6054 108.832 62.1009 108.186 62.1009Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M111.715 55.5152C111.715 53.7341 111.715 51.9764 111.715 50.1952C111.715 49.4922 112.237 49 112.983 49C116.215 49 119.446 49 122.678 49C123.424 49 123.946 49.5156 123.946 50.2421C123.946 50.9686 123.424 51.5077 122.678 51.5077C119.993 51.5077 117.333 51.5077 114.649 51.5077C114.45 51.5077 114.4 51.5545 114.4 51.742C114.4 54.2731 114.4 56.8042 114.4 59.3353C114.4 59.5463 114.475 59.5697 114.649 59.5697C117.309 59.5697 119.993 59.5697 122.653 59.5697C123.25 59.5697 123.722 59.8978 123.896 60.39C124.095 60.9759 123.871 61.5618 123.399 61.8899C123.175 62.0305 122.902 62.1008 122.628 62.1008C119.446 62.1008 116.24 62.1008 113.058 62.1008C112.237 62.1008 111.74 61.5852 111.74 60.8118C111.715 59.0307 111.715 57.273 111.715 55.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M98.7054 67.0416V67.6984H97V67.0416H97.4263V65.2587H97.0502V64.6019H98.6803V65.2587H98.3041V67.0416H98.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M102.534 67.7579H101.774L101.651 67.5451C101.381 67.687 101.062 67.7579 100.768 67.7579C99.9334 67.7342 99.1482 67.1668 99.1482 66.1501C99.1482 65.1806 99.8107 64.5422 100.817 64.5422C101.651 64.5422 102.461 65.0861 102.461 66.1501C102.461 66.5284 102.363 66.8121 102.215 67.0722L102.559 67.6397V67.7579H102.534ZM100.792 67.0013C101.185 67.0013 101.602 66.7175 101.602 66.1501C101.602 65.5826 101.185 65.2989 100.792 65.2989C100.375 65.2989 99.9824 65.5826 99.9824 66.1501C99.9824 66.7175 100.375 67.0013 100.792 67.0013Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M104.784 66.7486C104.784 66.9598 105.029 67.0772 105.299 67.0772C105.545 67.0772 105.79 66.9598 105.79 66.7955C105.79 66.4904 105.422 66.4904 105.201 66.4669C104.612 66.42 104.022 66.2087 104.022 65.5515C104.022 64.8708 104.612 64.5422 105.275 64.5422C105.889 64.5422 106.527 64.8708 106.527 65.5515H105.717C105.717 65.3403 105.496 65.2464 105.275 65.2464C105.078 65.2464 104.833 65.2933 104.833 65.5281C104.833 65.7393 105.029 65.7862 105.324 65.8097C106.011 65.8567 106.601 66.021 106.601 66.7721C106.601 67.4293 105.938 67.7579 105.275 67.7579C104.587 67.7579 103.924 67.4293 103.949 66.7486H104.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M109.229 65.311H108.038V65.7601H109.13V66.4693H108.038V66.9656H109.254V67.6984H107.169V64.6019H109.204V65.311H109.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M112.603 67.5802V67.6984H111.722L111.159 66.7293H110.719V67.6984H109.887V64.6019C110.376 64.6019 110.841 64.6019 111.306 64.6019C112.089 64.6019 112.456 65.1692 112.456 65.7128C112.456 66.0674 112.285 66.422 111.967 66.6111L112.603 67.5802ZM111.306 66.0674C111.551 66.0674 111.649 65.8783 111.649 65.6656C111.649 65.4765 111.526 65.2874 111.306 65.2874H110.719V66.0674H111.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M114.276 66.3342L114.553 65.5273L114.956 64.6019H115.887V64.7205L114.452 67.758H114.1L112.666 64.7205V64.6019H113.597L114.024 65.5273L114.276 66.3342Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M117.781 67.0416V67.6984H116.076V67.0416H116.502V65.2587H116.126V64.6019H117.756V65.2587H117.38V67.0416H117.781Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M119.835 64.6019C120.509 64.6019 121.258 64.9552 121.383 65.7795H120.559C120.434 65.4498 120.184 65.332 119.835 65.332C119.335 65.332 119.011 65.6618 119.011 66.1799C119.011 66.6274 119.335 67.0278 119.835 67.0278C120.184 67.0278 120.484 66.863 120.559 66.5332H121.383C121.258 67.4047 120.534 67.758 119.835 67.758C118.986 67.758 118.162 67.2162 118.162 66.1799C118.162 65.12 118.986 64.6019 119.835 64.6019Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M123.944 65.311H122.753V65.7601H123.845V66.4693H122.753V66.9656H123.969V67.6984H121.885V64.6019H123.919V65.311H123.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M117.985 121.741H115.447C114.8 121.741 114.253 121.234 114.253 120.636V120.405C114.253 119.806 114.8 119.3 115.447 119.3H117.985C118.632 119.3 119.179 119.806 119.179 120.405V120.636C119.179 121.257 118.657 121.741 117.985 121.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M100.426 124.565C100.725 124.565 100.799 124.565 101.197 124.565C101.943 124.565 102.49 125.105 102.49 125.833C102.49 126.584 101.918 127.101 101.147 127.101C99.705 127.101 98.8098 127.101 97.3674 127.101C96.8203 127.101 96.3727 126.913 96.1488 126.42C95.9499 126.115 95.8255 125.27 96.6213 124.565C97.1436 123.978 102.913 117.757 103.933 116.677C103.982 116.606 103.933 116.512 103.858 116.512C103.833 116.512 103.833 116.512 103.808 116.512C101.645 116.512 99.4812 116.512 97.3177 116.512C96.6711 116.512 96.1737 116.137 96.0494 115.573C95.9002 114.916 96.1986 114.352 96.7706 114.094C96.9446 114.023 97.1187 114 97.2928 114C100.501 114 103.684 114 106.892 114C107.514 114 107.937 114.188 108.185 114.728C108.509 115.409 108.16 115.878 107.937 116.183C107.489 116.723 102.938 121.56 100.426 124.283C100.401 124.307 100.376 124.354 100.327 124.377C100.277 124.448 100.327 124.565 100.426 124.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M107.186 127.101H104.648C104.001 127.101 103.453 126.582 103.453 125.968V125.732C103.453 125.119 104.001 124.6 104.648 124.6H107.186C107.832 124.6 108.38 125.119 108.38 125.732V125.968C108.38 126.605 107.832 127.101 107.186 127.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M110.715 120.515C110.715 118.734 110.715 116.976 110.715 115.195C110.715 114.492 111.237 114 111.983 114C115.215 114 118.446 114 121.678 114C122.424 114 122.946 114.516 122.946 115.242C122.946 115.969 122.424 116.508 121.678 116.508C118.993 116.508 116.333 116.508 113.649 116.508C113.45 116.508 113.4 116.555 113.4 116.742C113.4 119.273 113.4 121.804 113.4 124.335C113.4 124.546 113.475 124.57 113.649 124.57C116.309 124.57 118.993 124.57 121.653 124.57C122.25 124.57 122.722 124.898 122.896 125.39C123.095 125.976 122.871 126.562 122.399 126.89C122.175 127.03 121.902 127.101 121.628 127.101C118.446 127.101 115.24 127.101 112.058 127.101C111.237 127.101 110.74 126.585 110.74 125.812C110.715 124.031 110.715 122.273 110.715 120.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M97.7054 132.042V132.698H96V132.042H96.4263V130.259H96.0502V129.602H97.6803V130.259H97.3041V132.042H97.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M101.534 132.758H100.774L100.651 132.545C100.381 132.687 100.062 132.758 99.7676 132.758C98.9334 132.734 98.1482 132.167 98.1482 131.15C98.1482 130.181 98.8107 129.542 99.8167 129.542C100.651 129.542 101.461 130.086 101.461 131.15C101.461 131.528 101.363 131.812 101.215 132.072L101.559 132.64V132.758H101.534ZM99.7922 132.001C100.185 132.001 100.602 131.718 100.602 131.15C100.602 130.583 100.185 130.299 99.7922 130.299C99.375 130.299 98.9824 130.583 98.9824 131.15C98.9824 131.718 99.375 132.001 99.7922 132.001Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M103.784 131.749C103.784 131.96 104.029 132.077 104.299 132.077C104.545 132.077 104.79 131.96 104.79 131.796C104.79 131.49 104.422 131.49 104.201 131.467C103.612 131.42 103.022 131.209 103.022 130.552C103.022 129.871 103.612 129.542 104.275 129.542C104.889 129.542 105.527 129.871 105.527 130.552H104.717C104.717 130.34 104.496 130.246 104.275 130.246C104.078 130.246 103.833 130.293 103.833 130.528C103.833 130.739 104.029 130.786 104.324 130.81C105.011 130.857 105.601 131.021 105.601 131.772C105.601 132.429 104.938 132.758 104.275 132.758C103.587 132.758 102.924 132.429 102.949 131.749H103.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M108.229 130.311H107.038V130.76H108.13V131.469H107.038V131.966H108.254V132.698H106.169V129.602H108.204V130.311H108.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M111.603 132.58V132.698H110.722L110.159 131.729H109.719V132.698H108.887V129.602C109.376 129.602 109.841 129.602 110.306 129.602C111.089 129.602 111.456 130.169 111.456 130.713C111.456 131.067 111.285 131.422 110.967 131.611L111.603 132.58ZM110.306 131.067C110.551 131.067 110.649 130.878 110.649 130.666C110.649 130.476 110.526 130.287 110.306 130.287H109.719V131.067H110.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M113.276 131.334L113.553 130.527L113.956 129.602H114.887V129.721L113.452 132.758H113.1L111.666 129.721V129.602H112.597L113.024 130.527L113.276 131.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M116.781 132.042V132.698H115.076V132.042H115.502V130.259H115.126V129.602H116.756V130.259H116.38V132.042H116.781Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M118.835 129.602C119.509 129.602 120.258 129.955 120.383 130.78H119.559C119.434 130.45 119.184 130.332 118.835 130.332C118.335 130.332 118.011 130.662 118.011 131.18C118.011 131.627 118.335 132.028 118.835 132.028C119.184 132.028 119.484 131.863 119.559 131.533H120.383C120.258 132.405 119.534 132.758 118.835 132.758C117.986 132.758 117.162 132.216 117.162 131.18C117.162 130.12 117.986 129.602 118.835 129.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M122.944 130.311H121.753V130.76H122.845V131.469H121.753V131.966H122.969V132.698H120.885V129.602H122.919V130.311H122.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M117.985 184.741H115.447C114.8 184.741 114.253 184.234 114.253 183.636V183.405C114.253 182.806 114.8 182.3 115.447 182.3H117.985C118.632 182.3 119.179 182.806 119.179 183.405V183.636C119.179 184.257 118.657 184.741 117.985 184.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M100.426 187.565C100.725 187.565 100.799 187.565 101.197 187.565C101.943 187.565 102.49 188.105 102.49 188.833C102.49 189.584 101.918 190.101 101.147 190.101C99.705 190.101 98.8098 190.101 97.3674 190.101C96.8203 190.101 96.3727 189.913 96.1488 189.42C95.9499 189.115 95.8255 188.27 96.6213 187.565C97.1436 186.978 102.913 180.757 103.933 179.677C103.982 179.606 103.933 179.512 103.858 179.512C103.833 179.512 103.833 179.512 103.808 179.512C101.645 179.512 99.4812 179.512 97.3177 179.512C96.6711 179.512 96.1737 179.137 96.0494 178.573C95.9002 177.916 96.1986 177.352 96.7706 177.094C96.9446 177.023 97.1187 177 97.2928 177C100.501 177 103.684 177 106.892 177C107.514 177 107.937 177.188 108.185 177.728C108.509 178.409 108.16 178.878 107.937 179.183C107.489 179.723 102.938 184.56 100.426 187.283C100.401 187.307 100.376 187.354 100.327 187.377C100.277 187.448 100.327 187.565 100.426 187.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M107.186 190.101H104.648C104.001 190.101 103.453 189.582 103.453 188.968V188.732C103.453 188.119 104.001 187.6 104.648 187.6H107.186C107.832 187.6 108.38 188.119 108.38 188.732V188.968C108.38 189.605 107.832 190.101 107.186 190.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M110.715 183.515C110.715 181.734 110.715 179.976 110.715 178.195C110.715 177.492 111.237 177 111.983 177C115.215 177 118.446 177 121.678 177C122.424 177 122.946 177.516 122.946 178.242C122.946 178.969 122.424 179.508 121.678 179.508C118.993 179.508 116.333 179.508 113.649 179.508C113.45 179.508 113.4 179.555 113.4 179.742C113.4 182.273 113.4 184.804 113.4 187.335C113.4 187.546 113.475 187.57 113.649 187.57C116.309 187.57 118.993 187.57 121.653 187.57C122.25 187.57 122.722 187.898 122.896 188.39C123.095 188.976 122.871 189.562 122.399 189.89C122.175 190.03 121.902 190.101 121.628 190.101C118.446 190.101 115.24 190.101 112.058 190.101C111.237 190.101 110.74 189.585 110.74 188.812C110.715 187.031 110.715 185.273 110.715 183.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M97.7054 195.042V195.698H96V195.042H96.4263V193.259H96.0502V192.602H97.6803V193.259H97.3041V195.042H97.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M101.534 195.758H100.774L100.651 195.545C100.381 195.687 100.062 195.758 99.7676 195.758C98.9334 195.734 98.1482 195.167 98.1482 194.15C98.1482 193.181 98.8107 192.542 99.8167 192.542C100.651 192.542 101.461 193.086 101.461 194.15C101.461 194.528 101.363 194.812 101.215 195.072L101.559 195.64V195.758H101.534ZM99.7922 195.001C100.185 195.001 100.602 194.718 100.602 194.15C100.602 193.583 100.185 193.299 99.7922 193.299C99.375 193.299 98.9824 193.583 98.9824 194.15C98.9824 194.718 99.375 195.001 99.7922 195.001Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M103.784 194.749C103.784 194.96 104.029 195.077 104.299 195.077C104.545 195.077 104.79 194.96 104.79 194.796C104.79 194.49 104.422 194.49 104.201 194.467C103.612 194.42 103.022 194.209 103.022 193.552C103.022 192.871 103.612 192.542 104.275 192.542C104.889 192.542 105.527 192.871 105.527 193.552H104.717C104.717 193.34 104.496 193.246 104.275 193.246C104.078 193.246 103.833 193.293 103.833 193.528C103.833 193.739 104.029 193.786 104.324 193.81C105.011 193.857 105.601 194.021 105.601 194.772C105.601 195.429 104.938 195.758 104.275 195.758C103.587 195.758 102.924 195.429 102.949 194.749H103.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M108.229 193.311H107.038V193.76H108.13V194.469H107.038V194.966H108.254V195.698H106.169V192.602H108.204V193.311H108.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M111.603 195.58V195.698H110.722L110.159 194.729H109.719V195.698H108.887V192.602C109.376 192.602 109.841 192.602 110.306 192.602C111.089 192.602 111.456 193.169 111.456 193.713C111.456 194.067 111.285 194.422 110.967 194.611L111.603 195.58ZM110.306 194.067C110.551 194.067 110.649 193.878 110.649 193.666C110.649 193.476 110.526 193.287 110.306 193.287H109.719V194.067H110.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M113.276 194.334L113.553 193.527L113.956 192.602H114.887V192.721L113.452 195.758H113.1L111.666 192.721V192.602H112.597L113.024 193.527L113.276 194.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M116.781 195.042V195.698H115.076V195.042H115.502V193.259H115.126V192.602H116.756V193.259H116.38V195.042H116.781Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M118.835 192.602C119.509 192.602 120.258 192.955 120.383 193.78H119.559C119.434 193.45 119.184 193.332 118.835 193.332C118.335 193.332 118.011 193.662 118.011 194.18C118.011 194.627 118.335 195.028 118.835 195.028C119.184 195.028 119.484 194.863 119.559 194.533H120.383C120.258 195.405 119.534 195.758 118.835 195.758C117.986 195.758 117.162 195.216 117.162 194.18C117.162 193.12 117.986 192.602 118.835 192.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M122.944 193.311H121.753V193.76H122.845V194.469H121.753V194.966H122.969V195.698H120.885V192.602H122.919V193.311H122.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M117.985 249.741H115.447C114.8 249.741 114.253 249.234 114.253 248.636V248.405C114.253 247.806 114.8 247.3 115.447 247.3H117.985C118.632 247.3 119.179 247.806 119.179 248.405V248.636C119.179 249.257 118.657 249.741 117.985 249.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M100.426 252.565C100.725 252.565 100.799 252.565 101.197 252.565C101.943 252.565 102.49 253.105 102.49 253.833C102.49 254.584 101.918 255.101 101.147 255.101C99.705 255.101 98.8098 255.101 97.3674 255.101C96.8203 255.101 96.3727 254.913 96.1488 254.42C95.9499 254.115 95.8255 253.27 96.6213 252.565C97.1436 251.978 102.913 245.757 103.933 244.677C103.982 244.606 103.933 244.512 103.858 244.512C103.833 244.512 103.833 244.512 103.808 244.512C101.645 244.512 99.4812 244.512 97.3177 244.512C96.6711 244.512 96.1737 244.137 96.0494 243.573C95.9002 242.916 96.1986 242.352 96.7706 242.094C96.9446 242.023 97.1187 242 97.2928 242C100.501 242 103.684 242 106.892 242C107.514 242 107.937 242.188 108.185 242.728C108.509 243.409 108.16 243.878 107.937 244.183C107.489 244.723 102.938 249.56 100.426 252.283C100.401 252.307 100.376 252.354 100.327 252.377C100.277 252.448 100.327 252.565 100.426 252.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M107.186 255.101H104.648C104.001 255.101 103.453 254.582 103.453 253.968V253.732C103.453 253.119 104.001 252.6 104.648 252.6H107.186C107.832 252.6 108.38 253.119 108.38 253.732V253.968C108.38 254.605 107.832 255.101 107.186 255.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M110.715 248.515C110.715 246.734 110.715 244.976 110.715 243.195C110.715 242.492 111.237 242 111.983 242C115.215 242 118.446 242 121.678 242C122.424 242 122.946 242.516 122.946 243.242C122.946 243.969 122.424 244.508 121.678 244.508C118.993 244.508 116.333 244.508 113.649 244.508C113.45 244.508 113.4 244.555 113.4 244.742C113.4 247.273 113.4 249.804 113.4 252.335C113.4 252.546 113.475 252.57 113.649 252.57C116.309 252.57 118.993 252.57 121.653 252.57C122.25 252.57 122.722 252.898 122.896 253.39C123.095 253.976 122.871 254.562 122.399 254.89C122.175 255.03 121.902 255.101 121.628 255.101C118.446 255.101 115.24 255.101 112.058 255.101C111.237 255.101 110.74 254.585 110.74 253.812C110.715 252.031 110.715 250.273 110.715 248.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M97.7054 260.042V260.698H96V260.042H96.4263V258.259H96.0502V257.602H97.6803V258.259H97.3041V260.042H97.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M101.534 260.758H100.774L100.651 260.545C100.381 260.687 100.062 260.758 99.7676 260.758C98.9334 260.734 98.1482 260.167 98.1482 259.15C98.1482 258.181 98.8107 257.542 99.8167 257.542C100.651 257.542 101.461 258.086 101.461 259.15C101.461 259.528 101.363 259.812 101.215 260.072L101.559 260.64V260.758H101.534ZM99.7922 260.001C100.185 260.001 100.602 259.718 100.602 259.15C100.602 258.583 100.185 258.299 99.7922 258.299C99.375 258.299 98.9824 258.583 98.9824 259.15C98.9824 259.718 99.375 260.001 99.7922 260.001Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M103.784 259.749C103.784 259.96 104.029 260.077 104.299 260.077C104.545 260.077 104.79 259.96 104.79 259.796C104.79 259.49 104.422 259.49 104.201 259.467C103.612 259.42 103.022 259.209 103.022 258.552C103.022 257.871 103.612 257.542 104.275 257.542C104.889 257.542 105.527 257.871 105.527 258.552H104.717C104.717 258.34 104.496 258.246 104.275 258.246C104.078 258.246 103.833 258.293 103.833 258.528C103.833 258.739 104.029 258.786 104.324 258.81C105.011 258.857 105.601 259.021 105.601 259.772C105.601 260.429 104.938 260.758 104.275 260.758C103.587 260.758 102.924 260.429 102.949 259.749H103.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M108.229 258.311H107.038V258.76H108.13V259.469H107.038V259.966H108.254V260.698H106.169V257.602H108.204V258.311H108.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M111.603 260.58V260.698H110.722L110.159 259.729H109.719V260.698H108.887V257.602C109.376 257.602 109.841 257.602 110.306 257.602C111.089 257.602 111.456 258.169 111.456 258.713C111.456 259.067 111.285 259.422 110.967 259.611L111.603 260.58ZM110.306 259.067C110.551 259.067 110.649 258.878 110.649 258.666C110.649 258.476 110.526 258.287 110.306 258.287H109.719V259.067H110.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M113.276 259.334L113.553 258.527L113.956 257.602H114.887V257.721L113.452 260.758H113.1L111.666 257.721V257.602H112.597L113.024 258.527L113.276 259.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M116.781 260.042V260.698H115.076V260.042H115.502V258.259H115.126V257.602H116.756V258.259H116.38V260.042H116.781Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M118.835 257.602C119.509 257.602 120.258 257.955 120.383 258.78H119.559C119.434 258.45 119.184 258.332 118.835 258.332C118.335 258.332 118.011 258.662 118.011 259.18C118.011 259.627 118.335 260.028 118.835 260.028C119.184 260.028 119.484 259.863 119.559 259.533H120.383C120.258 260.405 119.534 260.758 118.835 260.758C117.986 260.758 117.162 260.216 117.162 259.18C117.162 258.12 117.986 257.602 118.835 257.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M122.944 258.311H121.753V258.76H122.845V259.469H121.753V259.966H122.969V260.698H120.885V257.602H122.919V258.311H122.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <defs>
          <pattern id="4-v-pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use
              xlinkHref="#image0"
              transform="translate(-2.07767 -0.027972) scale(0.00351032 0.0022839)"
            />
          </pattern>
          <filter
            id="4-v-filter0_i"
            x="37"
            y="40"
            width="146.94"
            height="47.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-v-filter1_i"
            x="37"
            y="104"
            width="146.94"
            height="47.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-v-filter2_i"
            x="37"
            y="168"
            width="146.94"
            height="47.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-v-filter3_i"
            x="37"
            y="233"
            width="146.94"
            height="47.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <linearGradient
            id="4-v-paint0_linear"
            x1="301.5"
            y1="291"
            x2="301.5"
            y2="316"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DBDDE1" />
            <stop offset="1" stopColor="#F9F9F9" />
          </linearGradient>
        </defs>
      </svg>
      {!withoutBackground && <ContainerBackground />}
    </ProductImageWrapper>
  )
}
