import styled, { css } from 'styled-components'

export const AlertWrapper = styled.div<{ hide?: boolean }>`
  background-color: #1c3775;
  border-radius: 0 0 0 50px;

  padding: 52px 0 52px 80px;
  margin-bottom: 80px;

  width: 100%;

  /* display: none; */

  position: relative;

  overflow: hidden;

  max-height: 9999px;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0), padding 0.3s;

  &:before {
    content: '';

    background-color: #141b4d;
    border-radius: 50%;

    width: 214px;
    height: 214px;

    position: absolute;

    right: -30px;
    bottom: -30px;

    pointer-events: none;
  }

  &:after {
    content: '';

    border-radius: 50%;
    border: 4px dashed #eaeaea;

    width: 214px;
    height: 214px;

    position: absolute;

    right: -87px;
    top: -20px;

    pointer-events: none;
  }

  ${({ hide = false }) =>
    hide &&
    css`
      max-height: 0;

      padding: 0 30px;
      margin-bottom: 0;

      transition: max-height 0.3s cubic-bezier(0, 1, 0, 1), padding 0.3s;
    `}
`

export const CloseButton = styled.div`
  position: absolute;

  right: 40px;
  top: 40px;

  height: 20px;
  width: 20px;

  cursor: pointer;
`
