import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement13Square8Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#1-3_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 46.9995C32 45.8952 31.1048 45 30.0005 45C28.8962 45 28.001 45.8952 28.001 46.9995V49.1665H28.001V60H28V63.0005C28 64.1048 28.8952 65 29.9995 65C31.1038 65 31.999 64.1048 31.999 63.0005V60.8332H32V50H32V46.9995Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 32 49.1665)"
          fill="#ABABAB"
        />
        <path
          d="M32 45.9399C32 45.4208 31.5792 45 31.0601 45H28.941C28.4219 45 28.001 45.4208 28.001 45.9399V50H32V45.9399Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M28 64.0601C28 64.5792 28.4208 65 28.9399 65H31.059C31.5781 65 31.999 64.5792 31.999 64.0601V60H28V64.0601Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 32 50.8335)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#1-3_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 123C32 121.895 31.1046 121 30 121C28.8954 121 28 121.895 28 123V137.56H32V123ZM32 137.56H28V139.4H27.9399C27.4208 139.4 27 139.821 27 140.34V142.12C27 143.158 27.8417 144 28.8799 144H30.1201C31.1583 144 32 143.158 32 142.12V139.4V139.4V137.56Z"
            fill="white"
          />
        </g>
        <rect
          width="3.99998"
          height="0.95833"
          transform="matrix(-1 0 0 1 32 138.25)"
          fill="#ABABAB"
        />
        <path
          d="M32 121.94C32 121.421 31.5792 121 31.0601 121H28.94C28.4208 121 28 121.421 28 121.94V138.25H32V121.94Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M32 143.06C32 143.579 31.5792 144 31.0601 144H27.94C27.4208 144 27 143.579 27 143.06V140.148C27 139.629 27.4208 139.208 27.94 139.208H32V143.06Z"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#1-3_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M121 34H32V155H121V34ZM110 45H43V144H110V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M121 34H32V155H33.2192V35H119.781V155H121V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-3_filter3_i)">
          <rect
            x="43.47"
            y="45.47"
            width="66.0601"
            height="98.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="38.53"
          width="79.9399"
          height="111.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="112"
          y="87"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#1-3_filter4_d)">
          <rect x="112.702" y="87.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.286 98.7956C113.798 99.4577 114.6 99.8841 115.502 99.8841C117.049 99.8841 118.302 98.6305 118.302 97.0841V90.4442C118.302 89.4557 117.79 88.5869 117.017 88.0886C117.383 88.5619 117.6 89.1556 117.6 89.8001V96.44C117.6 97.9864 116.347 99.24 114.8 99.24C114.242 99.24 113.723 99.0768 113.286 98.7956Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115.502 87.644C113.955 87.644 112.702 88.8976 112.702 90.444V96.4397C112.702 96.4601 112.702 96.4805 112.702 96.5007V91.0236C112.702 89.4772 113.956 88.2236 115.502 88.2236C117.028 88.2236 118.269 89.4444 118.302 90.9627V90.444C118.302 88.8976 117.048 87.644 115.502 87.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-3_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M115.5 91C114.672 91 114 91.6716 114 92.5V92.7143V94V112.5C114 113.328 114.672 114 115.5 114V114C116.328 114 117 113.328 117 112.5V96V92.5L117 92.2857C117 91.5756 116.424 91 115.714 91V91C115.677 91 115.64 91.0012 115.604 91.0035C115.569 91.0012 115.535 91 115.5 91Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M44.5001 46.9969L108.164 94.5L44.5001 142.003L44.5001 46.9969Z" stroke="white" />
        <path d="M44.6948 142.5L76.5 47.5739L108.305 142.5L44.6948 142.5Z" stroke="white" />
        <defs>
          <filter
            id="1-3_filter0_d"
            x="26.1201"
            y="43.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-3_filter1_d"
            x="25.1201"
            y="119.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-3_filter2_d"
            x="27.3003"
            y="29.3003"
            width="100.279"
            height="132.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-3_filter3_i"
            x="43"
            y="45"
            width="67.9399"
            height="101.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="1-3_filter4_d"
            x="110.822"
            y="85.7642"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-3_filter5_d"
            x="112.12"
            y="89.1201"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="34.8"
            y1="62.5"
            x2="27.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="25.2018"
            y1="47.5"
            x2="32.7998"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="34.7982"
            y1="62.5"
            x2="27.2002"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="25.2"
            y1="129.625"
            x2="32.8"
            y2="129.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="23.5"
            y1="141.604"
            x2="33"
            y2="141.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
