import React, { FC, useCallback, useEffect, useRef, useState, useContext, useMemo } from 'react'

import { IFilterItem, IItemSize, IMainViewProps } from './interface'
import {
  MainViewIconBlock,
  MainViewProductBlock,
  MainViewTopBlock,
  MainViewTopBlockLink,
  MainViewWrapper,
} from './styles'
import { DocImage } from './images'
import { HorizontalSize, VerticalSize, WallColorSelect, ProductImagesGenerator } from './components'
import { StepByStepHelper, VIEW_TYPES } from '../../../components'
import { ProductsContext } from './stage-calculator'
import { crossCheckSizeAccept, getArraySizes } from '../../../utilits'
import { HorizontalSizeType } from './components/horizontal-size/interface'
import { VerticalSizeType } from './components/vertical-size/interface'

export const MainView: FC<IMainViewProps> = props => {
  const {
    productType,
    product = {},
    onSetItemSize,
    onSetImageSize,
    onGetPreviewImages,
    filters,
    inProcess,
    isConfigurationEdit,
    setIsConfigurationEdit,
    setCasementsErrors,
    casementsErrors,
    initSize,
  } = props
  const { stepByStepHelper, onChangeStepByStepHelper, setFilters, itemSize } = useContext(
    ProductsContext,
  )
  const [casementChanged, setCasementChanged] = useState(false)
  const [verticalImposts, setVerticalImposts] = useState(0)
  const [horizontalImposts, setHorizontalImposts] = useState(0)
  const [imageWidth, setImageWidth] = useState(0)
  const [imageHeight, setImageHeight] = useState(0)
  const [casementsWidth, setCasementsWidth] = useState<number[]>([])
  const [casementsInnerErrors, setCasementsInnerErrors] = useState<
    Record<string, { title: string; description: string }[]>
  >({})
  const [casementsHeight, setCasementsHeight] = useState<number[]>([])
  const [canRenderSizes, setCanRenderSizes] = useState(false)
  const itemSizeRef = useRef({})
  const itemImpostRef = useRef({})
  const [sizesDisabled, setSizesDisabled] = useState(false)

  useEffect(() => {
    setCasementsInnerErrors(casementsErrors)
  }, [casementsErrors])

  useEffect(() => {
    if (casementChanged) {
      const errors = crossCheckSizeAccept(itemSize, product, filters)

      setCasementChanged(false)
      setCasementsInnerErrors(errors)
      setCasementsErrors(errors)
    }
  }, [casementChanged, filters, itemSize, product, setCasementsErrors])

  useEffect(() => {
    setSizesDisabled(Boolean(inProcess))
  }, [inProcess])

  useEffect(() => {
    const currentModelWidth = itemSizeRef?.current && itemSizeRef.current['model-width']
    const currentModelHeight = itemSizeRef?.current && itemSizeRef.current['model-height']
    const prevVerticalImposts = itemImpostRef?.current && itemImpostRef?.current['vertical']
    const prevHorizontalImposts = itemImpostRef?.current && itemImpostRef?.current['horizontal']
    let currentVerticalImposts: number | null = null
    let currentHorizontalImposts: number | null = null
    const isHaveMainSizes = Boolean(itemSize['model-width'] || itemSize['model-height'])
    const isFirstOpen =
      Object.keys(itemSizeRef.current).length === 0 && // first open or edit by stage cart
      (!itemSize['casements-x'] || !itemSize['casements-y'])
    const isSizesEdit =
      Object.keys(itemSizeRef.current).length > 0 &&
      (currentModelWidth !== itemSize['model-width'] ||
        currentModelHeight !== itemSize['model-height'])
    if (product !== null && product['configuration']) {
      currentHorizontalImposts = Number(product['configuration']['impost-horizontal-value'] || 0)
      currentVerticalImposts = Number(product['configuration']['impost-vertical-value'] || 0)

      setHorizontalImposts(currentHorizontalImposts)
      setVerticalImposts(currentVerticalImposts)
      setCanRenderSizes(true)
    }

    const isImpostsEdit =
      isConfigurationEdit &&
      (prevVerticalImposts !== currentVerticalImposts ||
        prevHorizontalImposts !== currentHorizontalImposts)

    if (isHaveMainSizes && currentVerticalImposts !== null && currentHorizontalImposts !== null) {
      const countVerticalCasements = currentVerticalImposts + 1
      const countHorizontalCasements = currentHorizontalImposts + 1
      const casementWidth = Math.round((itemSize['model-width'] as number) / countVerticalCasements)
      const casementHeight = Math.round(
        (itemSize['model-height'] as number) / countHorizontalCasements,
      )
      const newItemSize: IItemSize[] = []
      const updateSizes = (!initSize && isFirstOpen) || isSizesEdit || isImpostsEdit
      let widthArray = itemSize['casements-x'] as number[]
      let heightArray = itemSize['casements-y'] as number[]

      if (updateSizes) {
        widthArray = getArraySizes(
          countVerticalCasements,
          casementWidth,
          itemSize['model-width'] as number,
        )
        heightArray = getArraySizes(
          countHorizontalCasements,
          casementHeight,
          itemSize['model-height'] as number,
        )

        newItemSize.push({ key: 'casements-x', value: widthArray })
        newItemSize.push({ key: 'casements-y', value: heightArray })

        onSetItemSize(newItemSize)

        if (isImpostsEdit) {
          setIsConfigurationEdit(false)
        }
      }

      setCasementsWidth(widthArray)
      setCasementsHeight(heightArray)
      itemSizeRef.current = itemSize
      itemImpostRef.current = {
        vertical: currentVerticalImposts,
        horizontal: currentHorizontalImposts,
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, itemSize, isConfigurationEdit])

  const handleChangeImageSize = useCallback(
    sizes => {
      setImageWidth(sizes['width'])
      setImageHeight(sizes['height'])
      onSetImageSize(sizes)
    },
    [onSetImageSize],
  )

  const handleSetItemSizes = useCallback(
    (key: string) => (value: number | number[]) => {
      onSetItemSize([{ key, value }])

      if (Array.isArray(value)) {
        if (key === 'casements-x') {
          setCasementsWidth(value)
        } else if (key === 'casements-y') {
          setCasementsHeight(value)
        }
      }

      if (stepByStepHelper === 'outer-sizes') {
        onChangeStepByStepHelper()
      }
    },
    [onChangeStepByStepHelper, onSetItemSize, stepByStepHelper],
  )

  const handleChangeCasement = useCallback(
    (newFilters: IFilterItem[]) => {
      setFilters(newFilters)
      setCasementChanged(true)

      if (stepByStepHelper === 'casements') {
        onChangeStepByStepHelper()
      }
    },
    [onChangeStepByStepHelper, setFilters, stepByStepHelper],
  )

  const getExternalDimensionsErrors = useCallback(
    (impost, key) => {
      return impost === 0 ? casementsInnerErrors[key] : []
    },
    [casementsInnerErrors],
  )

  const countHorizontalSizes = useMemo(() => verticalImposts + 1, [verticalImposts])
  // @TODO костыль
  const crutchMaxWidth = useMemo(() => {
    if (product?.configuration?.images === 'square2Casement') {
      return 2200
    }
    return
  }, [product])

  const openDocument = useCallback(()=> {
    const url = 'https://msk.ze-okna.ru/upload/erp/%D0%97%D0%B0%D0%BC%D0%B5%D1%80%20%D0%BE%D0%BA%D0%BE%D0%BD_%D0%90%D0%BA%D1%81%D0%B8%D0%BE%D0%BC%D0%B0.pdf'
    // @ts-ignore
// eslint-disable-next-line 
    Object.assign(window.location, { href: url })
  }, [])

  return (
    <MainViewWrapper>
      <MainViewTopBlock>
        <MainViewTopBlockLink to="/">
          <span>Рекомендация по замерам</span>
          <MainViewIconBlock>
            <div onClick={openDocument}>
              <DocImage />
            </div>
          </MainViewIconBlock>
        </MainViewTopBlockLink>
      </MainViewTopBlock>
      <MainViewProductBlock>
        <StepByStepHelper
          code="casements"
          arrowDown
          view={VIEW_TYPES.BLUE}
          coord={{ x: 'calc(50% - 13px)', y: '20%' }}
        />
        <ProductImagesGenerator
          productType={productType}
          product={product}
          onChangeSize={handleChangeImageSize}
          onGetPreview={onGetPreviewImages}
          filters={filters}
          setFilters={handleChangeCasement}
        />
        {canRenderSizes && (
          <>
            {verticalImposts > 0 && (
              <HorizontalSize
                value={casementsWidth}
                type={HorizontalSizeType.TOP}
                count={countHorizontalSizes}
                imageWidth={imageWidth}
                maxWidth={(crutchMaxWidth || itemSize['model-width'] as number) - 100 * verticalImposts}
                onChange={handleSetItemSizes('casements-x')}
                disabled={sizesDisabled}
                errors={casementsInnerErrors['casements-x']}
              />
            )}
            <HorizontalSize
              value={[itemSize['model-width'] as number]}
              type={HorizontalSizeType.BOTTOM}
              count={1}
              imageWidth={imageWidth}
              onChange={handleSetItemSizes('model-width')}
              disabled={sizesDisabled}
              errors={getExternalDimensionsErrors(verticalImposts, 'casements-x')}
              maxWidth={crutchMaxWidth}
            />
            {horizontalImposts > 0 && (
              <VerticalSize
                value={casementsHeight}
                type={VerticalSizeType.LEFT}
                count={horizontalImposts + 1}
                imageHeight={imageHeight}
                maxHeight={(itemSize['model-height'] as number) - 100 * horizontalImposts}
                onChange={handleSetItemSizes('casements-y')}
                disabled={sizesDisabled}
                errors={casementsInnerErrors['casements-y']}
              />
            )}
            <VerticalSize
              value={[itemSize['model-height'] as number]}
              type={VerticalSizeType.RIGHT}
              count={1}
              imageHeight={imageHeight}
              onChange={handleSetItemSizes('model-height')}
              disabled={sizesDisabled}
              errors={getExternalDimensionsErrors(horizontalImposts, 'casements-y')}
            />
          </>
        )}
      </MainViewProductBlock>
    </MainViewWrapper>
  )
}
