import React, { FC, useCallback, useEffect, useState } from 'react'
import { useModal } from 'react-modal-hook'

import { AuthForm } from './auth-form'
import { RegisterForm } from './register-form'
import { RepairForm } from './repair-form'
import { RepairMessageForm } from './repair-message-form'
import { FormTypes, IRegisterUserProps } from './interface'
import { useRegister } from '../../../hooks'
import { CloseIcon } from '../../../assets/icons'
import { CloseButton, ModalBody, ModalWrapper } from '../styles'
import { fixBody } from '../../../utilits'
import { StyledButton } from '../../../components/button/styles'
import { getIsOldCase, updatePriceOldCase } from './utils'

export const RegisterUser: FC<IRegisterUserProps> = props => {
  const {
    startSubmit,
    onEndSubmit = () => null,
    onSubmit = () => null,
    isAutoRegister,
    children,
  } = props
  const [formType, setFormType] = useState<FormTypes>(FormTypes.AUTH)
  const {
    isAuthorized,
    onRegisterUser,
    onAuthorizeUser,
    errors,
    onFetchProfile,
    onCheckAuth,
    resetErrors,
  } = useRegister()
  const { toggleFixBody } = fixBody()



  const handleCloseModal2 = useCallback(
    hideModalUpdatePrice => async () => {
      await updatePriceOldCase()
      hideModalUpdatePrice()
      toggleFixBody()
    },
    [toggleFixBody],
  )

  const [showModalUpdatePrice, hideModalUpdatePrice] = useModal(
    ({ in: open }) => {
      return (
        <ModalWrapper show={open}>
          <ModalBody>
            <span className='yes-update-price-text' style={{ marginBottom: 20 }}>
               У вас имеются обращния старше пяти дней. Необходимо обновить цены на изделия.
            </span>
            <StyledButton className='yes-update-price-button' onClick={handleCloseModal2(hideModalUpdatePrice)}>Обновить цены</StyledButton>
          </ModalBody>
        </ModalWrapper>
      )
    },
    [formType, errors],
  )

  const validateOldCase = useCallback(async () => {
    const status = await getIsOldCase()
    if (status) {
      showModalUpdatePrice()
    }
  }, [showModalUpdatePrice])

  const handleAuth = useCallback(
    hideModal => async data => {
      const isAuth = await onAuthorizeUser({
        ...data,
      })

      if (isAuth) {
        onSubmit()
        hideModal()
        toggleFixBody()
        const profile = await onFetchProfile()
        await validateOldCase()
        onEndSubmit(profile)
      }
    },
    [onAuthorizeUser, onEndSubmit, onFetchProfile, onSubmit, toggleFixBody],
  )

  const handleRegister = useCallback(
    hideModal => async data => {
      const isAuth = await onRegisterUser(data)

      if (isAuth) {
        onSubmit()
        hideModal()
        toggleFixBody()
        const profile = await onFetchProfile()
        onEndSubmit(profile)
      }
    },
    [onEndSubmit, onFetchProfile, onRegisterUser, onSubmit, toggleFixBody],
  )

  const handleRepair = useCallback(() => {
    setFormType(FormTypes.REPAIR_MESSAGE)
  }, [])

  const handleOpenRegisterFrom = useCallback(() => {
    resetErrors()
    setFormType(FormTypes.REGISTER)
  }, [resetErrors])

  const handleOpenAuthFrom = useCallback(() => {
    resetErrors()
    setFormType(FormTypes.AUTH)
  }, [resetErrors])

  const handleOpenRepairForm = useCallback(() => {
    resetErrors()
    setFormType(FormTypes.REPAIR)
  }, [resetErrors])

  const handleCloseRepairMessageForm = useCallback(() => {
    setFormType(FormTypes.AUTH)
  }, [])

  const handleCloseModal = useCallback(
    hideModal => () => {
      setFormType(FormTypes.AUTH)
      hideModal()
      toggleFixBody()
    },
    [toggleFixBody],
  )





  const [showModal, hideModal] = useModal(
    ({ in: open }) => {
      return (
        <ModalWrapper show={open}>
          <ModalBody>
            <CloseButton onClick={handleCloseModal(hideModal)}>
              <CloseIcon />
            </CloseButton>
            {formType === 'auth' && (
              <AuthForm
                onAuth={handleAuth(hideModal)}
                onOpenRegisterFrom={handleOpenRegisterFrom}
                onOpenRepairFrom={handleOpenRepairForm}
                errors={errors}
                resetErrors={resetErrors}
              />
            )}
            {formType === 'register' && (
              <RegisterForm
                onRegister={handleRegister(hideModal)}
                onOpenAuthForm={handleOpenAuthFrom}
                errors={errors}
                resetErrors={resetErrors}
              />
            )}
            {formType === 'repair' && <RepairForm onRepair={handleRepair} />}
            {formType === 'repair-message' && (
              <RepairMessageForm onClose={handleCloseRepairMessageForm} />
            )}
          </ModalBody>
        </ModalWrapper>
      )
    },
    [formType, errors],
  )






  const handleChekAuth = useCallback(
    async (forwardAuth?: boolean) => {
      let profile: Record<string, any> | null = null

      if (isAuthorized || forwardAuth) {
        onSubmit()
        hideModal()
        toggleFixBody()
        profile = await onFetchProfile()
      } else {
        showModal()
        toggleFixBody(true)
      }

      onEndSubmit(profile)
    },
    [isAuthorized, onEndSubmit, onSubmit, hideModal, toggleFixBody, onFetchProfile, showModal],
  )

  /** First scenario - */
  useEffect(() => {
    const chekAuth = async () => {
      const isAuth = await onCheckAuth()

      handleChekAuth(isAuth)
    }

    if (typeof isAutoRegister !== 'undefined' && isAutoRegister) {
      chekAuth()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAutoRegister])

  useEffect(() => {
    if (typeof startSubmit !== 'undefined' && startSubmit) {
      handleChekAuth()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startSubmit])

  return <>{children}</>
}
