//import DeleteSvg from 'public/static/svg/delivery/delete.svg'
// <DeleteSvg />
import { InputWarapper } from './styles'

import React, { useEffect, useMemo, useRef } from 'react'

interface IInputAddress {
  value: any,
  placeholder?: string,
  onChange?: any
  onRemove?: any
  onClick?: any
  isLoading?: boolean
}

function InputAddress({ value, placeholder, onRemove, onClick, isLoading }: IInputAddress) {
  const isPlaceholder = useMemo(() => !Boolean(value?.['value']), [value])
  const text = useMemo(() => isLoading ? 'Определяем адрес…' : value.value, [isLoading, value])
 
  return (
    <InputWarapper isPlaceholder={isPlaceholder} >
      <span className='placeholder' onClick={onClick}>{placeholder}</span>
      <div className='address' >
        <div onClick={onClick}>
          <span className='help-label'>Адрес доставки</span>
          <span className='address-label'>{text}</span>
        </div>
        <button onClick={onRemove}>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="9" cy="9" r="9" fill="#CECECE"/>
          <path d="M5.16737 5.16737C5.39052 4.94421 5.75233 4.94421 5.97549 5.16737L9 8.19188L12.0245 5.16737C12.2477 4.94421 12.6095 4.94421 12.8326 5.16737C13.0558 5.39052 13.0558 5.75233 12.8326 5.97549L9.80812 9L12.8326 12.0245C13.0558 12.2477 13.0558 12.6095 12.8326 12.8326C12.6095 13.0558 12.2477 13.0558 12.0245 12.8326L9 9.80812L5.97549 12.8326C5.75233 13.0558 5.39052 13.0558 5.16737 12.8326C4.94421 12.6095 4.94421 12.2477 5.16737 12.0245L8.19188 9L5.16737 5.97549C4.94421 5.75233 4.94421 5.39052 5.16737 5.16737Z" fill="white"/>
        </svg>
        </button>
      </div>
    </InputWarapper>
  )

}

export default InputAddress