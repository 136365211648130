import React, { FC } from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'
import { IContainerSquareProps } from '../../interface'
import { ContainerBackground } from '../container-background'

export const ContainerSquare4Casement: FC<IContainerSquareProps> = props => {
  const { onCasementClick, withoutBackground } = props

  return (
    <ProductImageWrapper isClickable>
      <svg width="315" height="333" viewBox="0 0 315 333" fill="none">
        <rect width="315" height="333" fill="white" />
        <rect x="12" y="19" width="286.682" height="263.183" fill="#E8EAEE" />
        <rect x="12" y="19" width="286.682" height="263.183" fill="url(#21pattern0)" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M305 10H10V292H24V29H291V292H305V10Z"
          fill="#C4C4C4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 29H291V292H24V29ZM37 123H148V279H37V123ZM278 123H167V279H278V123ZM37 42H148V99H37V42ZM278 42H167V99H278V42Z"
          fill="#E8EAEE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M305 292H10V308H0V316H10H305H315V308H305V292Z"
          fill="url(#21paint0_linear)"
        />
        <path d="M305 10L291 28.805V292.075L305 309V10Z" fill="#E9EBEF" />
        <path d="M305 10L291 28.805V292.075L305 309V10Z" fill="#020202" fillOpacity="0.06" />
        <path d="M10 10L24 28.7988V291.982L10 308.901V10Z" fill="#E9EBEF" />
        <path d="M10 10L24 28.7988V291.982L10 308.901V10Z" fill="#020202" fillOpacity="0.06" />
        <rect y="316" width="315" height="17" fill="white" />
        <g
          filter="url(#21filter0_i)"
          className="casement"
          data-index="0"
          onClick={onCasementClick(0)}
        >
          <rect x="37" y="42" width="111" height="57" fill="#B8C1FD" fillOpacity="0.26" />
        </g>
        <rect
          x="37.47"
          y="42.47"
          width="110.06"
          height="56.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#21filter1_i)"
          className="casement"
          data-index="1"
          onClick={onCasementClick(1)}
        >
          <rect x="167" y="42" width="111" height="57" fill="#B8C1FD" fillOpacity="0.26" />
        </g>
        <rect
          x="167.47"
          y="42.47"
          width="110.06"
          height="56.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#21filter2_i)"
          className="casement"
          data-index="3"
          onClick={onCasementClick(3)}
        >
          <rect x="167" y="123" width="111" height="156" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="167.47"
          y="123.47"
          width="110.06"
          height="155.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#21filter3_i)"
          className="casement"
          data-index="2"
          onClick={onCasementClick(2)}
        >
          <rect x="37" y="123" width="111" height="156" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="37.47"
          y="123.47"
          width="110.06"
          height="155.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <path
          d="M100.985 65.7411H98.447C97.8001 65.7411 97.2527 65.2343 97.2527 64.6355V64.4052C97.2527 63.8063 97.8001 63.2996 98.447 63.2996H100.985C101.632 63.2996 102.179 63.8063 102.179 64.4052V64.6355C102.179 65.2574 101.657 65.7411 100.985 65.7411Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M83.4262 68.5652C83.7247 68.5652 83.7993 68.5652 84.1972 68.5652C84.9432 68.5652 85.4903 69.1052 85.4903 69.833C85.4903 70.5843 84.9184 71.1008 84.1474 71.1008C82.705 71.1008 81.8098 71.1008 80.3674 71.1008C79.8203 71.1008 79.3727 70.913 79.1488 70.4199C78.9499 70.1147 78.8255 69.2695 79.6213 68.5652C80.1436 67.9782 85.9131 61.7565 86.9327 60.6765C86.9824 60.6061 86.9327 60.5122 86.8581 60.5122C86.8332 60.5122 86.8332 60.5122 86.8084 60.5122C84.6448 60.5122 82.4812 60.5122 80.3177 60.5122C79.6711 60.5122 79.1737 60.1365 79.0494 59.573C78.9002 58.9156 79.1986 58.3522 79.7706 58.0939C79.9446 58.0235 80.1187 58 80.2928 58C83.5008 58 86.684 58 89.8921 58C90.5138 58 90.9366 58.1878 91.1852 58.7278C91.5085 59.4087 91.1604 59.8783 90.9366 60.1835C90.4889 60.7235 85.938 65.56 83.4262 68.2834C83.4014 68.3069 83.3765 68.3539 83.3268 68.3773C83.277 68.4478 83.3268 68.5652 83.4262 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M90.1856 71.1009H87.6477C87.0008 71.1009 86.4534 70.5818 86.4534 69.9684V69.7324C86.4534 69.1189 87.0008 68.5999 87.6477 68.5999H90.1856C90.8326 68.5999 91.38 69.1189 91.38 69.7324V69.9684C91.38 70.6054 90.8326 71.1009 90.1856 71.1009Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M93.7153 64.5152C93.7153 62.7341 93.7153 60.9764 93.7153 59.1952C93.7153 58.4922 94.2374 58 94.9831 58C98.2147 58 101.446 58 104.678 58C105.424 58 105.946 58.5156 105.946 59.2421C105.946 59.9686 105.424 60.5077 104.678 60.5077C101.993 60.5077 99.3334 60.5077 96.6486 60.5077C96.4498 60.5077 96.4001 60.5545 96.4001 60.742C96.4001 63.2731 96.4001 65.8042 96.4001 68.3353C96.4001 68.5463 96.4746 68.5697 96.6486 68.5697C99.3085 68.5697 101.993 68.5697 104.653 68.5697C105.25 68.5697 105.722 68.8978 105.896 69.39C106.095 69.9759 105.871 70.5618 105.399 70.8899C105.175 71.0305 104.902 71.1008 104.628 71.1008C101.446 71.1008 98.2396 71.1008 95.0577 71.1008C94.2374 71.1008 93.7402 70.5852 93.7402 69.8118C93.7153 68.0307 93.7153 66.273 93.7153 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M80.7054 76.0415V76.6984H79V76.0415H79.4263V74.2586H79.0502V73.6018H80.6803V74.2586H80.3041V76.0415H80.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M84.5344 76.7579H83.7737L83.651 76.5451C83.3811 76.687 83.0621 76.7579 82.7677 76.7579C81.9334 76.7342 81.1482 76.1668 81.1482 75.1501C81.1482 74.1806 81.8107 73.5422 82.8168 73.5422C83.651 73.5422 84.4608 74.0861 84.4608 75.1501C84.4608 75.5284 84.3626 75.8121 84.2154 76.0722L84.5589 76.6397V76.7579H84.5344ZM82.7922 76.0013C83.1848 76.0013 83.602 75.7175 83.602 75.1501C83.602 74.5826 83.1848 74.2989 82.7922 74.2989C82.3751 74.2989 81.9825 74.5826 81.9825 75.1501C81.9825 75.7175 82.3751 76.0013 82.7922 76.0013Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M86.7836 75.7486C86.7836 75.9598 87.0292 76.0772 87.2993 76.0772C87.5448 76.0772 87.7904 75.9598 87.7904 75.7955C87.7904 75.4904 87.4221 75.4904 87.2011 75.4669C86.6117 75.42 86.0223 75.2087 86.0223 74.5515C86.0223 73.8708 86.6117 73.5422 87.2747 73.5422C87.8886 73.5422 88.5271 73.8708 88.5271 74.5515H87.7167C87.7167 74.3403 87.4957 74.2464 87.2747 74.2464C87.0783 74.2464 86.8327 74.2933 86.8327 74.5281C86.8327 74.7393 87.0292 74.7862 87.3238 74.8097C88.0114 74.8567 88.6008 75.021 88.6008 75.7721C88.6008 76.4293 87.9378 76.7579 87.2747 76.7579C86.5871 76.7579 85.9241 76.4293 85.9487 75.7486H86.7836Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M91.2287 74.3109H90.0377V74.7601H91.1295V75.4692H90.0377V75.9656H91.2535V76.6984H89.1692V73.6018H91.2039V74.3109H91.2287Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M94.6032 76.5802V76.6984H93.7223L93.1595 75.7292H92.7191V76.6984H91.8872V73.6018C92.3766 73.6018 92.8415 73.6018 93.3063 73.6018C94.0893 73.6018 94.4563 74.1691 94.4563 74.7128C94.4563 75.0673 94.2851 75.4219 93.967 75.611L94.6032 76.5802ZM93.3063 75.0673C93.551 75.0673 93.6489 74.8782 93.6489 74.6655C93.6489 74.4764 93.5266 74.2873 93.3063 74.2873H92.7191V75.0673H93.3063Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M96.2761 75.3341L96.553 74.5273L96.9556 73.6018H97.8868V73.7205L96.4523 76.7579H96.1L94.6655 73.7205V73.6018H95.5967L96.0245 74.5273L96.2761 75.3341Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M99.7815 76.0415V76.6984H98.0762V76.0415H98.5025V74.2586H98.1263V73.6018H99.7565V74.2586H99.3803V76.0415H99.7815Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M101.835 73.6018C102.509 73.6018 103.258 73.9551 103.383 74.7795H102.559C102.434 74.4497 102.185 74.332 101.835 74.332C101.335 74.332 101.011 74.6617 101.011 75.1799C101.011 75.6274 101.335 76.0278 101.835 76.0278C102.185 76.0278 102.484 75.8629 102.559 75.5332H103.383C103.258 76.4046 102.534 76.7579 101.835 76.7579C100.986 76.7579 100.162 76.2162 100.162 75.1799C100.162 74.12 100.986 73.6018 101.835 73.6018Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M105.944 74.3109H104.753V74.7601H105.845V75.4692H104.753V75.9656H105.969V76.6984H103.885V73.6018H105.919V74.3109H105.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M231.985 65.7411H229.447C228.8 65.7411 228.253 65.2343 228.253 64.6355V64.4052C228.253 63.8063 228.8 63.2996 229.447 63.2996H231.985C232.632 63.2996 233.179 63.8063 233.179 64.4052V64.6355C233.179 65.2574 232.657 65.7411 231.985 65.7411Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M214.426 68.5652C214.725 68.5652 214.799 68.5652 215.197 68.5652C215.943 68.5652 216.49 69.1052 216.49 69.833C216.49 70.5843 215.918 71.1008 215.147 71.1008C213.705 71.1008 212.81 71.1008 211.367 71.1008C210.82 71.1008 210.373 70.913 210.149 70.4199C209.95 70.1147 209.826 69.2695 210.621 68.5652C211.144 67.9782 216.913 61.7565 217.933 60.6765C217.982 60.6061 217.933 60.5122 217.858 60.5122C217.833 60.5122 217.833 60.5122 217.808 60.5122C215.645 60.5122 213.481 60.5122 211.318 60.5122C210.671 60.5122 210.174 60.1365 210.049 59.573C209.9 58.9156 210.199 58.3522 210.771 58.0939C210.945 58.0235 211.119 58 211.293 58C214.501 58 217.684 58 220.892 58C221.514 58 221.937 58.1878 222.185 58.7278C222.509 59.4087 222.16 59.8783 221.937 60.1835C221.489 60.7235 216.938 65.56 214.426 68.2834C214.401 68.3069 214.376 68.3539 214.327 68.3773C214.277 68.4478 214.327 68.5652 214.426 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M221.186 71.1009H218.648C218.001 71.1009 217.453 70.5818 217.453 69.9684V69.7324C217.453 69.1189 218.001 68.5999 218.648 68.5999H221.186C221.833 68.5999 222.38 69.1189 222.38 69.7324V69.9684C222.38 70.6054 221.833 71.1009 221.186 71.1009Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M224.715 64.5152C224.715 62.7341 224.715 60.9764 224.715 59.1952C224.715 58.4922 225.237 58 225.983 58C229.215 58 232.446 58 235.678 58C236.424 58 236.946 58.5156 236.946 59.2421C236.946 59.9686 236.424 60.5077 235.678 60.5077C232.993 60.5077 230.333 60.5077 227.649 60.5077C227.45 60.5077 227.4 60.5545 227.4 60.742C227.4 63.2731 227.4 65.8042 227.4 68.3353C227.4 68.5463 227.475 68.5697 227.649 68.5697C230.309 68.5697 232.993 68.5697 235.653 68.5697C236.25 68.5697 236.722 68.8978 236.896 69.39C237.095 69.9759 236.871 70.5618 236.399 70.8899C236.175 71.0305 235.902 71.1008 235.628 71.1008C232.446 71.1008 229.24 71.1008 226.058 71.1008C225.237 71.1008 224.74 70.5852 224.74 69.8118C224.715 68.0307 224.715 66.273 224.715 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M211.705 76.0415V76.6984H210V76.0415H210.426V74.2586H210.05V73.6018H211.68V74.2586H211.304V76.0415H211.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M215.534 76.7579H214.774L214.651 76.5451C214.381 76.687 214.062 76.7579 213.768 76.7579C212.933 76.7342 212.148 76.1668 212.148 75.1501C212.148 74.1806 212.811 73.5422 213.817 73.5422C214.651 73.5422 215.461 74.0861 215.461 75.1501C215.461 75.5284 215.363 75.8121 215.215 76.0722L215.559 76.6397V76.7579H215.534ZM213.792 76.0013C214.185 76.0013 214.602 75.7175 214.602 75.1501C214.602 74.5826 214.185 74.2989 213.792 74.2989C213.375 74.2989 212.982 74.5826 212.982 75.1501C212.982 75.7175 213.375 76.0013 213.792 76.0013Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M217.784 75.7486C217.784 75.9598 218.029 76.0772 218.299 76.0772C218.545 76.0772 218.79 75.9598 218.79 75.7955C218.79 75.4904 218.422 75.4904 218.201 75.4669C217.612 75.42 217.022 75.2087 217.022 74.5515C217.022 73.8708 217.612 73.5422 218.275 73.5422C218.889 73.5422 219.527 73.8708 219.527 74.5515H218.717C218.717 74.3403 218.496 74.2464 218.275 74.2464C218.078 74.2464 217.833 74.2933 217.833 74.5281C217.833 74.7393 218.029 74.7862 218.324 74.8097C219.011 74.8567 219.601 75.021 219.601 75.7721C219.601 76.4293 218.938 76.7579 218.275 76.7579C217.587 76.7579 216.924 76.4293 216.949 75.7486H217.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M222.229 74.3109H221.038V74.7601H222.129V75.4692H221.038V75.9656H222.254V76.6984H220.169V73.6018H222.204V74.3109H222.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M225.603 76.5802V76.6984H224.722L224.16 75.7292H223.719V76.6984H222.887V73.6018C223.377 73.6018 223.841 73.6018 224.306 73.6018C225.089 73.6018 225.456 74.1691 225.456 74.7128C225.456 75.0673 225.285 75.4219 224.967 75.611L225.603 76.5802ZM224.306 75.0673C224.551 75.0673 224.649 74.8782 224.649 74.6655C224.649 74.4764 224.527 74.2873 224.306 74.2873H223.719V75.0673H224.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M227.276 75.3341L227.553 74.5273L227.956 73.6018H228.887V73.7205L227.452 76.7579H227.1L225.666 73.7205V73.6018H226.597L227.024 74.5273L227.276 75.3341Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M230.782 76.0415V76.6984H229.076V76.0415H229.503V74.2586H229.126V73.6018H230.756V74.2586H230.38V76.0415H230.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M232.835 73.6018C233.509 73.6018 234.258 73.9551 234.383 74.7795H233.559C233.434 74.4497 233.185 74.332 232.835 74.332C232.335 74.332 232.011 74.6617 232.011 75.1799C232.011 75.6274 232.335 76.0278 232.835 76.0278C233.185 76.0278 233.484 75.8629 233.559 75.5332H234.383C234.258 76.4046 233.534 76.7579 232.835 76.7579C231.986 76.7579 231.162 76.2162 231.162 75.1799C231.162 74.12 231.986 73.6018 232.835 73.6018Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M236.944 74.3109H235.753V74.7601H236.845V75.4692H235.753V75.9656H236.969V76.6984H234.885V73.6018H236.919V74.3109H236.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M230.985 141.741H228.447C227.8 141.741 227.253 141.234 227.253 140.635V140.405C227.253 139.806 227.8 139.3 228.447 139.3H230.985C231.632 139.3 232.179 139.806 232.179 140.405V140.635C232.179 141.257 231.657 141.741 230.985 141.741Z"
          fill="#141B4D"
          fillOpacity="0.7"
        />
        <path
          d="M213.426 144.565C213.725 144.565 213.799 144.565 214.197 144.565C214.943 144.565 215.49 145.105 215.49 145.833C215.49 146.584 214.918 147.101 214.147 147.101C212.705 147.101 211.81 147.101 210.367 147.101C209.82 147.101 209.373 146.913 209.149 146.42C208.95 146.115 208.826 145.27 209.621 144.565C210.144 143.978 215.913 137.757 216.933 136.677C216.982 136.606 216.933 136.512 216.858 136.512C216.833 136.512 216.833 136.512 216.808 136.512C214.645 136.512 212.481 136.512 210.318 136.512C209.671 136.512 209.174 136.137 209.049 135.573C208.9 134.916 209.199 134.352 209.771 134.094C209.945 134.023 210.119 134 210.293 134C213.501 134 216.684 134 219.892 134C220.514 134 220.937 134.188 221.185 134.728C221.509 135.409 221.16 135.878 220.937 136.183C220.489 136.723 215.938 141.56 213.426 144.283C213.401 144.307 213.376 144.354 213.327 144.377C213.277 144.448 213.327 144.565 213.426 144.565Z"
          fill="#141B4D"
          fillOpacity="0.7"
        />
        <path
          d="M220.186 147.101H217.648C217.001 147.101 216.453 146.582 216.453 145.968V145.732C216.453 145.119 217.001 144.6 217.648 144.6H220.186C220.833 144.6 221.38 145.119 221.38 145.732V145.968C221.38 146.605 220.833 147.101 220.186 147.101Z"
          fill="#141B4D"
          fillOpacity="0.7"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M223.715 140.515C223.715 138.734 223.715 136.976 223.715 135.195C223.715 134.492 224.237 134 224.983 134C228.215 134 231.446 134 234.678 134C235.424 134 235.946 134.516 235.946 135.242C235.946 135.969 235.424 136.508 234.678 136.508C231.993 136.508 229.333 136.508 226.649 136.508C226.45 136.508 226.4 136.555 226.4 136.742C226.4 139.273 226.4 141.804 226.4 144.335C226.4 144.546 226.475 144.57 226.649 144.57C229.309 144.57 231.993 144.57 234.653 144.57C235.25 144.57 235.722 144.898 235.896 145.39C236.095 145.976 235.871 146.562 235.399 146.89C235.175 147.03 234.902 147.101 234.628 147.101C231.446 147.101 228.24 147.101 225.058 147.101C224.237 147.101 223.74 146.585 223.74 145.812C223.715 144.031 223.715 142.273 223.715 140.515Z"
          fill="#141B4D"
          fillOpacity="0.7"
        />
        <path
          d="M210.705 152.042V152.698H209V152.042H209.426V150.259H209.05V149.602H210.68V150.259H210.304V152.042H210.705Z"
          fill="#141B4D"
          fillOpacity="0.7"
        />
        <path
          d="M214.534 152.758H213.774L213.651 152.545C213.381 152.687 213.062 152.758 212.768 152.758C211.933 152.734 211.148 152.167 211.148 151.15C211.148 150.181 211.811 149.542 212.817 149.542C213.651 149.542 214.461 150.086 214.461 151.15C214.461 151.528 214.363 151.812 214.215 152.072L214.559 152.64V152.758H214.534ZM212.792 152.001C213.185 152.001 213.602 151.718 213.602 151.15C213.602 150.583 213.185 150.299 212.792 150.299C212.375 150.299 211.982 150.583 211.982 151.15C211.982 151.718 212.375 152.001 212.792 152.001Z"
          fill="#141B4D"
          fillOpacity="0.7"
        />
        <path
          d="M216.784 151.749C216.784 151.96 217.029 152.077 217.299 152.077C217.545 152.077 217.79 151.96 217.79 151.796C217.79 151.49 217.422 151.49 217.201 151.467C216.612 151.42 216.022 151.209 216.022 150.552C216.022 149.871 216.612 149.542 217.275 149.542C217.889 149.542 218.527 149.871 218.527 150.552H217.717C217.717 150.34 217.496 150.246 217.275 150.246C217.078 150.246 216.833 150.293 216.833 150.528C216.833 150.739 217.029 150.786 217.324 150.81C218.011 150.857 218.601 151.021 218.601 151.772C218.601 152.429 217.938 152.758 217.275 152.758C216.587 152.758 215.924 152.429 215.949 151.749H216.784Z"
          fill="#141B4D"
          fillOpacity="0.7"
        />
        <path
          d="M221.229 150.311H220.038V150.76H221.129V151.469H220.038V151.966H221.254V152.698H219.169V149.602H221.204V150.311H221.229Z"
          fill="#141B4D"
          fillOpacity="0.7"
        />
        <path
          d="M224.603 152.58V152.698H223.722L223.16 151.729H222.719V152.698H221.887V149.602C222.377 149.602 222.841 149.602 223.306 149.602C224.089 149.602 224.456 150.169 224.456 150.713C224.456 151.067 224.285 151.422 223.967 151.611L224.603 152.58ZM223.306 151.067C223.551 151.067 223.649 150.878 223.649 150.666C223.649 150.476 223.527 150.287 223.306 150.287H222.719V151.067H223.306Z"
          fill="#141B4D"
          fillOpacity="0.7"
        />
        <path
          d="M226.276 151.334L226.553 150.527L226.956 149.602H227.887V149.72L226.452 152.758H226.1L224.666 149.72V149.602H225.597L226.024 150.527L226.276 151.334Z"
          fill="#141B4D"
          fillOpacity="0.7"
        />
        <path
          d="M229.782 152.042V152.698H228.076V152.042H228.503V150.259H228.126V149.602H229.756V150.259H229.38V152.042H229.782Z"
          fill="#141B4D"
          fillOpacity="0.7"
        />
        <path
          d="M231.835 149.602C232.509 149.602 233.258 149.955 233.383 150.779H232.559C232.434 150.45 232.185 150.332 231.835 150.332C231.335 150.332 231.011 150.662 231.011 151.18C231.011 151.627 231.335 152.028 231.835 152.028C232.185 152.028 232.484 151.863 232.559 151.533H233.383C233.258 152.405 232.534 152.758 231.835 152.758C230.986 152.758 230.162 152.216 230.162 151.18C230.162 150.12 230.986 149.602 231.835 149.602Z"
          fill="#141B4D"
          fillOpacity="0.7"
        />
        <path
          d="M235.944 150.311H234.753V150.76H235.845V151.469H234.753V151.966H235.969V152.698H233.885V149.602H235.919V150.311H235.944Z"
          fill="#141B4D"
          fillOpacity="0.7"
        />
        <path
          d="M100.985 141.741H98.447C97.8001 141.741 97.2527 141.234 97.2527 140.635V140.405C97.2527 139.806 97.8001 139.3 98.447 139.3H100.985C101.632 139.3 102.179 139.806 102.179 140.405V140.635C102.179 141.257 101.657 141.741 100.985 141.741Z"
          fill="#141B4D"
          fillOpacity="0.7"
        />
        <path
          d="M83.4262 144.565C83.7247 144.565 83.7993 144.565 84.1972 144.565C84.9432 144.565 85.4903 145.105 85.4903 145.833C85.4903 146.584 84.9184 147.101 84.1474 147.101C82.705 147.101 81.8098 147.101 80.3674 147.101C79.8203 147.101 79.3727 146.913 79.1488 146.42C78.9499 146.115 78.8255 145.27 79.6213 144.565C80.1436 143.978 85.9131 137.757 86.9327 136.677C86.9824 136.606 86.9327 136.512 86.8581 136.512C86.8332 136.512 86.8332 136.512 86.8084 136.512C84.6448 136.512 82.4812 136.512 80.3177 136.512C79.6711 136.512 79.1737 136.137 79.0494 135.573C78.9002 134.916 79.1986 134.352 79.7706 134.094C79.9446 134.023 80.1187 134 80.2928 134C83.5008 134 86.684 134 89.8921 134C90.5138 134 90.9366 134.188 91.1852 134.728C91.5085 135.409 91.1604 135.878 90.9366 136.183C90.4889 136.723 85.938 141.56 83.4262 144.283C83.4014 144.307 83.3765 144.354 83.3268 144.377C83.277 144.448 83.3268 144.565 83.4262 144.565Z"
          fill="#141B4D"
          fillOpacity="0.7"
        />
        <path
          d="M90.1856 147.101H87.6477C87.0008 147.101 86.4534 146.582 86.4534 145.968V145.732C86.4534 145.119 87.0008 144.6 87.6477 144.6H90.1856C90.8326 144.6 91.38 145.119 91.38 145.732V145.968C91.38 146.605 90.8326 147.101 90.1856 147.101Z"
          fill="#141B4D"
          fillOpacity="0.7"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M93.7153 140.515C93.7153 138.734 93.7153 136.976 93.7153 135.195C93.7153 134.492 94.2374 134 94.9831 134C98.2147 134 101.446 134 104.678 134C105.424 134 105.946 134.516 105.946 135.242C105.946 135.969 105.424 136.508 104.678 136.508C101.993 136.508 99.3334 136.508 96.6486 136.508C96.4498 136.508 96.4001 136.555 96.4001 136.742C96.4001 139.273 96.4001 141.804 96.4001 144.335C96.4001 144.546 96.4746 144.57 96.6486 144.57C99.3085 144.57 101.993 144.57 104.653 144.57C105.25 144.57 105.722 144.898 105.896 145.39C106.095 145.976 105.871 146.562 105.399 146.89C105.175 147.03 104.902 147.101 104.628 147.101C101.446 147.101 98.2396 147.101 95.0577 147.101C94.2374 147.101 93.7402 146.585 93.7402 145.812C93.7153 144.031 93.7153 142.273 93.7153 140.515Z"
          fill="#141B4D"
          fillOpacity="0.7"
        />
        <path
          d="M80.7054 152.042V152.698H79V152.042H79.4263V150.259H79.0502V149.602H80.6803V150.259H80.3041V152.042H80.7054Z"
          fill="#141B4D"
          fillOpacity="0.7"
        />
        <path
          d="M84.5344 152.758H83.7737L83.651 152.545C83.3811 152.687 83.0621 152.758 82.7677 152.758C81.9334 152.734 81.1482 152.167 81.1482 151.15C81.1482 150.181 81.8107 149.542 82.8168 149.542C83.651 149.542 84.4608 150.086 84.4608 151.15C84.4608 151.528 84.3626 151.812 84.2154 152.072L84.5589 152.64V152.758H84.5344ZM82.7922 152.001C83.1848 152.001 83.602 151.718 83.602 151.15C83.602 150.583 83.1848 150.299 82.7922 150.299C82.3751 150.299 81.9825 150.583 81.9825 151.15C81.9825 151.718 82.3751 152.001 82.7922 152.001Z"
          fill="#141B4D"
          fillOpacity="0.7"
        />
        <path
          d="M86.7836 151.749C86.7836 151.96 87.0292 152.077 87.2993 152.077C87.5448 152.077 87.7904 151.96 87.7904 151.796C87.7904 151.49 87.4221 151.49 87.2011 151.467C86.6117 151.42 86.0223 151.209 86.0223 150.552C86.0223 149.871 86.6117 149.542 87.2747 149.542C87.8886 149.542 88.5271 149.871 88.5271 150.552H87.7167C87.7167 150.34 87.4957 150.246 87.2747 150.246C87.0783 150.246 86.8327 150.293 86.8327 150.528C86.8327 150.739 87.0292 150.786 87.3238 150.81C88.0114 150.857 88.6008 151.021 88.6008 151.772C88.6008 152.429 87.9378 152.758 87.2747 152.758C86.5871 152.758 85.9241 152.429 85.9487 151.749H86.7836Z"
          fill="#141B4D"
          fillOpacity="0.7"
        />
        <path
          d="M91.2287 150.311H90.0377V150.76H91.1295V151.469H90.0377V151.966H91.2535V152.698H89.1692V149.602H91.2039V150.311H91.2287Z"
          fill="#141B4D"
          fillOpacity="0.7"
        />
        <path
          d="M94.6032 152.58V152.698H93.7223L93.1595 151.729H92.7191V152.698H91.8872V149.602C92.3766 149.602 92.8415 149.602 93.3063 149.602C94.0893 149.602 94.4563 150.169 94.4563 150.713C94.4563 151.067 94.2851 151.422 93.967 151.611L94.6032 152.58ZM93.3063 151.067C93.551 151.067 93.6489 150.878 93.6489 150.666C93.6489 150.476 93.5266 150.287 93.3063 150.287H92.7191V151.067H93.3063Z"
          fill="#141B4D"
          fillOpacity="0.7"
        />
        <path
          d="M96.2761 151.334L96.553 150.527L96.9556 149.602H97.8868V149.72L96.4523 152.758H96.1L94.6655 149.72V149.602H95.5967L96.0245 150.527L96.2761 151.334Z"
          fill="#141B4D"
          fillOpacity="0.7"
        />
        <path
          d="M99.7815 152.042V152.698H98.0762V152.042H98.5025V150.259H98.1263V149.602H99.7565V150.259H99.3803V152.042H99.7815Z"
          fill="#141B4D"
          fillOpacity="0.7"
        />
        <path
          d="M101.835 149.602C102.509 149.602 103.258 149.955 103.383 150.779H102.559C102.434 150.45 102.185 150.332 101.835 150.332C101.335 150.332 101.011 150.662 101.011 151.18C101.011 151.627 101.335 152.028 101.835 152.028C102.185 152.028 102.484 151.863 102.559 151.533H103.383C103.258 152.405 102.534 152.758 101.835 152.758C100.986 152.758 100.162 152.216 100.162 151.18C100.162 150.12 100.986 149.602 101.835 149.602Z"
          fill="#141B4D"
          fillOpacity="0.7"
        />
        <path
          d="M105.944 150.311H104.753V150.76H105.845V151.469H104.753V151.966H105.969V152.698H103.885V149.602H105.919V150.311H105.944Z"
          fill="#141B4D"
          fillOpacity="0.7"
        />
        <defs>
          <pattern id="21pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlinkHref="#image0" transform="translate(-0.18818) scale(0.00137636 0.00149925)" />
          </pattern>
          <filter
            id="21filter0_i"
            x="37"
            y="42"
            width="111.94"
            height="59.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="21filter1_i"
            x="167"
            y="42"
            width="111.94"
            height="59.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="21filter2_i"
            x="167"
            y="123"
            width="111.94"
            height="158.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="21filter3_i"
            x="37"
            y="123"
            width="111.94"
            height="158.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <linearGradient
            id="21paint0_linear"
            x1="167.5"
            y1="292"
            x2="167.5"
            y2="316"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DBDDE1" />
            <stop offset="1" stopColor="#F9F9F9" />
          </linearGradient>
        </defs>
      </svg>
      {!withoutBackground && <ContainerBackground />}
    </ProductImageWrapper>
  )
}
