import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement2Door1LCasement2 = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="451"
        height="451"
        viewBox="0 0 451 451"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#door-1-l-casement-2-2-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M186 271.999C186 270.895 185.105 270 184.001 270C182.896 270 182.001 270.895 182.001 271.999V274.167H182.001V285H182V288.001C182 289.105 182.895 290 183.999 290C185.104 290 185.999 289.105 185.999 288.001V285.833H186V275H186V271.999Z"
            fill="url(#door-1-l-casement-2-2-paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 186 274.167)"
          fill="#ABABAB"
        />
        <path
          d="M186 270.94C186 270.421 185.579 270 185.06 270H182.941C182.422 270 182.001 270.421 182.001 270.94V275H186V270.94Z"
          fill="url(#door-1-l-casement-2-2-paint1_linear)"
        />
        <path
          d="M182 289.06C182 289.579 182.421 290 182.94 290H185.059C185.578 290 185.999 289.579 185.999 289.06V285H182V289.06Z"
          fill="url(#door-1-l-casement-2-2-paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 186 275.833)"
          fill="url(#door-1-l-casement-2-2-paint3_linear)"
        />
        <g filter="url(#door-1-l-casement-2-2-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M186 46.9995C186 45.8952 185.105 45 184.001 45C182.896 45 182.001 45.8952 182.001 46.9995V49.1665H182.001V60H182V63.0005C182 64.1048 182.895 65 183.999 65C185.104 65 185.999 64.1048 185.999 63.0005V60.8332H186V50H186V46.9995Z"
            fill="url(#door-1-l-casement-2-2-paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 186 49.1665)"
          fill="#ABABAB"
        />
        <path
          d="M186 45.9399C186 45.4208 185.579 45 185.06 45H182.941C182.422 45 182.001 45.4208 182.001 45.9399V50H186V45.9399Z"
          fill="url(#door-1-l-casement-2-2-paint5_linear)"
        />
        <path
          d="M182 64.0601C182 64.5792 182.421 65 182.94 65H185.059C185.578 65 185.999 64.5792 185.999 64.0601V60H182V64.0601Z"
          fill="url(#door-1-l-casement-2-2-paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 186 50.8335)"
          fill="url(#door-1-l-casement-2-2-paint7_linear)"
        />
        <g filter="url(#door-1-l-casement-2-2-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M297 34H186V301H297V34ZM286 45H197V290H286V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M297 34H186V301H187V35H296V301H297V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="190.53"
          y="38.53"
          width="101.94"
          height="257.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#door-1-l-casement-2-2-filter3_i)">
          <rect
            x="197.47"
            y="45.47"
            width="88.0601"
            height="244.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <path d="M198.5 287.443V47.5571L284.385 167.5L198.5 287.443Z" stroke="white" />
        <rect
          x="288"
          y="161"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#door-1-l-casement-2-2-filter4_d)">
          <rect x="288.702" y="161.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M289.286 172.796C289.798 173.458 290.6 173.884 291.502 173.884C293.049 173.884 294.302 172.63 294.302 171.084V164.444C294.302 163.456 293.79 162.587 293.017 162.089C293.383 162.562 293.6 163.156 293.6 163.8V170.44C293.6 171.986 292.347 173.24 290.8 173.24C290.242 173.24 289.723 173.077 289.286 172.796Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M291.502 161.644C289.955 161.644 288.702 162.898 288.702 164.444V170.44C288.702 170.46 288.702 170.48 288.702 170.501V165.024C288.702 163.477 289.956 162.224 291.502 162.224C293.028 162.224 294.269 163.444 294.302 164.963V164.444C294.302 162.898 293.048 161.644 291.502 161.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#door-1-l-casement-2-2-filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M291.5 165C290.672 165 290 165.672 290 166.5V166.714V168V186.5C290 187.328 290.672 188 291.5 188V188C292.328 188 293 187.328 293 186.5V170V166.5L293 166.286C293 165.576 292.424 165 291.714 165V165C291.677 165 291.64 165.001 291.604 165.004C291.569 165.001 291.535 165 291.5 165Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="door-1-l-casement-2-2-filter0_d"
            x="180.12"
            y="268.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-2-2-filter1_d"
            x="180.12"
            y="43.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-2-2-filter2_d"
            x="181.3"
            y="29.3003"
            width="122.279"
            height="278.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-2-2-filter3_i"
            x="197"
            y="45"
            width="89.9399"
            height="247.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="door-1-l-casement-2-2-filter4_d"
            x="286.822"
            y="159.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-2-2-filter5_d"
            x="288.12"
            y="163.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="door-1-l-casement-2-2-paint0_linear"
            x1="188.8"
            y1="287.5"
            x2="181.2"
            y2="287.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-2-2-paint1_linear"
            x1="179.202"
            y1="272.5"
            x2="186.8"
            y2="272.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-2-2-paint2_linear"
            x1="188.798"
            y1="287.5"
            x2="181.2"
            y2="287.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-2-2-paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-2-2-paint4_linear"
            x1="188.8"
            y1="62.5"
            x2="181.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-2-2-paint5_linear"
            x1="179.202"
            y1="47.5"
            x2="186.8"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-2-2-paint6_linear"
            x1="188.798"
            y1="62.5"
            x2="181.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-2-2-paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
