import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement35Square41Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#3-5-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M319 255C319 253.895 319.895 253 321 253C322.105 253 323 253.895 323 255V269.56H319V255ZM319 269.56H323V271.4H323.06C323.579 271.4 324 271.821 324 272.34V274.12C324 275.158 323.158 276 322.12 276H320.88C319.842 276 319 275.158 319 274.12V271.4V271.4V269.56Z"
            fill="white"
          />
        </g>
        <rect x="319" y="270.25" width="3.99998" height="0.95833" fill="#ABABAB" />
        <path
          d="M319 253.94C319 253.421 319.421 253 319.94 253H322.06C322.579 253 323 253.421 323 253.94V270.25H319V253.94Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M319 275.06C319 275.579 319.421 276 319.94 276H323.06C323.579 276 324 275.579 324 275.06V272.148C324 271.629 323.579 271.208 323.06 271.208H319V275.06Z"
          fill="url(#paint1_linear)"
        />
        <g filter="url(#3-5-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M323 46.9995C323 45.8952 322.105 45 321.001 45C319.896 45 319.001 45.8952 319.001 46.9995V49.1665H319.001V60H319V63.0005C319 64.1048 319.895 65 320.999 65C322.104 65 322.999 64.1048 322.999 63.0005V60.8332H323V50H323V46.9995Z"
            fill="url(#paint2_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 323 49.1665)"
          fill="#ABABAB"
        />
        <path
          d="M323 45.9399C323 45.4208 322.579 45 322.06 45H319.941C319.422 45 319.001 45.4208 319.001 45.9399V50H323V45.9399Z"
          fill="url(#paint3_linear)"
        />
        <path
          d="M319 64.0601C319 64.5792 319.421 65 319.94 65H322.059C322.578 65 322.999 64.5792 322.999 64.0601V60H319V64.0601Z"
          fill="url(#paint4_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 323 50.8334)"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#3-5-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M319 34H230V287H319V34ZM308 45H241V276H308V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M319 34H230V287H231.219V35H317.781V287H319V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-5-filter3_i)">
          <rect
            x="241.47"
            y="45.47"
            width="66.0601"
            height="230.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="234.53"
          y="38.53"
          width="79.9399"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="232"
          y="155"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#3-5-filter4_d)">
          <rect x="232.702" y="155.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M233.286 166.795C233.798 167.458 234.6 167.884 235.502 167.884C237.049 167.884 238.302 166.63 238.302 165.084V158.444C238.302 157.456 237.79 156.587 237.017 156.089C237.383 156.562 237.6 157.155 237.6 157.8V164.44C237.6 165.986 236.347 167.24 234.8 167.24C234.242 167.24 233.723 167.077 233.286 166.795Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M235.502 155.644C233.955 155.644 232.702 156.898 232.702 158.444V164.44C232.702 164.46 232.702 164.48 232.702 164.501V159.024C232.702 157.477 233.956 156.224 235.502 156.224C237.028 156.224 238.269 157.444 238.302 158.963V158.444C238.302 156.898 237.048 155.644 235.502 155.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-5-filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M235.5 159C234.672 159 234 159.672 234 160.5V160.714V162V180.5C234 181.328 234.672 182 235.5 182V182C236.328 182 237 181.328 237 180.5V164V160.5L237 160.286C237 159.576 236.424 159 235.714 159V159C235.677 159 235.64 159.001 235.604 159.004C235.569 159.001 235.535 159 235.5 159Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M306.5 273.114L242.575 160.999L306.5 47.9008L306.5 273.114Z" stroke="white" />
        <path d="M306.424 274.5L274.5 49.5584L242.576 274.5L306.424 274.5Z" stroke="white" />
        <defs>
          <filter
            id="3-5-filter0_d"
            x="317.12"
            y="251.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-5-filter1_d"
            x="317.12"
            y="43.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-5-filter2_d"
            x="225.3"
            y="29.3003"
            width="100.279"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-5-filter3_i"
            x="241"
            y="45"
            width="67.9399"
            height="233.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-5-filter4_d"
            x="230.822"
            y="153.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-5-filter5_d"
            x="232.12"
            y="157.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="325.8"
            y1="261.625"
            x2="318.2"
            y2="261.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="327.5"
            y1="273.604"
            x2="318"
            y2="273.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="325.8"
            y1="62.5"
            x2="318.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="316.202"
            y1="47.5"
            x2="323.8"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="325.798"
            y1="62.5"
            x2="318.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
