import React from 'react'
import { Provider } from 'react-redux'
import { hot, setConfig } from 'react-hot-loader'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import isMobile from 'is-mobile'
import Modals from '../modals/modals'
import Normalize from '../styles/normalize'
import GlobalStyle from '../styles/main'
import FontsStyle from '../styles/fonts'
import theme from '../styles/theme'
import { DesktopRouter } from './desktop-router'
import { MobileRouter } from './mobile-router'

const GlobalStyles = createGlobalStyle`
  ${Normalize}
  ${GlobalStyle}
  ${FontsStyle}
`

function App({ store }) {
  const isMobileVersion = isMobile()

  return (
    <>
      <ThemeProvider theme={theme}>
        {/* @ts-ignore */}
        <Provider store={store}>
          <Modals>
            <DesktopRouter />
            {/*{!isMobileVersion && <DesktopRouter />}
            {isMobileVersion && <MobileRouter />}*/}
          </Modals>
        </Provider>
      </ThemeProvider>
      <GlobalStyles />
    </>
  )
}

setConfig({ logLevel: 'debug' })

export default hot(module)(App)
