import React, { FC, useContext, useState, useCallback } from 'react'
import { useModal } from 'react-modal-hook'
import {
  ProfileBlockItems,
  ProfileWrapper,
  ProfileGroup,
  PersonalAreaMainWrapper,
  ProfileTitle,
  ProfileBlockButtonWrapper,
  ProfileBlockResetButton,
  ProfileBlockErrorsWrapper,
} from './styles'
import { DateInput, Input, PhoneInput, Button } from '../../../components'
import { ButtonViews, InputTypes } from '../interface'
import { PersonalAreaContext } from './stage-personal-area'
import { useRegister } from '../../../hooks'
import { CloseIcon, CrossIcon } from '../../../assets/icons'
import { fixBody, validatePassword } from '../../../utilits'
import {
  CloseButton,
  LinkMessage,
  LinkWrapper,
  ModalBody,
  ModalWrapper,
  ModalContent,
} from '../styles'

export const ProfileBlock: FC = () => {
  const { profile } = useContext(PersonalAreaContext)
  const [profileData, setProfileData] = useState(() => (profile && profile['profile-data']) || {})
  const [passwords, setPasswords] = useState<Record<string, string>>({})
  const [errors, setErrors] = useState<string[]>([])
  const { onProfileUpdate, onUserPasswordUpdate } = useRegister()
  const { toggleFixBody } = fixBody()

  const handleCloseModal = useCallback(
    hideModal => () => {
      toggleFixBody()
      hideModal()
    },
    [toggleFixBody],
  )

  const [showModal, hideModal] = useModal(({ in: open }) => {
    return (
      <ModalWrapper show={open}>
        <ModalBody>
          <CloseButton onClick={handleCloseModal(hideModal)}>
            <CloseIcon />
          </CloseButton>
          <ModalContent isCenter>Данные успешно сохранены</ModalContent>
        </ModalBody>
      </ModalWrapper>
    )
  }, [])

  const handleSetProfile = useCallback(
    (key: string) => value => {
      setProfileData(prevState => ({
        ...prevState,
        [key]: value,
      }))
    },
    [],
  )

  const handleSaveProfile = useCallback(async () => {
    if (passwords['oldPassword'] && passwords['newPassword'] && passwords['retryNewPassword']) {
      // console.log(passwords)
      const passwordError = validatePassword(passwords['newPassword'])
      if (passwords['retryNewPassword'] !== passwords['newPassword']) {
        return setErrors(['Проверьте правильность нового пароля, пароль не совпадает'])
      }
      if (passwords['oldPassword'] === passwords['newPassword']) {
        return setErrors(['Новый пароль должен отличаться от старого'])
      }
      if (!passwordError) {
        const response = await onUserPasswordUpdate(
          passwords['oldPassword'],
          passwords['newPassword'],
        )

        if (response['errors'] && response['errors'].length > 0) {
          const translateErrors = errorText => {
            if (errorText === 'Password field') {
              return 'Старый пароль введен неверно'
            }
            return errorText
          }
          const errArr = response['errors'].map(error => translateErrors(error['detail']))

          setErrors(errArr)
        } else {
          showModal()
          toggleFixBody(true)
          setPasswords({})
        }
        return
      }
      if (passwordError) {
        return setErrors([passwordError]) 
      }
    } else if (profile) {
      const response = await onProfileUpdate(profile['id'], { 'profile-data': profileData })

      if (response && response['error']) {
        console.error(response['error'])
      } else {
        showModal()
        toggleFixBody(true)
      }
    }
  }, [
    onProfileUpdate,
    onUserPasswordUpdate,
    passwords,
    profile,
    profileData,
    showModal,
    toggleFixBody,
  ])

  const handleReset = useCallback(() => {
    const oldProfile = (profile && profile['profile-data']) || {}
    setProfileData(oldProfile)
  }, [profile])

  const handleChangePassword = useCallback(
    (key: string) => value => {
      setPasswords(prevState => ({
        ...prevState,
        [key]: value,
      }))
    },
    [],
  )

  return (
    <PersonalAreaMainWrapper>
      <ProfileWrapper>
        {profile && (
          <ProfileBlockItems>
            <ProfileGroup>
              <Input
                id="first-name"
                name="first-name"
                placeholder="Имя"
                value={profileData['first-name']}
                onChange={handleSetProfile('first-name')}
              />
            </ProfileGroup>
            <ProfileGroup>
              <Input
                id="last-name"
                name="last-name"
                placeholder="Фамилия"
                value={profileData['last-name']}
                onChange={handleSetProfile('last-name')}
              />
            </ProfileGroup>
            <ProfileGroup>
              <DateInput
                id="birthday"
                name="birthday"
                placeholder="День рождения"
                value={profileData['birthday']}
                onChange={handleSetProfile('birthday')}
              />
            </ProfileGroup>
            <ProfileGroup>
              <PhoneInput
                id="phone"
                name="phone"
                placeholder="Номер телефона"
                value={profileData['phone']}
                onChange={handleSetProfile('phone')}
                disabled
              />
            </ProfileGroup>
            <ProfileGroup>
              <Input
                id="recipient"
                name="email"
                placeholder="Email"
                value={profileData['email']}
                disabled
              />
            </ProfileGroup>
            {/* TODO: make password change */}
          </ProfileBlockItems>
        )}
        <ProfileBlockButtonWrapper>
          <Button typeView={ButtonViews.ORANGE} onClick={handleSaveProfile}>
            Сохранить
          </Button>
          <ProfileBlockResetButton onClick={handleReset}>
            <CrossIcon /> <span>Отменить</span>
          </ProfileBlockResetButton>
        </ProfileBlockButtonWrapper>
        <ProfileBlockErrorsWrapper>
          {errors &&
            errors.length > 0 &&
            errors.map(error => (
              <LinkWrapper key={error}>
                <LinkMessage isError>{error}</LinkMessage>
              </LinkWrapper>
            ))}
        </ProfileBlockErrorsWrapper>
      </ProfileWrapper>
    </PersonalAreaMainWrapper>
  )
}
/*
  <ProfileTitle>Изменить пароль</ProfileTitle>
  <ProfileBlockItems>
    <ProfileGroup>
      <Input
        id="old-password"
        name="old-password"
        placeholder="Введите старый пароль"
        type={InputTypes.password}
        value={passwords['oldPassword']}
        onChange={handleChangePassword('oldPassword')}
      />
    </ProfileGroup>
    <ProfileGroup>
      <Input
        id="new-password"
        name="new-password"
        placeholder="Введите новый пароль"
        type={InputTypes.password}
        value={passwords['newPassword']}
        onChange={handleChangePassword('newPassword')}
      />
    </ProfileGroup>
    <ProfileGroup>
      <Input
        id="repeat-password"
        name="repeat-password"
        placeholder="Повторите новый пароль"
        type={InputTypes.password}
        value={passwords['retryNewPassword']}
        onChange={handleChangePassword('retryNewPassword')}
      />
    </ProfileGroup>
  </ProfileBlockItems>
*/