import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement23Square6Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-3_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M271.001 34C272.105 34 273 33.1048 273 32.0005C273 30.8962 272.105 30.001 271.001 30.001L268.833 30.001L268.833 30.001L258 30.001L258 30L254.999 30C253.895 30 253 30.8952 253 31.9995C253 33.1038 253.895 33.999 254.999 33.999L257.167 33.999L257.167 34L268 34L268 34L271.001 34Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 268.834 34)"
          fill="#ABABAB"
        />
        <path
          d="M272.06 34C272.579 34 273 33.5792 273 33.0601L273 30.941C273 30.4219 272.579 30.001 272.06 30.001L268 30.001L268 34L272.06 34Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M253.94 30C253.421 30 253 30.4208 253 30.9399L253 33.059C253 33.5781 253.421 33.999 253.94 33.999L258 33.999L258 30L253.94 30Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 267.167 34)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#2-3_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M193.001 34C194.105 34 195 33.1048 195 32.0005C195 30.8962 194.105 30.001 193.001 30.001L190.833 30.001L190.833 30.001L180 30.001L180 30L176.999 30C175.895 30 175 30.8952 175 31.9995C175 33.1038 175.895 33.999 176.999 33.999L179.167 33.999L179.167 34L190 34L190 34L193.001 34Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 190.834 34)"
          fill="#ABABAB"
        />
        <path
          d="M194.06 34C194.579 34 195 33.5792 195 33.0601L195 30.941C195 30.4219 194.579 30.001 194.06 30.001L190 30.001L190 34L194.06 34Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M175.94 30C175.421 30 175 30.4208 175 30.9399L175 33.059C175 33.5781 175.421 33.999 175.94 33.999L180 33.999L180 30L175.94 30Z"
          fill="url(#paint2-3_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 189.167 34)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#2-3_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M284 34H164V118H284V34ZM273 45H175V107H273V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M284 34H164V118H165V35H283V118H284V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="168.53"
          y="38.53"
          width="110.94"
          height="74.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#2-3_filter3_i)">
          <rect
            x="175.47"
            y="45.47"
            width="97.0601"
            height="61.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="231"
          y="109"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 231 109)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="230.355"
          y="109.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 230.355 109.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M230.238 111.695C229.892 110.542 228.822 109.702 227.556 109.702L220.916 109.702C219.37 109.702 218.116 110.956 218.116 112.502C218.116 112.537 218.117 112.572 218.118 112.607C218.465 113.76 219.534 114.6 220.8 114.6L227.44 114.6C228.987 114.6 230.24 113.347 230.24 111.8C230.24 111.765 230.24 111.73 230.238 111.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M228.881 110.035C228.737 110.012 228.59 110 228.44 110L221.8 110C220.254 110 219 111.254 219 112.8C219 113.867 219.597 114.795 220.475 115.268C219.138 115.056 218.116 113.898 218.116 112.502C218.116 110.956 219.37 109.702 220.916 109.702L227.556 109.702C228.035 109.702 228.486 109.823 228.881 110.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-3_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M227 112.5C227 111.672 226.328 111 225.5 111L225.286 111L224 111L205.5 111C204.672 111 204 111.672 204 112.5C204 113.328 204.672 114 205.5 114L222 114L225.5 114L225.714 114C226.424 114 227 113.424 227 112.714C227 112.677 226.999 112.64 226.996 112.604C226.999 112.569 227 112.535 227 112.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M177.04 46.5L270.96 46.5L224 105.2L177.04 46.5Z" stroke="white" />
        <defs>
          <filter
            id="2-3_filter0_d"
            x="251.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-3_filter1_d"
            x="173.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-3_filter2_d"
            x="159.3"
            y="29.3003"
            width="131.279"
            height="95.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-3_filter3_i"
            x="175"
            y="45"
            width="98.9399"
            height="64.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-3_filter4_d"
            x="202.28"
            y="109.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="255.5"
            y1="36.8"
            x2="255.5"
            y2="29.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="270.5"
            y1="27.2018"
            x2="270.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="255.5"
            y1="36.7982"
            x2="255.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="177.5"
            y1="36.8"
            x2="177.5"
            y2="29.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="192.5"
            y1="27.2018"
            x2="192.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2-3_linear"
            x1="177.5"
            y1="36.7982"
            x2="177.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
