import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement43Square3Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-3_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.9995 199C38.8952 199 38 199.895 38 200.999C38 202.104 38.8952 202.999 39.9995 202.999L42.1665 202.999L42.1665 202.999L53 202.999L53 203L56.0005 203C57.1048 203 58 202.105 58 201.001C58 199.896 57.1048 199.001 56.0005 199.001L53.8332 199.001L53.8332 199L43 199L43 199L39.9995 199Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 42.166 199)"
          fill="#ABABAB"
        />
        <path
          d="M38.9399 199C38.4208 199 38 199.421 38 199.94L38 202.059C38 202.578 38.4208 202.999 38.9399 202.999L43 202.999L43 199L38.9399 199Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M57.0601 203C57.5792 203 58 202.579 58 202.06L58 199.941C58 199.422 57.5792 199.001 57.0601 199.001L53 199.001L53 203L57.0601 203Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 43.833 199)"
          fill="url(#paint4-3_linear)"
        />
        <g filter="url(#4-3_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M127.999 199C126.895 199 126 199.895 126 200.999C126 202.104 126.895 202.999 127.999 202.999L130.167 202.999L130.167 202.999L141 202.999L141 203L144.001 203C145.105 203 146 202.105 146 201.001C146 199.896 145.105 199.001 144.001 199.001L141.833 199.001L141.833 199L131 199L131 199L127.999 199Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 130.166 199)"
          fill="#ABABAB"
        />
        <path
          d="M126.94 199C126.421 199 126 199.421 126 199.94L126 202.059C126 202.578 126.421 202.999 126.94 202.999L131 202.999L131 199L126.94 199Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M145.06 203C145.579 203 146 202.579 146 202.06L146 199.941C146 199.422 145.579 199.001 145.06 199.001L141 199.001L141 203L145.06 203Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 131.833 199)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#4-3_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M152 122H32V199H152V122ZM141 133H43V188H141V133Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#4-3_filter4-3_i)">
          <rect
            x="43.47"
            y="133.47"
            width="97.0601"
            height="54.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="37.5" y="127.5" width="109" height="66" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M152 122H32V199H33V123H151V199H152V122Z"
          fill="#FBFBFB"
        />
        <path d="M138.873 186.5H45.1274L92 134.745L138.873 186.5Z" stroke="white" />
        <rect
          x="99.5283"
          y="124"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 99.5283 124)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#4-3_filter4_d)">
          <rect
            x="98.8838"
            y="124.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 98.8838 124.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M98.7666 126.695C98.4202 125.542 97.3505 124.702 96.0846 124.702L89.4447 124.702C87.8983 124.702 86.6447 125.956 86.6447 127.502C86.6447 127.537 86.6454 127.572 86.6466 127.607C86.993 128.76 88.0627 129.6 89.3286 129.6L95.9686 129.6C97.515 129.6 98.7686 128.347 98.7686 126.8C98.7686 126.765 98.7679 126.73 98.7666 126.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M97.4092 125.035C97.2655 125.012 97.1182 125 96.9681 125L90.3282 125C88.7818 125 87.5282 126.254 87.5282 127.8C87.5282 128.867 88.1251 129.795 89.0032 130.268C87.6664 130.056 86.6443 128.898 86.6443 127.502C86.6443 125.956 87.8979 124.702 89.4443 124.702L96.0842 124.702C96.5635 124.702 97.0147 124.823 97.4092 125.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-3_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M112.528 127.5C112.528 126.672 111.857 126 111.028 126L110.814 126L109.528 126L91.0283 126C90.1999 126 89.5283 126.672 89.5283 127.5C89.5283 128.328 90.1999 129 91.0283 129L107.528 129L111.028 129L111.243 129C111.953 129 112.528 128.424 112.528 127.714C112.528 127.677 112.527 127.64 112.525 127.604C112.527 127.569 112.528 127.535 112.528 127.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="4-3_filter0_d"
            x="36.1201"
            y="197.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-3_filter1_d"
            x="124.12"
            y="197.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-3_filter2_d"
            x="27.3003"
            y="117.3"
            width="131.279"
            height="88.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-3_filter4-3_i"
            x="43"
            y="133"
            width="98.9399"
            height="57.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-3_filter4_d"
            x="84.7637"
            y="122.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-3_filter5_d"
            x="88.6485"
            y="124.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="55.5"
            y1="196.2"
            x2="55.5"
            y2="203.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="40.5"
            y1="205.798"
            x2="40.5"
            y2="198.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="55.5"
            y1="196.202"
            x2="55.5"
            y2="203.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4-3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="143.5"
            y1="196.2"
            x2="143.5"
            y2="203.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="128.5"
            y1="205.798"
            x2="128.5"
            y2="198.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="143.5"
            y1="196.202"
            x2="143.5"
            y2="203.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
