import React, { FC } from 'react'
import { ProductImageWrapper } from '../../../components/product-images-generator/styles'
import { IContainerSquareProps } from '../../../interface'
import { ContainerBackground } from '../../container-background'

export const ContainerLoggiaR4Casement: FC<IContainerSquareProps> = props => {
  const { onCasementClick, withoutBackground } = props

  return (
    <ProductImageWrapper isClickable>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g clipPath="url(#clip0)">
          <path d="M225 8H406V267H220L65 322.5V63.5L225 8Z" fill="url(#pattern0)" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M401.725 4.02832H225.28V264.264H401.725V4.02832ZM401.724 8.05716H229.307V260.236H401.724V8.05716Z"
            fill="#C4C4C4"
          />
          <path
            d="M401.724 4.02832L396.084 8.05674V260.236L401.724 264.264V4.02832Z"
            fill="#E9EBEF"
          />
          <path
            d="M401.724 4.02832L396.084 8.05674V260.236L401.724 264.264V4.02832Z"
            fill="#CED0D4"
          />
          <rect x="225.28" y="264.264" width="180.473" height="4.02842" fill="white" />
          <rect x="225.28" width="176.445" height="4.02842" fill="white" />
          <rect
            x="228.1"
            y="10.8765"
            width="79.7627"
            height="120.047"
            stroke="#F3F4F8"
            strokeWidth="5.63979"
          />
          <rect
            x="313.502"
            y="10.8765"
            width="79.7627"
            height="120.047"
            stroke="#F3F4F8"
            strokeWidth="5.63979"
          />
          <rect
            x="228.1"
            y="136.564"
            width="79.7627"
            height="120.853"
            stroke="#F3F4F8"
            strokeWidth="5.63979"
          />
          <rect
            x="313.502"
            y="136.564"
            width="79.7627"
            height="120.853"
            stroke="#F3F4F8"
            strokeWidth="5.63979"
          />
          <g
            filter="url(#filter0_i)"
            className="casement"
            data-index="0"
            onClick={onCasementClick(0)}
          >
            <rect
              x="230.919"
              y="13.6963"
              width="74.123"
              height="114.407"
              fill="#B8C1FD"
              fillOpacity="0.4"
            />
          </g>
          <rect
            x="231.322"
            y="14.0991"
            width="73.3173"
            height="113.602"
            stroke="#979797"
            strokeWidth="0.805684"
          />
          <g
            filter="url(#filter1_i)"
            className="casement"
            data-index="0"
            onClick={onCasementClick(0)}
          >
            <rect
              x="316.321"
              y="13.6963"
              width="74.123"
              height="114.407"
              fill="#B8C1FD"
              fillOpacity="0.4"
            />
          </g>
          <rect
            x="316.724"
            y="14.0991"
            width="73.3173"
            height="113.602"
            stroke="#979797"
            strokeWidth="0.805684"
          />
          <g
            filter="url(#filter2_i)"
            className="casement"
            data-index="0"
            onClick={onCasementClick(0)}
          >
            <rect
              x="230.919"
              y="139.383"
              width="74.123"
              height="115.213"
              fill="#B8C1FD"
              fillOpacity="0.4"
            />
          </g>
          <rect
            x="231.322"
            y="139.786"
            width="73.3173"
            height="114.407"
            stroke="#979797"
            strokeWidth="0.805684"
          />
          <g
            filter="url(#filter3_i)"
            className="casement"
            data-index="0"
            onClick={onCasementClick(0)}
          >
            <rect
              x="316.321"
              y="139.383"
              width="74.123"
              height="115.213"
              fill="#B8C1FD"
              fillOpacity="0.4"
            />
          </g>
          <rect
            x="316.724"
            y="139.786"
            width="73.3173"
            height="114.407"
            stroke="#979797"
            strokeWidth="0.805684"
          />
          <path
            d="M276.003 37.4578H273.496C272.857 37.4578 272.316 36.9572 272.316 36.3656V36.1381C272.316 35.5465 272.857 35.0459 273.496 35.0459H276.003C276.642 35.0459 277.183 35.5465 277.183 36.1381V36.3656C277.183 36.98 276.667 37.4578 276.003 37.4578Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M258.657 40.2477C258.952 40.2477 259.026 40.2477 259.419 40.2477C260.156 40.2477 260.696 40.7812 260.696 41.5002C260.696 42.2424 260.131 42.7526 259.37 42.7526C257.945 42.7526 257.06 42.7526 255.636 42.7526C255.095 42.7526 254.653 42.5671 254.432 42.08C254.235 41.7785 254.112 40.9435 254.898 40.2477C255.414 39.6679 261.114 33.5215 262.121 32.4546C262.17 32.385 262.121 32.2923 262.048 32.2923C262.023 32.2923 262.023 32.2923 261.998 32.2923C259.861 32.2923 257.724 32.2923 255.586 32.2923C254.948 32.2923 254.456 31.9212 254.333 31.3645C254.186 30.7151 254.481 30.1585 255.046 29.9033C255.218 29.8337 255.39 29.8105 255.562 29.8105C258.731 29.8105 261.876 29.8105 265.045 29.8105C265.659 29.8105 266.077 29.9961 266.322 30.5296C266.642 31.2022 266.298 31.666 266.077 31.9676C265.634 32.501 261.139 37.2789 258.657 39.9694C258.633 39.9926 258.608 40.039 258.559 40.0622C258.51 40.1317 258.559 40.2477 258.657 40.2477Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M265.335 42.753H262.827C262.188 42.753 261.647 42.2402 261.647 41.6342V41.4011C261.647 40.795 262.188 40.2822 262.827 40.2822H265.335C265.974 40.2822 266.514 40.795 266.514 41.4011V41.6342C266.514 42.2635 265.974 42.753 265.335 42.753Z"
            fill="#141B4D"
            fillOpacity="0.7"
            stroke="#767676"
            strokeWidth="0.0802224"
            strokeMiterlimit="10"
          />
          <path
            d="M268.821 36.2469C268.821 34.4873 268.821 32.7509 268.821 30.9913C268.821 30.2967 269.337 29.8105 270.074 29.8105C273.266 29.8105 276.459 29.8105 279.651 29.8105C280.388 29.8105 280.904 30.3199 280.904 31.0376C280.904 31.7553 280.388 32.2878 279.651 32.2878C276.999 32.2878 274.371 32.2878 271.719 32.2878C271.523 32.2878 271.473 32.3341 271.473 32.5194C271.473 35.0198 271.473 37.5202 271.473 40.0207C271.473 40.229 271.547 40.2522 271.719 40.2522C274.347 40.2522 276.999 40.2522 279.627 40.2522C280.216 40.2522 280.683 40.5763 280.854 41.0625C281.051 41.6413 280.83 42.2201 280.363 42.5443C280.142 42.6832 279.872 42.7526 279.602 42.7526C276.459 42.7526 273.291 42.7526 270.147 42.7526C269.337 42.7526 268.846 42.2433 268.846 41.4793C268.821 39.7197 268.821 37.9833 268.821 36.2469Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M255.969 47.6338V48.2827H254.284V47.6338H254.705V45.8725H254.334V45.2236H255.944V45.8725H255.572V47.6338H255.969Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M259.751 48.3417H259L258.879 48.1315C258.612 48.2717 258.297 48.3417 258.006 48.3417C257.182 48.3184 256.406 47.7578 256.406 46.7534C256.406 45.7957 257.061 45.165 258.055 45.165C258.879 45.165 259.679 45.7023 259.679 46.7534C259.679 47.1271 259.582 47.4074 259.436 47.6643L259.776 48.2249V48.3417H259.751ZM258.03 47.5943C258.418 47.5943 258.83 47.314 258.83 46.7534C258.83 46.1928 258.418 45.9125 258.03 45.9125C257.618 45.9125 257.23 46.1928 257.23 46.7534C257.23 47.314 257.618 47.5943 258.03 47.5943Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M261.974 47.3447C261.974 47.5534 262.216 47.6693 262.483 47.6693C262.726 47.6693 262.969 47.5534 262.969 47.391C262.969 47.0896 262.605 47.0896 262.386 47.0664C261.804 47.02 261.222 46.8114 261.222 46.1621C261.222 45.4897 261.804 45.165 262.459 45.165C263.066 45.165 263.696 45.4897 263.696 46.1621H262.896C262.896 45.9534 262.677 45.8607 262.459 45.8607C262.265 45.8607 262.022 45.907 262.022 46.1389C262.022 46.3476 262.216 46.394 262.508 46.4172C263.187 46.4635 263.769 46.6259 263.769 47.3679C263.769 48.0171 263.114 48.3417 262.459 48.3417C261.78 48.3417 261.125 48.0171 261.149 47.3447H261.974Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M266.365 45.9242H265.189V46.3679H266.267V47.0684H265.189V47.5588H266.39V48.2827H264.331V45.2236H266.341V45.9242H266.365Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M269.698 48.1659V48.2827H268.828L268.272 47.3253H267.836V48.2827H267.015V45.2236C267.498 45.2236 267.957 45.2236 268.417 45.2236C269.19 45.2236 269.553 45.7841 269.553 46.3211C269.553 46.6714 269.383 47.0217 269.069 47.2085L269.698 48.1659ZM268.417 46.6714C268.658 46.6714 268.755 46.4846 268.755 46.2744C268.755 46.0876 268.634 45.9008 268.417 45.9008H267.836V46.6714H268.417Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M271.351 46.9349L271.624 46.1379L272.022 45.2236H272.942V45.3408L271.525 48.3415H271.177L269.76 45.3408V45.2236H270.68L271.102 46.1379L271.351 46.9349Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M274.814 47.6338V48.2827H273.129V47.6338H273.551V45.8725H273.179V45.2236H274.789V45.8725H274.418V47.6338H274.814Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M276.842 45.2236C277.508 45.2236 278.248 45.5726 278.372 46.387H277.558C277.434 46.0613 277.188 45.9449 276.842 45.9449C276.349 45.9449 276.028 46.2707 276.028 46.7826C276.028 47.2247 276.349 47.6202 276.842 47.6202C277.188 47.6202 277.484 47.4573 277.558 47.1316H278.372C278.248 47.9925 277.533 48.3415 276.842 48.3415C276.004 48.3415 275.189 47.8063 275.189 46.7826C275.189 45.7355 276.004 45.2236 276.842 45.2236Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M280.903 45.9242H279.726V46.3679H280.805V47.0684H279.726V47.5588H280.927V48.2827H278.868V45.2236H280.878V45.9242H280.903Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M361.405 37.4588H358.898C358.259 37.4588 357.718 36.9582 357.718 36.3666V36.1391C357.718 35.5475 358.259 35.0469 358.898 35.0469H361.405C362.044 35.0469 362.585 35.5475 362.585 36.1391V36.3666C362.585 36.981 362.069 37.4588 361.405 37.4588Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M344.059 40.2477C344.353 40.2477 344.427 40.2477 344.82 40.2477C345.557 40.2477 346.098 40.7812 346.098 41.5002C346.098 42.2424 345.533 42.7526 344.771 42.7526C343.346 42.7526 342.462 42.7526 341.037 42.7526C340.496 42.7526 340.054 42.5671 339.833 42.08C339.637 41.7785 339.514 40.9435 340.3 40.2477C340.816 39.6679 346.515 33.5215 347.523 32.4546C347.572 32.385 347.523 32.2923 347.449 32.2923C347.424 32.2923 347.424 32.2923 347.4 32.2923C345.262 32.2923 343.125 32.2923 340.988 32.2923C340.349 32.2923 339.858 31.9212 339.735 31.3645C339.587 30.7151 339.882 30.1585 340.447 29.9033C340.619 29.8337 340.791 29.8105 340.963 29.8105C344.132 29.8105 347.277 29.8105 350.446 29.8105C351.06 29.8105 351.478 29.9961 351.724 30.5296C352.043 31.2022 351.699 31.666 351.478 31.9676C351.036 32.501 346.54 37.2789 344.059 39.9694C344.034 39.9926 344.01 40.039 343.96 40.0622C343.911 40.1317 343.96 40.2477 344.059 40.2477Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M350.736 42.753H348.229C347.59 42.753 347.049 42.2402 347.049 41.6342V41.4011C347.049 40.795 347.59 40.2822 348.229 40.2822H350.736C351.375 40.2822 351.916 40.795 351.916 41.4011V41.6342C351.916 42.2635 351.375 42.753 350.736 42.753Z"
            fill="#141B4D"
            fillOpacity="0.7"
            stroke="#767676"
            strokeWidth="0.0802224"
            strokeMiterlimit="10"
          />
          <path
            d="M354.224 36.2469C354.224 34.4873 354.224 32.7509 354.224 30.9913C354.224 30.2967 354.739 29.8105 355.476 29.8105C358.669 29.8105 361.861 29.8105 365.053 29.8105C365.79 29.8105 366.306 30.3199 366.306 31.0376C366.306 31.7553 365.79 32.2878 365.053 32.2878C362.401 32.2878 359.774 32.2878 357.121 32.2878C356.925 32.2878 356.876 32.3341 356.876 32.5194C356.876 35.0198 356.876 37.5202 356.876 40.0207C356.876 40.229 356.95 40.2522 357.121 40.2522C359.749 40.2522 362.401 40.2522 365.029 40.2522C365.618 40.2522 366.085 40.5763 366.257 41.0625C366.453 41.6413 366.232 42.2201 365.766 42.5443C365.545 42.6832 365.274 42.7526 365.004 42.7526C361.861 42.7526 358.693 42.7526 355.55 42.7526C354.739 42.7526 354.248 42.2433 354.248 41.4793C354.224 39.7197 354.224 37.9833 354.224 36.2469Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M341.371 47.6338V48.2827H339.687V47.6338H340.108V45.8725H339.736V45.2236H341.346V45.8725H340.975V47.6338H341.371Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M345.153 48.3417H344.402L344.281 48.1315C344.014 48.2717 343.699 48.3417 343.408 48.3417C342.584 48.3184 341.808 47.7578 341.808 46.7534C341.808 45.7957 342.463 45.165 343.456 45.165C344.281 45.165 345.081 45.7023 345.081 46.7534C345.081 47.1271 344.984 47.4074 344.838 47.6643L345.178 48.2249V48.3417H345.153ZM343.432 47.5943C343.82 47.5943 344.232 47.314 344.232 46.7534C344.232 46.1928 343.82 45.9125 343.432 45.9125C343.02 45.9125 342.632 46.1928 342.632 46.7534C342.632 47.314 343.02 47.5943 343.432 47.5943Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M347.376 47.3447C347.376 47.5534 347.618 47.6693 347.885 47.6693C348.128 47.6693 348.37 47.5534 348.37 47.391C348.37 47.0896 348.006 47.0896 347.788 47.0664C347.206 47.02 346.624 46.8114 346.624 46.1621C346.624 45.4897 347.206 45.165 347.861 45.165C348.467 45.165 349.098 45.4897 349.098 46.1621H348.298C348.298 45.9534 348.079 45.8607 347.861 45.8607C347.667 45.8607 347.424 45.907 347.424 46.1389C347.424 46.3476 347.618 46.394 347.909 46.4172C348.589 46.4635 349.171 46.6259 349.171 47.3679C349.171 48.0171 348.516 48.3417 347.861 48.3417C347.182 48.3417 346.527 48.0171 346.551 47.3447H347.376Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M351.767 45.9242H350.59V46.3679H351.669V47.0684H350.59V47.5588H351.792V48.2827H349.732V45.2236H351.742V45.9242H351.767Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M355.101 48.1659V48.2827H354.23L353.674 47.3253H353.239V48.2827H352.417V45.2236C352.901 45.2236 353.36 45.2236 353.819 45.2236C354.593 45.2236 354.955 45.7841 354.955 46.3211C354.955 46.6714 354.786 47.0217 354.472 47.2085L355.101 48.1659ZM353.819 46.6714C354.061 46.6714 354.158 46.4846 354.158 46.2744C354.158 46.0876 354.037 45.9008 353.819 45.9008H353.239V46.6714H353.819Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M356.753 46.9349L357.027 46.1379L357.424 45.2236H358.344V45.3408L356.927 48.3415H356.579L355.162 45.3408V45.2236H356.082L356.505 46.1379L356.753 46.9349Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M360.216 47.6338V48.2827H358.532V47.6338H358.953V45.8725H358.581V45.2236H360.192V45.8725H359.82V47.6338H360.216Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M362.245 45.2236C362.911 45.2236 363.651 45.5726 363.775 46.387H362.96C362.837 46.0613 362.59 45.9449 362.245 45.9449C361.752 45.9449 361.431 46.2707 361.431 46.7826C361.431 47.2247 361.752 47.6202 362.245 47.6202C362.59 47.6202 362.886 47.4573 362.96 47.1316H363.775C363.651 47.9925 362.936 48.3415 362.245 48.3415C361.406 48.3415 360.592 47.8063 360.592 46.7826C360.592 45.7355 361.406 45.2236 362.245 45.2236Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M366.305 45.9242H365.128V46.3679H366.207V47.0684H365.128V47.5588H366.329V48.2827H364.27V45.2236H366.28V45.9242H366.305Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M276.003 163.143H273.496C272.857 163.143 272.316 162.643 272.316 162.051V161.824C272.316 161.232 272.857 160.731 273.496 160.731H276.003C276.642 160.731 277.183 161.232 277.183 161.824V162.051C277.183 162.666 276.667 163.143 276.003 163.143Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M258.657 165.934C258.952 165.934 259.026 165.934 259.419 165.934C260.156 165.934 260.696 166.468 260.696 167.187C260.696 167.929 260.131 168.439 259.37 168.439C257.945 168.439 257.06 168.439 255.636 168.439C255.095 168.439 254.653 168.254 254.432 167.767C254.235 167.465 254.112 166.63 254.898 165.934C255.414 165.354 261.114 159.208 262.121 158.141C262.17 158.072 262.121 157.979 262.048 157.979C262.023 157.979 262.023 157.979 261.998 157.979C259.861 157.979 257.724 157.979 255.586 157.979C254.948 157.979 254.456 157.608 254.333 157.051C254.186 156.402 254.481 155.845 255.046 155.59C255.218 155.52 255.39 155.497 255.562 155.497C258.731 155.497 261.876 155.497 265.045 155.497C265.659 155.497 266.077 155.683 266.322 156.216C266.642 156.889 266.298 157.353 266.077 157.654C265.634 158.188 261.139 162.965 258.657 165.656C258.633 165.679 258.608 165.725 258.559 165.749C258.51 165.818 258.559 165.934 258.657 165.934Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M265.335 168.439H262.827C262.188 168.439 261.647 167.926 261.647 167.32V167.087C261.647 166.481 262.188 165.968 262.827 165.968H265.335C265.974 165.968 266.514 166.481 266.514 167.087V167.32C266.514 167.949 265.974 168.439 265.335 168.439Z"
            fill="#141B4D"
            fillOpacity="0.7"
            stroke="#767676"
            strokeWidth="0.0802224"
            strokeMiterlimit="10"
          />
          <path
            d="M268.821 161.932C268.821 160.173 268.821 158.436 268.821 156.677C268.821 155.982 269.337 155.496 270.074 155.496C273.266 155.496 276.459 155.496 279.651 155.496C280.388 155.496 280.904 156.005 280.904 156.723C280.904 157.441 280.388 157.973 279.651 157.973C276.999 157.973 274.371 157.973 271.719 157.973C271.523 157.973 271.473 158.02 271.473 158.205C271.473 160.705 271.473 163.206 271.473 165.706C271.473 165.915 271.547 165.938 271.719 165.938C274.347 165.938 276.999 165.938 279.627 165.938C280.216 165.938 280.683 166.262 280.854 166.748C281.051 167.327 280.83 167.906 280.363 168.23C280.142 168.369 279.872 168.438 279.602 168.438C276.459 168.438 273.291 168.438 270.147 168.438C269.337 168.438 268.846 167.929 268.846 167.165C268.821 165.405 268.821 163.669 268.821 161.932Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M255.969 173.319V173.968H254.284V173.319H254.705V171.558H254.334V170.909H255.944V171.558H255.572V173.319H255.969Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M259.751 174.027H259L258.879 173.817C258.612 173.957 258.297 174.027 258.006 174.027C257.182 174.004 256.406 173.443 256.406 172.439C256.406 171.481 257.061 170.851 258.055 170.851C258.879 170.851 259.679 171.388 259.679 172.439C259.679 172.813 259.582 173.093 259.436 173.35L259.776 173.91V174.027H259.751ZM258.03 173.28C258.418 173.28 258.83 173 258.83 172.439C258.83 171.878 258.418 171.598 258.03 171.598C257.618 171.598 257.23 171.878 257.23 172.439C257.23 173 257.618 173.28 258.03 173.28Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M261.974 173.03C261.974 173.239 262.216 173.355 262.483 173.355C262.726 173.355 262.969 173.239 262.969 173.077C262.969 172.775 262.605 172.775 262.386 172.752C261.804 172.706 261.222 172.497 261.222 171.848C261.222 171.175 261.804 170.851 262.459 170.851C263.066 170.851 263.696 171.175 263.696 171.848H262.896C262.896 171.639 262.677 171.546 262.459 171.546C262.265 171.546 262.022 171.593 262.022 171.824C262.022 172.033 262.216 172.08 262.508 172.103C263.187 172.149 263.769 172.311 263.769 173.053C263.769 173.703 263.114 174.027 262.459 174.027C261.78 174.027 261.125 173.703 261.149 173.03H261.974Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M266.365 171.61H265.188V172.053H266.267V172.754H265.188V173.244H266.389V173.968H264.33V170.909H266.34V171.61H266.365Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M269.698 173.851V173.968H268.828L268.272 173.011H267.836V173.968H267.015V170.909C267.498 170.909 267.957 170.909 268.417 170.909C269.19 170.909 269.553 171.47 269.553 172.007C269.553 172.357 269.383 172.707 269.069 172.894L269.698 173.851ZM268.417 172.357C268.658 172.357 268.755 172.17 268.755 171.96C268.755 171.773 268.634 171.586 268.417 171.586H267.836V172.357H268.417Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M271.351 172.62L271.624 171.823L272.022 170.909H272.942V171.026L271.525 174.027H271.177L269.76 171.026V170.909H270.68L271.102 171.823L271.351 172.62Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M274.814 173.319V173.968H273.129V173.319H273.551V171.558H273.179V170.909H274.789V171.558H274.418V173.319H274.814Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M276.842 170.909C277.508 170.909 278.248 171.258 278.372 172.073H277.558C277.434 171.747 277.188 171.63 276.842 171.63C276.349 171.63 276.028 171.956 276.028 172.468C276.028 172.91 276.349 173.306 276.842 173.306C277.188 173.306 277.484 173.143 277.558 172.817H278.372C278.248 173.678 277.533 174.027 276.842 174.027C276.004 174.027 275.189 173.492 275.189 172.468C275.189 171.421 276.004 170.909 276.842 170.909Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M280.903 171.61H279.726V172.053H280.805V172.754H279.726V173.244H280.927V173.968H278.868V170.909H280.878V171.61H280.903Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M361.405 163.144H358.898C358.259 163.144 357.718 162.644 357.718 162.052V161.825C357.718 161.233 358.259 160.732 358.898 160.732H361.405C362.044 160.732 362.585 161.233 362.585 161.825V162.052C362.585 162.667 362.069 163.144 361.405 163.144Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M344.059 165.934C344.353 165.934 344.427 165.934 344.82 165.934C345.557 165.934 346.098 166.468 346.098 167.187C346.098 167.929 345.533 168.439 344.771 168.439C343.346 168.439 342.462 168.439 341.037 168.439C340.496 168.439 340.054 168.254 339.833 167.767C339.637 167.465 339.514 166.63 340.3 165.934C340.816 165.354 346.515 159.208 347.523 158.141C347.572 158.072 347.523 157.979 347.449 157.979C347.424 157.979 347.424 157.979 347.4 157.979C345.262 157.979 343.125 157.979 340.988 157.979C340.349 157.979 339.858 157.608 339.735 157.051C339.587 156.402 339.882 155.845 340.447 155.59C340.619 155.52 340.791 155.497 340.963 155.497C344.132 155.497 347.277 155.497 350.446 155.497C351.06 155.497 351.478 155.683 351.724 156.216C352.043 156.889 351.699 157.353 351.478 157.654C351.036 158.188 346.54 162.965 344.059 165.656C344.034 165.679 344.01 165.725 343.96 165.749C343.911 165.818 343.96 165.934 344.059 165.934Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M350.736 168.439H348.229C347.59 168.439 347.049 167.926 347.049 167.32V167.087C347.049 166.481 347.59 165.968 348.229 165.968H350.736C351.375 165.968 351.916 166.481 351.916 167.087V167.32C351.916 167.949 351.375 168.439 350.736 168.439Z"
            fill="#141B4D"
            fillOpacity="0.7"
            stroke="#767676"
            strokeWidth="0.0802224"
            strokeMiterlimit="10"
          />
          <path
            d="M354.224 161.932C354.224 160.173 354.224 158.436 354.224 156.677C354.224 155.982 354.739 155.496 355.476 155.496C358.669 155.496 361.861 155.496 365.053 155.496C365.79 155.496 366.306 156.005 366.306 156.723C366.306 157.441 365.79 157.973 365.053 157.973C362.401 157.973 359.774 157.973 357.121 157.973C356.925 157.973 356.876 158.02 356.876 158.205C356.876 160.705 356.876 163.206 356.876 165.706C356.876 165.915 356.95 165.938 357.121 165.938C359.749 165.938 362.401 165.938 365.029 165.938C365.618 165.938 366.085 166.262 366.257 166.748C366.453 167.327 366.232 167.906 365.766 168.23C365.545 168.369 365.274 168.438 365.004 168.438C361.861 168.438 358.693 168.438 355.55 168.438C354.739 168.438 354.248 167.929 354.248 167.165C354.224 165.405 354.224 163.669 354.224 161.932Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M341.371 173.319V173.968H339.687V173.319H340.108V171.558H339.736V170.909H341.346V171.558H340.975V173.319H341.371Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M345.153 174.027H344.402L344.281 173.817C344.014 173.957 343.699 174.027 343.408 174.027C342.584 174.004 341.808 173.443 341.808 172.439C341.808 171.481 342.463 170.851 343.456 170.851C344.281 170.851 345.081 171.388 345.081 172.439C345.081 172.813 344.984 173.093 344.838 173.35L345.178 173.91V174.027H345.153ZM343.432 173.28C343.82 173.28 344.232 173 344.232 172.439C344.232 171.878 343.82 171.598 343.432 171.598C343.02 171.598 342.632 171.878 342.632 172.439C342.632 173 343.02 173.28 343.432 173.28Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M347.376 173.03C347.376 173.239 347.618 173.355 347.885 173.355C348.128 173.355 348.37 173.239 348.37 173.077C348.37 172.775 348.006 172.775 347.788 172.752C347.206 172.706 346.624 172.497 346.624 171.848C346.624 171.175 347.206 170.851 347.861 170.851C348.467 170.851 349.098 171.175 349.098 171.848H348.298C348.298 171.639 348.079 171.546 347.861 171.546C347.667 171.546 347.424 171.593 347.424 171.824C347.424 172.033 347.618 172.08 347.909 172.103C348.589 172.149 349.171 172.311 349.171 173.053C349.171 173.703 348.516 174.027 347.861 174.027C347.182 174.027 346.527 173.703 346.551 173.03H347.376Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M351.767 171.61H350.59V172.053H351.669V172.754H350.59V173.244H351.792V173.968H349.732V170.909H351.742V171.61H351.767Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M355.101 173.851V173.968H354.23L353.674 173.011H353.239V173.968H352.417V170.909C352.901 170.909 353.36 170.909 353.819 170.909C354.593 170.909 354.955 171.47 354.955 172.007C354.955 172.357 354.786 172.707 354.472 172.894L355.101 173.851ZM353.819 172.357C354.061 172.357 354.158 172.17 354.158 171.96C354.158 171.773 354.037 171.586 353.819 171.586H353.239V172.357H353.819Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M356.753 172.62L357.027 171.823L357.424 170.909H358.344V171.026L356.927 174.027H356.579L355.162 171.026V170.909H356.082L356.505 171.823L356.753 172.62Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M360.216 173.319V173.968H358.532V173.319H358.953V171.558H358.581V170.909H360.192V171.558H359.82V173.319H360.216Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M362.245 170.909C362.911 170.909 363.651 171.258 363.775 172.073H362.96C362.837 171.747 362.59 171.63 362.245 171.63C361.752 171.63 361.431 171.956 361.431 172.468C361.431 172.91 361.752 173.306 362.245 173.306C362.59 173.306 362.886 173.143 362.96 172.817H363.775C363.651 173.678 362.936 174.027 362.245 174.027C361.406 174.027 360.592 173.492 360.592 172.468C360.592 171.421 361.406 170.909 362.245 170.909Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M366.305 171.61H365.128V172.053H366.207V172.754H365.128V173.244H366.329V173.968H364.27V170.909H366.28V171.61H366.305Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <rect x="401.724" width="4.02842" height="268.293" fill="white" />
          <rect
            width="180.473"
            height="4.02842"
            transform="matrix(0.938191 -0.346117 0 1 56.0859 326.729)"
            fill="white"
          />
          <g
            filter="url(#filter4_i)"
            className="casement"
            data-index="0"
            onClick={onCasementClick(0)}
          >
            <rect
              width="74.123"
              height="115.213"
              transform="matrix(0.938191 -0.346117 0 1 70.4478 196.55)"
              fill="#B8C1FD"
              fillOpacity="0.4"
            />
          </g>
          <rect
            x="0.377943"
            y="0.263412"
            width="73.3173"
            height="114.407"
            transform="matrix(0.938191 -0.346117 0 1 70.4711 196.681)"
            stroke="#979797"
            strokeWidth="0.805684"
          />
          <g
            filter="url(#filter5_i)"
            className="casement"
            data-index="0"
            onClick={onCasementClick(0)}
          >
            <rect
              width="74.123"
              height="116.019"
              transform="matrix(0.938191 -0.346117 0 1 150.572 166.185)"
              fill="#B8C1FD"
              fillOpacity="0.4"
            />
          </g>
          <rect
            x="0.377943"
            y="0.263412"
            width="73.3173"
            height="115.213"
            transform="matrix(0.938191 -0.346117 0 1 150.595 166.315)"
            stroke="#979797"
            strokeWidth="0.805684"
          />
          <g
            filter="url(#filter6_i)"
            className="casement"
            data-index="0"
            onClick={onCasementClick(0)}
          >
            <rect
              width="74.123"
              height="114.407"
              transform="matrix(0.938191 -0.346117 0 1 70.4478 70.8623)"
              fill="#B8C1FD"
              fillOpacity="0.4"
            />
          </g>
          <rect
            x="0.377943"
            y="0.263412"
            width="73.3173"
            height="113.602"
            transform="matrix(0.938191 -0.346117 0 1 70.4711 70.9931)"
            stroke="#979797"
            strokeWidth="0.805684"
          />
          <g
            filter="url(#filter7_i)"
            className="casement"
            data-index="0"
            onClick={onCasementClick(1)}
          >
            <rect
              width="74.123"
              height="114.407"
              transform="matrix(0.938191 -0.346117 0 1 150.572 41.3037)"
              fill="#B8C1FD"
              fillOpacity="0.4"
            />
          </g>
          <rect
            x="0.377943"
            y="0.263412"
            width="73.3173"
            height="113.602"
            transform="matrix(0.938191 -0.346117 0 1 150.595 41.4345)"
            stroke="#979797"
            strokeWidth="0.805684"
          />
          <path
            d="M112.745 78.86L110.393 79.8353C109.793 80.0838 109.286 79.8369 109.286 79.2964V79.0886C109.286 78.5481 109.793 77.8805 110.393 77.6319L112.745 76.6567C113.344 76.4081 113.852 76.655 113.852 77.1955V77.4033C113.852 77.9646 113.367 78.6019 112.745 78.86Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M96.4715 87.6977C96.7481 87.5789 96.8172 87.5492 97.186 87.3907C97.8775 87.0937 98.3845 87.3808 98.3845 88.0614C98.3845 88.7639 97.8544 89.4745 97.1399 89.7815C95.8031 90.3557 94.9733 90.7122 93.6365 91.2864C93.1294 91.5043 92.7145 91.5068 92.5071 91.1349C92.3227 90.9288 92.2075 90.188 92.945 89.2125C93.429 88.4558 98.7764 80.3412 99.7214 78.9254C99.7675 78.8398 99.7214 78.7718 99.6522 78.8015C99.6292 78.8114 99.6292 78.8114 99.6061 78.8213C97.6009 79.6826 95.5956 80.544 93.5904 81.4054C92.9911 81.6628 92.5301 81.5096 92.4149 81.0323C92.2766 80.477 92.5532 79.8313 93.0833 79.3621C93.2447 79.2269 93.406 79.1357 93.5673 79.0663C96.5406 77.7891 99.4909 76.5218 102.464 75.2446C103.04 74.997 103.432 75.0043 103.663 75.4102C103.962 75.9182 103.64 76.4958 103.432 76.8703C103.017 77.5535 98.7994 83.8877 96.4715 87.4342C96.4484 87.4661 96.4254 87.5199 96.3793 87.5617C96.3332 87.6473 96.3793 87.7373 96.4715 87.6977Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M102.736 87.8464L100.383 88.8228C99.7837 89.0717 99.2764 88.8133 99.2764 88.259V88.0458C99.2764 87.4915 99.7837 86.8119 100.383 86.563L102.736 85.5865C103.335 85.3376 103.842 85.5961 103.842 86.1504V86.3636C103.842 86.9392 103.335 87.5975 102.736 87.8464Z"
            fill="#141B4D"
            fillOpacity="0.7"
            stroke="#767676"
            strokeWidth="0.0802224"
            strokeMiterlimit="10"
          />
          <path
            d="M106.007 80.6402C106.007 78.9744 106.007 77.3305 106.007 75.6647C106.007 75.0072 106.491 74.339 107.182 74.042C110.177 72.7551 113.173 71.4683 116.168 70.1814C116.859 69.8844 117.343 70.1587 117.343 70.8382C117.343 71.5177 116.859 72.2297 116.168 72.5266C113.679 73.5957 111.214 74.6549 108.726 75.724C108.542 75.8032 108.496 75.8669 108.496 76.0422C108.496 78.4094 108.496 80.7766 108.496 83.1437C108.496 83.341 108.565 83.3332 108.726 83.2639C111.191 82.2047 113.679 81.1356 116.145 80.0764C116.698 79.8389 117.135 79.9576 117.297 80.3486C117.481 80.8174 117.274 81.4544 116.836 81.9494C116.629 82.17 116.375 82.3446 116.122 82.4535C113.173 83.7206 110.201 84.9976 107.251 86.2647C106.491 86.5913 106.03 86.3071 106.03 85.5838C106.007 83.9279 106.007 82.284 106.007 80.6402Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M93.9492 96.0418V96.6676L92.3687 97.3595V96.7337L92.7638 96.5607V94.862L92.4151 95.0147V94.3888L93.926 93.7275V94.3533L93.5773 94.506V96.2046L93.9492 96.0418Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M97.4983 95.3437L96.7933 95.645L96.6796 95.4956C96.4294 95.7346 96.1337 95.9269 95.8608 96.0436C95.0876 96.3521 94.3599 96.1352 94.3599 95.1892C94.3599 94.2873 94.9739 93.4309 95.9063 93.0324C96.6796 92.7019 97.43 92.8871 97.43 93.877C97.43 94.2289 97.3391 94.5318 97.2026 94.8321L97.521 95.224V95.334L97.4983 95.3437ZM95.8836 95.3299C96.2475 95.1744 96.6341 94.7451 96.6341 94.2172C96.6341 93.6892 96.2475 93.5905 95.8836 93.746C95.497 93.9113 95.1331 94.3308 95.1331 94.8587C95.1331 95.3867 95.497 95.4952 95.8836 95.3299Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M99.5826 93.7607C99.5826 93.9592 99.8101 93.9713 100.061 93.8632C100.288 93.7649 100.516 93.5563 100.516 93.4019C100.516 93.1152 100.174 93.2626 99.9695 93.3289C99.4232 93.5207 98.877 93.558 98.877 92.9403C98.877 92.3006 99.4232 91.7559 100.038 91.4906C100.607 91.2449 101.198 91.2982 101.198 91.938L100.447 92.2622C100.447 92.0637 100.243 92.0639 100.038 92.1524C99.8557 92.231 99.6281 92.3734 99.6281 92.594C99.6281 92.7925 99.8102 92.758 100.083 92.6621C100.721 92.4311 101.267 92.3497 101.267 93.0556C101.267 93.6732 100.652 94.2474 100.038 94.5127C99.4005 94.7879 98.786 94.7444 98.8087 94.0948L99.5826 93.7607Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M103.702 90.7933L102.598 91.2734V91.6986L103.61 91.2585V91.9298L102.598 92.3699V92.8399L103.725 92.3498V93.0435L101.793 93.8837V90.9521L103.679 90.132V90.8033L103.702 90.7933Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M106.83 91.76V91.8709L106.014 92.2225L105.492 91.5385L105.084 91.7143V92.623L104.313 92.9552V90.0518C104.767 89.8564 105.197 89.6708 105.628 89.4852C106.354 89.1726 106.694 89.558 106.694 90.0678C106.694 90.4002 106.535 90.801 106.241 91.1053L106.83 91.76ZM105.628 90.8593C105.855 90.7616 105.946 90.5453 105.946 90.3458C105.946 90.1685 105.832 90.04 105.628 90.1279L105.084 90.3624V91.0938L105.628 90.8593Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M108.381 90.0973L108.638 89.2356L109.011 88.2134L109.874 87.8439V87.9545L108.544 91.3542L108.218 91.494L106.888 89.2326V89.1221L107.751 88.7526L108.148 89.4453L108.381 90.0973Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M111.629 89.5203V90.1462L110.049 90.838V90.2122L110.444 90.0392V88.3406L110.095 88.4932V87.8673L111.606 87.206V87.8319L111.258 87.9845V89.6831L111.629 89.5203Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M113.533 86.5783C114.157 86.3108 114.852 86.3428 114.967 87.0615L114.204 87.3884C114.088 87.1307 113.857 87.12 113.533 87.2587C113.07 87.4569 112.769 87.893 112.769 88.3759C112.769 88.7929 113.07 89.0372 113.533 88.8391C113.857 88.7003 114.134 88.4278 114.204 88.0908L114.967 87.7638C114.852 88.6255 114.181 89.242 113.533 89.5195C112.746 89.8563 111.982 89.6785 111.982 88.7128C111.982 87.7251 112.746 86.9152 113.533 86.5783Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M117.342 85.7611L116.238 86.2412V86.6664L117.25 86.2263V86.8976L116.238 87.3377V87.8076L117.365 87.3175V88.0113L115.433 88.8514V85.9199L117.319 85.0997V85.7711L117.342 85.7611Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M192.868 49.3014L190.516 50.2767C189.916 50.5252 189.409 50.2783 189.409 49.7378V49.53C189.409 48.9895 189.916 48.3219 190.516 48.0733L192.868 47.0981C193.467 46.8495 193.975 47.0964 193.975 47.6369V47.8447C193.975 48.406 193.491 49.0433 192.868 49.3014Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M176.595 58.1381C176.871 58.0193 176.94 57.9896 177.309 57.8312C178.001 57.5341 178.508 57.8212 178.508 58.5018C178.508 59.2043 177.977 59.915 177.263 60.2219C175.926 60.7962 175.096 61.1526 173.76 61.7269C173.252 61.9447 172.838 61.9473 172.63 61.5754C172.446 61.3692 172.331 60.6284 173.068 59.653C173.552 58.8962 178.899 50.7816 179.844 49.3659C179.891 49.2802 179.844 49.2122 179.775 49.2419C179.752 49.2518 179.752 49.2518 179.729 49.2617C177.724 50.1231 175.719 50.9845 173.713 51.8459C173.114 52.1033 172.653 51.95 172.538 51.4727C172.4 50.9174 172.676 50.2717 173.206 49.8025C173.368 49.6673 173.529 49.5761 173.69 49.5068C176.664 48.2295 179.614 46.9622 182.587 45.685C183.163 45.4375 183.555 45.4448 183.786 45.8507C184.085 46.3586 183.763 46.9363 183.555 47.3108C183.14 47.9939 178.922 54.3281 176.595 57.8747C176.571 57.9065 176.548 57.9603 176.502 58.0021C176.456 58.0877 176.502 58.1777 176.595 58.1381Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M182.859 58.2868L180.506 59.2633C179.907 59.5122 179.399 59.2537 179.399 58.6994V58.4862C179.399 57.9319 179.907 57.2523 180.506 57.0034L182.859 56.027C183.458 55.7781 183.966 56.0365 183.966 56.5908V56.804C183.966 57.3796 183.458 58.0379 182.859 58.2868Z"
            fill="#141B4D"
            fillOpacity="0.7"
            stroke="#767676"
            strokeWidth="0.0802224"
            strokeMiterlimit="10"
          />
          <path
            d="M186.13 51.0806C186.13 49.4148 186.13 47.7709 186.13 46.1051C186.13 45.4476 186.614 44.7794 187.305 44.4825C190.301 43.1956 193.296 41.9087 196.291 40.6218C196.982 40.3248 197.466 40.5992 197.466 41.2786C197.466 41.9581 196.982 42.6701 196.291 42.9671C193.803 44.0362 191.337 45.0954 188.849 46.1645C188.665 46.2437 188.619 46.3073 188.619 46.4826C188.619 48.8498 188.619 51.217 188.619 53.5842C188.619 53.7814 188.688 53.7737 188.849 53.7044C191.314 52.6452 193.803 51.5761 196.268 50.5169C196.821 50.2793 197.258 50.3981 197.42 50.789C197.604 51.2578 197.397 51.8949 196.959 52.3898C196.752 52.6104 196.498 52.785 196.245 52.8939C193.296 54.161 190.324 55.438 187.375 56.7051C186.614 57.0317 186.153 56.7475 186.153 56.0242C186.13 54.3683 186.13 52.7245 186.13 51.0806Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M174.072 66.4832V67.1091L172.492 67.8009V67.1751L172.887 67.0021V65.3034L172.538 65.4561V64.8302L174.049 64.1689V64.7947L173.7 64.9474V66.646L174.072 66.4832Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M177.621 65.7841L176.916 66.0855L176.803 65.9361C176.552 66.175 176.257 66.3673 175.984 66.484C175.211 66.7925 174.483 66.5756 174.483 65.6297C174.483 64.7277 175.097 63.8713 176.029 63.4728C176.803 63.1423 177.553 63.3275 177.553 64.3174C177.553 64.6694 177.462 64.9722 177.326 65.2725L177.644 65.6644V65.7744L177.621 65.7841ZM176.007 65.7703C176.371 65.6148 176.757 65.1856 176.757 64.6576C176.757 64.1297 176.371 64.0309 176.007 64.1865C175.62 64.3517 175.256 64.7712 175.256 65.2992C175.256 65.8271 175.62 65.9356 176.007 65.7703Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M179.706 64.2021C179.706 64.4007 179.933 64.4127 180.184 64.3046C180.411 64.2063 180.639 63.9977 180.639 63.8433C180.639 63.5566 180.297 63.704 180.093 63.7703C179.546 63.9621 179 63.9994 179 63.3817C179 62.742 179.546 62.1973 180.161 61.932C180.73 61.6863 181.322 61.7396 181.322 62.3794L180.57 62.7037C180.57 62.5051 180.366 62.5053 180.161 62.5938C179.979 62.6724 179.751 62.8148 179.751 63.0354C179.751 63.2339 179.933 63.1994 180.206 63.1035C180.844 62.8725 181.39 62.7911 181.39 63.497C181.39 64.1147 180.775 64.6888 180.161 64.9541C179.524 65.2293 178.909 65.1858 178.932 64.5362L179.706 64.2021Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M183.825 61.2337L182.721 61.7138V62.139L183.733 61.6989V62.3703L182.721 62.8104V63.2803L183.848 62.7902V63.4839L181.917 64.3241V61.3926L183.802 60.5724V61.2437L183.825 61.2337Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M186.953 62.2005V62.3113L186.137 62.6629L185.615 61.9789L185.207 62.1548V63.0635L184.436 63.3956V60.4922C184.89 60.2968 185.32 60.1112 185.751 59.9256C186.477 59.613 186.817 59.9984 186.817 60.5082C186.817 60.8406 186.658 61.2415 186.364 61.5458L186.953 62.2005ZM185.751 61.2997C185.978 61.2021 186.069 60.9857 186.069 60.7862C186.069 60.6089 185.955 60.4804 185.751 60.5684L185.207 60.8028V61.5342L185.751 61.2997Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M188.504 60.5377L188.76 59.676L189.133 58.6538L189.996 58.2843V58.3949L188.667 61.7947L188.34 61.9345L187.011 59.6731V59.5625L187.874 59.193L188.27 59.8857L188.504 60.5377Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M191.752 59.9608V60.5866L190.172 61.2784V60.6526L190.567 60.4796V58.781L190.218 58.9336V58.3078L191.729 57.6465V58.2723L191.381 58.4249V60.1236L191.752 59.9608Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M193.657 57.0188C194.281 56.7512 194.976 56.7832 195.091 57.5019L194.328 57.8289C194.212 57.5711 193.981 57.5605 193.657 57.6992C193.194 57.8973 192.893 58.3334 192.893 58.8163C192.893 59.2333 193.194 59.4777 193.657 59.2795C193.981 59.1408 194.258 58.8682 194.328 58.5312L195.091 58.2043C194.976 59.0659 194.305 59.6825 193.657 59.9599C192.87 60.2968 192.106 60.1189 192.106 59.1532C192.106 58.1655 192.87 57.3556 193.657 57.0188Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M197.464 56.2015L196.361 56.6816V57.1068L197.372 56.6667V57.338L196.361 57.7781V58.2481L197.487 57.758V58.4517L195.556 59.2919V56.3604L197.441 55.5402V56.2115L197.464 56.2015Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M112.745 203.74L110.393 204.715C109.793 204.964 109.286 204.717 109.286 204.176V203.968C109.286 203.428 109.793 202.76 110.393 202.512L112.745 201.537C113.344 201.288 113.852 201.535 113.852 202.075V202.283C113.852 202.844 113.367 203.482 112.745 203.74Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M96.4715 212.578C96.7481 212.459 96.8172 212.429 97.186 212.271C97.8775 211.974 98.3845 212.261 98.3845 212.941C98.3845 213.644 97.8544 214.354 97.1399 214.661C95.8031 215.236 94.9733 215.592 93.6365 216.166C93.1294 216.384 92.7145 216.387 92.5071 216.015C92.3227 215.809 92.2075 215.068 92.945 214.092C93.429 213.336 98.7764 205.221 99.7214 203.805C99.7675 203.72 99.7214 203.652 99.6522 203.681C99.6292 203.691 99.6292 203.691 99.6061 203.701C97.6009 204.563 95.5956 205.424 93.5904 206.285C92.9911 206.543 92.5301 206.39 92.4149 205.912C92.2766 205.357 92.5532 204.711 93.0833 204.242C93.2447 204.107 93.406 204.016 93.5673 203.946C96.5406 202.669 99.4909 201.402 102.464 200.124C103.04 199.877 103.432 199.884 103.663 200.29C103.962 200.798 103.64 201.376 103.432 201.75C103.017 202.433 98.7994 208.768 96.4715 212.314C96.4484 212.346 96.4254 212.4 96.3793 212.442C96.3332 212.527 96.3793 212.617 96.4715 212.578Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M102.736 212.726L100.383 213.703C99.7837 213.952 99.2764 213.693 99.2764 213.139V212.926C99.2764 212.371 99.7837 211.692 100.383 211.443L102.736 210.466C103.335 210.218 103.842 210.476 103.842 211.03V211.243C103.842 211.819 103.335 212.477 102.736 212.726Z"
            fill="#141B4D"
            fillOpacity="0.7"
            stroke="#767676"
            strokeWidth="0.0802224"
            strokeMiterlimit="10"
          />
          <path
            d="M106.007 205.521C106.007 203.855 106.007 202.211 106.007 200.546C106.007 199.888 106.491 199.22 107.182 198.923C110.177 197.636 113.173 196.349 116.168 195.062C116.859 194.765 117.343 195.04 117.343 195.719C117.343 196.399 116.859 197.111 116.168 197.407C113.68 198.477 111.214 199.536 108.726 200.605C108.542 200.684 108.496 200.748 108.496 200.923C108.496 203.29 108.496 205.657 108.496 208.025C108.496 208.222 108.565 208.214 108.726 208.145C111.191 207.086 113.679 206.016 116.145 204.957C116.698 204.72 117.135 204.838 117.297 205.229C117.481 205.698 117.274 206.335 116.836 206.83C116.629 207.051 116.375 207.225 116.122 207.334C113.173 208.601 110.201 209.878 107.251 211.146C106.491 211.472 106.03 211.188 106.03 210.465C106.007 208.809 106.007 207.165 106.007 205.521Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M93.9492 220.924V221.549L92.3687 222.241V221.616L92.7638 221.443V219.744L92.4151 219.896V219.271L93.926 218.609V219.235L93.5773 219.388V221.086L93.9492 220.924Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M97.4983 220.224L96.7933 220.525L96.6796 220.376C96.4294 220.614 96.1337 220.807 95.8608 220.923C95.0876 221.232 94.3599 221.015 94.3599 220.069C94.3599 219.167 94.9739 218.311 95.9063 217.912C96.6796 217.582 97.43 217.767 97.43 218.757C97.43 219.109 97.3391 219.412 97.2026 219.712L97.521 220.104V220.214L97.4983 220.224ZM95.8836 220.21C96.2475 220.054 96.6341 219.625 96.6341 219.097C96.6341 218.569 96.2475 218.47 95.8836 218.626C95.497 218.791 95.1331 219.211 95.1331 219.739C95.1331 220.267 95.497 220.375 95.8836 220.21Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M99.5826 218.641C99.5826 218.839 99.8101 218.851 100.061 218.743C100.288 218.645 100.516 218.436 100.516 218.282C100.516 217.995 100.174 218.142 99.9695 218.209C99.4232 218.401 98.877 218.438 98.877 217.82C98.877 217.18 99.4232 216.636 100.038 216.37C100.607 216.125 101.198 216.178 101.198 216.818L100.447 217.142C100.447 216.944 100.243 216.944 100.038 217.032C99.8557 217.111 99.6281 217.253 99.6281 217.474C99.6281 217.672 99.8102 217.638 100.083 217.542C100.721 217.311 101.267 217.23 101.267 217.935C101.267 218.553 100.652 219.127 100.038 219.393C99.4005 219.668 98.786 219.624 98.8087 218.975L99.5826 218.641Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M103.702 215.673L102.598 216.153V216.578L103.61 216.138V216.81L102.598 217.25V217.72L103.725 217.23V217.923L101.793 218.764V215.832L103.679 215.012V215.683L103.702 215.673Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M106.83 216.64V216.751L106.014 217.102L105.492 216.418L105.084 216.594V217.503L104.313 217.835V214.932C104.767 214.736 105.197 214.551 105.628 214.365C106.354 214.052 106.694 214.438 106.694 214.948C106.694 215.28 106.535 215.681 106.241 215.985L106.83 216.64ZM105.628 215.739C105.855 215.642 105.946 215.425 105.946 215.226C105.946 215.048 105.832 214.92 105.628 215.008L105.084 215.242V215.974L105.628 215.739Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M108.381 214.977L108.638 214.115L109.011 213.093L109.874 212.724V212.834L108.544 216.234L108.218 216.374L106.888 214.113V214.002L107.751 213.632L108.148 214.325L108.381 214.977Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M111.629 214.401V215.027L110.049 215.719V215.093L110.444 214.92V213.221L110.095 213.374V212.748L111.606 212.087V212.713L111.258 212.865V214.564L111.629 214.401Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M113.533 211.459C114.157 211.192 114.852 211.224 114.967 211.942L114.204 212.269C114.088 212.012 113.857 212.001 113.533 212.14C113.07 212.338 112.769 212.774 112.769 213.257C112.769 213.674 113.07 213.918 113.533 213.72C113.857 213.581 114.134 213.309 114.204 212.972L114.967 212.645C114.852 213.506 114.181 214.123 113.533 214.4C112.746 214.737 111.982 214.559 111.982 213.594C111.982 212.606 112.746 211.796 113.533 211.459Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M117.342 210.642L116.238 211.122V211.547L117.25 211.107V211.778L116.238 212.219V212.689L117.365 212.198V212.892L115.433 213.732V210.801L117.319 209.981V210.652L117.342 210.642Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M192.868 174.183L190.516 175.158C189.916 175.407 189.409 175.16 189.409 174.62V174.412C189.409 173.871 189.916 173.204 190.516 172.955L192.868 171.98C193.467 171.731 193.975 171.978 193.975 172.519V172.727C193.975 173.288 193.491 173.925 192.868 174.183Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M176.595 183.02C176.871 182.901 176.94 182.871 177.309 182.713C178.001 182.416 178.508 182.703 178.508 183.384C178.508 184.086 177.977 184.797 177.263 185.104C175.926 185.678 175.096 186.034 173.76 186.609C173.252 186.827 172.838 186.829 172.63 186.457C172.446 186.251 172.331 185.51 173.068 184.535C173.552 183.778 178.899 175.663 179.844 174.248C179.891 174.162 179.844 174.094 179.775 174.124C179.752 174.134 179.752 174.134 179.729 174.144C177.724 175.005 175.719 175.866 173.713 176.728C173.114 176.985 172.653 176.832 172.538 176.355C172.4 175.799 172.676 175.154 173.206 174.684C173.368 174.549 173.529 174.458 173.69 174.389C176.664 173.111 179.614 171.844 182.587 170.567C183.163 170.319 183.555 170.327 183.786 170.733C184.085 171.24 183.763 171.818 183.555 172.193C183.14 172.876 178.922 179.21 176.595 182.757C176.571 182.788 176.548 182.842 176.502 182.884C176.456 182.97 176.502 183.06 176.595 183.02Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M182.859 183.168L180.506 184.144C179.907 184.393 179.399 184.135 179.399 183.58V183.367C179.399 182.813 179.907 182.133 180.506 181.884L182.859 180.908C183.458 180.659 183.966 180.917 183.966 181.472V181.685C183.966 182.26 183.458 182.919 182.859 183.168Z"
            fill="#141B4D"
            fillOpacity="0.7"
            stroke="#767676"
            strokeWidth="0.0802224"
            strokeMiterlimit="10"
          />
          <path
            d="M186.13 175.962C186.13 174.297 186.13 172.653 186.13 170.987C186.13 170.329 186.614 169.661 187.305 169.364C190.301 168.077 193.296 166.791 196.291 165.504C196.982 165.207 197.466 165.481 197.466 166.16C197.466 166.84 196.982 167.552 196.291 167.849C193.803 168.918 191.337 169.977 188.849 171.046C188.665 171.125 188.619 171.189 188.619 171.364C188.619 173.732 188.619 176.099 188.619 178.466C188.619 178.663 188.688 178.655 188.849 178.586C191.314 177.527 193.803 176.458 196.268 175.399C196.821 175.161 197.258 175.28 197.42 175.671C197.604 176.14 197.397 176.777 196.959 177.272C196.752 177.492 196.498 177.667 196.245 177.776C193.296 179.043 190.324 180.32 187.375 181.587C186.614 181.914 186.153 181.629 186.153 180.906C186.13 179.25 186.13 177.606 186.13 175.962Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M174.072 191.365V191.991L172.492 192.683V192.057L172.887 191.884V190.185L172.538 190.338V189.712L174.049 189.051V189.677L173.7 189.829V191.528L174.072 191.365Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M177.621 190.666L176.916 190.967L176.803 190.818C176.552 191.057 176.257 191.249 175.984 191.366C175.211 191.674 174.483 191.457 174.483 190.512C174.483 189.61 175.097 188.753 176.029 188.355C176.803 188.024 177.553 188.209 177.553 189.199C177.553 189.551 177.462 189.854 177.326 190.154L177.644 190.546V190.656L177.621 190.666ZM176.007 190.652C176.371 190.497 176.757 190.067 176.757 189.539C176.757 189.011 176.371 188.913 176.007 189.068C175.62 189.234 175.256 189.653 175.256 190.181C175.256 190.709 175.62 190.817 176.007 190.652Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M179.706 189.083C179.706 189.282 179.933 189.294 180.184 189.185C180.411 189.087 180.639 188.879 180.639 188.724C180.639 188.437 180.297 188.585 180.093 188.651C179.546 188.843 179 188.88 179 188.263C179 187.623 179.546 187.078 180.161 186.813C180.73 186.567 181.322 186.62 181.322 187.26L180.57 187.585C180.57 187.386 180.366 187.386 180.161 187.475C179.979 187.553 179.751 187.696 179.751 187.916C179.751 188.115 179.933 188.08 180.206 187.984C180.844 187.753 181.39 187.672 181.39 188.378C181.39 188.996 180.775 189.57 180.161 189.835C179.524 190.11 178.909 190.067 178.932 189.417L179.706 189.083Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M183.825 186.116L182.721 186.596V187.021L183.733 186.581V187.252L182.721 187.692V188.162L183.848 187.672V188.366L181.917 189.206V186.274L183.802 185.454V186.126L183.825 186.116Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M186.953 187.081V187.192L186.137 187.544L185.615 186.86L185.207 187.036V187.944L184.436 188.276V185.373C184.89 185.178 185.32 184.992 185.751 184.806C186.477 184.494 186.817 184.879 186.817 185.389C186.817 185.721 186.658 186.122 186.364 186.427L186.953 187.081ZM185.751 186.181C185.978 186.083 186.069 185.867 186.069 185.667C186.069 185.49 185.955 185.361 185.751 185.449L185.207 185.684V186.415L185.751 186.181Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M188.504 185.421L188.76 184.559L189.133 183.537L189.996 183.167V183.278L188.667 186.677L188.34 186.817L187.011 184.556V184.445L187.874 184.076L188.27 184.769L188.504 185.421Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M191.752 184.843V185.468L190.172 186.16V185.534L190.567 185.361V183.663L190.218 183.815V183.19L191.729 182.528V183.154L191.381 183.307V185.005L191.752 184.843Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M193.657 181.902C194.281 181.634 194.976 181.666 195.091 182.385L194.328 182.712C194.212 182.454 193.981 182.443 193.657 182.582C193.194 182.78 192.893 183.216 192.893 183.699C192.893 184.116 193.194 184.36 193.657 184.162C193.981 184.024 194.258 183.751 194.328 183.414L195.091 183.087C194.976 183.949 194.305 184.565 193.657 184.843C192.87 185.18 192.106 185.002 192.106 184.036C192.106 183.048 192.87 182.238 193.657 181.902Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <path
            d="M197.464 181.083L196.361 181.563V181.989L197.372 181.549V182.22L196.361 182.66V183.13L197.487 182.64V183.334L195.556 184.174V181.242L197.441 180.422V181.093L197.464 181.083Z"
            fill="#141B4D"
            fillOpacity="0.7"
          />
          <rect
            x="2.6456"
            y="1.84388"
            width="79.7627"
            height="120.047"
            transform="matrix(0.938191 -0.346117 0 1 65.3207 68.0905)"
            stroke="#F3F4F8"
            strokeWidth="5.63979"
          />
          <rect
            x="2.6456"
            y="1.84388"
            width="79.7627"
            height="120.047"
            transform="matrix(0.938191 -0.346117 0 1 145.444 38.5309)"
            stroke="#F3F4F8"
            strokeWidth="5.63979"
          />
          <rect
            x="2.6456"
            y="1.84388"
            width="79.7627"
            height="120.853"
            transform="matrix(0.938191 -0.346117 0 1 65.3207 193.777)"
            stroke="#F3F4F8"
            strokeWidth="5.63979"
          />
          <rect
            x="2.6456"
            y="1.84388"
            width="79.7627"
            height="121.658"
            transform="matrix(0.938191 -0.346117 0 1 145.444 163.413)"
            stroke="#F3F4F8"
            strokeWidth="5.63979"
          />
          <rect
            width="176.445"
            height="4.02842"
            transform="matrix(0.938191 -0.346117 0 1 59.8652 65.0986)"
            fill="#C4C4C4"
          />
          <rect
            width="176.445"
            height="4.02842"
            transform="matrix(0.938191 -0.346117 0 1 59.8652 321.308)"
            fill="#C4C4C4"
          />
          <path
            d="M59.8652 65.0986L65.1564 67.175V319.354L59.8652 325.335V65.0986Z"
            fill="#E9EBEF"
          />
          <path
            d="M59.8652 65.0986L65.1564 67.175V319.354L59.8652 325.335V65.0986Z"
            fill="#CED0D4"
          />
          <rect
            width="4.02842"
            height="260.236"
            transform="matrix(0.938191 -0.346117 0 1 56.0859 66.4922)"
            fill="white"
          />
          <rect
            width="180.473"
            height="4.02842"
            transform="matrix(0.938191 -0.346117 0 1 56.0879 62.4639)"
            fill="white"
          />
        </g>
        <defs>
          <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use
              xlinkHref="#image0"
              transform="translate(-0.406963 -0.00420323) scale(0.0013893 0.00150636)"
            />
          </pattern>
          <filter
            id="filter0_i"
            x="230.919"
            y="13.6963"
            width="75.5102"
            height="118.569"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1.38717" dy="4.16152" />
            <feGaussianBlur stdDeviation="2.77434" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter1_i"
            x="316.321"
            y="13.6963"
            width="75.5102"
            height="118.569"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1.38717" dy="4.16152" />
            <feGaussianBlur stdDeviation="2.77434" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter2_i"
            x="230.919"
            y="139.383"
            width="75.5102"
            height="119.374"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1.38717" dy="4.16152" />
            <feGaussianBlur stdDeviation="2.77434" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter3_i"
            x="316.321"
            y="139.383"
            width="75.5102"
            height="119.374"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1.38717" dy="4.16152" />
            <feGaussianBlur stdDeviation="2.77434" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter4_i"
            x="70.4478"
            y="170.895"
            width="70.9287"
            height="145.03"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1.38717" dy="4.16152" />
            <feGaussianBlur stdDeviation="2.77434" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter5_i"
            x="150.572"
            y="140.529"
            width="70.9287"
            height="145.835"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1.38717" dy="4.16152" />
            <feGaussianBlur stdDeviation="2.77434" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter6_i"
            x="70.4478"
            y="45.207"
            width="70.9287"
            height="144.224"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1.38717" dy="4.16152" />
            <feGaussianBlur stdDeviation="2.77434" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter7_i"
            x="150.572"
            y="15.6484"
            width="70.9287"
            height="144.224"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="1.38717" dy="4.16152" />
            <feGaussianBlur stdDeviation="2.77434" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <clipPath id="clip0">
            <rect width="361.752" height="331.136" fill="white" transform="translate(44)" />
          </clipPath>
        </defs>
      </svg>

      {!withoutBackground && <ContainerBackground />}
    </ProductImageWrapper>
  )
}
