import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement42Square8Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-2_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M334.999 155C333.895 155 333 155.895 333 156.999C333 158.104 333.895 158.999 334.999 158.999L337.167 158.999L337.167 158.999L348 158.999L348 159L351.001 159C352.105 159 353 158.105 353 157.001C353 155.896 352.105 155.001 351.001 155.001L348.833 155.001L348.833 155L338 155L338 155L334.999 155Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 337.167 155)"
          fill="#ABABAB"
        />
        <path
          d="M333.94 155C333.421 155 333 155.421 333 155.94L333 158.059C333 158.578 333.421 158.999 333.94 158.999L338 158.999L338 155L333.94 155Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M352.06 159C352.579 159 353 158.579 353 158.06L353 155.941C353 155.422 352.579 155.001 352.06 155.001L348 155.001L348 159L352.06 159Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 338.833 155)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#4-2_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M394.999 155C393.895 155 393 155.895 393 156.999C393 158.104 393.895 158.999 394.999 158.999L397.167 158.999L397.167 158.999L408 158.999L408 159L411.001 159C412.105 159 413 158.105 413 157.001C413 155.896 412.105 155.001 411.001 155.001L408.833 155.001L408.833 155L398 155L398 155L394.999 155Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 397.167 155)"
          fill="#ABABAB"
        />
        <path
          d="M393.94 155C393.421 155 393 155.421 393 155.94L393 158.059C393 158.578 393.421 158.999 393.94 158.999L398 158.999L398 155L393.94 155Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M412.06 159C412.579 159 413 158.579 413 158.06L413 155.941C413 155.422 412.579 155.001 412.06 155.001L408 155.001L408 159L412.06 159Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 398.833 155)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#4-2_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 34H328V155H417V34ZM406 45H339V144H406V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 34H328V155H329.219V35H415.781V155H417V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-2_filter3_i)">
          <rect
            x="339.47"
            y="45.47"
            width="66.0601"
            height="98.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="332.53"
          y="38.53"
          width="79.9399"
          height="111.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="379.528"
          y="36"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 379.528 36)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#4-2_filter4_d)">
          <rect
            x="378.884"
            y="36.7019"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 378.884 36.7019)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M378.766 38.6951C378.42 37.542 377.35 36.7019 376.084 36.7019L369.444 36.7019C367.898 36.7019 366.644 37.9555 366.644 39.5019C366.644 39.5371 366.645 39.5721 366.646 39.6069C366.993 40.76 368.062 41.6001 369.328 41.6001L375.968 41.6001C377.514 41.6001 378.768 40.3465 378.768 38.8001C378.768 38.7649 378.767 38.7299 378.766 38.6951Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M377.409 37.0346C377.266 37.0119 377.118 37.0001 376.968 37.0001L370.328 37.0001C368.782 37.0001 367.528 38.2537 367.528 39.8001C367.528 40.8672 368.125 41.7948 369.003 42.2674C367.666 42.0558 366.644 40.8982 366.644 39.5019C366.644 37.9555 367.898 36.7019 369.444 36.7019L376.084 36.7019C376.564 36.7019 377.015 36.8224 377.409 37.0346Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-2_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M392.528 39.5C392.528 38.6716 391.857 38 391.028 38L390.814 38L389.528 38L371.028 38C370.2 38 369.528 38.6716 369.528 39.5V39.5C369.528 40.3284 370.2 41 371.028 41L387.528 41L391.028 41L391.243 41C391.953 41 392.528 40.4244 392.528 39.7143V39.7143C392.528 39.6771 392.527 39.6401 392.525 39.6035C392.527 39.5693 392.528 39.5348 392.528 39.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M340.695 142.5L372.5 47.5738L404.305 142.5L340.695 142.5Z" stroke="white" />
        <defs>
          <filter
            id="4-2_filter0_d"
            x="331.12"
            y="153.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-2_filter1_d"
            x="391.12"
            y="153.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-2_filter2_d"
            x="323.3"
            y="29.3003"
            width="100.279"
            height="132.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-2_filter3_i"
            x="339"
            y="45"
            width="67.9399"
            height="101.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-2_filter4_d"
            x="364.765"
            y="34.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-2_filter5_d"
            x="368.649"
            y="36.1201"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="350.5"
            y1="152.2"
            x2="350.5"
            y2="159.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="335.5"
            y1="161.798"
            x2="335.5"
            y2="154.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="350.5"
            y1="152.202"
            x2="350.5"
            y2="159.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="410.5"
            y1="152.2"
            x2="410.5"
            y2="159.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="395.5"
            y1="161.798"
            x2="395.5"
            y2="154.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="410.5"
            y1="152.202"
            x2="410.5"
            y2="159.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
