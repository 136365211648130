import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement23Square41Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-3-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M154.001 34C155.105 34 156 33.1048 156 32.0005C156 30.8962 155.105 30.001 154.001 30.001L151.833 30.001L151.833 30.001L141 30.001L141 30L137.999 30C136.895 30 136 30.8952 136 31.9995C136 33.1038 136.895 33.999 137.999 33.999L140.167 33.999L140.167 34L151 34L151 34L154.001 34Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 151.833 34)"
          fill="#ABABAB"
        />
        <path
          d="M155.06 34C155.579 34 156 33.5792 156 33.0601L156 30.941C156 30.4219 155.579 30.001 155.06 30.001L151 30.001L151 34L155.06 34Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M136.94 30C136.421 30 136 30.4208 136 30.9399L136 33.059C136 33.5781 136.421 33.999 136.94 33.999L141 33.999L141 30L136.94 30Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 150.167 34)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#2-3-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M215.001 34C216.105 34 217 33.1048 217 32.0005C217 30.8962 216.105 30.001 215.001 30.001L212.833 30.001L212.833 30.001L202 30.001L202 30L198.999 30C197.895 30 197 30.8952 197 31.9995C197 33.1038 197.895 33.999 198.999 33.999L201.167 33.999L201.167 34L212 34L212 34L215.001 34Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 212.833 34)"
          fill="#ABABAB"
        />
        <path
          d="M216.06 34C216.579 34 217 33.5792 217 33.0601L217 30.941C217 30.4219 216.579 30.001 216.06 30.001L212 30.001L212 34L216.06 34Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M197.94 30C197.421 30 197 30.4208 197 30.9399L197 33.059C197 33.5781 197.421 33.999 197.94 33.999L202 33.999L202 30L197.94 30Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 211.167 34)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#2-3-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M221 34H132V287H221V34ZM210 45H143V276H210V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M221 34H132V287H133.219V35H219.781V287H221V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-3-filter3_i)">
          <rect
            x="143.47"
            y="45.47"
            width="66.0601"
            height="230.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="136.53"
          y="38.53"
          width="79.9399"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="183"
          y="278"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 183 278)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="182.356"
          y="278.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 182.356 278.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M182.238 280.695C181.892 279.542 180.822 278.702 179.556 278.702L172.916 278.702C171.37 278.702 170.116 279.955 170.116 281.502C170.116 281.537 170.117 281.572 170.118 281.607C170.464 282.76 171.534 283.6 172.8 283.6L179.44 283.6C180.986 283.6 182.24 282.346 182.24 280.8C182.24 280.765 182.239 280.73 182.238 280.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M180.881 279.035C180.737 279.012 180.59 279 180.44 279L173.8 279C172.254 279 171 280.254 171 281.8C171 282.867 171.597 283.795 172.475 284.267C171.138 284.056 170.116 282.898 170.116 281.502C170.116 279.955 171.37 278.702 172.916 278.702L179.556 278.702C180.035 278.702 180.487 278.822 180.881 279.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-3-filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M179 281.5C179 280.672 178.328 280 177.5 280L177.286 280L176 280L157.5 280C156.672 280 156 280.672 156 281.5V281.5C156 282.328 156.672 283 157.5 283L174 283L177.5 283L177.714 283C178.424 283 179 282.424 179 281.714V281.714C179 281.677 178.999 281.64 178.996 281.604C178.999 281.569 179 281.535 179 281.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M208.424 46.5L176.5 271.442L144.576 46.5L208.424 46.5Z" stroke="white" />
        <defs>
          <filter
            id="2-3-filter0_d"
            x="134.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-3-filter1_d"
            x="195.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-3-filter2_d"
            x="127.3"
            y="29.3003"
            width="100.279"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-3-filter3_i"
            x="143"
            y="45"
            width="67.9399"
            height="233.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-3-filter4_d"
            x="154.28"
            y="278.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="138.5"
            y1="36.8"
            x2="138.5"
            y2="29.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="153.5"
            y1="27.2018"
            x2="153.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="138.5"
            y1="36.7982"
            x2="138.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="199.5"
            y1="36.8"
            x2="199.5"
            y2="29.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="214.5"
            y1="27.2018"
            x2="214.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="199.5"
            y1="36.7982"
            x2="199.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
