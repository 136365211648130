import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement41Square4VerticalCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-1_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M60.0235 289.954C61.1151 289.954 62 289.069 62 287.978C62 286.886 61.1151 286.001 60.0235 286.001L57.8335 286.001L57.8335 286.001L47 286.001L47 286L43.9765 286C42.8849 286 42 286.885 42 287.976C42 289.068 42.8849 289.953 43.9765 289.953L46.1668 289.953L46.1668 289.954L57 289.954L57 289.954L60.0235 289.954Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 57.8335 289.954)"
          fill="#ABABAB"
        />
        <path
          d="M61.0601 289.954C61.5792 289.954 62 289.533 62 289.014L62 286.941C62 286.422 61.5792 286.001 61.0601 286.001L57 286.001L57 289.954L61.0601 289.954Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M42.9399 286C42.4208 286 42 286.421 42 286.94L42 289.013C42 289.532 42.4208 289.953 42.9399 289.953L47 289.953L47 286L42.9399 286Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 56.1666 289.954)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#4-1_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M176.024 289.954C177.115 289.954 178 289.069 178 287.978C178 286.886 177.115 286.001 176.024 286.001L173.833 286.001L173.833 286.001L163 286.001L163 286L159.976 286C158.885 286 158 286.885 158 287.976C158 289.068 158.885 289.953 159.976 289.953L162.167 289.953L162.167 289.954L173 289.954L173 289.954L176.024 289.954Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 173.834 289.954)"
          fill="#ABABAB"
        />
        <path
          d="M177.06 289.954C177.579 289.954 178 289.533 178 289.014L178 286.941C178 286.422 177.579 286.001 177.06 286.001L173 286.001L173 289.954L177.06 289.954Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M158.94 286C158.421 286 158 286.421 158 286.94L158 289.013C158 289.532 158.421 289.953 158.94 289.953L163 289.953L163 286L158.94 286Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 172.167 289.954)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#4-1_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 231H32V286H188V231ZM178 241H42V276H178V241Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 231H32V286H33V232H187V286H188V231Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-1_filter3_i)">
          <rect
            x="42.47"
            y="241.47"
            width="135.06"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="117.528"
          y="233"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 117.528 233)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#4-1_filter4_d)">
          <rect
            x="116.884"
            y="233.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 116.884 233.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M116.766 235.695C116.42 234.542 115.35 233.702 114.084 233.702L107.444 233.702C105.898 233.702 104.644 234.955 104.644 236.502C104.644 236.537 104.645 236.572 104.646 236.607C104.993 237.76 106.062 238.6 107.328 238.6L113.968 238.6C115.515 238.6 116.768 237.346 116.768 235.8C116.768 235.765 116.768 235.73 116.766 235.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115.409 234.035C115.266 234.012 115.118 234 114.968 234L108.328 234C106.782 234 105.528 235.254 105.528 236.8C105.528 237.867 106.125 238.795 107.003 239.267C105.666 239.056 104.644 237.898 104.644 236.502C104.644 234.955 105.898 233.702 107.444 233.702L114.084 233.702C114.564 233.702 115.015 233.822 115.409 234.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-1_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M130.528 236.5C130.528 235.672 129.857 235 129.028 235L128.814 235L127.528 235L109.028 235C108.2 235 107.528 235.672 107.528 236.5V236.5C107.528 237.328 108.2 238 109.028 238L125.528 238L129.028 238L129.243 238C129.953 238 130.528 237.424 130.528 236.714V236.714C130.528 236.677 130.527 236.64 130.525 236.604C130.527 236.569 130.528 236.535 130.528 236.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M174.853 274.5L110 242.557L45.1468 274.5L174.853 274.5Z" stroke="white" />
        <defs>
          <filter
            id="4-1_filter0_d"
            x="40.1201"
            y="284.12"
            width="25.6396"
            height="9.59374"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-1_filter1_d"
            x="156.12"
            y="284.12"
            width="25.6396"
            height="9.59374"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-1_filter2_d"
            x="27.3003"
            y="226.3"
            width="167.279"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-1_filter3_i"
            x="42"
            y="241"
            width="136.94"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-1_filter4_d"
            x="102.764"
            y="231.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-1_filter5_d"
            x="106.649"
            y="233.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="44.5"
            y1="292.722"
            x2="44.5"
            y2="285.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="59.5"
            y1="283.234"
            x2="59.5"
            y2="290.745"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="44.5"
            y1="292.72"
            x2="44.5"
            y2="285.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="160.5"
            y1="292.722"
            x2="160.5"
            y2="285.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="175.5"
            y1="283.234"
            x2="175.5"
            y2="290.745"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="160.5"
            y1="292.72"
            x2="160.5"
            y2="285.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
