import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement12Square5VerticalCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#1-2_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M50.0005 30C51.1048 30 52 29.1048 52 28.0005C52 26.8962 51.1048 26.001 50.0005 26.001L47.8335 26.001L47.8335 26.001L37 26.001L37 26L33.9995 26C32.8952 26 32 26.8952 32 27.9995C32 29.1038 32.8952 29.999 33.9995 29.999L36.1668 29.999L36.1668 30L47 30L47 30L50.0005 30Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 47.8335 30)"
          fill="#ABABAB"
        />
        <path
          d="M51.0601 30C51.5792 30 52 29.5792 52 29.0601L52 26.941C52 26.4219 51.5792 26.001 51.0601 26.001L47 26.001L47 30L51.0601 30Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M32.9399 26C32.4208 26 32 26.4208 32 26.9399L32 29.059C32 29.5781 32.4208 29.999 32.9399 29.999L37 29.999L37 26L32.9399 26Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 46.1666 30)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#1-2_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M186.001 30C187.105 30 188 29.1048 188 28.0005C188 26.8962 187.105 26.001 186.001 26.001L183.833 26.001L183.833 26.001L173 26.001L173 26L169.999 26C168.895 26 168 26.8952 168 27.9995C168 29.1038 168.895 29.999 169.999 29.999L172.167 29.999L172.167 30L183 30L183 30L186.001 30Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 183.833 30)"
          fill="#ABABAB"
        />
        <path
          d="M187.06 30C187.579 30 188 29.5792 188 29.0601L188 26.941C188 26.4219 187.579 26.001 187.06 26.001L183 26.001L183 30L187.06 30Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M168.94 26C168.421 26 168 26.4208 168 26.9399L168 29.059C168 29.5781 168.421 29.999 168.94 29.999L173 29.999L173 26L168.94 26Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 182.167 30)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#1-2_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M192 30H28V76H192V30ZM183 39H37V67H183V39Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M192 30H28V76H29V31H191V76H192V30Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-2_filter3_i)">
          <rect
            x="37.47"
            y="39.47"
            width="145.06"
            height="27.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="32.53"
          y="34.53"
          width="154.94"
          height="36.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="117"
          y="68"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 117 68)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="116.356"
          y="68.7017"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 116.356 68.7017)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M116.238 70.6948C115.892 69.5418 114.822 68.7017 113.556 68.7017L106.916 68.7017C105.37 68.7017 104.116 69.9553 104.116 71.5017C104.116 71.5368 104.117 71.5718 104.118 71.6067C104.464 72.7598 105.534 73.5998 106.8 73.5998L113.44 73.5998C114.986 73.5998 116.24 72.3462 116.24 70.7998C116.24 70.7647 116.239 70.7297 116.238 70.6948Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M114.881 69.0344C114.737 69.0117 114.59 68.9998 114.44 68.9998L107.8 68.9998C106.254 68.9998 105 70.2534 105 71.7998C105 72.8669 105.597 73.7946 106.475 74.2671C105.138 74.0556 104.116 72.898 104.116 71.5017C104.116 69.9553 105.37 68.7017 106.916 68.7017L113.556 68.7017C114.035 68.7017 114.487 68.8221 114.881 69.0344Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-2_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M113 71.5C113 70.6716 112.328 70 111.5 70L111.286 70L110 70L91.5 70C90.6716 70 90 70.6716 90 71.5V71.5C90 72.3284 90.6716 73 91.5 73L108 73L111.5 73L111.714 73C112.424 73 113 72.4244 113 71.7143V71.7143C113 71.6771 112.999 71.6401 112.996 71.6035C112.999 71.5693 113 71.5348 113 71.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M179.143 40.5L110 65.4684L40.8567 40.5L179.143 40.5Z" stroke="white" />
        <defs>
          <filter
            id="1-2_filter0_d"
            x="30.1201"
            y="24.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-2_filter1_d"
            x="166.12"
            y="24.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-2_filter2_d"
            x="23.3003"
            y="25.3003"
            width="175.279"
            height="57.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-2_filter3_i"
            x="37"
            y="39"
            width="146.94"
            height="30.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="1-2_filter4_d"
            x="88.28"
            y="68.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="34.5"
            y1="32.8"
            x2="34.5"
            y2="25.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="49.5"
            y1="23.2018"
            x2="49.5"
            y2="30.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="34.5"
            y1="32.7982"
            x2="34.5"
            y2="25.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="170.5"
            y1="32.8"
            x2="170.5"
            y2="25.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="185.5"
            y1="23.2018"
            x2="185.5"
            y2="30.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="170.5"
            y1="32.7982"
            x2="170.5"
            y2="25.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
