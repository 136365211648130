import CSS from 'csstype'
import { IImageSizes } from './interface'

export const previewWrapperStyle: (imageSizes: IImageSizes) => CSS.Properties = imageSizes => ({
  position: 'relative',
  padding: '10px',
  margin: '20px',
  width: `${imageSizes['width']}px`,
  height: `${imageSizes['height']}px`,
})

export const previewSizesHorizontalWrapperStyle: (bottom?: boolean) => CSS.Properties = bottom => {
  const bottomStyle = bottom
    ? {
        top: 'initial',
        bottom: '-20px',
      }
    : {}

  return {
    position: 'absolute',
    top: '-20px',
    left: '10px',
    right: '0',
    display:
      'display: flex; display: -webkit-box; display: -webkit-flex; display: -ms-flexbox',
    justifyContent: 'space-around',
    '-webkitBoxPack': 'space-between',
    ...bottomStyle,
  }
}

export const previewSizesHorizontalStyle: CSS.Properties = {
  position: 'relative',
  width: '100%',
  textAlign: 'center',
}

export const previewSizesVerticalWrapper: (left?: boolean) => CSS.Properties = left => {
  const leftStyle = left
    ? {
        right: 'initial',
        left: '-30px',
      }
    : {}

  return {
    position: 'absolute',
    right: '-30px',
    top: '0',
    display: 'display: flex; display: -webkit-box; display: -webkit-flex; display: -ms-flexbox;',
    justifyContent: 'space-around',
    '-webkitBoxPack': 'space-around',
    bottom: '0',
    flexDirection: 'column',
    ...leftStyle,
  }
}

export const previewSizesHorizontal: CSS.Properties = {
  width: '100%',
  textAlign: 'center',
}

export const previewSizesHorizontalAfter: CSS.Properties = {
  backgroundColor: '#cccccc',
  content: '',
  width: 'calc(100% - 4px)',
  height: '1px',
  display: 'block',
  position: 'relative',
  bottom: '10px',
}

export const previewSizesText: CSS.Properties = {
  backgroundColor: '#ffffff',
  display: 'inline-block',
  padding: '2px 4px',
  position: 'relative',
  zIndex: 1,
}

export const previewSizesVerticalStyle: (size?: number) => CSS.Properties = size => ({
  height: size ? `${100 / size}%` : '100%',
  position: 'relative',
})

export const previewSizesVerticalAfterStyle: CSS.Properties = {
  backgroundColor: '#cccccc',
  width: '1px',
  height: 'calc(100% - 15px)',
  display: 'block',
  position: 'absolute',
  left: '15px',
  top: '10px',
}

export const previewSizesTextStyle: (rotate?: boolean, top?: string) => CSS.Properties = (
  rotate,
  top,
) => ({
  backgroundColor: '#ffffff',
  display: 'inline-block',
  padding: '2px 4px',
  position: 'relative',
  zIndex: 1,
  transform: rotate ? 'rotate(90deg)' : '',
  top: top || '40%',
})

export const previewSizesHorizontalAfterStyle: CSS.Properties = {
  backgroundColor: '#cccccc',
  position: 'absolute',
  width: 'calc(100% - 4px)',
  height: '1px',
  display: 'block',
  bottom: '1px',
}
