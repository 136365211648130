import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement5Door1LCasement1 = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="451"
        height="451"
        viewBox="0 0 451 451"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#door-1-l-casement-1-5-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M185.954 46.9765C185.954 45.8849 185.069 45 183.978 45C182.886 45 182.001 45.8849 182.001 46.9765V49.1665H182.001V60H182V63.0235C182 64.1151 182.885 65 183.976 65C185.068 65 185.953 64.1151 185.953 63.0235V60.8332H185.954V50H185.954V46.9765Z"
            fill="url(#door-1-l-casement-1-5-paint0_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-1 0 0 1 185.954 49.1665)"
          fill="#ABABAB"
        />
        <path
          d="M185.954 45.9399C185.954 45.4208 185.533 45 185.014 45H182.941C182.422 45 182.001 45.4208 182.001 45.9399V50H185.954V45.9399Z"
          fill="url(#door-1-l-casement-1-5-paint1_linear)"
        />
        <path
          d="M182 64.0601C182 64.5792 182.421 65 182.94 65H185.013C185.532 65 185.953 64.5792 185.953 64.0601V60H182V64.0601Z"
          fill="url(#door-1-l-casement-1-5-paint2_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-1 0 0 1 185.954 50.8334)"
          fill="url(#door-1-l-casement-1-5-paint3_linear)"
        />
        <g filter="url(#door-1-l-casement-1-5-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M185.954 271.976C185.954 270.885 185.069 270 183.978 270C182.886 270 182.001 270.885 182.001 271.976V274.167H182.001V285H182V288.024C182 289.115 182.885 290 183.976 290C185.068 290 185.953 289.115 185.953 288.024V285.833H185.954V275H185.954V271.976Z"
            fill="url(#door-1-l-casement-1-5-paint4_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-1 0 0 1 185.954 274.166)"
          fill="#ABABAB"
        />
        <path
          d="M185.954 270.94C185.954 270.421 185.533 270 185.014 270H182.941C182.422 270 182.001 270.421 182.001 270.94V275H185.954V270.94Z"
          fill="url(#door-1-l-casement-1-5-paint5_linear)"
        />
        <path
          d="M182 289.06C182 289.579 182.421 290 182.94 290H185.013C185.532 290 185.953 289.579 185.953 289.06V285H182V289.06Z"
          fill="url(#door-1-l-casement-1-5-paint6_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-1 0 0 1 185.954 275.833)"
          fill="url(#door-1-l-casement-1-5-paint7_linear)"
        />
        <g filter="url(#door-1-l-casement-1-5-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M419 34H186V301H419V34ZM408 45H197V290H408V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M419 34H186V301H187V35H418V301H419V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="190.53"
          y="38.53"
          width="223.94"
          height="257.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#door-1-l-casement-1-5-filter3_i)">
          <rect
            x="197.47"
            y="45.47"
            width="210.06"
            height="244.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          width="6.91954"
          height="13.5283"
          rx="3.45977"
          transform="matrix(-1 0 0 1 416.919 161)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#door-1-l-casement-1-5-filter4_d)">
          <rect
            width="5.53563"
            height="12.2399"
            rx="2.76782"
            transform="matrix(-1 0 0 1 416.225 161.644)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M415.66 172.792C415.155 173.456 414.356 173.884 413.458 173.884C411.929 173.884 410.69 172.645 410.69 171.116V164.412C410.69 163.44 411.191 162.585 411.948 162.092C411.594 162.557 411.383 163.138 411.383 163.768V170.472C411.383 172.001 412.622 173.24 414.151 173.24C414.708 173.24 415.226 173.075 415.66 172.792Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M413.458 161.644C414.986 161.644 416.225 162.883 416.225 164.412V170.472C416.225 170.492 416.225 170.512 416.225 170.533V164.991C416.225 163.463 414.985 162.224 413.457 162.224C411.949 162.224 410.722 163.43 410.69 164.931V164.412C410.69 162.883 411.929 161.644 413.458 161.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#door-1-l-casement-1-5-filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M413.46 165C414.279 165 414.942 165.664 414.942 166.483V168C414.942 168.014 414.942 168.027 414.942 168.041L414.942 186.517C414.942 187.336 414.278 188 413.459 188C412.64 188 411.976 187.336 411.976 186.517L411.976 166.251C411.976 165.56 412.537 165 413.228 165C413.231 165 413.233 165 413.236 165C413.311 165 413.385 165 413.46 165Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M198.5 288.131V46.869L406.005 167.5L198.5 288.131Z" stroke="white" />
        <defs>
          <filter
            id="door-1-l-casement-1-5-filter0_d"
            x="180.12"
            y="43.1201"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-1-5-filter1_d"
            x="180.12"
            y="268.12"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-1-5-filter2_d"
            x="181.3"
            y="29.3003"
            width="244.279"
            height="278.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-1-5-filter3_i"
            x="197"
            y="45"
            width="211.94"
            height="247.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="door-1-l-casement-1-5-filter4_d"
            x="408.81"
            y="159.764"
            width="11.1753"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-1-5-filter5_d"
            x="410.097"
            y="163.12"
            width="8.60556"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="door-1-l-casement-1-5-paint0_linear"
            x1="188.722"
            y1="62.5"
            x2="181.209"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-1-5-paint1_linear"
            x1="179.234"
            y1="47.5"
            x2="186.745"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-1-5-paint2_linear"
            x1="188.72"
            y1="62.5"
            x2="181.209"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-1-5-paint3_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-1-5-paint4_linear"
            x1="188.722"
            y1="287.5"
            x2="181.209"
            y2="287.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-1-5-paint5_linear"
            x1="179.234"
            y1="272.5"
            x2="186.745"
            y2="272.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-1-5-paint6_linear"
            x1="188.72"
            y1="287.5"
            x2="181.209"
            y2="287.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-1-5-paint7_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
