import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement14Square82Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#1filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M100.001 92C101.105 92 102 91.1048 102 90.0005C102 88.8962 101.105 88.001 100.001 88.001L97.8335 88.001L97.8335 88.001L87 88.001L87 88L83.9995 88C82.8952 88 82 88.8952 82 89.9995C82 91.1038 82.8952 91.999 83.9995 91.999L86.1668 91.999L86.1668 92L97 92L97 92L100.001 92Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 97.8335 92)"
          fill="#ABABAB"
        />
        <path
          d="M101.06 92C101.579 92 102 91.5792 102 91.0601L102 88.941C102 88.4219 101.579 88.001 101.06 88.001L97 88.001L97 92L101.06 92Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M82.9399 88C82.4208 88 82 88.4208 82 88.9399L82 91.059C82 91.5781 82.4208 91.999 82.9399 91.999L87 91.999L87 88L82.9399 88Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 96.1666 92)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#1filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M55.0005 92C56.1048 92 57 91.1048 57 90.0005C57 88.8962 56.1048 88.001 55.0005 88.001L52.8335 88.001L52.8335 88.001L42 88.001L42 88L38.9995 88C37.8952 88 37 88.8952 37 89.9995C37 91.1038 37.8952 91.999 38.9995 91.999L41.1668 91.999L41.1668 92L52 92L52 92L55.0005 92Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 52.8335 92)"
          fill="#ABABAB"
        />
        <path
          d="M56.0601 92C56.5792 92 57 91.5792 57 91.0601L57 88.941C57 88.4219 56.5792 88.001 56.0601 88.001L52 88.001L52 92L56.0601 92Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M37.9399 88C37.4208 88 37 88.4208 37 88.9399L37 91.059C37 91.5781 37.4208 91.999 37.9399 91.999L42 91.999L42 88L37.9399 88Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 51.1666 92)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#1filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M105 33H32V88H105V33ZM95 43H42V78H95V43Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105 33H32V88H33V34H104V88H105V33Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1filter3_i)">
          <rect
            x="42.47"
            y="43.47"
            width="52.0601"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="37.53"
          width="63.9399"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="75.5283"
          y="35"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 75.5283 35)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#1filter4_d)">
          <rect
            x="74.8843"
            y="35.7019"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 74.8843 35.7019)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74.7663 37.6951C74.4199 36.542 73.3502 35.7019 72.0842 35.7019L65.4443 35.7019C63.8979 35.7019 62.6443 36.9555 62.6443 38.5019C62.6443 38.5371 62.645 38.5721 62.6463 38.6069C62.9927 39.76 64.0624 40.6001 65.3283 40.6001L71.9682 40.6001C73.5146 40.6001 74.7682 39.3465 74.7682 37.8001C74.7682 37.7649 74.7675 37.7299 74.7663 37.6951Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.4093 36.0346C73.2656 36.0119 73.1183 36.0001 72.9682 36.0001L66.3283 36.0001C64.7819 36.0001 63.5283 37.2537 63.5283 38.8001C63.5283 39.8672 64.1252 40.7948 65.0033 41.2674C63.6665 41.0558 62.6444 39.8982 62.6444 38.5019C62.6444 36.9555 63.898 35.7019 65.4444 35.7019L72.0843 35.7019C72.5636 35.7019 73.0148 35.8224 73.4093 36.0346Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M88.5283 38.5C88.5283 37.6716 87.8567 37 87.0283 37L86.8141 37L85.5283 37L67.0283 37C66.1999 37 65.5283 37.6716 65.5283 38.5V38.5C65.5283 39.3284 66.1999 40 67.0283 40L83.5283 40L87.0283 40L87.2426 40C87.9527 40 88.5283 39.4244 88.5283 38.7143V38.7143C88.5283 38.6771 88.5271 38.6401 88.5248 38.6035C88.5271 38.5693 88.5283 38.5348 88.5283 38.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M44.0182 76.5L68.5 44.8177L92.9818 76.5L44.0182 76.5Z" stroke="white" />
        <defs>
          <filter
            id="1filter0_d"
            x="80.1201"
            y="86.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1filter1_d"
            x="35.1201"
            y="86.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1filter2_d"
            x="27.3003"
            y="28.3003"
            width="84.2793"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1filter3_i"
            x="42"
            y="43"
            width="53.9399"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="1filter4_d"
            x="60.7645"
            y="33.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1filter5_d"
            x="64.6485"
            y="35.1201"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="84.5"
            y1="94.8"
            x2="84.5"
            y2="87.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="99.5"
            y1="85.2018"
            x2="99.5"
            y2="92.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="84.5"
            y1="94.7982"
            x2="84.5"
            y2="87.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="39.5"
            y1="94.8"
            x2="39.5"
            y2="87.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="54.5"
            y1="85.2018"
            x2="54.5"
            y2="92.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="39.5"
            y1="94.7982"
            x2="39.5"
            y2="87.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
