import React from 'react'

export const CalcIcon = ({ color = '#1C3775' }) => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.041 0H2.95727C1.69352 0 0.666016 1.0275 0.666016 2.29125V17.7075C0.666016 18.9713 1.69352 19.9988 2.95727 19.9988H15.041C16.3048 19.9988 17.3323 18.9713 17.3323 17.7075V2.29125C17.3335 1.0275 16.3048 0 15.041 0ZM6.49977 16.875C6.49977 17.22 6.21977 17.5 5.87477 17.5H3.79102C3.44602 17.5 3.16602 17.22 3.16602 16.875V13.9588C3.16602 13.6138 3.44602 13.3338 3.79102 13.3338H5.87477C6.21977 13.3338 6.49977 13.6138 6.49977 13.9588V16.875ZM6.49977 11.875C6.49977 12.22 6.21977 12.5 5.87477 12.5H3.79102C3.44602 12.5 3.16602 12.22 3.16602 11.875V8.95875C3.16602 8.61375 3.44602 8.33375 3.79102 8.33375H5.87477C6.21977 8.33375 6.49977 8.61375 6.49977 8.95875V11.875ZM10.666 16.875C10.666 17.22 10.386 17.5 10.041 17.5H7.95728C7.61228 17.5 7.33228 17.22 7.33228 16.875V13.9588C7.33228 13.6138 7.61228 13.3338 7.95728 13.3338H10.041C10.386 13.3338 10.666 13.6138 10.666 13.9588V16.875ZM10.666 11.875C10.666 12.22 10.386 12.5 10.041 12.5H7.95728C7.61228 12.5 7.33228 12.22 7.33228 11.875V8.95875C7.33228 8.61375 7.61228 8.33375 7.95728 8.33375H10.041C10.386 8.33375 10.666 8.61375 10.666 8.95875V11.875ZM14.8335 16.875C14.8335 17.22 14.5535 17.5 14.2085 17.5H12.1248C11.7798 17.5 11.4998 17.22 11.4998 16.875V8.95875C11.4998 8.61375 11.7798 8.33375 12.1248 8.33375H14.2085C14.5535 8.33375 14.8335 8.61375 14.8335 8.95875V16.875ZM14.8335 5.20875C14.8335 6.0125 14.1798 6.6675 13.3748 6.6675H4.62477C3.82102 6.6675 3.16602 6.01375 3.16602 5.20875V3.95875C3.16602 3.155 3.81977 2.5 4.62477 2.5H13.3748C14.1785 2.5 14.8335 3.15375 14.8335 3.95875V5.20875Z"
        fill={color}
      />
    </svg>
  )
}
