import React, { FC, useMemo, Fragment, useCallback } from 'react'
import { IOrderDataBlockProps } from '../stage-calculator/interface'
import {
  OrderDataBlockWrapper,
  OrderDataBlockTitle,
  OrderDataBlockContent,
  OrderDataBlockContentItem,
} from './styles'
// skeleton for grouping products by number
/*
const groupProducts = (acc, item) => {
  const result = [...acc]
  result.push(item)
  return result
}
*/

export const OrderDataBlock: FC<IOrderDataBlockProps> = props => {
  const { data } = props

  const elements = useMemo(() => {
    const groupProducts = (acc, item) => acc.concat(item)
    return data['specification-products']
      ? data['specification-products']['transition-products'].reduce(groupProducts, [])
      : []
  }, [data])

  const services = useMemo(() => {
    return data['services'] || []
  }, [data])

  const getName = useCallback((name, description) => {
    const result: string[] = []

    if (name) {
      result.push(name.replace('\n', '<br>'))
    }

    if (description) {
      result.push(description.replace('\n', '<br>'))
    }
    return result.join(' - ').replaceAll('\n', '<br>')
  }, [])

  return (
    <OrderDataBlockWrapper>
      <OrderDataBlockTitle>Состав заказа</OrderDataBlockTitle>
      <OrderDataBlockContent>
        {elements?.map?.(element => (
            <Fragment key={element['name']}>
              <OrderDataBlockContentItem
                noLeftGap
                dangerouslySetInnerHTML={{
                  __html: getName(element['name'], element['description']),
                }}
              />
              <OrderDataBlockContentItem center>
                {element['quantity'] || 1}
              </OrderDataBlockContentItem>
              <OrderDataBlockContentItem noRightBorder center>
                <b>{Math.trunc(element['price'])}</b>
              </OrderDataBlockContentItem>
            </Fragment>
          ))}
        {services.map(
          service =>
            service['price'] > 0 && (
              <Fragment key={service['name']}>
                <OrderDataBlockContentItem noLeftGap>
                  {getName(service['name'], service['description'])}
                </OrderDataBlockContentItem>
                <OrderDataBlockContentItem center />
                <OrderDataBlockContentItem noRightBorder center>
                  <b>{service['price']}</b>
                </OrderDataBlockContentItem>
              </Fragment>
            ),
        )}
      </OrderDataBlockContent>
    </OrderDataBlockWrapper>
  )
}
