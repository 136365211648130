import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement64Square62Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#6-4_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 262C188 260.895 188.895 260 190 260C191.105 260 192 260.895 192 262V276.56H188V262ZM188 276.56H192V278.4H192.06C192.579 278.4 193 278.821 193 279.34V281.12C193 282.158 192.158 283 191.12 283H189.88C188.842 283 188 282.158 188 281.12V278.4V278.4V276.56Z"
            fill="white"
          />
        </g>
        <rect x="188" y="277.25" width="3.99998" height="0.95833" fill="#ABABAB" />
        <path
          d="M188 260.94C188 260.421 188.421 260 188.94 260H191.06C191.579 260 192 260.421 192 260.94V277.25H188V260.94Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M188 282.06C188 282.579 188.421 283 188.94 283H192.06C192.579 283 193 282.579 193 282.06V279.148C193 278.629 192.579 278.208 192.06 278.208H188V282.06Z"
          fill="url(#paint1_linear)"
        />
        <g filter="url(#6-4_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M192 214.999C192 213.895 191.105 213 190.001 213C188.896 213 188.001 213.895 188.001 214.999V217.167H188.001V228H188V231.001C188 232.105 188.895 233 189.999 233C191.104 233 191.999 232.105 191.999 231.001V228.833H192V218H192V214.999Z"
            fill="url(#paint2_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 192 217.167)"
          fill="#ABABAB"
        />
        <path
          d="M192 213.94C192 213.421 191.579 213 191.06 213H188.941C188.422 213 188.001 213.421 188.001 213.94V218H192V213.94Z"
          fill="url(#paint3_linear)"
        />
        <path
          d="M188 232.06C188 232.579 188.421 233 188.94 233H191.059C191.578 233 191.999 232.579 191.999 232.06V228H188V232.06Z"
          fill="url(#paint4_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 192 218.833)"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#6-4_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 209H115V287H188V209ZM177 220H126V276H177V220Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 209H115V287H116V210H187V287H188V209Z"
          fill="#FBFBFB"
        />
        <g filter="url(#6-4_filter3_i)">
          <rect
            x="126.47"
            y="220.47"
            width="50.0601"
            height="55.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="119.53"
          y="213.53"
          width="63.9399"
          height="68.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="117"
          y="240"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#6-4_filter4_d)">
          <rect x="117.702" y="240.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M118.286 251.795C118.798 252.457 119.6 252.884 120.502 252.884C122.048 252.884 123.302 251.63 123.302 250.084V243.444C123.302 242.455 122.79 241.587 122.016 241.088C122.382 241.562 122.6 242.155 122.6 242.8V249.44C122.6 250.986 121.347 252.24 119.8 252.24C119.242 252.24 118.722 252.077 118.286 251.795Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M120.502 240.644C118.956 240.644 117.702 241.898 117.702 243.444V249.44C117.702 249.46 117.702 249.48 117.703 249.501V244.024C117.703 242.477 118.956 241.224 120.503 241.224C122.029 241.224 123.269 242.444 123.302 243.963V243.444C123.302 241.898 122.048 240.644 120.502 240.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#6-4_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M120.5 244C119.672 244 119 244.672 119 245.5V245.714V247V265.5C119 266.328 119.672 267 120.5 267V267C121.328 267 122 266.328 122 265.5V249V245.5L122 245.286C122 244.576 121.424 244 120.714 244V244C120.677 244 120.64 244.001 120.604 244.004C120.569 244.001 120.535 244 120.5 244Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M175.5 274.138L128.036 247.016L175.5 221.831L175.5 274.138Z" stroke="white" />
        <path d="M175.224 274.5L151.5 222.21L127.776 274.5L175.224 274.5Z" stroke="white" />
        <defs>
          <filter
            id="6-4_filter0_d"
            x="186.12"
            y="258.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-4_filter1_d"
            x="186.12"
            y="211.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-4_filter2_d"
            x="110.3"
            y="204.3"
            width="84.2793"
            height="89.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-4_filter3_i"
            x="126"
            y="220"
            width="51.9399"
            height="58.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="6-4_filter4_d"
            x="115.822"
            y="238.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-4_filter5_d"
            x="117.12"
            y="242.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="194.8"
            y1="268.625"
            x2="187.2"
            y2="268.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="196.5"
            y1="280.604"
            x2="187"
            y2="280.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="194.8"
            y1="230.5"
            x2="187.2"
            y2="230.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="185.202"
            y1="215.5"
            x2="192.8"
            y2="215.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="194.798"
            y1="230.5"
            x2="187.2"
            y2="230.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
