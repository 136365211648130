import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement41Square4BottomCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-1_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M404.217 202C405.322 202 406.217 201.105 406.217 200.001C406.217 198.896 405.322 198.001 404.217 198.001L401.797 198.001L401.797 198.001L390.304 198.001L390.304 198L386.999 198C385.895 198 385 198.895 385 199.999C385 201.104 385.895 201.999 386.999 201.999L389.42 201.999L389.42 202L400.913 202L400.913 202L404.217 202Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="12.3767"
          transform="matrix(1.26506e-08 -1 -1 -1.12408e-08 401.797 202)"
          fill="#ABABAB"
        />
        <path
          d="M405.277 202C405.796 202 406.217 201.579 406.217 201.06L406.217 198.941C406.217 198.422 405.796 198.001 405.277 198.001L400.913 198.001L400.913 202L405.277 202Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M385.94 198C385.421 198 385 198.421 385 198.94L385 201.059C385 201.578 385.421 201.999 385.94 201.999L390.304 201.999L390.304 198L385.94 198Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.84048"
          transform="matrix(1.26506e-08 -1 -1 -1.12408e-08 400.028 202)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#4-1_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M62.8872 202C63.9915 202 64.8867 201.105 64.8867 200.001C64.8867 198.896 63.9915 198.001 62.8872 198.001L60.4666 198.001L60.4666 198.001L48.9739 198.001L48.9739 198L45.669 198C44.5648 198 43.6696 198.895 43.6696 199.999C43.6696 201.104 44.5648 201.999 45.669 201.999L48.0899 201.999L48.0899 202L59.5824 202L59.5824 202L62.8872 202Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="12.3767"
          transform="matrix(1.26506e-08 -1 -1 -1.12408e-08 60.4668 202)"
          fill="#ABABAB"
        />
        <path
          d="M63.9468 202C64.4659 202 64.8867 201.579 64.8867 201.06L64.8867 198.941C64.8867 198.422 64.4659 198.001 63.9468 198.001L59.5824 198.001L59.5824 202L63.9468 202Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M44.6099 198C44.0907 198 43.6699 198.421 43.6699 198.94L43.6699 201.059C43.6699 201.578 44.0907 201.999 44.6099 201.999L48.9742 201.999L48.9742 198L44.6099 198Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.84048"
          transform="matrix(1.26506e-08 -1 -1 -1.12408e-08 58.6982 202)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#4-1_filter2_i)">
          <rect
            x="43.47"
            y="213.47"
            width="362.06"
            height="62.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <g filter="url(#4-1_filter3_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 202H32V287H417V202ZM406 213H43V276H406V213Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 202H32V287H33V203H416V287H417V202Z"
          fill="#FBFBFB"
        />
        <rect
          x="36.53"
          y="206.53"
          width="375.94"
          height="75.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M47.0332 274.5L224 214.528L401.951 274.5H47.0332Z" stroke="white" />
        <rect
          x="237.897"
          y="278"
          width="7"
          height="14.3516"
          rx="3.5"
          transform="rotate(90 237.897 278)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="237.214"
          y="278.702"
          width="5.6"
          height="12.9848"
          rx="2.8"
          transform="rotate(90 237.214 278.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M237.088 280.668C236.733 279.529 235.67 278.702 234.414 278.702L227.029 278.702C225.483 278.702 224.229 279.956 224.229 281.502C224.229 281.546 224.23 281.59 224.232 281.634C224.587 282.773 225.65 283.6 226.906 283.6L234.291 283.6C235.837 283.6 237.091 282.346 237.091 280.8C237.091 280.756 237.09 280.712 237.088 280.668Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M235.719 279.024C235.599 279.008 235.476 279 235.352 279L227.967 279C226.42 279 225.167 280.254 225.167 281.8C225.167 282.875 225.773 283.809 226.662 284.278C225.289 284.098 224.229 282.924 224.229 281.502C224.229 279.956 225.483 278.702 227.029 278.702L234.414 278.702C234.885 278.702 235.329 278.818 235.719 279.024Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-1_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M233.654 281.5C233.654 280.672 232.983 280 232.154 280L231.94 280L230.35 280L210.755 280C209.926 280 209.255 280.672 209.255 281.5C209.255 282.328 209.926 283 210.755 283L228.35 283L232.154 283L232.369 283C233.079 283 233.654 282.424 233.654 281.714C233.654 281.677 233.653 281.64 233.651 281.604C233.653 281.569 233.654 281.535 233.654 281.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="4-1_filter0_d"
            x="383.12"
            y="196.12"
            width="26.8568"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-1_filter1_d"
            x="41.79"
            y="196.12"
            width="26.8568"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-1_filter2_i"
            x="43"
            y="213"
            width="363.94"
            height="65.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-1_filter3_d"
            x="27.3003"
            y="197.3"
            width="396.279"
            height="96.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-1_filter4_d"
            x="207.535"
            y="278.18"
            width="30.0397"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="387.652"
            y1="204.8"
            x2="387.652"
            y2="197.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="403.565"
            y1="195.202"
            x2="403.565"
            y2="202.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="387.652"
            y1="204.798"
            x2="387.652"
            y2="197.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.42024"
            x2="-0.799791"
            y2="4.42024"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="46.3217"
            y1="204.8"
            x2="46.3217"
            y2="197.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="62.2346"
            y1="195.202"
            x2="62.2346"
            y2="202.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="46.3221"
            y1="204.798"
            x2="46.3221"
            y2="197.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.42024"
            x2="-0.799791"
            y2="4.42024"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
