import { IStepByStepHelperItem } from './interface'

export const stepByStepHelperData: Record<string, IStepByStepHelperItem> = {
  'configuration-type': {
    value: 1,
    text: 'Форма окна и деление на створки',
  },
  'outer-sizes': {
    value: 2,
    text: 'Размеры изделия и его частей',
  },
  casements: {
    value: 3,
    text: 'Нажмите на створку для выбора типа открывания',
  },
  'mosquito-button': {
    value: 4,
    text: 'Установить москитную сетку/Удалить москитную сетку',
  },
  'window-model': {
    value: 5,
    text: 'Готовое решение от ZE',
  },
  functionality: {
    value: 6,
    text: 'Удобства использования изделия',
  },
  'window-color': {
    value: 7,
    text: 'Цвет внутри, снаружи и самого профиля в массе',
  },
  'window-hand': {
    value: 8,
    text: 'Модель и цвет ручек/накладок на петли',
  },
  windowsill: {
    value: 9,
    text: 'Декоративный подоконник',
  },
  slopes: {
    value: 10,
    text: 'Внутренняя /внешняя часть оконного проема, между рамой и стеной',
  },
  'low-tide': {
    value: 11,
    text: 'Декоративное устройство отвода воды с окна',
  },
  mosquito: {
    value: 12,
    text: 'Сетка защищающая от насекомых',
  },
}
