import styled, { css } from 'styled-components'

export const SuggestWrapper = styled.div`
  position: relative;
`

export const InputTitle = styled.div`
  font-size: 14px;
  line-height: 140%;
  color: #525252;

  margin-top: 6px;
`

export const SuggestList = styled.div<{ isShow: boolean }>`
  background-color: #ffffff;

  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);

  display: flex;
  flex-direction: column;

  position: absolute;

  top: 56px;
  left: 0;

  min-width: 200px;

  padding: 4px 0;

  opacity: 0;

  pointer-events: none;
  transform: translateY(-10px);

  transition: opacity 0.3s, transform 0.3s;

  z-index: 1;

  ${({ isShow }) =>
    isShow &&
    css`
      opacity: 1;

      transform: translateY(0);

      pointer-events: all;
    `}
`

export const SuggestItem = styled.div`
  background-color: transparent;

  flex: auto;

  overflow: hidden;

  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  white-space: nowrap;
  text-overflow: ellipsis;

  position: relative;

  min-height: 32px;

  padding: 7px 23px;

  transition: background-color 0.3s;

  cursor: pointer;

  &:hover {
    background-color: #e0e0e0;
  }
`
