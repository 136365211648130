import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement41Square82Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#6filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M182.001 99C183.105 99 184 98.1048 184 97.0005C184 95.8962 183.105 95.001 182.001 95.001L179.833 95.001L179.833 95.001L169 95.001L169 95L165.999 95C164.895 95 164 95.8952 164 96.9995C164 98.1038 164.895 98.999 165.999 98.999L168.167 98.999L168.167 99L179 99L179 99L182.001 99Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 179.833 99)"
          fill="#ABABAB"
        />
        <path
          d="M183.06 99C183.579 99 184 98.5792 184 98.0601L184 95.941C184 95.4219 183.579 95.001 183.06 95.001L179 95.001L179 99L183.06 99Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M164.94 95C164.421 95 164 95.4208 164 95.9399L164 98.059C164 98.5781 164.421 98.999 164.94 98.999L169 98.999L169 95L164.94 95Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 178.167 99)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#6filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M137.001 99C138.105 99 139 98.1048 139 97.0005C139 95.8962 138.105 95.001 137.001 95.001L134.833 95.001L134.833 95.001L124 95.001L124 95L120.999 95C119.895 95 119 95.8952 119 96.9995C119 98.1038 119.895 98.999 120.999 98.999L123.167 98.999L123.167 99L134 99L134 99L137.001 99Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.833 99)"
          fill="#ABABAB"
        />
        <path
          d="M138.06 99C138.579 99 139 98.5792 139 98.0601L139 95.941C139 95.4219 138.579 95.001 138.06 95.001L134 95.001L134 99L138.06 99Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M119.94 95C119.421 95 119 95.4208 119 95.9399L119 98.059C119 98.5781 119.421 98.999 119.94 98.999L124 98.999L124 95L119.94 95Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 133.167 99)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#6filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 99H115V154H188V99ZM178 109H125V144H178V109Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 99H115V154H116V100H187V154H188V99Z"
          fill="#FBFBFB"
        />
        <g filter="url(#6filter3_i)">
          <rect
            x="125.47"
            y="109.47"
            width="52.0601"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="119.53"
          y="103.53"
          width="63.9399"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M127.018 110.5L151.5 142.182L175.982 110.5L127.018 110.5Z" stroke="white" />
        <rect
          x="158"
          y="145"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 158 145)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="157.356"
          y="145.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 157.356 145.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M157.238 147.695C156.892 146.542 155.822 145.702 154.556 145.702L147.916 145.702C146.37 145.702 145.116 146.956 145.116 148.502C145.116 148.537 145.117 148.572 145.118 148.607C145.464 149.76 146.534 150.6 147.8 150.6L154.44 150.6C155.986 150.6 157.24 149.346 157.24 147.8C157.24 147.765 157.239 147.73 157.238 147.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M155.881 146.035C155.737 146.012 155.59 146 155.44 146L148.8 146C147.254 146 146 147.254 146 148.8C146 149.867 146.597 150.795 147.475 151.267C146.138 151.056 145.116 149.898 145.116 148.502C145.116 146.956 146.37 145.702 147.916 145.702L154.556 145.702C155.035 145.702 155.486 145.822 155.881 146.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#6filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M154 148.5C154 147.672 153.328 147 152.5 147L152.286 147L151 147L132.5 147C131.672 147 131 147.672 131 148.5V148.5C131 149.328 131.672 150 132.5 150L149 150L152.5 150L152.714 150C153.424 150 154 149.424 154 148.714V148.714C154 148.677 153.999 148.64 153.996 148.604C153.999 148.569 154 148.535 154 148.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="6filter0_d"
            x="162.12"
            y="93.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6filter1_d"
            x="117.12"
            y="93.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6filter2_d"
            x="110.3"
            y="94.3003"
            width="84.2793"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6filter3_i"
            x="125"
            y="109"
            width="53.9399"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="6filter4_d"
            x="129.28"
            y="145.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="166.5"
            y1="101.8"
            x2="166.5"
            y2="94.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="181.5"
            y1="92.2018"
            x2="181.5"
            y2="99.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="166.5"
            y1="101.798"
            x2="166.5"
            y2="94.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="121.5"
            y1="101.8"
            x2="121.5"
            y2="94.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="136.5"
            y1="92.2018"
            x2="136.5"
            y2="99.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="121.5"
            y1="101.798"
            x2="121.5"
            y2="94.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
