import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement33Square3VerticalCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#3filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.9765 286C36.8849 286 36 286.885 36 287.976C36 289.068 36.8849 289.953 37.9765 289.953L40.1665 289.953L40.1665 289.953L51 289.953L51 289.954L54.0235 289.954C55.1151 289.954 56 289.069 56 287.978C56 286.886 55.1151 286.001 54.0235 286.001L51.8332 286.001L51.8332 286L41 286L41 286L37.9765 286Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 40.1665 286)"
          fill="#ABABAB"
        />
        <path
          d="M36.9399 286C36.4208 286 36 286.421 36 286.94L36 289.013C36 289.532 36.4208 289.953 36.9399 289.953L41 289.953L41 286L36.9399 286Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M55.0601 289.954C55.5792 289.954 56 289.533 56 289.014L56 286.941C56 286.422 55.5792 286.001 55.0601 286.001L51 286.001L51 289.954L55.0601 289.954Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 41.8334 286)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#3filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M165.976 286C164.885 286 164 286.885 164 287.976C164 289.068 164.885 289.953 165.976 289.953L168.167 289.953L168.167 289.953L179 289.953L179 289.954L182.024 289.954C183.115 289.954 184 289.069 184 287.978C184 286.886 183.115 286.001 182.024 286.001L179.833 286.001L179.833 286L169 286L169 286L165.976 286Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 168.167 286)"
          fill="#ABABAB"
        />
        <path
          d="M164.94 286C164.421 286 164 286.421 164 286.94L164 289.013C164 289.532 164.421 289.953 164.94 289.953L169 289.953L169 286L164.94 286Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M183.06 289.954C183.579 289.954 184 289.533 184 289.014L184 286.941C184 286.422 183.579 286.001 183.06 286.001L179 286.001L179 289.954L183.06 289.954Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 169.833 286)"
          fill="url(#paint7_linear)"
        />
        <path d="M174.599 273.5L110 220.646L45.4007 273.5L174.599 273.5Z" stroke="white" />
        <g filter="url(#3filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 208H32V286H188V208ZM177 219H43V275H177V219Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 208H32V286H33V209H187V286H188V208Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3filter3_i)">
          <rect
            x="43.47"
            y="219.47"
            width="133.06"
            height="55.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="212.53"
          width="146.94"
          height="68.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="116.528"
          y="210"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 116.528 210)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#3filter4_d)">
          <rect
            x="115.884"
            y="210.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 115.884 210.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115.766 212.695C115.42 211.542 114.35 210.702 113.084 210.702L106.444 210.702C104.898 210.702 103.644 211.955 103.644 213.502C103.644 213.537 103.645 213.572 103.646 213.607C103.993 214.76 105.062 215.6 106.328 215.6L112.968 215.6C114.515 215.6 115.768 214.346 115.768 212.8C115.768 212.765 115.768 212.73 115.766 212.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M114.409 211.035C114.266 211.012 114.118 211 113.968 211L107.328 211C105.782 211 104.528 212.254 104.528 213.8C104.528 214.867 105.125 215.795 106.003 216.267C104.667 216.056 103.644 214.898 103.644 213.502C103.644 211.955 104.898 210.702 106.444 210.702L113.084 210.702C113.564 210.702 114.015 210.822 114.409 211.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M129.528 213.5C129.528 212.672 128.857 212 128.028 212L127.814 212L126.528 212L108.028 212C107.2 212 106.528 212.672 106.528 213.5V213.5C106.528 214.328 107.2 215 108.028 215L124.528 215L128.028 215L128.243 215C128.953 215 129.528 214.424 129.528 213.714V213.714C129.528 213.677 129.527 213.64 129.525 213.604C129.527 213.569 129.528 213.535 129.528 213.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="3filter0_d"
            x="34.1201"
            y="284.12"
            width="25.6396"
            height="9.59374"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3filter1_d"
            x="162.12"
            y="284.12"
            width="25.6396"
            height="9.59374"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3filter2_d"
            x="27.3003"
            y="203.3"
            width="167.279"
            height="89.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3filter3_i"
            x="43"
            y="219"
            width="134.94"
            height="58.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3filter4_d"
            x="101.764"
            y="208.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3filter5_d"
            x="105.649"
            y="210.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="53.5"
            y1="283.232"
            x2="53.5"
            y2="290.745"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="38.5"
            y1="292.72"
            x2="38.5"
            y2="285.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="53.5"
            y1="283.234"
            x2="53.5"
            y2="290.745"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="181.5"
            y1="283.232"
            x2="181.5"
            y2="290.745"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="166.5"
            y1="292.72"
            x2="166.5"
            y2="285.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="181.5"
            y1="283.234"
            x2="181.5"
            y2="290.745"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
