import { useState, useCallback, useEffect } from 'react'
import dayjs from 'dayjs'
import { ICalculateMountingPriceParams, IUseMountingPrice } from '../views/calculator/interface'
import fetchAPI from '../../lib/utils/fetch-api'
import { getRequestData, prepareDataFromSchema } from '../utilits'

const cache_collections = {}
const dates_collections = {}
async function fetchAPIcache(url) {
  const prevDate = dates_collections?.[url]
  const nextDate = dayjs().format('DD-hh')
  if (prevDate === nextDate && url in cache_collections) {
    return cache_collections[url]
  }
  const json = await fetchAPI(url)
  cache_collections[url] = json
  dates_collections[url] = nextDate
  return json
}

export const useMountingPrice: ({ products }) => IUseMountingPrice = ({ products }) => {
  const [mountingPrice, setMountingPrice] = useState(0)
  const [mountingPriceSeparately, setMountingPriceSeparately] = useState<Record<string, number>>({})
  const [rawMountingPriceList, setRawMountingPriceList] = useState<Record<string, []>>({})
  const [priceList, setPriceList] = useState<Record<string, Record<string, string>[]>>({})
  const [schemas, setSchemas] = useState<any[]>([])
  const [reCalculate, setReCalculate] = useState(false)
  const [mountingParams, setMountingParams] = useState<ICalculateMountingPriceParams>({})
  const [totalSquare, setTotalSquare] = useState(0)
  const [calculateInProcess, setCalculateInProcess] = useState(false)

  useEffect(() => {
    const fetchSchemas = async () => {
      const rawSchemas = await fetchAPI('/api/schemas?access_key=axioma&fields=properties')

      if (rawSchemas && rawSchemas['data'] && rawSchemas['data']['data']) {
        setSchemas(rawSchemas['data']['data'])
      }
    }

    fetchSchemas()
  }, [])

  useEffect(() => {
    const priceListKeys = Object.keys(rawMountingPriceList)
    const priceData: Record<string, Record<string, string>[]> = {}

    /** Prepare product price data */
    if (schemas.length > 0 && priceListKeys.length > 0) {
      const schema = schemas.find(s => s.name === 'mounting')

      priceListKeys.forEach(priceListKey => {
        priceData[priceListKey] = []
        for (const objectData of rawMountingPriceList[priceListKey]) {
          priceData[priceListKey].push(prepareDataFromSchema(schema, objectData))
        }
      })

      if (Object.keys(priceData).length > 0) {
        setPriceList(priceData)
        setReCalculate(true)
      } else {
        setCalculateInProcess(false)
      }
    } else {
      setCalculateInProcess(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schemas, rawMountingPriceList])

  const calculateSelfPrice = useCallback(({ price, margin = 0 }, totalPrice = true) => {
    let result = totalPrice ? price : 0

    if (margin) {
      result += price * (margin / 100)
    }

    return Math.floor(result)
  }, [])

  const getTargetPrice = useCallback(
    (priceType, findValue) => {
      return priceList[priceType].find(data => {
        let result = false

        if (mountingParams[priceType]) {
          result = data.name === `${findValue}`
        }

        return result
      })
    },
    [mountingParams, priceList],
  )

  useEffect(() => {
    if (reCalculate) {
      let result = 0
      let mountingTypePrice = 0
      let targetPriceData: any
      const priceSeparately: Record<string, number> = {}

      if (
        'mounting-type' in priceList &&
        priceList['mounting-type'] &&
        mountingParams['mounting-type'] &&
        mountingParams['mounting']
      ) {
        targetPriceData = getTargetPrice('mounting-type', mountingParams['mounting-type']['id'])

        if (targetPriceData) {
          const selfPrice = calculateSelfPrice({
            price: Number(targetPriceData['price']),
            margin: Number(targetPriceData['margin']),
          })

          mountingTypePrice = selfPrice * totalSquare
        }
      }

      if (
        'house-type' in priceList &&
        Array.isArray(priceList['house-type']) &&
        mountingParams['house-type']
      ) {
        targetPriceData = getTargetPrice('house-type', mountingParams['house-type']['id'])

        if (targetPriceData) {
          const selfPrice = Number(targetPriceData['price'])

          priceSeparately['mounting'] = Math.floor(
            (selfPrice / 100) * mountingTypePrice + mountingTypePrice,
          )
          result += (selfPrice / 100) * mountingTypePrice
        }
      }

      if ('demounting' in priceList && priceList['demounting']) {
        targetPriceData = getTargetPrice('demounting', mountingParams['demounting'])

        if (targetPriceData) {
          const selfPrice = calculateSelfPrice({
            price: Number(targetPriceData['price']),
            margin: Number(targetPriceData['margin']),
          })

          priceSeparately['demounting'] = Math.floor(selfPrice * totalSquare)
          result += selfPrice * totalSquare
        }
      }

      if ('garbage-removal' in priceList && priceList['garbage-removal']) {
        targetPriceData = getTargetPrice('garbage-removal', mountingParams['garbage-removal'])

        if (targetPriceData) {
          const selfPrice = calculateSelfPrice({
            price: Number(targetPriceData['price']),
            margin: Number(targetPriceData['margin']),
          })

          priceSeparately['garbage-removal'] = Math.floor(selfPrice)
          result += selfPrice
        }
      }

      if ('mounting-kit' in priceList) {
        targetPriceData = getTargetPrice('mounting-kit', mountingParams['mounting-kit'])
        // console.log({ targetPriceData })
        if (targetPriceData) {
          const selfPrice = calculateSelfPrice({
            price: Number(targetPriceData['price']),
            margin: Number(targetPriceData['margin']),
          })

          const countProducts = products.length || 1
          priceSeparately['mounting-kit'] = Math.floor(selfPrice)
          // Умножаем цену за комплект монтажа, на количество продуктов
          result += selfPrice * countProducts
        }
      }

      setMountingPriceSeparately(priceSeparately)
      setMountingPrice(Math.floor(mountingTypePrice + result))
    }

    setCalculateInProcess(false)
    setReCalculate(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reCalculate])






  const fetchCollections = useCallback(async (collectionNames: string[]) => {
    const inProcess: string[] = []
    const requestList: any[] = []
    let data: Record<string, any> = {}
    const getRequest = name => fetchAPIcache(`/api/collections/objects-by-name/${name}`)

    for (const name of collectionNames) {
      requestList.push(getRequest(`${name}`))
      inProcess.push(name)
    }

    const requestsResult = await Promise.all(requestList)
    data = getRequestData(requestsResult, inProcess)

    setRawMountingPriceList(prevState => ({
      ...prevState,
      ...data,
    }))
  }, [])

  const handleCalculatePrice = useCallback(
    (params: ICalculateMountingPriceParams) => {
      const filteredParams: Record<string, any> = {}
      const mountingKeys: string[] = []

      setCalculateInProcess(true)

      Object.keys(params).forEach(key => {
        if ((typeof params[key] !== 'boolean' || params[key]) && key !== 'itemsSquare') {
          filteredParams[key] = params[key]
          mountingKeys.push(key)
        }
      })

      if (params.itemsSquare) {
        setTotalSquare(params.itemsSquare)
      }

      if (mountingKeys.length > 0) {
        setMountingParams(filteredParams)
        fetchCollections(mountingKeys)
      } else {
        setCalculateInProcess(false)
        setMountingPrice(0)
      }
    },
    [fetchCollections],
  )

  return {
    mountingPrice,
    mountingPriceSeparately,
    calculateMountingPrice: handleCalculatePrice,
    calculateInProcess,
  }
}
