import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement46Square4VerticalCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-4_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M191.954 236.976C191.954 235.885 191.069 235 189.977 235C188.886 235 188.001 235.885 188.001 236.976L188.001 239.167L188.001 239.167L188.001 250L188 250L188 253.024C188 254.115 188.885 255 189.976 255C191.068 255 191.953 254.115 191.953 253.024L191.953 250.833L191.954 250.833L191.954 240L191.954 240L191.954 236.976Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 191.954 239.167)"
          fill="#ABABAB"
        />
        <path
          d="M191.954 235.94C191.954 235.421 191.533 235 191.014 235L188.941 235C188.422 235 188.001 235.421 188.001 235.94L188.001 240L191.954 240L191.954 235.94Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M188 254.06C188 254.579 188.421 255 188.94 255L191.013 255C191.532 255 191.953 254.579 191.953 254.06L191.953 250L188 250L188 254.06Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 191.954 240.833)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#4-4_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M191.954 263.976C191.954 262.885 191.069 262 189.977 262C188.886 262 188.001 262.885 188.001 263.976L188.001 266.167L188.001 266.167L188.001 277L188 277L188 280.024C188 281.115 188.885 282 189.976 282C191.068 282 191.953 281.115 191.953 280.024L191.953 277.833L191.954 277.833L191.954 267L191.954 267L191.954 263.976Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 191.954 266.167)"
          fill="#ABABAB"
        />
        <path
          d="M191.954 262.94C191.954 262.421 191.533 262 191.014 262L188.941 262C188.422 262 188.001 262.421 188.001 262.94L188.001 267L191.954 267L191.954 262.94Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M188 281.06C188 281.579 188.421 282 188.94 282L191.013 282C191.532 282 191.953 281.579 191.953 281.06L191.953 277L188 277L188 281.06Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 191.954 267.833)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#4-4_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 231H32V286H188V231ZM178 241H42V276H178V241Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 231H32V286H33V232H187V286H188V231Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-4_filter3_i)">
          <rect
            x="42.47"
            y="241.47"
            width="135.06"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="235.53"
          width="146.94"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          width="6.91954"
          height="13.5283"
          rx="3.45977"
          transform="matrix(-1 0 0 1 40.9194 252)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#4-4_filter4_d)">
          <rect
            width="5.53563"
            height="12.2399"
            rx="2.76782"
            transform="matrix(-1 0 0 1 40.2253 252.644)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.6604 263.792C39.1548 264.456 38.3562 264.884 37.4577 264.884C35.9291 264.884 34.6899 263.645 34.6899 262.116V255.412C34.6899 254.44 35.1907 253.585 35.9482 253.092C35.5936 253.557 35.383 254.138 35.383 254.768V261.472C35.383 263.001 36.6222 264.24 38.1508 264.24C38.7078 264.24 39.2262 264.075 39.6604 263.792Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.4575 252.644C38.9861 252.644 40.2253 253.883 40.2253 255.412V261.472C40.2253 261.492 40.2251 261.512 40.2247 261.533V255.991C40.2247 254.463 38.9855 253.224 37.4569 253.224C35.9485 253.224 34.722 254.43 34.6897 255.931V255.412C34.6897 253.883 35.9289 252.644 37.4575 252.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-4_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.4597 256C38.2787 256 38.9425 256.664 38.9425 257.483V259C38.9425 259.014 38.9424 259.027 38.9421 259.041L38.9421 277.517C38.9421 278.336 38.2782 279 37.4593 279V279C36.6404 279 35.9766 278.336 35.9766 277.517L35.9766 257.251C35.9766 256.56 36.5368 256 37.2278 256V256C37.2307 256 37.2335 256 37.2363 256C37.3107 256 37.3853 256 37.4597 256V256Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M176.5 274.435L47.0913 258.5L176.5 242.565L176.5 274.435Z" stroke="white" />
        <defs>
          <filter
            id="4-4_filter0_d"
            x="186.12"
            y="233.12"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-4_filter1_d"
            x="186.12"
            y="260.12"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-4_filter2_d"
            x="27.3003"
            y="226.3"
            width="167.279"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-4_filter3_i"
            x="42"
            y="241"
            width="136.94"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-4_filter4_d"
            x="32.8098"
            y="250.764"
            width="11.1753"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-4_filter5_d"
            x="34.0967"
            y="254.12"
            width="8.60556"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="194.722"
            y1="252.5"
            x2="187.209"
            y2="252.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="185.234"
            y1="237.5"
            x2="192.744"
            y2="237.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="194.72"
            y1="252.5"
            x2="187.209"
            y2="252.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="194.722"
            y1="279.5"
            x2="187.209"
            y2="279.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="185.234"
            y1="264.5"
            x2="192.744"
            y2="264.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="194.72"
            y1="279.5"
            x2="187.209"
            y2="279.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
