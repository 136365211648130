import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement23Square62Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-3_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.0005 121C55.1048 121 56 120.105 56 119.001C56 117.896 55.1048 117.001 54.0005 117.001L51.8335 117.001L51.8335 117.001L41 117.001L41 117L37.9995 117C36.8952 117 36 117.895 36 118.999C36 120.104 36.8952 120.999 37.9995 120.999L40.1668 120.999L40.1668 121L51 121L51 121L54.0005 121Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 51.8335 121)"
          fill="#ABABAB"
        />
        <path
          d="M55.0601 121C55.5792 121 56 120.579 56 120.06L56 117.941C56 117.422 55.5792 117.001 55.0601 117.001L51 117.001L51 121L55.0601 121Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M36.9399 117C36.4208 117 36 117.421 36 117.94L36 120.059C36 120.578 36.4208 120.999 36.9399 120.999L41 120.999L41 117L36.9399 117Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 50.1666 121)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#2-3_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M99.0005 121C100.105 121 101 120.105 101 119.001C101 117.896 100.105 117.001 99.0005 117.001L96.8335 117.001L96.8335 117.001L86 117.001L86 117L82.9995 117C81.8952 117 81 117.895 81 118.999C81 120.104 81.8952 120.999 82.9995 120.999L85.1668 120.999L85.1668 121L96 121L96 121L99.0005 121Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 96.8335 121)"
          fill="#ABABAB"
        />
        <path
          d="M100.06 121C100.579 121 101 120.579 101 120.06L101 117.941C101 117.422 100.579 117.001 100.06 117.001L96 117.001L96 121L100.06 121Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M81.9399 117C81.4208 117 81 117.421 81 117.94L81 120.059C81 120.578 81.4208 120.999 81.9399 120.999L86 120.999L86 117L81.9399 117Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 95.1666 121)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#2-3_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M105 121H32V199H105V121ZM94 132H43V188H94V132Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105 121H32V199H33V122H104V199H105V121Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-3_filter3_i)">
          <rect
            x="43.47"
            y="132.47"
            width="50.0601"
            height="55.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="125.53"
          width="63.9399"
          height="68.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M44.7759 133.5L68.5 185.79L92.2241 133.5L44.7759 133.5Z" stroke="white" />
        <rect
          x="75"
          y="190"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 75 190)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="74.356"
          y="190.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 74.356 190.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74.2379 192.695C73.8915 191.542 72.8218 190.702 71.5559 190.702L64.916 190.702C63.3696 190.702 62.116 191.955 62.116 193.502C62.116 193.537 62.1167 193.572 62.118 193.607C62.4643 194.76 63.534 195.6 64.8 195.6L71.4399 195.6C72.9863 195.6 74.2399 194.346 74.2399 192.8C74.2399 192.765 74.2392 192.73 74.2379 192.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72.881 191.034C72.7373 191.012 72.59 191 72.4399 191L65.8 191C64.2536 191 63 192.253 63 193.8C63 194.867 63.5969 195.795 64.475 196.267C63.1382 196.056 62.1161 194.898 62.1161 193.502C62.1161 191.955 63.3697 190.702 64.9161 190.702L71.556 190.702C72.0353 190.702 72.4865 190.822 72.881 191.034Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-3_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M71 193.5C71 192.672 70.3284 192 69.5 192L69.2857 192L68 192L49.5 192C48.6716 192 48 192.672 48 193.5V193.5C48 194.328 48.6716 195 49.5 195L66 195L69.5 195L69.7143 195C70.4244 195 71 194.424 71 193.714V193.714C71 193.677 70.9988 193.64 70.9965 193.604C70.9988 193.569 71 193.535 71 193.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="2-3_filter0_d"
            x="34.1201"
            y="115.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-3_filter1_d"
            x="79.1201"
            y="115.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-3_filter2_d"
            x="27.3003"
            y="116.3"
            width="84.2793"
            height="89.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-3_filter3_i"
            x="43"
            y="132"
            width="51.9399"
            height="58.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-3_filter4_d"
            x="46.28"
            y="190.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="38.5"
            y1="123.8"
            x2="38.5"
            y2="116.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="53.5"
            y1="114.202"
            x2="53.5"
            y2="121.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="38.5"
            y1="123.798"
            x2="38.5"
            y2="116.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="83.5"
            y1="123.8"
            x2="83.5"
            y2="116.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="98.5"
            y1="114.202"
            x2="98.5"
            y2="121.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="83.5"
            y1="123.798"
            x2="83.5"
            y2="116.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
