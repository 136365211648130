import React, { FC, useMemo, useCallback } from 'react'

import { Checkbox } from '../../../components'
import { ICheckboxItem, IOrderNotificationsBlockProps } from '../stage-calculator/interface'
import { orderNotification } from './consts'
import {
  OrderNotificationsBlockWrapper,
  OrderNotificationsBlockTitle,
  OrderNotificationsBlockContent,
  OrderNotificationsBlockContentItem,
} from './styles'

export const OrderNotificationsBlock: FC<IOrderNotificationsBlockProps> = props => {
  const { data, onChangeNotification } = props

  const notifications = useMemo(() => {
    const result: ICheckboxItem[] = []

    if (
      data &&
      data['specification-products'] &&
      data['specification-products']['source'] &&
      data['specification-products']['source']['orderAdditionalData'] &&
      data['specification-products']['source']['orderAdditionalData']['notifications']
    ) {
      const { notifications: orderNotifications } = data['specification-products']['source'][
        'orderAdditionalData'
      ]

      orderNotification.forEach(notification => {
        result.push({
          ...notification,
          value: orderNotifications[notification['code']] || false,
        })
      })
    }

    return result
  }, [data])

  const handleOnChange = useCallback(
    notification => {
      const newNotifications = {}

      notifications.forEach(notify => {
        newNotifications[notify['code']] = notify['value']
      })

      newNotifications[notification['code']] = notification['value']

      onChangeNotification(newNotifications)
    },
    [notifications, onChangeNotification],
  )

  return (
    <OrderNotificationsBlockWrapper>
      <OrderNotificationsBlockTitle>Уведомления о статусе заказа</OrderNotificationsBlockTitle>
      <OrderNotificationsBlockContent>
        {notifications.map(notification => (
          <OrderNotificationsBlockContentItem key={`order-notification-${notification['code']}`}>
            <Checkbox
              id={notification['code']}
              items={[notification]}
              selected={notification}
              onSelect={handleOnChange}
            />
          </OrderNotificationsBlockContentItem>
        ))}
      </OrderNotificationsBlockContent>
    </OrderNotificationsBlockWrapper>
  )
}
