import React, { FC } from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'
import { IContainerSquareProps } from '../../interface'
import { ContainerBackground } from '../container-background'

export const ContainerSquare3VerticalCasement: FC<IContainerSquareProps> = props => {
  const { onCasementClick, withoutBackground } = props

  return (
    <ProductImageWrapper isClickable>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <rect width="220" height="333" fill="white" />
        <rect x="10" y="18" width="186" height="286" fill="#E8EAEE" />
        <rect x="10" y="18" width="186" height="286" fill="url(#3-v-pattern0)" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M210 10H10V292H24V28H196V292H210V10Z"
          fill="#C4C4C4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M210 292H10V308H0V316H10H210H220V308H210V292Z"
          fill="url(#3-v-paint0_linear)"
        />
        <path d="M10 10L24 28.1525V291.982L10 308V10Z" fill="#E9EBEF" />
        <path d="M10 10L24 28.1525V291.982L10 308V10Z" fill="#020202" fillOpacity="0.06" />
        <path d="M210 10L196 28.1525V291.982L210 308V10Z" fill="#E9EBEF" />
        <path d="M210 10L196 28.1525V291.982L210 308V10Z" fill="#020202" fillOpacity="0.06" />
        <rect y="316" width="220" height="17" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M196 27H24V292H196V27ZM183 40H37V106H183V40ZM37 127H183V193H37V127ZM183 212H37V278H183V212Z"
          fill="#E8EAEE"
        />
        <g
          filter="url(#3-v-filter0_i)"
          className="casement"
          data-index="0"
          onClick={onCasementClick(0)}
        >
          <rect x="37" y="39" width="146" height="67" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="37.47"
          y="39.47"
          width="145.06"
          height="66.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#3-v-filter1_i)"
          className="casement"
          data-index="1"
          onClick={onCasementClick(1)}
        >
          <rect x="37" y="127" width="146" height="67" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="37.47"
          y="127.47"
          width="145.06"
          height="66.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#3-v-filter2_i)"
          className="casement"
          data-index="2"
          onClick={onCasementClick(2)}
        >
          <rect x="37" y="212" width="146" height="66" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="37.47"
          y="212.47"
          width="145.06"
          height="65.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <path
          d="M118.985 243.741H116.447C115.8 243.741 115.253 243.234 115.253 242.636V242.405C115.253 241.806 115.8 241.3 116.447 241.3H118.985C119.632 241.3 120.179 241.806 120.179 242.405V242.636C120.179 243.257 119.657 243.741 118.985 243.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M101.426 246.565C101.725 246.565 101.799 246.565 102.197 246.565C102.943 246.565 103.49 247.105 103.49 247.833C103.49 248.584 102.918 249.101 102.147 249.101C100.705 249.101 99.8098 249.101 98.3674 249.101C97.8203 249.101 97.3727 248.913 97.1488 248.42C96.9499 248.115 96.8255 247.27 97.6213 246.565C98.1436 245.978 103.913 239.757 104.933 238.677C104.982 238.606 104.933 238.512 104.858 238.512C104.833 238.512 104.833 238.512 104.808 238.512C102.645 238.512 100.481 238.512 98.3177 238.512C97.6711 238.512 97.1737 238.137 97.0494 237.573C96.9002 236.916 97.1986 236.352 97.7706 236.094C97.9446 236.023 98.1187 236 98.2928 236C101.501 236 104.684 236 107.892 236C108.514 236 108.937 236.188 109.185 236.728C109.509 237.409 109.16 237.878 108.937 238.183C108.489 238.723 103.938 243.56 101.426 246.283C101.401 246.307 101.376 246.354 101.327 246.377C101.277 246.448 101.327 246.565 101.426 246.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M108.186 249.101H105.648C105.001 249.101 104.453 248.582 104.453 247.968V247.732C104.453 247.119 105.001 246.6 105.648 246.6H108.186C108.832 246.6 109.38 247.119 109.38 247.732V247.968C109.38 248.605 108.832 249.101 108.186 249.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M111.715 242.515C111.715 240.734 111.715 238.976 111.715 237.195C111.715 236.492 112.237 236 112.983 236C116.215 236 119.446 236 122.678 236C123.424 236 123.946 236.516 123.946 237.242C123.946 237.969 123.424 238.508 122.678 238.508C119.993 238.508 117.333 238.508 114.649 238.508C114.45 238.508 114.4 238.555 114.4 238.742C114.4 241.273 114.4 243.804 114.4 246.335C114.4 246.546 114.475 246.57 114.649 246.57C117.309 246.57 119.993 246.57 122.653 246.57C123.25 246.57 123.722 246.898 123.896 247.39C124.095 247.976 123.871 248.562 123.399 248.89C123.175 249.03 122.902 249.101 122.628 249.101C119.446 249.101 116.24 249.101 113.058 249.101C112.237 249.101 111.74 248.585 111.74 247.812C111.715 246.031 111.715 244.273 111.715 242.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M98.7054 254.042V254.698H97V254.042H97.4263V252.259H97.0502V251.602H98.6803V252.259H98.3041V254.042H98.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M102.534 254.758H101.774L101.651 254.545C101.381 254.687 101.062 254.758 100.768 254.758C99.9334 254.734 99.1482 254.167 99.1482 253.15C99.1482 252.181 99.8107 251.542 100.817 251.542C101.651 251.542 102.461 252.086 102.461 253.15C102.461 253.528 102.363 253.812 102.215 254.072L102.559 254.64V254.758H102.534ZM100.792 254.001C101.185 254.001 101.602 253.718 101.602 253.15C101.602 252.583 101.185 252.299 100.792 252.299C100.375 252.299 99.9824 252.583 99.9824 253.15C99.9824 253.718 100.375 254.001 100.792 254.001Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M104.784 253.749C104.784 253.96 105.029 254.077 105.299 254.077C105.545 254.077 105.79 253.96 105.79 253.796C105.79 253.49 105.422 253.49 105.201 253.467C104.612 253.42 104.022 253.209 104.022 252.552C104.022 251.871 104.612 251.542 105.275 251.542C105.889 251.542 106.527 251.871 106.527 252.552H105.717C105.717 252.34 105.496 252.246 105.275 252.246C105.078 252.246 104.833 252.293 104.833 252.528C104.833 252.739 105.029 252.786 105.324 252.81C106.011 252.857 106.601 253.021 106.601 253.772C106.601 254.429 105.938 254.758 105.275 254.758C104.587 254.758 103.924 254.429 103.949 253.749H104.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M109.229 252.311H108.038V252.76H109.13V253.469H108.038V253.966H109.254V254.698H107.169V251.602H109.204V252.311H109.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M112.603 254.58V254.698H111.722L111.159 253.729H110.719V254.698H109.887V251.602C110.376 251.602 110.841 251.602 111.306 251.602C112.089 251.602 112.456 252.169 112.456 252.713C112.456 253.067 112.285 253.422 111.967 253.611L112.603 254.58ZM111.306 253.067C111.551 253.067 111.649 252.878 111.649 252.666C111.649 252.477 111.526 252.287 111.306 252.287H110.719V253.067H111.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M114.276 253.334L114.553 252.527L114.956 251.602H115.887V251.721L114.452 254.758H114.1L112.666 251.721V251.602H113.597L114.024 252.527L114.276 253.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M117.781 254.042V254.698H116.076V254.042H116.502V252.259H116.126V251.602H117.756V252.259H117.38V254.042H117.781Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M119.835 251.602C120.509 251.602 121.258 251.955 121.383 252.78H120.559C120.434 252.45 120.184 252.332 119.835 252.332C119.335 252.332 119.011 252.662 119.011 253.18C119.011 253.627 119.335 254.028 119.835 254.028C120.184 254.028 120.484 253.863 120.559 253.533H121.383C121.258 254.405 120.534 254.758 119.835 254.758C118.986 254.758 118.162 254.216 118.162 253.18C118.162 252.12 118.986 251.602 119.835 251.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M123.944 252.311H122.753V252.76H123.845V253.469H122.753V253.966H123.969V254.698H121.885V251.602H123.919V252.311H123.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M118.985 70.7412H116.447C115.8 70.7412 115.253 70.2345 115.253 69.6356V69.4053C115.253 68.8064 115.8 68.2997 116.447 68.2997H118.985C119.632 68.2997 120.179 68.8064 120.179 69.4053V69.6356C120.179 70.2575 119.657 70.7412 118.985 70.7412Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M101.426 73.5652C101.725 73.5652 101.799 73.5652 102.197 73.5652C102.943 73.5652 103.49 74.1052 103.49 74.833C103.49 75.5843 102.918 76.1008 102.147 76.1008C100.705 76.1008 99.8098 76.1008 98.3674 76.1008C97.8203 76.1008 97.3727 75.913 97.1488 75.4199C96.9499 75.1147 96.8255 74.2695 97.6213 73.5652C98.1436 72.9782 103.913 66.7565 104.933 65.6765C104.982 65.6061 104.933 65.5122 104.858 65.5122C104.833 65.5122 104.833 65.5122 104.808 65.5122C102.645 65.5122 100.481 65.5122 98.3177 65.5122C97.6711 65.5122 97.1737 65.1365 97.0494 64.573C96.9002 63.9156 97.1986 63.3522 97.7706 63.0939C97.9446 63.0235 98.1187 63 98.2928 63C101.501 63 104.684 63 107.892 63C108.514 63 108.937 63.1878 109.185 63.7278C109.509 64.4087 109.16 64.8783 108.937 65.1835C108.489 65.7235 103.938 70.56 101.426 73.2834C101.401 73.3069 101.376 73.3539 101.327 73.3773C101.277 73.4478 101.327 73.5652 101.426 73.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M108.186 76.1009H105.648C105.001 76.1009 104.453 75.5818 104.453 74.9684V74.7324C104.453 74.1189 105.001 73.5999 105.648 73.5999H108.186C108.832 73.5999 109.38 74.1189 109.38 74.7324V74.9684C109.38 75.6054 108.832 76.1009 108.186 76.1009Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M111.715 69.5152C111.715 67.7341 111.715 65.9764 111.715 64.1952C111.715 63.4922 112.237 63 112.983 63C116.215 63 119.446 63 122.678 63C123.424 63 123.946 63.5156 123.946 64.2421C123.946 64.9686 123.424 65.5077 122.678 65.5077C119.993 65.5077 117.333 65.5077 114.649 65.5077C114.45 65.5077 114.4 65.5545 114.4 65.742C114.4 68.2731 114.4 70.8042 114.4 73.3353C114.4 73.5463 114.475 73.5697 114.649 73.5697C117.309 73.5697 119.993 73.5697 122.653 73.5697C123.25 73.5697 123.722 73.8978 123.896 74.39C124.095 74.9759 123.871 75.5618 123.399 75.8899C123.175 76.0305 122.902 76.1008 122.628 76.1008C119.446 76.1008 116.24 76.1008 113.058 76.1008C112.237 76.1008 111.74 75.5852 111.74 74.8118C111.715 73.0307 111.715 71.273 111.715 69.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M98.7054 81.0416V81.6985H97V81.0416H97.4263V79.2588H97.0502V78.6019H98.6803V79.2588H98.3041V81.0416H98.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M102.534 81.7579H101.774L101.651 81.5451C101.381 81.687 101.062 81.7579 100.768 81.7579C99.9334 81.7342 99.1482 81.1668 99.1482 80.1501C99.1482 79.1806 99.8107 78.5422 100.817 78.5422C101.651 78.5422 102.461 79.0861 102.461 80.1501C102.461 80.5284 102.363 80.8121 102.215 81.0722L102.559 81.6397V81.7579H102.534ZM100.792 81.0013C101.185 81.0013 101.602 80.7175 101.602 80.1501C101.602 79.5826 101.185 79.2989 100.792 79.2989C100.375 79.2989 99.9824 79.5826 99.9824 80.1501C99.9824 80.7175 100.375 81.0013 100.792 81.0013Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M104.784 80.7486C104.784 80.9598 105.029 81.0772 105.299 81.0772C105.545 81.0772 105.79 80.9598 105.79 80.7955C105.79 80.4904 105.422 80.4904 105.201 80.4669C104.612 80.42 104.022 80.2087 104.022 79.5515C104.022 78.8708 104.612 78.5422 105.275 78.5422C105.889 78.5422 106.527 78.8708 106.527 79.5515H105.717C105.717 79.3403 105.496 79.2464 105.275 79.2464C105.078 79.2464 104.833 79.2933 104.833 79.5281C104.833 79.7393 105.029 79.7862 105.324 79.8097C106.011 79.8567 106.601 80.021 106.601 80.7721C106.601 81.4293 105.938 81.7579 105.275 81.7579C104.587 81.7579 103.924 81.4293 103.949 80.7486H104.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M109.229 79.3111H108.038V79.7602H109.13V80.4693H108.038V80.9657H109.254V81.6985H107.169V78.6019H109.204V79.3111H109.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M112.603 81.5803V81.6985H111.722L111.159 80.7293H110.719V81.6985H109.887V78.6019C110.376 78.6019 110.841 78.6019 111.306 78.6019C112.089 78.6019 112.456 79.1692 112.456 79.7129C112.456 80.0675 112.285 80.422 111.967 80.6111L112.603 81.5803ZM111.306 80.0675C111.551 80.0675 111.649 79.8784 111.649 79.6656C111.649 79.4765 111.526 79.2874 111.306 79.2874H110.719V80.0675H111.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M114.276 80.3342L114.553 79.5274L114.956 78.6019H115.887V78.7206L114.452 81.758H114.1L112.666 78.7206V78.6019H113.597L114.024 79.5274L114.276 80.3342Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M117.781 81.0416V81.6985H116.076V81.0416H116.502V79.2588H116.126V78.6019H117.756V79.2588H117.38V81.0416H117.781Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M119.835 78.6019C120.509 78.6019 121.258 78.9552 121.383 79.7796H120.559C120.434 79.4498 120.184 79.3321 119.835 79.3321C119.335 79.3321 119.011 79.6618 119.011 80.18C119.011 80.6275 119.335 81.0279 119.835 81.0279C120.184 81.0279 120.484 80.863 120.559 80.5333H121.383C121.258 81.4047 120.534 81.758 119.835 81.758C118.986 81.758 118.162 81.2163 118.162 80.18C118.162 79.1201 118.986 78.6019 119.835 78.6019Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M123.944 79.3111H122.753V79.7602H123.845V80.4693H122.753V80.9657H123.969V81.6985H121.885V78.6019H123.919V79.3111H123.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <defs>
          <pattern id="3-v-pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use
              xlinkHref="#image0"
              transform="translate(-2.07767 -0.027972) scale(0.00351032 0.0022839)"
            />
          </pattern>
          <filter
            id="3-v-filter0_i"
            x="37"
            y="39"
            width="146.94"
            height="69.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-v-filter1_i"
            x="37"
            y="127"
            width="146.94"
            height="69.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-v-filter2_i"
            x="37"
            y="212"
            width="146.94"
            height="68.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <linearGradient
            id="3-v-paint0_linear"
            x1="301.5"
            y1="291"
            x2="301.5"
            y2="316"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DBDDE1" />
            <stop offset="1" stopColor="#F9F9F9" />
          </linearGradient>
        </defs>
      </svg>
      {!withoutBackground && <ContainerBackground />}
    </ProductImageWrapper>
  )
}
