import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement22Square52Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M151.001 115C152.105 115 153 114.105 153 113.001C153 111.896 152.105 111.001 151.001 111.001L148.833 111.001L148.833 111.001L138 111.001L138 111L134.999 111C133.895 111 133 111.895 133 112.999C133 114.104 133.895 114.999 134.999 114.999L137.167 114.999L137.167 115L148 115L148 115L151.001 115Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 148.833 115)"
          fill="#ABABAB"
        />
        <path
          d="M152.06 115C152.579 115 153 114.579 153 114.06L153 111.941C153 111.422 152.579 111.001 152.06 111.001L148 111.001L148 115L152.06 115Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M133.94 111C133.421 111 133 111.421 133 111.94L133 114.059C133 114.578 133.421 114.999 133.94 114.999L138 114.999L138 111L133.94 111Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 147.167 115)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M313.001 115C314.105 115 315 114.105 315 113.001C315 111.896 314.105 111.001 313.001 111.001L310.833 111.001L310.833 111.001L300 111.001L300 111L296.999 111C295.895 111 295 111.895 295 112.999C295 114.104 295.895 114.999 296.999 114.999L299.167 114.999L299.167 115L310 115L310 115L313.001 115Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 310.833 115)"
          fill="#ABABAB"
        />
        <path
          d="M314.06 115C314.579 115 315 114.579 315 114.06L315 111.941C315 111.422 314.579 111.001 314.06 111.001L310 111.001L310 115L314.06 115Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M295.94 111C295.421 111 295 111.421 295 111.94L295 114.059C295 114.578 295.421 114.999 295.94 114.999L300 114.999L300 111L295.94 111Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 309.167 115)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M321 111H127V34H321V111ZM310 100H138V45H310V100Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#filter3_i)">
          <rect
            x="0.46997"
            y="-0.46997"
            width="171.06"
            height="54.0601"
            transform="matrix(1 0 0 -1 138 99.0601)"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="0.5"
          y="-0.5"
          width="183"
          height="66"
          transform="matrix(1 0 0 -1 132 105)"
          stroke="#E2E4E8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M321 111H127V34H128V110H320V34H321V111Z"
          fill="#FBFBFB"
        />
        <path d="M140.747 98.5H307.253L224 46.5892L140.747 98.5Z" stroke="white" />
        <rect
          x="230.528"
          y="36"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 230.528 36)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#filter4_d)">
          <rect
            x="229.884"
            y="36.7012"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 229.884 36.7012)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M229.766 38.6944C229.42 37.5413 228.35 36.7012 227.084 36.7012L220.444 36.7012C218.898 36.7012 217.644 37.9548 217.644 39.5012C217.644 39.5363 217.645 39.5713 217.646 39.6062C217.993 40.7593 219.062 41.5994 220.328 41.5994L226.968 41.5994C228.514 41.5994 229.768 40.3458 229.768 38.7994C229.768 38.7642 229.767 38.7292 229.766 38.6944Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M228.409 37.0339C228.266 37.0112 228.118 36.9994 227.968 36.9994L221.328 36.9994C219.782 36.9994 218.528 38.253 218.528 39.7994C218.528 40.8664 219.125 41.7941 220.003 42.2666C218.666 42.0551 217.644 40.8975 217.644 39.5012C217.644 37.9548 218.898 36.7012 220.444 36.7012L227.084 36.7012C227.564 36.7012 228.015 36.8216 228.409 37.0339Z"
          fill="#FBFBFB"
        />
        <g filter="url(#filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M243.528 39.5C243.528 38.6716 242.857 38 242.028 38L241.814 38L240.528 38L222.028 38C221.2 38 220.528 38.6716 220.528 39.5C220.528 40.3284 221.2 41 222.028 41L238.528 41L242.028 41L242.243 41C242.953 41 243.528 40.4244 243.528 39.7143C243.528 39.6771 243.527 39.6401 243.525 39.6035C243.527 39.5693 243.528 39.5348 243.528 39.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="131.12"
            y="109.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter1_d"
            x="293.12"
            y="109.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter2_d"
            x="122.3"
            y="29.3003"
            width="205.279"
            height="88.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter3_i"
            x="138"
            y="45"
            width="172.94"
            height="57.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter4_d"
            x="215.765"
            y="34.8213"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter5_d"
            x="219.649"
            y="36.1201"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="135.5"
            y1="117.8"
            x2="135.5"
            y2="110.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="150.5"
            y1="108.202"
            x2="150.5"
            y2="115.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="135.5"
            y1="117.798"
            x2="135.5"
            y2="110.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="297.5"
            y1="117.8"
            x2="297.5"
            y2="110.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="312.5"
            y1="108.202"
            x2="312.5"
            y2="115.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="297.5"
            y1="117.798"
            x2="297.5"
            y2="110.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
