import React, { FC } from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'
import { IContainerSquareProps } from '../../interface'
import { ContainerBackground } from '../container-background'

export const ContainerDoor1LCasement1: FC<IContainerSquareProps> = props => {
  const { onCasementClick, withoutBackground } = props

  return (
    <ProductImageWrapper isClickable>
      <svg
        width="451"
        height="451"
        viewBox="0 0 451 451"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g clipPath="url(#door-1-l-casement-1-container-clip0)">
          <rect width="451" height="451" fill="white" />
          <rect x="-81" y="-1" width="647" height="426" fill="#E8EAEE" />
          <rect
            x="-81"
            y="-1"
            width="647"
            height="426"
            fill="url(#door-1-l-casement-1-container-pattern0)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M451 -1H0V451H451V-1ZM186 11H441V323.274H186V441H10V323.274V11H186Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 10H441V28H188V441H10V10ZM24 28H186V425H24V28Z"
            fill="#C4C4C4"
          />
          <path d="M10 10L24 28.1525V424.982L10 441V10Z" fill="#E9EBEF" />
          <path d="M10 10L24 28.1525V424.982L10 441V10Z" fill="#020202" fillOpacity="0.06" />
          <rect x="198" y="343" width="255" height="108" fill="#E8EAEE" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 28H24V425H188V28ZM166 41H37V412H166V41Z"
            fill="#E8EAEE"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M427 28H186V325H427V28ZM413 41H191V294H413V41Z"
            fill="#E8EAEE"
          />
          <g
            filter="url(#door-1-l-casement-1-container-filter0_i)"
            className="casement"
            data-index="0"
            onClick={onCasementClick(0)}
          >
            <rect x="37" y="41" width="129" height="371" fill="#B8C1FD" fillOpacity="0.4" />
          </g>
          <rect
            x="37.47"
            y="41.47"
            width="128.06"
            height="370.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
          <path
            d="M109.985 115.741H107.447C106.8 115.741 106.253 115.234 106.253 114.636V114.405C106.253 113.806 106.8 113.3 107.447 113.3H109.985C110.632 113.3 111.179 113.806 111.179 114.405V114.636C111.179 115.257 110.657 115.741 109.985 115.741Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M92.4262 118.565C92.7247 118.565 92.7993 118.565 93.1972 118.565C93.9432 118.565 94.4903 119.105 94.4903 119.833C94.4903 120.584 93.9184 121.101 93.1474 121.101C91.705 121.101 90.8098 121.101 89.3674 121.101C88.8203 121.101 88.3727 120.913 88.1488 120.42C87.9499 120.115 87.8255 119.27 88.6213 118.565C89.1436 117.978 94.9131 111.757 95.9327 110.677C95.9824 110.606 95.9327 110.512 95.8581 110.512C95.8332 110.512 95.8332 110.512 95.8084 110.512C93.6448 110.512 91.4812 110.512 89.3177 110.512C88.6711 110.512 88.1737 110.137 88.0494 109.573C87.9002 108.916 88.1986 108.352 88.7706 108.094C88.9446 108.023 89.1187 108 89.2928 108C92.5008 108 95.684 108 98.8921 108C99.5138 108 99.9366 108.188 100.185 108.728C100.509 109.409 100.16 109.878 99.9366 110.183C99.4889 110.723 94.938 115.56 92.4262 118.283C92.4014 118.307 92.3765 118.354 92.3268 118.377C92.277 118.448 92.3268 118.565 92.4262 118.565Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M99.1856 121.101H96.6476C96.0007 121.101 95.4533 120.582 95.4533 119.968V119.732C95.4533 119.119 96.0007 118.6 96.6476 118.6H99.1856C99.8325 118.6 100.38 119.119 100.38 119.732V119.968C100.38 120.605 99.8325 121.101 99.1856 121.101Z"
            fill="#141B4D"
            fillOpacity="0.63"
            stroke="#767676"
            strokeWidth="0.0812062"
            strokeMiterlimit="10"
          />
          <path
            d="M102.715 114.515C102.715 112.734 102.715 110.976 102.715 109.195C102.715 108.492 103.237 108 103.983 108C107.215 108 110.446 108 113.678 108C114.424 108 114.946 108.516 114.946 109.242C114.946 109.969 114.424 110.508 113.678 110.508C110.993 110.508 108.333 110.508 105.649 110.508C105.45 110.508 105.4 110.555 105.4 110.742C105.4 113.273 105.4 115.804 105.4 118.335C105.4 118.546 105.475 118.57 105.649 118.57C108.309 118.57 110.993 118.57 113.653 118.57C114.25 118.57 114.722 118.898 114.896 119.39C115.095 119.976 114.871 120.562 114.399 120.89C114.175 121.03 113.902 121.101 113.628 121.101C110.446 121.101 107.24 121.101 104.058 121.101C103.237 121.101 102.74 120.585 102.74 119.812C102.715 118.031 102.715 116.273 102.715 114.515Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M89.7054 126.042V126.698H88V126.042H88.4263V124.259H88.0502V123.602H89.6803V124.259H89.3041V126.042H89.7054Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M93.5344 126.758H92.7737L92.651 126.545C92.3811 126.687 92.0621 126.758 91.7676 126.758C90.9334 126.734 90.1482 126.167 90.1482 125.15C90.1482 124.181 90.8107 123.542 91.8167 123.542C92.651 123.542 93.4607 124.086 93.4607 125.15C93.4607 125.528 93.3626 125.812 93.2154 126.072L93.5589 126.64V126.758H93.5344ZM91.7922 126.001C92.1848 126.001 92.6019 125.718 92.6019 125.15C92.6019 124.583 92.1848 124.299 91.7922 124.299C91.375 124.299 90.9824 124.583 90.9824 125.15C90.9824 125.718 91.375 126.001 91.7922 126.001Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M95.7835 125.749C95.7835 125.96 96.0291 126.077 96.2992 126.077C96.5448 126.077 96.7904 125.96 96.7904 125.796C96.7904 125.49 96.422 125.49 96.201 125.467C95.6116 125.42 95.0223 125.209 95.0223 124.552C95.0223 123.871 95.6116 123.542 96.2747 123.542C96.8886 123.542 97.5271 123.871 97.5271 124.552H96.7167C96.7167 124.34 96.4957 124.246 96.2747 124.246C96.0782 124.246 95.8326 124.293 95.8326 124.528C95.8326 124.739 96.0291 124.786 96.3238 124.81C97.0114 124.857 97.6007 125.021 97.6007 125.772C97.6007 126.429 96.9377 126.758 96.2747 126.758C95.5871 126.758 94.924 126.429 94.9486 125.749H95.7835Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M100.229 124.311H99.0377V124.76H100.13V125.469H99.0377V125.966H100.254V126.698H98.1692V123.602H100.204V124.311H100.229Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M103.603 126.58V126.698H102.722L102.159 125.729H101.719V126.698H100.887V123.602C101.376 123.602 101.841 123.602 102.306 123.602C103.089 123.602 103.456 124.169 103.456 124.713C103.456 125.067 103.285 125.422 102.967 125.611L103.603 126.58ZM102.306 125.067C102.551 125.067 102.649 124.878 102.649 124.666C102.649 124.476 102.526 124.287 102.306 124.287H101.719V125.067H102.306Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M105.276 125.334L105.553 124.527L105.956 123.602H106.887V123.721L105.452 126.758H105.1L103.666 123.721V123.602H104.597L105.024 124.527L105.276 125.334Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M108.781 126.042V126.698H107.076V126.042H107.502V124.259H107.126V123.602H108.756V124.259H108.38V126.042H108.781Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M110.835 123.602C111.509 123.602 112.258 123.955 112.383 124.78H111.559C111.434 124.45 111.184 124.332 110.835 124.332C110.335 124.332 110.011 124.662 110.011 125.18C110.011 125.627 110.335 126.028 110.835 126.028C111.184 126.028 111.484 125.863 111.559 125.533H112.383C112.258 126.405 111.534 126.758 110.835 126.758C109.986 126.758 109.162 126.216 109.162 125.18C109.162 124.12 109.986 123.602 110.835 123.602Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M114.944 124.311H113.753V124.76H114.845V125.469H113.753V125.966H114.969V126.698H112.885V123.602H114.919V124.311H114.944Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <g
            filter="url(#door-1-l-casement-1-container-filter1_i)"
            className="casement"
            data-index="1"
            onClick={onCasementClick(1)}
          >
            <rect x="191" y="41" width="223" height="253" fill="#B8C1FD" fillOpacity="0.4" />
          </g>
          <rect
            x="191.47"
            y="41.47"
            width="222.06"
            height="252.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
          <path
            d="M311.985 115.741H309.447C308.8 115.741 308.253 115.234 308.253 114.636V114.405C308.253 113.806 308.8 113.3 309.447 113.3H311.985C312.632 113.3 313.179 113.806 313.179 114.405V114.636C313.179 115.257 312.657 115.741 311.985 115.741Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M294.426 118.565C294.725 118.565 294.799 118.565 295.197 118.565C295.943 118.565 296.49 119.105 296.49 119.833C296.49 120.584 295.918 121.101 295.147 121.101C293.705 121.101 292.81 121.101 291.367 121.101C290.82 121.101 290.373 120.913 290.149 120.42C289.95 120.115 289.826 119.27 290.621 118.565C291.144 117.978 296.913 111.757 297.933 110.677C297.982 110.606 297.933 110.512 297.858 110.512C297.833 110.512 297.833 110.512 297.808 110.512C295.645 110.512 293.481 110.512 291.318 110.512C290.671 110.512 290.174 110.137 290.049 109.573C289.9 108.916 290.199 108.352 290.771 108.094C290.945 108.023 291.119 108 291.293 108C294.501 108 297.684 108 300.892 108C301.514 108 301.937 108.188 302.185 108.728C302.509 109.409 302.16 109.878 301.937 110.183C301.489 110.723 296.938 115.56 294.426 118.283C294.401 118.307 294.376 118.354 294.327 118.377C294.277 118.448 294.327 118.565 294.426 118.565Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M301.186 121.101H298.648C298.001 121.101 297.453 120.582 297.453 119.968V119.732C297.453 119.119 298.001 118.6 298.648 118.6H301.186C301.832 118.6 302.38 119.119 302.38 119.732V119.968C302.38 120.605 301.832 121.101 301.186 121.101Z"
            fill="#141B4D"
            fillOpacity="0.63"
            stroke="#767676"
            strokeWidth="0.0812062"
            strokeMiterlimit="10"
          />
          <path
            d="M304.715 114.515C304.715 112.734 304.715 110.976 304.715 109.195C304.715 108.492 305.237 108 305.983 108C309.215 108 312.446 108 315.678 108C316.424 108 316.946 108.516 316.946 109.242C316.946 109.969 316.424 110.508 315.678 110.508C312.993 110.508 310.333 110.508 307.649 110.508C307.45 110.508 307.4 110.555 307.4 110.742C307.4 113.273 307.4 115.804 307.4 118.335C307.4 118.546 307.475 118.57 307.649 118.57C310.309 118.57 312.993 118.57 315.653 118.57C316.25 118.57 316.722 118.898 316.896 119.39C317.095 119.976 316.871 120.562 316.399 120.89C316.175 121.03 315.902 121.101 315.628 121.101C312.446 121.101 309.24 121.101 306.058 121.101C305.237 121.101 304.74 120.585 304.74 119.812C304.715 118.031 304.715 116.273 304.715 114.515Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M291.705 126.042V126.698H290V126.042H290.426V124.259H290.05V123.602H291.68V124.259H291.304V126.042H291.705Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M295.534 126.758H294.774L294.651 126.545C294.381 126.687 294.062 126.758 293.768 126.758C292.933 126.734 292.148 126.167 292.148 125.15C292.148 124.181 292.811 123.542 293.817 123.542C294.651 123.542 295.461 124.086 295.461 125.15C295.461 125.528 295.363 125.812 295.215 126.072L295.559 126.64V126.758H295.534ZM293.792 126.001C294.185 126.001 294.602 125.718 294.602 125.15C294.602 124.583 294.185 124.299 293.792 124.299C293.375 124.299 292.982 124.583 292.982 125.15C292.982 125.718 293.375 126.001 293.792 126.001Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M297.784 125.749C297.784 125.96 298.029 126.077 298.299 126.077C298.545 126.077 298.79 125.96 298.79 125.796C298.79 125.49 298.422 125.49 298.201 125.467C297.612 125.42 297.022 125.209 297.022 124.552C297.022 123.871 297.612 123.542 298.275 123.542C298.889 123.542 299.527 123.871 299.527 124.552H298.717C298.717 124.34 298.496 124.246 298.275 124.246C298.078 124.246 297.833 124.293 297.833 124.528C297.833 124.739 298.029 124.786 298.324 124.81C299.011 124.857 299.601 125.021 299.601 125.772C299.601 126.429 298.938 126.758 298.275 126.758C297.587 126.758 296.924 126.429 296.949 125.749H297.784Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M302.229 124.311H301.038V124.76H302.13V125.469H301.038V125.966H302.254V126.698H300.169V123.602H302.204V124.311H302.229Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M305.603 126.58V126.698H304.722L304.159 125.729H303.719V126.698H302.887V123.602C303.376 123.602 303.841 123.602 304.306 123.602C305.089 123.602 305.456 124.169 305.456 124.713C305.456 125.067 305.285 125.422 304.967 125.611L305.603 126.58ZM304.306 125.067C304.551 125.067 304.649 124.878 304.649 124.666C304.649 124.476 304.526 124.287 304.306 124.287H303.719V125.067H304.306Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M307.276 125.334L307.553 124.527L307.956 123.602H308.887V123.721L307.452 126.758H307.1L305.666 123.721V123.602H306.597L307.024 124.527L307.276 125.334Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M310.781 126.042V126.698H309.076V126.042H309.502V124.259H309.126V123.602H310.756V124.259H310.38V126.042H310.781Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M312.835 123.602C313.509 123.602 314.258 123.955 314.383 124.78H313.559C313.434 124.45 313.184 124.332 312.835 124.332C312.335 124.332 312.011 124.662 312.011 125.18C312.011 125.627 312.335 126.028 312.835 126.028C313.184 126.028 313.484 125.863 313.559 125.533H314.383C314.258 126.405 313.534 126.758 312.835 126.758C311.986 126.758 311.162 126.216 311.162 125.18C311.162 124.12 311.986 123.602 312.835 123.602Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M316.944 124.311H315.753V124.76H316.845V125.469H315.753V125.966H316.969V126.698H314.885V123.602H316.919V124.311H316.944Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M440.838 307H185L184 331H215.75H440.838H451V323H440.838V307Z"
            fill="url(#door-1-l-casement-1-container-paint0_linear)"
          />
          <path d="M441 10L427 28.0571V307.075L441 323V10Z" fill="#E9EBEF" />
          <path d="M441 10L427 28.0571V307.075L441 323V10Z" fill="#020202" fillOpacity="0.06" />
          <g filter="url(#door-1-l-casement-1-container-filter2_d)">
            <rect x="184" y="331" width="267" height="12" fill="white" />
          </g>
        </g>
        <defs>
          <pattern
            id="door-1-l-casement-1-container-pattern0"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
          >
            <use
              xlinkHref="#image0"
              transform="translate(-0.00768784) scale(0.00100915 0.00153332)"
            />
          </pattern>
          <filter
            id="door-1-l-casement-1-container-filter0_i"
            x="37"
            y="41"
            width="129.94"
            height="373.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="door-1-l-casement-1-container-filter1_i"
            x="191"
            y="41"
            width="223.94"
            height="255.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="door-1-l-casement-1-container-filter2_d"
            x="178.94"
            y="328.94"
            width="279"
            height="24"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="3.94" />
            <feGaussianBlur stdDeviation="3" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="door-1-l-casement-1-container-paint0_linear"
            x1="276.741"
            y1="306"
            x2="276.741"
            y2="331"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DBDDE1" />
            <stop offset="1" stopColor="#F9F9F9" />
          </linearGradient>
          <clipPath id="door-1-l-casement-1-container-clip0">
            <rect width="451" height="451" fill="white" />
          </clipPath>
        </defs>
      </svg>

      {!withoutBackground && <ContainerBackground />}
    </ProductImageWrapper>
  )
}
