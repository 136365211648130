import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement13Square62Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#1-3_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.0005 33C55.1048 33 56 32.1048 56 31.0005C56 29.8962 55.1048 29.001 54.0005 29.001L51.8335 29.001L51.8335 29.001L41 29.001L41 29L37.9995 29C36.8952 29 36 29.8952 36 30.9995C36 32.1038 36.8952 32.999 37.9995 32.999L40.1668 32.999L40.1668 33L51 33L51 33L54.0005 33Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 51.8335 33)"
          fill="#ABABAB"
        />
        <path
          d="M55.0601 33C55.5792 33 56 32.5792 56 32.0601L56 29.941C56 29.4219 55.5792 29.001 55.0601 29.001L51 29.001L51 33L55.0601 33Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M36.9399 29C36.4208 29 36 29.4208 36 29.9399L36 32.059C36 32.5781 36.4208 32.999 36.9399 32.999L41 32.999L41 29L36.9399 29Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 50.1666 33)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#1-3_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M99.0005 33C100.105 33 101 32.1048 101 31.0005C101 29.8962 100.105 29.001 99.0005 29.001L96.8335 29.001L96.8335 29.001L86 29.001L86 29L82.9995 29C81.8952 29 81 29.8952 81 30.9995C81 32.1038 81.8952 32.999 82.9995 32.999L85.1668 32.999L85.1668 33L96 33L96 33L99.0005 33Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 96.8335 33)"
          fill="#ABABAB"
        />
        <path
          d="M100.06 33C100.579 33 101 32.5792 101 32.0601L101 29.941C101 29.4219 100.579 29.001 100.06 29.001L96 29.001L96 33L100.06 33Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M81.9399 29C81.4208 29 81 29.4208 81 29.9399L81 32.059C81 32.5781 81.4208 32.999 81.9399 32.999L86 32.999L86 29L81.9399 29Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 95.1666 33)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#1-3_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M105 33H32V111H105V33ZM94 44H43V100H94V44Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105 33H32V111H33V34H104V111H105V33Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-3_filter3_i)">
          <rect
            x="43.47"
            y="44.47"
            width="50.0601"
            height="55.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="37.53"
          width="63.9399"
          height="68.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M44.7759 45.5L68.5 97.7898L92.2241 45.5L44.7759 45.5Z" stroke="white" />
        <rect
          x="75"
          y="102"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 75 102)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="74.356"
          y="102.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 74.356 102.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74.2379 104.695C73.8915 103.542 72.8218 102.702 71.5559 102.702L64.916 102.702C63.3696 102.702 62.116 103.955 62.116 105.502C62.116 105.537 62.1167 105.572 62.118 105.607C62.4643 106.76 63.534 107.6 64.8 107.6L71.4399 107.6C72.9863 107.6 74.2399 106.346 74.2399 104.8C74.2399 104.765 74.2392 104.73 74.2379 104.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72.881 103.034C72.7373 103.012 72.59 103 72.4399 103L65.8 103C64.2536 103 63 104.253 63 105.8C63 106.867 63.5969 107.795 64.475 108.267C63.1382 108.056 62.1161 106.898 62.1161 105.502C62.1161 103.955 63.3697 102.702 64.9161 102.702L71.556 102.702C72.0353 102.702 72.4865 102.822 72.881 103.034Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-3_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M71 105.5C71 104.672 70.3284 104 69.5 104L69.2857 104L68 104L49.5 104C48.6716 104 48 104.672 48 105.5V105.5C48 106.328 48.6716 107 49.5 107L66 107L69.5 107L69.7143 107C70.4244 107 71 106.424 71 105.714V105.714C71 105.677 70.9988 105.64 70.9965 105.604C70.9988 105.569 71 105.535 71 105.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="1-3_filter0_d"
            x="34.1201"
            y="27.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-3_filter1_d"
            x="79.1201"
            y="27.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-3_filter2_d"
            x="27.3003"
            y="28.3003"
            width="84.2793"
            height="89.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-3_filter3_i"
            x="43"
            y="44"
            width="51.9399"
            height="58.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="1-3_filter4_d"
            x="46.28"
            y="102.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="38.5"
            y1="35.8"
            x2="38.5"
            y2="28.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="53.5"
            y1="26.2018"
            x2="53.5"
            y2="33.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="38.5"
            y1="35.7982"
            x2="38.5"
            y2="28.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="83.5"
            y1="35.8"
            x2="83.5"
            y2="28.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="98.5"
            y1="26.2018"
            x2="98.5"
            y2="33.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="83.5"
            y1="35.7982"
            x2="83.5"
            y2="28.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
