import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement36Square3VerticalCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#3filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M192 213.999C192 212.895 191.105 212 190.001 212C188.896 212 188.001 212.895 188.001 213.999V216.167H188.001V227H188V230.001C188 231.105 188.895 232 189.999 232C191.104 232 191.999 231.105 191.999 230.001V227.833H192V217H192V213.999Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 192 216.167)"
          fill="#ABABAB"
        />
        <path
          d="M192 212.94C192 212.421 191.579 212 191.06 212H188.941C188.422 212 188.001 212.421 188.001 212.94V217H192V212.94Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M188 231.06C188 231.579 188.421 232 188.94 232H191.059C191.578 232 191.999 231.579 191.999 231.06V227H188V231.06Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 192 217.833)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#3filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M192 263.999C192 262.895 191.105 262 190.001 262C188.896 262 188.001 262.895 188.001 263.999V266.167H188.001V277H188V280.001C188 281.105 188.895 282 189.999 282C191.104 282 191.999 281.105 191.999 280.001V277.833H192V267H192V263.999Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 192 266.167)"
          fill="#ABABAB"
        />
        <path
          d="M192 262.94C192 262.421 191.579 262 191.06 262H188.941C188.422 262 188.001 262.421 188.001 262.94V267H192V262.94Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M188 281.06C188 281.579 188.421 282 188.94 282H191.059C191.578 282 191.999 281.579 191.999 281.06V277H188V281.06Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 192 267.833)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#3filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 208H32V286H188V208ZM177 219H43V275H177V219Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 208H32V286H33V209H187V286H188V208Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3filter3_i)">
          <rect
            x="43.47"
            y="219.47"
            width="133.06"
            height="55.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="212.53"
          width="146.94"
          height="68.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M175.5 220.613L46.495 247L175.5 273.387L175.5 220.613Z" stroke="white" />
        <rect
          x="34"
          y="240"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#3filter4_d)">
          <rect x="34.7018" y="240.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.286 251.795C35.7981 252.458 36.6004 252.884 37.5022 252.884C39.0486 252.884 40.3022 251.63 40.3022 250.084V243.444C40.3022 242.456 39.79 241.587 39.0166 241.089C39.3826 241.562 39.6004 242.155 39.6004 242.8V249.44C39.6004 250.986 38.3468 252.24 36.8004 252.24C36.2424 252.24 35.7226 252.077 35.286 251.795Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.5018 240.644C35.9554 240.644 34.7018 241.898 34.7018 243.444V249.44C34.7018 249.46 34.702 249.48 34.7024 249.501V244.024C34.7024 242.477 35.956 241.224 37.5024 241.224C39.0285 241.224 40.2693 242.444 40.3018 243.963V243.444C40.3018 241.898 39.0482 240.644 37.5018 240.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.5 244C36.6716 244 36 244.672 36 245.5V245.714V247V265.5C36 266.328 36.6716 267 37.5 267V267C38.3284 267 39 266.328 39 265.5V249V245.5L39 245.286C39 244.576 38.4244 244 37.7143 244V244C37.6771 244 37.6401 244.001 37.6035 244.004C37.5693 244.001 37.5348 244 37.5 244Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="3filter0_d"
            x="186.12"
            y="210.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3filter1_d"
            x="186.12"
            y="260.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3filter2_d"
            x="27.3003"
            y="203.3"
            width="167.279"
            height="89.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3filter3_i"
            x="43"
            y="219"
            width="134.94"
            height="58.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3filter4_d"
            x="32.8219"
            y="238.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3filter5_d"
            x="34.1201"
            y="242.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="194.8"
            y1="229.5"
            x2="187.2"
            y2="229.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="185.202"
            y1="214.5"
            x2="192.8"
            y2="214.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="194.798"
            y1="229.5"
            x2="187.2"
            y2="229.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="194.8"
            y1="279.5"
            x2="187.2"
            y2="279.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="185.202"
            y1="264.5"
            x2="192.8"
            y2="264.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="194.798"
            y1="279.5"
            x2="187.2"
            y2="279.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
