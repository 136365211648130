import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement22Square5VerticalCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-2_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M50.0005 86C51.1048 86 52 85.1048 52 84.0005C52 82.8962 51.1048 82.001 50.0005 82.001L47.8335 82.001L47.8335 82.001L37 82.001L37 82L33.9995 82C32.8952 82 32 82.8952 32 83.9995C32 85.1038 32.8952 85.999 33.9995 85.999L36.1668 85.999L36.1668 86L47 86L47 86L50.0005 86Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 47.8335 86)"
          fill="#ABABAB"
        />
        <path
          d="M51.0601 86C51.5792 86 52 85.5792 52 85.0601L52 82.941C52 82.4219 51.5792 82.001 51.0601 82.001L47 82.001L47 86L51.0601 86Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M32.9399 82C32.4208 82 32 82.4208 32 82.9399L32 85.059C32 85.5781 32.4208 85.999 32.9399 85.999L37 85.999L37 82L32.9399 82Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 46.1666 86)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#2-2_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M186.001 86C187.105 86 188 85.1048 188 84.0005C188 82.8962 187.105 82.001 186.001 82.001L183.833 82.001L183.833 82.001L173 82.001L173 82L169.999 82C168.895 82 168 82.8952 168 83.9995C168 85.1038 168.895 85.999 169.999 85.999L172.167 85.999L172.167 86L183 86L183 86L186.001 86Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 183.833 86)"
          fill="#ABABAB"
        />
        <path
          d="M187.06 86C187.579 86 188 85.5792 188 85.0601L188 82.941C188 82.4219 187.579 82.001 187.06 82.001L183 82.001L183 86L187.06 86Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M168.94 82C168.421 82 168 82.4208 168 82.9399L168 85.059C168 85.5781 168.421 85.999 168.94 85.999L173 85.999L173 82L168.94 82Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 182.167 86)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#2-2_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M192 86H28V132H192V86ZM183 95H37V123H183V95Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M192 86H28V132H29V87H191V132H192V86Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-2_filter3_i)">
          <rect
            x="37.47"
            y="95.47"
            width="145.06"
            height="27.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="32.53"
          y="90.53"
          width="154.94"
          height="36.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="117"
          y="124"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 117 124)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="116.356"
          y="124.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 116.356 124.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M116.238 126.695C115.892 125.542 114.822 124.702 113.556 124.702L106.916 124.702C105.37 124.702 104.116 125.955 104.116 127.502C104.116 127.537 104.117 127.572 104.118 127.607C104.464 128.76 105.534 129.6 106.8 129.6L113.44 129.6C114.986 129.6 116.24 128.346 116.24 126.8C116.24 126.765 116.239 126.73 116.238 126.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M114.881 125.034C114.737 125.012 114.59 125 114.44 125L107.8 125C106.254 125 105 126.253 105 127.8C105 128.867 105.597 129.795 106.475 130.267C105.138 130.056 104.116 128.898 104.116 127.502C104.116 125.955 105.37 124.702 106.916 124.702L113.556 124.702C114.035 124.702 114.487 124.822 114.881 125.034Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-2_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M113 127.5C113 126.672 112.328 126 111.5 126L111.286 126L110 126L91.5 126C90.6716 126 90 126.672 90 127.5V127.5C90 128.328 90.6716 129 91.5 129L108 129L111.5 129L111.714 129C112.424 129 113 128.424 113 127.714V127.714C113 127.677 112.999 127.64 112.996 127.604C112.999 127.569 113 127.535 113 127.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M179.143 96.5L110 121.468L40.8567 96.5H179.143Z" stroke="white" />
        <defs>
          <filter
            id="2-2_filter0_d"
            x="30.1201"
            y="80.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-2_filter1_d"
            x="166.12"
            y="80.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-2_filter2_d"
            x="23.3003"
            y="81.3003"
            width="175.279"
            height="57.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-2_filter3_i"
            x="37"
            y="95"
            width="146.94"
            height="30.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-2_filter4_d"
            x="88.28"
            y="124.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="34.5"
            y1="88.8"
            x2="34.5"
            y2="81.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="49.5"
            y1="79.2018"
            x2="49.5"
            y2="86.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="34.5"
            y1="88.7982"
            x2="34.5"
            y2="81.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="170.5"
            y1="88.8"
            x2="170.5"
            y2="81.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="185.5"
            y1="79.2018"
            x2="185.5"
            y2="86.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="170.5"
            y1="88.7982"
            x2="170.5"
            y2="81.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
