import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement53Square82Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#3filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M99.0005 165C100.105 165 101 164.105 101 163.001C101 161.896 100.105 161.001 99.0005 161.001L96.8335 161.001L96.8335 161.001L86 161.001L86 161L82.9995 161C81.8952 161 81 161.895 81 162.999C81 164.104 81.8952 164.999 82.9995 164.999L85.1668 164.999L85.1668 165L96 165L96 165L99.0005 165Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 96.8335 165)"
          fill="#ABABAB"
        />
        <path
          d="M100.06 165C100.579 165 101 164.579 101 164.06L101 161.941C101 161.422 100.579 161.001 100.06 161.001L96 161.001L96 165L100.06 165Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M81.9399 161C81.4208 161 81 161.421 81 161.94L81 164.059C81 164.578 81.4208 164.999 81.9399 164.999L86 164.999L86 161L81.9399 161Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 95.1666 165)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#3filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.0005 165C55.1048 165 56 164.105 56 163.001C56 161.896 55.1048 161.001 54.0005 161.001L51.8335 161.001L51.8335 161.001L41 161.001L41 161L37.9995 161C36.8952 161 36 161.895 36 162.999C36 164.104 36.8952 164.999 37.9995 164.999L40.1668 164.999L40.1668 165L51 165L51 165L54.0005 165Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 51.8335 165)"
          fill="#ABABAB"
        />
        <path
          d="M55.0601 165C55.5792 165 56 164.579 56 164.06L56 161.941C56 161.422 55.5792 161.001 55.0601 161.001L51 161.001L51 165L55.0601 165Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M36.9399 161C36.4208 161 36 161.421 36 161.94L36 164.059C36 164.578 36.4208 164.999 36.9399 164.999L41 164.999L41 161L36.9399 161Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 50.1666 165)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#3filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M105 165H32V220H105V165ZM95 175H42V210H95V175Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105 165H32V220H33V166H104V220H105V165Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3filter3_i)">
          <rect
            x="42.47"
            y="175.47"
            width="52.0601"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="169.53"
          width="63.9399"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M44.0182 176.5L68.5 208.182L92.9818 176.5L44.0182 176.5Z" stroke="white" />
        <rect
          x="75"
          y="211"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 75 211)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="74.356"
          y="211.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 74.356 211.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74.2379 213.695C73.8915 212.542 72.8218 211.702 71.5559 211.702L64.916 211.702C63.3696 211.702 62.116 212.956 62.116 214.502C62.116 214.537 62.1167 214.572 62.118 214.607C62.4643 215.76 63.534 216.6 64.8 216.6L71.4399 216.6C72.9863 216.6 74.2399 215.346 74.2399 213.8C74.2399 213.765 74.2392 213.73 74.2379 213.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72.881 212.035C72.7373 212.012 72.59 212 72.4399 212L65.8 212C64.2536 212 63 213.254 63 214.8C63 215.867 63.5969 216.795 64.475 217.267C63.1382 217.056 62.1161 215.898 62.1161 214.502C62.1161 212.956 63.3697 211.702 64.9161 211.702L71.556 211.702C72.0353 211.702 72.4865 211.822 72.881 212.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M71 214.5C71 213.672 70.3284 213 69.5 213L69.2857 213L68 213L49.5 213C48.6716 213 48 213.672 48 214.5V214.5C48 215.328 48.6716 216 49.5 216L66 216L69.5 216L69.7143 216C70.4244 216 71 215.424 71 214.714V214.714C71 214.677 70.9988 214.64 70.9965 214.604C70.9988 214.569 71 214.535 71 214.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="3filter0_d"
            x="79.1201"
            y="159.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3filter1_d"
            x="34.1201"
            y="159.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3filter2_d"
            x="27.3003"
            y="160.3"
            width="84.2793"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3filter3_i"
            x="42"
            y="175"
            width="53.9399"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3filter4_d"
            x="46.28"
            y="211.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="83.5"
            y1="167.8"
            x2="83.5"
            y2="160.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="98.5"
            y1="158.202"
            x2="98.5"
            y2="165.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="83.5"
            y1="167.798"
            x2="83.5"
            y2="160.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="38.5"
            y1="167.8"
            x2="38.5"
            y2="160.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="53.5"
            y1="158.202"
            x2="53.5"
            y2="165.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="38.5"
            y1="167.798"
            x2="38.5"
            y2="160.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
