import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement13Square4Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="315"
        height="333"
        viewBox="0 0 315 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#235filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M58.0005 109C59.1048 109 60 108.105 60 107.001C60 105.896 59.1048 105.001 58.0005 105.001L55.8335 105.001L55.8335 105.001L45 105.001L45 105L41.9995 105C40.8952 105 40 105.895 40 106.999C40 108.104 40.8952 108.999 41.9995 108.999L44.1668 108.999L44.1668 109L55 109L55 109L58.0005 109Z"
            fill="url(#235paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 55.8335 109)"
          fill="#ABABAB"
        />
        <path
          d="M59.0601 109C59.5792 109 60 108.579 60 108.06L60 105.941C60 105.422 59.5792 105.001 59.0601 105.001L55 105.001L55 109L59.0601 109Z"
          fill="url(#235paint1_linear)"
        />
        <path
          d="M40.9399 105C40.4208 105 40 105.421 40 105.94L40 108.059C40 108.578 40.4208 108.999 40.9399 108.999L45 108.999L45 105L40.9399 105Z"
          fill="url(#235paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 54.1666 109)"
          fill="url(#235paint3_linear)"
        />
        <g filter="url(#235filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M144.001 109C145.105 109 146 108.105 146 107.001C146 105.896 145.105 105.001 144.001 105.001L141.833 105.001L141.833 105.001L131 105.001L131 105L127.999 105C126.895 105 126 105.895 126 106.999C126 108.104 126.895 108.999 127.999 108.999L130.167 108.999L130.167 109L141 109L141 109L144.001 109Z"
            fill="url(#235paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 141.833 109)"
          fill="#ABABAB"
        />
        <path
          d="M145.06 109C145.579 109 146 108.579 146 108.06L146 105.941C146 105.422 145.579 105.001 145.06 105.001L141 105.001L141 109L145.06 109Z"
          fill="url(#235paint5_linear)"
        />
        <path
          d="M126.94 105C126.421 105 126 105.421 126 105.94L126 108.059C126 108.578 126.421 108.999 126.94 108.999L131 108.999L131 105L126.94 105Z"
          fill="url(#235paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 140.167 109)"
          fill="url(#235paint7_linear)"
        />
        <g filter="url(#235filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M150 34H35V105H150V34ZM139 45H46V94H139V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M150 34H35V105H36V35H149V105H150V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="39.4869"
          y="38.53"
          width="106.026"
          height="61.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#235filter3_i)">
          <rect
            x="46.47"
            y="45.47"
            width="92.0601"
            height="48.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <path d="M136.82 92.5L48.18 92.5L92.5 46.7189L136.82 92.5Z" stroke="white" />
        <rect
          x="99.5283"
          y="36"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 99.5283 36)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#235filter4_d)">
          <rect
            x="98.8843"
            y="36.7019"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 98.8843 36.7019)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M98.7663 38.6951C98.4199 37.542 97.3502 36.7019 96.0842 36.7019L89.4443 36.7019C87.8979 36.7019 86.6443 37.9555 86.6443 39.5019C86.6443 39.5371 86.645 39.5721 86.6463 39.6069C86.9927 40.76 88.0624 41.6001 89.3283 41.6001L95.9682 41.6001C97.5146 41.6001 98.7682 40.3465 98.7682 38.8001C98.7682 38.7649 98.7675 38.7299 98.7663 38.6951Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M97.4093 37.0346C97.2656 37.0119 97.1183 37.0001 96.9682 37.0001L90.3283 37.0001C88.7819 37.0001 87.5283 38.2537 87.5283 39.8001C87.5283 40.8672 88.1252 41.7948 89.0033 42.2674C87.6665 42.0558 86.6444 40.8982 86.6444 39.5019C86.6444 37.9555 87.898 36.7019 89.4444 36.7019L96.0843 36.7019C96.5636 36.7019 97.0148 36.8224 97.4093 37.0346Z"
          fill="#FBFBFB"
        />
        <g filter="url(#235filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M112.528 39.5C112.528 38.6716 111.857 38 111.028 38L110.814 38L109.528 38L91.0283 38C90.1999 38 89.5283 38.6716 89.5283 39.5C89.5283 40.3284 90.1999 41 91.0283 41L107.528 41L111.028 41L111.243 41C111.953 41 112.528 40.4244 112.528 39.7143C112.528 39.6771 112.527 39.6401 112.525 39.6035C112.527 39.5693 112.528 39.5348 112.528 39.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="235filter0_d"
            x="38.1201"
            y="103.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="235filter1_d"
            x="124.12"
            y="103.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="235filter2_d"
            x="30.3003"
            y="29.3003"
            width="126.279"
            height="82.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="235filter3_i"
            x="46"
            y="45"
            width="93.9399"
            height="51.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="235filter4_d"
            x="84.7645"
            y="34.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="235filter5_d"
            x="88.6485"
            y="36.1201"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="235paint0_linear"
            x1="42.5"
            y1="111.8"
            x2="42.5"
            y2="104.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="235paint1_linear"
            x1="57.5"
            y1="102.202"
            x2="57.5"
            y2="109.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="235paint2_linear"
            x1="42.5"
            y1="111.798"
            x2="42.5"
            y2="104.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="235paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="235paint4_linear"
            x1="128.5"
            y1="111.8"
            x2="128.5"
            y2="104.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="235paint5_linear"
            x1="143.5"
            y1="102.202"
            x2="143.5"
            y2="109.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="235paint6_linear"
            x1="128.5"
            y1="111.798"
            x2="128.5"
            y2="104.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="235paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
