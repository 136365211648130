import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement21Square2Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="315"
        height="333"
        viewBox="0 0 315 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-1_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M175.999 30C174.895 30 174 30.8952 174 31.9995C174 33.1038 174.895 33.999 175.999 33.999L178.167 33.999L178.167 33.999L189 33.999L189 34L192.001 34C193.105 34 194 33.1048 194 32.0005C194 30.8962 193.105 30.001 192.001 30.001L189.833 30.001L189.833 30L179 30L179 30L175.999 30Z"
            fill="url(#2-1_paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 178.167 30)"
          fill="#ABABAB"
        />
        <path
          d="M174.94 30C174.421 30 174 30.4208 174 30.9399L174 33.059C174 33.5781 174.421 33.999 174.94 33.999L179 33.999L179 30L174.94 30Z"
          fill="url(#2-1_paint1_linear)"
        />
        <path
          d="M193.06 34C193.579 34 194 33.5792 194 33.0601L194 30.941C194 30.4219 193.579 30.001 193.06 30.001L189 30.001L189 34L193.06 34Z"
          fill="url(#2-1_paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 179.833 30)"
          fill="url(#2-1_paint3_linear)"
        />
        <g filter="url(#2-1_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M253.999 30C252.895 30 252 30.8952 252 31.9995C252 33.1038 252.895 33.999 253.999 33.999L256.167 33.999L256.167 33.999L267 33.999L267 34L270.001 34C271.105 34 272 33.1048 272 32.0005C272 30.8962 271.105 30.001 270.001 30.001L267.833 30.001L267.833 30L257 30L257 30L253.999 30Z"
            fill="url(#2-1_paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 256.167 30)"
          fill="#ABABAB"
        />
        <path
          d="M252.94 30C252.421 30 252 30.4208 252 30.9399L252 33.059C252 33.5781 252.421 33.999 252.94 33.999L257 33.999L257 30L252.94 30Z"
          fill="url(#2-1_paint5_linear)"
        />
        <path
          d="M271.06 34C271.579 34 272 33.5792 272 33.0601L272 30.941C272 30.4219 271.579 30.001 271.06 30.001L267 30.001L267 34L271.06 34Z"
          fill="url(#2-1_paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 257.833 30)"
          fill="url(#2-1_paint7_linear)"
        />
        <g filter="url(#2-1_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M283 34H163V287H283V34ZM272 45H174V276H272V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M283 34H163V287L163.887 287V34.937H282.109V287L283 287V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="167.53"
          y="38.53"
          width="110.94"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#2-1_filter3_i)">
          <rect
            x="174.47"
            y="45.47"
            width="97.0601"
            height="230.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M223 275L271 46H175L223 275ZM223 270.665L269.893 46.9453H176.107L223 270.665Z"
          fill="white"
        />
        <rect
          x="231"
          y="278"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 231 278)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="230.356"
          y="278.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 230.356 278.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M230.238 280.695C229.892 279.542 228.822 278.702 227.556 278.702L220.916 278.702C219.37 278.702 218.116 279.955 218.116 281.502C218.116 281.537 218.117 281.572 218.118 281.607C218.464 282.76 219.534 283.6 220.8 283.6L227.44 283.6C228.986 283.6 230.24 282.346 230.24 280.8C230.24 280.765 230.239 280.73 230.238 280.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M228.881 279.035C228.737 279.012 228.59 279 228.44 279L221.8 279C220.254 279 219 280.254 219 281.8C219 282.867 219.597 283.795 220.475 284.267C219.138 284.056 218.116 282.898 218.116 281.502C218.116 279.955 219.37 278.702 220.916 278.702L227.556 278.702C228.035 278.702 228.487 278.822 228.881 279.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-1_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M227 281.5C227 280.672 226.328 280 225.5 280L225.286 280L224 280L205.5 280C204.672 280 204 280.672 204 281.5C204 282.328 204.672 283 205.5 283L222 283L225.5 283L225.714 283C226.424 283 227 282.424 227 281.714C227 281.677 226.999 281.64 226.996 281.604C226.999 281.569 227 281.535 227 281.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="2-1_filter0_d"
            x="172.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-1_filter1_d"
            x="250.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-1_filter2_d"
            x="158.3"
            y="29.3003"
            width="131.279"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-1_filter3_i"
            x="174"
            y="45"
            width="98.9399"
            height="233.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-1_filter4_d"
            x="202.28"
            y="278.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="2-1_paint0_linear"
            x1="191.5"
            y1="27.2"
            x2="191.5"
            y2="34.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="2-1_paint1_linear"
            x1="176.5"
            y1="36.7982"
            x2="176.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="2-1_paint2_linear"
            x1="191.5"
            y1="27.2018"
            x2="191.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="2-1_paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="2-1_paint4_linear"
            x1="269.5"
            y1="27.2"
            x2="269.5"
            y2="34.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="2-1_paint5_linear"
            x1="254.5"
            y1="36.7982"
            x2="254.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="2-1_paint6_linear"
            x1="269.5"
            y1="27.2018"
            x2="269.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="2-1_paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
