import styled, { css } from 'styled-components'

export const InputWrapper = styled.div<{ disabled?: boolean, isError?: boolean }>`
  display: inline-block;

  border-radius: 10px;

  position: relative;

  width: 100%;

  input {
    background-color: #ffffff;
    background-image: none;
    border: 1px solid ${({ isError }) => (isError ? '#ff453a' : '#e1e1e1')};
    border-radius: 10px;

    box-sizing: border-box;

    margin: 0;
    padding: 15px 23px;

    font-variant: tabular-nums;
    font-feature-settings: 'tnum';
    color: #333333;
    font-size: 16px;
    line-height: 1.5;

    list-style: none;

    position: relative;

    display: inline-block;

    width: 100%;
    min-width: 0;

    transition: all 0.3s;

    ${({ disabled }) =>
      disabled &&
      css`
        background-color: #e1e1e1;

        pointer-events: none;
      `}
  }
`
export const InputReq= styled.span`
  position: absolute;
  color: red;
  padding: 0px 0px 0px 8px;
  z-index: 4;
  font-size: 32px;
`