import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement23Square82Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#5filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M182.001 33C183.105 33 184 32.1048 184 31.0005C184 29.8962 183.105 29.001 182.001 29.001L179.833 29.001L179.833 29.001L169 29.001L169 29L165.999 29C164.895 29 164 29.8952 164 30.9995C164 32.1038 164.895 32.999 165.999 32.999L168.167 32.999L168.167 33L179 33L179 33L182.001 33Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 179.833 33)"
          fill="#ABABAB"
        />
        <path
          d="M183.06 33C183.579 33 184 32.5792 184 32.0601L184 29.941C184 29.4219 183.579 29.001 183.06 29.001L179 29.001L179 33L183.06 33Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M164.94 29C164.421 29 164 29.4208 164 29.9399L164 32.059C164 32.5781 164.421 32.999 164.94 32.999L169 32.999L169 29L164.94 29Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 178.167 33)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#5filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M137.001 33C138.105 33 139 32.1048 139 31.0005C139 29.8962 138.105 29.001 137.001 29.001L134.833 29.001L134.833 29.001L124 29.001L124 29L120.999 29C119.895 29 119 29.8952 119 30.9995C119 32.1038 119.895 32.999 120.999 32.999L123.167 32.999L123.167 33L134 33L134 33L137.001 33Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.833 33)"
          fill="#ABABAB"
        />
        <path
          d="M138.06 33C138.579 33 139 32.5792 139 32.0601L139 29.941C139 29.4219 138.579 29.001 138.06 29.001L134 29.001L134 33L138.06 33Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M119.94 29C119.421 29 119 29.4208 119 29.9399L119 32.059C119 32.5781 119.421 32.999 119.94 32.999L124 32.999L124 29L119.94 29Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 133.167 33)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#5filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 33H115V88H188V33ZM178 43H125V78H178V43Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 33H115V88H116V34H187V88H188V33Z"
          fill="#FBFBFB"
        />
        <g filter="url(#5filter3_i)">
          <rect
            x="125.47"
            y="43.47"
            width="52.0601"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="119.53"
          y="37.53"
          width="63.9399"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M127.018 44.5L151.5 76.1823L175.982 44.5L127.018 44.5Z" stroke="white" />
        <rect
          x="158"
          y="79"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 158 79)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="157.356"
          y="79.7019"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 157.356 79.7019)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M157.238 81.6951C156.892 80.542 155.822 79.7019 154.556 79.7019L147.916 79.7019C146.37 79.7019 145.116 80.9555 145.116 82.5019C145.116 82.5371 145.117 82.5721 145.118 82.6069C145.464 83.76 146.534 84.6001 147.8 84.6001L154.44 84.6001C155.986 84.6001 157.24 83.3465 157.24 81.8001C157.24 81.7649 157.239 81.7299 157.238 81.6951Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M155.881 80.0346C155.737 80.0119 155.59 80.0001 155.44 80.0001L148.8 80.0001C147.254 80.0001 146 81.2537 146 82.8001C146 83.8672 146.597 84.7948 147.475 85.2674C146.138 85.0558 145.116 83.8982 145.116 82.5019C145.116 80.9555 146.37 79.7019 147.916 79.7019L154.556 79.7019C155.035 79.7019 155.486 79.8224 155.881 80.0346Z"
          fill="#FBFBFB"
        />
        <g filter="url(#5filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M154 82.5C154 81.6716 153.328 81 152.5 81L152.286 81L151 81L132.5 81C131.672 81 131 81.6716 131 82.5V82.5C131 83.3284 131.672 84 132.5 84L149 84L152.5 84L152.714 84C153.424 84 154 83.4244 154 82.7143V82.7143C154 82.6771 153.999 82.6401 153.996 82.6035C153.999 82.5693 154 82.5348 154 82.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="5filter0_d"
            x="162.12"
            y="27.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5filter1_d"
            x="117.12"
            y="27.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5filter2_d"
            x="110.3"
            y="28.3003"
            width="84.2793"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5filter3_i"
            x="125"
            y="43"
            width="53.9399"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5filter4_d"
            x="129.28"
            y="79.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="166.5"
            y1="35.8"
            x2="166.5"
            y2="28.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="181.5"
            y1="26.2018"
            x2="181.5"
            y2="33.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="166.5"
            y1="35.7982"
            x2="166.5"
            y2="28.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="121.5"
            y1="35.8"
            x2="121.5"
            y2="28.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="136.5"
            y1="26.2018"
            x2="136.5"
            y2="33.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="121.5"
            y1="35.7982"
            x2="121.5"
            y2="28.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
