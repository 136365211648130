import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement71Square82Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 236.999C32 235.895 31.1048 235 30.0005 235C28.8962 235 28.001 235.895 28.001 236.999V239.167H28.001V250H28V253.001C28 254.105 28.8952 255 29.9995 255C31.1038 255 31.999 254.105 31.999 253.001V250.833H32V240H32V236.999Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 32 239.167)"
          fill="#ABABAB"
        />
        <path
          d="M32 235.94C32 235.421 31.5792 235 31.0601 235H28.941C28.4219 235 28.001 235.421 28.001 235.94V240H32V235.94Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M28 254.06C28 254.579 28.4208 255 28.9399 255H31.059C31.5781 255 31.999 254.579 31.999 254.06V250H28V254.06Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 32 240.833)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#4filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 263.999C32 262.895 31.1048 262 30.0005 262C28.8962 262 28.001 262.895 28.001 263.999V266.167H28.001V277H28V280.001C28 281.105 28.8952 282 29.9995 282C31.1038 282 31.999 281.105 31.999 280.001V277.833H32V267H32V263.999Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 32 266.167)"
          fill="#ABABAB"
        />
        <path
          d="M32 262.94C32 262.421 31.5792 262 31.0601 262H28.941C28.4219 262 28.001 262.421 28.001 262.94V267H32V262.94Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M28 281.06C28 281.579 28.4208 282 28.9399 282H31.059C31.5781 282 31.999 281.579 31.999 281.06V277H28V281.06Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 32 267.833)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#4filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M105 231H32V286H105V231ZM95 241H42V276H95V241Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105 231H32V286H33V232H104V286H105V231Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4filter3_i)">
          <rect
            x="42.47"
            y="241.47"
            width="52.0601"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="235.53"
          width="63.9399"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="97"
          y="251"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#4filter4_d)">
          <rect x="97.7018" y="251.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M98.2859 262.796C98.7981 263.458 99.6003 263.884 100.502 263.884C102.049 263.884 103.302 262.63 103.302 261.084V254.444C103.302 253.456 102.79 252.587 102.017 252.089C102.383 252.562 102.6 253.156 102.6 253.8V260.44C102.6 261.986 101.347 263.24 99.8003 263.24C99.2424 263.24 98.7225 263.077 98.2859 262.796Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M100.502 251.644C98.9554 251.644 97.7018 252.898 97.7018 254.444V260.44C97.7018 260.46 97.702 260.48 97.7025 260.501V255.024C97.7025 253.477 98.9561 252.224 100.502 252.224C102.028 252.224 103.269 253.444 103.302 254.963V254.444C103.302 252.898 102.048 251.644 100.502 251.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M100.5 255C99.6716 255 99 255.672 99 256.5V256.714V258V276.5C99 277.328 99.6716 278 100.5 278V278C101.328 278 102 277.328 102 276.5V260V256.5L102 256.286C102 255.576 101.424 255 100.714 255V255C100.677 255 100.64 255.001 100.604 255.004C100.569 255.001 100.535 255 100.5 255Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M43.5 274.306L92.3756 258.014L43.5 242.681L43.5 274.306Z" stroke="white" />
        <defs>
          <filter
            id="4filter0_d"
            x="26.1202"
            y="233.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4filter1_d"
            x="26.1202"
            y="260.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4filter2_d"
            x="27.3003"
            y="226.3"
            width="84.2793"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4filter3_i"
            x="42"
            y="241"
            width="53.9399"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4filter4_d"
            x="95.8219"
            y="249.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4filter5_d"
            x="97.1201"
            y="253.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="34.8"
            y1="252.5"
            x2="27.2"
            y2="252.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="25.2018"
            y1="237.5"
            x2="32.7998"
            y2="237.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="34.7982"
            y1="252.5"
            x2="27.2002"
            y2="252.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="34.8"
            y1="279.5"
            x2="27.2"
            y2="279.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="25.2018"
            y1="264.5"
            x2="32.7998"
            y2="264.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="34.7982"
            y1="279.5"
            x2="27.2002"
            y2="279.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
