import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement31Square4TopCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-top-3-1filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M164 140.999C164 139.895 163.105 139 162.001 139C160.896 139 160.001 139.895 160.001 140.999V143.167H160.001V154H160V157.001C160 158.105 160.895 159 161.999 159C163.104 159 163.999 158.105 163.999 157.001V154.833H164V144H164V140.999Z"
            fill="url(#4-top-3-1paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 164 143.167)"
          fill="#ABABAB"
        />
        <path
          d="M164 139.94C164 139.421 163.579 139 163.06 139H160.941C160.422 139 160.001 139.421 160.001 139.94V144H164V139.94Z"
          fill="url(#4-top-3-1paint1_linear)"
        />
        <path
          d="M160 158.06C160 158.579 160.421 159 160.94 159H163.059C163.578 159 163.999 158.579 163.999 158.06V154H160V158.06Z"
          fill="url(#4-top-3-1paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 164 144.833)"
          fill="url(#4-top-3-1paint3_linear)"
        />
        <g filter="url(#4-top-3-1filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M164 257.999C164 256.895 163.105 256 162.001 256C160.896 256 160.001 256.895 160.001 257.999V260.167H160.001V271H160V274.001C160 275.105 160.895 276 161.999 276C163.104 276 163.999 275.105 163.999 274.001V271.833H164V261H164V257.999Z"
            fill="url(#4-top-3-1paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 164 260.167)"
          fill="#ABABAB"
        />
        <path
          d="M164 256.94C164 256.421 163.579 256 163.06 256H160.941C160.422 256 160.001 256.421 160.001 256.94V261H164V256.94Z"
          fill="url(#4-top-3-1paint5_linear)"
        />
        <path
          d="M160 275.06C160 275.579 160.421 276 160.94 276H163.059C163.578 276 163.999 275.579 163.999 275.06V271H160V275.06Z"
          fill="url(#4-top-3-1paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 164 261.833)"
          fill="url(#4-top-3-1paint7_linear)"
        />
        <g filter="url(#4-top-3-1filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M284 128H164V287H284V128ZM273 139H175V276H273V139Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M284 128H164V287H165V129H283V287H284V128Z"
          fill="#FBFBFB"
        />
        <rect
          x="168.53"
          y="132.53"
          width="110.94"
          height="149.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#4-top-3-1filter3_i)">
          <rect
            x="175.47"
            y="139.47"
            width="97.0601"
            height="136.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="275"
          y="202"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#4-top-3-1filter4_d)">
          <rect x="275.702" y="202.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M276.286 213.796C276.798 214.458 277.6 214.884 278.502 214.884C280.049 214.884 281.302 213.63 281.302 212.084V205.444C281.302 204.456 280.79 203.587 280.017 203.089C280.383 203.562 280.601 204.156 280.601 204.8V211.44C280.601 212.986 279.347 214.24 277.801 214.24C277.243 214.24 276.723 214.077 276.286 213.796Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M278.502 202.644C276.956 202.644 275.702 203.898 275.702 205.444V211.44C275.702 211.46 275.702 211.48 275.703 211.501V206.024C275.703 204.477 276.956 203.224 278.503 203.224C280.029 203.224 281.27 204.444 281.302 205.963V205.444C281.302 203.898 280.049 202.644 278.502 202.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-top-3-1filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M278.5 206C277.672 206 277 206.672 277 207.5V207.714V209V227.5C277 228.328 277.672 229 278.5 229C279.328 229 280 228.328 280 227.5V211V207.5L280 207.286C280 206.576 279.424 206 278.714 206C278.677 206 278.64 206.001 278.604 206.004C278.569 206.001 278.535 206 278.5 206Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M176.5 141.959L176.5 274.041L271.126 208L176.5 141.959Z" stroke="white" />
        <defs>
          <filter
            id="4-top-3-1filter0_d"
            x="158.12"
            y="137.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-3-1filter1_d"
            x="158.12"
            y="254.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-3-1filter2_d"
            x="159.3"
            y="123.3"
            width="131.279"
            height="170.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-3-1filter3_i"
            x="175"
            y="139"
            width="98.9399"
            height="139.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-top-3-1filter4_d"
            x="273.822"
            y="200.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-3-1filter5_d"
            x="275.12"
            y="204.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="4-top-3-1paint0_linear"
            x1="166.8"
            y1="156.5"
            x2="159.2"
            y2="156.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="4-top-3-1paint1_linear"
            x1="157.202"
            y1="141.5"
            x2="164.8"
            y2="141.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-3-1paint2_linear"
            x1="166.798"
            y1="156.5"
            x2="159.2"
            y2="156.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-3-1paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-3-1paint4_linear"
            x1="166.8"
            y1="273.5"
            x2="159.2"
            y2="273.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="4-top-3-1paint5_linear"
            x1="157.202"
            y1="258.5"
            x2="164.8"
            y2="258.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-3-1paint6_linear"
            x1="166.798"
            y1="273.5"
            x2="159.2"
            y2="273.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-3-1paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
