export enum SUCCESS_THEME {
  DARK = 'dark',
  LIGHT = 'light',
}

export const colorList = [
  {
    code: 'none',
    color: '',
    successTheme: SUCCESS_THEME.LIGHT,
  },
  {
    code: 'dark-blue',
    color: '#87c1e7',
    successTheme: SUCCESS_THEME.LIGHT,
  },
  {
    code: 'dark-green',
    color: '#359834',
    successTheme: SUCCESS_THEME.LIGHT,
  },
  {
    code: 'yellow',
    color: '#fabd02',
    successTheme: SUCCESS_THEME.LIGHT,
  },
  {
    code: 'dark-red',
    color: '#ea2224',
    successTheme: SUCCESS_THEME.LIGHT,
  },
  {
    code: 'dark-purple',
    color: '#ae0288',
    successTheme: SUCCESS_THEME.LIGHT,
  },
  {
    code: 'brown',
    color: '#532819',
    successTheme: SUCCESS_THEME.LIGHT,
  },
  {
    code: 'blue',
    color: '#69dbff',
    successTheme: SUCCESS_THEME.LIGHT,
  },
  {
    code: 'green',
    color: '#49bc47',
    successTheme: SUCCESS_THEME.LIGHT,
  },
  {
    code: 'yellow-2',
    color: '#feea2f',
    successTheme: SUCCESS_THEME.DARK,
  },
  {
    code: 'red',
    color: '#fe0000',
    successTheme: SUCCESS_THEME.LIGHT,
  },
  {
    code: 'purple',
    color: '#f677c6',
    successTheme: SUCCESS_THEME.DARK,
  },
  {
    code: 'grey',
    color: '#505050',
    successTheme: SUCCESS_THEME.LIGHT,
  },
  {
    code: 'light-blue',
    color: '#c9e5fb',
    successTheme: SUCCESS_THEME.DARK,
  },
  {
    code: 'light-green',
    color: '#82cb86',
    successTheme: SUCCESS_THEME.DARK,
  },
  {
    code: 'light-yellow',
    color: '#d69a2d',
    successTheme: SUCCESS_THEME.LIGHT,
  },
  {
    code: 'light-red',
    color: '#ea4c1f',
    successTheme: SUCCESS_THEME.LIGHT,
  },
  {
    code: 'purple-2',
    color: '#ee4399',
    successTheme: SUCCESS_THEME.LIGHT,
  },
  {
    code: 'light-grey',
    color: '#b6b6b6',
    successTheme: SUCCESS_THEME.DARK,
  },
  {
    code: 'sub-light-grey',
    color: '#d8dde3',
    successTheme: SUCCESS_THEME.DARK,
  },
  {
    code: 'green-2',
    color: '#78d306',
    successTheme: SUCCESS_THEME.DARK,
  },
  {
    code: 'light-red-2',
    color: '#fd4600',
    successTheme: SUCCESS_THEME.LIGHT,
  },
  {
    code: 'dark-red-2',
    color: '#be1d01',
    successTheme: SUCCESS_THEME.LIGHT,
  },
  {
    code: 'purple-3',
    color: '#fe0084',
    successTheme: SUCCESS_THEME.LIGHT,
  },
]
