import {
  Energy1,
  HeatSaving1,
  HeatSaving2,
  HeatSaving3,
  HeatSaving4,
  HeatSaving5,
  HeatSaving6,
  HeatSaving7,
  HeatSaving8,
  Security1,
  Security2,
  Security3,
  Soundproofing1,
  Soundproofing2,
  Soundproofing3,
  Soundproofing4,
  SunProtection1,
  SunProtection2,
  SunProtection3,
  SunProtection4,
  Energy2,
} from './icons'

export const rows = {
  'heat-saving': {
    code: 'heat-saving',
    title: 'Теплосбережение',
    description: 'Сохранение тепла в доме, Ro (м2·°C/Вт)',
  },
  soundproofing: {
    code: 'soundproofing',
    title: 'Звукоизоляция',
    description: 'Степень защиты от уличного шума, Дб',
  },
  'sun-protection': {
    code: 'sun-protection',
    title: 'Солнцезащита',
    description: 'Отражение излишнего солнечного жара наружу, %',
  },
  security: {
    code: 'security',
    title: 'Безопасность',
    description: 'Прижим по контуру окна и противовзломные элементы',
  },
  'energy-efficiency': {
    code: 'energy-efficiency',
    title: 'Энергоэффективность',
    description: 'Ro, экономия на отоплении и охлаждении',
  },
}

export const getRows = sunProtection => {
  const result = [rows['soundproofing'], rows['sun-protection'], rows['security']]

  if (sunProtection > 1) {
    result.push(rows['energy-efficiency'])
  } else {
    result.push(rows['heat-saving'])
  }

  return result
}

export const totals = {
  'model-1': {
    'heat-saving': {
      1: {
        value: 1,
        title: 'Базовый уровень теплосбережения, 0.38(м2 ℃/Вт)',
        icons: [0, 1],
      },
      2: {
        value: 2,
        title: 'Базовый уровень теплосбережения, 0.52(м2 ℃/Вт)',
        icons: [3, 4, 5],
      },
      3: {
        value: 3,
        title: 'Базовый уровень теплосбережения, 0.6(м2 ℃/Вт)',
        icons: [3, 4, 5],
      },
    },
    'energy-efficiency': {
      1: {
        value: 1,
        title: 'Базовый уровень энергоэффективности, 0,38(м2·°C/Вт)',
        icons: [0, 1],
      },
      2: {
        value: 3,
        title: 'Экономия на доп отоплении, 0,61(м2·°C/Вт)',
        icons: [2, 3, 4],
      },
      3: {
        value: 4,
        title: 'Экономия на доп отоплении, 0,65(м2·°C/Вт)',
        icons: [5, 3, 6, 7, 4],
      },
    },
    soundproofing: {
      1: {
        value: 1,
        title: 'Стандартный уровень звукоизоляции, 30Дб',
        icons: [0],
      },
      2: {
        value: 3,
        title: 'Слышимое улучшение звукоизоляции +2Дб, 32Дб',
        icons: [0, 1],
      },
      3: {
        value: 3,
        title: 'Слышимое улучшение звукоизоляции +2Дб, 32Дб',
        icons: [0, 1],
      },
    },
    'sun-protection': {
      1: {
        value: 2,
        title: 'Отражает 21% солнечного жара',
        icons: [0],
      },
      2: {
        value: 5,
        title:
          'Отражает 57% солнечного жара. Защита от выгорания мебели, прохлада - летом, тепло - зимой',
        icons: [0, 1, 2, 3],
      },
      3: {
        value: 5,
        title:
          'Отражает 60% солнечного жара. Защита от выгорания мебели, прохлада - летом, тепло - зимой',
        icons: [0, 1, 2, 3],
      },
    },
    security: {
      1: {
        value: 1,
        title: 'Герметичность без противовзломности',
        icons: [0],
      },
      2: {
        value: 1,
        title: 'Герметичность без противовзломности',
        icons: [0],
      },
      3: {
        value: 1,
        title: 'Герметичность без противовзломности',
        icons: [0],
      },
    },
  },
  'model-2': {
    'heat-saving': {
      1: {
        value: 2,
        title: 'Базовый уровень теплосбережения, 0.52(м2 ℃/Вт)',
        icons: [2, 3, 4],
      },
      2: {
        value: 3,
        title: 'Базовый уровень теплосбережения, 0.62(м2 ℃/Вт)',
        icons: [5, 6, 7, 3, 4],
      },
      3: {
        value: 5,
        title: 'Экономия на доп. отоплении, комфортное цветоводство у окна, 0.79(м2 ℃/Вт)',
        icons: [5, 6, 7, 3],
      },
    },
    'energy-efficiency': {
      1: {
        value: 1,
        title: 'Базовый уровень энергоэффективности, 0,52(м2·°C/Вт)',
        icons: [2, 3, 4],
      },
      2: {
        value: 3,
        title: 'Экономия на доп отоплении, 0,67(м2·°C/Вт); экономия на кондиционировании',
        icons: [],
      },
      3: {
        value: 4,
        title: 'Экономия на доп отоплении, 0,96 (м2·°C/Вт); экономия на кондиционировании',
        icons: [5, 6, 7, 8, 9, 10],
      },
    },
    soundproofing: {
      1: {
        value: 2,
        title: 'Улучшенная звукоизоляция, 31Дб',
        icons: [0, 1],
      },
      2: {
        value: 3,
        title: 'Слышимое улучшение звукоизоляции +2Дб, 32Дб',
        icons: [0, 1],
      },
      3: {
        value: 7,
        title: 'Защита от шума автострады, 38Дб',
        icons: [1, 2],
      },
    },
    'sun-protection': {
      1: {
        value: 2,
        title: 'Отражает 21% солнечного жара',
        icons: [0],
      },
      2: {
        value: 5,
        title:
          'Отражает 60% солнечного жара. Защита от выгорания мебели, прохлада - летом, тепло - зимой',
        icons: [0, 1, 2, 3],
      },
      3: {
        value: 5,
        title:
          'Отражает 60% солнечного жара. Защита от выгорания мебели, прохлада - летом, тепло - зимой',
        icons: [0, 1, 2, 3],
      },
    },
    security: {
      1: {
        value: 4,
        title: 'Герметичность. Базовая противовзломность',
        icons: [0, 1],
      },
      2: {
        value: 7,
        title: 'Герметичность. Защита от применения физ силы',
        icons: [1, 2],
      },
      3: {
        value: 7,
        title: 'Герметичность. Защита от применения физ силы',
        icons: [1, 2],
      },
    },
  },
  'model-3': {
    'heat-saving': {
      1: {
        value: 2,
        title: 'Базовый уровень теплосбережения, 0.56(м2 ℃/Вт)',
        icons: [2, 3, 4],
      },
      2: {
        value: 6,
        title: 'Экономия на доп. отоплении, комфортное цветоводство у окна, 0.82(м2 ℃/Вт)',
        icons: [5, 6, 7, 3],
      },
      3: {
        value: 7,
        title: 'Экономия на доп. отоплении, комфортное цветоводство у окна, 0.82(м2 ℃/Вт)',
        icons: [5, 6, 7, 3],
      },
    },
    'energy-efficiency': {
      1: {
        value: 1,
        title: 'базовый уровень энергоэффективности, 0,56 (м2·°C/Вт)',
        icons: [2, 3, 4],
      },
      2: {
        value: 3,
        title: 'экономия на доп отоплении, 1,08 (м2·°C/Вт); экономия на кондиционировании',
        icons: [5, 6, 7, 8, 9, 10],
      },
      3: {
        value: 7,
        title: 'экономия на доп отоплении, 1,08 (м2·°C/Вт); экономия на кондиционировании',
        icons: [5, 6, 7, 8, 9, 10],
      },
    },
    soundproofing: {
      1: {
        value: 3,
        title: 'Слышимое улучшение звукоизоляции +2Дб',
        icons: [0, 1],
      },
      2: {
        value: 7,
        title: 'Защита от шума автострады, 39Дб',
        icons: [1, 2, 3],
      },
      3: {
        value: 7,
        title: 'Защита от шума автострады, 39Дб',
        icons: [1, 2, 3],
      },
    },
    'sun-protection': {
      1: {
        value: 2,
        title: 'Отражает 21% солнечного жара',
        icons: [0],
      },
      2: {
        value: 5,
        title:
          'Отражает 60% солнечного жара. Защита от выгорания мебели, прохлада - летом, тепло - зимой',
        icons: [0, 1, 2, 3],
      },
      3: {
        value: 7,
        title:
          'Отражает 60% солнечного жара. Защита от выгорания мебели, прохлада - летом, тепло - зимой',
        icons: [0, 1, 2, 3],
      },
    },
    security: {
      1: {
        value: 4,
        title: 'Герметичность. Базовая противовзломность',
        icons: [0, 1],
      },
      2: {
        value: 7,
        title: 'Герметичность. Защита от применения физ силы',
        icons: [1, 2],
      },
      3: {
        value: 7,
        title: 'Герметичность. Защита от применения физ силы',
        icons: [1, 2],
      },
    },
  },
}

/** Target icons from `totals['model-n']['type']`  */
export const totalsIcons = {
  'heat-saving': [
    HeatSaving1,
    HeatSaving2,
    HeatSaving3,
    HeatSaving4,
    HeatSaving5,
    HeatSaving6,
    HeatSaving7,
    HeatSaving8,
  ],
  soundproofing: [Soundproofing1, Soundproofing2, Soundproofing3, Soundproofing4],
  'sun-protection': [SunProtection1, SunProtection2, SunProtection3, SunProtection4],
  security: [Security1, Security2, Security3],
  'energy-efficiency': [
    HeatSaving7,
    HeatSaving8,
    HeatSaving1,
    HeatSaving3,
    HeatSaving6,
    HeatSaving2,
    HeatSaving4,
    HeatSaving5,
    SunProtection1,
    Energy1,
    Energy2,
  ],
}
