import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement14Square52Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#1-4_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.0005 34C57.1048 34 58 33.1048 58 32.0005C58 30.8962 57.1048 30.001 56.0005 30.001L53.8335 30.001L53.8335 30.001L43 30.001L43 30L39.9995 30C38.8952 30 38 30.8952 38 31.9995C38 33.1038 38.8952 33.999 39.9995 33.999L42.1668 33.999L42.1668 34L53 34L53 34L56.0005 34Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 53.834 34)"
          fill="#ABABAB"
        />
        <path
          d="M57.0601 34C57.5792 34 58 33.5792 58 33.0601L58 30.941C58 30.4219 57.5792 30.001 57.0601 30.001L53 30.001L53 34L57.0601 34Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M38.9399 30C38.4208 30 38 30.4208 38 30.9399L38 33.059C38 33.5781 38.4208 33.999 38.9399 33.999L43 33.999L43 30L38.9399 30Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 52.167 34)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#1-4_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M108.001 34C109.105 34 110 33.1048 110 32.0005C110 30.8962 109.105 30.001 108.001 30.001L105.833 30.001L105.833 30.001L95 30.001L95 30L91.9995 30C90.8952 30 90 30.8952 90 31.9995C90 33.1038 90.8952 33.999 91.9995 33.999L94.1668 33.999L94.1668 34L105 34L105 34L108.001 34Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 105.834 34)"
          fill="#ABABAB"
        />
        <path
          d="M109.06 34C109.579 34 110 33.5792 110 33.0601L110 30.941C110 30.4219 109.579 30.001 109.06 30.001L105 30.001L105 34L109.06 34Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M90.9399 30C90.4208 30 90 30.4208 90 30.9399L90 33.059C90 33.5781 90.4208 33.999 90.9399 33.999L95 33.999L95 30L90.9399 30Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 104.167 34)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#1-4_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M116 34H32V111H116V34ZM105 45H43V100H105V45Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#1-4_filter3_i)">
          <rect
            x="43.47"
            y="45.47"
            width="61.0601"
            height="54.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="37.5" y="39.5" width="73" height="66" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M116 34H32V111H33V35H115V111H116V34Z"
          fill="#FBFBFB"
        />
        <path d="M103.142 46.5L44.8576 46.5L74 97.985L103.142 46.5Z" stroke="white" />
        <rect
          x="80"
          y="102"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 80 102)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#1-4_filter4_d)">
          <rect
            x="79.3555"
            y="102.701"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 79.3555 102.701)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.2383 104.694C78.8919 103.541 77.8222 102.701 76.5563 102.701L69.9164 102.701C68.37 102.701 67.1164 103.955 67.1164 105.501C67.1164 105.536 67.117 105.571 67.1183 105.606C67.4647 106.759 68.5344 107.599 69.8003 107.599L76.4402 107.599C77.9866 107.599 79.2402 106.346 79.2402 104.799C79.2402 104.764 79.2396 104.729 79.2383 104.694Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M77.8809 103.034C77.7372 103.011 77.5899 102.999 77.4398 102.999L70.7999 102.999C69.2535 102.999 67.9999 104.253 67.9999 105.799C67.9999 106.866 68.5968 107.794 69.4749 108.267C68.1381 108.055 67.116 106.898 67.116 105.501C67.116 103.955 68.3696 102.701 69.916 102.701L76.5559 102.701C77.0352 102.701 77.4864 102.822 77.8809 103.034Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-4_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M76 105.5C76 104.672 75.3284 104 74.5 104L74.2857 104L73 104L54.5 104C53.6716 104 53 104.672 53 105.5C53 106.328 53.6716 107 54.5 107L71 107L74.5 107L74.7143 107C75.4244 107 76 106.424 76 105.714C76 105.677 75.9988 105.64 75.9965 105.604C75.9988 105.569 76 105.535 76 105.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="1-4_filter0_d"
            x="36.1201"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-4_filter1_d"
            x="88.1201"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-4_filter2_d"
            x="27.3003"
            y="29.3003"
            width="95.2793"
            height="88.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-4_filter3_i"
            x="43"
            y="45"
            width="62.9399"
            height="57.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="1-4_filter4_d"
            x="65.2354"
            y="100.821"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-4_filter5_d"
            x="51.1201"
            y="102.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="40.5"
            y1="36.8"
            x2="40.5"
            y2="29.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="55.5"
            y1="27.2018"
            x2="55.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="40.5"
            y1="36.7982"
            x2="40.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="92.5"
            y1="36.8"
            x2="92.5"
            y2="29.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="107.5"
            y1="27.2018"
            x2="107.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="92.5"
            y1="36.7982"
            x2="92.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
