import React from 'react'
import { StageDescriptionBlockContentItemIcon } from 'src/react-app/views/calculator/styles'

export const HeatSaving6 = () => {
  return (
    <StageDescriptionBlockContentItemIcon title="Подходит для комфортного цветоводства около окна">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.6"
          d="M32.5 40H7.5C3.3575 40 0 36.6425 0 32.5V7.5C0 3.3575 3.3575 0 7.5 0H32.5C36.6425 0 40 3.3575 40 7.5V32.5C40 36.6425 36.6425 40 32.5 40Z"
          fill="#E0E3E9"
        />
        <path
          d="M27.436 22.08C27.0998 21.7425 26.6148 21.6087 26.1398 21.7237C23.8235 22.2762 21.9823 23.5213 20.7498 25.2488V20.8C23.5723 20.3938 25.7498 17.9663 25.7498 15.0338V11.6125C25.7498 11.1425 25.4448 10.745 24.9923 10.6225C24.5398 10.5013 24.076 10.69 23.8385 11.0963L22.3635 13.625L20.8348 10.5663C20.6585 10.2175 20.3073 10 19.916 10C19.5248 10 19.1735 10.2175 18.9985 10.5675L17.4698 13.625L15.9948 11.0963C15.7585 10.69 15.2948 10.5013 14.841 10.6225C14.3873 10.745 14.0835 11.1437 14.0835 11.6125V15.0325C14.0835 17.965 16.261 20.3925 19.0835 20.7987V25.2475C17.851 23.52 16.0098 22.275 13.6935 21.7225C13.216 21.6075 12.7323 21.7413 12.396 22.0788C12.0598 22.4163 11.926 22.9 12.0398 23.375C12.8398 26.7288 15.321 29.1338 18.8485 29.9763C18.891 29.9863 18.9348 29.9913 18.9785 29.9938L19.361 30.0037C19.5085 30.1388 19.701 30.225 19.9173 30.225C20.1335 30.225 20.326 30.1375 20.4735 30.0037L20.856 29.9938C20.8998 29.9925 20.9435 29.9863 20.986 29.9763C24.5135 29.135 26.9948 26.7288 27.7948 23.375C27.906 22.9013 27.7735 22.4162 27.436 22.08Z"
          fill="#1C3775"
        />
      </svg>
    </StageDescriptionBlockContentItemIcon>
  )
}
