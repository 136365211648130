import React, { FC, useContext, useState, useCallback } from 'react'
import { PersonalAreaMainWrapper, OrderItemBlockWrapper } from './styles'
import { OrderItemBlock } from './order-item-block'
import { PersonalAreaContext } from './stage-personal-area'
import { useRegister } from '../../../hooks'
import { Loader } from '../../../components'

export const OrdersList: FC = () => {
  const { orders, inProcess } = useContext(PersonalAreaContext)
  const [activeItem, setActiveItem] = useState<number | null>(0)
  const { isAuthorized } = useRegister()

  const handleSetActiveIndex = useCallback(
    index => () => {
      setActiveItem(prevState => (prevState === index ? null : index))
    },
    [],
  )

  return (
    <PersonalAreaMainWrapper>
      {inProcess && (
        <OrderItemBlockWrapper active>
          <Loader />
        </OrderItemBlockWrapper>
      )}
      {!inProcess && (orders.length === 0 || !isAuthorized) && (
        <OrderItemBlockWrapper active>Нет заказов</OrderItemBlockWrapper>
      )}
      {orders.length > 0 &&
        isAuthorized &&
        orders.map((item, index) => (
          <OrderItemBlock
            active={activeItem === index}
            key={`order-item-${item['id']}`}
            data={item}
            setActiveItem={handleSetActiveIndex(index)}
          />
        ))}
    </PersonalAreaMainWrapper>
  )
}
