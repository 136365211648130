import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement16Square4VerticalCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#1-6_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M191.954 38.9765C191.954 37.8849 191.069 37 189.977 37C188.886 37 188.001 37.8849 188.001 38.9765L188.001 41.1665L188.001 41.1665L188.001 52L188 52L188 55.0235C188 56.1151 188.885 57 189.976 57C191.068 57 191.953 56.1151 191.953 55.0235L191.953 52.8332L191.954 52.8332L191.954 42L191.954 42L191.954 38.9765Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 191.954 41.1665)"
          fill="#ABABAB"
        />
        <path
          d="M191.954 37.9399C191.954 37.4208 191.533 37 191.014 37L188.941 37C188.422 37 188.001 37.4208 188.001 37.9399L188.001 42L191.954 42L191.954 37.9399Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M188 56.0601C188 56.5792 188.421 57 188.94 57L191.013 57C191.532 57 191.953 56.5792 191.953 56.0601L191.953 52L188 52L188 56.0601Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 191.954 42.8334)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#1-6_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M191.954 65.9765C191.954 64.8849 191.069 64 189.977 64C188.886 64 188.001 64.8849 188.001 65.9765L188.001 68.1665L188.001 68.1665L188.001 79L188 79L188 82.0235C188 83.1151 188.885 84 189.976 84C191.068 84 191.953 83.1151 191.953 82.0235L191.953 79.8332L191.954 79.8332L191.954 69L191.954 69L191.954 65.9765Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 191.954 68.1665)"
          fill="#ABABAB"
        />
        <path
          d="M191.954 64.9399C191.954 64.4208 191.533 64 191.014 64L188.941 64C188.422 64 188.001 64.4208 188.001 64.9399L188.001 69L191.954 69L191.954 64.9399Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M188 83.0601C188 83.5792 188.421 84 188.94 84L191.013 84C191.532 84 191.953 83.5792 191.953 83.0601L191.953 79L188 79L188 83.0601Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 191.954 69.8334)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#1-6_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 33H32V88H188V33ZM178 43H42V78H178V43Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 33H32V88H33V34H187V88H188V33Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-6_filter3_i)">
          <rect
            x="42.47"
            y="43.47"
            width="135.06"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="37.53"
          width="146.94"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          width="6.91954"
          height="13.5283"
          rx="3.45977"
          transform="matrix(-1 0 0 1 40.9194 54)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#1-6_filter4_d)">
          <rect
            width="5.53563"
            height="12.2399"
            rx="2.76782"
            transform="matrix(-1 0 0 1 40.2253 54.644)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.6604 65.7924C39.1548 66.4558 38.3562 66.884 37.4577 66.884C35.9291 66.884 34.6899 65.6448 34.6899 64.1161V57.4119C34.6899 56.4401 35.1907 55.5854 35.9482 55.0916C35.5936 55.5568 35.383 56.1377 35.383 56.7678V63.4721C35.383 65.0007 36.6222 66.2399 38.1508 66.2399C38.7078 66.2399 39.2262 66.0754 39.6604 65.7924Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.4575 54.644C38.9861 54.644 40.2253 55.8832 40.2253 57.4119V63.4719C40.2253 63.4922 40.2251 63.5124 40.2247 63.5326V57.9914C40.2247 56.4628 38.9855 55.2236 37.4569 55.2236C35.9485 55.2236 34.722 56.4302 34.6897 57.9308V57.4119C34.6897 55.8832 35.9289 54.644 37.4575 54.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-6_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.4597 58C38.2787 58 38.9425 58.6639 38.9425 59.4828V61C38.9425 61.0136 38.9424 61.0271 38.9421 61.0406L38.9421 79.5172C38.9421 80.3361 38.2782 81 37.4593 81V81C36.6404 81 35.9766 80.3361 35.9766 79.5172L35.9766 59.2513C35.9766 58.5602 36.5368 58 37.2278 58V58C37.2307 58 37.2335 58 37.2363 58C37.3107 58.0004 37.3853 58 37.4597 58V58Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M176.5 76.4347L47.0913 60.5L176.5 44.5653L176.5 76.4347Z" stroke="white" />
        <defs>
          <filter
            id="1-6_filter0_d"
            x="186.12"
            y="35.1201"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-6_filter1_d"
            x="186.12"
            y="62.1201"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-6_filter2_d"
            x="27.3003"
            y="28.3003"
            width="167.279"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-6_filter3_i"
            x="42"
            y="43"
            width="136.94"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="1-6_filter4_d"
            x="32.8098"
            y="52.7642"
            width="11.1753"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-6_filter5_d"
            x="34.0967"
            y="56.1201"
            width="8.60556"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="194.722"
            y1="54.5"
            x2="187.209"
            y2="54.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="185.234"
            y1="39.5"
            x2="192.744"
            y2="39.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="194.72"
            y1="54.5"
            x2="187.209"
            y2="54.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="194.722"
            y1="81.5"
            x2="187.209"
            y2="81.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="185.234"
            y1="66.5"
            x2="192.744"
            y2="66.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="194.72"
            y1="81.5"
            x2="187.209"
            y2="81.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
