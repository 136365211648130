import React from 'react'
import { ComponentShadow } from 'src/react-app/views/calculator/stage-calculator/components'

import { TextWrapper } from './styles'

export const Text = props => {
  const { data = { value: '' }, disabled = false } = props
  const { value } = data

  return (
    <>
      {value && (
        <TextWrapper>
          {disabled && <ComponentShadow />}
          {value}
        </TextWrapper>
      )}
    </>
  )
}
