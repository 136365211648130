import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement43Square4TopCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-top-4-3_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M421 141.999C421 140.895 420.105 140 419.001 140C417.896 140 417.001 140.895 417.001 141.999V144.167H417.001V155H417V158.001C417 159.105 417.895 160 418.999 160C420.104 160 420.999 159.105 420.999 158.001V155.833H421V145H421V141.999Z"
            fill="url(#4-top-4-3_paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 421 144.167)"
          fill="#ABABAB"
        />
        <path
          d="M421 140.94C421 140.421 420.579 140 420.06 140H417.941C417.422 140 417.001 140.421 417.001 140.94V145H421V140.94Z"
          fill="url(#4-top-4-3_paint1_linear)"
        />
        <path
          d="M417 159.06C417 159.579 417.421 160 417.94 160H420.059C420.578 160 420.999 159.579 420.999 159.06V155H417V159.06Z"
          fill="url(#4-top-4-3_paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 421 145.833)"
          fill="url(#4-top-4-3_paint3_linear)"
        />
        <g filter="url(#4-top-4-3_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 254C417 252.895 417.895 252 419 252C420.105 252 421 252.895 421 254V268.56H417V254ZM417 268.56H421V270.4H421.06C421.579 270.4 422 270.821 422 271.34V273.12C422 274.158 421.158 275 420.12 275H418.88C417.842 275 417 274.158 417 273.12V270.4V270.4V268.56Z"
            fill="white"
          />
        </g>
        <rect x="417" y="269.25" width="3.99998" height="0.95833" fill="#ABABAB" />
        <path
          d="M417 252.94C417 252.421 417.421 252 417.94 252H420.06C420.579 252 421 252.421 421 252.94V269.25H417V252.94Z"
          fill="url(#4-top-4-3_paint4_linear)"
        />
        <path
          d="M417 274.06C417 274.579 417.421 275 417.94 275H421.06C421.579 275 422 274.579 422 274.06V271.148C422 270.629 421.579 270.208 421.06 270.208H417V274.06Z"
          fill="url(#4-top-4-3_paint5_linear)"
        />
        <g filter="url(#4-top-4-3_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 128H297V287H417V128ZM406 139H308V276H406V139Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 128H297V287H298V129H416V287H417V128Z"
          fill="#FBFBFB"
        />
        <rect
          x="301.53"
          y="132.53"
          width="110.94"
          height="149.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#4-top-4-3_filter3_i)">
          <rect
            x="308.47"
            y="139.47"
            width="97.0601"
            height="136.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="299"
          y="202"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#4-top-4-3_filter4_d)">
          <rect x="299.702" y="202.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M300.286 213.795C300.798 214.458 301.6 214.884 302.502 214.884C304.049 214.884 305.302 213.63 305.302 212.084V205.444C305.302 204.456 304.79 203.587 304.017 203.089C304.383 203.562 304.601 204.155 304.601 204.8V211.44C304.601 212.986 303.347 214.24 301.801 214.24C301.243 214.24 300.723 214.077 300.286 213.795Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M302.502 202.644C300.956 202.644 299.702 203.898 299.702 205.444V211.44C299.702 211.46 299.702 211.48 299.703 211.501V206.024C299.703 204.477 300.956 203.224 302.503 203.224C304.029 203.224 305.27 204.444 305.302 205.963V205.444C305.302 203.898 304.049 202.644 302.502 202.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-top-4-3_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M302.5 206C301.672 206 301 206.672 301 207.5V207.714V209V227.5C301 228.328 301.672 229 302.5 229C303.328 229 304 228.328 304 227.5V211V207.5L304 207.286C304 206.576 303.424 206 302.714 206C302.677 206 302.64 206.001 302.604 206.004C302.569 206.001 302.535 206 302.5 206Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M404.29 274.5H309.71L357 142.483L404.29 274.5Z" stroke="white" />
        <path d="M404.5 141.953L404.5 274.047L308.88 208L404.5 141.953Z" stroke="white" />
        <defs>
          <filter
            id="4-top-4-3_filter0_d"
            x="415.12"
            y="138.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-4-3_filter1_d"
            x="415.12"
            y="250.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-4-3_filter2_d"
            x="292.3"
            y="123.3"
            width="131.279"
            height="170.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-4-3_filter3_i"
            x="308"
            y="139"
            width="98.9399"
            height="139.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-top-4-3_filter4_d"
            x="297.822"
            y="200.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-4-3_filter5_d"
            x="299.12"
            y="204.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="4-top-4-3_paint0_linear"
            x1="423.8"
            y1="157.5"
            x2="416.2"
            y2="157.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="4-top-4-3_paint1_linear"
            x1="414.202"
            y1="142.5"
            x2="421.8"
            y2="142.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-4-3_paint2_linear"
            x1="423.798"
            y1="157.5"
            x2="416.2"
            y2="157.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-4-3_paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-4-3_paint4_linear"
            x1="423.8"
            y1="260.625"
            x2="416.2"
            y2="260.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-4-3_paint5_linear"
            x1="425.5"
            y1="272.604"
            x2="416"
            y2="272.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
