import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement84Square8Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#8-4_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M328 177.999C328 176.895 327.105 176 326.001 176C324.896 176 324.001 176.895 324.001 177.999V180.167H324.001V191H324V194.001C324 195.105 324.895 196 325.999 196C327.104 196 327.999 195.105 327.999 194.001V191.833H328V181H328V177.999Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 328 180.167)"
          fill="#ABABAB"
        />
        <path
          d="M328 176.94C328 176.421 327.579 176 327.06 176H324.941C324.422 176 324.001 176.421 324.001 176.94V181H328V176.94Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M324 195.06C324 195.579 324.421 196 324.94 196H327.059C327.578 196 327.999 195.579 327.999 195.06V191H324V195.06Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 328 181.833)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#8-4_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M328 254C328 252.895 327.105 252 326 252C324.895 252 324 252.895 324 254V268.56H328V254ZM328 268.56H324V270.4H323.94C323.421 270.4 323 270.821 323 271.34V273.12C323 274.158 323.842 275 324.88 275H326.12C327.158 275 328 274.158 328 273.12V270.4V270.4V268.56Z"
            fill="white"
          />
        </g>
        <rect
          width="3.99998"
          height="0.95833"
          transform="matrix(-1 0 0 1 328 269.25)"
          fill="#ABABAB"
        />
        <path
          d="M328 252.94C328 252.421 327.579 252 327.06 252H324.94C324.421 252 324 252.421 324 252.94V269.25H328V252.94Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M328 274.06C328 274.579 327.579 275 327.06 275H323.94C323.421 275 323 274.579 323 274.06V271.148C323 270.629 323.421 270.208 323.94 270.208H328V274.06Z"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#8-4_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 165H328V286H417V165ZM406 176H339V275H406V176Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 165H328V286H329.219V166H415.781V286H417V165Z"
          fill="#FBFBFB"
        />
        <g filter="url(#8-4_filter3_i)">
          <rect
            x="339.47"
            y="176.47"
            width="66.0601"
            height="98.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="332.53"
          y="169.53"
          width="79.9399"
          height="111.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="408"
          y="218"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#8-4_filter4_d)">
          <rect x="408.702" y="218.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M409.286 229.796C409.798 230.458 410.6 230.884 411.502 230.884C413.049 230.884 414.302 229.63 414.302 228.084V221.444C414.302 220.456 413.79 219.587 413.017 219.089C413.383 219.562 413.601 220.156 413.601 220.8V227.44C413.601 228.986 412.347 230.24 410.801 230.24C410.243 230.24 409.723 230.077 409.286 229.796Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M411.502 218.644C409.955 218.644 408.702 219.898 408.702 221.444V227.44C408.702 227.46 408.702 227.48 408.702 227.501V222.024C408.702 220.477 409.956 219.224 411.502 219.224C413.028 219.224 414.269 220.444 414.302 221.963V221.444C414.302 219.898 413.048 218.644 411.502 218.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#8-4_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M411.5 222C410.672 222 410 222.672 410 223.5V223.714V225V243.5C410 244.328 410.672 245 411.5 245V245C412.328 245 413 244.328 413 243.5V227V223.5L413 223.286C413 222.576 412.424 222 411.714 222V222C411.677 222 411.64 222.001 411.604 222.004C411.569 222.001 411.535 222 411.5 222Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M340.5 177.997L404.164 225.5L340.5 273.003L340.5 177.997Z" stroke="white" />
        <path d="M340.695 273.5L372.5 178.574L404.305 273.5L340.695 273.5Z" stroke="white" />
        <defs>
          <filter
            id="8-4_filter0_d"
            x="322.12"
            y="174.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8-4_filter1_d"
            x="321.12"
            y="250.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8-4_filter2_d"
            x="323.3"
            y="160.3"
            width="100.279"
            height="132.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8-4_filter3_i"
            x="339"
            y="176"
            width="67.9399"
            height="101.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="8-4_filter4_d"
            x="406.822"
            y="216.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8-4_filter5_d"
            x="408.12"
            y="220.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="330.8"
            y1="193.5"
            x2="323.2"
            y2="193.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="321.202"
            y1="178.5"
            x2="328.8"
            y2="178.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="330.798"
            y1="193.5"
            x2="323.2"
            y2="193.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="321.2"
            y1="260.625"
            x2="328.8"
            y2="260.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="319.5"
            y1="272.604"
            x2="329"
            y2="272.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
