import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement31Square4VerticalCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#3-1_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M60.0235 223.954C61.1151 223.954 62 223.069 62 221.978C62 220.886 61.1151 220.001 60.0235 220.001L57.8335 220.001L57.8335 220.001L47 220.001L47 220L43.9765 220C42.8849 220 42 220.885 42 221.976C42 223.068 42.8849 223.953 43.9765 223.953L46.1668 223.953L46.1668 223.954L57 223.954L57 223.954L60.0235 223.954Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 57.8335 223.954)"
          fill="#ABABAB"
        />
        <path
          d="M61.0601 223.954C61.5792 223.954 62 223.533 62 223.014L62 220.941C62 220.422 61.5792 220.001 61.0601 220.001L57 220.001L57 223.954L61.0601 223.954Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M42.9399 220C42.4208 220 42 220.421 42 220.94L42 223.013C42 223.532 42.4208 223.953 42.9399 223.953L47 223.953L47 220L42.9399 220Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 56.1666 223.954)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#3-1_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M176.024 223.954C177.115 223.954 178 223.069 178 221.978C178 220.886 177.115 220.001 176.024 220.001L173.833 220.001L173.833 220.001L163 220.001L163 220L159.976 220C158.885 220 158 220.885 158 221.976C158 223.068 158.885 223.953 159.976 223.953L162.167 223.953L162.167 223.954L173 223.954L173 223.954L176.024 223.954Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 173.834 223.954)"
          fill="#ABABAB"
        />
        <path
          d="M177.06 223.954C177.579 223.954 178 223.533 178 223.014L178 220.941C178 220.422 177.579 220.001 177.06 220.001L173 220.001L173 223.954L177.06 223.954Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M158.94 220C158.421 220 158 220.421 158 220.94L158 223.013C158 223.532 158.421 223.953 158.94 223.953L163 223.953L163 220L158.94 220Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 172.167 223.954)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#3-1_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 165H32V220H188V165ZM178 175H42V210H178V175Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 165H32V220H33V166H187V220H188V165Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-1_filter3_i)">
          <rect
            x="42.47"
            y="175.47"
            width="135.06"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="117.528"
          y="167"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 117.528 167)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#3-1_filter4_d)">
          <rect
            x="116.884"
            y="167.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 116.884 167.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M116.766 169.695C116.42 168.542 115.35 167.702 114.084 167.702L107.444 167.702C105.898 167.702 104.644 168.955 104.644 170.502C104.644 170.537 104.645 170.572 104.646 170.607C104.993 171.76 106.062 172.6 107.328 172.6L113.968 172.6C115.515 172.6 116.768 171.346 116.768 169.8C116.768 169.765 116.768 169.73 116.766 169.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115.409 168.035C115.266 168.012 115.118 168 114.968 168L108.328 168C106.782 168 105.528 169.254 105.528 170.8C105.528 171.867 106.125 172.795 107.003 173.267C105.666 173.056 104.644 171.898 104.644 170.502C104.644 168.955 105.898 167.702 107.444 167.702L114.084 167.702C114.564 167.702 115.015 167.822 115.409 168.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-1_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M130.528 170.5C130.528 169.672 129.857 169 129.028 169L128.814 169L127.528 169L109.028 169C108.2 169 107.528 169.672 107.528 170.5V170.5C107.528 171.328 108.2 172 109.028 172L125.528 172L129.028 172L129.243 172C129.953 172 130.528 171.424 130.528 170.714V170.714C130.528 170.677 130.527 170.64 130.525 170.604C130.527 170.569 130.528 170.535 130.528 170.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M174.853 208.5L110 176.557L45.1468 208.5L174.853 208.5Z" stroke="white" />
        <defs>
          <filter
            id="3-1_filter0_d"
            x="40.1201"
            y="218.12"
            width="25.6396"
            height="9.59374"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-1_filter1_d"
            x="156.12"
            y="218.12"
            width="25.6396"
            height="9.59374"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-1_filter2_d"
            x="27.3003"
            y="160.3"
            width="167.279"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-1_filter3_i"
            x="42"
            y="175"
            width="136.94"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-1_filter4_d"
            x="102.764"
            y="165.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-1_filter5_d"
            x="106.649"
            y="167.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="44.5"
            y1="226.722"
            x2="44.5"
            y2="219.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="59.5"
            y1="217.234"
            x2="59.5"
            y2="224.745"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="44.5"
            y1="226.72"
            x2="44.5"
            y2="219.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="160.5"
            y1="226.722"
            x2="160.5"
            y2="219.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="175.5"
            y1="217.234"
            x2="175.5"
            y2="224.745"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="160.5"
            y1="226.72"
            x2="160.5"
            y2="219.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
