import React, { FC, useCallback, useContext } from 'react'

import { ISubMenuBodyProps } from './interface'
import { SubMenuBodyWrapper } from './styles'
import { SubMenuAttribute } from './components'
import { ProductsContext } from './stage-calculator'

export const SubMenuBody: FC<ISubMenuBodyProps> = props => {
  const { data, menuItemsData, product, productType, onSelect } = props

  const { calculateAdditionalElementPrice, itemSize } = useContext(ProductsContext)

  const getSubMenuAttribute = useCallback(
    attribute => {
      const price = calculateAdditionalElementPrice(attribute.id, productType, product, itemSize)
      const attributeData = menuItemsData[attribute['id']]

      const getAtr = () => {
        if (attribute.id === 'window-model-text') {
          const value = product?.['window-model']?.['window-model-text']
          return { ...attribute, data: { ...attribute?.data, value }}
        }
        return attribute
      }
      const nextAttribute = getAtr()

      return (
        <SubMenuAttribute
          key={attribute.id}
          attribute={nextAttribute}
          data={attributeData}
          onSelect={onSelect({ attribute })}
          price={price}
        />

      )
    },
    [calculateAdditionalElementPrice, itemSize, menuItemsData, onSelect, product, productType],
  )

  return (
    <SubMenuBodyWrapper>
      {data?.attributes?.map(attribute => getSubMenuAttribute(attribute))}
    </SubMenuBodyWrapper>
  )
}
