import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement92Square3Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#9-2_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M421 41.9995C421 40.8952 420.105 40 419.001 40C417.896 40 417.001 40.8952 417.001 41.9995V44.1665H417.001V55H417V58.0005C417 59.1048 417.895 60 418.999 60C420.104 60 420.999 59.1048 420.999 58.0005V55.8332H421V45H421V41.9995Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 421 44.166)"
          fill="#ABABAB"
        />
        <path
          d="M421 40.9399C421 40.4208 420.579 40 420.06 40H417.941C417.422 40 417.001 40.4208 417.001 40.9399V45H421V40.9399Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M417 59.0601C417 59.5792 417.421 60 417.94 60H420.059C420.578 60 420.999 59.5792 420.999 59.0601V55H417V59.0601Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 421 45.833)"
          fill="url(#paint9-2_linear)"
        />
        <g filter="url(#9-2_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M421 86.9995C421 85.8952 420.105 85 419.001 85C417.896 85 417.001 85.8952 417.001 86.9995V89.1665H417.001V100H417V103.001C417 104.105 417.895 105 418.999 105C420.104 105 420.999 104.105 420.999 103.001V100.833H421V90H421V86.9995Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 421 89.166)"
          fill="#ABABAB"
        />
        <path
          d="M421 85.9399C421 85.4208 420.579 85 420.06 85H417.941C417.422 85 417.001 85.4208 417.001 85.9399V90H421V85.9399Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M417 104.06C417 104.579 417.421 105 417.94 105H420.059C420.578 105 420.999 104.579 420.999 104.06V100H417V104.06Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 421 90.833)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#9-2_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 34H297V111H417V34ZM406 45H308V100H406V45Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#9-2_filter9-2_i)">
          <rect
            x="308.47"
            y="45.47"
            width="97.0601"
            height="54.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="302.5" y="39.5" width="109" height="66" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 34H297V111H298V35H416V111H417V34Z"
          fill="#FBFBFB"
        />
        <path d="M404.5 98.3433L404.5 46.6567L310.879 72.5L404.5 98.3433Z" stroke="white" />
        <rect
          x="299"
          y="65"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#9-2_filter4_d)">
          <rect x="299.702" y="65.6445" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M300.286 76.7958C300.798 77.458 301.6 77.8843 302.502 77.8843C304.049 77.8843 305.302 76.6307 305.302 75.0843V68.4444C305.302 67.456 304.79 66.5871 304.017 66.0889C304.383 66.5621 304.601 67.1558 304.601 67.8004V74.4403C304.601 75.9867 303.347 77.2403 301.801 77.2403C301.243 77.2403 300.723 77.0771 300.286 76.7958Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M302.502 65.6445C300.955 65.6445 299.702 66.8981 299.702 68.4445V74.4402C299.702 74.4606 299.702 74.4809 299.702 74.5012V69.0241C299.702 67.4777 300.956 66.2241 302.502 66.2241C304.028 66.2241 305.269 67.4449 305.302 68.9631V68.4445C305.302 66.8981 304.048 65.6445 302.502 65.6445Z"
          fill="#FBFBFB"
        />
        <g filter="url(#9-2_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M302.5 69C301.672 69 301 69.6716 301 70.5V70.7143V72V90.5C301 91.3284 301.672 92 302.5 92C303.328 92 304 91.3284 304 90.5V74V70.5L304 70.2857C304 69.5756 303.424 69 302.714 69C302.677 69 302.64 69.0012 302.604 69.0035C302.569 69.0012 302.535 69 302.5 69Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="9-2_filter0_d"
            x="415.12"
            y="38.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="9-2_filter1_d"
            x="415.12"
            y="83.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="9-2_filter2_d"
            x="292.3"
            y="29.3003"
            width="131.279"
            height="88.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="9-2_filter9-2_i"
            x="308"
            y="45"
            width="98.9399"
            height="57.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="9-2_filter4_d"
            x="297.822"
            y="63.7647"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="9-2_filter5_d"
            x="299.12"
            y="67.1201"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="423.8"
            y1="57.5"
            x2="416.2"
            y2="57.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="414.202"
            y1="42.5"
            x2="421.8"
            y2="42.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="423.798"
            y1="57.5"
            x2="416.2"
            y2="57.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint9-2_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="423.8"
            y1="102.5"
            x2="416.2"
            y2="102.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="414.202"
            y1="87.5"
            x2="421.8"
            y2="87.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="423.798"
            y1="102.5"
            x2="416.2"
            y2="102.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
