import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement32Square82Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 104.999C32 103.895 31.1048 103 30.0005 103C28.8962 103 28.001 103.895 28.001 104.999V107.167H28.001V118H28V121.001C28 122.105 28.8952 123 29.9995 123C31.1038 123 31.999 122.105 31.999 121.001V118.833H32V108H32V104.999Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 32 107.167)"
          fill="#ABABAB"
        />
        <path
          d="M32 103.94C32 103.421 31.5792 103 31.0601 103H28.941C28.4219 103 28.001 103.421 28.001 103.94V108H32V103.94Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M28 122.06C28 122.579 28.4208 123 28.9399 123H31.059C31.5781 123 31.999 122.579 31.999 122.06V118H28V122.06Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 32 108.833)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#2filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 129C32 127.895 31.1046 127 30 127C28.8954 127 28 127.895 28 129V143.56H32V129ZM32 143.56H28V145.4H27.9399C27.4208 145.4 27 145.821 27 146.34V148.12C27 149.158 27.8417 150 28.8799 150H30.1201C31.1583 150 32 149.158 32 148.12V145.4V145.4V143.56Z"
            fill="white"
          />
        </g>
        <rect
          width="3.99998"
          height="0.95833"
          transform="matrix(-1 0 0 1 32 144.25)"
          fill="#ABABAB"
        />
        <path
          d="M32 127.94C32 127.421 31.5792 127 31.0601 127H28.94C28.4208 127 28 127.421 28 127.94V144.25H32V127.94Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M32 149.06C32 149.579 31.5792 150 31.0601 150H27.94C27.4208 150 27 149.579 27 149.06V146.148C27 145.629 27.4208 145.208 27.94 145.208H32V149.06Z"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#2filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M105 99H32V154H105V99ZM95 109H42V144H95V109Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105 99H32V154H33V100H104V154H105V99Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2filter3_i)">
          <rect
            x="42.47"
            y="109.47"
            width="52.0601"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="103.53"
          width="63.9399"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="97"
          y="119"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#2filter4_d)">
          <rect x="97.7018" y="119.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M98.2859 130.796C98.7981 131.458 99.6003 131.884 100.502 131.884C102.049 131.884 103.302 130.63 103.302 129.084V122.444C103.302 121.456 102.79 120.587 102.017 120.089C102.383 120.562 102.6 121.156 102.6 121.8V128.44C102.6 129.986 101.347 131.24 99.8003 131.24C99.2424 131.24 98.7225 131.077 98.2859 130.796Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M100.502 119.644C98.9554 119.644 97.7018 120.898 97.7018 122.444V128.44C97.7018 128.46 97.7021 128.48 97.7025 128.501V123.024C97.7025 121.477 98.9561 120.224 100.502 120.224C102.029 120.224 103.269 121.444 103.302 122.963V122.444C103.302 120.898 102.048 119.644 100.502 119.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M100.5 123C99.6716 123 99 123.672 99 124.5V124.714V126V144.5C99 145.328 99.6716 146 100.5 146V146C101.328 146 102 145.328 102 144.5V128V124.5L102 124.286C102 123.576 101.424 123 100.714 123V123C100.677 123 100.64 123.001 100.604 123.004C100.569 123.001 100.535 123 100.5 123Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M43.5 142.306L92.3756 126.014L43.5 110.681L43.5 142.306Z" stroke="white" />
        <path d="M44.0182 142.5L68.5 110.818L92.9818 142.5H44.0182Z" stroke="white" />
        <defs>
          <filter
            id="2filter0_d"
            x="26.1201"
            y="101.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2filter1_d"
            x="25.1201"
            y="125.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2filter2_d"
            x="27.3003"
            y="94.3003"
            width="84.2793"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2filter3_i"
            x="42"
            y="109"
            width="53.9399"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2filter4_d"
            x="95.822"
            y="117.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2filter5_d"
            x="97.1201"
            y="121.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="34.8"
            y1="120.5"
            x2="27.2"
            y2="120.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="25.2018"
            y1="105.5"
            x2="32.7998"
            y2="105.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="34.7982"
            y1="120.5"
            x2="27.2002"
            y2="120.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="25.2"
            y1="135.625"
            x2="32.8"
            y2="135.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="23.5"
            y1="147.604"
            x2="33"
            y2="147.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
