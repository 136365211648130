import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement51Square62Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#5-1_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M137.001 121C138.105 121 139 120.105 139 119.001C139 117.896 138.105 117.001 137.001 117.001L134.833 117.001L134.833 117.001L124 117.001L124 117L120.999 117C119.895 117 119 117.895 119 118.999C119 120.104 119.895 120.999 120.999 120.999L123.167 120.999L123.167 121L134 121L134 121L137.001 121Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 134.833 121)"
          fill="#ABABAB"
        />
        <path
          d="M138.06 121C138.579 121 139 120.579 139 120.06L139 117.941C139 117.422 138.579 117.001 138.06 117.001L134 117.001L134 121L138.06 121Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M119.94 117C119.421 117 119 117.421 119 117.94L119 120.059C119 120.578 119.421 120.999 119.94 120.999L124 120.999L124 117L119.94 117Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 133.167 121)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#5-1_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M182.001 121C183.105 121 184 120.105 184 119.001C184 117.896 183.105 117.001 182.001 117.001L179.833 117.001L179.833 117.001L169 117.001L169 117L165.999 117C164.895 117 164 117.895 164 118.999C164 120.104 164.895 120.999 165.999 120.999L168.167 120.999L168.167 121L179 121L179 121L182.001 121Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 179.833 121)"
          fill="#ABABAB"
        />
        <path
          d="M183.06 121C183.579 121 184 120.579 184 120.06L184 117.941C184 117.422 183.579 117.001 183.06 117.001L179 117.001L179 121L183.06 121Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M164.94 117C164.421 117 164 117.421 164 117.94L164 120.059C164 120.578 164.421 120.999 164.94 120.999L169 120.999L169 117L164.94 117Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 178.167 121)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#5-1_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 121H115V199H188V121ZM177 132H126V188H177V132Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 121H115V199H116V122H187V199H188V121Z"
          fill="#FBFBFB"
        />
        <g filter="url(#5-1_filter3_i)">
          <rect
            x="126.47"
            y="132.47"
            width="50.0601"
            height="55.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="119.53"
          y="125.53"
          width="63.9399"
          height="68.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M127.776 133.5L151.5 185.79L175.224 133.5L127.776 133.5Z" stroke="white" />
        <rect
          x="158"
          y="190"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 158 190)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="157.356"
          y="190.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 157.356 190.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M157.238 192.695C156.892 191.542 155.822 190.702 154.556 190.702L147.916 190.702C146.37 190.702 145.116 191.955 145.116 193.502C145.116 193.537 145.117 193.572 145.118 193.607C145.464 194.76 146.534 195.6 147.8 195.6L154.44 195.6C155.986 195.6 157.24 194.346 157.24 192.8C157.24 192.765 157.239 192.73 157.238 192.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M155.881 191.034C155.737 191.012 155.59 191 155.44 191L148.8 191C147.254 191 146 192.253 146 193.8C146 194.867 146.597 195.795 147.475 196.267C146.138 196.056 145.116 194.898 145.116 193.502C145.116 191.955 146.37 190.702 147.916 190.702L154.556 190.702C155.035 190.702 155.487 190.822 155.881 191.034Z"
          fill="#FBFBFB"
        />
        <g filter="url(#5-1_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M154 193.5C154 192.672 153.328 192 152.5 192L152.286 192L151 192L132.5 192C131.672 192 131 192.672 131 193.5V193.5C131 194.328 131.672 195 132.5 195L149 195L152.5 195L152.714 195C153.424 195 154 194.424 154 193.714V193.714C154 193.677 153.999 193.64 153.996 193.604C153.999 193.569 154 193.535 154 193.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="5-1_filter0_d"
            x="117.12"
            y="115.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-1_filter1_d"
            x="162.12"
            y="115.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-1_filter2_d"
            x="110.3"
            y="116.3"
            width="84.2793"
            height="89.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-1_filter3_i"
            x="126"
            y="132"
            width="51.9399"
            height="58.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-1_filter4_d"
            x="129.28"
            y="190.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="121.5"
            y1="123.8"
            x2="121.5"
            y2="116.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="136.5"
            y1="114.202"
            x2="136.5"
            y2="121.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="121.5"
            y1="123.798"
            x2="121.5"
            y2="116.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="166.5"
            y1="123.8"
            x2="166.5"
            y2="116.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="181.5"
            y1="114.202"
            x2="181.5"
            y2="121.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="166.5"
            y1="123.798"
            x2="166.5"
            y2="116.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
