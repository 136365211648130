import React from 'react'
import { ArrowDownWrapper } from '../../views/calculator/styles'

export const ArrowDownIcon = ({ up = false }) => {
  return (
    <ArrowDownWrapper up={up}>
      <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.07078 5.83709L0.17573 1.29221C0.0624222 1.1871 -4.57559e-08 1.04677 -3.92158e-08 0.897152C-3.26756e-08 0.74753 0.0624222 0.607207 0.17573 0.50209L0.536134 0.167391C0.770977 -0.0503998 1.15267 -0.0503998 1.38715 0.167391L5.49772 3.98384L9.61285 0.163157C9.72616 0.0580392 9.8772 4.31746e-07 10.0383 4.38787e-07C10.1995 4.45835e-07 10.3506 0.0580392 10.464 0.163157L10.8243 0.497855C10.9376 0.603056 11 0.743296 11 0.892918C11 1.04254 10.9376 1.18286 10.8243 1.28798L5.92475 5.83709C5.81108 5.94246 5.65932 6.00033 5.49799 6C5.33603 6.00033 5.18436 5.94246 5.07078 5.83709Z"
          fill="#1C3775"
        />
      </svg>
    </ArrowDownWrapper>
  )
}
