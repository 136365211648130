import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement12Square62Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#1-2_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 88.9995C32 87.8952 31.1048 87 30.0005 87C28.8962 87 28.001 87.8952 28.001 88.9995V91.1665H28.001V102H28V105.001C28 106.105 28.8952 107 29.9995 107C31.1038 107 31.999 106.105 31.999 105.001V102.833H32V92H32V88.9995Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 32 91.1665)"
          fill="#ABABAB"
        />
        <path
          d="M32 87.9399C32 87.4208 31.5792 87 31.0601 87H28.941C28.4219 87 28.001 87.4208 28.001 87.9399V92H32V87.9399Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M28 106.06C28 106.579 28.4208 107 28.9399 107H31.059C31.5781 107 31.999 106.579 31.999 106.06V102H28V106.06Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 32 92.8335)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#1-2_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 38.9995C32 37.8952 31.1048 37 30.0005 37C28.8962 37 28.001 37.8952 28.001 38.9995V41.1665H28.001V52H28V55.0005C28 56.1048 28.8952 57 29.9995 57C31.1038 57 31.999 56.1048 31.999 55.0005V52.8332H32V42H32V38.9995Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 32 41.1665)"
          fill="#ABABAB"
        />
        <path
          d="M32 37.9399C32 37.4208 31.5792 37 31.0601 37H28.941C28.4219 37 28.001 37.4208 28.001 37.9399V42H32V37.9399Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M28 56.0601C28 56.5792 28.4208 57 28.9399 57H31.059C31.5781 57 31.999 56.5792 31.999 56.0601V52H28V56.0601Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 32 42.8335)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#1-2_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M105 33H32V111H105V33ZM94 44H43V100H94V44Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105 33H32V111H33V34H104V111H105V33Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-2_filter3_i)">
          <rect
            x="43.47"
            y="44.47"
            width="50.0601"
            height="55.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="37.53"
          width="63.9399"
          height="68.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect x="96" y="64" width="7" height="13.5283" rx="3.5" fill="#9B9DA2" fillOpacity="0.19" />
        <g filter="url(#1-2_filter4_d)">
          <rect x="96.7018" y="64.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M97.2859 75.7953C97.7981 76.4575 98.6003 76.8838 99.5021 76.8838C101.049 76.8838 102.302 75.6302 102.302 74.0838V67.4439C102.302 66.4555 101.79 65.5867 101.017 65.0884C101.383 65.5616 101.6 66.1553 101.6 66.7999V73.4398C101.6 74.9862 100.347 76.2398 98.8003 76.2398C98.2424 76.2398 97.7225 76.0766 97.2859 75.7953Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99.5018 64.644C97.9554 64.644 96.7018 65.8976 96.7018 67.444V73.4397C96.7018 73.4601 96.7021 73.4805 96.7025 73.5007V68.0236C96.7025 66.4772 97.9561 65.2236 99.5025 65.2236C101.029 65.2236 102.269 66.4444 102.302 67.9627V67.444C102.302 65.8976 101.048 64.644 99.5018 64.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-2_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M99.5 68C98.6716 68 98 68.6716 98 69.5V69.7143V71V89.5C98 90.3284 98.6716 91 99.5 91V91C100.328 91 101 90.3284 101 89.5V73V69.5L101 69.2857C101 68.5756 100.424 68 99.7143 68V68C99.6771 68 99.6401 68.0012 99.6035 68.0035C99.5693 68.0012 99.5348 68 99.5 68Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M44.5 98.1384L91.9638 71.0162L44.5 45.8312L44.5 98.1384Z" stroke="white" />
        <defs>
          <filter
            id="1-2_filter0_d"
            x="26.1201"
            y="85.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-2_filter1_d"
            x="26.1201"
            y="35.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-2_filter2_d"
            x="27.3003"
            y="28.3003"
            width="84.2793"
            height="89.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-2_filter3_i"
            x="43"
            y="44"
            width="51.9399"
            height="58.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="1-2_filter4_d"
            x="94.822"
            y="62.7642"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-2_filter5_d"
            x="96.1201"
            y="66.1201"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="34.8"
            y1="104.5"
            x2="27.2"
            y2="104.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="25.2018"
            y1="89.5"
            x2="32.7998"
            y2="89.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="34.7982"
            y1="104.5"
            x2="27.2002"
            y2="104.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="34.8"
            y1="54.5"
            x2="27.2"
            y2="54.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="25.2018"
            y1="39.5"
            x2="32.7998"
            y2="39.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="34.7982"
            y1="54.5"
            x2="27.2002"
            y2="54.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
