import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement33Square62Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#3-3_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.0005 209C55.1048 209 56 208.105 56 207.001C56 205.896 55.1048 205.001 54.0005 205.001L51.8335 205.001L51.8335 205.001L41 205.001L41 205L37.9995 205C36.8952 205 36 205.895 36 206.999C36 208.104 36.8952 208.999 37.9995 208.999L40.1668 208.999L40.1668 209L51 209L51 209L54.0005 209Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 51.8335 209)"
          fill="#ABABAB"
        />
        <path
          d="M55.0601 209C55.5792 209 56 208.579 56 208.06L56 205.941C56 205.422 55.5792 205.001 55.0601 205.001L51 205.001L51 209L55.0601 209Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M36.9399 205C36.4208 205 36 205.421 36 205.94L36 208.059C36 208.578 36.4208 208.999 36.9399 208.999L41 208.999L41 205L36.9399 205Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 50.1666 209)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#3-3_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M99.0005 209C100.105 209 101 208.105 101 207.001C101 205.896 100.105 205.001 99.0005 205.001L96.8335 205.001L96.8335 205.001L86 205.001L86 205L82.9995 205C81.8952 205 81 205.895 81 206.999C81 208.104 81.8952 208.999 82.9995 208.999L85.1668 208.999L85.1668 209L96 209L96 209L99.0005 209Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 96.8335 209)"
          fill="#ABABAB"
        />
        <path
          d="M100.06 209C100.579 209 101 208.579 101 208.06L101 205.941C101 205.422 100.579 205.001 100.06 205.001L96 205.001L96 209L100.06 209Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M81.9399 205C81.4208 205 81 205.421 81 205.94L81 208.059C81 208.578 81.4208 208.999 81.9399 208.999L86 208.999L86 205L81.9399 205Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 95.1666 209)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#3-3_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M105 209H32V287H105V209ZM94 220H43V276H94V220Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105 209H32V287H33V210H104V287H105V209Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-3_filter3_i)">
          <rect
            x="43.47"
            y="220.47"
            width="50.0601"
            height="55.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="213.53"
          width="63.9399"
          height="68.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M44.7759 221.5L68.5 273.79L92.2241 221.5L44.7759 221.5Z" stroke="white" />
        <rect
          x="75"
          y="278"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 75 278)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="74.356"
          y="278.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 74.356 278.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74.2379 280.695C73.8915 279.542 72.8218 278.702 71.5559 278.702L64.916 278.702C63.3696 278.702 62.116 279.955 62.116 281.502C62.116 281.537 62.1167 281.572 62.118 281.607C62.4643 282.76 63.534 283.6 64.8 283.6L71.4399 283.6C72.9863 283.6 74.2399 282.346 74.2399 280.8C74.2399 280.765 74.2392 280.73 74.2379 280.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72.881 279.034C72.7373 279.012 72.59 279 72.4399 279L65.8 279C64.2536 279 63 280.253 63 281.8C63 282.867 63.5969 283.795 64.475 284.267C63.1382 284.056 62.1161 282.898 62.1161 281.502C62.1161 279.955 63.3697 278.702 64.9161 278.702L71.556 278.702C72.0353 278.702 72.4865 278.822 72.881 279.034Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-3_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M71 281.5C71 280.672 70.3284 280 69.5 280L69.2857 280L68 280L49.5 280C48.6716 280 48 280.672 48 281.5V281.5C48 282.328 48.6716 283 49.5 283L66 283L69.5 283L69.7143 283C70.4244 283 71 282.424 71 281.714V281.714C71 281.677 70.9988 281.64 70.9965 281.604C70.9988 281.569 71 281.535 71 281.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="3-3_filter0_d"
            x="34.1201"
            y="203.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-3_filter1_d"
            x="79.1201"
            y="203.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-3_filter2_d"
            x="27.3003"
            y="204.3"
            width="84.2793"
            height="89.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-3_filter3_i"
            x="43"
            y="220"
            width="51.9399"
            height="58.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-3_filter4_d"
            x="46.28"
            y="278.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="38.5"
            y1="211.8"
            x2="38.5"
            y2="204.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="53.5"
            y1="202.202"
            x2="53.5"
            y2="209.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="38.5"
            y1="211.798"
            x2="38.5"
            y2="204.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="83.5"
            y1="211.8"
            x2="83.5"
            y2="204.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="98.5"
            y1="202.202"
            x2="98.5"
            y2="209.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="83.5"
            y1="211.798"
            x2="83.5"
            y2="204.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
