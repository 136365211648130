import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement42Square4VerticalCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-2_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M60.0235 230.954C61.1151 230.954 62 230.069 62 228.978C62 227.886 61.1151 227.001 60.0235 227.001L57.8335 227.001L57.8335 227.001L47 227.001L47 227L43.9765 227C42.8849 227 42 227.885 42 228.976C42 230.068 42.8849 230.953 43.9765 230.953L46.1668 230.953L46.1668 230.954L57 230.954L57 230.954L60.0235 230.954Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 57.8335 230.954)"
          fill="#ABABAB"
        />
        <path
          d="M61.0601 230.954C61.5792 230.954 62 230.533 62 230.014L62 227.941C62 227.422 61.5792 227.001 61.0601 227.001L57 227.001L57 230.954L61.0601 230.954Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M42.9399 227C42.4208 227 42 227.421 42 227.94L42 230.013C42 230.532 42.4208 230.953 42.9399 230.953L47 230.953L47 227L42.9399 227Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 56.1666 230.954)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#4-2_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M176.024 230.954C177.115 230.954 178 230.069 178 228.978C178 227.886 177.115 227.001 176.024 227.001L173.833 227.001L173.833 227.001L163 227.001L163 227L159.976 227C158.885 227 158 227.885 158 228.976C158 230.068 158.885 230.953 159.976 230.953L162.167 230.953L162.167 230.954L173 230.954L173 230.954L176.024 230.954Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 173.833 230.954)"
          fill="#ABABAB"
        />
        <path
          d="M177.06 230.954C177.579 230.954 178 230.533 178 230.014L178 227.941C178 227.422 177.579 227.001 177.06 227.001L173 227.001L173 230.954L177.06 230.954Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M158.94 227C158.421 227 158 227.421 158 227.94L158 230.013C158 230.532 158.421 230.953 158.94 230.953L163 230.953L163 227L158.94 227Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 172.167 230.954)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#4-2_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 231H32V286H188V231ZM178 241H42V276H178V241Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 231H32V286H33V232H187V286H188V231Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-2_filter3_i)">
          <rect
            x="42.47"
            y="241.47"
            width="135.06"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="235.53"
          width="146.94"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="116"
          y="278"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 116 278)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="115.356"
          y="278.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 115.356 278.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115.238 280.695C114.892 279.542 113.822 278.702 112.556 278.702L105.916 278.702C104.37 278.702 103.116 279.955 103.116 281.502C103.116 281.537 103.117 281.572 103.118 281.607C103.464 282.76 104.534 283.6 105.8 283.6L112.44 283.6C113.986 283.6 115.24 282.346 115.24 280.8C115.24 280.765 115.239 280.73 115.238 280.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.881 279.035C113.737 279.012 113.59 279 113.44 279L106.8 279C105.254 279 104 280.254 104 281.8C104 282.867 104.597 283.795 105.475 284.267C104.138 284.056 103.116 282.898 103.116 281.502C103.116 279.955 104.37 278.702 105.916 278.702L112.556 278.702C113.035 278.702 113.487 278.822 113.881 279.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-2_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M112 281.5C112 280.672 111.328 280 110.5 280L110.286 280L109 280L90.5 280C89.6716 280 89 280.672 89 281.5V281.5C89 282.328 89.6716 283 90.5 283L107 283L110.5 283L110.714 283C111.424 283 112 282.424 112 281.714V281.714C112 281.677 111.999 281.64 111.996 281.604C111.999 281.569 112 281.535 112 281.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M174.853 242.5L110 274.443L45.1468 242.5L174.853 242.5Z" stroke="white" />
        <defs>
          <filter
            id="4-2_filter0_d"
            x="40.1201"
            y="225.12"
            width="25.6396"
            height="9.59374"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-2_filter1_d"
            x="156.12"
            y="225.12"
            width="25.6396"
            height="9.59374"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-2_filter2_d"
            x="27.3003"
            y="226.3"
            width="167.279"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-2_filter3_i"
            x="42"
            y="241"
            width="136.94"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-2_filter4_d"
            x="87.28"
            y="278.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="44.5"
            y1="233.722"
            x2="44.5"
            y2="226.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="59.5"
            y1="224.234"
            x2="59.5"
            y2="231.745"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="44.5"
            y1="233.72"
            x2="44.5"
            y2="226.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="160.5"
            y1="233.722"
            x2="160.5"
            y2="226.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="175.5"
            y1="224.234"
            x2="175.5"
            y2="231.745"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="160.5"
            y1="233.72"
            x2="160.5"
            y2="226.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
