import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement22Square41Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-2filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M132 257.999C132 256.895 131.105 256 130.001 256C128.896 256 128.001 256.895 128.001 257.999V260.167H128.001V271H128V274.001C128 275.105 128.895 276 129.999 276C131.104 276 131.999 275.105 131.999 274.001V271.833H132V261H132V257.999Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 132 260.166)"
          fill="#ABABAB"
        />
        <path
          d="M132 256.94C132 256.421 131.579 256 131.06 256H128.941C128.422 256 128.001 256.421 128.001 256.94V261H132V256.94Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M128 275.06C128 275.579 128.421 276 128.94 276H131.059C131.578 276 131.999 275.579 131.999 275.06V271H128V275.06Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 132 261.833)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#2-2filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M132 46.9995C132 45.8952 131.105 45 130.001 45C128.896 45 128.001 45.8952 128.001 46.9995V49.1665H128.001V60H128V63.0005C128 64.1048 128.895 65 129.999 65C131.104 65 131.999 64.1048 131.999 63.0005V60.8332H132V50H132V46.9995Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 132 49.1665)"
          fill="#ABABAB"
        />
        <path
          d="M132 45.9399C132 45.4208 131.579 45 131.06 45H128.941C128.422 45 128.001 45.4208 128.001 45.9399V50H132V45.9399Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M128 64.0601C128 64.5792 128.421 65 128.94 65H131.059C131.578 65 131.999 64.5792 131.999 64.0601V60H128V64.0601Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 132 50.8334)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#2-2filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M221 34H132V287H221V34ZM210 45H143V276H210V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M221 34H132V287H133.219V35H219.781V287H221V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-2filter3_i)">
          <rect
            x="143.47"
            y="45.47"
            width="66.0601"
            height="230.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="136.53"
          y="38.53"
          width="79.9399"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="212"
          y="155"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#2-2filter4_d)">
          <rect x="212.702" y="155.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M213.286 166.795C213.798 167.458 214.6 167.884 215.502 167.884C217.049 167.884 218.302 166.63 218.302 165.084V158.444C218.302 157.456 217.79 156.587 217.017 156.089C217.383 156.562 217.6 157.155 217.6 157.8V164.44C217.6 165.986 216.347 167.24 214.8 167.24C214.242 167.24 213.723 167.077 213.286 166.795Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M215.502 155.644C213.955 155.644 212.702 156.898 212.702 158.444V164.44C212.702 164.46 212.702 164.48 212.702 164.501V159.024C212.702 157.477 213.956 156.224 215.502 156.224C217.028 156.224 218.269 157.444 218.302 158.963V158.444C218.302 156.898 217.048 155.644 215.502 155.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-2filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M215.5 159C214.672 159 214 159.672 214 160.5V160.714V162V180.5C214 181.328 214.672 182 215.5 182V182C216.328 182 217 181.328 217 180.5V164V160.5L217 160.286C217 159.576 216.424 159 215.714 159V159C215.677 159 215.64 159.001 215.604 159.004C215.569 159.001 215.535 159 215.5 159Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M144.5 273.114L208.425 160.999L144.5 47.9008L144.5 273.114Z" stroke="white" />
        <defs>
          <filter
            id="2-2-filter0_d"
            x="126.12"
            y="254.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-2-filter1_d"
            x="126.12"
            y="43.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-2-filter2_d"
            x="127.3"
            y="29.3003"
            width="100.279"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-2-filter3_i"
            x="143"
            y="45"
            width="67.9399"
            height="233.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-2-filter4_d"
            x="210.822"
            y="153.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-2-filter5_d"
            x="212.12"
            y="157.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="134.8"
            y1="273.5"
            x2="127.2"
            y2="273.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="125.202"
            y1="258.5"
            x2="132.8"
            y2="258.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="134.798"
            y1="273.5"
            x2="127.2"
            y2="273.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="134.8"
            y1="62.5"
            x2="127.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="125.202"
            y1="47.5"
            x2="132.8"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="134.798"
            y1="62.5"
            x2="127.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
