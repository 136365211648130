import React from 'react'

import { SelectIconWrapper } from '../../components/select/styles'

export const SelectIcon = ({ arrowDown = true }) => {
  return (
    <SelectIconWrapper arrowDown={arrowDown}>
      <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.64092 3.22484L1.83602 0.215363C1.56839 -0.0717874 1.13447 -0.0717874 0.866843 0.215363C0.599213 0.502513 0.599213 0.968076 0.866843 1.25523L4.15633 4.78464C4.42396 5.07179 4.85788 5.07179 5.12551 4.78464L8.415 1.25523C8.68263 0.968076 8.68263 0.502513 8.415 0.215363C8.14737 -0.0717874 7.71345 -0.0717874 7.44582 0.215363L4.64092 3.22484Z"
          fill="#333333"
        />
      </svg>
    </SelectIconWrapper>
  )
}
