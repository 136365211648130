import fetchAPI from 'src/lib/utils/fetch-api'

export async function insetFileToColletion(file, targetId?: number) {
  const name = 'file-s3'
  const writeFile = {
    date: file['date'] || new Date().toISOString(),
    name: file['name'],
    size: file['size'],
    type: file['type'] || '',
    url: file['url'],
    'name-field': file['name-field'],
    'is_deleted': false,
    'code': file['code'] || null,
  }

  const requestBody = {
    'parent_id': Number.isFinite(targetId) ? targetId : 0,
    'data': {
      'name': writeFile['name'],
      'attributes': writeFile,
      'metadata': {
        'schema': name,
      },
    },
  }

  const result = await fetchAPI(`/api/collections/${name}`, {
    method: 'POST',
    body: JSON.stringify(requestBody),
  })
  if (result['data'] && result['data']['id']) {
    return result['data']['id']
  }
  return null
}
