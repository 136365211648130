import React, { useCallback, useEffect, useRef, useState, useMemo, useContext } from 'react'
import { MainViewIconBlock } from '../../styles'
import { ColorBucketImage } from '../../images'
import { WallColorItem, WallColorSelectTooltip, WallColorSelectWrapper } from './styles'
import { colorList, SUCCESS_THEME } from './consts'
import { NoneIcon } from '../../../../../assets/icons'
import { SuccessRadiusIcon } from '../../../../../assets/icons/success-radius-icon'
import { ProductsContext } from '../../stage-calculator'

export const WallColorSelect = () => {
  const [active, setActive] = useState('none')
  const [show, setShow] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const { setWallColor } = useContext(ProductsContext)

  const handleToggleTooltip = useCallback(() => {
    setShow(prevState => !prevState)
  }, [])

  const handleClickOutside = useCallback(event => {
    if (wrapperRef && wrapperRef.current !== null && !wrapperRef.current.contains(event.target)) {
      setShow(false)
    }
  }, [])

  useEffect(() => {
    // eslint-disable-next-line no-undef
    document.addEventListener('click', handleClickOutside)

    return () => {
      // eslint-disable-next-line no-undef
      document.removeEventListener('click', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelectColor = useCallback(
    item => () => {
      setActive(item['code'])
      setWallColor(item['color'])
    },
    [setWallColor],
  )

  const successColor = useCallback((theme = SUCCESS_THEME) => {
    // @ts-ignore
    return theme === SUCCESS_THEME.DARK ? '#1c3775' : '#ffffff'
  }, [])

  const bucketColor = useMemo(() => {
    return show ? '#ffffff' : '#1c3775'
  }, [show])

  return (
    <WallColorSelectWrapper ref={wrapperRef} onClick={handleToggleTooltip}>
      <MainViewIconBlock active={show}>
        <ColorBucketImage color={bucketColor} />
      </MainViewIconBlock>
      <span>Цвет стен</span>
      <WallColorSelectTooltip show={show}>
        {colorList.map(item => (
          <WallColorItem key={item['code']} color={item['color']} onClick={handleSelectColor(item)}>
            {item['code'] === 'none' && <NoneIcon />}
            {active === item['code'] && item['code'] !== 'none' && (
              <SuccessRadiusIcon
                width={10}
                height={10}
                color={successColor(item['successTheme'] as any)}
              />
            )}
          </WallColorItem>
        ))}
      </WallColorSelectTooltip>
    </WallColorSelectWrapper>
  )
}
