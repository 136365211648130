import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement66Square8Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#6-6_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M224 177.999C224 176.895 223.105 176 222.001 176C220.896 176 220.001 176.895 220.001 177.999V180.167H220.001V191H220V194.001C220 195.105 220.895 196 221.999 196C223.104 196 223.999 195.105 223.999 194.001V191.833H224V181H224V177.999Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 224 180.167)"
          fill="#ABABAB"
        />
        <path
          d="M224 176.94C224 176.421 223.579 176 223.06 176H220.941C220.422 176 220.001 176.421 220.001 176.94V181H224V176.94Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M220 195.06C220 195.579 220.421 196 220.94 196H223.059C223.578 196 223.999 195.579 223.999 195.06V191H220V195.06Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 224 181.833)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#6-6_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M220 254C220 252.895 220.895 252 222 252C223.105 252 224 252.895 224 254V268.56H220V254ZM220 268.56H224V270.4H224.06C224.579 270.4 225 270.821 225 271.34V273.12C225 274.158 224.158 275 223.12 275H221.88C220.842 275 220 274.158 220 273.12V270.4V270.4V268.56Z"
            fill="white"
          />
        </g>
        <rect x="220" y="269.25" width="3.99998" height="0.95833" fill="#ABABAB" />
        <path
          d="M220 252.94C220 252.421 220.421 252 220.94 252H223.06C223.579 252 224 252.421 224 252.94V269.25H220V252.94Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M220 274.06C220 274.579 220.421 275 220.94 275H224.06C224.579 275 225 274.579 225 274.06V271.148C225 270.629 224.579 270.208 224.06 270.208H220V274.06Z"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#6-6_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M220 165H131V286H220V165ZM209 176H142V275H209V176Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M220 165H131V286H132.219V166H218.781V286H220V165Z"
          fill="#FBFBFB"
        />
        <g filter="url(#6-6_filter3_i)">
          <rect
            x="142.47"
            y="176.47"
            width="66.0601"
            height="98.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="135.53"
          y="169.53"
          width="79.9399"
          height="111.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="133"
          y="218"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#6-6_filter4_d)">
          <rect x="133.702" y="218.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M134.286 229.795C134.798 230.457 135.6 230.884 136.502 230.884C138.048 230.884 139.302 229.63 139.302 228.084V221.444C139.302 220.455 138.79 219.587 138.016 219.088C138.382 219.562 138.6 220.155 138.6 220.8V227.44C138.6 228.986 137.347 230.24 135.8 230.24C135.242 230.24 134.722 230.077 134.286 229.795Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M136.502 218.644C134.956 218.644 133.702 219.898 133.702 221.444V227.44C133.702 227.46 133.702 227.48 133.703 227.501V222.024C133.703 220.477 134.956 219.224 136.503 219.224C138.029 219.224 139.269 220.444 139.302 221.963V221.444C139.302 219.898 138.048 218.644 136.502 218.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#6-6_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M136.5 222C135.672 222 135 222.672 135 223.5V223.714V225V243.5C135 244.328 135.672 245 136.5 245V245C137.328 245 138 244.328 138 243.5V227V223.5L138 223.286C138 222.576 137.424 222 136.714 222V222C136.677 222 136.64 222.001 136.604 222.004C136.569 222.001 136.535 222 136.5 222Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M207.5 177.997L143.836 225.5L207.5 273.003L207.5 177.997Z" stroke="white" />
        <path d="M143.695 273.5L175.5 178.574L207.305 273.5L143.695 273.5Z" stroke="white" />
        <defs>
          <filter
            id="6-6_filter0_d"
            x="218.12"
            y="174.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-6_filter1_d"
            x="218.12"
            y="250.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-6_filter2_d"
            x="126.3"
            y="160.3"
            width="100.279"
            height="132.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-6_filter3_i"
            x="142"
            y="176"
            width="67.9399"
            height="101.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="6-6_filter4_d"
            x="131.822"
            y="216.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-6_filter5_d"
            x="133.12"
            y="220.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="226.8"
            y1="193.5"
            x2="219.2"
            y2="193.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="217.202"
            y1="178.5"
            x2="224.8"
            y2="178.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="226.798"
            y1="193.5"
            x2="219.2"
            y2="193.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="226.8"
            y1="260.625"
            x2="219.2"
            y2="260.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="228.5"
            y1="272.604"
            x2="219"
            y2="272.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
