import React from 'react'

export const CarIcon = ({ color = '#D4D4D4' }) => {
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5454 3.49998H13.0909V0H1.63637C0.732259 0 0 0.78311 0 1.75001V11.375H1.63637C1.63637 12.8231 2.73681 14 4.09092 14C5.44502 14 6.54546 12.8231 6.54546 11.375H11.4545C11.4545 12.8231 12.555 14 13.9091 14C15.2632 14 16.3636 12.8231 16.3636 11.375H18V7L15.5454 3.49998ZM4.09092 12.6875C3.41181 12.6875 2.86365 12.1012 2.86365 11.375C2.86365 10.6487 3.41181 10.0625 4.09092 10.0625C4.77002 10.0625 5.31819 10.6487 5.31819 11.375C5.31819 12.1012 4.76998 12.6875 4.09092 12.6875ZM13.9091 12.6875C13.23 12.6875 12.6818 12.1012 12.6818 11.375C12.6818 10.6487 13.23 10.0625 13.9091 10.0625C14.5882 10.0625 15.1364 10.6487 15.1364 11.375C15.1364 12.1012 14.5881 12.6875 13.9091 12.6875ZM13.0909 7V4.81252H15.1363L16.744 7H13.0909Z"
        fill={color}
      />
    </svg>
  )
}
