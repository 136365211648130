import React, { FC } from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'
import { IContainerSquareProps } from '../../interface'
import { ContainerBackground } from '../container-background'

export const ContainerSquare53Casement: FC<IContainerSquareProps> = props => {
  const { onCasementClick, withoutBackground } = props

  return (
    <ProductImageWrapper isClickable>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <rect width="449" height="333" fill="white" />
        <rect x="24" y="28" width="401" height="264" fill="#E8EAEE" />
        <rect x="24" y="28" width="401" height="264" fill="url(#5-3-pattern0)" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M439 10H10V292H24V29H425V292H439V10Z"
          fill="#C4C4C4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M439 292H10V308H0V316H10H439H449V308H439V292Z"
          fill="url(#5-3-paint0_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M425 28H23V292H425V28ZM214 41H36V104H214V41ZM36 127H112V282H36V127ZM317 127H131V282H317V127ZM336 127H412V282H336V127ZM412 41H234V104H412V41Z"
          fill="#E8EAEE"
        />
        <path d="M439 10L425 28.0571V292.075L439 308V10Z" fill="#E9EBEF" />
        <path d="M439 10L425 28.0571V292.075L439 308V10Z" fill="#020202" fillOpacity="0.06" />
        <path d="M10 10L24 28.1525V291.982L10 308V10Z" fill="#E9EBEF" />
        <path d="M10 10L24 28.1525V291.982L10 308V10Z" fill="#020202" fillOpacity="0.06" />
        <rect y="316" width="449" height="17" fill="white" />
        <g
          filter="url(#5-3-filter0_i)"
          className="casement"
          data-index="3"
          onClick={onCasementClick(3)}
        >
          <rect x="131" y="127" width="186" height="155" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="131.47"
          y="127.47"
          width="185.06"
          height="154.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#5-3-filter1_i)"
          className="casement"
          data-index="4"
          onClick={onCasementClick(4)}
        >
          <rect x="336" y="127" width="76" height="155" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="336.47"
          y="127.47"
          width="75.0601"
          height="154.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#5-3-filter2_i)"
          className="casement"
          data-index="2"
          onClick={onCasementClick(2)}
        >
          <rect x="36" y="127" width="76" height="155" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="36.47"
          y="127.47"
          width="75.0601"
          height="154.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#5-3-filter3_i)"
          className="casement"
          data-index="0"
          onClick={onCasementClick(0)}
        >
          <rect x="36" y="41" width="178" height="63" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="36.47"
          y="41.47"
          width="177.06"
          height="62.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#5-3-filter4_i)"
          className="casement"
          data-index="1"
          onClick={onCasementClick(1)}
        >
          <rect x="234" y="41" width="178" height="63" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="234.47"
          y="41.47"
          width="177.06"
          height="62.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <path
          d="M232.985 148.74H230.447C229.8 148.74 229.253 148.234 229.253 147.635V147.404C229.253 146.806 229.8 146.299 230.447 146.299H232.985C233.632 146.299 234.18 146.806 234.18 147.404V147.635C234.18 148.257 233.657 148.74 232.985 148.74Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M215.426 151.565C215.725 151.565 215.799 151.565 216.197 151.565C216.943 151.565 217.49 152.105 217.49 152.833C217.49 153.584 216.918 154.101 216.147 154.101C214.705 154.101 213.81 154.101 212.367 154.101C211.82 154.101 211.373 153.913 211.149 153.42C210.95 153.115 210.826 152.27 211.621 151.565C212.144 150.978 217.913 144.757 218.933 143.677C218.982 143.606 218.933 143.512 218.858 143.512C218.833 143.512 218.833 143.512 218.808 143.512C216.645 143.512 214.481 143.512 212.318 143.512C211.671 143.512 211.174 143.137 211.049 142.573C210.9 141.916 211.199 141.352 211.771 141.094C211.945 141.023 212.119 141 212.293 141C215.501 141 218.684 141 221.892 141C222.514 141 222.937 141.188 223.185 141.728C223.509 142.409 223.16 142.878 222.937 143.183C222.489 143.723 217.938 148.56 215.426 151.283C215.401 151.307 215.376 151.354 215.327 151.377C215.277 151.448 215.327 151.565 215.426 151.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M222.185 154.101H219.647C219.001 154.101 218.453 153.582 218.453 152.968V152.732C218.453 152.119 219.001 151.6 219.647 151.6H222.185C222.832 151.6 223.38 152.119 223.38 152.732V152.968C223.38 153.605 222.832 154.101 222.185 154.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M225.716 147.515C225.716 145.734 225.716 143.976 225.716 142.195C225.716 141.492 226.238 141 226.984 141C230.215 141 233.447 141 236.678 141C237.424 141 237.946 141.516 237.946 142.242C237.946 142.969 237.424 143.508 236.678 143.508C233.994 143.508 231.334 143.508 228.649 143.508C228.45 143.508 228.401 143.555 228.401 143.742C228.401 146.273 228.401 148.804 228.401 151.335C228.401 151.546 228.475 151.57 228.649 151.57C231.309 151.57 233.994 151.57 236.654 151.57C237.25 151.57 237.723 151.898 237.897 152.39C238.095 152.976 237.872 153.562 237.399 153.89C237.176 154.03 236.902 154.101 236.629 154.101C233.447 154.101 230.24 154.101 227.058 154.101C226.238 154.101 225.741 153.585 225.741 152.812C225.716 151.031 225.716 149.273 225.716 147.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M212.705 159.041V159.698H211V159.041H211.426V157.258H211.05V156.602H212.68V157.258H212.304V159.041H212.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M216.535 159.759H215.774L215.651 159.546C215.381 159.688 215.062 159.759 214.768 159.759C213.934 159.735 213.148 159.168 213.148 158.151C213.148 157.181 213.811 156.543 214.817 156.543C215.651 156.543 216.461 157.087 216.461 158.151C216.461 158.529 216.363 158.813 216.216 159.073L216.559 159.64V159.759H216.535ZM214.792 159.002C215.185 159.002 215.602 158.718 215.602 158.151C215.602 157.583 215.185 157.3 214.792 157.3C214.375 157.3 213.983 157.583 213.983 158.151C213.983 158.718 214.375 159.002 214.792 159.002Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M218.784 158.749C218.784 158.961 219.029 159.078 219.3 159.078C219.545 159.078 219.791 158.961 219.791 158.796C219.791 158.491 219.422 158.491 219.201 158.468C218.612 158.421 218.023 158.209 218.023 157.552C218.023 156.872 218.612 156.543 219.275 156.543C219.889 156.543 220.527 156.872 220.527 157.552H219.717C219.717 157.341 219.496 157.247 219.275 157.247C219.079 157.247 218.833 157.294 218.833 157.529C218.833 157.74 219.029 157.787 219.324 157.81C220.012 157.857 220.601 158.022 220.601 158.773C220.601 159.43 219.938 159.759 219.275 159.759C218.587 159.759 217.924 159.43 217.949 158.749H218.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M223.228 157.311H222.037V157.76H223.129V158.469H222.037V158.965H223.253V159.698H221.169V156.602H223.204V157.311H223.228Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M226.603 159.58V159.698H225.722L225.159 158.729H224.719V159.698H223.887V156.602C224.376 156.602 224.841 156.602 225.306 156.602C226.089 156.602 226.456 157.169 226.456 157.713C226.456 158.067 226.285 158.422 225.966 158.611L226.603 159.58ZM225.306 158.067C225.551 158.067 225.648 157.878 225.648 157.665C225.648 157.476 225.526 157.287 225.306 157.287H224.719V158.067H225.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M228.276 158.334L228.552 157.527L228.955 156.602H229.886V156.72L228.452 159.758H228.099L226.665 156.72V156.602H227.596L228.024 157.527L228.276 158.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M231.782 159.041V159.698H230.076V159.041H230.503V157.258H230.126V156.602H231.756V157.258H231.38V159.041H231.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M233.835 156.602C234.509 156.602 235.258 156.955 235.383 157.779H234.559C234.434 157.449 234.185 157.332 233.835 157.332C233.336 157.332 233.011 157.661 233.011 158.18C233.011 158.627 233.336 159.028 233.835 159.028C234.185 159.028 234.484 158.863 234.559 158.533H235.383C235.258 159.404 234.534 159.758 233.835 159.758C232.986 159.758 232.162 159.216 232.162 158.18C232.162 157.12 232.986 156.602 233.835 156.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M237.944 157.311H236.753V157.76H237.845V158.469H236.753V158.965H237.969V159.698H235.885V156.602H237.919V157.311H237.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M382.985 148.74H380.447C379.8 148.74 379.253 148.234 379.253 147.635V147.404C379.253 146.806 379.8 146.299 380.447 146.299H382.985C383.632 146.299 384.18 146.806 384.18 147.404V147.635C384.18 148.257 383.657 148.74 382.985 148.74Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M365.426 151.565C365.725 151.565 365.799 151.565 366.197 151.565C366.943 151.565 367.49 152.105 367.49 152.833C367.49 153.584 366.918 154.101 366.147 154.101C364.705 154.101 363.81 154.101 362.367 154.101C361.82 154.101 361.373 153.913 361.149 153.42C360.95 153.115 360.826 152.27 361.621 151.565C362.144 150.978 367.913 144.757 368.933 143.677C368.982 143.606 368.933 143.512 368.858 143.512C368.833 143.512 368.833 143.512 368.808 143.512C366.645 143.512 364.481 143.512 362.318 143.512C361.671 143.512 361.174 143.137 361.049 142.573C360.9 141.916 361.199 141.352 361.771 141.094C361.945 141.023 362.119 141 362.293 141C365.501 141 368.684 141 371.892 141C372.514 141 372.937 141.188 373.185 141.728C373.509 142.409 373.16 142.878 372.937 143.183C372.489 143.723 367.938 148.56 365.426 151.283C365.401 151.307 365.376 151.354 365.327 151.377C365.277 151.448 365.327 151.565 365.426 151.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M372.185 154.101H369.647C369.001 154.101 368.453 153.582 368.453 152.968V152.732C368.453 152.119 369.001 151.6 369.647 151.6H372.185C372.832 151.6 373.38 152.119 373.38 152.732V152.968C373.38 153.605 372.832 154.101 372.185 154.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M375.716 147.515C375.716 145.734 375.716 143.976 375.716 142.195C375.716 141.492 376.238 141 376.984 141C380.215 141 383.447 141 386.678 141C387.424 141 387.946 141.516 387.946 142.242C387.946 142.969 387.424 143.508 386.678 143.508C383.994 143.508 381.334 143.508 378.649 143.508C378.45 143.508 378.401 143.555 378.401 143.742C378.401 146.273 378.401 148.804 378.401 151.335C378.401 151.546 378.475 151.57 378.649 151.57C381.309 151.57 383.994 151.57 386.654 151.57C387.25 151.57 387.723 151.898 387.897 152.39C388.095 152.976 387.872 153.562 387.399 153.89C387.176 154.03 386.902 154.101 386.629 154.101C383.447 154.101 380.24 154.101 377.058 154.101C376.238 154.101 375.741 153.585 375.741 152.812C375.716 151.031 375.716 149.273 375.716 147.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M362.705 159.041V159.698H361V159.041H361.426V157.258H361.05V156.602H362.68V157.258H362.304V159.041H362.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M366.535 159.759H365.774L365.651 159.546C365.381 159.688 365.062 159.759 364.768 159.759C363.934 159.735 363.148 159.168 363.148 158.151C363.148 157.181 363.811 156.543 364.817 156.543C365.651 156.543 366.461 157.087 366.461 158.151C366.461 158.529 366.363 158.813 366.216 159.073L366.559 159.64V159.759H366.535ZM364.792 159.002C365.185 159.002 365.602 158.718 365.602 158.151C365.602 157.583 365.185 157.3 364.792 157.3C364.375 157.3 363.983 157.583 363.983 158.151C363.983 158.718 364.375 159.002 364.792 159.002Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M368.784 158.749C368.784 158.961 369.029 159.078 369.3 159.078C369.545 159.078 369.791 158.961 369.791 158.796C369.791 158.491 369.422 158.491 369.201 158.468C368.612 158.421 368.023 158.209 368.023 157.552C368.023 156.872 368.612 156.543 369.275 156.543C369.889 156.543 370.527 156.872 370.527 157.552H369.717C369.717 157.341 369.496 157.247 369.275 157.247C369.079 157.247 368.833 157.294 368.833 157.529C368.833 157.74 369.029 157.787 369.324 157.81C370.012 157.857 370.601 158.022 370.601 158.773C370.601 159.43 369.938 159.759 369.275 159.759C368.587 159.759 367.924 159.43 367.949 158.749H368.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M373.228 157.311H372.037V157.76H373.129V158.469H372.037V158.965H373.253V159.698H371.169V156.602H373.204V157.311H373.228Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M376.603 159.58V159.698H375.722L375.159 158.729H374.719V159.698H373.887V156.602C374.376 156.602 374.841 156.602 375.306 156.602C376.089 156.602 376.456 157.169 376.456 157.713C376.456 158.067 376.285 158.422 375.966 158.611L376.603 159.58ZM375.306 158.067C375.551 158.067 375.648 157.878 375.648 157.665C375.648 157.476 375.526 157.287 375.306 157.287H374.719V158.067H375.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M378.276 158.334L378.552 157.527L378.955 156.602H379.886V156.72L378.452 159.758H378.099L376.665 156.72V156.602H377.596L378.024 157.527L378.276 158.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M381.782 159.041V159.698H380.076V159.041H380.503V157.258H380.126V156.602H381.756V157.258H381.38V159.041H381.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M383.835 156.602C384.509 156.602 385.258 156.955 385.383 157.779H384.559C384.434 157.449 384.185 157.332 383.835 157.332C383.336 157.332 383.011 157.661 383.011 158.18C383.011 158.627 383.336 159.028 383.835 159.028C384.185 159.028 384.484 158.863 384.559 158.533H385.383C385.258 159.404 384.534 159.758 383.835 159.758C382.986 159.758 382.162 159.216 382.162 158.18C382.162 157.12 382.986 156.602 383.835 156.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M387.944 157.311H386.753V157.76H387.845V158.469H386.753V158.965H387.969V159.698H385.885V156.602H387.919V157.311H387.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M82.9852 148.74H80.4473C79.8003 148.74 79.2529 148.234 79.2529 147.635V147.404C79.2529 146.806 79.8003 146.299 80.4473 146.299H82.9852C83.6321 146.299 84.1795 146.806 84.1795 147.404V147.635C84.1795 148.257 83.657 148.74 82.9852 148.74Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M65.4262 151.565C65.7247 151.565 65.7993 151.565 66.1972 151.565C66.9432 151.565 67.4903 152.105 67.4903 152.833C67.4903 153.584 66.9184 154.101 66.1474 154.101C64.705 154.101 63.8098 154.101 62.3674 154.101C61.8203 154.101 61.3727 153.913 61.1488 153.42C60.9499 153.115 60.8255 152.27 61.6213 151.565C62.1436 150.978 67.9131 144.757 68.9327 143.677C68.9824 143.606 68.9327 143.512 68.8581 143.512C68.8332 143.512 68.8332 143.512 68.8084 143.512C66.6448 143.512 64.4812 143.512 62.3177 143.512C61.6711 143.512 61.1737 143.137 61.0494 142.573C60.9002 141.916 61.1986 141.352 61.7706 141.094C61.9446 141.023 62.1187 141 62.2928 141C65.5008 141 68.684 141 71.8921 141C72.5138 141 72.9366 141.188 73.1852 141.728C73.5085 142.409 73.1604 142.878 72.9366 143.183C72.4889 143.723 67.938 148.56 65.4262 151.283C65.4014 151.307 65.3765 151.354 65.3268 151.377C65.277 151.448 65.3268 151.565 65.4262 151.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M72.1854 154.101H69.6475C69.0005 154.101 68.4531 153.582 68.4531 152.968V152.732C68.4531 152.119 69.0005 151.6 69.6475 151.6H72.1854C72.8323 151.6 73.3797 152.119 73.3797 152.732V152.968C73.3797 153.605 72.8323 154.101 72.1854 154.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M75.7158 147.515C75.7158 145.734 75.7158 143.976 75.7158 142.195C75.7158 141.492 76.2379 141 76.9836 141C80.2152 141 83.4468 141 86.6785 141C87.4242 141 87.9462 141.516 87.9462 142.242C87.9462 142.969 87.4242 143.508 86.6785 143.508C83.9937 143.508 81.3339 143.508 78.6491 143.508C78.4503 143.508 78.4005 143.555 78.4005 143.742C78.4005 146.273 78.4005 148.804 78.4005 151.335C78.4005 151.546 78.4751 151.57 78.6491 151.57C81.309 151.57 83.9937 151.57 86.6536 151.57C87.2502 151.57 87.7225 151.898 87.8965 152.39C88.0954 152.976 87.8717 153.562 87.3994 153.89C87.1756 154.03 86.9022 154.101 86.6287 154.101C83.4468 154.101 80.2401 154.101 77.0582 154.101C76.2378 154.101 75.7407 153.585 75.7407 152.812C75.7158 151.031 75.7158 149.273 75.7158 147.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M62.7054 159.041V159.698H61V159.041H61.4263V157.258H61.0502V156.602H62.6803V157.258H62.3041V159.041H62.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M66.5346 159.759H65.774L65.6513 159.546C65.3814 159.688 65.0624 159.759 64.7679 159.759C63.9336 159.735 63.1484 159.168 63.1484 158.151C63.1484 157.181 63.811 156.543 64.817 156.543C65.6513 156.543 66.461 157.087 66.461 158.151C66.461 158.529 66.3629 158.813 66.2156 159.073L66.5592 159.64V159.759H66.5346ZM64.7925 159.002C65.1851 159.002 65.6022 158.718 65.6022 158.151C65.6022 157.583 65.1851 157.3 64.7925 157.3C64.3753 157.3 63.9827 157.583 63.9827 158.151C63.9827 158.718 64.3753 159.002 64.7925 159.002Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M68.7838 158.749C68.7838 158.961 69.0294 159.078 69.2995 159.078C69.5451 159.078 69.7907 158.961 69.7907 158.796C69.7907 158.491 69.4223 158.491 69.2013 158.468C68.6119 158.421 68.0226 158.209 68.0226 157.552C68.0226 156.872 68.6119 156.543 69.275 156.543C69.8889 156.543 70.5274 156.872 70.5274 157.552H69.717C69.717 157.341 69.496 157.247 69.275 157.247C69.0785 157.247 68.8329 157.294 68.8329 157.529C68.8329 157.74 69.0294 157.787 69.3241 157.81C70.0117 157.857 70.601 158.022 70.601 158.773C70.601 159.43 69.938 159.759 69.275 159.759C68.5874 159.759 67.9243 159.43 67.9489 158.749H68.7838Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M73.2285 157.311H72.0374V157.76H73.1292V158.469H72.0374V158.965H73.2533V159.698H71.1689V156.602H73.2036V157.311H73.2285Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M76.6027 159.58V159.698H75.7218L75.159 158.729H74.7186V159.698H73.8867V156.602C74.3761 156.602 74.841 156.602 75.3059 156.602C76.0888 156.602 76.4559 157.169 76.4559 157.713C76.4559 158.067 76.2846 158.422 75.9665 158.611L76.6027 159.58ZM75.3059 158.067C75.5505 158.067 75.6484 157.878 75.6484 157.665C75.6484 157.476 75.5261 157.287 75.3059 157.287H74.7186V158.067H75.3059Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M78.2757 158.334L78.5525 157.527L78.9551 156.602H79.8863V156.72L78.4518 159.758H78.0995L76.665 156.72V156.602H77.5962L78.024 157.527L78.2757 158.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M81.7815 159.041V159.698H80.0762V159.041H80.5025V157.258H80.1263V156.602H81.7565V157.258H81.3803V159.041H81.7815Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M83.8352 156.602C84.5094 156.602 85.2585 156.955 85.3833 157.779H84.5593C84.4345 157.449 84.1848 157.332 83.8352 157.332C83.3357 157.332 83.0111 157.661 83.0111 158.18C83.0111 158.627 83.3357 159.028 83.8352 159.028C84.1848 159.028 84.4844 158.863 84.5593 158.533H85.3833C85.2585 159.404 84.5343 159.758 83.8352 159.758C82.9862 159.758 82.1621 159.216 82.1621 158.18C82.1621 157.12 82.9862 156.602 83.8352 156.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M87.9443 157.311H86.7532V157.76H87.845V158.469H86.7532V158.965H87.9691V159.698H85.8848V156.602H87.9195V157.311H87.9443Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M133.985 58.7403H131.447C130.8 58.7403 130.253 58.2336 130.253 57.6347V57.4044C130.253 56.8056 130.8 56.2988 131.447 56.2988H133.985C134.632 56.2988 135.18 56.8056 135.18 57.4044V57.6347C135.18 58.2566 134.657 58.7403 133.985 58.7403Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M116.426 61.5652C116.725 61.5652 116.799 61.5652 117.197 61.5652C117.943 61.5652 118.49 62.1052 118.49 62.833C118.49 63.5843 117.918 64.1008 117.147 64.1008C115.705 64.1008 114.81 64.1008 113.367 64.1008C112.82 64.1008 112.373 63.913 112.149 63.4199C111.95 63.1147 111.826 62.2695 112.621 61.5652C113.144 60.9782 118.913 54.7565 119.933 53.6765C119.982 53.6061 119.933 53.5122 119.858 53.5122C119.833 53.5122 119.833 53.5122 119.808 53.5122C117.645 53.5122 115.481 53.5122 113.318 53.5122C112.671 53.5122 112.174 53.1365 112.049 52.573C111.9 51.9156 112.199 51.3522 112.771 51.0939C112.945 51.0235 113.119 51 113.293 51C116.501 51 119.684 51 122.892 51C123.514 51 123.937 51.1878 124.185 51.7278C124.509 52.4087 124.16 52.8783 123.937 53.1835C123.489 53.7235 118.938 58.56 116.426 61.2834C116.401 61.3069 116.376 61.3539 116.327 61.3773C116.277 61.4478 116.327 61.5652 116.426 61.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M123.185 64.1007H120.647C120.001 64.1007 119.453 63.5816 119.453 62.9681V62.7322C119.453 62.1187 120.001 61.5996 120.647 61.5996H123.185C123.832 61.5996 124.38 62.1187 124.38 62.7322V62.9681C124.38 63.6052 123.832 64.1007 123.185 64.1007Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M126.716 57.5152C126.716 55.7341 126.716 53.9764 126.716 52.1952C126.716 51.4922 127.238 51 127.984 51C131.215 51 134.447 51 137.678 51C138.424 51 138.946 51.5156 138.946 52.2421C138.946 52.9686 138.424 53.5077 137.678 53.5077C134.994 53.5077 132.334 53.5077 129.649 53.5077C129.45 53.5077 129.401 53.5545 129.401 53.742C129.401 56.2731 129.401 58.8042 129.401 61.3353C129.401 61.5463 129.475 61.5697 129.649 61.5697C132.309 61.5697 134.994 61.5697 137.654 61.5697C138.25 61.5697 138.723 61.8978 138.897 62.39C139.095 62.9759 138.872 63.5618 138.399 63.8899C138.176 64.0305 137.902 64.1008 137.629 64.1008C134.447 64.1008 131.24 64.1008 128.058 64.1008C127.238 64.1008 126.741 63.5852 126.741 62.8118C126.716 61.0307 126.716 59.273 126.716 57.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M113.705 69.0413V69.6981H112V69.0413H112.426V67.2584H112.05V66.6016H113.68V67.2584H113.304V69.0413H113.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M117.535 69.7586H116.774L116.651 69.5458C116.381 69.6877 116.062 69.7586 115.768 69.7586C114.934 69.735 114.148 69.1675 114.148 68.1508C114.148 67.1814 114.811 66.543 115.817 66.543C116.651 66.543 117.461 67.0868 117.461 68.1508C117.461 68.5291 117.363 68.8128 117.216 69.0729L117.559 69.6404V69.7586H117.535ZM115.792 69.002C116.185 69.002 116.602 68.7183 116.602 68.1508C116.602 67.5833 116.185 67.2996 115.792 67.2996C115.375 67.2996 114.983 67.5833 114.983 68.1508C114.983 68.7183 115.375 69.002 115.792 69.002Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M119.784 68.7493C119.784 68.9606 120.029 69.0779 120.3 69.0779C120.545 69.0779 120.791 68.9606 120.791 68.7963C120.791 68.4911 120.422 68.4911 120.201 68.4677C119.612 68.4207 119.023 68.2095 119.023 67.5523C119.023 66.8716 119.612 66.543 120.275 66.543C120.889 66.543 121.527 66.8716 121.527 67.5523H120.717C120.717 67.341 120.496 67.2471 120.275 67.2471C120.079 67.2471 119.833 67.2941 119.833 67.5288C119.833 67.74 120.029 67.787 120.324 67.8105C121.012 67.8574 121.601 68.0217 121.601 68.7728C121.601 69.43 120.938 69.7586 120.275 69.7586C119.587 69.7586 118.924 69.43 118.949 68.7493H119.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M124.228 67.3107H123.037V67.7598H124.129V68.4689H123.037V68.9653H124.253V69.6981H122.169V66.6016H124.204V67.3107H124.228Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M127.603 69.5799V69.6981H126.722L126.159 68.729H125.719V69.6981H124.887V66.6016C125.376 66.6016 125.841 66.6016 126.306 66.6016C127.089 66.6016 127.456 67.1689 127.456 67.7125C127.456 68.0671 127.285 68.4217 126.966 68.6108L127.603 69.5799ZM126.306 68.0671C126.551 68.0671 126.648 67.878 126.648 67.6653C126.648 67.4762 126.526 67.2871 126.306 67.2871H125.719V68.0671H126.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M129.276 68.3339L129.552 67.527L129.955 66.6016H130.886V66.7202L129.452 69.7577H129.099L127.665 66.7202V66.6016H128.596L129.024 67.527L129.276 68.3339Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M132.782 69.0413V69.6981H131.076V69.0413H131.503V67.2584H131.126V66.6016H132.756V67.2584H132.38V69.0413H132.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M134.835 66.6016C135.509 66.6016 136.258 66.9549 136.383 67.7792H135.559C135.434 67.4495 135.185 67.3317 134.835 67.3317C134.336 67.3317 134.011 67.6614 134.011 68.1796C134.011 68.6271 134.336 69.0275 134.835 69.0275C135.185 69.0275 135.484 68.8626 135.559 68.5329H136.383C136.258 69.4044 135.534 69.7577 134.835 69.7577C133.986 69.7577 133.162 69.2159 133.162 68.1796C133.162 67.1197 133.986 66.6016 134.835 66.6016Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M138.944 67.3107H137.753V67.7598H138.845V68.4689H137.753V68.9653H138.969V69.6981H136.885V66.6016H138.919V67.3107H138.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M331.985 58.7403H329.447C328.8 58.7403 328.253 58.2336 328.253 57.6347V57.4044C328.253 56.8056 328.8 56.2988 329.447 56.2988H331.985C332.632 56.2988 333.18 56.8056 333.18 57.4044V57.6347C333.18 58.2566 332.657 58.7403 331.985 58.7403Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M314.426 61.5652C314.725 61.5652 314.799 61.5652 315.197 61.5652C315.943 61.5652 316.49 62.1052 316.49 62.833C316.49 63.5843 315.918 64.1008 315.147 64.1008C313.705 64.1008 312.81 64.1008 311.367 64.1008C310.82 64.1008 310.373 63.913 310.149 63.4199C309.95 63.1147 309.826 62.2695 310.621 61.5652C311.144 60.9782 316.913 54.7565 317.933 53.6765C317.982 53.6061 317.933 53.5122 317.858 53.5122C317.833 53.5122 317.833 53.5122 317.808 53.5122C315.645 53.5122 313.481 53.5122 311.318 53.5122C310.671 53.5122 310.174 53.1365 310.049 52.573C309.9 51.9156 310.199 51.3522 310.771 51.0939C310.945 51.0235 311.119 51 311.293 51C314.501 51 317.684 51 320.892 51C321.514 51 321.937 51.1878 322.185 51.7278C322.509 52.4087 322.16 52.8783 321.937 53.1835C321.489 53.7235 316.938 58.56 314.426 61.2834C314.401 61.3069 314.376 61.3539 314.327 61.3773C314.277 61.4478 314.327 61.5652 314.426 61.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M321.185 64.1007H318.647C318.001 64.1007 317.453 63.5816 317.453 62.9681V62.7322C317.453 62.1187 318.001 61.5996 318.647 61.5996H321.185C321.832 61.5996 322.38 62.1187 322.38 62.7322V62.9681C322.38 63.6052 321.832 64.1007 321.185 64.1007Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M324.716 57.5152C324.716 55.7341 324.716 53.9764 324.716 52.1952C324.716 51.4922 325.238 51 325.984 51C329.215 51 332.447 51 335.678 51C336.424 51 336.946 51.5156 336.946 52.2421C336.946 52.9686 336.424 53.5077 335.678 53.5077C332.994 53.5077 330.334 53.5077 327.649 53.5077C327.45 53.5077 327.401 53.5545 327.401 53.742C327.401 56.2731 327.401 58.8042 327.401 61.3353C327.401 61.5463 327.475 61.5697 327.649 61.5697C330.309 61.5697 332.994 61.5697 335.654 61.5697C336.25 61.5697 336.723 61.8978 336.897 62.39C337.095 62.9759 336.872 63.5618 336.399 63.8899C336.176 64.0305 335.902 64.1008 335.629 64.1008C332.447 64.1008 329.24 64.1008 326.058 64.1008C325.238 64.1008 324.741 63.5852 324.741 62.8118C324.716 61.0307 324.716 59.273 324.716 57.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M311.705 69.0413V69.6981H310V69.0413H310.426V67.2584H310.05V66.6016H311.68V67.2584H311.304V69.0413H311.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M315.535 69.7586H314.774L314.651 69.5458C314.381 69.6877 314.062 69.7586 313.768 69.7586C312.934 69.735 312.148 69.1675 312.148 68.1508C312.148 67.1814 312.811 66.543 313.817 66.543C314.651 66.543 315.461 67.0868 315.461 68.1508C315.461 68.5291 315.363 68.8128 315.216 69.0729L315.559 69.6404V69.7586H315.535ZM313.792 69.002C314.185 69.002 314.602 68.7183 314.602 68.1508C314.602 67.5833 314.185 67.2996 313.792 67.2996C313.375 67.2996 312.983 67.5833 312.983 68.1508C312.983 68.7183 313.375 69.002 313.792 69.002Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M317.784 68.7493C317.784 68.9606 318.029 69.0779 318.3 69.0779C318.545 69.0779 318.791 68.9606 318.791 68.7963C318.791 68.4911 318.422 68.4911 318.201 68.4677C317.612 68.4207 317.023 68.2095 317.023 67.5523C317.023 66.8716 317.612 66.543 318.275 66.543C318.889 66.543 319.527 66.8716 319.527 67.5523H318.717C318.717 67.341 318.496 67.2471 318.275 67.2471C318.079 67.2471 317.833 67.2941 317.833 67.5288C317.833 67.74 318.029 67.787 318.324 67.8105C319.012 67.8574 319.601 68.0217 319.601 68.7728C319.601 69.43 318.938 69.7586 318.275 69.7586C317.587 69.7586 316.924 69.43 316.949 68.7493H317.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M322.228 67.3107H321.037V67.7598H322.129V68.4689H321.037V68.9653H322.253V69.6981H320.169V66.6016H322.204V67.3107H322.228Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M325.603 69.5799V69.6981H324.722L324.159 68.729H323.719V69.6981H322.887V66.6016C323.376 66.6016 323.841 66.6016 324.306 66.6016C325.089 66.6016 325.456 67.1689 325.456 67.7125C325.456 68.0671 325.285 68.4217 324.966 68.6108L325.603 69.5799ZM324.306 68.0671C324.551 68.0671 324.648 67.878 324.648 67.6653C324.648 67.4762 324.526 67.2871 324.306 67.2871H323.719V68.0671H324.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M327.276 68.3339L327.552 67.527L327.955 66.6016H328.886V66.7202L327.452 69.7577H327.099L325.665 66.7202V66.6016H326.596L327.024 67.527L327.276 68.3339Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M330.782 69.0413V69.6981H329.076V69.0413H329.503V67.2584H329.126V66.6016H330.756V67.2584H330.38V69.0413H330.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M332.835 66.6016C333.509 66.6016 334.258 66.9549 334.383 67.7792H333.559C333.434 67.4495 333.185 67.3317 332.835 67.3317C332.336 67.3317 332.011 67.6614 332.011 68.1796C332.011 68.6271 332.336 69.0275 332.835 69.0275C333.185 69.0275 333.484 68.8626 333.559 68.5329H334.383C334.258 69.4044 333.534 69.7577 332.835 69.7577C331.986 69.7577 331.162 69.2159 331.162 68.1796C331.162 67.1197 331.986 66.6016 332.835 66.6016Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M336.944 67.3107H335.753V67.7598H336.845V68.4689H335.753V68.9653H336.969V69.6981H334.885V66.6016H336.919V67.3107H336.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <defs>
          <pattern id="5-3-pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use
              xlinkHref="#image0"
              transform="translate(-0.00768784) scale(0.00100915 0.00153332)"
            />
          </pattern>
          <filter
            id="5-3-filter0_i"
            x="131"
            y="127"
            width="186.94"
            height="157.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-3-filter1_i"
            x="336"
            y="127"
            width="76.9399"
            height="157.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-3-filter2_i"
            x="36"
            y="127"
            width="76.9399"
            height="157.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-3-filter3_i"
            x="36"
            y="41"
            width="178.94"
            height="65.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-3-filter4_i"
            x="234"
            y="41"
            width="178.94"
            height="65.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <linearGradient
            id="5-3-paint0_linear"
            x1="301.5"
            y1="291"
            x2="301.5"
            y2="316"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DBDDE1" />
            <stop offset="1" stopColor="#F9F9F9" />
          </linearGradient>
        </defs>
      </svg>
      {!withoutBackground && <ContainerBackground />}
    </ProductImageWrapper>
  )
}
