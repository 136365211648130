import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement22Square3VerticalCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 125.999C32 124.895 31.1048 124 30.0005 124C28.8962 124 28.001 124.895 28.001 125.999V128.167H28.001V139H28V142.001C28 143.105 28.8952 144 29.9995 144C31.1038 144 31.999 143.105 31.999 142.001V139.833H32V129H32V125.999Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 32 128.167)"
          fill="#ABABAB"
        />
        <path
          d="M32 124.94C32 124.421 31.5792 124 31.0601 124H28.941C28.4219 124 28.001 124.421 28.001 124.94V129H32V124.94Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M28 143.06C28 143.579 28.4208 144 28.9399 144H31.059C31.5781 144 31.999 143.579 31.999 143.06V139H28V143.06Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 32 129.833)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#2filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 173C32 171.895 31.1046 171 30 171C28.8954 171 28 171.895 28 173V187.56H32V173ZM32 187.56H28V189.4H27.9399C27.4208 189.4 27 189.821 27 190.34V192.12C27 193.158 27.8417 194 28.8799 194H30.1201C31.1583 194 32 193.158 32 192.12V189.4V189.4V187.56Z"
            fill="white"
          />
        </g>
        <rect
          width="3.99998"
          height="0.95833"
          transform="matrix(-1 0 0 1 32 188.25)"
          fill="#ABABAB"
        />
        <path
          d="M32 171.94C32 171.421 31.5792 171 31.0601 171H28.94C28.4208 171 28 171.421 28 171.94V188.25H32V171.94Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M32 193.06C32 193.579 31.5792 194 31.0601 194H27.94C27.4208 194 27 193.579 27 193.06V190.148C27 189.629 27.4208 189.208 27.94 189.208H32V193.06Z"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#2filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 120H32V198H188V120ZM177 131H43V187H177V131Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 120H32V198H33V121H187V198H188V120Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2filter3_i)">
          <rect
            x="43.47"
            y="131.47"
            width="133.06"
            height="55.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="124.53"
          width="146.94"
          height="68.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M44.5 132.613L173.505 159L44.5 185.387L44.5 132.613Z" stroke="white" />
        <path d="M45.4007 185.5L110 132.646L174.599 185.5H45.4007Z" stroke="white" />
        <rect
          x="179"
          y="152"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#2filter4_d)">
          <rect x="179.702" y="152.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M180.286 163.795C180.798 164.458 181.6 164.884 182.502 164.884C184.049 164.884 185.302 163.63 185.302 162.084V155.444C185.302 154.456 184.79 153.587 184.017 153.089C184.383 153.562 184.6 154.155 184.6 154.8V161.44C184.6 162.986 183.347 164.24 181.8 164.24C181.242 164.24 180.723 164.077 180.286 163.795Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M182.502 152.644C180.955 152.644 179.702 153.898 179.702 155.444V161.44C179.702 161.46 179.702 161.48 179.702 161.501V156.024C179.702 154.477 180.956 153.224 182.502 153.224C184.029 153.224 185.269 154.444 185.302 155.963V155.444C185.302 153.898 184.048 152.644 182.502 152.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M182.5 156C181.672 156 181 156.672 181 157.5V157.714V159V177.5C181 178.328 181.672 179 182.5 179V179C183.328 179 184 178.328 184 177.5V161V157.5L184 157.286C184 156.576 183.424 156 182.714 156V156C182.677 156 182.64 156.001 182.604 156.004C182.569 156.001 182.535 156 182.5 156Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="2filter0_d"
            x="26.1201"
            y="122.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2filter1_d"
            x="25.1201"
            y="169.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2filter2_d"
            x="27.3003"
            y="115.3"
            width="167.279"
            height="89.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2filter3_i"
            x="43"
            y="131"
            width="134.94"
            height="58.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2filter4_d"
            x="177.822"
            y="150.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2filter5_d"
            x="179.12"
            y="154.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="34.8"
            y1="141.5"
            x2="27.2"
            y2="141.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="25.2018"
            y1="126.5"
            x2="32.7998"
            y2="126.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="34.7982"
            y1="141.5"
            x2="27.2002"
            y2="141.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="25.2"
            y1="179.625"
            x2="32.8"
            y2="179.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="23.5"
            y1="191.604"
            x2="33"
            y2="191.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
