import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement24Square4TopBottomCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-4_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.0005 104C57.1048 104 58 103.105 58 102.001C58 100.896 57.1048 100.001 56.0005 100.001L53.8335 100.001L53.8335 100.001L43 100.001L43 100L39.9995 100C38.8952 100 38 100.895 38 101.999C38 103.104 38.8952 103.999 39.9995 103.999L42.1668 103.999L42.1668 104L53 104L53 104L56.0005 104Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 53.834 104)"
          fill="#ABABAB"
        />
        <path
          d="M57.0601 104C57.5792 104 58 103.579 58 103.06L58 100.941C58 100.422 57.5792 100.001 57.0601 100.001L53 100.001L53 104L57.0601 104Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M38.9399 100C38.4208 100 38 100.421 38 100.94L38 103.059C38 103.578 38.4208 103.999 38.9399 103.999L43 103.999L43 100L38.9399 100Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 52.167 104)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#2-4_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M219 104H32V216H219V104ZM208 115H43V205H208V115Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#2-4_filter2_i)">
          <rect
            x="43.47"
            y="115.47"
            width="164.06"
            height="89.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="37.5" y="109.5" width="176" height="101" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M219 104H32V105H218V216H219V104Z"
          fill="#FBFBFB"
        />
        <path d="M45.1446 116.5L205.855 116.5L125.5 203.264L45.1446 116.5Z" stroke="white" />
        <g filter="url(#2-4_filter3_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M211.001 104C212.105 104 213 103.105 213 102.001C213 100.896 212.105 100.001 211.001 100.001L208.833 100.001L208.833 100.001L198 100.001L198 100L194.999 100C193.895 100 193 100.895 193 101.999C193 103.104 193.895 103.999 194.999 103.999L197.167 103.999L197.167 104L208 104L208 104L211.001 104Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 208.834 104)"
          fill="#ABABAB"
        />
        <path
          d="M212.06 104C212.579 104 213 103.579 213 103.06L213 100.941C213 100.422 212.579 100.001 212.06 100.001L208 100.001L208 104L212.06 104Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M193.94 100C193.421 100 193 100.421 193 100.94L193 103.059C193 103.578 193.421 103.999 193.94 103.999L198 103.999L198 100L193.94 100Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 207.167 104)"
          fill="url(#paint7_linear)"
        />
        <rect
          x="132"
          y="207"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 132 207)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#2-4_filter4_d)">
          <rect
            x="131.355"
            y="207.701"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 131.355 207.701)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M131.238 209.694C130.892 208.541 129.822 207.701 128.556 207.701L121.916 207.701C120.37 207.701 119.116 208.955 119.116 210.501C119.116 210.536 119.117 210.571 119.118 210.606C119.465 211.759 120.534 212.599 121.8 212.599L128.44 212.599C129.987 212.599 131.24 211.346 131.24 209.799C131.24 209.764 131.24 209.729 131.238 209.694Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M129.881 208.034C129.737 208.011 129.59 207.999 129.44 207.999L122.8 207.999C121.254 207.999 120 209.253 120 210.799C120 211.866 120.597 212.794 121.475 213.267C120.138 213.055 119.116 211.898 119.116 210.501C119.116 208.955 120.37 207.701 121.916 207.701L128.556 207.701C129.035 207.701 129.486 207.822 129.881 208.034Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-4_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M128 210.5C128 209.672 127.328 209 126.5 209L126.286 209L125 209L106.5 209C105.672 209 105 209.672 105 210.5V210.5C105 211.328 105.672 212 106.5 212L123 212L126.5 212L126.714 212C127.424 212 128 211.424 128 210.714V210.714C128 210.677 127.999 210.64 127.996 210.604C127.999 210.569 128 210.535 128 210.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="2-4_filter0_d"
            x="36.1201"
            y="98.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-4_filter1_d"
            x="27.3003"
            y="99.3003"
            width="198.279"
            height="123.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-4_filter2_i"
            x="43"
            y="115"
            width="165.94"
            height="92.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-4_filter3_d"
            x="191.12"
            y="98.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-4_filter4_d"
            x="117.235"
            y="205.821"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-4_filter5_d"
            x="103.12"
            y="207.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="40.5"
            y1="106.8"
            x2="40.5"
            y2="99.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="55.5"
            y1="97.2018"
            x2="55.5"
            y2="104.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="40.5"
            y1="106.798"
            x2="40.5"
            y2="99.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="195.5"
            y1="106.8"
            x2="195.5"
            y2="99.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="210.5"
            y1="97.2018"
            x2="210.5"
            y2="104.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="195.5"
            y1="106.798"
            x2="195.5"
            y2="99.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
