import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement14Square3Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#1-4_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.9995 30C38.8952 30 38 30.8952 38 31.9995C38 33.1038 38.8952 33.999 39.9995 33.999L42.1665 33.999L42.1665 33.999L53 33.999L53 34L56.0005 34C57.1048 34 58 33.1048 58 32.0005C58 30.8962 57.1048 30.001 56.0005 30.001L53.8332 30.001L53.8332 30L43 30L43 30L39.9995 30Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 42.166 30)"
          fill="#ABABAB"
        />
        <path
          d="M38.9399 30C38.4208 30 38 30.4208 38 30.9399L38 33.059C38 33.5781 38.4208 33.999 38.9399 33.999L43 33.999L43 30L38.9399 30Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M57.0601 34C57.5792 34 58 33.5792 58 33.0601L58 30.941C58 30.4219 57.5792 30.001 57.0601 30.001L53 30.001L53 34L57.0601 34Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 43.833 30)"
          fill="url(#paint1-4_linear)"
        />
        <g filter="url(#1-4_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M127.999 30C126.895 30 126 30.8952 126 31.9995C126 33.1038 126.895 33.999 127.999 33.999L130.167 33.999L130.167 33.999L141 33.999L141 34L144.001 34C145.105 34 146 33.1048 146 32.0005C146 30.8962 145.105 30.001 144.001 30.001L141.833 30.001L141.833 30L131 30L131 30L127.999 30Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 130.166 30)"
          fill="#ABABAB"
        />
        <path
          d="M126.94 30C126.421 30 126 30.4208 126 30.9399L126 33.059C126 33.5781 126.421 33.999 126.94 33.999L131 33.999L131 30L126.94 30Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M145.06 34C145.579 34 146 33.5792 146 33.0601L146 30.941C146 30.4219 145.579 30.001 145.06 30.001L141 30.001L141 34L145.06 34Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 131.833 30)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#1-4_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M152 34H32V111H152V34ZM141 45H43V100H141V45Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#1-4_filter1-4_i)">
          <rect
            x="43.47"
            y="45.47"
            width="97.0601"
            height="54.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="37.5" y="39.5" width="109" height="66" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M152 34H32V111H33V35H151V111H152V34Z"
          fill="#FBFBFB"
        />
        <path d="M45.1274 46.5L138.873 46.5L92 98.2552L45.1274 46.5Z" stroke="white" />
        <rect
          x="98"
          y="102"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 98 102)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#1-4_filter4_d)">
          <rect
            x="97.3555"
            y="102.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 97.3555 102.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M97.2383 104.695C96.8919 103.542 95.8222 102.702 94.5563 102.702L87.9164 102.702C86.37 102.702 85.1164 103.956 85.1164 105.502C85.1164 105.537 85.117 105.572 85.1183 105.607C85.4647 106.76 86.5344 107.6 87.8003 107.6L94.4402 107.6C95.9866 107.6 97.2402 106.347 97.2402 104.8C97.2402 104.765 97.2396 104.73 97.2383 104.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M95.8809 103.035C95.7372 103.012 95.5899 103 95.4398 103L88.7999 103C87.2535 103 85.9999 104.254 85.9999 105.8C85.9999 106.867 86.5968 107.795 87.4749 108.268C86.1381 108.056 85.116 106.898 85.116 105.502C85.116 103.956 86.3696 102.702 87.916 102.702L94.5559 102.702C95.0352 102.702 95.4864 102.823 95.8809 103.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-4_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M94 105.5C94 104.672 93.3284 104 92.5 104L92.2857 104L91 104L72.5 104C71.6716 104 71 104.672 71 105.5C71 106.328 71.6716 107 72.5 107L89 107L92.5 107L92.7143 107C93.4244 107 94 106.424 94 105.714C94 105.677 93.9988 105.64 93.9965 105.604C93.9988 105.569 94 105.535 94 105.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="1-4_filter0_d"
            x="36.1201"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-4_filter1_d"
            x="124.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-4_filter2_d"
            x="27.3003"
            y="29.3003"
            width="131.279"
            height="88.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-4_filter1-4_i"
            x="43"
            y="45"
            width="98.9399"
            height="57.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="1-4_filter4_d"
            x="83.2354"
            y="100.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-4_filter5_d"
            x="69.1201"
            y="102.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="55.5"
            y1="27.2"
            x2="55.5"
            y2="34.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="40.5"
            y1="36.7982"
            x2="40.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="55.5"
            y1="27.2018"
            x2="55.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint1-4_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="143.5"
            y1="27.2"
            x2="143.5"
            y2="34.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="128.5"
            y1="36.7982"
            x2="128.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="143.5"
            y1="27.2018"
            x2="143.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
