import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement84Square3Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#8-4_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M171.999 206C170.895 206 170 206.895 170 207.999C170 209.104 170.895 209.999 171.999 209.999L174.167 209.999L174.167 209.999L185 209.999L185 210L188.001 210C189.105 210 190 209.105 190 208.001C190 206.896 189.105 206.001 188.001 206.001L185.833 206.001L185.833 206L175 206L175 206L171.999 206Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 174.166 206)"
          fill="#ABABAB"
        />
        <path
          d="M170.94 206C170.421 206 170 206.421 170 206.94L170 209.059C170 209.578 170.421 209.999 170.94 209.999L175 209.999L175 206L170.94 206Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M189.06 210C189.579 210 190 209.579 190 209.06L190 206.941C190 206.422 189.579 206.001 189.06 206.001L185 206.001L185 210L189.06 210Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 175.833 206)"
          fill="url(#paint8-4_linear)"
        />
        <g filter="url(#8-4_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M259.999 206C258.895 206 258 206.895 258 207.999C258 209.104 258.895 209.999 259.999 209.999L262.167 209.999L262.167 209.999L273 209.999L273 210L276.001 210C277.105 210 278 209.105 278 208.001C278 206.896 277.105 206.001 276.001 206.001L273.833 206.001L273.833 206L263 206L263 206L259.999 206Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 262.166 206)"
          fill="#ABABAB"
        />
        <path
          d="M258.94 206C258.421 206 258 206.421 258 206.94L258 209.059C258 209.578 258.421 209.999 258.94 209.999L263 209.999L263 206L258.94 206Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M277.06 210C277.579 210 278 209.579 278 209.06L278 206.941C278 206.422 277.579 206.001 277.06 206.001L273 206.001L273 210L277.06 210Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 263.833 206)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#8-4_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M284 210H164V287H284V210ZM273 221H175V276H273V221Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#8-4_filter8-4_i)">
          <rect
            x="175.47"
            y="221.47"
            width="97.0601"
            height="54.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="169.5" y="215.5" width="109" height="66" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M284 210H164V287H165V211H283V287H284V210Z"
          fill="#FBFBFB"
        />
        <path d="M177.127 222.5H270.873L224 274.255L177.127 222.5Z" stroke="white" />
        <rect
          x="230"
          y="278"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 230 278)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#8-4_filter4_d)">
          <rect
            x="229.355"
            y="278.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 229.355 278.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M229.238 280.695C228.892 279.542 227.822 278.702 226.556 278.702L219.916 278.702C218.37 278.702 217.116 279.956 217.116 281.502C217.116 281.537 217.117 281.572 217.118 281.607C217.465 282.76 218.534 283.6 219.8 283.6L226.44 283.6C227.987 283.6 229.24 282.347 229.24 280.8C229.24 280.765 229.24 280.73 229.238 280.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M227.881 279.035C227.737 279.012 227.59 279 227.44 279L220.8 279C219.254 279 218 280.254 218 281.8C218 282.867 218.597 283.795 219.475 284.268C218.138 284.056 217.116 282.898 217.116 281.502C217.116 279.956 218.37 278.702 219.916 278.702L226.556 278.702C227.035 278.702 227.486 278.823 227.881 279.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#8-4_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M226 281.5C226 280.672 225.328 280 224.5 280L224.286 280L223 280L204.5 280C203.672 280 203 280.672 203 281.5C203 282.328 203.672 283 204.5 283L221 283L224.5 283L224.714 283C225.424 283 226 282.424 226 281.714C226 281.677 225.999 281.64 225.996 281.604C225.999 281.569 226 281.535 226 281.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="8-4_filter0_d"
            x="168.12"
            y="204.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8-4_filter1_d"
            x="256.12"
            y="204.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8-4_filter2_d"
            x="159.3"
            y="205.3"
            width="131.279"
            height="88.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8-4_filter8-4_i"
            x="175"
            y="221"
            width="98.9399"
            height="57.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="8-4_filter4_d"
            x="215.235"
            y="276.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8-4_filter5_d"
            x="201.12"
            y="278.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="187.5"
            y1="203.2"
            x2="187.5"
            y2="210.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="172.5"
            y1="212.798"
            x2="172.5"
            y2="205.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="187.5"
            y1="203.202"
            x2="187.5"
            y2="210.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint8-4_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="275.5"
            y1="203.2"
            x2="275.5"
            y2="210.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="260.5"
            y1="212.798"
            x2="260.5"
            y2="205.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="275.5"
            y1="203.202"
            x2="275.5"
            y2="210.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
