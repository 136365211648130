import { DADATA_TOKEN } from '../../../services/environment'

export async function getAddress([lat, lon]) {
  console.log(' send request to get address in delivery-select-address-map/utils.ts')
  const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address'
  const query = {
    lat,
    lon,
    count: 3,
    radius_meters: 50,
    from_bound: { value: 'street-house' },
    to_bound: { value: 'street-house' },
  }
  const options = {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Token ' + DADATA_TOKEN,
    },
    body: JSON.stringify(query),
  } as any

  return fetch(url, options)
    .then(response => response.json())
    .then(result => {
      const suggestions = result?.['suggestions']?.[0]
      const data = suggestions?.data
      const resultData = {
        lat: parseFloat(data?.['geo_lat']),
        lng: parseFloat(data?.['geo_lon']),
        country_code: data?.['country_iso_code'] ?? null,
        postal_code: data?.['postal_code'] ?? null,
        value: suggestions?.['value'] || '',
        country: data?.['country'] ?? null,
        locality: data?.['locality'] ?? null,
        street: data?.['street'] ?? null,
        house: data?.['house'] ?? null,
        city: data?.['city'] ?? null,
      }
      console.log({ result, resultData })
      return resultData
    })
    .catch(error => console.warn('error', error))
}

function formatAddress({ formatted, country }) {
  if (Boolean(country) === false) {
    return formatted
  }
  const length = country.length
  return formatted.substr(length + 2)
}

export async function getAddressYandex(coords) {
  // @ts-ignore
  if (Boolean(window?.ymaps) === false) {
    return Promise.resolve(null)
  }
  // @ts-ignore
  return window.ymaps
    .geocode(coords.join(','), { json: true, results: 1 })
    .then(result => {
      const geoObject = result?.GeoObjectCollection?.featureMember?.[0]?.GeoObject
      const coords = geoObject?.Point?.pos?.split(' ').map(parseFloat)
      const lat = coords?.[0] || 0
      const lng = coords?.[1] || 0
      const address: any = geoObject?.metaDataProperty?.GeocoderMetaData?.Address
      const componentsArray: any[] =
        address?.Components?.map(item => [item?.kind, item?.name]).filter(item => item[0]) ?? []
      const components = Object.fromEntries(componentsArray)

      const resultData = {
        lat: lat,
        lng: lng,
        country_code: address?.['country_code'] ?? null,
        postal_code: address?.['postal_code'] ?? null,
        formatted: address?.['formatted'] ?? null,
        country: components?.['country'] ?? null,
        locality: components?.['locality'] ?? null,
        street: components?.['street'] ?? null,
        house: components?.['house'] ?? null,
        city: components?.['locality'] ?? null,
        value: address?.['formatted'] ?? null,
      }
      return Object.assign({ value: formatAddress(resultData) }, resultData)
    })
    .catch(console.warn)
}

// Если дадата ничего не нашла, отсылаем запрос в Яндекс-геокодер
export async function getAddressBalacer(coords) {
  const dadataAdr = await getAddress(coords)
  if (Number.isFinite(dadataAdr?.['lat']) || Number.isFinite(dadataAdr?.['lng'])) {
    return dadataAdr
  }
  const yandexAdr = await getAddressYandex(coords)
  return yandexAdr
}

function getControlId(control) {
  for (const key of Object.keys(control)) {
    if (/^id_\d+$/.test(key) && typeof control?.[key] === 'string') {
      return control?.[key]
    }
  }
  return null
}

export function getControlZoomPosition(height) {
  const top = Math.trunc(height / 2) - 55
  return { top, left: 20 }
}

export function getControlGeolocationPosition(height) {
  return { bottom: 64, left: 20 }
}

export const getGeolocationControl = height => {
  // @ts-ignore
  const geolocationControl = new ymaps.control.GeolocationControl({
    // data: { image: 'static/svg/delivery/geolocate.svg' },
    options: { position: getControlGeolocationPosition(height) },
  })
  geolocationControl.events.add('parentchange', ({ originalEvent }) => {
    const id = getControlId(geolocationControl)
    const targetNode = originalEvent?.newParent?._childElements?.[id]
    targetNode?.classList?.add?.('geolocation-control')
  })
  return geolocationControl
}

// @ts-ignore
export const getCenterMark = coords =>
 // @ts-ignore
  new ymaps.Placemark(
    coords,
    {},
    {
      iconLayout: 'default#image',
      iconImageHref: 'static/svg/delivery/placemark.svg',
      iconImageSize: [37, 50],
      iconImageOffset: [0, -50],
      separateContainer: true,
      syncOverlayInit: true,
    },
  )

export function сreateYandexMapCustomControl(callback) {
  function CustomControlClass(options) {
    // @ts-ignore
    CustomControlClass.superclass.constructor.call(this, options)
  }
  // @ts-ignore
  ymaps.util.augment(CustomControlClass, ymaps.collection.Item, {
    onAddToMap(map) {
      // @ts-ignore
      CustomControlClass.superclass.onAddToMap.call(this, map)
      this.getParent().getChildElement(this).then(callback)
    },
    onRemoveFromMap(oldMap) {
      // @ts-ignore
      CustomControlClass.superclass.onRemoveFromMap.call(this, oldMap)
    },
  })
  // @ts-ignore
  return new CustomControlClass()
}

export function setCenterMark({ centerMark, address }) {
  const tagSvg = address?.['value'] ? '' : '-gray'
  const url = `static/svg/delivery/placemark${tagSvg}.svg`
  const prevUrl = centerMark.options.get('iconImageHref')
  if (url !== prevUrl) {
    centerMark.options.set('iconImageHref', url)
  }
}
