import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement5Door1RCasement1 = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="451"
        height="451"
        viewBox="0 0 451 451"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#door-1-casement-1-5-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M268.954 46.9765C268.954 45.8849 268.069 45 266.978 45C265.886 45 265.001 45.8849 265.001 46.9765V49.1665H265.001V60H265V63.0235C265 64.1151 265.885 65 266.976 65C268.068 65 268.953 64.1151 268.953 63.0235V60.8332H268.954V50H268.954V46.9765Z"
            fill="url(#door-1-casement-1-5-paint0_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-1 0 0 1 268.954 49.1665)"
          fill="#ABABAB"
        />
        <path
          d="M268.954 45.9399C268.954 45.4208 268.533 45 268.014 45H265.941C265.422 45 265.001 45.4208 265.001 45.9399V50H268.954V45.9399Z"
          fill="url(#door-1-casement-1-5-paint1_linear)"
        />
        <path
          d="M265 64.0601C265 64.5792 265.421 65 265.94 65H268.013C268.532 65 268.953 64.5792 268.953 64.0601V60H265V64.0601Z"
          fill="url(#door-1-casement-1-5-paint2_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-1 0 0 1 268.954 50.8334)"
          fill="url(#door-1-casement-1-5-paint3_linear)"
        />
        <g filter="url(#door-1-casement-1-5-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M268.954 271.976C268.954 270.885 268.069 270 266.977 270C265.886 270 265.001 270.885 265.001 271.976V274.167H265.001V285H265V288.024C265 289.115 265.885 290 266.976 290C268.068 290 268.953 289.115 268.953 288.024V285.833H268.954V275H268.954V271.976Z"
            fill="url(#door-1-casement-1-5-paint4_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-1 0 0 1 268.954 274.167)"
          fill="#ABABAB"
        />
        <path
          d="M268.954 270.94C268.954 270.421 268.533 270 268.014 270H265.941C265.422 270 265.001 270.421 265.001 270.94V275H268.954V270.94Z"
          fill="url(#door-1-casement-1-5-paint5_linear)"
        />
        <path
          d="M265 289.06C265 289.579 265.421 290 265.94 290H268.013C268.532 290 268.953 289.579 268.953 289.06V285H265V289.06Z"
          fill="url(#door-1-casement-1-5-paint6_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-1 0 0 1 268.954 275.833)"
          fill="url(#door-1-casement-1-5-paint7_linear)"
        />
        <g filter="url(#door-1-casement-1-5-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M265 34H32V301H265V34ZM254 45H43V290H254V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M265 34H32V301H33V35H264V301H265V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="36.53"
          y="38.53"
          width="223.94"
          height="257.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#door-1-casement-1-5-filter3_i)">
          <rect
            x="43.47"
            y="45.47"
            width="210.06"
            height="244.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          width="6.91954"
          height="13.5283"
          rx="3.45977"
          transform="matrix(-1 0 0 1 40.9196 161)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#door-1-casement-1-5-filter4_d)">
          <rect
            width="5.53563"
            height="12.2399"
            rx="2.76782"
            transform="matrix(-1 0 0 1 40.2255 161.644)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.6606 172.792C39.1549 173.456 38.3564 173.884 37.4579 173.884C35.9293 173.884 34.6901 172.645 34.6901 171.116V164.412C34.6901 163.44 35.1908 162.585 35.9483 162.092C35.5938 162.557 35.3832 163.138 35.3832 163.768V170.472C35.3832 172.001 36.6224 173.24 38.151 173.24C38.7079 173.24 39.2264 173.075 39.6606 172.792Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.4577 161.644C38.9863 161.644 40.2255 162.883 40.2255 164.412V170.472C40.2255 170.492 40.2253 170.512 40.2249 170.533V164.991C40.2249 163.463 38.9857 162.224 37.4571 162.224C35.9487 162.224 34.7221 163.43 34.6899 164.931V164.412C34.6899 162.883 35.9291 161.644 37.4577 161.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#door-1-casement-1-5-filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.4598 165C38.2787 165 38.9426 165.664 38.9426 166.483V168C38.9426 168.014 38.9424 168.027 38.9422 168.041L38.9422 186.517C38.9422 187.336 38.2783 188 37.4594 188C36.6405 188 35.9766 187.336 35.9766 186.517L35.9766 166.251C35.9766 165.56 36.5369 165 37.2279 165C37.2307 165 37.2335 165 37.2363 165C37.3108 165 37.3853 165 37.4598 165Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M252.5 288.131V46.869L44.9949 167.5L252.5 288.131Z" stroke="white" />
        <defs>
          <filter
            id="door-1-casement-1-5-filter0_d"
            x="263.12"
            y="43.1201"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-1-5-filter1_d"
            x="263.12"
            y="268.12"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-1-5-filter2_d"
            x="27.3003"
            y="29.3003"
            width="244.279"
            height="278.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-1-5-filter3_i"
            x="43"
            y="45"
            width="211.94"
            height="247.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="door-1-casement-1-5-filter4_d"
            x="32.81"
            y="159.764"
            width="11.1753"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-1-5-filter5_d"
            x="34.0967"
            y="163.12"
            width="8.60556"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="door-1-casement-1-5-paint0_linear"
            x1="271.722"
            y1="62.5"
            x2="264.209"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-5-paint1_linear"
            x1="262.234"
            y1="47.5"
            x2="269.745"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-5-paint2_linear"
            x1="271.72"
            y1="62.5"
            x2="264.209"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-5-paint3_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-5-paint4_linear"
            x1="271.722"
            y1="287.5"
            x2="264.209"
            y2="287.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-5-paint5_linear"
            x1="262.234"
            y1="272.5"
            x2="269.745"
            y2="272.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-5-paint6_linear"
            x1="271.72"
            y1="287.5"
            x2="264.209"
            y2="287.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-5-paint7_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
