import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement62Square82Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#7filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M183.001 224C184.105 224 185 223.105 185 222.001C185 220.896 184.105 220.001 183.001 220.001L180.833 220.001L180.833 220.001L170 220.001L170 220L166.999 220C165.895 220 165 220.895 165 221.999C165 223.104 165.895 223.999 166.999 223.999L169.167 223.999L169.167 224L180 224L180 224L183.001 224Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 180.833 224)"
          fill="#ABABAB"
        />
        <path
          d="M184.06 224C184.579 224 185 223.579 185 223.06L185 220.941C185 220.422 184.579 220.001 184.06 220.001L180 220.001L180 224L184.06 224Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M165.94 220C165.421 220 165 220.421 165 220.94L165 223.059C165 223.578 165.421 223.999 165.94 223.999L170 223.999L170 220L165.94 220Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 179.167 224)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#7filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M138.001 224C139.105 224 140 223.105 140 222.001C140 220.896 139.105 220.001 138.001 220.001L135.833 220.001L135.833 220.001L125 220.001L125 220L121.999 220C120.895 220 120 220.895 120 221.999C120 223.104 120.895 223.999 121.999 223.999L124.167 223.999L124.167 224L135 224L135 224L138.001 224Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 135.833 224)"
          fill="#ABABAB"
        />
        <path
          d="M139.06 224C139.579 224 140 223.579 140 223.06L140 220.941C140 220.422 139.579 220.001 139.06 220.001L135 220.001L135 224L139.06 224Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M120.94 220C120.421 220 120 220.421 120 220.94L120 223.059C120 223.578 120.421 223.999 120.94 223.999L125 223.999L125 220L120.94 220Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.167 224)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#7filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 165H115V220H188V165ZM178 175H125V210H178V175Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 165H115V220H116V166H187V220H188V165Z"
          fill="#FBFBFB"
        />
        <g filter="url(#7filter3_i)">
          <rect
            x="125.47"
            y="175.47"
            width="52.0601"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="119.53"
          y="169.53"
          width="63.9399"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="158.528"
          y="167"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 158.528 167)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#7filter4_d)">
          <rect
            x="157.884"
            y="167.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 157.884 167.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M157.766 169.695C157.42 168.542 156.35 167.702 155.084 167.702L148.444 167.702C146.898 167.702 145.644 168.956 145.644 170.502C145.644 170.537 145.645 170.572 145.646 170.607C145.993 171.76 147.062 172.6 148.328 172.6L154.968 172.6C156.515 172.6 157.768 171.346 157.768 169.8C157.768 169.765 157.768 169.73 157.766 169.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M156.409 168.035C156.266 168.012 156.118 168 155.968 168L149.328 168C147.782 168 146.528 169.254 146.528 170.8C146.528 171.867 147.125 172.795 148.003 173.267C146.666 173.056 145.644 171.898 145.644 170.502C145.644 168.956 146.898 167.702 148.444 167.702L155.084 167.702C155.564 167.702 156.015 167.822 156.409 168.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#7filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M171.528 170.5C171.528 169.672 170.857 169 170.028 169L169.814 169L168.528 169L150.028 169C149.2 169 148.528 169.672 148.528 170.5V170.5C148.528 171.328 149.2 172 150.028 172L166.528 172L170.028 172L170.243 172C170.953 172 171.528 171.424 171.528 170.714V170.714C171.528 170.677 171.527 170.64 171.525 170.604C171.527 170.569 171.528 170.535 171.528 170.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M127.018 208.5L151.5 176.818L175.982 208.5L127.018 208.5Z" stroke="white" />
        <defs>
          <filter
            id="7filter0_d"
            x="163.12"
            y="218.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7filter1_d"
            x="118.12"
            y="218.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7filter2_d"
            x="110.3"
            y="160.3"
            width="84.2793"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7filter3_i"
            x="125"
            y="175"
            width="53.9399"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="7filter4_d"
            x="143.764"
            y="165.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7filter5_d"
            x="147.649"
            y="167.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="167.5"
            y1="226.8"
            x2="167.5"
            y2="219.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="182.5"
            y1="217.202"
            x2="182.5"
            y2="224.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="167.5"
            y1="226.798"
            x2="167.5"
            y2="219.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="122.5"
            y1="226.8"
            x2="122.5"
            y2="219.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="137.5"
            y1="217.202"
            x2="137.5"
            y2="224.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="122.5"
            y1="226.798"
            x2="122.5"
            y2="219.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
