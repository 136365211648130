import styled, { css } from 'styled-components'

import { HRWrapper } from '../hr'
import { ComponentShadowWrapper } from '../component-shadow'

export const GalleryWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;

  position: relative;

  & + ${HRWrapper} {
    margin-top: 0;

    ${ComponentShadowWrapper} {
      top: 0;
    }
  }
`

export const GalleryGroup = styled.div`
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const GalleryGroupTitle = styled.div`
  font-size: 14px;
  line-height: 140%;
  color: #666666;

  margin-bottom: 20px;
`

export const GalleryGroupTitleButton = styled.span`
  color: #333333;
  font-size: 14px;
  border-bottom: 1px dashed #333333;

  cursor: pointer;
`

export const GalleryContent = styled.div<{ view: number }>`
  display: grid;
  gap: 15px;

  margin-bottom: ${({ view }) => (view === 1 ? '16px' : '0')};

  grid-template-columns: ${({ view }) => (view === 1 ? '1fr 1fr 1fr' : '1fr 1fr')};
`

export const GalleryItemText = styled.div`
  display: flex;
  flex-direction: column;

  position: absolute;

  bottom: 0;
  width: 100%;

  font-size: 12px;
  text-align: center;

  opacity: 0;

  transition: opacity 0.3s;

  pointer-events: none;

  span {
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  }
`

export const GalleryItemText2 = styled.div`
  display: flex;
  flex-direction: column;

  padding-top: 8px;

  font-size: 16px;

  span {
    padding-bottom: 4px;
  }
`

export const GalleryItemImage = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  position: relative;

  width: 100%;
  height: 110px;

  padding-top: 100%;

  transform: scale(1);
  transition: transform 0.3s, box-shadow 0.3s;
  will-change: transform;

  cursor: pointer;
`

export const GalleryItemWrapper = styled.div``

export const GallerySelected = styled.div`
  position: absolute;

  top: 5px;
  left: 5px;

  height: 16px;
  width: 16px;

  transition: left 0.3s, top 0.3s, transform 0.3s;
`

export const GalleryItem = styled.div<{ view: number; hideBefore?: boolean }>`
  position: relative;

  border: 1px dashed #d4d4d4;
  border-radius: 6px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.25);

  padding: 2px;

  &:before {
    content: '';
    display: block;
    background-color: #f3f4f6;
    border-radius: 50px;

    position: absolute;

    width: 16px;
    height: 16px;

    top: 5px;
    left: 5px;

    opacity: 0;

    transition: opacity 0.3s, transform 0.3s, left 0.3s, top 0.3s;

    z-index: 2;

    ${({ hideBefore = false }) =>
      hideBefore &&
      css`
        display: none;
      `}
  }

  ${({ view }) =>
    view === 1 &&
    css`
      &:hover {
        &:before {
          opacity: 1;

          transform: scale(1.5);

          left: -10px;
          top: -15px;
        }

        ${GalleryItemImage} {
          transform: scale(1.5);

          background-color: #ffffff;
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);

          z-index: 1;
        }

        ${GalleryItemText} {
          opacity: 1;

          z-index: 1;
        }

        ${GallerySelected} {
          z-index: 3;

          left: -10px;
          top: -15px;

          transform: scale(1.5);
        }
      }
    `}
`

export const GalleryClearItems = styled.div`
  text-align: center;
  color: #959595;
`
