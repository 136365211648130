import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement42Square5Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-2_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 209.999C32 208.895 31.1048 208 30.0005 208C28.8962 208 28.001 208.895 28.001 209.999V212.167H28.001V223H28V226.001C28 227.105 28.8952 228 29.9995 228C31.1038 228 31.999 227.105 31.999 226.001V223.833H32V213H32V209.999Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 32 212.166)"
          fill="#ABABAB"
        />
        <path
          d="M32 208.94C32 208.421 31.5792 208 31.0601 208H28.941C28.4219 208 28.001 208.421 28.001 208.94V213H32V208.94Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M28 227.06C28 227.579 28.4208 228 28.9399 228H31.059C31.5781 228 31.999 227.579 31.999 227.06V223H28V227.06Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 32 213.833)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#4-2_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 261C32 259.895 31.1046 259 30 259C28.8954 259 28 259.895 28 261V275.56H32V261ZM32 275.56H28V277.4H27.9399C27.4208 277.4 27 277.821 27 278.34V280.12C27 281.158 27.8417 282 28.8799 282H30.1201C31.1583 282 32 281.158 32 280.12V277.4V277.4V275.56Z"
            fill="white"
          />
        </g>
        <rect
          width="3.99998"
          height="0.95833"
          transform="matrix(-1 0 0 1 32 276.25)"
          fill="#ABABAB"
        />
        <path
          d="M32 259.94C32 259.421 31.5792 259 31.0601 259H28.94C28.4208 259 28 259.421 28 259.94V276.25H32V259.94Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M32 281.06C32 281.579 31.5792 282 31.0601 282H27.94C27.4208 282 27 281.579 27 281.06V278.148C27 277.629 27.4208 277.208 27.94 277.208H32V281.06Z"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#4-2_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M152 203H32V287H152V203ZM141 214H43V276H141V214Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M152 203H32V287H33V204H151V287H152V203Z"
          fill="#FBFBFB"
        />
        <rect
          x="36.53"
          y="207.53"
          width="110.94"
          height="74.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#4-2_filter3_i)">
          <rect
            x="43.47"
            y="214.47"
            width="97.0601"
            height="61.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="143"
          y="239"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#4-2_filter4_d)">
          <rect x="143.702" y="239.645" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M144.286 250.796C144.798 251.458 145.6 251.884 146.502 251.884C148.049 251.884 149.302 250.631 149.302 249.084V242.444C149.302 241.456 148.79 240.587 148.017 240.089C148.383 240.562 148.601 241.156 148.601 241.8V248.44C148.601 249.987 147.347 251.24 145.801 251.24C145.243 251.24 144.723 251.077 144.286 250.796Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M146.502 239.645C144.956 239.645 143.702 240.898 143.702 242.445V248.44C143.702 248.461 143.702 248.481 143.703 248.501V243.024C143.703 241.478 144.956 240.224 146.503 240.224C148.029 240.224 149.27 241.445 149.302 242.963V242.445C149.302 240.898 148.049 239.645 146.502 239.645Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-2_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M146.5 243C145.672 243 145 243.672 145 244.5V244.714V246V264.5C145 265.328 145.672 266 146.5 266C147.328 266 148 265.328 148 264.5V248V244.5L148 244.286C148 243.576 147.424 243 146.714 243C146.677 243 146.64 243.001 146.604 243.004C146.569 243.001 146.535 243 146.5 243Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M44.5 274.32L44.5 215.68L138.324 245L44.5 274.32Z" stroke="white" />
        <path d="M138.96 274.5L45.0403 274.5L92 215.8L138.96 274.5Z" stroke="white" />
        <defs>
          <filter
            id="4-2_filter0_d"
            x="26.1201"
            y="206.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-2_filter1_d"
            x="25.1201"
            y="257.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-2_filter2_d"
            x="27.3003"
            y="198.3"
            width="131.279"
            height="95.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-2_filter3_i"
            x="43"
            y="214"
            width="98.9399"
            height="64.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-2_filter4_d"
            x="141.822"
            y="237.765"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-2_filter5_d"
            x="143.12"
            y="241.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="34.8"
            y1="225.5"
            x2="27.2"
            y2="225.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="25.2018"
            y1="210.5"
            x2="32.7998"
            y2="210.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="34.7982"
            y1="225.5"
            x2="27.2002"
            y2="225.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="25.2"
            y1="267.625"
            x2="32.8"
            y2="267.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="23.5"
            y1="279.604"
            x2="33"
            y2="279.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
