import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import { StyledButton } from '../../../components/button/styles'
import { NumberWrapper } from './components'
import { InfoWrapper } from '../../../components'

export const CalculatorWrapper = styled.div<{ isNoDraft?: boolean }>`
  width: 100%;

  display: grid;
  grid-template-columns: ${({ isNoDraft }) => (isNoDraft ? '1fr' : '256px 1fr 332px')};

  padding-top: 27px;
`

export const CalculatorEmptyWrapper = styled.div`
  margin: 0 auto;
`

export const CalculatorEmptyTitle = styled.h3`
  font-size: 28px;
`
export const CalculatorEmptyDescription = styled.span`
  font-size: 18px;

  display: flex;
  align-items: center;
  gap: 5px;

  a {
    display: flex;
    gap: 5px;
  }
`

export const MenuWrapper = styled.div`
  background-color: #1c3775;

  display: flex;
  flex-direction: column;
  flex-basis: 277px;

  position: relative;

  width: 100%;
  height: 100%;
  min-width: 277px;
`

export const MenuList = styled.div`
  padding-bottom: 22px;
`

export const MenuListTitle = styled.div`
  color: #f3f4f6;
  font-size: 12px;

  padding: 31px 35px 14px 50px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const MenuListItemChecked = styled.div`
  position: absolute;
  left: 20px;
  top: 17px;

  width: 9px;
  height: 7px;
`

export const MenuListItem = styled.div<{ active: boolean; checked: boolean }>`
  color: ${({ active }) => (active ? '#d69a2d' : '#ffffff')};
  font-size: 16px;
  line-height: 24px;

  padding: ${({ active }) => (active ? '18px 40px 18px 63px' : '18px 40px 18px 50px')};

  min-height: 60px;

  cursor: pointer;

  transition: color 0.3s, padding-left 0.3s;

  position: relative;

  &:hover {
    color: #d69a2d;
  }

  &:after {
    content: '';
    display: block;

    background-color: ${({ active }) => (active ? '#d69a2d' : 'transparent')};

    position: absolute;
    left: 40px;
    top: 20px;

    width: 3px;
    height: 20px;
  }
`

export const MenuListItemTitle = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-right: 10px;
  }
`

export const SubMenuWrapper = styled.div<{ show: boolean }>`
  background-color: #ffffff;
  box-shadow: 10px 0 10px rgba(0, 0, 0, 0.25);

  width: 350px;
  min-height: 100%;
  max-height: 100%;

  overflow-y: auto;
  overflow-x: hidden;

  position: absolute;
  top: 0;
  left: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  padding: 24px 36px 74px 32px;

  transition: opacity 0.3s;

  z-index: 1001;

  ${({ show }) =>
    show
      ? css`
          opacity: 1;
          pointer-events: all;
        `
      : css`
          opacity: 0;
          pointer-events: none;
        `}
`

export const SubMenuContent = styled.div`
  width: 100%;
`

export const SubMenuTitle = styled.div`
  color: #333333;
  font-size: 16px;
  font-weight: bold;
  line-height: 140%;

  margin-bottom: 12px;

  &:empty {
    display: none;
  }
`

export const SubMenuFooter = styled.div`
  display: flex;
  justify-content: flex-end;

  position: absolute;
  bottom: 20px;
  left: 173%;
  z-index: 1002;

  width: 120px;

  ${StyledButton} {
    width: 120px;
  }
`

export const MainViewWrapper = styled.div`
  background-color: #f7f7fb;
`

export const MainViewTopBlock = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 40px 40px 20px;

  color: #1c3775;

  a {
    color: #1c3775;
  }
`

export const MainViewTopBlockLink = styled(Link)`
  display: flex;
  align-items: center;

  text-decoration: none;

  cursor: pointer;

  span {
    margin-right: 16px;
  }
`

export const MainViewIconBlock = styled.div<{ active?: boolean }>`
  background-color: ${({ active = false }) => (active ? '#1c3775' : 'rgba(224, 227, 233, 0.6)')};
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  transition: background-color 0.3s;

  cursor: pointer;
`

export const MainViewProductBlock = styled.div`
  background-color: #fefeff;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  margin: 0 40px;
  padding-top: 83px;
  padding-bottom: 20px;

  height: calc(100% - 124px);

  position: relative;
`

export const AdditionalBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  padding: 40px;

  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.1);
`

export const AdditionalBlockMainTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;

  margin-bottom: 12px;

  position: relative;
`

export const AdditionalBlockMain = styled.div`
  width: 100%;
`

export const AdditionalBlockMainInputDesc = styled.div`
  font-size: 14px;
  line-height: 140%;
  color: #525252;

  margin-top: 6px;
`

export const AdditionalBlockPrice = styled.div`
  border-top: 1px dashed #bbc3d6;

  padding-top: 20px;

  span {
    display: inline-block;

    font-size: 14px;
    color: #333333;

    margin-bottom: 12px;
  }
`

export const AdditionalBlockForecastWrapper = styled.div`
  margin-bottom: 16px;
`

export const AdditionalBlockForecastTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  color: #666666;

  margin-bottom: 4px;

  span {
    margin-right: 4px;
  }

  ${InfoWrapper} {
    display: inline-block;
  }
`

export const AdditionalBlockForecastValue = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: #000000;
`

export const AdditionalBlockPriceValue = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #000000;
`

export const AdditionalBlockButtonBlock = styled.div`
  align-self: center;
`

export const AdditionalBlockButtonBlockWrapper = styled.div`
  margin-top: 20px;
`

export const SubMenuBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  width: 100%;
`

export const SubMenuAttributeDescriptionBody = styled.div`
  background-color: #ffffff;
  box-shadow: 0 4px 50px rgba(0, 0, 0, 0.25);

  position: absolute;

  left: -13px;
  bottom: 10px;

  min-width: 220px;

  padding: 6px 12px;

  opacity: 0;

  pointer-events: none;

  transition: opacity 0.3s, bottom 0.3s;

  &:after {
    content: '';

    display: inline-block;

    position: absolute;

    bottom: -10px;
    left: 5%;

    width: 0;
    height: 0;

    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #ffffff transparent transparent transparent;
  }
`

export const SubMenuAttributeDescriptionButton = styled.div`
  background-color: #7a7a7a;
  border-radius: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  height: 15px;
  width: 15px;

  margin-left: 3px;

  cursor: pointer;

  &:hover {
    ${SubMenuAttributeDescriptionBody} {
      opacity: 1;

      bottom: 20px;
    }
  }
`

export const AdditionalBlockSizes = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 28px;

  ${NumberWrapper} {
    &:first-child {
      margin-right: 12px;
    }

    &:last-child {
      margin-left: 12px;
    }
  }
`

export const AdditionalBlockSizesDelimiter = styled.div`
  margin: 0 12px 25px 0;
`

export const ItemViewConstructorWrapperStyles: any = {
  position: 'relative',
  display: 'display: flex; display: -webkit-box; display: -webkit-flex; display: -ms-flexbox;',
  justifyContent: 'center',
  '-webkitBoxPack': 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
}

export const ItemViewConstructorWrapper = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`

export const ComponentShadow = styled.div<{ coord?: Record<string, string> }>`
  background-color: rgba(0, 0, 0, 0.3);

  position: absolute;

  ${({ coord = { top: '0', left: '-50px', right: '-50px', bottom: '0' } }) => css`
    top: ${coord['top']};
    left: ${coord['left']};
    right: ${coord['right']};
    bottom: ${coord['bottom']};
  `}

  z-index: 1;
`
