import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement63Square82Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#7filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M192 170.999C192 169.895 191.105 169 190.001 169C188.896 169 188.001 169.895 188.001 170.999V173.167H188.001V184H188V187.001C188 188.105 188.895 189 189.999 189C191.104 189 191.999 188.105 191.999 187.001V184.833H192V174H192V170.999Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 192 173.167)"
          fill="#ABABAB"
        />
        <path
          d="M192 169.94C192 169.421 191.579 169 191.06 169H188.941C188.422 169 188.001 169.421 188.001 169.94V174H192V169.94Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M188 188.06C188 188.579 188.421 189 188.94 189H191.059C191.578 189 191.999 188.579 191.999 188.06V184H188V188.06Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 192 174.833)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#7filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M192 197.999C192 196.895 191.105 196 190.001 196C188.896 196 188.001 196.895 188.001 197.999V200.167H188.001V211H188V214.001C188 215.105 188.895 216 189.999 216C191.104 216 191.999 215.105 191.999 214.001V211.833H192V201H192V197.999Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 192 200.167)"
          fill="#ABABAB"
        />
        <path
          d="M192 196.94C192 196.421 191.579 196 191.06 196H188.941C188.422 196 188.001 196.421 188.001 196.94V201H192V196.94Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M188 215.06C188 215.579 188.421 216 188.94 216H191.059C191.578 216 191.999 215.579 191.999 215.06V211H188V215.06Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 192 201.833)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#7filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 165H115V220H188V165ZM178 175H125V210H178V175Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 165H115V220H116V166H187V220H188V165Z"
          fill="#FBFBFB"
        />
        <g filter="url(#7filter3_i)">
          <rect
            x="125.47"
            y="175.47"
            width="52.0601"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="119.53"
          y="169.53"
          width="63.9399"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="117"
          y="185"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#7filter4_d)">
          <rect x="117.702" y="185.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M118.286 196.796C118.798 197.458 119.6 197.884 120.502 197.884C122.049 197.884 123.302 196.63 123.302 195.084V188.444C123.302 187.456 122.79 186.587 122.017 186.089C122.383 186.562 122.6 187.156 122.6 187.8V194.44C122.6 195.986 121.347 197.24 119.8 197.24C119.242 197.24 118.723 197.077 118.286 196.796Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M120.502 185.644C118.955 185.644 117.702 186.898 117.702 188.444V194.44C117.702 194.46 117.702 194.48 117.702 194.501V189.024C117.702 187.477 118.956 186.224 120.502 186.224C122.028 186.224 123.269 187.444 123.302 188.963V188.444C123.302 186.898 122.048 185.644 120.502 185.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#7filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M120.5 189C119.672 189 119 189.672 119 190.5V190.714V192V210.5C119 211.328 119.672 212 120.5 212V212C121.328 212 122 211.328 122 210.5V194V190.5L122 190.286C122 189.576 121.424 189 120.714 189V189C120.677 189 120.64 189.001 120.604 189.004C120.569 189.001 120.535 189 120.5 189Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M176.5 208.306L127.624 192.014L176.5 176.681L176.5 208.306Z" stroke="white" />
        <defs>
          <filter
            id="7filter0_d"
            x="186.12"
            y="167.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7filter1_d"
            x="186.12"
            y="194.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7filter2_d"
            x="110.3"
            y="160.3"
            width="84.2793"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7filter3_i"
            x="125"
            y="175"
            width="53.9399"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="7filter4_d"
            x="115.822"
            y="183.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7filter5_d"
            x="117.12"
            y="187.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="194.8"
            y1="186.5"
            x2="187.2"
            y2="186.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="185.202"
            y1="171.5"
            x2="192.8"
            y2="171.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="194.798"
            y1="186.5"
            x2="187.2"
            y2="186.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="194.8"
            y1="213.5"
            x2="187.2"
            y2="213.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="185.202"
            y1="198.5"
            x2="192.8"
            y2="198.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="194.798"
            y1="213.5"
            x2="187.2"
            y2="213.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
