import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement82Square8Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#8-2_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M334.999 286C333.895 286 333 286.895 333 287.999C333 289.104 333.895 289.999 334.999 289.999L337.167 289.999L337.167 289.999L348 289.999L348 290L351.001 290C352.105 290 353 289.105 353 288.001C353 286.896 352.105 286.001 351.001 286.001L348.833 286.001L348.833 286L338 286L338 286L334.999 286Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 337.167 286)"
          fill="#ABABAB"
        />
        <path
          d="M333.94 286C333.421 286 333 286.421 333 286.94L333 289.059C333 289.578 333.421 289.999 333.94 289.999L338 289.999L338 286L333.94 286Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M352.06 290C352.579 290 353 289.579 353 289.06L353 286.941C353 286.422 352.579 286.001 352.06 286.001L348 286.001L348 290L352.06 290Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 338.833 286)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#8-2_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M394.999 286C393.895 286 393 286.895 393 287.999C393 289.104 393.895 289.999 394.999 289.999L397.167 289.999L397.167 289.999L408 289.999L408 290L411.001 290C412.105 290 413 289.105 413 288.001C413 286.896 412.105 286.001 411.001 286.001L408.833 286.001L408.833 286L398 286L398 286L394.999 286Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 397.167 286)"
          fill="#ABABAB"
        />
        <path
          d="M393.94 286C393.421 286 393 286.421 393 286.94L393 289.059C393 289.578 393.421 289.999 393.94 289.999L398 289.999L398 286L393.94 286Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M412.06 290C412.579 290 413 289.579 413 289.06L413 286.941C413 286.422 412.579 286.001 412.06 286.001L408 286.001L408 290L412.06 290Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 398.833 286)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#8-2_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 165H328V286H417V165ZM406 176H339V275H406V176Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 165H328V286H329.219V166H415.781V286H417V165Z"
          fill="#FBFBFB"
        />
        <g filter="url(#8-2_filter3_i)">
          <rect
            x="339.47"
            y="176.47"
            width="66.0601"
            height="98.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="332.53"
          y="169.53"
          width="79.9399"
          height="111.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="379.528"
          y="167"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 379.528 167)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#8-2_filter4_d)">
          <rect
            x="378.884"
            y="167.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 378.884 167.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M378.766 169.695C378.42 168.542 377.35 167.702 376.084 167.702L369.444 167.702C367.898 167.702 366.644 168.956 366.644 170.502C366.644 170.537 366.645 170.572 366.646 170.607C366.993 171.76 368.062 172.6 369.328 172.6L375.968 172.6C377.514 172.6 378.768 171.346 378.768 169.8C378.768 169.765 378.767 169.73 378.766 169.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M377.409 168.035C377.266 168.012 377.118 168 376.968 168L370.328 168C368.782 168 367.528 169.254 367.528 170.8C367.528 171.867 368.125 172.795 369.003 173.267C367.666 173.056 366.644 171.898 366.644 170.502C366.644 168.956 367.898 167.702 369.444 167.702L376.084 167.702C376.564 167.702 377.015 167.822 377.409 168.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#8-2_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M392.528 170.5C392.528 169.672 391.857 169 391.028 169L390.814 169L389.528 169L371.028 169C370.2 169 369.528 169.672 369.528 170.5V170.5C369.528 171.328 370.2 172 371.028 172L387.528 172L391.028 172L391.243 172C391.953 172 392.528 171.424 392.528 170.714V170.714C392.528 170.677 392.527 170.64 392.525 170.604C392.527 170.569 392.528 170.535 392.528 170.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M340.695 273.5L372.5 178.574L404.305 273.5L340.695 273.5Z" stroke="white" />
        <defs>
          <filter
            id="8-2_filter0_d"
            x="331.12"
            y="284.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8-2_filter1_d"
            x="391.12"
            y="284.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8-2_filter2_d"
            x="323.3"
            y="160.3"
            width="100.279"
            height="132.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8-2_filter3_i"
            x="339"
            y="176"
            width="67.9399"
            height="101.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="8-2_filter4_d"
            x="364.765"
            y="165.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8-2_filter5_d"
            x="368.649"
            y="167.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="350.5"
            y1="283.2"
            x2="350.5"
            y2="290.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="335.5"
            y1="292.798"
            x2="335.5"
            y2="285.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="350.5"
            y1="283.202"
            x2="350.5"
            y2="290.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="410.5"
            y1="283.2"
            x2="410.5"
            y2="290.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="395.5"
            y1="292.798"
            x2="395.5"
            y2="285.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="410.5"
            y1="283.202"
            x2="410.5"
            y2="290.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
