import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement11Square4BottomCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#1-1_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 46.9995C32 45.8952 31.1048 45 30.0005 45C28.8962 45 28.001 45.8952 28.001 46.9995V49.1665H28.001V60H28V63.0005C28 64.1048 28.8952 65 29.9995 65C31.1038 65 31.999 64.1048 31.999 63.0005V60.8332H32V50H32V46.9995Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 32 49.1665)"
          fill="#ABABAB"
        />
        <path
          d="M32 45.9399C32 45.4208 31.5792 45 31.0601 45H28.941C28.4219 45 28.001 45.4208 28.001 45.9399V50H32V45.9399Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M28 64.0601C28 64.5792 28.4208 65 28.9399 65H31.059C31.5781 65 31.999 64.5792 31.999 64.0601V60H28V64.0601Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 32 50.8335)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#1-1_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 163.999C32 162.895 31.1048 162 30.0005 162C28.8962 162 28.001 162.895 28.001 163.999V166.167H28.001V177H28V180.001C28 181.105 28.8952 182 29.9995 182C31.1038 182 31.999 181.105 31.999 180.001V177.833H32V167H32V163.999Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 32 166.167)"
          fill="#ABABAB"
        />
        <path
          d="M32 162.94C32 162.421 31.5792 162 31.0601 162H28.941C28.4219 162 28.001 162.421 28.001 162.94V167H32V162.94Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M28 181.06C28 181.579 28.4208 182 28.9399 182H31.059C31.5781 182 31.999 181.579 31.999 181.06V177H28V181.06Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 32 167.833)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#1-1_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M152 34H32V193H152V34ZM141 45H43V182H141V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M152 34H32V193H33V35H151V193H152V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="36.53"
          y="38.53"
          width="110.94"
          height="149.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#1-1_filter3_i)">
          <rect
            x="43.47"
            y="45.47"
            width="97.0601"
            height="136.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="143"
          y="108"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#1-1_filter4_d)">
          <rect x="143.702" y="108.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M144.286 119.795C144.798 120.457 145.6 120.884 146.502 120.884C148.049 120.884 149.302 119.63 149.302 118.084V111.444C149.302 110.455 148.79 109.587 148.017 109.088C148.383 109.562 148.601 110.155 148.601 110.8V117.44C148.601 118.986 147.347 120.24 145.801 120.24C145.243 120.24 144.723 120.077 144.286 119.795Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M146.502 108.644C144.956 108.644 143.702 109.898 143.702 111.444V117.44C143.702 117.46 143.702 117.48 143.703 117.501V112.024C143.703 110.477 144.956 109.224 146.503 109.224C148.029 109.224 149.27 110.444 149.302 111.963V111.444C149.302 109.898 148.049 108.644 146.502 108.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-1_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M146.5 112C145.672 112 145 112.672 145 113.5V113.714V115V133.5C145 134.328 145.672 135 146.5 135C147.328 135 148 134.328 148 133.5V117V113.5L148 113.286C148 112.576 147.424 112 146.714 112C146.677 112 146.64 112.001 146.604 112.004C146.569 112.001 146.535 112 146.5 112Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M44.5 47.9587L44.5 180.041L139.126 114L44.5 47.9587Z" stroke="white" />
        <defs>
          <filter
            id="1-1_filter0_d"
            x="26.1201"
            y="43.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-1_filter1_d"
            x="26.1201"
            y="160.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-1_filter2_d"
            x="27.3003"
            y="29.3003"
            width="131.279"
            height="170.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-1_filter3_i"
            x="43"
            y="45"
            width="98.9399"
            height="139.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="1-1_filter4_d"
            x="141.822"
            y="106.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-1_filter5_d"
            x="143.12"
            y="110.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="34.8"
            y1="62.5"
            x2="27.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="25.2018"
            y1="47.5"
            x2="32.7998"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="34.7982"
            y1="62.5"
            x2="27.2002"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="34.8"
            y1="179.5"
            x2="27.2"
            y2="179.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="25.2018"
            y1="164.5"
            x2="32.7998"
            y2="164.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="34.7982"
            y1="179.5"
            x2="27.2002"
            y2="179.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
