import styled, { css } from 'styled-components'

export const SelectWrapper = styled.div`
  margin: 0;
`

export const SelectBlock = styled.div`
  box-sizing: border-box;

  padding: 0;

  width: 100%;

  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';

  position: relative;

  display: inline-block;

  cursor: pointer;
`

export const SelectSelector = styled.div`
  display: flex;

  position: relative;

  background-color: #ffffff;

  border: 1px solid #e1e1e1;
  border-radius: 10px;

  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  width: 100%;
  min-width: 120px;
  height: 56px;

  padding: 0 23px;
`

export const SelectArrow = styled.span`
  display: inline-block;

  font-style: normal;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  text-align: center;

  position: absolute;

  top: 57%;
  right: 23px;
  width: 12px;
  height: 6px;

  margin-top: -6px;

  pointer-events: none;
  user-select: none;
`

export const SelectIconWrapper = styled.span<{ arrowDown: boolean }>`
  display: inline-block;

  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  text-rendering: optimizeLegibility;

  transition: transform 0.3s, -webkit-transform 0.3s;
  vertical-align: top;

  transform: rotate(${({ arrowDown }) => (arrowDown ? 0 : css`180deg`)});
`

export const SelectText = styled.span`
  flex: 1;
  overflow: hidden;
  align-self: center;

  white-space: nowrap;
  text-overflow: ellipsis;

  padding: 0 18px 0 0;
  line-height: 30px;
  transition: all 0.3s;

  font-size: 16px;
`

export const SelectOptionsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`

export const SelectDropdown = styled.div<{ open: boolean }>`
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);

  margin: 0;
  padding: 4px 0;
  min-width: 120px;
  width: 100%;

  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  font-size: 14px;
  font-variant: normal;

  position: absolute;
  left: -6000px;
  top: -742px;

  box-sizing: border-box;

  overflow: hidden;

  z-index: 1050;
  outline: none;

  ${({ open }) =>
    open &&
    css`
      top: 56px;
      left: 0;
    `}
`

export const SelectOption = styled.div<{ active: boolean }>`
  background-color: ${({ active }) => (active ? '#e0e0e0' : 'transparent')};

  flex: auto;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  position: relative;
  display: block;

  min-height: 32px;

  padding: 7px 23px;

  font-weight: 400;
  font-size: 16px;
  line-height: 32px;

  transition: background-color 0.3s;

  &:hover {
    background-color: #e0e0e0;
  }
`

export const SelectContentIcon = styled.img`
  max-height: 32px;

  margin-right: 16px;
`
export const InputReq22 = styled.span`
  position: absolute;
  color: red;
  padding: 0px 0px 0px 8px;
  z-index: 4;
  font-size: 32px;
`
