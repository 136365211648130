import React, { memo } from 'react'

interface TB2BLayoutProps {
  layoutRef?: any
  children: any
  breadcrumbs?: Array<IBreadcrumb>
}

export interface IBreadcrumb {
  href?: string
  title: string
}

const B2BLayout = ({ children, layoutRef }: TB2BLayoutProps) => {
  return (
    <div ref={layoutRef}>
      <div style={{ minHeight: '100vh' }}>
        <div className="site-layout" style={{ backgroundColor: '#f6f8fa', padding: '0 24px 24px' }}>
          <div
            style={{
              padding: 0,
              margin: 0,
              minHeight: 280,
            }}
          >
            <div style={{ padding: 24, minHeight: 360, backgroundColor: '#f6f8fa' }}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(B2BLayout)
