export enum VerticalSizeType {
  LEFT = 'left',
  RIGHT = 'right',
}

export interface IVerticalSizeProps {
  value: number[]
  type?: VerticalSizeType
  count: number
  imageHeight: number
  maxHeight?: undefined | number
  onChange: (sizes: number | number[]) => void
  disabled?: boolean
  errors?: { title: string; description: string }[]
}

export interface IThumbSizeProps {
  index: number
  onChange: (index: number | null) => (val: number) => void
  minSize?: number
  maxSize: number
  currentSize: number
  disabled?: boolean
}
