import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement53Square5Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#5-3_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M404.001 203C405.105 203 406 202.105 406 201.001C406 199.896 405.105 199.001 404.001 199.001L401.833 199.001L401.833 199.001L391 199.001L391 199L387.999 199C386.895 199 386 199.895 386 200.999C386 202.104 386.895 202.999 387.999 202.999L390.167 202.999L390.167 203L401 203L401 203L404.001 203Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 401.833 203)"
          fill="#ABABAB"
        />
        <path
          d="M405.06 203C405.579 203 406 202.579 406 202.06L406 199.941C406 199.422 405.579 199.001 405.06 199.001L401 199.001L401 203L405.06 203Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M386.94 199C386.421 199 386 199.421 386 199.94L386 202.059C386 202.578 386.421 202.999 386.94 202.999L391 202.999L391 199L386.94 199Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 400.167 203)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#5-3_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M326.001 203C327.105 203 328 202.105 328 201.001C328 199.896 327.105 199.001 326.001 199.001L323.833 199.001L323.833 199.001L313 199.001L313 199L309.999 199C308.895 199 308 199.895 308 200.999C308 202.104 308.895 202.999 309.999 202.999L312.167 202.999L312.167 203L323 203L323 203L326.001 203Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 323.833 203)"
          fill="#ABABAB"
        />
        <path
          d="M327.06 203C327.579 203 328 202.579 328 202.06L328 199.941C328 199.422 327.579 199.001 327.06 199.001L323 199.001L323 203L327.06 203Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M308.94 199C308.421 199 308 199.421 308 199.94L308 202.059C308 202.578 308.421 202.999 308.94 202.999L313 202.999L313 199L308.94 199Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 322.167 203)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#5-3_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 203H297V287H417V203ZM406 214H308V276H406V214Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 203H297V287H298V204H416V287H417V203Z"
          fill="#FBFBFB"
        />
        <rect
          x="301.53"
          y="207.53"
          width="110.94"
          height="74.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#5-3_filter3_i)">
          <rect
            x="308.47"
            y="214.47"
            width="97.0601"
            height="61.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="364"
          y="278"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 364 278)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="363.356"
          y="278.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 363.356 278.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M363.238 280.695C362.891 279.542 361.822 278.702 360.556 278.702L353.916 278.702C352.369 278.702 351.116 279.956 351.116 281.502C351.116 281.537 351.117 281.572 351.118 281.607C351.464 282.76 352.534 283.6 353.8 283.6L360.44 283.6C361.986 283.6 363.24 282.347 363.24 280.8C363.24 280.765 363.239 280.73 363.238 280.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M361.881 279.035C361.737 279.012 361.59 279 361.44 279L354.8 279C353.254 279 352 280.254 352 281.8C352 282.867 352.597 283.795 353.475 284.268C352.138 284.056 351.116 282.898 351.116 281.502C351.116 279.956 352.37 278.702 353.916 278.702L360.556 278.702C361.035 278.702 361.486 278.823 361.881 279.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#5-3_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M360 281.5C360 280.672 359.328 280 358.5 280L358.286 280L357 280L338.5 280C337.672 280 337 280.672 337 281.5C337 282.328 337.672 283 338.5 283L355 283L358.5 283L358.714 283C359.424 283 360 282.424 360 281.714C360 281.677 359.999 281.64 359.996 281.604C359.999 281.569 360 281.535 360 281.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M310.04 215.5L403.96 215.5L357 274.2L310.04 215.5Z" stroke="white" />
        <defs>
          <filter
            id="5-3_filter0_d"
            x="384.12"
            y="197.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-3_filter1_d"
            x="306.12"
            y="197.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-3_filter2_d"
            x="292.3"
            y="198.3"
            width="131.279"
            height="95.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-3_filter3_i"
            x="308"
            y="214"
            width="98.9399"
            height="64.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-3_filter4_d"
            x="335.28"
            y="278.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="388.5"
            y1="205.8"
            x2="388.5"
            y2="198.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="403.5"
            y1="196.202"
            x2="403.5"
            y2="203.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="388.5"
            y1="205.798"
            x2="388.5"
            y2="198.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="310.5"
            y1="205.8"
            x2="310.5"
            y2="198.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="325.5"
            y1="196.202"
            x2="325.5"
            y2="203.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="310.5"
            y1="205.798"
            x2="310.5"
            y2="198.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
