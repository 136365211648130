import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement15Square41Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#1-5-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M121 255C121 253.895 121.895 253 123 253C124.105 253 125 253.895 125 255V269.56H121V255ZM121 269.56H125V271.4H125.06C125.579 271.4 126 271.821 126 272.34V274.12C126 275.158 125.158 276 124.12 276H122.88C121.842 276 121 275.158 121 274.12V271.4V271.4V269.56Z"
            fill="white"
          />
        </g>
        <rect x="121" y="270.25" width="3.99998" height="0.95833" fill="#ABABAB" />
        <path
          d="M121 253.94C121 253.421 121.421 253 121.94 253H124.06C124.579 253 125 253.421 125 253.94V270.25H121V253.94Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M121 275.06C121 275.579 121.421 276 121.94 276H125.06C125.579 276 126 275.579 126 275.06V272.148C126 271.629 125.579 271.208 125.06 271.208H121V275.06Z"
          fill="url(#paint1_linear)"
        />
        <g filter="url(#1-5-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M125 46.9995C125 45.8952 124.105 45 123.001 45C121.896 45 121.001 45.8952 121.001 46.9995V49.1665H121.001V60H121V63.0005C121 64.1048 121.895 65 122.999 65C124.104 65 124.999 64.1048 124.999 63.0005V60.8332H125V50H125V46.9995Z"
            fill="url(#paint2_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 125 49.1665)"
          fill="#ABABAB"
        />
        <path
          d="M125 45.9399C125 45.4208 124.579 45 124.06 45H121.941C121.422 45 121.001 45.4208 121.001 45.9399V50H125V45.9399Z"
          fill="url(#paint3_linear)"
        />
        <path
          d="M121 64.0601C121 64.5792 121.421 65 121.94 65H124.059C124.578 65 124.999 64.5792 124.999 64.0601V60H121V64.0601Z"
          fill="url(#paint4_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 125 50.8334)"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#1-5-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M121 34H32V287H121V34ZM110 45H43V276H110V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M121 34H32V287H33.2192V35H119.781V287H121V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-5-filter3_i)">
          <rect
            x="43.47"
            y="45.47"
            width="66.0601"
            height="230.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="38.53"
          width="79.9399"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="34"
          y="155"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#1-5-filter4_d)">
          <rect x="34.7018" y="155.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.2859 166.795C35.7981 167.458 36.6003 167.884 37.5021 167.884C39.0485 167.884 40.3021 166.63 40.3021 165.084V158.444C40.3021 157.456 39.79 156.587 39.0165 156.089C39.3825 156.562 39.6003 157.155 39.6003 157.8V164.44C39.6003 165.986 38.3467 167.24 36.8003 167.24C36.2424 167.24 35.7225 167.077 35.2859 166.795Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.5018 155.644C35.9554 155.644 34.7018 156.898 34.7018 158.444V164.44C34.7018 164.46 34.7021 164.48 34.7025 164.501V159.024C34.7025 157.477 35.9561 156.224 37.5025 156.224C39.0285 156.224 40.2694 157.444 40.3018 158.963V158.444C40.3018 156.898 39.0482 155.644 37.5018 155.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-5-filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.5 159C36.6716 159 36 159.672 36 160.5V160.714V162V180.5C36 181.328 36.6716 182 37.5 182V182C38.3284 182 39 181.328 39 180.5V164V160.5L39 160.286C39 159.576 38.4244 159 37.7143 159V159C37.6771 159 37.6401 159.001 37.6035 159.004C37.5693 159.001 37.5348 159 37.5 159Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M108.5 273.114L44.575 160.999L108.5 47.9008L108.5 273.114Z" stroke="white" />
        <path d="M108.424 274.5L76.5 49.5584L44.576 274.5L108.424 274.5Z" stroke="white" />
        <defs>
          <filter
            id="1-5-filter0_d"
            x="119.12"
            y="251.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-5-filter1_d"
            x="119.12"
            y="43.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-5-filter2_d"
            x="27.3003"
            y="29.3003"
            width="100.279"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-5-filter3_i"
            x="43"
            y="45"
            width="67.9399"
            height="233.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="1-5-filter4_d"
            x="32.822"
            y="153.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-5-filter5_d"
            x="34.1201"
            y="157.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="127.8"
            y1="261.625"
            x2="120.2"
            y2="261.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="129.5"
            y1="273.604"
            x2="120"
            y2="273.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="127.8"
            y1="62.5"
            x2="120.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="118.202"
            y1="47.5"
            x2="125.8"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="127.798"
            y1="62.5"
            x2="120.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
