import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement21Square4Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="315"
        height="333"
        viewBox="0 0 315 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#28filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188.001 109C189.105 109 190 108.105 190 107.001C190 105.896 189.105 105.001 188.001 105.001L185.833 105.001L185.833 105.001L175 105.001L175 105L171.999 105C170.895 105 170 105.895 170 106.999C170 108.104 170.895 108.999 171.999 108.999L174.167 108.999L174.167 109L185 109L185 109L188.001 109Z"
            fill="url(#28paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 185.833 109)"
          fill="#ABABAB"
        />
        <path
          d="M189.06 109C189.579 109 190 108.579 190 108.06L190 105.941C190 105.422 189.579 105.001 189.06 105.001L185 105.001L185 109L189.06 109Z"
          fill="url(#28paint1_linear)"
        />
        <path
          d="M170.94 105C170.421 105 170 105.421 170 105.94L170 108.059C170 108.578 170.421 108.999 170.94 108.999L175 108.999L175 105L170.94 105Z"
          fill="url(#28paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 184.167 109)"
          fill="url(#28paint3_linear)"
        />
        <g filter="url(#28filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M274.001 109C275.105 109 276 108.105 276 107.001C276 105.896 275.105 105.001 274.001 105.001L271.833 105.001L271.833 105.001L261 105.001L261 105L257.999 105C256.895 105 256 105.895 256 106.999C256 108.104 256.895 108.999 257.999 108.999L260.167 108.999L260.167 109L271 109L271 109L274.001 109Z"
            fill="url(#28paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 271.833 109)"
          fill="#ABABAB"
        />
        <path
          d="M275.06 109C275.579 109 276 108.579 276 108.06L276 105.941C276 105.422 275.579 105.001 275.06 105.001L271 105.001L271 109L275.06 109Z"
          fill="url(#28paint5_linear)"
        />
        <path
          d="M256.94 105C256.421 105 256 105.421 256 105.94L256 108.059C256 108.578 256.421 108.999 256.94 108.999L261 108.999L261 105L256.94 105Z"
          fill="url(#28paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 270.167 109)"
          fill="url(#28paint7_linear)"
        />
        <g filter="url(#28filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M280 34H165V105H280V34ZM269 45H176V94H269V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M280 34H165V105H166V35H279V105H280V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="169.487"
          y="38.53"
          width="106.026"
          height="61.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#28filter3_i)">
          <rect
            x="176.47"
            y="45.47"
            width="92.0601"
            height="48.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <path d="M266.82 92.5L178.18 92.5L222.5 46.7189L266.82 92.5Z" stroke="white" />
        <rect
          x="229.528"
          y="36"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 229.528 36)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#28filter4_d)">
          <rect
            x="228.884"
            y="36.7019"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 228.884 36.7019)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M228.766 38.6951C228.42 37.542 227.35 36.7019 226.084 36.7019L219.444 36.7019C217.898 36.7019 216.644 37.9555 216.644 39.5019C216.644 39.5371 216.645 39.5721 216.646 39.6069C216.993 40.76 218.062 41.6001 219.328 41.6001L225.968 41.6001C227.515 41.6001 228.768 40.3465 228.768 38.8001C228.768 38.7649 228.768 38.7299 228.766 38.6951Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M227.409 37.0346C227.266 37.0119 227.118 37.0001 226.968 37.0001L220.328 37.0001C218.782 37.0001 217.528 38.2537 217.528 39.8001C217.528 40.8672 218.125 41.7948 219.003 42.2674C217.666 42.0558 216.644 40.8982 216.644 39.5019C216.644 37.9555 217.898 36.7019 219.444 36.7019L226.084 36.7019C226.564 36.7019 227.015 36.8224 227.409 37.0346Z"
          fill="#FBFBFB"
        />
        <g filter="url(#28filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M242.528 39.5C242.528 38.6716 241.857 38 241.028 38L240.814 38L239.528 38L221.028 38C220.2 38 219.528 38.6716 219.528 39.5C219.528 40.3284 220.2 41 221.028 41L237.528 41L241.028 41L241.243 41C241.953 41 242.528 40.4244 242.528 39.7143C242.528 39.6771 242.527 39.6401 242.525 39.6035C242.527 39.5693 242.528 39.5348 242.528 39.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="28filter0_d"
            x="168.12"
            y="103.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="28filter1_d"
            x="254.12"
            y="103.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="28filter2_d"
            x="160.3"
            y="29.3003"
            width="126.279"
            height="82.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="28filter3_i"
            x="176"
            y="45"
            width="93.9399"
            height="51.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="28filter4_d"
            x="214.764"
            y="34.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="28filter5_d"
            x="218.649"
            y="36.1201"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="28paint0_linear"
            x1="172.5"
            y1="111.8"
            x2="172.5"
            y2="104.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="28paint1_linear"
            x1="187.5"
            y1="102.202"
            x2="187.5"
            y2="109.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="28paint2_linear"
            x1="172.5"
            y1="111.798"
            x2="172.5"
            y2="104.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="28paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="28paint4_linear"
            x1="258.5"
            y1="111.8"
            x2="258.5"
            y2="104.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="28paint5_linear"
            x1="273.5"
            y1="102.202"
            x2="273.5"
            y2="109.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="28paint6_linear"
            x1="258.5"
            y1="111.798"
            x2="258.5"
            y2="104.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="28paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
