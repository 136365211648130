import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import {
  StageOrderBlockItems,
  StageOrderBlockSubTitle,
  StageOrderBlockTitle,
  StageOrderBlockWrapper,
  StageOrderDateWrapper,
  StageOrderGroup,
  InputTitle,
} from './styles'
import { Input, Select, Suggest } from '../../../components'
import { IDeliveryProps } from '../interface'
import { NumberInput, ISelectOption } from '../stage-calculator/components'
import { dateFNS, splitString } from '../../../utilits'
import { showStageInputList, liftingItems } from './consts'
import { InfoTooltip } from '../../../assets/icons'

export const Delivery: FC<IDeliveryProps> = props => {
  const { onChange, data, forecast } = props
  const [deliveryData, setDeliveryData] = useState<Record<string, any>>({})
  const [deliveryDate, setDeliveryDate] = useState<null | string>(null)
  const [isFirstRender, setIsFirstRender] = useState(true)
  const showStageInput = useMemo(() => {
    return deliveryData['lifting'] && showStageInputList.includes(deliveryData['lifting']['id'])
  }, [deliveryData])

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false)
    } else {
      onChange(deliveryData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryData])

  useEffect(() => {
    const parseDate = date => (date ? dateFNS.format(new Date(date), 'dd MMMM') : '')
    let result = ''

    if (forecast) {
      result = parseDate(forecast['date-avg'])
    }

    setDeliveryDate(result)
  }, [forecast])

  useEffect(() => {
    // if (data?.value && Number.isFinite(deliveryData?.lat) === false) {
    //   setDeliveryData(data)
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const getStreet = useCallback(
    (street: string) => {
      return splitString(street)
        .filter(block => block.indexOf(deliveryData['city']) === -1)
        .join(', ')
    },
    [deliveryData],
  )

  const handleSuggestChange = useCallback((suggest, key) => {
      const city = suggest?.['data']?.['city'] || 'Москва'
      const additionalData = {}
      const value = suggest?.['value'] || ''
      const lat = suggest?.['data']?.['geo_lat'] || 0
      const lng = suggest?.['data']?.['geo_lon'] || 0
      setDeliveryData(prevState => ({
        ...prevState,
        ...additionalData,
        lat: lat || prevState['lat'],
        lng: lng || prevState['lng'],
        value,
        [key]: value,
        city
      }))
  }, [])

  const handleChange = useCallback(
    (value, key) => {
      let result = value

      if (key === 'street') {
        result = getStreet(value)
      }

      setDeliveryData(prevState => ({
        ...prevState,
        [key]: result,
      }))
    },
    [getStreet],
  )

  const handleSelect = useCallback(
    key => value => {
      // console.log({ key, value})
      setDeliveryData(prevState => ({
        ...prevState,
        [key]: value,
      }))
    },
    [],
  )

  const selectedLifting = useMemo(() => {
    let result: ISelectOption | null = null

    if (deliveryData && deliveryData['lifting']) {
      result = deliveryData['lifting']
    }

    return result
  }, [deliveryData])

  const localHandleSuggestChange = useCallback((...args) => {
    //console.log( 'TEST88', ...args)

    //handleSuggestChange(args[0], 'city')
    // @ts-ignore
    handleSuggestChange(...args)
  }, [handleSuggestChange])

  useEffect(() => {

    // console.log({ deliveryData, }, 'deliveryData00' )
  }, [])

  return (
    <StageOrderBlockWrapper>
      <StageOrderBlockTitle>Доставка</StageOrderBlockTitle>
      <StageOrderBlockSubTitle>Куда доставить заказ?</StageOrderBlockSubTitle>
      <div style={{ display: 'flex', flexDirection: 'row', 'gap': 10, marginBottom: 15 }}>
        <InfoTooltip />
        <span>
          Поля, отмеченные звёздочками, обязательны к заполнению
        </span>
      </div>
      <StageOrderBlockItems>
      <StageOrderGroup fullWidth>
          <Suggest
            onChange={localHandleSuggestChange}
            value={deliveryData}
            name="street"
            title="Город, улица, дом, корпус"
            placeholder="Введите город, улицу, дом, корпус"
            valueKey={['street_with_type', 'house']}
            suggestFilter={{ locations_boost: deliveryData['locationsBoost'] }}
          />
        </StageOrderGroup>
        <StageOrderGroup>
          <Input
            onChange={handleChange}
            value={deliveryData['apartment']}
            name="apartment"
            placeholder="Введите номер квартиры"
          />
          <InputTitle>Номер квартиры</InputTitle>
        </StageOrderGroup>
        <StageOrderGroup>
          <Input
            onChange={handleChange}
            value={deliveryData['entrance']}
            name="entrance"
            placeholder="Введите номер подъезда"
          />
          <InputTitle>Номер подъезда</InputTitle>
        </StageOrderGroup>
        <StageOrderGroup fullWidth>
          <Input
            onChange={handleChange}
            value={deliveryData['comment']}
            name="comment"
            placeholder="Введите комментарий к доставке"
          />
          <InputTitle>Комментарий к доставке</InputTitle>
        </StageOrderGroup>
        <StageOrderGroup noBottomMargin>
          <Select
            req={true}
            placeholder="Особенности подъема"
            name="lifting"
            id="lifting"
            onSelect={handleChange}
            items={liftingItems}
            selected={selectedLifting}
          />
          <InputTitle>Особенности подъема</InputTitle>
          {deliveryDate && (
            <StageOrderDateWrapper>Ожидаемая дата доставки {deliveryDate}</StageOrderDateWrapper>
          )}
        </StageOrderGroup>
        {showStageInput && (
          <StageOrderGroup>
            <NumberInput
              req={true}
              id="stage"
              placeholder="Укажите этаж"
              onSelect={handleSelect('stage')}
              data={{ min: 1, max: 999 }}
              selected={deliveryData['stage']}
              title="stage"
              noError
            />
            <InputTitle>Этаж</InputTitle>
          </StageOrderGroup>
        )}
      </StageOrderBlockItems>
    </StageOrderBlockWrapper>
  )
}
