import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement14Square54Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#1-4_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M55.0005 34C56.1048 34 57 33.1048 57 32.0005C57 30.8962 56.1048 30.001 55.0005 30.001L52.8335 30.001L52.8335 30.001L42 30.001L42 30L38.9995 30C37.8952 30 37 30.8952 37 31.9995C37 33.1038 37.8952 33.999 38.9995 33.999L41.1668 33.999L41.1668 34L52 34L52 34L55.0005 34Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 52.8335 34)"
          fill="#ABABAB"
        />
        <path
          d="M56.0601 34C56.5792 34 57 33.5792 57 33.0601L57 30.941C57 30.4219 56.5792 30.001 56.0601 30.001L52 30.001L52 34L56.0601 34Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M37.9399 30C37.4208 30 37 30.4208 37 30.9399L37 33.059C37 33.5781 37.4208 33.999 37.9399 33.999L42 33.999L42 30L37.9399 30Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 51.1666 34)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#1-4_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M95.0005 34C96.1048 34 97 33.1048 97 32.0005C97 30.8962 96.1048 30.001 95.0005 30.001L92.8335 30.001L92.8335 30.001L82 30.001L82 30L78.9995 30C77.8952 30 77 30.8952 77 31.9995C77 33.1038 77.8952 33.999 78.9995 33.999L81.1668 33.999L81.1668 34L92 34L92 34L95.0005 34Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 92.8335 34)"
          fill="#ABABAB"
        />
        <path
          d="M96.0601 34C96.5792 34 97 33.5792 97 33.0601L97 30.941C97 30.4219 96.5792 30.001 96.0601 30.001L92 30.001L92 34L96.0601 34Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M77.9399 30C77.4208 30 77 30.4208 77 30.9399L77 33.059C77 33.5781 77.4208 33.999 77.9399 33.999L82 33.999L82 30L77.9399 30Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 91.1666 34)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#1-4_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M101 34H33V287H101V34ZM91 44H43V277H91V44Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M101 34H33V287H33.9315V35H100.068V287H101V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-4_filter3_i)">
          <rect
            x="43.47"
            y="44.47"
            width="47.0601"
            height="232.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="37.53"
          y="38.53"
          width="58.9399"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M44.5523 45.5L67 270.953L89.4477 45.5L44.5523 45.5Z" stroke="white" />
        <rect
          x="74"
          y="278"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 74 278)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="73.356"
          y="278.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 73.356 278.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.238 280.695C72.8916 279.542 71.8219 278.702 70.556 278.702L63.9161 278.702C62.3697 278.702 61.1161 279.955 61.1161 281.502C61.1161 281.537 61.1167 281.572 61.118 281.607C61.4644 282.76 62.5341 283.6 63.8 283.6L70.4399 283.6C71.9863 283.6 73.2399 282.346 73.2399 280.8C73.2399 280.765 73.2393 280.73 73.238 280.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.881 279.034C71.7373 279.012 71.59 279 71.4399 279L64.8 279C63.2536 279 62 280.253 62 281.8C62 282.867 62.5969 283.795 63.475 284.267C62.1382 284.056 61.1161 282.898 61.1161 281.502C61.1161 279.955 62.3697 278.702 63.9161 278.702L70.556 278.702C71.0353 278.702 71.4865 278.822 71.881 279.034Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-4_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M70 281.5C70 280.672 69.3284 280 68.5 280L68.2857 280L67 280L48.5 280C47.6716 280 47 280.672 47 281.5V281.5C47 282.328 47.6716 283 48.5 283L65 283L68.5 283L68.7143 283C69.4244 283 70 282.424 70 281.714V281.714C70 281.677 69.9988 281.64 69.9965 281.604C69.9988 281.569 70 281.535 70 281.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="1-4_filter0_d"
            x="35.1201"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-4_filter1_d"
            x="75.1201"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-4_filter2_d"
            x="28.3003"
            y="29.3003"
            width="79.2793"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-4_filter3_i"
            x="43"
            y="44"
            width="48.9399"
            height="235.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="1-4_filter4_d"
            x="45.28"
            y="278.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="39.5"
            y1="36.8"
            x2="39.5"
            y2="29.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="54.5"
            y1="27.2018"
            x2="54.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="39.5"
            y1="36.7982"
            x2="39.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="79.5"
            y1="36.8"
            x2="79.5"
            y2="29.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="94.5"
            y1="27.2018"
            x2="94.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="79.5"
            y1="36.7982"
            x2="79.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
