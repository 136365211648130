import React, { FC } from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'
import { IContainerSquareProps } from '../../interface'
import { ContainerBackground } from '../container-background'

export const ContainerSquare5Casement: FC<IContainerSquareProps> = props => {
  const { onCasementClick, withoutBackground } = props

  return (
    <ProductImageWrapper isClickable>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <rect width="449" height="333" fill="white" />
        <rect x="24" y="28" width="401" height="264" fill="#E8EAEE" />
        <rect x="24" y="28" width="401" height="264" fill="url(#5-0-pattern0)" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M439 10H10V292H24V29H425V292H439V10Z"
          fill="#C4C4C4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M439 292H10V308H0V316H10H439H449V308H439V292Z"
          fill="url(#5-0-paint0_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M425 28H23V292H425V28ZM148 41H36V187H148V41ZM36 207H149V279H36V207ZM281 41H168V279H281V41ZM300 207H413V279H300V207ZM412 41H300V187H412V41Z"
          fill="#E8EAEE"
        />
        <path d="M439 10L425 28.0571V292.075L439 308V10Z" fill="#E9EBEF" />
        <path d="M439 10L425 28.0571V292.075L439 308V10Z" fill="#020202" fillOpacity="0.06" />
        <path d="M10 10L24 28.1525V291.982L10 308V10Z" fill="#E9EBEF" />
        <path d="M10 10L24 28.1525V291.982L10 308V10Z" fill="#020202" fillOpacity="0.06" />
        <g
          filter="url(#5-0-filter0_i)"
          className="casement"
          data-index="2"
          onClick={onCasementClick(2)}
        >
          <rect x="300" y="41" width="112" height="146" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="300.47"
          y="41.47"
          width="111.06"
          height="145.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#5-0-filter1_i)"
          className="casement"
          data-index="0"
          onClick={onCasementClick(0)}
        >
          <rect x="36" y="41" width="113" height="146" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="36.47"
          y="41.47"
          width="112.06"
          height="145.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <path
          d="M100.985 65.7413H98.4473C97.8003 65.7413 97.2529 65.2346 97.2529 64.6357V64.4054C97.2529 63.8065 97.8003 63.2998 98.4473 63.2998H100.985C101.632 63.2998 102.18 63.8065 102.18 64.4054V64.6357C102.18 65.2576 101.657 65.7413 100.985 65.7413Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M83.4262 68.5652C83.7247 68.5652 83.7993 68.5652 84.1972 68.5652C84.9432 68.5652 85.4903 69.1052 85.4903 69.833C85.4903 70.5843 84.9184 71.1008 84.1474 71.1008C82.705 71.1008 81.8098 71.1008 80.3674 71.1008C79.8203 71.1008 79.3727 70.913 79.1488 70.4199C78.9499 70.1147 78.8255 69.2695 79.6213 68.5652C80.1436 67.9782 85.9131 61.7565 86.9327 60.6765C86.9824 60.6061 86.9327 60.5122 86.8581 60.5122C86.8332 60.5122 86.8332 60.5122 86.8084 60.5122C84.6448 60.5122 82.4812 60.5122 80.3177 60.5122C79.6711 60.5122 79.1737 60.1365 79.0494 59.573C78.9002 58.9156 79.1986 58.3522 79.7706 58.0939C79.9446 58.0235 80.1187 58 80.2928 58C83.5008 58 86.684 58 89.8921 58C90.5138 58 90.9366 58.1878 91.1852 58.7278C91.5085 59.4087 91.1604 59.8783 90.9366 60.1835C90.4889 60.7235 85.938 65.56 83.4262 68.2834C83.4014 68.3069 83.3765 68.3539 83.3268 68.3773C83.277 68.4478 83.3268 68.5652 83.4262 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M90.1854 71.1007H87.6475C87.0005 71.1007 86.4531 70.5816 86.4531 69.9681V69.7322C86.4531 69.1187 87.0005 68.5996 87.6475 68.5996H90.1854C90.8323 68.5996 91.3797 69.1187 91.3797 69.7322V69.9681C91.3797 70.6052 90.8323 71.1007 90.1854 71.1007Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M93.7158 64.5152C93.7158 62.7341 93.7158 60.9764 93.7158 59.1952C93.7158 58.4922 94.2379 58 94.9836 58C98.2152 58 101.447 58 104.678 58C105.424 58 105.946 58.5156 105.946 59.2421C105.946 59.9686 105.424 60.5077 104.678 60.5077C101.994 60.5077 99.3339 60.5077 96.6491 60.5077C96.4503 60.5077 96.4005 60.5545 96.4005 60.742C96.4005 63.2731 96.4005 65.8042 96.4005 68.3353C96.4005 68.5463 96.4751 68.5697 96.6491 68.5697C99.309 68.5697 101.994 68.5697 104.654 68.5697C105.25 68.5697 105.723 68.8978 105.897 69.39C106.095 69.9759 105.872 70.5618 105.399 70.8899C105.176 71.0305 104.902 71.1008 104.629 71.1008C101.447 71.1008 98.2401 71.1008 95.0582 71.1008C94.2378 71.1008 93.7407 70.5852 93.7407 69.8118C93.7158 68.0307 93.7158 66.273 93.7158 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M80.7054 76.0413V76.6981H79V76.0413H79.4263V74.2584H79.0502V73.6016H80.6803V74.2584H80.3041V76.0413H80.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M84.5346 76.7576H83.774L83.6513 76.5448C83.3814 76.6867 83.0624 76.7576 82.7679 76.7576C81.9336 76.734 81.1484 76.1665 81.1484 75.1498C81.1484 74.1804 81.811 73.542 82.817 73.542C83.6513 73.542 84.461 74.0858 84.461 75.1498C84.461 75.5281 84.3629 75.8119 84.2156 76.072L84.5592 76.6394V76.7576H84.5346ZM82.7925 76.001C83.1851 76.001 83.6022 75.7173 83.6022 75.1498C83.6022 74.5824 83.1851 74.2986 82.7925 74.2986C82.3753 74.2986 81.9827 74.5824 81.9827 75.1498C81.9827 75.7173 82.3753 76.001 82.7925 76.001Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M86.7838 75.7484C86.7838 75.9596 87.0294 76.077 87.2995 76.077C87.5451 76.077 87.7907 75.9596 87.7907 75.7953C87.7907 75.4902 87.4223 75.4902 87.2013 75.4667C86.6119 75.4197 86.0226 75.2085 86.0226 74.5513C86.0226 73.8706 86.6119 73.542 87.275 73.542C87.8889 73.542 88.5274 73.8706 88.5274 74.5513H87.717C87.717 74.34 87.496 74.2461 87.275 74.2461C87.0785 74.2461 86.8329 74.2931 86.8329 74.5278C86.8329 74.7391 87.0294 74.786 87.3241 74.8095C88.0117 74.8564 88.601 75.0207 88.601 75.7718C88.601 76.429 87.938 76.7576 87.275 76.7576C86.5874 76.7576 85.9243 76.429 85.9489 75.7484H86.7838Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M91.2285 74.3107H90.0374V74.7598H91.1292V75.4689H90.0374V75.9653H91.2533V76.6981H89.1689V73.6016H91.2036V74.3107H91.2285Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M94.6027 76.5799V76.6981H93.7218L93.159 75.729H92.7186V76.6981H91.8867V73.6016C92.3761 73.6016 92.841 73.6016 93.3059 73.6016C94.0888 73.6016 94.4559 74.1689 94.4559 74.7125C94.4559 75.0671 94.2846 75.4217 93.9665 75.6108L94.6027 76.5799ZM93.3059 75.0671C93.5505 75.0671 93.6484 74.878 93.6484 74.6653C93.6484 74.4762 93.5261 74.2871 93.3059 74.2871H92.7186V75.0671H93.3059Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M96.2757 75.3339L96.5525 74.527L96.9551 73.6016H97.8863V73.7202L96.4518 76.7577H96.0995L94.665 73.7202V73.6016H95.5962L96.024 74.527L96.2757 75.3339Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M99.7815 76.0413V76.6981H98.0762V76.0413H98.5025V74.2584H98.1263V73.6016H99.7565V74.2584H99.3803V76.0413H99.7815Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M101.835 73.6016C102.509 73.6016 103.258 73.9549 103.383 74.7792H102.559C102.434 74.4495 102.185 74.3317 101.835 74.3317C101.336 74.3317 101.011 74.6614 101.011 75.1796C101.011 75.6271 101.336 76.0275 101.835 76.0275C102.185 76.0275 102.484 75.8626 102.559 75.5329H103.383C103.258 76.4044 102.534 76.7577 101.835 76.7577C100.986 76.7577 100.162 76.2159 100.162 75.1796C100.162 74.1197 100.986 73.6016 101.835 73.6016Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M105.944 74.3107H104.753V74.7598H105.845V75.4689H104.753V75.9653H105.969V76.6981H103.885V73.6016H105.919V74.3107H105.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M364.985 65.7413H362.447C361.8 65.7413 361.253 65.2346 361.253 64.6357V64.4054C361.253 63.8065 361.8 63.2998 362.447 63.2998H364.985C365.632 63.2998 366.18 63.8065 366.18 64.4054V64.6357C366.18 65.2576 365.657 65.7413 364.985 65.7413Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M347.426 68.5652C347.725 68.5652 347.799 68.5652 348.197 68.5652C348.943 68.5652 349.49 69.1052 349.49 69.833C349.49 70.5843 348.918 71.1008 348.147 71.1008C346.705 71.1008 345.81 71.1008 344.367 71.1008C343.82 71.1008 343.373 70.913 343.149 70.4199C342.95 70.1147 342.826 69.2695 343.621 68.5652C344.144 67.9782 349.913 61.7565 350.933 60.6765C350.982 60.6061 350.933 60.5122 350.858 60.5122C350.833 60.5122 350.833 60.5122 350.808 60.5122C348.645 60.5122 346.481 60.5122 344.318 60.5122C343.671 60.5122 343.174 60.1365 343.049 59.573C342.9 58.9156 343.199 58.3522 343.771 58.0939C343.945 58.0235 344.119 58 344.293 58C347.501 58 350.684 58 353.892 58C354.514 58 354.937 58.1878 355.185 58.7278C355.509 59.4087 355.16 59.8783 354.937 60.1835C354.489 60.7235 349.938 65.56 347.426 68.2834C347.401 68.3069 347.376 68.3539 347.327 68.3773C347.277 68.4478 347.327 68.5652 347.426 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M354.185 71.1007H351.647C351.001 71.1007 350.453 70.5816 350.453 69.9681V69.7322C350.453 69.1187 351.001 68.5996 351.647 68.5996H354.185C354.832 68.5996 355.38 69.1187 355.38 69.7322V69.9681C355.38 70.6052 354.832 71.1007 354.185 71.1007Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M357.716 64.5152C357.716 62.7341 357.716 60.9764 357.716 59.1952C357.716 58.4922 358.238 58 358.984 58C362.215 58 365.447 58 368.678 58C369.424 58 369.946 58.5156 369.946 59.2421C369.946 59.9686 369.424 60.5077 368.678 60.5077C365.994 60.5077 363.334 60.5077 360.649 60.5077C360.45 60.5077 360.401 60.5545 360.401 60.742C360.401 63.2731 360.401 65.8042 360.401 68.3353C360.401 68.5463 360.475 68.5697 360.649 68.5697C363.309 68.5697 365.994 68.5697 368.654 68.5697C369.25 68.5697 369.723 68.8978 369.897 69.39C370.095 69.9759 369.872 70.5618 369.399 70.8899C369.176 71.0305 368.902 71.1008 368.629 71.1008C365.447 71.1008 362.24 71.1008 359.058 71.1008C358.238 71.1008 357.741 70.5852 357.741 69.8118C357.716 68.0307 357.716 66.273 357.716 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M344.705 76.0413V76.6981H343V76.0413H343.426V74.2584H343.05V73.6016H344.68V74.2584H344.304V76.0413H344.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M348.535 76.7576H347.774L347.651 76.5448C347.381 76.6867 347.062 76.7576 346.768 76.7576C345.934 76.734 345.148 76.1665 345.148 75.1498C345.148 74.1804 345.811 73.542 346.817 73.542C347.651 73.542 348.461 74.0858 348.461 75.1498C348.461 75.5281 348.363 75.8119 348.216 76.072L348.559 76.6394V76.7576H348.535ZM346.792 76.001C347.185 76.001 347.602 75.7173 347.602 75.1498C347.602 74.5824 347.185 74.2986 346.792 74.2986C346.375 74.2986 345.983 74.5824 345.983 75.1498C345.983 75.7173 346.375 76.001 346.792 76.001Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M350.784 75.7484C350.784 75.9596 351.029 76.077 351.3 76.077C351.545 76.077 351.791 75.9596 351.791 75.7953C351.791 75.4902 351.422 75.4902 351.201 75.4667C350.612 75.4197 350.023 75.2085 350.023 74.5513C350.023 73.8706 350.612 73.542 351.275 73.542C351.889 73.542 352.527 73.8706 352.527 74.5513H351.717C351.717 74.34 351.496 74.2461 351.275 74.2461C351.079 74.2461 350.833 74.2931 350.833 74.5278C350.833 74.7391 351.029 74.786 351.324 74.8095C352.012 74.8564 352.601 75.0207 352.601 75.7718C352.601 76.429 351.938 76.7576 351.275 76.7576C350.587 76.7576 349.924 76.429 349.949 75.7484H350.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M355.228 74.3107H354.037V74.7598H355.129V75.4689H354.037V75.9653H355.253V76.6981H353.169V73.6016H355.204V74.3107H355.228Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M358.603 76.5799V76.6981H357.722L357.159 75.729H356.719V76.6981H355.887V73.6016C356.376 73.6016 356.841 73.6016 357.306 73.6016C358.089 73.6016 358.456 74.1689 358.456 74.7125C358.456 75.0671 358.285 75.4217 357.966 75.6108L358.603 76.5799ZM357.306 75.0671C357.551 75.0671 357.648 74.878 357.648 74.6653C357.648 74.4762 357.526 74.2871 357.306 74.2871H356.719V75.0671H357.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M360.276 75.3339L360.552 74.527L360.955 73.6016H361.886V73.7202L360.452 76.7577H360.099L358.665 73.7202V73.6016H359.596L360.024 74.527L360.276 75.3339Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M363.782 76.0413V76.6981H362.076V76.0413H362.503V74.2584H362.126V73.6016H363.756V74.2584H363.38V76.0413H363.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M365.835 73.6016C366.509 73.6016 367.258 73.9549 367.383 74.7792H366.559C366.434 74.4495 366.185 74.3317 365.835 74.3317C365.336 74.3317 365.011 74.6614 365.011 75.1796C365.011 75.6271 365.336 76.0275 365.835 76.0275C366.185 76.0275 366.484 75.8626 366.559 75.5329H367.383C367.258 76.4044 366.534 76.7577 365.835 76.7577C364.986 76.7577 364.162 76.2159 364.162 75.1796C364.162 74.1197 364.986 73.6016 365.835 73.6016Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M369.944 74.3107H368.753V74.7598H369.845V75.4689H368.753V75.9653H369.969V76.6981H367.885V73.6016H369.919V74.3107H369.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <rect y="316" width="449" height="17" fill="white" />
        <g
          filter="url(#5-0-filter2_i)"
          className="casement"
          data-index="3"
          onClick={onCasementClick(3)}
        >
          <rect x="36" y="207" width="113" height="72" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="36.47"
          y="207.47"
          width="112.06"
          height="71.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#5-0-filter3_i)"
          className="casement"
          data-index="1"
          onClick={onCasementClick(1)}
        >
          <rect x="168" y="41" width="113" height="238" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="168.47"
          y="41.47"
          width="112.06"
          height="237.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#5-0-filter4_i)"
          className="casement"
          data-index="4"
          onClick={onCasementClick(4)}
        >
          <rect x="300" y="207" width="113" height="72" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="300.47"
          y="207.47"
          width="112.06"
          height="71.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <path
          d="M101.985 231.741H99.4473C98.8003 231.741 98.2529 231.235 98.2529 230.636V230.405C98.2529 229.807 98.8003 229.3 99.4473 229.3H101.985C102.632 229.3 103.18 229.807 103.18 230.405V230.636C103.18 231.258 102.657 231.741 101.985 231.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M84.4262 234.565C84.7247 234.565 84.7993 234.565 85.1972 234.565C85.9432 234.565 86.4903 235.105 86.4903 235.833C86.4903 236.584 85.9184 237.101 85.1474 237.101C83.705 237.101 82.8098 237.101 81.3674 237.101C80.8203 237.101 80.3727 236.913 80.1488 236.42C79.9499 236.115 79.8255 235.27 80.6213 234.565C81.1436 233.978 86.9131 227.757 87.9327 226.677C87.9824 226.606 87.9327 226.512 87.8581 226.512C87.8332 226.512 87.8332 226.512 87.8084 226.512C85.6448 226.512 83.4812 226.512 81.3177 226.512C80.6711 226.512 80.1737 226.137 80.0494 225.573C79.9002 224.916 80.1986 224.352 80.7706 224.094C80.9446 224.023 81.1187 224 81.2928 224C84.5008 224 87.684 224 90.8921 224C91.5138 224 91.9366 224.188 92.1852 224.728C92.5085 225.409 92.1604 225.878 91.9366 226.183C91.4889 226.723 86.938 231.56 84.4262 234.283C84.4014 234.307 84.3765 234.354 84.3268 234.377C84.277 234.448 84.3268 234.565 84.4262 234.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M91.1854 237.101H88.6475C88.0005 237.101 87.4531 236.582 87.4531 235.968V235.732C87.4531 235.119 88.0005 234.6 88.6475 234.6H91.1854C91.8323 234.6 92.3797 235.119 92.3797 235.732V235.968C92.3797 236.605 91.8323 237.101 91.1854 237.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M94.7158 230.515C94.7158 228.734 94.7158 226.976 94.7158 225.195C94.7158 224.492 95.2379 224 95.9836 224C99.2152 224 102.447 224 105.678 224C106.424 224 106.946 224.516 106.946 225.242C106.946 225.969 106.424 226.508 105.678 226.508C102.994 226.508 100.334 226.508 97.6491 226.508C97.4503 226.508 97.4005 226.555 97.4005 226.742C97.4005 229.273 97.4005 231.804 97.4005 234.335C97.4005 234.546 97.4751 234.57 97.6491 234.57C100.309 234.57 102.994 234.57 105.654 234.57C106.25 234.57 106.723 234.898 106.897 235.39C107.095 235.976 106.872 236.562 106.399 236.89C106.176 237.03 105.902 237.101 105.629 237.101C102.447 237.101 99.2401 237.101 96.0582 237.101C95.2378 237.101 94.7407 236.585 94.7407 235.812C94.7158 234.031 94.7158 232.273 94.7158 230.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M81.7054 242.041V242.698H80V242.041H80.4263V240.258H80.0502V239.602H81.6803V240.258H81.3041V242.041H81.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M85.5346 242.758H84.774L84.6513 242.545C84.3814 242.687 84.0624 242.758 83.7679 242.758C82.9336 242.734 82.1484 242.167 82.1484 241.15C82.1484 240.18 82.811 239.542 83.817 239.542C84.6513 239.542 85.461 240.086 85.461 241.15C85.461 241.528 85.3629 241.812 85.2156 242.072L85.5592 242.639V242.758H85.5346ZM83.7925 242.001C84.1851 242.001 84.6022 241.717 84.6022 241.15C84.6022 240.582 84.1851 240.299 83.7925 240.299C83.3753 240.299 82.9827 240.582 82.9827 241.15C82.9827 241.717 83.3753 242.001 83.7925 242.001Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M87.7838 241.748C87.7838 241.96 88.0294 242.077 88.2995 242.077C88.5451 242.077 88.7907 241.96 88.7907 241.795C88.7907 241.49 88.4223 241.49 88.2013 241.467C87.6119 241.42 87.0226 241.208 87.0226 240.551C87.0226 239.871 87.6119 239.542 88.275 239.542C88.8889 239.542 89.5274 239.871 89.5274 240.551H88.717C88.717 240.34 88.496 240.246 88.275 240.246C88.0785 240.246 87.8329 240.293 87.8329 240.528C87.8329 240.739 88.0294 240.786 88.3241 240.809C89.0117 240.856 89.601 241.021 89.601 241.772C89.601 242.429 88.938 242.758 88.275 242.758C87.5874 242.758 86.9243 242.429 86.9489 241.748H87.7838Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M92.2285 240.311H91.0374V240.76H92.1292V241.469H91.0374V241.965H92.2533V242.698H90.1689V239.602H92.2036V240.311H92.2285Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M95.6027 242.58V242.698H94.7218L94.159 241.729H93.7186V242.698H92.8867V239.602C93.3761 239.602 93.841 239.602 94.3059 239.602C95.0888 239.602 95.4559 240.169 95.4559 240.713C95.4559 241.067 95.2846 241.422 94.9665 241.611L95.6027 242.58ZM94.3059 241.067C94.5505 241.067 94.6484 240.878 94.6484 240.665C94.6484 240.476 94.5261 240.287 94.3059 240.287H93.7186V241.067H94.3059Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M97.2757 241.334L97.5525 240.527L97.9551 239.602H98.8863V239.72L97.4518 242.758H97.0995L95.665 239.72V239.602H96.5962L97.024 240.527L97.2757 241.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M100.782 242.041V242.698H99.0762V242.041H99.5025V240.258H99.1263V239.602H100.756V240.258H100.38V242.041H100.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M102.835 239.602C103.509 239.602 104.258 239.955 104.383 240.779H103.559C103.434 240.449 103.185 240.332 102.835 240.332C102.336 240.332 102.011 240.661 102.011 241.18C102.011 241.627 102.336 242.028 102.835 242.028C103.185 242.028 103.484 241.863 103.559 241.533H104.383C104.258 242.404 103.534 242.758 102.835 242.758C101.986 242.758 101.162 242.216 101.162 241.18C101.162 240.12 101.986 239.602 102.835 239.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M106.944 240.311H105.753V240.76H106.845V241.469H105.753V241.965H106.969V242.698H104.885V239.602H106.919V240.311H106.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M233.985 65.7413H231.447C230.8 65.7413 230.253 65.2346 230.253 64.6357V64.4054C230.253 63.8065 230.8 63.2998 231.447 63.2998H233.985C234.632 63.2998 235.18 63.8065 235.18 64.4054V64.6357C235.18 65.2576 234.657 65.7413 233.985 65.7413Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M216.426 68.5652C216.725 68.5652 216.799 68.5652 217.197 68.5652C217.943 68.5652 218.49 69.1052 218.49 69.833C218.49 70.5843 217.918 71.1008 217.147 71.1008C215.705 71.1008 214.81 71.1008 213.367 71.1008C212.82 71.1008 212.373 70.913 212.149 70.4199C211.95 70.1147 211.826 69.2695 212.621 68.5652C213.144 67.9782 218.913 61.7565 219.933 60.6765C219.982 60.6061 219.933 60.5122 219.858 60.5122C219.833 60.5122 219.833 60.5122 219.808 60.5122C217.645 60.5122 215.481 60.5122 213.318 60.5122C212.671 60.5122 212.174 60.1365 212.049 59.573C211.9 58.9156 212.199 58.3522 212.771 58.0939C212.945 58.0235 213.119 58 213.293 58C216.501 58 219.684 58 222.892 58C223.514 58 223.937 58.1878 224.185 58.7278C224.509 59.4087 224.16 59.8783 223.937 60.1835C223.489 60.7235 218.938 65.56 216.426 68.2834C216.401 68.3069 216.376 68.3539 216.327 68.3773C216.277 68.4478 216.327 68.5652 216.426 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M223.185 71.1007H220.647C220.001 71.1007 219.453 70.5816 219.453 69.9681V69.7322C219.453 69.1187 220.001 68.5996 220.647 68.5996H223.185C223.832 68.5996 224.38 69.1187 224.38 69.7322V69.9681C224.38 70.6052 223.832 71.1007 223.185 71.1007Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M226.716 64.5152C226.716 62.7341 226.716 60.9764 226.716 59.1952C226.716 58.4922 227.238 58 227.984 58C231.215 58 234.447 58 237.678 58C238.424 58 238.946 58.5156 238.946 59.2421C238.946 59.9686 238.424 60.5077 237.678 60.5077C234.994 60.5077 232.334 60.5077 229.649 60.5077C229.45 60.5077 229.401 60.5545 229.401 60.742C229.401 63.2731 229.401 65.8042 229.401 68.3353C229.401 68.5463 229.475 68.5697 229.649 68.5697C232.309 68.5697 234.994 68.5697 237.654 68.5697C238.25 68.5697 238.723 68.8978 238.897 69.39C239.095 69.9759 238.872 70.5618 238.399 70.8899C238.176 71.0305 237.902 71.1008 237.629 71.1008C234.447 71.1008 231.24 71.1008 228.058 71.1008C227.238 71.1008 226.741 70.5852 226.741 69.8118C226.716 68.0307 226.716 66.273 226.716 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M213.705 76.0413V76.6981H212V76.0413H212.426V74.2584H212.05V73.6016H213.68V74.2584H213.304V76.0413H213.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M217.535 76.7576H216.774L216.651 76.5448C216.381 76.6867 216.062 76.7576 215.768 76.7576C214.934 76.734 214.148 76.1665 214.148 75.1498C214.148 74.1804 214.811 73.542 215.817 73.542C216.651 73.542 217.461 74.0858 217.461 75.1498C217.461 75.5281 217.363 75.8119 217.216 76.072L217.559 76.6394V76.7576H217.535ZM215.792 76.001C216.185 76.001 216.602 75.7173 216.602 75.1498C216.602 74.5824 216.185 74.2986 215.792 74.2986C215.375 74.2986 214.983 74.5824 214.983 75.1498C214.983 75.7173 215.375 76.001 215.792 76.001Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M219.784 75.7484C219.784 75.9596 220.029 76.077 220.3 76.077C220.545 76.077 220.791 75.9596 220.791 75.7953C220.791 75.4902 220.422 75.4902 220.201 75.4667C219.612 75.4197 219.023 75.2085 219.023 74.5513C219.023 73.8706 219.612 73.542 220.275 73.542C220.889 73.542 221.527 73.8706 221.527 74.5513H220.717C220.717 74.34 220.496 74.2461 220.275 74.2461C220.079 74.2461 219.833 74.2931 219.833 74.5278C219.833 74.7391 220.029 74.786 220.324 74.8095C221.012 74.8564 221.601 75.0207 221.601 75.7718C221.601 76.429 220.938 76.7576 220.275 76.7576C219.587 76.7576 218.924 76.429 218.949 75.7484H219.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M224.228 74.3107H223.037V74.7598H224.129V75.4689H223.037V75.9653H224.253V76.6981H222.169V73.6016H224.204V74.3107H224.228Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M227.603 76.5799V76.6981H226.722L226.159 75.729H225.719V76.6981H224.887V73.6016C225.376 73.6016 225.841 73.6016 226.306 73.6016C227.089 73.6016 227.456 74.1689 227.456 74.7125C227.456 75.0671 227.285 75.4217 226.966 75.6108L227.603 76.5799ZM226.306 75.0671C226.551 75.0671 226.648 74.878 226.648 74.6653C226.648 74.4762 226.526 74.2871 226.306 74.2871H225.719V75.0671H226.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M229.276 75.3339L229.552 74.527L229.955 73.6016H230.886V73.7202L229.452 76.7577H229.099L227.665 73.7202V73.6016H228.596L229.024 74.527L229.276 75.3339Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M232.782 76.0413V76.6981H231.076V76.0413H231.503V74.2584H231.126V73.6016H232.756V74.2584H232.38V76.0413H232.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M234.835 73.6016C235.509 73.6016 236.258 73.9549 236.383 74.7792H235.559C235.434 74.4495 235.185 74.3317 234.835 74.3317C234.336 74.3317 234.011 74.6614 234.011 75.1796C234.011 75.6271 234.336 76.0275 234.835 76.0275C235.185 76.0275 235.484 75.8626 235.559 75.5329H236.383C236.258 76.4044 235.534 76.7577 234.835 76.7577C233.986 76.7577 233.162 76.2159 233.162 75.1796C233.162 74.1197 233.986 73.6016 234.835 73.6016Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M238.944 74.3107H237.753V74.7598H238.845V75.4689H237.753V75.9653H238.969V76.6981H236.885V73.6016H238.919V74.3107H238.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M365.985 231.741H363.447C362.8 231.741 362.253 231.235 362.253 230.636V230.405C362.253 229.807 362.8 229.3 363.447 229.3H365.985C366.632 229.3 367.18 229.807 367.18 230.405V230.636C367.18 231.258 366.657 231.741 365.985 231.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M348.426 234.565C348.725 234.565 348.799 234.565 349.197 234.565C349.943 234.565 350.49 235.105 350.49 235.833C350.49 236.584 349.918 237.101 349.147 237.101C347.705 237.101 346.81 237.101 345.367 237.101C344.82 237.101 344.373 236.913 344.149 236.42C343.95 236.115 343.826 235.27 344.621 234.565C345.144 233.978 350.913 227.757 351.933 226.677C351.982 226.606 351.933 226.512 351.858 226.512C351.833 226.512 351.833 226.512 351.808 226.512C349.645 226.512 347.481 226.512 345.318 226.512C344.671 226.512 344.174 226.137 344.049 225.573C343.9 224.916 344.199 224.352 344.771 224.094C344.945 224.023 345.119 224 345.293 224C348.501 224 351.684 224 354.892 224C355.514 224 355.937 224.188 356.185 224.728C356.509 225.409 356.16 225.878 355.937 226.183C355.489 226.723 350.938 231.56 348.426 234.283C348.401 234.307 348.376 234.354 348.327 234.377C348.277 234.448 348.327 234.565 348.426 234.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M355.185 237.101H352.647C352.001 237.101 351.453 236.582 351.453 235.968V235.732C351.453 235.119 352.001 234.6 352.647 234.6H355.185C355.832 234.6 356.38 235.119 356.38 235.732V235.968C356.38 236.605 355.832 237.101 355.185 237.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M358.716 230.515C358.716 228.734 358.716 226.976 358.716 225.195C358.716 224.492 359.238 224 359.984 224C363.215 224 366.447 224 369.678 224C370.424 224 370.946 224.516 370.946 225.242C370.946 225.969 370.424 226.508 369.678 226.508C366.994 226.508 364.334 226.508 361.649 226.508C361.45 226.508 361.401 226.555 361.401 226.742C361.401 229.273 361.401 231.804 361.401 234.335C361.401 234.546 361.475 234.57 361.649 234.57C364.309 234.57 366.994 234.57 369.654 234.57C370.25 234.57 370.723 234.898 370.897 235.39C371.095 235.976 370.872 236.562 370.399 236.89C370.176 237.03 369.902 237.101 369.629 237.101C366.447 237.101 363.24 237.101 360.058 237.101C359.238 237.101 358.741 236.585 358.741 235.812C358.716 234.031 358.716 232.273 358.716 230.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M345.705 242.041V242.698H344V242.041H344.426V240.258H344.05V239.602H345.68V240.258H345.304V242.041H345.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M349.535 242.758H348.774L348.651 242.545C348.381 242.687 348.062 242.758 347.768 242.758C346.934 242.734 346.148 242.167 346.148 241.15C346.148 240.18 346.811 239.542 347.817 239.542C348.651 239.542 349.461 240.086 349.461 241.15C349.461 241.528 349.363 241.812 349.216 242.072L349.559 242.639V242.758H349.535ZM347.792 242.001C348.185 242.001 348.602 241.717 348.602 241.15C348.602 240.582 348.185 240.299 347.792 240.299C347.375 240.299 346.983 240.582 346.983 241.15C346.983 241.717 347.375 242.001 347.792 242.001Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M351.784 241.748C351.784 241.96 352.029 242.077 352.3 242.077C352.545 242.077 352.791 241.96 352.791 241.795C352.791 241.49 352.422 241.49 352.201 241.467C351.612 241.42 351.023 241.208 351.023 240.551C351.023 239.871 351.612 239.542 352.275 239.542C352.889 239.542 353.527 239.871 353.527 240.551H352.717C352.717 240.34 352.496 240.246 352.275 240.246C352.079 240.246 351.833 240.293 351.833 240.528C351.833 240.739 352.029 240.786 352.324 240.809C353.012 240.856 353.601 241.021 353.601 241.772C353.601 242.429 352.938 242.758 352.275 242.758C351.587 242.758 350.924 242.429 350.949 241.748H351.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M356.228 240.311H355.037V240.76H356.129V241.469H355.037V241.965H356.253V242.698H354.169V239.602H356.204V240.311H356.228Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M359.603 242.58V242.698H358.722L358.159 241.729H357.719V242.698H356.887V239.602C357.376 239.602 357.841 239.602 358.306 239.602C359.089 239.602 359.456 240.169 359.456 240.713C359.456 241.067 359.285 241.422 358.966 241.611L359.603 242.58ZM358.306 241.067C358.551 241.067 358.648 240.878 358.648 240.665C358.648 240.476 358.526 240.287 358.306 240.287H357.719V241.067H358.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M361.276 241.334L361.552 240.527L361.955 239.602H362.886V239.72L361.452 242.758H361.099L359.665 239.72V239.602H360.596L361.024 240.527L361.276 241.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M364.782 242.041V242.698H363.076V242.041H363.503V240.258H363.126V239.602H364.756V240.258H364.38V242.041H364.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M366.835 239.602C367.509 239.602 368.258 239.955 368.383 240.779H367.559C367.434 240.449 367.185 240.332 366.835 240.332C366.336 240.332 366.011 240.661 366.011 241.18C366.011 241.627 366.336 242.028 366.835 242.028C367.185 242.028 367.484 241.863 367.559 241.533H368.383C368.258 242.404 367.534 242.758 366.835 242.758C365.986 242.758 365.162 242.216 365.162 241.18C365.162 240.12 365.986 239.602 366.835 239.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M370.944 240.311H369.753V240.76H370.845V241.469H369.753V241.965H370.969V242.698H368.885V239.602H370.919V240.311H370.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <defs>
          <pattern id="5-0-pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use
              xlinkHref="#image0"
              transform="translate(-0.00768784) scale(0.00100915 0.00153332)"
            />
          </pattern>
          <filter
            id="5-0-filter0_i"
            x="300"
            y="41"
            width="112.94"
            height="148.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-0-filter1_i"
            x="36"
            y="41"
            width="113.94"
            height="148.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-0-filter2_i"
            x="36"
            y="207"
            width="113.94"
            height="74.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-0-filter3_i"
            x="168"
            y="41"
            width="113.94"
            height="240.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-0-filter4_i"
            x="300"
            y="207"
            width="113.94"
            height="74.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <linearGradient
            id="5-0-paint0_linear"
            x1="301.5"
            y1="291"
            x2="301.5"
            y2="316"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DBDDE1" />
            <stop offset="1" stopColor="#F9F9F9" />
          </linearGradient>
        </defs>
      </svg>
      {!withoutBackground && <ContainerBackground />}
    </ProductImageWrapper>
  )
}
