import React, { useState, useEffect, useContext, useMemo, FC } from 'react'

import { PriceViewWrapper, PriceViewItem } from './styles'
import { ProductsContext } from '../../stage-calculator'
import { IPriceViewProps } from './interface'
import { ComponentShadow } from '../component-shadow'

export const PriceView: FC<IPriceViewProps> = props => {
  const { data = { items: [] }, show = true, disabled = false } = props
  const { items } = data
  const { calculateAdditionalElementPrice, type, product, itemSize, filters } = useContext(
    ProductsContext,
  )

  const [price, setPrice] = useState(0)

  useEffect(() => {
    if (Array.isArray(items) && items.length > 0) {
      let result = 0

      items.forEach(key => {
        const itemPrice = calculateAdditionalElementPrice(key, type, product, itemSize, filters, [
          key,
        ])

        result += itemPrice
      })

      setPrice(result)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  const canShow = useMemo(() => {
    const result = price > 0

    return show && result
  }, [price, show])

  return (
    <>
      {canShow && (
        <PriceViewWrapper>
          {disabled && <ComponentShadow />}
          <PriceViewItem>+{price} ₽ к стоимости изделия</PriceViewItem>
        </PriceViewWrapper>
      )}
    </>
  )
}
