import styled, { css } from 'styled-components'

export const InputFileWrapper = styled.div`
  display: inline-block;

  position: relative;
`

export const InputFileStyle = styled.input<{ isError?: boolean; }>`
  background-image: none;
  box-sizing: border-box;
  margin: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  min-width: 0;
`

export const InputFileStatus = styled.span`
  position: absolute;

  top: calc(50% + 1px);
  right: 10px;

  font-size: 11px;
  line-height: 1.2;

  color: #ff453a;

  width: 21px;
  height: 20px;

  transform: translateY(-50%);

  svg {
    width: 14px;
    height: 14px;
  }
`

export const InputFileReq= styled.span`
  position: absolute;
  color: red;
  padding: 0px 0px 0px 8px;
  z-index: 4;
  font-size: 32px;
`
export const UploadWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 600px;
`