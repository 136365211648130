import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement42Square4TopCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-top-4-2_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M326.001 128C327.105 128 328 127.105 328 126.001C328 124.896 327.105 124.001 326.001 124.001L323.833 124.001L323.833 124.001L313 124.001L313 124L309.999 124C308.895 124 308 124.895 308 125.999C308 127.104 308.895 127.999 309.999 127.999L312.167 127.999L312.167 128L323 128L323 128L326.001 128Z"
            fill="url(#4-top-4-2_paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 323.834 128)"
          fill="#ABABAB"
        />
        <path
          d="M327.06 128C327.579 128 328 127.579 328 127.06L328 124.941C328 124.422 327.579 124.001 327.06 124.001L323 124.001L323 128L327.06 128Z"
          fill="url(#4-top-4-2_paint1_linear)"
        />
        <path
          d="M308.94 124C308.421 124 308 124.421 308 124.94L308 127.059C308 127.578 308.421 127.999 308.94 127.999L313 127.999L313 124L308.94 124Z"
          fill="url(#4-top-4-2_paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 322.167 128)"
          fill="url(#4-top-4-2_paint3_linear)"
        />
        <g filter="url(#4-top-4-2_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M404.001 128C405.105 128 406 127.105 406 126.001C406 124.896 405.105 124.001 404.001 124.001L401.833 124.001L401.833 124.001L391 124.001L391 124L387.999 124C386.895 124 386 124.895 386 125.999C386 127.104 386.895 127.999 387.999 127.999L390.167 127.999L390.167 128L401 128L401 128L404.001 128Z"
            fill="url(#4-top-4-2_paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 401.834 128)"
          fill="#ABABAB"
        />
        <path
          d="M405.06 128C405.579 128 406 127.579 406 127.06L406 124.941C406 124.422 405.579 124.001 405.06 124.001L401 124.001L401 128L405.06 128Z"
          fill="url(#4-top-4-2_paint5_linear)"
        />
        <path
          d="M386.94 124C386.421 124 386 124.421 386 124.94L386 127.059C386 127.578 386.421 127.999 386.94 127.999L391 127.999L391 124L386.94 124Z"
          fill="url(#4-top-4-2_paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 400.167 128)"
          fill="url(#4-top-4-2_paint7_linear)"
        />
        <g filter="url(#4-top-4-2_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 128H297V287H417V128ZM406 139H308V276H406V139Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 128H297V287H298V129H416V287H417V128Z"
          fill="#FBFBFB"
        />
        <rect
          x="301.53"
          y="132.53"
          width="110.94"
          height="149.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#4-top-4-2_filter3_i)">
          <rect
            x="308.47"
            y="139.47"
            width="97.0601"
            height="136.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="365"
          y="278"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 365 278)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="364.355"
          y="278.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 364.355 278.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M364.238 280.695C363.892 279.542 362.822 278.702 361.556 278.702L354.916 278.702C353.37 278.702 352.116 279.956 352.116 281.502C352.116 281.537 352.117 281.572 352.118 281.607C352.465 282.76 353.534 283.6 354.8 283.6L361.44 283.6C362.987 283.6 364.24 282.346 364.24 280.8C364.24 280.765 364.24 280.73 364.238 280.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M362.881 279.035C362.737 279.012 362.59 279 362.44 279L355.8 279C354.254 279 353 280.254 353 281.8C353 282.867 353.597 283.795 354.475 284.267C353.138 284.056 352.116 282.898 352.116 281.502C352.116 279.956 353.37 278.702 354.916 278.702L361.556 278.702C362.035 278.702 362.486 278.822 362.881 279.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-top-4-2_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M361 281.5C361 280.672 360.328 280 359.5 280L359.286 280L358 280L339.5 280C338.672 280 338 280.672 338 281.5C338 282.328 338.672 283 339.5 283L356 283L359.5 283L359.714 283C360.424 283 361 282.424 361 281.714C361 281.677 360.999 281.64 360.996 281.604C360.999 281.569 361 281.535 361 281.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M309.708 140.5L404.292 140.5L357 273.508L309.708 140.5Z" stroke="white" />
        <defs>
          <filter
            id="4-top-4-2_filter0_d"
            x="306.12"
            y="122.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-4-2_filter1_d"
            x="384.12"
            y="122.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-4-2_filter2_d"
            x="292.3"
            y="123.3"
            width="131.279"
            height="170.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-4-2_filter3_i"
            x="308"
            y="139"
            width="98.9399"
            height="139.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-top-4-2_filter4_d"
            x="336.28"
            y="278.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="4-top-4-2_paint0_linear"
            x1="310.5"
            y1="130.8"
            x2="310.5"
            y2="123.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="4-top-4-2_paint1_linear"
            x1="325.5"
            y1="121.202"
            x2="325.5"
            y2="128.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-4-2_paint2_linear"
            x1="310.5"
            y1="130.798"
            x2="310.5"
            y2="123.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-4-2_paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-4-2_paint4_linear"
            x1="388.5"
            y1="130.8"
            x2="388.5"
            y2="123.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="4-top-4-2_paint5_linear"
            x1="403.5"
            y1="121.202"
            x2="403.5"
            y2="128.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-4-2_paint6_linear"
            x1="388.5"
            y1="130.798"
            x2="388.5"
            y2="123.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-4-2_paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
