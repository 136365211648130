import React from 'react'
import { ArrowDownWrapper } from '../../views/calculator/styles'

export const ArrowDownIcon = ({ up = false }) => {
  return (
    <ArrowDownWrapper up={up}>
<svg width="37" height="50" viewBox="0 0 37 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.0007 48.256L16.9972 48.2517C14.4974 45.1276 10.4723 39.7849 7.07776 34.1719C5.38036 31.3652 3.85206 28.5095 2.7508 25.8428C1.64295 23.1601 1 20.7456 1 18.7986C1 8.95344 8.84993 1 18.5 1C28.1464 1 36 8.95358 36 18.7986C36 20.7443 35.3525 23.1579 34.2378 25.8408C33.1299 28.5076 31.5935 31.3634 29.8904 34.1707C26.485 39.7841 22.4595 45.1274 19.9983 48.2574C19.2132 49.248 17.7851 49.2475 17.0007 48.256ZM18.5 26.0649C22.4686 26.0649 25.6667 22.792 25.6667 18.7986C25.6667 14.8053 22.4686 11.5324 18.5 11.5324C14.5314 11.5324 11.3333 14.8053 11.3333 18.7986C11.3333 22.792 14.5314 26.0649 18.5 26.0649Z" fill="#EC4C41" stroke="white" stroke-width="2"/>
</svg>

    </ArrowDownWrapper>
  )
}

export default ArrowDownIcon