import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { InputStatus, InputStatus2, InputStatus3, InputStyle, InputWrapper, InputReq } from './styles'
import { IInputProps, InputTypes } from '../../views/calculator/interface'
import { validateEmail, validatePassword, validateRequiredText } from '../../utilits'
import { ShowIcon1 } from '../../assets/icons'
import { ShowIcon2 } from '../../assets/icons'
import MapSvg from '../../assets/icons/map-icon'

export const Input: FC<IInputProps> = props => {
  const {
    id = '',
    type = InputTypes.text,
    value: initValue = '',
    defaultValue = '',
    placeholder,
    name = '',
    req = false,
    disabled = false,
    errorMessage,
    onChange,
    onFocus,
    onBlur,
    onAffix = null
  } = props
  const [value, setValue] = useState<string | boolean>(defaultValue)
  const [error, setError] = useState('')
  const [firstLoad, setFirstLoad] = useState(true)
  const [isShowPassword, setShowPassword] = useState(false)
  const timerRef = useRef<any>(0)

  // console.log({ req, placeholder })

  useEffect(() => {
    //onChange && onChange(value, name)
    if (onChange && timerRef && !firstLoad) {
      //console.log({ value, timerRef,  })
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }

      timerRef.current = setTimeout(() => {
        onChange(value, name)
        timerRef.current = 0
      }, 300)
    }
    
    setFirstLoad(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, firstLoad])

  useEffect(() => {
    setValue(initValue)
  }, [initValue])

  useEffect(() => {
    if (errorMessage) {
      setError(errorMessage)
    }
  }, [errorMessage])

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      
      let result: string | boolean = event?.target?.value || ''

      if (type === InputTypes.checkbox) {
        result = event?.target?.checked
      }

      if (result !== value) {
        setValue(result)
      }
    },
    [type, value],
  )

  const inputProps = useMemo(() => {
    const result = {}

    if (id) {
      result['id'] = id
    }
    if (name) {
      result['name'] = name
    }
    if (placeholder) {
      result['placeholder'] = placeholder
    }

    return result
  }, [id, name, placeholder])

  const handleFocus = useCallback(() => {
    setError('')

    if (onFocus) {
      onFocus()
    }
  }, [onFocus])

  const handleBlur = useCallback(() => {
    let newError: string = ''

    if (onBlur) {
      onBlur()
    }

    if (type === InputTypes.email) {
      console.log('oks')
      newError = validateEmail(value)
    } else if (type === InputTypes.password) {
      newError = validatePassword(`${value}`)
    } else if (req) {
      newError = validateRequiredText(`${value}`)
    }

    if (newError) {
      setError(newError)
    }
  }, [onBlur, type, value, req])

  const localType = useMemo(() => {
    if (isShowPassword) {
      return InputTypes.text
    }
    return type
  }, [type, isShowPassword])

  return (
    <InputWrapper type={type}>
      <InputStyle
        {...inputProps}

        type={localType}
        value={`${value}`}
        defaultValue={defaultValue}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        isError={Boolean(error)}
        autoComplete="off"
        disabled={disabled}
      />
      {type === InputTypes.password && isShowPassword && (
        <InputStatus2 title={''} onClick={() => setShowPassword(false)}>
          <ShowIcon2  color="#000" />
        </InputStatus2>
      )}
      {type === InputTypes.password && !isShowPassword && (
        <InputStatus2 title={''} onClick={() => setShowPassword(true)}>
          <ShowIcon1 color="#000" />
        </InputStatus2>
      )}
      {onAffix && (
        <InputStatus3 title={''} onClick={() => onAffix?.()}>
          <MapSvg />
        </InputStatus3>
      )}
      {error && (
        <InputStatus title={error}>
          <span>{error}</span>
        </InputStatus>
      )}
    </InputWrapper>
  )
}
