import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement3Door1LCasement1 = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="451"
        height="451"
        viewBox="0 0 451 451"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#door-1-l-casement-1-3-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 384.999C32 383.895 31.1048 383 30.0005 383C28.8962 383 28.001 383.895 28.001 384.999V387.167H28.001V398H28V401.001C28 402.105 28.8952 403 29.9995 403C31.1038 403 31.999 402.105 31.999 401.001V398.833H32V388H32V384.999Z"
            fill="url(#door-1-l-casement-1-3-paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 32 387.167)"
          fill="#ABABAB"
        />
        <path
          d="M32 383.94C32 383.421 31.5792 383 31.0601 383H28.941C28.4219 383 28.001 383.421 28.001 383.94V388H32V383.94Z"
          fill="url(#door-1-l-casement-1-3-paint1_linear)"
        />
        <path
          d="M28 402.06C28 402.579 28.4208 403 28.9399 403H31.059C31.5781 403 31.999 402.579 31.999 402.06V398H28V402.06Z"
          fill="url(#door-1-l-casement-1-3-paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 32 388.833)"
          fill="url(#door-1-l-casement-1-3-paint3_linear)"
        />
        <g filter="url(#door-1-l-casement-1-3-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.954 50.9765C31.954 49.8849 31.0691 49 29.9775 49C28.886 49 28.0011 49.8849 28.0011 50.9765V53.1665H28.001V64H27.9999V67.0235C27.9999 68.1151 28.8848 69 29.9764 69C31.068 69 31.9529 68.1151 31.9529 67.0235V64.8332H31.954V54H31.954V50.9765Z"
            fill="url(#door-1-l-casement-1-3-paint4_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-1 0 0 1 31.954 53.1665)"
          fill="#ABABAB"
        />
        <path
          d="M31.954 49.9399C31.954 49.4208 31.5332 49 31.0141 49H28.941C28.4219 49 28.0011 49.4208 28.0011 49.9399V54H31.954V49.9399Z"
          fill="url(#door-1-l-casement-1-3-paint5_linear)"
        />
        <path
          d="M27.9999 68.0601C27.9999 68.5792 28.4208 69 28.9399 69H31.013C31.5321 69 31.9529 68.5792 31.9529 68.0601V64H27.9999V68.0601Z"
          fill="url(#door-1-l-casement-1-3-paint6_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-1 0 0 1 31.954 54.8334)"
          fill="url(#door-1-l-casement-1-3-paint7_linear)"
        />
        <g filter="url(#door-1-l-casement-1-3-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 34H172V419H32V34ZM48 50H156V293H48V50ZM157 312H48V403H157V312Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M172 34H32V419H33V35H171V419H172V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="36.53"
          y="38.53"
          width="130.94"
          height="375.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="48.47"
          y="312.47"
          width="108.06"
          height="90.0601"
          fill="#E8EAEE"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#door-1-l-casement-1-3-filter3_i)">
          <rect
            x="48.4691"
            y="50.47"
            width="107.06"
            height="242.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          width="6.91954"
          height="13.5283"
          rx="3.45977"
          transform="matrix(-1 0 0 1 167.92 209)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#door-1-l-casement-1-3-filter4_d)">
          <rect
            width="5.53563"
            height="12.2399"
            rx="2.76782"
            transform="matrix(-1 0 0 1 167.226 209.644)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M166.661 220.792C166.155 221.456 165.356 221.884 164.458 221.884C162.929 221.884 161.69 220.645 161.69 219.116V212.412C161.69 211.44 162.191 210.585 162.948 210.092C162.594 210.557 162.383 211.138 162.383 211.768V218.472C162.383 220.001 163.622 221.24 165.151 221.24C165.708 221.24 166.226 221.075 166.661 220.792Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M164.458 209.644C165.986 209.644 167.226 210.883 167.226 212.412V218.472C167.226 218.492 167.225 218.512 167.225 218.533V212.991C167.225 211.463 165.986 210.224 164.457 210.224C162.949 210.224 161.722 211.43 161.69 212.931V212.412C161.69 210.883 162.929 209.644 164.458 209.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#door-1-l-casement-1-3-filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M164.46 213C165.279 213 165.943 213.664 165.943 214.483V216C165.943 216.014 165.942 216.027 165.942 216.041L165.942 234.517C165.942 235.336 165.278 236 164.459 236C163.64 236 162.977 235.336 162.977 234.517L162.977 214.251C162.977 213.56 163.537 213 164.228 213C164.231 213 164.234 213 164.236 213C164.311 213 164.385 213 164.46 213Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M154.334 171.999L49.5 290.679V52.3295L154.334 171.999Z" stroke="white" />
        <defs>
          <filter
            id="door-1-l-casement-1-3-filter0_d"
            x="26.1201"
            y="381.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-1-3-filter1_d"
            x="26.1201"
            y="47.1201"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-1-3-filter2_d"
            x="27.3003"
            y="29.3003"
            width="151.279"
            height="396.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-1-3-filter3_i"
            x="47.9991"
            y="50"
            width="108.94"
            height="245.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="door-1-l-casement-1-3-filter4_d"
            x="159.81"
            y="207.764"
            width="11.1753"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-1-3-filter5_d"
            x="161.097"
            y="211.12"
            width="8.60556"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="door-1-l-casement-1-3-paint0_linear"
            x1="34.8"
            y1="400.5"
            x2="27.2"
            y2="400.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-1-3-paint1_linear"
            x1="25.2018"
            y1="385.5"
            x2="32.7998"
            y2="385.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-1-3-paint2_linear"
            x1="34.7982"
            y1="400.5"
            x2="27.2002"
            y2="400.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-1-3-paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-1-3-paint4_linear"
            x1="34.7218"
            y1="66.5"
            x2="27.2092"
            y2="66.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-1-3-paint5_linear"
            x1="25.234"
            y1="51.5"
            x2="32.7446"
            y2="51.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-1-3-paint6_linear"
            x1="34.72"
            y1="66.5"
            x2="27.2093"
            y2="66.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-1-3-paint7_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
