import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement12Square4VerticalCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#1-2_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M60.0235 32.954C61.1151 32.954 62 32.0691 62 30.9775C62 29.886 61.1151 29.0011 60.0235 29.0011L57.8335 29.0011L57.8335 29.001L47 29.001L47 28.9999L43.9765 28.9999C42.8849 28.9999 42 29.8848 42 30.9764C42 32.068 42.8849 32.9529 43.9765 32.9529L46.1668 32.9529L46.1668 32.954L57 32.954L57 32.954L60.0235 32.954Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 57.8335 32.954)"
          fill="#ABABAB"
        />
        <path
          d="M61.0601 32.954C61.5792 32.954 62 32.5332 62 32.0141L62 29.941C62 29.4219 61.5792 29.0011 61.0601 29.0011L57 29.0011L57 32.954L61.0601 32.954Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M42.9399 28.9999C42.4208 28.9999 42 29.4208 42 29.9399L42 32.013C42 32.5321 42.4208 32.9529 42.9399 32.9529L47 32.9529L47 28.9999L42.9399 28.9999Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 56.1666 32.954)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#1-2_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M176.024 32.954C177.115 32.954 178 32.0691 178 30.9775C178 29.886 177.115 29.0011 176.024 29.0011L173.833 29.0011L173.833 29.001L163 29.001L163 28.9999L159.976 28.9999C158.885 28.9999 158 29.8848 158 30.9764C158 32.068 158.885 32.9529 159.976 32.9529L162.167 32.9529L162.167 32.954L173 32.954L173 32.954L176.024 32.954Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 173.833 32.954)"
          fill="#ABABAB"
        />
        <path
          d="M177.06 32.954C177.579 32.954 178 32.5332 178 32.0141L178 29.941C178 29.4219 177.579 29.0011 177.06 29.0011L173 29.0011L173 32.954L177.06 32.954Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M158.94 28.9999C158.421 28.9999 158 29.4208 158 29.9399L158 32.013C158 32.5321 158.421 32.9529 158.94 32.9529L163 32.9529L163 28.9999L158.94 28.9999Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 172.167 32.954)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#1-2_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 33H32V88H188V33ZM178 43H42V78H178V43Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 33H32V88H33V34H187V88H188V33Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-2_filter3_i)">
          <rect
            x="42.47"
            y="43.47"
            width="135.06"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="37.53"
          width="146.94"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="116"
          y="80"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 116 80)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="115.356"
          y="80.7018"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 115.356 80.7018)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115.238 82.695C114.892 81.5419 113.822 80.7018 112.556 80.7018L105.916 80.7018C104.37 80.7018 103.116 81.9554 103.116 83.5018C103.116 83.537 103.117 83.572 103.118 83.6068C103.464 84.7599 104.534 85.6 105.8 85.6L112.44 85.6C113.986 85.6 115.24 84.3464 115.24 82.8C115.24 82.7649 115.239 82.7299 115.238 82.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.881 81.0346C113.737 81.0118 113.59 81 113.44 81L106.8 81C105.254 81 104 82.2536 104 83.8C104 84.8671 104.597 85.7947 105.475 86.2673C104.138 86.0558 103.116 84.8982 103.116 83.5018C103.116 81.9554 104.37 80.7018 105.916 80.7018L112.556 80.7018C113.035 80.7018 113.487 80.8223 113.881 81.0346Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-2_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M112 83.5C112 82.6716 111.328 82 110.5 82L110.286 82L109 82L90.5 82C89.6716 82 89 82.6716 89 83.5V83.5C89 84.3284 89.6716 85 90.5 85L107 85L110.5 85L110.714 85C111.424 85 112 84.4244 112 83.7143V83.7143C112 83.6771 111.999 83.6401 111.996 83.6035C111.999 83.5693 112 83.5348 112 83.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M174.853 44.5L110 76.4426L45.1468 44.5L174.853 44.5Z" stroke="white" />
        <defs>
          <filter
            id="1-2_filter0_d"
            x="40.1201"
            y="27.1201"
            width="25.6396"
            height="9.59374"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-2_filter1_d"
            x="156.12"
            y="27.1201"
            width="25.6396"
            height="9.59374"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-2_filter2_d"
            x="27.3003"
            y="28.3003"
            width="167.279"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-2_filter3_i"
            x="42"
            y="43"
            width="136.94"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="1-2_filter4_d"
            x="87.28"
            y="80.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="44.5"
            y1="35.7218"
            x2="44.5"
            y2="28.2092"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="59.5"
            y1="26.234"
            x2="59.5"
            y2="33.7446"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="44.5"
            y1="35.72"
            x2="44.5"
            y2="28.2093"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="160.5"
            y1="35.7218"
            x2="160.5"
            y2="28.2092"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="175.5"
            y1="26.234"
            x2="175.5"
            y2="33.7446"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="160.5"
            y1="35.72"
            x2="160.5"
            y2="28.2093"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
