import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement34Square62Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#3-4_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.0005 291C55.1048 291 56 290.105 56 289.001C56 287.896 55.1048 287.001 54.0005 287.001L51.8335 287.001L51.8335 287.001L41 287.001L41 287L37.9995 287C36.8952 287 36 287.895 36 288.999C36 290.104 36.8952 290.999 37.9995 290.999L40.1668 290.999L40.1668 291L51 291L51 291L54.0005 291Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 51.8335 291)"
          fill="#ABABAB"
        />
        <path
          d="M55.0601 291C55.5792 291 56 290.579 56 290.06L56 287.941C56 287.422 55.5792 287.001 55.0601 287.001L51 287.001L51 291L55.0601 291Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M36.9399 287C36.4208 287 36 287.421 36 287.94L36 290.059C36 290.578 36.4208 290.999 36.9399 290.999L41 290.999L41 287L36.9399 287Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 50.1666 291)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#3-4_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M99.0005 291C100.105 291 101 290.105 101 289.001C101 287.896 100.105 287.001 99.0005 287.001L96.8335 287.001L96.8335 287.001L86 287.001L86 287L82.9995 287C81.8952 287 81 287.895 81 288.999C81 290.104 81.8952 290.999 82.9995 290.999L85.1668 290.999L85.1668 291L96 291L96 291L99.0005 291Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 96.8335 291)"
          fill="#ABABAB"
        />
        <path
          d="M100.06 291C100.579 291 101 290.579 101 290.06L101 287.941C101 287.422 100.579 287.001 100.06 287.001L96 287.001L96 291L100.06 291Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M81.9399 287C81.4208 287 81 287.421 81 287.94L81 290.059C81 290.578 81.4208 290.999 81.9399 290.999L86 290.999L86 287L81.9399 287Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 95.1666 291)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#3-4_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M105 209H32V287H105V209ZM94 220H43V276H94V220Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105 209H32V287H33V210H104V287H105V209Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-4_filter3_i)">
          <rect
            x="43.47"
            y="220.47"
            width="50.0601"
            height="55.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="213.53"
          width="63.9399"
          height="68.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="75.5283"
          y="211"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 75.5283 211)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#3-4_filter4_d)">
          <rect
            x="74.8843"
            y="211.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 74.8843 211.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74.7663 213.695C74.4199 212.542 73.3502 211.702 72.0842 211.702L65.4443 211.702C63.8979 211.702 62.6443 212.955 62.6443 214.502C62.6443 214.537 62.645 214.572 62.6463 214.607C62.9927 215.76 64.0624 216.6 65.3283 216.6L71.9682 216.6C73.5146 216.6 74.7682 215.346 74.7682 213.8C74.7682 213.765 74.7675 213.73 74.7663 213.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.4093 212.034C73.2656 212.012 73.1183 212 72.9682 212L66.3283 212C64.7819 212 63.5283 213.253 63.5283 214.8C63.5283 215.867 64.1252 216.795 65.0033 217.267C63.6665 217.056 62.6444 215.898 62.6444 214.502C62.6444 212.955 63.898 211.702 65.4444 211.702L72.0843 211.702C72.5636 211.702 73.0148 211.822 73.4093 212.034Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-4_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M88.5283 214.5C88.5283 213.672 87.8567 213 87.0283 213L86.8141 213L85.5283 213L67.0283 213C66.1999 213 65.5283 213.672 65.5283 214.5V214.5C65.5283 215.328 66.1999 216 67.0283 216L83.5283 216L87.0283 216L87.2426 216C87.9527 216 88.5283 215.424 88.5283 214.714V214.714C88.5283 214.677 88.5271 214.64 88.5248 214.604C88.5271 214.569 88.5283 214.535 88.5283 214.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M44.7759 274.5L68.5 222.21L92.2241 274.5L44.7759 274.5Z" stroke="white" />
        <defs>
          <filter
            id="3-4_filter0_d"
            x="34.1201"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-4_filter1_d"
            x="79.1201"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-4_filter2_d"
            x="27.3003"
            y="204.3"
            width="84.2793"
            height="89.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-4_filter3_i"
            x="43"
            y="220"
            width="51.9399"
            height="58.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-4_filter4_d"
            x="60.7645"
            y="209.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-4_filter5_d"
            x="64.6485"
            y="211.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="38.5"
            y1="293.8"
            x2="38.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="53.5"
            y1="284.202"
            x2="53.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="38.5"
            y1="293.798"
            x2="38.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="83.5"
            y1="293.8"
            x2="83.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="98.5"
            y1="284.202"
            x2="98.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="83.5"
            y1="293.798"
            x2="83.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
