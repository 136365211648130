import React, { ReactNode } from 'react'
import { ButtonWrapper } from './styles'

interface IButton {
  children: ReactNode
  disabled?: boolean
  onClick?: any
  className?: string
}

function Button({ children, disabled, className, onClick, ...props }: IButton) {
  return (
      <ButtonWrapper disabled={disabled} onClick={onClick} className={className} {...props}>
        {children}
      </ButtonWrapper>
    )
}

export default Button