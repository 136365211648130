import React, { FC, useCallback, useMemo, useState, useEffect } from 'react'
import { useModal } from 'react-modal-hook'
import { Link } from 'react-router-dom'
import { sendCt } from 'src/lib/utils/ct'
import { ButtonViews, InputTypes, IViewPreferences3Props } from '../../../interface'
import {
  SelectBlock,
  StageTypeWrapper,
  SubTitle,
  ModalWrapper,
  ModalBody,
  CloseButton,
  ModalContent,
  StageContentBlock,
  StageContentMain,
  StageContentRowWrapper,
  StageDescriptionBlockPoint,
  StageDescriptionBlockPointText,
  StageDescriptionBlockTop,
  ModalTitle,
  ModalDescription,
} from '../../../styles'
import { PreviewBlock } from '../../components'
import { CloseIcon } from '../../../../../assets/icons'
import { fixBody, prepareOrderProducts } from '../../../../../utilits'
import { DescriptionRow } from './description-row'
import { Button, ControlGroup, Input, PhoneInput } from '../../../../../components'
import { getRows, totals } from './consts'
import { ButtonWrapper, RightConfirmWrapper } from '../../../registr/styles'
import { useRegister, useSendOrder } from '../../../../../hooks'
import { saveUserDataFromLocalStore, getUserDataFromLocalStore } from 'src/react-app/utilits/get-user-data'

export const ViewPreferences3: FC<IViewPreferences3Props> = props => {
  const {
    selected,
    currentGlassUnit,
    type,
    modelTitle,
    onFinish,
    price,
    calculateInProgress,
    products,
  } = props

  useEffect(() => {
   onFinish && onFinish()
  }, [])

  const [data, setData] = useState<Record<string, any>>({
    'first-name': '',
    phone: '',
    'right-confirm': false,
  })
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const { createOrder } = useSendOrder()
  const { isAuthorized, onFetchProfile } = useRegister()
  const [orderData, setOrderData] = useState<Record<string, any>>({})
  const { toggleFixBody } = fixBody()

  useEffect(() => {
    const getProfileData = async () => {
      //if (isAuthorized) {
        const profileData = await onFetchProfile()
        const localData = getUserDataFromLocalStore()
        const name = profileData?.['profile-data']?.['name'] || localData?.['name'] || ''
        const phone = profileData?.['profile-data']?.['phone'] || localData?.['phone'] || ''
 
        if (name || phone) {
          setData(() => ({
            'first-name': name,
            phone: phone,
          }))
        }
      //}
    }

    getProfileData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized])

  const handleCloseModal = useCallback(
    hideModal => () => {
      toggleFixBody()
      hideModal()
      setShowSuccessMessage(false)
    },
    [toggleFixBody],
  )

  const handleCall = useCallback(async () => {
    const { newProducts, transitionProducts } = prepareOrderProducts(products, [price], type)
    const glassUnit =
      products.length > 0 && products[0]['product'] && products[0]['product']['glass-unit-2']
        ? products[0]['product']['glass-unit-2']['description']
        : ''
    const print = [
      {
        ...transitionProducts[0],
        name: `${transitionProducts[0]['name']} ${glassUnit}`,
      },
    ]
    const totalCost = price ? price['total'] : 0

    const orderData = {
      stage: 'new',
      source: 'Вызов замерщика',
      // @ts-ignore
// eslint-disable-next-line
      location: window.location.href,
      'specification-products': {
        'transition-products': print,
        print,
        source: {
          type,
          products: newProducts,
          totalCost,
        },
      },
    }

    saveUserDataFromLocalStore({
      phone: data['phone'],
      name: data['first-name'],
    })

    const caseOrder = await createOrder(orderData, {
      phone: data['phone'],
      name: data['first-name'],
    })
    sendCt({ name: data['first-name'], phone: data['phone'] })
    /*
    const targetId = caseOrder?.['id']
    if (typeof targetId === 'number') {
      await fetchAPI('web-hooks/set-case-to-dealer', {method: 'POST', body: JSON.stringify({ 'target-id': targetId })} )
    }
    */
    if (caseOrder) {
      setOrderData(caseOrder)
    }
    setShowSuccessMessage(true)
  }, [createOrder, data, price, products, type])

  const handleChange = useCallback((value, key) => {
    setData(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }, [])

  const handleConfirm = useCallback(() => {
    setData(prevState => ({
      ...prevState,
      'right-confirm': !prevState['right-confirm'],
    }))
  }, [])

  const isCantCall = useMemo(() => {
    return !data['right-confirm'] || !data['phone']
  }, [data])

  const [showModal, hideModal] = useModal(
    ({ in: open }) => {
      return (
        <ModalWrapper show={open}>
          <ModalBody>
            <CloseButton onClick={handleCloseModal(hideModal)}>
              <CloseIcon />
            </CloseButton>
            <ModalTitle>Номер обращения</ModalTitle>
            <ModalContent>
              {showSuccessMessage && (
                <ModalDescription>
                  Благодарим за обращение №{orderData['id']}. В течение 30 минут менеджер заказа свяжется с Вами для
                  назначения времени встречи
                </ModalDescription>
              )}
              {!showSuccessMessage && (
                <>
                  <ControlGroup>
                    <Input
                      placeholder="Укажите Ваше имя"
                      name="first-name"
                      type={InputTypes.text}
                      onChange={handleChange}
                      value={data['first-name']}
                    />
                  </ControlGroup>
                  <ControlGroup>
                    <PhoneInput
                      id="phone"
                      placeholder="Контактный телефон"
                      name="phone"
                      onChange={handleChange}
                      value={data['phone']}
                    />
                  </ControlGroup>
                  <RightConfirmWrapper>
                    <Input
                      id="right-confirm"
                      type={InputTypes.checkbox}
                      name="right-confirm"
                      onChange={handleConfirm}
                    />
                    <label htmlFor="right-confirm">
                      Я согласен с правилами обработки{' '}
                      <Link to="/privacy-policy/" target="_blank">
                        персональных данных
                      </Link>
                    </label>
                  </RightConfirmWrapper>
                  <ButtonWrapper>
                    <Button
                      typeView={ButtonViews.ORANGE}
                      onClick={handleCall}
                      disabled={isCantCall}
                    >
                      Оформить заявку
                    </Button>
                  </ButtonWrapper>
                </>
              )}
            </ModalContent>
          </ModalBody>
        </ModalWrapper>
      )
    },
    [data, showSuccessMessage, isCantCall],
  )

  const handleOnlineConsult = useCallback(() => {
    showModal()
    toggleFixBody(true)
  }, [showModal, toggleFixBody])

  const baseModelData = useMemo(() => {
    const currentTotals = totals['model-1']

    return Object.keys(currentTotals).reduce((acc: Record<string, any>, key) => {
      const result = { ...acc }

      // eslint-disable-next-line prefer-destructuring
      result[key] = currentTotals[key][1]

      return result
    }, {})
  }, [])

  const currentData = useMemo(() => {
    const model = selected[`${type}-model`]
    const currentTotals = totals[model]

    return Object.keys(currentTotals).reduce((acc: Record<string, any>, key) => {
      const result = { ...acc }
      const selectedData = selected[key]

      if (key === 'sun-protection' && selectedData > 1) {
        const selectedValue = selected['heat-saving']

        result['energy-efficiency'] = currentTotals['energy-efficiency'][selectedValue]
        result[key] = currentTotals[key][selectedData]
      } else if (selectedData) {
        result[key] = currentTotals[key][selectedData]
      }

      return result
    }, {})
  }, [selected, type])

  const rows = useMemo(() => {
    return getRows(selected['sun-protection'])
  }, [selected])

  if (window) {
    // Пропускаем этот блок, сразу запускаем калькулятор
    return null
  }

  return (
    <StageTypeWrapper>
      <SelectBlock>
        <SubTitle>Мы подобрали для Вас оптимальную модель</SubTitle>
        <StageContentBlock>
          <StageContentMain>
            {/*<PreviewBlock
              glassUnit={currentGlassUnit}
              type={type}
              title={modelTitle}
              price={price}
              calculateInProgress={calculateInProgress}
              onFinish={onFinish}
              handleOnlineConsult={handleOnlineConsult}
              nonBorder
              bigView
            />*/}
            <StageContentRowWrapper>
              <StageDescriptionBlockTop>
                <div>
                  <StageDescriptionBlockPoint />
                  <StageDescriptionBlockPointText>Базовая</StageDescriptionBlockPointText>
                </div>
                <div>
                  <StageDescriptionBlockPoint view="current" />
                  <span>Выбранная для Вас модель</span>
                </div>
              </StageDescriptionBlockTop>
              {rows &&
                rows.map(row => (
                  <DescriptionRow
                    key={`description-row-${row['code']}`}
                    code={row['code']}
                    title={row['title']}
                    description={row['description']}
                    baseModel={baseModelData[row['code']]}
                    currentModel={currentData[row['code']]}
                    initCollapsed={false}
                  />
                ))}
            </StageContentRowWrapper>
          </StageContentMain>
        </StageContentBlock>
      </SelectBlock>
    </StageTypeWrapper>
  )
}
