import { IFormParam, RegisterFormKeys } from './interface'
import { InputTypes } from '../interface'
import { DateInput, Input, PhoneInput } from '../../../components'

export const formParams: IFormParam[] = [
  {
    name: RegisterFormKeys.LAST_NAME,
    placeholder: 'Введите фамилию',
    type: InputTypes.text,
    Component: Input,
    required: true,
  },
  {
    name: RegisterFormKeys.FIRST_NAME,
    placeholder: 'Введите имя',
    type: InputTypes.text,
    Component: Input,
    required: true,
  },
  {
    name: RegisterFormKeys.BIRTHDAY,
    placeholder: 'Введите дату рождения',
    type: InputTypes.text,
    Component: DateInput,
    required: false,
  },
  {
    name: RegisterFormKeys.EMAIL,
    placeholder: 'Введите e-mail',
    type: InputTypes.email,
    Component: Input,
    required: true,
  },
  {
    name: RegisterFormKeys.PHONE,
    placeholder: 'Введите номер телефона',
    type: InputTypes.text,
    Component: PhoneInput,
    required: true,
  },
]

export const formTitles = {
  [RegisterFormKeys.LAST_NAME]: 'Фамилия',
  [RegisterFormKeys.FIRST_NAME]: 'Имя',
  [RegisterFormKeys.BIRTHDAY]: 'Дата рождения',
  [RegisterFormKeys.EMAIL]: 'E-mail',
  [RegisterFormKeys.PHONE]: 'Номер телефона',
  [RegisterFormKeys.PASSWORD_CONFIRM]: 'Подтверждение пароля',
}

export const errorTitles = {
  'duplicate login': 'Такой пользователь уже зарегистрирован. Введите другой телефон',
  'Invalid password or user not found': 'Неверный СМС код',
}
