import React, { FC, useContext, useMemo } from 'react'
import { SubMenuAttributeTitle } from './styles'
import { ISubMenuAttributeProps } from './interface'
import { AttributeView } from '../attribute-view'
import { ProductsContext } from '../../stage-calculator'
import { Info } from '../../../../../components'
import { checkAttributeIsDisabled } from '../../../../../utilits'
import { ComponentShadow } from '../component-shadow'

export const SubMenuAttribute: FC<ISubMenuAttributeProps> = props => {
  const { attribute, data, price, onSelect, selected: importantSelected } = props

  const { selectedSubAttributeData: selected = {}, filters } = useContext(ProductsContext)

  const show = useMemo(() => {
    let result = false

    if (attribute['groupTargetValue'] && attribute['groupTargetKey'] && selected !== null) {
      if (
        selected[attribute['groupTargetKey']] &&
        selected[attribute['groupTargetKey']]['value'] === attribute['groupTargetValue']
      ) {
        result = true
      }
    } else {
      result = true
    }

    return result
  }, [attribute, selected])

  const isDisabled = useMemo(() => {
    let result = false

    if (attribute['disabledKey']) {
      result = checkAttributeIsDisabled(attribute['disabledKey'], filters)
    }

    return result
  }, [attribute, filters])


  return (
    <>
      {show && attribute.title && (
        <SubMenuAttributeTitle>
          {isDisabled && <ComponentShadow coord={{ bottom: '-14px' }} />}
          <span>{attribute.title}</span>
          {attribute.description && <Info color="#bbc3d6">{attribute.description}</Info>}
        </SubMenuAttributeTitle>
      )}
      {attribute.type && (
        <AttributeView
          {...attribute}
          menuData={data}
          onSelect={onSelect}
          price={price}
          importantSelected={importantSelected}
        />
      )}
      {attribute.type && attribute.id === 'mosquito-type-not-needed' && 
        <span style={{ marginTop: -20, marginBottom: 20, fontSize: 14 }}>
          На макете окна нажмите значок #, чтобы указать расположение москитной сетки
        </span>}
    </>
  )
}
