import styled from 'styled-components'

export const RadiobuttonWrapper = styled.div`
  margin-bottom: 25px;
`

export const RadiobuttonPrice = styled.span`
  font-size: 14px;
  font-weight: bold;
  line-height: 140%;
  color: #333333;
`

export const RadiobuttonBlock = styled.div`
  display: flex;
`

export const RadiobuttonLabel = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding-right: 34px;
  padding-bottom: 14px;

  color: #333333;

  cursor: pointer;
`

export const RadiobuttonButton = styled.input`
  height: 18px;
  width: 18px;

  margin-right: 11px;

  cursor: pointer;
`

export const RadiobuttonText = styled.span`
  font-size: 16px;
  line-height: 140%;
`
