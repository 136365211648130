import React, { FC, useEffect, useState, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'

import { Calculator } from '../../views/calculator'
import { ICalculatorPageProps } from './interface'
import {
  CalculatorStage,
  CalculatorType,
  CalculatorTypePremises,
  QuizView,
} from '../../views/calculator/interface'

export const CalculatorPage: FC<ICalculatorPageProps> = ({
  clearView = false,
  isDraftEdit = false,
  isCart = false,
}) => {
  const location = useLocation()
  const [productType, setProductType] = useState<CalculatorType | null>(null)
  const [premisesType, setPremisesType] = useState<CalculatorTypePremises | null>(null)
  const [initGlassUnit, setInitGlassUnit] = useState('')
  const [initModel, setInitModel] = useState('model-1')
  const [stage, setStage] = useState(CalculatorStage.SELECT_TYPE)
  const [id, setId] = useState<null | number>(null)
  const [quizStep, setQuizStep] = useState<QuizView>()

  useEffect(() => {
    if (isDraftEdit) {
      setStage(CalculatorStage.CALCULATE)
    }
    if (isCart) {
      setStage(CalculatorStage.CART)
    }
  }, [isCart, isDraftEdit])

  useEffect(() => {
    const parsedQuery = qs.parse(location['search'])

    if ('quiz-step' in parsedQuery) {
      setQuizStep(parsedQuery['quiz-step'] as QuizView)
    }

    if ('product-type' in parsedQuery) {
      setProductType(parsedQuery['product-type'] as CalculatorType)
    }

    if ('premises-type' in parsedQuery) {
      setPremisesType(parsedQuery['premises-type'] as CalculatorTypePremises)
    }

    if ('stage' in parsedQuery) {
      setStage(parsedQuery['stage'] as CalculatorStage)
    }

    if ('glass-unit-2' in parsedQuery) {
      setInitGlassUnit(parsedQuery['glass-unit-2'] as string)
    }

    if ('window-model' in parsedQuery) {
      setInitModel(parsedQuery['window-model'] as string)
    }

    if ('id' in parsedQuery && parsedQuery['id']) {
      setId(Number(parsedQuery['id']))
    }
  }, [location])

  const handleDropSettings = useCallback(() => {
    setQuizStep(QuizView.SELECT_TYPE)
  }, [])

  return (
    <Calculator
      clearView={clearView}
      productType={productType}
      premisesType={premisesType}
      initGlassUnit={initGlassUnit}
      initModel={initModel}
      stage={stage}
      id={id}
      quizStep={quizStep}
      isDraft={isDraftEdit}
      dropSettings={handleDropSettings}
    />
  )
}
