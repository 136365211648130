import React from 'react'
import { StageDescriptionBlockContentItemIcon } from 'src/react-app/views/calculator/styles'

export const HeatSaving4 = () => {
  return (
    <StageDescriptionBlockContentItemIcon title="Теплая приоконная зона">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.6"
          d="M32.5 40H7.5C3.3575 40 0 36.6425 0 32.5V7.5C0 3.3575 3.3575 0 7.5 0H32.5C36.6425 0 40 3.3575 40 7.5V32.5C40 36.6425 36.6425 40 32.5 40Z"
          fill="#E0E3E9"
        />
        <path
          d="M25.8316 14.1663H24.9978C24.5378 14.1663 24.1641 13.7925 24.1641 13.3325C24.1641 12.8725 24.5378 12.5 24.9978 12.5H25.8316C26.2916 12.5 26.6653 12.8737 26.6653 13.3337C26.6653 13.7937 26.2916 14.1663 25.8316 14.1663Z"
          fill="#1C3775"
        />
        <path
          d="M25.8316 17.5H24.9978C24.5378 17.5 24.1641 17.1263 24.1641 16.6663C24.1641 16.2063 24.5378 15.8325 24.9978 15.8325H25.8316C26.2916 15.8325 26.6653 16.2063 26.6653 16.6663C26.6653 17.1263 26.2916 17.5 25.8316 17.5Z"
          fill="#1C3775"
        />
        <path
          d="M25.8316 20.8337H24.9978C24.5378 20.8337 24.1641 20.46 24.1641 20C24.1641 19.54 24.5378 19.1662 24.9978 19.1662H25.8316C26.2916 19.1662 26.6653 19.54 26.6653 20C26.6653 20.46 26.2916 20.8337 25.8316 20.8337Z"
          fill="#1C3775"
        />
        <path
          d="M22.4988 21.8837V12.9163C22.4988 11.3088 21.19 10 19.5825 10C17.975 10 16.6663 11.3088 16.6663 12.9163V21.8837C15.6163 22.75 15 24.0475 15 25.4163C14.9988 27.9438 17.055 30 19.5825 30C22.11 30 24.1663 27.9438 24.1663 25.4163C24.1663 24.0475 23.5488 22.7513 22.4988 21.8837ZM19.5825 27.5C18.4338 27.5 17.4988 26.5662 17.4988 25.4163C17.4988 24.5638 18.0138 23.8313 18.7488 23.51V15C18.7488 14.54 19.1225 14.1663 19.5825 14.1663C20.0425 14.1663 20.4163 14.54 20.4163 15V23.51C21.1513 23.8325 21.6663 24.565 21.6663 25.4163C21.6663 26.5662 20.7313 27.5 19.5825 27.5Z"
          fill="#1C3775"
        />
      </svg>
    </StageDescriptionBlockContentItemIcon>
  )
}
