import React, {useCallback, useMemo, FC, useState} from 'react'
import InputMask from 'react-input-mask'
import {InputWrapper, InputReq} from './styles'
import {InputStatus} from '../input'
import {IPhoneInputProps} from '../../views/calculator/interface'
import {SuccessIcon} from '../../assets/icons'
import {validatePhone} from '../../utilits'

const normalizePhone = (value: string | number): string | number => {
  if (typeof value !== 'string') {
    return value
  }

  return value.replace(/[-_() ]/g, '')
}

const formatPhone = (value: string | number): string | number => {
  const formatString = '__ (___)-___-____'

  if (typeof value !== 'string') {
    return value
  }

  return Array.prototype.reduce.call(
    normalizePhone(value),
    (acc: any, symbol) => acc.replace('_', symbol),
    formatString,
  ) as string
}

export const PhoneInput: FC<IPhoneInputProps> = props => {
  const {value, onChange, id, name, placeholder, success, disabled = false, req = false} = props
  const valueFormat = useMemo(() => formatPhone(value), [value])
  const [error, setError] = useState('')

  const inputProps = useMemo(() => {
    const result = {}

    if (id) {
      result['id'] = id
    }
    if (name) {
      result['name'] = name
    }
    if (placeholder) {
      result['placeholder'] = placeholder
    }

    return result
  }, [id, name, placeholder])

  const handleChange = useCallback(
    ({target}) => {
      if (onChange && onChange.constructor === Function) {
        onChange(normalizePhone(target.value), name)
      }
    },
    [name, onChange],
  )

  const handleFocus = useCallback(() => {
    setError('')
  }, [])

  const handleBlur = useCallback(() => {
    let newError: string = ''

    newError = validatePhone(value)

    if (newError) {
      setError(newError)
    }
  }, [value, valueFormat])

  return (
    <InputWrapper disabled={disabled} isError={Boolean(error)}>
      {req && <InputReq>*</InputReq>}
      <InputMask
        {...inputProps}
        mask="+7 (999) 999-99-99"
        onChange={handleChange}
        value={valueFormat}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      {success && (
        <InputStatus title={success}>
          <SuccessIcon/>
        </InputStatus>
      )}
      {error && (
        <InputStatus title={error}>
          <span>{error}</span>
        </InputStatus>
      )}
    </InputWrapper>
  )
}
