import React from 'react'
import { StageDescriptionBlockContentItemIcon } from 'src/react-app/views/calculator/styles'

export const Soundproofing2 = () => {
  return (
    <StageDescriptionBlockContentItemIcon title="Для окон выходящих на автостраду">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32.5 40H7.5C3.3575 40 0 36.6425 0 32.5V7.5C0 3.3575 3.3575 0 7.5 0H32.5C36.6425 0 40 3.3575 40 7.5V32.5C40 36.6425 36.6425 40 32.5 40Z"
          fill="#ECEEF2"
        />
        <path
          d="M29.8762 18.7067L28.9109 17.0267C28.9109 17 28.8861 16.9467 28.8861 16.92L27.5495 11.5867C27.4505 11.24 27.1782 11 26.8317 11H13.4158C13.0693 11 12.797 11.24 12.698 11.5867L11.3614 16.92L10.1485 18.6267C10.0495 18.76 10 18.9467 10 19.1333V25.1867C10 25.64 10.3218 25.9867 10.7426 25.9867H10.9901V28.2C10.9901 28.6533 11.3119 29 11.7327 29H15.3465C15.7673 29 16.0891 28.6533 16.0891 28.2V25.9867H23.9109V28.2C23.9109 28.6533 24.2327 29 24.6535 29H28.2673C28.6881 29 29.0099 28.6533 29.0099 28.2V25.9867H29.2574C29.6782 25.9867 30 25.64 30 25.1867V19.1333C30 18.9733 29.9505 18.84 29.8762 18.7067ZM15.9158 22.44C15.9158 22.8933 15.5941 23.24 15.1733 23.24H12.8713C12.4505 23.24 12.1287 22.8933 12.1287 22.44V20.7333C12.1287 20.28 12.4505 19.9333 12.8713 19.9333H15.1733C15.5941 19.9333 15.9158 20.28 15.9158 20.7333V22.44ZM22.302 22.2533H17.698V20.6533H22.302V22.2533ZM13.0446 16.3333L13.9851 12.6H26.2624L27.203 16.3333H13.0446ZM27.8713 22.44C27.8713 22.8933 27.5495 23.24 27.1287 23.24H24.8267C24.4059 23.24 24.0842 22.8933 24.0842 22.44V20.7333C24.0842 20.28 24.4059 19.9333 24.8267 19.9333H27.1535C27.5743 19.9333 27.896 20.28 27.896 20.7333L27.8713 22.44Z"
          fill="#1C3775"
        />
      </svg>
    </StageDescriptionBlockContentItemIcon>
  )
}
