import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement24Square32Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-4_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M176.999 30C175.895 30 175 30.8952 175 31.9995C175 33.1038 175.895 33.999 176.999 33.999L179.167 33.999L179.167 33.999L190 33.999L190 34L193.001 34C194.105 34 195 33.1048 195 32.0005C195 30.8962 194.105 30.001 193.001 30.001L190.833 30.001L190.833 30L180 30L180 30L176.999 30Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 179.166 30)"
          fill="#ABABAB"
        />
        <path
          d="M175.94 30C175.421 30 175 30.4208 175 30.9399L175 33.059C175 33.5781 175.421 33.999 175.94 33.999L180 33.999L180 30L175.94 30Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M194.06 34C194.579 34 195 33.5792 195 33.0601L195 30.941C195 30.4219 194.579 30.001 194.06 30.001L190 30.001L190 34L194.06 34Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 180.833 30)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#2-4_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M254.999 30C253.895 30 253 30.8952 253 31.9995C253 33.1038 253.895 33.999 254.999 33.999L257.167 33.999L257.167 33.999L268 33.999L268 34L271.001 34C272.105 34 273 33.1048 273 32.0005C273 30.8962 272.105 30.001 271.001 30.001L268.833 30.001L268.833 30L258 30L258 30L254.999 30Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 257.166 30)"
          fill="#ABABAB"
        />
        <path
          d="M253.94 30C253.421 30 253 30.4208 253 30.9399L253 33.059C253 33.5781 253.421 33.999 253.94 33.999L258 33.999L258 30L253.94 30Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M272.06 34C272.579 34 273 33.5792 273 33.0601L273 30.941C273 30.4219 272.579 30.001 272.06 30.001L268 30.001L268 34L272.06 34Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 258.833 30)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#2-4_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M284 34H164V287H284V34ZM273 45H175V276H273V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M284 34H164V287L164.887 287V34.937H283.109V287L284 287V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="168.53"
          y="38.53"
          width="110.94"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#2-4_filter3_i)">
          <rect
            x="175.47"
            y="45.47"
            width="97.0601"
            height="230.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M224 275L272 46H176L224 275ZM224 270.665L270.893 46.9453H177.107L224 270.665Z"
          fill="white"
        />
        <rect
          x="232"
          y="278"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 232 278)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="231.355"
          y="278.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 231.355 278.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M231.238 280.695C230.892 279.542 229.822 278.702 228.556 278.702L221.916 278.702C220.37 278.702 219.116 279.955 219.116 281.502C219.116 281.537 219.117 281.572 219.118 281.607C219.465 282.76 220.534 283.6 221.8 283.6L228.44 283.6C229.987 283.6 231.24 282.346 231.24 280.8C231.24 280.765 231.24 280.73 231.238 280.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M229.881 279.035C229.737 279.012 229.59 279 229.44 279L222.8 279C221.254 279 220 280.254 220 281.8C220 282.867 220.597 283.795 221.475 284.267C220.138 284.056 219.116 282.898 219.116 281.502C219.116 279.955 220.37 278.702 221.916 278.702L228.556 278.702C229.035 278.702 229.486 278.822 229.881 279.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-4_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M228 281.5C228 280.672 227.328 280 226.5 280L226.286 280L225 280L206.5 280C205.672 280 205 280.672 205 281.5C205 282.328 205.672 283 206.5 283L223 283L226.5 283L226.714 283C227.424 283 228 282.424 228 281.714C228 281.677 227.999 281.64 227.996 281.604C227.999 281.569 228 281.535 228 281.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="2-4_filter0_d"
            x="173.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-4_filter1_d"
            x="251.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-4_filter2_d"
            x="159.3"
            y="29.3003"
            width="131.279"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-4_filter3_i"
            x="175"
            y="45"
            width="98.9399"
            height="233.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-4_filter4_d"
            x="203.28"
            y="278.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="192.5"
            y1="27.2"
            x2="192.5"
            y2="34.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="177.5"
            y1="36.7982"
            x2="177.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="192.5"
            y1="27.2018"
            x2="192.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="270.5"
            y1="27.2"
            x2="270.5"
            y2="34.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="255.5"
            y1="36.7982"
            x2="255.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="270.5"
            y1="27.2018"
            x2="270.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
