import React, { FC, useCallback, useMemo, useState } from 'react'
import { Input } from '../input'
import { useSuggestionsAddress } from '../../hooks'
import { ISuggestProps } from '../../views/calculator/interface'
import { InputTitle, SuggestItem, SuggestList, SuggestWrapper } from './styles'
import DeliverySelectAddressMap from '../modals/delivery-select-address-map'
import useNextModal from '../../modals'

export const Suggest: FC<ISuggestProps> = props => {
  const {
    onChange,
    name,
    suggestKey,
    valueKey,
    queryPrefix,
    defaultValue,
    value: newValue,
    title,
    placeholder,
    fromBound,
    suggestFilter,
  } = props
  const { createModal } = useNextModal()
  const { onSuggest, suggestData } = useSuggestionsAddress(fromBound, suggestKey)
  const [isShow, setIsShow] = useState(false)
  const value = useMemo(() => newValue['street'], [newValue])

  const getOnSuggestValue = useCallback(
    (item: Record<string, any>) => {
      let result = item['data']['city_with_type'] || item['data']['settlement_with_type']
      if (valueKey) {
        valueKey.some(key => {
          const data = item['data'][key]

          if (data) {
            result = data
          }

          return data
        })
      }

      return result
    },
    [valueKey],
  )

  const handleChange = useCallback(
    (newValue, key) => {
      const suggestQuery: string[] = []
      if (queryPrefix) {
        suggestQuery.push(queryPrefix)
      }

      suggestQuery.push(newValue)
      onChange({ value: newValue }, key)
      onSuggest(suggestQuery.join(' '), suggestFilter)
    },
    [onChange, onSuggest, queryPrefix, suggestFilter],
  )

  const handleFocus = useCallback(() => {
    setIsShow(true)
  }, [])

  const handleBlur = useCallback(() => {
    setTimeout(() => setIsShow(false), 300)
  }, [])

  const handleItemClick = useCallback(
    (item: Record<string, any>) => () => {
      console.log('handleItemClick - item - ', item)
      const city = getOnSuggestValue(item)

      onChange(item, name)
      onSuggest(city, suggestFilter)
    },
    [getOnSuggestValue, name, onChange, onSuggest, suggestFilter],
  )

  const onAffix = useCallback(async () => {
    const result = await createModal(DeliverySelectAddressMap, newValue)
    if (result?.stageCode === 'ok') {
      const value = result?.['formData']
      const nextValue = {
        value: value?.['value'] || '',
        data: {
          lat: value?.['lat'] || 0,
          lng: value?.['lng'] || 0,
          city: value?.['city'] || '',
          city_with_type: value?.['city'] || '',
          settlement_with_type: value?.['city'] || '',
          street: value?.['street'] || '',
          geo_lat: value?.['lat'] || 0,
          geo_lon: value?.['lng'] || 0,
        },
      }
      onChange(nextValue, name)
    }
  }, [onChange, name, newValue])

  return (
    <SuggestWrapper>
      <Input
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        id={name}
        name={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        onAffix={onAffix}
      />
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <InputTitle>{title}</InputTitle>
        <InputTitle
          style={{ textDecoration: 'underline #32557f dashed', cursor: 'pointer' }}
          onClick={onAffix}
        >
          {'Показать на карте'}
        </InputTitle>
      </div>
      {suggestData.length > 0 && (
        <SuggestList isShow={isShow}>
          {suggestData.map(suggest => (
            <SuggestItem key={suggest.value} onClick={handleItemClick(suggest)}>
              {suggest.unrestricted_value}
            </SuggestItem>
          ))}
        </SuggestList>
      )}
    </SuggestWrapper>
  )
}
