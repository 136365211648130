import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement24Square6Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-4_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M271.001 122C272.105 122 273 121.105 273 120.001C273 118.896 272.105 118.001 271.001 118.001L268.833 118.001L268.833 118.001L258 118.001L258 118L254.999 118C253.895 118 253 118.895 253 119.999C253 121.104 253.895 121.999 254.999 121.999L257.167 121.999L257.167 122L268 122L268 122L271.001 122Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 268.834 122)"
          fill="#ABABAB"
        />
        <path
          d="M272.06 122C272.579 122 273 121.579 273 121.06L273 118.941C273 118.422 272.579 118.001 272.06 118.001L268 118.001L268 122L272.06 122Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M253.94 118C253.421 118 253 118.421 253 118.94L253 121.059C253 121.578 253.421 121.999 253.94 121.999L258 121.999L258 118L253.94 118Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 267.167 122)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#2-4_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M193.001 122C194.105 122 195 121.105 195 120.001C195 118.896 194.105 118.001 193.001 118.001L190.833 118.001L190.833 118.001L180 118.001L180 118L176.999 118C175.895 118 175 118.895 175 119.999C175 121.104 175.895 121.999 176.999 121.999L179.167 121.999L179.167 122L190 122L190 122L193.001 122Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 190.834 122)"
          fill="#ABABAB"
        />
        <path
          d="M194.06 122C194.579 122 195 121.579 195 121.06L195 118.941C195 118.422 194.579 118.001 194.06 118.001L190 118.001L190 122L194.06 122Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M175.94 118C175.421 118 175 118.421 175 118.94L175 121.059C175 121.578 175.421 121.999 175.94 121.999L180 121.999L180 118L175.94 118Z"
          fill="url(#paint2-4_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 189.167 122)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#2-4_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M284 34H164V118H284V34ZM273 45H175V107H273V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M284 34H164V118H165V35H283V118H284V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="168.53"
          y="38.53"
          width="110.94"
          height="74.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#2-4_filter3_i)">
          <rect
            x="175.47"
            y="45.47"
            width="97.0601"
            height="61.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <path d="M270.96 105.5L177.04 105.5L224 46.8004L270.96 105.5Z" stroke="white" />
        <rect
          x="230.528"
          y="36"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 230.528 36)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#2-4_filter4_d)">
          <rect
            x="229.884"
            y="36.7021"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 229.884 36.7021)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M229.767 38.6953C229.42 37.5422 228.351 36.7021 227.085 36.7021L220.445 36.7021C218.898 36.7021 217.645 37.9558 217.645 39.5021C217.645 39.5373 217.645 39.5723 217.647 39.6072C217.993 40.7602 219.063 41.6003 220.329 41.6003L226.969 41.6003C228.515 41.6003 229.769 40.3467 229.769 38.8003C229.769 38.7652 229.768 38.7302 229.767 38.6953Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M228.409 37.0349C228.266 37.0121 228.118 37.0003 227.968 37.0003L221.328 37.0003C219.782 37.0003 218.528 38.2539 218.528 39.8003C218.528 40.8674 219.125 41.795 220.003 42.2676C218.666 42.0561 217.644 40.8985 217.644 39.5021C217.644 37.9558 218.898 36.7021 220.444 36.7021L227.084 36.7021C227.564 36.7021 228.015 36.8226 228.409 37.0349Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-4_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M243.528 39.5C243.528 38.6716 242.857 38 242.028 38L241.814 38L240.528 38L222.028 38C221.2 38 220.528 38.6716 220.528 39.5C220.528 40.3284 221.2 41 222.028 41L238.528 41L242.028 41L242.243 41C242.953 41 243.528 40.4244 243.528 39.7143C243.528 39.6771 243.527 39.6401 243.525 39.6035C243.527 39.5693 243.528 39.5348 243.528 39.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="2-4_filter0_d"
            x="251.12"
            y="116.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-4_filter1_d"
            x="173.12"
            y="116.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-4_filter2_d"
            x="159.3"
            y="29.3003"
            width="131.279"
            height="95.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-4_filter3_i"
            x="175"
            y="45"
            width="98.9399"
            height="64.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-4_filter4_d"
            x="215.764"
            y="34.8223"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-4_filter5_d"
            x="219.649"
            y="36.1201"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="255.5"
            y1="124.8"
            x2="255.5"
            y2="117.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="270.5"
            y1="115.202"
            x2="270.5"
            y2="122.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="255.5"
            y1="124.798"
            x2="255.5"
            y2="117.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="177.5"
            y1="124.8"
            x2="177.5"
            y2="117.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="192.5"
            y1="115.202"
            x2="192.5"
            y2="122.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2-4_linear"
            x1="177.5"
            y1="124.798"
            x2="177.5"
            y2="117.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
