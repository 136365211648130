import React, { FC } from 'react'
import styled from 'styled-components'

const Svg = styled.svg`
  shape-rendering: geometricPrecision;
  text-rendering: geometricPrecision;
  image-rendering: optimizeQuality;
  pointer-events: all;
`

export const EditIcon: FC<{ color?: string; onClick?: () => void }> = ({
  color = '#1C3775',
  onClick = () => null,
}) => {
  return (
    <Svg
      onClick={onClick}
      xmlSpace="preserve"
      version="1.1"
      width="16px"
      height="16px"
      viewBox="0 0 193 365"
      x="0px"
      y="0px"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <g>
        <polygon fill={color} points="107,40 0,227 4,292 63,263 170,76 " />
        <polygon fill={color} points="130,0 115,27 177,63 193,36 " />
      </g>
    </Svg>
  )
}


