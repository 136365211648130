import React, { FC } from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'
import { IContainerSquareProps } from '../../interface'
import { ContainerBackground } from '../container-background'

export const ContainerSquare6Casement: FC<IContainerSquareProps> = props => {
  const { onCasementClick, withoutBackground } = props

  return (
    <ProductImageWrapper isClickable>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <rect width="449" height="333" fill="white" />
        <rect x="24" y="28" width="401" height="264" fill="#E8EAEE" />
        <rect x="24" y="28" width="401" height="264" fill="url(#6-0-pattern0)" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M439 10H10V292H24V29H425V292H439V10Z"
          fill="#C4C4C4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M439 292H10V308H0V316H10H439H449V308H439V292Z"
          fill="url(#6-0-paint0_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23 28H425V292H23V28ZM36 133H148V279H36V133ZM149 41H36V113H149V41ZM168 41H281V113H168V41ZM413 41H300V113H413V41ZM168 133H281V279H168V133ZM412 133H300V279H412V133Z"
          fill="#E8EAEE"
        />
        <path d="M439 10L425 28.0571V292.075L439 308V10Z" fill="#E9EBEF" />
        <path d="M439 10L425 28.0571V292.075L439 308V10Z" fill="#020202" fillOpacity="0.06" />
        <path d="M10 10L24 28.1525V291.982L10 308V10Z" fill="#E9EBEF" />
        <path d="M10 10L24 28.1525V291.982L10 308V10Z" fill="#020202" fillOpacity="0.06" />
        <g
          filter="url(#6-0-filter0_i)"
          className="casement"
          data-index="5"
          onClick={onCasementClick(5)}
        >
          <rect x="300" y="133" width="112" height="146" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="300.47"
          y="133.47"
          width="111.06"
          height="145.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#6-0-filter1_i)"
          className="casement"
          data-index="4"
          onClick={onCasementClick(4)}
        >
          <rect x="168" y="133" width="113" height="146" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="168.47"
          y="133.47"
          width="112.06"
          height="145.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#6-0-filter2_i)"
          className="casement"
          data-index="3"
          onClick={onCasementClick(3)}
        >
          <rect x="36" y="133" width="113" height="146" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="36.47"
          y="133.47"
          width="112.06"
          height="145.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <path
          d="M100.985 157.741H98.4473C97.8003 157.741 97.2529 157.235 97.2529 156.636V156.405C97.2529 155.807 97.8003 155.3 98.4473 155.3H100.985C101.632 155.3 102.18 155.807 102.18 156.405V156.636C102.18 157.258 101.657 157.741 100.985 157.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M83.4262 160.565C83.7247 160.565 83.7993 160.565 84.1972 160.565C84.9432 160.565 85.4903 161.105 85.4903 161.833C85.4903 162.584 84.9184 163.101 84.1474 163.101C82.705 163.101 81.8098 163.101 80.3674 163.101C79.8203 163.101 79.3727 162.913 79.1488 162.42C78.9499 162.115 78.8255 161.27 79.6213 160.565C80.1436 159.978 85.9131 153.757 86.9327 152.677C86.9824 152.606 86.9327 152.512 86.8581 152.512C86.8332 152.512 86.8332 152.512 86.8084 152.512C84.6448 152.512 82.4812 152.512 80.3177 152.512C79.6711 152.512 79.1737 152.137 79.0494 151.573C78.9002 150.916 79.1986 150.352 79.7706 150.094C79.9446 150.023 80.1187 150 80.2928 150C83.5008 150 86.684 150 89.8921 150C90.5138 150 90.9366 150.188 91.1852 150.728C91.5085 151.409 91.1604 151.878 90.9366 152.183C90.4889 152.723 85.938 157.56 83.4262 160.283C83.4014 160.307 83.3765 160.354 83.3268 160.377C83.277 160.448 83.3268 160.565 83.4262 160.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M90.1854 163.101H87.6475C87.0005 163.101 86.4531 162.582 86.4531 161.968V161.732C86.4531 161.119 87.0005 160.6 87.6475 160.6H90.1854C90.8323 160.6 91.3797 161.119 91.3797 161.732V161.968C91.3797 162.605 90.8323 163.101 90.1854 163.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M93.7158 156.515C93.7158 154.734 93.7158 152.976 93.7158 151.195C93.7158 150.492 94.2379 150 94.9836 150C98.2152 150 101.447 150 104.678 150C105.424 150 105.946 150.516 105.946 151.242C105.946 151.969 105.424 152.508 104.678 152.508C101.994 152.508 99.3339 152.508 96.6491 152.508C96.4503 152.508 96.4005 152.555 96.4005 152.742C96.4005 155.273 96.4005 157.804 96.4005 160.335C96.4005 160.546 96.4751 160.57 96.6491 160.57C99.309 160.57 101.994 160.57 104.654 160.57C105.25 160.57 105.723 160.898 105.897 161.39C106.095 161.976 105.872 162.562 105.399 162.89C105.176 163.03 104.902 163.101 104.629 163.101C101.447 163.101 98.2401 163.101 95.0582 163.101C94.2378 163.101 93.7407 162.585 93.7407 161.812C93.7158 160.031 93.7158 158.273 93.7158 156.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M80.7054 168.042V168.699H79V168.042H79.4263V166.259H79.0502V165.602H80.6803V166.259H80.3041V168.042H80.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M84.5346 168.758H83.774L83.6513 168.545C83.3814 168.687 83.0624 168.758 82.7679 168.758C81.9336 168.734 81.1484 168.167 81.1484 167.15C81.1484 166.181 81.811 165.542 82.817 165.542C83.6513 165.542 84.461 166.086 84.461 167.15C84.461 167.529 84.3629 167.812 84.2156 168.072L84.5592 168.64V168.758H84.5346ZM82.7925 168.002C83.1851 168.002 83.6022 167.718 83.6022 167.15C83.6022 166.583 83.1851 166.299 82.7925 166.299C82.3753 166.299 81.9827 166.583 81.9827 167.15C81.9827 167.718 82.3753 168.002 82.7925 168.002Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M86.7838 167.749C86.7838 167.96 87.0294 168.077 87.2995 168.077C87.5451 168.077 87.7907 167.96 87.7907 167.796C87.7907 167.491 87.4223 167.491 87.2013 167.467C86.6119 167.42 86.0226 167.209 86.0226 166.552C86.0226 165.871 86.6119 165.542 87.275 165.542C87.8889 165.542 88.5274 165.871 88.5274 166.552H87.717C87.717 166.341 87.496 166.247 87.275 166.247C87.0785 166.247 86.8329 166.294 86.8329 166.528C86.8329 166.74 87.0294 166.786 87.3241 166.81C88.0117 166.857 88.601 167.021 88.601 167.772C88.601 168.43 87.938 168.758 87.275 168.758C86.5874 168.758 85.9243 168.43 85.9489 167.749H86.7838Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M91.2285 166.311H90.0374V166.76H91.1292V167.469H90.0374V167.966H91.2533V168.699H89.1689V165.602H91.2036V166.311H91.2285Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M94.6027 168.58V168.699H93.7218L93.159 167.729H92.7186V168.699H91.8867V165.602C92.3761 165.602 92.841 165.602 93.3059 165.602C94.0888 165.602 94.4559 166.169 94.4559 166.713C94.4559 167.068 94.2846 167.422 93.9665 167.611L94.6027 168.58ZM93.3059 167.068C93.5505 167.068 93.6484 166.878 93.6484 166.666C93.6484 166.477 93.5261 166.288 93.3059 166.288H92.7186V167.068H93.3059Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M96.2757 167.334L96.5525 166.528L96.9551 165.602H97.8863V165.721L96.4518 168.758H96.0995L94.665 165.721V165.602H95.5962L96.024 166.528L96.2757 167.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M99.7815 168.042V168.699H98.0762V168.042H98.5025V166.259H98.1263V165.602H99.7565V166.259H99.3803V168.042H99.7815Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M101.835 165.602C102.509 165.602 103.258 165.955 103.383 166.78H102.559C102.434 166.45 102.185 166.332 101.835 166.332C101.336 166.332 101.011 166.662 101.011 167.18C101.011 167.628 101.336 168.028 101.835 168.028C102.185 168.028 102.484 167.863 102.559 167.533H103.383C103.258 168.405 102.534 168.758 101.835 168.758C100.986 168.758 100.162 168.216 100.162 167.18C100.162 166.12 100.986 165.602 101.835 165.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M105.944 166.311H104.753V166.76H105.845V167.469H104.753V167.966H105.969V168.699H103.885V165.602H105.919V166.311H105.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M232.985 157.741H230.447C229.8 157.741 229.253 157.235 229.253 156.636V156.405C229.253 155.807 229.8 155.3 230.447 155.3H232.985C233.632 155.3 234.18 155.807 234.18 156.405V156.636C234.18 157.258 233.657 157.741 232.985 157.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M215.426 160.565C215.725 160.565 215.799 160.565 216.197 160.565C216.943 160.565 217.49 161.105 217.49 161.833C217.49 162.584 216.918 163.101 216.147 163.101C214.705 163.101 213.81 163.101 212.367 163.101C211.82 163.101 211.373 162.913 211.149 162.42C210.95 162.115 210.826 161.27 211.621 160.565C212.144 159.978 217.913 153.757 218.933 152.677C218.982 152.606 218.933 152.512 218.858 152.512C218.833 152.512 218.833 152.512 218.808 152.512C216.645 152.512 214.481 152.512 212.318 152.512C211.671 152.512 211.174 152.137 211.049 151.573C210.9 150.916 211.199 150.352 211.771 150.094C211.945 150.023 212.119 150 212.293 150C215.501 150 218.684 150 221.892 150C222.514 150 222.937 150.188 223.185 150.728C223.509 151.409 223.16 151.878 222.937 152.183C222.489 152.723 217.938 157.56 215.426 160.283C215.401 160.307 215.376 160.354 215.327 160.377C215.277 160.448 215.327 160.565 215.426 160.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M222.185 163.101H219.647C219.001 163.101 218.453 162.582 218.453 161.968V161.732C218.453 161.119 219.001 160.6 219.647 160.6H222.185C222.832 160.6 223.38 161.119 223.38 161.732V161.968C223.38 162.605 222.832 163.101 222.185 163.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M225.716 156.515C225.716 154.734 225.716 152.976 225.716 151.195C225.716 150.492 226.238 150 226.984 150C230.215 150 233.447 150 236.678 150C237.424 150 237.946 150.516 237.946 151.242C237.946 151.969 237.424 152.508 236.678 152.508C233.994 152.508 231.334 152.508 228.649 152.508C228.45 152.508 228.401 152.555 228.401 152.742C228.401 155.273 228.401 157.804 228.401 160.335C228.401 160.546 228.475 160.57 228.649 160.57C231.309 160.57 233.994 160.57 236.654 160.57C237.25 160.57 237.723 160.898 237.897 161.39C238.095 161.976 237.872 162.562 237.399 162.89C237.176 163.03 236.902 163.101 236.629 163.101C233.447 163.101 230.24 163.101 227.058 163.101C226.238 163.101 225.741 162.585 225.741 161.812C225.716 160.031 225.716 158.273 225.716 156.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M212.705 168.042V168.699H211V168.042H211.426V166.259H211.05V165.602H212.68V166.259H212.304V168.042H212.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M216.535 168.758H215.774L215.651 168.545C215.381 168.687 215.062 168.758 214.768 168.758C213.934 168.734 213.148 168.167 213.148 167.15C213.148 166.181 213.811 165.542 214.817 165.542C215.651 165.542 216.461 166.086 216.461 167.15C216.461 167.529 216.363 167.812 216.216 168.072L216.559 168.64V168.758H216.535ZM214.792 168.002C215.185 168.002 215.602 167.718 215.602 167.15C215.602 166.583 215.185 166.299 214.792 166.299C214.375 166.299 213.983 166.583 213.983 167.15C213.983 167.718 214.375 168.002 214.792 168.002Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M218.784 167.749C218.784 167.96 219.029 168.077 219.3 168.077C219.545 168.077 219.791 167.96 219.791 167.796C219.791 167.491 219.422 167.491 219.201 167.467C218.612 167.42 218.023 167.209 218.023 166.552C218.023 165.871 218.612 165.542 219.275 165.542C219.889 165.542 220.527 165.871 220.527 166.552H219.717C219.717 166.341 219.496 166.247 219.275 166.247C219.079 166.247 218.833 166.294 218.833 166.528C218.833 166.74 219.029 166.786 219.324 166.81C220.012 166.857 220.601 167.021 220.601 167.772C220.601 168.43 219.938 168.758 219.275 168.758C218.587 168.758 217.924 168.43 217.949 167.749H218.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M223.228 166.311H222.037V166.76H223.129V167.469H222.037V167.966H223.253V168.699H221.169V165.602H223.204V166.311H223.228Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M226.603 168.58V168.699H225.722L225.159 167.729H224.719V168.699H223.887V165.602C224.376 165.602 224.841 165.602 225.306 165.602C226.089 165.602 226.456 166.169 226.456 166.713C226.456 167.068 226.285 167.422 225.966 167.611L226.603 168.58ZM225.306 167.068C225.551 167.068 225.648 166.878 225.648 166.666C225.648 166.477 225.526 166.288 225.306 166.288H224.719V167.068H225.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M228.276 167.334L228.552 166.528L228.955 165.602H229.886V165.721L228.452 168.758H228.099L226.665 165.721V165.602H227.596L228.024 166.528L228.276 167.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M231.782 168.042V168.699H230.076V168.042H230.503V166.259H230.126V165.602H231.756V166.259H231.38V168.042H231.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M233.835 165.602C234.509 165.602 235.258 165.955 235.383 166.78H234.559C234.434 166.45 234.185 166.332 233.835 166.332C233.336 166.332 233.011 166.662 233.011 167.18C233.011 167.628 233.336 168.028 233.835 168.028C234.185 168.028 234.484 167.863 234.559 167.533H235.383C235.258 168.405 234.534 168.758 233.835 168.758C232.986 168.758 232.162 168.216 232.162 167.18C232.162 166.12 232.986 165.602 233.835 165.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M237.944 166.311H236.753V166.76H237.845V167.469H236.753V167.966H237.969V168.699H235.885V165.602H237.919V166.311H237.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M364.985 157.741H362.447C361.8 157.741 361.253 157.235 361.253 156.636V156.405C361.253 155.807 361.8 155.3 362.447 155.3H364.985C365.632 155.3 366.18 155.807 366.18 156.405V156.636C366.18 157.258 365.657 157.741 364.985 157.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M347.426 160.565C347.725 160.565 347.799 160.565 348.197 160.565C348.943 160.565 349.49 161.105 349.49 161.833C349.49 162.584 348.918 163.101 348.147 163.101C346.705 163.101 345.81 163.101 344.367 163.101C343.82 163.101 343.373 162.913 343.149 162.42C342.95 162.115 342.826 161.27 343.621 160.565C344.144 159.978 349.913 153.757 350.933 152.677C350.982 152.606 350.933 152.512 350.858 152.512C350.833 152.512 350.833 152.512 350.808 152.512C348.645 152.512 346.481 152.512 344.318 152.512C343.671 152.512 343.174 152.137 343.049 151.573C342.9 150.916 343.199 150.352 343.771 150.094C343.945 150.023 344.119 150 344.293 150C347.501 150 350.684 150 353.892 150C354.514 150 354.937 150.188 355.185 150.728C355.509 151.409 355.16 151.878 354.937 152.183C354.489 152.723 349.938 157.56 347.426 160.283C347.401 160.307 347.376 160.354 347.327 160.377C347.277 160.448 347.327 160.565 347.426 160.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M354.185 163.101H351.647C351.001 163.101 350.453 162.582 350.453 161.968V161.732C350.453 161.119 351.001 160.6 351.647 160.6H354.185C354.832 160.6 355.38 161.119 355.38 161.732V161.968C355.38 162.605 354.832 163.101 354.185 163.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M357.716 156.515C357.716 154.734 357.716 152.976 357.716 151.195C357.716 150.492 358.238 150 358.984 150C362.215 150 365.447 150 368.678 150C369.424 150 369.946 150.516 369.946 151.242C369.946 151.969 369.424 152.508 368.678 152.508C365.994 152.508 363.334 152.508 360.649 152.508C360.45 152.508 360.401 152.555 360.401 152.742C360.401 155.273 360.401 157.804 360.401 160.335C360.401 160.546 360.475 160.57 360.649 160.57C363.309 160.57 365.994 160.57 368.654 160.57C369.25 160.57 369.723 160.898 369.897 161.39C370.095 161.976 369.872 162.562 369.399 162.89C369.176 163.03 368.902 163.101 368.629 163.101C365.447 163.101 362.24 163.101 359.058 163.101C358.238 163.101 357.741 162.585 357.741 161.812C357.716 160.031 357.716 158.273 357.716 156.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M344.705 168.042V168.699H343V168.042H343.426V166.259H343.05V165.602H344.68V166.259H344.304V168.042H344.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M348.535 168.758H347.774L347.651 168.545C347.381 168.687 347.062 168.758 346.768 168.758C345.934 168.734 345.148 168.167 345.148 167.15C345.148 166.181 345.811 165.542 346.817 165.542C347.651 165.542 348.461 166.086 348.461 167.15C348.461 167.529 348.363 167.812 348.216 168.072L348.559 168.64V168.758H348.535ZM346.792 168.002C347.185 168.002 347.602 167.718 347.602 167.15C347.602 166.583 347.185 166.299 346.792 166.299C346.375 166.299 345.983 166.583 345.983 167.15C345.983 167.718 346.375 168.002 346.792 168.002Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M350.784 167.749C350.784 167.96 351.029 168.077 351.3 168.077C351.545 168.077 351.791 167.96 351.791 167.796C351.791 167.491 351.422 167.491 351.201 167.467C350.612 167.42 350.023 167.209 350.023 166.552C350.023 165.871 350.612 165.542 351.275 165.542C351.889 165.542 352.527 165.871 352.527 166.552H351.717C351.717 166.341 351.496 166.247 351.275 166.247C351.079 166.247 350.833 166.294 350.833 166.528C350.833 166.74 351.029 166.786 351.324 166.81C352.012 166.857 352.601 167.021 352.601 167.772C352.601 168.43 351.938 168.758 351.275 168.758C350.587 168.758 349.924 168.43 349.949 167.749H350.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M355.228 166.311H354.037V166.76H355.129V167.469H354.037V167.966H355.253V168.699H353.169V165.602H355.204V166.311H355.228Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M358.603 168.58V168.699H357.722L357.159 167.729H356.719V168.699H355.887V165.602C356.376 165.602 356.841 165.602 357.306 165.602C358.089 165.602 358.456 166.169 358.456 166.713C358.456 167.068 358.285 167.422 357.966 167.611L358.603 168.58ZM357.306 167.068C357.551 167.068 357.648 166.878 357.648 166.666C357.648 166.477 357.526 166.288 357.306 166.288H356.719V167.068H357.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M360.276 167.334L360.552 166.528L360.955 165.602H361.886V165.721L360.452 168.758H360.099L358.665 165.721V165.602H359.596L360.024 166.528L360.276 167.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M363.782 168.042V168.699H362.076V168.042H362.503V166.259H362.126V165.602H363.756V166.259H363.38V168.042H363.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M365.835 165.602C366.509 165.602 367.258 165.955 367.383 166.78H366.559C366.434 166.45 366.185 166.332 365.835 166.332C365.336 166.332 365.011 166.662 365.011 167.18C365.011 167.628 365.336 168.028 365.835 168.028C366.185 168.028 366.484 167.863 366.559 167.533H367.383C367.258 168.405 366.534 168.758 365.835 168.758C364.986 168.758 364.162 168.216 364.162 167.18C364.162 166.12 364.986 165.602 365.835 165.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M369.944 166.311H368.753V166.76H369.845V167.469H368.753V167.966H369.969V168.699H367.885V165.602H369.919V166.311H369.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <rect y="316" width="449" height="17" fill="white" />
        <g
          filter="url(#6-0-filter3_i)"
          className="casement"
          data-index="0"
          onClick={onCasementClick(0)}
        >
          <rect x="36" y="41" width="113" height="72" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="36.47"
          y="41.47"
          width="112.06"
          height="71.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#6-0-filter4_i)"
          className="casement"
          data-index="1"
          onClick={onCasementClick(1)}
        >
          <rect x="168" y="41" width="113" height="72" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="168.47"
          y="41.47"
          width="112.06"
          height="71.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#6-0-filter5_i)"
          className="casement"
          data-index="2"
          onClick={onCasementClick(2)}
        >
          <rect x="300" y="41" width="113" height="72" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="300.47"
          y="41.47"
          width="112.06"
          height="71.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <path
          d="M101.985 65.7413H99.4473C98.8003 65.7413 98.2529 65.2346 98.2529 64.6357V64.4054C98.2529 63.8065 98.8003 63.2998 99.4473 63.2998H101.985C102.632 63.2998 103.18 63.8065 103.18 64.4054V64.6357C103.18 65.2576 102.657 65.7413 101.985 65.7413Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M84.4262 68.5652C84.7247 68.5652 84.7993 68.5652 85.1972 68.5652C85.9432 68.5652 86.4903 69.1052 86.4903 69.833C86.4903 70.5843 85.9184 71.1008 85.1474 71.1008C83.705 71.1008 82.8098 71.1008 81.3674 71.1008C80.8203 71.1008 80.3727 70.913 80.1488 70.4199C79.9499 70.1147 79.8255 69.2695 80.6213 68.5652C81.1436 67.9782 86.9131 61.7565 87.9327 60.6765C87.9824 60.6061 87.9327 60.5122 87.8581 60.5122C87.8332 60.5122 87.8332 60.5122 87.8084 60.5122C85.6448 60.5122 83.4812 60.5122 81.3177 60.5122C80.6711 60.5122 80.1737 60.1365 80.0494 59.573C79.9002 58.9156 80.1986 58.3522 80.7706 58.0939C80.9446 58.0235 81.1187 58 81.2928 58C84.5008 58 87.684 58 90.8921 58C91.5138 58 91.9366 58.1878 92.1852 58.7278C92.5085 59.4087 92.1604 59.8783 91.9366 60.1835C91.4889 60.7235 86.938 65.56 84.4262 68.2834C84.4014 68.3069 84.3765 68.3539 84.3268 68.3773C84.277 68.4478 84.3268 68.5652 84.4262 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M91.1854 71.1007H88.6475C88.0005 71.1007 87.4531 70.5816 87.4531 69.9681V69.7322C87.4531 69.1187 88.0005 68.5996 88.6475 68.5996H91.1854C91.8323 68.5996 92.3797 69.1187 92.3797 69.7322V69.9681C92.3797 70.6052 91.8323 71.1007 91.1854 71.1007Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M94.7158 64.5152C94.7158 62.7341 94.7158 60.9764 94.7158 59.1952C94.7158 58.4922 95.2379 58 95.9836 58C99.2152 58 102.447 58 105.678 58C106.424 58 106.946 58.5156 106.946 59.2421C106.946 59.9686 106.424 60.5077 105.678 60.5077C102.994 60.5077 100.334 60.5077 97.6491 60.5077C97.4503 60.5077 97.4005 60.5545 97.4005 60.742C97.4005 63.2731 97.4005 65.8042 97.4005 68.3353C97.4005 68.5463 97.4751 68.5697 97.6491 68.5697C100.309 68.5697 102.994 68.5697 105.654 68.5697C106.25 68.5697 106.723 68.8978 106.897 69.39C107.095 69.9759 106.872 70.5618 106.399 70.8899C106.176 71.0305 105.902 71.1008 105.629 71.1008C102.447 71.1008 99.2401 71.1008 96.0582 71.1008C95.2378 71.1008 94.7407 70.5852 94.7407 69.8118C94.7158 68.0307 94.7158 66.273 94.7158 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M81.7054 76.0418V76.6986H80V76.0418H80.4263V74.2589H80.0502V73.6021H81.6803V74.2589H81.3041V76.0418H81.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M85.5346 76.7581H84.774L84.6513 76.5453C84.3814 76.6872 84.0624 76.7581 83.7679 76.7581C82.9336 76.7345 82.1484 76.167 82.1484 75.1503C82.1484 74.1809 82.811 73.5425 83.817 73.5425C84.6513 73.5425 85.461 74.0863 85.461 75.1503C85.461 75.5286 85.3629 75.8124 85.2156 76.0724L85.5592 76.6399V76.7581H85.5346ZM83.7925 76.0015C84.1851 76.0015 84.6022 75.7178 84.6022 75.1503C84.6022 74.5828 84.1851 74.2991 83.7925 74.2991C83.3753 74.2991 82.9827 74.5828 82.9827 75.1503C82.9827 75.7178 83.3753 76.0015 83.7925 76.0015Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M87.7838 75.7488C87.7838 75.9601 88.0294 76.0774 88.2995 76.0774C88.5451 76.0774 88.7907 75.9601 88.7907 75.7958C88.7907 75.4906 88.4223 75.4906 88.2013 75.4672C87.6119 75.4202 87.0226 75.209 87.0226 74.5518C87.0226 73.8711 87.6119 73.5425 88.275 73.5425C88.8889 73.5425 89.5274 73.8711 89.5274 74.5518H88.717C88.717 74.3405 88.496 74.2466 88.275 74.2466C88.0785 74.2466 87.8329 74.2936 87.8329 74.5283C87.8329 74.7395 88.0294 74.7865 88.3241 74.81C89.0117 74.8569 89.601 75.0212 89.601 75.7723C89.601 76.4295 88.938 76.7581 88.275 76.7581C87.5874 76.7581 86.9243 76.4295 86.9489 75.7488H87.7838Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M92.2285 74.3112H91.0374V74.7603H92.1292V75.4694H91.0374V75.9658H92.2533V76.6986H90.1689V73.6021H92.2036V74.3112H92.2285Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M95.6027 76.5804V76.6986H94.7218L94.159 75.7295H93.7186V76.6986H92.8867V73.6021C93.3761 73.6021 93.841 73.6021 94.3059 73.6021C95.0888 73.6021 95.4559 74.1694 95.4559 74.713C95.4559 75.0676 95.2846 75.4222 94.9665 75.6113L95.6027 76.5804ZM94.3059 75.0676C94.5505 75.0676 94.6484 74.8785 94.6484 74.6658C94.6484 74.4766 94.5261 74.2875 94.3059 74.2875H93.7186V75.0676H94.3059Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M97.2757 75.3343L97.5525 74.5275L97.9551 73.6021H98.8863V73.7207L97.4518 76.7582H97.0995L95.665 73.7207V73.6021H96.5962L97.024 74.5275L97.2757 75.3343Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M100.782 76.0418V76.6986H99.0762V76.0418H99.5025V74.2589H99.1263V73.6021H100.756V74.2589H100.38V76.0418H100.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M102.835 73.6021C103.509 73.6021 104.258 73.9553 104.383 74.7797H103.559C103.434 74.45 103.185 74.3322 102.835 74.3322C102.336 74.3322 102.011 74.6619 102.011 75.1801C102.011 75.6276 102.336 76.028 102.835 76.028C103.185 76.028 103.484 75.8631 103.559 75.5334H104.383C104.258 76.4049 103.534 76.7582 102.835 76.7582C101.986 76.7582 101.162 76.2164 101.162 75.1801C101.162 74.1202 101.986 73.6021 102.835 73.6021Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M106.944 74.3112H105.753V74.7603H106.845V75.4694H105.753V75.9658H106.969V76.6986H104.885V73.6021H106.919V74.3112H106.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M233.985 65.7413H231.447C230.8 65.7413 230.253 65.2346 230.253 64.6357V64.4054C230.253 63.8065 230.8 63.2998 231.447 63.2998H233.985C234.632 63.2998 235.18 63.8065 235.18 64.4054V64.6357C235.18 65.2576 234.657 65.7413 233.985 65.7413Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M216.426 68.5652C216.725 68.5652 216.799 68.5652 217.197 68.5652C217.943 68.5652 218.49 69.1052 218.49 69.833C218.49 70.5843 217.918 71.1008 217.147 71.1008C215.705 71.1008 214.81 71.1008 213.367 71.1008C212.82 71.1008 212.373 70.913 212.149 70.4199C211.95 70.1147 211.826 69.2695 212.621 68.5652C213.144 67.9782 218.913 61.7565 219.933 60.6765C219.982 60.6061 219.933 60.5122 219.858 60.5122C219.833 60.5122 219.833 60.5122 219.808 60.5122C217.645 60.5122 215.481 60.5122 213.318 60.5122C212.671 60.5122 212.174 60.1365 212.049 59.573C211.9 58.9156 212.199 58.3522 212.771 58.0939C212.945 58.0235 213.119 58 213.293 58C216.501 58 219.684 58 222.892 58C223.514 58 223.937 58.1878 224.185 58.7278C224.509 59.4087 224.16 59.8783 223.937 60.1835C223.489 60.7235 218.938 65.56 216.426 68.2834C216.401 68.3069 216.376 68.3539 216.327 68.3773C216.277 68.4478 216.327 68.5652 216.426 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M223.185 71.1007H220.647C220.001 71.1007 219.453 70.5816 219.453 69.9681V69.7322C219.453 69.1187 220.001 68.5996 220.647 68.5996H223.185C223.832 68.5996 224.38 69.1187 224.38 69.7322V69.9681C224.38 70.6052 223.832 71.1007 223.185 71.1007Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M226.716 64.5152C226.716 62.7341 226.716 60.9764 226.716 59.1952C226.716 58.4922 227.238 58 227.984 58C231.215 58 234.447 58 237.678 58C238.424 58 238.946 58.5156 238.946 59.2421C238.946 59.9686 238.424 60.5077 237.678 60.5077C234.994 60.5077 232.334 60.5077 229.649 60.5077C229.45 60.5077 229.401 60.5545 229.401 60.742C229.401 63.2731 229.401 65.8042 229.401 68.3353C229.401 68.5463 229.475 68.5697 229.649 68.5697C232.309 68.5697 234.994 68.5697 237.654 68.5697C238.25 68.5697 238.723 68.8978 238.897 69.39C239.095 69.9759 238.872 70.5618 238.399 70.8899C238.176 71.0305 237.902 71.1008 237.629 71.1008C234.447 71.1008 231.24 71.1008 228.058 71.1008C227.238 71.1008 226.741 70.5852 226.741 69.8118C226.716 68.0307 226.716 66.273 226.716 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M213.705 76.0418V76.6986H212V76.0418H212.426V74.2589H212.05V73.6021H213.68V74.2589H213.304V76.0418H213.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M217.535 76.7581H216.774L216.651 76.5453C216.381 76.6872 216.062 76.7581 215.768 76.7581C214.934 76.7345 214.148 76.167 214.148 75.1503C214.148 74.1809 214.811 73.5425 215.817 73.5425C216.651 73.5425 217.461 74.0863 217.461 75.1503C217.461 75.5286 217.363 75.8124 217.216 76.0724L217.559 76.6399V76.7581H217.535ZM215.792 76.0015C216.185 76.0015 216.602 75.7178 216.602 75.1503C216.602 74.5828 216.185 74.2991 215.792 74.2991C215.375 74.2991 214.983 74.5828 214.983 75.1503C214.983 75.7178 215.375 76.0015 215.792 76.0015Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M219.784 75.7488C219.784 75.9601 220.029 76.0774 220.3 76.0774C220.545 76.0774 220.791 75.9601 220.791 75.7958C220.791 75.4906 220.422 75.4906 220.201 75.4672C219.612 75.4202 219.023 75.209 219.023 74.5518C219.023 73.8711 219.612 73.5425 220.275 73.5425C220.889 73.5425 221.527 73.8711 221.527 74.5518H220.717C220.717 74.3405 220.496 74.2466 220.275 74.2466C220.079 74.2466 219.833 74.2936 219.833 74.5283C219.833 74.7395 220.029 74.7865 220.324 74.81C221.012 74.8569 221.601 75.0212 221.601 75.7723C221.601 76.4295 220.938 76.7581 220.275 76.7581C219.587 76.7581 218.924 76.4295 218.949 75.7488H219.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M224.228 74.3112H223.037V74.7603H224.129V75.4694H223.037V75.9658H224.253V76.6986H222.169V73.6021H224.204V74.3112H224.228Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M227.603 76.5804V76.6986H226.722L226.159 75.7295H225.719V76.6986H224.887V73.6021C225.376 73.6021 225.841 73.6021 226.306 73.6021C227.089 73.6021 227.456 74.1694 227.456 74.713C227.456 75.0676 227.285 75.4222 226.966 75.6113L227.603 76.5804ZM226.306 75.0676C226.551 75.0676 226.648 74.8785 226.648 74.6658C226.648 74.4766 226.526 74.2875 226.306 74.2875H225.719V75.0676H226.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M229.276 75.3343L229.552 74.5275L229.955 73.6021H230.886V73.7207L229.452 76.7582H229.099L227.665 73.7207V73.6021H228.596L229.024 74.5275L229.276 75.3343Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M232.782 76.0418V76.6986H231.076V76.0418H231.503V74.2589H231.126V73.6021H232.756V74.2589H232.38V76.0418H232.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M234.835 73.6021C235.509 73.6021 236.258 73.9553 236.383 74.7797H235.559C235.434 74.45 235.185 74.3322 234.835 74.3322C234.336 74.3322 234.011 74.6619 234.011 75.1801C234.011 75.6276 234.336 76.028 234.835 76.028C235.185 76.028 235.484 75.8631 235.559 75.5334H236.383C236.258 76.4049 235.534 76.7582 234.835 76.7582C233.986 76.7582 233.162 76.2164 233.162 75.1801C233.162 74.1202 233.986 73.6021 234.835 73.6021Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M238.944 74.3112H237.753V74.7603H238.845V75.4694H237.753V75.9658H238.969V76.6986H236.885V73.6021H238.919V74.3112H238.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M365.985 65.7413H363.447C362.8 65.7413 362.253 65.2346 362.253 64.6357V64.4054C362.253 63.8065 362.8 63.2998 363.447 63.2998H365.985C366.632 63.2998 367.18 63.8065 367.18 64.4054V64.6357C367.18 65.2576 366.657 65.7413 365.985 65.7413Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M348.426 68.5652C348.725 68.5652 348.799 68.5652 349.197 68.5652C349.943 68.5652 350.49 69.1052 350.49 69.833C350.49 70.5843 349.918 71.1008 349.147 71.1008C347.705 71.1008 346.81 71.1008 345.367 71.1008C344.82 71.1008 344.373 70.913 344.149 70.4199C343.95 70.1147 343.826 69.2695 344.621 68.5652C345.144 67.9782 350.913 61.7565 351.933 60.6765C351.982 60.6061 351.933 60.5122 351.858 60.5122C351.833 60.5122 351.833 60.5122 351.808 60.5122C349.645 60.5122 347.481 60.5122 345.318 60.5122C344.671 60.5122 344.174 60.1365 344.049 59.573C343.9 58.9156 344.199 58.3522 344.771 58.0939C344.945 58.0235 345.119 58 345.293 58C348.501 58 351.684 58 354.892 58C355.514 58 355.937 58.1878 356.185 58.7278C356.509 59.4087 356.16 59.8783 355.937 60.1835C355.489 60.7235 350.938 65.56 348.426 68.2834C348.401 68.3069 348.376 68.3539 348.327 68.3773C348.277 68.4478 348.327 68.5652 348.426 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M355.185 71.1007H352.647C352.001 71.1007 351.453 70.5816 351.453 69.9681V69.7322C351.453 69.1187 352.001 68.5996 352.647 68.5996H355.185C355.832 68.5996 356.38 69.1187 356.38 69.7322V69.9681C356.38 70.6052 355.832 71.1007 355.185 71.1007Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M358.716 64.5152C358.716 62.7341 358.716 60.9764 358.716 59.1952C358.716 58.4922 359.238 58 359.984 58C363.215 58 366.447 58 369.678 58C370.424 58 370.946 58.5156 370.946 59.2421C370.946 59.9686 370.424 60.5077 369.678 60.5077C366.994 60.5077 364.334 60.5077 361.649 60.5077C361.45 60.5077 361.401 60.5545 361.401 60.742C361.401 63.2731 361.401 65.8042 361.401 68.3353C361.401 68.5463 361.475 68.5697 361.649 68.5697C364.309 68.5697 366.994 68.5697 369.654 68.5697C370.25 68.5697 370.723 68.8978 370.897 69.39C371.095 69.9759 370.872 70.5618 370.399 70.8899C370.176 71.0305 369.902 71.1008 369.629 71.1008C366.447 71.1008 363.24 71.1008 360.058 71.1008C359.238 71.1008 358.741 70.5852 358.741 69.8118C358.716 68.0307 358.716 66.273 358.716 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M345.705 76.0418V76.6986H344V76.0418H344.426V74.2589H344.05V73.6021H345.68V74.2589H345.304V76.0418H345.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M349.535 76.7581H348.774L348.651 76.5453C348.381 76.6872 348.062 76.7581 347.768 76.7581C346.934 76.7345 346.148 76.167 346.148 75.1503C346.148 74.1809 346.811 73.5425 347.817 73.5425C348.651 73.5425 349.461 74.0863 349.461 75.1503C349.461 75.5286 349.363 75.8124 349.216 76.0724L349.559 76.6399V76.7581H349.535ZM347.792 76.0015C348.185 76.0015 348.602 75.7178 348.602 75.1503C348.602 74.5828 348.185 74.2991 347.792 74.2991C347.375 74.2991 346.983 74.5828 346.983 75.1503C346.983 75.7178 347.375 76.0015 347.792 76.0015Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M351.784 75.7488C351.784 75.9601 352.029 76.0774 352.3 76.0774C352.545 76.0774 352.791 75.9601 352.791 75.7958C352.791 75.4906 352.422 75.4906 352.201 75.4672C351.612 75.4202 351.023 75.209 351.023 74.5518C351.023 73.8711 351.612 73.5425 352.275 73.5425C352.889 73.5425 353.527 73.8711 353.527 74.5518H352.717C352.717 74.3405 352.496 74.2466 352.275 74.2466C352.079 74.2466 351.833 74.2936 351.833 74.5283C351.833 74.7395 352.029 74.7865 352.324 74.81C353.012 74.8569 353.601 75.0212 353.601 75.7723C353.601 76.4295 352.938 76.7581 352.275 76.7581C351.587 76.7581 350.924 76.4295 350.949 75.7488H351.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M356.228 74.3112H355.037V74.7603H356.129V75.4694H355.037V75.9658H356.253V76.6986H354.169V73.6021H356.204V74.3112H356.228Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M359.603 76.5804V76.6986H358.722L358.159 75.7295H357.719V76.6986H356.887V73.6021C357.376 73.6021 357.841 73.6021 358.306 73.6021C359.089 73.6021 359.456 74.1694 359.456 74.713C359.456 75.0676 359.285 75.4222 358.966 75.6113L359.603 76.5804ZM358.306 75.0676C358.551 75.0676 358.648 74.8785 358.648 74.6658C358.648 74.4766 358.526 74.2875 358.306 74.2875H357.719V75.0676H358.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M361.276 75.3343L361.552 74.5275L361.955 73.6021H362.886V73.7207L361.452 76.7582H361.099L359.665 73.7207V73.6021H360.596L361.024 74.5275L361.276 75.3343Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M364.782 76.0418V76.6986H363.076V76.0418H363.503V74.2589H363.126V73.6021H364.756V74.2589H364.38V76.0418H364.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M366.835 73.6021C367.509 73.6021 368.258 73.9553 368.383 74.7797H367.559C367.434 74.45 367.185 74.3322 366.835 74.3322C366.336 74.3322 366.011 74.6619 366.011 75.1801C366.011 75.6276 366.336 76.028 366.835 76.028C367.185 76.028 367.484 75.8631 367.559 75.5334H368.383C368.258 76.4049 367.534 76.7582 366.835 76.7582C365.986 76.7582 365.162 76.2164 365.162 75.1801C365.162 74.1202 365.986 73.6021 366.835 73.6021Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M370.944 74.3112H369.753V74.7603H370.845V75.4694H369.753V75.9658H370.969V76.6986H368.885V73.6021H370.919V74.3112H370.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <defs>
          <pattern id="6-0-pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use
              xlinkHref="#image0"
              transform="translate(-0.00768784) scale(0.00100915 0.00153332)"
            />
          </pattern>
          <filter
            id="6-0-filter0_i"
            x="300"
            y="133"
            width="112.94"
            height="148.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="6-0-filter1_i"
            x="168"
            y="133"
            width="113.94"
            height="148.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="6-0-filter2_i"
            x="36"
            y="133"
            width="113.94"
            height="148.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="6-0-filter3_i"
            x="36"
            y="41"
            width="113.94"
            height="74.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="6-0-filter4_i"
            x="168"
            y="41"
            width="113.94"
            height="74.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="6-0-filter5_i"
            x="300"
            y="41"
            width="113.94"
            height="74.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <linearGradient
            id="6-0-paint0_linear"
            x1="301.5"
            y1="291"
            x2="301.5"
            y2="316"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DBDDE1" />
            <stop offset="1" stopColor="#F9F9F9" />
          </linearGradient>
        </defs>
      </svg>
      {!withoutBackground && <ContainerBackground />}
    </ProductImageWrapper>
  )
}
