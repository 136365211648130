import React, { FC } from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'
import { IContainerSquareProps } from '../../interface'
import { ContainerBackground } from '../container-background'

export const ContainerSquare41Casement: FC<IContainerSquareProps> = props => {
  const { onCasementClick, withoutBackground } = props

  return (
    <ProductImageWrapper isClickable>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <rect width="449" height="333" fill="white" />
        <rect x="24" y="28" width="401" height="264" fill="#E8EAEE" />
        <rect x="24" y="28" width="401" height="264" fill="url(#4-pattern0)" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M439 10H10V292H24V29H425V292H439V10Z"
          fill="#C4C4C4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M439 292H10V308H0V316H10H439H449V308H439V292Z"
          fill="url(#4-paint0_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23 28H425V292H23V28ZM37 41H117V279H37V41ZM215 41H136V279H215V41ZM234 41H313V279H234V41ZM412 41H332V279H412V41Z"
          fill="#E8EAEE"
        />
        <path d="M439 10L425 28.0571V292.075L439 308V10Z" fill="#E9EBEF" />
        <path d="M439 10L425 28.0571V292.075L439 308V10Z" fill="#020202" fillOpacity="0.06" />
        <path d="M10 10L24 28.1525V291.982L10 308V10Z" fill="#E9EBEF" />
        <path d="M10 10L24 28.1525V291.982L10 308V10Z" fill="#020202" fillOpacity="0.06" />
        <rect y="316" width="449" height="17" fill="white" />
        <g
          filter="url(#4-filter0_i)"
          className="casement"
          data-index="0"
          onClick={onCasementClick(0)}
        >
          <rect x="37" y="41" width="80" height="238" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="37.47"
          y="41.47"
          width="79.0601"
          height="237.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#4-filter1_i)"
          className="casement"
          data-index="1"
          onClick={onCasementClick(1)}
        >
          <rect x="136" y="41" width="80" height="238" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="136.47"
          y="41.47"
          width="79.0601"
          height="237.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#4-filter2_i)"
          className="casement"
          data-index="2"
          onClick={onCasementClick(2)}
        >
          <rect x="234" y="41" width="80" height="238" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="234.47"
          y="41.47"
          width="79.0601"
          height="237.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#4-filter3_i)"
          className="casement"
          data-index="3"
          onClick={onCasementClick(3)}
        >
          <rect x="332" y="41" width="80" height="238" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="332.47"
          y="41.47"
          width="79.0601"
          height="237.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <path
          d="M85.9851 65.7412H83.4471C82.8002 65.7412 82.2528 65.2345 82.2528 64.6356V64.4053C82.2528 63.8064 82.8002 63.2997 83.4471 63.2997H85.9851C86.632 63.2997 87.1794 63.8064 87.1794 64.4053V64.6356C87.1794 65.2575 86.6569 65.7412 85.9851 65.7412Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M68.4262 68.5652C68.7247 68.5652 68.7993 68.5652 69.1972 68.5652C69.9432 68.5652 70.4903 69.1052 70.4903 69.833C70.4903 70.5843 69.9184 71.1008 69.1474 71.1008C67.705 71.1008 66.8098 71.1008 65.3674 71.1008C64.8203 71.1008 64.3727 70.913 64.1488 70.4199C63.9499 70.1147 63.8255 69.2695 64.6213 68.5652C65.1436 67.9782 70.9131 61.7565 71.9327 60.6765C71.9824 60.6061 71.9327 60.5122 71.8581 60.5122C71.8332 60.5122 71.8332 60.5122 71.8084 60.5122C69.6448 60.5122 67.4812 60.5122 65.3177 60.5122C64.6711 60.5122 64.1737 60.1365 64.0494 59.573C63.9002 58.9156 64.1986 58.3522 64.7706 58.0939C64.9446 58.0235 65.1187 58 65.2928 58C68.5008 58 71.684 58 74.8921 58C75.5138 58 75.9366 58.1878 76.1852 58.7278C76.5085 59.4087 76.1604 59.8783 75.9366 60.1835C75.4889 60.7235 70.938 65.56 68.4262 68.2834C68.4014 68.3069 68.3765 68.3539 68.3268 68.3773C68.277 68.4478 68.3268 68.5652 68.4262 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M75.1856 71.1009H72.6476C72.0007 71.1009 71.4533 70.5818 71.4533 69.9684V69.7324C71.4533 69.1189 72.0007 68.5999 72.6476 68.5999H75.1856C75.8325 68.5999 76.3799 69.1189 76.3799 69.7324V69.9684C76.3799 70.6054 75.8325 71.1009 75.1856 71.1009Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M78.7154 64.5152C78.7154 62.7341 78.7154 60.9764 78.7154 59.1952C78.7154 58.4922 79.2374 58 79.9832 58C83.2148 58 86.4464 58 89.678 58C90.4238 58 90.9458 58.5156 90.9458 59.2421C90.9458 59.9686 90.4238 60.5077 89.678 60.5077C86.9933 60.5077 84.3334 60.5077 81.6487 60.5077C81.4498 60.5077 81.4001 60.5545 81.4001 60.742C81.4001 63.2731 81.4001 65.8042 81.4001 68.3353C81.4001 68.5463 81.4747 68.5697 81.6487 68.5697C84.3086 68.5697 86.9933 68.5697 89.6532 68.5697C90.2498 68.5697 90.7221 68.8978 90.8961 69.39C91.095 69.9759 90.8712 70.5618 90.3989 70.8899C90.1752 71.0305 89.9018 71.1008 89.6283 71.1008C86.4464 71.1008 83.2397 71.1008 80.0578 71.1008C79.2374 71.1008 78.7403 70.5852 78.7403 69.8118C78.7154 68.0307 78.7154 66.273 78.7154 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M65.7054 76.0416V76.6984H64V76.0416H64.4263V74.2587H64.0502V73.6019H65.6803V74.2587H65.3041V76.0416H65.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M69.5344 76.7579H68.7737L68.651 76.5451C68.3811 76.687 68.0621 76.7579 67.7676 76.7579C66.9334 76.7342 66.1482 76.1668 66.1482 75.1501C66.1482 74.1806 66.8107 73.5422 67.8167 73.5422C68.651 73.5422 69.4607 74.0861 69.4607 75.1501C69.4607 75.5284 69.3626 75.8121 69.2154 76.0722L69.5589 76.6397V76.7579H69.5344ZM67.7922 76.0013C68.1848 76.0013 68.6019 75.7175 68.6019 75.1501C68.6019 74.5826 68.1848 74.2989 67.7922 74.2989C67.375 74.2989 66.9824 74.5826 66.9824 75.1501C66.9824 75.7175 67.375 76.0013 67.7922 76.0013Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M71.7835 75.7486C71.7835 75.9598 72.0291 76.0772 72.2992 76.0772C72.5448 76.0772 72.7904 75.9598 72.7904 75.7955C72.7904 75.4904 72.422 75.4904 72.201 75.4669C71.6116 75.42 71.0223 75.2087 71.0223 74.5515C71.0223 73.8708 71.6116 73.5422 72.2747 73.5422C72.8886 73.5422 73.5271 73.8708 73.5271 74.5515H72.7167C72.7167 74.3403 72.4957 74.2464 72.2747 74.2464C72.0782 74.2464 71.8326 74.2933 71.8326 74.5281C71.8326 74.7393 72.0291 74.7862 72.3238 74.8097C73.0114 74.8567 73.6007 75.021 73.6007 75.7721C73.6007 76.4293 72.9377 76.7579 72.2747 76.7579C71.5871 76.7579 70.924 76.4293 70.9486 75.7486H71.7835Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M76.2288 74.311H75.0377V74.7601H76.1295V75.4693H75.0377V75.9656H76.2536V76.6984H74.1692V73.6019H76.2039V74.311H76.2288Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M79.603 76.5802V76.6984H78.7222L78.1594 75.7293H77.719V76.6984H76.8871V73.6019C77.3765 73.6019 77.8413 73.6019 78.3062 73.6019C79.0892 73.6019 79.4562 74.1692 79.4562 74.7128C79.4562 75.0674 79.285 75.422 78.9669 75.6111L79.603 76.5802ZM78.3062 75.0674C78.5509 75.0674 78.6488 74.8783 78.6488 74.6656C78.6488 74.4765 78.5265 74.2874 78.3062 74.2874H77.719V75.0674H78.3062Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M81.2761 75.3342L81.553 74.5273L81.9556 73.6019H82.8868V73.7205L81.4523 76.758H81.1L79.6655 73.7205V73.6019H80.5967L81.0245 74.5273L81.2761 75.3342Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M84.7814 76.0416V76.6984H83.0761V76.0416H83.5024V74.2587H83.1262V73.6019H84.7564V74.2587H84.3802V76.0416H84.7814Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M86.8348 73.6019C87.5091 73.6019 88.2582 73.9552 88.383 74.7795H87.559C87.4341 74.4498 87.1844 74.332 86.8348 74.332C86.3354 74.332 86.0108 74.6618 86.0108 75.1799C86.0108 75.6274 86.3354 76.0278 86.8348 76.0278C87.1844 76.0278 87.4841 75.863 87.559 75.5332H88.383C88.2582 76.4047 87.534 76.758 86.8348 76.758C85.9858 76.758 85.1618 76.2162 85.1618 75.1799C85.1618 74.12 85.9858 73.6019 86.8348 73.6019Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M90.9442 74.311H89.7531V74.7601H90.8449V75.4693H89.7531V75.9656H90.969V76.6984H88.8847V73.6019H90.9194V74.311H90.9442Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M184.985 65.7412H182.447C181.8 65.7412 181.253 65.2345 181.253 64.6356V64.4053C181.253 63.8064 181.8 63.2997 182.447 63.2997H184.985C185.632 63.2997 186.179 63.8064 186.179 64.4053V64.6356C186.179 65.2575 185.657 65.7412 184.985 65.7412Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M167.426 68.5652C167.725 68.5652 167.799 68.5652 168.197 68.5652C168.943 68.5652 169.49 69.1052 169.49 69.833C169.49 70.5843 168.918 71.1008 168.147 71.1008C166.705 71.1008 165.81 71.1008 164.367 71.1008C163.82 71.1008 163.373 70.913 163.149 70.4199C162.95 70.1147 162.826 69.2695 163.621 68.5652C164.144 67.9782 169.913 61.7565 170.933 60.6765C170.982 60.6061 170.933 60.5122 170.858 60.5122C170.833 60.5122 170.833 60.5122 170.808 60.5122C168.645 60.5122 166.481 60.5122 164.318 60.5122C163.671 60.5122 163.174 60.1365 163.049 59.573C162.9 58.9156 163.199 58.3522 163.771 58.0939C163.945 58.0235 164.119 58 164.293 58C167.501 58 170.684 58 173.892 58C174.514 58 174.937 58.1878 175.185 58.7278C175.509 59.4087 175.16 59.8783 174.937 60.1835C174.489 60.7235 169.938 65.56 167.426 68.2834C167.401 68.3069 167.376 68.3539 167.327 68.3773C167.277 68.4478 167.327 68.5652 167.426 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M174.186 71.1009H171.648C171.001 71.1009 170.453 70.5818 170.453 69.9684V69.7324C170.453 69.1189 171.001 68.5999 171.648 68.5999H174.186C174.832 68.5999 175.38 69.1189 175.38 69.7324V69.9684C175.38 70.6054 174.832 71.1009 174.186 71.1009Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M177.715 64.5152C177.715 62.7341 177.715 60.9764 177.715 59.1952C177.715 58.4922 178.237 58 178.983 58C182.215 58 185.446 58 188.678 58C189.424 58 189.946 58.5156 189.946 59.2421C189.946 59.9686 189.424 60.5077 188.678 60.5077C185.993 60.5077 183.333 60.5077 180.649 60.5077C180.45 60.5077 180.4 60.5545 180.4 60.742C180.4 63.2731 180.4 65.8042 180.4 68.3353C180.4 68.5463 180.475 68.5697 180.649 68.5697C183.309 68.5697 185.993 68.5697 188.653 68.5697C189.25 68.5697 189.722 68.8978 189.896 69.39C190.095 69.9759 189.871 70.5618 189.399 70.8899C189.175 71.0305 188.902 71.1008 188.628 71.1008C185.446 71.1008 182.24 71.1008 179.058 71.1008C178.237 71.1008 177.74 70.5852 177.74 69.8118C177.715 68.0307 177.715 66.273 177.715 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M164.705 76.0416V76.6984H163V76.0416H163.426V74.2587H163.05V73.6019H164.68V74.2587H164.304V76.0416H164.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M168.534 76.7579H167.774L167.651 76.5451C167.381 76.687 167.062 76.7579 166.768 76.7579C165.933 76.7342 165.148 76.1668 165.148 75.1501C165.148 74.1806 165.811 73.5422 166.817 73.5422C167.651 73.5422 168.461 74.0861 168.461 75.1501C168.461 75.5284 168.363 75.8121 168.215 76.0722L168.559 76.6397V76.7579H168.534ZM166.792 76.0013C167.185 76.0013 167.602 75.7175 167.602 75.1501C167.602 74.5826 167.185 74.2989 166.792 74.2989C166.375 74.2989 165.982 74.5826 165.982 75.1501C165.982 75.7175 166.375 76.0013 166.792 76.0013Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M170.784 75.7486C170.784 75.9598 171.029 76.0772 171.299 76.0772C171.545 76.0772 171.79 75.9598 171.79 75.7955C171.79 75.4904 171.422 75.4904 171.201 75.4669C170.612 75.42 170.022 75.2087 170.022 74.5515C170.022 73.8708 170.612 73.5422 171.275 73.5422C171.889 73.5422 172.527 73.8708 172.527 74.5515H171.717C171.717 74.3403 171.496 74.2464 171.275 74.2464C171.078 74.2464 170.833 74.2933 170.833 74.5281C170.833 74.7393 171.029 74.7862 171.324 74.8097C172.011 74.8567 172.601 75.021 172.601 75.7721C172.601 76.4293 171.938 76.7579 171.275 76.7579C170.587 76.7579 169.924 76.4293 169.949 75.7486H170.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M175.229 74.311H174.038V74.7601H175.13V75.4693H174.038V75.9656H175.254V76.6984H173.169V73.6019H175.204V74.311H175.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M178.603 76.5802V76.6984H177.722L177.159 75.7293H176.719V76.6984H175.887V73.6019C176.376 73.6019 176.841 73.6019 177.306 73.6019C178.089 73.6019 178.456 74.1692 178.456 74.7128C178.456 75.0674 178.285 75.422 177.967 75.6111L178.603 76.5802ZM177.306 75.0674C177.551 75.0674 177.649 74.8783 177.649 74.6656C177.649 74.4765 177.526 74.2874 177.306 74.2874H176.719V75.0674H177.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M180.276 75.3342L180.553 74.5273L180.956 73.6019H181.887V73.7205L180.452 76.758H180.1L178.666 73.7205V73.6019H179.597L180.024 74.5273L180.276 75.3342Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M183.781 76.0416V76.6984H182.076V76.0416H182.502V74.2587H182.126V73.6019H183.756V74.2587H183.38V76.0416H183.781Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M185.835 73.6019C186.509 73.6019 187.258 73.9552 187.383 74.7795H186.559C186.434 74.4498 186.184 74.332 185.835 74.332C185.335 74.332 185.011 74.6618 185.011 75.1799C185.011 75.6274 185.335 76.0278 185.835 76.0278C186.184 76.0278 186.484 75.863 186.559 75.5332H187.383C187.258 76.4047 186.534 76.758 185.835 76.758C184.986 76.758 184.162 76.2162 184.162 75.1799C184.162 74.12 184.986 73.6019 185.835 73.6019Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M189.944 74.311H188.753V74.7601H189.845V75.4693H188.753V75.9656H189.969V76.6984H187.885V73.6019H189.919V74.311H189.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M282.985 65.7412H280.447C279.8 65.7412 279.253 65.2345 279.253 64.6356V64.4053C279.253 63.8064 279.8 63.2997 280.447 63.2997H282.985C283.632 63.2997 284.179 63.8064 284.179 64.4053V64.6356C284.179 65.2575 283.657 65.7412 282.985 65.7412Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M265.426 68.5652C265.725 68.5652 265.799 68.5652 266.197 68.5652C266.943 68.5652 267.49 69.1052 267.49 69.833C267.49 70.5843 266.918 71.1008 266.147 71.1008C264.705 71.1008 263.81 71.1008 262.367 71.1008C261.82 71.1008 261.373 70.913 261.149 70.4199C260.95 70.1147 260.826 69.2695 261.621 68.5652C262.144 67.9782 267.913 61.7565 268.933 60.6765C268.982 60.6061 268.933 60.5122 268.858 60.5122C268.833 60.5122 268.833 60.5122 268.808 60.5122C266.645 60.5122 264.481 60.5122 262.318 60.5122C261.671 60.5122 261.174 60.1365 261.049 59.573C260.9 58.9156 261.199 58.3522 261.771 58.0939C261.945 58.0235 262.119 58 262.293 58C265.501 58 268.684 58 271.892 58C272.514 58 272.937 58.1878 273.185 58.7278C273.509 59.4087 273.16 59.8783 272.937 60.1835C272.489 60.7235 267.938 65.56 265.426 68.2834C265.401 68.3069 265.376 68.3539 265.327 68.3773C265.277 68.4478 265.327 68.5652 265.426 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M272.186 71.1009H269.648C269.001 71.1009 268.453 70.5818 268.453 69.9684V69.7324C268.453 69.1189 269.001 68.5999 269.648 68.5999H272.186C272.832 68.5999 273.38 69.1189 273.38 69.7324V69.9684C273.38 70.6054 272.832 71.1009 272.186 71.1009Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M275.715 64.5152C275.715 62.7341 275.715 60.9764 275.715 59.1952C275.715 58.4922 276.237 58 276.983 58C280.215 58 283.446 58 286.678 58C287.424 58 287.946 58.5156 287.946 59.2421C287.946 59.9686 287.424 60.5077 286.678 60.5077C283.993 60.5077 281.333 60.5077 278.649 60.5077C278.45 60.5077 278.4 60.5545 278.4 60.742C278.4 63.2731 278.4 65.8042 278.4 68.3353C278.4 68.5463 278.475 68.5697 278.649 68.5697C281.309 68.5697 283.993 68.5697 286.653 68.5697C287.25 68.5697 287.722 68.8978 287.896 69.39C288.095 69.9759 287.871 70.5618 287.399 70.8899C287.175 71.0305 286.902 71.1008 286.628 71.1008C283.446 71.1008 280.24 71.1008 277.058 71.1008C276.237 71.1008 275.74 70.5852 275.74 69.8118C275.715 68.0307 275.715 66.273 275.715 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M262.705 76.0416V76.6984H261V76.0416H261.426V74.2587H261.05V73.6019H262.68V74.2587H262.304V76.0416H262.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M266.534 76.7579H265.774L265.651 76.5451C265.381 76.687 265.062 76.7579 264.768 76.7579C263.933 76.7342 263.148 76.1668 263.148 75.1501C263.148 74.1806 263.811 73.5422 264.817 73.5422C265.651 73.5422 266.461 74.0861 266.461 75.1501C266.461 75.5284 266.363 75.8121 266.215 76.0722L266.559 76.6397V76.7579H266.534ZM264.792 76.0013C265.185 76.0013 265.602 75.7175 265.602 75.1501C265.602 74.5826 265.185 74.2989 264.792 74.2989C264.375 74.2989 263.982 74.5826 263.982 75.1501C263.982 75.7175 264.375 76.0013 264.792 76.0013Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M268.784 75.7486C268.784 75.9598 269.029 76.0772 269.299 76.0772C269.545 76.0772 269.79 75.9598 269.79 75.7955C269.79 75.4904 269.422 75.4904 269.201 75.4669C268.612 75.42 268.022 75.2087 268.022 74.5515C268.022 73.8708 268.612 73.5422 269.275 73.5422C269.889 73.5422 270.527 73.8708 270.527 74.5515H269.717C269.717 74.3403 269.496 74.2464 269.275 74.2464C269.078 74.2464 268.833 74.2933 268.833 74.5281C268.833 74.7393 269.029 74.7862 269.324 74.8097C270.011 74.8567 270.601 75.021 270.601 75.7721C270.601 76.4293 269.938 76.7579 269.275 76.7579C268.587 76.7579 267.924 76.4293 267.949 75.7486H268.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M273.229 74.311H272.038V74.7601H273.13V75.4693H272.038V75.9656H273.254V76.6984H271.169V73.6019H273.204V74.311H273.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M276.603 76.5802V76.6984H275.722L275.159 75.7293H274.719V76.6984H273.887V73.6019C274.376 73.6019 274.841 73.6019 275.306 73.6019C276.089 73.6019 276.456 74.1692 276.456 74.7128C276.456 75.0674 276.285 75.422 275.967 75.6111L276.603 76.5802ZM275.306 75.0674C275.551 75.0674 275.649 74.8783 275.649 74.6656C275.649 74.4765 275.526 74.2874 275.306 74.2874H274.719V75.0674H275.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M278.276 75.3342L278.553 74.5273L278.956 73.6019H279.887V73.7205L278.452 76.758H278.1L276.666 73.7205V73.6019H277.597L278.024 74.5273L278.276 75.3342Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M281.781 76.0416V76.6984H280.076V76.0416H280.502V74.2587H280.126V73.6019H281.756V74.2587H281.38V76.0416H281.781Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M283.835 73.6019C284.509 73.6019 285.258 73.9552 285.383 74.7795H284.559C284.434 74.4498 284.184 74.332 283.835 74.332C283.335 74.332 283.011 74.6618 283.011 75.1799C283.011 75.6274 283.335 76.0278 283.835 76.0278C284.184 76.0278 284.484 75.863 284.559 75.5332H285.383C285.258 76.4047 284.534 76.758 283.835 76.758C282.986 76.758 282.162 76.2162 282.162 75.1799C282.162 74.12 282.986 73.6019 283.835 73.6019Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M287.944 74.311H286.753V74.7601H287.845V75.4693H286.753V75.9656H287.969V76.6984H285.885V73.6019H287.919V74.311H287.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M380.985 65.7412H378.447C377.8 65.7412 377.253 65.2345 377.253 64.6356V64.4053C377.253 63.8064 377.8 63.2997 378.447 63.2997H380.985C381.632 63.2997 382.179 63.8064 382.179 64.4053V64.6356C382.179 65.2575 381.657 65.7412 380.985 65.7412Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M363.426 68.5652C363.725 68.5652 363.799 68.5652 364.197 68.5652C364.943 68.5652 365.49 69.1052 365.49 69.833C365.49 70.5843 364.918 71.1008 364.147 71.1008C362.705 71.1008 361.81 71.1008 360.367 71.1008C359.82 71.1008 359.373 70.913 359.149 70.4199C358.95 70.1147 358.826 69.2695 359.621 68.5652C360.144 67.9782 365.913 61.7565 366.933 60.6765C366.982 60.6061 366.933 60.5122 366.858 60.5122C366.833 60.5122 366.833 60.5122 366.808 60.5122C364.645 60.5122 362.481 60.5122 360.318 60.5122C359.671 60.5122 359.174 60.1365 359.049 59.573C358.9 58.9156 359.199 58.3522 359.771 58.0939C359.945 58.0235 360.119 58 360.293 58C363.501 58 366.684 58 369.892 58C370.514 58 370.937 58.1878 371.185 58.7278C371.509 59.4087 371.16 59.8783 370.937 60.1835C370.489 60.7235 365.938 65.56 363.426 68.2834C363.401 68.3069 363.376 68.3539 363.327 68.3773C363.277 68.4478 363.327 68.5652 363.426 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M370.186 71.1009H367.648C367.001 71.1009 366.453 70.5818 366.453 69.9684V69.7324C366.453 69.1189 367.001 68.5999 367.648 68.5999H370.186C370.832 68.5999 371.38 69.1189 371.38 69.7324V69.9684C371.38 70.6054 370.832 71.1009 370.186 71.1009Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M373.715 64.5152C373.715 62.7341 373.715 60.9764 373.715 59.1952C373.715 58.4922 374.237 58 374.983 58C378.215 58 381.446 58 384.678 58C385.424 58 385.946 58.5156 385.946 59.2421C385.946 59.9686 385.424 60.5077 384.678 60.5077C381.993 60.5077 379.333 60.5077 376.649 60.5077C376.45 60.5077 376.4 60.5545 376.4 60.742C376.4 63.2731 376.4 65.8042 376.4 68.3353C376.4 68.5463 376.475 68.5697 376.649 68.5697C379.309 68.5697 381.993 68.5697 384.653 68.5697C385.25 68.5697 385.722 68.8978 385.896 69.39C386.095 69.9759 385.871 70.5618 385.399 70.8899C385.175 71.0305 384.902 71.1008 384.628 71.1008C381.446 71.1008 378.24 71.1008 375.058 71.1008C374.237 71.1008 373.74 70.5852 373.74 69.8118C373.715 68.0307 373.715 66.273 373.715 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M360.705 76.0416V76.6984H359V76.0416H359.426V74.2587H359.05V73.6019H360.68V74.2587H360.304V76.0416H360.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M364.534 76.7579H363.774L363.651 76.5451C363.381 76.687 363.062 76.7579 362.768 76.7579C361.933 76.7342 361.148 76.1668 361.148 75.1501C361.148 74.1806 361.811 73.5422 362.817 73.5422C363.651 73.5422 364.461 74.0861 364.461 75.1501C364.461 75.5284 364.363 75.8121 364.215 76.0722L364.559 76.6397V76.7579H364.534ZM362.792 76.0013C363.185 76.0013 363.602 75.7175 363.602 75.1501C363.602 74.5826 363.185 74.2989 362.792 74.2989C362.375 74.2989 361.982 74.5826 361.982 75.1501C361.982 75.7175 362.375 76.0013 362.792 76.0013Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M366.784 75.7486C366.784 75.9598 367.029 76.0772 367.299 76.0772C367.545 76.0772 367.79 75.9598 367.79 75.7955C367.79 75.4904 367.422 75.4904 367.201 75.4669C366.612 75.42 366.022 75.2087 366.022 74.5515C366.022 73.8708 366.612 73.5422 367.275 73.5422C367.889 73.5422 368.527 73.8708 368.527 74.5515H367.717C367.717 74.3403 367.496 74.2464 367.275 74.2464C367.078 74.2464 366.833 74.2933 366.833 74.5281C366.833 74.7393 367.029 74.7862 367.324 74.8097C368.011 74.8567 368.601 75.021 368.601 75.7721C368.601 76.4293 367.938 76.7579 367.275 76.7579C366.587 76.7579 365.924 76.4293 365.949 75.7486H366.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M371.229 74.311H370.038V74.7601H371.13V75.4693H370.038V75.9656H371.254V76.6984H369.169V73.6019H371.204V74.311H371.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M374.603 76.5802V76.6984H373.722L373.159 75.7293H372.719V76.6984H371.887V73.6019C372.376 73.6019 372.841 73.6019 373.306 73.6019C374.089 73.6019 374.456 74.1692 374.456 74.7128C374.456 75.0674 374.285 75.422 373.967 75.6111L374.603 76.5802ZM373.306 75.0674C373.551 75.0674 373.649 74.8783 373.649 74.6656C373.649 74.4765 373.526 74.2874 373.306 74.2874H372.719V75.0674H373.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M376.276 75.3342L376.553 74.5273L376.956 73.6019H377.887V73.7205L376.452 76.758H376.1L374.666 73.7205V73.6019H375.597L376.024 74.5273L376.276 75.3342Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M379.781 76.0416V76.6984H378.076V76.0416H378.502V74.2587H378.126V73.6019H379.756V74.2587H379.38V76.0416H379.781Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M381.835 73.6019C382.509 73.6019 383.258 73.9552 383.383 74.7795H382.559C382.434 74.4498 382.184 74.332 381.835 74.332C381.335 74.332 381.011 74.6618 381.011 75.1799C381.011 75.6274 381.335 76.0278 381.835 76.0278C382.184 76.0278 382.484 75.863 382.559 75.5332H383.383C383.258 76.4047 382.534 76.758 381.835 76.758C380.986 76.758 380.162 76.2162 380.162 75.1799C380.162 74.12 380.986 73.6019 381.835 73.6019Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M385.944 74.311H384.753V74.7601H385.845V75.4693H384.753V75.9656H385.969V76.6984H383.885V73.6019H385.919V74.311H385.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <defs>
          <pattern id="4-pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use
              xlinkHref="#image0"
              transform="translate(-0.00768784) scale(0.00100915 0.00153332)"
            />
          </pattern>
          <filter
            id="4-filter0_i"
            x="37"
            y="41"
            width="80.9399"
            height="240.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-filter1_i"
            x="136"
            y="41"
            width="80.9399"
            height="240.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-filter2_i"
            x="234"
            y="41"
            width="80.9399"
            height="240.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-filter3_i"
            x="332"
            y="41"
            width="80.9399"
            height="240.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <linearGradient
            id="4-paint0_linear"
            x1="301.5"
            y1="291"
            x2="301.5"
            y2="316"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DBDDE1" />
            <stop offset="1" stopColor="#F9F9F9" />
          </linearGradient>
        </defs>
      </svg>
      {!withoutBackground && <ContainerBackground />}
    </ProductImageWrapper>
  )
}
