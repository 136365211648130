export function saveUserDataFromLocalStore(data) {
  const strityfy = JSON.stringify(data)
  localStorage.setItem('userdata', strityfy)
}

export function getUserDataFromLocalStore() {
 try {
  const data = localStorage.getItem('userdata')
  return JSON.parse(data as any)
 } catch (err) {
  return {}
 }
}
