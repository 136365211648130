import React from 'react'
import { StageDescriptionBlockContentItemIcon } from 'src/react-app/views/calculator/styles'

export const Soundproofing4 = () => {
  return (
    <StageDescriptionBlockContentItemIcon title="Для помещений требующих специальной защиты от сильного уличного шума">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.6"
          d="M32.5 40H7.5C3.3575 40 0 36.6425 0 32.5V7.5C0 3.3575 3.3575 0 7.5 0H32.5C36.6425 0 40 3.3575 40 7.5V32.5C40 36.6425 36.6425 40 32.5 40Z"
          fill="#E0E3E9"
        />
        <path
          d="M16.194 30C16.1565 30 16.1203 29.9987 16.0828 29.9962C15.664 29.9637 15.2828 29.7512 15.0353 29.4125L13.2915 27.0225C13.249 26.965 13.199 26.915 13.1428 26.8725L10.5853 24.9875C10.2478 24.7387 10.0365 24.3562 10.004 23.9387C9.97279 23.5212 10.124 23.1112 10.4203 22.815C10.8615 22.3737 11.514 22.25 12.084 22.5L14.0215 23.3462L17.9628 19.1C18.0328 19.0237 18.0328 18.9375 18.0265 18.8937C18.0203 18.85 17.9965 18.7662 17.9065 18.7125L11.1265 15.125C10.6978 14.8675 10.4378 14.465 10.3815 14.0037C10.3253 13.5412 10.4815 13.0875 10.8103 12.7587C11.1315 12.435 11.5878 12.3 12.0353 12.4L21.944 14.4987C22.0353 14.5187 22.1253 14.49 22.1865 14.4262L25.7403 10.6512C26.1203 10.2487 26.7603 10.0125 27.4978 10.0012C28.2515 9.98748 28.9615 10.2275 29.3678 10.635C29.7753 11.0425 30.0115 11.7425 30.0003 12.5062C29.9903 13.2437 29.7528 13.8837 29.3515 14.2625C29.3515 14.2625 29.3515 14.2625 29.3503 14.2625L25.5653 17.83C25.4978 17.8937 25.4703 17.9862 25.4903 18.075L27.7703 28.0987C27.8765 28.5475 27.744 29.015 27.414 29.3437C27.0878 29.67 26.6378 29.8262 26.1765 29.7737C25.7178 29.72 25.3153 29.4625 25.0728 29.0687L21.2815 22.0612C21.244 22.0025 21.1628 21.98 21.1178 21.9737C21.074 21.9687 20.9878 21.9687 20.914 22.0387L16.6765 25.9775L17.5228 27.9162C17.7715 28.4862 17.649 29.14 17.209 29.58C16.9378 29.85 16.5728 30 16.194 30Z"
          fill="#1C3775"
        />
      </svg>
    </StageDescriptionBlockContentItemIcon>
  )
}
