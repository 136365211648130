import React, { FC, useCallback, useEffect, useState, useMemo, useContext } from 'react'
import { sendCt } from 'src/lib/utils/ct'
import { ElementsList } from 'src/react-app/components/elements-list'
import { IAdditionBlockProps, IFilterItem } from './interface'
import {
  Button,
  ControlGroup,
  Info,
  Input,
  Loader,
  PhoneInput,
  StepByStepHelper,
  VIEW_TYPES,
} from '../../../components'
import {
  AdditionalBlockButtonBlock,
  AdditionalBlockMain,
  AdditionalBlockPrice,
  AdditionalBlockPriceValue,
  AdditionalBlockSizes,
  AdditionalBlockWrapper,
  AdditionalBlockMainTitle,
  AdditionalBlockMainInputDesc,
  AdditionalBlockSizesDelimiter,
  AdditionalBlockForecastWrapper,
  AdditionalBlockForecastTitle,
  AdditionalBlockForecastValue,
  AdditionalBlockButtonBlockWrapper,
} from './styles'
import { NumberInput } from './components'
import { ButtonViews, InputTypes, IOrderForecastItem } from '../interface'
import { useOrders, useSendOrder } from '../../../hooks'
import {
  fixBody,
  handlerReplaceHandlebars,
  parseDate,
  prepareOrderProducts,
} from '../../../utilits'
import { ProductsContext } from './stage-calculator'
import { filterValueSwitchOffMap } from './consts'
import {
  CloseButton,
  ModalBody,
  ModalContent,
  ModalDescription,
  ModalTitle,
  ModalWrapper,
} from '../styles'
import { CloseIcon } from '../../../assets/icons'
import { ButtonWrapper, RightConfirmWrapper } from '../registr/styles'
import { useModal } from 'react-modal-hook'
import { Link } from 'react-router-dom'
import { saveUserDataFromLocalStore, getUserDataFromLocalStore } from 'src/react-app/utilits/get-user-data'

export const AdditionalBlock: FC<IAdditionBlockProps> = props => {
  const {
    product = null,
    onSubmit: onSubmitUp,
    onSetItemSize,
    inProcess,
    isEdit,
    price,
    additionalPrice,
    isCanSubmit,
  } = props
  const {
    type,
    itemSize,
    setFilters,
    filters,
    setSelectedSubAttributeData,
    menuItemsData,
    stepByStepHelper,
    onChangeStepByStepHelper,
  } = useContext(ProductsContext)
  const [sizes, setSizes] = useState<Record<string, any>>({ width: 0, height: 0 })
  const [widthRange, setWidthRange] = useState({ min: 0, max: 0 })
  const [heightRange, setHeightRange] = useState({ min: 0, max: 0 })
  const { forecast, fetchForecast } = useOrders()
  const additionalPriceKeys = Object.keys(additionalPrice)
  const [sizesDisabled, setSizesDisabled] = useState(false)
  const [userData, setUserData] = useState<any>({
    'first-name': '',
    'last-name': '',
    'passport-issued': '',
    'passport-number': '',
    'phone': '',
    'right-confirm': false,
  })
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const { createOrder } = useSendOrder()
  const { toggleFixBody } = fixBody()

  useEffect(() => {
    fetchForecast()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setSizesDisabled(Boolean(inProcess))
  }, [inProcess])

  useEffect(() => {
    if (product !== null && product['window-model']) {
      const rawWidthRange = product['window-model']['width-range']
        ? product['window-model']['width-range'].split('~')
        : []
      const rawHeightRange = product['window-model']['height-range']
        ? product['window-model']['height-range'].split('~')
        : []
      const widthDefault = Number(product['window-model']['width-default'] || 0)
      const heightDefault = Number(product['window-model']['height-default'] || 0)

      if (rawWidthRange.length > 0) {
        // @TODO костыль
        let max = Number(rawWidthRange[1])
        if (product?.configuration?.images === 'square2Casement') {
          max = 2200
        }
        setWidthRange({
          min: Number(rawWidthRange[0]),
          max: max,
        })
      }

      if (rawHeightRange.length > 0) {
        setHeightRange({
          min: Number(rawHeightRange[0]),
          max: Number(rawHeightRange[1]),
        })
      }

      setSizes(prevState => ({
        ...prevState,
        'model-width': itemSize['model-width'] || widthDefault,
        'model-height': itemSize['model-height'] || heightDefault,
      }))

      if (!itemSize['model-width'] || !itemSize['model-height']) {
        onSetItemSize([
          {
            key: 'model-width',
            value: widthDefault,
          },
          {
            key: 'model-height',
            value: heightDefault,
          },
        ])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  useEffect(() => {
    if (itemSize['model-width'] && itemSize['model-height']) {
      setSizes(itemSize)
    }
  }, [itemSize])

  const handleCloseModal = useCallback(
    hideModal => () => {
      toggleFixBody()
      hideModal()
      setShowSuccessMessage(false)
    },
    [toggleFixBody],
  )

  const handleChange = useCallback((value, key) => {
    setUserData(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }, [])

  const handleConfirm = useCallback(() => {
    setUserData(prevState => ({
      ...prevState,
      'right-confirm': !prevState['right-confirm'],
    }))
  }, [])

  const handleCall = useCallback(() => {
    const { newProducts, transitionProducts } = prepareOrderProducts(
      [{ product, sizes: itemSize, filters }],
      [price],
      type,
    )
    const totalCost = price ? price['total'] : 0

    const orderData = {
      stage: 'new',
      source: 'Консультация менеджера заказа по адресу',
      // @ts-ignore
// eslint-disable-next-line
      location: window.location.href,
      'specification-products': {
        'transition-products': transitionProducts,
        print: transitionProducts,
        source: {
          type,
          products: newProducts,
          totalCost,
        },
      },
    }

    // console.log({ userData })
    sendCt({ name: userData['first-name'], phone: userData['phone'] })
    createOrder(orderData, { 
      phone: userData['phone'], 
      name: userData['first-name'],  
      'last-name': userData?.['last-name'] || '',
      'first-name': userData?.['first-name'],
      'passport-issued': userData?.['passport-issued'],
      'passport-number': userData?.['passport-number']
    } as any)
    setShowSuccessMessage(true)
  }, [product, itemSize, filters, price, type, createOrder, userData])

  const isCantCall = useMemo(() => {
    return !userData['right-confirm'] || !userData['phone']
  }, [userData])

  const [showModal, hideModal] = useModal(
    ({ in: open }) => {
      return (
        <ModalWrapper show={open}>
          <ModalBody>
            <CloseButton onClick={handleCloseModal(hideModal)}>
              <CloseIcon />
            </CloseButton>
            <ModalTitle>Консультация менеджера заказа по Вашему адресу</ModalTitle>
            <ModalContent>
              {showSuccessMessage && (
                <ModalDescription>
                  Благодарим за обращение. В течении 10 минут менеджер заказа свяжется с Вами для
                  назначения времени встречи
                </ModalDescription>
              )}
              {!showSuccessMessage && (
                <>
                  <ControlGroup>
                    <Input
                      placeholder="Укажите Ваше имя"
                      name="first-name"
                      type={InputTypes.text}
                      onChange={handleChange}
                      value={userData['first-name']}
                    />
                  </ControlGroup>
                  <ControlGroup>
                    <PhoneInput
                      id="phone"
                      placeholder="Контактный телефон"
                      name="phone"
                      onChange={handleChange}
                      value={userData['phone']}
                    />
                  </ControlGroup>
                  <RightConfirmWrapper>
                    <Input
                      id="right-confirm"
                      type={InputTypes.checkbox}
                      name="right-confirm"
                      onChange={handleConfirm}
                    />
                    <label htmlFor="right-confirm">
                      Я согласен с правилами обработки{' '}
                      <Link to="/privacy-policy/" target="_blank">
                        персональных данных
                      </Link>
                    </label>
                  </RightConfirmWrapper>
                  <ButtonWrapper>
                    <Button
                      typeView={ButtonViews.ORANGE}
                      onClick={handleCall}
                      disabled={isCantCall}
                    >
                      Оформить заявку
                    </Button>
                  </ButtonWrapper>
                </>
              )}
            </ModalContent>
          </ModalBody>
        </ModalWrapper>
      )
    },
    [userData, showSuccessMessage, isCantCall],
  )

  const handleOnlineConsult = useCallback(() => {
    showModal()
    toggleFixBody(true)
  }, [showModal, toggleFixBody])

  const handleSizeChange = useCallback(
    (key: string) => value => {
      onSetItemSize([
        {
          key,
          value,
        },
      ])

      if (stepByStepHelper === 'outer-sizes') {
        onChangeStepByStepHelper()
      }
    },
    [onChangeStepByStepHelper, onSetItemSize, stepByStepHelper],
  )

  const cartButtonText = useMemo(() => {
    return isEdit ? 'В корзину' : 'Добавить в корзину'
  }, [isEdit])
  const onSubmit = useCallback(() => {
    onSubmitUp()
    if (Boolean(isEdit) === false) {
      // @ts-ignore
      window?.ym?.(82697449,'reachGoal','add_to_basket')
    }
  }, [isEdit, onSubmitUp])

  const getCurrentForecast = useCallback(
    (serviceType = '') => {
      let result: IOrderForecastItem | null = null

      if (forecast && forecast.length > 0) {
        const data = forecast.find(cast => cast.code === serviceType)

        if (data) {
          result = data
        }
      }

      return result
    },
    [forecast],
  )

  const deliveryForecast = useMemo(() => {
    const data = getCurrentForecast('delivery')
    let result = ''

    if (data) {
      result = parseDate(data['date-avg'])
    }

    return result
  }, [getCurrentForecast])

  const handleAdditionalElementUncheck = useCallback(
    (key: string) => () => {
      const mappedData = filterValueSwitchOffMap[key] || { key, value: false }
      const currentMenuData = menuItemsData[mappedData['key']]
      const currentValue = currentMenuData
        ? currentMenuData.find(item => item['value'] === mappedData['value'])
        : mappedData['value']

      setFilters(prevState => {
        const currentIndex = prevState.findIndex(item => item['key'] === mappedData['key'])
        const result: IFilterItem[] =
          currentIndex === -1
            ? [...prevState, mappedData]
            : [
                ...prevState.slice(0, currentIndex),
                mappedData,
                ...prevState.slice(currentIndex + 1, prevState.length),
              ]

        return result
      })
      setSelectedSubAttributeData(prevState => ({
        ...prevState,
        [mappedData['key']]: currentValue,
      }))
    },
    [menuItemsData, setFilters, setSelectedSubAttributeData],
  )

  const namedAdditionalPrice = useMemo(() => {
    const result: Record<string, any> = { ...additionalPrice }
    const keys = Object.keys(additionalPrice)

    keys.forEach(key => {
      const currentProduct = product[key] || {}
      const name = currentProduct['name']
        ? handlerReplaceHandlebars(currentProduct['name'], product, sizes, filters)
        : ''

      result[key] = {
        ...result[key],
        name,
      }
    })

    return result
  }, [additionalPrice, filters, product, sizes])

  const handlerBlur = useCallback(() => {
    setTimeout(() => setSizesDisabled(true), 100)
  }, [])

  return (
    <AdditionalBlockWrapper>
      <AdditionalBlockMain>
        <AdditionalBlockMainTitle>
          <StepByStepHelper code="outer-sizes" view={VIEW_TYPES.BLUE} coord={{ x: -34, y: -5 }} />
          <span>Внешние габариты, мм</span>
        </AdditionalBlockMainTitle>
        <AdditionalBlockSizes>
          <div>
            <NumberInput
              onSelect={handleSizeChange('model-width')}
              onBlur={handlerBlur}
              id="model-width"
              title="Ширина"
              placeholder="Ширина"
              data={{ min: widthRange.min, max: widthRange.max }}
              selected={sizes['model-width']}
              selectOnFocus
              disabled={sizesDisabled}
            />
            <AdditionalBlockMainInputDesc>Ширина</AdditionalBlockMainInputDesc>
          </div>
          <AdditionalBlockSizesDelimiter>X</AdditionalBlockSizesDelimiter>
          <div>
            <NumberInput
              onSelect={handleSizeChange('model-height')}
              onBlur={handlerBlur}
              id="model-height"
              title="Высота"
              placeholder="Высота"
              data={{ min: heightRange.min, max: heightRange.max }}
              selected={sizes['model-height']}
              selectOnFocus
              disabled={sizesDisabled}
            />
            <AdditionalBlockMainInputDesc>Высота</AdditionalBlockMainInputDesc>
          </div>
        </AdditionalBlockSizes>
        {additionalPriceKeys.length > 0 && (
          <ElementsList
            additionalPrice={namedAdditionalPrice}
            inProcess={inProcess}
            onAdditionalElementUncheck={handleAdditionalElementUncheck}
            filters={filters}
            product={product}
          />
        )}
        {forecast && (
          <AdditionalBlockForecastWrapper>
            <AdditionalBlockForecastTitle>
              <span>Ожидаемая дата доставки</span>
              <Info color="#bbc3d6">Примерная дата доставки</Info>
            </AdditionalBlockForecastTitle>
            <AdditionalBlockForecastValue>{deliveryForecast}</AdditionalBlockForecastValue>
          </AdditionalBlockForecastWrapper>
        )}
        <AdditionalBlockPrice>
          <span>Cтоимость:</span>
          {inProcess && <Loader />}
          {!inProcess && <AdditionalBlockPriceValue>{price} ₽</AdditionalBlockPriceValue>}
        </AdditionalBlockPrice>
      </AdditionalBlockMain>
      <AdditionalBlockButtonBlock>
        <Button typeView={ButtonViews.ORANGE} onClick={onSubmit} disabled={!isCanSubmit}>
          {cartButtonText}
        </Button>
        {!isCanSubmit && (
          <AdditionalBlockButtonBlockWrapper>
            <Button typeView={ButtonViews.ORANGE} onClick={handleOnlineConsult}>
              Онлайн консультация
            </Button>
          </AdditionalBlockButtonBlockWrapper>
        )}
      </AdditionalBlockButtonBlock>
    </AdditionalBlockWrapper>
  )
}

export default AdditionalBlock
