import React, { FC, useCallback, useMemo } from 'react'

import {
  StyledVerticalSizeWrapper,
  VerticalSizesTrack,
  VerticalSizeWrapper,
  VerticalErrorsWrapper,
  VerticalContentBlock,
  VerticalContentSize,
  VerticalErrorsContent,
  VerticalErrorsText,
} from './styles'
import { IVerticalSizeProps } from './interface'
import { getSliderSizes } from '../../../../../utilits'
import { ThumbSizeContainer } from './thumb-size-container'
import { Tooltip, TooltipDirection } from '../../../../../components'

export const VerticalSize: FC<IVerticalSizeProps> = props => {
  const { value, count, type, imageHeight, maxHeight = 9999, onChange, disabled, errors } = props

  const isMainSize = useMemo(() => {
    return count === 1
  }, [count])

  const handleInputSize = useCallback(
    (index: number | null) => (val: number) => {
      const result = index !== null ? getSliderSizes(value, maxHeight, val, count, index) : [val]

      onChange(isMainSize ? result[0] : result)
    },
    [count, isMainSize, maxHeight, onChange, value],
  )

  const groupHeight = useMemo(() => {
    return imageHeight / count
  }, [count, imageHeight])



  const getSizeBlock = useCallback(
    (size, index) => (
      <StyledVerticalSizeWrapper height={groupHeight} key={`vertical-${maxHeight}-${index}`}>
        <VerticalContentBlock>
          <VerticalContentSize height={groupHeight}>
            <VerticalSizesTrack />
            <div>
              <ThumbSizeContainer
                index={index}
                onChange={handleInputSize}
                maxSize={maxHeight}
                currentSize={size}
                disabled={disabled}
              />
            </div>
          </VerticalContentSize>
          <VerticalErrorsWrapper height={groupHeight}>
            <VerticalErrorsContent>
              {errors && errors[index] ? (
                <Tooltip
                  text={errors[index]['description']}
                  direction={TooltipDirection.BOTTOM}
                  coord={{ x: -255, y: 40 }}
                >
                  <VerticalErrorsText>{errors[index]['title']}</VerticalErrorsText>
                </Tooltip>
              ) : null}
            </VerticalErrorsContent>
          </VerticalErrorsWrapper>
        </VerticalContentBlock>
      </StyledVerticalSizeWrapper>
    ),
    [disabled, errors, groupHeight, handleInputSize, maxHeight],
  )

  return (
    <VerticalSizeWrapper height={imageHeight} type={type}>
      {count > 0 && imageHeight > 0 && value.map(getSizeBlock)}
    </VerticalSizeWrapper>
  )
}
