import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement14Square4BottomCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#1-4_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M61.0005 197C62.1048 197 63 196.105 63 195.001C63 193.896 62.1048 193.001 61.0005 193.001L58.8335 193.001L58.8335 193.001L48 193.001L48 193L44.9995 193C43.8952 193 43 193.895 43 194.999C43 196.104 43.8952 196.999 44.9995 196.999L47.1668 196.999L47.1668 197L58 197L58 197L61.0005 197Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 58.834 197)"
          fill="#ABABAB"
        />
        <path
          d="M62.0601 197C62.5792 197 63 196.579 63 196.06L63 193.941C63 193.422 62.5792 193.001 62.0601 193.001L58 193.001L58 197L62.0601 197Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M43.9399 193C43.4208 193 43 193.421 43 193.94L43 196.059C43 196.578 43.4208 196.999 43.9399 196.999L48 196.999L48 193L43.9399 193Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 57.167 197)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#1-4_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M139.001 197C140.105 197 141 196.105 141 195.001C141 193.896 140.105 193.001 139.001 193.001L136.833 193.001L136.833 193.001L126 193.001L126 193L122.999 193C121.895 193 121 193.895 121 194.999C121 196.104 121.895 196.999 122.999 196.999L125.167 196.999L125.167 197L136 197L136 197L139.001 197Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 136.834 197)"
          fill="#ABABAB"
        />
        <path
          d="M140.06 197C140.579 197 141 196.579 141 196.06L141 193.941C141 193.422 140.579 193.001 140.06 193.001L136 193.001L136 197L140.06 197Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M121.94 193C121.421 193 121 193.421 121 193.94L121 196.059C121 196.578 121.421 196.999 121.94 196.999L126 196.999L126 193L121.94 193Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 135.167 197)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#1-4_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M152 34H32V193H152V34ZM141 45H43V182H141V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M152 34H32V193H33V35H151V193H152V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="36.53"
          y="38.53"
          width="110.94"
          height="149.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#1-4_filter3_i)">
          <rect
            x="43.47"
            y="45.47"
            width="97.0601"
            height="136.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <path d="M139.295 183.5L44.705 183.5L92 48.5121L139.295 183.5Z" stroke="white" />
        <rect
          x="98.5283"
          y="36"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 98.5283 36)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#1-4_filter4_d)">
          <rect
            x="97.8838"
            y="36.7017"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 97.8838 36.7017)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M97.7666 38.6948C97.4202 37.5418 96.3505 36.7017 95.0846 36.7017L88.4447 36.7017C86.8983 36.7017 85.6447 37.9553 85.6447 39.5017C85.6447 39.5368 85.6454 39.5718 85.6466 39.6067C85.993 40.7598 87.0627 41.5998 88.3286 41.5998L94.9686 41.5998C96.515 41.5998 97.7686 40.3462 97.7686 38.7998C97.7686 38.7647 97.7679 38.7297 97.7666 38.6948Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M96.4092 37.0344C96.2655 37.0117 96.1182 36.9998 95.9681 36.9998L89.3282 36.9998C87.7818 36.9998 86.5282 38.2534 86.5282 39.7998C86.5282 40.8669 87.1251 41.7946 88.0032 42.2671C86.6664 42.0556 85.6443 40.898 85.6443 39.5017C85.6443 37.9553 86.8979 36.7017 88.4443 36.7017L95.0842 36.7017C95.5635 36.7017 96.0147 36.8221 96.4092 37.0344Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-4_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M111.528 39.5C111.528 38.6716 110.857 38 110.028 38L109.814 38L108.528 38L90.0283 38C89.1999 38 88.5283 38.6716 88.5283 39.5C88.5283 40.3284 89.1999 41 90.0283 41L106.528 41L110.028 41L110.243 41C110.953 41 111.528 40.4244 111.528 39.7143C111.528 39.6771 111.527 39.6401 111.525 39.6035C111.527 39.5693 111.528 39.5348 111.528 39.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="1-4_filter0_d"
            x="41.1201"
            y="191.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-4_filter1_d"
            x="119.12"
            y="191.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-4_filter2_d"
            x="27.3003"
            y="29.3003"
            width="131.279"
            height="170.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-4_filter3_i"
            x="43"
            y="45"
            width="98.9399"
            height="139.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="1-4_filter4_d"
            x="83.7637"
            y="34.8218"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-4_filter5_d"
            x="87.6485"
            y="36.1201"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="45.5"
            y1="199.8"
            x2="45.5"
            y2="192.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="60.5"
            y1="190.202"
            x2="60.5"
            y2="197.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="45.5"
            y1="199.798"
            x2="45.5"
            y2="192.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="123.5"
            y1="199.8"
            x2="123.5"
            y2="192.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="138.5"
            y1="190.202"
            x2="138.5"
            y2="197.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="123.5"
            y1="199.798"
            x2="123.5"
            y2="192.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
