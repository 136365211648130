import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement14Square2Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="315"
        height="333"
        viewBox="0 0 315 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="1-4_1-4_1-4_LUP">
          <g id="1-4_1-4_1-4_Group 1171">
            <g id="1-4_1-4_1-4_TopY">
              <g id="1-4_1-4_1-4_Group 889">
                <g id="1-4_1-4_1-4_Group 855">
                  <g id="1-4_1-4_1-4_Union" filter="url(#1-4_filter0_d)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M44.9995 287C43.8952 287 43 287.895 43 288.999C43 290.104 43.8952 290.999 44.9995 290.999L47.1665 290.999L47.1665 290.999L58 290.999L58 291L61.0005 291C62.1048 291 63 290.105 63 289.001C63 287.896 62.1048 287.001 61.0005 287.001L58.8332 287.001L58.8332 287L48 287L48 287L44.9995 287Z"
                      fill="url(#1-4_paint0_linear)"
                    />
                  </g>
                  <rect
                    id="1-4_1-4_1-4_Rectangle 114"
                    width="3.99896"
                    height="11.6667"
                    transform="matrix(-4.37114e-08 1 1 4.37114e-08 47.1665 287)"
                    fill="#ABABAB"
                  />
                  <path
                    id="1-4_1-4_1-4_Rectangle 111"
                    d="M43.9399 287C43.4208 287 43 287.421 43 287.94L43 290.059C43 290.578 43.4208 290.999 43.9399 290.999L48 290.999L48 287L43.9399 287Z"
                    fill="url(#1-4_paint1_linear)"
                  />
                  <path
                    id="1-4_1-4_1-4_Rectangle 112"
                    d="M62.0601 291C62.5792 291 63 290.579 63 290.06L63 287.941C63 287.422 62.5792 287.001 62.0601 287.001L58 287.001L58 291L62.0601 291Z"
                    fill="url(#1-4_paint2_linear)"
                  />
                  <rect
                    id="1-4_1-4_1-4_Rectangle 113"
                    width="3.99896"
                    height="8.33334"
                    transform="matrix(-4.37114e-08 1 1 4.37114e-08 48.8335 287)"
                    fill="url(#1-4_paint3_linear)"
                  />
                </g>
              </g>
              <g id="1-4_1-4_1-4_Group 890">
                <g id="1-4_1-4_1-4_Group 855_2">
                  <g id="1-4_1-4_1-4_Union_2" filter="url(#1-4_filter1_d)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M122.999 287C121.895 287 121 287.895 121 288.999C121 290.104 121.895 290.999 122.999 290.999L125.167 290.999L125.167 290.999L136 290.999L136 291L139.001 291C140.105 291 141 290.105 141 289.001C141 287.896 140.105 287.001 139.001 287.001L136.833 287.001L136.833 287L126 287L126 287L122.999 287Z"
                      fill="url(#1-4_paint4_linear)"
                    />
                  </g>
                  <rect
                    id="1-4_1-4_1-4_Rectangle 114_2"
                    width="3.99896"
                    height="11.6667"
                    transform="matrix(-4.37114e-08 1 1 4.37114e-08 125.167 287)"
                    fill="#ABABAB"
                  />
                  <path
                    id="1-4_Rectangle 111_2"
                    d="M121.94 287C121.421 287 121 287.421 121 287.94L121 290.059C121 290.578 121.421 290.999 121.94 290.999L126 290.999L126 287L121.94 287Z"
                    fill="url(#1-4_paint5_linear)"
                  />
                  <path
                    id="1-4_Rectangle 112_2"
                    d="M140.06 291C140.579 291 141 290.579 141 290.06L141 287.941C141 287.422 140.579 287.001 140.06 287.001L136 287.001L136 291L140.06 291Z"
                    fill="url(#1-4_paint6_linear)"
                  />
                  <rect
                    id="1-4_Rectangle 113_2"
                    width="3.99896"
                    height="8.33334"
                    transform="matrix(-4.37114e-08 1 1 4.37114e-08 126.833 287)"
                    fill="url(#1-4_paint7_linear)"
                  />
                </g>
              </g>
              <g id="1-4_Group 891">
                <g id="1-4_Exclude" filter="url(#1-4_filter2_d)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M152 34H32V287H152V34ZM141 45H43V276H141V45Z"
                    fill="#E8EAEE"
                  />
                </g>
                <path
                  id="1-4_Subtract"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M152 34H32V287L32.8872 287V34.937H151.109V287L152 287V34Z"
                  fill="#FBFBFB"
                />
                <rect
                  id="1-4_Rectangle 102"
                  x="36.53"
                  y="38.53"
                  width="110.94"
                  height="243.94"
                  stroke="#E2E4E8"
                  strokeWidth="0.93994"
                />
                <g id="1-4_Rectangle 114_3" filter="url(#1-4_filter3_i)">
                  <rect
                    x="43.47"
                    y="45.47"
                    width="97.0601"
                    height="230.06"
                    stroke="#979797"
                    strokeWidth="0.93994"
                  />
                </g>
              </g>
              <path
                id="1-4_Polygon 2 (Stroke)"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M92 46L44 275H140L92 46ZM92 50.3351L45.1068 274.055H138.893L92 50.3351Z"
                fill="white"
              />
            </g>
            <g id="1-4_Group 868">
              <g id="1-4_Group 1170">
                <g id="1-4_Group 834">
                  <g id="1-4_Group 842">
                    <rect
                      id="1-4_Rectangle 94"
                      x="98.5283"
                      y="36"
                      width="7"
                      height="13.5283"
                      rx="3.5"
                      transform="rotate(90 98.5283 36)"
                      fill="#9B9DA2"
                      fillOpacity="0.19"
                    />
                    <g id="1-4_Rectangle 88" filter="url(#1-4_filter4_d)">
                      <rect
                        x="97.8843"
                        y="36.7018"
                        width="5.6"
                        height="12.2399"
                        rx="2.8"
                        transform="rotate(90 97.8843 36.7018)"
                        fill="#E8EAEE"
                      />
                    </g>
                    <path
                      id="1-4_Intersect"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M97.7664 38.695C97.42 37.5419 96.3503 36.7018 95.0844 36.7018L88.4445 36.7018C86.8981 36.7018 85.6445 37.9554 85.6445 39.5018C85.6445 39.537 85.6451 39.572 85.6464 39.6068C85.9928 40.7599 87.0625 41.6 88.3284 41.6L94.9683 41.6C96.5147 41.6 97.7683 40.3464 97.7683 38.8C97.7683 38.7648 97.7677 38.7298 97.7664 38.695Z"
                      fill="#DFE0E2"
                    />
                    <path
                      id="1-4_Subtract_2"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M96.4092 37.0345C96.2655 37.0118 96.1182 37 95.9681 37L89.3282 37C87.7818 37 86.5282 38.2536 86.5282 39.8C86.5282 40.8671 87.1251 41.7947 88.0032 42.2673C86.6664 42.0557 85.6443 40.8981 85.6443 39.5018C85.6443 37.9554 86.8979 36.7018 88.4443 36.7018L95.0842 36.7018C95.5635 36.7018 96.0147 36.8223 96.4092 37.0345Z"
                      fill="#FBFBFB"
                    />
                  </g>
                </g>
                <g id="1-4_Group 853">
                  <g id="1-4_Union_3" filter="url(#1-4_filter5_d)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M111.528 39.5C111.528 38.6716 110.857 38 110.028 38L109.814 38L108.528 38L90.0283 38C89.1999 38 88.5283 38.6716 88.5283 39.5C88.5283 40.3284 89.1999 41 90.0283 41L106.528 41L110.028 41L110.243 41C110.953 41 111.528 40.4244 111.528 39.7143C111.528 39.6771 111.527 39.6401 111.525 39.6035C111.527 39.5693 111.528 39.5348 111.528 39.5Z"
                      fill="#E8EAEE"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="1-4_filter0_d"
            x="41.1201"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-4_filter1_d"
            x="119.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-4_filter2_d"
            x="27.3003"
            y="29.3003"
            width="131.279"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-4_filter3_i"
            x="43"
            y="45"
            width="98.9399"
            height="233.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="1-4_filter4_d"
            x="83.7644"
            y="34.8219"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-4_filter5_d"
            x="87.6485"
            y="36.1201"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="1-4_paint0_linear"
            x1="60.5"
            y1="284.2"
            x2="60.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="1-4_paint1_linear"
            x1="45.5"
            y1="293.798"
            x2="45.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="1-4_paint2_linear"
            x1="60.5"
            y1="284.202"
            x2="60.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="1-4_paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="1-4_paint4_linear"
            x1="138.5"
            y1="284.2"
            x2="138.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="1-4_paint5_linear"
            x1="123.5"
            y1="293.798"
            x2="123.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="1-4_paint6_linear"
            x1="138.5"
            y1="284.202"
            x2="138.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="1-4_paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
