import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement55Square8Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#5-5_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M38.9995 161C37.8952 161 37 161.895 37 162.999C37 164.104 37.8952 164.999 38.9995 164.999L41.1665 164.999L41.1665 164.999L52 164.999L52 165L55.0005 165C56.1048 165 57 164.105 57 163.001C57 161.896 56.1048 161.001 55.0005 161.001L52.8332 161.001L52.8332 161L42 161L42 161L38.9995 161Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 41.1665 161)"
          fill="#ABABAB"
        />
        <path
          d="M37.9399 161C37.4208 161 37 161.421 37 161.94L37 164.059C37 164.578 37.4208 164.999 37.9399 164.999L42 164.999L42 161L37.9399 161Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M56.0601 165C56.5792 165 57 164.579 57 164.06L57 161.941C57 161.422 56.5792 161.001 56.0601 161.001L52 161.001L52 165L56.0601 165Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 42.8334 161)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#5-5_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M98.9995 161C97.8952 161 97 161.895 97 162.999C97 164.104 97.8952 164.999 98.9995 164.999L101.167 164.999L101.167 164.999L112 164.999L112 165L115.001 165C116.105 165 117 164.105 117 163.001C117 161.896 116.105 161.001 115.001 161.001L112.833 161.001L112.833 161L102 161L102 161L98.9995 161Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 101.167 161)"
          fill="#ABABAB"
        />
        <path
          d="M97.9399 161C97.4208 161 97 161.421 97 161.94L97 164.059C97 164.578 97.4208 164.999 97.9399 164.999L102 164.999L102 161L97.9399 161Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M116.06 165C116.579 165 117 164.579 117 164.06L117 161.941C117 161.422 116.579 161.001 116.06 161.001L112 161.001L112 165L116.06 165Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 102.833 161)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#5-5_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M121 165H32V286H121V165ZM110 176H43V275H110V176Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M121 165H32V286H33.2192V166H119.781V286H121V165Z"
          fill="#FBFBFB"
        />
        <g filter="url(#5-5_filter3_i)">
          <rect
            x="43.47"
            y="176.47"
            width="66.0601"
            height="98.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="169.53"
          width="79.9399"
          height="111.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="83"
          y="277"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 83 277)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="82.356"
          y="277.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 82.356 277.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.2379 279.695C81.8915 278.542 80.8218 277.702 79.5559 277.702L72.916 277.702C71.3696 277.702 70.116 278.955 70.116 280.502C70.116 280.537 70.1167 280.572 70.118 280.607C70.4643 281.76 71.534 282.6 72.8 282.6L79.4399 282.6C80.9863 282.6 82.2399 281.346 82.2399 279.8C82.2399 279.765 82.2392 279.73 82.2379 279.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M80.881 278.034C80.7373 278.012 80.59 278 80.4399 278L73.8 278C72.2536 278 71 279.253 71 280.8C71 281.867 71.5969 282.795 72.475 283.267C71.1382 283.056 70.1161 281.898 70.1161 280.502C70.1161 278.955 71.3697 277.702 72.9161 277.702L79.556 277.702C80.0353 277.702 80.4865 277.822 80.881 278.034Z"
          fill="#FBFBFB"
        />
        <g filter="url(#5-5_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M79 280.5C79 279.672 78.3284 279 77.5 279L77.2857 279L76 279L57.5 279C56.6716 279 56 279.672 56 280.5V280.5C56 281.328 56.6716 282 57.5 282L74 282L77.5 282L77.7143 282C78.4244 282 79 281.424 79 280.714V280.714C79 280.677 78.9988 280.64 78.9965 280.604C78.9988 280.569 79 280.535 79 280.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M108.305 177.5L76.5 272.426L44.6948 177.5L108.305 177.5Z" stroke="white" />
        <defs>
          <filter
            id="5-5_filter0_d"
            x="35.1201"
            y="159.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-5_filter1_d"
            x="95.1201"
            y="159.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-5_filter2_d"
            x="27.3003"
            y="160.3"
            width="100.279"
            height="132.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-5_filter3_i"
            x="43"
            y="176"
            width="67.9399"
            height="101.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-5_filter4_d"
            x="54.28"
            y="277.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="54.5"
            y1="158.2"
            x2="54.5"
            y2="165.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="39.5"
            y1="167.798"
            x2="39.5"
            y2="160.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="54.5"
            y1="158.202"
            x2="54.5"
            y2="165.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="114.5"
            y1="158.2"
            x2="114.5"
            y2="165.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="99.5"
            y1="167.798"
            x2="99.5"
            y2="160.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="114.5"
            y1="158.202"
            x2="114.5"
            y2="165.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
