import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement43Square5Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-3_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M139.001 203C140.105 203 141 202.105 141 201.001C141 199.896 140.105 199.001 139.001 199.001L136.833 199.001L136.833 199.001L126 199.001L126 199L122.999 199C121.895 199 121 199.895 121 200.999C121 202.104 121.895 202.999 122.999 202.999L125.167 202.999L125.167 203L136 203L136 203L139.001 203Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 136.834 203)"
          fill="#ABABAB"
        />
        <path
          d="M140.06 203C140.579 203 141 202.579 141 202.06L141 199.941C141 199.422 140.579 199.001 140.06 199.001L136 199.001L136 203L140.06 203Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M121.94 199C121.421 199 121 199.421 121 199.94L121 202.059C121 202.578 121.421 202.999 121.94 202.999L126 202.999L126 199L121.94 199Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 135.167 203)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#4-3_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M61.0005 203C62.1048 203 63 202.105 63 201.001C63 199.896 62.1048 199.001 61.0005 199.001L58.8335 199.001L58.8335 199.001L48 199.001L48 199L44.9995 199C43.8952 199 43 199.895 43 200.999C43 202.104 43.8952 202.999 44.9995 202.999L47.1668 202.999L47.1668 203L58 203L58 203L61.0005 203Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 58.834 203)"
          fill="#ABABAB"
        />
        <path
          d="M62.0601 203C62.5792 203 63 202.579 63 202.06L63 199.941C63 199.422 62.5792 199.001 62.0601 199.001L58 199.001L58 203L62.0601 203Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M43.9399 199C43.4208 199 43 199.421 43 199.94L43 202.059C43 202.578 43.4208 202.999 43.9399 202.999L48 202.999L48 199L43.9399 199Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 57.167 203)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#4-3_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M152 203H32V287H152V203ZM141 214H43V276H141V214Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M152 203H32V287H33V204H151V287H152V203Z"
          fill="#FBFBFB"
        />
        <rect
          x="36.53"
          y="207.53"
          width="110.94"
          height="74.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#4-3_filter3_i)">
          <rect
            x="43.47"
            y="214.47"
            width="97.0601"
            height="61.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="99"
          y="278"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 99 278)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="98.3555"
          y="278.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 98.3555 278.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M98.2383 280.695C97.8919 279.542 96.8222 278.702 95.5563 278.702L88.9164 278.702C87.37 278.702 86.1164 279.956 86.1164 281.502C86.1164 281.537 86.117 281.572 86.1183 281.607C86.4647 282.76 87.5344 283.6 88.8003 283.6L95.4402 283.6C96.9866 283.6 98.2402 282.347 98.2402 280.8C98.2402 280.765 98.2396 280.73 98.2383 280.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M96.8809 279.035C96.7372 279.012 96.5899 279 96.4398 279L89.7999 279C88.2535 279 86.9999 280.254 86.9999 281.8C86.9999 282.867 87.5968 283.795 88.4749 284.268C87.1381 284.056 86.116 282.898 86.116 281.502C86.116 279.956 87.3696 278.702 88.916 278.702L95.5559 278.702C96.0352 278.702 96.4864 278.823 96.8809 279.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-3_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M95 281.5C95 280.672 94.3284 280 93.5 280L93.2857 280L92 280L73.5 280C72.6716 280 72 280.672 72 281.5C72 282.328 72.6716 283 73.5 283L90 283L93.5 283L93.7143 283C94.4244 283 95 282.424 95 281.714C95 281.677 94.9988 281.64 94.9965 281.604C94.9988 281.569 95 281.535 95 281.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M45.0403 215.5L138.96 215.5L92 274.2L45.0403 215.5Z" stroke="white" />
        <defs>
          <filter
            id="4-3_filter0_d"
            x="119.12"
            y="197.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-3_filter1_d"
            x="41.1201"
            y="197.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-3_filter2_d"
            x="27.3003"
            y="198.3"
            width="131.279"
            height="95.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-3_filter3_i"
            x="43"
            y="214"
            width="98.9399"
            height="64.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-3_filter4_d"
            x="70.28"
            y="278.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="123.5"
            y1="205.8"
            x2="123.5"
            y2="198.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="138.5"
            y1="196.202"
            x2="138.5"
            y2="203.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="123.5"
            y1="205.798"
            x2="123.5"
            y2="198.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="45.5"
            y1="205.8"
            x2="45.5"
            y2="198.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="60.5"
            y1="196.202"
            x2="60.5"
            y2="203.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="45.5"
            y1="205.798"
            x2="45.5"
            y2="198.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
