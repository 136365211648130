import React, { FC } from 'react'

import { IRenderSketchProps } from './interface'
import {
  previewSizesHorizontal,
  previewSizesHorizontalAfter,
  previewSizesHorizontalAfterStyle,
  previewSizesHorizontalStyle,
  previewSizesHorizontalWrapperStyle,
  previewSizesText,
  previewSizesTextStyle,
  previewSizesVerticalAfterStyle,
  previewSizesVerticalStyle,
  previewSizesVerticalWrapper,
  previewWrapperStyle,
} from './styles'

export const RenderSketch: FC<IRenderSketchProps> = props => {
  const { sketchSizes, imageSizes = { width: 300, height: 300 }, images } = props

  return (
    <div style={previewWrapperStyle(imageSizes)}>
      <div style={previewSizesHorizontalWrapperStyle()}>
        {sketchSizes['casements-x'] &&
          sketchSizes['casements-x'].map((x, index) => (
            <div style={previewSizesHorizontal} key={`casements-x-${x}-${index}`}>
              <div style={previewSizesText}>{x}</div>
              <div style={previewSizesHorizontalAfter} />
            </div>
          ))}
      </div>
      <div style={previewSizesVerticalWrapper()}>
        {sketchSizes['casements-y'] &&
          sketchSizes['casements-y'].map((y, index) => (
            <div
              style={previewSizesVerticalStyle(sketchSizes['casements-y'].length)}
              key={`casements-y-${y}-${index}`}
            >
              <span style={previewSizesTextStyle(true)}>{y}</span>
              <div style={previewSizesVerticalAfterStyle} />
            </div>
          ))}
      </div>
      <div style={previewSizesHorizontalWrapperStyle(true)}>
        <div style={previewSizesHorizontalStyle}>
          <span style={previewSizesTextStyle()}>{sketchSizes['model-width']}</span>
          <div style={previewSizesHorizontalAfterStyle} />
        </div>
      </div>
      <div style={previewSizesVerticalWrapper(true)}>
        <div style={previewSizesVerticalStyle()}>
          <span style={previewSizesTextStyle(true, '45%')}>{sketchSizes['model-height']}</span>
          <div style={previewSizesVerticalAfterStyle} />
        </div>
      </div>
      {images}
    </div>
  )
}
