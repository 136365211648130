import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement7Door1LCasement1 = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="451"
        height="451"
        viewBox="0 0 451 451"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#door-1-l-casement-1-7-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M176 384.999C176 383.895 175.105 383 174.001 383C172.896 383 172.001 383.895 172.001 384.999V387.167H172.001V398H172V401.001C172 402.105 172.895 403 173.999 403C175.104 403 175.999 402.105 175.999 401.001V398.833H176V388H176V384.999Z"
            fill="url(#door-1-l-casement-1-7-paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 176 387.167)"
          fill="#ABABAB"
        />
        <path
          d="M176 383.94C176 383.421 175.579 383 175.06 383H172.941C172.422 383 172.001 383.421 172.001 383.94V388H176V383.94Z"
          fill="url(#door-1-l-casement-1-7-paint1_linear)"
        />
        <path
          d="M172 402.06C172 402.579 172.421 403 172.94 403H175.059C175.578 403 175.999 402.579 175.999 402.06V398H172V402.06Z"
          fill="url(#door-1-l-casement-1-7-paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 176 388.833)"
          fill="url(#door-1-l-casement-1-7-paint3_linear)"
        />
        <g filter="url(#door-1-l-casement-1-7-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M175.954 50.9765C175.954 49.8849 175.069 49 173.978 49C172.886 49 172.001 49.8849 172.001 50.9765V53.1665H172.001V64H172V67.0235C172 68.1151 172.885 69 173.976 69C175.068 69 175.953 68.1151 175.953 67.0235V64.8332H175.954V54H175.954V50.9765Z"
            fill="url(#door-1-l-casement-1-7-paint4_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-1 0 0 1 175.954 53.1665)"
          fill="#ABABAB"
        />
        <path
          d="M175.954 49.9399C175.954 49.4208 175.533 49 175.014 49H172.941C172.422 49 172.001 49.4208 172.001 49.9399V54H175.954V49.9399Z"
          fill="url(#door-1-l-casement-1-7-paint5_linear)"
        />
        <path
          d="M172 68.0601C172 68.5792 172.421 69 172.94 69H175.013C175.532 69 175.953 68.5792 175.953 68.0601V64H172V68.0601Z"
          fill="url(#door-1-l-casement-1-7-paint6_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-1 0 0 1 175.954 54.8334)"
          fill="url(#door-1-l-casement-1-7-paint7_linear)"
        />
        <g filter="url(#door-1-l-casement-1-7-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 34H172V419H32V34ZM48 50H156V293H48V50ZM157 312H48V403H157V312Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M172 34H32V419H33V35H171V419H172V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="36.53"
          y="38.53"
          width="130.94"
          height="375.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="48.47"
          y="312.47"
          width="108.06"
          height="90.0601"
          fill="#E8EAEE"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#door-1-l-casement-1-7-filter3_i)">
          <rect
            x="48.4691"
            y="50.47"
            width="107.06"
            height="242.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          width="6.91954"
          height="13.5283"
          rx="3.45977"
          transform="matrix(-1 0 0 1 43.9194 209)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#door-1-l-casement-1-7-filter4_d)">
          <rect
            width="5.53563"
            height="12.2399"
            rx="2.76782"
            transform="matrix(-1 0 0 1 43.2253 209.644)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.6604 220.792C42.1548 221.456 41.3562 221.884 40.4577 221.884C38.9291 221.884 37.6899 220.645 37.6899 219.116V212.412C37.6899 211.44 38.1907 210.585 38.9482 210.092C38.5936 210.557 38.383 211.138 38.383 211.768V218.472C38.383 220.001 39.6222 221.24 41.1508 221.24C41.7078 221.24 42.2262 221.075 42.6604 220.792Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.4575 209.644C41.9861 209.644 43.2253 210.883 43.2253 212.412V218.472C43.2253 218.492 43.2251 218.512 43.2247 218.533V212.991C43.2247 211.463 41.9855 210.224 40.4569 210.224C38.9485 210.224 37.722 211.43 37.6897 212.931V212.412C37.6897 210.883 38.9289 209.644 40.4575 209.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#door-1-l-casement-1-7-filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40.4597 213C41.2787 213 41.9425 213.664 41.9425 214.483V216C41.9425 216.014 41.9424 216.027 41.9421 216.041L41.9421 234.517C41.9421 235.336 41.2782 236 40.4593 236C39.6404 236 38.9766 235.336 38.9766 234.517L38.9766 214.251C38.9766 213.56 39.5368 213 40.2278 213C40.2307 213 40.2335 213 40.2363 213C40.3107 213 40.3853 213 40.4597 213Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M49.6659 171.999L154.5 290.679V52.3295L49.6659 171.999Z" stroke="white" />
        <defs>
          <filter
            id="door-1-l-casement-1-7-filter0_d"
            x="170.12"
            y="381.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-1-7-filter1_d"
            x="170.12"
            y="47.1201"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-1-7-filter2_d"
            x="27.3003"
            y="29.3003"
            width="151.279"
            height="396.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-1-7-filter3_i"
            x="47.9991"
            y="50"
            width="108.94"
            height="245.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="door-1-l-casement-1-7-filter4_d"
            x="35.8098"
            y="207.764"
            width="11.1753"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-1-7-filter5_d"
            x="37.0967"
            y="211.12"
            width="8.60556"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="door-1-l-casement-1-7-paint0_linear"
            x1="178.8"
            y1="400.5"
            x2="171.2"
            y2="400.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-1-7-paint1_linear"
            x1="169.202"
            y1="385.5"
            x2="176.8"
            y2="385.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-1-7-paint2_linear"
            x1="178.798"
            y1="400.5"
            x2="171.2"
            y2="400.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-1-7-paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-1-7-paint4_linear"
            x1="178.722"
            y1="66.5"
            x2="171.209"
            y2="66.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-1-7-paint5_linear"
            x1="169.234"
            y1="51.5"
            x2="176.745"
            y2="51.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-1-7-paint6_linear"
            x1="178.72"
            y1="66.5"
            x2="171.209"
            y2="66.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-1-7-paint7_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
