import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement34Square8Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#3-4_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M230 46.9995C230 45.8952 229.105 45 228.001 45C226.896 45 226.001 45.8952 226.001 46.9995V49.1665H226.001V60H226V63.0005C226 64.1048 226.895 65 227.999 65C229.104 65 229.999 64.1048 229.999 63.0005V60.8332H230V50H230V46.9995Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 230 49.1665)"
          fill="#ABABAB"
        />
        <path
          d="M230 45.9399C230 45.4208 229.579 45 229.06 45H226.941C226.422 45 226.001 45.4208 226.001 45.9399V50H230V45.9399Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M226 64.0601C226 64.5792 226.421 65 226.94 65H229.059C229.578 65 229.999 64.5792 229.999 64.0601V60H226V64.0601Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 230 50.8335)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#3-4_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M230 123C230 121.895 229.105 121 228 121C226.895 121 226 121.895 226 123V137.56H230V123ZM230 137.56H226V139.4H225.94C225.421 139.4 225 139.821 225 140.34V142.12C225 143.158 225.842 144 226.88 144H228.12C229.158 144 230 143.158 230 142.12V139.4V139.4V137.56Z"
            fill="white"
          />
        </g>
        <rect
          width="3.99998"
          height="0.95833"
          transform="matrix(-1 0 0 1 230 138.25)"
          fill="#ABABAB"
        />
        <path
          d="M230 121.94C230 121.421 229.579 121 229.06 121H226.94C226.421 121 226 121.421 226 121.94V138.25H230V121.94Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M230 143.06C230 143.579 229.579 144 229.06 144H225.94C225.421 144 225 143.579 225 143.06V140.148C225 139.629 225.421 139.208 225.94 139.208H230V143.06Z"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#3-4_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M319 34H230V155H319V34ZM308 45H241V144H308V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M319 34H230V155H231.219V35H317.781V155H319V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-4_filter3_i)">
          <rect
            x="241.47"
            y="45.47"
            width="66.0601"
            height="98.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="234.53"
          y="38.53"
          width="79.9399"
          height="111.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="310"
          y="87"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#3-4_filter4_d)">
          <rect x="310.702" y="87.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M311.286 98.7956C311.798 99.4577 312.6 99.8841 313.502 99.8841C315.048 99.8841 316.302 98.6305 316.302 97.0841V90.4442C316.302 89.4557 315.79 88.5869 315.016 88.0886C315.382 88.5619 315.6 89.1556 315.6 89.8001V96.44C315.6 97.9864 314.347 99.24 312.8 99.24C312.242 99.24 311.722 99.0768 311.286 98.7956Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M313.502 87.644C311.956 87.644 310.702 88.8976 310.702 90.444V96.4397C310.702 96.4601 310.702 96.4805 310.703 96.5007V91.0236C310.703 89.4772 311.956 88.2236 313.503 88.2236C315.029 88.2236 316.269 89.4444 316.302 90.9627V90.444C316.302 88.8976 315.048 87.644 313.502 87.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-4_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M313.5 91C312.672 91 312 91.6716 312 92.5V92.7143V94V112.5C312 113.328 312.672 114 313.5 114V114C314.328 114 315 113.328 315 112.5V96V92.5L315 92.2857C315 91.5756 314.424 91 313.714 91V91C313.677 91 313.64 91.0012 313.604 91.0035C313.569 91.0012 313.535 91 313.5 91Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M242.5 46.9969L306.164 94.5L242.5 142.003L242.5 46.9969Z" stroke="white" />
        <path d="M242.695 142.5L274.5 47.5739L306.305 142.5L242.695 142.5Z" stroke="white" />
        <defs>
          <filter
            id="3-4_filter0_d"
            x="224.12"
            y="43.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-4_filter1_d"
            x="223.12"
            y="119.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-4_filter2_d"
            x="225.3"
            y="29.3003"
            width="100.279"
            height="132.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-4_filter3_i"
            x="241"
            y="45"
            width="67.9399"
            height="101.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-4_filter4_d"
            x="308.822"
            y="85.7642"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-4_filter5_d"
            x="310.12"
            y="89.1201"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="232.8"
            y1="62.5"
            x2="225.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="223.202"
            y1="47.5"
            x2="230.8"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="232.798"
            y1="62.5"
            x2="225.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="223.2"
            y1="129.625"
            x2="230.8"
            y2="129.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="221.5"
            y1="141.604"
            x2="231"
            y2="141.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
