import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import configureStore from './react-app/store'
import App from './react-app/entries/b2b'
import * as serviceWorker from './serviceWorker'
import { getLoadSessionAction } from './react-app/store/session/action-creators'
import { LOAD_PAGE_DONE, LOAD_PAGE_FAILED } from './react-app/store/page/actions'

declare global {
  interface Window {
    ym: any,
    roistatGoal: any,
  }
}

const store = configureStore({
  page: {
    isLoading: true,
  },
  // initial state
})

const dataToLoad = [
  // @ts-ignore
  store.dispatch(getLoadSessionAction()),
]

Promise.all(dataToLoad)
  .then(data => {
    // @ts-ignore
    store.dispatch({ type: LOAD_PAGE_DONE, payload: data })
  })
  .catch(err => {
    store.dispatch({ type: LOAD_PAGE_FAILED, payload: err })
  })

ReactDOM.render(
  <React.StrictMode>
    {/* @ts-ignore */}
    <BrowserRouter>
      <App store={store} />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('calculator'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
