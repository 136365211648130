import React, { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import B2BLayout from '../../layouts/b2b'

export const CalculatorOrder = () => {
  const { id } = useParams() as any
  const layoutRef = useRef<HTMLDivElement>(null)
  const iframeRef = useRef<HTMLIFrameElement>(null)

  const breadcrumbs = [
    {
      href: '/sales/orders',
      title: 'Заказы',
    },
    {
      href: `/calculator/${id}`,
      title: 'Список клиентов',
    },
  ]

  useEffect(() => {
    if (iframeRef.current && layoutRef.current) {
      iframeRef.current.style.height = `${layoutRef.current.offsetHeight}px`
    }
  }, [])

  return (
    <B2BLayout breadcrumbs={breadcrumbs} layoutRef={layoutRef}>
      <iframe
        width={1630}
        src="http://localhost:3000/calculator"
        frameBorder="0"
        ref={iframeRef}
        title="Calculator"
      />
    </B2BLayout>
  )
}
