import React, { FC, useCallback, useMemo, useState, useEffect } from 'react'

import {
  StageOrderProductsListItem,
  StageOrderProductsListItemDescBlock,
  StageOrderProductsListItemDescBlockDesc,
  StageOrderProductsListItemDescBlockTitle,
  StageOrderProductsListItemPriceTitle,
  StageOrderProductsListItemPriceValue,
  StageOrderProductsListItemPriceWrapper,
  StageOrderProductsMain,
  StageOrderShowMoreBlockWrapper,
  StageOrderTitleWrapper,
  StageOrderToggleButton,
  StageOrderToggleWrapper,
} from './styles'
import { DescriptionTypeWindow } from './description-type-window'
import { IOrderItemProps } from '../stage-calculator/interface'
import { getElementsData, replaceHandlebars } from '../../../utilits'
import { Info } from '../../../components'
import { LinkButton, SubTitleText } from '../styles'
import { TrashIcon } from '../../../assets/icons'

export const OrderItem: FC<IOrderItemProps> = props => {
  const { product, count = 1, itemNumber, sizes, onDeleteOrder, totalPrice = null, filters, isSdk, bitrixId } = props
  const [showMore, setShowMore] = useState(false)
  const [elementsData, setElementsData] = useState<Record<string, any>>({})

  useEffect(() => {
    let result = {}

    if (totalPrice && totalPrice['additionalPrice']) {
      result = getElementsData(product, totalPrice['additionalPrice'], sizes, filters)
    }

    setElementsData(result)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPrice])

  const toggleSHowMore = useCallback(() => {
    setShowMore(prevState => !prevState)
  }, [])

  const productDescription = useMemo(
    () => replaceHandlebars('configuration', 'name', product, sizes, filters),
    [filters, product, sizes],
  )

  const isNonStandard = useMemo(() => {
    return product?.['window-shape']?.['is-non-standard-shape'] === true
  }, [product])

  const totalPriceCorrect = useMemo(() => {
    const sumElements = Object
      .values(elementsData)
      .reduce((acc, item) => 
      (Number(item?.['price']) || 0) + acc, 0)
    const allSum = sumElements + (totalPrice?.['itemPrice'] || 0)
    return Math.trunc(allSum)
  }, [elementsData, totalPrice])

  return (
    <StageOrderProductsListItem key={product?.['configuration']?.['id'] || bitrixId}>
      <StageOrderProductsMain>
        <StageOrderProductsListItemDescBlock>
          <StageOrderProductsListItemDescBlockTitle>
            <span>
              {bitrixId && product?.['name']
                ? product['name']
                : `${isSdk ? 'Готовое изделие' : 'Изделие'} №${itemNumber}`}
            </span>
            {isNonStandard && (
              <StageOrderTitleWrapper>
                <SubTitleText>Нестандартное изделие</SubTitleText>
                <Info color="#BBC3D6">Нестандартное изделие</Info>
              </StageOrderTitleWrapper>
            )}
          </StageOrderProductsListItemDescBlockTitle>
          <StageOrderProductsListItemDescBlockDesc
            dangerouslySetInnerHTML={{ __html: productDescription }}
          />
          {/*{!showMore && (
            <div>
              <StageOrderToggleButton onClick={toggleSHowMore}>Подробнее</StageOrderToggleButton>
            </div>
          )}
          {showMore && (
            <StageOrderToggleWrapper>
              <StageOrderToggleButton onClick={toggleSHowMore}>Скрыть</StageOrderToggleButton>
            </StageOrderToggleWrapper>
          )}*/}
        </StageOrderProductsListItemDescBlock>
        <StageOrderProductsListItemPriceWrapper>
          <StageOrderProductsListItemPriceTitle>
          Cтоимость:
          </StageOrderProductsListItemPriceTitle>
          <StageOrderProductsListItemPriceValue>
            {totalPriceCorrect?.toLocaleString('ru-RU') || '0'} ₽
          </StageOrderProductsListItemPriceValue>
          <div>{/* TODO: add edit button */}</div>
          <LinkButton onClick={onDeleteOrder}>
            <TrashIcon />
            Удалить
          </LinkButton>
        </StageOrderProductsListItemPriceWrapper>
      </StageOrderProductsMain>
      {showMore && (
        <StageOrderShowMoreBlockWrapper>
          <DescriptionTypeWindow
            product={product}
            filters={filters}
            sizes={sizes}
            count={count}
            itemPrice={totalPrice?.['itemPrice']}
            elementsData={elementsData}
          />
        </StageOrderShowMoreBlockWrapper>
      )}
    </StageOrderProductsListItem>
  )
}

export default OrderItem
