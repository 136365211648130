import styled from 'styled-components'

export const TabsWrapper = styled.div`
  position: relative;
`

export const TabsTitleWrapper = styled.div`
  margin-bottom: 16px;
`

export const TabsTitle = styled.span<{ active: boolean }>`
  font-size: 14px;

  cursor: pointer;

  color: ${({ active }) => (active ? '#333333' : '#7A7A7A')};

  margin-right: 17px;
`

export const TabsContent = styled.div``
