import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement11Square3Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#1-1_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 41.9995C32 40.8952 31.1048 40 30.0005 40C28.8962 40 28.001 40.8952 28.001 41.9995V44.1665H28.001V55H28V58.0005C28 59.1048 28.8952 60 29.9995 60C31.1038 60 31.999 59.1048 31.999 58.0005V55.8332H32V45H32V41.9995Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 32 44.166)"
          fill="#ABABAB"
        />
        <path
          d="M32 40.9399C32 40.4208 31.5792 40 31.0601 40H28.941C28.4219 40 28.001 40.4208 28.001 40.9399V45H32V40.9399Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M28 59.0601C28 59.5792 28.4208 60 28.9399 60H31.059C31.5781 60 31.999 59.5792 31.999 59.0601V55H28V59.0601Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 32 45.833)"
          fill="url(#paint1-1_linear)"
        />
        <g filter="url(#1-1_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 86.9995C32 85.8952 31.1048 85 30.0005 85C28.8962 85 28.001 85.8952 28.001 86.9995V89.1665H28.001V100H28V103.001C28 104.105 28.8952 105 29.9995 105C31.1038 105 31.999 104.105 31.999 103.001V100.833H32V90H32V86.9995Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 32 89.166)"
          fill="#ABABAB"
        />
        <path
          d="M32 85.9399C32 85.4208 31.5792 85 31.0601 85H28.941C28.4219 85 28.001 85.4208 28.001 85.9399V90H32V85.9399Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M28 104.06C28 104.579 28.4208 105 28.9399 105H31.059C31.5781 105 31.999 104.579 31.999 104.06V100H28V104.06Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 32 90.833)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#1-1_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M152 34H32V111H152V34ZM141 45H43V100H141V45Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#1-1_filter1-1_i)">
          <rect
            x="43.47"
            y="45.47"
            width="97.0601"
            height="54.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="37.5" y="39.5" width="109" height="66" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M152 34H32V111H33V35H151V111H152V34Z"
          fill="#FBFBFB"
        />
        <path d="M44.5 98.3433L44.5 46.6567L138.121 72.5L44.5 98.3433Z" stroke="white" />
        <rect
          x="143"
          y="65"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#1-1_filter4_d)">
          <rect x="143.702" y="65.6445" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M144.286 76.7958C144.798 77.458 145.6 77.8843 146.502 77.8843C148.049 77.8843 149.302 76.6307 149.302 75.0843V68.4444C149.302 67.456 148.79 66.5871 148.017 66.0889C148.383 66.5621 148.601 67.1558 148.601 67.8004V74.4403C148.601 75.9867 147.347 77.2403 145.801 77.2403C145.243 77.2403 144.723 77.0771 144.286 76.7958Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M146.502 65.6445C144.956 65.6445 143.702 66.8981 143.702 68.4445V74.4402C143.702 74.4606 143.702 74.4809 143.703 74.5012V69.0241C143.703 67.4777 144.956 66.2241 146.503 66.2241C148.029 66.2241 149.27 67.4449 149.302 68.9631V68.4445C149.302 66.8981 148.049 65.6445 146.502 65.6445Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-1_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M146.5 69C145.672 69 145 69.6716 145 70.5V70.7143V72V90.5C145 91.3284 145.672 92 146.5 92C147.328 92 148 91.3284 148 90.5V74V70.5L148 70.2857C148 69.5756 147.424 69 146.714 69C146.677 69 146.64 69.0012 146.604 69.0035C146.569 69.0012 146.535 69 146.5 69Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="1-1_filter0_d"
            x="26.1201"
            y="38.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-1_filter1_d"
            x="26.1201"
            y="83.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-1_filter2_d"
            x="27.3003"
            y="29.3003"
            width="131.279"
            height="88.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-1_filter1-1_i"
            x="43"
            y="45"
            width="98.9399"
            height="57.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="1-1_filter4_d"
            x="141.822"
            y="63.7647"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-1_filter5_d"
            x="143.12"
            y="67.1201"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="34.8"
            y1="57.5"
            x2="27.2"
            y2="57.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="25.2018"
            y1="42.5"
            x2="32.7998"
            y2="42.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="34.7982"
            y1="57.5"
            x2="27.2002"
            y2="57.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint1-1_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="34.8"
            y1="102.5"
            x2="27.2"
            y2="102.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="25.2018"
            y1="87.5"
            x2="32.7998"
            y2="87.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="34.7982"
            y1="102.5"
            x2="27.2002"
            y2="102.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
