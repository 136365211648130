import React, { FC, useCallback } from 'react'
import { CalculatorStage, IStageSelectView } from '../interface'
import {
  SelectBlock,
  SelectItem,
  SelectItemIcon,
  SelectItems,
  SelectItemText,
  StageViewWrapper,
  SubTitle,
} from '../styles'
import { Button } from '../../../components'

export const StageView: FC<IStageSelectView> = props => {
  const { onChangeStage } = props

  const handleButtonClick = useCallback(
    (view: CalculatorStage) => () => {
      onChangeStage(view)
    },
    [onChangeStage],
  )

  return (
    <StageViewWrapper>
      <SelectBlock>
        <SubTitle>Хотите воспользоваться индивидуальным подбором или рассчитать сразу?</SubTitle>
        <SelectItems countColumns={2}>
          <SelectItem onClick={handleButtonClick(CalculatorStage.INDIVIDUAL)}>
            <SelectItemIcon />
            <SelectItemText>Мне нужен индивидуальный подбор</SelectItemText>
            <Button onClick={handleButtonClick(CalculatorStage.INDIVIDUAL)}>Перейти</Button>
          </SelectItem>
          <SelectItem onClick={handleButtonClick(CalculatorStage.INDIVIDUAL)}>
            <SelectItemIcon />
            <SelectItemText>Рассчитать сразу</SelectItemText>
            <Button onClick={handleButtonClick(CalculatorStage.CALCULATE)}>Перейти</Button>
          </SelectItem>
        </SelectItems>
      </SelectBlock>
    </StageViewWrapper>
  )
}
