import React, { FC } from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'
import { IContainerSquareProps } from '../../interface'
import { ContainerBackground } from '../container-background'

export const ContainerSquare4TopBottomCasement: FC<IContainerSquareProps> = props => {
  const { onCasementClick, withoutBackground } = props

  return (
    <ProductImageWrapper isClickable>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <rect width="449" height="333" fill="white" />
        <rect x="24" y="28" width="401" height="264" fill="#E8EAEE" />
        <rect x="24" y="28" width="401" height="264" fill="url(#4-t-b-pattern0)" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M439 10H10V292H24V29H425V292H439V10Z"
          fill="#C4C4C4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M439 292H10V308H0V316H10H439H449V308H439V292Z"
          fill="url(#4-t-b-paint0_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 28H425V292H24V28ZM36 106H214V214H36V106ZM412 234H37V279H412V234ZM234 106H412V214H234V106ZM412 41H37V87H412V41Z"
          fill="#E8EAEE"
        />
        <path d="M439 10L425 28.0571V292.075L439 308V10Z" fill="#E9EBEF" />
        <path d="M439 10L425 28.0571V292.075L439 308V10Z" fill="#020202" fillOpacity="0.06" />
        <path d="M10 10L24 28.1525V291.982L10 308V10Z" fill="#E9EBEF" />
        <path d="M10 10L24 28.1525V291.982L10 308V10Z" fill="#020202" fillOpacity="0.06" />
        <rect y="316" width="449" height="17" fill="white" />
        <g
          filter="url(#4-t-b-filter0_i)"
          className="casement"
          data-index="0"
          onClick={onCasementClick(0)}
        >
          <rect x="36" y="41" width="376" height="46" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="36.47"
          y="41.47"
          width="375.06"
          height="45.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#4-t-b-filter1_i)"
          className="casement"
          data-index="3"
          onClick={onCasementClick(3)}
        >
          <rect x="36" y="233" width="376" height="46" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="36.47"
          y="233.47"
          width="375.06"
          height="45.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <path
          d="M232.985 59.7403H230.447C229.8 59.7403 229.253 59.2336 229.253 58.6347V58.4044C229.253 57.8056 229.8 57.2988 230.447 57.2988H232.985C233.632 57.2988 234.18 57.8056 234.18 58.4044V58.6347C234.18 59.2566 233.657 59.7403 232.985 59.7403Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M215.426 62.5652C215.725 62.5652 215.799 62.5652 216.197 62.5652C216.943 62.5652 217.49 63.1052 217.49 63.833C217.49 64.5843 216.918 65.1008 216.147 65.1008C214.705 65.1008 213.81 65.1008 212.367 65.1008C211.82 65.1008 211.373 64.913 211.149 64.4199C210.95 64.1147 210.826 63.2695 211.621 62.5652C212.144 61.9782 217.913 55.7565 218.933 54.6765C218.982 54.6061 218.933 54.5122 218.858 54.5122C218.833 54.5122 218.833 54.5122 218.808 54.5122C216.645 54.5122 214.481 54.5122 212.318 54.5122C211.671 54.5122 211.174 54.1365 211.049 53.573C210.9 52.9156 211.199 52.3522 211.771 52.0939C211.945 52.0235 212.119 52 212.293 52C215.501 52 218.684 52 221.892 52C222.514 52 222.937 52.1878 223.185 52.7278C223.509 53.4087 223.16 53.8783 222.937 54.1835C222.489 54.7235 217.938 59.56 215.426 62.2834C215.401 62.3069 215.376 62.3539 215.327 62.3773C215.277 62.4478 215.327 62.5652 215.426 62.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M222.185 65.1007H219.647C219.001 65.1007 218.453 64.5816 218.453 63.9681V63.7322C218.453 63.1187 219.001 62.5996 219.647 62.5996H222.185C222.832 62.5996 223.38 63.1187 223.38 63.7322V63.9681C223.38 64.6052 222.832 65.1007 222.185 65.1007Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M225.716 58.5152C225.716 56.7341 225.716 54.9764 225.716 53.1952C225.716 52.4922 226.238 52 226.984 52C230.215 52 233.447 52 236.678 52C237.424 52 237.946 52.5156 237.946 53.2421C237.946 53.9686 237.424 54.5077 236.678 54.5077C233.994 54.5077 231.334 54.5077 228.649 54.5077C228.45 54.5077 228.401 54.5545 228.401 54.742C228.401 57.2731 228.401 59.8042 228.401 62.3353C228.401 62.5463 228.475 62.5697 228.649 62.5697C231.309 62.5697 233.994 62.5697 236.654 62.5697C237.25 62.5697 237.723 62.8978 237.897 63.39C238.095 63.9759 237.872 64.5618 237.399 64.8899C237.176 65.0305 236.902 65.1008 236.629 65.1008C233.447 65.1008 230.24 65.1008 227.058 65.1008C226.238 65.1008 225.741 64.5852 225.741 63.8118C225.716 62.0307 225.716 60.273 225.716 58.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M212.705 70.0413V70.6981H211V70.0413H211.426V68.2584H211.05V67.6016H212.68V68.2584H212.304V70.0413H212.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M216.535 70.7586H215.774L215.651 70.5458C215.381 70.6877 215.062 70.7586 214.768 70.7586C213.934 70.735 213.148 70.1675 213.148 69.1508C213.148 68.1814 213.811 67.543 214.817 67.543C215.651 67.543 216.461 68.0868 216.461 69.1508C216.461 69.5291 216.363 69.8128 216.216 70.0729L216.559 70.6404V70.7586H216.535ZM214.792 70.002C215.185 70.002 215.602 69.7183 215.602 69.1508C215.602 68.5833 215.185 68.2996 214.792 68.2996C214.375 68.2996 213.983 68.5833 213.983 69.1508C213.983 69.7183 214.375 70.002 214.792 70.002Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M218.784 69.7493C218.784 69.9606 219.029 70.0779 219.3 70.0779C219.545 70.0779 219.791 69.9606 219.791 69.7963C219.791 69.4911 219.422 69.4911 219.201 69.4677C218.612 69.4207 218.023 69.2095 218.023 68.5523C218.023 67.8716 218.612 67.543 219.275 67.543C219.889 67.543 220.527 67.8716 220.527 68.5523H219.717C219.717 68.341 219.496 68.2471 219.275 68.2471C219.079 68.2471 218.833 68.2941 218.833 68.5288C218.833 68.74 219.029 68.787 219.324 68.8105C220.012 68.8574 220.601 69.0217 220.601 69.7728C220.601 70.43 219.938 70.7586 219.275 70.7586C218.587 70.7586 217.924 70.43 217.949 69.7493H218.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M223.228 68.3107H222.037V68.7598H223.129V69.4689H222.037V69.9653H223.253V70.6981H221.169V67.6016H223.204V68.3107H223.228Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M226.603 70.5799V70.6981H225.722L225.159 69.729H224.719V70.6981H223.887V67.6016C224.376 67.6016 224.841 67.6016 225.306 67.6016C226.089 67.6016 226.456 68.1689 226.456 68.7125C226.456 69.0671 226.285 69.4217 225.966 69.6108L226.603 70.5799ZM225.306 69.0671C225.551 69.0671 225.648 68.878 225.648 68.6653C225.648 68.4762 225.526 68.2871 225.306 68.2871H224.719V69.0671H225.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M228.276 69.3339L228.552 68.527L228.955 67.6016H229.886V67.7202L228.452 70.7577H228.099L226.665 67.7202V67.6016H227.596L228.024 68.527L228.276 69.3339Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M231.782 70.0413V70.6981H230.076V70.0413H230.503V68.2584H230.126V67.6016H231.756V68.2584H231.38V70.0413H231.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M233.835 67.6016C234.509 67.6016 235.258 67.9549 235.383 68.7792H234.559C234.434 68.4495 234.185 68.3317 233.835 68.3317C233.336 68.3317 233.011 68.6614 233.011 69.1796C233.011 69.6271 233.336 70.0275 233.835 70.0275C234.185 70.0275 234.484 69.8626 234.559 69.5329H235.383C235.258 70.4044 234.534 70.7577 233.835 70.7577C232.986 70.7577 232.162 70.2159 232.162 69.1796C232.162 68.1197 232.986 67.6016 233.835 67.6016Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M237.944 68.3107H236.753V68.7598H237.845V69.4689H236.753V69.9653H237.969V70.6981H235.885V67.6016H237.919V68.3107H237.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M232.985 252.74H230.447C229.8 252.74 229.253 252.234 229.253 251.635V251.404C229.253 250.806 229.8 250.299 230.447 250.299H232.985C233.632 250.299 234.18 250.806 234.18 251.404V251.635C234.18 252.257 233.657 252.74 232.985 252.74Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M215.426 255.565C215.725 255.565 215.799 255.565 216.197 255.565C216.943 255.565 217.49 256.105 217.49 256.833C217.49 257.584 216.918 258.101 216.147 258.101C214.705 258.101 213.81 258.101 212.367 258.101C211.82 258.101 211.373 257.913 211.149 257.42C210.95 257.115 210.826 256.27 211.621 255.565C212.144 254.978 217.913 248.757 218.933 247.677C218.982 247.606 218.933 247.512 218.858 247.512C218.833 247.512 218.833 247.512 218.808 247.512C216.645 247.512 214.481 247.512 212.318 247.512C211.671 247.512 211.174 247.137 211.049 246.573C210.9 245.916 211.199 245.352 211.771 245.094C211.945 245.023 212.119 245 212.293 245C215.501 245 218.684 245 221.892 245C222.514 245 222.937 245.188 223.185 245.728C223.509 246.409 223.16 246.878 222.937 247.183C222.489 247.723 217.938 252.56 215.426 255.283C215.401 255.307 215.376 255.354 215.327 255.377C215.277 255.448 215.327 255.565 215.426 255.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M222.185 258.101H219.647C219.001 258.101 218.453 257.582 218.453 256.968V256.732C218.453 256.119 219.001 255.6 219.647 255.6H222.185C222.832 255.6 223.38 256.119 223.38 256.732V256.968C223.38 257.605 222.832 258.101 222.185 258.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M225.716 251.515C225.716 249.734 225.716 247.976 225.716 246.195C225.716 245.492 226.238 245 226.984 245C230.215 245 233.447 245 236.678 245C237.424 245 237.946 245.516 237.946 246.242C237.946 246.969 237.424 247.508 236.678 247.508C233.994 247.508 231.334 247.508 228.649 247.508C228.45 247.508 228.401 247.555 228.401 247.742C228.401 250.273 228.401 252.804 228.401 255.335C228.401 255.546 228.475 255.57 228.649 255.57C231.309 255.57 233.994 255.57 236.654 255.57C237.25 255.57 237.723 255.898 237.897 256.39C238.095 256.976 237.872 257.562 237.399 257.89C237.176 258.03 236.902 258.101 236.629 258.101C233.447 258.101 230.24 258.101 227.058 258.101C226.238 258.101 225.741 257.585 225.741 256.812C225.716 255.031 225.716 253.273 225.716 251.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M212.705 263.041V263.698H211V263.041H211.426V261.258H211.05V260.602H212.68V261.258H212.304V263.041H212.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M216.535 263.759H215.774L215.651 263.546C215.381 263.688 215.062 263.759 214.768 263.759C213.934 263.735 213.148 263.168 213.148 262.151C213.148 261.181 213.811 260.543 214.817 260.543C215.651 260.543 216.461 261.087 216.461 262.151C216.461 262.529 216.363 262.813 216.216 263.073L216.559 263.64V263.759H216.535ZM214.792 263.002C215.185 263.002 215.602 262.718 215.602 262.151C215.602 261.583 215.185 261.3 214.792 261.3C214.375 261.3 213.983 261.583 213.983 262.151C213.983 262.718 214.375 263.002 214.792 263.002Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M218.784 262.749C218.784 262.961 219.029 263.078 219.3 263.078C219.545 263.078 219.791 262.961 219.791 262.796C219.791 262.491 219.422 262.491 219.201 262.468C218.612 262.421 218.023 262.209 218.023 261.552C218.023 260.872 218.612 260.543 219.275 260.543C219.889 260.543 220.527 260.872 220.527 261.552H219.717C219.717 261.341 219.496 261.247 219.275 261.247C219.079 261.247 218.833 261.294 218.833 261.529C218.833 261.74 219.029 261.787 219.324 261.81C220.012 261.857 220.601 262.022 220.601 262.773C220.601 263.43 219.938 263.759 219.275 263.759C218.587 263.759 217.924 263.43 217.949 262.749H218.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M223.228 261.311H222.037V261.76H223.129V262.469H222.037V262.965H223.253V263.698H221.169V260.602H223.204V261.311H223.228Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M226.603 263.58V263.698H225.722L225.159 262.729H224.719V263.698H223.887V260.602C224.376 260.602 224.841 260.602 225.306 260.602C226.089 260.602 226.456 261.169 226.456 261.713C226.456 262.067 226.285 262.422 225.966 262.611L226.603 263.58ZM225.306 262.067C225.551 262.067 225.648 261.878 225.648 261.665C225.648 261.476 225.526 261.287 225.306 261.287H224.719V262.067H225.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M228.276 262.334L228.552 261.527L228.955 260.602H229.886V260.72L228.452 263.758H228.099L226.665 260.72V260.602H227.596L228.024 261.527L228.276 262.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M231.782 263.041V263.698H230.076V263.041H230.503V261.258H230.126V260.602H231.756V261.258H231.38V263.041H231.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M233.835 260.602C234.509 260.602 235.258 260.955 235.383 261.779H234.559C234.434 261.449 234.185 261.332 233.835 261.332C233.336 261.332 233.011 261.661 233.011 262.18C233.011 262.627 233.336 263.028 233.835 263.028C234.185 263.028 234.484 262.863 234.559 262.533H235.383C235.258 263.404 234.534 263.758 233.835 263.758C232.986 263.758 232.162 263.216 232.162 262.18C232.162 261.12 232.986 260.602 233.835 260.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M237.944 261.311H236.753V261.76H237.845V262.469H236.753V262.965H237.969V263.698H235.885V260.602H237.919V261.311H237.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <g
          filter="url(#4-t-b-filter2_i)"
          className="casement"
          data-index="1"
          onClick={onCasementClick(1)}
        >
          <rect x="36" y="106" width="178" height="108" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="36.47"
          y="106.47"
          width="177.06"
          height="107.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#4-t-b-filter3_i)"
          className="casement"
          data-index="2"
          onClick={onCasementClick(2)}
        >
          <rect x="234" y="106" width="178" height="108" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="234.47"
          y="106.47"
          width="177.06"
          height="107.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <path
          d="M133.985 130.74H131.447C130.8 130.74 130.253 130.234 130.253 129.635V129.404C130.253 128.806 130.8 128.299 131.447 128.299H133.985C134.632 128.299 135.18 128.806 135.18 129.404V129.635C135.18 130.257 134.657 130.74 133.985 130.74Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M116.426 133.565C116.725 133.565 116.799 133.565 117.197 133.565C117.943 133.565 118.49 134.105 118.49 134.833C118.49 135.584 117.918 136.101 117.147 136.101C115.705 136.101 114.81 136.101 113.367 136.101C112.82 136.101 112.373 135.913 112.149 135.42C111.95 135.115 111.826 134.27 112.621 133.565C113.144 132.978 118.913 126.757 119.933 125.677C119.982 125.606 119.933 125.512 119.858 125.512C119.833 125.512 119.833 125.512 119.808 125.512C117.645 125.512 115.481 125.512 113.318 125.512C112.671 125.512 112.174 125.137 112.049 124.573C111.9 123.916 112.199 123.352 112.771 123.094C112.945 123.023 113.119 123 113.293 123C116.501 123 119.684 123 122.892 123C123.514 123 123.937 123.188 124.185 123.728C124.509 124.409 124.16 124.878 123.937 125.183C123.489 125.723 118.938 130.56 116.426 133.283C116.401 133.307 116.376 133.354 116.327 133.377C116.277 133.448 116.327 133.565 116.426 133.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M123.185 136.101H120.647C120.001 136.101 119.453 135.582 119.453 134.968V134.732C119.453 134.119 120.001 133.6 120.647 133.6H123.185C123.832 133.6 124.38 134.119 124.38 134.732V134.968C124.38 135.605 123.832 136.101 123.185 136.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M126.716 129.515C126.716 127.734 126.716 125.976 126.716 124.195C126.716 123.492 127.238 123 127.984 123C131.215 123 134.447 123 137.678 123C138.424 123 138.946 123.516 138.946 124.242C138.946 124.969 138.424 125.508 137.678 125.508C134.994 125.508 132.334 125.508 129.649 125.508C129.45 125.508 129.401 125.555 129.401 125.742C129.401 128.273 129.401 130.804 129.401 133.335C129.401 133.546 129.475 133.57 129.649 133.57C132.309 133.57 134.994 133.57 137.654 133.57C138.25 133.57 138.723 133.898 138.897 134.39C139.095 134.976 138.872 135.562 138.399 135.89C138.176 136.03 137.902 136.101 137.629 136.101C134.447 136.101 131.24 136.101 128.058 136.101C127.238 136.101 126.741 135.585 126.741 134.812C126.716 133.031 126.716 131.273 126.716 129.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M113.705 141.041V141.698H112V141.041H112.426V139.258H112.05V138.602H113.68V139.258H113.304V141.041H113.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M117.535 141.759H116.774L116.651 141.546C116.381 141.688 116.062 141.759 115.768 141.759C114.934 141.735 114.148 141.168 114.148 140.151C114.148 139.181 114.811 138.543 115.817 138.543C116.651 138.543 117.461 139.087 117.461 140.151C117.461 140.529 117.363 140.813 117.216 141.073L117.559 141.64V141.759H117.535ZM115.792 141.002C116.185 141.002 116.602 140.718 116.602 140.151C116.602 139.583 116.185 139.3 115.792 139.3C115.375 139.3 114.983 139.583 114.983 140.151C114.983 140.718 115.375 141.002 115.792 141.002Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M119.784 140.749C119.784 140.961 120.029 141.078 120.3 141.078C120.545 141.078 120.791 140.961 120.791 140.796C120.791 140.491 120.422 140.491 120.201 140.468C119.612 140.421 119.023 140.209 119.023 139.552C119.023 138.872 119.612 138.543 120.275 138.543C120.889 138.543 121.527 138.872 121.527 139.552H120.717C120.717 139.341 120.496 139.247 120.275 139.247C120.079 139.247 119.833 139.294 119.833 139.529C119.833 139.74 120.029 139.787 120.324 139.81C121.012 139.857 121.601 140.022 121.601 140.773C121.601 141.43 120.938 141.759 120.275 141.759C119.587 141.759 118.924 141.43 118.949 140.749H119.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M124.228 139.311H123.037V139.76H124.129V140.469H123.037V140.965H124.253V141.698H122.169V138.602H124.204V139.311H124.228Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M127.603 141.58V141.698H126.722L126.159 140.729H125.719V141.698H124.887V138.602C125.376 138.602 125.841 138.602 126.306 138.602C127.089 138.602 127.456 139.169 127.456 139.713C127.456 140.067 127.285 140.422 126.966 140.611L127.603 141.58ZM126.306 140.067C126.551 140.067 126.648 139.878 126.648 139.665C126.648 139.476 126.526 139.287 126.306 139.287H125.719V140.067H126.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M129.276 140.334L129.552 139.527L129.955 138.602H130.886V138.72L129.452 141.758H129.099L127.665 138.72V138.602H128.596L129.024 139.527L129.276 140.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M132.782 141.041V141.698H131.076V141.041H131.503V139.258H131.126V138.602H132.756V139.258H132.38V141.041H132.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M134.835 138.602C135.509 138.602 136.258 138.955 136.383 139.779H135.559C135.434 139.449 135.185 139.332 134.835 139.332C134.336 139.332 134.011 139.661 134.011 140.18C134.011 140.627 134.336 141.028 134.835 141.028C135.185 141.028 135.484 140.863 135.559 140.533H136.383C136.258 141.404 135.534 141.758 134.835 141.758C133.986 141.758 133.162 141.216 133.162 140.18C133.162 139.12 133.986 138.602 134.835 138.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M138.944 139.311H137.753V139.76H138.845V140.469H137.753V140.965H138.969V141.698H136.885V138.602H138.919V139.311H138.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M331.985 130.74H329.447C328.8 130.74 328.253 130.234 328.253 129.635V129.404C328.253 128.806 328.8 128.299 329.447 128.299H331.985C332.632 128.299 333.18 128.806 333.18 129.404V129.635C333.18 130.257 332.657 130.74 331.985 130.74Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M314.426 133.565C314.725 133.565 314.799 133.565 315.197 133.565C315.943 133.565 316.49 134.105 316.49 134.833C316.49 135.584 315.918 136.101 315.147 136.101C313.705 136.101 312.81 136.101 311.367 136.101C310.82 136.101 310.373 135.913 310.149 135.42C309.95 135.115 309.826 134.27 310.621 133.565C311.144 132.978 316.913 126.757 317.933 125.677C317.982 125.606 317.933 125.512 317.858 125.512C317.833 125.512 317.833 125.512 317.808 125.512C315.645 125.512 313.481 125.512 311.318 125.512C310.671 125.512 310.174 125.137 310.049 124.573C309.9 123.916 310.199 123.352 310.771 123.094C310.945 123.023 311.119 123 311.293 123C314.501 123 317.684 123 320.892 123C321.514 123 321.937 123.188 322.185 123.728C322.509 124.409 322.16 124.878 321.937 125.183C321.489 125.723 316.938 130.56 314.426 133.283C314.401 133.307 314.376 133.354 314.327 133.377C314.277 133.448 314.327 133.565 314.426 133.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M321.185 136.101H318.647C318.001 136.101 317.453 135.582 317.453 134.968V134.732C317.453 134.119 318.001 133.6 318.647 133.6H321.185C321.832 133.6 322.38 134.119 322.38 134.732V134.968C322.38 135.605 321.832 136.101 321.185 136.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M324.716 129.515C324.716 127.734 324.716 125.976 324.716 124.195C324.716 123.492 325.238 123 325.984 123C329.215 123 332.447 123 335.678 123C336.424 123 336.946 123.516 336.946 124.242C336.946 124.969 336.424 125.508 335.678 125.508C332.994 125.508 330.334 125.508 327.649 125.508C327.45 125.508 327.401 125.555 327.401 125.742C327.401 128.273 327.401 130.804 327.401 133.335C327.401 133.546 327.475 133.57 327.649 133.57C330.309 133.57 332.994 133.57 335.654 133.57C336.25 133.57 336.723 133.898 336.897 134.39C337.095 134.976 336.872 135.562 336.399 135.89C336.176 136.03 335.902 136.101 335.629 136.101C332.447 136.101 329.24 136.101 326.058 136.101C325.238 136.101 324.741 135.585 324.741 134.812C324.716 133.031 324.716 131.273 324.716 129.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M311.705 141.041V141.698H310V141.041H310.426V139.258H310.05V138.602H311.68V139.258H311.304V141.041H311.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M315.535 141.759H314.774L314.651 141.546C314.381 141.688 314.062 141.759 313.768 141.759C312.934 141.735 312.148 141.168 312.148 140.151C312.148 139.181 312.811 138.543 313.817 138.543C314.651 138.543 315.461 139.087 315.461 140.151C315.461 140.529 315.363 140.813 315.216 141.073L315.559 141.64V141.759H315.535ZM313.792 141.002C314.185 141.002 314.602 140.718 314.602 140.151C314.602 139.583 314.185 139.3 313.792 139.3C313.375 139.3 312.983 139.583 312.983 140.151C312.983 140.718 313.375 141.002 313.792 141.002Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M317.784 140.749C317.784 140.961 318.029 141.078 318.3 141.078C318.545 141.078 318.791 140.961 318.791 140.796C318.791 140.491 318.422 140.491 318.201 140.468C317.612 140.421 317.023 140.209 317.023 139.552C317.023 138.872 317.612 138.543 318.275 138.543C318.889 138.543 319.527 138.872 319.527 139.552H318.717C318.717 139.341 318.496 139.247 318.275 139.247C318.079 139.247 317.833 139.294 317.833 139.529C317.833 139.74 318.029 139.787 318.324 139.81C319.012 139.857 319.601 140.022 319.601 140.773C319.601 141.43 318.938 141.759 318.275 141.759C317.587 141.759 316.924 141.43 316.949 140.749H317.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M322.228 139.311H321.037V139.76H322.129V140.469H321.037V140.965H322.253V141.698H320.169V138.602H322.204V139.311H322.228Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M325.603 141.58V141.698H324.722L324.159 140.729H323.719V141.698H322.887V138.602C323.376 138.602 323.841 138.602 324.306 138.602C325.089 138.602 325.456 139.169 325.456 139.713C325.456 140.067 325.285 140.422 324.966 140.611L325.603 141.58ZM324.306 140.067C324.551 140.067 324.648 139.878 324.648 139.665C324.648 139.476 324.526 139.287 324.306 139.287H323.719V140.067H324.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M327.276 140.334L327.552 139.527L327.955 138.602H328.886V138.72L327.452 141.758H327.099L325.665 138.72V138.602H326.596L327.024 139.527L327.276 140.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M330.782 141.041V141.698H329.076V141.041H329.503V139.258H329.126V138.602H330.756V139.258H330.38V141.041H330.782Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M332.835 138.602C333.509 138.602 334.258 138.955 334.383 139.779H333.559C333.434 139.449 333.185 139.332 332.835 139.332C332.336 139.332 332.011 139.661 332.011 140.18C332.011 140.627 332.336 141.028 332.835 141.028C333.185 141.028 333.484 140.863 333.559 140.533H334.383C334.258 141.404 333.534 141.758 332.835 141.758C331.986 141.758 331.162 141.216 331.162 140.18C331.162 139.12 331.986 138.602 332.835 138.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M336.944 139.311H335.753V139.76H336.845V140.469H335.753V140.965H336.969V141.698H334.885V138.602H336.919V139.311H336.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <defs>
          <pattern id="4-t-b-pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use
              xlinkHref="#image0"
              transform="translate(-0.00768784) scale(0.00100915 0.00153332)"
            />
          </pattern>
          <filter
            id="4-t-b-filter0_i"
            x="36"
            y="41"
            width="376.94"
            height="48.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-t-b-filter1_i"
            x="36"
            y="233"
            width="376.94"
            height="48.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-t-b-filter2_i"
            x="36"
            y="106"
            width="178.94"
            height="110.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-t-b-filter3_i"
            x="234"
            y="106"
            width="178.94"
            height="110.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-t-b-filter4_d"
            x="27.3003"
            y="28.3003"
            width="396.279"
            height="71.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-t-b-filter5_i"
            x="43"
            y="44"
            width="363.94"
            height="40.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-t-b-filter6_d"
            x="27.3003"
            y="99.3003"
            width="198.279"
            height="123.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-t-b-filter7_i"
            x="43"
            y="115"
            width="165.94"
            height="92.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-t-b-filter8_d"
            x="225.3"
            y="99.3003"
            width="198.279"
            height="123.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-t-b-filter9_i"
            x="241"
            y="115"
            width="165.94"
            height="92.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-t-b-filter10_d"
            x="27.3003"
            y="222.3"
            width="396.279"
            height="71.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-t-b-filter11_i"
            x="43"
            y="238"
            width="363.94"
            height="40.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <linearGradient
            id="4-t-b-paint0_linear"
            x1="301.5"
            y1="291"
            x2="301.5"
            y2="316"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DBDDE1" />
            <stop offset="1" stopColor="#F9F9F9" />
          </linearGradient>
        </defs>
      </svg>
      {!withoutBackground && <ContainerBackground />}
    </ProductImageWrapper>
  )
}
