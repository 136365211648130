import React, {FC, useCallback, useEffect, useMemo, useState, useContext, useRef} from 'react'
import { Link } from 'react-router-dom'
import { useModal } from 'react-modal-hook'
import { sendCt } from 'src/lib/utils/ct'
import fetchAPI from '../../../../lib/utils/fetch-api'
import { ButtonViews, InputTypes, IStageCartProps } from '../interface'
import {
  StageCartAdditionalBlock,
  StageCartAdditionalBlockDescription,
  StageCartAdditionalBlockRow,
  StageCartAdditionalBlockTotal,
  StageCartAdditionalBlockWrapper,
  StageCartBackButton,
  StageCartProductsList,
  StageCartWrapper,
  StageCartEmptyWrapper,
  StageCartEmptyTitle,
  StageCartEmptyDescription,
} from './styles'
import { CartItem } from './cart-item'
import { RegisterButton } from '../registr'
import { useRegister, useSendOrder } from '../../../hooks'
import {
  CloseButton,
  LinkButton,
  LinkWrapper,
  ModalBody,
  ModalContent,
  ModalDescription,
  ModalTitle,
  ModalWrapper,
} from '../styles'
import { ArrowLeftIcon, CloseIcon } from '../../../assets/icons'
import { FloatBlock } from '../../../components/float-block'
import { fixBody, prepareOrderProducts } from '../../../utilits'
import { Button, ControlGroup, Input, Loader, PhoneInput } from '../../../components'
import { ButtonWrapper, RightConfirmWrapper } from '../registr/styles'
import { calculateProductsSquare } from '../stage-calculator/consts'
import { MainContext } from '../calculator'
import { CalcIcon } from '../../../assets/icons/calc'
import { saveUserDataFromLocalStore, getUserDataFromLocalStore } from 'src/react-app/utilits/get-user-data'
import { getLocalStorageItem, setLocalStorageItem } from '../../../services/local-storage'

function calcTotalCost(priceList): number {
  return Object.values(priceList).reduce((acc, price: any) => Number.isFinite(price?.['total']) ? acc + price['total'] : acc, 0) as number
}

function calcOrderData(products, priceList, type) {
  const { newProducts, transitionProducts } = prepareOrderProducts(products, priceList, type)
  const square = calculateProductsSquare(products)
  const totalCost = calcTotalCost(priceList)

  const orderData = {
    stage: 'new',
    'specification-products': {
      'transition-products': transitionProducts,
      print: transitionProducts,
      source: {
        type,
        products: newProducts,
        totalCost: totalCost,
      },
      square,
    },
    location: window.location.href
  }

  return { orderData, newProducts: newProducts, totalCost }
}

function concatProductsAndPriceList(products, priceList) {
  return products.map((item, index) => {
    return { ...item, priceListItem: priceList?.[index], /*id: item?.product?.id*/ }
  })
}
function concatProducts(products1, products2) {
  const addProducts = products2.filter(item => {
    const findItem = products1.find((itemFind) => itemFind?.['id'] === item['id'])
    return !Boolean(findItem?.['id'])
  })
  // console.log({ products1, products2, addProducts })
  return products1.concat(addProducts).filter(item => item?.['id'])
}
function getProductsPriceList(products) {
  return products.map((item) => item['priceListItem'])
}

export const StageCart: FC<IStageCartProps> = props => {
  const { orders, type, onNewCalculate, onChangeStage, onEditOrder, onSetCurrentOrderId, onUpdateOrder } = props
  const { onSetOrder, onDeleteOrder, currentOrderId } = useContext(MainContext)
  const [products, setProducts] = useState<any[]>([])
  const { isSending, createOrder, updateOrder } = useSendOrder()
  const [priceList, setPriceList] = useState<Record<string, number>[]>([])
  const [superKey, setSuperKey] = useState(Date.now)
  const { isAuthorized, onFetchProfile } = useRegister()
  const [data, setData] = useState<Record<string, any>>({
    'first-name': '',
    'last-name': '',
    'passport-issued': '',
    'passport-number': '',
    passportIssued: '',
    valuePassportNumber: '',
    phone: '',
    'right-confirm': false,
  })
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const { toggleFixBody } = fixBody()
  const [targetId, setTargetId] = useState<null|number>(null)

  useEffect(() => {
    const getProfileData = async () => {
      const profileData = await onFetchProfile()
      const localData = getUserDataFromLocalStore()
      const name = profileData?.['profile-data']?.['name'] || localData?.['name'] || ''
      const phone = profileData?.['profile-data']?.['phone'] || localData?.['phone'] || ''

      setData(() => ({
        'first-name': profileData?.['profile-data']?.['first-name'] || name,
        'last-name': profileData?.['profile-data']?.['last-name'],
        'passport-issued': profileData?.['profile-data']?.['passport-issued'],
        'passport-number': profileData?.['profile-data']?.['passport-number'],
        passportIssued: profileData?.['profile-data']?.['passport-issued'],
        valuePassportNumber: profileData?.['profile-data']?.['passport-number'],
        phone: phone,
      }))
    }

    getProfileData()
  }, [isAuthorized, onFetchProfile])

  useEffect(() => {
    const fetchCartItems = async () => {
      const cartItems = JSON.parse(await getLocalStorageItem('cartItems') || '[]')

      if (cartItems && cartItems.length > 0) {
        setProducts(cartItems)
      } else if (orders && orders.length > 0) {
        setProducts(orders)
      } else {
        setProducts([])
      }
    }

    fetchCartItems()
  }, [orders])

  const handleSetPriceList = useCallback(
    (index: number) => (price: Record<string, any>) => {
      setPriceList(prevState => {
        const result = [...prevState]
        result[index] = price
        return result
      })
    },
    [],
  )

  const handleCloseModal = useCallback(
    hideModal => () => {
      toggleFixBody()
      hideModal()
      setShowSuccessMessage(false)
    },
    [toggleFixBody],
  )

  const rawTotalCost = useMemo(() => calcTotalCost(priceList), [priceList])

  const getOrderData = useCallback(() => {
    const { newProducts, transitionProducts } = prepareOrderProducts(products, priceList, type)
    const square = calculateProductsSquare(products)
    const totalCost = calcTotalCost(priceList)

    const orderData = {
      stage: 'new',
      'specification-products': {
        'transition-products': transitionProducts,
        print: transitionProducts,
        source: {
          type,
          products: newProducts,
          totalCost: totalCost,
        },
        square,
      },
      location: window.location.href
    }

    return { orderData, newProducts }
  }, [products, priceList, type])

  const handleCall = useCallback(async () => {
    const { orderData } = getOrderData()

    const data1 = {
      ...orderData,
      'source-appeal': 'Корзина - сайт',
      location: window.location.href,
    }

    saveUserDataFromLocalStore({
      phone: data['phone'],
      name: data['first-name'],
      'last-name': data['last-name'],
      'first-name': data['first-name'],
      'passport-issued': data['passport-issued'],
      'passport-number': data['passport-number']
    })

    sendCt({ name: data['first-name'], phone: data['phone'] })

    window?.roistatGoal?.reach?.({
      leadName: `Форма в корзине "Онлайн консультация"`,
      text: `Ссылка на страницу: ${window.location.href}`,
      name: data['first-name'],
      phone: data['phone'],
    });

    const responseOrder = await createOrder(data1, {
      phone: data['phone'],
      name: data['first-name'],
      'last-name': data['last-name'],
      'first-name': data['first-name'],
      'passport-issued': data['passport-issued'],
      'passport-number': data['passport-number']
    } as any)

    setTargetId(responseOrder?.['id'])
    if (responseOrder && responseOrder['id']) {
      onSetCurrentOrderId(responseOrder['id'])
    }
    setShowSuccessMessage(true)
  }, [createOrder, getOrderData, onSetCurrentOrderId, data])

  const handleChange = useCallback((value, key) => {
    setData(prevState => ({
      ...prevState,
      [key]: value,
    }))
  }, [])

  const handleConfirm = useCallback(() => {
    setData(prevState => ({
      ...prevState,
      'right-confirm': !prevState['right-confirm'],
    }))
  }, [])

  const [showModal, hideModal] = useModal(
    ({ in: open }) => {
      return (
        <ModalWrapper show={open}>
          <ModalBody>
            <CloseButton onClick={handleCloseModal(hideModal)}>
              <CloseIcon />
            </CloseButton>
            <ModalTitle>Консультация менеджера заказа по Вашему адресу</ModalTitle>
            <ModalContent>
              {showSuccessMessage && (
                <ModalDescription>
                  {`Благодарим за обращение №${targetId}. В течение 30 минут менеджер заказа свяжется с Вами для
                  назначения времени встречи`}
                </ModalDescription>
              )}
              {!showSuccessMessage && (
                <>
                  <ControlGroup>
                    <Input
                      placeholder="Укажите Ваше имя"
                      name="first-name"
                      type={InputTypes.text}
                      onChange={handleChange}
                      value={data['first-name']}
                    />
                  </ControlGroup>
                  <ControlGroup>
                    <PhoneInput
                      id="phone"
                      placeholder="Контактный телефон"
                      name="phone"
                      onChange={handleChange}
                      value={data['phone']}
                    />
                  </ControlGroup>
                  <RightConfirmWrapper>
                    <Input
                      id="right-confirm"
                      type={InputTypes.checkbox}
                      name="right-confirm"
                      onChange={handleConfirm}
                    />
                    <label htmlFor="right-confirm">
                      Я согласен с правилами обработки{' '}
                      <Link to="/privacy-policy/" target="_blank">
                        персональных данных
                      </Link>
                    </label>
                  </RightConfirmWrapper>
                  <div style={{margin: '0 0 20px',textAlign: 'center'}}>пн-пт с 10 до 19 часов</div>
                  <ButtonWrapper>
                    <Button
                      typeView={ButtonViews.ORANGE}
                      onClick={handleCall}
                      disabled={data['right-confirm']}
                    >
                      Оформить заявку
                    </Button>
                  </ButtonWrapper>
                </>
              )}
            </ModalContent>
          </ModalBody>
        </ModalWrapper>
      )
    },
    [data, showSuccessMessage],
  )

  const deleteCartItemByLS = useCallback(async (id: string) => {
    let cartItems: any[] = JSON.parse(await getLocalStorageItem('cartItems') || '[]')
    const itemIndex = cartItems.findIndex(item => item['id'] === id || item['bitrix-id'] === id)

    if (cartItems && cartItems.constructor !== Array) {
      cartItems = []
    }

    if (cartItems.length > 0) {
      cartItems = [
        ...cartItems.slice(0, itemIndex),
        ...cartItems.slice(itemIndex + 1, cartItems.length),
      ]
    }

    await setLocalStorageItem('cartItems', JSON.stringify(cartItems));
    await setLocalStorageItem('cartItemsCount', JSON.stringify(cartItems.length));
  }, [])

  const handleDeleteOrder = useCallback(
    (id: string, index: number) => () => {
      deleteCartItemByLS(id)
      onDeleteOrder(id)
      setPriceList(prevState => [
        ...prevState.slice(0, index),
        ...prevState.slice(index + 1, prevState.length),
      ])
    },
    [deleteCartItemByLS, onDeleteOrder],
  )

  const handleQuantityChange = async (id, quantity) => {
    setProducts(prevProducts =>
      prevProducts.map(product =>
        product['id'] === id || product['bitrix-id'] === id ? { ...product, quantity: Math.max(1, quantity) } : product
      )
    )
    setPriceList(prevPriceList =>
      prevPriceList.map(priceList =>
        priceList['id'] === id ? { ...priceList, total: priceList.itemPrice * quantity } : priceList
      ))

    await setLocalStorageItem(
      'cartItems',
      JSON.stringify(products.map(product => product['id'] === id || product['bitrix-id'] === id ? { ...product, quantity: Math.max(1, quantity) } : product))
    );
  }

  const handleOnlineConsult = useCallback(() => {
    showModal()
    toggleFixBody(true)
  }, [showModal, toggleFixBody])

  const handleToOrder = useCallback(async () => {
    if (!isSending) {
      window?.ym?.(82697449,'reachGoal','proceed_to_checkout')
      let result: any = null

      let findId = null
      let findData: any = null
      if (Number.isInteger(currentOrderId) === false) {
        findData = await fetchAPI('web-hooks/find-case')
        findId = findData?.['data']?.['id']
      }
      const prevProducts = findData?.data?.products?.source?.products?.map?.(item => item?.['product']) || []
      const prevPriceList = findData?.data?.products?.source?.totalPriceList || []
      const getAb = (products, priceList) => {
        if (Number.isInteger(findId) === false) {
          return [products, priceList]
        }
        const nextProducts = concatProducts(
          concatProductsAndPriceList(products, priceList),
          concatProductsAndPriceList(prevProducts, prevPriceList)
        )
        const nextPriceList = getProductsPriceList(nextProducts)
        return [nextProducts, nextPriceList]
      }


      const [nextProducts, nextPriceList] = getAb(products, priceList)
      const { orderData, newProducts, totalCost } = calcOrderData(nextProducts, nextPriceList, type)
      const nextOrderData = { ...orderData, ...data }

      if (currentOrderId || Number.isInteger(findId)) {
        result = await updateOrder((currentOrderId || findId) as number, nextOrderData)
      }
      if (Number.isInteger(currentOrderId) === false && Number.isInteger(findId) === false ) {
        result = await createOrder({ ...nextOrderData, 'set-case-to-dealer': true })
      }
      setProducts(newProducts.map(item => item?.['product']).filter(Boolean))
      setPriceList([...nextPriceList])

      if (result && result['id']) {
        onChangeStage(result['id'])
        await onSetOrder({
          'specification-products': orderData['specification-products'],
          totalCost: totalCost,
          products: newProducts,
          totalPriceList: nextPriceList,
          type,
        })
      }
      setProducts(newProducts.map(item => item?.['product']).filter(Boolean))
      onUpdateOrder(newProducts.map(item => item?.['product']).filter(Boolean))
      setPriceList([...nextPriceList])
      setSuperKey(Date.now)
    }
  }, [
    isSending,
    currentOrderId,
    updateOrder,
    createOrder,
    onChangeStage,
    onSetOrder,
    handleDeleteOrder,
    priceList,
    type,
    data,
    products
  ])

  const handleNewCalculate = useCallback(() => {
    onSetOrder(null)
    onNewCalculate()
  }, [onNewCalculate, onSetOrder])

  const handleEditOrder = useCallback(
    (id: string) => () => {
      deleteCartItemByLS(id)
      onEditOrder(id)
    },
    [deleteCartItemByLS, onEditOrder],
  )

  const canSubmit = useMemo(() => rawTotalCost > 0, [rawTotalCost])

  const StageCartAdditionalBlockWrapperRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <StageCartWrapper>
        <StageCartProductsList key={superKey}>
          {products.length === 0 && (
            <StageCartEmptyWrapper>
              <StageCartEmptyTitle>Корзина пуста.</StageCartEmptyTitle>
              <StageCartEmptyDescription>
                Выберите{' '}
                <a href="/gotovye-okna-pvh/">готовое окно</a> или отправьте нам свой
                <Link to="/podbor" onClick={handleNewCalculate}>
                  заказ
                </Link>
              </StageCartEmptyDescription>
            </StageCartEmptyWrapper>
          )}
          {products?.map?.((item, index) => (
            <CartItem
              productType={item?.['type']}
              key={`cart-item-${item?.['id']}-${superKey}`}
              itemNumber={index + 1}
              product={item?.['product']}
              sizes={item?.['sizes']}
              onDeleteOrder={handleDeleteOrder(item?.['bitrix-id'] || item?.['id'], index)}
              onEditOrder={handleEditOrder(item?.['bitrix-id'] || item?.['id'])}
              count={item?.['quantity']}
              filters={item?.['filters']}
              onSetPrice={handleSetPriceList(index)}
              isSdk={'sdk-id' in item}
              bitrixId={item?.['bitrix-id']}
              onQuantityChange={handleQuantityChange}
            />
          ))}
        </StageCartProductsList>
        {products.length > 0 && (
          <StageCartAdditionalBlockWrapper ref={StageCartAdditionalBlockWrapperRef}>
            <FloatBlock stageWrapperRef={StageCartAdditionalBlockWrapperRef}>
              <StageCartAdditionalBlock>
                <div>
                  <StageCartAdditionalBlockRow>
                    <span>Количество изделий({products.length})</span>{' '}
                    {!rawTotalCost ? <Loader /> : <span>{rawTotalCost?.toLocaleString('ru-RU') || '0'} ₽</span>}
                  </StageCartAdditionalBlockRow>
                </div>
                <StageCartAdditionalBlockTotal>
                  <StageCartAdditionalBlockRow>
                    <b>Итого</b> {!rawTotalCost ? <Loader /> : <b>{rawTotalCost?.toLocaleString('ru-RU') || '0'} ₽</b>}
                  </StageCartAdditionalBlockRow>
                </StageCartAdditionalBlockTotal>
                <RegisterButton
                  onSubmit={handleToOrder}
                  buttonText="Перейти к оформлению"
                  disabled={!canSubmit}
                />
                <LinkWrapper>
                  <LinkButton centerText onClick={handleOnlineConsult}>
                    Онлайн консультация
                  </LinkButton>
                </LinkWrapper>
                <StageCartAdditionalBlockDescription>
                  <span>Стоимость доставки рассчитывается в оформлении заказа</span>
                </StageCartAdditionalBlockDescription>
              </StageCartAdditionalBlock>
              <a href="/gotovye-okna-pvh/" style={{ textDecoration: 'none' }}>
                <StageCartBackButton>
                  <ArrowLeftIcon />
                  <span>Продолжить покупки</span>
                </StageCartBackButton>
              </a>
            </FloatBlock>
          </StageCartAdditionalBlockWrapper>
        )}
      </StageCartWrapper>
    </>
  )

}
