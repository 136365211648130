import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement33Square41Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#3-3-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M252.001 34C253.105 34 254 33.1048 254 32.0005C254 30.8962 253.105 30.001 252.001 30.001L249.833 30.001L249.833 30.001L239 30.001L239 30L235.999 30C234.895 30 234 30.8952 234 31.9995C234 33.1038 234.895 33.999 235.999 33.999L238.167 33.999L238.167 34L249 34L249 34L252.001 34Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 249.833 34)"
          fill="#ABABAB"
        />
        <path
          d="M253.06 34C253.579 34 254 33.5792 254 33.0601L254 30.941C254 30.4219 253.579 30.001 253.06 30.001L249 30.001L249 34L253.06 34Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M234.94 30C234.421 30 234 30.4208 234 30.9399L234 33.059C234 33.5781 234.421 33.999 234.94 33.999L239 33.999L239 30L234.94 30Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 248.167 34)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#3-3-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M313.001 34C314.105 34 315 33.1048 315 32.0005C315 30.8962 314.105 30.001 313.001 30.001L310.833 30.001L310.833 30.001L300 30.001L300 30L296.999 30C295.895 30 295 30.8952 295 31.9995C295 33.1038 295.895 33.999 296.999 33.999L299.167 33.999L299.167 34L310 34L310 34L313.001 34Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 310.833 34)"
          fill="#ABABAB"
        />
        <path
          d="M314.06 34C314.579 34 315 33.5792 315 33.0601L315 30.941C315 30.4219 314.579 30.001 314.06 30.001L310 30.001L310 34L314.06 34Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M295.94 30C295.421 30 295 30.4208 295 30.9399L295 33.059C295 33.5781 295.421 33.999 295.94 33.999L300 33.999L300 30L295.94 30Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 309.167 34)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#3-3-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M319 34H230V287H319V34ZM308 45H241V276H308V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M319 34H230V287H231.219V35H317.781V287H319V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-3-filter3_i)">
          <rect
            x="241.47"
            y="45.47"
            width="66.0601"
            height="230.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="234.53"
          y="38.53"
          width="79.9399"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="281"
          y="278"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 281 278)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="280.356"
          y="278.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 280.356 278.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M280.238 280.695C279.892 279.542 278.822 278.702 277.556 278.702L270.916 278.702C269.37 278.702 268.116 279.955 268.116 281.502C268.116 281.537 268.117 281.572 268.118 281.607C268.464 282.76 269.534 283.6 270.8 283.6L277.44 283.6C278.986 283.6 280.24 282.346 280.24 280.8C280.24 280.765 280.239 280.73 280.238 280.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M278.881 279.035C278.737 279.012 278.59 279 278.44 279L271.8 279C270.254 279 269 280.254 269 281.8C269 282.867 269.597 283.795 270.475 284.267C269.138 284.056 268.116 282.898 268.116 281.502C268.116 279.955 269.37 278.702 270.916 278.702L277.556 278.702C278.035 278.702 278.487 278.822 278.881 279.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-3-filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M277 281.5C277 280.672 276.328 280 275.5 280L275.286 280L274 280L255.5 280C254.672 280 254 280.672 254 281.5V281.5C254 282.328 254.672 283 255.5 283L272 283L275.5 283L275.714 283C276.424 283 277 282.424 277 281.714V281.714C277 281.677 276.999 281.64 276.996 281.604C276.999 281.569 277 281.535 277 281.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M306.424 46.5L274.5 271.442L242.576 46.5L306.424 46.5Z" stroke="white" />
        <defs>
          <filter
            id="3-3-filter0_d"
            x="232.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-3-filter1_d"
            x="293.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-3-filter2_d"
            x="225.3"
            y="29.3003"
            width="100.279"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-3-filter3_i"
            x="241"
            y="45"
            width="67.9399"
            height="233.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-3-filter4_d"
            x="252.28"
            y="278.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="236.5"
            y1="36.8"
            x2="236.5"
            y2="29.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="251.5"
            y1="27.2018"
            x2="251.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="236.5"
            y1="36.7982"
            x2="236.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="297.5"
            y1="36.8"
            x2="297.5"
            y2="29.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="312.5"
            y1="27.2018"
            x2="312.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="297.5"
            y1="36.7982"
            x2="297.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
