import React from 'react'
import { StageDescriptionBlockContentItemIcon } from 'src/react-app/views/calculator/styles'

export const SunProtection3 = () => {
  return (
    <StageDescriptionBlockContentItemIcon title="Экономия на охлаждении помещения летом">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.6"
          d="M32.5 40H7.5C3.3575 40 0 36.6425 0 32.5V7.5C0 3.3575 3.3575 0 7.5 0H32.5C36.6425 0 40 3.3575 40 7.5V32.5C40 36.6425 36.6425 40 32.5 40Z"
          fill="#E0E3E9"
        />
        <circle cx="20" cy="20" r="10" fill="#1C3775" />
        <path
          d="M19.7952 18.8674L19.7123 18.7465L17.9443 16.2045C17.7093 15.8682 17.3255 15.667 16.9168 15.667C16.4368 15.667 15.993 15.947 15.7868 16.382L14.0405 20.072C13.988 20.1831 13.9891 20.3007 14.029 20.4018C14.1017 20.5064 14.2219 20.5789 14.3677 20.5798L18.8727 20.609H19.0801L18.9218 20.667L16.2043 22.557C15.868 22.7907 15.668 23.1745 15.668 23.5832C15.668 23.9035 15.7933 24.2076 16.0058 24.4358C16.0943 24.5171 16.1953 24.5863 16.3065 24.6399L19.9851 26.4101C20.2617 26.5431 20.5805 26.3414 20.5825 26.0377L20.6117 21.5328L20.6693 21.6167L22.4841 24.2625C22.7156 24.6002 23.0981 24.8027 23.5068 24.8053C23.9868 24.8085 24.4324 24.5301 24.6414 24.0964L26.4115 20.4178C26.5421 20.1474 26.3479 19.8224 26.0392 19.8204L21.6412 19.7919L21.5343 19.7912L24.2993 17.9432C24.6355 17.7095 24.8355 17.3257 24.8355 16.917C24.8355 16.437 24.5543 15.9932 24.1193 15.787L20.4293 14.0407C20.158 13.9107 19.8343 14.107 19.8343 14.417V18.922L19.7952 18.8658L19.7952 18.8674Z"
          fill="#ECEEF2"
        />
      </svg>
    </StageDescriptionBlockContentItemIcon>
  )
}
