import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement42Square82Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#6filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M183.001 158C184.105 158 185 157.105 185 156.001C185 154.896 184.105 154.001 183.001 154.001L180.833 154.001L180.833 154.001L170 154.001L170 154L166.999 154C165.895 154 165 154.895 165 155.999C165 157.104 165.895 157.999 166.999 157.999L169.167 157.999L169.167 158L180 158L180 158L183.001 158Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 180.833 158)"
          fill="#ABABAB"
        />
        <path
          d="M184.06 158C184.579 158 185 157.579 185 157.06L185 154.941C185 154.422 184.579 154.001 184.06 154.001L180 154.001L180 158L184.06 158Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M165.94 154C165.421 154 165 154.421 165 154.94L165 157.059C165 157.578 165.421 157.999 165.94 157.999L170 157.999L170 154L165.94 154Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 179.167 158)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#6filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M138.001 158C139.105 158 140 157.105 140 156.001C140 154.896 139.105 154.001 138.001 154.001L135.833 154.001L135.833 154.001L125 154.001L125 154L121.999 154C120.895 154 120 154.895 120 155.999C120 157.104 120.895 157.999 121.999 157.999L124.167 157.999L124.167 158L135 158L135 158L138.001 158Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 135.833 158)"
          fill="#ABABAB"
        />
        <path
          d="M139.06 158C139.579 158 140 157.579 140 157.06L140 154.941C140 154.422 139.579 154.001 139.06 154.001L135 154.001L135 158L139.06 158Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M120.94 154C120.421 154 120 154.421 120 154.94L120 157.059C120 157.578 120.421 157.999 120.94 157.999L125 157.999L125 154L120.94 154Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.167 158)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#6filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 99H115V154H188V99ZM178 109H125V144H178V109Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 99H115V154H116V100H187V154H188V99Z"
          fill="#FBFBFB"
        />
        <g filter="url(#6filter3_i)">
          <rect
            x="125.47"
            y="109.47"
            width="52.0601"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="119.53"
          y="103.53"
          width="63.9399"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="158.528"
          y="101"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 158.528 101)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#6filter4_d)">
          <rect
            x="157.884"
            y="101.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 157.884 101.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M157.766 103.695C157.42 102.542 156.35 101.702 155.084 101.702L148.444 101.702C146.898 101.702 145.644 102.956 145.644 104.502C145.644 104.537 145.645 104.572 145.646 104.607C145.993 105.76 147.062 106.6 148.328 106.6L154.968 106.6C156.515 106.6 157.768 105.346 157.768 103.8C157.768 103.765 157.768 103.73 157.766 103.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M156.409 102.035C156.266 102.012 156.118 102 155.968 102L149.328 102C147.782 102 146.528 103.254 146.528 104.8C146.528 105.867 147.125 106.795 148.003 107.267C146.666 107.056 145.644 105.898 145.644 104.502C145.644 102.956 146.898 101.702 148.444 101.702L155.084 101.702C155.564 101.702 156.015 101.822 156.409 102.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#6filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M171.528 104.5C171.528 103.672 170.857 103 170.028 103L169.814 103L168.528 103L150.028 103C149.2 103 148.528 103.672 148.528 104.5V104.5C148.528 105.328 149.2 106 150.028 106L166.528 106L170.028 106L170.243 106C170.953 106 171.528 105.424 171.528 104.714V104.714C171.528 104.677 171.527 104.64 171.525 104.604C171.527 104.569 171.528 104.535 171.528 104.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M127.018 142.5L151.5 110.818L175.982 142.5L127.018 142.5Z" stroke="white" />
        <defs>
          <filter
            id="6filter0_d"
            x="163.12"
            y="152.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6filter1_d"
            x="118.12"
            y="152.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6filter2_d"
            x="110.3"
            y="94.3003"
            width="84.2793"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6filter3_i"
            x="125"
            y="109"
            width="53.9399"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="6filter4_d"
            x="143.764"
            y="99.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6filter5_d"
            x="147.649"
            y="101.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="167.5"
            y1="160.8"
            x2="167.5"
            y2="153.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="182.5"
            y1="151.202"
            x2="182.5"
            y2="158.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="167.5"
            y1="160.798"
            x2="167.5"
            y2="153.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="122.5"
            y1="160.8"
            x2="122.5"
            y2="153.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="137.5"
            y1="151.202"
            x2="137.5"
            y2="158.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="122.5"
            y1="160.798"
            x2="122.5"
            y2="153.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
