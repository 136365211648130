import styled, { css } from 'styled-components'
import { NumberStyle, NumberWrapper } from '../number-input'
import { HorizontalSizeType } from './interface'

export const StyledSizesTrack = styled.div`
  top: 0;
  bottom: 0;
  width: 100%;
  height: 1px;

  background: #d4d4d4;
`

export const HorizontalSizeWrapper = styled.div<{ width: number; type?: HorizontalSizeType }>`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;

  margin: 0 auto;
  padding-top: 6px;
  padding-bottom: 6px;

  left: 0;
  right: 0;
  
  ${({ type = HorizontalSizeType.TOP }) =>
    type === HorizontalSizeType.TOP &&
    css`
      top: 53px;
    `}

  ${({ type = HorizontalSizeType.TOP }) =>
    type === HorizontalSizeType.BOTTOM &&
    css`
      bottom: 20px;
    `}

  width: ${({ width = 0 }) => width}px;
  height: 24px;

  cursor: pointer;

  &:empty {
    display: none;
  }
`

export const StyledHorizontalSizeWrapper = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
  padding: 0 3px;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:hover ${StyledSizesTrack} {
    background-color: #d69a2d;
  }
`

export const HorizontalThumbWrapper = styled.div`
  position: relative;
`

export const HorizontalErrorsWrapper = styled.span`
  position: absolute;

  top: 21px;

  text-align: center;
`

export const HorizontalErrorsText = styled.span`
  font-size: 11px;
  line-height: 1.2;
  color: #ff453a;
`

export const HorizontalThumbSizesInput = styled.input<{
  left?: number | string
  disabled?: boolean
}>`
  border: 1px solid #000000;
  border-radius: 10px;
  background-color: #ffffff;

  position: absolute;

  font-size: 16px;
  line-height: 22.4px;
  text-align: center;

  padding: 3px 0;

  width: 56px;

  top: -15px;
  left: calc(50% + -9px);
  transform: translateX(-19px);

  ${({ disabled = false }) =>
    disabled &&
    css`
      pointer-events: none;
    `};

  cursor: pointer;
`

export const HorizontalThumbSizesInputNumberWrapper = styled.div<{ show: boolean }>`
  position: absolute;

  opacity: 0;
  z-index: 1;
  transform: translateX(-14px);
  pointer-events: none;

  top: -16px;
  left: calc(50% + -12px);

  width: 52px;

  ${({ show = false }) =>
    show &&
    css`
      opacity: 1;
      //transform: translateY(0);
      pointer-events: initial;
    `}

  ${NumberWrapper} {
    min-width: initial;
  }
  
  ${NumberStyle} {
    border: 1px solid #000000;
    border-radius: 10px;

    padding: 3px 0;
    
    text-align: center;
  }
}
`
