import React, { useState, useCallback, useEffect, FC } from 'react'

import {
  StageOrderDateWrapper,
  StageOrderBlockItems,
  StageOrderBlockTitle,
  StageOrderBlockWrapper,
  StageOrderGroup,
  InputTitle,
} from './styles'
import { Select, Checkbox } from '../../../components'
import { IMountingProps } from '../interface'
import { dateFNS } from '../../../utilits'
import { houseTypes, mountingTypes, params1, params2 } from './consts'
import { ICheckboxItem } from '../stage-calculator/interface'

export const Mounting: FC<IMountingProps> = props => {
  const { onChange, forecast, data } = props

  const [mountingData, setMountingData] = useState<Record<string, any>>({})
  const [mountingDate, setMountingDate] = useState<null | string>(null)

  useEffect(() => {
    if (Object.keys(mountingData).length === 0) {
      if (Object.keys(data).length > 0) {
        const newData = {
          ...data,
        }

        if (data?.['house-type'] && Object.keys(data?.['house-type']).length === 0) {
          newData['house-type'] = houseTypes[0]
        }
        if (data?.['mounting-type'] && Object.keys(data?.['mounting-type']).length === 0) {
          newData['mounting-type'] = mountingTypes[0]
        }

        setMountingData(newData)
      } else {
        setMountingData({
          'house-type': houseTypes[0],
          'mounting-type': mountingTypes[0],
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    const parseDate = date => (date ? dateFNS.format(new Date(date), 'dd MMMM') : '')
    let result = ''

    if (forecast) {
      result = parseDate(forecast['date-avg'])
    }

    setMountingDate(result)
  }, [forecast])

  const handleChange = useCallback(
    async (value, key) => {
      let result: Record<string, any> = {}

      await setMountingData(prevState => {
        result = {
          ...prevState,
          [key]: value,
        }

        return result
      })

      onChange(result)
    },
    [onChange],
  )

  const handleSelect = useCallback(
    async mountData => {
      let result: Record<string, any> = {}
      await setMountingData(prevState => {
        result = {
          ...prevState,
          [mountData['name']]: mountData['value'],
        }
        if (mountData['name'] === 'mounting') {
          result['mounting-kit'] = false
          if (mountData['value'] === false) {
            result['demounting'] = false
            result['garbage-removal'] = false
          }
        }
        return result
      })
      onChange(result)
    },
    [onChange],
  )

  const mountingSelected = useCallback(
    (param: ICheckboxItem) => ({
      ...param,
      value: mountingData ? mountingData[param['name']] : false,
    }),
    [mountingData],
  )

  const isMountingKiDisabled = useCallback(
    (key: string) => {
      if (key === 'mounting-kit' && mountingData['mounting']) {
        return true
      }
      if (key === 'demounting' && (mountingData['mounting'] === false)) {
        return true
      }
      if (key === 'garbage-removal' && (mountingData['mounting'] === false)) {
        return true
      }
      return false
    },
    [mountingData],
  )

  return (
    <StageOrderBlockWrapper style={{ display: 'none' }}>
      <StageOrderBlockTitle>Монтаж</StageOrderBlockTitle>
      {params1.map(param => (
        <Checkbox
          key={param['name']}
          items={[param]}
          onSelect={handleSelect}
          selected={mountingSelected(param)}
          id="mounting-params"
          disabled={isMountingKiDisabled(param['name'])}
        />
      ))}
      <StageOrderBlockItems>
        <StageOrderGroup noBottomMargin>
          <Select
            placeholder="Выберите тип дома"
            name="house-type"
            id="house-type"
            onSelect={handleChange}
            items={houseTypes}
            selected={mountingData['house-type']}
          />
          <InputTitle>Тип дома</InputTitle>
          {mountingDate && (
            <StageOrderDateWrapper style={{ marginBottom: 20 }}>
              Ожидаемая дата монтажа {mountingDate}
            </StageOrderDateWrapper>
          )}
        </StageOrderGroup>
        <StageOrderGroup noBottomMargin>
          <Select
            placeholder="Выберите тип монтажа"
            name="mounting-type"
            id="mounting-type"
            onSelect={handleChange}
            items={mountingTypes}
            selected={mountingData['mounting-type']}
          />
          <InputTitle>Тип монтажа</InputTitle>
        </StageOrderGroup>
      </StageOrderBlockItems>
      {params2.map(param => (
        <Checkbox
          key={param['name']}
          items={[param]}
          onSelect={handleSelect}
          selected={mountingSelected(param)}
          id="mounting-params"
          disabled={isMountingKiDisabled(param['name'])}
        />
      ))}
    </StageOrderBlockWrapper>
  )
}
