import React, { FC, useCallback, useEffect, useState, useMemo, useContext, useRef } from 'react'

import { ProductsContext } from '../../stage-calculator'
import { ISliderProps, ISliderRange } from './interface'
import {
  SliderWrapper,
  StyledSlider,
  StyledThumb,
  StyledTrack,
  ThumbText,
  SliderTitle,
  SliderContent,
} from './styles'
import { modelPaddings, valueMap } from './consts'
import { ComponentShadow } from '../component-shadow'
import { Info } from '../../../../../components'

export const Slider: FC<ISliderProps> = props => {
  const { id, items = [], selected, onSelect, disabled = false, show = true } = props
  const { type, product } = useContext(ProductsContext)

  const [range, setRange] = useState<ISliderRange>({ min: 0, max: 0 })
  const [value, setValue] = useState(0)
  const sliderRef = useRef<any>(null)

  const wrapperPaddings = useMemo(() => {
    const model = product[`${type}-model`]
    let result = { left: 0, right: 0 }

    if (model && modelPaddings[model['value']] && modelPaddings[model['value']][id]) {
      result = modelPaddings[model['value']][id]
    }

    return result
  }, [id, product, type])

  const currentValueMap = useMemo(() => {
    return valueMap[id] || {}
  }, [id])

  useEffect(() => {
    if (sliderRef.current !== null) {
      sliderRef.current.resize()
    }
  }, [wrapperPaddings])

  useEffect(() => {
    /** Reduce min & max value of range */
    const result = items.reduce((previousValue, currentValue) => {
      const resultRange = { ...previousValue }
      const currentValueValue = Number(currentValue.value)

      if (resultRange.min > currentValueValue) {
        resultRange.min = currentValueValue
      }
      if (resultRange.max < currentValueValue) {
        resultRange.max = currentValueValue
      }

      return resultRange
    }, range)

    if (result.min === 0) {
      result.min = 1
    }

    setRange(result)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selected) {
      if (selected.constructor === Object && 'value' in selected) {
        setValue(Number(selected.value))
      } else if (Number(selected)) {
        setValue(Number(selected))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  const handleChangeAfter = useCallback(
    val => {
      const item = items.find(itemData => Number(itemData.value) === val[1]) // val[1] because val[0] it is base value

      if (item) {
        onSelect(item)
      }
    },
    [items, onSelect],
  )

  const Thumb = useCallback(
    (thumbProps, state) => {
      let valueNow = state['index'] === 0 ? 'База' : `+${currentValueMap[state.valueNow]}%`

      if (state['index'] === 1 && state['valueNow'] === 1) {
        valueNow = ''
      }

      return (
        <StyledThumb {...thumbProps} type={state['index']}>
          <ThumbText>{valueNow}</ThumbText>
        </StyledThumb>
      )
    },
    [currentValueMap],
  )

  const Track = useCallback((trackProps, state) => {
    return <StyledTrack {...trackProps} index={state.index} />
  }, [])

  const title = useMemo(() => {
    const result = items.filter(item => item['title'])

    return result.length > 0 ? result[0]['title'] : null
  }, [items])

  const description = useMemo(() => {
    const result = items.filter(item => item['description'])

    return result.length > 0 ? result[0]['description'] : null
  }, [items])

  const currentValue = useMemo(() => {
    return selected ? value : 0
  }, [selected, value])

  return (
    <SliderWrapper>
      {disabled && <ComponentShadow />}
      {show && title && (
        <SliderTitle>
          <span>{title}</span>
          <Info color="#bbc3d6">{description}</Info>
        </SliderTitle>
      )}
      {show && (
        <SliderContent {...wrapperPaddings}>
          <StyledSlider
            ref={sliderRef}
            {...range}
            value={[1, currentValue]}
            renderTrack={Track}
            renderThumb={Thumb}
            step={1}
            onAfterChange={handleChangeAfter}
          />
        </SliderContent>
      )}
    </SliderWrapper>
  )
}
