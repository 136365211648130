import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement74Square3Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#7-4_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.9995 206C38.8952 206 38 206.895 38 207.999C38 209.104 38.8952 209.999 39.9995 209.999L42.1665 209.999L42.1665 209.999L53 209.999L53 210L56.0005 210C57.1048 210 58 209.105 58 208.001C58 206.896 57.1048 206.001 56.0005 206.001L53.8332 206.001L53.8332 206L43 206L43 206L39.9995 206Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 42.166 206)"
          fill="#ABABAB"
        />
        <path
          d="M38.9399 206C38.4208 206 38 206.421 38 206.94L38 209.059C38 209.578 38.4208 209.999 38.9399 209.999L43 209.999L43 206L38.9399 206Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M57.0601 210C57.5792 210 58 209.579 58 209.06L58 206.941C58 206.422 57.5792 206.001 57.0601 206.001L53 206.001L53 210L57.0601 210Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 43.833 206)"
          fill="url(#paint7-4_linear)"
        />
        <g filter="url(#7-4_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M127.999 206C126.895 206 126 206.895 126 207.999C126 209.104 126.895 209.999 127.999 209.999L130.167 209.999L130.167 209.999L141 209.999L141 210L144.001 210C145.105 210 146 209.105 146 208.001C146 206.896 145.105 206.001 144.001 206.001L141.833 206.001L141.833 206L131 206L131 206L127.999 206Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 130.166 206)"
          fill="#ABABAB"
        />
        <path
          d="M126.94 206C126.421 206 126 206.421 126 206.94L126 209.059C126 209.578 126.421 209.999 126.94 209.999L131 209.999L131 206L126.94 206Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M145.06 210C145.579 210 146 209.579 146 209.06L146 206.941C146 206.422 145.579 206.001 145.06 206.001L141 206.001L141 210L145.06 210Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 131.833 206)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#7-4_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M152 210H32V287H152V210ZM141 221H43V276H141V221Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#7-4_filter7-4_i)">
          <rect
            x="43.47"
            y="221.47"
            width="97.0601"
            height="54.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="37.5" y="215.5" width="109" height="66" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M152 210H32V287H33V211H151V287H152V210Z"
          fill="#FBFBFB"
        />
        <path d="M45.1274 222.5H138.873L92 274.255L45.1274 222.5Z" stroke="white" />
        <rect
          x="98"
          y="278"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 98 278)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#7-4_filter4_d)">
          <rect
            x="97.3555"
            y="278.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 97.3555 278.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M97.2383 280.695C96.8919 279.542 95.8222 278.702 94.5563 278.702L87.9164 278.702C86.37 278.702 85.1164 279.956 85.1164 281.502C85.1164 281.537 85.117 281.572 85.1183 281.607C85.4647 282.76 86.5344 283.6 87.8003 283.6L94.4402 283.6C95.9866 283.6 97.2402 282.347 97.2402 280.8C97.2402 280.765 97.2396 280.73 97.2383 280.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M95.8809 279.035C95.7372 279.012 95.5899 279 95.4398 279L88.7999 279C87.2535 279 85.9999 280.254 85.9999 281.8C85.9999 282.867 86.5968 283.795 87.4749 284.268C86.1381 284.056 85.116 282.898 85.116 281.502C85.116 279.956 86.3696 278.702 87.916 278.702L94.5559 278.702C95.0352 278.702 95.4864 278.823 95.8809 279.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#7-4_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M94 281.5C94 280.672 93.3284 280 92.5 280L92.2857 280L91 280L72.5 280C71.6716 280 71 280.672 71 281.5C71 282.328 71.6716 283 72.5 283L89 283L92.5 283L92.7143 283C93.4244 283 94 282.424 94 281.714C94 281.677 93.9988 281.64 93.9965 281.604C93.9988 281.569 94 281.535 94 281.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="7-4_filter0_d"
            x="36.1201"
            y="204.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7-4_filter1_d"
            x="124.12"
            y="204.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7-4_filter2_d"
            x="27.3003"
            y="205.3"
            width="131.279"
            height="88.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7-4_filter7-4_i"
            x="43"
            y="221"
            width="98.9399"
            height="57.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="7-4_filter4_d"
            x="83.2354"
            y="276.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7-4_filter5_d"
            x="69.1201"
            y="278.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="55.5"
            y1="203.2"
            x2="55.5"
            y2="210.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="40.5"
            y1="212.798"
            x2="40.5"
            y2="205.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="55.5"
            y1="203.202"
            x2="55.5"
            y2="210.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7-4_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="143.5"
            y1="203.2"
            x2="143.5"
            y2="210.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="128.5"
            y1="212.798"
            x2="128.5"
            y2="205.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="143.5"
            y1="203.202"
            x2="143.5"
            y2="210.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
