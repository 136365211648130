import React from 'react'

export const DocImage = () => {
  return (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.8033 0V2.97297C11.8033 3.71809 12.3916 4.3243 13.1148 4.3243H16L11.8033 0Z"
        fill="#1C3775"
      />
      <path
        d="M13.1148 5.6757C11.6721 5.6757 10.4918 4.45949 10.4918 2.97301V0H0V17.2973C0 18.7838 1.18033 20 2.62295 20H13.377C14.8197 20 16 18.7838 16 17.2973V5.6757H13.1148ZM8.39345 14.4595H3.67215V13.1081H8.39345V14.4595ZM12.3279 11.2162H3.67211V9.86488H12.3279V11.2162Z"
        fill="#1C3775"
      />
    </svg>
  )
}
