import { useCallback, useRef, useState } from 'react'
import fetchAPI from '../../lib/utils/fetch-api'
import { IProductionsMenuMap } from '../views/calculator/stage-calculator/interface'
import windowProps from './window-props'
import { CalculatorType } from '../views/calculator/interface'
import { getItemsTargetKey } from '../utilits'

interface IFilterItem {
  key: string
  value: string | boolean | number
}

interface IMapParam {
  originalKey: string
  targetKey: string
}

const typesProduct = {
  window: 1,
}

const getMappedData = (inputData, mapParams, mapId: string) => {
  const result: Record<string, string>[] = []

  inputData.forEach(enumRow => {
    const mapDataResult: Record<string, string> = {}

    mapParams.forEach(param => {
      const key = param['originalKey'].substr(mapId.length + 1, param['originalKey'].length)
      mapDataResult[param['targetKey']] = enumRow[key]
    })

    result.push(mapDataResult)
  })

  return result
}

const getClearData = (data: Record<string, string>[]) => {
  const filterList: any[] = []

  const result: (Record<string, string> | undefined)[] = data
    .map(item => {
      let filteredResult: Record<string, string> | undefined

      if (!filterList.includes(item['value'])) {
        filteredResult = item
        filterList.push(item['value'])
      }

      return filteredResult
    })
    .filter(Boolean)

  return result
}

const getSchemaEnum = (mapId: string, filters, reducer?: Function) => {
  let result = []

  if (windowProps[mapId] && windowProps[mapId]['enum'] && windowProps[mapId]['enum'].length > 0) {
    result = windowProps[mapId]['enum']
  }

  if (reducer && filters.length > 0 && result.length > 0) {
    const preparedFilters = filters.reduce((accum, { key, value }) => {
      // eslint-disable-next-line no-param-reassign
      accum[key] = value

      return accum
    }, {})

    result = reducer(result, preparedFilters)
  }

  return result
}

const getSchemaReducer = (mapId: string, reducerName = 'reducer-filter'): Function | undefined => {
  let result: Function | undefined

  if (
    windowProps[mapId] &&
    windowProps[mapId][reducerName] &&
    windowProps[mapId][reducerName].length > 0
  ) {
    result = windowProps[mapId][reducerName]
  }

  return result
}

export const prepareFilterItemData: (
  filters: IFilterItem[],
  mapParams: IMapParam[],
  mapId: string,
  categoryId: string,
) => Promise<any[]> = async (filters, mapParams, mapId) => {
  let result: (Record<string, string> | undefined)[] = []

  const reducer = getSchemaReducer(mapId)
  const enums = getSchemaEnum(mapId, filters, reducer)
  const preResult: Record<string, string>[] = getMappedData(enums, mapParams, mapId)

  if (preResult.length > 0) {
    result = getClearData(preResult)
  }

  return result
}

export const getFilterItemData = async (
  type,
  categoryId,
  filters,
  mapId: string,
  mapParams: IMapParam[],
): Promise<{ id: string; data: {}[] }> => {
  const data = await prepareFilterItemData(filters, mapParams, mapId, categoryId)

  return { id: mapId, data }
}

export const accumulateAttributesData = async (
  menuItems,
  filters,
  type,
  categoryId,
  products,
  dataFromProduct = false,
): Promise<{}> => {
  const result = {}
  const resultArr: (Promise<{ id: string; data: {}[] }> | { id: string; data: {}[] })[] = []

  if (menuItems.length > 0) {
    for (const menuItem of menuItems) {
      if (menuItem.children && menuItem.children.length > 0) {
        for (const child of menuItem.children) {
          if (child.attributes && child.attributes.length > 0) {
            for (const attribute of child.attributes) {
              if (attribute.mapParams && attribute.mapParams.length > 0) {
                resultArr.push(
                  getFilterItemData(type, categoryId, filters, attribute.id, attribute.mapParams),
                )
              } else if (attribute.data && 'items' in attribute.data) {
                if (attribute.data.items && attribute.data.items?.length > 0) {
                  let value = attribute.data.items

                  if (dataFromProduct) {
                    value = attribute.data.items.map(item => ({
                      ...item,
                      value: products[0][attribute.id], // hack for setting data from quiz
                    }))
                  }

                  resultArr.push({ id: attribute.id, data: value })
                }
              } else if (attribute.data && 'attributes' in attribute.data) {
                for (const dataAttribute of attribute.data.attributes) {
                  if (dataAttribute.mapParams && dataAttribute.mapParams.length > 0) {
                    resultArr.push(
                      getFilterItemData(
                        type,
                        categoryId,
                        filters,
                        dataAttribute.id,
                        dataAttribute.mapParams,
                      ),
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  await Promise.all(resultArr).then((res: any) => {
    res.forEach(item => {
      if (item && 'id' in item && 'data' in item) {
        result[item['id']] = item['data']
      }
    })
  })

  return result
}

export const getMenuData = (filters, menuItems, category): Record<string, any> => {
  return accumulateAttributesData(menuItems, filters, category, category, [])
}

export const getSpecification = (filters, props) => {
  const result = {}
  const enumsWithReducer: {} = {}
  const enumsWithoutReducer: {} = {}
  const itemsKeys = Object.keys(props)

  itemsKeys.forEach(key => {
    const reducer = getSchemaReducer(key, 'reducer-specification')

    if (props[key] && props[key]['enum']) {
      if (reducer) {
        enumsWithReducer[key] = getSchemaEnum(key, filters, reducer)
      } else {
        const propEnums = props[key] && props[key]['enum'] ? props[key]['enum'] : []
        const targetKey = getItemsTargetKey(propEnums)
        const currentFilterData = filters.find(filter => filter['key'] === key)

        if (currentFilterData) {
          enumsWithoutReducer[key] = props[key]['enum'].filter(
            item => item[targetKey] === currentFilterData['value'],
          )
        }
      }
    }
  })

  Object.keys(enumsWithoutReducer).forEach(key => {
    // eslint-disable-next-line prefer-destructuring
    result[key] = enumsWithoutReducer[key][0]
  })
  Object.keys(enumsWithReducer).forEach(key => {
    // eslint-disable-next-line prefer-destructuring
    result[key] = enumsWithReducer[key][0]
  })

  return result
}

export const useMenuData = () => {
  const [menuItems, setMenuItems] = useState<IProductionsMenuMap[]>([])
  const [menuInitItemsData, setMenuInitItemsData] = useState<Record<string, any[]>>({})
  const [menuItemsData, setMenuItemsData] = useState<Record<string, any[]>>({})
  const [specification, setSpecification] = useState<Record<string, any>>({})
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  const handleFetchMenuData = useCallback(async (type = CalculatorType.WINDOW, filters = []) => {
    const startFetch = () => {
      const url = `pim/${typesProduct[type]}/filters?filter[selected]=${JSON.stringify(filters)}`

      Promise.all([fetchAPI(url)])
        .then(response => (response.length > 0 ? response[0] : {}))
        .then(response => {
          if (response && response['data']) {
            // if (response['data']['menu-items']) {
            //   setMenuItems(newProductsMenuMap())
            //   const requestList = [
            //     getMenuData([], newProductsMenuMap(), typesProduct[type]),
            //     getMenuData(filters, newProductsMenuMap(), typesProduct[type]),
            //     getSpecification(filters, windowProps),
            //   ]
            //   Promise.all(requestList).then(([initData, filteredData, currentSpecification]) => {
            //     setMenuInitItemsData(initData)
            //     setMenuItemsData(filteredData)
            //     setSpecification(currentSpecification)
            //   })
            // }

            setMenuItems(response['data']['menu-items'])

            if (response['data']['init-menu-data']) {
              setMenuInitItemsData(response['data']['init-menu-data'])
            }

            if (response['data']['menu-data']) {
              setMenuItemsData(response['data']['menu-data'])
            }

            if (response['data']['spec']) {
              setSpecification(response['data']['spec'])
            }
          }
        })
    }

    if (timerRef.current !== null) {
      clearTimeout(timerRef.current)
    }

    timerRef.current = setTimeout(startFetch, 600)
  }, [])

  const handleDropSpecification = useCallback(() => {
    setSpecification({})
  }, [])

  return {
    menuItems,
    menuInitItemsData,
    menuItemsData,
    specification,
    fetchMenuData: handleFetchMenuData,
    onDropSpecification: handleDropSpecification,
  }
}
