import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement22Square6Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-2_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M164 40.9995C164 39.8952 163.105 39 162.001 39C160.896 39 160.001 39.8952 160.001 40.9995V43.1665H160.001V54H160V57.0005C160 58.1048 160.895 59 161.999 59C163.104 59 163.999 58.1048 163.999 57.0005V54.8332H164V44H164V40.9995Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 164 43.166)"
          fill="#ABABAB"
        />
        <path
          d="M164 39.9399C164 39.4208 163.579 39 163.06 39H160.941C160.422 39 160.001 39.4208 160.001 39.9399V44H164V39.9399Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M160 58.0601C160 58.5792 160.421 59 160.94 59H163.059C163.578 59 163.999 58.5792 163.999 58.0601V54H160V58.0601Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 164 44.833)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#2-2_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M164 92C164 90.8954 163.105 90 162 90C160.895 90 160 90.8954 160 92V106.56H164V92ZM164 106.56H160V108.4H159.94C159.421 108.4 159 108.821 159 109.34V111.12C159 112.158 159.842 113 160.88 113H162.12C163.158 113 164 112.158 164 111.12V108.4V108.4V106.56Z"
            fill="white"
          />
        </g>
        <rect
          width="3.99998"
          height="0.95833"
          transform="matrix(-1 0 0 1 164 107.25)"
          fill="#ABABAB"
        />
        <path
          d="M164 90.9399C164 90.4208 163.579 90 163.06 90H160.94C160.421 90 160 90.4208 160 90.9399V107.25H164V90.9399Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M164 112.06C164 112.579 163.579 113 163.06 113H159.94C159.421 113 159 112.579 159 112.06V109.148C159 108.629 159.421 108.208 159.94 108.208H164V112.06Z"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#2-2_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M284 34H164V118H284V34ZM273 45H175V107H273V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M284 34H164V118H165V35H283V118H284V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="168.53"
          y="38.53"
          width="110.94"
          height="74.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#2-2_filter3_i)">
          <rect
            x="175.47"
            y="45.47"
            width="97.0601"
            height="61.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="275"
          y="70"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#2-2_filter4_d)">
          <rect x="275.702" y="70.6445" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M276.286 81.7958C276.798 82.458 277.6 82.8843 278.502 82.8843C280.049 82.8843 281.302 81.6307 281.302 80.0843V73.4444C281.302 72.456 280.79 71.5871 280.017 71.0889C280.383 71.5621 280.601 72.1558 280.601 72.8004V79.4403C280.601 80.9867 279.347 82.2403 277.801 82.2403C277.243 82.2403 276.723 82.0771 276.286 81.7958Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M278.502 70.6445C276.956 70.6445 275.702 71.8981 275.702 73.4445V79.4402C275.702 79.4606 275.702 79.4809 275.703 79.5012V74.0241C275.703 72.4777 276.956 71.2241 278.503 71.2241C280.029 71.2241 281.27 72.4449 281.302 73.9631V73.4445C281.302 71.8981 280.049 70.6445 278.502 70.6445Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-2_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M278.5 74C277.672 74 277 74.6716 277 75.5V75.7143V77V95.5C277 96.3284 277.672 97 278.5 97C279.328 97 280 96.3284 280 95.5V79V75.5L280 75.2857C280 74.5756 279.424 74 278.714 74C278.677 74 278.64 74.0012 278.604 74.0035C278.569 74.0012 278.535 74 278.5 74Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M176.5 105.32L176.5 46.6801L270.324 76L176.5 105.32Z" stroke="white" />
        <path d="M270.96 105.5L177.04 105.5L224 46.8004L270.96 105.5Z" stroke="white" />
        <defs>
          <filter
            id="2-2_filter0_d"
            x="158.12"
            y="37.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-2_filter1_d"
            x="157.12"
            y="88.1201"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-2_filter2_d"
            x="159.3"
            y="29.3003"
            width="131.279"
            height="95.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-2_filter3_i"
            x="175"
            y="45"
            width="98.9399"
            height="64.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-2_filter4_d"
            x="273.822"
            y="68.7647"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-2_filter5_d"
            x="275.12"
            y="72.1201"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="166.8"
            y1="56.5"
            x2="159.2"
            y2="56.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="157.202"
            y1="41.5"
            x2="164.8"
            y2="41.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="166.798"
            y1="56.5"
            x2="159.2"
            y2="56.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="157.2"
            y1="98.625"
            x2="164.8"
            y2="98.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="155.5"
            y1="110.604"
            x2="165"
            y2="110.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
