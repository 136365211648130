import { IOrder } from '../stage-calculator/interface'

export enum VIEWS_CODES {
  ORDERS = 'orders',
  CASES = 'cases',
  PROFILE = 'profile',
  EXIT = 'exit',
}

export enum ORDER_STATUSES {
  NEW = 'new',
  MANUFACTURE = 'manufacture',
  PASS_TO_DELIVERY = 'pass-to-delivery',
  DELIVERY = 'delivery',
  MOUNTING = 'mounting',
  DONE = 'done',
}

export const views = [
  {
    title: 'Обращения',
    code: VIEWS_CODES.CASES,
  },
  {
    title: 'Заказы',
    code: VIEWS_CODES.ORDERS,
  },
  {
    title: 'Профиль',
    code: VIEWS_CODES.PROFILE,
  },
  {
    title: 'Выход',
    code: VIEWS_CODES.EXIT,
  },
]

export interface IPersonalAreaContext {
  profile: Record<string, any> | null
  orders: IOrder[]
  cases: IOrder[]
  inProcess: boolean
  fetchOrderById: (orderId: number) => void
}

export interface IStatusesProps {
  status: ORDER_STATUSES
  isHaveMounting: boolean
}
