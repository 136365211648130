import React from 'react'
import { StageDescriptionBlockContentItemIcon } from 'src/react-app/views/calculator/styles'

export const HeatSaving2 = () => {
  return (
    <StageDescriptionBlockContentItemIcon title="Помещения с высокими требованиями к теплосбережению">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.6"
          d="M32.5 40H7.5C3.3575 40 0 36.6425 0 32.5V7.5C0 3.3575 3.3575 0 7.5 0H32.5C36.6425 0 40 3.3575 40 7.5V32.5C40 36.6425 36.6425 40 32.5 40Z"
          fill="#E0E3E9"
        />
        <path
          d="M28.6419 18.7991L26.7507 17.0339V12.5C26.7507 12.086 26.4143 11.7496 26.0003 11.7496H23.7502C23.3362 11.7496 22.9998 12.086 22.9998 12.5V13.526L20.5112 11.2017C20.2221 10.9328 19.7754 10.9328 19.4874 11.2017L11.3386 18.8171C11.1204 19.0354 11 19.3223 11 19.6249C11 20.2448 11.5051 20.75 12.1251 20.75H13.2501V27.1257C13.2501 28.1585 14.0916 29 15.1256 29H24.8764C25.9092 29 26.7507 28.1585 26.7507 27.1245V20.7489H27.8758C28.4957 20.7489 29.0008 20.2437 29.0008 19.6238C29.0008 19.3223 28.8805 19.0354 28.6419 18.7991ZM18.3128 24.8744H20.1883C20.4988 24.8744 20.7508 25.1264 20.7508 25.437C20.7508 25.7475 20.4988 25.9995 20.1883 25.9995H18.3128C17.1754 25.9995 16.2506 25.0747 16.2506 23.9373V21.3125C16.2506 20.1751 17.1754 19.2503 18.3128 19.2503H20.1883C20.4988 19.2503 20.7508 19.5023 20.7508 19.8128C20.7508 20.1233 20.4988 20.3753 20.1883 20.3753H18.3128C17.7953 20.3753 17.3757 20.7961 17.3757 21.3125V23.9373C17.3757 24.4548 17.7953 24.8744 18.3128 24.8744ZM22.6252 19.9996C22.0041 19.9996 21.5001 19.4956 21.5001 18.8745C21.5001 18.2535 22.0041 17.7495 22.6252 17.7495C23.2462 17.7495 23.7502 18.2535 23.7502 18.8745C23.7502 19.4956 23.2473 19.9996 22.6252 19.9996Z"
          fill="#1C3775"
        />
      </svg>
    </StageDescriptionBlockContentItemIcon>
  )
}
