import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { SimpleSliderProps } from './interface'
import {
  SimpleSliderContent,
  SimpleSliderTitle,
  SimpleSliderWrapper,
  StyledMark,
  StyledSimpleSlider,
  StyledThumb,
  StyledTrack,
} from './styles'
import { Info } from '../info'

export const SimpleSlider: FC<SimpleSliderProps> = props => {
  const { data, onSelect, title = '', description = '', selected, disabled = false } = props

  const [value, setValue] = useState(0)

  useEffect(() => {
    if (typeof selected !== 'undefined') {
      if (Number(selected)) {
        setValue(selected - 1) // "-1" - its shift from value to index
      } else {
        const index = data.findIndex(item => item['value'] === selected)

        if (index > -1) {
          setValue(index)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  const Track = useCallback(
    (trackProps, state) => <StyledTrack {...trackProps} index={state.index} />,
    [],
  )

  const Thumb = useCallback(thumbProps => {
    return (
      <StyledThumb {...thumbProps}>{/* <ThumbText>{state.valueNow}</ThumbText> */}</StyledThumb>
    )
  }, [])

  const Mark: any = useCallback(
    ({ key }) => {
      const item = data[key]
      const itemTitle = item ? item['title'] : ''

      return (
        <StyledMark isFirst={key === 0} isActive={key === value}>
          {itemTitle}
        </StyledMark>
      )
    },
    [data, value],
  )

  const handleChangeAfter = useCallback(
    val => {
      if (data && data[val]) {
        onSelect(data[val])
      }
    },
    [data, onSelect],
  )

  const maxValue = useMemo(() => {
    return data ? data.length - 1 : 0
  }, [data])

  const marks = useMemo(() => {
    return data.map((i, index) => index)
  }, [data])

  return (
    <SimpleSliderWrapper>
      {title && (
        <SimpleSliderTitle>
          <span>{title}</span>
          {description && <Info color="#bbc3d6">{description}</Info>}
        </SimpleSliderTitle>
      )}
      <SimpleSliderContent>
        {/* @ts-ignore */}
        <StyledSimpleSlider
          columnCount={data.length}
          value={value}
          renderTrack={Track}
          renderThumb={Thumb}
          renderMark={Mark}
          marks={marks}
          step={1}
          onAfterChange={handleChangeAfter}
          min={0}
          max={maxValue}
          disabled={disabled}
        />
      </SimpleSliderContent>
    </SimpleSliderWrapper>
  )
}
