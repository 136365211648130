import React from 'react'
import { StageDescriptionBlockContentItemIcon } from 'src/react-app/views/calculator/styles'

export const HeatSaving8 = () => {
  return (
    <StageDescriptionBlockContentItemIcon title="Для нежилых помещений">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.6"
          d="M32.5 40H7.5C3.3575 40 0 36.6425 0 32.5V7.5C0 3.3575 3.3575 0 7.5 0H32.5C36.6425 0 40 3.3575 40 7.5V32.5C40 36.6425 36.6425 40 32.5 40Z"
          fill="#E0E3E9"
        />
        <path
          d="M20.9998 20.8462V23.5C20.9998 23.9599 20.6271 24.3337 20.166 24.3337C19.7061 24.3337 19.3323 23.9599 19.3323 23.5V20.8462C18.3649 20.5013 17.666 19.5849 17.666 18.5C17.666 17.1212 18.7872 16 20.166 16C21.5448 16 22.666 17.1212 22.666 18.5C22.666 19.5849 21.9672 20.5013 20.9998 20.8462Z"
          fill="#ECEEF2"
        />
        <path
          d="M29 14.6191C29 14.501 28.9719 14.384 28.9179 14.2782L27.6567 11.8021C27.4036 11.3083 26.8951 11 26.3292 11H13.6707C13.1049 11 12.5953 11.3083 12.3433 11.8044L11.0821 14.2794C11.0281 14.384 11 14.501 11 14.6191V18.0268C11 18.0481 11.0056 18.0695 11.0067 18.092C11.0067 18.1033 11 18.1134 11 18.1246V26.75C11 27.9909 12.0091 29 13.25 29H26.75C27.9909 29 29 27.9909 29 26.75V18.1246C29 18.1134 28.9944 18.1033 28.9932 18.092C28.9932 18.0695 29 18.0493 29 18.0268V14.6191ZM26.75 27.5004H23.375V24.3132C23.375 23.1747 22.4502 22.25 21.3129 22.25H18.6882C17.5497 22.25 16.625 23.1748 16.625 24.3121V27.4993H13.25C12.836 27.4993 12.4996 27.1629 12.4996 26.7489V20.4331C12.8866 20.6322 13.3141 20.7493 13.7664 20.7493C14.5876 20.7493 15.3267 20.396 15.8341 19.8346C15.8555 19.8571 15.8769 19.8785 15.8994 19.8999C16.4495 20.4478 17.1898 20.7504 17.984 20.7504C18.8446 20.7504 19.6198 20.3859 20.1597 19.8054C20.18 19.829 20.2014 19.8515 20.2227 19.874C20.7301 20.4309 21.4512 20.7504 22.2005 20.7504C22.9824 20.7504 23.6889 20.4084 24.1917 19.8605C24.221 19.8909 24.2503 19.9213 24.2806 19.9505C24.7981 20.4669 25.4911 20.7504 26.2336 20.7504C26.6926 20.7504 27.119 20.6289 27.5004 20.4342V26.75C27.5004 27.164 27.164 27.5004 26.75 27.5004Z"
          fill="#1C3775"
        />
      </svg>
    </StageDescriptionBlockContentItemIcon>
  )
}
