import { format } from 'date-fns'
import ru from 'date-fns/locale/ru'

import { IFilterItem, IMapParam } from '../views/calculator/stage-calculator/interface'
import {
  casementsSizeAcceptHeightList,
  casementsSizeAcceptWidthList,
  prepareFilterData,
} from '../views/calculator/stage-calculator/consts'
import fetchAPI from '../../lib/utils/fetch-api'
import { TProduct } from '../views/calculator/interface'
import { md5Generator } from './m5generator'
import { glassUnitMatrix } from '../views/calculator/stage-quiz/consts'

// modern Chrome requires { passive: false } when adding event
let supportsPassive = false
try {
  // @ts-ignore
  // eslint-disable-next-line
  window.addEventListener(
    'test',
    () => null,
    Object.defineProperty({}, 'passive', {
      get() {
        supportsPassive = true

        return null
      },
    }) as any,
  )
 
} catch (e) {
  console.error(e)
}

const getIsCasements = filters => {
	const casements = filters?.find?.(item => item?.['key'] === 'casements-index')?.['value'] || []
	return casements?.filter?.(Number.isInteger).length !== 0
}

const wheelOpt: boolean | any = supportsPassive ? { passive: false, capture: true } : false
// eslint-disable-next-line no-undef
const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel'

export const getRegionFromHost = () => {
  // @ts-ignore
  // eslint-disable-next-line
  const { location } = window
    // @ts-ignore
  // eslint-disable-next-line
  const { host } = location
  const hostParts = host.split('.')
  return hostParts.length === 3 ? hostParts[0] : 'msk'
}

export const getProducts = async (
  type,
  products,
  filters,
  productTypeId: string,
  mapParams: IMapParam[],
  target?: string,
) => {
  const filter: IFilterItem[] = [{ key: 'production-type', value: type }]

  if (target && filters.length > 0) {
    filters.forEach(item => {
      if (item.key === target) {
        filter.push(item)
      }
    })
  }

  const data = await prepareFilterData(products, filter, mapParams, productTypeId)

  return {
    id: productTypeId,
    data,
  }
}

export const createHeaders = headers => {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...headers,
  }
}

export const getPrefix = (key, value) => {
  const valueIndex = key.indexOf(value)
  const prefix = valueIndex > 0 ? key.substr(0, valueIndex) : ''

  return prefix || ''
}

const cache_collections = {}
async function fetchAPIcache(url) {
  if (url in cache_collections) {
    return cache_collections[url]
  }
  const json = await fetchAPI(url)
  cache_collections[url] = json
  return json
}

export const getCollectionsObjectsRequest = name =>
  fetchAPIcache(`/api/collections/objects-by-name/${name}`)
  .then( collection => collectionMarkup(name, collection))

export const prepareDataFromSchema = (schema, objectData: Record<string, any> = {}) => {
  const result: Record<string, any> = {}
  const { data } = objectData
  const attributes = data && data['attributes'] ? data['attributes'] : {}
  const attributesKeys = Object.keys(attributes)

  for (const aKey of attributesKeys) {
    if (schema['properties'][aKey]) {
      result[schema['properties'][aKey]['code']] = attributes[aKey]
    }
  }

  return result
}

export const getElementCount = (key, product) => {
  const isPrice = product[key]['price']
  const leavesDepends = isPrice ? product[key]['number-of-leaves-depends'] : false

  /* TODO: rework for windowsill count */
  return leavesDepends ? product['configuration']['casements-number-of-leaves'] : 0
}

export const getElementValue = (parentKey, childKey, product) =>
  product[parentKey]['price'] ? product[parentKey][childKey] : 'Значение не указано'

export const validateEmail = value => {
  let error = ''

  if (!value || !value.trim()) {
    error = 'Это поле обязательное для заполнения'
  } else if (!/\S+@\S+\.\S+/.test(value) || /[а-яА-ЯЁё]/.test(value)) {
    error = 'Неверный формат'
  }

  return error
}

export const validatePhone = value => {
  let error = ''

  if (!value || !value.trim() || !value.replace(/\D/g, '').substring(10)) {
    error = 'Это поле обязательное для заполнения'
  }

  return error
}

export const validatePassword = (value = '') => {
  let error = ''

  if (!value.trim()) {
    error = 'Это поле обязательное для заполнения'
  } else if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/.test(value)) {
    error = 'Пароль должен содержать минимум 6 символов, 1 букву и 1 цифру'
  }

  return error
}

export const validateRequiredText = (value = '') => {
  let error = ''

  if (!value.trim()) {
    error = 'Это поле обязательное для заполнения'
  }

  return error
}

export const dateFNS = {
  code: 'ru',
  localize: ru,
  format: (date, dateFormat) => format(date, dateFormat, { locale: ru }),
  options: {
    // Index of the first day of the week.
    // Sunday is 0, Monday is 1, Saturday is 6.
    weekStartsOn: 1,

    // Nth of January which is always in the first week of the year. See:
    // https://en.wikipedia.org/wiki/Week#Week_numbering
    // http://www.pjh2.de/datetime/weeknumber/wnd.php?l=en
    firstWeekContainsDate: 1,
  },
}

export const splitString = (string = '', separator = ',') => {
  return string.split(separator).map(item => item.trim())
}

export const priceReducer = (accumulator, currentValue) => accumulator + currentValue

export const getRequestData = (requestsResult, keysList) => {
  const data: Record<string, any> = {}

  keysList.forEach((key, index) => {
    const item = requestsResult[index]

    if (item && item['data'] && item['data']['data']) {
      data[key] = item['data']['data']
    }
  })

  return data
}

export function jsonParse<T>(selected = '{}'): T | Record<string, string> | {}[] {
  let result = {}

  try {
    result = JSON.parse(selected)
  } catch (parseError) {
    console.error(parseError)
  }

  return result
}

export const getItemsTargetKey = (items: {}[]) => {
  let result = 'value'

  if (items.length > 0) {
    result = items[0]['target-key'] ? items[0]['target-key'] : result
  }

  return result
}

export const extractValue = (source, path) => {
  let result = source

  if (source[path]) {
    result = source[path]
  }

  return result
}

export const clearCasements = (casements: string[]) =>
  casements
    .map(casement => casement.replace('[', ''))
    .map(casement => casement.replace(']', ''))
    .map(group => splitString(group))

export const getHandlebars = (name: string): string[] | null =>
  (name && name.match(/{{([\s\S]+?)}}/gi)) || null

export const getMosquitoPreparedIndexes = (mosquitoIndex: number[] = []): string => {
  const result = mosquitoIndex.sort((a, b) => a - b).map(index => `S${index + 1}`)

  return result.join(', ')
}

export const handlerReplaceHandlebars = (name, product, sizes, filters?: IFilterItem[]) => {
  const getMosquitoValueHandlebars = (handlebarsKey, value) => {
    let result = value
    const mosquitoIndex = filters && filters.find(filter => filter.key === 'mosquito-index')
    const casementsSizes = product['configuration']['casements-sizes']
    const casementsSizesList = clearCasements(splitString(casementsSizes, '],'))

    if (mosquitoIndex && mosquitoIndex['value'] && Array.isArray(mosquitoIndex['value'])) {
      const casementsParsed: string[] = []

      mosquitoIndex['value'].forEach((casementIndex, groupIndex) => {
        const casementName = casementsSizesList[groupIndex][casementIndex]

        casementsParsed.push(handlerReplaceHandlebars(casementName, product, sizes, filters))
      })

      result = value.replace(handlebarsKey, casementsParsed.join(', '))
    }

    return result
  }

  const handlebars = getHandlebars(name)
  let result = name

  if (handlebars !== null) {
    let newName = name

    handlebars.forEach(handlebarsKey => {
      const clearKey = handlebarsKey.substr(2, handlebarsKey.length - 4)
      const isPatch = clearKey.indexOf('/') > -1
      const isSize = [
        'width',
        'length',
        'height',
        'impost',
        'casements-x',
        'casements-y',
        'sizes',
        'model-width',
        'model-height',
        'ebb-type-width',
        'ebb-type-length',
        'inner-slope-type-width',
        'inner-slope-type-length',
        'outer-slope-type-width',
        'outer-slope-type-length',
        'windowsill-model-width',
        'windowsill-model-length',
        'mosquito-type-sizes',
      ].some(key => clearKey.indexOf(key) > -1)
      let value = ''

      if (isPatch) {
        let productValue = { ...product }

        clearKey.split('/').forEach(patch => {
          productValue = extractValue(productValue, patch)
        })
        if (productValue && productValue.constructor === String) {
          value = `${productValue}`
        }
      }

      if (isSize) {
        if (clearKey.indexOf('/') > -1) {
          const [key, index] = clearKey.split('/')

          value = `${sizes[key][index]} мм`
        } else if (clearKey === 'mosquito-type-sizes' && filters) {
          newName = getMosquitoValueHandlebars(handlebarsKey, newName)
        } else {
          value = `${sizes[clearKey]} мм`
        }
      }

      if (['mosquito-type-index', 'mosquito-index'].includes(clearKey) && filters) {
        const mosquitoIndex = filters.find(filter => filter.key === 'mosquito-index')
        const preparedIndexes =
          mosquitoIndex && getMosquitoPreparedIndexes(mosquitoIndex['value'] as number[])
        newName = newName.replace(handlebarsKey, preparedIndexes)
      } else if (clearKey === 'casements' && filters) {
        const casementsIndex = filters.find(filter => filter.key === 'casements-index')
        const casementsName = product['configuration']['casements-name']
        const casementsNameList = clearCasements(splitString(casementsName, '],'))

        if (casementsIndex && casementsIndex['value'] && Array.isArray(casementsIndex['value'])) {
          const casementsParsed: string[] = []

          casementsIndex['value'].forEach((casementIndex, groupIndex) => {
            const casementName = casementsNameList[groupIndex][casementIndex]

            casementsParsed.push(handlerReplaceHandlebars(casementName, product, sizes, filters))
          })

          newName = newName.replace(handlebarsKey, casementsParsed.join(', '))
        }
      }

      if (value) {
        const checkHaveHandlebars = getHandlebars(value)

        if (checkHaveHandlebars !== null) {
          value = handlerReplaceHandlebars(value, product, sizes, filters)
        }

        newName = newName.replace(handlebarsKey, value)
      }
    })

    result = newName
  }

  return result
}

export const replaceHandlebars = (parentKey, childKey, product, sizes, filters?: IFilterItem[]) => {
  const name = product[parentKey] && product[parentKey][childKey]

  return handlerReplaceHandlebars(name, product, sizes, filters)
}

export const getProductKeyStr = (product, sizes): string => {
  return `${product['window-model']['value']}-${product['configuration']['title']}-${sizes['model-width']}-${sizes['model-height']}`
}

export const getProductKey = (product, sizes): string => {
  return md5Generator(getProductKeyStr(product, sizes))
}

export const prepareTableData = (
  { product, sizes, filters, type },
  priceData,
  productType,
  elementsData,
  number = 0,
) => {
  const result: Record<string, string | number>[] = []
  const getPrice = (key: string): number => {
    let priceResult = 0

    if (priceData) {
      if (key === `${productType}-model`) {
        priceResult = priceData['itemPrice']
      } else {
        priceResult =
          (priceData['additionalPrice'] &&
            priceData['additionalPrice'][key] &&
            priceData['additionalPrice'][key]['price']) ||
          0
      }
    }

    return priceResult
  }

  Object.keys(product).forEach(key => {
    const isOrder = product[key] ? product[key]['is-order'] : false
    const productKey = isOrder ? key : null

    if (productKey) {
      const accumulator: string[] = []
      const price = getPrice(productKey)
      const quantity = priceData?.count || 1
      let dimension = ''

      if (key === `${type}-model`) {
        accumulator.push(replaceHandlebars('configuration', 'description', product, sizes, filters))
      } else if (product[key]['name']) {
        accumulator.push(replaceHandlebars(key, 'name', product, sizes, filters))
      } else {
        if (product[key]['title']) {
          accumulator.push(replaceHandlebars(key, 'title', product, sizes, filters))
        }

        if (product[key]['description']) {
          accumulator.push(replaceHandlebars(key, 'description', product, sizes, filters))
        }
      }
      if (product[key]['sizes']) {
        dimension = replaceHandlebars(key, 'sizes', product, sizes, filters)
      }

      // Есть ли створки у окна?
      const IsCasements = getIsCasements(filters)
      /*
      /*
          window-hand - цвет ручки для окна
          Условие отбрасывает параметры с ценой ноль, если ключ паараметра не ходит в список
      */
     //console.log(productKey, 1)
     //const showAdditional =  price > 0

      const showAdditional = (['window-hand'].includes(productKey) && IsCasements) ||  price > 0 || ['window-model'].includes(productKey)

      if (showAdditional && accumulator.length > 0) {
        let name = ''
        if(product['vendor-code']) {
          name = 'Артикул: ' + product['vendor-code'] + "\n"
        }
        name += accumulator.join(' - ').replace(/<br ?\/?>/g, "\n")

        /* TODO: rework price getting - accumulate price on up level */
        result.push({
          name,
          price,
          number,
          quantity,
          dimension,
          // description: description
        })
      }
    }
  })

  return result
}

export const getElementsData = (
  product: TProduct,
  additionalPrice: Record<string, number>,
  sizes: Record<string, any> = {},
  filters: IFilterItem[] = [],
) => {
  const result: Record<string, {}> = {}

  if (additionalPrice) {
    Object.keys(additionalPrice).forEach(key => {
      // Есть ли створки у окна?
      const IsCasements = getIsCasements(filters)
      /*
          window-hand - цвет ручки для окна
          Условие отбрасывает параметры с ценой ноль, если ключ паараметра не ходит в список
      */
      //    const showAdditional =  additionalPrice?.[key]?.['price'] > 0
      const showAdditional = (['window-hand'].includes(key) && IsCasements) || additionalPrice?.[key]?.['price'] > 0
      if (additionalPrice[key] && showAdditional) {
        const name = product[key]
          ? handlerReplaceHandlebars(product[key]['name'], product, sizes, filters)
          : ''

        result[key] = {
          ...product[key],
          name,
          price: additionalPrice[key]['price'],
          count: additionalPrice[key]['count'],
        }
      }
    })
  }

  return result
}

export const prepareOrderProducts = (
  products,
  totalPrice,
  productType: string| null = 'window',
  filters?: IFilterItem[],
) => {
  console.log({ products, totalPrice, productType, filters })

  let transitionProducts: Record<string, string | number>[] = []
  const result = products.map((product, index) => {
    const additionalPrice = totalPrice[index] ? totalPrice[index]['additionalPrice'] : {}
    const elementsData = getElementsData(
      product,
      additionalPrice,
      product['sizes'],
      filters || product['filters'],
    )

    const table = prepareTableData(product, totalPrice[index], productType ?? 'window', elementsData, index + 1)

    console.log({ table })

    //transitionProducts = [...transitionProducts, ...table]
    transitionProducts.push(...table)

    return {
      product,
      cost: totalPrice[index] ? totalPrice[index]['total'] : 0,
      print: elementsData,
      preview: product['preview'],
      type: product['type'],
    }
  })

  return { newProducts: result, transitionProducts }
}

export const getArraySizes: (count: number, size: number, maxWidth: number) => number[] = (
  count,
  size,
  maxWidth,
) => {
  const result = new Array(count).fill(Math.round(size))

  const unitResultWithoutLastSize = result.reduce((acc, r) => acc + r, 0) - size

  result[result.length - 1] = maxWidth - unitResultWithoutLastSize

  return result
}

export const getSliderSizes = (
  sizes: number[],
  maxSize: number,
  value: number,
  count: number,
  index: number,
  invert?: boolean,
) => {
  const isLastSize = count === index + 1
  const newSizes: number[] = [...sizes]
  let nextItemIndexUpper = 1

  if (isLastSize && count > 1) {
    nextItemIndexUpper = -1
  } else if (invert && index !== 0) {
    nextItemIndexUpper = -1
  }

  const nextItemIndex = index + nextItemIndexUpper
  const currentSizeDelta = sizes[index] - value

  newSizes[index] = value
  newSizes[nextItemIndex] = sizes[nextItemIndex] + currentSizeDelta

  return newSizes
}

export function fixBody() {
  const handleToggle = (needFix = false) => {
    const { body } = document

    if (body) {
      if (needFix) {
        body.style.height = 'auto'
        body.style.overflow = 'hidden'
        body.classList.add('modal-open')
      } else {
        body.style.height = 'initial'
        body.style.overflow = 'initial'
        body.classList.remove('modal-open')
      }
    }
  }

  return {
    toggleFixBody: handleToggle,
  }
}

export const arrayAssign = (state, index, newData) => [
  ...state.slice(0, index),
  newData,
  ...state.slice(index + 1, state.length),
]

export const findAllIndexes = (arr: any[] = [], key = '') => {
  const indexes: number[] = []

  arr.forEach((item, index) => {
    if (item?.['key'] === key) {
      indexes.push(index)
    }
  })

  return indexes
}

export const getFilterItemIndex = (filtersState: IFilterItem[], targetKey) => {
  return filtersState.findIndex(filter => filter.key === targetKey)
}

export const updateFilterItemData = (targetKey, filtersState, data) => {
  let filterItemIndex = getFilterItemIndex(filtersState, targetKey)

  if (filterItemIndex === -1) {
    filterItemIndex = filtersState.length
  }

  return arrayAssign(filtersState, filterItemIndex, data)
}

export const parseDate = (date: string, dateFormat = 'dd.MM.yyyy') =>
  date ? dateFNS.format(new Date(date), dateFormat) : ''

export const accessGlassUnitList = [
  'heat-saving',
  'soundproofing',
  'sun-protection',
  'window-model',
]

/* получение названия стеклопакета исходя из его параметров*/
export const getGlassUnit = (glassUnitData: Record<string, any>) => {
  let result = ''
  const glassUnitKeys = Object.keys(glassUnitData).filter(key => accessGlassUnitList.includes(key))

  if (glassUnitMatrix.length > 0) {
    let currentGlassUnits = [...glassUnitMatrix]

    glassUnitKeys.forEach(key => {
      currentGlassUnits = currentGlassUnits.filter(item => item[key] === glassUnitData[key])
    })

    if (currentGlassUnits.length > 0) {
      result = currentGlassUnits[0]['value']
    }
  }

  return result
}

export const checkAttributeIsDisabled = (disabledKey = '', filters) => {
  let result = false
  const disabledItem = filters.find(filter => filter['key'] === disabledKey)

  if (disabledKey && disabledItem) {
    result = disabledItem['value'] === true
  }

  return result
}

export const getCasementsValue = (product: TProduct, index: number): null | string[] => {
  let result: null | string[] = null
  const casementsValue =
    (product['configuration'] && product['configuration']['casements-value']) || ''
  const casementsValueGroup = casementsValue ? splitString(casementsValue, '], ') : []

  if (casementsValueGroup[index]) {
    const clearValue = casementsValueGroup[index].replace(/(\[)|(])/g, '')

    result = splitString(clearValue)
  }

  return result
}

export const getCasementsValues = (product: TProduct, filters?: IFilterItem[]) => {
  let result: string[] = []
  const casementsIndex = filters?.find(item => item['key'] === 'casements-index')

  if (casementsIndex && casementsIndex['value'] && Array.isArray(casementsIndex['value'])) {
    result = casementsIndex['value'].reduce((acc, index, groupIndex) => {
      const groupValue = getCasementsValue(product, groupIndex)

      if (groupValue && groupValue[index]) {
        acc.push(groupValue[index])
      }

      return acc
    }, [])
  }

  return result
}

export const getAxisCoord = (data, axis, size, clearView, zeroCoord) => {
  let result = data[size] / 2 + (data['delta'] ? data['delta'][axis] : 0)

  if (clearView) {
    result -= zeroCoord[axis] - 25
  }

  return result
}

export const checkIsHaveDoor = product => {
  const result = { isCasementsDoor: false, doorGroup: 0 }
  const casementsValue = product['configuration'] && product['configuration']['casements-value']
  const casementsValueGroup = casementsValue
    ? splitString(casementsValue, '], ').map(group =>
        splitString(group).map(casement => casement.replace(/[|]/g, '')),
      )
    : []

  casementsValueGroup.forEach((group, index) => {
    if (!result['isCasementsDoor']) {
      result['isCasementsDoor'] = group.some(casement => casement.indexOf('(Д)') !== -1)

      if (result['isCasementsDoor']) {
        result['doorGroup'] = index
      }
    }
  })

  return result
}

/** Get casements formulas
 * @param {Object} configuration - settings
 * @return {Array} - array of casements formulas
 * */
export const getCasementsFormulaGroups = configuration => {
  const casementsFormulaStr = configuration['casements-formula']
  let result: string[][] = []

  if (casementsFormulaStr) {
    result = splitString(casementsFormulaStr).map(formula => {
      return splitString(formula, '+')
        .map(formulaGroup => {
          return splitString(formulaGroup, '*')
        })
        .map(formulaGroupItem => {
          return formulaGroupItem[1]
        })
    })
  }

  return result
}

/** Get index of selected casements
 * @param {Number} casementGroupIndex - casement group index
 * @param {Array} filters - array of product data
 * @return {Number} - casement index */
export const getCasementIndex = (casementGroupIndex: number, filters: IFilterItem[]) => {
  const casementsIndex: IFilterItem = filters.find(
    filter => filter['key'] === 'casements-index',
  ) || {
    key: '',
    value: [],
  }

  return casementsIndex['value'] ? casementsIndex['value'][casementGroupIndex] : 0
}

/** Get casement size accept group
 * @param {String} key - key for search
 * @param {Number} value - value for search
 * @return {Object} - accept size group
 * */
export const getCasementSizeAcceptGroup = (key: string, value: number) => {
  const acceptRange =
    key === 'height' ? casementsSizeAcceptHeightList : casementsSizeAcceptWidthList
  return acceptRange.find(acceptGroup => {
    let thisGroup = false
    const sizes = acceptGroup[key].split('~')

    if (Number(sizes[0]) <= value && Number(sizes[1]) >= value) {
      thisGroup = true
    }
    
    return thisGroup
  })
}

/** Get type of casement
 * @param {Object} configuration - settings
 * @param {Number} casementGroupIndex - casement group
 * @param {Number} casementIndex - casement index
 * @return {String} - type of casement
 * */
export const getCasementType = (
  configuration: Record<string, any>,
  casementGroupIndex: number,
  casementIndex: number,
) => {
  let result = ''
  const casementsValueStr = configuration['casements-value'] || ''
  const casementsTypeGroups = clearCasements(splitString(casementsValueStr, '],'))

  if (
    casementIndex >= 0 &&
    casementsTypeGroups[casementGroupIndex] &&
    casementsTypeGroups[casementGroupIndex][casementIndex]
  ) {
    result = casementsTypeGroups[casementGroupIndex][casementIndex]
  }

  return result
}

/** Make check accept of size
 * @param {Object} newSize - new product sizes
 * @param {Object} oldSizes - old product sizes
 * @param {Object} product - specification
 * @param {Array} filters - data of product
 * @return {Array} - array of errors if have
 * */
export const checkSizeAccept = (
  newSize: IFilterItem,
  oldSizes: Record<string, any>,
  product: TProduct,
  filters: IFilterItem[],
) => {
  const result: { title: string; description: string }[] = []
  const configuration = product['configuration'] || {}
  /** casements formulas */
  const casementsFormulaGroups = getCasementsFormulaGroups(configuration)
  const isWidth = ['casements-x', 'model-width'].includes(newSize['key'])
  const currentSizeKey = isWidth ? 'width' : 'height'
  const otherSizeKey = isWidth ? 'height' : 'width'
  const currentAxisKey = isWidth ? 'x' : 'y'
  const otherAxisKey = isWidth ? 'y' : 'x'

  if (newSize['value'] && Array.isArray(newSize['value']) && casementsFormulaGroups.length > 0) {
    casementsFormulaGroups.forEach((casementFormula, casementGroupIndex) => {
      const currentAxisValueRaw = casementFormula.find(
        formula => formula.indexOf(currentAxisKey) !== -1,
      )
      const currentAxisValue = currentAxisValueRaw ? Number(currentAxisValueRaw[1]) - 1 : 0
      const otherAxisValue = casementFormula.find(formula => formula.indexOf(otherAxisKey) !== -1)
      const casementIndex = getCasementIndex(casementGroupIndex, filters)
      const casementType = getCasementType(configuration, casementGroupIndex, casementIndex)

      /** Casements type transom has no restrictions */
      if (casementType && casementType !== 'transom') {
        const otherCasementsGroupIndex = otherAxisValue ? Number(otherAxisValue[1]) - 1 : 0
        const otherSizeValue = oldSizes[`casements-${otherAxisKey}`][otherCasementsGroupIndex]
        const currentSizeValue = newSize['value'] && newSize['value'][currentAxisValue]
        const acceptSizesGroup = getCasementSizeAcceptGroup(otherSizeKey, otherSizeValue)
        const checkAcceptSizesGroup = getCasementSizeAcceptGroup(currentSizeKey, currentSizeValue)

        if (!acceptSizesGroup && !checkAcceptSizesGroup) {
          result[currentAxisValue] = {
            title: 'Недопустимый размер',
            description: 'Измените ширину и/или высоту створки, либо закажите онлайн консультацию',
          }
        } else if (acceptSizesGroup && newSize['value'] && newSize['value'][currentAxisValue]) {
          const value = newSize['value'][currentAxisValue]
          const range = acceptSizesGroup[currentSizeKey].split('~')
          const inRange = Number(range[0]) <= value && Number(range[1]) >= value

          if (!inRange) {
            result[currentAxisValue] = {
              title: 'Недопустимый размер',
              description: `Ограничение: от ${range[0]} до ${range[1]}. Измените ширину и/или высоту створки, либо закажите онлайн консультацию`,
            }
          }
        }
      }
    })
  }

  return result
}

/** Make cross size checking of accept size
 * @param {Object} sizes - product sizes
 * @param {Object} product - specification
 * @param {Array} filters - data of product
 * @return {Object} - object of errors if have
 * */
export const crossCheckSizeAccept = (
  sizes: Record<string, any>,
  product: TProduct,
  filters: IFilterItem[],
): Record<string, { title: string; description: string }[]> => {
  const casementXKey = 'casements-x'
  const casementYKey = 'casements-y'
  const casementsXErrors = checkSizeAccept(
    { key: casementXKey, value: sizes[casementXKey] },
    sizes,
    product,
    filters,
  )
  const casementsYErrors = checkSizeAccept(
    { key: casementYKey, value: sizes[casementYKey] },
    sizes,
    product,
    filters,
  )

  return {
    [casementXKey]: casementsXErrors,
    [casementYKey]: casementsYErrors,
  }
}

/** Get error key of casements
 * @param {String} key - key of changed size
 * @return {String} - key for casements size errors
 * */
export const getCasementsErrorKey = (key: string): string => {
  let result = key

  if (key === 'model-width') {
    result = 'casements-x'
  } else if (key === 'model-height') {
    result = 'casements-y'
  }

  return result
}

/** Prepare string size from configuration
 * @param {String} size - size from configuration
 * @return {Object} - parsed size
 * */
export const parseConfigurationSize = (size: string) => {
  let result: Record<string, any> | null = null

  try {
    if (size) {
      result = jsonParse(size)
    }
  } catch (e) {
    console.error('parseConfigurationSize', e)
  }

  return result
}


const type8 = ['ebb-type','aeration','security','children-security','comfortable-floriculture','inner-slope-type','mosquito-type','outer-slope-type','window-hand','windowsill-model', 'impost-vertical', 'impost-horizontal', 'reinforced-windows-for-high-rise-buildings', 'elderly', 'pet-safety', 'sun-protection', 'layout-model']
const colors = ['inner-slope-type-color','model-inside-color','model-outside-color','model-profile-color','mosquito-type-color','outer-slope-type-color','ebb-type-color', 'layout-model-inside-color', 'layout-model-outside-color', 'windowsill-model-color']
const unit2 = ['glass-unit-2']
const unit = ['glass-unit']
const casements = ['casements']
const window2 = ['window-price']

function parsePr(pr) {
  if (Number.isFinite(pr)) {
    return pr
  }
  if (typeof pr === 'string') {
    const nextPr = parseFloat(pr)
    return Number.isFinite(nextPr) ? nextPr : 0
  }
  return 0
}

function getCalcPricePr(price, pr, pr2?: number | string ) {
  const numPr1 = parsePr(pr)
  const numPr2 = parsePr(pr2)
  const d1 = Math.ceil(price * (numPr1 / 100)) || 0
  const d2 = Math.ceil(price * (numPr2 / 100)) || 0
  return price + d1 + d2
}

function objectMarkup(name, object) {
  const attributes = object?.['data']?.['attributes']
  if (type8.includes(name)) {
    const price = getCalcPricePr(attributes?.['field_1'], attributes?.['field_2'], attributes?.['field_8'])
    return { data: { ...object?.['data'], attributes: { ...attributes, ['field_1']: price } } }
  }
  if (unit2.includes(name)) {
    const attributes = object?.['data']?.['attributes']
    const pr = attributes?.['coefficient']
    const field_1 = attributes?.['field_1']?.map?.(item => {
      return { 
        ...item, 
        'price_1': getCalcPricePr(item['price_1'], pr), 
        'price_2': getCalcPricePr(item['price_2'], pr), 
        'price_3': getCalcPricePr(item['price_3'], pr)
      }
    })
    return { data: { ...object?.['data'], attributes: { ...attributes, ['field_1']: field_1 } } }
  }
  if (unit.includes(name)) {
    const attributes = object?.['data']?.['attributes']
    const pr = attributes?.['field_2']
    const field_1 = attributes?.['field_1']?.map?.(item => {
      return { 
        ...item, 
        'price': getCalcPricePr(item['price'], pr)
      }
    })
    return { data: { ...object?.['data'], attributes: { ...attributes, ['field_1']: field_1 } } }
  }
  if (casements.includes(name)) {
    const attributes = object?.['data']?.['attributes']
    const pr = attributes?.['field_2']
    const pr2 = attributes?.['field_8']
    const field_1 = attributes?.['field_1']?.map?.(item => {
      return { 
        ...item, 
        'price': getCalcPricePr(item['price'], pr, pr2)
      }
    })
    return { data: { ...object?.['data'], attributes: { ...attributes, ['field_1']: field_1 } } }
  }
  if (window2.includes(name)) {
    const attributes = object?.['data']?.['attributes']
    const price = getCalcPricePr(attributes?.['field_1'], attributes?.['field_2'], attributes?.['field_3'])
    return { data: { ...object?.['data'], attributes: { ...attributes, ['field_1']: price } } }
  }
  if (colors.includes(name)) {
    return object
  }
  return object
}

export function collectionMarkup(name, data) {
  const collection = data?.['data']?.['data']
  if (Array.isArray(collection) === false) {
    return data
  }
  const result = collection.map(object => objectMarkup(name, object))
  return { data: {  data: result }}
}