import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement43Square82Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#6filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M192 104.999C192 103.895 191.105 103 190.001 103C188.896 103 188.001 103.895 188.001 104.999V107.167H188.001V118H188V121.001C188 122.105 188.895 123 189.999 123C191.104 123 191.999 122.105 191.999 121.001V118.833H192V108H192V104.999Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 192 107.167)"
          fill="#ABABAB"
        />
        <path
          d="M192 103.94C192 103.421 191.579 103 191.06 103H188.941C188.422 103 188.001 103.421 188.001 103.94V108H192V103.94Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M188 122.06C188 122.579 188.421 123 188.94 123H191.059C191.578 123 191.999 122.579 191.999 122.06V118H188V122.06Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 192 108.833)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#6filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M192 131.999C192 130.895 191.105 130 190.001 130C188.896 130 188.001 130.895 188.001 131.999V134.167H188.001V145H188V148.001C188 149.105 188.895 150 189.999 150C191.104 150 191.999 149.105 191.999 148.001V145.833H192V135H192V131.999Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 192 134.167)"
          fill="#ABABAB"
        />
        <path
          d="M192 130.94C192 130.421 191.579 130 191.06 130H188.941C188.422 130 188.001 130.421 188.001 130.94V135H192V130.94Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M188 149.06C188 149.579 188.421 150 188.94 150H191.059C191.578 150 191.999 149.579 191.999 149.06V145H188V149.06Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 192 135.833)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#6filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 99H115V154H188V99ZM178 109H125V144H178V109Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 99H115V154H116V100H187V154H188V99Z"
          fill="#FBFBFB"
        />
        <g filter="url(#6filter3_i)">
          <rect
            x="125.47"
            y="109.47"
            width="52.0601"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="119.53"
          y="103.53"
          width="63.9399"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="117"
          y="119"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#6filter4_d)">
          <rect x="117.702" y="119.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M118.286 130.796C118.798 131.458 119.6 131.884 120.502 131.884C122.049 131.884 123.302 130.63 123.302 129.084V122.444C123.302 121.456 122.79 120.587 122.017 120.089C122.383 120.562 122.6 121.156 122.6 121.8V128.44C122.6 129.986 121.347 131.24 119.8 131.24C119.242 131.24 118.723 131.077 118.286 130.796Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M120.502 119.644C118.955 119.644 117.702 120.898 117.702 122.444V128.44C117.702 128.46 117.702 128.48 117.702 128.501V123.024C117.702 121.477 118.956 120.224 120.502 120.224C122.028 120.224 123.269 121.444 123.302 122.963V122.444C123.302 120.898 122.048 119.644 120.502 119.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#6filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M120.5 123C119.672 123 119 123.672 119 124.5V124.714V126V144.5C119 145.328 119.672 146 120.5 146V146C121.328 146 122 145.328 122 144.5V128V124.5L122 124.286C122 123.576 121.424 123 120.714 123V123C120.677 123 120.64 123.001 120.604 123.004C120.569 123.001 120.535 123 120.5 123Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M176.5 142.306L127.624 126.014L176.5 110.681L176.5 142.306Z" stroke="white" />
        <defs>
          <filter
            id="6filter0_d"
            x="186.12"
            y="101.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6filter1_d"
            x="186.12"
            y="128.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6filter2_d"
            x="110.3"
            y="94.3003"
            width="84.2793"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6filter3_i"
            x="125"
            y="109"
            width="53.9399"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="6filter4_d"
            x="115.822"
            y="117.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6filter5_d"
            x="117.12"
            y="121.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="194.8"
            y1="120.5"
            x2="187.2"
            y2="120.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="185.202"
            y1="105.5"
            x2="192.8"
            y2="105.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="194.798"
            y1="120.5"
            x2="187.2"
            y2="120.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="194.8"
            y1="147.5"
            x2="187.2"
            y2="147.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="185.202"
            y1="132.5"
            x2="192.8"
            y2="132.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="194.798"
            y1="147.5"
            x2="187.2"
            y2="147.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
