import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement51Square53Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M353.001 291C354.105 291 355 290.105 355 289.001C355 287.896 354.105 287.001 353.001 287.001L350.833 287.001L350.833 287.001L340 287.001L340 287L336.999 287C335.895 287 335 287.895 335 288.999C335 290.104 335.895 290.999 336.999 290.999L339.167 290.999L339.167 291L350 291L350 291L353.001 291Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 350.834 291)"
          fill="#ABABAB"
        />
        <path
          d="M354.06 291C354.579 291 355 290.579 355 290.06L355 287.941C355 287.422 354.579 287.001 354.06 287.001L350 287.001L350 291L354.06 291Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M335.94 287C335.421 287 335 287.421 335 287.94L335 290.059C335 290.578 335.421 290.999 335.94 290.999L340 290.999L340 287L335.94 287Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 349.167 291)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M409.001 291C410.105 291 411 290.105 411 289.001C411 287.896 410.105 287.001 409.001 287.001L406.833 287.001L406.833 287.001L396 287.001L396 287L392.999 287C391.895 287 391 287.895 391 288.999C391 290.104 391.895 290.999 392.999 290.999L395.167 290.999L395.167 291L406 291L406 291L409.001 291Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 406.834 291)"
          fill="#ABABAB"
        />
        <path
          d="M410.06 291C410.579 291 411 290.579 411 290.06L411 287.941C411 287.422 410.579 287.001 410.06 287.001L406 287.001L406 291L410.06 291Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M391.94 287C391.421 287 391 287.421 391 287.94L391 290.059C391 290.578 391.421 290.999 391.94 290.999L396 290.999L396 287L391.94 287Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 405.167 291)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 121H329V287H417V121ZM406 132H340V276H406V132Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#filter3_i)">
          <rect
            x="340.47"
            y="132.47"
            width="65.0601"
            height="143.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="334.5" y="126.5" width="77" height="155" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 121H329V287H330V122H416V287H417V121Z"
          fill="#FBFBFB"
        />
        <path d="M341.625 274.5L404.375 274.5L373 135.274L341.625 274.5Z" stroke="white" />
        <rect
          x="380.528"
          y="123"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 380.528 123)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#filter4_d)">
          <rect
            x="379.884"
            y="123.701"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 379.884 123.701)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M379.767 125.694C379.42 124.541 378.351 123.701 377.085 123.701L370.445 123.701C368.898 123.701 367.645 124.955 367.645 126.501C367.645 126.536 367.645 126.571 367.647 126.606C367.993 127.759 369.063 128.599 370.329 128.599L376.969 128.599C378.515 128.599 379.769 127.346 379.769 125.799C379.769 125.764 379.768 125.729 379.767 125.694Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M378.409 124.034C378.266 124.011 378.118 123.999 377.968 123.999L371.328 123.999C369.782 123.999 368.528 125.253 368.528 126.799C368.528 127.866 369.125 128.794 370.003 129.267C368.666 129.055 367.644 127.898 367.644 126.501C367.644 124.955 368.898 123.701 370.444 123.701L377.084 123.701C377.564 123.701 378.015 123.822 378.409 124.034Z"
          fill="#FBFBFB"
        />
        <g filter="url(#filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M393.528 126.5C393.528 125.672 392.857 125 392.028 125L391.814 125L390.528 125L372.028 125C371.2 125 370.528 125.672 370.528 126.5V126.5C370.528 127.328 371.2 128 372.028 128L388.528 128L392.028 128L392.243 128C392.953 128 393.528 127.424 393.528 126.714V126.714C393.528 126.677 393.527 126.64 393.525 126.604C393.527 126.569 393.528 126.535 393.528 126.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="333.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter1_d"
            x="389.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter2_d"
            x="324.3"
            y="116.3"
            width="99.2793"
            height="177.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter3_i"
            x="340"
            y="132"
            width="66.9399"
            height="146.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter4_d"
            x="365.764"
            y="121.821"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter5_d"
            x="369.649"
            y="123.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="337.5"
            y1="293.8"
            x2="337.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="352.5"
            y1="284.202"
            x2="352.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="337.5"
            y1="293.798"
            x2="337.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="393.5"
            y1="293.8"
            x2="393.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="408.5"
            y1="284.202"
            x2="408.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="393.5"
            y1="293.798"
            x2="393.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
