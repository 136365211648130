import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement35Square8Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#3-5_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M236.999 30C235.895 30 235 30.8952 235 31.9995C235 33.1038 235.895 33.999 236.999 33.999L239.167 33.999L239.167 33.999L250 33.999L250 34L253.001 34C254.105 34 255 33.1048 255 32.0005C255 30.8962 254.105 30.001 253.001 30.001L250.833 30.001L250.833 30L240 30L240 30L236.999 30Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 239.167 30)"
          fill="#ABABAB"
        />
        <path
          d="M235.94 30C235.421 30 235 30.4208 235 30.9399L235 33.059C235 33.5781 235.421 33.999 235.94 33.999L240 33.999L240 30L235.94 30Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M254.06 34C254.579 34 255 33.5792 255 33.0601L255 30.941C255 30.4219 254.579 30.001 254.06 30.001L250 30.001L250 34L254.06 34Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 240.833 30)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#3-5_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M296.999 30C295.895 30 295 30.8952 295 31.9995C295 33.1038 295.895 33.999 296.999 33.999L299.167 33.999L299.167 33.999L310 33.999L310 34L313.001 34C314.105 34 315 33.1048 315 32.0005C315 30.8962 314.105 30.001 313.001 30.001L310.833 30.001L310.833 30L300 30L300 30L296.999 30Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 299.167 30)"
          fill="#ABABAB"
        />
        <path
          d="M295.94 30C295.421 30 295 30.4208 295 30.9399L295 33.059C295 33.5781 295.421 33.999 295.94 33.999L300 33.999L300 30L295.94 30Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M314.06 34C314.579 34 315 33.5792 315 33.0601L315 30.941C315 30.4219 314.579 30.001 314.06 30.001L310 30.001L310 34L314.06 34Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 300.833 30)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#3-5_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M319 34H230V155H319V34ZM308 45H241V144H308V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M319 34H230V155H231.219V35H317.781V155H319V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-5_filter3_i)">
          <rect
            x="241.47"
            y="45.47"
            width="66.0601"
            height="98.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="234.53"
          y="38.53"
          width="79.9399"
          height="111.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="281"
          y="146"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 281 146)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="280.356"
          y="146.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 280.356 146.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M280.238 148.695C279.892 147.542 278.822 146.702 277.556 146.702L270.916 146.702C269.37 146.702 268.116 147.956 268.116 149.502C268.116 149.537 268.117 149.572 268.118 149.607C268.464 150.76 269.534 151.6 270.8 151.6L277.44 151.6C278.986 151.6 280.24 150.346 280.24 148.8C280.24 148.765 280.239 148.73 280.238 148.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M278.881 147.035C278.737 147.012 278.59 147 278.44 147L271.8 147C270.254 147 269 148.254 269 149.8C269 150.867 269.597 151.795 270.475 152.267C269.138 152.056 268.116 150.898 268.116 149.502C268.116 147.956 269.37 146.702 270.916 146.702L277.556 146.702C278.035 146.702 278.486 146.822 278.881 147.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-5_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M277 149.5C277 148.672 276.328 148 275.5 148L275.286 148L274 148L255.5 148C254.672 148 254 148.672 254 149.5V149.5C254 150.328 254.672 151 255.5 151L272 151L275.5 151L275.714 151C276.424 151 277 150.424 277 149.714V149.714C277 149.677 276.999 149.64 276.996 149.604C276.999 149.569 277 149.535 277 149.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M306.305 46.5L274.5 141.426L242.695 46.4999L306.305 46.5Z" stroke="white" />
        <defs>
          <filter
            id="3-5_filter0_d"
            x="233.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-5_filter1_d"
            x="293.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-5_filter2_d"
            x="225.3"
            y="29.3003"
            width="100.279"
            height="132.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-5_filter3_i"
            x="241"
            y="45"
            width="67.9399"
            height="101.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-5_filter4_d"
            x="252.28"
            y="146.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="252.5"
            y1="27.2"
            x2="252.5"
            y2="34.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="237.5"
            y1="36.7982"
            x2="237.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="252.5"
            y1="27.2018"
            x2="252.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="312.5"
            y1="27.2"
            x2="312.5"
            y2="34.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="297.5"
            y1="36.7982"
            x2="297.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="312.5"
            y1="27.2018"
            x2="312.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
