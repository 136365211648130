import React, { FC, useEffect, useMemo } from 'react'

import { Button, Loader } from 'src/react-app/components'
import {
  PreviewBlockText,
  PreviewBlockStyle,
  PreviewBlockDesc,
  PreviewBlockContent,
  PreviewBlockImage,
  PreviewBlockTitle,
  PreviewBlockPrice,
} from '../../styles'
import { ButtonViews, IQuizPreviewBlockProps } from '../../interface'
import { quizSchema } from '../consts'

export const PreviewBlock: FC<IQuizPreviewBlockProps> = props => {
  const {
    glassUnit,
    title,
    type,
    onFinish,
    handleOnlineConsult,
    price,
    calculateInProgress,
    nonBorder,
    bigView = false,
    currentStep = 0
  } = props

  const totalPrice = useMemo(() => {
    return price && price['total'] ? price['total'] : 0
  }, [price])

  const image = useMemo(() => {
    let result = ''
    const typeData = quizSchema['product-type']['enum'].find(item => item['value'] === type)

    if (typeData) {
      result = typeData['preview-url']
    }

    return result
  }, [type])

  const showPrice = useMemo(() => !(totalPrice === 0 || calculateInProgress), [
    calculateInProgress,
    totalPrice,
  ])

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }}>
    <PreviewBlockStyle noBG={Boolean(glassUnit)} nonBorder={nonBorder}>
      {!glassUnit && <PreviewBlockText>Здесь появится результат подбора</PreviewBlockText>}
      {glassUnit && (
        <PreviewBlockContent>
          {!bigView && <PreviewBlockDesc>Рекомендуемая модель:</PreviewBlockDesc>}
          {!bigView && <span style={{ marginTop: 10, whiteSpace: 'nowrap' }}>Ширина 1250мм, Высота 1300мм</span> }
          {!bigView && <span style={{ marginTop: 0 }}>1 поворотная створка, 1 глухая</span> }
          {image && (
            <PreviewBlockImage bigView={bigView} src={image} alt={type || 'Изображение изделия'} />
          )}
          <PreviewBlockTitle>{title}</PreviewBlockTitle>
          <PreviewBlockPrice>
            {!showPrice && <Loader />}
            {showPrice && <span>от {totalPrice} ₽</span>}
          </PreviewBlockPrice>
          
        </PreviewBlockContent>
      )}
    </PreviewBlockStyle>
      {(currentStep === 4) && <div style={{ width: 250, marginLeft: 50, marginBottom: 60 }}>
         <Button onClick={handleOnlineConsult}>Вызвать замерщика</Button>
      </div>}
    { bigView && <div>
      <Button onClick={onFinish}>Внести размеры изделия</Button>
      <div style={{ marginTop: 20 }} />
      <Button onClick={handleOnlineConsult}>Вызвать замерщика</Button>
      <div style={{ marginTop: 35 }} />
    </div>}
    </div>
  )
 
}


/*


          {onFinish && (
            <Button typeView={ButtonViews.ORANGE} onClick={onFinish}>
              Перейти в калькулятор
            </Button>
          )}
*/