import styled from 'styled-components'

export const ButtonBackStyled = styled.button`
  width: 43px;
  height: 32px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  padding-right: 18px;

  :active {
    background-color: #e7e7e7;
  }
`
