import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement23Square2Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="315"
        height="333"
        viewBox="0 0 315 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-3_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M287 46.9995C287 45.8952 286.105 45 285.001 45C283.896 45 283.001 45.8952 283.001 46.9995V49.1665H283.001V60H283V63.0005C283 64.1048 283.895 65 284.999 65C286.104 65 286.999 64.1048 286.999 63.0005V60.8332H287V50H287V46.9995Z"
            fill="url(#2-3_paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 287 49.1665)"
          fill="#ABABAB"
        />
        <path
          d="M287 45.9399C287 45.4208 286.579 45 286.06 45H283.941C283.422 45 283.001 45.4208 283.001 45.9399V50H287V45.9399Z"
          fill="url(#2-3_paint1_linear)"
        />
        <path
          d="M283 64.0601C283 64.5792 283.421 65 283.94 65H286.059C286.578 65 286.999 64.5792 286.999 64.0601V60H283V64.0601Z"
          fill="url(#2-3_paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 287 50.8334)"
          fill="url(#2-3_paint3_linear)"
        />
        <g filter="url(#2-3_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M287 257.999C287 256.895 286.105 256 285.001 256C283.896 256 283.001 256.895 283.001 257.999V260.167H283.001V271H283V274.001C283 275.105 283.895 276 284.999 276C286.104 276 286.999 275.105 286.999 274.001V271.833H287V261H287V257.999Z"
            fill="url(#2-3_paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 287 260.166)"
          fill="#ABABAB"
        />
        <path
          d="M287 256.94C287 256.421 286.579 256 286.06 256H283.941C283.422 256 283.001 256.421 283.001 256.94V261H287V256.94Z"
          fill="url(#2-3_paint5_linear)"
        />
        <path
          d="M283 275.06C283 275.579 283.421 276 283.94 276H286.059C286.578 276 286.999 275.579 286.999 275.06V271H283V275.06Z"
          fill="url(#2-3_paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 287 261.833)"
          fill="url(#2-3_paint7_linear)"
        />
        <g filter="url(#2-3_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M283 34H163V287H283V34ZM272 45H174V276H272V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M283 34H163V287L163.887 287V34.937H282.109V287L283 287V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="167.53"
          y="38.53"
          width="110.94"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#2-3_filter3_i)">
          <rect
            x="174.47"
            y="45.47"
            width="97.0601"
            height="230.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          width="7"
          height="13.5283"
          rx="3.5"
          transform="matrix(-1 0 0 1 172 154)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#2-3_filter4_d)">
          <rect
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="matrix(-1 0 0 1 171.298 154.644)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M170.714 165.795C170.202 166.458 169.4 166.884 168.498 166.884C166.951 166.884 165.698 165.63 165.698 164.084V157.444C165.698 156.456 166.21 155.587 166.983 155.089C166.617 155.562 166.4 156.155 166.4 156.8V163.44C166.4 164.986 167.653 166.24 169.2 166.24C169.758 166.24 170.277 166.077 170.714 165.795Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M168.498 154.644C170.045 154.644 171.298 155.898 171.298 157.444V163.44C171.298 163.46 171.298 163.48 171.298 163.501V158.024C171.298 156.477 170.044 155.224 168.498 155.224C166.972 155.224 165.731 156.444 165.698 157.963V157.444C165.698 155.898 166.952 154.644 168.498 154.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-3_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M168.5 158C169.328 158 170 158.672 170 159.5V159.714V161V179.5C170 180.328 169.328 181 168.5 181C167.672 181 167 180.328 167 179.5V163V159.5L167 159.286C167 158.576 167.576 158 168.286 158C168.323 158 168.36 158.001 168.396 158.004C168.431 158.001 168.465 158 168.5 158Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M176.199 160.5L270.105 272.503L270.105 48.4975L176.199 160.5ZM175 160.5L271 275L271 46L175 160.5Z"
          fill="white"
        />
        <defs>
          <filter
            id="2-3_filter0_d"
            x="281.12"
            y="43.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-3_filter1_d"
            x="281.12"
            y="254.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-3_filter2_d"
            x="158.3"
            y="29.3003"
            width="131.279"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-3_filter3_i"
            x="174"
            y="45"
            width="98.9399"
            height="233.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-3_filter4_d"
            x="163.818"
            y="152.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-3_filter5_d"
            x="165.12"
            y="156.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="2-3_paint0_linear"
            x1="289.8"
            y1="62.5"
            x2="282.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="2-3_paint1_linear"
            x1="280.202"
            y1="47.5"
            x2="287.8"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="2-3_paint2_linear"
            x1="289.798"
            y1="62.5"
            x2="282.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="2-3_paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="2-3_paint4_linear"
            x1="289.8"
            y1="273.5"
            x2="282.2"
            y2="273.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="2-3_paint5_linear"
            x1="280.202"
            y1="258.5"
            x2="287.8"
            y2="258.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="2-3_paint6_linear"
            x1="289.798"
            y1="273.5"
            x2="282.2"
            y2="273.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="2-3_paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
