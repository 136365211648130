import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement32Square3Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#3-2_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M304.999 206C303.895 206 303 206.895 303 207.999C303 209.104 303.895 209.999 304.999 209.999L307.167 209.999L307.167 209.999L318 209.999L318 210L321.001 210C322.105 210 323 209.105 323 208.001C323 206.896 322.105 206.001 321.001 206.001L318.833 206.001L318.833 206L308 206L308 206L304.999 206Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 307.167 206)"
          fill="#ABABAB"
        />
        <path
          d="M303.94 206C303.421 206 303 206.421 303 206.94L303 209.059C303 209.578 303.421 209.999 303.94 209.999L308 209.999L308 206L303.94 206Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M322.06 210C322.579 210 323 209.579 323 209.06L323 206.941C323 206.422 322.579 206.001 322.06 206.001L318 206.001L318 210L322.06 210Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 308.833 206)"
          fill="url(#paint3-2_linear)"
        />
        <g filter="url(#3-2_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M392.999 206C391.895 206 391 206.895 391 207.999C391 209.104 391.895 209.999 392.999 209.999L395.167 209.999L395.167 209.999L406 209.999L406 210L409.001 210C410.105 210 411 209.105 411 208.001C411 206.896 410.105 206.001 409.001 206.001L406.833 206.001L406.833 206L396 206L396 206L392.999 206Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 395.167 206)"
          fill="#ABABAB"
        />
        <path
          d="M391.94 206C391.421 206 391 206.421 391 206.94L391 209.059C391 209.578 391.421 209.999 391.94 209.999L396 209.999L396 206L391.94 206Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M410.06 210C410.579 210 411 209.579 411 209.06L411 206.941C411 206.422 410.579 206.001 410.06 206.001L406 206.001L406 210L410.06 210Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 396.833 206)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#3-2_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 210H297V287H417V210ZM406 221H308V276H406V221Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#3-2_filter3-2_i)">
          <rect
            x="308.47"
            y="221.47"
            width="97.0601"
            height="54.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="302.5" y="215.5" width="109" height="66" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 210H297V287H298V211H416V287H417V210Z"
          fill="#FBFBFB"
        />
        <path d="M310.127 222.5H403.873L357 274.255L310.127 222.5Z" stroke="white" />
        <rect
          x="363"
          y="278"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 363 278)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#3-2_filter4_d)">
          <rect
            x="362.356"
            y="278.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 362.356 278.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M362.238 280.695C361.891 279.542 360.822 278.702 359.556 278.702L352.916 278.702C351.369 278.702 350.116 279.956 350.116 281.502C350.116 281.537 350.117 281.572 350.118 281.607C350.464 282.76 351.534 283.6 352.8 283.6L359.44 283.6C360.986 283.6 362.24 282.347 362.24 280.8C362.24 280.765 362.239 280.73 362.238 280.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M360.881 279.035C360.737 279.012 360.59 279 360.44 279L353.8 279C352.254 279 351 280.254 351 281.8C351 282.867 351.597 283.795 352.475 284.268C351.138 284.056 350.116 282.898 350.116 281.502C350.116 279.956 351.37 278.702 352.916 278.702L359.556 278.702C360.035 278.702 360.486 278.823 360.881 279.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-2_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M359 281.5C359 280.672 358.328 280 357.5 280L357.286 280L356 280L337.5 280C336.672 280 336 280.672 336 281.5C336 282.328 336.672 283 337.5 283L354 283L357.5 283L357.714 283C358.424 283 359 282.424 359 281.714C359 281.677 358.999 281.64 358.996 281.604C358.999 281.569 359 281.535 359 281.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="3-2_filter0_d"
            x="301.12"
            y="204.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-2_filter1_d"
            x="389.12"
            y="204.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-2_filter2_d"
            x="292.3"
            y="205.3"
            width="131.279"
            height="88.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-2_filter3-2_i"
            x="308"
            y="221"
            width="98.9399"
            height="57.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-2_filter4_d"
            x="348.236"
            y="276.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-2_filter5_d"
            x="334.12"
            y="278.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="320.5"
            y1="203.2"
            x2="320.5"
            y2="210.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="305.5"
            y1="212.798"
            x2="305.5"
            y2="205.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="320.5"
            y1="203.202"
            x2="320.5"
            y2="210.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3-2_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="408.5"
            y1="203.2"
            x2="408.5"
            y2="210.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="393.5"
            y1="212.798"
            x2="393.5"
            y2="205.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="408.5"
            y1="203.202"
            x2="408.5"
            y2="210.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
