import React, { FC, useCallback, useEffect, useState } from 'react'

import {
  RadiobuttonBlock,
  RadiobuttonButton,
  RadiobuttonLabel,
  RadiobuttonText,
  RadiobuttonWrapper,
  RadiobuttonPrice,
} from './styles'
import { IRadiobuttonItem, IRadiobuttonProps } from './interface'

export const Radiobutton: FC<IRadiobuttonProps> = props => {
  const { items = [] as IRadiobuttonItem[], id, onSelect, selected, price } = props

  const [selectedItem, setSelectedItem] = useState<null | IRadiobuttonItem>(null)

  useEffect(() => {
    if (selected) {
      if (selected.constructor === Array) {
        selected.forEach(item => {
          if (item[id]) {
            setSelectedItem(item[id])
          }
        })
      } else if (selected.constructor === Object) {
        setSelectedItem(selected as IRadiobuttonItem)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  const handleSelect = useCallback(
    (item: IRadiobuttonItem) => () => {
      setSelectedItem(item)
      onSelect(item)
    },
    [onSelect],
  )

  return (
    <RadiobuttonWrapper>
      <RadiobuttonBlock>
        {items?.length > 0 &&
          items.map(item => (
            <RadiobuttonLabel
              htmlFor={`${id}-${item.id}`}
              onClick={handleSelect(item)}
              key={item.id}
            >
              <RadiobuttonButton
                type="radio"
                value={item.value}
                name={id}
                id={`${id}-${item.id}`}
                checked={item.id === selectedItem?.id}
              />
              <RadiobuttonText>{item.text}</RadiobuttonText>
            </RadiobuttonLabel>
          ))}
      </RadiobuttonBlock>
      {price && <RadiobuttonPrice>Цена: {price || 0} ₽</RadiobuttonPrice>}
    </RadiobuttonWrapper>
  )
}
