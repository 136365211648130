import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import { CalculatorPage } from '../pages/calculator'
import Error404Page from '../pages/error-404-not-found'
import { IStore } from '../store/interfaces'
import AppLoader from '../components/app-loader'
import { Switch, Route } from 'react-router-dom'
import {StagePersonalArea} from "../views/calculator/stage-personal-area";

const createPageSelector = createSelector(
  (state: IStore) => ({
    page: state.page,
    session: state.session,
  }),
  ({ page, session }) => ({ page, session }),
)

export const DesktopRouter = memo(() => {
  const { page, session } = useSelector(createPageSelector)

  if (page.isLoading) {
    return <AppLoader />
  }

  return (
    <>
      <Switch>
        {/* @ts-ignore */}
        <Route exact path="/" session={session}>
          <CalculatorPage clearView />
        </Route>
        {/* @ts-ignore */}
        <Route exact path="/calculator" session={session}>
          <CalculatorPage />
        </Route>
        {/* @ts-ignore */}
        <Route exact path="/podbor" session={session}>
          <CalculatorPage />
        </Route>
        {/* @ts-ignore */}
        <Route exact path="/podbor/step1" session={session}>
          <CalculatorPage />
        </Route>
        {/* @ts-ignore */}
        <Route exact path="/podbor/step2" session={session}>
          <CalculatorPage />
        </Route>
        {/* @ts-ignore */}
        <Route exact path="/podbor/step3" session={session}>
          <CalculatorPage />
        </Route>
        {/* @ts-ignore */}
        <Route exact path="/podbor/step4" session={session}>
          <CalculatorPage />
        </Route>
        {/* @ts-ignore */}
        <Route exact path="/podbor/step5" session={session}>
          <CalculatorPage />
        </Route>
        {/* @ts-ignore */}
        <Route exact path="/personal" session={session}>
          <StagePersonalArea />
        </Route>
        {/* @ts-ignore */}
        <Route exact path="/personal/my" session={session}>
          <CalculatorPage isDraftEdit />
        </Route>
        {/* @ts-ignore */}
        <Route exact path="/personal/basket" session={session}>
          <CalculatorPage isCart />
        </Route>
        {/* @ts-ignore */}
        <Route exact path="/order" session={session}>
          <CalculatorPage />
        </Route>
        <Route component={Error404Page} />
      </Switch>
    </>
  )
})
