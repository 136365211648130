import React from 'react'
import { CarIcon, SettingIcon } from '../../../assets/icons'
import { ORDER_STATUSES } from './interface'
import { SuccessRadiusIcon } from '../../../assets/icons/success-radius-icon'

export const statusTitles = {
  [ORDER_STATUSES.NEW]: 'Заявка принята',
  [ORDER_STATUSES.MANUFACTURE]: 'Изготавливается',
  [ORDER_STATUSES.PASS_TO_DELIVERY]: 'Передано в доставку',
  [ORDER_STATUSES.DELIVERY]: 'Доставляется',
  [ORDER_STATUSES.MOUNTING]: 'Монтаж запланирован',
  [ORDER_STATUSES.DONE]: 'Установленно',
}

export const statusList = (activeStatus: ORDER_STATUSES = ORDER_STATUSES.NEW) => [
  {
    title: statusTitles[ORDER_STATUSES.NEW],
    icon: false,
    status: ORDER_STATUSES.NEW,
  },
  {
    title: statusTitles[ORDER_STATUSES.MANUFACTURE],
    icon: true,
    status: ORDER_STATUSES.MANUFACTURE,
  },
  // {
  //   title: statusTitles[ORDER_STATUSES.PASS_TO_DELIVERY] в доставку',
  //   icon: '',
  //   status: ORDER_STATUSES.PASS_TO_DELIVERY,
  // },
  {
    title: statusTitles[ORDER_STATUSES.DELIVERY],
    icon: true,
    status: ORDER_STATUSES.DELIVERY,
  },
  {
    title: statusTitles[ORDER_STATUSES.MOUNTING],
    icon: true,
    status: ORDER_STATUSES.MOUNTING,
  },
  {
    title: statusTitles[ORDER_STATUSES.DONE],
    icon: (
      <SuccessRadiusIcon color={activeStatus === ORDER_STATUSES.DONE ? '#ffffff' : '#D4D4D4'} />
    ),
    status: ORDER_STATUSES.DONE,
  },
]

export const orderInfoList = [
  {
    title: 'Ожидаемая дата готовности',
    code: 'manufacture',
  },
  {
    title: 'Ожидаемая дата доставки',
    code: 'delivery',
  },
  {
    title: 'Ожидаемая дата монтажа',
    code: 'mounting',
  },
  {
    title: 'Заказчик',
    code: 'recipient',
  },
  {
    title: 'Статус оплаты',
    code: 'pay-type',
  },
  {
    title: 'Адрес доставки',
    code: 'address',
  },
  {
    title: 'Получатель',
    code: 'other-recipient',
  },
  {
    title: 'Спецификация',
    code: 'scan-spec',
  },
  {
    title: 'Акт принятия доставки',
    code: 'file-accepted-client',
  },
  {
    title: 'Акт принятия монтажа',
    code: 'file-mounting-accepted',
  },
]

export const orderNotification = [
  {
    name: 'Принят в работу',
    title: 'Принят в работу',
    code: 'work',
    value: false,
  },
  {
    name: 'В производстве',
    title: 'В производстве',
    code: 'manufacture',
    value: false,
  },
  {
    name: 'На доставке',
    title: 'На доставке',
    code: 'delivery',
    value: false,
  },
  {
    name: 'Монтаж запланирован',
    title: 'Монтаж запланирован',
    code: 'mounting',
    value: false,
  },
]
