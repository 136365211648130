import { CalculatorStage } from '../interface'

export const pageTitles: Record<CalculatorStage, string> = {
  [CalculatorStage.ORDER]: 'Оформление заказа',
  [CalculatorStage.PERSONAL_AREA]: '',
  [CalculatorStage.CALCULATE]: 'Подбор остекления',
  [CalculatorStage.CART]: 'Корзина',
  [CalculatorStage.SELECT_TYPE]: 'Подбор остекления',
  [CalculatorStage.SELECT_TYPE_PREMISES]: 'Подбор остекления',
  [CalculatorStage.SELECT_VIEW]: 'Подбор остекления',
  [CalculatorStage.INDIVIDUAL]: 'Подбор остекления',
  [CalculatorStage.QUIZ]: 'Подбор продукции',
}

export const pageVideos: Record<CalculatorStage, string> = {
  [CalculatorStage.ORDER]: '',
  [CalculatorStage.PERSONAL_AREA]: '',
  [CalculatorStage.CALCULATE]: 'https://www.youtube.com/embed/WoVpudPByM4',
  [CalculatorStage.CART]: '',
  [CalculatorStage.SELECT_TYPE]: 'https://www.youtube.com/embed/eAN-LDMYT10',
  [CalculatorStage.SELECT_TYPE_PREMISES]: 'https://www.youtube.com/embed/eAN-LDMYT10',
  [CalculatorStage.SELECT_VIEW]: 'https://www.youtube.com/embed/eAN-LDMYT10',
  [CalculatorStage.INDIVIDUAL]: 'https://www.youtube.com/embed/eAN-LDMYT10',
  [CalculatorStage.QUIZ]: 'https://www.youtube.com/embed/eAN-LDMYT10',
}

/*
export const pageVideos: Record<CalculatorStage, string> = {
  [CalculatorStage.ORDER]: '',
  [CalculatorStage.PERSONAL_AREA]: '',
  [CalculatorStage.CALCULATE]: 'https://www.youtube.com/embed/7UaHZOgvHeg',
  [CalculatorStage.CART]: '',
  [CalculatorStage.SELECT_TYPE]: 'https://www.youtube.com/embed/JC1EGMoRnaU',
  [CalculatorStage.SELECT_TYPE_PREMISES]: 'https://www.youtube.com/embed/JC1EGMoRnaU',
  [CalculatorStage.SELECT_VIEW]: 'https://www.youtube.com/embed/JC1EGMoRnaU',
  [CalculatorStage.INDIVIDUAL]: 'https://www.youtube.com/embed/JC1EGMoRnaU',
  [CalculatorStage.QUIZ]: 'https://www.youtube.com/embed/JC1EGMoRnaU',
}
*/

export const calculatorPool: CalculatorStage[] = [CalculatorStage.CALCULATE]
export const selectionPool: CalculatorStage[] = [
  CalculatorStage.SELECT_TYPE,
  CalculatorStage.SELECT_TYPE_PREMISES,
  CalculatorStage.SELECT_VIEW,
  CalculatorStage.INDIVIDUAL,
  CalculatorStage.QUIZ,
]
