import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement53Square3Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#5-3_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M171.999 199C170.895 199 170 199.895 170 200.999C170 202.104 170.895 202.999 171.999 202.999L174.167 202.999L174.167 202.999L185 202.999L185 203L188.001 203C189.105 203 190 202.105 190 201.001C190 199.896 189.105 199.001 188.001 199.001L185.833 199.001L185.833 199L175 199L175 199L171.999 199Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 174.166 199)"
          fill="#ABABAB"
        />
        <path
          d="M170.94 199C170.421 199 170 199.421 170 199.94L170 202.059C170 202.578 170.421 202.999 170.94 202.999L175 202.999L175 199L170.94 199Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M189.06 203C189.579 203 190 202.579 190 202.06L190 199.941C190 199.422 189.579 199.001 189.06 199.001L185 199.001L185 203L189.06 203Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 175.833 199)"
          fill="url(#paint5-3_linear)"
        />
        <g filter="url(#5-3_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M259.999 199C258.895 199 258 199.895 258 200.999C258 202.104 258.895 202.999 259.999 202.999L262.167 202.999L262.167 202.999L273 202.999L273 203L276.001 203C277.105 203 278 202.105 278 201.001C278 199.896 277.105 199.001 276.001 199.001L273.833 199.001L273.833 199L263 199L263 199L259.999 199Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 262.166 199)"
          fill="#ABABAB"
        />
        <path
          d="M258.94 199C258.421 199 258 199.421 258 199.94L258 202.059C258 202.578 258.421 202.999 258.94 202.999L263 202.999L263 199L258.94 199Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M277.06 203C277.579 203 278 202.579 278 202.06L278 199.941C278 199.422 277.579 199.001 277.06 199.001L273 199.001L273 203L277.06 203Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 263.833 199)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#5-3_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M284 122H164V199H284V122ZM273 133H175V188H273V133Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#5-3_filter5-3_i)">
          <rect
            x="175.47"
            y="133.47"
            width="97.0601"
            height="54.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="169.5" y="127.5" width="109" height="66" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M284 122H164V199H165V123H283V199H284V122Z"
          fill="#FBFBFB"
        />
        <path d="M270.873 186.5H177.127L224 134.745L270.873 186.5Z" stroke="white" />
        <rect
          x="231.528"
          y="124"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 231.528 124)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#5-3_filter4_d)">
          <rect
            x="230.884"
            y="124.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 230.884 124.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M230.767 126.695C230.42 125.542 229.351 124.702 228.085 124.702L221.445 124.702C219.898 124.702 218.645 125.956 218.645 127.502C218.645 127.537 218.645 127.572 218.647 127.607C218.993 128.76 220.063 129.6 221.329 129.6L227.969 129.6C229.515 129.6 230.769 128.347 230.769 126.8C230.769 126.765 230.768 126.73 230.767 126.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M229.409 125.035C229.266 125.012 229.118 125 228.968 125L222.328 125C220.782 125 219.528 126.254 219.528 127.8C219.528 128.867 220.125 129.795 221.003 130.268C219.666 130.056 218.644 128.898 218.644 127.502C218.644 125.956 219.898 124.702 221.444 124.702L228.084 124.702C228.564 124.702 229.015 124.823 229.409 125.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#5-3_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M244.528 127.5C244.528 126.672 243.857 126 243.028 126L242.814 126L241.528 126L223.028 126C222.2 126 221.528 126.672 221.528 127.5C221.528 128.328 222.2 129 223.028 129L239.528 129L243.028 129L243.243 129C243.953 129 244.528 128.424 244.528 127.714C244.528 127.677 244.527 127.64 244.525 127.604C244.527 127.569 244.528 127.535 244.528 127.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="5-3_filter0_d"
            x="168.12"
            y="197.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-3_filter1_d"
            x="256.12"
            y="197.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-3_filter2_d"
            x="159.3"
            y="117.3"
            width="131.279"
            height="88.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-3_filter5-3_i"
            x="175"
            y="133"
            width="98.9399"
            height="57.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-3_filter4_d"
            x="216.764"
            y="122.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-3_filter5_d"
            x="220.649"
            y="124.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="187.5"
            y1="196.2"
            x2="187.5"
            y2="203.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="172.5"
            y1="205.798"
            x2="172.5"
            y2="198.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="187.5"
            y1="196.202"
            x2="187.5"
            y2="203.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5-3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="275.5"
            y1="196.2"
            x2="275.5"
            y2="203.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="260.5"
            y1="205.798"
            x2="260.5"
            y2="198.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="275.5"
            y1="196.202"
            x2="275.5"
            y2="203.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
