import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement85Square8Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#8-5_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M334.999 161C333.895 161 333 161.895 333 162.999C333 164.104 333.895 164.999 334.999 164.999L337.167 164.999L337.167 164.999L348 164.999L348 165L351.001 165C352.105 165 353 164.105 353 163.001C353 161.896 352.105 161.001 351.001 161.001L348.833 161.001L348.833 161L338 161L338 161L334.999 161Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 337.167 161)"
          fill="#ABABAB"
        />
        <path
          d="M333.94 161C333.421 161 333 161.421 333 161.94L333 164.059C333 164.578 333.421 164.999 333.94 164.999L338 164.999L338 161L333.94 161Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M352.06 165C352.579 165 353 164.579 353 164.06L353 161.941C353 161.422 352.579 161.001 352.06 161.001L348 161.001L348 165L352.06 165Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 338.833 161)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#8-5_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M394.999 161C393.895 161 393 161.895 393 162.999C393 164.104 393.895 164.999 394.999 164.999L397.167 164.999L397.167 164.999L408 164.999L408 165L411.001 165C412.105 165 413 164.105 413 163.001C413 161.896 412.105 161.001 411.001 161.001L408.833 161.001L408.833 161L398 161L398 161L394.999 161Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 397.167 161)"
          fill="#ABABAB"
        />
        <path
          d="M393.94 161C393.421 161 393 161.421 393 161.94L393 164.059C393 164.578 393.421 164.999 393.94 164.999L398 164.999L398 161L393.94 161Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M412.06 165C412.579 165 413 164.579 413 164.06L413 161.941C413 161.422 412.579 161.001 412.06 161.001L408 161.001L408 165L412.06 165Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 398.833 161)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#8-5_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 165H328V286H417V165ZM406 176H339V275H406V176Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 165H328V286H329.219V166H415.781V286H417V165Z"
          fill="#FBFBFB"
        />
        <g filter="url(#8-5_filter3_i)">
          <rect
            x="339.47"
            y="176.47"
            width="66.0601"
            height="98.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="332.53"
          y="169.53"
          width="79.9399"
          height="111.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="379"
          y="277"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 379 277)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="378.356"
          y="277.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 378.356 277.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M378.238 279.695C377.891 278.542 376.822 277.702 375.556 277.702L368.916 277.702C367.369 277.702 366.116 278.955 366.116 280.502C366.116 280.537 366.117 280.572 366.118 280.607C366.464 281.76 367.534 282.6 368.8 282.6L375.44 282.6C376.986 282.6 378.24 281.346 378.24 279.8C378.24 279.765 378.239 279.73 378.238 279.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M376.881 278.034C376.737 278.012 376.59 278 376.44 278L369.8 278C368.254 278 367 279.253 367 280.8C367 281.867 367.597 282.795 368.475 283.267C367.138 283.056 366.116 281.898 366.116 280.502C366.116 278.955 367.37 277.702 368.916 277.702L375.556 277.702C376.035 277.702 376.486 277.822 376.881 278.034Z"
          fill="#FBFBFB"
        />
        <g filter="url(#8-5_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M375 280.5C375 279.672 374.328 279 373.5 279L373.286 279L372 279L353.5 279C352.672 279 352 279.672 352 280.5V280.5C352 281.328 352.672 282 353.5 282L370 282L373.5 282L373.714 282C374.424 282 375 281.424 375 280.714V280.714C375 280.677 374.999 280.64 374.996 280.604C374.999 280.569 375 280.535 375 280.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M404.305 177.5L372.5 272.426L340.695 177.5L404.305 177.5Z" stroke="white" />
        <defs>
          <filter
            id="8-5_filter0_d"
            x="331.12"
            y="159.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8-5_filter1_d"
            x="391.12"
            y="159.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8-5_filter2_d"
            x="323.3"
            y="160.3"
            width="100.279"
            height="132.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8-5_filter3_i"
            x="339"
            y="176"
            width="67.9399"
            height="101.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="8-5_filter4_d"
            x="350.28"
            y="277.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="350.5"
            y1="158.2"
            x2="350.5"
            y2="165.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="335.5"
            y1="167.798"
            x2="335.5"
            y2="160.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="350.5"
            y1="158.202"
            x2="350.5"
            y2="165.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="410.5"
            y1="158.2"
            x2="410.5"
            y2="165.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="395.5"
            y1="167.798"
            x2="395.5"
            y2="160.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="410.5"
            y1="158.202"
            x2="410.5"
            y2="165.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
