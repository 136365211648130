import React, { FC, useCallback, useState, useEffect } from 'react'
import { INotificationsProps } from '../interface'
import { StageOrderBlockDescription, StageOrderBlockTitle, StageOrderBlockWrapper } from './styles'
import { Checkbox } from '../../../components'
import { ICheckboxItem } from '../stage-calculator/components'

const notifications: ICheckboxItem[] = [
  { name: 'work', value: false, title: 'Принят в работу' },
  { name: 'manufacture', value: false, title: 'В производстве' },
  { name: 'delivery', value: false, title: 'На доставке' },
  // { name: 'mounting', value: false, title: 'Монтаж запланирован' },
]

export const Notifications: FC<INotificationsProps> = props => {
  const { onChange, data } = props
  const [selected, setSelected] = useState<ICheckboxItem[]>([])
  const [notificationsData, setNotificationsData] = useState<Record<string, any>>({})

  useEffect(() => {
    const newSelected: ICheckboxItem[] = []
    const newNotificationsData: Record<string, any> = {}

    notifications.forEach(notification => {
      newNotificationsData[notification['name']] = notification['value']

      if (data && data[notification['name']]) {
        newNotificationsData[notification['name']] = data[notification['name']]
        newSelected.push({
          ...notification,
          value: data[notification['name']],
        })
      }
    })

    setSelected(newSelected)
  }, [data])

  useEffect(() => {
    onChange(notificationsData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsData])

  const handleSelect = useCallback(value => {
    setNotificationsData(prevState => ({
      ...prevState,
      [value['name']]: value['value'],
    }))
    setSelected(prevState => {
      const index = prevState.findIndex(state => state['name'] === value['name'])
      let result: ICheckboxItem[] = []

      if (index === -1) {
        result.push(...prevState)
        result.push(value)
      } else {
        result = [
          ...prevState.slice(0, index),
          value,
          ...prevState.slice(index + 1, prevState.length),
        ]
      }

      return result
    })
  }, [])

  return (
    <StageOrderBlockWrapper>
      <StageOrderBlockTitle>Уведомления о статусе заказа</StageOrderBlockTitle>
      <StageOrderBlockDescription>
        Выберите пункты, чтобы получать оповещения о действиях на почту
      </StageOrderBlockDescription>
      <Checkbox
        items={notifications}
        onSelect={handleSelect}
        selected={selected}
        id="notifications"
      />
    </StageOrderBlockWrapper>
  )
}
