import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement42Square4Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="315"
        height="333"
        viewBox="0 0 315 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#23filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M280 140.001C280 141.105 280.895 142 281.999 142C283.104 142 283.999 141.105 283.999 140.001V137.833H283.999V127H284V123.999C284 122.895 283.105 122 282.001 122C280.896 122 280.001 122.895 280.001 123.999V126.167H280V137H280V140.001Z"
            fill="url(#23paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1 0 0 -1 280 137.833)"
          fill="#ABABAB"
        />
        <path
          d="M280 141.06C280 141.579 280.421 142 280.94 142H283.059C283.578 142 283.999 141.579 283.999 141.06V137H280V141.06Z"
          fill="url(#23paint1_linear)"
        />
        <path
          d="M284 122.94C284 122.421 283.579 122 283.06 122H280.941C280.422 122 280.001 122.421 280.001 122.94V127H284V122.94Z"
          fill="url(#23paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1 0 0 -1 280 136.167)"
          fill="url(#23paint3_linear)"
        />
        <g filter="url(#23filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M280 264.999C280 263.895 280.895 263 281.999 263C283.104 263 283.999 263.895 283.999 264.999V267.167H283.999V278H284V281.001C284 282.105 283.105 283 282.001 283C280.896 283 280.001 282.105 280.001 281.001V278.833H280V268H280V264.999Z"
            fill="url(#23paint4_linear)"
          />
        </g>
        <rect x="280" y="267.167" width="3.99896" height="11.6667" fill="#ABABAB" />
        <path
          d="M280 263.94C280 263.421 280.421 263 280.94 263H283.059C283.578 263 283.999 263.421 283.999 263.94V268H280V263.94Z"
          fill="url(#23paint5_linear)"
        />
        <path
          d="M284 282.06C284 282.579 283.579 283 283.06 283H280.941C280.422 283 280.001 282.579 280.001 282.06V278H284V282.06Z"
          fill="url(#23paint6_linear)"
        />
        <rect x="280" y="268.833" width="3.99896" height="8.33334" fill="url(#23paint7_linear)" />
        <g filter="url(#23filter2_i)">
          <rect
            x="176.47"
            y="127.47"
            width="92.0601"
            height="148.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <g filter="url(#23filter3_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M280 116H165V287H280V116ZM269 127H176V276H269V127Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M280 116H165V287H166V117H279V287H280V116Z"
          fill="#FBFBFB"
        />
        <rect
          x="169.53"
          y="122.53"
          width="105.94"
          height="159.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="167"
          y="196"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#23filter4_d)">
          <rect x="167.702" y="196.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M168.286 207.796C168.798 208.458 169.6 208.884 170.502 208.884C172.049 208.884 173.302 207.63 173.302 206.084V199.444C173.302 198.456 172.79 197.587 172.017 197.089C172.383 197.562 172.6 198.156 172.6 198.8V205.44C172.6 206.986 171.347 208.24 169.8 208.24C169.242 208.24 168.723 208.077 168.286 207.796Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M170.502 196.644C168.955 196.644 167.702 197.898 167.702 199.444V205.44C167.702 205.46 167.702 205.48 167.702 205.501V200.024C167.702 198.477 168.956 197.224 170.502 197.224C172.028 197.224 173.269 198.444 173.302 199.963V199.444C173.302 197.898 172.048 196.644 170.502 196.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#23filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M170.5 200C169.672 200 169 200.672 169 201.5V201.714V203V221.5C169 222.328 169.672 223 170.5 223C171.328 223 172 222.328 172 221.5V205V201.5L172 201.286C172 200.576 171.424 200 170.714 200C170.677 200 170.64 200.001 170.604 200.004C170.569 200.001 170.535 200 170.5 200Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M267.5 273.953L267.5 129.047L177.796 201.5L267.5 273.953Z" stroke="white" />
        <defs>
          <filter
            id="23filter0_d"
            x="278.12"
            y="120.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="23filter1_d"
            x="278.12"
            y="261.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="23filter2_i"
            x="176"
            y="127"
            width="93.9399"
            height="151.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="23filter3_d"
            x="160.3"
            y="111.3"
            width="126.279"
            height="182.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="23filter4_d"
            x="165.822"
            y="194.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="23filter5_d"
            x="167.12"
            y="198.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="23paint0_linear"
            x1="277.2"
            y1="124.5"
            x2="284.8"
            y2="124.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="23paint1_linear"
            x1="286.798"
            y1="139.5"
            x2="279.2"
            y2="139.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="23paint2_linear"
            x1="277.202"
            y1="124.5"
            x2="284.8"
            y2="124.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="23paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="23paint4_linear"
            x1="277.2"
            y1="280.5"
            x2="284.8"
            y2="280.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="23paint5_linear"
            x1="286.798"
            y1="265.5"
            x2="279.2"
            y2="265.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="23paint6_linear"
            x1="277.202"
            y1="280.5"
            x2="284.8"
            y2="280.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="23paint7_linear"
            x1="286.798"
            y1="273"
            x2="279.2"
            y2="273"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
