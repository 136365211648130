import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement23Square4TopBottomCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-3_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.0005 220C57.1048 220 58 219.105 58 218.001C58 216.896 57.1048 216.001 56.0005 216.001L53.8335 216.001L53.8335 216.001L43 216.001L43 216L39.9995 216C38.8952 216 38 216.895 38 217.999C38 219.104 38.8952 219.999 39.9995 219.999L42.1668 219.999L42.1668 220L53 220L53 220L56.0005 220Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 53.834 220)"
          fill="#ABABAB"
        />
        <path
          d="M57.0601 220C57.5792 220 58 219.579 58 219.06L58 216.941C58 216.422 57.5792 216.001 57.0601 216.001L53 216.001L53 220L57.0601 220Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M38.9399 216C38.4208 216 38 216.421 38 216.94L38 219.059C38 219.578 38.4208 219.999 38.9399 219.999L43 219.999L43 216L38.9399 216Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 52.167 220)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#2-3_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M219 104H32V216H219V104ZM208 115H43V205H208V115Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#2-3_filter2_i)">
          <rect
            x="43.47"
            y="115.47"
            width="164.06"
            height="89.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="37.5" y="109.5" width="176" height="101" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M219 104H32V105H218V216H219V104Z"
          fill="#FBFBFB"
        />
        <path d="M45.1446 203.5L205.855 203.5L125.5 116.736L45.1446 203.5Z" stroke="white" />
        <g filter="url(#2-3_filter3_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M211.001 220C212.105 220 213 219.105 213 218.001C213 216.896 212.105 216.001 211.001 216.001L208.833 216.001L208.833 216.001L198 216.001L198 216L194.999 216C193.895 216 193 216.895 193 217.999C193 219.104 193.895 219.999 194.999 219.999L197.167 219.999L197.167 220L208 220L208 220L211.001 220Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 208.834 220)"
          fill="#ABABAB"
        />
        <path
          d="M212.06 220C212.579 220 213 219.579 213 219.06L213 216.941C213 216.422 212.579 216.001 212.06 216.001L208 216.001L208 220L212.06 220Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M193.94 216C193.421 216 193 216.421 193 216.94L193 219.059C193 219.578 193.421 219.999 193.94 219.999L198 219.999L198 216L193.94 216Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 207.167 220)"
          fill="url(#paint7_linear)"
        />
        <rect
          x="132.528"
          y="106"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 132.528 106)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#2-3_filter4_d)">
          <rect
            x="131.884"
            y="106.701"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 131.884 106.701)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M131.767 108.694C131.42 107.541 130.351 106.701 129.085 106.701L122.445 106.701C120.898 106.701 119.645 107.955 119.645 109.501C119.645 109.536 119.645 109.571 119.647 109.606C119.993 110.759 121.063 111.599 122.329 111.599L128.969 111.599C130.515 111.599 131.769 110.346 131.769 108.799C131.769 108.764 131.768 108.729 131.767 108.694Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M130.409 107.034C130.266 107.011 130.118 106.999 129.968 106.999L123.328 106.999C121.782 106.999 120.528 108.253 120.528 109.799C120.528 110.866 121.125 111.794 122.003 112.267C120.666 112.055 119.644 110.898 119.644 109.501C119.644 107.955 120.898 106.701 122.444 106.701L129.084 106.701C129.564 106.701 130.015 106.822 130.409 107.034Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-3_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M145.528 109.5C145.528 108.672 144.857 108 144.028 108L143.814 108L142.528 108L124.028 108C123.2 108 122.528 108.672 122.528 109.5V109.5C122.528 110.328 123.2 111 124.028 111L140.528 111L144.028 111L144.243 111C144.953 111 145.528 110.424 145.528 109.714V109.714C145.528 109.677 145.527 109.64 145.525 109.604C145.527 109.569 145.528 109.535 145.528 109.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="2-3_filter0_d"
            x="36.1201"
            y="214.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-3_filter1_d"
            x="27.3003"
            y="99.3003"
            width="198.279"
            height="123.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-3_filter2_i"
            x="43"
            y="115"
            width="165.94"
            height="92.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-3_filter3_d"
            x="191.12"
            y="214.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-3_filter4_d"
            x="117.764"
            y="104.821"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-3_filter5_d"
            x="121.649"
            y="106.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="40.5"
            y1="222.8"
            x2="40.5"
            y2="215.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="55.5"
            y1="213.202"
            x2="55.5"
            y2="220.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="40.5"
            y1="222.798"
            x2="40.5"
            y2="215.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="195.5"
            y1="222.8"
            x2="195.5"
            y2="215.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="210.5"
            y1="213.202"
            x2="210.5"
            y2="220.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="195.5"
            y1="222.798"
            x2="195.5"
            y2="215.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
