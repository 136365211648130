import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement14Square62Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#1-4_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.0005 115C55.1048 115 56 114.105 56 113.001C56 111.896 55.1048 111.001 54.0005 111.001L51.8335 111.001L51.8335 111.001L41 111.001L41 111L37.9995 111C36.8952 111 36 111.895 36 112.999C36 114.104 36.8952 114.999 37.9995 114.999L40.1668 114.999L40.1668 115L51 115L51 115L54.0005 115Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 51.8335 115)"
          fill="#ABABAB"
        />
        <path
          d="M55.0601 115C55.5792 115 56 114.579 56 114.06L56 111.941C56 111.422 55.5792 111.001 55.0601 111.001L51 111.001L51 115L55.0601 115Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M36.9399 111C36.4208 111 36 111.421 36 111.94L36 114.059C36 114.578 36.4208 114.999 36.9399 114.999L41 114.999L41 111L36.9399 111Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 50.1666 115)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#1-4_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M99.0005 115C100.105 115 101 114.105 101 113.001C101 111.896 100.105 111.001 99.0005 111.001L96.8335 111.001L96.8335 111.001L86 111.001L86 111L82.9995 111C81.8952 111 81 111.895 81 112.999C81 114.104 81.8952 114.999 82.9995 114.999L85.1668 114.999L85.1668 115L96 115L96 115L99.0005 115Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 96.8335 115)"
          fill="#ABABAB"
        />
        <path
          d="M100.06 115C100.579 115 101 114.579 101 114.06L101 111.941C101 111.422 100.579 111.001 100.06 111.001L96 111.001L96 115L100.06 115Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M81.9399 111C81.4208 111 81 111.421 81 111.94L81 114.059C81 114.578 81.4208 114.999 81.9399 114.999L86 114.999L86 111L81.9399 111Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 95.1666 115)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#1-4_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M105 33H32V111H105V33ZM94 44H43V100H94V44Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105 33H32V111H33V34H104V111H105V33Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-4_filter3_i)">
          <rect
            x="43.47"
            y="44.47"
            width="50.0601"
            height="55.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="37.53"
          width="63.9399"
          height="68.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="75.5283"
          y="35"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 75.5283 35)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#1-4_filter4_d)">
          <rect
            x="74.8843"
            y="35.7017"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 74.8843 35.7017)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74.7663 37.6948C74.4199 36.5418 73.3502 35.7017 72.0842 35.7017L65.4443 35.7017C63.8979 35.7017 62.6443 36.9553 62.6443 38.5017C62.6443 38.5368 62.645 38.5718 62.6463 38.6067C62.9927 39.7598 64.0624 40.5998 65.3283 40.5998L71.9682 40.5998C73.5146 40.5998 74.7682 39.3462 74.7682 37.7998C74.7682 37.7647 74.7675 37.7297 74.7663 37.6948Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.4093 36.0344C73.2656 36.0117 73.1183 35.9998 72.9682 35.9998L66.3283 35.9998C64.7819 35.9998 63.5283 37.2534 63.5283 38.7998C63.5283 39.8669 64.1252 40.7946 65.0033 41.2671C63.6665 41.0556 62.6444 39.898 62.6444 38.5017C62.6444 36.9553 63.898 35.7017 65.4444 35.7017L72.0843 35.7017C72.5636 35.7017 73.0148 35.8221 73.4093 36.0344Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-4_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M88.5283 38.5C88.5283 37.6716 87.8567 37 87.0283 37L86.8141 37L85.5283 37L67.0283 37C66.1999 37 65.5283 37.6716 65.5283 38.5V38.5C65.5283 39.3284 66.1999 40 67.0283 40L83.5283 40L87.0283 40L87.2426 40C87.9527 40 88.5283 39.4244 88.5283 38.7143V38.7143C88.5283 38.6771 88.5271 38.6401 88.5248 38.6035C88.5271 38.5693 88.5283 38.5348 88.5283 38.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M44.7759 98.5L68.5 46.2102L92.2241 98.5L44.7759 98.5Z" stroke="white" />
        <defs>
          <filter
            id="1-4_filter0_d"
            x="34.1201"
            y="109.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-4_filter1_d"
            x="79.1201"
            y="109.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-4_filter2_d"
            x="27.3003"
            y="28.3003"
            width="84.2793"
            height="89.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-4_filter3_i"
            x="43"
            y="44"
            width="51.9399"
            height="58.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="1-4_filter4_d"
            x="60.7645"
            y="33.8218"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-4_filter5_d"
            x="64.6485"
            y="35.1201"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="38.5"
            y1="117.8"
            x2="38.5"
            y2="110.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="53.5"
            y1="108.202"
            x2="53.5"
            y2="115.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="38.5"
            y1="117.798"
            x2="38.5"
            y2="110.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="83.5"
            y1="117.8"
            x2="83.5"
            y2="110.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="98.5"
            y1="108.202"
            x2="98.5"
            y2="115.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="83.5"
            y1="117.798"
            x2="83.5"
            y2="110.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
