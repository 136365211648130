import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement11Square4VerticalCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#1-1_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M60.0235 91.954C61.1151 91.954 62 91.0691 62 89.9775C62 88.886 61.1151 88.0011 60.0235 88.0011L57.8335 88.0011L57.8335 88.001L47 88.001L47 87.9999L43.9765 87.9999C42.8849 87.9999 42 88.8848 42 89.9764C42 91.068 42.8849 91.9529 43.9765 91.9529L46.1668 91.9529L46.1668 91.954L57 91.954L57 91.954L60.0235 91.954Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 57.8335 91.954)"
          fill="#ABABAB"
        />
        <path
          d="M61.0601 91.954C61.5792 91.954 62 91.5332 62 91.0141L62 88.941C62 88.4219 61.5792 88.0011 61.0601 88.0011L57 88.0011L57 91.954L61.0601 91.954Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M42.9399 87.9999C42.4208 87.9999 42 88.4208 42 88.9399L42 91.013C42 91.5321 42.4208 91.9529 42.9399 91.9529L47 91.9529L47 87.9999L42.9399 87.9999Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 56.1666 91.954)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#1-1_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M176.024 91.954C177.115 91.954 178 91.0691 178 89.9775C178 88.886 177.115 88.0011 176.024 88.0011L173.833 88.0011L173.833 88.001L163 88.001L163 87.9999L159.976 87.9999C158.885 87.9999 158 88.8848 158 89.9764C158 91.068 158.885 91.9529 159.976 91.9529L162.167 91.9529L162.167 91.954L173 91.954L173 91.954L176.024 91.954Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 173.834 91.954)"
          fill="#ABABAB"
        />
        <path
          d="M177.06 91.954C177.579 91.954 178 91.5332 178 91.0141L178 88.941C178 88.4219 177.579 88.0011 177.06 88.0011L173 88.0011L173 91.954L177.06 91.954Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M158.94 87.9999C158.421 87.9999 158 88.4208 158 88.9399L158 91.013C158 91.5321 158.421 91.9529 158.94 91.9529L163 91.9529L163 87.9999L158.94 87.9999Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 172.167 91.954)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#1-1_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 33H32V88H188V33ZM178 43H42V78H178V43Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 33H32V88H33V34H187V88H188V33Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-1_filter3_i)">
          <rect
            x="42.47"
            y="43.47"
            width="135.06"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="117.528"
          y="35"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 117.528 35)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#1-1_filter4_d)">
          <rect
            x="116.884"
            y="35.7018"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 116.884 35.7018)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M116.766 37.695C116.42 36.5419 115.35 35.7018 114.084 35.7018L107.444 35.7018C105.898 35.7018 104.644 36.9554 104.644 38.5018C104.644 38.537 104.645 38.572 104.646 38.6068C104.993 39.7599 106.062 40.6 107.328 40.6L113.968 40.6C115.515 40.6 116.768 39.3464 116.768 37.8C116.768 37.7649 116.768 37.7299 116.766 37.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115.409 36.0346C115.266 36.0118 115.118 36 114.968 36L108.328 36C106.782 36 105.528 37.2536 105.528 38.8C105.528 39.8671 106.125 40.7947 107.003 41.2673C105.666 41.0558 104.644 39.8982 104.644 38.5018C104.644 36.9554 105.898 35.7018 107.444 35.7018L114.084 35.7018C114.564 35.7018 115.015 35.8223 115.409 36.0346Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-1_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M130.528 38.5C130.528 37.6716 129.857 37 129.028 37L128.814 37L127.528 37L109.028 37C108.2 37 107.528 37.6716 107.528 38.5V38.5C107.528 39.3284 108.2 40 109.028 40L125.528 40L129.028 40L129.243 40C129.953 40 130.528 39.4244 130.528 38.7143V38.7143C130.528 38.6771 130.527 38.6401 130.525 38.6035C130.527 38.5693 130.528 38.5348 130.528 38.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M174.853 76.5L110 44.5574L45.1468 76.5L174.853 76.5Z" stroke="white" />
        <defs>
          <filter
            id="1-1_filter0_d"
            x="40.1201"
            y="86.1201"
            width="25.6396"
            height="9.59374"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-1_filter1_d"
            x="156.12"
            y="86.1201"
            width="25.6396"
            height="9.59374"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-1_filter2_d"
            x="27.3003"
            y="28.3003"
            width="167.279"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-1_filter3_i"
            x="42"
            y="43"
            width="136.94"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="1-1_filter4_d"
            x="102.764"
            y="33.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-1_filter5_d"
            x="106.649"
            y="35.1201"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="44.5"
            y1="94.7218"
            x2="44.5"
            y2="87.2092"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="59.5"
            y1="85.234"
            x2="59.5"
            y2="92.7446"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="44.5"
            y1="94.72"
            x2="44.5"
            y2="87.2093"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="160.5"
            y1="94.7218"
            x2="160.5"
            y2="87.2092"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="175.5"
            y1="85.234"
            x2="175.5"
            y2="92.7446"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="160.5"
            y1="94.72"
            x2="160.5"
            y2="87.2093"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
