import styled, { css, keyframes } from 'styled-components'
import { VIEW_TYPES } from './interface'

const stretch = keyframes`
  0% {
    transform: scale(.3);
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  75% {
    opacity: 0.4;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
`

export const StepByStepWrapper = styled.div<{
  show: boolean
  x: number | string
  y: number | string
  view?: VIEW_TYPES
}>`
  position: absolute;

  ${({ x, y }) => css`
    left: ${typeof x === 'number' ? `${x}px` : x};
    top: ${typeof y === 'number' ? `${y}px` : y};
  `}

  ${({ view = VIEW_TYPES.DEFAULT }) => {
    switch (view) {
      case VIEW_TYPES.BLUE:
        return css`
          background: #1c3775;
          color: #ffffff;
        `
      case VIEW_TYPES.DEFAULT:
      default:
        return css`
          background: #ffffff;
          color: #000000;
        `
    }
  }}

  width: 30px;
  height: 30px;

  border-radius: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding-bottom: 1px;

  box-shadow: 0 0 10px 2px rgb(0 0 0 / 30%);

  transform: scale(0);
  transition: transform 0.3s;

  z-index: 1000;

  ${({ show = false }) =>
    show &&
    css`
      transform: scale(1);
    `}

  span {
    position: relative;

    padding: 6px;

    font-weight: 400;
    font-size: 14px;
    line-height: 38px;

    z-index: 1000;
  }

  &:before {
    content: '';
    position: absolute;

    left: 0;
    top: 0;

    ${({ view = VIEW_TYPES.DEFAULT }) => {
      switch (view) {
        case VIEW_TYPES.BLUE:
          return css`
            border: 4px solid #1c3775;
          `
        case VIEW_TYPES.DEFAULT:
        default:
          return css`
            border: 4px solid #ffffff;
          `
      }
    }}

    border-radius: 50px;

    width: 30px;
    height: 30px;

    animation: ${stretch} 0.8s linear infinite;

    z-index: -1;
  }
`
