import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement33Square4Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="315"
        height="333"
        viewBox="0 0 315 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M137.322 274.5L47.6782 274.5L92.5 129.691L137.322 274.5Z" stroke="white" />
        <g filter="url(#27filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35 262C35 260.895 34.1046 260 33 260C31.8954 260 31 260.895 31 262V276.56H35V262ZM35 276.56H31V278.4H30.9399C30.4208 278.4 30 278.821 30 279.34V281.12C30 282.158 30.8417 283 31.8799 283H33.1201C34.1583 283 35 282.158 35 281.12V278.4V278.4V276.56Z"
            fill="white"
          />
        </g>
        <rect
          width="3.99998"
          height="0.95833"
          transform="matrix(-1 0 0 1 35 277.25)"
          fill="#ABABAB"
        />
        <path
          d="M35 260.94C35 260.421 34.5792 260 34.0601 260H31.94C31.4208 260 31 260.421 31 260.94V277.25H35V260.94Z"
          fill="url(#27paint0_linear)"
        />
        <path
          d="M35 282.06C35 282.579 34.5792 283 34.0601 283H30.94C30.4208 283 30 282.579 30 282.06V279.148C30 278.629 30.4208 278.208 30.94 278.208H35V282.06Z"
          fill="url(#27paint1_linear)"
        />
        <g filter="url(#27filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31 140.001C31 141.105 31.8952 142 32.9995 142C34.1038 142 34.999 141.105 34.999 140.001V137.833H34.999V127H35V123.999C35 122.895 34.1048 122 33.0005 122C31.8962 122 31.001 122.895 31.001 123.999V126.167H31V137H31V140.001Z"
            fill="url(#27paint2_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1 0 0 -1 31 137.833)"
          fill="#ABABAB"
        />
        <path
          d="M31 141.06C31 141.579 31.4208 142 31.9399 142H34.059C34.5781 142 34.999 141.579 34.999 141.06V137H31V141.06Z"
          fill="url(#27paint3_linear)"
        />
        <path
          d="M35 122.94C35 122.421 34.5792 122 34.0601 122H31.941C31.4219 122 31.001 122.421 31.001 122.94V127H35V122.94Z"
          fill="url(#27paint4_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1 0 0 -1 31 136.167)"
          fill="url(#27paint5_linear)"
        />
        <g filter="url(#27filter2_i)">
          <rect
            x="46.47"
            y="127.47"
            width="92.0601"
            height="148.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <g filter="url(#27filter3_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M150 116H35V287H150V116ZM139 127H46V276H139V127Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M150 116H35V287H36V117H149V287H150V116Z"
          fill="#FBFBFB"
        />
        <rect
          x="39.53"
          y="122.53"
          width="105.94"
          height="159.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="141"
          y="196"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#27filter4_d)">
          <rect x="141.702" y="196.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M142.286 207.796C142.798 208.458 143.6 208.884 144.502 208.884C146.049 208.884 147.302 207.63 147.302 206.084V199.444C147.302 198.456 146.79 197.587 146.017 197.089C146.383 197.562 146.6 198.156 146.6 198.8V205.44C146.6 206.986 145.347 208.24 143.8 208.24C143.242 208.24 142.723 208.077 142.286 207.796Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M144.502 196.644C142.955 196.644 141.702 197.898 141.702 199.444V205.44C141.702 205.46 141.702 205.48 141.702 205.501V200.024C141.702 198.477 142.956 197.224 144.502 197.224C146.028 197.224 147.269 198.444 147.302 199.963V199.444C147.302 197.898 146.048 196.644 144.502 196.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#27filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M144.5 200C143.672 200 143 200.672 143 201.5V201.714V203V221.5C143 222.328 143.672 223 144.5 223C145.328 223 146 222.328 146 221.5V205V201.5L146 201.286C146 200.576 145.424 200 144.714 200C144.677 200 144.64 200.001 144.604 200.004C144.569 200.001 144.535 200 144.5 200Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M47.5 273.953L47.5 129.047L137.204 201.5L47.5 273.953Z" stroke="white" />
        <defs>
          <filter
            id="27filter0_d"
            x="28.1201"
            y="258.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="27filter1_d"
            x="29.1201"
            y="120.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="27filter2_i"
            x="46"
            y="127"
            width="93.9399"
            height="151.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="27filter3_d"
            x="30.3003"
            y="111.3"
            width="126.279"
            height="182.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="27filter4_d"
            x="139.822"
            y="194.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="27filter5_d"
            x="141.12"
            y="198.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="27paint0_linear"
            x1="28.2"
            y1="268.625"
            x2="35.8"
            y2="268.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="27paint1_linear"
            x1="26.5"
            y1="280.604"
            x2="36"
            y2="280.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="27paint2_linear"
            x1="28.2"
            y1="124.5"
            x2="35.8"
            y2="124.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="27paint3_linear"
            x1="37.7982"
            y1="139.5"
            x2="30.2002"
            y2="139.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="27paint4_linear"
            x1="28.2018"
            y1="124.5"
            x2="35.7998"
            y2="124.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="27paint5_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
