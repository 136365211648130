import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement22Square4BottomCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-2_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M288 47.9995C288 46.8952 287.105 46 286.001 46C284.896 46 284.001 46.8952 284.001 47.9995V50.1665H284.001V61H284V64.0005C284 65.1048 284.895 66 285.999 66C287.104 66 287.999 65.1048 287.999 64.0005V61.8332H288V51H288V47.9995Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 288 50.1665)"
          fill="#ABABAB"
        />
        <path
          d="M288 46.9399C288 46.4208 287.579 46 287.06 46H284.941C284.422 46 284.001 46.4208 284.001 46.9399V51H288V46.9399Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M284 65.0601C284 65.5792 284.421 66 284.94 66H287.059C287.578 66 287.999 65.5792 287.999 65.0601V61H284V65.0601Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 288 51.8335)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#2-2_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M288 162.999C288 161.895 287.105 161 286.001 161C284.896 161 284.001 161.895 284.001 162.999V165.167H284.001V176H284V179.001C284 180.105 284.895 181 285.999 181C287.104 181 287.999 180.105 287.999 179.001V176.833H288V166H288V162.999Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 288 165.167)"
          fill="#ABABAB"
        />
        <path
          d="M288 161.94C288 161.421 287.579 161 287.06 161H284.941C284.422 161 284.001 161.421 284.001 161.94V166H288V161.94Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M284 180.06C284 180.579 284.421 181 284.94 181H287.059C287.578 181 287.999 180.579 287.999 180.06V176H284V180.06Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 288 166.833)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#2-2_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M284 34H164V193H284V34ZM273 45H175V182H273V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M284 34H164V193H165V35H283V193H284V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="168.53"
          y="38.53"
          width="110.94"
          height="149.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#2-2_filter3_i)">
          <rect
            x="175.47"
            y="45.47"
            width="97.0601"
            height="136.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="166"
          y="108"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#2-2_filter4_d)">
          <rect x="166.702" y="108.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M167.286 119.795C167.798 120.457 168.6 120.884 169.502 120.884C171.049 120.884 172.302 119.63 172.302 118.084V111.444C172.302 110.455 171.79 109.587 171.017 109.088C171.383 109.562 171.601 110.155 171.601 110.8V117.44C171.601 118.986 170.347 120.24 168.801 120.24C168.243 120.24 167.723 120.077 167.286 119.795Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M169.502 108.644C167.956 108.644 166.702 109.898 166.702 111.444V117.44C166.702 117.46 166.702 117.48 166.703 117.501V112.024C166.703 110.477 167.956 109.224 169.503 109.224C171.029 109.224 172.27 110.444 172.302 111.963V111.444C172.302 109.898 171.049 108.644 169.502 108.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-2_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M169.5 112C168.672 112 168 112.672 168 113.5V113.714V115V133.5C168 134.328 168.672 135 169.5 135C170.328 135 171 134.328 171 133.5V117V113.5L171 113.286C171 112.576 170.424 112 169.714 112C169.677 112 169.64 112.001 169.604 112.004C169.569 112.001 169.535 112 169.5 112Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M271.5 46.9571L271.5 180.043L175.875 113.5L271.5 46.9571Z" stroke="white" />
        <defs>
          <filter
            id="2-2_filter0_d"
            x="282.12"
            y="44.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-2_filter1_d"
            x="282.12"
            y="159.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-2_filter2_d"
            x="159.3"
            y="29.3003"
            width="131.279"
            height="170.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-2_filter3_i"
            x="175"
            y="45"
            width="98.9399"
            height="139.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-2_filter4_d"
            x="164.822"
            y="106.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-2_filter5_d"
            x="166.12"
            y="110.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="290.8"
            y1="63.5"
            x2="283.2"
            y2="63.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="281.202"
            y1="48.5"
            x2="288.8"
            y2="48.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="290.798"
            y1="63.5"
            x2="283.2"
            y2="63.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="290.8"
            y1="178.5"
            x2="283.2"
            y2="178.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="281.202"
            y1="163.5"
            x2="288.8"
            y2="163.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="290.798"
            y1="178.5"
            x2="283.2"
            y2="178.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
