import React, { FC } from 'react'

import { ButtonViews } from '../interface'
import { Button } from '../../../components'
import { ButtonWrapper } from './styles'
import { IRepairMessageFormProps } from './interface'
import { ModalSubTitle } from '../styles'

export const RepairMessageForm: FC<IRepairMessageFormProps> = props => {
  const { onClose } = props

  return (
    <>
      <ModalSubTitle>На Ваш почтовый адрес направлено письмо с инструкцией</ModalSubTitle>
      <ButtonWrapper>
        <Button typeView={ButtonViews.ORANGE} onClick={onClose}>
          Хорошо
        </Button>
      </ButtonWrapper>
    </>
  )
}
