import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement44Square62Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-4_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M137.001 115C138.105 115 139 114.105 139 113.001C139 111.896 138.105 111.001 137.001 111.001L134.833 111.001L134.833 111.001L124 111.001L124 111L120.999 111C119.895 111 119 111.895 119 112.999C119 114.104 119.895 114.999 120.999 114.999L123.167 114.999L123.167 115L134 115L134 115L137.001 115Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 134.833 115)"
          fill="#ABABAB"
        />
        <path
          d="M138.06 115C138.579 115 139 114.579 139 114.06L139 111.941C139 111.422 138.579 111.001 138.06 111.001L134 111.001L134 115L138.06 115Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M119.94 111C119.421 111 119 111.421 119 111.94L119 114.059C119 114.578 119.421 114.999 119.94 114.999L124 114.999L124 111L119.94 111Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 133.167 115)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#4-4_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M182.001 115C183.105 115 184 114.105 184 113.001C184 111.896 183.105 111.001 182.001 111.001L179.833 111.001L179.833 111.001L169 111.001L169 111L165.999 111C164.895 111 164 111.895 164 112.999C164 114.104 164.895 114.999 165.999 114.999L168.167 114.999L168.167 115L179 115L179 115L182.001 115Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 179.833 115)"
          fill="#ABABAB"
        />
        <path
          d="M183.06 115C183.579 115 184 114.579 184 114.06L184 111.941C184 111.422 183.579 111.001 183.06 111.001L179 111.001L179 115L183.06 115Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M164.94 111C164.421 111 164 111.421 164 111.94L164 114.059C164 114.578 164.421 114.999 164.94 114.999L169 114.999L169 111L164.94 111Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 178.167 115)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#4-4_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 33H115V111H188V33ZM177 44H126V100H177V44Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 33H115V111H116V34H187V111H188V33Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-4_filter3_i)">
          <rect
            x="126.47"
            y="44.47"
            width="50.0601"
            height="55.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="119.53"
          y="37.53"
          width="63.9399"
          height="68.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="158.528"
          y="35"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 158.528 35)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#4-4_filter4_d)">
          <rect
            x="157.884"
            y="35.7017"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 157.884 35.7017)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M157.766 37.6948C157.42 36.5418 156.35 35.7017 155.084 35.7017L148.444 35.7017C146.898 35.7017 145.644 36.9553 145.644 38.5017C145.644 38.5368 145.645 38.5718 145.646 38.6067C145.993 39.7598 147.062 40.5998 148.328 40.5998L154.968 40.5998C156.515 40.5998 157.768 39.3462 157.768 37.7998C157.768 37.7647 157.768 37.7297 157.766 37.6948Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M156.409 36.0344C156.266 36.0117 156.118 35.9998 155.968 35.9998L149.328 35.9998C147.782 35.9998 146.528 37.2534 146.528 38.7998C146.528 39.8669 147.125 40.7946 148.003 41.2671C146.666 41.0556 145.644 39.898 145.644 38.5017C145.644 36.9553 146.898 35.7017 148.444 35.7017L155.084 35.7017C155.564 35.7017 156.015 35.8221 156.409 36.0344Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-4_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M171.528 38.5C171.528 37.6716 170.857 37 170.028 37L169.814 37L168.528 37L150.028 37C149.2 37 148.528 37.6716 148.528 38.5V38.5C148.528 39.3284 149.2 40 150.028 40L166.528 40L170.028 40L170.243 40C170.953 40 171.528 39.4244 171.528 38.7143V38.7143C171.528 38.6771 171.527 38.6401 171.525 38.6035C171.527 38.5693 171.528 38.5348 171.528 38.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M127.776 98.5L151.5 46.2102L175.224 98.5L127.776 98.5Z" stroke="white" />
        <defs>
          <filter
            id="4-4_filter0_d"
            x="117.12"
            y="109.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-4_filter1_d"
            x="162.12"
            y="109.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-4_filter2_d"
            x="110.3"
            y="28.3003"
            width="84.2793"
            height="89.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-4_filter3_i"
            x="126"
            y="44"
            width="51.9399"
            height="58.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-4_filter4_d"
            x="143.764"
            y="33.8218"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-4_filter5_d"
            x="147.649"
            y="35.1201"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="121.5"
            y1="117.8"
            x2="121.5"
            y2="110.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="136.5"
            y1="108.202"
            x2="136.5"
            y2="115.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="121.5"
            y1="117.798"
            x2="121.5"
            y2="110.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="166.5"
            y1="117.8"
            x2="166.5"
            y2="110.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="181.5"
            y1="108.202"
            x2="181.5"
            y2="115.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="166.5"
            y1="117.798"
            x2="166.5"
            y2="110.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
