import styled, { css } from 'styled-components'

import { CheckboxLabel } from '../../../components/checkbox/styles'
import { InfoTooltipWrapper } from '../../../assets/icons'



export const StageOrderWrapper = styled.div`
  display: flex;

  width: 100%;
  max-width: 1200px;

  @media only screen and (max-width: 991px) {
    flex-direction: column;
    padding: 0 10px;
  }
  
  @media only screen and (max-width: 420px) {
    padding: 0 5px;
  }
`

export const StageOrderMain = styled.div`
  display: inline-block;
  width: 100%;
`

export const StageOrderProductsList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  padding: 0 20px 10px 0;

  @media only screen and (max-width: 991px) {
    padding-right: 0;
  }
`

export const StageOrderProductsListItem = styled.div`
  border: 1px dashed #8f8f8f;

  padding: 40px;
  margin-bottom: 20px;

  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    padding: 15px;
  }
`

export const StageOrderProductsMain = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`

export const StageOrderAdditionalBlock = styled.div`
  display: flex;
  flex-direction: column;

  background-color: #ffffff;
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.1);

  padding: 32px;

  min-width: 344px;
  max-width: 344px;

  margin-bottom: 24px;

  @media only screen and (max-width: 991px) {
    min-width: 100%;
    max-width: 100%;
  }
`

export const StageOrderAdditionalBlockWrapper = styled.div`
  position: relative;
  min-width: 344px;

  @media only screen and (max-width: 991px) {
    position: unset;
    width: 100%;
    order: 10;
  }
`

export const StageOrderAdditionalBlockRow = styled.div`
  display: flex;
  justify-content: space-between;

  font-size: 16px;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 28px;
  }
`

export const StageOrderAdditionalBlockPriceWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const StageOrderAdditionalBlockTotal = styled.div`
  border-top: 1px dashed #8f8f8f;

  padding-top: 28px;
`

export const StageOrderProductsListItemDescBlock = styled.div`
  flex: 1;
`

export const StageOrderProductsListItemPriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const StageOrderProductsListItemPriceTitle = styled.span`
  font-size: 12px;
  line-height: 16.8px;

  margin-bottom: 16px;
`

export const StageOrderProductsListItemPriceValue = styled.span`
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;

  margin-bottom: 13px;
`

export const StageOrderProductsListItemDescBlockTitle = styled.div`
  display: flex;

  font-size: 18px;
  font-weight: bold;

  margin-bottom: 12px;

  max-width: 545px;

  @media only screen and (max-width: 991px) {
    font-size: 16px;
  }
`

export const StageOrderProductsListItemDescBlockDesc = styled.div`
  font-size: 16px;

  margin-bottom: 12px;

  max-width: 545px;

  @media only screen and (max-width: 991px) {
    font-size: 14px;
  }
`

export const StageOrderToggleButton = styled.div`
  font-size: 14px;
  color: #1c3775;

  cursor: pointer;
`

export const StageOrderToggleWrapper = styled.div`
  border-bottom: 1px dashed #d4d4d4;

  padding-bottom: 40px;

  max-width: 545px;
`

export const StageOrderShowMoreBlockWrapper = styled.div`
  margin-top: 40px;
`

export const StageOrderShowMoreBlock = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;

  width: 100%;
`

export const StageOrderShowMoreBlockTitle = styled.div`
  padding-bottom: 12px;

  font-weight: bold;
`

export const StageOrderShowMoreBlockRowItem = styled.div<{
  noLeftGap?: boolean
  noRightBorder?: boolean
}>`
  border-right: 1px dashed #8f8f8f;

  padding: 0 28px 20px;

  ${({ noRightBorder = false }) =>
    noRightBorder &&
    css`
      border-right: none;
    `}

  ${({ noLeftGap = false }) =>
    noLeftGap &&
    css`
      padding-left: 0;
    `}
`

export const StageOrderBackButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  font-weight: bold;
  color: #141b4d;

  cursor: pointer;
`

export const StageOrderBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 60px;

  max-width: 630px;

  @media only screen and (max-width: 991px) {
    margin-top: 0;
    max-width: 100%;
  }
`

export const StageOrderBlockTitle = styled.h4`
  font-size: 30px;
  font-weight: 700;
  line-height: 42px;

  margin-top: 0;
  margin-bottom: 32px;
`

export const StageOrderBlockDescription = styled.h4`
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;

  margin-top: -10px;
  margin-bottom: 32px;
`

export const StageOrderBlockSubTitle = styled.span`
  font-size: 18px;
  font-weight: 700;
  line-height: 25.2px;

  margin-bottom: 20px;
`

export const StageOrderBlockItems = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const StageOrderGroup = styled.div<{ noBottomMargin?: boolean; fullWidth?: boolean }>`
  margin-bottom: 20px;
  margin-right: 20px;

  min-width: 295px;

  @media only screen and (max-width: 991px) {
    width: 100%;
  }

  ${({ noBottomMargin }) =>
    noBottomMargin &&
    css`
      margin-bottom: 0;
    `}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`

export const InputTitle = styled.div`
  font-size: 14px;
  line-height: 140%;
  color: #525252;

  margin-top: 6px;
`

export const StageOrderRecipientDescription = styled.div`
  display: flex;

  font-size: 14px;
  line-height: 22.4px;
  color: #333333;

  margin-top: 32px;
  margin-bottom: 40px;

  span {
    display: inline-block;

    margin-left: 16px;
  }

  ${InfoTooltipWrapper} {
    width: 16px;
  }
`

export const StageOrderAcceptWrapper = styled.div`
  font-size: 14px;

  ${CheckboxLabel} {
    margin-bottom: 0;
  }
`

export const StageOrderButtonDescription = styled.ul`
  font-size: 12px;
  text-align: left;
  line-height: 1.14;
  margin-top: 10px;
  list-style: decimal;
`

export const StageOrderDateWrapper = styled.div`
  font-size: 14px;
  line-height: 19.6px;
  color: #333333;

  margin-top: 6px;
`

export const StageOrderTitleWrapper = styled.div`
  display: flex;
`

export const StageOrderEmptyWrapper = styled.div`
  margin: 0 auto;
`

export const StageOrderEmptyTitle = styled.h3`
  font-size: 28px;
`
export const StageOrderEmptyDescription = styled.span`
  font-size: 18px;

  display: flex;
  align-items: center;
  gap: 5px;

  a {
    display: flex;
    gap: 5px;
  }
`

export const StageOrderLeftBlock = styled.div`
  flex: 1;
  width: 100%;
`