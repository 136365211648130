import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement31Square5VerticalCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#3-1_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M50.0005 192C51.1048 192 52 191.105 52 190.001C52 188.896 51.1048 188.001 50.0005 188.001L47.8335 188.001L47.8335 188.001L37 188.001L37 188L33.9995 188C32.8952 188 32 188.895 32 189.999C32 191.104 32.8952 191.999 33.9995 191.999L36.1668 191.999L36.1668 192L47 192L47 192L50.0005 192Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 47.8335 192)"
          fill="#ABABAB"
        />
        <path
          d="M51.0601 192C51.5792 192 52 191.579 52 191.06L52 188.941C52 188.422 51.5792 188.001 51.0601 188.001L47 188.001L47 192L51.0601 192Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M32.9399 188C32.4208 188 32 188.421 32 188.94L32 191.059C32 191.578 32.4208 191.999 32.9399 191.999L37 191.999L37 188L32.9399 188Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 46.1666 192)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#3-1_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M186.001 192C187.105 192 188 191.105 188 190.001C188 188.896 187.105 188.001 186.001 188.001L183.833 188.001L183.833 188.001L173 188.001L173 188L169.999 188C168.895 188 168 188.895 168 189.999C168 191.104 168.895 191.999 169.999 191.999L172.167 191.999L172.167 192L183 192L183 192L186.001 192Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 183.833 192)"
          fill="#ABABAB"
        />
        <path
          d="M187.06 192C187.579 192 188 191.579 188 191.06L188 188.941C188 188.422 187.579 188.001 187.06 188.001L183 188.001L183 192L187.06 192Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M168.94 188C168.421 188 168 188.421 168 188.94L168 191.059C168 191.578 168.421 191.999 168.94 191.999L173 191.999L173 188L168.94 188Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 182.167 192)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#3-1_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M192 142H28V188H192V142ZM183 151H37V179H183V151Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M192 142H28V188H29V143H191V188H192V142Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-1_filter3_i)">
          <rect
            x="37.47"
            y="151.47"
            width="145.06"
            height="27.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="32.53"
          y="146.53"
          width="154.94"
          height="36.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M40.8567 177.5L110 152.532L179.143 177.5H40.8567Z" stroke="white" />
        <rect
          x="117.528"
          y="143"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 117.528 143)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#3-1_filter4_d)">
          <rect
            x="116.884"
            y="143.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 116.884 143.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M116.766 145.695C116.42 144.542 115.35 143.702 114.084 143.702L107.444 143.702C105.898 143.702 104.644 144.955 104.644 146.502C104.644 146.537 104.645 146.572 104.646 146.607C104.993 147.76 106.062 148.6 107.328 148.6L113.968 148.6C115.515 148.6 116.768 147.346 116.768 145.8C116.768 145.765 116.768 145.73 116.766 145.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115.409 144.034C115.266 144.012 115.118 144 114.968 144L108.328 144C106.782 144 105.528 145.253 105.528 146.8C105.528 147.867 106.125 148.795 107.003 149.267C105.666 149.056 104.644 147.898 104.644 146.502C104.644 144.955 105.898 143.702 107.444 143.702L114.084 143.702C114.564 143.702 115.015 143.822 115.409 144.034Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-1_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M130.528 146.5C130.528 145.672 129.857 145 129.028 145L128.814 145L127.528 145L109.028 145C108.2 145 107.528 145.672 107.528 146.5V146.5C107.528 147.328 108.2 148 109.028 148L125.528 148L129.028 148L129.243 148C129.953 148 130.528 147.424 130.528 146.714V146.714C130.528 146.677 130.527 146.64 130.525 146.604C130.527 146.569 130.528 146.535 130.528 146.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="3-1_filter0_d"
            x="30.1201"
            y="186.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-1_filter1_d"
            x="166.12"
            y="186.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-1_filter2_d"
            x="23.3003"
            y="137.3"
            width="175.279"
            height="57.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-1_filter3_i"
            x="37"
            y="151"
            width="146.94"
            height="30.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-1_filter4_d"
            x="102.764"
            y="141.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-1_filter5_d"
            x="106.649"
            y="143.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="34.5"
            y1="194.8"
            x2="34.5"
            y2="187.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="49.5"
            y1="185.202"
            x2="49.5"
            y2="192.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="34.5"
            y1="194.798"
            x2="34.5"
            y2="187.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="170.5"
            y1="194.8"
            x2="170.5"
            y2="187.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="185.5"
            y1="185.202"
            x2="185.5"
            y2="192.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="170.5"
            y1="194.798"
            x2="170.5"
            y2="187.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
