import React, { useMemo, FC } from 'react'
import {
  StageContentRowWrapper,
  StageDescriptionBlockPoint,
  StageDescriptionBlockPointText,
  StageDescriptionBlockTop,
} from '../../views/calculator/styles'
import { DescriptionRow } from '../../views/calculator/stage-quiz/view/view-preferences-3/description-row'
import { getRows, totals } from '../../views/calculator/stage-quiz/view/view-preferences-3/consts'
import { IModelComparisonProps } from './interface'

export const ModelComparison: FC<IModelComparisonProps> = props => {
  const { type, filters } = props

  const selected = useMemo(() => {
    const result = {}

    if (filters.length > 0) {
      filters.forEach(({ key, value }) => {
        result[key] = value
      })
    }

    return result
  }, [filters])

  const baseModelData = useMemo(() => {
    const currentTotals = totals['model-1']

    return Object.keys(currentTotals).reduce((acc: Record<string, any>, key) => {
      const result = { ...acc }

      // eslint-disable-next-line prefer-destructuring
      result[key] = currentTotals[key][1]

      return result
    }, {})
  }, [])

  const currentData = useMemo(() => {
    const model = selected[`${type}-model`]
    const currentTotals = totals[model]

    return Object.keys(currentTotals).reduce((acc: Record<string, any>, key) => {
      const result = { ...acc }
      const selectedData = selected[key]

      if (key === 'sun-protection' && selectedData > 1) {
        const selectedValue = selected['heat-saving']

        result['energy-efficiency'] = currentTotals['energy-efficiency'][selectedValue]
        result[key] = currentTotals[key][selectedData]
      } else if (selectedData) {
        result[key] = currentTotals[key][selectedData]
      }

      return result
    }, {})
  }, [selected, type])

  const rows = useMemo(() => {
    return getRows(selected['sun-protection'])
  }, [selected])

  return (
    <StageContentRowWrapper>
      <StageDescriptionBlockTop>
        <div>
          <StageDescriptionBlockPoint />
          <StageDescriptionBlockPointText>Базовая</StageDescriptionBlockPointText>
        </div>
        <div>
          <StageDescriptionBlockPoint view="current" />
          <span>Выбранная для Вас модель</span>
        </div>
      </StageDescriptionBlockTop>
      {rows &&
        rows.map((row, index) => (
          <DescriptionRow
            key={`description-row-${row['code']}`}
            code={row['code']}
            title={row['title']}
            description={row['description']}
            baseModel={baseModelData[row['code']]}
            currentModel={currentData[row['code']]}
            initCollapsed={index !== 0}
          />
        ))}
    </StageContentRowWrapper>
  )
}
