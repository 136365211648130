import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement81Square82Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#8filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M182.001 231C183.105 231 184 230.105 184 229.001C184 227.896 183.105 227.001 182.001 227.001L179.833 227.001L179.833 227.001L169 227.001L169 227L165.999 227C164.895 227 164 227.895 164 228.999C164 230.104 164.895 230.999 165.999 230.999L168.167 230.999L168.167 231L179 231L179 231L182.001 231Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 179.833 231)"
          fill="#ABABAB"
        />
        <path
          d="M183.06 231C183.579 231 184 230.579 184 230.06L184 227.941C184 227.422 183.579 227.001 183.06 227.001L179 227.001L179 231L183.06 231Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M164.94 227C164.421 227 164 227.421 164 227.94L164 230.059C164 230.578 164.421 230.999 164.94 230.999L169 230.999L169 227L164.94 227Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 178.167 231)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#8filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M137.001 231C138.105 231 139 230.105 139 229.001C139 227.896 138.105 227.001 137.001 227.001L134.833 227.001L134.833 227.001L124 227.001L124 227L120.999 227C119.895 227 119 227.895 119 228.999C119 230.104 119.895 230.999 120.999 230.999L123.167 230.999L123.167 231L134 231L134 231L137.001 231Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.833 231)"
          fill="#ABABAB"
        />
        <path
          d="M138.06 231C138.579 231 139 230.579 139 230.06L139 227.941C139 227.422 138.579 227.001 138.06 227.001L134 227.001L134 231L138.06 231Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M119.94 227C119.421 227 119 227.421 119 227.94L119 230.059C119 230.578 119.421 230.999 119.94 230.999L124 230.999L124 227L119.94 227Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 133.167 231)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#8filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 231H115V286H188V231ZM178 241H125V276H178V241Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 231H115V286H116V232H187V286H188V231Z"
          fill="#FBFBFB"
        />
        <g filter="url(#8filter3_i)">
          <rect
            x="125.47"
            y="241.47"
            width="52.0601"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="119.53"
          y="235.53"
          width="63.9399"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M127.018 242.5L151.5 274.182L175.982 242.5L127.018 242.5Z" stroke="white" />
        <rect
          x="158"
          y="277"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 158 277)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="157.356"
          y="277.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 157.356 277.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M157.238 279.695C156.892 278.542 155.822 277.702 154.556 277.702L147.916 277.702C146.37 277.702 145.116 278.956 145.116 280.502C145.116 280.537 145.117 280.572 145.118 280.607C145.464 281.76 146.534 282.6 147.8 282.6L154.44 282.6C155.986 282.6 157.24 281.346 157.24 279.8C157.24 279.765 157.239 279.73 157.238 279.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M155.881 278.035C155.737 278.012 155.59 278 155.44 278L148.8 278C147.254 278 146 279.254 146 280.8C146 281.867 146.597 282.795 147.475 283.267C146.138 283.056 145.116 281.898 145.116 280.502C145.116 278.956 146.37 277.702 147.916 277.702L154.556 277.702C155.035 277.702 155.486 277.822 155.881 278.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#8filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M154 280.5C154 279.672 153.328 279 152.5 279L152.286 279L151 279L132.5 279C131.672 279 131 279.672 131 280.5V280.5C131 281.328 131.672 282 132.5 282L149 282L152.5 282L152.714 282C153.424 282 154 281.424 154 280.714V280.714C154 280.677 153.999 280.64 153.996 280.604C153.999 280.569 154 280.535 154 280.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="8filter0_d"
            x="162.12"
            y="225.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8filter1_d"
            x="117.12"
            y="225.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8filter2_d"
            x="110.3"
            y="226.3"
            width="84.2793"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="8filter3_i"
            x="125"
            y="241"
            width="53.9399"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="8filter4_d"
            x="129.28"
            y="277.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="166.5"
            y1="233.8"
            x2="166.5"
            y2="226.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="181.5"
            y1="224.202"
            x2="181.5"
            y2="231.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="166.5"
            y1="233.798"
            x2="166.5"
            y2="226.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="121.5"
            y1="233.8"
            x2="121.5"
            y2="226.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="136.5"
            y1="224.202"
            x2="136.5"
            y2="231.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="121.5"
            y1="233.798"
            x2="121.5"
            y2="226.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
