import React, {
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { AttributesTypes, IFilterItem, IMenuItemChildren, IMenuProps } from './interface'
import {
  MenuList,
  MenuListItem,
  MenuListTitle,
  MenuWrapper,
  SubMenuContent,
  SubMenuFooter,
  SubMenuTitle,
  SubMenuWrapper,
  MenuListItemChecked,
  MenuListItemTitle,
} from './styles'
import { SubMenuBody } from './sub-menu-body'
import { ProductsContext } from './stage-calculator'
import { Button, Info, StepByStepHelper } from '../../../components'
import {
  accessGlassUnitList,
  arrayAssign,
  checkIsHaveDoor,
  findAllIndexes,
  getGlassUnit,
  updateFilterItemData,
} from '../../../utilits'
import { Check2Icon } from '../../../assets/icons'

export const Menu: FC<IMenuProps> = props => {
  const {
    defaultData,
    product,
    productType,
    onSetItemSize,
    elementsPriceList,
    setFilters,
    setIsConfigurationEdit,
    setNeedUpdateItemSize,
  } = props
  const {
    menuItems,
    filters,
    menuItemsData,
    selectedSubAttributeData,
    setSelectedSubAttributeData,
    onChangeStepByStepHelper,
    stepByStepHelper,
    calculateAdditionalElementPrice,
    checkedMenu,
    setCheckedMenu,
  } = useContext(ProductsContext)

  const [showSubMenu, setShowSubMenu] = useState(false)
  const [selectedMenuItem, setSelectedMenuItem] = useState('')
  const [selectedMenuItemChild, setSelectedMenuItemChild] = useState('')
  const menuRef = useRef<HTMLDivElement>(null)
  const lastFilterKey = useRef('')

  useEffect(() => {
    if (
      defaultData &&
      (selectedSubAttributeData === null || Object.keys(selectedSubAttributeData).length === 0)
    ) {
      setSelectedSubAttributeData(defaultData)
    }
  }, [defaultData, selectedSubAttributeData, setSelectedSubAttributeData])

  useEffect(() => {
    if (filters.length === 0) {
      /** Drop selected data after clear filters - need for change product */
      setSelectedSubAttributeData(null)
    }
  }, [filters.length, setSelectedSubAttributeData])

  const handleOpenSubMenu = useCallback(
    (itemCode, childCode) => async () => {
      setSelectedMenuItem(itemCode)
      setSelectedMenuItemChild(childCode)
      setShowSubMenu(true)
      setCheckedMenu(childCode)

      if (stepByStepHelper === childCode) {
        onChangeStepByStepHelper()
      }
    },
    [onChangeStepByStepHelper, setCheckedMenu, stepByStepHelper],
  )

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        !showSubMenu &&
        menuRef.current !== null &&
        event.target !== null &&
        !menuRef.current.contains(event.target as Node)
      ) {
        setShowSubMenu(false)
        setSelectedMenuItem('')
        setSelectedMenuItemChild('')
      }
    },
    [showSubMenu],
  )

  const getNewWindowsillFilter = useCallback(
    (data: Record<string, any>) => {
      const windowsillModelData = {
        key: 'windowsill-model',
        value: '',
      }
      const windowsillModel = menuItemsData['windowsill-model'].find(
        windowsillItem => windowsillItem['id'] === 'model-2',
      )

      if (windowsillModel) {
        windowsillModelData['value'] = windowsillModel['value']
        setSelectedSubAttributeData(prevSelectedState => ({
          ...prevSelectedState,
          'windowsill-model': windowsillModel,
        }))
      }

      return updateFilterItemData('windowsill-model', data, windowsillModelData)
    },
    [menuItemsData, setSelectedSubAttributeData],
  )

  const getNewGlassUnitFilter = useCallback((data: IFilterItem[]) => {
    let result = [...data]

    const glassUnitSettings = accessGlassUnitList.reduce((accum, glassKey) => {
      const glassData = result.find(filter => filter['key'] === glassKey)

      return {
        ...accum,
        [glassKey]: glassData ? glassData['value'] : '',
      }
    }, {})
    const glassUnit = getGlassUnit(glassUnitSettings)

    if (glassUnit) {
      result = updateFilterItemData('glass-unit-2', result, {
        key: 'glass-unit-2',
        value: glassUnit,
      })
    }

    return result
  }, [])

  /** main handler for setting filters */
  const handleFilterChange = useCallback(
    (filterChangeProps = {}) => (key, item, notFilter: boolean): void => {
      const { dropFilter = false, attribute } = filterChangeProps as any
      const casementsData = {
        key: 'casements-index',
        value: [],
      }
      const mosquitoData = {
        key: 'mosquito-index',
        value: [],
      }
      const slopeTypeColorData = (newKey: string) => ({
        key: newKey,
        value: '',
      })
      const slopeKeys = ['outer-slope-type', 'inner-slope-type']

      setSelectedSubAttributeData(prevState => {
        const result = {
          ...prevState,
          [key]: item,
        }

        if (slopeKeys.includes(key)) {
          const slopeColorKey = `${key}-color`

          result[slopeColorKey] = slopeTypeColorData(slopeColorKey)
        }

        return result
      })

      setFilters(prevState => {
        let result: IFilterItem[] = [...prevState]
        const value = typeof item.value !== 'undefined' ? item.value : item
        const data = notFilter ? null : { key, value }

        let itemIndex = prevState.findIndex(prevFilter => prevFilter.key === key)
        const allIndexes = findAllIndexes(prevState, key)
        const isConfiguration = key === 'configuration'

        if (data) {
          if (itemIndex === -1) {
            itemIndex = result.length
          } else if (allIndexes.length > 1) {
            allIndexes.shift()
            allIndexes.forEach(index => {
              result.splice(index, 1)
            })
          }

          result = arrayAssign(result, itemIndex, data)

          if (dropFilter) {
            result = [data]
          }
        }

        if (isConfiguration) {
          lastFilterKey.current = 'configuration'
          result = updateFilterItemData('casements-index', result, casementsData)
          result = updateFilterItemData('mosquito-index', result, mosquitoData)

          setIsConfigurationEdit(true)
        }

        if (accessGlassUnitList.includes(key)) {
          result = getNewGlassUnitFilter(result)
        }

        /* TODO: КОСТЫЛЬ! Необходимо переписать! */
        if (key === 'comfortable-floriculture' && item['value']) {
          result = getNewWindowsillFilter(result)
        }

        return result
      })

      if (key === 'configuration') {
        setNeedUpdateItemSize(true)
      }

      if (attribute.type === AttributesTypes.SIZES) {
        const result: { key: string; value: any }[] = []
        const itemKeys = Object.keys(item)

        for (const itemKey of itemKeys) {
          if (typeof item[itemKey] === 'number') {
            result.push({
              key: itemKey,
              value: item[itemKey],
            })
          } else if (typeof item[itemKey] === 'object' && item[itemKey]['value']) {
            result.push({
              key: itemKey,
              value: Number(item[itemKey]['value']),
            })
          }
        }

        onSetItemSize(result)
      }
    },
    [
      getNewGlassUnitFilter,
      getNewWindowsillFilter,
      onSetItemSize,
      setFilters,
      setIsConfigurationEdit,
      setNeedUpdateItemSize,
      setSelectedSubAttributeData,
    ],
  )

  useEffect(() => {
    if (lastFilterKey.current === 'configuration') {
      lastFilterKey.current = ''
      const { isCasementsDoor, doorGroup } = checkIsHaveDoor(product)
      const result: number[] = []
      const casementsIndexKey = 'casements-index'

      if (isCasementsDoor) {
        setFilters(prevFilters => {
          const casementsFilterIndex = prevFilters.findIndex(
            filter => filter.key === casementsIndexKey,
          )
          const casementsFilter = prevFilters[casementsFilterIndex]
          const casementsFilterValue =
            casementsFilter && Array.isArray(casementsFilter['value'])
              ? [...casementsFilter['value']]
              : []
          if (casementsFilterValue.length === 0) {
            result[doorGroup] = 0
          }

          return [
            ...prevFilters.slice(0, casementsFilterIndex),
            { key: casementsIndexKey, value: result },
            ...prevFilters.slice(casementsFilterIndex + 1, prevFilters.length - 1),
          ]
        })
      }
    }
  }, [product, setFilters])

  useEffect(() => {
    // eslint-disable-next-line no-undef
    document.addEventListener('click', handleClickOutside)

    // eslint-disable-next-line no-undef
    return () => document.removeEventListener('click', handleClickOutside)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const currentSubMenu = useMemo(() => {
    let child: IMenuItemChildren | null = null

    /* Get selected child */
    menuItems.forEach(item => {
      if (item.code === selectedMenuItem && item.children?.length > 0) {
        const selectedChild = item.children.find(
          itemChild => itemChild.code === selectedMenuItemChild,
        )

        if (selectedChild) {
          child = selectedChild
        }
      }
    })

    return child
  }, [menuItems, selectedMenuItem, selectedMenuItemChild])

  const handleNextSubMenu = useCallback(() => {
    const parentIndex = menuItems.findIndex(item => item.code === selectedMenuItem)
    const haveParent = parentIndex > -1

    const childIndex = haveParent
      ? menuItems[parentIndex]['children'].findIndex(item => item.code === selectedMenuItemChild)
      : -1
    const haveChild = childIndex > -1
    let nextChildIndex = 0
    let nextParentIndex = parentIndex
    let nextParentCode = ''
    let nextChildCode = ''

    if (haveParent && haveChild) {
      if (childIndex + 1 >= menuItems[parentIndex]['children'].length) {
        nextParentIndex =  parentIndex + 1
      } else {
        // Костыль для пропуска откосов
        const isOtkos = (parentIndex === 2 && childIndex === 0)
        nextChildIndex = isOtkos ? 2 : childIndex + 1
      }
    }

    if (menuItems[nextParentIndex] && menuItems[nextParentIndex]['code']) {
      const nextChildren = menuItems[nextParentIndex]['children']

      nextParentCode = menuItems[nextParentIndex]['code']

      if (nextChildren && nextChildren[nextChildIndex]) {
        nextChildCode = nextChildren[nextChildIndex]['code']
      }
    }

    setSelectedMenuItem(prevState => nextParentCode || prevState)
    setSelectedMenuItemChild(prevState => nextChildCode || prevState)

    if (!nextParentCode && !nextChildCode) {
      setShowSubMenu(false)
    }
    if (nextChildCode === stepByStepHelper) {
      onChangeStepByStepHelper()
    }
  }, [
    menuItems,
    onChangeStepByStepHelper,
    selectedMenuItem,
    selectedMenuItemChild,
    stepByStepHelper,
  ])

  const checked = useCallback(key => checkedMenu.includes(key), [checkedMenu])

  const checkActive = useCallback((childCode: string) => selectedMenuItemChild === childCode, [
    selectedMenuItemChild,
  ])

  return (
    <MenuWrapper ref={menuRef}>
      <MenuList>
        {menuItems.map(item => (
          <Fragment key={item.code}>
            <MenuListTitle>
              <span>{item.title}</span>
            </MenuListTitle>
            {item.children?.length > 0 &&
              item.children.filter(child => child.code !== 'slopes').map(child => (
                <MenuListItem
                  key={child.code}
                  onClick={handleOpenSubMenu(item.code, child.code)}
                  active={checkActive(child.code)}
                  checked={checked(child.code)}
                >
                  {checked(child.code) && (
                    <MenuListItemChecked>
                      <Check2Icon />
                    </MenuListItemChecked>
                  )}
                  <StepByStepHelper code={child.code} coord={{ x: 11, y: 15 }} />
                  <MenuListItemTitle>
                    <span>{child.title}</span>
                    {checkActive(child.code) && child.description && (
                      <Info>{child.description}</Info>
                    )}
                  </MenuListItemTitle>
                </MenuListItem>
              ))}
          </Fragment>
        ))}
      </MenuList>
      <SubMenuWrapper show={showSubMenu}>
        <SubMenuContent>
          <SubMenuTitle>{currentSubMenu && currentSubMenu['full-title']}</SubMenuTitle>
          <SubMenuBody
            data={currentSubMenu}
            menuItemsData={menuItemsData}
            product={product}
            productType={productType}
            filters={filters}
            onSelect={handleFilterChange}
            elementsPriceList={elementsPriceList}
            calculateAdditionalElementPrice={calculateAdditionalElementPrice}
          />
        </SubMenuContent>
      </SubMenuWrapper>
      {showSubMenu && (
        <SubMenuFooter>
          <Button onClick={handleNextSubMenu}>Далее</Button>
        </SubMenuFooter>
      )}
    </MenuWrapper>
  )
}

export default Menu
