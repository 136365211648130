import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement45Square8Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-5_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M334.999 30C333.895 30 333 30.8952 333 31.9995C333 33.1038 333.895 33.999 334.999 33.999L337.167 33.999L337.167 33.999L348 33.999L348 34L351.001 34C352.105 34 353 33.1048 353 32.0005C353 30.8962 352.105 30.001 351.001 30.001L348.833 30.001L348.833 30L338 30L338 30L334.999 30Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 337.167 30)"
          fill="#ABABAB"
        />
        <path
          d="M333.94 30C333.421 30 333 30.4208 333 30.9399L333 33.059C333 33.5781 333.421 33.999 333.94 33.999L338 33.999L338 30L333.94 30Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M352.06 34C352.579 34 353 33.5792 353 33.0601L353 30.941C353 30.4219 352.579 30.001 352.06 30.001L348 30.001L348 34L352.06 34Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 338.833 30)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#4-5_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M394.999 30C393.895 30 393 30.8952 393 31.9995C393 33.1038 393.895 33.999 394.999 33.999L397.167 33.999L397.167 33.999L408 33.999L408 34L411.001 34C412.105 34 413 33.1048 413 32.0005C413 30.8962 412.105 30.001 411.001 30.001L408.833 30.001L408.833 30L398 30L398 30L394.999 30Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 397.167 30)"
          fill="#ABABAB"
        />
        <path
          d="M393.94 30C393.421 30 393 30.4208 393 30.9399L393 33.059C393 33.5781 393.421 33.999 393.94 33.999L398 33.999L398 30L393.94 30Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M412.06 34C412.579 34 413 33.5792 413 33.0601L413 30.941C413 30.4219 412.579 30.001 412.06 30.001L408 30.001L408 34L412.06 34Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 398.833 30)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#4-5_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 34H328V155H417V34ZM406 45H339V144H406V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 34H328V155H329.219V35H415.781V155H417V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-5_filter3_i)">
          <rect
            x="339.47"
            y="45.47"
            width="66.0601"
            height="98.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="332.53"
          y="38.53"
          width="79.9399"
          height="111.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="379"
          y="146"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 379 146)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="378.356"
          y="146.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 378.356 146.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M378.238 148.695C377.891 147.542 376.822 146.702 375.556 146.702L368.916 146.702C367.369 146.702 366.116 147.956 366.116 149.502C366.116 149.537 366.117 149.572 366.118 149.607C366.464 150.76 367.534 151.6 368.8 151.6L375.44 151.6C376.986 151.6 378.24 150.346 378.24 148.8C378.24 148.765 378.239 148.73 378.238 148.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M376.881 147.035C376.737 147.012 376.59 147 376.44 147L369.8 147C368.254 147 367 148.254 367 149.8C367 150.867 367.597 151.795 368.475 152.267C367.138 152.056 366.116 150.898 366.116 149.502C366.116 147.956 367.37 146.702 368.916 146.702L375.556 146.702C376.035 146.702 376.486 146.822 376.881 147.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-5_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M375 149.5C375 148.672 374.328 148 373.5 148L373.286 148L372 148L353.5 148C352.672 148 352 148.672 352 149.5V149.5C352 150.328 352.672 151 353.5 151L370 151L373.5 151L373.714 151C374.424 151 375 150.424 375 149.714V149.714C375 149.677 374.999 149.64 374.996 149.604C374.999 149.569 375 149.535 375 149.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M404.305 46.5L372.5 141.426L340.695 46.4999L404.305 46.5Z" stroke="white" />
        <defs>
          <filter
            id="4-5_filter0_d"
            x="331.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-5_filter1_d"
            x="391.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-5_filter2_d"
            x="323.3"
            y="29.3003"
            width="100.279"
            height="132.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-5_filter3_i"
            x="339"
            y="45"
            width="67.9399"
            height="101.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-5_filter4_d"
            x="350.28"
            y="146.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="350.5"
            y1="27.2"
            x2="350.5"
            y2="34.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="335.5"
            y1="36.7982"
            x2="335.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="350.5"
            y1="27.2018"
            x2="350.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="410.5"
            y1="27.2"
            x2="410.5"
            y2="34.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="395.5"
            y1="36.7982"
            x2="395.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="410.5"
            y1="27.2018"
            x2="410.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
