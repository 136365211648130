import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement26Square4BottomCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-6_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M193.001 197C194.105 197 195 196.105 195 195.001C195 193.896 194.105 193.001 193.001 193.001L190.833 193.001L190.833 193.001L180 193.001L180 193L176.999 193C175.895 193 175 193.895 175 194.999C175 196.104 175.895 196.999 176.999 196.999L179.167 196.999L179.167 197L190 197L190 197L193.001 197Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 190.834 197)"
          fill="#ABABAB"
        />
        <path
          d="M194.06 197C194.579 197 195 196.579 195 196.06L195 193.941C195 193.422 194.579 193.001 194.06 193.001L190 193.001L190 197L194.06 197Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M175.94 193C175.421 193 175 193.421 175 193.94L175 196.059C175 196.578 175.421 196.999 175.94 196.999L180 196.999L180 193L175.94 193Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 189.167 197)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#2-6_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M271.001 197C272.105 197 273 196.105 273 195.001C273 193.896 272.105 193.001 271.001 193.001L268.833 193.001L268.833 193.001L258 193.001L258 193L254.999 193C253.895 193 253 193.895 253 194.999C253 196.104 253.895 196.999 254.999 196.999L257.167 196.999L257.167 197L268 197L268 197L271.001 197Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 268.834 197)"
          fill="#ABABAB"
        />
        <path
          d="M272.06 197C272.579 197 273 196.579 273 196.06L273 193.941C273 193.422 272.579 193.001 272.06 193.001L268 193.001L268 197L272.06 197Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M253.94 193C253.421 193 253 193.421 253 193.94L253 196.059C253 196.578 253.421 196.999 253.94 196.999L258 196.999L258 193L253.94 193Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 267.167 197)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#2-6_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M284 34H164V193H284V34ZM273 45H175V182H273V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M284 34H164V193H165V35H283V193H284V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="168.53"
          y="38.53"
          width="110.94"
          height="149.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#2-6_filter3_i)">
          <rect
            x="175.47"
            y="45.47"
            width="97.0601"
            height="136.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <path d="M271.295 183.5L176.705 183.5L224 48.5121L271.295 183.5Z" stroke="white" />
        <rect
          x="230.528"
          y="36"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 230.528 36)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#2-6_filter4_d)">
          <rect
            x="229.884"
            y="36.7017"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 229.884 36.7017)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M229.767 38.6948C229.42 37.5418 228.351 36.7017 227.085 36.7017L220.445 36.7017C218.898 36.7017 217.645 37.9553 217.645 39.5017C217.645 39.5368 217.645 39.5718 217.647 39.6067C217.993 40.7598 219.063 41.5998 220.329 41.5998L226.969 41.5998C228.515 41.5998 229.769 40.3462 229.769 38.7998C229.769 38.7647 229.768 38.7297 229.767 38.6948Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M228.409 37.0344C228.266 37.0117 228.118 36.9998 227.968 36.9998L221.328 36.9998C219.782 36.9998 218.528 38.2534 218.528 39.7998C218.528 40.8669 219.125 41.7946 220.003 42.2671C218.666 42.0556 217.644 40.898 217.644 39.5017C217.644 37.9553 218.898 36.7017 220.444 36.7017L227.084 36.7017C227.564 36.7017 228.015 36.8221 228.409 37.0344Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-6_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M243.528 39.5C243.528 38.6716 242.857 38 242.028 38L241.814 38L240.528 38L222.028 38C221.2 38 220.528 38.6716 220.528 39.5C220.528 40.3284 221.2 41 222.028 41L238.528 41L242.028 41L242.243 41C242.953 41 243.528 40.4244 243.528 39.7143C243.528 39.6771 243.527 39.6401 243.525 39.6035C243.527 39.5693 243.528 39.5348 243.528 39.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="2-6_filter0_d"
            x="173.12"
            y="191.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-6_filter1_d"
            x="251.12"
            y="191.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-6_filter2_d"
            x="159.3"
            y="29.3003"
            width="131.279"
            height="170.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-6_filter3_i"
            x="175"
            y="45"
            width="98.9399"
            height="139.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-6_filter4_d"
            x="215.764"
            y="34.8218"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-6_filter5_d"
            x="219.649"
            y="36.1201"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="177.5"
            y1="199.8"
            x2="177.5"
            y2="192.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="192.5"
            y1="190.202"
            x2="192.5"
            y2="197.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="177.5"
            y1="199.798"
            x2="177.5"
            y2="192.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="255.5"
            y1="199.8"
            x2="255.5"
            y2="192.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="270.5"
            y1="190.202"
            x2="270.5"
            y2="197.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="255.5"
            y1="199.798"
            x2="255.5"
            y2="192.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
