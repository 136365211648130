import styled, { css } from 'styled-components'

export const NumberWrapper = styled.div`
  position: relative;

  min-width: 100px;
`

export const NumberStyle = styled.input<{ disabled?: boolean }>`
  background-color: ${({ disabled = false }) => (disabled ? '#f4f4f4' : '#ffffff')};
  background-image: none;
  border: 1px solid #e1e1e1;
  border-radius: 10px;

  box-sizing: border-box;

  margin: 0;
  padding: 15px 10px 15px 23px;

  font-variant: tabular-nums;
  font-feature-settings: 'tnum';
  color: #333333;
  font-size: 16px;
  line-height: 1.5;

  list-style: none;

  position: relative;

  display: inline-block;

  width: 100%;
  min-width: 0;

  transition: all 0.3s;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`

export const NumberError = styled.span`
  background-color: #ffffff;

  position: absolute;

  bottom: -34px;
  left: 0;

  font-size: 11px;
  line-height: 1.2;

  color: #ff453a;
`

export const InputReq= styled.span`
  position: absolute;
  color: red;
  padding: 0px 0px 0px 8px;
  z-index: 4;
  font-size: 32px;
`