import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement12Square1Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 254C32 252.895 31.1046 252 30 252C28.8954 252 28 252.895 28 254V268.56H32V254ZM32 268.56H28V270.4H27.9399C27.4208 270.4 27 270.821 27 271.34V273.12C27 274.158 27.8417 275 28.8799 275H30.1201C31.1583 275 32 274.158 32 273.12V270.4V270.4V268.56Z"
            fill="white"
          />
        </g>
        <rect
          width="3.99998"
          height="0.95833"
          transform="matrix(-1 0 0 1 32 269.25)"
          fill="#ABABAB"
        />
        <path
          d="M32 252.94C32 252.421 31.5792 252 31.0601 252H28.94C28.4208 252 28 252.421 28 252.94V269.25H32V252.94Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M32 274.06C32 274.579 31.5792 275 31.0601 275H27.94C27.4208 275 27 274.579 27 274.06V271.148C27 270.629 27.4208 270.208 27.94 270.208H32V274.06Z"
          fill="url(#paint1_linear)"
        />
        <g filter="url(#filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.954 45.9765C31.954 44.8849 31.0691 44 29.9775 44C28.8859 44 28.001 44.8849 28.001 45.9765V48.1665H28.001V59H27.9999V62.0235C27.9999 63.1151 28.8848 64 29.9764 64C31.068 64 31.9529 63.1151 31.9529 62.0235V59.8332H31.954V49H31.954V45.9765Z"
            fill="url(#paint2_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-1 0 0 1 31.954 48.1665)"
          fill="#ABABAB"
        />
        <path
          d="M31.954 44.9399C31.954 44.4208 31.5332 44 31.014 44H28.9409C28.4218 44 28.001 44.4208 28.001 44.9399V49H31.954V44.9399Z"
          fill="url(#paint3_linear)"
        />
        <path
          d="M27.9999 63.0601C27.9999 63.5792 28.4207 64 28.9398 64H31.0129C31.532 64 31.9529 63.5792 31.9529 63.0601V59H27.9999V63.0601Z"
          fill="url(#paint4_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-1 0 0 1 31.954 49.8334)"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 33H32V286H188V33ZM177 44H43V275H177V44Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 33H32V286H33V34H187V286H188V33Z"
          fill="#FBFBFB"
        />
        <rect
          x="36.53"
          y="37.53"
          width="146.94"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#filter3_i)">
          <rect
            x="43.47"
            y="44.47"
            width="133.06"
            height="230.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          width="6.91954"
          height="13.5283"
          rx="3.45977"
          transform="matrix(-1 0 0 1 185.92 153)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#filter4_d)">
          <rect
            width="5.53563"
            height="12.2399"
            rx="2.76782"
            transform="matrix(-1 0 0 1 185.226 153.644)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M184.661 164.792C184.155 165.456 183.356 165.884 182.458 165.884C180.929 165.884 179.69 164.645 179.69 163.116V156.412C179.69 155.44 180.191 154.585 180.948 154.092C180.594 154.557 180.383 155.138 180.383 155.768V162.472C180.383 164.001 181.622 165.24 183.151 165.24C183.708 165.24 184.226 165.075 184.661 164.792Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M182.458 153.644C183.986 153.644 185.226 154.883 185.226 156.412V162.472C185.226 162.492 185.225 162.512 185.225 162.533V156.991C185.225 155.463 183.986 154.224 182.457 154.224C180.949 154.224 179.722 155.43 179.69 156.931V156.412C179.69 154.883 180.929 153.644 182.458 153.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M182.46 157C183.279 157 183.943 157.664 183.943 158.483V160C183.943 160.014 183.942 160.027 183.942 160.041L183.942 178.517C183.942 179.336 183.278 180 182.459 180V180C181.64 180 180.977 179.336 180.977 178.517L180.977 158.251C180.977 157.56 181.537 157 182.228 157V157C182.231 157 182.234 157 182.236 157C182.311 157 182.385 157 182.46 157V157Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M44.5 272.906L175.237 159.816L44.5 46.0976L44.5 272.906Z" stroke="white" />
        <path d="M175.336 273.5L110.181 46.8055L44.665 273.5L175.336 273.5Z" stroke="white" />
        <defs>
          <filter
            id="filter0_d"
            x="25.1201"
            y="250.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter1_d"
            x="26.12"
            y="42.1201"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter2_d"
            x="27.3003"
            y="28.3003"
            width="167.279"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter3_i"
            x="43"
            y="44"
            width="134.94"
            height="233.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter4_d"
            x="177.81"
            y="151.764"
            width="11.1753"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter5_d"
            x="179.097"
            y="155.12"
            width="8.60556"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="25.2"
            y1="260.625"
            x2="32.8"
            y2="260.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="23.5"
            y1="272.604"
            x2="33"
            y2="272.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="34.7218"
            y1="61.5"
            x2="27.2092"
            y2="61.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="25.2339"
            y1="46.5"
            x2="32.7446"
            y2="46.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="34.72"
            y1="61.5"
            x2="27.2093"
            y2="61.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
