import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement54Square82Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#3filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M100.001 224C101.105 224 102 223.105 102 222.001C102 220.896 101.105 220.001 100.001 220.001L97.8335 220.001L97.8335 220.001L87 220.001L87 220L83.9995 220C82.8952 220 82 220.895 82 221.999C82 223.104 82.8952 223.999 83.9995 223.999L86.1668 223.999L86.1668 224L97 224L97 224L100.001 224Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 97.8335 224)"
          fill="#ABABAB"
        />
        <path
          d="M101.06 224C101.579 224 102 223.579 102 223.06L102 220.941C102 220.422 101.579 220.001 101.06 220.001L97 220.001L97 224L101.06 224Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M82.9399 220C82.4208 220 82 220.421 82 220.94L82 223.059C82 223.578 82.4208 223.999 82.9399 223.999L87 223.999L87 220L82.9399 220Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 96.1666 224)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#3filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M55.0005 224C56.1048 224 57 223.105 57 222.001C57 220.896 56.1048 220.001 55.0005 220.001L52.8335 220.001L52.8335 220.001L42 220.001L42 220L38.9995 220C37.8952 220 37 220.895 37 221.999C37 223.104 37.8952 223.999 38.9995 223.999L41.1668 223.999L41.1668 224L52 224L52 224L55.0005 224Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 52.8335 224)"
          fill="#ABABAB"
        />
        <path
          d="M56.0601 224C56.5792 224 57 223.579 57 223.06L57 220.941C57 220.422 56.5792 220.001 56.0601 220.001L52 220.001L52 224L56.0601 224Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M37.9399 220C37.4208 220 37 220.421 37 220.94L37 223.059C37 223.578 37.4208 223.999 37.9399 223.999L42 223.999L42 220L37.9399 220Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 51.1666 224)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#3filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M105 165H32V220H105V165ZM95 175H42V210H95V175Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105 165H32V220H33V166H104V220H105V165Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3filter3_i)">
          <rect
            x="42.47"
            y="175.47"
            width="52.0601"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="169.53"
          width="63.9399"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="75.5283"
          y="167"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 75.5283 167)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#3filter4_d)">
          <rect
            x="74.8843"
            y="167.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 74.8843 167.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74.7663 169.695C74.4199 168.542 73.3502 167.702 72.0842 167.702L65.4443 167.702C63.8979 167.702 62.6443 168.956 62.6443 170.502C62.6443 170.537 62.645 170.572 62.6463 170.607C62.9927 171.76 64.0624 172.6 65.3283 172.6L71.9682 172.6C73.5146 172.6 74.7682 171.346 74.7682 169.8C74.7682 169.765 74.7675 169.73 74.7663 169.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.4093 168.035C73.2656 168.012 73.1183 168 72.9682 168L66.3283 168C64.7819 168 63.5283 169.254 63.5283 170.8C63.5283 171.867 64.1252 172.795 65.0033 173.267C63.6665 173.056 62.6444 171.898 62.6444 170.502C62.6444 168.956 63.898 167.702 65.4444 167.702L72.0843 167.702C72.5636 167.702 73.0148 167.822 73.4093 168.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M88.5283 170.5C88.5283 169.672 87.8567 169 87.0283 169L86.8141 169L85.5283 169L67.0283 169C66.1999 169 65.5283 169.672 65.5283 170.5V170.5C65.5283 171.328 66.1999 172 67.0283 172L83.5283 172L87.0283 172L87.2426 172C87.9527 172 88.5283 171.424 88.5283 170.714V170.714C88.5283 170.677 88.5271 170.64 88.5248 170.604C88.5271 170.569 88.5283 170.535 88.5283 170.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M44.0182 208.5L68.5 176.818L92.9818 208.5L44.0182 208.5Z" stroke="white" />
        <defs>
          <filter
            id="3filter0_d"
            x="80.1201"
            y="218.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3filter1_d"
            x="35.1201"
            y="218.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3filter2_d"
            x="27.3003"
            y="160.3"
            width="84.2793"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3filter3_i"
            x="42"
            y="175"
            width="53.9399"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3filter4_d"
            x="60.7645"
            y="165.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3filter5_d"
            x="64.6485"
            y="167.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="84.5"
            y1="226.8"
            x2="84.5"
            y2="219.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="99.5"
            y1="217.202"
            x2="99.5"
            y2="224.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="84.5"
            y1="226.798"
            x2="84.5"
            y2="219.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="39.5"
            y1="226.8"
            x2="39.5"
            y2="219.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="54.5"
            y1="217.202"
            x2="54.5"
            y2="224.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="39.5"
            y1="226.798"
            x2="39.5"
            y2="219.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
