import { useCallback, useState } from 'react'

import { IUsePhoneConfirmationProps } from '../views/calculator/interface'
import fetchAPI from 'src/lib/utils/fetch-api'

export const usePhoneConfirmation: () => IUsePhoneConfirmationProps = () => {
  const [confirmed, setConfirmed] = useState(false)
  const [confirmError, setConfirmError] = useState('')

  const timeout = () => {
    return new Promise<boolean>(resolve => setTimeout(resolve, 1000))
  }

  const handleConfirmPhone = useCallback(async (phone: number) => {
    const fetchPhoneValid = async () => {
      await timeout()
      /* TODO: make fetch code on phone by special service */

      return Number(phone) === 1234
    }

    fetchPhoneValid()
  }, [])

  const handleConfirmCode = useCallback(
    async ({ code, phone }: { code: string; phone: string }) => {
      const fetchCodeValid = async () => {
        const isValidCode: boolean = await fetchAPI('web-hooks/is-valid-sms-code-from-user', {
          method: 'POST',
          body: JSON.stringify({
            phone: phone,
            sms_code: code,
          }),
        })
          .then(item => item?.['data']?.['is_valid_code'] ?? false)
          .catch(console.error)
        return isValidCode ?? false
      }
      let valid: boolean

      /* TODO: fetch code confirmation - if valid - set confirmed */
      valid = await fetchCodeValid()

      if (valid) {
        setConfirmed(true)
        setConfirmError('')
        return true
      }
      setConfirmError('Код не валидный')
      return false
    },
    [],
  )

  return {
    confirmed,
    confirmError,
    confirmCode: handleConfirmCode,
    confirmPhone: handleConfirmPhone,
  }
}
