import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement53Square54Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#5-3_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M370.001 291C371.105 291 372 290.105 372 289.001C372 287.896 371.105 287.001 370.001 287.001L367.833 287.001L367.833 287.001L357 287.001L357 287L353.999 287C352.895 287 352 287.895 352 288.999C352 290.104 352.895 290.999 353.999 290.999L356.167 290.999L356.167 291L367 291L367 291L370.001 291Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 367.833 291)"
          fill="#ABABAB"
        />
        <path
          d="M371.06 291C371.579 291 372 290.579 372 290.06L372 287.941C372 287.422 371.579 287.001 371.06 287.001L367 287.001L367 291L371.06 291Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M352.94 287C352.421 287 352 287.421 352 287.94L352 290.059C352 290.578 352.421 290.999 352.94 290.999L357 290.999L357 287L352.94 287Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 366.167 291)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#5-3_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M410.001 291C411.105 291 412 290.105 412 289.001C412 287.896 411.105 287.001 410.001 287.001L407.833 287.001L407.833 287.001L397 287.001L397 287L393.999 287C392.895 287 392 287.895 392 288.999C392 290.104 392.895 290.999 393.999 290.999L396.167 290.999L396.167 291L407 291L407 291L410.001 291Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 407.833 291)"
          fill="#ABABAB"
        />
        <path
          d="M411.06 291C411.579 291 412 290.579 412 290.06L412 287.941C412 287.422 411.579 287.001 411.06 287.001L407 287.001L407 291L411.06 291Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M392.94 287C392.421 287 392 287.421 392 287.94L392 290.059C392 290.578 392.421 290.999 392.94 290.999L397 290.999L397 287L392.94 287Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 406.167 291)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#5-3_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M416 34H348V287H416V34ZM406 44H358V277H406V44Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M416 34H348V287H348.932V35H415.068V287H416V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#5-3_filter3_i)">
          <rect
            x="358.47"
            y="44.47"
            width="47.0601"
            height="232.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="352.53"
          y="38.53"
          width="58.9399"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M404.448 275.5L382 50.0466L359.552 275.5L404.448 275.5Z" stroke="white" />
        <rect
          x="388.528"
          y="36"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 388.528 36)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#5-3_filter4_d)">
          <rect
            x="387.884"
            y="36.7017"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 387.884 36.7017)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M387.766 38.6948C387.42 37.5418 386.35 36.7017 385.084 36.7017L378.444 36.7017C376.898 36.7017 375.644 37.9553 375.644 39.5017C375.644 39.5368 375.645 39.5718 375.646 39.6067C375.993 40.7598 377.062 41.5998 378.328 41.5998L384.968 41.5998C386.515 41.5998 387.768 40.3462 387.768 38.7998C387.768 38.7647 387.768 38.7297 387.766 38.6948Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M386.409 37.0344C386.266 37.0117 386.118 36.9998 385.968 36.9998L379.328 36.9998C377.782 36.9998 376.528 38.2534 376.528 39.7998C376.528 40.8669 377.125 41.7946 378.003 42.2671C376.666 42.0556 375.644 40.898 375.644 39.5017C375.644 37.9553 376.898 36.7017 378.444 36.7017L385.084 36.7017C385.564 36.7017 386.015 36.8221 386.409 37.0344Z"
          fill="#FBFBFB"
        />
        <g filter="url(#5-3_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M401.528 39.5C401.528 38.6716 400.857 38 400.028 38L399.814 38L398.528 38L380.028 38C379.2 38 378.528 38.6716 378.528 39.5V39.5C378.528 40.3284 379.2 41 380.028 41L396.528 41L400.028 41L400.243 41C400.953 41 401.528 40.4244 401.528 39.7143V39.7143C401.528 39.6771 401.527 39.6401 401.525 39.6035C401.527 39.5693 401.528 39.5348 401.528 39.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="5-3_filter0_d"
            x="350.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-3_filter1_d"
            x="390.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-3_filter2_d"
            x="343.3"
            y="29.3003"
            width="79.2793"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-3_filter3_i"
            x="358"
            y="44"
            width="48.9399"
            height="235.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-3_filter4_d"
            x="373.764"
            y="34.8218"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-3_filter5_d"
            x="377.649"
            y="36.1201"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="354.5"
            y1="293.8"
            x2="354.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="369.5"
            y1="284.202"
            x2="369.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="354.5"
            y1="293.798"
            x2="354.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="394.5"
            y1="293.8"
            x2="394.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="409.5"
            y1="284.202"
            x2="409.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="394.5"
            y1="293.798"
            x2="394.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
