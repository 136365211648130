import React, {
  useMemo,
  FC,
  useCallback,
  ReactElement,
  useContext,
  useRef,
  useEffect,
  useState,
} from 'react'

import {
  ProductImagesWrapperStyle,
  MosquitoButtonItemStyle,
  MosquitoButtonTextStyle,
} from './styles'
import { IProductImagesProps } from './interface'
import { ProductsContext } from '../../stage-calculator'
import { IFilterItem } from '../../interface'
import { getImagesObject } from './consts'
import { ItemViewConstructorWrapperStyles } from '../../styles'
import { arrayAssign, getAxisCoord, getCasementsValue, splitString } from '../../../../../utilits'
import { Tooltip, StepByStepHelper, VIEW_TYPES } from '../../../../../components'

export const ProductImagesGenerator: FC<IProductImagesProps> = props => {
  const {
    productType,
    product,
    onChangeSize,
    onGetPreview = () => null,
    onlyView = false,
    filters,
    setFilters,
  } = props
  const { stepByStepHelper, onChangeStepByStepHelper } = useContext(ProductsContext)
  const [needRefreshSizes, setNeedRefreshSizes] = useState(false)
  const [images, setImages] = useState<(ReactElement | null)[]>([])
  const [casements, setCasements] = useState<Record<string, any>[]>([])
  const wrapperRef = useRef<HTMLDivElement>(null)
  const firstActiveCasement = useRef<number | null>(null)
  const zeroCasementCoord = useRef({ x: 0, y: 0 })

  useEffect(() => {
    if (needRefreshSizes && wrapperRef !== null && wrapperRef.current !== null) {
      const image = wrapperRef.current.querySelector('svg')

      if (!onlyView && onChangeSize && image !== null) {
        onChangeSize({
          width: Number(image['width']['baseVal']['value']),
          height: Number(image['height']['baseVal']['value']),
        })
      }

      setNeedRefreshSizes(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needRefreshSizes])

  const imageList = useMemo(() => {
    const configuration = product['configuration'] || {}
    const result: string = configuration['images']

    return result
  }, [product])

  const getProductImage = useCallback(
    (filterHandler, withoutBackground?: boolean) => {
      // console.log({ filterHandler, productType, withoutBackground })
      return getImagesObject(filterHandler, productType, withoutBackground)
    },
    [productType],
  )

  const getNewBaseValue = useCallback(
    (innerBaseValue: string, innerCasementGroupIndex: number) => {
      let baseValueList = splitString(innerBaseValue, ',')
      const casementConfig = baseValueList.length > 0 && baseValueList[0].split('/')[0]
      const currentCasementIndex = baseValueList.findIndex(
        value => value && value.indexOf(`casement-${innerCasementGroupIndex}`) >= 0,
      )
      const currentCasement = currentCasementIndex && baseValueList[currentCasementIndex]
      const productImages = getProductImage(() => null)

      /** Getting new casements names */
      if (currentCasement && casementConfig) {
        const currentCasementElements = currentCasement.split('-').map(value => value.trim())
        const newCurrentCasementSubIndex = Number(currentCasementElements[2]) + 1
        const newCurrentCasement = `casement-${innerCasementGroupIndex}-${newCurrentCasementSubIndex}`
        const currentCasementElement =
          productImages[casementConfig] && productImages[casementConfig][newCurrentCasement]

        if (currentCasementElement && React.isValidElement(currentCasementElement)) {
          baseValueList[currentCasementIndex] = `${casementConfig}/${newCurrentCasement}`
        } else {
          baseValueList = [
            ...baseValueList.slice(0, currentCasementIndex),
            ...baseValueList.slice(currentCasementIndex + 1, baseValueList.length),
          ]
        }
      } else {
        baseValueList.push(`${casementConfig}/casement-${innerCasementGroupIndex}-1`)
      }

      return baseValueList
        .sort((valueA, valueB) => {
          const valueListA: string[] = valueA.split('-')
          const valueListB: string[] = valueB.split('-')

          return Number(valueListA[1]) - Number(valueListB[1])
        })
        .join(', ')
    },
    [getProductImage],
  )

  const getNextCasementsIndex = useCallback(
    (groupIndex: number, itemIndex: number) => {
      let result: undefined | number = 0
      const casementsValue = product['configuration']['casements-value']
      const groups = splitString(casementsValue, '],').map(item => item.replace(/[|]/g, ''))

      if (typeof groupIndex !== 'undefined') {
        const currentGroup = groups[groupIndex]
        const currentGroupItems = splitString(currentGroup, ',')

        if (currentGroupItems.length - 1 === itemIndex) {
          if (currentGroupItems[itemIndex].indexOf('(Д)') !== -1) {
            result = 0
          } else {
            result = undefined
          }
        } else if (
          typeof itemIndex !== 'undefined' &&
          itemIndex !== null &&
          itemIndex < currentGroupItems.length - 1
        ) {
          result = itemIndex + 1
        } else {
          result = 0
        }
      }

      return result
    },
    [product],
  )

  const handleFilterChange = useCallback(
    (baseValue: string) => (casementGroupIndex: number) => () => {
      if (!onlyView) {
        const getNewFilters = () => {
          let result: IFilterItem[]
          const imageKey = 'configuration-images'
          const casementKey = 'casements-index'
          const filterItemIndex = filters.findIndex(prevFilter => prevFilter.key === imageKey)
          const filterCasementItemIndex = filters.findIndex(
            prevFilter => prevFilter.key === casementKey,
          )
          const isHaveImagesFilter = filterItemIndex >= 0
          const isHaveCasementsFilter = filterCasementItemIndex >= 0
          let casementValue = isHaveCasementsFilter && filters[filterCasementItemIndex]['value']

          if (!Array.isArray(casementValue)) {
            casementValue = []
          }
          const newBaseValue: string = isHaveImagesFilter
            ? getNewBaseValue(
                (filters[filterItemIndex]['value'] as string) || baseValue,
                casementGroupIndex,
              )
            : baseValue
          casementValue[casementGroupIndex] = getNextCasementsIndex(
            casementGroupIndex,
            casementValue[casementGroupIndex],
          )

          const data = { key: imageKey, value: newBaseValue }
          const casementData = { key: casementKey, value: casementValue as any }

          if (isHaveImagesFilter) {
            result = arrayAssign(filters, filterItemIndex, data)
          } else {
            result = [...filters, data]
          }

          if (isHaveCasementsFilter) {
            result = arrayAssign(result, filterCasementItemIndex, casementData)
          }

          if (!casementValue[casementGroupIndex]) {
            const mosquitoKey = 'mosquito-index'
            const mosquitoDataIndex = result.findIndex(item => item['key'] === mosquitoKey)

            if (result[mosquitoDataIndex] && Array.isArray(result[mosquitoDataIndex]['value'])) {
              const { value }: { value: any } = result[mosquitoDataIndex]
              const mosquitoIndex = value.findIndex(item => item === casementGroupIndex)

              if (mosquitoIndex >= 0) {
                const newMosquitoData = {
                  key: mosquitoKey,
                  value: [
                    ...value.splice(0, mosquitoIndex),
                    ...value.splice(mosquitoIndex + 1, value.length),
                  ],
                }

                result = arrayAssign(result, mosquitoDataIndex, newMosquitoData)
              }
            }
          }

          return result
        }

        if (setFilters) {
          setFilters(getNewFilters())
        }
      }
    },
    [filters, getNewBaseValue, getNextCasementsIndex, onlyView, setFilters],
  )

  const handleToggleMosquito = useCallback(
    index => () => {
      const getNewFilters = () => {
        const result: IFilterItem[] = [...(filters as IFilterItem[])]
        const filterKey = 'mosquito-index'
        const mosquitoIndex = filters.findIndex(item => item.key === filterKey)
        let newMosquitoIndex =
          mosquitoIndex >= 0 ? filters[mosquitoIndex] : { key: filterKey, value: [index] }

        if (mosquitoIndex >= 0 && Array.isArray(newMosquitoIndex['value'])) {
          const prevStateIndex = newMosquitoIndex['value'].findIndex(item => item === index)

          if (prevStateIndex >= 0) {
            newMosquitoIndex['value'] = [
              ...newMosquitoIndex['value'].slice(0, prevStateIndex),
              ...newMosquitoIndex['value'].slice(
                prevStateIndex + 1,
                newMosquitoIndex['value'].length,
              ),
            ]
          } else {
            newMosquitoIndex['value'].push(index)
            newMosquitoIndex = {
              ...newMosquitoIndex,
              value: newMosquitoIndex['value'],
            }
          }

          result[mosquitoIndex] = newMosquitoIndex
        } else {
          /** if its first mosquito index */
          result.push(newMosquitoIndex)
        }

        return result
      }

      if (setFilters) {
        setFilters(getNewFilters())
      }

      if (stepByStepHelper === 'mosquito-button') {
        onChangeStepByStepHelper()
      }
    },
    [filters, onChangeStepByStepHelper, setFilters, stepByStepHelper],
  )

  const getMosquitoButton = useCallback(
    (clearView = false) => (casement, index) => {
      // eslint-disable-next-line no-undef
      const x = getAxisCoord(casement['data'], 'x', 'width', clearView, zeroCasementCoord.current)
      const y = getAxisCoord(casement['data'], 'y', 'height', clearView, zeroCasementCoord.current)
      const casementsValue = getCasementsValue(product, index)
      const casementsIndex = filters.find(filter => filter['key'] === 'casements-index')
      const mosquitoIndex = filters.find(filter => filter['key'] === 'mosquito-index')
      const mosquitoNotNeeded = filters.find(filter => filter['key'] === 'mosquito-type-not-needed')
      const haveMosquitoNotNeeded = typeof mosquitoNotNeeded !== 'undefined'
      let show = false
      let active = false

      if (
        (!haveMosquitoNotNeeded || (mosquitoNotNeeded && mosquitoNotNeeded['value'] === false)) &&
        casementsIndex &&
        Array.isArray(casementsIndex['value'])
      ) {
        show = casementsIndex['value'][index] !== null && casementsIndex['value'][index] >= 0

        if (show && casementsValue && casementsValue[0]) {
          show = casementsValue[0].indexOf('(Д)') === -1
        }

        if (firstActiveCasement.current === null) {
          firstActiveCasement.current = index
        }
      }

      if (typeof mosquitoIndex !== 'undefined' && Array.isArray(mosquitoIndex['value'])) {
        const mosquitoSelected = mosquitoIndex['value'].find(item => item === index)

        if (typeof mosquitoSelected !== 'undefined') {
          active = true
        }
      }

      return show ? (
        <div
          style={MosquitoButtonItemStyle(x, y, active, clearView)}
          onClick={handleToggleMosquito(index)}
          key={`mosquito-button-${index}`}
        >
          {!clearView && !onlyView && (
            <>
              {index === firstActiveCasement.current && (
                <StepByStepHelper
                  code="mosquito-button"
                  view={VIEW_TYPES.BLUE}
                  coord={{ x: -40, y: -5 }}
                />
              )}
              <Tooltip text="Добавить москитную сетку" coord={{ x: 12, y: 36 }}>
                <span style={MosquitoButtonTextStyle()}>#</span>
              </Tooltip>
            </>
          )}
          {(clearView || onlyView) && active && <span style={MosquitoButtonTextStyle(3)}>#</span>}
        </div>
      ) : null
    },
    [filters, handleToggleMosquito, onlyView, product],
  )

  const getImage = useCallback(
    (baseImage: string, withoutBackground?: boolean) => {
      const productImages = getProductImage(handleFilterChange, withoutBackground)
      const result: (ReactElement | null)[] = []
      const casementsFilter = filters.find(filter => filter.key === 'casements-index')
      const casementsFilterValue =
        casementsFilter && Array.isArray(casementsFilter['value'])
          ? [...casementsFilter['value']]
          : []

      if (baseImage && productImages[baseImage]) {
        if (React.isValidElement(productImages[baseImage]['container'])) {
          result.push(productImages[baseImage]['container'])
        }
      }

      /** Getting casements from filters */
      if (casementsFilterValue) {
        if (Array.isArray(casementsFilterValue)) {
          casementsFilterValue.forEach((index, groupIndex) => {
            if (index !== null) {
              const casementName = `casement-${groupIndex + 1}-${index + 1}`

              if (
                productImages[baseImage] &&
                React.isValidElement(productImages[baseImage][casementName])
              ) {
                result.push(productImages[baseImage][casementName])
              }
            }
          })
        }
      }

      if (withoutBackground && casements.length > 0) {
        result.push(...casements.map(getMosquitoButton(true)))
      }

      if (result.length === 0) {
        result.push(<div key="no-image">No Image</div>)
      } else if (!needRefreshSizes) {
        setNeedRefreshSizes(true)
      }

      return result
    },
    [casements, filters, getMosquitoButton, getProductImage, handleFilterChange, needRefreshSizes],
  )

  useEffect(() => {
    if (imageList) {
      const result = getImage(imageList)
      const sketch = getImage(imageList, true)

      setImages(result)
      onGetPreview(sketch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageList, filters, stepByStepHelper])

  const calculateCasementDelta = useCallback(casement => {
    const result = {}
    const { width, height, index } = casement

    if (index === '0') {
      zeroCasementCoord.current = {
        ...casement['delta'],
      }
    }

    result['data'] = {
      width,
      height,
      delta: casement['delta'],
    }

    return result
  }, [])

  useEffect(() => {
    if (wrapperRef.current) {
      const wrapperCoord = wrapperRef.current.getBoundingClientRect()
      const casementList = wrapperRef.current.querySelectorAll('.casement')
      const newCasementData: Record<string, any>[] = []

      if (casementList.length > 0) {
        casementList.forEach(node => {
          let result: Record<string, any> | null = null
          const { index } = (node as HTMLElement).dataset

          if (node) {
            const nodeData = node.getBoundingClientRect()

            result = calculateCasementDelta({
              nodeData,
              width: nodeData['width'],
              height: nodeData['height'],
              delta: {
                x: nodeData['x'] - wrapperCoord['x'],
                y: nodeData['y'] - wrapperCoord['y'],
              },
              index,
            })
          }

          if (result && index) {
            newCasementData[index] = result
          }
        })
      }

      if (newCasementData.length > 0) {
        setCasements(newCasementData)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images])

  return (
    <div style={ItemViewConstructorWrapperStyles}>
      <div style={ProductImagesWrapperStyle(onlyView)} ref={wrapperRef}>
        {images.length > 0 && images.map(image => image)}
        {casements.length > 0 && casements.map(getMosquitoButton())}
      </div>
    </div>
  )
}
