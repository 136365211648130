import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement42Square4BottomCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-2_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M404.217 291C405.322 291 406.217 290.105 406.217 289.001C406.217 287.896 405.322 287.001 404.217 287.001L401.797 287.001L401.797 287.001L390.304 287.001L390.304 287L386.999 287C385.895 287 385 287.895 385 288.999C385 290.104 385.895 290.999 386.999 290.999L389.42 290.999L389.42 291L400.913 291L400.913 291L404.217 291Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="12.3767"
          transform="matrix(1.26506e-08 -1 -1 -1.12408e-08 401.797 291)"
          fill="#ABABAB"
        />
        <path
          d="M405.277 291C405.796 291 406.217 290.579 406.217 290.06L406.217 287.941C406.217 287.422 405.796 287.001 405.277 287.001L400.913 287.001L400.913 291L405.277 291Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M385.94 287C385.421 287 385 287.421 385 287.94L385 290.059C385 290.578 385.421 290.999 385.94 290.999L390.304 290.999L390.304 287L385.94 287Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.84048"
          transform="matrix(1.26506e-08 -1 -1 -1.12408e-08 400.028 291)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#4-2_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M62.8872 291C63.9915 291 64.8867 290.105 64.8867 289.001C64.8867 287.896 63.9915 287.001 62.8872 287.001L60.4666 287.001L60.4666 287.001L48.9739 287.001L48.9739 287L45.669 287C44.5648 287 43.6696 287.895 43.6696 288.999C43.6696 290.104 44.5648 290.999 45.669 290.999L48.0899 290.999L48.0899 291L59.5824 291L59.5824 291L62.8872 291Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="12.3767"
          transform="matrix(1.26506e-08 -1 -1 -1.12408e-08 60.4668 291)"
          fill="#ABABAB"
        />
        <path
          d="M63.9468 291C64.4659 291 64.8867 290.579 64.8867 290.06L64.8867 287.941C64.8867 287.422 64.4659 287.001 63.9468 287.001L59.5824 287.001L59.5824 291L63.9468 291Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M44.6099 287C44.0907 287 43.6699 287.421 43.6699 287.94L43.6699 290.059C43.6699 290.578 44.0907 290.999 44.6099 290.999L48.9742 290.999L48.9742 287L44.6099 287Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.84048"
          transform="matrix(1.26506e-08 -1 -1 -1.12408e-08 58.6982 291)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#4-2_filter2_i)">
          <rect
            x="43.47"
            y="213.47"
            width="362.06"
            height="62.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <g filter="url(#4-2_filter3_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 202H32V287H417V202ZM406 213H43V276H406V213Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 202H32V287H33V203H416V287H417V202Z"
          fill="#FBFBFB"
        />
        <rect
          x="36.53"
          y="206.53"
          width="375.94"
          height="75.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M47.0332 214.5L224 274.472L401.951 214.5H47.0332Z" stroke="white" />
        <rect
          x="237.528"
          y="204"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 237.528 204)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#4-2_filter4_d)">
          <rect
            x="236.884"
            y="204.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 236.884 204.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M236.767 206.695C236.42 205.542 235.351 204.702 234.085 204.702L227.445 204.702C225.898 204.702 224.645 205.956 224.645 207.502C224.645 207.537 224.645 207.572 224.647 207.607C224.993 208.76 226.063 209.6 227.329 209.6L233.969 209.6C235.515 209.6 236.769 208.346 236.769 206.8C236.769 206.765 236.768 206.73 236.767 206.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M235.409 205.035C235.266 205.012 235.118 205 234.968 205L228.328 205C226.782 205 225.528 206.254 225.528 207.8C225.528 208.867 226.125 209.795 227.003 210.267C225.666 210.056 224.644 208.898 224.644 207.502C224.644 205.956 225.898 204.702 227.444 204.702L234.084 204.702C234.564 204.702 235.015 204.822 235.409 205.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-2_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M250.528 207.5C250.528 206.672 249.857 206 249.028 206L248.814 206L247.528 206L229.028 206C228.2 206 227.528 206.672 227.528 207.5C227.528 208.328 228.2 209 229.028 209L245.528 209L249.028 209L249.243 209C249.953 209 250.528 208.424 250.528 207.714C250.528 207.677 250.527 207.64 250.525 207.604C250.527 207.569 250.528 207.535 250.528 207.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="4-2_filter0_d"
            x="383.12"
            y="285.12"
            width="26.8568"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-2_filter1_d"
            x="41.79"
            y="285.12"
            width="26.8568"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-2_filter2_i"
            x="43"
            y="213"
            width="363.94"
            height="65.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-2_filter3_d"
            x="27.3003"
            y="197.3"
            width="396.279"
            height="96.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-2_filter4_d"
            x="222.764"
            y="202.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-2_filter5_d"
            x="226.649"
            y="204.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="387.652"
            y1="293.8"
            x2="387.652"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="403.565"
            y1="284.202"
            x2="403.565"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="387.652"
            y1="293.798"
            x2="387.652"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.42024"
            x2="-0.799791"
            y2="4.42024"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="46.3217"
            y1="293.8"
            x2="46.3217"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="62.2346"
            y1="284.202"
            x2="62.2346"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="46.3221"
            y1="293.798"
            x2="46.3221"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.42024"
            x2="-0.799791"
            y2="4.42024"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
