import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement16Square8Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#1-4_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M125 46.9995C125 45.8952 124.105 45 123.001 45C121.896 45 121.001 45.8952 121.001 46.9995V49.1665H121.001V60H121V63.0005C121 64.1048 121.895 65 122.999 65C124.104 65 124.999 64.1048 124.999 63.0005V60.8332H125V50H125V46.9995Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 125 49.1665)"
          fill="#ABABAB"
        />
        <path
          d="M125 45.9399C125 45.4208 124.579 45 124.06 45H121.941C121.422 45 121.001 45.4208 121.001 45.9399V50H125V45.9399Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M121 64.0601C121 64.5792 121.421 65 121.94 65H124.059C124.578 65 124.999 64.5792 124.999 64.0601V60H121V64.0601Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 125 50.8335)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#1-4_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M121 123C121 121.895 121.895 121 123 121C124.105 121 125 121.895 125 123V137.56H121V123ZM121 137.56H125V139.4H125.06C125.579 139.4 126 139.821 126 140.34V142.12C126 143.158 125.158 144 124.12 144H122.88C121.842 144 121 143.158 121 142.12V139.4V139.4V137.56Z"
            fill="white"
          />
        </g>
        <rect x="121" y="138.25" width="3.99998" height="0.95833" fill="#ABABAB" />
        <path
          d="M121 121.94C121 121.421 121.421 121 121.94 121H124.06C124.579 121 125 121.421 125 121.94V138.25H121V121.94Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M121 143.06C121 143.579 121.421 144 121.94 144H125.06C125.579 144 126 143.579 126 143.06V140.148C126 139.629 125.579 139.208 125.06 139.208H121V143.06Z"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#1-4_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M121 34H32V155H121V34ZM110 45H43V144H110V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M121 34H32V155H33.2192V35H119.781V155H121V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-4_filter3_i)">
          <rect
            x="43.47"
            y="45.47"
            width="66.0601"
            height="98.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="38.53"
          width="79.9399"
          height="111.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect x="34" y="87" width="7" height="13.5283" rx="3.5" fill="#9B9DA2" fillOpacity="0.19" />
        <g filter="url(#1-4_filter4_d)">
          <rect x="34.7018" y="87.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.286 98.7956C35.7981 99.4577 36.6004 99.8841 37.5022 99.8841C39.0486 99.8841 40.3022 98.6305 40.3022 97.0841V90.4442C40.3022 89.4557 39.79 88.5869 39.0166 88.0886C39.3826 88.5619 39.6004 89.1556 39.6004 89.8001V96.44C39.6004 97.9864 38.3468 99.24 36.8004 99.24C36.2424 99.24 35.7226 99.0768 35.286 98.7956Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.5018 87.644C35.9554 87.644 34.7018 88.8976 34.7018 90.444V96.4397C34.7018 96.4601 34.702 96.4805 34.7024 96.5007V91.0236C34.7024 89.4772 35.956 88.2236 37.5024 88.2236C39.0285 88.2236 40.2693 89.4444 40.3018 90.9627V90.444C40.3018 88.8976 39.0482 87.644 37.5018 87.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-4_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.5 91C36.6716 91 36 91.6716 36 92.5V92.7143V94V112.5C36 113.328 36.6716 114 37.5 114V114C38.3284 114 39 113.328 39 112.5V96V92.5L39 92.2857C39 91.5756 38.4244 91 37.7143 91V91C37.6771 91 37.6401 91.0012 37.6035 91.0035C37.5693 91.0012 37.5348 91 37.5 91Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M108.5 46.9969L44.836 94.5L108.5 142.003L108.5 46.9969Z" stroke="white" />
        <path d="M44.6948 142.5L76.5 47.5739L108.305 142.5L44.6948 142.5Z" stroke="white" />
        <defs>
          <filter
            id="1-4_filter0_d"
            x="119.12"
            y="43.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-4_filter1_d"
            x="119.12"
            y="119.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-4_filter2_d"
            x="27.3003"
            y="29.3003"
            width="100.279"
            height="132.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-4_filter3_i"
            x="43"
            y="45"
            width="67.9399"
            height="101.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="1-4_filter4_d"
            x="32.8219"
            y="85.7642"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-4_filter5_d"
            x="34.1201"
            y="89.1201"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="127.8"
            y1="62.5"
            x2="120.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="118.202"
            y1="47.5"
            x2="125.8"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="127.798"
            y1="62.5"
            x2="120.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="127.8"
            y1="129.625"
            x2="120.2"
            y2="129.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="129.5"
            y1="141.604"
            x2="120"
            y2="141.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
