import styled from 'styled-components'
import { GalleryWrapper } from '../gallery'
import { RadiobuttonWrapper } from '../radiobutton'
import { ComponentShadowWrapper } from '../component-shadow'

export const BadgeWrapper = styled.div`
  margin-top: 6px;
  margin-bottom: 6px;

  position: relative;

  & + ${GalleryWrapper} {
    margin-top: 14px;
  }

  & + ${RadiobuttonWrapper} {
    margin-top: 14px;

    ${ComponentShadowWrapper} {
      top: -14px;
    }
  }
`

export const BadgeContent = styled.div`
  background-color: rgba(224, 227, 233, 0.6);
  border-radius: 20px;

  display: inline-block;

  position: relative;

  padding: 7px 16px 7px 16px;

  line-height: 0;
`

export const BadgeText = styled.span`
  color: #1c3775;
  font-size: 13px;
  line-height: 140%;

  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
`

export const BadgeClose = styled.div`
  position: absolute;

  width: 26px;
  height: 26px;

  top: 3px;
  right: 10px;

  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;

    pointer-events: none;
  }
`
