import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'

import { ICheckboxItem, ICheckboxProps } from './interface'
import { CheckboxWrapper, CheckboxBlock, CheckboxInput, CheckboxLabel } from './styles'
import { ComponentShadow } from '../component-shadow'

export const Checkbox: FC<ICheckboxProps> = props => {
  const { id, items, onSelect = () => null, selected, disabled = false, show = true } = props
  const [checked, setChecked] = useState<Record<string, boolean>>({})
  const [values, setValues] = useState<Record<string, string>>({})

  const handleCheck = useCallback(
    (item: ICheckboxItem) => (event: ChangeEvent<HTMLInputElement>) => {
      const value = event?.target?.value
      const check = event?.target?.checked

      setChecked(prevState => ({
        ...prevState,
        [item.name]: check,
      }))

      setValues(prevState => ({
        ...prevState,
        [item.name]: value,
      }))

      onSelect({
        ...item,
        value: check,
        attributes: check === item.isEqually ? item.attributes : [],
      })
    },
    [onSelect],
  )

  useEffect(() => {
    if (selected) {
      if (typeof selected['value'] !== 'undefined') {
        setChecked({
          [selected['name']]: selected['value'],
        })

        setValues({
          [selected['name']]: selected['value'],
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  const parsedItems = useMemo(() => {
    const result: ICheckboxItem[] = []
    const names = {}

    if (items.length > 0) {
      items.forEach(item => {
        if (!names[item.name]) {
          names[item.name] = true
          result.push(item)
        }
      })
    }

    return result
  }, [items])

  return (
    <>
      {show && (
        <CheckboxWrapper>
          {disabled && <ComponentShadow />}
          {parsedItems?.map(item => (
            <CheckboxBlock key={`${id}-${item.name}`}>
              <CheckboxLabel htmlFor={`${id}-${item.name}`}>
                <CheckboxInput
                  type="checkbox"
                  id={`${id}-${item.name}`}
                  name={item.name}
                  value={values[item.name]}
                  checked={checked[item.name] || false}
                  onChange={handleCheck(item)}
                  disabled={disabled}
                />
                <span>{item.title}</span>
              </CheckboxLabel>
            </CheckboxBlock>
          ))}
        </CheckboxWrapper>
      )}
    </>
  )
}
