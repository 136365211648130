import React from 'react'

import {
  Casement11Square3Casement,
  Casement12Square3Casement,
  Casement13Square3Casement,
  Casement14Square3Casement,
  Casement22Square3Casement,
  Casement23Square3Casement,
  Casement25Square3Casement,
  Casement27Square3Casement,
  Casement31Square3Casement,
  Casement32Square3Casement,
  Casement33Square3Casement,
  Casement34Square3Casement,
  Casement41Square3Casement,
  Casement42Square3Casement,
  Casement43Square3Casement,
  Casement44Square3Casement,
  Casement51Square3Casement,
  Casement52Square3Casement,
  Casement53Square3Casement,
  Casement54Square3Casement,
  Casement61Square3Casement,
  Casement62Square3Casement,
  Casement63Square3Casement,
  Casement64Square3Casement,
  Casement71Square3Casement,
  Casement72Square3Casement,
  Casement73Square3Casement,
  Casement74Square3Casement,
  Casement81Square3Casement,
  Casement82Square3Casement,
  Casement83Square3Casement,
  Casement84Square3Casement,
  Casement91Square3Casement,
  Casement92Square3Casement,
  Casement93Square3Casement,
  Casement94Square3Casement,
  ContainerSquare3Casement,
} from '../../images/square-3-casement'
import {
  ContainerSquare4Casement,
  Casement13Square4Casement,
  Casement21Square4Casement,
  Casement33Square4Casement,
  Casement34Square4Casement,
  Casement42Square4Casement,
  Casement43Square4Casement,
} from '../../images/square-4-casement'
import {
  Casement11Square2Casement,
  Casement12Square2Casement,
  Casement13Square2Casement,
  Casement14Square2Casement,
  Casement21Square2Casement,
  Casement22Square2Casement,
  Casement23Square2Casement,
  Casement24Square2Casement,
  ContainerSquare2Casement,
} from '../../images/square-2-casement'
import {
  Casement11Square4TopCasement,
  Casement12Square4TopCasement,
  Casement21Square4TopCasement,
  Casement23Square4TopCasement,
  Casement31Square4TopCasement,
  Casement32Square4TopCasement,
  Casement34Square4TopCasement,
  Casement41Square4TopCasement,
  Casement43Square4TopCasement,
  ContainerSquare4TopCasement,
} from '../../images/square-4-top-casement'
import { Casement35Square4TopCasement } from '../../images/square-4-top-casement/casement-3-5-square-4-top-casement'
import {
  Casement11Square4BottomCasement,
  Casement12Square4BottomCasement,
  Casement13Square4BottomCasement,
  Casement14Square4BottomCasement,
  Casement21Square4BottomCasement,
  Casement22Square4BottomCasement,
  Casement23Square4BottomCasement,
  Casement24Square4BottomCasement,
  Casement25Square4BottomCasement,
  Casement26Square4BottomCasement,
  Casement31Square4BottomCasement,
  Casement32Square4BottomCasement,
  Casement33Square4BottomCasement,
  Casement34Square4BottomCasement,
  Casement41Square4BottomCasement,
  Casement42Square4BottomCasement,
  ContainerSquare4BottomCasement,
} from '../../images/square-4-bottom-casement'
import {
  Casement11Square32Casement,
  Casement12Square32Casement,
  Casement13Square32Casement,
  Casement14Square32Casement,
  Casement21Square32Casement,
  Casement22Square32Casement,
  Casement23Square32Casement,
  Casement24Square32Casement,
  Casement31Square32Casement,
  Casement32Square32Casement,
  Casement33Square32Casement,
  Casement34Square32Casement,
  ContainerSquare32Casement,
} from '../../images/square-3-2-casement'
import { Casement25Square32Casement } from '../../images/square-3-2-casement/casement-2-5-square-3-2-casement'
import { Casement26Square32Casement } from '../../images/square-3-2-casement/casement-2-6-square-3-2-casement'
import {
  Casement14Square6Casement,
  Casement24Square6Casement,
  Casement34Square6Casement,
  Casement43Square6Casement,
  Casement44Square6Casement,
  Casement53Square6Casement,
  Casement54Square6Casement,
  Casement55Square6Casement,
  Casement56Square6Casement,
  Casement63Square6Casement,
  Casement64Square6Casement,
  ContainerSquare6Casement,
} from '../../images/square-6-casement'
import {
  Casement11Square5Casement,
  Casement12Square5Casement,
  Casement13Square5Casement,
  Casement14Square5Casement,
  Casement21Square5Casement,
  Casement22Square5Casement,
  Casement23Square5Casement,
  Casement24Square5Casement,
  Casement25Square5Casement,
  Casement26Square5Casement,
  Casement31Square5Casement,
  Casement32Square5Casement,
  Casement33Square5Casement,
  Casement34Square5Casement,
  Casement41Square5Casement,
  Casement42Square5Casement,
  Casement43Square5Casement,
  Casement44Square5Casement,
  Casement51Square5Casement,
  Casement52Square5Casement,
  Casement53Square5Casement,
  Casement54Square5Casement,
  ContainerSquare5Casement,
} from '../../images/square-5-casement'
import {
  Casement11Square52Casement,
  Casement12Square52Casement,
  Casement13Square52Casement,
  Casement14Square52Casement,
  Casement21Square52Casement,
  Casement22Square52Casement,
  Casement31Square52Casement,
  Casement32Square52Casement,
  Casement33Square52Casement,
  Casement34Square52Casement,
  Casement41Square52Casement,
  Casement42Square52Casement,
  Casement51Square52Casement,
  Casement52Square52Casement,
  ContainerSquare52Casement,
} from '../../images/square-5-2-casement'
import {
  Casement11Square53Casement,
  Casement12Square53Casement,
  Casement21Square53Casement,
  Casement22Square53Casement,
  Casement31Square53Casement,
  Casement32Square53Casement,
  Casement33Square53Casement,
  Casement34Square53Casement,
  Casement41Square53Casement,
  Casement42Square53Casement,
  Casement51Square53Casement,
  Casement52Square53Casement,
  ContainerSquare53Casement,
} from '../../images/square-5-3-casement'
import {
  Casement22LoggiaR4Casement,
  Casement21LoggiaR4Casement,
  ContainerLoggiaR4Casement,
} from '../../images/loggia/loggia-r-4-casements'
import {
  Casement21Loggia10Casement,
  Casement22Loggia10Casement,
  ContainerLoggia10Casement,
} from '../../images/loggia/loggia-10-casements'
import {
  Casement11Square4TopBottomCasement,
  Casement12Square4TopBottomCasement,
  Casement21Square4TopBottomCasement,
  Casement22Square4TopBottomCasement,
  Casement23Square4TopBottomCasement,
  Casement24Square4TopBottomCasement,
  Casement31Square4TopBottomCasement,
  Casement32Square4TopBottomCasement,
  Casement33Square4TopBottomCasement,
  Casement34Square4TopBottomCasement,
  Casement41Square4TopBottomCasement,
  Casement42Square4TopBottomCasement,
  ContainerSquare4TopBottomCasement,
} from '../../images/square-4-top-bottom-casement'
import {
  Casement11Square41Casement,
  Casement12Square41Casement,
  Casement13Square41Casement,
  Casement14Square41Casement,
  Casement15Square41Casement,
  Casement16Square41Casement,
  Casement21Square41Casement,
  Casement22Square41Casement,
  Casement23Square41Casement,
  Casement24Square41Casement,
  Casement25Square41Casement,
  Casement26Square41Casement,
  Casement31Square41Casement,
  Casement32Square41Casement,
  Casement33Square41Casement,
  Casement34Square41Casement,
  Casement35Square41Casement,
  Casement36Square41Casement,
  Casement41Square41Casement,
  Casement42Square41Casement,
  Casement43Square41Casement,
  Casement44Square41Casement,
  Casement45Square41Casement,
  Casement46Square41Casement,
  ContainerSquare41Casement,
} from '../../images/square-4-1-casement'
import {
  Casement11Square8Casement,
  Casement12Square8Casement,
  Casement13Square8Casement,
  Casement14Square8Casement,
  Casement15Square8Casement,
  Casement16Square8Casement,
  Casement21Square8Casement,
  Casement22Square8Casement,
  Casement23Square8Casement,
  Casement24Square8Casement,
  Casement25Square8Casement,
  Casement26Square8Casement,
  Casement31Square8Casement,
  Casement32Square8Casement,
  Casement33Square8Casement,
  Casement34Square8Casement,
  Casement35Square8Casement,
  Casement36Square8Casement,
  Casement41Square8Casement,
  Casement42Square8Casement,
  Casement43Square8Casement,
  Casement44Square8Casement,
  Casement45Square8Casement,
  Casement46Square8Casement,
  Casement51Square8Casement,
  Casement52Square8Casement,
  Casement53Square8Casement,
  Casement54Square8Casement,
  Casement55Square8Casement,
  Casement56Square8Casement,
  Casement61Square8Casement,
  Casement62Square8Casement,
  Casement63Square8Casement,
  Casement64Square8Casement,
  Casement65Square8Casement,
  Casement66Square8Casement,
  Casement71Square8Casement,
  Casement72Square8Casement,
  Casement73Square8Casement,
  Casement74Square8Casement,
  Casement75Square8Casement,
  Casement76Square8Casement,
  Casement81Square8Casement,
  Casement82Square8Casement,
  Casement83Square8Casement,
  Casement84Square8Casement,
  Casement85Square8Casement,
  Casement86Square8Casement,
  ContainerSquare8Casement,
} from '../../images/square-8-casement'
import {
  Casement11Square54Casement,
  Casement12Square54Casement,
  Casement13Square54Casement,
  Casement14Square54Casement,
  Casement21Square54Casement,
  Casement22Square54Casement,
  Casement23Square54Casement,
  Casement24Square54Casement,
  Casement31Square54Casement,
  Casement32Square54Casement,
  Casement33Square54Casement,
  Casement34Square54Casement,
  Casement41Square54Casement,
  Casement42Square54Casement,
  Casement43Square54Casement,
  Casement44Square54Casement,
  Casement51Square54Casement,
  Casement52Square54Casement,
  Casement53Square54Casement,
  Casement54Square54Casement,
  ContainerSquare54Casement,
} from '../../images/square-5-4-casement'
import {
  Casement11Square1Casement,
  Casement12Square1Casement,
  Casement13Square1Casement,
  Casement14Square1Casement,
  Casement15Square1Casement,
  Casement16Square1Casement,
  ContainerSquare1Casement,
} from '../../images/square-1-casement'
import {
  Casement11Square4VerticalCasement,
  Casement12Square4VerticalCasement,
  Casement13Square4VerticalCasement,
  Casement14Square4VerticalCasement,
  Casement15Square4VerticalCasement,
  Casement16Square4VerticalCasement,
  Casement21Square4VerticalCasement,
  Casement22Square4VerticalCasement,
  Casement23Square4VerticalCasement,
  Casement24Square4VerticalCasement,
  Casement25Square4VerticalCasement,
  Casement26Square4VerticalCasement,
  Casement31Square4VerticalCasement,
  Casement32Square4VerticalCasement,
  Casement33Square4VerticalCasement,
  Casement34Square4VerticalCasement,
  Casement35Square4VerticalCasement,
  Casement36Square4VerticalCasement,
  Casement41Square4VerticalCasement,
  Casement42Square4VerticalCasement,
  Casement43Square4VerticalCasement,
  Casement44Square4VerticalCasement,
  Casement45Square4VerticalCasement,
  Casement46Square4VerticalCasement,
  ContainerSquare4VerticalCasement,
} from '../../images/square-4-vertical-casement'
import {
  Casement11Square5VerticalCasement,
  Casement12Square5VerticalCasement,
  Casement21Square5VerticalCasement,
  Casement22Square5VerticalCasement,
  Casement31Square5VerticalCasement,
  Casement32Square5VerticalCasement,
  Casement41Square5VerticalCasement,
  Casement42Square5VerticalCasement,
  Casement51Square5VerticalCasement,
  Casement52Square5VerticalCasement,
  ContainerSquare5VerticalCasement,
} from '../../images/square-5-vertical-casement'
import {
  Casement14Square62Casement,
  Casement11Square62Casement,
  Casement12Square62Casement,
  Casement13Square62Casement,
  Casement21Square62Casement,
  Casement22Square62Casement,
  Casement24Square62Casement,
  Casement31Square62Casement,
  Casement32Square62Casement,
  Casement33Square62Casement,
  Casement34Square62Casement,
  Casement42Square62Casement,
  Casement43Square62Casement,
  Casement44Square62Casement,
  Casement41Square62Casement,
  Casement52Square62Casement,
  Casement54Square62Casement,
  Casement62Square62Casement,
  Casement64Square62Casement,
  ContainerSquare62Casement,
} from '../../images/square-6-2-casement'
import {
  Casement11Square3VerticalCasement,
  Casement12Square3VerticalCasement,
  Casement13Square3VerticalCasement,
  Casement14Square3VerticalCasement,
  Casement15Square3VerticalCasement,
  Casement16Square3VerticalCasement,
  Casement21Square3VerticalCasement,
  Casement22Square3VerticalCasement,
  Casement23Square3VerticalCasement,
  Casement24Square3VerticalCasement,
  Casement25Square3VerticalCasement,
  Casement26Square3VerticalCasement,
  Casement31Square3VerticalCasement,
  Casement32Square3VerticalCasement,
  Casement33Square3VerticalCasement,
  Casement34Square3VerticalCasement,
  Casement35Square3VerticalCasement,
  Casement36Square3VerticalCasement,
  ContainerSquare3VerticalCasement,
} from '../../images/square-3-vertical-casement'
import {
  Casement11Square82Casement,
  Casement12Square82Casement,
  Casement13Square82Casement,
  Casement14Square82Casement,
  Casement21Square82Casement,
  Casement22Square82Casement,
  Casement23Square82Casement,
  Casement24Square82Casement,
  Casement31Square82Casement,
  Casement32Square82Casement,
  Casement33Square82Casement,
  Casement34Square82Casement,
  Casement41Square82Casement,
  Casement42Square82Casement,
  Casement43Square82Casement,
  Casement44Square82Casement,
  Casement51Square82Casement,
  Casement52Square82Casement,
  Casement53Square82Casement,
  Casement54Square82Casement,
  Casement61Square82Casement,
  Casement62Square82Casement,
  Casement63Square82Casement,
  Casement64Square82Casement,
  Casement71Square82Casement,
  Casement72Square82Casement,
  Casement73Square82Casement,
  Casement74Square82Casement,
  Casement81Square82Casement,
  Casement82Square82Casement,
  Casement83Square82Casement,
  Casement84Square82Casement,
  ContainerSquare82Casement,
} from '../../images/square-8-2-casement'

import {
  Casement1Door1RCasement1,
  Casement2Door1RCasement1,
  Casement3Door1RCasement1,
  Casement4Door1RCasement1,
  Casement5Door1RCasement1,
  Casement6Door1RCasement1,
  Casement7Door1RCasement1,
  Casement8Door1RCasement1,
  ContainerDoor1RCasement1,
} from '../../images/door-1-r-casement-1'

import {
  Casement1Door1RCasement2,
  Casement2Door1RCasement2,
  Casement3Door1RCasement2,
  Casement4Door1RCasement2,
  Casement5Door1RCasement2,
  Casement6Door1RCasement2,
  Casement7Door1RCasement2,
  Casement8Door1RCasement2,
  ContainerDoor1RCasement2,
} from '../../images/door-1-r-casement-2'

import {
  Casement1Door1LCasement1,
  Casement2Door1LCasement1,
  Casement3Door1LCasement1,
  Casement4Door1LCasement1,
  Casement5Door1LCasement1,
  Casement6Door1LCasement1,
  Casement7Door1LCasement1,
  Casement8Door1LCasement1,
  ContainerDoor1LCasement1,
} from '../../images/door-1-l-casement-1'

import {
  Casement1Door1LCasement2,
  Casement2Door1LCasement2,
  Casement3Door1LCasement2,
  Casement4Door1LCasement2,
  Casement5Door1LCasement2,
  Casement6Door1LCasement2,
  Casement7Door1LCasement2,
  Casement8Door1LCasement2,
  ContainerDoor1LCasement2,
} from '../../images/door-1-l-casement-2'

export const getImagesObject = (handleFilterChange, type, withoutBackground = false) => {
  /*
    Здесь можно настроить створки (формуга) и рамы
  */

  const imagesList = {
    window: {
      // Окно только из одной створки
      square1Casement: {
        container: (
          <ContainerSquare1Casement
            key="square1Casement-container"
            onCasementClick={handleFilterChange('square1Casement/container')}
            withoutBackground={withoutBackground}
          />
        ),
         'casement-1-1': <Casement11Square1Casement key="square1Casement-casement-1-1" />,
         'casement-1-2': <Casement12Square1Casement key="square1Casement-casement-1-2" />,
         'casement-1-3': <Casement13Square1Casement key="square1Casement-casement-1-3" />,
         'casement-1-4': <Casement14Square1Casement key="square1Casement-casement-1-4" />,
        // 'casement-1-5': <Casement15Square1Casement key="square1Casement-casement-1-5" />,
        // 'casement-1-5': <Casement16Square1Casement key="square1Casement-casement-1-6" />,
      },
      // Окно только из двух створок по горизантале
      square2Casement: {
        container: (
          <ContainerSquare2Casement
            key="square2Casement-container"
            onCasementClick={handleFilterChange('square2Casement/container')}
            withoutBackground={withoutBackground}
          />
        ),
         'casement-1-1': <Casement13Square2Casement key="square2Casement-casement-1-1" />,
         'casement-1-2': <Casement12Square2Casement key="square2Casement-casement-1-2" />,
         // 'casement-1-3': <Casement11Square2Casement key="square2Casement-casement-1-3" />,
         // 'casement-1-4': <Casement14Square2Casement key="square2Casement-casement-1-4" />,
         'casement-2-1': <Casement23Square2Casement key="square2Casement-casement-2-1" />,
         'casement-2-2': <Casement22Square2Casement key="square2Casement-casement-2-2" />,
         // 'casement-2-3': <Casement21Square2Casement key="square2Casement-casement-2-3" />,
         // 'casement-2-3': <Casement24Square2Casement key="square2Casement-casement-2-4" />,
      },
      // Окно из трех створок по горезантале
      square32Casement: {
        container: (
          <ContainerSquare32Casement
            key="square32Casement-container"
            onCasementClick={handleFilterChange('square32Casement/container')}
            withoutBackground={withoutBackground}
          />
        ),
         'casement-1-1': <Casement14Square32Casement key="square32Casement-casement-1-1" />,
         'casement-1-2': <Casement11Square32Casement key="square32Casement-casement-1-2" />,
        // 'casement-1-3': <Casement12Square32Casement key="square32Casement-casement-1-3" />,
        // 'casement-1-3': <Casement13Square32Casement key="square32Casement-casement-1-4" />,
         'casement-2-1': <Casement26Square32Casement key="square32Casement-casement-2-1" />,
         'casement-2-2': <Casement21Square32Casement key="square32Casement-casement-2-2" />,
         'casement-2-3': <Casement22Square32Casement key="square32Casement-casement-2-3" />,
         'casement-2-4': <Casement23Square32Casement key="square32Casement-casement-2-4" />,
        // 'casement-2-5': <Casement24Square32Casement key="square32Casement-casement-2-5" />,
        // 'casement-2-5': <Casement25Square32Casement key="square32Casement-casement-2-6" />,
         'casement-3-1': <Casement31Square32Casement key="square32Casement-casement-3-1" />,
         'casement-3-2': <Casement34Square32Casement key="square32Casement-casement-3-2" />,
        // 'casement-3-3': <Casement32Square32Casement key="square32Casement-casement-3-3" />,
        // 'casement-3-4': <Casement33Square32Casement key="square32Casement-casement-3-4" />,
      },
      square3Casement: {
        container: (
          <ContainerSquare3Casement
            key="square3Casement-container"
            onCasementClick={handleFilterChange('square3Casement/container')}
            withoutBackground={withoutBackground}
          />
        ),
        'casement-1-1': <Casement11Square3Casement key="square32Casement-casement-1-1" />,
        'casement-1-2': <Casement12Square3Casement key="square32Casement-casement-1-2" />,
        'casement-1-3': <Casement13Square3Casement key="square32Casement-casement-1-3" />,
        'casement-1-4': <Casement14Square3Casement key="square32Casement-casement-1-4" />,
        'casement-2-1': <Casement22Square3Casement key="square32Casement-casement-2-1" />,
        'casement-2-2': <Casement23Square3Casement key="square32Casement-casement-2-2" />,
        'casement-2-3': <Casement25Square3Casement key="square32Casement-casement-2-4" />,
        'casement-2-4': <Casement27Square3Casement key="square32Casement-casement-2-6" />,
        'casement-9-1': <Casement31Square3Casement key="square32Casement-casement-9-1" />,
        'casement-9-2': <Casement32Square3Casement key="square32Casement-casement-9-2" />,
        'casement-9-3': <Casement33Square3Casement key="square32Casement-casement-9-3" />,
        'casement-9-4': <Casement34Square3Casement key="square32Casement-casement-9-4" />,
        'casement-4-1': <Casement41Square3Casement key="square32Casement-casement-4-1" />,
        'casement-4-2': <Casement42Square3Casement key="square32Casement-casement-4-2" />,
        'casement-4-3': <Casement43Square3Casement key="square32Casement-casement-4-3" />,
        'casement-4-4': <Casement44Square3Casement key="square32Casement-casement-4-4" />,
        'casement-5-1': <Casement51Square3Casement key="square32Casement-casement-5-1" />,
        'casement-5-2': <Casement52Square3Casement key="square32Casement-casement-5-2" />,
        'casement-5-3': <Casement53Square3Casement key="square32Casement-casement-5-3" />,
        'casement-5-4': <Casement54Square3Casement key="square32Casement-casement-5-4" />,
        'casement-6-1': <Casement61Square3Casement key="square32Casement-casement-6-1" />,
        'casement-6-2': <Casement62Square3Casement key="square32Casement-casement-6-2" />,
        'casement-6-3': <Casement63Square3Casement key="square32Casement-casement-6-3" />,
        'casement-6-4': <Casement64Square3Casement key="square32Casement-casement-6-4" />,
        'casement-7-1': <Casement71Square3Casement key="square32Casement-casement-7-1" />,
        'casement-7-2': <Casement72Square3Casement key="square32Casement-casement-7-2" />,
        'casement-7-3': <Casement73Square3Casement key="square32Casement-casement-7-3" />,
        'casement-7-4': <Casement74Square3Casement key="square32Casement-casement-7-4" />,
        'casement-8-1': <Casement81Square3Casement key="square32Casement-casement-8-1" />,
        'casement-8-2': <Casement82Square3Casement key="square32Casement-casement-8-2" />,
        'casement-8-3': <Casement83Square3Casement key="square32Casement-casement-8-3" />,
        'casement-8-4': <Casement84Square3Casement key="square32Casement-casement-8-4" />,
        'casement-3-1': <Casement91Square3Casement key="square32Casement-casement-3-1" />,
        'casement-3-2': <Casement92Square3Casement key="square32Casement-casement-3-2" />,
        'casement-3-3': <Casement93Square3Casement key="square32Casement-casement-3-3" />,
        'casement-3-4': <Casement94Square3Casement key="square32Casement-casement-3-4" />,
      },
      // Окно, две створкги горизанатально сверху, 2 створки горизонтально снизу
      square4Casement: {
        container: (
          <ContainerSquare4Casement
            key="square4Casement-container"
            onCasementClick={handleFilterChange('square4Casement/container')}
            withoutBackground={withoutBackground}
          />
        ),
        'casement-1-1': <Casement13Square4Casement key="square4Casement-casement-1-1" />,
        'casement-2-1': <Casement21Square4Casement key="square4Casement-casement-2-1" />,
        'casement-3-1': <Casement34Square4Casement key="square4Casement-casement-3-1" />,
        'casement-3-2': <Casement33Square4Casement key="square4Casement-casement-3-2" />,
        'casement-4-1': <Casement42Square4Casement key="square4Casement-casement-4-1" />,
        'casement-4-2': <Casement43Square4Casement key="square4Casement-casement-4-2" />,
      },
      // Окно, одна створка сверху, три створки горизантально снизу
      square4TopCasement: {
        container: (
          <ContainerSquare4TopCasement
            key="square4TopCasement-container"
            onCasementClick={handleFilterChange('square4TopCasement/container')}
            withoutBackground={withoutBackground}
          />
        ),
        // 'casement-1-1': <Casement11Square4TopCasement key="square4TopCasement-casement-1-1" />,
        'casement-1-1': <Casement12Square4TopCasement key="square4TopCasement-casement-1-1" />,
        'casement-2-1': <Casement21Square4TopCasement key="square4TopCasement-casement-2-1" />,
        'casement-2-2': <Casement23Square4TopCasement key="square4TopCasement-casement-2-3" />,
        'casement-3-1': <Casement31Square4TopCasement key="square4TopCasement-casement-3-1" />,
        'casement-3-2': <Casement32Square4TopCasement key="square4TopCasement-casement-3-2" />,
        'casement-3-3': <Casement34Square4TopCasement key="square4TopCasement-casement-3-4" />,
        'casement-3-4': <Casement35Square4TopCasement key="square4TopCasement-casement-3-5" />,
        'casement-4-1': <Casement41Square4TopCasement key="square4TopCasement-casement-4-1" />,
        'casement-4-2': <Casement43Square4TopCasement key="square4TopCasement-casement-4-3" />,
      },
      square4BottomCasement: {
        container: (
          <ContainerSquare4BottomCasement
            key="square4BottomCasement-container"
            onCasementClick={handleFilterChange('square4BottomCasement/container')}
            withoutBackground={withoutBackground}
          />
        ),
        'casement-1-1': (
          <Casement11Square4BottomCasement key="square4BottomCasement-casement-1-1" />
        ),
        'casement-1-2': (
          <Casement12Square4BottomCasement key="square4BottomCasement-casement-1-2" />
        ),
        'casement-1-3': (
          <Casement13Square4BottomCasement key="square4BottomCasement-casement-1-1" />
        ),
        'casement-1-4': (
          <Casement14Square4BottomCasement key="square4BottomCasement-casement-1-2" />
        ),
        'casement-2-1': (
          <Casement21Square4BottomCasement key="square4BottomCasement-casement-2-1" />
        ),
        'casement-2-2': (
          <Casement22Square4BottomCasement key="square4BottomCasement-casement-2-2" />
        ),
        'casement-2-3': (
          <Casement23Square4BottomCasement key="square4BottomCasement-casement-2-3" />
        ),
        'casement-2-4': (
          <Casement24Square4BottomCasement key="square4BottomCasement-casement-2-4" />
        ),
        'casement-2-5': (
          <Casement25Square4BottomCasement key="square4BottomCasement-casement-2-5" />
        ),
        'casement-2-6': (
          <Casement26Square4BottomCasement key="square4BottomCasement-casement-2-6" />
        ),
        'casement-3-1': (
          <Casement31Square4BottomCasement key="square4BottomCasement-casement-3-1" />
        ),
        'casement-3-2': (
          <Casement32Square4BottomCasement key="square4BottomCasement-casement-3-2" />
        ),
        'casement-3-3': (
          <Casement33Square4BottomCasement key="square4BottomCasement-casement-3-3" />
        ),
        'casement-3-4': (
          <Casement34Square4BottomCasement key="square4BottomCasement-casement-3-4" />
        ),
        'casement-4-1': (
          <Casement41Square4BottomCasement key="square4BottomCasement-casement-4-1" />
        ),
        'casement-4-2': (
          <Casement42Square4BottomCasement key="square4BottomCasement-casement-4-2" />
        ),
      },
      square5Casement: {
        container: (
          <ContainerSquare5Casement
            key="square5TopCasement-container"
            onCasementClick={handleFilterChange('square5Casement/container')}
            withoutBackground={withoutBackground}
          />
        ),
        'casement-1-1': <Casement11Square5Casement key="square5Casement-casement-1-1" />,
        'casement-1-2': <Casement12Square5Casement key="square5Casement-casement-1-2" />,
        'casement-1-3': <Casement13Square5Casement key="square5Casement-casement-1-3" />,
        'casement-1-4': <Casement14Square5Casement key="square5Casement-casement-1-4" />,
        'casement-2-1': <Casement21Square5Casement key="square5Casement-casement-2-1" />,
        'casement-2-2': <Casement22Square5Casement key="square5Casement-casement-2-2" />,
        'casement-2-3': <Casement23Square5Casement key="square5Casement-casement-2-3" />,
        'casement-2-4': <Casement24Square5Casement key="square5Casement-casement-2-4" />,
        'casement-2-5': <Casement25Square5Casement key="square5Casement-casement-2-5" />,
        'casement-2-6': <Casement26Square5Casement key="square5Casement-casement-2-6" />,
        'casement-3-1': <Casement31Square5Casement key="square5Casement-casement-3-1" />,
        'casement-3-2': <Casement32Square5Casement key="square5Casement-casement-3-2" />,
        'casement-3-3': <Casement33Square5Casement key="square5Casement-casement-3-3" />,
        'casement-3-4': <Casement34Square5Casement key="square5Casement-casement-3-4" />,
        'casement-4-1': <Casement41Square5Casement key="square5Casement-casement-4-1" />,
        'casement-4-2': <Casement42Square5Casement key="square5Casement-casement-4-2" />,
        'casement-4-3': <Casement43Square5Casement key="square5Casement-casement-4-3" />,
        'casement-4-4': <Casement44Square5Casement key="square5Casement-casement-4-4" />,
        'casement-5-1': <Casement51Square5Casement key="square5Casement-casement-5-1" />,
        'casement-5-2': <Casement52Square5Casement key="square5Casement-casement-5-2" />,
        'casement-5-3': <Casement53Square5Casement key="square5Casement-casement-5-3" />,
        'casement-5-4': <Casement54Square5Casement key="square5Casement-casement-5-4" />,
      },
      square52Casement: {
        container: (
          <ContainerSquare52Casement
            key="square52Casement-container"
            onCasementClick={handleFilterChange('square52Casement/container')}
            withoutBackground={withoutBackground}
          />
        ),
        'casement-1-1': <Casement11Square52Casement key="square52Casement-casement-1-1" />,
        'casement-1-2': <Casement12Square52Casement key="square52Casement-casement-1-2" />,
        'casement-1-3': <Casement13Square52Casement key="square52Casement-casement-1-3" />,
        'casement-1-4': <Casement14Square52Casement key="square52Casement-casement-1-4" />,
        'casement-2-1': <Casement21Square52Casement key="square52Casement-casement-2-1" />,
        'casement-2-2': <Casement22Square52Casement key="square52Casement-casement-2-2" />,
        'casement-3-1': <Casement31Square52Casement key="square52Casement-casement-3-1" />,
        'casement-3-2': <Casement32Square52Casement key="square52Casement-casement-3-2" />,
        'casement-3-3': <Casement33Square52Casement key="square52Casement-casement-3-3" />,
        'casement-3-4': <Casement34Square52Casement key="square52Casement-casement-3-4" />,
        'casement-4-1': <Casement41Square52Casement key="square52Casement-casement-4-1" />,
        'casement-4-2': <Casement42Square52Casement key="square52Casement-casement-4-2" />,
        'casement-5-1': <Casement51Square52Casement key="square52Casement-casement-5-1" />,
        'casement-5-2': <Casement52Square52Casement key="square52Casement-casement-5-2" />,
      },
      square53Casement: {
        container: (
          <ContainerSquare53Casement
            key="square53Casement-container"
            onCasementClick={handleFilterChange('square53Casement/container')}
            withoutBackground={withoutBackground}
          />
        ),
        'casement-1-1': <Casement11Square53Casement key="square53Casement-casement-1-1" />,
        'casement-1-2': <Casement12Square53Casement key="square53Casement-casement-1-2" />,
        'casement-2-1': <Casement21Square53Casement key="square53Casement-casement-2-1" />,
        'casement-2-2': <Casement22Square53Casement key="square53Casement-casement-2-2" />,
        'casement-3-1': <Casement31Square53Casement key="square53Casement-casement-3-1" />,
        'casement-3-2': <Casement32Square53Casement key="square53Casement-casement-3-2" />,
        'casement-3-3': <Casement33Square53Casement key="square53Casement-casement-3-3" />,
        'casement-3-4': <Casement34Square53Casement key="square53Casement-casement-3-4" />,
        'casement-4-1': <Casement41Square53Casement key="square53Casement-casement-4-1" />,
        'casement-4-2': <Casement42Square53Casement key="square53Casement-casement-4-2" />,
        'casement-5-1': <Casement51Square53Casement key="square53Casement-casement-5-1" />,
        'casement-5-2': <Casement52Square53Casement key="square53Casement-casement-5-2" />,
      },
      // Три стоврки по горезонтале сверху, три створки по горезонтали снизу
      square6Casement: {
        container: (
          <ContainerSquare6Casement
            key="square6TopCasement-container"
            onCasementClick={handleFilterChange('square6Casement/container')}
            withoutBackground={withoutBackground}
          />
        ),
        'casement-1-1': <Casement14Square6Casement key="square6Casement-casement-1-4" />,
        'casement-2-1': <Casement24Square6Casement key="square6Casement-casement-2-4" />,
        'casement-3-1': <Casement34Square6Casement key="square6Casement-casement-3-4" />,
        'casement-4-1': <Casement43Square6Casement key="square6Casement-casement-4-3" />,
        'casement-4-2': <Casement44Square6Casement key="square6Casement-casement-4-4" />,
        'casement-5-1': <Casement53Square6Casement key="square6Casement-casement-5-3" />,
        'casement-5-2': <Casement54Square6Casement key="square6Casement-casement-5-4" />,
        'casement-5-3': <Casement55Square6Casement key="square6Casement-casement-5-5" />,
        'casement-5-4': <Casement56Square6Casement key="square6Casement-casement-5-6" />,
        'casement-6-1': <Casement63Square6Casement key="square6Casement-casement-6-3" />,
        'casement-6-2': <Casement64Square6Casement key="square6Casement-casement-6-4" />,
      },
      loggiaR4Casement: {
        container: (
          <ContainerLoggiaR4Casement
            key="LoggiaR4Casement-container"
            onCasementClick={handleFilterChange('loggiaR4Casement/container')}
            withoutBackground={withoutBackground}
          />
        ),
        'casement-2-1': <Casement21LoggiaR4Casement key="casement-2-1-loggia-r-6-Casement" />,
        'casement-2-2': <Casement22LoggiaR4Casement key="casement-2-2-loggia-r-6-Casement" />,
      },
      loggia10Casement: {
        container: (
          <ContainerLoggia10Casement
            key="Loggia10Casement-container"
            onCasementClick={handleFilterChange('loggia10Casement/container')}
            withoutBackground={withoutBackground}
          />
        ),
        'casement-2-1': <Casement21Loggia10Casement key="casement-2-1-loggia-10-Casement" />,
        'casement-2-2': <Casement22Loggia10Casement key="casement-2-2-loggia-10-Casement" />,
      },
      square4TopBottomCasement: {
        container: (
          <ContainerSquare4TopBottomCasement
            key="square4TopBottomCasement-container"
            onCasementClick={handleFilterChange('square4TopBottomCasement/container')}
            withoutBackground={withoutBackground}
          />
        ),
        'casement-1-1': (
          <Casement11Square4TopBottomCasement key="square4TopBottomCasement-casement-1-1" />
        ),
        'casement-1-2': (
          <Casement12Square4TopBottomCasement key="square4TopBottomCasement-casement-1-2" />
        ),
        'casement-2-1': (
          <Casement21Square4TopBottomCasement key="square4TopBottomCasement-casement-2-1" />
        ),
        'casement-2-2': (
          <Casement22Square4TopBottomCasement key="square4TopBottomCasement-casement-2-2" />
        ),
        'casement-2-3': (
          <Casement23Square4TopBottomCasement key="square4TopBottomCasement-casement-2-3" />
        ),
        'casement-2-4': (
          <Casement24Square4TopBottomCasement key="square4TopBottomCasement-casement-2-4" />
        ),
        'casement-3-1': (
          <Casement31Square4TopBottomCasement key="square4TopBottomCasement-casement-3-1" />
        ),
        'casement-3-2': (
          <Casement32Square4TopBottomCasement key="square4TopBottomCasement-casement-3-2" />
        ),
        'casement-3-3': (
          <Casement33Square4TopBottomCasement key="square4TopBottomCasement-casement-3-3" />
        ),
        'casement-3-4': (
          <Casement34Square4TopBottomCasement key="square4TopBottomCasement-casement-3-4" />
        ),
        'casement-4-1': (
          <Casement41Square4TopBottomCasement key="square4TopBottomCasement-casement-4-1" />
        ),
        'casement-4-2': (
          <Casement42Square4TopBottomCasement key="square4TopBottomCasement-casement-4-2" />
        ),
      },
      square41Casement: {
        container: (
          <ContainerSquare41Casement
            key="square41Casement-container"
            onCasementClick={handleFilterChange('square41Casement/container')}
            withoutBackground={withoutBackground}
          />
        ),
        'casement-1-1': <Casement11Square41Casement key="square41Casement-casement-1-1" />,
        'casement-1-2': <Casement12Square41Casement key="square41Casement-casement-1-2" />,
        'casement-1-3': <Casement13Square41Casement key="square41Casement-casement-1-3" />,
        'casement-1-4': <Casement14Square41Casement key="square41Casement-casement-1-4" />,
        'casement-1-5': <Casement15Square41Casement key="square41Casement-casement-1-5" />,
        'casement-1-6': <Casement16Square41Casement key="square41Casement-casement-1-6" />,
        'casement-2-1': <Casement21Square41Casement key="square41Casement-casement-2-1" />,
        'casement-2-2': <Casement22Square41Casement key="square41Casement-casement-2-2" />,
        'casement-2-3': <Casement23Square41Casement key="square41Casement-casement-2-3" />,
        'casement-2-4': <Casement24Square41Casement key="square41Casement-casement-2-4" />,
        'casement-2-5': <Casement25Square41Casement key="square41Casement-casement-2-5" />,
        'casement-2-6': <Casement26Square41Casement key="square41Casement-casement-2-6" />,
        'casement-3-1': <Casement31Square41Casement key="square41Casement-casement-3-1" />,
        'casement-3-2': <Casement32Square41Casement key="square41Casement-casement-3-2" />,
        'casement-3-3': <Casement33Square41Casement key="square41Casement-casement-3-3" />,
        'casement-3-4': <Casement34Square41Casement key="square41Casement-casement-3-4" />,
        'casement-3-5': <Casement35Square41Casement key="square41Casement-casement-3-5" />,
        'casement-3-6': <Casement36Square41Casement key="square41Casement-casement-3-6" />,
        'casement-4-1': <Casement41Square41Casement key="square41Casement-casement-4-1" />,
        'casement-4-2': <Casement42Square41Casement key="square41Casement-casement-4-2" />,
        'casement-4-3': <Casement43Square41Casement key="square41Casement-casement-4-3" />,
        'casement-4-4': <Casement44Square41Casement key="square41Casement-casement-4-4" />,
        'casement-4-5': <Casement45Square41Casement key="square41Casement-casement-4-5" />,
        'casement-4-6': <Casement46Square41Casement key="square41Casement-casement-4-6" />,
      },
      // Окно, 4 створки друг под другом
      square4VerticalCasement: {
        container: (
          <ContainerSquare4VerticalCasement
            key="square4VerticalCasement-container"
            onCasementClick={handleFilterChange('square4VerticalCasement/container')}
            withoutBackground={withoutBackground}
          />
        ),
        'casement-1-1': (
          <Casement11Square4VerticalCasement key="square4VerticalCasement-casement-1-1" />
        ),
        //  'casement-1-2': ( <Casement12Square4VerticalCasement key="square4VerticalCasement-casement-1-2" /> ),
        //  'casement-1-2': ( <Casement13Square4VerticalCasement key="square4VerticalCasement-casement-1-3" /> ),
        //  'casement-1-3': ( <Casement14Square4VerticalCasement key="square4VerticalCasement-casement-1-4" /> ),
        //  'casement-1-4': ( <Casement15Square4VerticalCasement key="square4VerticalCasement-casement-1-5" /> ),
        //  'casement-1-5': ( <Casement16Square4VerticalCasement key="square4VerticalCasement-casement-1-6" /> ),
        'casement-2-1': (
          <Casement21Square4VerticalCasement key="square4VerticalCasement-casement-2-1" />
        ),
        // 'casement-2-2': ( <Casement22Square4VerticalCasement key="square4VerticalCasement-casement-2-2" /> ),
        // 'casement-2-2': ( <Casement23Square4VerticalCasement key="square4VerticalCasement-casement-2-3" /> ),
        // 'casement-2-3': ( <Casement24Square4VerticalCasement key="square4VerticalCasement-casement-2-4" /> ),
        // 'casement-2-4': (  <Casement25Square4VerticalCasement key="square4VerticalCasement-casement-2-5" /> ),
        // 'casement-2-5': ( <Casement26Square4VerticalCasement key="square4VerticalCasement-casement-2-6" /> ),
        'casement-3-1': (
          <Casement31Square4VerticalCasement key="square4VerticalCasement-casement-3-1" />
        ),
        //  'casement-3-2': (  <Casement32Square4VerticalCasement key="square4VerticalCasement-casement-3-2" /> ),
        //  'casement-3-2': (  <Casement33Square4VerticalCasement key="square4VerticalCasement-casement-3-3" /> ),
        //  'casement-3-3': (  <Casement34Square4VerticalCasement key="square4VerticalCasement-casement-3-4" /> ),
        //  'casement-3-4': ( <Casement35Square4VerticalCasement key="square4VerticalCasement-casement-3-5" /> ),
        //  'casement-3-5': (  <Casement36Square4VerticalCasement key="square4VerticalCasement-casement-3-6" /> ),
        'casement-4-1': (
          <Casement41Square4VerticalCasement key="square4VerticalCasement-casement-4-1" />
        ),
        // 'casement-4-2': ( <Casement42Square4VerticalCasement key="square4VerticalCasement-casement-4-2" /> ),
        // 'casement-4-2': ( <Casement43Square4VerticalCasement key="square4VerticalCasement-casement-4-3" /> ),
        // 'casement-4-3': ( <Casement44Square4VerticalCasement key="square4VerticalCasement-casement-4-4" /> ),
        // 'casement-4-4': ( <Casement45Square4VerticalCasement key="square4VerticalCasement-casement-4-5" /> ),
        // 'casement-4-5': ( <Casement46Square4VerticalCasement key="square4VerticalCasement-casement-4-6" /> ),
      },
      square8Casement: {
        container: (
          <ContainerSquare8Casement
            key="square8Casement-container"
            onCasementClick={handleFilterChange('square8Casement/container')}
            withoutBackground={withoutBackground}
          />
        ),
        'casement-1-1': <Casement11Square8Casement key="square8Casement-casement-1-1" />,
        'casement-1-2': <Casement12Square8Casement key="square8Casement-casement-1-2" />,
        'casement-1-3': <Casement13Square8Casement key="square8Casement-casement-1-3" />,
        'casement-1-4': <Casement14Square8Casement key="square8Casement-casement-1-4" />,
        'casement-1-5': <Casement15Square8Casement key="square8Casement-casement-1-5" />,
        'casement-1-6': <Casement16Square8Casement key="square8Casement-casement-1-6" />,
        'casement-2-1': <Casement21Square8Casement key="square8Casement-casement-2-1" />,
        'casement-2-2': <Casement22Square8Casement key="square8Casement-casement-2-2" />,
        'casement-2-3': <Casement23Square8Casement key="square8Casement-casement-2-3" />,
        'casement-2-4': <Casement24Square8Casement key="square8Casement-casement-2-4" />,
        'casement-2-5': <Casement25Square8Casement key="square8Casement-casement-2-5" />,
        'casement-2-6': <Casement26Square8Casement key="square8Casement-casement-2-6" />,
        'casement-3-1': <Casement31Square8Casement key="square8Casement-casement-3-1" />,
        'casement-3-2': <Casement32Square8Casement key="square8Casement-casement-3-2" />,
        'casement-3-3': <Casement33Square8Casement key="square8Casement-casement-3-3" />,
        'casement-3-4': <Casement34Square8Casement key="square8Casement-casement-3-4" />,
        'casement-3-5': <Casement35Square8Casement key="square8Casement-casement-3-5" />,
        'casement-3-6': <Casement36Square8Casement key="square8Casement-casement-3-6" />,
        'casement-4-1': <Casement41Square8Casement key="square8Casement-casement-4-1" />,
        'casement-4-2': <Casement42Square8Casement key="square8Casement-casement-4-2" />,
        'casement-4-3': <Casement43Square8Casement key="square8Casement-casement-4-3" />,
        'casement-4-4': <Casement44Square8Casement key="square8Casement-casement-4-4" />,
        'casement-4-5': <Casement45Square8Casement key="square8Casement-casement-4-5" />,
        'casement-4-6': <Casement46Square8Casement key="square8Casement-casement-4-6" />,
        'casement-5-1': <Casement51Square8Casement key="square8Casement-casement-5-1" />,
        'casement-5-2': <Casement52Square8Casement key="square8Casement-casement-5-2" />,
        'casement-5-3': <Casement53Square8Casement key="square8Casement-casement-5-3" />,
        'casement-5-4': <Casement54Square8Casement key="square8Casement-casement-5-4" />,
        'casement-5-5': <Casement55Square8Casement key="square8Casement-casement-5-5" />,
        'casement-5-6': <Casement56Square8Casement key="square8Casement-casement-5-6" />,
        'casement-6-1': <Casement61Square8Casement key="square8Casement-casement-6-1" />,
        'casement-6-2': <Casement62Square8Casement key="square8Casement-casement-6-2" />,
        'casement-6-3': <Casement63Square8Casement key="square8Casement-casement-6-3" />,
        'casement-6-4': <Casement64Square8Casement key="square8Casement-casement-6-4" />,
        'casement-6-5': <Casement65Square8Casement key="square8Casement-casement-6-5" />,
        'casement-6-6': <Casement66Square8Casement key="square8Casement-casement-6-6" />,
        'casement-7-1': <Casement71Square8Casement key="square8Casement-casement-7-1" />,
        'casement-7-2': <Casement72Square8Casement key="square8Casement-casement-7-2" />,
        'casement-7-3': <Casement73Square8Casement key="square8Casement-casement-7-3" />,
        'casement-7-4': <Casement74Square8Casement key="square8Casement-casement-7-4" />,
        'casement-7-5': <Casement75Square8Casement key="square8Casement-casement-7-5" />,
        'casement-7-6': <Casement76Square8Casement key="square8Casement-casement-7-6" />,
        'casement-8-1': <Casement81Square8Casement key="square8Casement-casement-8-1" />,
        'casement-8-2': <Casement82Square8Casement key="square8Casement-casement-8-2" />,
        'casement-8-3': <Casement83Square8Casement key="square8Casement-casement-8-3" />,
        'casement-8-4': <Casement84Square8Casement key="square8Casement-casement-8-4" />,
        'casement-8-5': <Casement85Square8Casement key="square8Casement-casement-8-5" />,
        'casement-8-6': <Casement86Square8Casement key="square8Casement-casement-8-6" />,
      },
      square54Casement: {
        container: (
          <ContainerSquare54Casement
            key="square54Casement-container"
            onCasementClick={handleFilterChange('square54Casement/container')}
            withoutBackground={withoutBackground}
          />
        ),
        'casement-1-1': <Casement11Square54Casement key="square54Casement-casement-1-1" />,
        'casement-1-2': <Casement12Square54Casement key="square54Casement-casement-1-2" />,
        'casement-1-3': <Casement13Square54Casement key="square54Casement-casement-1-3" />,
        'casement-1-4': <Casement14Square54Casement key="square54Casement-casement-1-4" />,
        'casement-2-1': <Casement21Square54Casement key="square54Casement-casement-2-1" />,
        'casement-2-2': <Casement22Square54Casement key="square54Casement-casement-2-2" />,
        'casement-2-3': <Casement23Square54Casement key="square54Casement-casement-2-3" />,
        'casement-2-4': <Casement24Square54Casement key="square54Casement-casement-2-4" />,
        'casement-3-1': <Casement31Square54Casement key="square54Casement-casement-3-1" />,
        'casement-3-2': <Casement32Square54Casement key="square54Casement-casement-3-2" />,
        'casement-3-3': <Casement33Square54Casement key="square54Casement-casement-3-3" />,
        'casement-3-4': <Casement34Square54Casement key="square54Casement-casement-3-4" />,
        'casement-4-1': <Casement41Square54Casement key="square54Casement-casement-4-1" />,
        'casement-4-2': <Casement42Square54Casement key="square54Casement-casement-4-2" />,
        'casement-4-3': <Casement43Square54Casement key="square54Casement-casement-4-3" />,
        'casement-4-4': <Casement44Square54Casement key="square54Casement-casement-4-4" />,
        'casement-5-1': <Casement51Square54Casement key="square54Casement-casement-5-1" />,
        'casement-5-2': <Casement52Square54Casement key="square54Casement-casement-5-2" />,
        'casement-5-3': <Casement53Square54Casement key="square54Casement-casement-5-3" />,
        'casement-5-4': <Casement54Square54Casement key="square54Casement-casement-5-4" />,
      },
      square5VerticalCasement: {
        container: (
          <ContainerSquare5VerticalCasement
            key="square5VerticalCasement-container"
            onCasementClick={handleFilterChange('square5VerticalCasement/container')}
            withoutBackground={withoutBackground}
          />
        ),
        'casement-1-1': (
          <Casement11Square5VerticalCasement key="square5VerticalCasement-casement-1-1" />
        ),
        'casement-1-2': (
          <Casement12Square5VerticalCasement key="square5VerticalCasement-casement-1-2" />
        ),
        'casement-2-1': (
          <Casement21Square5VerticalCasement key="square5VerticalCasement-casement-2-1" />
        ),
        'casement-2-2': (
          <Casement22Square5VerticalCasement key="square5VerticalCasement-casement-2-2" />
        ),
        'casement-3-1': (
          <Casement31Square5VerticalCasement key="square5VerticalCasement-casement-3-1" />
        ),
        'casement-3-2': (
          <Casement32Square5VerticalCasement key="square5VerticalCasement-casement-3-2" />
        ),
        'casement-4-1': (
          <Casement41Square5VerticalCasement key="square5VerticalCasement-casement-4-1" />
        ),
        'casement-4-2': (
          <Casement42Square5VerticalCasement key="square5VerticalCasement-casement-4-2" />
        ),
        'casement-5-1': (
          <Casement51Square5VerticalCasement key="square5VerticalCasement-casement-5-1" />
        ),
        'casement-5-2': (
          <Casement52Square5VerticalCasement key="square5VerticalCasement-casement-5-2" />
        ),
      },
      // Вопрос по створкам
      // Окно, две створки горизонально сверху, две створки горизанстально в середине, 2 свтворки горизонтально снизу
      square62Casement: {
        container: (
          <ContainerSquare62Casement
            key="square62Casement-container"
            onCasementClick={handleFilterChange('square62Casement/container')}
            withoutBackground={withoutBackground}
          />
        ),
        //'casement-1-1': <Casement14Square62Casement key="square62Casement-casement-1-4" />,
        'casement-1-1': <Casement11Square62Casement key="square62Casement-casement-1-1" />,
        'casement-1-2': <Casement12Square62Casement key="square62Casement-casement-1-2" />,

        'casement-2-1': <Casement21Square62Casement key="square62Casement-casement-2-1" />,
        'casement-2-2': <Casement22Square62Casement key="square62Casement-casement-2-2" />,
        'casement-2-3': <Casement24Square62Casement key="square62Casement-casement-2-4" />,
        'casement-3-1': <Casement31Square62Casement key="square62Casement-casement-3-1" />,
        'casement-3-2': <Casement32Square62Casement key="square62Casement-casement-3-2" />,
        'casement-3-3': <Casement33Square62Casement key="square62Casement-casement-3-3" />,
        'casement-3-4': <Casement34Square62Casement key="square62Casement-casement-3-4" />,
        'casement-4-1': <Casement41Square62Casement key="square62Casement-casement-4-1" />,
        'casement-4-2': <Casement42Square62Casement key="square62Casement-casement-4-2" />,
        //'casement-4-3': <Casement43Square62Casement key="square62Casement-casement-4-3" />,
        //'casement-4-2': <Casement44Square62Casement key="square62Casement-casement-4-2" />,

        'casement-5-1': <Casement52Square62Casement key="square62Casement-casement-5-2" />,
        'casement-5-2': <Casement54Square62Casement key="square62Casement-casement-5-4" />,
        'casement-6-1': <Casement62Square62Casement key="square62Casement-casement-6-2" />,
        'casement-6-2': <Casement64Square62Casement key="square62Casement-casement-6-4" />,
      },
      // Окно из трех створок по вертикале
      square3VerticalCasement: {
        container: (
          <ContainerSquare3VerticalCasement
            key="square3VerticalCasement-container"
            onCasementClick={handleFilterChange('square3VerticalCasement/container')}
            withoutBackground={withoutBackground}
          />
        ),
        // 'casement-1-1': ( <Casement11Square3VerticalCasement key="square3VerticalCasement-casement-1-1" /> ),
        // 'casement-1-2': ( <Casement12Square3VerticalCasement key="square3VerticalCasement-casement-1-2" /> ),
        'casement-1-1': ( <Casement13Square3VerticalCasement key="square3VerticalCasement-casement-1-1" /> ),
        // 'casement-1-4': ( <Casement14Square3VerticalCasement key="square3VerticalCasement-casement-1-4" /> ),
        // 'casement-1-4': ( <Casement15Square3VerticalCasement key="square3VerticalCasement-casement-1-5" /> ),
        // 'casement-1-5': ( <Casement16Square3VerticalCasement key="square3VerticalCasement-casement-1-6" /> ),
        // 'casement-2-1': ( <Casement21Square3VerticalCasement key="square3VerticalCasement-casement-2-1" /> ),
        // 'casement-2-2': ( <Casement22Square3VerticalCasement key="square3VerticalCasement-casement-2-2" /> ),
        'casement-2-1': ( <Casement23Square3VerticalCasement key="square3VerticalCasement-casement-2-1" /> ),
        // 'casement-2-4': ( <Casement24Square3VerticalCasement key="square3VerticalCasement-casement-2-4" /> ),
        // 'casement-2-4': ( <Casement25Square3VerticalCasement key="square3VerticalCasement-casement-2-5" />  ),
        // 'casement-2-5': ( <Casement26Square3VerticalCasement key="square3VerticalCasement-casement-2-6" /> ),
        // 'casement-3-1': ( <Casement31Square3VerticalCasement key="square3VerticalCasement-casement-3-1" /> ),
        // 'casement-3-2': ( <Casement32Square3VerticalCasement key="square3VerticalCasement-casement-3-2" /> ),
        'casement-3-1': ( <Casement33Square3VerticalCasement key="square3VerticalCasement-casement-3-1" /> ),
        // 'casement-3-4': ( <Casement34Square3VerticalCasement key="square3VerticalCasement-casement-3-4" /> ),
        // 'casement-3-5': ( <Casement35Square3VerticalCasement key="square3VerticalCasement-casement-3-5" /> ),
        // 'casement-3-6': ( <Casement36Square3VerticalCasement key="square3VerticalCasement-casement-3-6" /> ),
      },
      square82Casement: {
        container: (
          <ContainerSquare82Casement
            key="square82Casement-container"
            onCasementClick={handleFilterChange('square82Casement/container')}
            withoutBackground={withoutBackground}
          />
        ),
        'casement-1-1': <Casement11Square82Casement key="square82Casement-casement-1-1" />,
        'casement-1-2': <Casement12Square82Casement key="square82Casement-casement-1-2" />,
        'casement-1-3': <Casement13Square82Casement key="square82Casement-casement-1-3" />,
        'casement-1-4': <Casement14Square82Casement key="square82Casement-casement-1-4" />,
        'casement-2-1': <Casement21Square82Casement key="square82Casement-casement-2-1" />,
        'casement-2-2': <Casement22Square82Casement key="square82Casement-casement-2-2" />,
        'casement-2-3': <Casement23Square82Casement key="square82Casement-casement-2-3" />,
        'casement-2-4': <Casement24Square82Casement key="square82Casement-casement-2-4" />,
        'casement-3-1': <Casement31Square82Casement key="square82Casement-casement-3-1" />,
        'casement-3-2': <Casement32Square82Casement key="square82Casement-casement-3-2" />,
        'casement-3-3': <Casement33Square82Casement key="square82Casement-casement-3-3" />,
        'casement-3-4': <Casement34Square82Casement key="square82Casement-casement-3-4" />,
        'casement-4-1': <Casement41Square82Casement key="square82Casement-casement-4-1" />,
        'casement-4-2': <Casement42Square82Casement key="square82Casement-casement-4-2" />,
        'casement-4-3': <Casement43Square82Casement key="square82Casement-casement-4-3" />,
        'casement-4-4': <Casement44Square82Casement key="square82Casement-casement-4-4" />,
        'casement-5-1': <Casement51Square82Casement key="square82Casement-casement-5-1" />,
        'casement-5-2': <Casement52Square82Casement key="square82Casement-casement-5-2" />,
        'casement-5-3': <Casement53Square82Casement key="square82Casement-casement-5-3" />,
        'casement-5-4': <Casement54Square82Casement key="square82Casement-casement-5-4" />,
        'casement-6-1': <Casement61Square82Casement key="square82Casement-casement-6-1" />,
        'casement-6-2': <Casement62Square82Casement key="square82Casement-casement-6-2" />,
        'casement-6-3': <Casement63Square82Casement key="square82Casement-casement-6-3" />,
        'casement-6-4': <Casement64Square82Casement key="square82Casement-casement-6-4" />,
        'casement-7-1': <Casement71Square82Casement key="square82Casement-casement-7-1" />,
        'casement-7-2': <Casement72Square82Casement key="square82Casement-casement-7-2" />,
        'casement-7-3': <Casement73Square82Casement key="square82Casement-casement-7-3" />,
        'casement-7-4': <Casement74Square82Casement key="square82Casement-casement-7-4" />,
        'casement-8-1': <Casement81Square82Casement key="square82Casement-casement-8-1" />,
        'casement-8-2': <Casement82Square82Casement key="square82Casement-casement-8-2" />,
        'casement-8-3': <Casement83Square82Casement key="square82Casement-casement-8-3" />,
        'casement-8-4': <Casement84Square82Casement key="square82Casement-casement-8-4" />,
      },
      door1RCasement1: {
        container: (
          <ContainerDoor1RCasement1
            key="door-1-r-casement-1-container"
            onCasementClick={handleFilterChange('door1RCasement1/container')}
            withoutBackground={withoutBackground}
          />
        ),
        'casement-1-1': <Casement5Door1RCasement1 key="door1RCasement1-casement-1-5" />,
        'casement-1-2': <Casement6Door1RCasement1 key="door1RCasement1-casement-1-6" />,
        'casement-1-3': <Casement7Door1RCasement1 key="door1RCasement1-casement-1-7" />,
        'casement-1-4': <Casement8Door1RCasement1 key="door1RCasement1-casement-1-8" />,
        'casement-2-1': <Casement1Door1RCasement1 key="door1RCasement1-casement-2-1" />,
        'casement-2-2': <Casement2Door1RCasement1 key="door1RCasement1-casement-2-2" />,
        'casement-2-3': <Casement3Door1RCasement1 key="door1RCasement1-casement-2-3" />,
        'casement-2-4': <Casement4Door1RCasement1 key="door1RCasement1-casement-2-4" />,
      },
      door1RCasement2: {
        container: (
          <ContainerDoor1RCasement2
            key="door-1-r-casement-1-container"
            onCasementClick={handleFilterChange('door1RCasement2/container')}
            withoutBackground={withoutBackground}
          />
        ),
        'casement-1-1': <Casement1Door1RCasement2 key="door1RCasement2-casement-1-1" />,
        'casement-1-2': <Casement5Door1RCasement2 key="door1RCasement2-casement-1-2" />,
        'casement-1-3': <Casement3Door1RCasement2 key="door1RCasement2-casement-1-3" />,
        'casement-1-4': <Casement6Door1RCasement2 key="door1RCasement2-casement-1-4" />,
        'casement-2-1': <Casement2Door1RCasement2 key="door1RCasement2-casement-2-1" />,
        'casement-2-2': <Casement4Door1RCasement2 key="door1RCasement2-casement-2-2" />,
        'casement-2-3': <Casement7Door1RCasement2 key="door1RCasement2-casement-2-3" />,
        'casement-2-4': <Casement8Door1RCasement2 key="door1RCasement2-casement-2-4" />,
        'casement-3-1': <Casement1Door1RCasement1 key="door1RCasement1-casement-3-1" />,
        'casement-3-2': <Casement2Door1RCasement1 key="door1RCasement1-casement-3-2" />,
        'casement-3-3': <Casement3Door1RCasement1 key="door1RCasement1-casement-3-3" />,
        'casement-3-4': <Casement4Door1RCasement1 key="door1RCasement1-casement-3-4" />,
      },
      door1LCasement1: {
        container: (
          <ContainerDoor1LCasement1
            key="door-1-l-casement-1-container"
            onCasementClick={handleFilterChange('door1LCasement1/container')}
            withoutBackground={withoutBackground}
          />
        ),
        'casement-1-1': <Casement7Door1LCasement1 key="door1LCasement1-casement-1-1" />,
        'casement-1-2': <Casement8Door1LCasement1 key="door1LCasement1-casement-1-2" />,
        'casement-1-3': <Casement3Door1LCasement1 key="door1LCasement1-casement-1-3" />,
        'casement-1-4': <Casement4Door1LCasement1 key="door1LCasement1-casement-1-4" />,
        'casement-2-1': <Casement5Door1LCasement1 key="door1LCasement1-casement-2-1" />,
        'casement-2-2': <Casement6Door1LCasement1 key="door1LCasement1-casement-2-2" />,
        'casement-2-3': <Casement1Door1LCasement1 key="door1LCasement1-casement-2-3" />,
        'casement-2-4': <Casement2Door1LCasement1 key="door1LCasement1-casement-2-4" />,
      },
      door1LCasement2: {
        container: (
          <ContainerDoor1LCasement2
            key="door-1-l-casement-2-container"
            onCasementClick={handleFilterChange('door1LCasement2/container')}
            withoutBackground={withoutBackground}
          />
        ),
        'casement-1-1': <Casement7Door1LCasement1 key="door1LCasement1-casement-1-1" />,
        'casement-1-2': <Casement8Door1LCasement1 key="door1LCasement1-casement-1-2" />,
        'casement-1-3': <Casement3Door1LCasement1 key="door1LCasement1-casement-1-3" />,
        'casement-1-4': <Casement4Door1LCasement1 key="door1LCasement1-casement-1-4" />,
        'casement-2-1': <Casement1Door1LCasement2 key="door1LCasement2-casement-2-1" />,
        'casement-2-2': <Casement2Door1LCasement2 key="door1LCasement2-casement-2-2" />,
        'casement-2-3': <Casement3Door1LCasement2 key="door1LCasement2-casement-2-3" />,
        'casement-2-4': <Casement4Door1LCasement2 key="door1LCasement2-casement-2-4" />,
        'casement-3-1': <Casement5Door1LCasement2 key="door1LCasement2-casement-3-1" />,
        'casement-3-2': <Casement6Door1LCasement2 key="door1LCasement2-casement-3-2" />,
        'casement-3-3': <Casement7Door1LCasement2 key="door1LCasement2-casement-3-3" />,
        'casement-3-4': <Casement8Door1LCasement2 key="door1LCasement2-casement-3-4" />,
      },
    },
  }

  return imagesList[type] || {}
}

export const shortCssColorNames = {
  aqua: /#00ffff(ff)?(?!\w)|#0ff(f)?(?!\w)/gi,
  azure: /#f0ffff(ff)?(?!\w)/gi,
  beige: /#f5f5dc(ff)?(?!\w)/gi,
  bisque: /#ffe4c4(ff)?(?!\w)/gi,
  black: /#000000(ff)?(?!\w)|#000(f)?(?!\w)/gi,
  blue: /#0000ff(ff)?(?!\w)|#00f(f)?(?!\w)/gi,
  brown: /#a52a2a(ff)?(?!\w)/gi,
  coral: /#ff7f50(ff)?(?!\w)/gi,
  cornsilk: /#fff8dc(ff)?(?!\w)/gi,
  crimson: /#dc143c(ff)?(?!\w)/gi,
  cyan: /#00ffff(ff)?(?!\w)|#0ff(f)?(?!\w)/gi,
  darkblue: /#00008b(ff)?(?!\w)/gi,
  darkcyan: /#008b8b(ff)?(?!\w)/gi,
  darkgrey: /#a9a9a9(ff)?(?!\w)/gi,
  darkred: /#8b0000(ff)?(?!\w)/gi,
  deeppink: /#ff1493(ff)?(?!\w)/gi,
  dimgrey: /#696969(ff)?(?!\w)/gi,
  gold: /#ffd700(ff)?(?!\w)/gi,
  green: /#008000(ff)?(?!\w)/gi,
  grey: /#808080(ff)?(?!\w)/gi,
  honeydew: /#f0fff0(ff)?(?!\w)/gi,
  hotpink: /#ff69b4(ff)?(?!\w)/gi,
  indigo: /#4b0082(ff)?(?!\w)/gi,
  ivory: /#fffff0(ff)?(?!\w)/gi,
  khaki: /#f0e68c(ff)?(?!\w)/gi,
  lavender: /#e6e6fa(ff)?(?!\w)/gi,
  lime: /#00ff00(ff)?(?!\w)|#0f0(f)?(?!\w)/gi,
  linen: /#faf0e6(ff)?(?!\w)/gi,
  maroon: /#800000(ff)?(?!\w)/gi,
  moccasin: /#ffe4b5(ff)?(?!\w)/gi,
  navy: /#000080(ff)?(?!\w)/gi,
  oldlace: /#fdf5e6(ff)?(?!\w)/gi,
  olive: /#808000(ff)?(?!\w)/gi,
  orange: /#ffa500(ff)?(?!\w)/gi,
  orchid: /#da70d6(ff)?(?!\w)/gi,
  peru: /#cd853f(ff)?(?!\w)/gi,
  pink: /#ffc0cb(ff)?(?!\w)/gi,
  plum: /#dda0dd(ff)?(?!\w)/gi,
  purple: /#800080(ff)?(?!\w)/gi,
  red: /#ff0000(ff)?(?!\w)|#f00(f)?(?!\w)/gi,
  salmon: /#fa8072(ff)?(?!\w)/gi,
  seagreen: /#2e8b57(ff)?(?!\w)/gi,
  seashell: /#fff5ee(ff)?(?!\w)/gi,
  sienna: /#a0522d(ff)?(?!\w)/gi,
  silver: /#c0c0c0(ff)?(?!\w)/gi,
  skyblue: /#87ceeb(ff)?(?!\w)/gi,
  snow: /#fffafa(ff)?(?!\w)/gi,
  tan: /#d2b48c(ff)?(?!\w)/gi,
  teal: /#008080(ff)?(?!\w)/gi,
  thistle: /#d8bfd8(ff)?(?!\w)/gi,
  tomato: /#ff6347(ff)?(?!\w)/gi,
  violet: /#ee82ee(ff)?(?!\w)/gi,
  wheat: /#f5deb3(ff)?(?!\w)/gi,
  white: /#ffffff(ff)?(?!\w)|#fff(f)?(?!\w)/gi,
}

export const REGEX = {
  whitespace: /\s+/g,
  urlHexPairs: /%[\dA-F]{2}/g,
  quotes: /"/g,
}
