import React, { FC } from 'react'

import { ICheckProps } from '../../views/calculator/interface'
import { CheckWrapper } from '../../views/calculator/styles'

export const Check2Icon: FC<ICheckProps> = props => {
  const { color = '#d69a2d' } = props

  return (
    <CheckWrapper>
      <svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.64436 0.256282C7.95448 -0.0854272 8.45729 -0.0854272 8.76741 0.256282C9.07753 0.59799 9.07753 1.15201 8.76741 1.49372L4.0027 6.74372C3.69258 7.08543 3.18977 7.08543 2.87965 6.74372L0.232592 3.82705C-0.0775305 3.48534 -0.0775305 2.93132 0.232592 2.58961C0.542714 2.24791 1.04552 2.24791 1.35564 2.58961L3.44118 4.88756L7.64436 0.256282Z"
          fill={color}
        />
      </svg>
    </CheckWrapper>
  )
}
