import styled, { css } from 'styled-components'

export const ComponentShadowWrapper = styled.div<{ coord: Record<string, string> }>`
  background-color: rgba(0, 0, 0, 0.3);

  position: absolute;

  ${({ coord }) => css`
    top: ${coord['top']};
    left: ${coord['left']};
    right: ${coord['right']};
    bottom: ${coord['bottom']};
  `}

  z-index: 1000;
`
