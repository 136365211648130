import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement54Square62Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#5-4_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 174C188 172.895 188.895 172 190 172C191.105 172 192 172.895 192 174V188.56H188V174ZM188 188.56H192V190.4H192.06C192.579 190.4 193 190.821 193 191.34V193.12C193 194.158 192.158 195 191.12 195H189.88C188.842 195 188 194.158 188 193.12V190.4V190.4V188.56Z"
            fill="white"
          />
        </g>
        <rect x="188" y="189.25" width="3.99998" height="0.95833" fill="#ABABAB" />
        <path
          d="M188 172.94C188 172.421 188.421 172 188.94 172H191.06C191.579 172 192 172.421 192 172.94V189.25H188V172.94Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M188 194.06C188 194.579 188.421 195 188.94 195H192.06C192.579 195 193 194.579 193 194.06V191.148C193 190.629 192.579 190.208 192.06 190.208H188V194.06Z"
          fill="url(#paint1_linear)"
        />
        <g filter="url(#5-4_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M192 126.999C192 125.895 191.105 125 190.001 125C188.896 125 188.001 125.895 188.001 126.999V129.167H188.001V140H188V143.001C188 144.105 188.895 145 189.999 145C191.104 145 191.999 144.105 191.999 143.001V140.833H192V130H192V126.999Z"
            fill="url(#paint2_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 192 129.167)"
          fill="#ABABAB"
        />
        <path
          d="M192 125.94C192 125.421 191.579 125 191.06 125H188.941C188.422 125 188.001 125.421 188.001 125.94V130H192V125.94Z"
          fill="url(#paint3_linear)"
        />
        <path
          d="M188 144.06C188 144.579 188.421 145 188.94 145H191.059C191.578 145 191.999 144.579 191.999 144.06V140H188V144.06Z"
          fill="url(#paint4_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 192 130.833)"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#5-4_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 121H115V199H188V121ZM177 132H126V188H177V132Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 121H115V199H116V122H187V199H188V121Z"
          fill="#FBFBFB"
        />
        <g filter="url(#5-4_filter3_i)">
          <rect
            x="126.47"
            y="132.47"
            width="50.0601"
            height="55.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="119.53"
          y="125.53"
          width="63.9399"
          height="68.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="117"
          y="152"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#5-4_filter4_d)">
          <rect x="117.702" y="152.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M118.286 163.795C118.798 164.457 119.6 164.884 120.502 164.884C122.048 164.884 123.302 163.63 123.302 162.084V155.444C123.302 154.455 122.79 153.587 122.016 153.088C122.382 153.562 122.6 154.155 122.6 154.8V161.44C122.6 162.986 121.347 164.24 119.8 164.24C119.242 164.24 118.722 164.077 118.286 163.795Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M120.502 152.644C118.956 152.644 117.702 153.898 117.702 155.444V161.44C117.702 161.46 117.702 161.48 117.703 161.501V156.024C117.703 154.477 118.956 153.224 120.503 153.224C122.029 153.224 123.269 154.444 123.302 155.963V155.444C123.302 153.898 122.048 152.644 120.502 152.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#5-4_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M120.5 156C119.672 156 119 156.672 119 157.5V157.714V159V177.5C119 178.328 119.672 179 120.5 179V179C121.328 179 122 178.328 122 177.5V161V157.5L122 157.286C122 156.576 121.424 156 120.714 156V156C120.677 156 120.64 156.001 120.604 156.004C120.569 156.001 120.535 156 120.5 156Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M175.5 186.138L128.036 159.016L175.5 133.831L175.5 186.138Z" stroke="white" />
        <path d="M175.224 186.5L151.5 134.21L127.776 186.5L175.224 186.5Z" stroke="white" />
        <defs>
          <filter
            id="5-4_filter0_d"
            x="186.12"
            y="170.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-4_filter1_d"
            x="186.12"
            y="123.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-4_filter2_d"
            x="110.3"
            y="116.3"
            width="84.2793"
            height="89.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-4_filter3_i"
            x="126"
            y="132"
            width="51.9399"
            height="58.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-4_filter4_d"
            x="115.822"
            y="150.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-4_filter5_d"
            x="117.12"
            y="154.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="194.8"
            y1="180.625"
            x2="187.2"
            y2="180.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="196.5"
            y1="192.604"
            x2="187"
            y2="192.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="194.8"
            y1="142.5"
            x2="187.2"
            y2="142.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="185.202"
            y1="127.5"
            x2="192.8"
            y2="127.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="194.798"
            y1="142.5"
            x2="187.2"
            y2="142.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
