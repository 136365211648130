import React, { FC } from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'
import { IContainerSquareProps } from '../../interface'
import { ContainerBackground } from '../container-background'

export const ContainerSquare82Casement: FC<IContainerSquareProps> = props => {
  const { onCasementClick, withoutBackground } = props

  return (
    <ProductImageWrapper isClickable>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <rect width="220" height="333" fill="white" />
        <rect x="10" y="18" width="186" height="286" fill="#E8EAEE" />
        <rect x="10" y="18" width="186" height="286" fill="url(#8-2-pattern0)" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M210 10H10V292H24V28H196V292H210V10Z"
          fill="#C4C4C4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M210 292H10V308H0V316H10H210H220V308H210V292Z"
          fill="url(#8-2-paint0_linear)"
        />
        <path d="M10 10L24 28.1525V291.982L10 308V10Z" fill="#E9EBEF" />
        <path d="M10 10L24 28.1525V291.982L10 308V10Z" fill="#020202" fillOpacity="0.06" />
        <path d="M210 10L196 28.1525V291.982L210 308V10Z" fill="#E9EBEF" />
        <path d="M210 10L196 28.1525V291.982L210 308V10Z" fill="#020202" fillOpacity="0.06" />
        <rect y="316" width="220" height="17" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 27H196V292H24V27ZM37 233H101V278H37V233ZM101 168H37V213H101V168ZM37 105H101V150H37V105ZM101 40H37V85H101V40ZM119 233H183V278H119V233ZM183 168H119V213H183V168ZM119 105H183V150H119V105ZM183 40H119V85H183V40Z"
          fill="#E8EAEE"
        />
        <g
          filter="url(#8-2-filter0_i)"
          className="casement"
          data-index="0"
          onClick={onCasementClick(0)}
        >
          <rect x="37" y="40" width="64" height="45" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="37.47"
          y="40.47"
          width="63.0601"
          height="44.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#8-2-filter1_i)"
          className="casement"
          data-index="1"
          onClick={onCasementClick(1)}
        >
          <rect x="119" y="40" width="64" height="45" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="119.47"
          y="40.47"
          width="63.0601"
          height="44.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#8-2-filter2_i)"
          className="casement"
          data-index="3"
          onClick={onCasementClick(3)}
        >
          <rect x="119" y="105" width="64" height="45" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="119.47"
          y="105.47"
          width="63.0601"
          height="44.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#8-2-filter3_i)"
          className="casement"
          data-index="2"
          onClick={onCasementClick(2)}
        >
          <rect x="37" y="105" width="64" height="45" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="37.47"
          y="105.47"
          width="63.0601"
          height="44.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#8-2-filter4_i)"
          className="casement"
          data-index="4"
          onClick={onCasementClick(4)}
        >
          <rect x="37" y="168" width="64" height="45" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="37.47"
          y="168.47"
          width="63.0601"
          height="44.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#8-2-filter5_i)"
          className="casement"
          data-index="5"
          onClick={onCasementClick(5)}
        >
          <rect x="119" y="168" width="64" height="45" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="119.47"
          y="168.47"
          width="63.0601"
          height="44.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#8-2-filter6_i)"
          className="casement"
          data-index="7"
          onClick={onCasementClick(7)}
        >
          <rect x="119" y="233" width="64" height="45" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="119.47"
          y="233.47"
          width="63.0601"
          height="44.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#8-2-filter7_i)"
          className="casement"
          data-index="6"
          onClick={onCasementClick(6)}
        >
          <rect x="37" y="233" width="64" height="45" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="37.47"
          y="233.47"
          width="63.0601"
          height="44.0601"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <path
          d="M77.9851 56.7411H75.4471C74.8002 56.7411 74.2528 56.2343 74.2528 55.6355V55.4052C74.2528 54.8063 74.8002 54.2996 75.4471 54.2996H77.9851C78.632 54.2996 79.1794 54.8063 79.1794 55.4052V55.6355C79.1794 56.2574 78.6569 56.7411 77.9851 56.7411Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M60.4262 59.5652C60.7247 59.5652 60.7993 59.5652 61.1972 59.5652C61.9432 59.5652 62.4903 60.1052 62.4903 60.833C62.4903 61.5843 61.9184 62.1008 61.1474 62.1008C59.705 62.1008 58.8098 62.1008 57.3674 62.1008C56.8203 62.1008 56.3727 61.913 56.1488 61.4199C55.9499 61.1147 55.8255 60.2695 56.6213 59.5652C57.1436 58.9782 62.9131 52.7565 63.9327 51.6765C63.9824 51.6061 63.9327 51.5122 63.8581 51.5122C63.8332 51.5122 63.8332 51.5122 63.8084 51.5122C61.6448 51.5122 59.4812 51.5122 57.3177 51.5122C56.6711 51.5122 56.1737 51.1365 56.0494 50.573C55.9002 49.9156 56.1986 49.3522 56.7706 49.0939C56.9446 49.0235 57.1187 49 57.2928 49C60.5008 49 63.684 49 66.8921 49C67.5138 49 67.9366 49.1878 68.1852 49.7278C68.5085 50.4087 68.1604 50.8783 67.9366 51.1835C67.4889 51.7235 62.938 56.56 60.4262 59.2834C60.4014 59.3069 60.3765 59.3539 60.3268 59.3773C60.277 59.4478 60.3268 59.5652 60.4262 59.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M67.1856 62.1009H64.6476C64.0007 62.1009 63.4533 61.5818 63.4533 60.9684V60.7324C63.4533 60.1189 64.0007 59.5999 64.6476 59.5999H67.1856C67.8325 59.5999 68.3799 60.1189 68.3799 60.7324V60.9684C68.3799 61.6054 67.8325 62.1009 67.1856 62.1009Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M70.7154 55.5152C70.7154 53.7341 70.7154 51.9764 70.7154 50.1952C70.7154 49.4922 71.2374 49 71.9832 49C75.2148 49 78.4464 49 81.678 49C82.4238 49 82.9458 49.5156 82.9458 50.2421C82.9458 50.9686 82.4238 51.5077 81.678 51.5077C78.9933 51.5077 76.3334 51.5077 73.6487 51.5077C73.4498 51.5077 73.4001 51.5545 73.4001 51.742C73.4001 54.2731 73.4001 56.8042 73.4001 59.3353C73.4001 59.5463 73.4747 59.5697 73.6487 59.5697C76.3086 59.5697 78.9933 59.5697 81.6532 59.5697C82.2498 59.5697 82.7221 59.8978 82.8961 60.39C83.095 60.9759 82.8713 61.5618 82.3989 61.8899C82.1752 62.0305 81.9018 62.1008 81.6283 62.1008C78.4464 62.1008 75.2397 62.1008 72.0578 62.1008C71.2374 62.1008 70.7403 61.5852 70.7403 60.8118C70.7154 59.0307 70.7154 57.273 70.7154 55.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M57.7054 67.0415V67.6984H56V67.0415H56.4263V65.2586H56.0502V64.6018H57.6803V65.2586H57.3041V67.0415H57.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M61.5344 67.7579H60.7737L60.651 67.5451C60.3811 67.687 60.0621 67.7579 59.7676 67.7579C58.9334 67.7342 58.1482 67.1668 58.1482 66.1501C58.1482 65.1806 58.8107 64.5422 59.8167 64.5422C60.651 64.5422 61.4607 65.0861 61.4607 66.1501C61.4607 66.5284 61.3626 66.8121 61.2154 67.0722L61.5589 67.6397V67.7579H61.5344ZM59.7922 67.0013C60.1848 67.0013 60.6019 66.7175 60.6019 66.1501C60.6019 65.5826 60.1848 65.2989 59.7922 65.2989C59.375 65.2989 58.9824 65.5826 58.9824 66.1501C58.9824 66.7175 59.375 67.0013 59.7922 67.0013Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M63.7835 66.7486C63.7835 66.9598 64.0291 67.0772 64.2992 67.0772C64.5448 67.0772 64.7904 66.9598 64.7904 66.7955C64.7904 66.4904 64.422 66.4904 64.201 66.4669C63.6116 66.42 63.0223 66.2087 63.0223 65.5515C63.0223 64.8708 63.6116 64.5422 64.2747 64.5422C64.8886 64.5422 65.5271 64.8708 65.5271 65.5515H64.7167C64.7167 65.3403 64.4957 65.2464 64.2747 65.2464C64.0782 65.2464 63.8326 65.2933 63.8326 65.5281C63.8326 65.7393 64.0291 65.7862 64.3238 65.8097C65.0114 65.8567 65.6007 66.021 65.6007 66.7721C65.6007 67.4293 64.9377 67.7579 64.2747 67.7579C63.5871 67.7579 62.924 67.4293 62.9486 66.7486H63.7835Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M68.2288 65.3109H67.0377V65.7601H68.1295V66.4692H67.0377V66.9656H68.2536V67.6984H66.1692V64.6018H68.2039V65.3109H68.2288Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M71.603 67.5802V67.6984H70.7222L70.1594 66.7292H69.719V67.6984H68.8871V64.6018C69.3765 64.6018 69.8413 64.6018 70.3062 64.6018C71.0892 64.6018 71.4562 65.1691 71.4562 65.7128C71.4562 66.0673 71.285 66.4219 70.9669 66.611L71.603 67.5802ZM70.3062 66.0673C70.5509 66.0673 70.6488 65.8782 70.6488 65.6655C70.6488 65.4764 70.5265 65.2873 70.3062 65.2873H69.719V66.0673H70.3062Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M73.2761 66.3341L73.553 65.5273L73.9556 64.6018H74.8868V64.7205L73.4523 67.7579H73.1L71.6655 64.7205V64.6018H72.5967L73.0245 65.5273L73.2761 66.3341Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M76.7814 67.0415V67.6984H75.0761V67.0415H75.5024V65.2586H75.1262V64.6018H76.7564V65.2586H76.3802V67.0415H76.7814Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M78.8348 64.6018C79.5091 64.6018 80.2582 64.9551 80.383 65.7795H79.559C79.4341 65.4497 79.1844 65.332 78.8348 65.332C78.3354 65.332 78.0108 65.6617 78.0108 66.1799C78.0108 66.6274 78.3354 67.0278 78.8348 67.0278C79.1844 67.0278 79.4841 66.8629 79.559 66.5332H80.383C80.2582 67.4046 79.534 67.7579 78.8348 67.7579C77.9858 67.7579 77.1618 67.2162 77.1618 66.1799C77.1618 65.12 77.9858 64.6018 78.8348 64.6018Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M82.9442 65.3109H81.7531V65.7601H82.8449V66.4692H81.7531V66.9656H82.969V67.6984H80.8847V64.6018H82.9194V65.3109H82.9442Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M159.985 56.7411H157.447C156.8 56.7411 156.253 56.2343 156.253 55.6355V55.4052C156.253 54.8063 156.8 54.2996 157.447 54.2996H159.985C160.632 54.2996 161.179 54.8063 161.179 55.4052V55.6355C161.179 56.2574 160.657 56.7411 159.985 56.7411Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M142.426 59.5652C142.725 59.5652 142.799 59.5652 143.197 59.5652C143.943 59.5652 144.49 60.1052 144.49 60.833C144.49 61.5843 143.918 62.1008 143.147 62.1008C141.705 62.1008 140.81 62.1008 139.367 62.1008C138.82 62.1008 138.373 61.913 138.149 61.4199C137.95 61.1147 137.826 60.2695 138.621 59.5652C139.144 58.9782 144.913 52.7565 145.933 51.6765C145.982 51.6061 145.933 51.5122 145.858 51.5122C145.833 51.5122 145.833 51.5122 145.808 51.5122C143.645 51.5122 141.481 51.5122 139.318 51.5122C138.671 51.5122 138.174 51.1365 138.049 50.573C137.9 49.9156 138.199 49.3522 138.771 49.0939C138.945 49.0235 139.119 49 139.293 49C142.501 49 145.684 49 148.892 49C149.514 49 149.937 49.1878 150.185 49.7278C150.509 50.4087 150.16 50.8783 149.937 51.1835C149.489 51.7235 144.938 56.56 142.426 59.2834C142.401 59.3069 142.376 59.3539 142.327 59.3773C142.277 59.4478 142.327 59.5652 142.426 59.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M149.186 62.1009H146.648C146.001 62.1009 145.453 61.5818 145.453 60.9684V60.7324C145.453 60.1189 146.001 59.5999 146.648 59.5999H149.186C149.832 59.5999 150.38 60.1189 150.38 60.7324V60.9684C150.38 61.6054 149.832 62.1009 149.186 62.1009Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M152.715 55.5152C152.715 53.7341 152.715 51.9764 152.715 50.1952C152.715 49.4922 153.237 49 153.983 49C157.215 49 160.446 49 163.678 49C164.424 49 164.946 49.5156 164.946 50.2421C164.946 50.9686 164.424 51.5077 163.678 51.5077C160.993 51.5077 158.333 51.5077 155.649 51.5077C155.45 51.5077 155.4 51.5545 155.4 51.742C155.4 54.2731 155.4 56.8042 155.4 59.3353C155.4 59.5463 155.475 59.5697 155.649 59.5697C158.309 59.5697 160.993 59.5697 163.653 59.5697C164.25 59.5697 164.722 59.8978 164.896 60.39C165.095 60.9759 164.871 61.5618 164.399 61.8899C164.175 62.0305 163.902 62.1008 163.628 62.1008C160.446 62.1008 157.24 62.1008 154.058 62.1008C153.237 62.1008 152.74 61.5852 152.74 60.8118C152.715 59.0307 152.715 57.273 152.715 55.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M139.705 67.0415V67.6984H138V67.0415H138.426V65.2586H138.05V64.6018H139.68V65.2586H139.304V67.0415H139.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M143.534 67.7579H142.774L142.651 67.5451C142.381 67.687 142.062 67.7579 141.768 67.7579C140.933 67.7342 140.148 67.1668 140.148 66.1501C140.148 65.1806 140.811 64.5422 141.817 64.5422C142.651 64.5422 143.461 65.0861 143.461 66.1501C143.461 66.5284 143.363 66.8121 143.215 67.0722L143.559 67.6397V67.7579H143.534ZM141.792 67.0013C142.185 67.0013 142.602 66.7175 142.602 66.1501C142.602 65.5826 142.185 65.2989 141.792 65.2989C141.375 65.2989 140.982 65.5826 140.982 66.1501C140.982 66.7175 141.375 67.0013 141.792 67.0013Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M145.784 66.7486C145.784 66.9598 146.029 67.0772 146.299 67.0772C146.545 67.0772 146.79 66.9598 146.79 66.7955C146.79 66.4904 146.422 66.4904 146.201 66.4669C145.612 66.42 145.022 66.2087 145.022 65.5515C145.022 64.8708 145.612 64.5422 146.275 64.5422C146.889 64.5422 147.527 64.8708 147.527 65.5515H146.717C146.717 65.3403 146.496 65.2464 146.275 65.2464C146.078 65.2464 145.833 65.2933 145.833 65.5281C145.833 65.7393 146.029 65.7862 146.324 65.8097C147.011 65.8567 147.601 66.021 147.601 66.7721C147.601 67.4293 146.938 67.7579 146.275 67.7579C145.587 67.7579 144.924 67.4293 144.949 66.7486H145.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M150.229 65.3109H149.038V65.7601H150.13V66.4692H149.038V66.9656H150.254V67.6984H148.169V64.6018H150.204V65.3109H150.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M153.603 67.5802V67.6984H152.722L152.159 66.7292H151.719V67.6984H150.887V64.6018C151.376 64.6018 151.841 64.6018 152.306 64.6018C153.089 64.6018 153.456 65.1691 153.456 65.7128C153.456 66.0673 153.285 66.4219 152.967 66.611L153.603 67.5802ZM152.306 66.0673C152.551 66.0673 152.649 65.8782 152.649 65.6655C152.649 65.4764 152.526 65.2873 152.306 65.2873H151.719V66.0673H152.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M155.276 66.3341L155.553 65.5273L155.956 64.6018H156.887V64.7205L155.452 67.7579H155.1L153.666 64.7205V64.6018H154.597L155.024 65.5273L155.276 66.3341Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M158.781 67.0415V67.6984H157.076V67.0415H157.502V65.2586H157.126V64.6018H158.756V65.2586H158.38V67.0415H158.781Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M160.835 64.6018C161.509 64.6018 162.258 64.9551 162.383 65.7795H161.559C161.434 65.4497 161.184 65.332 160.835 65.332C160.335 65.332 160.011 65.6617 160.011 66.1799C160.011 66.6274 160.335 67.0278 160.835 67.0278C161.184 67.0278 161.484 66.8629 161.559 66.5332H162.383C162.258 67.4046 161.534 67.7579 160.835 67.7579C159.986 67.7579 159.162 67.2162 159.162 66.1799C159.162 65.12 159.986 64.6018 160.835 64.6018Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M164.944 65.3109H163.753V65.7601H164.845V66.4692H163.753V66.9656H164.969V67.6984H162.885V64.6018H164.919V65.3109H164.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M159.985 121.741H157.447C156.8 121.741 156.253 121.234 156.253 120.635V120.405C156.253 119.806 156.8 119.3 157.447 119.3H159.985C160.632 119.3 161.179 119.806 161.179 120.405V120.635C161.179 121.257 160.657 121.741 159.985 121.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M142.426 124.565C142.725 124.565 142.799 124.565 143.197 124.565C143.943 124.565 144.49 125.105 144.49 125.833C144.49 126.584 143.918 127.101 143.147 127.101C141.705 127.101 140.81 127.101 139.367 127.101C138.82 127.101 138.373 126.913 138.149 126.42C137.95 126.115 137.826 125.27 138.621 124.565C139.144 123.978 144.913 117.757 145.933 116.677C145.982 116.606 145.933 116.512 145.858 116.512C145.833 116.512 145.833 116.512 145.808 116.512C143.645 116.512 141.481 116.512 139.318 116.512C138.671 116.512 138.174 116.137 138.049 115.573C137.9 114.916 138.199 114.352 138.771 114.094C138.945 114.023 139.119 114 139.293 114C142.501 114 145.684 114 148.892 114C149.514 114 149.937 114.188 150.185 114.728C150.509 115.409 150.16 115.878 149.937 116.183C149.489 116.723 144.938 121.56 142.426 124.283C142.401 124.307 142.376 124.354 142.327 124.377C142.277 124.448 142.327 124.565 142.426 124.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M149.186 127.101H146.648C146.001 127.101 145.453 126.582 145.453 125.968V125.732C145.453 125.119 146.001 124.6 146.648 124.6H149.186C149.832 124.6 150.38 125.119 150.38 125.732V125.968C150.38 126.605 149.832 127.101 149.186 127.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M152.715 120.515C152.715 118.734 152.715 116.976 152.715 115.195C152.715 114.492 153.237 114 153.983 114C157.215 114 160.446 114 163.678 114C164.424 114 164.946 114.516 164.946 115.242C164.946 115.969 164.424 116.508 163.678 116.508C160.993 116.508 158.333 116.508 155.649 116.508C155.45 116.508 155.4 116.555 155.4 116.742C155.4 119.273 155.4 121.804 155.4 124.335C155.4 124.546 155.475 124.57 155.649 124.57C158.309 124.57 160.993 124.57 163.653 124.57C164.25 124.57 164.722 124.898 164.896 125.39C165.095 125.976 164.871 126.562 164.399 126.89C164.175 127.03 163.902 127.101 163.628 127.101C160.446 127.101 157.24 127.101 154.058 127.101C153.237 127.101 152.74 126.585 152.74 125.812C152.715 124.031 152.715 122.273 152.715 120.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M139.705 132.042V132.698H138V132.042H138.426V130.259H138.05V129.602H139.68V130.259H139.304V132.042H139.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M143.534 132.758H142.774L142.651 132.545C142.381 132.687 142.062 132.758 141.768 132.758C140.933 132.734 140.148 132.167 140.148 131.15C140.148 130.181 140.811 129.542 141.817 129.542C142.651 129.542 143.461 130.086 143.461 131.15C143.461 131.528 143.363 131.812 143.215 132.072L143.559 132.64V132.758H143.534ZM141.792 132.001C142.185 132.001 142.602 131.718 142.602 131.15C142.602 130.583 142.185 130.299 141.792 130.299C141.375 130.299 140.982 130.583 140.982 131.15C140.982 131.718 141.375 132.001 141.792 132.001Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M145.784 131.749C145.784 131.96 146.029 132.077 146.299 132.077C146.545 132.077 146.79 131.96 146.79 131.796C146.79 131.49 146.422 131.49 146.201 131.467C145.612 131.42 145.022 131.209 145.022 130.552C145.022 129.871 145.612 129.542 146.275 129.542C146.889 129.542 147.527 129.871 147.527 130.552H146.717C146.717 130.34 146.496 130.246 146.275 130.246C146.078 130.246 145.833 130.293 145.833 130.528C145.833 130.739 146.029 130.786 146.324 130.81C147.011 130.857 147.601 131.021 147.601 131.772C147.601 132.429 146.938 132.758 146.275 132.758C145.587 132.758 144.924 132.429 144.949 131.749H145.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M150.229 130.311H149.038V130.76H150.13V131.469H149.038V131.966H150.254V132.698H148.169V129.602H150.204V130.311H150.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M153.603 132.58V132.698H152.722L152.159 131.729H151.719V132.698H150.887V129.602C151.376 129.602 151.841 129.602 152.306 129.602C153.089 129.602 153.456 130.169 153.456 130.713C153.456 131.067 153.285 131.422 152.967 131.611L153.603 132.58ZM152.306 131.067C152.551 131.067 152.649 130.878 152.649 130.666C152.649 130.476 152.526 130.287 152.306 130.287H151.719V131.067H152.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M155.276 131.334L155.553 130.527L155.956 129.602H156.887V129.72L155.452 132.758H155.1L153.666 129.72V129.602H154.597L155.024 130.527L155.276 131.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M158.781 132.042V132.698H157.076V132.042H157.502V130.259H157.126V129.602H158.756V130.259H158.38V132.042H158.781Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M160.835 129.602C161.509 129.602 162.258 129.955 162.383 130.779H161.559C161.434 130.45 161.184 130.332 160.835 130.332C160.335 130.332 160.011 130.662 160.011 131.18C160.011 131.627 160.335 132.028 160.835 132.028C161.184 132.028 161.484 131.863 161.559 131.533H162.383C162.258 132.405 161.534 132.758 160.835 132.758C159.986 132.758 159.162 132.216 159.162 131.18C159.162 130.12 159.986 129.602 160.835 129.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M164.944 130.311H163.753V130.76H164.845V131.469H163.753V131.966H164.969V132.698H162.885V129.602H164.919V130.311H164.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M77.9851 121.741H75.4471C74.8002 121.741 74.2528 121.234 74.2528 120.635V120.405C74.2528 119.806 74.8002 119.3 75.4471 119.3H77.9851C78.632 119.3 79.1794 119.806 79.1794 120.405V120.635C79.1794 121.257 78.6569 121.741 77.9851 121.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M60.4262 124.565C60.7247 124.565 60.7993 124.565 61.1972 124.565C61.9432 124.565 62.4903 125.105 62.4903 125.833C62.4903 126.584 61.9184 127.101 61.1474 127.101C59.705 127.101 58.8098 127.101 57.3674 127.101C56.8203 127.101 56.3727 126.913 56.1488 126.42C55.9499 126.115 55.8255 125.27 56.6213 124.565C57.1436 123.978 62.9131 117.757 63.9327 116.677C63.9824 116.606 63.9327 116.512 63.8581 116.512C63.8332 116.512 63.8332 116.512 63.8084 116.512C61.6448 116.512 59.4812 116.512 57.3177 116.512C56.6711 116.512 56.1737 116.137 56.0494 115.573C55.9002 114.916 56.1986 114.352 56.7706 114.094C56.9446 114.023 57.1187 114 57.2928 114C60.5008 114 63.684 114 66.8921 114C67.5138 114 67.9366 114.188 68.1852 114.728C68.5085 115.409 68.1604 115.878 67.9366 116.183C67.4889 116.723 62.938 121.56 60.4262 124.283C60.4014 124.307 60.3765 124.354 60.3268 124.377C60.277 124.448 60.3268 124.565 60.4262 124.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M67.1856 127.101H64.6476C64.0007 127.101 63.4533 126.582 63.4533 125.968V125.732C63.4533 125.119 64.0007 124.6 64.6476 124.6H67.1856C67.8325 124.6 68.3799 125.119 68.3799 125.732V125.968C68.3799 126.605 67.8325 127.101 67.1856 127.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M70.7154 120.515C70.7154 118.734 70.7154 116.976 70.7154 115.195C70.7154 114.492 71.2374 114 71.9832 114C75.2148 114 78.4464 114 81.678 114C82.4238 114 82.9458 114.516 82.9458 115.242C82.9458 115.969 82.4238 116.508 81.678 116.508C78.9933 116.508 76.3334 116.508 73.6487 116.508C73.4498 116.508 73.4001 116.555 73.4001 116.742C73.4001 119.273 73.4001 121.804 73.4001 124.335C73.4001 124.546 73.4747 124.57 73.6487 124.57C76.3086 124.57 78.9933 124.57 81.6532 124.57C82.2498 124.57 82.7221 124.898 82.8961 125.39C83.095 125.976 82.8713 126.562 82.3989 126.89C82.1752 127.03 81.9018 127.101 81.6283 127.101C78.4464 127.101 75.2397 127.101 72.0578 127.101C71.2374 127.101 70.7403 126.585 70.7403 125.812C70.7154 124.031 70.7154 122.273 70.7154 120.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M57.7054 132.042V132.698H56V132.042H56.4263V130.259H56.0502V129.602H57.6803V130.259H57.3041V132.042H57.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M61.5344 132.758H60.7737L60.651 132.545C60.3811 132.687 60.0621 132.758 59.7676 132.758C58.9334 132.734 58.1482 132.167 58.1482 131.15C58.1482 130.181 58.8107 129.542 59.8167 129.542C60.651 129.542 61.4607 130.086 61.4607 131.15C61.4607 131.528 61.3626 131.812 61.2154 132.072L61.5589 132.64V132.758H61.5344ZM59.7922 132.001C60.1848 132.001 60.6019 131.718 60.6019 131.15C60.6019 130.583 60.1848 130.299 59.7922 130.299C59.375 130.299 58.9824 130.583 58.9824 131.15C58.9824 131.718 59.375 132.001 59.7922 132.001Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M63.7835 131.749C63.7835 131.96 64.0291 132.077 64.2992 132.077C64.5448 132.077 64.7904 131.96 64.7904 131.796C64.7904 131.49 64.422 131.49 64.201 131.467C63.6116 131.42 63.0223 131.209 63.0223 130.552C63.0223 129.871 63.6116 129.542 64.2747 129.542C64.8886 129.542 65.5271 129.871 65.5271 130.552H64.7167C64.7167 130.34 64.4957 130.246 64.2747 130.246C64.0782 130.246 63.8326 130.293 63.8326 130.528C63.8326 130.739 64.0291 130.786 64.3238 130.81C65.0114 130.857 65.6007 131.021 65.6007 131.772C65.6007 132.429 64.9377 132.758 64.2747 132.758C63.5871 132.758 62.924 132.429 62.9486 131.749H63.7835Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M68.2288 130.311H67.0377V130.76H68.1295V131.469H67.0377V131.966H68.2536V132.698H66.1692V129.602H68.2039V130.311H68.2288Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M71.603 132.58V132.698H70.7222L70.1594 131.729H69.719V132.698H68.8871V129.602C69.3765 129.602 69.8413 129.602 70.3062 129.602C71.0892 129.602 71.4562 130.169 71.4562 130.713C71.4562 131.067 71.285 131.422 70.9669 131.611L71.603 132.58ZM70.3062 131.067C70.5509 131.067 70.6488 130.878 70.6488 130.666C70.6488 130.476 70.5265 130.287 70.3062 130.287H69.719V131.067H70.3062Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M73.2761 131.334L73.553 130.527L73.9556 129.602H74.8868V129.72L73.4523 132.758H73.1L71.6655 129.72V129.602H72.5967L73.0245 130.527L73.2761 131.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M76.7814 132.042V132.698H75.0761V132.042H75.5024V130.259H75.1262V129.602H76.7564V130.259H76.3802V132.042H76.7814Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M78.8348 129.602C79.5091 129.602 80.2582 129.955 80.383 130.779H79.559C79.4341 130.45 79.1844 130.332 78.8348 130.332C78.3354 130.332 78.0108 130.662 78.0108 131.18C78.0108 131.627 78.3354 132.028 78.8348 132.028C79.1844 132.028 79.4841 131.863 79.559 131.533H80.383C80.2582 132.405 79.534 132.758 78.8348 132.758C77.9858 132.758 77.1618 132.216 77.1618 131.18C77.1618 130.12 77.9858 129.602 78.8348 129.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M82.9442 130.311H81.7531V130.76H82.8449V131.469H81.7531V131.966H82.969V132.698H80.8847V129.602H82.9194V130.311H82.9442Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M77.9851 187.741H75.4471C74.8002 187.741 74.2528 187.234 74.2528 186.635V186.405C74.2528 185.806 74.8002 185.3 75.4471 185.3H77.9851C78.632 185.3 79.1794 185.806 79.1794 186.405V186.635C79.1794 187.257 78.6569 187.741 77.9851 187.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M60.4262 190.565C60.7247 190.565 60.7993 190.565 61.1972 190.565C61.9432 190.565 62.4903 191.105 62.4903 191.833C62.4903 192.584 61.9184 193.101 61.1474 193.101C59.705 193.101 58.8098 193.101 57.3674 193.101C56.8203 193.101 56.3727 192.913 56.1488 192.42C55.9499 192.115 55.8255 191.27 56.6213 190.565C57.1436 189.978 62.9131 183.757 63.9327 182.677C63.9824 182.606 63.9327 182.512 63.8581 182.512C63.8332 182.512 63.8332 182.512 63.8084 182.512C61.6448 182.512 59.4812 182.512 57.3177 182.512C56.6711 182.512 56.1737 182.137 56.0494 181.573C55.9002 180.916 56.1986 180.352 56.7706 180.094C56.9446 180.023 57.1187 180 57.2928 180C60.5008 180 63.684 180 66.8921 180C67.5138 180 67.9366 180.188 68.1852 180.728C68.5085 181.409 68.1604 181.878 67.9366 182.183C67.4889 182.723 62.938 187.56 60.4262 190.283C60.4014 190.307 60.3765 190.354 60.3268 190.377C60.277 190.448 60.3268 190.565 60.4262 190.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M67.1856 193.101H64.6476C64.0007 193.101 63.4533 192.582 63.4533 191.968V191.732C63.4533 191.119 64.0007 190.6 64.6476 190.6H67.1856C67.8325 190.6 68.3799 191.119 68.3799 191.732V191.968C68.3799 192.605 67.8325 193.101 67.1856 193.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M70.7154 186.515C70.7154 184.734 70.7154 182.976 70.7154 181.195C70.7154 180.492 71.2374 180 71.9832 180C75.2148 180 78.4464 180 81.678 180C82.4238 180 82.9458 180.516 82.9458 181.242C82.9458 181.969 82.4238 182.508 81.678 182.508C78.9933 182.508 76.3334 182.508 73.6487 182.508C73.4498 182.508 73.4001 182.555 73.4001 182.742C73.4001 185.273 73.4001 187.804 73.4001 190.335C73.4001 190.546 73.4747 190.57 73.6487 190.57C76.3086 190.57 78.9933 190.57 81.6532 190.57C82.2498 190.57 82.7221 190.898 82.8961 191.39C83.095 191.976 82.8713 192.562 82.3989 192.89C82.1752 193.03 81.9018 193.101 81.6283 193.101C78.4464 193.101 75.2397 193.101 72.0578 193.101C71.2374 193.101 70.7403 192.585 70.7403 191.812C70.7154 190.031 70.7154 188.273 70.7154 186.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M57.7054 198.042V198.698H56V198.042H56.4263V196.259H56.0502V195.602H57.6803V196.259H57.3041V198.042H57.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M61.5344 198.758H60.7737L60.651 198.545C60.3811 198.687 60.0621 198.758 59.7676 198.758C58.9334 198.734 58.1482 198.167 58.1482 197.15C58.1482 196.181 58.8107 195.542 59.8167 195.542C60.651 195.542 61.4607 196.086 61.4607 197.15C61.4607 197.528 61.3626 197.812 61.2154 198.072L61.5589 198.64V198.758H61.5344ZM59.7922 198.001C60.1848 198.001 60.6019 197.718 60.6019 197.15C60.6019 196.583 60.1848 196.299 59.7922 196.299C59.375 196.299 58.9824 196.583 58.9824 197.15C58.9824 197.718 59.375 198.001 59.7922 198.001Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M63.7835 197.749C63.7835 197.96 64.0291 198.077 64.2992 198.077C64.5448 198.077 64.7904 197.96 64.7904 197.796C64.7904 197.49 64.422 197.49 64.201 197.467C63.6116 197.42 63.0223 197.209 63.0223 196.552C63.0223 195.871 63.6116 195.542 64.2747 195.542C64.8886 195.542 65.5271 195.871 65.5271 196.552H64.7167C64.7167 196.34 64.4957 196.246 64.2747 196.246C64.0782 196.246 63.8326 196.293 63.8326 196.528C63.8326 196.739 64.0291 196.786 64.3238 196.81C65.0114 196.857 65.6007 197.021 65.6007 197.772C65.6007 198.429 64.9377 198.758 64.2747 198.758C63.5871 198.758 62.924 198.429 62.9486 197.749H63.7835Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M68.2288 196.311H67.0377V196.76H68.1295V197.469H67.0377V197.966H68.2536V198.698H66.1692V195.602H68.2039V196.311H68.2288Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M71.603 198.58V198.698H70.7222L70.1594 197.729H69.719V198.698H68.8871V195.602C69.3765 195.602 69.8413 195.602 70.3062 195.602C71.0892 195.602 71.4562 196.169 71.4562 196.713C71.4562 197.067 71.285 197.422 70.9669 197.611L71.603 198.58ZM70.3062 197.067C70.5509 197.067 70.6488 196.878 70.6488 196.666C70.6488 196.476 70.5265 196.287 70.3062 196.287H69.719V197.067H70.3062Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M73.2761 197.334L73.553 196.527L73.9556 195.602H74.8868V195.72L73.4523 198.758H73.1L71.6655 195.72V195.602H72.5967L73.0245 196.527L73.2761 197.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M76.7814 198.042V198.698H75.0761V198.042H75.5024V196.259H75.1262V195.602H76.7564V196.259H76.3802V198.042H76.7814Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M78.8348 195.602C79.5091 195.602 80.2582 195.955 80.383 196.779H79.559C79.4341 196.45 79.1844 196.332 78.8348 196.332C78.3354 196.332 78.0108 196.662 78.0108 197.18C78.0108 197.627 78.3354 198.028 78.8348 198.028C79.1844 198.028 79.4841 197.863 79.559 197.533H80.383C80.2582 198.405 79.534 198.758 78.8348 198.758C77.9858 198.758 77.1618 198.216 77.1618 197.18C77.1618 196.12 77.9858 195.602 78.8348 195.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M82.9442 196.311H81.7531V196.76H82.8449V197.469H81.7531V197.966H82.969V198.698H80.8847V195.602H82.9194V196.311H82.9442Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M159.985 187.741H157.447C156.8 187.741 156.253 187.234 156.253 186.635V186.405C156.253 185.806 156.8 185.3 157.447 185.3H159.985C160.632 185.3 161.179 185.806 161.179 186.405V186.635C161.179 187.257 160.657 187.741 159.985 187.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M142.426 190.565C142.725 190.565 142.799 190.565 143.197 190.565C143.943 190.565 144.49 191.105 144.49 191.833C144.49 192.584 143.918 193.101 143.147 193.101C141.705 193.101 140.81 193.101 139.367 193.101C138.82 193.101 138.373 192.913 138.149 192.42C137.95 192.115 137.826 191.27 138.621 190.565C139.144 189.978 144.913 183.757 145.933 182.677C145.982 182.606 145.933 182.512 145.858 182.512C145.833 182.512 145.833 182.512 145.808 182.512C143.645 182.512 141.481 182.512 139.318 182.512C138.671 182.512 138.174 182.137 138.049 181.573C137.9 180.916 138.199 180.352 138.771 180.094C138.945 180.023 139.119 180 139.293 180C142.501 180 145.684 180 148.892 180C149.514 180 149.937 180.188 150.185 180.728C150.509 181.409 150.16 181.878 149.937 182.183C149.489 182.723 144.938 187.56 142.426 190.283C142.401 190.307 142.376 190.354 142.327 190.377C142.277 190.448 142.327 190.565 142.426 190.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M149.186 193.101H146.648C146.001 193.101 145.453 192.582 145.453 191.968V191.732C145.453 191.119 146.001 190.6 146.648 190.6H149.186C149.832 190.6 150.38 191.119 150.38 191.732V191.968C150.38 192.605 149.832 193.101 149.186 193.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M152.715 186.515C152.715 184.734 152.715 182.976 152.715 181.195C152.715 180.492 153.237 180 153.983 180C157.215 180 160.446 180 163.678 180C164.424 180 164.946 180.516 164.946 181.242C164.946 181.969 164.424 182.508 163.678 182.508C160.993 182.508 158.333 182.508 155.649 182.508C155.45 182.508 155.4 182.555 155.4 182.742C155.4 185.273 155.4 187.804 155.4 190.335C155.4 190.546 155.475 190.57 155.649 190.57C158.309 190.57 160.993 190.57 163.653 190.57C164.25 190.57 164.722 190.898 164.896 191.39C165.095 191.976 164.871 192.562 164.399 192.89C164.175 193.03 163.902 193.101 163.628 193.101C160.446 193.101 157.24 193.101 154.058 193.101C153.237 193.101 152.74 192.585 152.74 191.812C152.715 190.031 152.715 188.273 152.715 186.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M139.705 198.042V198.698H138V198.042H138.426V196.259H138.05V195.602H139.68V196.259H139.304V198.042H139.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M143.534 198.758H142.774L142.651 198.545C142.381 198.687 142.062 198.758 141.768 198.758C140.933 198.734 140.148 198.167 140.148 197.15C140.148 196.181 140.811 195.542 141.817 195.542C142.651 195.542 143.461 196.086 143.461 197.15C143.461 197.528 143.363 197.812 143.215 198.072L143.559 198.64V198.758H143.534ZM141.792 198.001C142.185 198.001 142.602 197.718 142.602 197.15C142.602 196.583 142.185 196.299 141.792 196.299C141.375 196.299 140.982 196.583 140.982 197.15C140.982 197.718 141.375 198.001 141.792 198.001Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M145.784 197.749C145.784 197.96 146.029 198.077 146.299 198.077C146.545 198.077 146.79 197.96 146.79 197.796C146.79 197.49 146.422 197.49 146.201 197.467C145.612 197.42 145.022 197.209 145.022 196.552C145.022 195.871 145.612 195.542 146.275 195.542C146.889 195.542 147.527 195.871 147.527 196.552H146.717C146.717 196.34 146.496 196.246 146.275 196.246C146.078 196.246 145.833 196.293 145.833 196.528C145.833 196.739 146.029 196.786 146.324 196.81C147.011 196.857 147.601 197.021 147.601 197.772C147.601 198.429 146.938 198.758 146.275 198.758C145.587 198.758 144.924 198.429 144.949 197.749H145.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M150.229 196.311H149.038V196.76H150.13V197.469H149.038V197.966H150.254V198.698H148.169V195.602H150.204V196.311H150.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M153.603 198.58V198.698H152.722L152.159 197.729H151.719V198.698H150.887V195.602C151.376 195.602 151.841 195.602 152.306 195.602C153.089 195.602 153.456 196.169 153.456 196.713C153.456 197.067 153.285 197.422 152.967 197.611L153.603 198.58ZM152.306 197.067C152.551 197.067 152.649 196.878 152.649 196.666C152.649 196.476 152.526 196.287 152.306 196.287H151.719V197.067H152.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M155.276 197.334L155.553 196.527L155.956 195.602H156.887V195.72L155.452 198.758H155.1L153.666 195.72V195.602H154.597L155.024 196.527L155.276 197.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M158.781 198.042V198.698H157.076V198.042H157.502V196.259H157.126V195.602H158.756V196.259H158.38V198.042H158.781Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M160.835 195.602C161.509 195.602 162.258 195.955 162.383 196.779H161.559C161.434 196.45 161.184 196.332 160.835 196.332C160.335 196.332 160.011 196.662 160.011 197.18C160.011 197.627 160.335 198.028 160.835 198.028C161.184 198.028 161.484 197.863 161.559 197.533H162.383C162.258 198.405 161.534 198.758 160.835 198.758C159.986 198.758 159.162 198.216 159.162 197.18C159.162 196.12 159.986 195.602 160.835 195.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M164.944 196.311H163.753V196.76H164.845V197.469H163.753V197.966H164.969V198.698H162.885V195.602H164.919V196.311H164.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M159.985 251.741H157.447C156.8 251.741 156.253 251.234 156.253 250.635V250.405C156.253 249.806 156.8 249.3 157.447 249.3H159.985C160.632 249.3 161.179 249.806 161.179 250.405V250.635C161.179 251.257 160.657 251.741 159.985 251.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M142.426 254.565C142.725 254.565 142.799 254.565 143.197 254.565C143.943 254.565 144.49 255.105 144.49 255.833C144.49 256.584 143.918 257.101 143.147 257.101C141.705 257.101 140.81 257.101 139.367 257.101C138.82 257.101 138.373 256.913 138.149 256.42C137.95 256.115 137.826 255.27 138.621 254.565C139.144 253.978 144.913 247.757 145.933 246.677C145.982 246.606 145.933 246.512 145.858 246.512C145.833 246.512 145.833 246.512 145.808 246.512C143.645 246.512 141.481 246.512 139.318 246.512C138.671 246.512 138.174 246.137 138.049 245.573C137.9 244.916 138.199 244.352 138.771 244.094C138.945 244.023 139.119 244 139.293 244C142.501 244 145.684 244 148.892 244C149.514 244 149.937 244.188 150.185 244.728C150.509 245.409 150.16 245.878 149.937 246.183C149.489 246.723 144.938 251.56 142.426 254.283C142.401 254.307 142.376 254.354 142.327 254.377C142.277 254.448 142.327 254.565 142.426 254.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M149.186 257.101H146.648C146.001 257.101 145.453 256.582 145.453 255.968V255.732C145.453 255.119 146.001 254.6 146.648 254.6H149.186C149.832 254.6 150.38 255.119 150.38 255.732V255.968C150.38 256.605 149.832 257.101 149.186 257.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M152.715 250.515C152.715 248.734 152.715 246.976 152.715 245.195C152.715 244.492 153.237 244 153.983 244C157.215 244 160.446 244 163.678 244C164.424 244 164.946 244.516 164.946 245.242C164.946 245.969 164.424 246.508 163.678 246.508C160.993 246.508 158.333 246.508 155.649 246.508C155.45 246.508 155.4 246.555 155.4 246.742C155.4 249.273 155.4 251.804 155.4 254.335C155.4 254.546 155.475 254.57 155.649 254.57C158.309 254.57 160.993 254.57 163.653 254.57C164.25 254.57 164.722 254.898 164.896 255.39C165.095 255.976 164.871 256.562 164.399 256.89C164.175 257.03 163.902 257.101 163.628 257.101C160.446 257.101 157.24 257.101 154.058 257.101C153.237 257.101 152.74 256.585 152.74 255.812C152.715 254.031 152.715 252.273 152.715 250.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M139.705 262.042V262.698H138V262.042H138.426V260.259H138.05V259.602H139.68V260.259H139.304V262.042H139.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M143.534 262.758H142.774L142.651 262.545C142.381 262.687 142.062 262.758 141.768 262.758C140.933 262.734 140.148 262.167 140.148 261.15C140.148 260.181 140.811 259.542 141.817 259.542C142.651 259.542 143.461 260.086 143.461 261.15C143.461 261.528 143.363 261.812 143.215 262.072L143.559 262.64V262.758H143.534ZM141.792 262.001C142.185 262.001 142.602 261.718 142.602 261.15C142.602 260.583 142.185 260.299 141.792 260.299C141.375 260.299 140.982 260.583 140.982 261.15C140.982 261.718 141.375 262.001 141.792 262.001Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M145.784 261.749C145.784 261.96 146.029 262.077 146.299 262.077C146.545 262.077 146.79 261.96 146.79 261.796C146.79 261.49 146.422 261.49 146.201 261.467C145.612 261.42 145.022 261.209 145.022 260.552C145.022 259.871 145.612 259.542 146.275 259.542C146.889 259.542 147.527 259.871 147.527 260.552H146.717C146.717 260.34 146.496 260.246 146.275 260.246C146.078 260.246 145.833 260.293 145.833 260.528C145.833 260.739 146.029 260.786 146.324 260.81C147.011 260.857 147.601 261.021 147.601 261.772C147.601 262.429 146.938 262.758 146.275 262.758C145.587 262.758 144.924 262.429 144.949 261.749H145.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M150.229 260.311H149.038V260.76H150.13V261.469H149.038V261.966H150.254V262.698H148.169V259.602H150.204V260.311H150.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M153.603 262.58V262.698H152.722L152.159 261.729H151.719V262.698H150.887V259.602C151.376 259.602 151.841 259.602 152.306 259.602C153.089 259.602 153.456 260.169 153.456 260.713C153.456 261.067 153.285 261.422 152.967 261.611L153.603 262.58ZM152.306 261.067C152.551 261.067 152.649 260.878 152.649 260.666C152.649 260.476 152.526 260.287 152.306 260.287H151.719V261.067H152.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M155.276 261.334L155.553 260.527L155.956 259.602H156.887V259.72L155.452 262.758H155.1L153.666 259.72V259.602H154.597L155.024 260.527L155.276 261.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M158.781 262.042V262.698H157.076V262.042H157.502V260.259H157.126V259.602H158.756V260.259H158.38V262.042H158.781Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M160.835 259.602C161.509 259.602 162.258 259.955 162.383 260.779H161.559C161.434 260.45 161.184 260.332 160.835 260.332C160.335 260.332 160.011 260.662 160.011 261.18C160.011 261.627 160.335 262.028 160.835 262.028C161.184 262.028 161.484 261.863 161.559 261.533H162.383C162.258 262.405 161.534 262.758 160.835 262.758C159.986 262.758 159.162 262.216 159.162 261.18C159.162 260.12 159.986 259.602 160.835 259.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M164.944 260.311H163.753V260.76H164.845V261.469H163.753V261.966H164.969V262.698H162.885V259.602H164.919V260.311H164.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M77.9851 251.741H75.4471C74.8002 251.741 74.2528 251.234 74.2528 250.635V250.405C74.2528 249.806 74.8002 249.3 75.4471 249.3H77.9851C78.632 249.3 79.1794 249.806 79.1794 250.405V250.635C79.1794 251.257 78.6569 251.741 77.9851 251.741Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M60.4262 254.565C60.7247 254.565 60.7993 254.565 61.1972 254.565C61.9432 254.565 62.4903 255.105 62.4903 255.833C62.4903 256.584 61.9184 257.101 61.1474 257.101C59.705 257.101 58.8098 257.101 57.3674 257.101C56.8203 257.101 56.3727 256.913 56.1488 256.42C55.9499 256.115 55.8255 255.27 56.6213 254.565C57.1436 253.978 62.9131 247.757 63.9327 246.677C63.9824 246.606 63.9327 246.512 63.8581 246.512C63.8332 246.512 63.8332 246.512 63.8084 246.512C61.6448 246.512 59.4812 246.512 57.3177 246.512C56.6711 246.512 56.1737 246.137 56.0494 245.573C55.9002 244.916 56.1986 244.352 56.7706 244.094C56.9446 244.023 57.1187 244 57.2928 244C60.5008 244 63.684 244 66.8921 244C67.5138 244 67.9366 244.188 68.1852 244.728C68.5085 245.409 68.1604 245.878 67.9366 246.183C67.4889 246.723 62.938 251.56 60.4262 254.283C60.4014 254.307 60.3765 254.354 60.3268 254.377C60.277 254.448 60.3268 254.565 60.4262 254.565Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M67.1856 257.101H64.6476C64.0007 257.101 63.4533 256.582 63.4533 255.968V255.732C63.4533 255.119 64.0007 254.6 64.6476 254.6H67.1856C67.8325 254.6 68.3799 255.119 68.3799 255.732V255.968C68.3799 256.605 67.8325 257.101 67.1856 257.101Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M70.7154 250.515C70.7154 248.734 70.7154 246.976 70.7154 245.195C70.7154 244.492 71.2374 244 71.9832 244C75.2148 244 78.4464 244 81.678 244C82.4238 244 82.9458 244.516 82.9458 245.242C82.9458 245.969 82.4238 246.508 81.678 246.508C78.9933 246.508 76.3334 246.508 73.6487 246.508C73.4498 246.508 73.4001 246.555 73.4001 246.742C73.4001 249.273 73.4001 251.804 73.4001 254.335C73.4001 254.546 73.4747 254.57 73.6487 254.57C76.3086 254.57 78.9933 254.57 81.6532 254.57C82.2498 254.57 82.7221 254.898 82.8961 255.39C83.095 255.976 82.8713 256.562 82.3989 256.89C82.1752 257.03 81.9018 257.101 81.6283 257.101C78.4464 257.101 75.2397 257.101 72.0578 257.101C71.2374 257.101 70.7403 256.585 70.7403 255.812C70.7154 254.031 70.7154 252.273 70.7154 250.515Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M57.7054 262.042V262.698H56V262.042H56.4263V260.259H56.0502V259.602H57.6803V260.259H57.3041V262.042H57.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M61.5344 262.758H60.7737L60.651 262.545C60.3811 262.687 60.0621 262.758 59.7676 262.758C58.9334 262.734 58.1482 262.167 58.1482 261.15C58.1482 260.181 58.8107 259.542 59.8167 259.542C60.651 259.542 61.4607 260.086 61.4607 261.15C61.4607 261.528 61.3626 261.812 61.2154 262.072L61.5589 262.64V262.758H61.5344ZM59.7922 262.001C60.1848 262.001 60.6019 261.718 60.6019 261.15C60.6019 260.583 60.1848 260.299 59.7922 260.299C59.375 260.299 58.9824 260.583 58.9824 261.15C58.9824 261.718 59.375 262.001 59.7922 262.001Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M63.7835 261.749C63.7835 261.96 64.0291 262.077 64.2992 262.077C64.5448 262.077 64.7904 261.96 64.7904 261.796C64.7904 261.49 64.422 261.49 64.201 261.467C63.6116 261.42 63.0223 261.209 63.0223 260.552C63.0223 259.871 63.6116 259.542 64.2747 259.542C64.8886 259.542 65.5271 259.871 65.5271 260.552H64.7167C64.7167 260.34 64.4957 260.246 64.2747 260.246C64.0782 260.246 63.8326 260.293 63.8326 260.528C63.8326 260.739 64.0291 260.786 64.3238 260.81C65.0114 260.857 65.6007 261.021 65.6007 261.772C65.6007 262.429 64.9377 262.758 64.2747 262.758C63.5871 262.758 62.924 262.429 62.9486 261.749H63.7835Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M68.2288 260.311H67.0377V260.76H68.1295V261.469H67.0377V261.966H68.2536V262.698H66.1692V259.602H68.2039V260.311H68.2288Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M71.603 262.58V262.698H70.7222L70.1594 261.729H69.719V262.698H68.8871V259.602C69.3765 259.602 69.8413 259.602 70.3062 259.602C71.0892 259.602 71.4562 260.169 71.4562 260.713C71.4562 261.067 71.285 261.422 70.9669 261.611L71.603 262.58ZM70.3062 261.067C70.5509 261.067 70.6488 260.878 70.6488 260.666C70.6488 260.476 70.5265 260.287 70.3062 260.287H69.719V261.067H70.3062Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M73.2761 261.334L73.553 260.527L73.9556 259.602H74.8868V259.72L73.4523 262.758H73.1L71.6655 259.72V259.602H72.5967L73.0245 260.527L73.2761 261.334Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M76.7814 262.042V262.698H75.0761V262.042H75.5024V260.259H75.1262V259.602H76.7564V260.259H76.3802V262.042H76.7814Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M78.8348 259.602C79.5091 259.602 80.2582 259.955 80.383 260.779H79.559C79.4341 260.45 79.1844 260.332 78.8348 260.332C78.3354 260.332 78.0108 260.662 78.0108 261.18C78.0108 261.627 78.3354 262.028 78.8348 262.028C79.1844 262.028 79.4841 261.863 79.559 261.533H80.383C80.2582 262.405 79.534 262.758 78.8348 262.758C77.9858 262.758 77.1618 262.216 77.1618 261.18C77.1618 260.12 77.9858 259.602 78.8348 259.602Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M82.9442 260.311H81.7531V260.76H82.8449V261.469H81.7531V261.966H82.969V262.698H80.8847V259.602H82.9194V260.311H82.9442Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <defs>
          <pattern id="8-2-pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use
              xlinkHref="#image0"
              transform="translate(-2.07767 -0.027972) scale(0.00351032 0.0022839)"
            />
          </pattern>
          <filter
            id="8-2-filter0_i"
            x="37"
            y="40"
            width="64.9399"
            height="47.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="8-2-filter1_i"
            x="119"
            y="40"
            width="64.9399"
            height="47.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="8-2-filter2_i"
            x="119"
            y="105"
            width="64.9399"
            height="47.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="8-2-filter3_i"
            x="37"
            y="105"
            width="64.9399"
            height="47.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="8-2-filter4_i"
            x="37"
            y="168"
            width="64.9399"
            height="47.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="8-2-filter5_i"
            x="119"
            y="168"
            width="64.9399"
            height="47.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="8-2-filter6_i"
            x="119"
            y="233"
            width="64.9399"
            height="47.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="8-2-filter7_i"
            x="37"
            y="233"
            width="64.9399"
            height="47.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <linearGradient
            id="8-2-paint0_linear"
            x1="301.5"
            y1="291"
            x2="301.5"
            y2="316"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DBDDE1" />
            <stop offset="1" stopColor="#F9F9F9" />
          </linearGradient>
        </defs>
      </svg>
      {!withoutBackground && <ContainerBackground />}
    </ProductImageWrapper>
  )
}
