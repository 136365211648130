import React, { FC, useCallback, useContext, useMemo, useEffect } from 'react'

import { AttributesTypes, IAttributeViewProps } from '../interface'
import {
  Select,
  Checkbox,
  HR,
  PriceView,
  Gallery,
  Slider,
  Radiobutton,
  Tabs,
  Sizes,
  NumberInput,
  Badge,
  Text,
  Switcher,
} from './index'
import { ProductsContext } from '../stage-calculator'
import { checkAttributeIsDisabled, splitString } from '../../../../utilits'
import { InputTypes } from '../../interface'

export const AttributeView: FC<IAttributeViewProps> = props => {
  const { id, type: attributeType, onSelect, notFilter = false, importantSelected } = props

  const { menuItemsData, selectedSubAttributeData: selected = {}, filters } = useContext(
    ProductsContext,
  )

  const selectedAttribute = useMemo(() => {
    const data = importantSelected || selected

    return data && data[id]
  }, [id, importantSelected, selected])

  const targetAttribute = useCallback(
    (targetKey?: string) => {
      const data = importantSelected || selected
      let result: string | Record<string, any> = ''

      if (targetKey) {
        const patch = targetKey.split('/')

        patch.forEach(p => {
          if (!result) {
            if (data !== null && data[p]) {
              result = data[p]
            }
          } else if (typeof result !== 'string') {
            result = result[p]
          }
        })
      }

      return result
    },
    [importantSelected, selected],
  )

  const items = useMemo(() => {
    // Отключенные конфигурации
    const isImages = item => ['square3VerticalCasement', 'square4VerticalCasement', 'square62Casement'].includes(item) 
    let result: any[] = []

    if (id && menuItemsData && menuItemsData[id]) {
      result = menuItemsData[id]
    }

    return result.filter(item => isImages(item?.images) === false)
  }, [id, menuItemsData])

  const handleSelect = useCallback(
    (key: string) => item => {
      if (typeof onSelect !== 'undefined') {
        onSelect(key, item, notFilter)
      }
    },
    [notFilter, onSelect],
  )

  const show = useMemo(() => {
    let result = false
    const data = importantSelected || selected

    if (props['groupTargetValue'] && props['groupTargetKey'] && data !== null) {
      const splited = props['groupTargetValue'].indexOf(',') !== -1
      const splitedValue =
        splited && props['groupTargetValue'] && splitString(props['groupTargetValue'])

      if (data[props['groupTargetKey']]) {
        if (splited) {
          splitedValue.some(value => {
            result = Boolean(data[props['groupTargetKey'] as string]['value'] === value)

            return result
          })
        } else if (data[props['groupTargetKey']]['value'] === props['groupTargetValue']) {
          result = true
        }
      }
    } else {
      result = true
    }

    return result
  }, [importantSelected, props, selected])

  const getComponent = useCallback(() => {
    let result: any
    const selectMethod = handleSelect(id)
    const isDisabled = checkAttributeIsDisabled(props['disabledKey'], filters)

    switch (attributeType) {
      case AttributesTypes.SELECT:
        result = (
          <Select
            {...props}
            onSelect={selectMethod}
            selected={selectedAttribute}
            items={items}
            disabled={isDisabled}
          />
        )
        break
      case AttributesTypes.GALLERY:
        result = (
          <Gallery
            {...props}
            onSelect={selectMethod}
            selected={selectedAttribute}
            items={items}
            disabled={isDisabled}
            show={show}
            isLastElement={props['isLastElement']}
          />
        )
        break
      case AttributesTypes.SLIDER:
        result = (
          <Slider
            {...props}
            onSelect={selectMethod}
            selected={selectedAttribute}
            items={items}
            disabled={isDisabled}
            show={show}
          />
        )
        break
      case AttributesTypes.RADIOBUTTON:
        result = (
          <Radiobutton
            {...props}
            onSelect={selectMethod}
            selected={selectedAttribute}
            items={items}
            disabled={isDisabled}
          />
        )
        break
      case AttributesTypes.TABS:
        result = (
          <Tabs
            {...props}
            onSelect={selectMethod}
            selected={selectedAttribute}
            items={items}
            disabled={isDisabled}
          />
        )
        break
      case AttributesTypes.CHECKBOX:
        result = (
          <Checkbox
            {...props}
            onSelect={selectMethod}
            selected={selectedAttribute}
            items={items}
            show={show}
            disabled={isDisabled || !show}
          />
        )
        break
      case AttributesTypes.SIZES:
        result = (
          <Sizes
            {...props}
            onSelect={selectMethod}
            selected={selectedAttribute}
            show={show}
            disabled={isDisabled}
          />
        )
        break
      case AttributesTypes.NUMBER:
        result = (
          <NumberInput
            {...props}
            onSelect={selectMethod}
            selected={selectedAttribute}
            disabled={!show}
            type={InputTypes.number}
          />
        )
        break
      case AttributesTypes.PRICE:
        result = <PriceView {...props} show={show} disabled={isDisabled} />
        break
      case AttributesTypes.HR:
        result = show ? <HR disabled={isDisabled} /> : null
        break
      case AttributesTypes.SWITCHER:
        result = <Switcher {...props} onSelect={selectMethod} selected={selectedAttribute} />
        break
      case AttributesTypes.BADGE:
        result = (
          <Badge
            {...props}
            value={targetAttribute(props['target'])}
            selected={selected}
            onSelect={onSelect}
            show={show}
            disabled={isDisabled}
          />
        )
        break
      case AttributesTypes.TEXT:
        result = <Text {...props} disabled={isDisabled} />
        break
      case AttributesTypes.BOOLEAN:
      case AttributesTypes.COMPARE:
      case AttributesTypes.TEXT_RANGE:
      case AttributesTypes.VIDEO_LINK:
      default:
        result = null
        break
    }

    return result
  }, [
    attributeType,
    filters,
    handleSelect,
    id,
    items,
    onSelect,
    props,
    selected,
    selectedAttribute,
    show,
    targetAttribute,
  ])

  return <>{getComponent()}</>
}
