import styled from 'styled-components'

export const SizesWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;

  position: relative;
`

export const SizesBlock = styled.div`
  display: flex;
  align-items: center;
`

export const SizeDivider = styled.span`
  padding: 0 5px;
`
