export const params1 = [
  { name: 'mounting', value: true, title: 'Нужен монтаж' },
]
export const params2 = [
  { name: 'mounting-kit', value: true, title: 'Нужен комплект для монтажа' },
  { name: 'demounting', value: true, title: 'Нужен демонтаж старого окна' },
  {
    name: 'garbage-removal',
    value: true,
    title: 'Вывоз мусора',
    description: 'Вывоз мусора с помощью контейнера',
  },
]

export const houseTypes = [
  { id: 'panel', value: 'panel', name: 'house-type', text: 'Панель' },
  { id: 'brick', value: 'brick', name: 'house-type', text: 'Кирпич' },
  { id: 'wood', value: 'wood', name: 'house-type', text: 'Дерево' },
]

export const mountingTypes = [
  { id: 'gost', value: 'gost', name: 'mounting-type', text: 'Технические условия (ТУ)' },
  { id: 'gost+', value: 'gost+', name: 'mounting-type', text: 'ТУ + материалы по ГОСТ' },
]

export const liftingItems = [
  { id: 'service-lifting', text: 'Подъем на грузовом лифте' },
  { id: 'ropes-lifting', text: 'Подъем на веревках' },
  { id: 'hands-lifting', text: 'Подъем руками' },
]

export const showStageInputList = ['ropes-lifting', 'hands-lifting']

export const cityKeys = ['city_with_type', 'settlement_with_type']

export const streetKeys = [
  'city',
  'street_with_type',
  'house_type',
  'house',
  'house_cadnum',
  'block_type',
  'block',
]
