import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement21Square41Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-1-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M132 255C132 253.895 131.105 253 130 253C128.895 253 128 253.895 128 255V269.56H132V255ZM132 269.56H128V271.4H127.94C127.421 271.4 127 271.821 127 272.34V274.12C127 275.158 127.842 276 128.88 276H130.12C131.158 276 132 275.158 132 274.12V271.4V271.4V269.56Z"
            fill="white"
          />
        </g>
        <rect
          width="3.99998"
          height="0.95833"
          transform="matrix(-1 0 0 1 132 270.25)"
          fill="#ABABAB"
        />
        <path
          d="M132 253.94C132 253.421 131.579 253 131.06 253H128.94C128.421 253 128 253.421 128 253.94V270.25H132V253.94Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M132 275.06C132 275.579 131.579 276 131.06 276H127.94C127.421 276 127 275.579 127 275.06V272.148C127 271.629 127.421 271.208 127.94 271.208H132V275.06Z"
          fill="url(#paint1_linear)"
        />
        <g filter="url(#2-1-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M132 46.9995C132 45.8952 131.105 45 130.001 45C128.896 45 128.001 45.8952 128.001 46.9995V49.1665H128.001V60H128V63.0005C128 64.1048 128.895 65 129.999 65C131.104 65 131.999 64.1048 131.999 63.0005V60.8332H132V50H132V46.9995Z"
            fill="url(#paint2_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 132 49.1665)"
          fill="#ABABAB"
        />
        <path
          d="M132 45.9399C132 45.4208 131.579 45 131.06 45H128.941C128.422 45 128.001 45.4208 128.001 45.9399V50H132V45.9399Z"
          fill="url(#paint3_linear)"
        />
        <path
          d="M128 64.0601C128 64.5792 128.421 65 128.94 65H131.059C131.578 65 131.999 64.5792 131.999 64.0601V60H128V64.0601Z"
          fill="url(#paint4_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 132 50.8334)"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#2-1-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M221 34H132V287H221V34ZM210 45H143V276H210V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M221 34H132V287H133.219V35H219.781V287H221V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-1-filter3_i)">
          <rect
            x="143.47"
            y="45.47"
            width="66.0601"
            height="230.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="136.53"
          y="38.53"
          width="79.9399"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="212"
          y="155"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#2-1-filter4_d)">
          <rect x="212.702" y="155.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M213.286 166.795C213.798 167.458 214.6 167.884 215.502 167.884C217.049 167.884 218.302 166.63 218.302 165.084V158.444C218.302 157.456 217.79 156.587 217.017 156.089C217.383 156.562 217.6 157.155 217.6 157.8V164.44C217.6 165.986 216.347 167.24 214.8 167.24C214.242 167.24 213.723 167.077 213.286 166.795Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M215.502 155.644C213.955 155.644 212.702 156.898 212.702 158.444V164.44C212.702 164.46 212.702 164.48 212.702 164.501V159.024C212.702 157.477 213.956 156.224 215.502 156.224C217.028 156.224 218.269 157.444 218.302 158.963V158.444C218.302 156.898 217.048 155.644 215.502 155.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-1-filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M215.5 159C214.672 159 214 159.672 214 160.5V160.714V162V180.5C214 181.328 214.672 182 215.5 182V182C216.328 182 217 181.328 217 180.5V164V160.5L217 160.286C217 159.576 216.424 159 215.714 159V159C215.677 159 215.64 159.001 215.604 159.004C215.569 159.001 215.535 159 215.5 159Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M144.5 273.114L208.425 160.999L144.5 47.9008L144.5 273.114Z" stroke="white" />
        <path d="M208.424 274.5L176.5 49.5584L144.576 274.5L208.424 274.5Z" stroke="white" />
        <defs>
          <filter
            id="2-1-filter0_d"
            x="125.12"
            y="251.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-1-filter1_d"
            x="126.12"
            y="43.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-1-filter2_d"
            x="127.3"
            y="29.3003"
            width="100.279"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-1-filter3_i"
            x="143"
            y="45"
            width="67.9399"
            height="233.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-1-filter4_d"
            x="210.822"
            y="153.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-1-filter5_d"
            x="212.12"
            y="157.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="125.2"
            y1="261.625"
            x2="132.8"
            y2="261.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="123.5"
            y1="273.604"
            x2="133"
            y2="273.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="134.8"
            y1="62.5"
            x2="127.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="125.202"
            y1="47.5"
            x2="132.8"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="134.798"
            y1="62.5"
            x2="127.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
