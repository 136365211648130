import React, {FC, useState, useCallback, useMemo, useContext, useRef} from 'react'
import { FloatBlock } from '../../../components/float-block'
import {
  StageOrderAcceptWrapper,
  StageOrderAdditionalBlock,
  StageOrderAdditionalBlockRow,
  StageOrderAdditionalBlockTotal,
  StageOrderAdditionalBlockWrapper,
  StageOrderAdditionalBlockPriceWrapper,
  StageOrderButtonDescription,
} from './styles'
import { Button, Checkbox, Info, Loader } from '../../../components'
import { ButtonViews, PayTypes } from '../interface'
import { LinkButton, LinkWrapper, LinkAnchor, RedErrorText } from '../styles'
import { StageCartBackButton } from '../stage-cart/styles'
import { ArrowLeftIcon } from '../../../assets/icons'
import { IStageOrderAdditionalBlock } from './interface'
import { ICheckboxItem } from '../stage-calculator/interface'
import { MainContext } from '../calculator'
import fetchAPI from 'src/lib/utils/fetch-api'

export const AdditionalBlock: FC<IStageOrderAdditionalBlock> = props => {
  const {
    mounting,
    products,
    totalCost,
    deliveryCost,
    deliveryCalculateProcess,
    mountingPrice,
    resultPrice,
    payType,
    delivery,
    isNotStandard,
    onOpenModal,
    onBackToCart,
    mountingCalculateInProcess,
    recipient,
    stageWrapperRef
  } = props
  const [accept, setAccept] = useState<ICheckboxItem | null>(null)
  const [isError, setError] = useState(false)
  const { currentOrderId } = useContext(MainContext)

  const handleAccept = useCallback(value => {
    setAccept(value)
  }, [])

  const textConfirm = useMemo(() => {
    const textArr: string[] = []
    const deliveryLifting = delivery?.['lifting']?.['id']
    const deliveryStage = delivery?.['stage'] || 0
    if (!accept?.['value']) {
      textArr.push('Необходимо согласиться с правилами персональных данных')
    }
    if (Boolean(recipient?.['last-name'] && recipient?.['first-name']) === false) {
      textArr.push('Необходимо заполнить ФИО')
    }
    if (recipient?.['isValidEmail'] === false) {
      textArr.push('Необходимо заполнить и подтвердить e-mail')
    }
    if (Boolean((recipient?.['valuePassportNumber']?.length === 10) && recipient?.['passportIssued']) === false) {
      textArr.push('Необходимо заполнить паспортные данные')
    }
    if (['service-lifting', 'ropes-lifting',  'hands-lifting'].includes(deliveryLifting) === false) {
      textArr.push('Необходимо заполнить особенности подъема')
    }
    if (['ropes-lifting',  'hands-lifting'].includes(deliveryLifting) && deliveryStage <= 0) {
      textArr.push('Необходимо заполнить этаж')
    }

    // console.log({ deliveryLifting, deliveryStage })

    // console.log(recipient)
    return textArr
  }, [accept, recipient, delivery])

  const canPayOnline = useMemo(() => {
    const isCardPay = payType.id === PayTypes.CARD
    const isAccepted = Boolean(accept?.['value']) 
    const isMouting = mounting?.['mounting'] || mounting?.['demounting'] || mounting?.['garbage-removal']
    if (isMouting) {
      return false
    }
    const isAcceptedTextConfirm = Boolean(textConfirm.length) === false
    return (
      isCardPay &&
      !isNotStandard &&
      delivery['city'] &&
      delivery['street'] &&
      isAccepted &&
      deliveryCost > 0 &&
      isAcceptedTextConfirm
    )    
  }, [accept, deliveryCost, delivery, isNotStandard, payType.id, mounting, textConfirm])

  const handlePay = useCallback(async () => {

    // @ts-ignore
    ym?.(82697449,'reachGoal','pay_order')
    console.info(82697449,'reachGoal','pay_order')
    await new Promise(res => setTimeout(res, 3000))
    // @ts-ignore
// eslint-disable-next-line

    const isAccepted = Boolean(textConfirm.length) === false
    if (isAccepted === false) {
      setError(false)
      return setError(true)
    }
    setError(false)
    
    
    const returnUrl = new URL(window.location.href)?.['origin'] ?? null
    if (returnUrl === null) {
      return
    }
    const result = await fetchAPI('/web-hooks/alfa-payment', {
      method: 'POST',
      body: JSON.stringify({
        'target-id': currentOrderId,
        'return-url': returnUrl,
      }),
    })
    const url = result?.['data']?.['formUrl'] ?? null
    if (url === null) {
      return
    }
    // @ts-ignore
// eslint-disable-next-line
    Object.assign(window.location, { href: url })
    /* TODO: redirect to pay system */
    // eslint-disable-next-line no-undef
    //alert( currentOrderId)
  }, [currentOrderId, textConfirm])


  const onOpenModalLocal = useCallback(() => {
    const isAccepted = Boolean(textConfirm.length) === false
    if (isAccepted) {
      setError(false)
      onOpenModal()
      return 
    }
    return setError(true)
  }, [onOpenModal, textConfirm])

  const StageOrderAdditionalBlockWrapperRef = useRef<HTMLDivElement>(null)
  return (
    <StageOrderAdditionalBlockWrapper ref={StageOrderAdditionalBlockWrapperRef}>
      <FloatBlock stageWrapperRef={StageOrderAdditionalBlockWrapperRef}>
        <StageOrderAdditionalBlock>
          <div>
            <StageOrderAdditionalBlockRow>
              <span>Количество изделий({products.length})</span> <span>{totalCost?.toLocaleString('ru-RU') || '0'} ₽</span>
            </StageOrderAdditionalBlockRow>
            {(deliveryCost > 0 || deliveryCalculateProcess) && (
              <StageOrderAdditionalBlockRow>
                <span>Доставка</span>{' '}
                {deliveryCalculateProcess && (
                  <StageOrderAdditionalBlockPriceWrapper>
                    <Loader />
                    <Info showIcon>Расчёт стоимости доставки может занять до 1 минуты</Info>
                  </StageOrderAdditionalBlockPriceWrapper>
                )}
                {!deliveryCalculateProcess && <span>{deliveryCost?.toLocaleString('ru-RU') || '0'} ₽</span>}
              </StageOrderAdditionalBlockRow>
            )}
            {(mountingPrice > 0 || mountingCalculateInProcess) && (
              <StageOrderAdditionalBlockRow>
                <span>Монтаж</span>{' '}
                <span>{mountingCalculateInProcess ? <Loader /> : `${mountingPrice?.toLocaleString('ru-RU') || '0'} ₽`}</span>
              </StageOrderAdditionalBlockRow>
            )}
          </div>
          <StageOrderAdditionalBlockTotal>
            <StageOrderAdditionalBlockRow>
              <b>Итого</b> <b>{resultPrice?.toLocaleString('ru-RU') || '0'} ₽</b>
            </StageOrderAdditionalBlockRow>
            <StageOrderAdditionalBlockRow>
              <span>Способ оплаты</span> <span>{payType.text}</span>
            </StageOrderAdditionalBlockRow>
          </StageOrderAdditionalBlockTotal>
          <Button typeView={ButtonViews.ORANGE} onClick={handlePay} disabled={true}>
            Оплатить
          </Button>
          <LinkWrapper>
            {isNotStandard && (
              <>
                <Button typeView={ButtonViews.ORANGE} onClick={onOpenModalLocal}>
                  Оформить с помощью специалиста
                </Button>
                {isError && Boolean(textConfirm.length) && textConfirm.map(text => <RedErrorText>{text}</RedErrorText>)}
                <StageOrderButtonDescription>
                  <li>Нестандартные изделия доступны к заказу только через специалиста</li>
                  <li>Стоимость заказа может измениться</li>
                </StageOrderButtonDescription>
              </>
            )}
            {!isNotStandard && (
              <>
              <LinkButton onClick={onOpenModalLocal}>Оформить с помощью специалиста</LinkButton>
              {isError && Boolean(textConfirm.length) && textConfirm.map(text => <RedErrorText>{text}</RedErrorText>)}
              </>
            )}
          </LinkWrapper>
          <StageOrderAcceptWrapper>
            <Checkbox
              items={[
                {
                  name: 'accept',
                  value: true,
                  title: 'Я согласен с условиями:',
                },
              ]}
              onSelect={handleAccept}
              id="accept"
              selected={accept}
            />
            <ol style={{ marginLeft: -24 }}>
              <li style={{ paddingLeft: 5 }}>
                <LinkAnchor target="_blank" href="/privacy-policy/">
                Политики конфиденциальности
                </LinkAnchor>
               </li> 
               <li style={{ paddingLeft: 5 }}>
                <LinkAnchor target="_blank" href="/client/oferta-s-pokupatelem">
                  Договора оферты
                </LinkAnchor>
               </li> 
            </ol>
          </StageOrderAcceptWrapper>
        </StageOrderAdditionalBlock>
        <StageCartBackButton onClick={onBackToCart}>
          <ArrowLeftIcon />
          <span>Вернуться в корзину</span>
        </StageCartBackButton>
      </FloatBlock>
    </StageOrderAdditionalBlockWrapper>
  )
}

