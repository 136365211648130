import React, {FC, useCallback, useState} from 'react'

import { IStageSelectType } from '../../../interface'
import {
  SelectBlock,
  SelectItem,
  SelectItems,
  SelectItemText,
  StageTypeWrapper,
  SubTitle,
  PreviewBlockStyle,
  SubDescription,
  PreviewBlockText,
} from '../../../styles'
import { QuizBottomBlock } from '../../components'

export const ViewType: FC<IStageSelectType> = props => {
  const { onChangeType, onChangeTypePremises, data, dataPremises, currentPremises, currentType, currentStep, steps, type } = props

  const handleSelect = useCallback(
    (newType: string) => () => {
      onChangeType(newType)
    },
    [onChangeType],
  )

  const handleSelectPremises = useCallback(
    (type: string, index?: any) => () => {
      if (type === 'model-1') {
        // @ts-ignore
        window?.ym?.(82697449,'reachGoal','calculator_apartment')
        console.info('calculator_apartment')
      }
      if (type === 'model-2' && index === 2) {
        // @ts-ignore
        window?.ym?.(82697449,'reachGoal','calculator_dacha')
        console.info('calculator_dacha')
      }
      if (type === 'model-3') {
        // @ts-ignore
        window?.ym?.(82697449,'reachGoal','calculator_cottage')
        console.info('calculator_cottage')
      }
      if (type === 'model-2' && index === 3) {
        // @ts-ignore
        window?.ym?.(82697449,'reachGoal','calculator_other')
        console.info('calculator_other')
      }

      onChangeTypePremises(type)
      //onChangeType(type)
    },
    [onChangeTypePremises, onChangeType],
  )

  const isActiveType = useCallback(type => currentType === type, [currentType])
  const isActivePremises = useCallback(premises => currentPremises === premises, [currentPremises])

  return (
    <StageTypeWrapper>
      <SelectBlock>
        <SubDescription></SubDescription>
        <SubTitle></SubTitle>
        <SelectItems>
          {data &&
            data.length > 0 &&
            data.map(item => (
              <SelectItem
                disabled={item['disabled']}
                key={item['value']}
                onClick={handleSelect(item['value'])}
                active={isActiveType(item['value'])}
              >
                <img src={item['preview-url']} alt="Окно" />
                <SelectItemText>{item['title']}</SelectItemText>
              </SelectItem>
            ))}
        </SelectItems>
        <SelectItems countColumns={dataPremises.length}>
          {dataPremises &&
            dataPremises.length > 0 &&
            dataPremises.map((item, index) => (
              <SelectItem
                key={`${item['code']}-${index}`}
                onClick={handleSelectPremises(item['value'], index)}
                active={isActivePremises(item['value'])}
              >
                <img src={item['preview-url']} alt="Окно" />
                <SelectItemText>{item['title']}</SelectItemText>
              </SelectItem>
            ))}
        </SelectItems>
        <QuizBottomBlock
          isActiveNextStep={Boolean(type)}
          currentStep={currentStep}
          steps={steps}
          onChangeType={onChangeType}
        />
      </SelectBlock>
      {/*<PreviewBlockStyle>
        <PreviewBlockText>Здесь появится результат подбора</PreviewBlockText>
      </PreviewBlockStyle>*/}
    </StageTypeWrapper>
  )
}
