import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement13Square5Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#1-3_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 162C32 160.895 31.1046 160 30 160C28.8954 160 28 160.895 28 162V176.56H32V162ZM32 176.56H28V178.4H27.9399C27.4208 178.4 27 178.821 27 179.34V181.12C27 182.158 27.8417 183 28.8799 183H30.1201C31.1583 183 32 182.158 32 181.12V178.4V178.4V176.56Z"
            fill="white"
          />
        </g>
        <rect
          width="3.99998"
          height="0.95833"
          transform="matrix(-1 0 0 1 32 177.25)"
          fill="#ABABAB"
        />
        <path
          d="M32 160.94C32 160.421 31.5792 160 31.0601 160H28.94C28.4208 160 28 160.421 28 160.94V177.25H32V160.94Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M32 182.06C32 182.579 31.5792 183 31.0601 183H27.94C27.4208 183 27 182.579 27 182.06V179.148C27 178.629 27.4208 178.208 27.94 178.208H32V182.06Z"
          fill="url(#paint1_linear)"
        />
        <g filter="url(#1-3_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 45.9995C32 44.8952 31.1048 44 30.0005 44C28.8962 44 28.001 44.8952 28.001 45.9995V48.1665H28.001V59H28V62.0005C28 63.1048 28.8952 64 29.9995 64C31.1038 64 31.999 63.1048 31.999 62.0005V59.8332H32V49H32V45.9995Z"
            fill="url(#paint2_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 32 48.166)"
          fill="#ABABAB"
        />
        <path
          d="M32 44.9399C32 44.4208 31.5792 44 31.0601 44H28.941C28.4219 44 28.001 44.4208 28.001 44.9399V49H32V44.9399Z"
          fill="url(#paint3_linear)"
        />
        <path
          d="M28 63.0601C28 63.5792 28.4208 64 28.9399 64H31.059C31.5781 64 31.999 63.5792 31.999 63.0601V59H28V63.0601Z"
          fill="url(#paint4_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 32 49.833)"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#1-3_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M152 34H32V193H152V34ZM141 45H43V182H141V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M152 34H32V193H33V35H151V193H152V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="36.53"
          y="38.53"
          width="110.94"
          height="149.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#1-3_filter3_i)">
          <rect
            x="43.47"
            y="45.47"
            width="97.0601"
            height="136.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="143"
          y="108"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#1-3_filter4_d)">
          <rect x="143.702" y="108.645" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M144.286 119.796C144.798 120.458 145.6 120.884 146.502 120.884C148.049 120.884 149.302 119.631 149.302 118.084V111.444C149.302 110.456 148.79 109.587 148.017 109.089C148.383 109.562 148.601 110.156 148.601 110.8V117.44C148.601 118.987 147.347 120.24 145.801 120.24C145.243 120.24 144.723 120.077 144.286 119.796Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M146.502 108.645C144.955 108.645 143.702 109.898 143.702 111.445V117.44C143.702 117.461 143.702 117.481 143.702 117.501V112.024C143.702 110.478 144.956 109.224 146.502 109.224C148.028 109.224 149.269 110.445 149.302 111.963V111.445C149.302 109.898 148.048 108.645 146.502 108.645Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-3_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M146.5 112C145.672 112 145 112.672 145 113.5V113.714V115V133.5C145 134.328 145.672 135 146.5 135C147.328 135 148 134.328 148 133.5V117V113.5L148 113.286C148 112.576 147.424 112 146.714 112C146.677 112 146.64 112.001 146.604 112.004C146.569 112.001 146.535 112 146.5 112Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M44.5 47.9587L44.5 180.041L139.126 114L44.5 47.9587Z" stroke="white" />
        <path d="M139.29 180.5L44.7102 180.5L92 48.4827L139.29 180.5Z" stroke="white" />
        <defs>
          <filter
            id="1-3_filter0_d"
            x="25.1201"
            y="158.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-3_filter1_d"
            x="26.1201"
            y="42.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-3_filter2_d"
            x="27.3003"
            y="29.3003"
            width="131.279"
            height="170.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-3_filter3_i"
            x="43"
            y="45"
            width="98.9399"
            height="139.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="1-3_filter4_d"
            x="141.822"
            y="106.765"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-3_filter5_d"
            x="143.12"
            y="110.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="25.2"
            y1="168.625"
            x2="32.8"
            y2="168.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="23.5"
            y1="180.604"
            x2="33"
            y2="180.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="34.8"
            y1="61.5"
            x2="27.2"
            y2="61.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="25.2018"
            y1="46.5"
            x2="32.7998"
            y2="46.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="34.7982"
            y1="61.5"
            x2="27.2002"
            y2="61.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
