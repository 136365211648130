import styled, { css } from 'styled-components'

import { NumberStyle } from '../number-input'
import { VerticalSizeType } from './interface'

export const StyledSizesTrack = styled.div`
  top: 0;
  bottom: 0;
  background: #d4d4d4;
`

export const VerticalSizesTrack = styled.div`
  left: 0;
  right: 0;
  width: 1px;
  height: 100%;

  position: absolute;

  background: #d4d4d4;
`

export const VerticalContentBlock = styled.div`
  display: flex;
  align-items: center;
`

export const VerticalContentSize = styled.div<{ height: number }>`
  height: ${({ height = 0 }) => height}px;
`

export const VerticalErrorsWrapper = styled.div<{ height: number }>`
  position: absolute;

  top: calc(50% - 10px);
  right: -86px;

  height: ${({ height = 0 }) => height}px;
`

export const VerticalErrorsContent = styled.span`
  position: absolute;

  right: 0;

  white-space: nowrap;

  transform: rotate(-90deg);
`

export const VerticalErrorsText = styled.span`
  font-size: 11px;
  line-height: 1.2;
  color: #ff453a;
`

export const VerticalSizeWrapper = styled.div<{ height: number; type?: VerticalSizeType }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  position: absolute;

  margin: 0 auto;
  padding: 0;

  top: 50%;
  
  ${({ type = VerticalSizeType.LEFT }) =>
    type === VerticalSizeType.LEFT &&
    css`
      left: 10px;
    `}
  
  ${({ type = VerticalSizeType.LEFT }) =>
    type === VerticalSizeType.RIGHT &&
    css`
      right: 10px;
    `}

  height: ${({ height = 0 }) => height}px;
  width: 24px;

  cursor: pointer;

  transform: translateY(-45%);

  &:empty {
    display: none;
  }
`

export const StyledVerticalSizeWrapper = styled.div<{ height: number }>`
  height: ${({ height }) => height}px;
  width: 2px;

  padding: 3px 0;

  position: relative;

  &:hover ${VerticalSizesTrack} {
    background-color: #d69a2d;
  }
`

export const VerticalThumbSizesInputNumberWrapper = styled.div<{
  show: boolean
  disabled?: boolean
  active?: boolean
}>`
  position: absolute;

  opacity: 0;
  transition: transform 0.3s;
  pointer-events: none;

  top: 50%;
  left: 0;

  width: 100%;

  z-index: 1;

  ${({ show = false }) =>
    show &&
    css`
      opacity: 1;
      pointer-events: initial;
    `}

  ${NumberStyle} {
    border: 1px solid #000000;
    border-radius: 10px;

    position: absolute;

    font-size: 16px;
    line-height: 22.4px;
    text-align: center;

    padding: 3px 0;

    width: 56px;

    left: -27px;
    bottom: calc(50% - 12px);
    transform: translateY(4px) rotate(-90deg);
    
    ${({ active }) =>
      active &&
      css`
        transform: translateY(4px) rotate(0deg);
      `}

    cursor: pointer;
  }
}
`
