import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement61Square62Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#6-1_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M137.001 209C138.105 209 139 208.105 139 207.001C139 205.896 138.105 205.001 137.001 205.001L134.833 205.001L134.833 205.001L124 205.001L124 205L120.999 205C119.895 205 119 205.895 119 206.999C119 208.104 119.895 208.999 120.999 208.999L123.167 208.999L123.167 209L134 209L134 209L137.001 209Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 134.833 209)"
          fill="#ABABAB"
        />
        <path
          d="M138.06 209C138.579 209 139 208.579 139 208.06L139 205.941C139 205.422 138.579 205.001 138.06 205.001L134 205.001L134 209L138.06 209Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M119.94 205C119.421 205 119 205.421 119 205.94L119 208.059C119 208.578 119.421 208.999 119.94 208.999L124 208.999L124 205L119.94 205Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 133.167 209)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#6-1_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M182.001 209C183.105 209 184 208.105 184 207.001C184 205.896 183.105 205.001 182.001 205.001L179.833 205.001L179.833 205.001L169 205.001L169 205L165.999 205C164.895 205 164 205.895 164 206.999C164 208.104 164.895 208.999 165.999 208.999L168.167 208.999L168.167 209L179 209L179 209L182.001 209Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 179.833 209)"
          fill="#ABABAB"
        />
        <path
          d="M183.06 209C183.579 209 184 208.579 184 208.06L184 205.941C184 205.422 183.579 205.001 183.06 205.001L179 205.001L179 209L183.06 209Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M164.94 205C164.421 205 164 205.421 164 205.94L164 208.059C164 208.578 164.421 208.999 164.94 208.999L169 208.999L169 205L164.94 205Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 178.167 209)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#6-1_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 209H115V287H188V209ZM177 220H126V276H177V220Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 209H115V287H116V210H187V287H188V209Z"
          fill="#FBFBFB"
        />
        <g filter="url(#6-1_filter3_i)">
          <rect
            x="126.47"
            y="220.47"
            width="50.0601"
            height="55.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="119.53"
          y="213.53"
          width="63.9399"
          height="68.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M127.776 221.5L151.5 273.79L175.224 221.5L127.776 221.5Z" stroke="white" />
        <rect
          x="158"
          y="278"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 158 278)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="157.356"
          y="278.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 157.356 278.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M157.238 280.695C156.892 279.542 155.822 278.702 154.556 278.702L147.916 278.702C146.37 278.702 145.116 279.955 145.116 281.502C145.116 281.537 145.117 281.572 145.118 281.607C145.464 282.76 146.534 283.6 147.8 283.6L154.44 283.6C155.986 283.6 157.24 282.346 157.24 280.8C157.24 280.765 157.239 280.73 157.238 280.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M155.881 279.034C155.737 279.012 155.59 279 155.44 279L148.8 279C147.254 279 146 280.253 146 281.8C146 282.867 146.597 283.795 147.475 284.267C146.138 284.056 145.116 282.898 145.116 281.502C145.116 279.955 146.37 278.702 147.916 278.702L154.556 278.702C155.035 278.702 155.487 278.822 155.881 279.034Z"
          fill="#FBFBFB"
        />
        <g filter="url(#6-1_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M154 281.5C154 280.672 153.328 280 152.5 280L152.286 280L151 280L132.5 280C131.672 280 131 280.672 131 281.5V281.5C131 282.328 131.672 283 132.5 283L149 283L152.5 283L152.714 283C153.424 283 154 282.424 154 281.714V281.714C154 281.677 153.999 281.64 153.996 281.604C153.999 281.569 154 281.535 154 281.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="6-1_filter0_d"
            x="117.12"
            y="203.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-1_filter1_d"
            x="162.12"
            y="203.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-1_filter2_d"
            x="110.3"
            y="204.3"
            width="84.2793"
            height="89.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-1_filter3_i"
            x="126"
            y="220"
            width="51.9399"
            height="58.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="6-1_filter4_d"
            x="129.28"
            y="278.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="121.5"
            y1="211.8"
            x2="121.5"
            y2="204.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="136.5"
            y1="202.202"
            x2="136.5"
            y2="209.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="121.5"
            y1="211.798"
            x2="121.5"
            y2="204.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="166.5"
            y1="211.8"
            x2="166.5"
            y2="204.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="181.5"
            y1="202.202"
            x2="181.5"
            y2="209.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="166.5"
            y1="211.798"
            x2="166.5"
            y2="204.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
