import { useCallback, useState } from 'react'

import { ISuggestItem, IUseSuggestionsAddress } from '../views/calculator/interface'
import fetchURL from '../../lib/utils/fetch-url'
import { DADATA_TOKEN } from '../services/environment'

export const useSuggestionsAddress: IUseSuggestionsAddress = (fromBound, suggestKey) => {
  const [suggestData, setSuggestData] = useState<ISuggestItem[]>([])

  //console.log({ suggestData })

  const handleSuggest = useCallback(
    async (query, props) => {
      let value: Record<string, any> = {
        query,
        count: 10,
      }

      if (fromBound) {
        value['from_bound'] = {
          value: fromBound,
        }
      }

      if (props) {
        value = {
          ...value,
          ...props,
        }
      }

      console.log(' send request to get address in use-suggestions-address.ts')
      const res = await fetchURL(
        'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${DADATA_TOKEN}`,
          },
          body: JSON.stringify(value),
        },
      )

      if (res && res['suggestions']) {
        const list: ISuggestItem[] = []
        const filteredCities: string[] = []

        res['suggestions'].forEach(suggest => {
          let result = suggest['value']

          if (suggestKey && suggestKey.length > 0) {
            suggestKey.some(key => {
              const value = suggest['data'][key]

              if (value) {
                result = value
              }

              return value
            })
          }

          if (!filteredCities.includes(result)) {
            list.push(suggest)
            filteredCities.push(result)
          }
        })

        setSuggestData(list)
      }

      return res
    },
    [fromBound, suggestKey],
  )

  return {
    suggestData,
    onSuggest: handleSuggest,
  }
}
