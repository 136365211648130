import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement23Square4VerticalCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-3_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.954 104.976C31.954 103.885 31.0691 103 29.9775 103C28.886 103 28.0011 103.885 28.0011 104.976L28.0011 107.167L28.001 107.167L28.001 118L27.9999 118L27.9999 121.024C27.9999 122.115 28.8848 123 29.9764 123C31.068 123 31.9529 122.115 31.9529 121.024L31.9529 118.833L31.954 118.833L31.954 108L31.954 108L31.954 104.976Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 31.954 107.166)"
          fill="#ABABAB"
        />
        <path
          d="M31.954 103.94C31.954 103.421 31.5332 103 31.0141 103L28.941 103C28.4219 103 28.0011 103.421 28.0011 103.94L28.0011 108L31.954 108L31.954 103.94Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M27.9999 122.06C27.9999 122.579 28.4208 123 28.9399 123L31.013 123C31.5321 123 31.9529 122.579 31.9529 122.06L31.9529 118L27.9999 118L27.9999 122.06Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 31.954 108.833)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#2-3_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.954 131.976C31.954 130.885 31.0691 130 29.9775 130C28.886 130 28.0011 130.885 28.0011 131.976L28.0011 134.167L28.001 134.167L28.001 145L27.9999 145L27.9999 148.024C27.9999 149.115 28.8848 150 29.9764 150C31.068 150 31.9529 149.115 31.9529 148.024L31.9529 145.833L31.954 145.833L31.954 135L31.954 135L31.954 131.976Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 31.954 134.166)"
          fill="#ABABAB"
        />
        <path
          d="M31.954 130.94C31.954 130.421 31.5332 130 31.0141 130L28.941 130C28.4219 130 28.0011 130.421 28.0011 130.94L28.0011 135L31.954 135L31.954 130.94Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M27.9999 149.06C27.9999 149.579 28.4208 150 28.9399 150L31.013 150C31.5321 150 31.9529 149.579 31.9529 149.06L31.9529 145L27.9999 145L27.9999 149.06Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 31.954 135.833)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#2-3_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 99H32.0001V154H188V99ZM178 109H42.0001V144H178V109Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 99H32.0001V154H33.0001V100H187V154H188V99Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-3_filter3_i)">
          <rect
            x="42.47"
            y="109.47"
            width="135.06"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.5301"
          y="103.53"
          width="146.94"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M43.5001 110.565L172.909 126.5L43.5001 142.435L43.5001 110.565Z" stroke="white" />
        <rect
          width="6.91954"
          height="13.5283"
          rx="3.45977"
          transform="matrix(-1 0 0 1 185.92 120)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#2-3_filter4_d)">
          <rect
            width="5.53563"
            height="12.2399"
            rx="2.76782"
            transform="matrix(-1 0 0 1 185.226 120.644)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M184.661 131.792C184.155 132.456 183.357 132.884 182.458 132.884C180.929 132.884 179.69 131.645 179.69 130.116V123.412C179.69 122.44 180.191 121.585 180.948 121.092C180.594 121.557 180.383 122.138 180.383 122.768V129.472C180.383 131.001 181.623 132.24 183.151 132.24C183.708 132.24 184.227 132.075 184.661 131.792Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M182.458 120.644C183.986 120.644 185.226 121.883 185.226 123.412V129.472C185.226 129.492 185.225 129.512 185.225 129.533V123.991C185.225 122.463 183.986 121.224 182.457 121.224C180.949 121.224 179.722 122.43 179.69 123.931V123.412C179.69 121.883 180.929 120.644 182.458 120.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-3_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M182.46 124C183.279 124 183.943 124.664 183.943 125.483V127C183.943 127.014 183.943 127.027 183.942 127.041L183.942 145.517C183.942 146.336 183.278 147 182.46 147V147C181.641 147 180.977 146.336 180.977 145.517L180.977 125.251C180.977 124.56 181.537 124 182.228 124V124C182.231 124 182.234 124 182.236 124C182.311 124 182.385 124 182.46 124V124Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="2-3_filter0_d"
            x="26.1201"
            y="101.12"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-3_filter1_d"
            x="26.1201"
            y="128.12"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-3_filter2_d"
            x="27.3004"
            y="94.3003"
            width="167.279"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-3_filter3_i"
            x="42.0001"
            y="109"
            width="136.94"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-3_filter4_d"
            x="177.81"
            y="118.764"
            width="11.1753"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-3_filter5_d"
            x="179.097"
            y="122.12"
            width="8.60556"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="34.7218"
            y1="120.5"
            x2="27.2092"
            y2="120.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="25.234"
            y1="105.5"
            x2="32.7446"
            y2="105.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="34.72"
            y1="120.5"
            x2="27.2093"
            y2="120.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="34.7218"
            y1="147.5"
            x2="27.2092"
            y2="147.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="25.234"
            y1="132.5"
            x2="32.7446"
            y2="132.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="34.72"
            y1="147.5"
            x2="27.2093"
            y2="147.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
