import React, { forwardRef } from 'react'
import ReactDOM from 'react-dom'
import { ButtonBackStyled } from './styles'
//import ArroeLeftSvg from 'public/static/svg/delivery/arrow-left.svg'

function ButtonBack({ onClick }, ref) {
  return ref.current ? ReactDOM.createPortal(
    <ButtonBackStyled onClick={onClick} >
      <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.74372 0.251051C7.08543 0.585786 7.08543 1.1285 6.74372 1.46323L2.11244 6L6.74372 10.5368C7.08543 10.8715 7.08543 11.4142 6.74372 11.7489C6.40201 12.0837 5.84799 12.0837 5.50628 11.7489L0.256282 6.60609C-0.0854271 6.27136 -0.0854271 5.72864 0.256282 5.39391L5.50628 0.251051C5.84799 -0.0836838 6.40201 -0.0836838 6.74372 0.251051Z" fill="#212121"/>
      </svg>
    </ ButtonBackStyled>,
    ref.current
  ) : null
}

export default forwardRef(ButtonBack as any)


