import React, { FC, useContext, useEffect, useMemo, useState } from 'react'

import { IStepByStepHelperProps, VIEW_TYPES } from './interface'
import { ProductsContext } from '../../views/calculator'
import { StepByStepWrapper } from './styles'
import { stepByStepHelperData } from './consts'
import { Tooltip } from '../tooltip'
import { ArrowRightIcon } from '../../assets/icons'

export const StepByStepHelper: FC<IStepByStepHelperProps> = props => {
  const { code, coord, view, arrowDown } = props
  const { stepByStepHelper } = useContext(ProductsContext)
  const [canShow, setCanShow] = useState(false)

  useEffect(() => {
    if (code) {
      const result = code === stepByStepHelper

      setCanShow(result)
    }
  }, [code, stepByStepHelper])

  const currentStep = useMemo(() => {
    return stepByStepHelperData[code] || {}
  }, [code])

  const arrowColor = useMemo(() => {
    return view === VIEW_TYPES.BLUE ? '#ffffff' : '#000000'
  }, [view])

  return (
    <>
      <StepByStepWrapper {...coord} show={canShow} view={view}>
        <Tooltip text={currentStep['text']} coord={{ x: 10, y: 25 }}>
          <ArrowRightIcon color={arrowColor} arrowDown={arrowDown} />
        </Tooltip>
      </StepByStepWrapper>
    </>
  )
}
