import React, { FC } from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'
import { IContainerSquareProps } from '../../interface'
import { ContainerBackground } from '../container-background'

export const ContainerSquare54Casement: FC<IContainerSquareProps> = props => {
  const { onCasementClick, withoutBackground } = props

  return (
    <ProductImageWrapper isClickable>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <rect width="449" height="333" fill="white" />
        <rect x="24" y="28" width="401" height="264" fill="#E8EAEE" />
        <rect x="24" y="28" width="401" height="264" fill="url(#5-4-pattern0)" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M439 10H10V292H24V29H425V292H439V10Z"
          fill="#C4C4C4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M439 292H10V308H0V316H10H439H449V308H439V292Z"
          fill="url(#5-4-paint0_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M425 28H23V292H425V28ZM97 41H37V279H97V41ZM116 41H176V279H116V41ZM254 41H194V279H254V41ZM273 41H333V279H273V41ZM412 41H352V279H412V41Z"
          fill="#E8EAEE"
        />
        <path d="M439 10L425 28.0571V292.075L439 308V10Z" fill="#E9EBEF" />
        <path d="M439 10L425 28.0571V292.075L439 308V10Z" fill="#020202" fillOpacity="0.06" />
        <path d="M10 10L24 28.1525V291.982L10 308V10Z" fill="#E9EBEF" />
        <path d="M10 10L24 28.1525V291.982L10 308V10Z" fill="#020202" fillOpacity="0.06" />
        <rect y="316" width="449" height="17" fill="white" />
        <g
          filter="url(#5-4-filter0_i)"
          className="casement"
          data-index="0"
          onClick={onCasementClick(0)}
        >
          <rect x="37" y="41" width="60" height="238" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="37.47"
          y="41.47"
          width="59.0601"
          height="237.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#5-4-filter1_i)"
          className="casement"
          data-index="1"
          onClick={onCasementClick(1)}
        >
          <rect x="116" y="41" width="60" height="238" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="116.47"
          y="41.47"
          width="59.0601"
          height="237.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#5-4-filter2_i)"
          className="casement"
          data-index="2"
          onClick={onCasementClick(2)}
        >
          <rect x="194" y="41" width="60" height="238" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="194.47"
          y="41.47"
          width="59.0601"
          height="237.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#5-4-filter3_i)"
          className="casement"
          data-index="3"
          onClick={onCasementClick(3)}
        >
          <rect x="273" y="41" width="60" height="238" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="273.47"
          y="41.47"
          width="59.0601"
          height="237.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <g
          filter="url(#5-4-filter4_i)"
          className="casement"
          data-index="4"
          onClick={onCasementClick(4)}
        >
          <rect x="352" y="41" width="60" height="238" fill="#B8C1FD" fillOpacity="0.4" />
        </g>
        <rect
          x="352.47"
          y="41.47"
          width="59.0601"
          height="237.06"
          stroke="#979797"
          strokeWidth="0.93994"
        />
        <path
          d="M75.9851 65.7413H73.4471C72.8002 65.7413 72.2528 65.2346 72.2528 64.6357V64.4054C72.2528 63.8065 72.8002 63.2998 73.4471 63.2998H75.9851C76.632 63.2998 77.1794 63.8065 77.1794 64.4054V64.6357C77.1794 65.2576 76.6569 65.7413 75.9851 65.7413Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M58.4262 68.5652C58.7247 68.5652 58.7993 68.5652 59.1972 68.5652C59.9432 68.5652 60.4903 69.1052 60.4903 69.833C60.4903 70.5843 59.9184 71.1008 59.1474 71.1008C57.705 71.1008 56.8098 71.1008 55.3674 71.1008C54.8203 71.1008 54.3727 70.913 54.1488 70.4199C53.9499 70.1147 53.8255 69.2695 54.6213 68.5652C55.1436 67.9782 60.9131 61.7565 61.9327 60.6765C61.9824 60.6061 61.9327 60.5122 61.8581 60.5122C61.8332 60.5122 61.8332 60.5122 61.8084 60.5122C59.6448 60.5122 57.4812 60.5122 55.3177 60.5122C54.6711 60.5122 54.1737 60.1365 54.0494 59.573C53.9002 58.9156 54.1986 58.3522 54.7706 58.0939C54.9446 58.0235 55.1187 58 55.2928 58C58.5008 58 61.684 58 64.8921 58C65.5138 58 65.9366 58.1878 66.1852 58.7278C66.5085 59.4087 66.1604 59.8783 65.9366 60.1835C65.4889 60.7235 60.938 65.56 58.4262 68.2834C58.4014 68.3069 58.3765 68.3539 58.3268 68.3773C58.277 68.4478 58.3268 68.5652 58.4262 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M65.1856 71.1007H62.6476C62.0007 71.1007 61.4533 70.5816 61.4533 69.9681V69.7322C61.4533 69.1187 62.0007 68.5996 62.6476 68.5996H65.1856C65.8325 68.5996 66.3799 69.1187 66.3799 69.7322V69.9681C66.3799 70.6052 65.8325 71.1007 65.1856 71.1007Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M68.7154 64.5152C68.7154 62.7341 68.7154 60.9764 68.7154 59.1952C68.7154 58.4922 69.2374 58 69.9832 58C73.2148 58 76.4464 58 79.678 58C80.4238 58 80.9458 58.5156 80.9458 59.2421C80.9458 59.9686 80.4238 60.5077 79.678 60.5077C76.9933 60.5077 74.3334 60.5077 71.6487 60.5077C71.4498 60.5077 71.4001 60.5545 71.4001 60.742C71.4001 63.2731 71.4001 65.8042 71.4001 68.3353C71.4001 68.5463 71.4747 68.5697 71.6487 68.5697C74.3086 68.5697 76.9933 68.5697 79.6532 68.5697C80.2498 68.5697 80.7221 68.8978 80.8961 69.39C81.095 69.9759 80.8712 70.5618 80.3989 70.8899C80.1752 71.0305 79.9018 71.1008 79.6283 71.1008C76.4464 71.1008 73.2397 71.1008 70.0578 71.1008C69.2374 71.1008 68.7403 70.5852 68.7403 69.8118C68.7154 68.0307 68.7154 66.273 68.7154 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M55.7054 76.0418V76.6986H54V76.0418H54.4263V74.2589H54.0502V73.6021H55.6803V74.2589H55.3041V76.0418H55.7054Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M59.5344 76.7581H58.7737L58.651 76.5453C58.3811 76.6872 58.0621 76.7581 57.7676 76.7581C56.9334 76.7345 56.1482 76.167 56.1482 75.1503C56.1482 74.1809 56.8107 73.5425 57.8167 73.5425C58.651 73.5425 59.4607 74.0863 59.4607 75.1503C59.4607 75.5286 59.3626 75.8124 59.2154 76.0724L59.5589 76.6399V76.7581H59.5344ZM57.7922 76.0015C58.1848 76.0015 58.6019 75.7178 58.6019 75.1503C58.6019 74.5828 58.1848 74.2991 57.7922 74.2991C57.375 74.2991 56.9824 74.5828 56.9824 75.1503C56.9824 75.7178 57.375 76.0015 57.7922 76.0015Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M61.7835 75.7488C61.7835 75.9601 62.0291 76.0774 62.2992 76.0774C62.5448 76.0774 62.7904 75.9601 62.7904 75.7958C62.7904 75.4906 62.422 75.4906 62.201 75.4672C61.6116 75.4202 61.0223 75.209 61.0223 74.5518C61.0223 73.8711 61.6116 73.5425 62.2747 73.5425C62.8886 73.5425 63.5271 73.8711 63.5271 74.5518H62.7167C62.7167 74.3405 62.4957 74.2466 62.2747 74.2466C62.0782 74.2466 61.8326 74.2936 61.8326 74.5283C61.8326 74.7395 62.0291 74.7865 62.3238 74.81C63.0114 74.8569 63.6007 75.0212 63.6007 75.7723C63.6007 76.4295 62.9377 76.7581 62.2747 76.7581C61.5871 76.7581 60.924 76.4295 60.9486 75.7488H61.7835Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M66.2288 74.3112H65.0377V74.7603H66.1295V75.4694H65.0377V75.9658H66.2536V76.6986H64.1692V73.6021H66.2039V74.3112H66.2288Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M69.603 76.5804V76.6986H68.7222L68.1594 75.7295H67.719V76.6986H66.8871V73.6021C67.3765 73.6021 67.8413 73.6021 68.3062 73.6021C69.0892 73.6021 69.4562 74.1694 69.4562 74.713C69.4562 75.0676 69.285 75.4222 68.9669 75.6113L69.603 76.5804ZM68.3062 75.0676C68.5509 75.0676 68.6488 74.8785 68.6488 74.6658C68.6488 74.4766 68.5265 74.2875 68.3062 74.2875H67.719V75.0676H68.3062Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M71.2761 75.3343L71.553 74.5275L71.9556 73.6021H72.8868V73.7207L71.4523 76.7582H71.1L69.6655 73.7207V73.6021H70.5967L71.0245 74.5275L71.2761 75.3343Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M74.7814 76.0418V76.6986H73.0761V76.0418H73.5024V74.2589H73.1262V73.6021H74.7564V74.2589H74.3802V76.0418H74.7814Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M76.8348 73.6021C77.5091 73.6021 78.2582 73.9553 78.383 74.7797H77.559C77.4341 74.45 77.1844 74.3322 76.8348 74.3322C76.3354 74.3322 76.0108 74.6619 76.0108 75.1801C76.0108 75.6276 76.3354 76.028 76.8348 76.028C77.1844 76.028 77.4841 75.8631 77.559 75.5334H78.383C78.2582 76.4049 77.534 76.7582 76.8348 76.7582C75.9858 76.7582 75.1618 76.2164 75.1618 75.1801C75.1618 74.1202 75.9858 73.6021 76.8348 73.6021Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M80.9442 74.3112H79.7531V74.7603H80.8449V75.4694H79.7531V75.9658H80.969V76.6986H78.8847V73.6021H80.9194V74.3112H80.9442Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M154.985 65.7413H152.447C151.8 65.7413 151.253 65.2346 151.253 64.6357V64.4054C151.253 63.8065 151.8 63.2998 152.447 63.2998H154.985C155.632 63.2998 156.179 63.8065 156.179 64.4054V64.6357C156.179 65.2576 155.657 65.7413 154.985 65.7413Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M137.426 68.5652C137.725 68.5652 137.799 68.5652 138.197 68.5652C138.943 68.5652 139.49 69.1052 139.49 69.833C139.49 70.5843 138.918 71.1008 138.147 71.1008C136.705 71.1008 135.81 71.1008 134.367 71.1008C133.82 71.1008 133.373 70.913 133.149 70.4199C132.95 70.1147 132.826 69.2695 133.621 68.5652C134.144 67.9782 139.913 61.7565 140.933 60.6765C140.982 60.6061 140.933 60.5122 140.858 60.5122C140.833 60.5122 140.833 60.5122 140.808 60.5122C138.645 60.5122 136.481 60.5122 134.318 60.5122C133.671 60.5122 133.174 60.1365 133.049 59.573C132.9 58.9156 133.199 58.3522 133.771 58.0939C133.945 58.0235 134.119 58 134.293 58C137.501 58 140.684 58 143.892 58C144.514 58 144.937 58.1878 145.185 58.7278C145.509 59.4087 145.16 59.8783 144.937 60.1835C144.489 60.7235 139.938 65.56 137.426 68.2834C137.401 68.3069 137.376 68.3539 137.327 68.3773C137.277 68.4478 137.327 68.5652 137.426 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M144.186 71.1007H141.648C141.001 71.1007 140.453 70.5816 140.453 69.9681V69.7322C140.453 69.1187 141.001 68.5996 141.648 68.5996H144.186C144.832 68.5996 145.38 69.1187 145.38 69.7322V69.9681C145.38 70.6052 144.832 71.1007 144.186 71.1007Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M147.715 64.5152C147.715 62.7341 147.715 60.9764 147.715 59.1952C147.715 58.4922 148.237 58 148.983 58C152.215 58 155.446 58 158.678 58C159.424 58 159.946 58.5156 159.946 59.2421C159.946 59.9686 159.424 60.5077 158.678 60.5077C155.993 60.5077 153.333 60.5077 150.649 60.5077C150.45 60.5077 150.4 60.5545 150.4 60.742C150.4 63.2731 150.4 65.8042 150.4 68.3353C150.4 68.5463 150.475 68.5697 150.649 68.5697C153.309 68.5697 155.993 68.5697 158.653 68.5697C159.25 68.5697 159.722 68.8978 159.896 69.39C160.095 69.9759 159.871 70.5618 159.399 70.8899C159.175 71.0305 158.902 71.1008 158.628 71.1008C155.446 71.1008 152.24 71.1008 149.058 71.1008C148.237 71.1008 147.74 70.5852 147.74 69.8118C147.715 68.0307 147.715 66.273 147.715 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M134.705 76.0418V76.6986H133V76.0418H133.426V74.2589H133.05V73.6021H134.68V74.2589H134.304V76.0418H134.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M138.534 76.7581H137.774L137.651 76.5453C137.381 76.6872 137.062 76.7581 136.768 76.7581C135.933 76.7345 135.148 76.167 135.148 75.1503C135.148 74.1809 135.811 73.5425 136.817 73.5425C137.651 73.5425 138.461 74.0863 138.461 75.1503C138.461 75.5286 138.363 75.8124 138.215 76.0724L138.559 76.6399V76.7581H138.534ZM136.792 76.0015C137.185 76.0015 137.602 75.7178 137.602 75.1503C137.602 74.5828 137.185 74.2991 136.792 74.2991C136.375 74.2991 135.982 74.5828 135.982 75.1503C135.982 75.7178 136.375 76.0015 136.792 76.0015Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M140.784 75.7488C140.784 75.9601 141.029 76.0774 141.299 76.0774C141.545 76.0774 141.79 75.9601 141.79 75.7958C141.79 75.4906 141.422 75.4906 141.201 75.4672C140.612 75.4202 140.022 75.209 140.022 74.5518C140.022 73.8711 140.612 73.5425 141.275 73.5425C141.889 73.5425 142.527 73.8711 142.527 74.5518H141.717C141.717 74.3405 141.496 74.2466 141.275 74.2466C141.078 74.2466 140.833 74.2936 140.833 74.5283C140.833 74.7395 141.029 74.7865 141.324 74.81C142.011 74.8569 142.601 75.0212 142.601 75.7723C142.601 76.4295 141.938 76.7581 141.275 76.7581C140.587 76.7581 139.924 76.4295 139.949 75.7488H140.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M145.229 74.3112H144.038V74.7603H145.13V75.4694H144.038V75.9658H145.254V76.6986H143.169V73.6021H145.204V74.3112H145.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M148.603 76.5804V76.6986H147.722L147.159 75.7295H146.719V76.6986H145.887V73.6021C146.376 73.6021 146.841 73.6021 147.306 73.6021C148.089 73.6021 148.456 74.1694 148.456 74.713C148.456 75.0676 148.285 75.4222 147.967 75.6113L148.603 76.5804ZM147.306 75.0676C147.551 75.0676 147.649 74.8785 147.649 74.6658C147.649 74.4766 147.526 74.2875 147.306 74.2875H146.719V75.0676H147.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M150.276 75.3343L150.553 74.5275L150.956 73.6021H151.887V73.7207L150.452 76.7582H150.1L148.666 73.7207V73.6021H149.597L150.024 74.5275L150.276 75.3343Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M153.781 76.0418V76.6986H152.076V76.0418H152.502V74.2589H152.126V73.6021H153.756V74.2589H153.38V76.0418H153.781Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M155.835 73.6021C156.509 73.6021 157.258 73.9553 157.383 74.7797H156.559C156.434 74.45 156.184 74.3322 155.835 74.3322C155.335 74.3322 155.011 74.6619 155.011 75.1801C155.011 75.6276 155.335 76.028 155.835 76.028C156.184 76.028 156.484 75.8631 156.559 75.5334H157.383C157.258 76.4049 156.534 76.7582 155.835 76.7582C154.986 76.7582 154.162 76.2164 154.162 75.1801C154.162 74.1202 154.986 73.6021 155.835 73.6021Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M159.944 74.3112H158.753V74.7603H159.845V75.4694H158.753V75.9658H159.969V76.6986H157.885V73.6021H159.919V74.3112H159.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M232.985 65.7413H230.447C229.8 65.7413 229.253 65.2346 229.253 64.6357V64.4054C229.253 63.8065 229.8 63.2998 230.447 63.2998H232.985C233.632 63.2998 234.179 63.8065 234.179 64.4054V64.6357C234.179 65.2576 233.657 65.7413 232.985 65.7413Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M215.426 68.5652C215.725 68.5652 215.799 68.5652 216.197 68.5652C216.943 68.5652 217.49 69.1052 217.49 69.833C217.49 70.5843 216.918 71.1008 216.147 71.1008C214.705 71.1008 213.81 71.1008 212.367 71.1008C211.82 71.1008 211.373 70.913 211.149 70.4199C210.95 70.1147 210.826 69.2695 211.621 68.5652C212.144 67.9782 217.913 61.7565 218.933 60.6765C218.982 60.6061 218.933 60.5122 218.858 60.5122C218.833 60.5122 218.833 60.5122 218.808 60.5122C216.645 60.5122 214.481 60.5122 212.318 60.5122C211.671 60.5122 211.174 60.1365 211.049 59.573C210.9 58.9156 211.199 58.3522 211.771 58.0939C211.945 58.0235 212.119 58 212.293 58C215.501 58 218.684 58 221.892 58C222.514 58 222.937 58.1878 223.185 58.7278C223.509 59.4087 223.16 59.8783 222.937 60.1835C222.489 60.7235 217.938 65.56 215.426 68.2834C215.401 68.3069 215.376 68.3539 215.327 68.3773C215.277 68.4478 215.327 68.5652 215.426 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M222.186 71.1007H219.648C219.001 71.1007 218.453 70.5816 218.453 69.9681V69.7322C218.453 69.1187 219.001 68.5996 219.648 68.5996H222.186C222.832 68.5996 223.38 69.1187 223.38 69.7322V69.9681C223.38 70.6052 222.832 71.1007 222.186 71.1007Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M225.715 64.5152C225.715 62.7341 225.715 60.9764 225.715 59.1952C225.715 58.4922 226.237 58 226.983 58C230.215 58 233.446 58 236.678 58C237.424 58 237.946 58.5156 237.946 59.2421C237.946 59.9686 237.424 60.5077 236.678 60.5077C233.993 60.5077 231.333 60.5077 228.649 60.5077C228.45 60.5077 228.4 60.5545 228.4 60.742C228.4 63.2731 228.4 65.8042 228.4 68.3353C228.4 68.5463 228.475 68.5697 228.649 68.5697C231.309 68.5697 233.993 68.5697 236.653 68.5697C237.25 68.5697 237.722 68.8978 237.896 69.39C238.095 69.9759 237.871 70.5618 237.399 70.8899C237.175 71.0305 236.902 71.1008 236.628 71.1008C233.446 71.1008 230.24 71.1008 227.058 71.1008C226.237 71.1008 225.74 70.5852 225.74 69.8118C225.715 68.0307 225.715 66.273 225.715 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M212.705 76.0418V76.6986H211V76.0418H211.426V74.2589H211.05V73.6021H212.68V74.2589H212.304V76.0418H212.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M216.534 76.7581H215.774L215.651 76.5453C215.381 76.6872 215.062 76.7581 214.768 76.7581C213.933 76.7345 213.148 76.167 213.148 75.1503C213.148 74.1809 213.811 73.5425 214.817 73.5425C215.651 73.5425 216.461 74.0863 216.461 75.1503C216.461 75.5286 216.363 75.8124 216.215 76.0724L216.559 76.6399V76.7581H216.534ZM214.792 76.0015C215.185 76.0015 215.602 75.7178 215.602 75.1503C215.602 74.5828 215.185 74.2991 214.792 74.2991C214.375 74.2991 213.982 74.5828 213.982 75.1503C213.982 75.7178 214.375 76.0015 214.792 76.0015Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M218.784 75.7488C218.784 75.9601 219.029 76.0774 219.299 76.0774C219.545 76.0774 219.79 75.9601 219.79 75.7958C219.79 75.4906 219.422 75.4906 219.201 75.4672C218.612 75.4202 218.022 75.209 218.022 74.5518C218.022 73.8711 218.612 73.5425 219.275 73.5425C219.889 73.5425 220.527 73.8711 220.527 74.5518H219.717C219.717 74.3405 219.496 74.2466 219.275 74.2466C219.078 74.2466 218.833 74.2936 218.833 74.5283C218.833 74.7395 219.029 74.7865 219.324 74.81C220.011 74.8569 220.601 75.0212 220.601 75.7723C220.601 76.4295 219.938 76.7581 219.275 76.7581C218.587 76.7581 217.924 76.4295 217.949 75.7488H218.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M223.229 74.3112H222.038V74.7603H223.13V75.4694H222.038V75.9658H223.254V76.6986H221.169V73.6021H223.204V74.3112H223.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M226.603 76.5804V76.6986H225.722L225.159 75.7295H224.719V76.6986H223.887V73.6021C224.376 73.6021 224.841 73.6021 225.306 73.6021C226.089 73.6021 226.456 74.1694 226.456 74.713C226.456 75.0676 226.285 75.4222 225.967 75.6113L226.603 76.5804ZM225.306 75.0676C225.551 75.0676 225.649 74.8785 225.649 74.6658C225.649 74.4766 225.526 74.2875 225.306 74.2875H224.719V75.0676H225.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M228.276 75.3343L228.553 74.5275L228.956 73.6021H229.887V73.7207L228.452 76.7582H228.1L226.666 73.7207V73.6021H227.597L228.024 74.5275L228.276 75.3343Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M231.781 76.0418V76.6986H230.076V76.0418H230.502V74.2589H230.126V73.6021H231.756V74.2589H231.38V76.0418H231.781Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M233.835 73.6021C234.509 73.6021 235.258 73.9553 235.383 74.7797H234.559C234.434 74.45 234.184 74.3322 233.835 74.3322C233.335 74.3322 233.011 74.6619 233.011 75.1801C233.011 75.6276 233.335 76.028 233.835 76.028C234.184 76.028 234.484 75.8631 234.559 75.5334H235.383C235.258 76.4049 234.534 76.7582 233.835 76.7582C232.986 76.7582 232.162 76.2164 232.162 75.1801C232.162 74.1202 232.986 73.6021 233.835 73.6021Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M237.944 74.3112H236.753V74.7603H237.845V75.4694H236.753V75.9658H237.969V76.6986H235.885V73.6021H237.919V74.3112H237.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M311.985 65.7413H309.447C308.8 65.7413 308.253 65.2346 308.253 64.6357V64.4054C308.253 63.8065 308.8 63.2998 309.447 63.2998H311.985C312.632 63.2998 313.179 63.8065 313.179 64.4054V64.6357C313.179 65.2576 312.657 65.7413 311.985 65.7413Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M294.426 68.5652C294.725 68.5652 294.799 68.5652 295.197 68.5652C295.943 68.5652 296.49 69.1052 296.49 69.833C296.49 70.5843 295.918 71.1008 295.147 71.1008C293.705 71.1008 292.81 71.1008 291.367 71.1008C290.82 71.1008 290.373 70.913 290.149 70.4199C289.95 70.1147 289.826 69.2695 290.621 68.5652C291.144 67.9782 296.913 61.7565 297.933 60.6765C297.982 60.6061 297.933 60.5122 297.858 60.5122C297.833 60.5122 297.833 60.5122 297.808 60.5122C295.645 60.5122 293.481 60.5122 291.318 60.5122C290.671 60.5122 290.174 60.1365 290.049 59.573C289.9 58.9156 290.199 58.3522 290.771 58.0939C290.945 58.0235 291.119 58 291.293 58C294.501 58 297.684 58 300.892 58C301.514 58 301.937 58.1878 302.185 58.7278C302.509 59.4087 302.16 59.8783 301.937 60.1835C301.489 60.7235 296.938 65.56 294.426 68.2834C294.401 68.3069 294.376 68.3539 294.327 68.3773C294.277 68.4478 294.327 68.5652 294.426 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M301.186 71.1007H298.648C298.001 71.1007 297.453 70.5816 297.453 69.9681V69.7322C297.453 69.1187 298.001 68.5996 298.648 68.5996H301.186C301.832 68.5996 302.38 69.1187 302.38 69.7322V69.9681C302.38 70.6052 301.832 71.1007 301.186 71.1007Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M304.715 64.5152C304.715 62.7341 304.715 60.9764 304.715 59.1952C304.715 58.4922 305.237 58 305.983 58C309.215 58 312.446 58 315.678 58C316.424 58 316.946 58.5156 316.946 59.2421C316.946 59.9686 316.424 60.5077 315.678 60.5077C312.993 60.5077 310.333 60.5077 307.649 60.5077C307.45 60.5077 307.4 60.5545 307.4 60.742C307.4 63.2731 307.4 65.8042 307.4 68.3353C307.4 68.5463 307.475 68.5697 307.649 68.5697C310.309 68.5697 312.993 68.5697 315.653 68.5697C316.25 68.5697 316.722 68.8978 316.896 69.39C317.095 69.9759 316.871 70.5618 316.399 70.8899C316.175 71.0305 315.902 71.1008 315.628 71.1008C312.446 71.1008 309.24 71.1008 306.058 71.1008C305.237 71.1008 304.74 70.5852 304.74 69.8118C304.715 68.0307 304.715 66.273 304.715 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M291.705 76.0418V76.6986H290V76.0418H290.426V74.2589H290.05V73.6021H291.68V74.2589H291.304V76.0418H291.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M295.534 76.7581H294.774L294.651 76.5453C294.381 76.6872 294.062 76.7581 293.768 76.7581C292.933 76.7345 292.148 76.167 292.148 75.1503C292.148 74.1809 292.811 73.5425 293.817 73.5425C294.651 73.5425 295.461 74.0863 295.461 75.1503C295.461 75.5286 295.363 75.8124 295.215 76.0724L295.559 76.6399V76.7581H295.534ZM293.792 76.0015C294.185 76.0015 294.602 75.7178 294.602 75.1503C294.602 74.5828 294.185 74.2991 293.792 74.2991C293.375 74.2991 292.982 74.5828 292.982 75.1503C292.982 75.7178 293.375 76.0015 293.792 76.0015Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M297.784 75.7488C297.784 75.9601 298.029 76.0774 298.299 76.0774C298.545 76.0774 298.79 75.9601 298.79 75.7958C298.79 75.4906 298.422 75.4906 298.201 75.4672C297.612 75.4202 297.022 75.209 297.022 74.5518C297.022 73.8711 297.612 73.5425 298.275 73.5425C298.889 73.5425 299.527 73.8711 299.527 74.5518H298.717C298.717 74.3405 298.496 74.2466 298.275 74.2466C298.078 74.2466 297.833 74.2936 297.833 74.5283C297.833 74.7395 298.029 74.7865 298.324 74.81C299.011 74.8569 299.601 75.0212 299.601 75.7723C299.601 76.4295 298.938 76.7581 298.275 76.7581C297.587 76.7581 296.924 76.4295 296.949 75.7488H297.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M302.229 74.3112H301.038V74.7603H302.13V75.4694H301.038V75.9658H302.254V76.6986H300.169V73.6021H302.204V74.3112H302.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M305.603 76.5804V76.6986H304.722L304.159 75.7295H303.719V76.6986H302.887V73.6021C303.376 73.6021 303.841 73.6021 304.306 73.6021C305.089 73.6021 305.456 74.1694 305.456 74.713C305.456 75.0676 305.285 75.4222 304.967 75.6113L305.603 76.5804ZM304.306 75.0676C304.551 75.0676 304.649 74.8785 304.649 74.6658C304.649 74.4766 304.526 74.2875 304.306 74.2875H303.719V75.0676H304.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M307.276 75.3343L307.553 74.5275L307.956 73.6021H308.887V73.7207L307.452 76.7582H307.1L305.666 73.7207V73.6021H306.597L307.024 74.5275L307.276 75.3343Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M310.781 76.0418V76.6986H309.076V76.0418H309.502V74.2589H309.126V73.6021H310.756V74.2589H310.38V76.0418H310.781Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M312.835 73.6021C313.509 73.6021 314.258 73.9553 314.383 74.7797H313.559C313.434 74.45 313.184 74.3322 312.835 74.3322C312.335 74.3322 312.011 74.6619 312.011 75.1801C312.011 75.6276 312.335 76.028 312.835 76.028C313.184 76.028 313.484 75.8631 313.559 75.5334H314.383C314.258 76.4049 313.534 76.7582 312.835 76.7582C311.986 76.7582 311.162 76.2164 311.162 75.1801C311.162 74.1202 311.986 73.6021 312.835 73.6021Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M316.944 74.3112H315.753V74.7603H316.845V75.4694H315.753V75.9658H316.969V76.6986H314.885V73.6021H316.919V74.3112H316.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M390.985 65.7413H388.447C387.8 65.7413 387.253 65.2346 387.253 64.6357V64.4054C387.253 63.8065 387.8 63.2998 388.447 63.2998H390.985C391.632 63.2998 392.179 63.8065 392.179 64.4054V64.6357C392.179 65.2576 391.657 65.7413 390.985 65.7413Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M373.426 68.5652C373.725 68.5652 373.799 68.5652 374.197 68.5652C374.943 68.5652 375.49 69.1052 375.49 69.833C375.49 70.5843 374.918 71.1008 374.147 71.1008C372.705 71.1008 371.81 71.1008 370.367 71.1008C369.82 71.1008 369.373 70.913 369.149 70.4199C368.95 70.1147 368.826 69.2695 369.621 68.5652C370.144 67.9782 375.913 61.7565 376.933 60.6765C376.982 60.6061 376.933 60.5122 376.858 60.5122C376.833 60.5122 376.833 60.5122 376.808 60.5122C374.645 60.5122 372.481 60.5122 370.318 60.5122C369.671 60.5122 369.174 60.1365 369.049 59.573C368.9 58.9156 369.199 58.3522 369.771 58.0939C369.945 58.0235 370.119 58 370.293 58C373.501 58 376.684 58 379.892 58C380.514 58 380.937 58.1878 381.185 58.7278C381.509 59.4087 381.16 59.8783 380.937 60.1835C380.489 60.7235 375.938 65.56 373.426 68.2834C373.401 68.3069 373.376 68.3539 373.327 68.3773C373.277 68.4478 373.327 68.5652 373.426 68.5652Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M380.186 71.1007H377.648C377.001 71.1007 376.453 70.5816 376.453 69.9681V69.7322C376.453 69.1187 377.001 68.5996 377.648 68.5996H380.186C380.832 68.5996 381.38 69.1187 381.38 69.7322V69.9681C381.38 70.6052 380.832 71.1007 380.186 71.1007Z"
          fill="#141B4D"
          fillOpacity="0.63"
          stroke="#767676"
          strokeWidth="0.0812062"
          strokeMiterlimit="10"
        />
        <path
          d="M383.715 64.5152C383.715 62.7341 383.715 60.9764 383.715 59.1952C383.715 58.4922 384.237 58 384.983 58C388.215 58 391.446 58 394.678 58C395.424 58 395.946 58.5156 395.946 59.2421C395.946 59.9686 395.424 60.5077 394.678 60.5077C391.993 60.5077 389.333 60.5077 386.649 60.5077C386.45 60.5077 386.4 60.5545 386.4 60.742C386.4 63.2731 386.4 65.8042 386.4 68.3353C386.4 68.5463 386.475 68.5697 386.649 68.5697C389.309 68.5697 391.993 68.5697 394.653 68.5697C395.25 68.5697 395.722 68.8978 395.896 69.39C396.095 69.9759 395.871 70.5618 395.399 70.8899C395.175 71.0305 394.902 71.1008 394.628 71.1008C391.446 71.1008 388.24 71.1008 385.058 71.1008C384.237 71.1008 383.74 70.5852 383.74 69.8118C383.715 68.0307 383.715 66.273 383.715 64.5152Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M370.705 76.0418V76.6986H369V76.0418H369.426V74.2589H369.05V73.6021H370.68V74.2589H370.304V76.0418H370.705Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M374.534 76.7581H373.774L373.651 76.5453C373.381 76.6872 373.062 76.7581 372.768 76.7581C371.933 76.7345 371.148 76.167 371.148 75.1503C371.148 74.1809 371.811 73.5425 372.817 73.5425C373.651 73.5425 374.461 74.0863 374.461 75.1503C374.461 75.5286 374.363 75.8124 374.215 76.0724L374.559 76.6399V76.7581H374.534ZM372.792 76.0015C373.185 76.0015 373.602 75.7178 373.602 75.1503C373.602 74.5828 373.185 74.2991 372.792 74.2991C372.375 74.2991 371.982 74.5828 371.982 75.1503C371.982 75.7178 372.375 76.0015 372.792 76.0015Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M376.784 75.7488C376.784 75.9601 377.029 76.0774 377.299 76.0774C377.545 76.0774 377.79 75.9601 377.79 75.7958C377.79 75.4906 377.422 75.4906 377.201 75.4672C376.612 75.4202 376.022 75.209 376.022 74.5518C376.022 73.8711 376.612 73.5425 377.275 73.5425C377.889 73.5425 378.527 73.8711 378.527 74.5518H377.717C377.717 74.3405 377.496 74.2466 377.275 74.2466C377.078 74.2466 376.833 74.2936 376.833 74.5283C376.833 74.7395 377.029 74.7865 377.324 74.81C378.011 74.8569 378.601 75.0212 378.601 75.7723C378.601 76.4295 377.938 76.7581 377.275 76.7581C376.587 76.7581 375.924 76.4295 375.949 75.7488H376.784Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M381.229 74.3112H380.038V74.7603H381.13V75.4694H380.038V75.9658H381.254V76.6986H379.169V73.6021H381.204V74.3112H381.229Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M384.603 76.5804V76.6986H383.722L383.159 75.7295H382.719V76.6986H381.887V73.6021C382.376 73.6021 382.841 73.6021 383.306 73.6021C384.089 73.6021 384.456 74.1694 384.456 74.713C384.456 75.0676 384.285 75.4222 383.967 75.6113L384.603 76.5804ZM383.306 75.0676C383.551 75.0676 383.649 74.8785 383.649 74.6658C383.649 74.4766 383.526 74.2875 383.306 74.2875H382.719V75.0676H383.306Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M386.276 75.3343L386.553 74.5275L386.956 73.6021H387.887V73.7207L386.452 76.7582H386.1L384.666 73.7207V73.6021H385.597L386.024 74.5275L386.276 75.3343Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M389.781 76.0418V76.6986H388.076V76.0418H388.502V74.2589H388.126V73.6021H389.756V74.2589H389.38V76.0418H389.781Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M391.835 73.6021C392.509 73.6021 393.258 73.9553 393.383 74.7797H392.559C392.434 74.45 392.184 74.3322 391.835 74.3322C391.335 74.3322 391.011 74.6619 391.011 75.1801C391.011 75.6276 391.335 76.028 391.835 76.028C392.184 76.028 392.484 75.8631 392.559 75.5334H393.383C393.258 76.4049 392.534 76.7582 391.835 76.7582C390.986 76.7582 390.162 76.2164 390.162 75.1801C390.162 74.1202 390.986 73.6021 391.835 73.6021Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <path
          d="M395.944 74.3112H394.753V74.7603H395.845V75.4694H394.753V75.9658H395.969V76.6986H393.885V73.6021H395.919V74.3112H395.944Z"
          fill="#141B4D"
          fillOpacity="0.63"
        />
        <defs>
          <pattern id="5-4-pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use
              xlinkHref="#image0"
              transform="translate(-0.00768784) scale(0.00100915 0.00153332)"
            />
          </pattern>
          <filter
            id="5-4-filter0_i"
            x="37"
            y="41"
            width="60.9399"
            height="240.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-4-filter1_i"
            x="116"
            y="41"
            width="60.9399"
            height="240.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-4-filter2_i"
            x="194"
            y="41"
            width="60.9399"
            height="240.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-4-filter3_i"
            x="273"
            y="41"
            width="60.9399"
            height="240.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-4-filter4_i"
            x="352"
            y="41"
            width="60.9399"
            height="240.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <linearGradient
            id="5-4-paint0_linear"
            x1="301.5"
            y1="291"
            x2="301.5"
            y2="316"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DBDDE1" />
            <stop offset="1" stopColor="#F9F9F9" />
          </linearGradient>
        </defs>
      </svg>
      {!withoutBackground && <ContainerBackground />}
    </ProductImageWrapper>
  )
}
