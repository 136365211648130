import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement21Square8Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-1_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M131 46.9995C131 45.8952 130.105 45 129.001 45C127.896 45 127.001 45.8952 127.001 46.9995V49.1665H127.001V60H127V63.0005C127 64.1048 127.895 65 128.999 65C130.104 65 130.999 64.1048 130.999 63.0005V60.8332H131V50H131V46.9995Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 131 49.1665)"
          fill="#ABABAB"
        />
        <path
          d="M131 45.9399C131 45.4208 130.579 45 130.06 45H127.941C127.422 45 127.001 45.4208 127.001 45.9399V50H131V45.9399Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M127 64.0601C127 64.5792 127.421 65 127.94 65H130.059C130.578 65 130.999 64.5792 130.999 64.0601V60H127V64.0601Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 131 50.8335)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#2-1_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M131 125.999C131 124.895 130.105 124 129.001 124C127.896 124 127.001 124.895 127.001 125.999V128.167H127.001V139H127V142.001C127 143.105 127.895 144 128.999 144C130.104 144 130.999 143.105 130.999 142.001V139.833H131V129H131V125.999Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 131 128.167)"
          fill="#ABABAB"
        />
        <path
          d="M131 124.94C131 124.421 130.579 124 130.06 124H127.941C127.422 124 127.001 124.421 127.001 124.94V129H131V124.94Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M127 143.06C127 143.579 127.421 144 127.94 144H130.059C130.578 144 130.999 143.579 130.999 143.06V139H127V143.06Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 131 129.833)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#2-1_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M220 34H131V155H220V34ZM209 45H142V144H209V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M220 34H131V155H132.219V35H218.781V155H220V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-1_filter3_i)">
          <rect
            x="142.47"
            y="45.47"
            width="66.0601"
            height="98.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="135.53"
          y="38.53"
          width="79.9399"
          height="111.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="211"
          y="87"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#2-1_filter4_d)">
          <rect x="211.702" y="87.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M212.286 98.7956C212.798 99.4577 213.6 99.8841 214.502 99.8841C216.049 99.8841 217.302 98.6305 217.302 97.0841V90.4442C217.302 89.4557 216.79 88.5869 216.017 88.0886C216.383 88.5619 216.6 89.1556 216.6 89.8001V96.44C216.6 97.9864 215.347 99.24 213.8 99.24C213.242 99.24 212.723 99.0768 212.286 98.7956Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M214.502 87.644C212.955 87.644 211.702 88.8976 211.702 90.444V96.4397C211.702 96.4601 211.702 96.4805 211.702 96.5007V91.0236C211.702 89.4772 212.956 88.2236 214.502 88.2236C216.028 88.2236 217.269 89.4444 217.302 90.9627V90.444C217.302 88.8976 216.048 87.644 214.502 87.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-1_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M214.5 91C213.672 91 213 91.6716 213 92.5V92.7143V94V112.5C213 113.328 213.672 114 214.5 114V114C215.328 114 216 113.328 216 112.5V96V92.5L216 92.2857C216 91.5756 215.424 91 214.714 91V91C214.677 91 214.64 91.0012 214.604 91.0035C214.569 91.0012 214.535 91 214.5 91Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M143.5 46.9969L207.164 94.5L143.5 142.003L143.5 46.9969Z" stroke="white" />
        <defs>
          <filter
            id="2-1_filter0_d"
            x="125.12"
            y="43.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-1_filter1_d"
            x="125.12"
            y="122.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-1_filter2_d"
            x="126.3"
            y="29.3003"
            width="100.279"
            height="132.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-1_filter3_i"
            x="142"
            y="45"
            width="67.9399"
            height="101.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-1_filter4_d"
            x="209.822"
            y="85.7642"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-1_filter5_d"
            x="211.12"
            y="89.1201"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="133.8"
            y1="62.5"
            x2="126.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="124.202"
            y1="47.5"
            x2="131.8"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="133.798"
            y1="62.5"
            x2="126.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="133.8"
            y1="141.5"
            x2="126.2"
            y2="141.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="124.202"
            y1="126.5"
            x2="131.8"
            y2="126.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="133.798"
            y1="141.5"
            x2="126.2"
            y2="141.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
