import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement51Square3Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#5-1_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M164 129.999C164 128.895 163.105 128 162.001 128C160.896 128 160.001 128.895 160.001 129.999V132.167H160.001V143H160V146.001C160 147.105 160.895 148 161.999 148C163.104 148 163.999 147.105 163.999 146.001V143.833H164V133H164V129.999Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 164 132.166)"
          fill="#ABABAB"
        />
        <path
          d="M164 128.94C164 128.421 163.579 128 163.06 128H160.941C160.422 128 160.001 128.421 160.001 128.94V133H164V128.94Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M160 147.06C160 147.579 160.421 148 160.94 148H163.059C163.578 148 163.999 147.579 163.999 147.06V143H160V147.06Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 164 133.833)"
          fill="url(#paint5-1_linear)"
        />
        <g filter="url(#5-1_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M164 174.999C164 173.895 163.105 173 162.001 173C160.896 173 160.001 173.895 160.001 174.999V177.167H160.001V188H160V191.001C160 192.105 160.895 193 161.999 193C163.104 193 163.999 192.105 163.999 191.001V188.833H164V178H164V174.999Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 164 177.166)"
          fill="#ABABAB"
        />
        <path
          d="M164 173.94C164 173.421 163.579 173 163.06 173H160.941C160.422 173 160.001 173.421 160.001 173.94V178H164V173.94Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M160 192.06C160 192.579 160.421 193 160.94 193H163.059C163.578 193 163.999 192.579 163.999 192.06V188H160V192.06Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 164 178.833)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#5-1_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M284 122H164V199H284V122ZM273 133H175V188H273V133Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#5-1_filter5-1_i)">
          <rect
            x="175.47"
            y="133.47"
            width="97.0601"
            height="54.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="169.5" y="127.5" width="109" height="66" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M284 122H164V199H165V123H283V199H284V122Z"
          fill="#FBFBFB"
        />
        <path d="M176.5 186.343L176.5 134.657L270.121 160.5L176.5 186.343Z" stroke="white" />
        <rect
          x="275"
          y="153"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#5-1_filter4_d)">
          <rect x="275.702" y="153.645" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M276.286 164.796C276.798 165.458 277.6 165.884 278.502 165.884C280.049 165.884 281.302 164.631 281.302 163.084V156.444C281.302 155.456 280.79 154.587 280.017 154.089C280.383 154.562 280.601 155.156 280.601 155.8V162.44C280.601 163.987 279.347 165.24 277.801 165.24C277.243 165.24 276.723 165.077 276.286 164.796Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M278.502 153.645C276.956 153.645 275.702 154.898 275.702 156.445V162.44C275.702 162.461 275.702 162.481 275.703 162.501V157.024C275.703 155.478 276.956 154.224 278.503 154.224C280.029 154.224 281.27 155.445 281.302 156.963V156.445C281.302 154.898 280.049 153.645 278.502 153.645Z"
          fill="#FBFBFB"
        />
        <g filter="url(#5-1_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M278.5 157C277.672 157 277 157.672 277 158.5V158.714V160V178.5C277 179.328 277.672 180 278.5 180C279.328 180 280 179.328 280 178.5V162V158.5L280 158.286C280 157.576 279.424 157 278.714 157C278.677 157 278.64 157.001 278.604 157.004C278.569 157.001 278.535 157 278.5 157Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="5-1_filter0_d"
            x="158.12"
            y="126.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-1_filter1_d"
            x="158.12"
            y="171.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-1_filter2_d"
            x="159.3"
            y="117.3"
            width="131.279"
            height="88.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-1_filter5-1_i"
            x="175"
            y="133"
            width="98.9399"
            height="57.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-1_filter4_d"
            x="273.822"
            y="151.765"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-1_filter5_d"
            x="275.12"
            y="155.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="166.8"
            y1="145.5"
            x2="159.2"
            y2="145.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="157.202"
            y1="130.5"
            x2="164.8"
            y2="130.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="166.798"
            y1="145.5"
            x2="159.2"
            y2="145.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5-1_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="166.8"
            y1="190.5"
            x2="159.2"
            y2="190.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="157.202"
            y1="175.5"
            x2="164.8"
            y2="175.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="166.798"
            y1="190.5"
            x2="159.2"
            y2="190.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
