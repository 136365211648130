import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement12Square82Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#1filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 38.9995C32 37.8952 31.1048 37 30.0005 37C28.8962 37 28.001 37.8952 28.001 38.9995V41.1665H28.001V52H28V55.0005C28 56.1048 28.8952 57 29.9995 57C31.1038 57 31.999 56.1048 31.999 55.0005V52.8332H32V42H32V38.9995Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 32 41.1665)"
          fill="#ABABAB"
        />
        <path
          d="M32 37.9399C32 37.4208 31.5792 37 31.0601 37H28.941C28.4219 37 28.001 37.4208 28.001 37.9399V42H32V37.9399Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M28 56.0601C28 56.5792 28.4208 57 28.9399 57H31.059C31.5781 57 31.999 56.5792 31.999 56.0601V52H28V56.0601Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 32 42.8335)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#1filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 63C32 61.8954 31.1046 61 30 61C28.8954 61 28 61.8954 28 63V77.5599H32V63ZM32 77.5602H28V79.4H27.9399C27.4208 79.4 27 79.8209 27 80.34V82.1201C27 83.1584 27.8417 84 28.8799 84H30.1201C31.1583 84 32 83.1584 32 82.1201V79.4002V79.4V77.5602Z"
            fill="white"
          />
        </g>
        <rect
          width="3.99998"
          height="0.95833"
          transform="matrix(-1 0 0 1 32 78.25)"
          fill="#ABABAB"
        />
        <path
          d="M32 61.9399C32 61.4208 31.5792 61 31.0601 61H28.94C28.4208 61 28 61.4208 28 61.9399V78.2499H32V61.9399Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M32 83.0601C32 83.5792 31.5792 84 31.0601 84H27.94C27.4208 84 27 83.5792 27 83.0601V80.1483C27 79.6292 27.4208 79.2083 27.94 79.2083H32V83.0601Z"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#1filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M105 33H32V88H105V33ZM95 43H42V78H95V43Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105 33H32V88H33V34H104V88H105V33Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1filter3_i)">
          <rect
            x="42.47"
            y="43.47"
            width="52.0601"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="37.53"
          width="63.9399"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect x="97" y="53" width="7" height="13.5283" rx="3.5" fill="#9B9DA2" fillOpacity="0.19" />
        <g filter="url(#1filter4_d)">
          <rect x="97.7018" y="53.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M98.2859 64.7956C98.7981 65.4577 99.6003 65.8841 100.502 65.8841C102.049 65.8841 103.302 64.6305 103.302 63.0841V56.4442C103.302 55.4557 102.79 54.5869 102.017 54.0886C102.383 54.5619 102.6 55.1556 102.6 55.8001V62.44C102.6 63.9864 101.347 65.24 99.8003 65.24C99.2424 65.24 98.7225 65.0768 98.2859 64.7956Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M100.502 53.644C98.9554 53.644 97.7018 54.8976 97.7018 56.444V62.4397C97.7018 62.4601 97.7021 62.4805 97.7025 62.5007V57.0236C97.7025 55.4772 98.9561 54.2236 100.502 54.2236C102.029 54.2236 103.269 55.4444 103.302 56.9627V56.444C103.302 54.8976 102.048 53.644 100.502 53.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M100.5 57C99.6716 57 99 57.6716 99 58.5V58.7143V60V78.5C99 79.3284 99.6716 80 100.5 80V80C101.328 80 102 79.3284 102 78.5V62V58.5L102 58.2857C102 57.5756 101.424 57 100.714 57V57C100.677 57 100.64 57.0012 100.604 57.0035C100.569 57.0012 100.535 57 100.5 57Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M43.5 76.3063L92.3756 60.0144L43.5 44.6809L43.5 76.3063Z" stroke="white" />
        <path d="M44.0182 76.5L68.5 44.8177L92.9818 76.5H44.0182Z" stroke="white" />
        <defs>
          <filter
            id="1filter0_d"
            x="26.1201"
            y="35.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1filter1_d"
            x="25.1201"
            y="59.1201"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1filter2_d"
            x="27.3003"
            y="28.3003"
            width="84.2793"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1filter3_i"
            x="42"
            y="43"
            width="53.9399"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="1filter4_d"
            x="95.822"
            y="51.7642"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1filter5_d"
            x="97.1201"
            y="55.1201"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="34.8"
            y1="54.5"
            x2="27.2"
            y2="54.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="25.2018"
            y1="39.5"
            x2="32.7998"
            y2="39.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="34.7982"
            y1="54.5"
            x2="27.2002"
            y2="54.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="25.2"
            y1="69.625"
            x2="32.8"
            y2="69.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="23.5"
            y1="81.6042"
            x2="33"
            y2="81.6042"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
