import styled, { css } from 'styled-components'
import ReactSlider from 'react-slider'
import { colorCode } from '../../views/calculator/stage-calculator/consts'

export const SimpleSliderWrapper = styled.div`
  margin-bottom: 10px;
  min-height: 110px;
`

export const SimpleSliderTitle = styled.h3`
  margin-bottom: 17px;

  display: flex;
  align-items: center;

  span {
    margin-right: 10px;

    font-size: 28px;
    font-weight: 400;
  }
`

export const SimpleSliderContent = styled.div`
  display: flex;
  justify-content: center;
`

export const StyledSimpleSlider = styled(ReactSlider)<{ columnCount?: number }>`
  width: 80%;

  display: grid;
  grid-template-columns: ${({ columnCount = 1 }) => css`repeat(${columnCount}, 1fr)`};

  margin-bottom: 11px;

  font-size: 16px;
  line-height: 22.4px;
  color: #000000;

  &:before {
    content: '';
    background-color: #1c3775;

    display: block;

    position: absolute;

    width: 12%;
    height: 2px;

    left: -12%;
  }

  &:after {
    content: '';
    background-color: #d69a2d;

    display: block;

    position: absolute;

    width: 12%;
    height: 2px;

    right: -12%;
  }
`

export const StyledTrack = styled.div<{ index: number }>`
  top: 0;
  bottom: 0;
  background: ${({ index }) => colorCode[index] || '#1C3775'};
  border-radius: 999px;

  height: 2px;
`

export const StyledThumb = styled.div`
  background-color: #141b4d;
  border-radius: 50%;
  border: 3px solid rgba(20, 27, 77, 0.3);
  box-shadow: 0 5px 10px rgba(129, 130, 135, 0.2);

  height: 12px;
  width: 12px;

  top: -5px;

  line-height: 15px;
  text-align: center;
  color: #252525;

  cursor: grab;
`

export const ThumbText = styled.span`
  position: relative;
  top: 24px;
  left: -80%;

  font-size: 13px;
  color: #333333;
`

export const StyledMark = styled.div<{ isFirst?: boolean; isActive?: boolean }>`
  display: inline-block;

  max-width: 250px;

  vertical-align: top;

  margin-top: 10px;

  text-align: center;
  font-size: 18px;
  line-height: 16.8px;
  font-weight: ${({ isActive = false }) => (isActive ? 'bold' : '400')};;
  color: ${({ isActive = false }) => (isActive ? '#1C3775' : '#525252')};
  white-space: pre-line;

  transition: color 0.1s, font-weight 0.1s;

  cursor: pointer;

  &:last-child {
    transform: translateX(47%);

    position: absolute;
    right: 0;
  }

  ${({ isFirst = false }) =>
    isFirst &&
    css`
      transform: translateX(-47%);
    `}
`
