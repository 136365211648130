import React, { useRef, useState, useMemo, useCallback, FC } from 'react'
import { Tooltip } from '../tooltip'
import { Checkbox } from '../checkbox'
import {
  ElementsListWrapper,
  ElementsListContent,
  ElementsListItem,
  ElementsListItemContent,
  ElementsListPrice,
} from './styles'
import { IElementsListProps } from './interface'
import { casementsDependencyKeys } from './consts'
import { getCasementsValues } from '../../utilits'

const ElementsListView = ({ getCheckboxItem, onAdditionalElementUncheck, data: { key, additionalPrice }}) => {
  const [price, isPrice] = useMemo(() => {
    const price = additionalPrice[key]['price']
    return [price, Boolean(Number(price))]
  }, [additionalPrice, key])
  const item: any = useMemo(() => getCheckboxItem(key), [getCheckboxItem, key])
  return (<>
    <ElementsListItemContent>
        <Checkbox
          disabled={!isPrice}
          items={[item]}
          selected={item}
          onSelect={onAdditionalElementUncheck(key)}
          id={key}
        />
    </ElementsListItemContent>
    {isPrice && <ElementsListPrice>{price} ₽</ElementsListPrice>}
  </>)
}

export const ElementsList: FC<IElementsListProps> = props => {
  const { additionalPrice, inProcess, onAdditionalElementUncheck, filters, product } = props
  const [tooltipDelta, setTooltipDelta] = useState(0)
  const [isTooltipShow, setIsTooltipShow] = useState(false)
  const additionalElementsRef = useRef<HTMLDivElement | null>(null)
  const additionalPriceKeys = Object.keys(additionalPrice)

  const handleAdditionalElementOver = useCallback(event => {
    let wrapperTop = 0
    let delta = 0

    if (additionalElementsRef?.current) {
      const wrapperCoord = additionalElementsRef.current.getBoundingClientRect()

      wrapperTop = wrapperCoord.top
    }

    if (event && event.currentTarget) {
      const elementCoord = event.currentTarget.getBoundingClientRect()
      delta = elementCoord.top - wrapperTop + 5

      setTooltipDelta(-delta)
      setIsTooltipShow(true)
    }
  }, [])

  const getAdditionalElementTitle = useCallback(
    element => `${element['name'] || element['description']}`,
    [],
  )

  const handleAdditionalElementLeave = useCallback(() => {
    setTooltipDelta(0)
    setIsTooltipShow(false)
  }, [])

  const getCount = useCallback(
    (key: string) => {
      let result = additionalPrice[key]['count']

      if (typeof result === 'undefined' && casementsDependencyKeys.includes(key) && filters) {
        const casementsIndex = filters.find(filter => filter['key'] === 'casements-index')

        if (casementsIndex && casementsIndex['value']) {
          if (key === 'aeration') {
            const casements = getCasementsValues(product, filters)

            if (casements.length > 0) {
              result = casements.filter(casement => casement === 'swing-out').length
            }
          } else {
            result = (casementsIndex['value'] as (number | undefined)[]).filter(
              item => typeof item === 'number',
            ).length
          }
        }
      }

      return result ? ` (${result})` : ''
    },
    [additionalPrice, filters, product],
  )

  const getCheckboxItem = useCallback(
    (key: string) => {
      return {
        name: key,
        value: true,
        title: getAdditionalElementTitle(additionalPrice[key]) + getCount(key),
      }
    },
    [additionalPrice, getAdditionalElementTitle, getCount],
  )

  return (
    <ElementsListWrapper ref={additionalElementsRef}>
      <Tooltip
        text="Удаление позиции приведет к возврату стандартной модели"
        coord={{ y: tooltipDelta, x: 6 }}
        importantShow={isTooltipShow}
      />
      <ElementsListContent showAfter={additionalPriceKeys.length > 1} disabled={inProcess}>
        {additionalPriceKeys.map(key => (
          <ElementsListItem
            key={key}
            onMouseOver={handleAdditionalElementOver}
            onMouseLeave={handleAdditionalElementLeave}
          >
            <ElementsListView 
              getCheckboxItem={getCheckboxItem} 
              onAdditionalElementUncheck={onAdditionalElementUncheck} 
              data={{ key, additionalPrice}} 
            />
          </ElementsListItem>
        ))}
      </ElementsListContent>
    </ElementsListWrapper>
  )
}
