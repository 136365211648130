import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement24Square82Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#5filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M183.001 92C184.105 92 185 91.1048 185 90.0005C185 88.8962 184.105 88.001 183.001 88.001L180.833 88.001L180.833 88.001L170 88.001L170 88L166.999 88C165.895 88 165 88.8952 165 89.9995C165 91.1038 165.895 91.999 166.999 91.999L169.167 91.999L169.167 92L180 92L180 92L183.001 92Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 180.833 92)"
          fill="#ABABAB"
        />
        <path
          d="M184.06 92C184.579 92 185 91.5792 185 91.0601L185 88.941C185 88.4219 184.579 88.001 184.06 88.001L180 88.001L180 92L184.06 92Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M165.94 88C165.421 88 165 88.4208 165 88.9399L165 91.059C165 91.5781 165.421 91.999 165.94 91.999L170 91.999L170 88L165.94 88Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 179.167 92)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#5filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M138.001 92C139.105 92 140 91.1048 140 90.0005C140 88.8962 139.105 88.001 138.001 88.001L135.833 88.001L135.833 88.001L125 88.001L125 88L121.999 88C120.895 88 120 88.8952 120 89.9995C120 91.1038 120.895 91.999 121.999 91.999L124.167 91.999L124.167 92L135 92L135 92L138.001 92Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 135.833 92)"
          fill="#ABABAB"
        />
        <path
          d="M139.06 92C139.579 92 140 91.5792 140 91.0601L140 88.941C140 88.4219 139.579 88.001 139.06 88.001L135 88.001L135 92L139.06 92Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M120.94 88C120.421 88 120 88.4208 120 88.9399L120 91.059C120 91.5781 120.421 91.999 120.94 91.999L125 91.999L125 88L120.94 88Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.167 92)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#5filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 33H115V88H188V33ZM178 43H125V78H178V43Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 33H115V88H116V34H187V88H188V33Z"
          fill="#FBFBFB"
        />
        <g filter="url(#5filter3_i)">
          <rect
            x="125.47"
            y="43.47"
            width="52.0601"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="119.53"
          y="37.53"
          width="63.9399"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="158.528"
          y="35"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 158.528 35)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#5filter4_d)">
          <rect
            x="157.884"
            y="35.7019"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 157.884 35.7019)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M157.766 37.6951C157.42 36.542 156.35 35.7019 155.084 35.7019L148.444 35.7019C146.898 35.7019 145.644 36.9555 145.644 38.5019C145.644 38.5371 145.645 38.5721 145.646 38.6069C145.993 39.76 147.062 40.6001 148.328 40.6001L154.968 40.6001C156.515 40.6001 157.768 39.3465 157.768 37.8001C157.768 37.7649 157.768 37.7299 157.766 37.6951Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M156.409 36.0346C156.266 36.0119 156.118 36.0001 155.968 36.0001L149.328 36.0001C147.782 36.0001 146.528 37.2537 146.528 38.8001C146.528 39.8672 147.125 40.7948 148.003 41.2674C146.666 41.0558 145.644 39.8982 145.644 38.5019C145.644 36.9555 146.898 35.7019 148.444 35.7019L155.084 35.7019C155.564 35.7019 156.015 35.8224 156.409 36.0346Z"
          fill="#FBFBFB"
        />
        <g filter="url(#5filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M171.528 38.5C171.528 37.6716 170.857 37 170.028 37L169.814 37L168.528 37L150.028 37C149.2 37 148.528 37.6716 148.528 38.5V38.5C148.528 39.3284 149.2 40 150.028 40L166.528 40L170.028 40L170.243 40C170.953 40 171.528 39.4244 171.528 38.7143V38.7143C171.528 38.6771 171.527 38.6401 171.525 38.6035C171.527 38.5693 171.528 38.5348 171.528 38.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M127.018 76.5L151.5 44.8177L175.982 76.5L127.018 76.5Z" stroke="white" />
        <defs>
          <filter
            id="5filter0_d"
            x="163.12"
            y="86.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5filter1_d"
            x="118.12"
            y="86.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5filter2_d"
            x="110.3"
            y="28.3003"
            width="84.2793"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5filter3_i"
            x="125"
            y="43"
            width="53.9399"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5filter4_d"
            x="143.764"
            y="33.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5filter5_d"
            x="147.649"
            y="35.1201"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="167.5"
            y1="94.8"
            x2="167.5"
            y2="87.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="182.5"
            y1="85.2018"
            x2="182.5"
            y2="92.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="167.5"
            y1="94.7982"
            x2="167.5"
            y2="87.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="122.5"
            y1="94.8"
            x2="122.5"
            y2="87.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="137.5"
            y1="85.2018"
            x2="137.5"
            y2="92.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="122.5"
            y1="94.7982"
            x2="122.5"
            y2="87.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
