import React, { FC } from 'react'

import { ISuccessProps } from '../../views/calculator/interface'
import { CheckWrapper } from '../../views/calculator/styles'

export const SuccessIcon: FC<ISuccessProps> = props => {
  const { color = '#55A361' } = props

  return (
    <CheckWrapper>
      <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.75758 1L3.42424 6.33333L1 3.90909"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </CheckWrapper>
  )
}
