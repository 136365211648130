import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement73Square3Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#7-3_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.9995 287C38.8952 287 38 287.895 38 288.999C38 290.104 38.8952 290.999 39.9995 290.999L42.1665 290.999L42.1665 290.999L53 290.999L53 291L56.0005 291C57.1048 291 58 290.105 58 289.001C58 287.896 57.1048 287.001 56.0005 287.001L53.8332 287.001L53.8332 287L43 287L43 287L39.9995 287Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 42.166 287)"
          fill="#ABABAB"
        />
        <path
          d="M38.9399 287C38.4208 287 38 287.421 38 287.94L38 290.059C38 290.578 38.4208 290.999 38.9399 290.999L43 290.999L43 287L38.9399 287Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M57.0601 291C57.5792 291 58 290.579 58 290.06L58 287.941C58 287.422 57.5792 287.001 57.0601 287.001L53 287.001L53 291L57.0601 291Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 43.833 287)"
          fill="url(#paint7-3_linear)"
        />
        <g filter="url(#7-3_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M127.999 287C126.895 287 126 287.895 126 288.999C126 290.104 126.895 290.999 127.999 290.999L130.167 290.999L130.167 290.999L141 290.999L141 291L144.001 291C145.105 291 146 290.105 146 289.001C146 287.896 145.105 287.001 144.001 287.001L141.833 287.001L141.833 287L131 287L131 287L127.999 287Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 130.166 287)"
          fill="#ABABAB"
        />
        <path
          d="M126.94 287C126.421 287 126 287.421 126 287.94L126 290.059C126 290.578 126.421 290.999 126.94 290.999L131 290.999L131 287L126.94 287Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M145.06 291C145.579 291 146 290.579 146 290.06L146 287.941C146 287.422 145.579 287.001 145.06 287.001L141 287.001L141 291L145.06 291Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 131.833 287)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#7-3_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M152 210H32V287H152V210ZM141 221H43V276H141V221Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#7-3_filter7-3_i)">
          <rect
            x="43.47"
            y="221.47"
            width="97.0601"
            height="54.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="37.5" y="215.5" width="109" height="66" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M152 210H32V287H33V211H151V287H152V210Z"
          fill="#FBFBFB"
        />
        <path d="M138.873 274.5H45.1274L92 222.745L138.873 274.5Z" stroke="white" />
        <rect
          x="99.5283"
          y="212"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 99.5283 212)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#7-3_filter4_d)">
          <rect
            x="98.8838"
            y="212.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 98.8838 212.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M98.7666 214.695C98.4202 213.542 97.3505 212.702 96.0846 212.702L89.4447 212.702C87.8983 212.702 86.6447 213.956 86.6447 215.502C86.6447 215.537 86.6454 215.572 86.6466 215.607C86.993 216.76 88.0627 217.6 89.3286 217.6L95.9686 217.6C97.515 217.6 98.7686 216.347 98.7686 214.8C98.7686 214.765 98.7679 214.73 98.7666 214.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M97.4092 213.035C97.2655 213.012 97.1182 213 96.9681 213L90.3282 213C88.7818 213 87.5282 214.254 87.5282 215.8C87.5282 216.867 88.1251 217.795 89.0032 218.268C87.6664 218.056 86.6443 216.898 86.6443 215.502C86.6443 213.956 87.8979 212.702 89.4443 212.702L96.0842 212.702C96.5635 212.702 97.0147 212.823 97.4092 213.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#7-3_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M112.528 215.5C112.528 214.672 111.857 214 111.028 214L110.814 214L109.528 214L91.0283 214C90.1999 214 89.5283 214.672 89.5283 215.5C89.5283 216.328 90.1999 217 91.0283 217L107.528 217L111.028 217L111.243 217C111.953 217 112.528 216.424 112.528 215.714C112.528 215.677 112.527 215.64 112.525 215.604C112.527 215.569 112.528 215.535 112.528 215.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="7-3_filter0_d"
            x="36.1201"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7-3_filter1_d"
            x="124.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7-3_filter2_d"
            x="27.3003"
            y="205.3"
            width="131.279"
            height="88.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7-3_filter7-3_i"
            x="43"
            y="221"
            width="98.9399"
            height="57.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="7-3_filter4_d"
            x="84.7637"
            y="210.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7-3_filter5_d"
            x="88.6485"
            y="212.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="55.5"
            y1="284.2"
            x2="55.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="40.5"
            y1="293.798"
            x2="40.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="55.5"
            y1="284.202"
            x2="55.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7-3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="143.5"
            y1="284.2"
            x2="143.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="128.5"
            y1="293.798"
            x2="128.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="143.5"
            y1="284.202"
            x2="143.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
