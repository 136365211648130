import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement35Square4VerticalCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#3-5_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M191.954 170.976C191.954 169.885 191.069 169 189.977 169C188.886 169 188.001 169.885 188.001 170.976L188.001 173.167L188.001 173.167L188.001 184L188 184L188 187.024C188 188.115 188.885 189 189.976 189C191.068 189 191.953 188.115 191.953 187.024L191.953 184.833L191.954 184.833L191.954 174L191.954 174L191.954 170.976Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 191.954 173.166)"
          fill="#ABABAB"
        />
        <path
          d="M191.954 169.94C191.954 169.421 191.533 169 191.014 169L188.941 169C188.422 169 188.001 169.421 188.001 169.94L188.001 174L191.954 174L191.954 169.94Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M188 188.06C188 188.579 188.421 189 188.94 189L191.013 189C191.532 189 191.953 188.579 191.953 188.06L191.953 184L188 184L188 188.06Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 191.954 174.833)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#3-5_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 195C188 193.895 188.895 193 190 193C191.105 193 192 193.895 192 195L192 209.56L188 209.56L188 195ZM188 209.56L192 209.56L192 211.4L192.06 211.4C192.579 211.4 193 211.821 193 212.34L193 214.12C193 215.158 192.158 216 191.12 216L189.88 216C188.842 216 188 215.158 188 214.12L188 211.4L188 211.4L188 209.56Z"
            fill="white"
          />
        </g>
        <rect x="188" y="210.25" width="3.99998" height="0.95833" fill="#ABABAB" />
        <path
          d="M188 193.94C188 193.421 188.421 193 188.94 193L191.06 193C191.579 193 192 193.421 192 193.94L192 210.25L188 210.25L188 193.94Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M188 215.06C188 215.579 188.421 216 188.94 216L192.06 216C192.579 216 193 215.579 193 215.06L193 212.148C193 211.629 192.579 211.208 192.06 211.208L188 211.208L188 215.06Z"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#3-5_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 165H32V220H188V165ZM178 175H42V210H178V175Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 165H32V220H33V166H187V220H188V165Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-5_filter3_i)">
          <rect
            x="42.47"
            y="175.47"
            width="135.06"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="169.53"
          width="146.94"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M176.5 176.565L47.0913 192.5L176.5 208.435L176.5 176.565Z" stroke="white" />
        <rect
          width="6.91954"
          height="13.5283"
          rx="3.45977"
          transform="matrix(-1 0 0 1 40.9196 186)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#3-5_filter4_d)">
          <rect
            width="5.53563"
            height="12.2399"
            rx="2.76782"
            transform="matrix(-1 0 0 1 40.2255 186.644)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.6605 197.792C39.1549 198.456 38.3564 198.884 37.4578 198.884C35.9292 198.884 34.69 197.645 34.69 196.116V189.412C34.69 188.44 35.1908 187.585 35.9483 187.092C35.5937 187.557 35.3831 188.138 35.3831 188.768V195.472C35.3831 197.001 36.6223 198.24 38.151 198.24C38.7079 198.24 39.2264 198.075 39.6605 197.792Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.4576 186.644C38.9863 186.644 40.2255 187.883 40.2255 189.412V195.472C40.2255 195.492 40.2252 195.512 40.2248 195.533V189.991C40.2248 188.463 38.9856 187.224 37.457 187.224C35.9486 187.224 34.7221 188.43 34.6898 189.931V189.412C34.6898 187.883 35.929 186.644 37.4576 186.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-5_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.4599 190C38.2788 190 38.9426 190.664 38.9426 191.483V193C38.9426 193.014 38.9425 193.027 38.9422 193.041L38.9422 211.517C38.9422 212.336 38.2784 213 37.4595 213V213C36.6406 213 35.9767 212.336 35.9767 211.517L35.9767 191.251C35.9767 190.56 36.5369 190 37.228 190V190C37.2308 190 37.2336 190 37.2364 190C37.3109 190 37.3854 190 37.4599 190V190Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M45.1468 208.5L110 176.557L174.853 208.5H45.1468Z" stroke="white" />
        <defs>
          <filter
            id="3-5_filter0_d"
            x="186.12"
            y="167.12"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-5_filter1_d"
            x="186.12"
            y="191.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-5_filter2_d"
            x="27.3003"
            y="160.3"
            width="167.279"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-5_filter3_i"
            x="42"
            y="175"
            width="136.94"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-5_filter4_d"
            x="32.8099"
            y="184.764"
            width="11.1753"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-5_filter5_d"
            x="34.0968"
            y="188.12"
            width="8.60556"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="194.722"
            y1="186.5"
            x2="187.209"
            y2="186.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="185.234"
            y1="171.5"
            x2="192.745"
            y2="171.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="194.72"
            y1="186.5"
            x2="187.209"
            y2="186.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="194.8"
            y1="201.625"
            x2="187.2"
            y2="201.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="196.5"
            y1="213.604"
            x2="187"
            y2="213.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
