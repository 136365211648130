import React, { useRef, FC } from 'react'

import { HorizontalThumbSizesInputNumberWrapper } from './styles'
import { IThumbSizeProps } from './interface'
import { NumberInput } from '../number-input'
import { InputTypes } from '../../../interface'

export const ThumbSizeContainer: FC<IThumbSizeProps> = props => {
  const { index, currentSize, onChange, minSize, maxSize, disabled } = props
  const thumbInputRef = useRef<HTMLDivElement>(null)

  return (
    <HorizontalThumbSizesInputNumberWrapper ref={thumbInputRef} show>
      <NumberInput
        onSelect={onChange}
        data={{ min: minSize, max: maxSize }}
        id={`number-input-${index}`}
        selected={currentSize}
        selectOnFocus
        type={InputTypes.text}
        disabled={disabled}
      />
    </HorizontalThumbSizesInputNumberWrapper>
  )
}
