import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement32Square8Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#3-2_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M236.999 155C235.895 155 235 155.895 235 156.999C235 158.104 235.895 158.999 236.999 158.999L239.167 158.999L239.167 158.999L250 158.999L250 159L253.001 159C254.105 159 255 158.105 255 157.001C255 155.896 254.105 155.001 253.001 155.001L250.833 155.001L250.833 155L240 155L240 155L236.999 155Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 239.167 155)"
          fill="#ABABAB"
        />
        <path
          d="M235.94 155C235.421 155 235 155.421 235 155.94L235 158.059C235 158.578 235.421 158.999 235.94 158.999L240 158.999L240 155L235.94 155Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M254.06 159C254.579 159 255 158.579 255 158.06L255 155.941C255 155.422 254.579 155.001 254.06 155.001L250 155.001L250 159L254.06 159Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 240.833 155)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#3-2_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M296.999 155C295.895 155 295 155.895 295 156.999C295 158.104 295.895 158.999 296.999 158.999L299.167 158.999L299.167 158.999L310 158.999L310 159L313.001 159C314.105 159 315 158.105 315 157.001C315 155.896 314.105 155.001 313.001 155.001L310.833 155.001L310.833 155L300 155L300 155L296.999 155Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 299.167 155)"
          fill="#ABABAB"
        />
        <path
          d="M295.94 155C295.421 155 295 155.421 295 155.94L295 158.059C295 158.578 295.421 158.999 295.94 158.999L300 158.999L300 155L295.94 155Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M314.06 159C314.579 159 315 158.579 315 158.06L315 155.941C315 155.422 314.579 155.001 314.06 155.001L310 155.001L310 159L314.06 159Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 300.833 155)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#3-2_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M319 34H230V155H319V34ZM308 45H241V144H308V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M319 34H230V155H231.219V35H317.781V155H319V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-2_filter3_i)">
          <rect
            x="241.47"
            y="45.47"
            width="66.0601"
            height="98.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="234.53"
          y="38.53"
          width="79.9399"
          height="111.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="281.528"
          y="36"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 281.528 36)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#3-2_filter4_d)">
          <rect
            x="280.884"
            y="36.7019"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 280.884 36.7019)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M280.766 38.6951C280.42 37.542 279.35 36.7019 278.084 36.7019L271.444 36.7019C269.898 36.7019 268.644 37.9555 268.644 39.5019C268.644 39.5371 268.645 39.5721 268.646 39.6069C268.993 40.76 270.062 41.6001 271.328 41.6001L277.968 41.6001C279.515 41.6001 280.768 40.3465 280.768 38.8001C280.768 38.7649 280.768 38.7299 280.766 38.6951Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M279.409 37.0346C279.266 37.0119 279.118 37.0001 278.968 37.0001L272.328 37.0001C270.782 37.0001 269.528 38.2537 269.528 39.8001C269.528 40.8672 270.125 41.7948 271.003 42.2674C269.666 42.0558 268.644 40.8982 268.644 39.5019C268.644 37.9555 269.898 36.7019 271.444 36.7019L278.084 36.7019C278.564 36.7019 279.015 36.8224 279.409 37.0346Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-2_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M294.528 39.5C294.528 38.6716 293.857 38 293.028 38L292.814 38L291.528 38L273.028 38C272.2 38 271.528 38.6716 271.528 39.5V39.5C271.528 40.3284 272.2 41 273.028 41L289.528 41L293.028 41L293.243 41C293.953 41 294.528 40.4244 294.528 39.7143V39.7143C294.528 39.6771 294.527 39.6401 294.525 39.6035C294.527 39.5693 294.528 39.5348 294.528 39.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M242.695 142.5L274.5 47.5738L306.305 142.5L242.695 142.5Z" stroke="white" />
        <defs>
          <filter
            id="3-2_filter0_d"
            x="233.12"
            y="153.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-2_filter1_d"
            x="293.12"
            y="153.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-2_filter2_d"
            x="225.3"
            y="29.3003"
            width="100.279"
            height="132.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-2_filter3_i"
            x="241"
            y="45"
            width="67.9399"
            height="101.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-2_filter4_d"
            x="266.764"
            y="34.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-2_filter5_d"
            x="270.649"
            y="36.1201"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="252.5"
            y1="152.2"
            x2="252.5"
            y2="159.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="237.5"
            y1="161.798"
            x2="237.5"
            y2="154.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="252.5"
            y1="152.202"
            x2="252.5"
            y2="159.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="312.5"
            y1="152.2"
            x2="312.5"
            y2="159.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="297.5"
            y1="161.798"
            x2="297.5"
            y2="154.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="312.5"
            y1="152.202"
            x2="312.5"
            y2="159.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
