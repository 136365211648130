import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement64Square3Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#6-4_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M421 129.999C421 128.895 420.105 128 419.001 128C417.896 128 417.001 128.895 417.001 129.999V132.167H417.001V143H417V146.001C417 147.105 417.895 148 418.999 148C420.104 148 420.999 147.105 420.999 146.001V143.833H421V133H421V129.999Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 421 132.166)"
          fill="#ABABAB"
        />
        <path
          d="M421 128.94C421 128.421 420.579 128 420.06 128H417.941C417.422 128 417.001 128.421 417.001 128.94V133H421V128.94Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M417 147.06C417 147.579 417.421 148 417.94 148H420.059C420.578 148 420.999 147.579 420.999 147.06V143H417V147.06Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 421 133.833)"
          fill="url(#paint6-4_linear)"
        />
        <g filter="url(#6-4_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 173C417 171.895 417.895 171 419 171C420.105 171 421 171.895 421 173V187.56H417V173ZM417 187.56H421V189.4H421.06C421.579 189.4 422 189.821 422 190.34V192.12C422 193.158 421.158 194 420.12 194H418.88C417.842 194 417 193.158 417 192.12V189.4V189.4V187.56Z"
            fill="white"
          />
        </g>
        <rect x="417" y="188.25" width="3.99998" height="0.95833" fill="#ABABAB" />
        <path
          d="M417 171.94C417 171.421 417.421 171 417.94 171H420.06C420.579 171 421 171.421 421 171.94V188.25H417V171.94Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M417 193.06C417 193.579 417.421 194 417.94 194H421.06C421.579 194 422 193.579 422 193.06V190.148C422 189.629 421.579 189.208 421.06 189.208H417V193.06Z"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#6-4_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 122H297V199H417V122ZM406 133H308V188H406V133Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#6-4_filter6-4_i)">
          <rect
            x="308.47"
            y="133.47"
            width="97.0601"
            height="54.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="302.5" y="127.5" width="109" height="66" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 122H297V199H298V123H416V199H417V122Z"
          fill="#FBFBFB"
        />
        <path d="M404.5 186.343L404.5 134.657L310.879 160.5L404.5 186.343Z" stroke="white" />
        <path d="M403.873 186.5H310.127L357 134.745L403.873 186.5Z" stroke="white" />
        <rect
          x="299"
          y="153"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#6-4_filter4_d)">
          <rect x="299.702" y="153.645" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M300.286 164.796C300.798 165.458 301.6 165.884 302.502 165.884C304.049 165.884 305.302 164.631 305.302 163.084V156.444C305.302 155.456 304.79 154.587 304.017 154.089C304.383 154.562 304.601 155.156 304.601 155.8V162.44C304.601 163.987 303.347 165.24 301.801 165.24C301.243 165.24 300.723 165.077 300.286 164.796Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M302.502 153.645C300.955 153.645 299.702 154.898 299.702 156.445V162.44C299.702 162.461 299.702 162.481 299.702 162.501V157.024C299.702 155.478 300.956 154.224 302.502 154.224C304.028 154.224 305.269 155.445 305.302 156.963V156.445C305.302 154.898 304.048 153.645 302.502 153.645Z"
          fill="#FBFBFB"
        />
        <g filter="url(#6-4_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M302.5 157C301.672 157 301 157.672 301 158.5V158.714V160V178.5C301 179.328 301.672 180 302.5 180C303.328 180 304 179.328 304 178.5V162V158.5L304 158.286C304 157.576 303.424 157 302.714 157C302.677 157 302.64 157.001 302.604 157.004C302.569 157.001 302.535 157 302.5 157Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="6-4_filter0_d"
            x="415.12"
            y="126.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-4_filter1_d"
            x="415.12"
            y="169.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-4_filter2_d"
            x="292.3"
            y="117.3"
            width="131.279"
            height="88.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-4_filter6-4_i"
            x="308"
            y="133"
            width="98.9399"
            height="57.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="6-4_filter4_d"
            x="297.822"
            y="151.765"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="6-4_filter5_d"
            x="299.12"
            y="155.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="423.8"
            y1="145.5"
            x2="416.2"
            y2="145.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="414.202"
            y1="130.5"
            x2="421.8"
            y2="130.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="423.798"
            y1="145.5"
            x2="416.2"
            y2="145.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6-4_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="423.8"
            y1="179.625"
            x2="416.2"
            y2="179.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="425.5"
            y1="191.604"
            x2="416"
            y2="191.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
