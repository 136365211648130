import React, { FC } from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'
import { IContainerSquareProps } from '../../interface'
import { ContainerBackground } from '../container-background'

export const ContainerDoor1RCasement1: FC<IContainerSquareProps> = props => {
  const { onCasementClick, withoutBackground } = props

  return (
    <ProductImageWrapper isClickable>
      <svg
        width="451"
        height="451"
        viewBox="0 0 451 451"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g clipPath="url(#clip0)">
          <rect width="451" height="451" fill="white" />
          <rect x="-81" y="-1" width="647" height="426" fill="#E8EAEE" />
          <rect x="-81" y="-1" width="647" height="426" fill="url(#pattern0)" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 -1H451V451H0V-1ZM265 11H10V323.274H265V441H441V323.274V11H265Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M441 10H10V28H263V441H441V10ZM427 28H265V425H427V28Z"
            fill="#C4C4C4"
          />
          <path d="M441 10L427 28.1525V424.982L441 441V10Z" fill="#E9EBEF" />
          <path d="M441 10L427 28.1525V424.982L441 441V10Z" fill="#020202" fillOpacity="0.06" />
          <rect width="255" height="108" transform="matrix(-1 0 0 1 253 343)" fill="#E8EAEE" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M263 28H427V425H263V28ZM285 41H414V412H285V41Z"
            fill="#E8EAEE"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 28H265V325H24V28ZM38 41H260V294H38V41Z"
            fill="#E8EAEE"
          />
          <g
            filter="url(#filter-door-1-casement-1-0_i)"
            className="casement"
            data-index="1"
            onClick={onCasementClick(1)}
          >
            <rect
              width="129"
              height="371"
              transform="matrix(-1 0 0 1 414 41)"
              fill="#B8C1FD"
              fillOpacity="0.4"
            />
          </g>
          <rect
            x="-0.46997"
            y="0.46997"
            width="128.06"
            height="370.06"
            transform="matrix(-1 0 0 1 413.06 41)"
            stroke="#979797"
            strokeWidth="0.93994"
          />
          <g
            filter="url(#filter-door-1-casement-1-1_i)"
            className="casement"
            data-index="0"
            onClick={onCasementClick(0)}
          >
            <rect
              width="223"
              height="253"
              transform="matrix(-1 0 0 1 260 41)"
              fill="#B8C1FD"
              fillOpacity="0.4"
            />
          </g>
          <rect
            x="-0.46997"
            y="0.46997"
            width="222.06"
            height="252.06"
            transform="matrix(-1 0 0 1 259.06 41)"
            stroke="#979797"
            strokeWidth="0.93994"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.162 307H266L267 331H235.25H10.162H0.00012207V323H10.162V307Z"
            fill="url(#paint0_linear)"
          />
          <path d="M10 10L24 28.0571V307.075L10 323V10Z" fill="#E9EBEF" />
          <path d="M10 10L24 28.0571V307.075L10 323V10Z" fill="#020202" fillOpacity="0.06" />
          <g filter="url(#filter-door-1-casement-1-2_d)">
            <rect width="267" height="12" transform="matrix(-1 0 0 1 267 331)" fill="white" />
          </g>
          <path
            d="M357.985 115.741H355.447C354.8 115.741 354.253 115.234 354.253 114.636V114.405C354.253 113.806 354.8 113.3 355.447 113.3H357.985C358.632 113.3 359.179 113.806 359.179 114.405V114.636C359.179 115.257 358.657 115.741 357.985 115.741Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M340.426 118.565C340.725 118.565 340.799 118.565 341.197 118.565C341.943 118.565 342.49 119.105 342.49 119.833C342.49 120.584 341.918 121.101 341.147 121.101C339.705 121.101 338.81 121.101 337.367 121.101C336.82 121.101 336.373 120.913 336.149 120.42C335.95 120.115 335.826 119.27 336.621 118.565C337.144 117.978 342.913 111.757 343.933 110.677C343.982 110.606 343.933 110.512 343.858 110.512C343.833 110.512 343.833 110.512 343.808 110.512C341.645 110.512 339.481 110.512 337.318 110.512C336.671 110.512 336.174 110.137 336.049 109.573C335.9 108.916 336.199 108.352 336.771 108.094C336.945 108.023 337.119 108 337.293 108C340.501 108 343.684 108 346.892 108C347.514 108 347.937 108.188 348.185 108.728C348.509 109.409 348.16 109.878 347.937 110.183C347.489 110.723 342.938 115.56 340.426 118.283C340.401 118.307 340.376 118.354 340.327 118.377C340.277 118.448 340.327 118.565 340.426 118.565Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M347.186 121.101H344.648C344.001 121.101 343.453 120.582 343.453 119.968V119.732C343.453 119.119 344.001 118.6 344.648 118.6H347.186C347.832 118.6 348.38 119.119 348.38 119.732V119.968C348.38 120.605 347.832 121.101 347.186 121.101Z"
            fill="#141B4D"
            fillOpacity="0.63"
            stroke="#767676"
            strokeWidth="0.0812062"
            strokeMiterlimit="10"
          />
          <path
            d="M350.715 114.515C350.715 112.734 350.715 110.976 350.715 109.195C350.715 108.492 351.237 108 351.983 108C355.215 108 358.446 108 361.678 108C362.424 108 362.946 108.516 362.946 109.242C362.946 109.969 362.424 110.508 361.678 110.508C358.993 110.508 356.333 110.508 353.649 110.508C353.45 110.508 353.4 110.555 353.4 110.742C353.4 113.273 353.4 115.804 353.4 118.335C353.4 118.546 353.475 118.57 353.649 118.57C356.309 118.57 358.993 118.57 361.653 118.57C362.25 118.57 362.722 118.898 362.896 119.39C363.095 119.976 362.871 120.562 362.399 120.89C362.175 121.03 361.902 121.101 361.628 121.101C358.446 121.101 355.24 121.101 352.058 121.101C351.237 121.101 350.74 120.585 350.74 119.812C350.715 118.031 350.715 116.273 350.715 114.515Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M337.705 126.042V126.698H336V126.042H336.426V124.259H336.05V123.602H337.68V124.259H337.304V126.042H337.705Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M341.534 126.758H340.774L340.651 126.545C340.381 126.687 340.062 126.758 339.768 126.758C338.933 126.734 338.148 126.167 338.148 125.15C338.148 124.181 338.811 123.542 339.817 123.542C340.651 123.542 341.461 124.086 341.461 125.15C341.461 125.528 341.363 125.812 341.215 126.072L341.559 126.64V126.758H341.534ZM339.792 126.001C340.185 126.001 340.602 125.718 340.602 125.15C340.602 124.583 340.185 124.299 339.792 124.299C339.375 124.299 338.982 124.583 338.982 125.15C338.982 125.718 339.375 126.001 339.792 126.001Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M343.784 125.749C343.784 125.96 344.029 126.077 344.299 126.077C344.545 126.077 344.79 125.96 344.79 125.796C344.79 125.49 344.422 125.49 344.201 125.467C343.612 125.42 343.022 125.209 343.022 124.552C343.022 123.871 343.612 123.542 344.275 123.542C344.889 123.542 345.527 123.871 345.527 124.552H344.717C344.717 124.34 344.496 124.246 344.275 124.246C344.078 124.246 343.833 124.293 343.833 124.528C343.833 124.739 344.029 124.786 344.324 124.81C345.011 124.857 345.601 125.021 345.601 125.772C345.601 126.429 344.938 126.758 344.275 126.758C343.587 126.758 342.924 126.429 342.949 125.749H343.784Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M348.229 124.311H347.038V124.76H348.13V125.469H347.038V125.966H348.254V126.698H346.169V123.602H348.204V124.311H348.229Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M351.603 126.58V126.698H350.722L350.159 125.729H349.719V126.698H348.887V123.602C349.376 123.602 349.841 123.602 350.306 123.602C351.089 123.602 351.456 124.169 351.456 124.713C351.456 125.067 351.285 125.422 350.967 125.611L351.603 126.58ZM350.306 125.067C350.551 125.067 350.649 124.878 350.649 124.666C350.649 124.476 350.526 124.287 350.306 124.287H349.719V125.067H350.306Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M353.276 125.334L353.553 124.527L353.956 123.602H354.887V123.721L353.452 126.758H353.1L351.666 123.721V123.602H352.597L353.024 124.527L353.276 125.334Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M356.781 126.042V126.698H355.076V126.042H355.502V124.259H355.126V123.602H356.756V124.259H356.38V126.042H356.781Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M358.835 123.602C359.509 123.602 360.258 123.955 360.383 124.78H359.559C359.434 124.45 359.184 124.332 358.835 124.332C358.335 124.332 358.011 124.662 358.011 125.18C358.011 125.627 358.335 126.028 358.835 126.028C359.184 126.028 359.484 125.863 359.559 125.533H360.383C360.258 126.405 359.534 126.758 358.835 126.758C357.986 126.758 357.162 126.216 357.162 125.18C357.162 124.12 357.986 123.602 358.835 123.602Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M362.944 124.311H361.753V124.76H362.845V125.469H361.753V125.966H362.969V126.698H360.885V123.602H362.919V124.311H362.944Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M152.985 115.741H150.447C149.8 115.741 149.253 115.234 149.253 114.636V114.405C149.253 113.806 149.8 113.3 150.447 113.3H152.985C153.632 113.3 154.179 113.806 154.179 114.405V114.636C154.179 115.257 153.657 115.741 152.985 115.741Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M135.426 118.565C135.725 118.565 135.799 118.565 136.197 118.565C136.943 118.565 137.49 119.105 137.49 119.833C137.49 120.584 136.918 121.101 136.147 121.101C134.705 121.101 133.81 121.101 132.367 121.101C131.82 121.101 131.373 120.913 131.149 120.42C130.95 120.115 130.826 119.27 131.621 118.565C132.144 117.978 137.913 111.757 138.933 110.677C138.982 110.606 138.933 110.512 138.858 110.512C138.833 110.512 138.833 110.512 138.808 110.512C136.645 110.512 134.481 110.512 132.318 110.512C131.671 110.512 131.174 110.137 131.049 109.573C130.9 108.916 131.199 108.352 131.771 108.094C131.945 108.023 132.119 108 132.293 108C135.501 108 138.684 108 141.892 108C142.514 108 142.937 108.188 143.185 108.728C143.509 109.409 143.16 109.878 142.937 110.183C142.489 110.723 137.938 115.56 135.426 118.283C135.401 118.307 135.376 118.354 135.327 118.377C135.277 118.448 135.327 118.565 135.426 118.565Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M142.186 121.101H139.648C139.001 121.101 138.453 120.582 138.453 119.968V119.732C138.453 119.119 139.001 118.6 139.648 118.6H142.186C142.832 118.6 143.38 119.119 143.38 119.732V119.968C143.38 120.605 142.832 121.101 142.186 121.101Z"
            fill="#141B4D"
            fillOpacity="0.63"
            stroke="#767676"
            strokeWidth="0.0812062"
            strokeMiterlimit="10"
          />
          <path
            d="M145.715 114.515C145.715 112.734 145.715 110.976 145.715 109.195C145.715 108.492 146.237 108 146.983 108C150.215 108 153.446 108 156.678 108C157.424 108 157.946 108.516 157.946 109.242C157.946 109.969 157.424 110.508 156.678 110.508C153.993 110.508 151.333 110.508 148.649 110.508C148.45 110.508 148.4 110.555 148.4 110.742C148.4 113.273 148.4 115.804 148.4 118.335C148.4 118.546 148.475 118.57 148.649 118.57C151.309 118.57 153.993 118.57 156.653 118.57C157.25 118.57 157.722 118.898 157.896 119.39C158.095 119.976 157.871 120.562 157.399 120.89C157.175 121.03 156.902 121.101 156.628 121.101C153.446 121.101 150.24 121.101 147.058 121.101C146.237 121.101 145.74 120.585 145.74 119.812C145.715 118.031 145.715 116.273 145.715 114.515Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M132.705 126.042V126.698H131V126.042H131.426V124.259H131.05V123.602H132.68V124.259H132.304V126.042H132.705Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M136.534 126.758H135.774L135.651 126.545C135.381 126.687 135.062 126.758 134.768 126.758C133.933 126.734 133.148 126.167 133.148 125.15C133.148 124.181 133.811 123.542 134.817 123.542C135.651 123.542 136.461 124.086 136.461 125.15C136.461 125.528 136.363 125.812 136.215 126.072L136.559 126.64V126.758H136.534ZM134.792 126.001C135.185 126.001 135.602 125.718 135.602 125.15C135.602 124.583 135.185 124.299 134.792 124.299C134.375 124.299 133.982 124.583 133.982 125.15C133.982 125.718 134.375 126.001 134.792 126.001Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M138.784 125.749C138.784 125.96 139.029 126.077 139.299 126.077C139.545 126.077 139.79 125.96 139.79 125.796C139.79 125.49 139.422 125.49 139.201 125.467C138.612 125.42 138.022 125.209 138.022 124.552C138.022 123.871 138.612 123.542 139.275 123.542C139.889 123.542 140.527 123.871 140.527 124.552H139.717C139.717 124.34 139.496 124.246 139.275 124.246C139.078 124.246 138.833 124.293 138.833 124.528C138.833 124.739 139.029 124.786 139.324 124.81C140.011 124.857 140.601 125.021 140.601 125.772C140.601 126.429 139.938 126.758 139.275 126.758C138.587 126.758 137.924 126.429 137.949 125.749H138.784Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M143.229 124.311H142.038V124.76H143.13V125.469H142.038V125.966H143.254V126.698H141.169V123.602H143.204V124.311H143.229Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M146.603 126.58V126.698H145.722L145.159 125.729H144.719V126.698H143.887V123.602C144.376 123.602 144.841 123.602 145.306 123.602C146.089 123.602 146.456 124.169 146.456 124.713C146.456 125.067 146.285 125.422 145.967 125.611L146.603 126.58ZM145.306 125.067C145.551 125.067 145.649 124.878 145.649 124.666C145.649 124.476 145.526 124.287 145.306 124.287H144.719V125.067H145.306Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M148.276 125.334L148.553 124.527L148.956 123.602H149.887V123.721L148.452 126.758H148.1L146.666 123.721V123.602H147.597L148.024 124.527L148.276 125.334Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M151.781 126.042V126.698H150.076V126.042H150.502V124.259H150.126V123.602H151.756V124.259H151.38V126.042H151.781Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M153.835 123.602C154.509 123.602 155.258 123.955 155.383 124.78H154.559C154.434 124.45 154.184 124.332 153.835 124.332C153.335 124.332 153.011 124.662 153.011 125.18C153.011 125.627 153.335 126.028 153.835 126.028C154.184 126.028 154.484 125.863 154.559 125.533H155.383C155.258 126.405 154.534 126.758 153.835 126.758C152.986 126.758 152.162 126.216 152.162 125.18C152.162 124.12 152.986 123.602 153.835 123.602Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M157.944 124.311H156.753V124.76H157.845V125.469H156.753V125.966H157.969V126.698H155.885V123.602H157.919V124.311H157.944Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
        </g>
        <defs>
          <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use
              xlinkHref="#image0"
              transform="translate(-0.00768784) scale(0.00100915 0.00153332)"
            />
          </pattern>
          <filter
            id="filter-door-1-casement-1-0_i"
            x="285"
            y="41"
            width="129.94"
            height="373.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter-door-1-casement-1-1_i"
            x="37"
            y="41"
            width="223.94"
            height="255.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter-door-1-casement-1-2_d"
            x="-5.06006"
            y="328.94"
            width="279"
            height="24"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="3.94" />
            <feGaussianBlur stdDeviation="3" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="174.259"
            y1="306"
            x2="174.259"
            y2="331"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DBDDE1" />
            <stop offset="1" stopColor="#F9F9F9" />
          </linearGradient>
          <clipPath id="clip0">
            <rect width="451" height="451" fill="white" />
          </clipPath>
        </defs>
      </svg>

      {!withoutBackground && <ContainerBackground />}
    </ProductImageWrapper>
  )
}
