import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement53Square8Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#5-3_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M125 177.999C125 176.895 124.105 176 123.001 176C121.896 176 121.001 176.895 121.001 177.999V180.167H121.001V191H121V194.001C121 195.105 121.895 196 122.999 196C124.104 196 124.999 195.105 124.999 194.001V191.833H125V181H125V177.999Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 125 180.167)"
          fill="#ABABAB"
        />
        <path
          d="M125 176.94C125 176.421 124.579 176 124.06 176H121.941C121.422 176 121.001 176.421 121.001 176.94V181H125V176.94Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M121 195.06C121 195.579 121.421 196 121.94 196H124.059C124.578 196 124.999 195.579 124.999 195.06V191H121V195.06Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 125 181.833)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#5-3_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M125 256.999C125 255.895 124.105 255 123.001 255C121.896 255 121.001 255.895 121.001 256.999V259.167H121.001V270H121V273.001C121 274.105 121.895 275 122.999 275C124.104 275 124.999 274.105 124.999 273.001V270.833H125V260H125V256.999Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 125 259.167)"
          fill="#ABABAB"
        />
        <path
          d="M125 255.94C125 255.421 124.579 255 124.06 255H121.941C121.422 255 121.001 255.421 121.001 255.94V260H125V255.94Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M121 274.06C121 274.579 121.421 275 121.94 275H124.059C124.578 275 124.999 274.579 124.999 274.06V270H121V274.06Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 125 260.833)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#5-3_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M121 165H32V286H121V165ZM110 176H43V275H110V176Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M121 165H32V286H33.2192V166H119.781V286H121V165Z"
          fill="#FBFBFB"
        />
        <g filter="url(#5-3_filter3_i)">
          <rect
            x="43.47"
            y="176.47"
            width="66.0601"
            height="98.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="169.53"
          width="79.9399"
          height="111.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="34"
          y="218"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#5-3_filter4_d)">
          <rect x="34.7018" y="218.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.2859 229.795C35.7981 230.457 36.6003 230.884 37.5021 230.884C39.0485 230.884 40.3021 229.63 40.3021 228.084V221.444C40.3021 220.455 39.79 219.587 39.0165 219.088C39.3825 219.562 39.6003 220.155 39.6003 220.8V227.44C39.6003 228.986 38.3467 230.24 36.8003 230.24C36.2424 230.24 35.7225 230.077 35.2859 229.795Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.5018 218.644C35.9554 218.644 34.7018 219.898 34.7018 221.444V227.44C34.7018 227.46 34.7021 227.48 34.7025 227.501V222.024C34.7025 220.477 35.9561 219.224 37.5025 219.224C39.0285 219.224 40.2694 220.444 40.3018 221.963V221.444C40.3018 219.898 39.0482 218.644 37.5018 218.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#5-3_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.5 222C36.6716 222 36 222.672 36 223.5V223.714V225V243.5C36 244.328 36.6716 245 37.5 245V245C38.3284 245 39 244.328 39 243.5V227V223.5L39 223.286C39 222.576 38.4244 222 37.7143 222V222C37.6771 222 37.6401 222.001 37.6035 222.004C37.5693 222.001 37.5348 222 37.5 222Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M108.5 177.997L44.836 225.5L108.5 273.003L108.5 177.997Z" stroke="white" />
        <defs>
          <filter
            id="5-3_filter0_d"
            x="119.12"
            y="174.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-3_filter1_d"
            x="119.12"
            y="253.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-3_filter2_d"
            x="27.3003"
            y="160.3"
            width="100.279"
            height="132.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-3_filter3_i"
            x="43"
            y="176"
            width="67.9399"
            height="101.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="5-3_filter4_d"
            x="32.822"
            y="216.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="5-3_filter5_d"
            x="34.1201"
            y="220.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="127.8"
            y1="193.5"
            x2="120.2"
            y2="193.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="118.202"
            y1="178.5"
            x2="125.8"
            y2="178.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="127.798"
            y1="193.5"
            x2="120.2"
            y2="193.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="127.8"
            y1="272.5"
            x2="120.2"
            y2="272.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="118.202"
            y1="257.5"
            x2="125.8"
            y2="257.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="127.798"
            y1="272.5"
            x2="120.2"
            y2="272.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
