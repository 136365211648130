import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement36Square8Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#3-6_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M323 46.9995C323 45.8952 322.105 45 321.001 45C319.896 45 319.001 45.8952 319.001 46.9995V49.1665H319.001V60H319V63.0005C319 64.1048 319.895 65 320.999 65C322.104 65 322.999 64.1048 322.999 63.0005V60.8332H323V50H323V46.9995Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 323 49.1665)"
          fill="#ABABAB"
        />
        <path
          d="M323 45.9399C323 45.4208 322.579 45 322.06 45H319.941C319.422 45 319.001 45.4208 319.001 45.9399V50H323V45.9399Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M319 64.0601C319 64.5792 319.421 65 319.94 65H322.059C322.578 65 322.999 64.5792 322.999 64.0601V60H319V64.0601Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 323 50.8335)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#3-6_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M319 123C319 121.895 319.895 121 321 121C322.105 121 323 121.895 323 123V137.56H319V123ZM319 137.56H323V139.4H323.06C323.579 139.4 324 139.821 324 140.34V142.12C324 143.158 323.158 144 322.12 144H320.88C319.842 144 319 143.158 319 142.12V139.4V139.4V137.56Z"
            fill="white"
          />
        </g>
        <rect x="319" y="138.25" width="3.99998" height="0.95833" fill="#ABABAB" />
        <path
          d="M319 121.94C319 121.421 319.421 121 319.94 121H322.06C322.579 121 323 121.421 323 121.94V138.25H319V121.94Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M319 143.06C319 143.579 319.421 144 319.94 144H323.06C323.579 144 324 143.579 324 143.06V140.148C324 139.629 323.579 139.208 323.06 139.208H319V143.06Z"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#3-6_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M319 34H230V155H319V34ZM308 45H241V144H308V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M319 34H230V155H231.219V35H317.781V155H319V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-6_filter3_i)">
          <rect
            x="241.47"
            y="45.47"
            width="66.0601"
            height="98.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="234.53"
          y="38.53"
          width="79.9399"
          height="111.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="232"
          y="87"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#3-6_filter4_d)">
          <rect x="232.702" y="87.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M233.286 98.7956C233.798 99.4577 234.6 99.8841 235.502 99.8841C237.048 99.8841 238.302 98.6305 238.302 97.0841V90.4442C238.302 89.4557 237.79 88.5869 237.016 88.0886C237.382 88.5619 237.6 89.1556 237.6 89.8001V96.44C237.6 97.9864 236.347 99.24 234.8 99.24C234.242 99.24 233.722 99.0768 233.286 98.7956Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M235.502 87.644C233.956 87.644 232.702 88.8976 232.702 90.444V96.4397C232.702 96.4601 232.702 96.4805 232.703 96.5007V91.0236C232.703 89.4772 233.956 88.2236 235.503 88.2236C237.029 88.2236 238.269 89.4444 238.302 90.9627V90.444C238.302 88.8976 237.048 87.644 235.502 87.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-6_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M235.5 91C234.672 91 234 91.6716 234 92.5V92.7143V94V112.5C234 113.328 234.672 114 235.5 114V114C236.328 114 237 113.328 237 112.5V96V92.5L237 92.2857C237 91.5756 236.424 91 235.714 91V91C235.677 91 235.64 91.0012 235.604 91.0035C235.569 91.0012 235.535 91 235.5 91Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M306.5 46.9969L242.836 94.5L306.5 142.003L306.5 46.9969Z" stroke="white" />
        <path d="M242.695 142.5L274.5 47.5739L306.305 142.5L242.695 142.5Z" stroke="white" />
        <defs>
          <filter
            id="3-6_filter0_d"
            x="317.12"
            y="43.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-6_filter1_d"
            x="317.12"
            y="119.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-6_filter2_d"
            x="225.3"
            y="29.3003"
            width="100.279"
            height="132.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-6_filter3_i"
            x="241"
            y="45"
            width="67.9399"
            height="101.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-6_filter4_d"
            x="230.822"
            y="85.7642"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-6_filter5_d"
            x="232.12"
            y="89.1201"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="325.8"
            y1="62.5"
            x2="318.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="316.202"
            y1="47.5"
            x2="323.8"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="325.798"
            y1="62.5"
            x2="318.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="325.8"
            y1="129.625"
            x2="318.2"
            y2="129.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="327.5"
            y1="141.604"
            x2="318"
            y2="141.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
