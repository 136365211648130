import { createStore, applyMiddleware, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'

import { sessionReducer } from './session/reducer'
import { pageReducer } from './page/reducer'

const reducers = combineReducers({
  page: pageReducer,
  session: sessionReducer,
})

export default (initialState?: any) => {
  const middlewares = composeWithDevTools(applyMiddleware(thunkMiddleware))
  return createStore(reducers, initialState, middlewares)
}
