import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement27Square3Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#2-7_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M171.999 30C170.895 30 170 30.8952 170 31.9995C170 33.1038 170.895 33.999 171.999 33.999L174.167 33.999L174.167 33.999L185 33.999L185 34L188.001 34C189.105 34 190 33.1048 190 32.0005C190 30.8962 189.105 30.001 188.001 30.001L185.833 30.001L185.833 30L175 30L175 30L171.999 30Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 174.166 30)"
          fill="#ABABAB"
        />
        <path
          d="M170.94 30C170.421 30 170 30.4208 170 30.9399L170 33.059C170 33.5781 170.421 33.999 170.94 33.999L175 33.999L175 30L170.94 30Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M189.06 34C189.579 34 190 33.5792 190 33.0601L190 30.941C190 30.4219 189.579 30.001 189.06 30.001L185 30.001L185 34L189.06 34Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 175.833 30)"
          fill="url(#paint2-7_linear)"
        />
        <g filter="url(#2-7_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M259.999 30C258.895 30 258 30.8952 258 31.9995C258 33.1038 258.895 33.999 259.999 33.999L262.167 33.999L262.167 33.999L273 33.999L273 34L276.001 34C277.105 34 278 33.1048 278 32.0005C278 30.8962 277.105 30.001 276.001 30.001L273.833 30.001L273.833 30L263 30L263 30L259.999 30Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 262.166 30)"
          fill="#ABABAB"
        />
        <path
          d="M258.94 30C258.421 30 258 30.4208 258 30.9399L258 33.059C258 33.5781 258.421 33.999 258.94 33.999L263 33.999L263 30L258.94 30Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M277.06 34C277.579 34 278 33.5792 278 33.0601L278 30.941C278 30.4219 277.579 30.001 277.06 30.001L273 30.001L273 34L277.06 34Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 263.833 30)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#2-7_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M284 34H164V111H284V34ZM273 45H175V100H273V45Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#2-7_filter2-7_i)">
          <rect
            x="175.47"
            y="45.47"
            width="97.0601"
            height="54.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="169.5" y="39.5" width="109" height="66" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M284 34H164V111H165V35H283V111H284V34Z"
          fill="#FBFBFB"
        />
        <path d="M177.127 46.5L270.873 46.5L224 98.2552L177.127 46.5Z" stroke="white" />
        <rect
          x="230"
          y="102"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 230 102)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#2-7_filter4_d)">
          <rect
            x="229.355"
            y="102.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 229.355 102.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M229.238 104.695C228.892 103.542 227.822 102.702 226.556 102.702L219.916 102.702C218.37 102.702 217.116 103.956 217.116 105.502C217.116 105.537 217.117 105.572 217.118 105.607C217.465 106.76 218.534 107.6 219.8 107.6L226.44 107.6C227.987 107.6 229.24 106.347 229.24 104.8C229.24 104.765 229.24 104.73 229.238 104.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M227.881 103.035C227.737 103.012 227.59 103 227.44 103L220.8 103C219.254 103 218 104.254 218 105.8C218 106.867 218.597 107.795 219.475 108.268C218.138 108.056 217.116 106.898 217.116 105.502C217.116 103.956 218.37 102.702 219.916 102.702L226.556 102.702C227.035 102.702 227.486 102.823 227.881 103.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#2-7_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M226 105.5C226 104.672 225.328 104 224.5 104L224.286 104L223 104L204.5 104C203.672 104 203 104.672 203 105.5C203 106.328 203.672 107 204.5 107L221 107L224.5 107L224.714 107C225.424 107 226 106.424 226 105.714C226 105.677 225.999 105.64 225.996 105.604C225.999 105.569 226 105.535 226 105.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="2-7_filter0_d"
            x="168.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-7_filter1_d"
            x="256.12"
            y="28.1201"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-7_filter2_d"
            x="159.3"
            y="29.3003"
            width="131.279"
            height="88.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-7_filter2-7_i"
            x="175"
            y="45"
            width="98.9399"
            height="57.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="2-7_filter4_d"
            x="215.235"
            y="100.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="2-7_filter5_d"
            x="201.12"
            y="102.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="187.5"
            y1="27.2"
            x2="187.5"
            y2="34.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="172.5"
            y1="36.7982"
            x2="172.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="187.5"
            y1="27.2018"
            x2="187.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2-7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="275.5"
            y1="27.2"
            x2="275.5"
            y2="34.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="260.5"
            y1="36.7982"
            x2="260.5"
            y2="29.2002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="275.5"
            y1="27.2018"
            x2="275.5"
            y2="34.7998"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
