import { QuizView } from '../interface'

export const quizSchema = {
  'product-type': {
    type: 'object',
    code: 'product-type',
    title: 'Тип продукта',
    properties: {
      value: { type: 'string' },
      title: { type: 'string' },
      description: { type: 'string' },
      'preview-url': { type: 'string' },
    },
    enum: [
      {
        value: 'window',
        title: 'Окно',
        'preview-url':
          'https://storage.yandexcloud.net/axioma/files/c3454f9e-bdd2-459a-bc27-6afba4fc4b3b.png',
      },
      {
        value: 'door',
        title: 'Дверь',
        'preview-url':
          'https://storage.yandexcloud.net/axioma/files/91d7a378-a8ca-42b2-b3f2-ccbf09456a52.png',
      },
      {
        value: 'loggia',
        title: 'Лоджия',
        'preview-url':
          'https://storage.yandexcloud.net/axioma/files/8c40e0d6-be97-403c-bd02-42db2966c0f6.png',
      },
    ],
  },
  'window-model': {
    type: 'object',
    code: 'window-model',
    title: 'Тип помещения',
    properties: {
      value: { type: 'string' },
      title: { type: 'string' },
      description: { type: 'string' },
      'preview-url': { type: 'string' },
    },
    enum: [
      {
        value: 'model-1',
        title: 'Квартира',
        'preview-url':
          'https://storage.yandexcloud.net/axioma/files/e4007b83-7cdb-4bbd-bf07-d36d4c95232f.png',
      },
      {
        value: 'model-3',
        title: 'Дом для круглогодичного проживания',
        'preview-url':
          'https://storage.yandexcloud.net/axioma/files/da66e3b4-39d3-49dc-95d6-82f462ebafd6.png',
      },
      {
        value: 'model-2',
        title: 'Дом для летнего проживания',
        'preview-url':
          'https://storage.yandexcloud.net/axioma/files/6fc57abc-2058-46b2-8b58-8d322e04c2ba.png',
      },
      {
        value: 'model-4',
        title: 'Нежилое помещение',
        'preview-url':
          'https://storage.yandexcloud.net/axioma/files/28638669-8ff2-4cac-89ad-e1def41bd89e.png',
      },
    ],
  },
  'door-model': {
    type: 'object',
    code: 'door-model',
    title: 'Тип помещения',
    properties: {
      value: { type: 'string' },
      title: { type: 'string' },
      description: { type: 'string' },
      'preview-url': { type: 'string' },
    },
    enum: [
      {
        value: 'model-1',
        title: 'Квартира',
        'preview-url':
          'https://storage.yandexcloud.net/axioma/files/e4007b83-7cdb-4bbd-bf07-d36d4c95232f.png',
      },
      {
        value: 'model-3',
        title: 'Дом для круглогодичного проживания',
        'preview-url':
          'https://storage.yandexcloud.net/axioma/files/da66e3b4-39d3-49dc-95d6-82f462ebafd6.png',
      },
      {
        value: 'model-2',
        title: 'Дом для летнего проживания',
        'preview-url':
          'https://storage.yandexcloud.net/axioma/files/6fc57abc-2058-46b2-8b58-8d322e04c2ba.png',
      },
      {
        value: 'model-4',
        title: 'Нежилое помещение',
        'preview-url':
          'https://storage.yandexcloud.net/axioma/files/28638669-8ff2-4cac-89ad-e1def41bd89e.png',
      },
    ],
  },
  'loggia-model': {
    type: 'object',
    code: 'loggia-model',
    title: 'Тип помещения',
    properties: {
      value: { type: 'string' },
      title: { type: 'string' },
      description: { type: 'string' },
      'preview-url': { type: 'string' },
    },
    enum: [
      {
        value: 'model-1',
        title: 'Квартира',
        'preview-url':
          'https://storage.yandexcloud.net/axioma/files/e4007b83-7cdb-4bbd-bf07-d36d4c95232f.png',
      },
      {
        value: 'model-3',
        title: 'Дом для круглогодичного проживания',
        'preview-url':
          'https://storage.yandexcloud.net/axioma/files/da66e3b4-39d3-49dc-95d6-82f462ebafd6.png',
      },
      {
        value: 'model-2',
        title: 'Дом для летнего проживания',
        'preview-url':
          'https://storage.yandexcloud.net/axioma/files/6fc57abc-2058-46b2-8b58-8d322e04c2ba.png',
      },
      {
        value: 'model-4',
        title: 'Нежилое помещение',
        'preview-url':
          'https://storage.yandexcloud.net/axioma/files/28638669-8ff2-4cac-89ad-e1def41bd89e.png',
      },
    ],
  },
  filters: {
    type: 'object',
    code: 'filters',
    title: 'Фильтры',
    properties: {
      value: { type: 'string' },
      'product-type': { type: 'string' },
      'window-model': { type: 'string' },
    },
    reducer:
      '(props, state) => props.filter(item => { let result = true; Object.keys(item).forEach(key => { if (result && key !== "value") result = item[key] === state[key]}); return result})',
    enum: [
      {
        value:
          '[{"key":"window-shape","value":"rectangular"},{"key":"configuration","value":"ПО-144/ЕВРО-3"},{"key":"aeration","value":true},{"key":"window-color","value":"model-outside-color"},{"key":"slope-switcher","value":"inner-slope"},{"key":"model-outside-color","value":"-"},{"key":"model-inside-color","value":"-"},{"key":"model-profile-color","value":"2052089wop"},{"key":"configuration-images","value":"square2Casement, square2Casement/casement-0-1"},{"key":"casements-index","value":[0]},{"key":"mosquito-type-color","value":"RAL 9010"},{"key":"window-hand","value":"white"},{"key": "windowsill-model-color","value":"2052089sw"},{"key": "outer-slope-type-color","value":"2052089z4b"},{"key": "inner-slope-type-color","value":"2052089z4b"},{"key": "ebb-type","value":"simple"},{"key": "ebb-type-color","value":"RAL 9010"},{"key": "outer-slope-type","value":"pvh"},{"key": "inner-slope-type","value":"pvh"}]',
        'product-type': 'window',
        'window-model': 'model-1',
      },
      {
        value:
          '[{"key":"window-shape","value":"rectangular"},{"key":"configuration","value":"ПО-144/ЕВРО-3"},{"key":"aeration","value":true},{"key":"window-color","value":"model-outside-color"},{"key":"slope-switcher","value":"inner-slope"},{"key":"model-outside-color","value":"-"},{"key":"model-inside-color","value":"-"},{"key":"model-profile-color","value":"2052089wop"},{"key":"configuration-images","value":"square2Casement, square2Casement/casement-0-1"},{"key":"casements-index","value":[0]},{"key":"mosquito-type-color","value":"RAL 9010"},{"key":"window-hand","value":"white"},{"key": "windowsill-model-color","value":"2052089sw"},{"key": "outer-slope-type-color","value":"2052089z4b"},{"key": "inner-slope-type-color","value":"2052089z4b"},{"key": "ebb-type","value":"simple"},{"key": "ebb-type-color","value":"RAL 9010"},{"key": "outer-slope-type","value":"pvh"},{"key": "inner-slope-type","value":"pvh"}]',
        'product-type': 'window',
        'window-model': 'model-2',
      },
      {
        value:
          '[{"key":"window-shape","value":"rectangular"},{"key":"configuration","value":"ПО-144/ЕВРО-3"},{"key":"aeration","value":true},{"key":"window-color","value":"model-outside-color"},{"key":"slope-switcher","value":"inner-slope"},{"key":"model-outside-color","value":"-"},{"key":"model-inside-color","value":"-"},{"key":"model-profile-color","value":"2052089wop"},{"key":"configuration-images","value":"square2Casement, square2Casement/casement-0-1"},{"key":"casements-index","value":[0]},{"key":"mosquito-type-color","value":"RAL 9010"},{"key":"window-hand","value":"white"},{"key": "windowsill-model-color","value":"2052089sw"},{"key": "outer-slope-type-color","value":"2052089z4b"},{"key": "inner-slope-type-color","value":"2052089z4b"},{"key": "ebb-type","value":"simple"},{"key": "ebb-type-color","value":"RAL 9010"},{"key": "outer-slope-type","value":"pvh"},{"key": "inner-slope-type","value":"pvh"}]',
        'product-type': 'window',
        'window-model': 'model-3',
      },
    ],
  },
}

export const preferences = {
  'window-model': [
    {
      title: 'Какую задачу решаем?',
      code: 'window-task',
      enum: [
        {
          title: 'Замена старых окон',
          key: 'window-task',
          value: 1,
        },
        {
          title: `Улучшение характеристик 
        (шумозащита, теплозащита и т.п)`,
          key: 'window-task',
          value: 2,
        },
        {
          title: 'Меняю окна от застройщика',
          key: 'window-task',
          value: 3,
        },
      ],
    },
    {
      title: 'Какое окно выбираете?',
      code: 'window-type',
      enum: [
        {
          title: `Окно в комнату`,
          key: 'window-type',
          value: 1,
        },
        {
          title: `Балконный блок`,
          key: 'window-type',
          value: 2,
        },
        {
          title: 'Оба варианта',
          key: 'window-type',
          value: 3,
        },
      ],
    },
    {
      title: 'Замер нужен?',
      code: 'window-measurement',
      enum: [
        {
          title: 'Да, нужен',
          key: 'window-measurement',
          value: 1,
        },
        {
          title: 'Нет, я знаю размеры',
          key: 'window-measurement',
          value: 2,
        },
        {
          title: 'Есть готовый проект, файл вышлю',
          key: 'window-measurement',
          value: 3,
        },
      ],
    },
    {
      title: 'Монтаж нужен?',
      code: 'window-installation',
      enum: [
        {
          title: 'Нет, сами установим',
          key: 'window-installation',
          value: 1,
        },
        {
          title: 'Да, под ключ с отделкой',
          key: 'window-installation',
          value: 2,
        },
        {
          title: 'Да, нужен монтаж и демонтаж',
          key: 'window-installation',
          value: 3,
        },
      ],
    },
    /*{
      title: 'Защита от взлома',
      description: 'Безопасность доступна только для Модель 2.0 и Модель 3.0',
      code: 'security',
      'access-list': ['model-2', 'model-3'],
      enum: [
        {
          title: 'Не требуется',
          key: 'security',
          value: 1,
        },
        {
          title: 'Важно, помещение на 1,2 или последнем этаже',
          key: 'security',
          value: 2,
        },
        // {
        //   title: 'Крайне важно, помещение требует высокой степени противовзломности и безопасности',
        //   key: 'security',
        //   value: 3,
        // },
      ],
    },*/
  ],
  'door-model': [
    {
      title: 'Где будет установлена дверь?',
      code: 'door-where',
      enum: [
        {
          title: 'Наружняя',
          key: 'door-where',
          value: 1
        },
        {
          title: 'Внутренняя',
          key: 'door-where',
          value: 2,
        },
      ],
    },
    {
      title: 'Тип двери?',
      code: 'door-type',
      enum: [
        {
          title: `Первая и единственная`,
          key: 'door-type',
          value: 1,
        },
        {
          title: `Тамбурная система`,
          key: 'door-type',
          value: 2,
        },
        {
          title: 'Внутри помещения',
          key: 'door-type',
          value: 3,
        },
      ],
    },
    {
      title: 'Тип заполнения?',
      code: 'fill-type',
      enum: [
        {
          title: 'Полностью стеклянная',
          key: 'fill-type',
          value: 1,
        },
        {
          title: 'Полностью непрозрачная',
          key: 'fill-type',
          value: 2,
        },
        {
          title: 'Комбинированное',
          key: 'fill-type',
          value: 3,
        },
      ],
    },
    {
      title: 'Проходимость в день большая?',
      code: 'day-traffic',
      enum: [
        {
          title: 'До 10 чел',
          key: 'day-traffic',
          value: 1,
        },
        {
          title: 'От 10 до 50 чел',
          key: 'day-traffic',
          value: 2,
        },
        {
          title: 'Свыше 50 чел',
          key: 'day-traffic',
          value: 3,
        },
      ],
    },
  ],
  'loggia-model': [
    {
      title: 'Тип остекления',
      code: 'glazing-type',
      enum: [
        {
          title: 'Теплое',
          key: 'glazing-type',
          value: 1
        },
        {
          title: 'Холодное',
          key: 'glazing-type',
          value: 2,
        },
      ],
    },
    {
      title: 'Сейчас есть остекление?',
      code: 'glazing-now',
      enum: [
        {
          title: `Да`,
          key: 'glazing-now',
          value: 1,
        },
        {
          title: `Нет`,
          key: 'glazing-now',
          value: 2,
        },
      ],
    },
    {
      title: 'Планируете отделку в последующем?',
      code: 'glazing-future',
      enum: [
        {
          title: 'Да',
          key: 'glazing-future',
          value: 1,
        },
        {
          title: 'Нет',
          key: 'glazing-future',
          value: 2,
        },
      ],
    },
  ]
}

export const preferences1 = [
  {
    title: 'Какие окна хотите купить?',
    code: 'window-model',
    enum: [
      {
        title: 'Базовая комплектация',
        key: 'window-model',
        value: 'model-1',
      },
      {
        title: 'Стандарт, выгодные по соотношению цена и качество',
        key: 'window-model',
        value: 'model-2',
      },
      {
        title: 'Я выбираю всегда лучшие современные решения',
        key: 'window-model',
        value: 'model-3',
      },
    ],
  },
  {
    title: 'Теплосбережение',
    code: 'heat-saving',
    enum: [
      {
        title: `Батареи греют хорошо.
        Тепло всегда`,
        key: 'heat-saving',
        value: 1,
      },
      {
        title: `Около окна зимой холодно.
        Хочу теплее`,
        key: 'heat-saving',
        value: 2,
      },
      {
        title: 'Дует, зимой холодно. Батареи работают плохо',
        key: 'heat-saving',
        value: 3,
      },
    ],
  },
  {
    title: 'Звукоизоляция',
    code: 'soundproofing',
    enum: [
      {
        title: 'Дополнительная не требуется',
        key: 'soundproofing',
        value: 1,
      },
      {
        title: 'Окно выходит на шумную сторону',
        key: 'soundproofing',
        value: 2,
      },
      {
        title: 'Я выбираю всегда лучшие современные решения',
        key: 'soundproofing',
        value: 3,
      },
    ],
  },
  {
    title: 'Защита от солнца',
    code: 'sun-protection',
    enum: [
      {
        title: 'Не требуется',
        key: 'sun-protection',
        value: 1,
      },
      {
        title: 'Окно на солнечной стороне',
        key: 'sun-protection',
        value: 2,
      },
      {
        title: 'Я выбираю всегда лучшие современные решения',
        key: 'sun-protection',
        value: 3,
      },
    ],
  },
  {
    title: 'Защита от взлома',
    description: 'Безопасность доступна только для Модель 2.0 и Модель 3.0',
    code: 'security',
    'access-list': ['model-2', 'model-3'],
    enum: [
      {
        title: 'Не требуется',
        key: 'security',
        value: 1,
      },
      {
        title: 'Важно, помещение на 1,2 или последнем этаже',
        key: 'security',
        value: 2,
      },
      // {
      //   title: 'Крайне важно, помещение требует высокой степени противовзломности и безопасности',
      //   key: 'security',
      //   value: 3,
      // },
    ],
  },
]

export const preferences2 = [
  {
    title: 'Защита от солнца',
    code: 'sun-protection',
    enum: [
      {
        title: 'Не требуется',
        key: 'sun-protection',
        value: 1,
      },
      {
        title: 'Окно на солнечной стороне',
        key: 'sun-protection',
        value: 2,
      },
      {
        title: 'Я выбираю всегда лучшие современные решения',
        key: 'sun-protection',
        value: 3,
      },
    ],
  },
  {
    title: 'Защита от взлома',
    description: 'Безопасность доступна только для Модель 2.0 и Модель 3.0',
    code: 'security',
    'access-list': ['model-2', 'model-3'],
    enum: [
      {
        title: 'Не требуется',
        key: 'security',
        value: 1,
      },
      {
        title: 'Важно, помещение на 1,2 или последнем этаже',
        key: 'security',
        value: 2,
      },
      // {
      //   title: 'Крайне важно, помещение требует высокой степени противовзломности и безопасности',
      //   key: 'security',
      //   value: 3,
      // },
    ],
  },
]

export const glassUnitMatrix = [
  /* model-1 */
  {
    'window-model': 'model-1',
    'heat-saving': 1,
    soundproofing: 1,
    'sun-protection': 1,
    value: '4-16-4',
  },
  {
    'window-model': 'model-1',
    'heat-saving': 2,
    soundproofing: 1,
    'sun-protection': 1,
    value: '4-10-4-10-4',
  },
  {
    'window-model': 'model-1',
    'heat-saving': 3,
    soundproofing: 1,
    'sun-protection': 1,
    value: '4-10-4-10-4И',
  },
  {
    'window-model': 'model-1',
    'heat-saving': 1,
    soundproofing: 2,
    'sun-protection': 1,
    value: '6-10-4-8-4',
  },
  {
    'window-model': 'model-1',
    'heat-saving': 1,
    soundproofing: 3,
    'sun-protection': 1,
    value: '4-10-4-10-4И',
  },
  {
    'window-model': 'model-1',
    'heat-saving': 2,
    soundproofing: 2,
    'sun-protection': 1,
    value: '6-10-4-8-4',
  },
  {
    'window-model': 'model-1',
    'heat-saving': 2,
    soundproofing: 3,
    'sun-protection': 1,
    value: '6-10-4-8-4И',
  },
  {
    'window-model': 'model-1',
    'heat-saving': 3,
    soundproofing: 2,
    'sun-protection': 1,
    value: '6-10-4-8-4И',
  },
  {
    'window-model': 'model-1',
    'heat-saving': 3,
    soundproofing: 3,
    'sun-protection': 1,
    value: '6-10-4-8-4И',
  },
  {
    'window-model': 'model-1',
    'heat-saving': 1,
    soundproofing: 1,
    'sun-protection': 2,
    value: '4CGS-10-4-10-4',
  },
  {
    'window-model': 'model-1',
    'heat-saving': 2,
    soundproofing: 1,
    'sun-protection': 2,
    value: '4CGS-10-4-10-4',
  },
  {
    'window-model': 'model-1',
    'heat-saving': 3,
    soundproofing: 1,
    'sun-protection': 2,
    value: '4CGS-10-4-10-4И',
  },
  {
    'window-model': 'model-1',
    'heat-saving': 1,
    soundproofing: 2,
    'sun-protection': 2,
    value: '6CGS-10-4-8-4',
  },
  {
    'window-model': 'model-1',
    'heat-saving': 1,
    soundproofing: 3,
    'sun-protection': 2,
    value: '6CGS-10-4-8-4',
  },
  {
    'window-model': 'model-1',
    'heat-saving': 2,
    soundproofing: 2,
    'sun-protection': 2,
    value: '6CGS-10-4-8-4',
  },
  {
    'window-model': 'model-1',
    'heat-saving': 2,
    soundproofing: 3,
    'sun-protection': 2,
    value: '6CGS-10-4-8-4И',
  },
  {
    'window-model': 'model-1',
    'heat-saving': 3,
    soundproofing: 2,
    'sun-protection': 2,
    value: '6CGS-10-4-8-4И',
  },
  {
    'window-model': 'model-1',
    'heat-saving': 3,
    soundproofing: 3,
    'sun-protection': 2,
    value: '6CGS-10-4-8-4И',
  },
  {
    'window-model': 'model-1',
    'heat-saving': 1,
    soundproofing: 1,
    'sun-protection': 3,
    value: '4CGS-10-4-10-4И',
  },
  {
    'window-model': 'model-1',
    'heat-saving': 2,
    soundproofing: 1,
    'sun-protection': 3,
    value: '4CGS-10-4-10-4И',
  },
  {
    'window-model': 'model-1',
    'heat-saving': 3,
    soundproofing: 1,
    'sun-protection': 3,
    value: '4CGS-10-4-10-4И',
  },
  {
    'window-model': 'model-1',
    'heat-saving': 1,
    soundproofing: 2,
    'sun-protection': 3,
    value: '6-10-4-8-4И',
  },
  {
    'window-model': 'model-1',
    'heat-saving': 1,
    soundproofing: 3,
    'sun-protection': 3,
    value: '6CGS-10-4-8-4',
  },
  {
    'window-model': 'model-1',
    'heat-saving': 2,
    soundproofing: 2,
    'sun-protection': 3,
    value: '6CGS-10-4-8-4И',
  },
  {
    'window-model': 'model-1',
    'heat-saving': 2,
    soundproofing: 3,
    'sun-protection': 3,
    value: '6CGS-10-4-8-4И',
  },
  {
    'window-model': 'model-1',
    'heat-saving': 3,
    soundproofing: 2,
    'sun-protection': 3,
    value: '6CGS-10-4-8-4И',
  },
  {
    'window-model': 'model-1',
    'heat-saving': 3,
    soundproofing: 3,
    'sun-protection': 3,
    value: '6CGS-10-4-8-4И',
  },
  /* model-2 */
  {
    'window-model': 'model-2',
    'heat-saving': 1,
    soundproofing: 1,
    'sun-protection': 1,
    value: '4-10-4-10-4',
  },
  {
    'window-model': 'model-2',
    'heat-saving': 2,
    soundproofing: 1,
    'sun-protection': 1,
    value: '4-10-4-10-4И',
  },
  {
    'window-model': 'model-2',
    'heat-saving': 3,
    soundproofing: 1,
    'sun-protection': 1,
    value: '4-14ПВХAr-4-14ПВХAr-4И',
  },
  {
    'window-model': 'model-2',
    'heat-saving': 1,
    soundproofing: 2,
    'sun-protection': 1,
    value: '4-10-4-10-4И',
  },
  {
    'window-model': 'model-2',
    'heat-saving': 1,
    soundproofing: 3,
    'sun-protection': 1,
    value: '6-12ПВХ-4-12ПВХ-3.3.1',
  },
  {
    'window-model': 'model-2',
    'heat-saving': 2,
    soundproofing: 2,
    'sun-protection': 1,
    value: '6-10-4-8-4И',
  },
  {
    'window-model': 'model-2',
    'heat-saving': 3,
    soundproofing: 2,
    'sun-protection': 1,
    value: '6CGS-12ПВХAr-4-12ПВХAr-3.3.1',
  },
  {
    'window-model': 'model-2',
    'heat-saving': 2,
    soundproofing: 3,
    'sun-protection': 1,
    value: '6CGS-12ПВХAr-4-12ПВХAr-3.3.1',
  },
  {
    'window-model': 'model-2',
    'heat-saving': 1,
    soundproofing: 1,
    'sun-protection': 2,
    value: '4CGS-14ПВХAr-4-14ПВХAr-4',
  },
  {
    'window-model': 'model-2',
    'heat-saving': 2,
    soundproofing: 1,
    'sun-protection': 2,
    value: '4CGS-10-4-10-4И',
  },
  {
    'window-model': 'model-2',
    'heat-saving': 1,
    soundproofing: 2,
    'sun-protection': 2,
    value: '6CGS-10-4-8-4',
  },
  {
    'window-model': 'model-2',
    'heat-saving': 3,
    soundproofing: 1,
    'sun-protection': 2,
    value: '4CGS-14ПВХAr-4-14ПВХAr-4И',
  },
  {
    'window-model': 'model-2',
    'heat-saving': 1,
    soundproofing: 3,
    'sun-protection': 2,
    value: '6CGS-12ПВХAr-4-12ПВХAr-3.3.1',
  },
  {
    'window-model': 'model-2',
    'heat-saving': 2,
    soundproofing: 3,
    'sun-protection': 2,
    value: '6CGS-12ПВХAr-4-12ПВХAr-3.3.1',
  },
  {
    'window-model': 'model-2',
    'heat-saving': 3,
    soundproofing: 2,
    'sun-protection': 2,
    value: '6CGS-12ПВХAr-4-12ПВХAr-3.3.1',
  },
  {
    'window-model': 'model-2',
    'heat-saving': 2,
    soundproofing: 2,
    'sun-protection': 2,
    value: '6CGS-10-4-8-4И',
  },
  {
    'window-model': 'model-2',
    'heat-saving': 3,
    soundproofing: 3,
    'sun-protection': 1,
    value: '6CGS-12ПВХAr-4-12ПВХAr-3.3.1',
  },
  {
    'window-model': 'model-2',
    'heat-saving': 3,
    soundproofing: 3,
    'sun-protection': 2,
    value: '6CGS-12ПВХAr-4-12ПВХAr-3.3.1',
  },
  {
    'window-model': 'model-2',
    'heat-saving': 1,
    soundproofing: 1,
    'sun-protection': 3,
    value: '4CGS-14ПВХAr-4-14ПВХAr-4',
  },
  {
    'window-model': 'model-2',
    'heat-saving': 2,
    soundproofing: 1,
    'sun-protection': 3,
    value: '4CGS-14ПВХAr-4-14ПВХAr-4И',
  },
  {
    'window-model': 'model-2',
    'heat-saving': 3,
    soundproofing: 1,
    'sun-protection': 3,
    value: '4CGS-14ПВХAr-4-14ПВХAr-4И',
  },
  {
    'window-model': 'model-2',
    'heat-saving': 1,
    soundproofing: 2,
    'sun-protection': 3,
    value: '6CGS-12ПВХAr-4-12ПВХAr-3.3.1',
  },
  {
    'window-model': 'model-2',
    'heat-saving': 1,
    soundproofing: 3,
    'sun-protection': 3,
    value: '6CGS-12ПВХAr-4-12ПВХAr-3.3.1',
  },
  {
    'window-model': 'model-2',
    'heat-saving': 2,
    soundproofing: 2,
    'sun-protection': 3,
    value: '6CGS-12ПВХAr-4-12ПВХAr-3.3.1',
  },
  {
    'window-model': 'model-2',
    'heat-saving': 2,
    soundproofing: 3,
    'sun-protection': 3,
    value: '6CGS-12ПВХAr-4-12ПВХAr-3.3.1',
  },
  {
    'window-model': 'model-2',
    'heat-saving': 3,
    soundproofing: 2,
    'sun-protection': 3,
    value: '6CGS-12ПВХAr-4-12ПВХAr-3.3.1',
  },
  {
    'window-model': 'model-2',
    'heat-saving': 3,
    soundproofing: 3,
    'sun-protection': 3,
    value: '6CGS-12ПВХAr-4-12ПВХAr-3.3.1',
  },
  /* model-3 */
  {
    'window-model': 'model-3',
    'heat-saving': 1,
    soundproofing: 1,
    'sun-protection': 1,
    value: '4-14-4-16-4',
  },
  {
    'window-model': 'model-3',
    'heat-saving': 2,
    soundproofing: 1,
    'sun-protection': 1,
    value: '4-14ПВХAr-4-16ПВХAr-4И',
  },
  {
    'window-model': 'model-3',
    'heat-saving': 3,
    soundproofing: 1,
    'sun-protection': 1,
    value: '4-14ПВХAr-4-16ПВХAr-4И',
  },
  {
    'window-model': 'model-3',
    'heat-saving': 1,
    soundproofing: 2,
    'sun-protection': 1,
    value: '6И-12ПВХAr-4-12ПВХAr-4.4.1',
  },
  {
    'window-model': 'model-3',
    'heat-saving': 1,
    soundproofing: 3,
    'sun-protection': 1,
    value: '6CGS-12ПВХAr-4-12ПВХAr-4.4.1',
  },
  {
    'window-model': 'model-3',
    'heat-saving': 2,
    soundproofing: 2,
    'sun-protection': 1,
    value: '6И-12ПВХAr-4-12ПВХAr-4.4.1',
  },
  {
    'window-model': 'model-3',
    'heat-saving': 3,
    soundproofing: 2,
    'sun-protection': 1,
    value: '6И-12ПВХAr-4-12ПВХAr-4.4.1',
  },
  {
    'window-model': 'model-3',
    'heat-saving': 2,
    soundproofing: 3,
    'sun-protection': 1,
    value: '6И-12ПВХAr-4-12ПВХAr-4.4.1',
  },
  {
    'window-model': 'model-3',
    'heat-saving': 3,
    soundproofing: 3,
    'sun-protection': 1,
    value: '6И-12ПВХAr-4-12ПВХAr-4.4.1',
  },
  {
    'window-model': 'model-3',
    'heat-saving': 1,
    soundproofing: 1,
    'sun-protection': 2,
    value: '4CGS-14ПВХAr-4-16ПВХAr-4',
  },
  {
    'window-model': 'model-3',
    'heat-saving': 2,
    soundproofing: 1,
    'sun-protection': 2,
    value: '4CGS-14ПВХAr-4-16ПВХAr-4И',
  },
  {
    'window-model': 'model-3',
    'heat-saving': 3,
    soundproofing: 1,
    'sun-protection': 2,
    value: '4CGS-14ПВХAr-4-16ПВХAr-4И',
  },
  {
    'window-model': 'model-3',
    'heat-saving': 1,
    soundproofing: 2,
    'sun-protection': 2,
    value: '6CGS-12ПВХAr-4-12ПВХAr-4.4.1',
  },
  {
    'window-model': 'model-3',
    'heat-saving': 1,
    soundproofing: 3,
    'sun-protection': 2,
    value: '6CGS-12ПВХAr-4-12ПВХAr-4.4.1',
  },
  {
    'window-model': 'model-3',
    'heat-saving': 2,
    soundproofing: 2,
    'sun-protection': 2,
    value: '6CGS-12ПВХAr-4-12ПВХAr-4.4.1',
  },
  {
    'window-model': 'model-3',
    'heat-saving': 3,
    soundproofing: 2,
    'sun-protection': 2,
    value: '6CGS-12ПВХAr-4-12ПВХAr-4.4.1',
  },
  {
    'window-model': 'model-3',
    'heat-saving': 2,
    soundproofing: 3,
    'sun-protection': 2,
    value: '6CGS-12ПВХAr-4-12ПВХAr-4.4.1',
  },
  {
    'window-model': 'model-3',
    'heat-saving': 3,
    soundproofing: 3,
    'sun-protection': 2,
    value: '6CGS-12ПВХAr-4-12ПВХAr-4.4.1',
  },
  {
    'window-model': 'model-3',
    'heat-saving': 1,
    soundproofing: 1,
    'sun-protection': 3,
    value: '4CGS-14ПВХAr-4-16ПВХAr-4',
  },
  {
    'window-model': 'model-3',
    'heat-saving': 2,
    soundproofing: 1,
    'sun-protection': 3,
    value: '4CGS-14ПВХAr-4-16ПВХAr-4И',
  },
  {
    'window-model': 'model-3',
    'heat-saving': 3,
    soundproofing: 1,
    'sun-protection': 3,
    value: '4CGS-14ПВХAr-4-16ПВХAr-4И',
  },
  {
    'window-model': 'model-3',
    'heat-saving': 1,
    soundproofing: 2,
    'sun-protection': 3,
    value: '6CGS-12ПВХAr-4-12ПВХAr-4.4.1',
  },
  {
    'window-model': 'model-3',
    'heat-saving': 1,
    soundproofing: 3,
    'sun-protection': 3,
    value: '6CGS-12ПВХAr-4-12ПВХAr-4.4.1',
  },
  {
    'window-model': 'model-3',
    'heat-saving': 2,
    soundproofing: 2,
    'sun-protection': 3,
    value: '6CGS-12ПВХAr-4-12ПВХAr-4.4.1',
  },
  {
    'window-model': 'model-3',
    'heat-saving': 3,
    soundproofing: 2,
    'sun-protection': 3,
    value: '6CGS-12ПВХAr-4-12ПВХAr-4.4.1',
  },
  {
    'window-model': 'model-3',
    'heat-saving': 2,
    soundproofing: 3,
    'sun-protection': 3,
    value: '6CGS-12ПВХAr-4-12ПВХAr-4.4.1',
  },
  {
    'window-model': 'model-3',
    'heat-saving': 3,
    soundproofing: 3,
    'sun-protection': 3,
    value: '6CGS-12ПВХAr-4-12ПВХAr-4.4.1',
  },
]

export const functionality = [
  {
    title: 'Выращиваете цветы на подоконнике?',
    code: 'comfortable-floriculture',
    enum: [
      {
        title: 'Да',
        value: true,
        code: 'comfortable-floriculture',
      },
      {
        title: 'Нет',
        value: false,
        code: 'comfortable-floriculture',
      },
    ],
  },
  {
    title: 'Подоконник нужен?',
    code: 'windowsill-model-not-needed',
    enum: [
      {
        title: 'Да',
        value: false,
        code: 'windowsill-model-not-needed',
      },
      {
        title: 'Нет',
        value: true,
        code: 'windowsill-model-not-needed',
      },
    ],
  },
  // {
  //   title: 'Нужен внутренний откос?',
  //   code: 'inner-slope-type-not-needed',
  //   enum: [
  //     {
  //       title: 'Да',
  //       value: false,
  //       code: 'inner-slope-type-not-needed',
  //     },
  //     {
  //       title: 'Нет',
  //       value: true,
  //       code: 'inner-slope-type-not-needed',
  //     },
  //   ],
  // },
  {
    title: 'Детский замок нужен?',
    code: 'children-security',
    enum: [
      {
        title: 'Да',
        value: true,
        code: 'children-security',
      },
      {
        title: 'Нет',
        value: false,
        code: 'children-security',
      },
    ],
  },
  {
    title: 'Отлив нужен?',
    code: 'ebb-type-not-needed',
    enum: [
      {
        title: 'Да',
        value: false,
        code: 'ebb-type-not-needed',
      },
      {
        title: 'Нет',
        value: true,
        code: 'ebb-type-not-needed',
      },
    ],
  },
  // {
  //   title: 'Нужен внешний откос?',
  //   code: 'outer-slope-type-not-needed',
  //   enum: [
  //     {
  //       title: 'Да',
  //       value: false,
  //       code: 'outer-slope-type-not-needed',
  //     },
  //     {
  //       title: 'Нет',
  //       value: true,
  //       code: 'outer-slope-type-not-needed',
  //     },
  //   ],
  // },
  {
    title: 'Дома есть домашние животные?',
    code: 'mosquito-type',
    enum: [
      {
        title: 'Да',
        value: 'anti-cat',
        code: 'mosquito-type',
      },
      {
        title: 'Нет',
        value: 'simple',
        code: 'mosquito-type',
      },
    ],
  },
  // {
  //   title: 'Помещение расположено на 5 и выше этаже?',
  //   code: 'reinforced-windows-for-high-rise-buildings',
  //   enum: [
  //     {
  //       title: 'Да',
  //       value: true,
  //       code: 'reinforced-windows-for-high-rise-buildings',
  //     },
  //     {
  //       title: 'Нет',
  //       value: false,
  //       code: 'reinforced-windows-for-high-rise-buildings',
  //     },
  //   ],
  // },
]

export const initSizes = {
  'model-width': 1250,
  'model-height': 1300,
  'casements-x': [625, 625],
  'casements-y': [1300],
}

export const initGlassUnitSettings = {
  'sun-protection': 1,
  'heat-saving': 1,
  'energy-efficiency': 1,
  security: 1,
  soundproofing: 1,
  'door-where': 1,
  'door-type': 1,
  'fill-type': 1,
  'day-traffic': 1,
  'glazing-type': 1,
  'glazing-now': 1,
  'glazing-future': 1,
  'window-task': 1,
  'window-type': 1,
  'window-measurement': 1,
  'window-installation': 1,
}

export const quizStepMap = {
  [QuizView.SELECT_TYPE]: 'step1',
  [QuizView.SELECT_TYPE_PREMISES]: 'step2',
  [QuizView.SELECT_PREFERENCES_1]: 'step3',
  [QuizView.SELECT_PREFERENCES_2]: 'step4',
  [QuizView.SELECT_PREFERENCES_3]: 'step5',
}
