import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement41Square6Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-1_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M61.0005 128C62.1048 128 63 127.105 63 126.001C63 124.896 62.1048 124.001 61.0005 124.001L58.8335 124.001L58.8335 124.001L48 124.001L48 124L44.9995 124C43.8952 124 43 124.895 43 125.999C43 127.104 43.8952 127.999 44.9995 127.999L47.1668 127.999L47.1668 128L58 128L58 128L61.0005 128Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 58.834 128)"
          fill="#ABABAB"
        />
        <path
          d="M62.0601 128C62.5792 128 63 127.579 63 127.06L63 124.941C63 124.422 62.5792 124.001 62.0601 124.001L58 124.001L58 128L62.0601 128Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M43.9399 124C43.4208 124 43 124.421 43 124.94L43 127.059C43 127.578 43.4208 127.999 43.9399 127.999L48 127.999L48 124L43.9399 124Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 57.167 128)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#4-1_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M139.001 128C140.105 128 141 127.105 141 126.001C141 124.896 140.105 124.001 139.001 124.001L136.833 124.001L136.833 124.001L126 124.001L126 124L122.999 124C121.895 124 121 124.895 121 125.999C121 127.104 121.895 127.999 122.999 127.999L125.167 127.999L125.167 128L136 128L136 128L139.001 128Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 136.834 128)"
          fill="#ABABAB"
        />
        <path
          d="M140.06 128C140.579 128 141 127.579 141 127.06L141 124.941C141 124.422 140.579 124.001 140.06 124.001L136 124.001L136 128L140.06 128Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M121.94 124C121.421 124 121 124.421 121 124.94L121 127.059C121 127.578 121.421 127.999 121.94 127.999L126 127.999L126 124L121.94 124Z"
          fill="url(#paint4-1_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 135.167 128)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#4-1_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M152 128H32V287H152V128ZM141 139H43V276H141V139Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M152 128H32V287H33V129H151V287H152V128Z"
          fill="#FBFBFB"
        />
        <rect
          x="36.53"
          y="132.53"
          width="110.94"
          height="149.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#4-1_filter3_i)">
          <rect
            x="43.47"
            y="139.47"
            width="97.0601"
            height="136.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="100"
          y="278"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 100 278)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="99.3555"
          y="278.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 99.3555 278.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99.2383 280.695C98.8919 279.542 97.8222 278.702 96.5563 278.702L89.9164 278.702C88.37 278.702 87.1164 279.955 87.1164 281.502C87.1164 281.537 87.117 281.572 87.1183 281.607C87.4647 282.76 88.5344 283.6 89.8003 283.6L96.4402 283.6C97.9866 283.6 99.2402 282.346 99.2402 280.8C99.2402 280.765 99.2396 280.73 99.2383 280.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M97.8809 279.034C97.7372 279.012 97.5899 279 97.4398 279L90.7999 279C89.2535 279 87.9999 280.253 87.9999 281.8C87.9999 282.867 88.5968 283.795 89.4749 284.267C88.1381 284.056 87.116 282.898 87.116 281.502C87.116 279.955 88.3696 278.702 89.916 278.702L96.5559 278.702C97.0352 278.702 97.4864 278.822 97.8809 279.034Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-1_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M96 281.5C96 280.672 95.3284 280 94.5 280L94.2857 280L93 280L74.5 280C73.6716 280 73 280.672 73 281.5C73 282.328 73.6716 283 74.5 283L91 283L94.5 283L94.7143 283C95.4244 283 96 282.424 96 281.714C96 281.677 95.9988 281.64 95.9965 281.604C95.9988 281.569 96 281.535 96 281.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M44.7084 140.5L139.292 140.5L92 273.508L44.7084 140.5Z" stroke="white" />
        <defs>
          <filter
            id="4-1_filter0_d"
            x="41.1201"
            y="122.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-1_filter1_d"
            x="119.12"
            y="122.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-1_filter2_d"
            x="27.3003"
            y="123.3"
            width="131.279"
            height="170.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-1_filter3_i"
            x="43"
            y="139"
            width="98.9399"
            height="139.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-1_filter4_d"
            x="71.28"
            y="278.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="45.5"
            y1="130.8"
            x2="45.5"
            y2="123.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="60.5"
            y1="121.202"
            x2="60.5"
            y2="128.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="45.5"
            y1="130.798"
            x2="45.5"
            y2="123.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="123.5"
            y1="130.8"
            x2="123.5"
            y2="123.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="138.5"
            y1="121.202"
            x2="138.5"
            y2="128.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4-1_linear"
            x1="123.5"
            y1="130.798"
            x2="123.5"
            y2="123.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
