import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement71Square8Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#7-1_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M230 177.999C230 176.895 229.105 176 228.001 176C226.896 176 226.001 176.895 226.001 177.999V180.167H226.001V191H226V194.001C226 195.105 226.895 196 227.999 196C229.104 196 229.999 195.105 229.999 194.001V191.833H230V181H230V177.999Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 230 180.167)"
          fill="#ABABAB"
        />
        <path
          d="M230 176.94C230 176.421 229.579 176 229.06 176H226.941C226.422 176 226.001 176.421 226.001 176.94V181H230V176.94Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M226 195.06C226 195.579 226.421 196 226.94 196H229.059C229.578 196 229.999 195.579 229.999 195.06V191H226V195.06Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 230 181.833)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#7-1_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M230 256.999C230 255.895 229.105 255 228.001 255C226.896 255 226.001 255.895 226.001 256.999V259.167H226.001V270H226V273.001C226 274.105 226.895 275 227.999 275C229.104 275 229.999 274.105 229.999 273.001V270.833H230V260H230V256.999Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 230 259.167)"
          fill="#ABABAB"
        />
        <path
          d="M230 255.94C230 255.421 229.579 255 229.06 255H226.941C226.422 255 226.001 255.421 226.001 255.94V260H230V255.94Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M226 274.06C226 274.579 226.421 275 226.94 275H229.059C229.578 275 229.999 274.579 229.999 274.06V270H226V274.06Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 230 260.833)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#7-1_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M319 165H230V286H319V165ZM308 176H241V275H308V176Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M319 165H230V286H231.219V166H317.781V286H319V165Z"
          fill="#FBFBFB"
        />
        <g filter="url(#7-1_filter3_i)">
          <rect
            x="241.47"
            y="176.47"
            width="66.0601"
            height="98.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="234.53"
          y="169.53"
          width="79.9399"
          height="111.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="310"
          y="218"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#7-1_filter4_d)">
          <rect x="310.702" y="218.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M311.286 229.796C311.798 230.458 312.6 230.884 313.502 230.884C315.048 230.884 316.302 229.63 316.302 228.084V221.444C316.302 220.456 315.79 219.587 315.016 219.089C315.382 219.562 315.6 220.156 315.6 220.8V227.44C315.6 228.986 314.347 230.24 312.8 230.24C312.242 230.24 311.722 230.077 311.286 229.796Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M313.502 218.644C311.956 218.644 310.702 219.898 310.702 221.444V227.44C310.702 227.46 310.702 227.48 310.703 227.501V222.024C310.703 220.477 311.956 219.224 313.503 219.224C315.029 219.224 316.269 220.444 316.302 221.963V221.444C316.302 219.898 315.048 218.644 313.502 218.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#7-1_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M313.5 222C312.672 222 312 222.672 312 223.5V223.714V225V243.5C312 244.328 312.672 245 313.5 245V245C314.328 245 315 244.328 315 243.5V227V223.5L315 223.286C315 222.576 314.424 222 313.714 222V222C313.677 222 313.64 222.001 313.604 222.004C313.569 222.001 313.535 222 313.5 222Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M242.5 177.997L306.164 225.5L242.5 273.003L242.5 177.997Z" stroke="white" />
        <defs>
          <filter
            id="7-1_filter0_d"
            x="224.12"
            y="174.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7-1_filter1_d"
            x="224.12"
            y="253.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7-1_filter2_d"
            x="225.3"
            y="160.3"
            width="100.279"
            height="132.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7-1_filter3_i"
            x="241"
            y="176"
            width="67.9399"
            height="101.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="7-1_filter4_d"
            x="308.822"
            y="216.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="7-1_filter5_d"
            x="310.12"
            y="220.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="232.8"
            y1="193.5"
            x2="225.2"
            y2="193.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="223.202"
            y1="178.5"
            x2="230.8"
            y2="178.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="232.798"
            y1="193.5"
            x2="225.2"
            y2="193.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="232.8"
            y1="272.5"
            x2="225.2"
            y2="272.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="223.202"
            y1="257.5"
            x2="230.8"
            y2="257.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="232.798"
            y1="272.5"
            x2="225.2"
            y2="272.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
