import React, { FC, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  SelectArrow,
  SelectContentIcon,
  SelectDropdown,
  SelectIcon,
  SelectOption,
  SelectOptionsWrapper,
  SelectSelector,
  SelectText,
  SelectBlock,
  SelectWrapper,
  SelectContentIconWrapper,
} from './styles'
import { ISelectOption, ISelectProps } from './interface'
import { ComponentShadow } from '../component-shadow'

export const Select: FC<ISelectProps> = props => {
  const { placeholder = '', onSelect, selected: selectedItem, items, disabled = false } = props
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [selectedId, setSelectedId] = useState('')
  const selectRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (selectedItem && 'id' in selectedItem) {
      setSelectedId(selectedItem['id'])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const selected = useMemo(() => {
    const option = items.find(item => item.id === selectedId)

    return option || null
  }, [items, selectedId])

  const toggleDropdown = useCallback(() => {
    setDropdownOpen(prevState => !prevState)
  }, [])

  const handleSelectOption = useCallback(
    (item: ISelectOption) => () => {
      setSelectedId(item.id)

      if (onSelect && onSelect.constructor === Function) {
        onSelect(item)
      }

      setDropdownOpen(false)
    },
    [onSelect],
  )

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        !dropdownOpen &&
        selectRef?.current !== null &&
        event.target !== null &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false)
      }
    },
    [dropdownOpen],
  )

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => document.removeEventListener('click', handleClickOutside)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getOptionContent = useCallback((option: ISelectOption) => {
    const result: ReactElement[] = []
    const text = String(option?.text || '')

    if ('icon' in option && option.icon && option.icon.length > 0) {
      result.push(
        <SelectContentIconWrapper>
          <SelectContentIcon src={option.icon} alt="icon" key={`icon-${option.id}`} />
        </SelectContentIconWrapper>,
      )
    }

    if ('text' in option && text.length > 0) {
      result.push(<span key={`text-${option.id}`}>{text}</span>)
    }

    return result
  }, [])

  return (
    <SelectWrapper>
      {disabled && <ComponentShadow />}
      <SelectBlock ref={selectRef}>
        <SelectSelector onClick={toggleDropdown} open={dropdownOpen}>
          <SelectText>
            {selected ? getOptionContent(selected) : placeholder || 'Выберите значение'}
          </SelectText>
        </SelectSelector>
        <SelectArrow onClick={toggleDropdown}>
          <SelectIcon arrowDown={!dropdownOpen}>
            <svg
              width="9"
              height="5"
              viewBox="0 0 9 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.64092 3.22484L1.83602 0.215363C1.56839 -0.0717874 1.13447 -0.0717874 0.866843 0.215363C0.599213 0.502513 0.599213 0.968076 0.866843 1.25523L4.15633 4.78464C4.42396 5.07179 4.85788 5.07179 5.12551 4.78464L8.415 1.25523C8.68263 0.968076 8.68263 0.502513 8.415 0.215363C8.14737 -0.0717874 7.71345 -0.0717874 7.44582 0.215363L4.64092 3.22484Z"
                fill="#333333"
              />
            </svg>
          </SelectIcon>
        </SelectArrow>
        <SelectOptionsWrapper>
          <div>
            <SelectDropdown open={dropdownOpen}>
              {items?.length > 0 &&
                items.map(option => (
                  <SelectOption
                    id={option.id}
                    onClick={handleSelectOption(option)}
                    active={option.id === selectedId}
                    key={option.id}
                  >
                    {getOptionContent(option)}
                  </SelectOption>
                ))}
            </SelectDropdown>
          </div>
        </SelectOptionsWrapper>
      </SelectBlock>
      {/* {price > 0 && <SelectPrice>Цена: {price} ₽</SelectPrice>} */}
    </SelectWrapper>
  )
}
