import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement6Door1RCasement1 = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="451"
        height="451"
        viewBox="0 0 451 451"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#door-1-casement-1-6-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M265 269C265 267.895 265.895 267 267 267C268.105 267 269 267.895 269 269V283.56H265V269ZM265 283.56H269V285.4H269.06C269.579 285.4 270 285.821 270 286.34V288.12C270 289.158 269.158 290 268.12 290H266.88C265.842 290 265 289.158 265 288.12V285.4V285.4V283.56Z"
            fill="white"
          />
        </g>
        <rect x="265" y="284.25" width="3.99998" height="0.95833" fill="#ABABAB" />
        <path
          d="M265 267.94C265 267.421 265.421 267 265.94 267H268.06C268.579 267 269 267.421 269 267.94V284.25H265V267.94Z"
          fill="url(#door-1-casement-1-6-paint0_linear)"
        />
        <path
          d="M265 289.06C265 289.579 265.421 290 265.94 290H269.06C269.579 290 270 289.579 270 289.06V286.148C270 285.629 269.579 285.208 269.06 285.208H265V289.06Z"
          fill="url(#door-1-casement-1-6-paint1_linear)"
        />
        <g filter="url(#door-1-casement-1-6-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M268.954 46.9765C268.954 45.8849 268.069 45 266.978 45C265.886 45 265.001 45.8849 265.001 46.9765V49.1665H265.001V60H265V63.0235C265 64.1151 265.885 65 266.976 65C268.068 65 268.953 64.1151 268.953 63.0235V60.8332H268.954V50H268.954V46.9765Z"
            fill="url(#door-1-casement-1-6-paint2_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-1 0 0 1 268.954 49.1665)"
          fill="#ABABAB"
        />
        <path
          d="M268.954 45.9399C268.954 45.4208 268.533 45 268.014 45H265.941C265.422 45 265.001 45.4208 265.001 45.9399V50H268.954V45.9399Z"
          fill="url(#door-1-casement-1-6-paint3_linear)"
        />
        <path
          d="M265 64.0601C265 64.5792 265.421 65 265.94 65H268.013C268.532 65 268.953 64.5792 268.953 64.0601V60H265V64.0601Z"
          fill="url(#door-1-casement-1-6-paint4_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-1 0 0 1 268.954 50.8334)"
          fill="url(#door-1-casement-1-6-paint5_linear)"
        />
        <g filter="url(#door-1-casement-1-6-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M265 34H32V301H265V34ZM254 45H43V290H254V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M265 34H32V301H33V35H264V301H265V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="36.53"
          y="38.53"
          width="223.94"
          height="257.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#door-1-casement-1-6-filter3_i)">
          <rect
            x="43.47"
            y="45.47"
            width="210.06"
            height="244.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          width="6.91954"
          height="13.5283"
          rx="3.45977"
          transform="matrix(-1 0 0 1 40.9196 161)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#door-1-casement-1-6-filter4_d)">
          <rect
            width="5.53563"
            height="12.2399"
            rx="2.76782"
            transform="matrix(-1 0 0 1 40.2255 161.644)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.6605 172.792C39.1549 173.456 38.3564 173.884 37.4578 173.884C35.9292 173.884 34.69 172.645 34.69 171.116V164.412C34.69 163.44 35.1908 162.585 35.9483 162.092C35.5937 162.557 35.3831 163.138 35.3831 163.768V170.472C35.3831 172.001 36.6223 173.24 38.151 173.24C38.7079 173.24 39.2264 173.075 39.6605 172.792Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.4576 161.644C38.9863 161.644 40.2255 162.883 40.2255 164.412V170.472C40.2255 170.492 40.2252 170.512 40.2248 170.533V164.991C40.2248 163.463 38.9856 162.224 37.457 162.224C35.9486 162.224 34.7221 163.43 34.6898 164.931V164.412C34.6898 162.883 35.929 161.644 37.4576 161.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#door-1-casement-1-6-filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.4599 165C38.2788 165 38.9426 165.664 38.9426 166.483V168C38.9426 168.014 38.9425 168.027 38.9422 168.041L38.9422 186.517C38.9422 187.336 38.2784 188 37.4595 188C36.6406 188 35.9767 187.336 35.9767 186.517L35.9767 166.251C35.9767 165.56 36.5369 165 37.228 165C37.2308 165 37.2336 165 37.2364 165C37.3109 165 37.3854 165 37.4599 165Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M252.5 288.131V46.869L44.9949 167.5L252.5 288.131Z" stroke="white" />
        <path d="M252.241 288.5H44.7593L148.5 47.2656L252.241 288.5Z" stroke="white" />
        <defs>
          <filter
            id="door-1-casement-1-6-filter0_d"
            x="263.12"
            y="265.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-1-6-filter1_d"
            x="263.12"
            y="43.1201"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-1-6-filter2_d"
            x="27.3003"
            y="29.3003"
            width="244.279"
            height="278.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-1-6-filter3_i"
            x="43"
            y="45"
            width="211.94"
            height="247.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="door-1-casement-1-6-filter4_d"
            x="32.8099"
            y="159.764"
            width="11.1753"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-casement-1-6-filter5_d"
            x="34.0968"
            y="163.12"
            width="8.60556"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="door-1-casement-1-6-paint0_linear"
            x1="271.8"
            y1="275.625"
            x2="264.2"
            y2="275.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-6-paint1_linear"
            x1="273.5"
            y1="287.604"
            x2="264"
            y2="287.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-6-paint2_linear"
            x1="271.722"
            y1="62.5"
            x2="264.209"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-6-paint3_linear"
            x1="262.234"
            y1="47.5"
            x2="269.745"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-6-paint4_linear"
            x1="271.72"
            y1="62.5"
            x2="264.209"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-casement-1-6-paint5_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
