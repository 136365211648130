import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CalculatorStage, IStageSelectProps } from '../interface'
import { StageCalculator, StageCart, StageOrder, StageView } from '../index'
import { StagePersonalArea } from '../stage-personal-area'
import { StageQuiz } from '../stage-quiz'
import { IFilterItem } from '../stage-calculator/interface'
import { useMenuData } from '../../../hooks'
import {setLocalStorageItem} from "../../../services/local-storage";

export const StageSelector: FC<IStageSelectProps> = props => {
  const {
    stage,
    type,
    orders,
    currentOrder,
    preFilters,
    onChangeStage,
    onChangeType,
    onAddOrder,
    onEditOrder,
    onSetPreFilters,
    onSetCurrentOrderId,
    onUpdateOrder,
  } = props
  const [defaultData, setDefaultData] = useState<Record<string, any>>({})
  const [filters, setFilters] = useState<IFilterItem[]>([])
  const [isFirstPast, setIsFirstPast] = useState(true)
  const {
    fetchMenuData, // запрос данных меню и спецификации продукта
    menuItems, // поля меню
    menuItemsData, // данные меню
    specification, // спецификация продукта
    onDropSpecification, // обнуление спецификации
  } = useMenuData()
  const history = useHistory()

  useEffect(() => {
    const result: Record<string, any> = {}

    if (preFilters.length > 0) {
      if (filters.length === 0) {
        setFilters(preFilters)
      } else {
        filters.forEach(({ key, value }) => {
          const preData = menuItemsData[key]

          if (preData && preData.length > 0) {
            const preValue = preData.find(item => {
              let newValue = `${value}`

              if (value && value.constructor === Object && 'value' in (value as object)) {
                newValue = value['value']
              }

              return `${item.value}` === `${newValue}`
            })

            if (typeof preValue !== 'undefined') {
              result[key] = preValue
            }
          } else if (value) {
            result[key] = value
          }
        })
      }

      if (Object.keys(result).length > 0) {
        setDefaultData(result)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItemsData, preFilters])

  useEffect(() => {
    if (filters.length > 0 || preFilters.length > 0) {
      const currentFilters = filters.length > 0 ? filters : preFilters
      // @ts-ignore
      fetchMenuData(type, currentFilters)
    }
  }, [fetchMenuData, filters, preFilters, type])

  const handleChangeStageType = useCallback(
    newType => {
      if (typeof newType !== 'undefined') {
        onChangeType(newType)
      }
      setIsFirstPast(true)
    },
    [onChangeType],
  )

  const setPreFiltersFromProduct = useCallback(
    async product => {
      const result: IFilterItem[] = []
      const dataKeys = Object.keys(menuItemsData)

      for (const key of dataKeys) {
        const value =
          menuItemsData[key] && menuItemsData[key].length > 0 ? menuItemsData[key][0]['value'] : ''

        result.push({ key, value })
      }

      if (product['configuration-images']) {
        result.push({
          key: 'configuration-images',
          value: product['configuration-images'],
        })
      }

      if (product['casements-index']) {
        result.push({
          key: 'casements-index',
          value: product['casements-index'],
        })
      }

      if (product['mosquito-index']) {
        result.push({
          key: 'mosquito-index',
          value: product['mosquito-index'],
        })
      }

      onSetPreFilters(result)
    },
    [menuItemsData, onSetPreFilters],
  )

  const handleChangeQuiz = useCallback(
    newFilters => {
      onSetPreFilters(newFilters)
      history.push('/calculator')
      onChangeStage(CalculatorStage.CALCULATE)
    },
    [history, onChangeStage, onSetPreFilters],
  )

  const handleChangeCalculate = useCallback(
    (product, newFilters, sizes, productId, preview) => {
      onAddOrder({ product, filters: newFilters, sizes, id: productId, preview, type })
      onChangeStage(CalculatorStage.CART)
    },
    [onAddOrder, onChangeStage, type],
  )

  const handleChangeCart = useCallback(
    orderId => {
      onSetCurrentOrderId(orderId)
      onChangeStage(CalculatorStage.ORDER)
    },
    [onChangeStage, onSetCurrentOrderId],
  )

  const handleChangeOrder = useCallback(() => {
    onChangeStage(CalculatorStage.ORDER)
  }, [onChangeStage])

  const handleNewCalculate = useCallback(() => {
    setIsFirstPast(false)
    setFilters([])
    onChangeStage(CalculatorStage.QUIZ)
  }, [onChangeStage])

  const handleBackToCart = useCallback(async () => {
    onChangeStage(CalculatorStage.CART)
    // eslint-disable-next-line no-undef
    await setLocalStorageItem('cartItems', JSON.stringify(orders))
    history.push('/personal/basket')
  }, [history, onChangeStage, orders])

  const handleEditOrder = useCallback(
    id => {
      const order = orders.find(item => item.id === id)

      if (order && order['product']) {
        setPreFiltersFromProduct(order['product'])
      }

      onEditOrder(id)
      onChangeType(order['type'])
      history.push('/calculator')
    },
    [orders, onEditOrder, onChangeType, history, setPreFiltersFromProduct],
  )

  const handleDropData = useCallback(() => {
    setDefaultData({})
    onDropSpecification()
  }, [onDropSpecification])

  const renderStage = useCallback(() => {
    let result

    switch (stage) {
      case CalculatorStage.CALCULATE:
        result = (
          <StageCalculator
            type={type}
            filters={filters}
            setFilters={setFilters}
            menuItems={menuItems}
            menuItemsData={menuItemsData}
            specification={specification}
            defaultData={defaultData}
            onChangeStage={handleChangeCalculate}
            currentOrder={currentOrder}
            onNewCalculate={handleNewCalculate}
            onDropSpecification={handleDropData}
          />
        )

        break
      case CalculatorStage.CART:
        result = (
          <StageCart
            type={type}
            orders={orders}
            onNewCalculate={handleNewCalculate}
            onChangeStage={handleChangeCart}
            onEditOrder={handleEditOrder}
            onSetCurrentOrderId={onSetCurrentOrderId}
            onUpdateOrder={onUpdateOrder}
          />
        )

        break

      case CalculatorStage.ORDER:
        result = (
          <StageOrder
            type={type}
            onChangeType={onChangeType}
            products={orders}
            onBackToCart={handleBackToCart}
            onChangeStage={handleChangeOrder}
            currentOrder={currentOrder}
            onNewCalculate={handleNewCalculate}
          />
        )

        break
      case CalculatorStage.INDIVIDUAL:
        result = (
          <StageCalculator
            type={type}
            filters={filters}
            setFilters={setFilters}
            menuItems={menuItems}
            menuItemsData={menuItemsData}
            specification={specification}
            defaultData={defaultData}
            onChangeStage={onChangeStage}
            currentOrder={currentOrder}
            onDropSpecification={onDropSpecification}
            onNewCalculate={handleNewCalculate}
          />
        )

        break
      case CalculatorStage.PERSONAL_AREA:
        result = <StagePersonalArea />

        break
      case CalculatorStage.SELECT_VIEW:
        result = <StageView onChangeStage={onChangeStage} />

        break
      case CalculatorStage.QUIZ:
      default:
        result = (
          <StageQuiz
            onChange={handleChangeQuiz}
            onChangeType={handleChangeStageType}
            type={type}
            specification={specification}
            firstPast={isFirstPast}
          />
        )

        break
    }

    return result
  }, [
    stage,
    type,
    filters,
    menuItems,
    menuItemsData,
    specification,
    defaultData,
    handleChangeCalculate,
    currentOrder,
    handleDropData,
    orders,
    handleNewCalculate,
    handleChangeCart,
    handleEditOrder,
    onSetCurrentOrderId,
    onChangeType,
    handleBackToCart,
    handleChangeOrder,
    onChangeStage,
    onDropSpecification,
    handleChangeQuiz,
    handleChangeStageType,
    isFirstPast,
  ])

  return <>{renderStage()}</>
}
