import styled, { css } from 'styled-components'
import { ICoord, TooltipDirection } from './interface'

export const TooltipWrapper = styled.div`
  position: relative;

  font-size: 0;
`

export const TooltipBlock = styled.div<{
  show: boolean
  importantShow?: boolean
  coord?: ICoord
  direction?: TooltipDirection
}>`
  background-color: #333333;
  border-radius: 10px;
  box-shadow: 0 8px 40px rgb(0 0 0 / 10%);

  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  color: #ffffff;
  white-space: normal;

  ${({ coord = { x: 0, y: 16 }, direction = TooltipDirection.TOP }) => {
    switch (direction) {
      case TooltipDirection.BOTTOM:

      case TooltipDirection.LEFT:
        return css`
          top: ${typeof coord['y'] === 'number' ? `${coord['y']}px` : coord['y']};
          right: ${typeof coord['x'] === 'number' ? `${coord['x']}px` : coord['x']};
        `
      case TooltipDirection.RIGHT:
      case TooltipDirection.TOP:
      default:
        return css`
          bottom: ${typeof coord['y'] === 'number' ? `${coord['y']}px` : coord['y']};
          left: ${typeof coord['x'] === 'number' ? `${coord['x']}px` : coord['x']};
        `
    }
  }}

  min-width: 160px;
  max-width: 300px;

  padding: 6px 11px;

  position: absolute;

  opacity: 0;

  transform: translateX(-50%) translateY(0);
  transition: opacity 0.3s, transform 0.3s;

  pointer-events: none;

  z-index: 1002;

  &:before,
  &:after {
    content: '';

    width: 0;
    height: 0;

    position: absolute;
  }
  &:before {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;

    ${({ direction = TooltipDirection.TOP }) => {
      switch (direction) {
        case TooltipDirection.BOTTOM:
          return css`
            top: -2px;
            left: 50%;
            margin-left: -10px;

            transform: rotate(180deg);
          `
        case TooltipDirection.LEFT:
          return css`
            bottom: calc(50% - 3px);
            right: -10px;

            transform: rotate(270deg) translateY(-50%);
          `
        case TooltipDirection.RIGHT:
          return css`
            bottom: calc(50% - 3px);
            left: -10px;

            transform: rotate(90deg) translateY(-50%);
          `
        case TooltipDirection.TOP:
        default:
          return css`
            bottom: -8px;
            left: 50%;
            margin-left: -10px;
          `
      }
    }}
  }
  &:after {
    border-top-color: #333333;
    border-top-style: solid;
    border-top-width: 6px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;

    ${({ direction = TooltipDirection.TOP }) => {
      switch (direction) {
        case TooltipDirection.BOTTOM:
          return css`
            top: -5px;
            left: 50%;
            margin-left: -8px;

            transform: rotate(180deg);
          `
        case TooltipDirection.LEFT:
          return css`
            bottom: calc(50% - 3px);
            right: -13px;
            //margin-bottom: -4px;

            transform: rotate(270deg) translateY(-50%);
          `
        case TooltipDirection.RIGHT:
          return css`
            bottom: calc(50% - 3px);
            left: -13px;
            //margin-bottom: -4px;

            transform: rotate(90deg) translateY(-50%);
          `
        case TooltipDirection.TOP:
        default:
          return css`
            bottom: -6px;
            left: 50%;

            margin-left: -8px;
          `
      }
    }}
  }

  ${({ show, importantShow }) =>
    show &&
    css`
      opacity: 1;

      pointer-events: ${importantShow ? 'none' : 'all'};

      transform: translateX(-50%) translateY(-10px);
    `}
`
