import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement74Square82Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M100.001 290C101.105 290 102 289.105 102 288.001C102 286.896 101.105 286.001 100.001 286.001L97.8335 286.001L97.8335 286.001L87 286.001L87 286L83.9995 286C82.8952 286 82 286.895 82 287.999C82 289.104 82.8952 289.999 83.9995 289.999L86.1668 289.999L86.1668 290L97 290L97 290L100.001 290Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 97.8335 290)"
          fill="#ABABAB"
        />
        <path
          d="M101.06 290C101.579 290 102 289.579 102 289.06L102 286.941C102 286.422 101.579 286.001 101.06 286.001L97 286.001L97 290L101.06 290Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M82.9399 286C82.4208 286 82 286.421 82 286.94L82 289.059C82 289.578 82.4208 289.999 82.9399 289.999L87 289.999L87 286L82.9399 286Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 96.1666 290)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#4filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M55.0005 290C56.1048 290 57 289.105 57 288.001C57 286.896 56.1048 286.001 55.0005 286.001L52.8335 286.001L52.8335 286.001L42 286.001L42 286L38.9995 286C37.8952 286 37 286.895 37 287.999C37 289.104 37.8952 289.999 38.9995 289.999L41.1668 289.999L41.1668 290L52 290L52 290L55.0005 290Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 52.8335 290)"
          fill="#ABABAB"
        />
        <path
          d="M56.0601 290C56.5792 290 57 289.579 57 289.06L57 286.941C57 286.422 56.5792 286.001 56.0601 286.001L52 286.001L52 290L56.0601 290Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M37.9399 286C37.4208 286 37 286.421 37 286.94L37 289.059C37 289.578 37.4208 289.999 37.9399 289.999L42 289.999L42 286L37.9399 286Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 51.1666 290)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#4filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M105 231H32V286H105V231ZM95 241H42V276H95V241Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105 231H32V286H33V232H104V286H105V231Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4filter3_i)">
          <rect
            x="42.47"
            y="241.47"
            width="52.0601"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="235.53"
          width="63.9399"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          x="75.5283"
          y="233"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 75.5283 233)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#4filter4_d)">
          <rect
            x="74.8843"
            y="233.702"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 74.8843 233.702)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74.7663 235.695C74.4199 234.542 73.3502 233.702 72.0842 233.702L65.4443 233.702C63.8979 233.702 62.6443 234.956 62.6443 236.502C62.6443 236.537 62.645 236.572 62.6463 236.607C62.9927 237.76 64.0624 238.6 65.3283 238.6L71.9682 238.6C73.5146 238.6 74.7682 237.346 74.7682 235.8C74.7682 235.765 74.7675 235.73 74.7663 235.695Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.4093 234.035C73.2656 234.012 73.1183 234 72.9682 234L66.3283 234C64.7819 234 63.5283 235.254 63.5283 236.8C63.5283 237.867 64.1252 238.795 65.0033 239.267C63.6665 239.056 62.6444 237.898 62.6444 236.502C62.6444 234.956 63.898 233.702 65.4444 233.702L72.0843 233.702C72.5636 233.702 73.0148 233.822 73.4093 234.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M88.5283 236.5C88.5283 235.672 87.8567 235 87.0283 235L86.8141 235L85.5283 235L67.0283 235C66.1999 235 65.5283 235.672 65.5283 236.5V236.5C65.5283 237.328 66.1999 238 67.0283 238L83.5283 238L87.0283 238L87.2426 238C87.9527 238 88.5283 237.424 88.5283 236.714V236.714C88.5283 236.677 88.5271 236.64 88.5248 236.604C88.5271 236.569 88.5283 236.535 88.5283 236.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M44.0182 274.5L68.5 242.818L92.9818 274.5L44.0182 274.5Z" stroke="white" />
        <defs>
          <filter
            id="4filter0_d"
            x="80.1201"
            y="284.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4filter1_d"
            x="35.1201"
            y="284.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4filter2_d"
            x="27.3003"
            y="226.3"
            width="84.2793"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4filter3_i"
            x="42"
            y="241"
            width="53.9399"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4filter4_d"
            x="60.7645"
            y="231.822"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4filter5_d"
            x="64.6485"
            y="233.12"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="84.5"
            y1="292.8"
            x2="84.5"
            y2="285.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="99.5"
            y1="283.202"
            x2="99.5"
            y2="290.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="84.5"
            y1="292.798"
            x2="84.5"
            y2="285.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="39.5"
            y1="292.8"
            x2="39.5"
            y2="285.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="54.5"
            y1="283.202"
            x2="54.5"
            y2="290.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="39.5"
            y1="292.798"
            x2="39.5"
            y2="285.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
