import React, { FC, ReactElement, useCallback, useMemo } from 'react'
import { statusList } from './consts'
import { StatusesItem, StatusesItemIconWrapper, StatusesItemTitle, StatusesWrapper } from './styles'
import { IStatusesProps, ORDER_STATUSES } from './interface'
import { CarIcon, SettingIcon } from '../../../assets/icons'
import { SuccessRadiusIcon } from '../../../assets/icons/success-radius-icon'

export const Statuses: FC<IStatusesProps> = props => {
  const { status, isHaveMounting } = props

  const statuses = useMemo(() => {
    return statusList(status).filter(
      item => item['status'] !== ORDER_STATUSES.MOUNTING || isHaveMounting,
    )
  }, [isHaveMounting, status])

  const isFirstItem = useCallback(index => {
    return index === 0
  }, [])

  const isLastItem = useCallback(
    index => {
      return index === statuses.length - 1
    },
    [statuses.length],
  )

  const withIcon = useCallback(
    (item, index) => {
      return item.icon && !(isFirstItem(index) || isLastItem(index))
    },
    [isFirstItem, isLastItem],
  )

  const activeItemIndex = useMemo(() => {
    return statuses.findIndex(item => item.status === status)
  }, [status, statuses])

  const isActiveCheck = useCallback(
    index => {
      return index <= activeItemIndex
    },
    [activeItemIndex],
  )

  const getStatusIcon = useCallback((currentStatus: ORDER_STATUSES, active) => {
    let result: ReactElement | null

    switch (currentStatus) {
      case ORDER_STATUSES.MANUFACTURE:
        result = <SettingIcon color={active ? '#ffffff' : '#D4D4D4'} />
        break
      case ORDER_STATUSES.DELIVERY:
        result = <CarIcon color={active ? '#ffffff' : '#D4D4D4'} />
        break
      case ORDER_STATUSES.MOUNTING:
        result = <SuccessRadiusIcon color={active ? '#ffffff' : '#D4D4D4'} />
        break
      default:
        result = null
        break
    }

    return result
  }, [])

  return (
    <StatusesWrapper>
      {statuses.map((item, index) => (
        <StatusesItem
          key={`status-item-${item.title}`}
          isFirst={isFirstItem(index)}
          isLast={isLastItem(index)}
          active={isFirstItem(index) || isActiveCheck(index)}
        >
          <StatusesItemTitle withIcon={withIcon(item, index)}>{item.title}</StatusesItemTitle>
          <StatusesItemIconWrapper
            small={!item.icon}
            active={isFirstItem(index) || isActiveCheck(index)}
          >
            {getStatusIcon(item.status, isActiveCheck(index))}
          </StatusesItemIconWrapper>
        </StatusesItem>
      ))}
    </StatusesWrapper>
  )
}
