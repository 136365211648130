import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement11Square1Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#1-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.9541 256.976C31.9541 255.885 31.0692 255 29.9776 255C28.886 255 28.0011 255.885 28.0011 256.976V259.167V270H28V273.024C28 274.115 28.8849 275 29.9765 275C31.0681 275 31.953 274.115 31.953 273.024V270.833H31.9541V260V256.976Z"
            fill="url(#1-paint0_linear)"
          />
        </g>
        <path d="M28.0011 259.168H31.9541V270.835H28.0011V259.168Z" fill="#ABABAB" />
        <path
          d="M31.954 255.94C31.954 255.421 31.5332 255 31.0141 255H28.941C28.4218 255 28.001 255.421 28.001 255.94V260H31.954V255.94Z"
          fill="url(#1-paint1_linear)"
        />
        <path
          d="M28 274.06C28 274.579 28.4208 275 28.9399 275H31.0131C31.5322 275 31.953 274.579 31.953 274.06V270H28V274.06Z"
          fill="url(#1-paint2_linear)"
        />
        <path d="M28.0011 260.832H31.9541V269.165H28.0011V260.832Z" fill="url(#1-paint3_linear)" />
        <g filter="url(#1-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.9541 45.9765C31.9541 44.8849 31.0692 44 29.9776 44C28.886 44 28.0011 44.8849 28.0011 45.9765V48.1665V59H28V62.0235C28 63.1151 28.8849 64 29.9765 64C31.0681 64 31.953 63.1151 31.953 62.0235V59.8332H31.9541V49V45.9765Z"
            fill="url(#1-paint4_linear)"
          />
        </g>
        <path d="M28.0011 48.166H31.9541V59.8327H28.0011V48.166Z" fill="#ABABAB" />
        <path
          d="M31.954 44.9399C31.954 44.4208 31.5332 44 31.0141 44H28.941C28.4218 44 28.001 44.4208 28.001 44.9399V49H31.954V44.9399Z"
          fill="url(#1-paint5_linear)"
        />
        <path
          d="M28 63.0601C28 63.5792 28.4208 64 28.9399 64H31.0131C31.5322 64 31.953 63.5792 31.953 63.0601V59H28V63.0601Z"
          fill="url(#1-paint6_linear)"
        />
        <path d="M28.0011 49.834H31.9541V58.1673H28.0011V49.834Z" fill="url(#1-paint7_linear)" />
        <g filter="url(#1-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 33H32V286H188V33ZM177 44H43V275H177V44Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 33H32V286H33V34H187V286H188V33Z"
          fill="#FBFBFB"
        />
        <path
          d="M183.47 37.5293H36.53V281.469H183.47V37.5293Z"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#1-filter3_i)">
          <path
            d="M176.53 44.4707H43.47V274.531H176.53V44.4707Z"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <path
          d="M179 156.46C179 154.549 180.549 153 182.46 153C184.371 153 185.92 154.549 185.92 156.46V163.069C185.92 164.979 184.371 166.528 182.46 166.528C180.549 166.528 179 164.979 179 163.069V156.46Z"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#1-filter4_d)">
          <path
            d="M179.69 156.412C179.69 154.884 180.93 153.645 182.458 153.645C183.987 153.645 185.226 154.884 185.226 156.412V163.117C185.226 164.645 183.987 165.884 182.458 165.884C180.93 165.884 179.69 164.645 179.69 163.117V156.412Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M184.661 164.792C184.155 165.456 183.356 165.884 182.458 165.884C180.929 165.884 179.69 164.645 179.69 163.116V156.412C179.69 155.44 180.191 154.585 180.948 154.092C180.594 154.557 180.383 155.138 180.383 155.768V162.472C180.383 164.001 181.622 165.24 183.151 165.24C183.708 165.24 184.226 165.075 184.661 164.792Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M182.458 153.645C183.986 153.645 185.226 154.884 185.226 156.413V162.473C185.226 162.493 185.225 162.513 185.225 162.534V156.992C185.225 155.464 183.986 154.225 182.457 154.225C180.949 154.225 179.722 155.431 179.69 156.932V156.413C179.69 154.884 180.929 153.645 182.458 153.645Z"
          fill="#FBFBFB"
        />
        <g filter="url(#1-filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M182.46 157C183.279 157 183.943 157.664 183.943 158.483V160C183.943 160.014 183.942 160.027 183.942 160.041V178.517C183.942 179.336 183.278 180 182.459 180C181.641 180 180.977 179.336 180.977 178.517V158.251C180.977 157.56 181.537 157 182.228 157C182.231 157 182.234 157 182.236 157C182.311 157 182.385 157 182.46 157Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M44.5 272.908V46.0987L175.237 159.998L44.5 272.908Z" stroke="white" />
        <defs>
          <filter
            id="1-filter0_d"
            x="26.1201"
            y="253.12"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-filter1_d"
            x="26.1201"
            y="42.1201"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-filter2_d"
            x="27.3003"
            y="28.3003"
            width="167.279"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-filter3_i"
            x="43"
            y="44"
            width="134.94"
            height="233.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="1-filter4_d"
            x="177.811"
            y="151.765"
            width="11.1753"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="1-filter5_d"
            x="179.097"
            y="155.12"
            width="8.60563"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="1-paint0_linear"
            x1="34.7219"
            y1="272.5"
            x2="27.2093"
            y2="272.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="1-paint1_linear"
            x1="25.2339"
            y1="257.5"
            x2="32.7446"
            y2="257.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="1-paint2_linear"
            x1="34.7201"
            y1="272.5"
            x2="27.2094"
            y2="272.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="1-paint3_linear"
            x1="25.234"
            y1="264.999"
            x2="32.7447"
            y2="264.999"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="1-paint4_linear"
            x1="34.7219"
            y1="61.5"
            x2="27.2093"
            y2="61.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="1-paint5_linear"
            x1="25.2339"
            y1="46.5"
            x2="32.7446"
            y2="46.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="1-paint6_linear"
            x1="34.7201"
            y1="61.5"
            x2="27.2094"
            y2="61.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="1-paint7_linear"
            x1="25.234"
            y1="54.0007"
            x2="32.7447"
            y2="54.0007"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
