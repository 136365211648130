import styled, { css } from 'styled-components'

export const InputWrapper = styled.div<{ disabled?: boolean }>`
  display: inline-block;

  border-radius: 10px;

  position: relative;

  width: 100%;

  input {
    background-color: #ffffff;
    background-image: none;
    border: 1px solid #e1e1e1;
    border-radius: 10px;

    box-sizing: border-box;

    margin: 0;
    padding: 15px 23px;

    font-variant: tabular-nums;
    font-feature-settings: 'tnum';
    color: #333333;
    font-size: 16px;
    line-height: 1.5;

    list-style: none;

    position: relative;

    display: inline-block;

    width: 100%;
    min-width: 0;

    transition: all 0.3s;

    ${({ disabled }) =>
      disabled &&
      css`
        background-color: #e1e1e1;

        pointer-events: none;
      `}
  }
`

export const InputPlaceholder = styled.div`
  background: white;

  position: absolute;

  font-size: 16px;
  color: #757575;
  line-height: 22.4px;

  top: 18px;
  left: 1px;

  width: calc(100% - 2px);
  height: 30px;

  padding-left: 23px;

  cursor: text;
`

export const InputError = styled.span`
  position: absolute;

  top: calc(50% + 1px);
  right: 10px;

  font-size: 11px;
  line-height: 1.2;

  color: #ff453a;

  transform: translateY(-50%);

  svg {
    width: 14px;
    height: 14px;
  }
`

export const InputStyle = styled.input<{ isError?: boolean }>`
  background-color: #ffffff;
  background-image: none;
  border: 1px solid ${({ isError }) => (isError ? '#ff453a' : '#E1E1E1')};
  border-radius: 10px;

  box-sizing: border-box;

  margin: 0;
  padding: 15px 23px;

  font-variant: tabular-nums;
  font-feature-settings: 'tnum';
  color: #333333;
  font-size: 16px;
  line-height: 1.5;

  list-style: none;

  position: relative;

  display: inline-block;

  width: 100%;
  min-width: 0;

  transition: all 0.3s;
`
