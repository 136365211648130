import React, { FC } from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'
import { IContainerSquareProps } from '../../interface'
import { ContainerBackground } from '../container-background'

export const ContainerDoor1RCasement2: FC<IContainerSquareProps> = props => {
  const { onCasementClick, withoutBackground } = props

  return (
    <ProductImageWrapper isClickable>
      <svg
        width="451"
        height="451"
        viewBox="0 0 451 451"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g clipPath="url(#door-1-r-casement-2-container-clip0)">
          <rect width="451" height="451" fill="white" />
          <rect x="-81" y="-1" width="647" height="426" fill="#E8EAEE" />
          <rect
            x="-81"
            y="-1"
            width="647"
            height="426"
            fill="url(#door-1-r-casement-2-container-pattern0)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 -1H451V451H0V-1ZM265 11H10V323.274H265V441H441V323.274V11H265Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M441 10H10V28H263V441H441V10ZM427 28H265V425H427V28Z"
            fill="#C4C4C4"
          />
          <path d="M441 10L427 28.1525V424.982L441 441V10Z" fill="#E9EBEF" />
          <path d="M441 10L427 28.1525V424.982L441 441V10Z" fill="#020202" fillOpacity="0.06" />
          <rect width="255" height="108" transform="matrix(-1 0 0 1 253 343)" fill="#E8EAEE" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M263 28H427V425H263V28ZM285 41H414V412H285V41Z"
            fill="#E8EAEE"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M265 28H24V325H265V28ZM260 41H158V294H260V41ZM37 41H139V294H37V41Z"
            fill="#E8EAEE"
          />
          <g
            filter="url(#door-1-r-casement-2-container-filter0_i)"
            className="casement"
            data-index="2"
            onClick={onCasementClick(2)}
          >
            <rect
              width="129"
              height="371"
              transform="matrix(-1 0 0 1 414 41)"
              fill="#B8C1FD"
              fillOpacity="0.4"
            />
          </g>
          <rect
            x="-0.46997"
            y="0.46997"
            width="128.06"
            height="370.06"
            transform="matrix(-1 0 0 1 413.06 41)"
            stroke="#979797"
            strokeWidth="0.93994"
          />
          <g
            filter="url(#door-1-r-casement-2-container-filter1_i)"
            className="casement"
            data-index="1"
            onClick={onCasementClick(1)}
          >
            <rect
              width="102"
              height="253"
              transform="matrix(-1 0 0 1 260 41)"
              fill="#B8C1FD"
              fillOpacity="0.4"
            />
          </g>
          <rect
            x="-0.46997"
            y="0.46997"
            width="101.06"
            height="252.06"
            transform="matrix(-1 0 0 1 259.06 41)"
            stroke="#979797"
            strokeWidth="0.93994"
          />
          <g
            filter="url(#door-1-r-casement-2-container-filter2_i)"
            className="casement"
            data-index="0"
            onClick={onCasementClick(0)}
          >
            <rect
              width="102"
              height="253"
              transform="matrix(-1 0 0 1 139 41)"
              fill="#B8C1FD"
              fillOpacity="0.4"
            />
          </g>
          <rect
            x="-0.46997"
            y="0.46997"
            width="101.06"
            height="252.06"
            transform="matrix(-1 0 0 1 138.06 41)"
            stroke="#979797"
            strokeWidth="0.93994"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.162 307H266L267 331H235.25H10.162H0.00012207V323H10.162V307Z"
            fill="url(#door-1-r-casement-2-container-paint0_linear)"
          />
          <path d="M10 10L24 28.0571V307.075L10 323V10Z" fill="#E9EBEF" />
          <path d="M10 10L24 28.0571V307.075L10 323V10Z" fill="#020202" fillOpacity="0.06" />
          <g filter="url(#door-1-r-casement-2-container-filter3_d)">
            <rect width="267" height="12" transform="matrix(-1 0 0 1 267 331)" fill="white" />
          </g>
          <path
            d="M96.9851 100.741H94.4471C93.8002 100.741 93.2528 100.234 93.2528 99.6355V99.4052C93.2528 98.8063 93.8002 98.2996 94.4471 98.2996H96.9851C97.632 98.2996 98.1794 98.8063 98.1794 99.4052V99.6355C98.1794 100.257 97.6569 100.741 96.9851 100.741Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M79.4262 103.565C79.7247 103.565 79.7993 103.565 80.1972 103.565C80.9432 103.565 81.4903 104.105 81.4903 104.833C81.4903 105.584 80.9184 106.101 80.1474 106.101C78.705 106.101 77.8098 106.101 76.3674 106.101C75.8203 106.101 75.3727 105.913 75.1488 105.42C74.9499 105.115 74.8255 104.27 75.6213 103.565C76.1436 102.978 81.9131 96.7565 82.9327 95.6765C82.9824 95.6061 82.9327 95.5122 82.8581 95.5122C82.8332 95.5122 82.8332 95.5122 82.8084 95.5122C80.6448 95.5122 78.4812 95.5122 76.3177 95.5122C75.6711 95.5122 75.1737 95.1365 75.0494 94.573C74.9002 93.9156 75.1986 93.3522 75.7706 93.0939C75.9446 93.0235 76.1187 93 76.2928 93C79.5008 93 82.684 93 85.8921 93C86.5138 93 86.9366 93.1878 87.1852 93.7278C87.5085 94.4087 87.1604 94.8783 86.9366 95.1835C86.4889 95.7235 81.938 100.56 79.4262 103.283C79.4014 103.307 79.3765 103.354 79.3268 103.377C79.277 103.448 79.3268 103.565 79.4262 103.565Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M86.1855 106.101H83.6476C83.0007 106.101 82.4533 105.582 82.4533 104.968V104.732C82.4533 104.119 83.0007 103.6 83.6476 103.6H86.1855C86.8325 103.6 87.3799 104.119 87.3799 104.732V104.968C87.3799 105.605 86.8325 106.101 86.1855 106.101Z"
            fill="#141B4D"
            fillOpacity="0.63"
            stroke="#767676"
            strokeWidth="0.0812062"
            strokeMiterlimit="10"
          />
          <path
            d="M89.7154 99.5152C89.7154 97.7341 89.7154 95.9764 89.7154 94.1952C89.7154 93.4922 90.2374 93 90.9832 93C94.2148 93 97.4464 93 100.678 93C101.424 93 101.946 93.5156 101.946 94.2421C101.946 94.9686 101.424 95.5077 100.678 95.5077C97.9933 95.5077 95.3334 95.5077 92.6487 95.5077C92.4498 95.5077 92.4001 95.5545 92.4001 95.742C92.4001 98.2731 92.4001 100.804 92.4001 103.335C92.4001 103.546 92.4747 103.57 92.6487 103.57C95.3086 103.57 97.9933 103.57 100.653 103.57C101.25 103.57 101.722 103.898 101.896 104.39C102.095 104.976 101.871 105.562 101.399 105.89C101.175 106.03 100.902 106.101 100.628 106.101C97.4464 106.101 94.2397 106.101 91.0578 106.101C90.2374 106.101 89.7403 105.585 89.7403 104.812C89.7154 103.031 89.7154 101.273 89.7154 99.5152Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M76.7054 111.042V111.698H75V111.042H75.4263V109.259H75.0502V108.602H76.6803V109.259H76.3041V111.042H76.7054Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M80.5344 111.758H79.7737L79.651 111.545C79.3811 111.687 79.0621 111.758 78.7676 111.758C77.9334 111.734 77.1482 111.167 77.1482 110.15C77.1482 109.181 77.8107 108.542 78.8167 108.542C79.651 108.542 80.4607 109.086 80.4607 110.15C80.4607 110.528 80.3626 110.812 80.2154 111.072L80.5589 111.64V111.758H80.5344ZM78.7922 111.001C79.1848 111.001 79.6019 110.718 79.6019 110.15C79.6019 109.583 79.1848 109.299 78.7922 109.299C78.375 109.299 77.9824 109.583 77.9824 110.15C77.9824 110.718 78.375 111.001 78.7922 111.001Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M82.7835 110.749C82.7835 110.96 83.0291 111.077 83.2992 111.077C83.5448 111.077 83.7903 110.96 83.7903 110.796C83.7903 110.49 83.422 110.49 83.201 110.467C82.6116 110.42 82.0223 110.209 82.0223 109.552C82.0223 108.871 82.6116 108.542 83.2747 108.542C83.8886 108.542 84.527 108.871 84.527 109.552H83.7167C83.7167 109.34 83.4957 109.246 83.2747 109.246C83.0782 109.246 82.8326 109.293 82.8326 109.528C82.8326 109.739 83.0291 109.786 83.3238 109.81C84.0114 109.857 84.6007 110.021 84.6007 110.772C84.6007 111.429 83.9377 111.758 83.2747 111.758C82.5871 111.758 81.924 111.429 81.9486 110.749H82.7835Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M87.2288 109.311H86.0377V109.76H87.1295V110.469H86.0377V110.966H87.2536V111.698H85.1693V108.602H87.204V109.311H87.2288Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M90.603 111.58V111.698H89.7222L89.1594 110.729H88.719V111.698H87.8871V108.602C88.3765 108.602 88.8413 108.602 89.3062 108.602C90.0892 108.602 90.4562 109.169 90.4562 109.713C90.4562 110.067 90.285 110.422 89.9669 110.611L90.603 111.58ZM89.3062 110.067C89.5509 110.067 89.6488 109.878 89.6488 109.666C89.6488 109.476 89.5265 109.287 89.3062 109.287H88.719V110.067H89.3062Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M92.2761 110.334L92.553 109.527L92.9556 108.602H93.8868V108.72L92.4523 111.758H92.1L90.6655 108.72V108.602H91.5967L92.0245 109.527L92.2761 110.334Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M95.7814 111.042V111.698H94.0761V111.042H94.5024V109.259H94.1262V108.602H95.7564V109.259H95.3802V111.042H95.7814Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M97.8348 108.602C98.5091 108.602 99.2582 108.955 99.383 109.779H98.559C98.4341 109.45 98.1844 109.332 97.8348 109.332C97.3354 109.332 97.0108 109.662 97.0108 110.18C97.0108 110.627 97.3354 111.028 97.8348 111.028C98.1844 111.028 98.4841 110.863 98.559 110.533H99.383C99.2582 111.405 98.534 111.758 97.8348 111.758C96.9858 111.758 96.1618 111.216 96.1618 110.18C96.1618 109.12 96.9858 108.602 97.8348 108.602Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M101.944 109.311H100.753V109.76H101.845V110.469H100.753V110.966H101.969V111.698H99.8846V108.602H101.919V109.311H101.944Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M217.985 100.741H215.447C214.8 100.741 214.253 100.234 214.253 99.6355V99.4052C214.253 98.8063 214.8 98.2996 215.447 98.2996H217.985C218.632 98.2996 219.179 98.8063 219.179 99.4052V99.6355C219.179 100.257 218.657 100.741 217.985 100.741Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M200.426 103.565C200.725 103.565 200.799 103.565 201.197 103.565C201.943 103.565 202.49 104.105 202.49 104.833C202.49 105.584 201.918 106.101 201.147 106.101C199.705 106.101 198.81 106.101 197.367 106.101C196.82 106.101 196.373 105.913 196.149 105.42C195.95 105.115 195.826 104.27 196.621 103.565C197.144 102.978 202.913 96.7565 203.933 95.6765C203.982 95.6061 203.933 95.5122 203.858 95.5122C203.833 95.5122 203.833 95.5122 203.808 95.5122C201.645 95.5122 199.481 95.5122 197.318 95.5122C196.671 95.5122 196.174 95.1365 196.049 94.573C195.9 93.9156 196.199 93.3522 196.771 93.0939C196.945 93.0235 197.119 93 197.293 93C200.501 93 203.684 93 206.892 93C207.514 93 207.937 93.1878 208.185 93.7278C208.509 94.4087 208.16 94.8783 207.937 95.1835C207.489 95.7235 202.938 100.56 200.426 103.283C200.401 103.307 200.376 103.354 200.327 103.377C200.277 103.448 200.327 103.565 200.426 103.565Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M207.186 106.101H204.648C204.001 106.101 203.453 105.582 203.453 104.968V104.732C203.453 104.119 204.001 103.6 204.648 103.6H207.186C207.832 103.6 208.38 104.119 208.38 104.732V104.968C208.38 105.605 207.832 106.101 207.186 106.101Z"
            fill="#141B4D"
            fillOpacity="0.63"
            stroke="#767676"
            strokeWidth="0.0812062"
            strokeMiterlimit="10"
          />
          <path
            d="M210.715 99.5152C210.715 97.7341 210.715 95.9764 210.715 94.1952C210.715 93.4922 211.237 93 211.983 93C215.215 93 218.446 93 221.678 93C222.424 93 222.946 93.5156 222.946 94.2421C222.946 94.9686 222.424 95.5077 221.678 95.5077C218.993 95.5077 216.333 95.5077 213.649 95.5077C213.45 95.5077 213.4 95.5545 213.4 95.742C213.4 98.2731 213.4 100.804 213.4 103.335C213.4 103.546 213.475 103.57 213.649 103.57C216.309 103.57 218.993 103.57 221.653 103.57C222.25 103.57 222.722 103.898 222.896 104.39C223.095 104.976 222.871 105.562 222.399 105.89C222.175 106.03 221.902 106.101 221.628 106.101C218.446 106.101 215.24 106.101 212.058 106.101C211.237 106.101 210.74 105.585 210.74 104.812C210.715 103.031 210.715 101.273 210.715 99.5152Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M197.705 111.042V111.698H196V111.042H196.426V109.259H196.05V108.602H197.68V109.259H197.304V111.042H197.705Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M201.534 111.758H200.774L200.651 111.545C200.381 111.687 200.062 111.758 199.768 111.758C198.933 111.734 198.148 111.167 198.148 110.15C198.148 109.181 198.811 108.542 199.817 108.542C200.651 108.542 201.461 109.086 201.461 110.15C201.461 110.528 201.363 110.812 201.215 111.072L201.559 111.64V111.758H201.534ZM199.792 111.001C200.185 111.001 200.602 110.718 200.602 110.15C200.602 109.583 200.185 109.299 199.792 109.299C199.375 109.299 198.982 109.583 198.982 110.15C198.982 110.718 199.375 111.001 199.792 111.001Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M203.784 110.749C203.784 110.96 204.029 111.077 204.299 111.077C204.545 111.077 204.79 110.96 204.79 110.796C204.79 110.49 204.422 110.49 204.201 110.467C203.612 110.42 203.022 110.209 203.022 109.552C203.022 108.871 203.612 108.542 204.275 108.542C204.889 108.542 205.527 108.871 205.527 109.552H204.717C204.717 109.34 204.496 109.246 204.275 109.246C204.078 109.246 203.833 109.293 203.833 109.528C203.833 109.739 204.029 109.786 204.324 109.81C205.011 109.857 205.601 110.021 205.601 110.772C205.601 111.429 204.938 111.758 204.275 111.758C203.587 111.758 202.924 111.429 202.949 110.749H203.784Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M208.229 109.311H207.038V109.76H208.13V110.469H207.038V110.966H208.254V111.698H206.169V108.602H208.204V109.311H208.229Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M211.603 111.58V111.698H210.722L210.159 110.729H209.719V111.698H208.887V108.602C209.376 108.602 209.841 108.602 210.306 108.602C211.089 108.602 211.456 109.169 211.456 109.713C211.456 110.067 211.285 110.422 210.967 110.611L211.603 111.58ZM210.306 110.067C210.551 110.067 210.649 109.878 210.649 109.666C210.649 109.476 210.526 109.287 210.306 109.287H209.719V110.067H210.306Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M213.276 110.334L213.553 109.527L213.956 108.602H214.887V108.72L213.452 111.758H213.1L211.666 108.72V108.602H212.597L213.024 109.527L213.276 110.334Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M216.781 111.042V111.698H215.076V111.042H215.502V109.259H215.126V108.602H216.756V109.259H216.38V111.042H216.781Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M218.835 108.602C219.509 108.602 220.258 108.955 220.383 109.779H219.559C219.434 109.45 219.184 109.332 218.835 109.332C218.335 109.332 218.011 109.662 218.011 110.18C218.011 110.627 218.335 111.028 218.835 111.028C219.184 111.028 219.484 110.863 219.559 110.533H220.383C220.258 111.405 219.534 111.758 218.835 111.758C217.986 111.758 217.162 111.216 217.162 110.18C217.162 109.12 217.986 108.602 218.835 108.602Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M222.944 109.311H221.753V109.76H222.845V110.469H221.753V110.966H222.969V111.698H220.885V108.602H222.919V109.311H222.944Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M357.985 99.7411H355.447C354.8 99.7411 354.253 99.2343 354.253 98.6355V98.4052C354.253 97.8063 354.8 97.2996 355.447 97.2996H357.985C358.632 97.2996 359.179 97.8063 359.179 98.4052V98.6355C359.179 99.2574 358.657 99.7411 357.985 99.7411Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M340.426 102.565C340.725 102.565 340.799 102.565 341.197 102.565C341.943 102.565 342.49 103.105 342.49 103.833C342.49 104.584 341.918 105.101 341.147 105.101C339.705 105.101 338.81 105.101 337.367 105.101C336.82 105.101 336.373 104.913 336.149 104.42C335.95 104.115 335.826 103.27 336.621 102.565C337.144 101.978 342.913 95.7565 343.933 94.6765C343.982 94.6061 343.933 94.5122 343.858 94.5122C343.833 94.5122 343.833 94.5122 343.808 94.5122C341.645 94.5122 339.481 94.5122 337.318 94.5122C336.671 94.5122 336.174 94.1365 336.049 93.573C335.9 92.9156 336.199 92.3522 336.771 92.0939C336.945 92.0235 337.119 92 337.293 92C340.501 92 343.684 92 346.892 92C347.514 92 347.937 92.1878 348.185 92.7278C348.509 93.4087 348.16 93.8783 347.937 94.1835C347.489 94.7235 342.938 99.56 340.426 102.283C340.401 102.307 340.376 102.354 340.327 102.377C340.277 102.448 340.327 102.565 340.426 102.565Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M347.186 105.101H344.648C344.001 105.101 343.453 104.582 343.453 103.968V103.732C343.453 103.119 344.001 102.6 344.648 102.6H347.186C347.832 102.6 348.38 103.119 348.38 103.732V103.968C348.38 104.605 347.832 105.101 347.186 105.101Z"
            fill="#141B4D"
            fillOpacity="0.63"
            stroke="#767676"
            strokeWidth="0.0812062"
            strokeMiterlimit="10"
          />
          <path
            d="M350.715 98.5152C350.715 96.7341 350.715 94.9764 350.715 93.1952C350.715 92.4922 351.237 92 351.983 92C355.215 92 358.446 92 361.678 92C362.424 92 362.946 92.5156 362.946 93.2421C362.946 93.9686 362.424 94.5077 361.678 94.5077C358.993 94.5077 356.333 94.5077 353.649 94.5077C353.45 94.5077 353.4 94.5545 353.4 94.742C353.4 97.2731 353.4 99.8042 353.4 102.335C353.4 102.546 353.475 102.57 353.649 102.57C356.309 102.57 358.993 102.57 361.653 102.57C362.25 102.57 362.722 102.898 362.896 103.39C363.095 103.976 362.871 104.562 362.399 104.89C362.175 105.03 361.902 105.101 361.628 105.101C358.446 105.101 355.24 105.101 352.058 105.101C351.237 105.101 350.74 104.585 350.74 103.812C350.715 102.031 350.715 100.273 350.715 98.5152Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M337.705 110.042V110.698H336V110.042H336.426V108.259H336.05V107.602H337.68V108.259H337.304V110.042H337.705Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M341.534 110.758H340.774L340.651 110.545C340.381 110.687 340.062 110.758 339.768 110.758C338.933 110.734 338.148 110.167 338.148 109.15C338.148 108.181 338.811 107.542 339.817 107.542C340.651 107.542 341.461 108.086 341.461 109.15C341.461 109.528 341.363 109.812 341.215 110.072L341.559 110.64V110.758H341.534ZM339.792 110.001C340.185 110.001 340.602 109.718 340.602 109.15C340.602 108.583 340.185 108.299 339.792 108.299C339.375 108.299 338.982 108.583 338.982 109.15C338.982 109.718 339.375 110.001 339.792 110.001Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M343.784 109.749C343.784 109.96 344.029 110.077 344.299 110.077C344.545 110.077 344.79 109.96 344.79 109.796C344.79 109.49 344.422 109.49 344.201 109.467C343.612 109.42 343.022 109.209 343.022 108.552C343.022 107.871 343.612 107.542 344.275 107.542C344.889 107.542 345.527 107.871 345.527 108.552H344.717C344.717 108.34 344.496 108.246 344.275 108.246C344.078 108.246 343.833 108.293 343.833 108.528C343.833 108.739 344.029 108.786 344.324 108.81C345.011 108.857 345.601 109.021 345.601 109.772C345.601 110.429 344.938 110.758 344.275 110.758C343.587 110.758 342.924 110.429 342.949 109.749H343.784Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M348.229 108.311H347.038V108.76H348.13V109.469H347.038V109.966H348.254V110.698H346.169V107.602H348.204V108.311H348.229Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M351.603 110.58V110.698H350.722L350.159 109.729H349.719V110.698H348.887V107.602C349.376 107.602 349.841 107.602 350.306 107.602C351.089 107.602 351.456 108.169 351.456 108.713C351.456 109.067 351.285 109.422 350.967 109.611L351.603 110.58ZM350.306 109.067C350.551 109.067 350.649 108.878 350.649 108.666C350.649 108.476 350.526 108.287 350.306 108.287H349.719V109.067H350.306Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M353.276 109.334L353.553 108.527L353.956 107.602H354.887V107.72L353.452 110.758H353.1L351.666 107.72V107.602H352.597L353.024 108.527L353.276 109.334Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M356.781 110.042V110.698H355.076V110.042H355.502V108.259H355.126V107.602H356.756V108.259H356.38V110.042H356.781Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M358.835 107.602C359.509 107.602 360.258 107.955 360.383 108.779H359.559C359.434 108.45 359.184 108.332 358.835 108.332C358.335 108.332 358.011 108.662 358.011 109.18C358.011 109.627 358.335 110.028 358.835 110.028C359.184 110.028 359.484 109.863 359.559 109.533H360.383C360.258 110.405 359.534 110.758 358.835 110.758C357.986 110.758 357.162 110.216 357.162 109.18C357.162 108.12 357.986 107.602 358.835 107.602Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
          <path
            d="M362.944 108.311H361.753V108.76H362.845V109.469H361.753V109.966H362.969V110.698H360.885V107.602H362.919V108.311H362.944Z"
            fill="#141B4D"
            fillOpacity="0.63"
          />
        </g>
        <defs>
          <pattern
            id="door-1-r-casement-2-container-pattern0"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
          >
            <use
              xlinkHref="#image0"
              transform="translate(-0.00768784) scale(0.00100915 0.00153332)"
            />
          </pattern>
          <filter
            id="door-1-r-casement-2-container-filter0_i"
            x="285"
            y="41"
            width="129.94"
            height="373.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="door-1-r-casement-2-container-filter1_i"
            x="158"
            y="41"
            width="102.94"
            height="255.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="door-1-r-casement-2-container-filter2_i"
            x="37"
            y="41"
            width="102.94"
            height="255.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="door-1-r-casement-2-container-filter3_d"
            x="-5.06006"
            y="328.94"
            width="279"
            height="24"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="3.94" />
            <feGaussianBlur stdDeviation="3" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="door-1-r-casement-2-container-paint0_linear"
            x1="174.259"
            y1="306"
            x2="174.259"
            y2="331"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DBDDE1" />
            <stop offset="1" stopColor="#F9F9F9" />
          </linearGradient>
          <clipPath id="door-1-r-casement-2-container-clip0">
            <rect width="451" height="451" fill="white" />
          </clipPath>
        </defs>
      </svg>

      {!withoutBackground && <ContainerBackground />}
    </ProductImageWrapper>
  )
}
