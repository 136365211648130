import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {InputFileStatus, InputFileStyle, InputFileWrapper, InputFileReq, UploadWrapper} from './styles'
import {IInputProps, InputTypes, IUploadProps} from '../../views/calculator/interface'
import { validateEmail, validatePassword, validateRequiredText } from '../../utilits'
import { CloseIcon } from '../../assets/icons'
import { ShowIcon1 } from '../../assets/icons'
import { ShowIcon2 } from '../../assets/icons'
import MapSvg from '../../assets/icons/map-icon'
import {Upload, Button} from 'antd'
import {UploadOutlined} from '@ant-design/icons'
import type {UploadProps} from 'antd/es/upload/interface';
import { API_URL } from '../../services/environment'

export const FileInput: FC<IUploadProps> = props => {
  const {
    files = [],
    name = '',
    req = false,
    multiple = false,
    errorMessage,
    onChange,
  } = props
  const [value, setValue] = useState<string | boolean>('')
  const [error, setError] = useState('')
  const [firstLoad, setFirstLoad] = useState(true)
  const [isShowPassword, setShowPassword] = useState(false)
  const timerRef = useRef<any>(0)
  const [fileList, setFileList] = useState<any>(files)

  // console.log({ req, placeholder })

  useEffect(() => {
    console.log('useEffect')
    //onChange && onChange(value, name)
    if (onChange && timerRef && !firstLoad) {
      //console.log({ value, timerRef,  })
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }

      timerRef.current = setTimeout(() => {
        onChange(fileList, name)
        timerRef.current = 0
      }, 300)
    }
    
    setFirstLoad(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList, firstLoad])

  useEffect(() => {
    if (errorMessage) {
      setError(errorMessage)
    }
  }, [errorMessage])

  const localOnChange: UploadProps['onChange'] = (info) => {
    let newFileList = [...info.fileList];

    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    console.log('newFileList', newFileList)
    //fetchAPI(`/api/claims/${id}/upload_file`, {method: 'POST', body: JSON.stringify(newFileList)})
    setFileList(newFileList);
  };

  const uploadProps = {
    name: 'file',
    action: `${API_URL}/api/upload`,
    onChange: localOnChange,
  };

  return (
    <InputFileWrapper>
      { req && <InputFileReq >*</InputFileReq> }
      <UploadWrapper>
        <Upload {...uploadProps}
                fileList={fileList}
                className="upload-wrapper"
                multiple={multiple}
        >
          <Button icon={<UploadOutlined rev={undefined}/>}>
            Загрузить файл
          </Button>
        </Upload>
      </UploadWrapper>
      {error && (
        <InputFileStatus title={error}>
          <CloseIcon color="#ff453a" />
        </InputFileStatus>
      )}

    </InputFileWrapper>
  )
}
