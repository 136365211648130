import React from 'react'
import { ProductImageWrapper } from '../../../components/product-images-generator/styles'

export const Casement21LoggiaR4Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <g filter="url(#filter0_d)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M147.779 50.6383C147.779 49.7486 147.103 49.277 146.268 49.585C145.433 49.8929 144.757 50.8638 144.757 51.7535V54.171L144.757 54.1709V62.228L144.756 62.2283V64.6458C144.756 65.5355 145.433 66.0071 146.267 65.6991C147.102 65.3912 147.779 64.4203 147.779 63.5306V61.7848L147.78 61.7845V52.3848L147.779 52.3849V50.6383Z"
              fill="url(#paint0_linear)"
            />
          </g>
          <rect
            width="3.2219"
            height="9.39966"
            transform="matrix(-0.938191 0.346117 0 1 147.78 52.3838)"
            fill="#ABABAB"
          />
          <path
            d="M147.78 49.7846C147.78 49.3664 147.461 49.1447 147.069 49.2895L145.467 49.8804C145.075 50.0251 144.757 50.4815 144.757 50.8998V54.1709L147.78 53.0558V49.7846Z"
            fill="url(#paint1_linear)"
          />
          <path
            d="M144.756 65.4995C144.756 65.9178 145.074 66.1395 145.467 65.9947L147.068 65.4038C147.461 65.259 147.779 64.8026 147.779 64.3844V61.1133L144.756 62.2284V65.4995Z"
            fill="url(#paint2_linear)"
          />
          <rect
            width="3.2219"
            height="6.71404"
            transform="matrix(-0.938191 0.346117 0 1 147.78 53.7275)"
            fill="url(#paint3_linear)"
          />
          <g filter="url(#filter1_d)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M147.779 138.458C147.779 137.568 147.103 137.096 146.268 137.404C145.433 137.712 144.757 138.683 144.757 139.573V141.99L144.757 141.99V150.047L144.756 150.047V152.464C144.756 153.354 145.433 153.826 146.267 153.518C147.102 153.21 147.779 152.239 147.779 151.349V149.603L147.78 149.603V140.203L147.779 140.204V138.458Z"
              fill="url(#paint4_linear)"
            />
          </g>
          <rect
            width="3.2219"
            height="9.39966"
            transform="matrix(-0.938191 0.346117 0 1 147.78 140.203)"
            fill="#ABABAB"
          />
          <path
            d="M147.78 137.604C147.78 137.186 147.461 136.964 147.069 137.109L145.467 137.7C145.075 137.844 144.757 138.301 144.757 138.719V141.99L147.78 140.875V137.604Z"
            fill="url(#paint5_linear)"
          />
          <path
            d="M144.756 153.318C144.756 153.736 145.074 153.958 145.467 153.813L147.068 153.222C147.461 153.077 147.779 152.621 147.779 152.203V148.932L144.756 150.047V153.318Z"
            fill="url(#paint6_linear)"
          />
          <rect
            width="3.2219"
            height="6.71404"
            transform="matrix(-0.938191 0.346117 0 1 147.78 141.546)"
            fill="url(#paint7_linear)"
          />
          <g filter="url(#filter2_d)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M220.345 13.3944L147.78 40.165V161.823L220.345 135.053V13.3944ZM212.03 25.3243L156.094 45.9601V149.893L212.03 129.258V25.3243Z"
              fill="#E8EAEE"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M220.345 13.3944L147.78 40.165V161.823L148.536 161.544V40.6918L219.589 14.4789V135.332L220.345 135.053V13.3944Z"
              fill="#FBFBFB"
            />
            <rect
              x="-0.355244"
              y="-0.247591"
              width="70.0462"
              height="114.359"
              transform="matrix(0.938191 -0.346117 0 1 151.537 42.6759)"
              stroke="#E2E4E8"
              strokeWidth="0.757295"
            />
            <g filter="url(#filter3_i)">
              <rect
                x="0.377943"
                y="0.263412"
                width="58.815"
                height="103.128"
                transform="matrix(0.938191 -0.346117 0 1 156.118 46.0908)"
                stroke="#979797"
                strokeWidth="0.805684"
              />
            </g>
          </g>
          <rect
            width="5.63979"
            height="10.8996"
            rx="2.8199"
            transform="matrix(-0.938191 0.346117 0 1 218.833 68.7383)"
            fill="#9B9DA2"
            fillOpacity="0.19"
          />
          <g filter="url(#filter4_d)">
            <rect
              width="4.51183"
              height="9.8615"
              rx="2.25592"
              transform="matrix(-0.938191 0.346117 0 1 218.302 69.4541)"
              fill="#E8EAEE"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M217.862 78.5995C217.475 79.2763 216.868 79.8441 216.186 80.0957C215.017 80.527 214.069 79.8665 214.069 78.6206V73.271C214.069 72.4749 214.456 71.6325 215.04 71.0155C214.764 71.4985 214.6 72.0371 214.6 72.556V77.9057C214.6 79.1516 215.547 79.812 216.716 79.3808C217.138 79.2251 217.531 78.9483 217.862 78.5995Z"
            fill="#DFE0E2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M216.186 70.2349C217.355 69.8037 218.302 70.4641 218.302 71.71V76.5407C218.302 76.5512 218.302 76.5617 218.302 76.5722V72.1776C218.302 70.9317 217.355 70.2713 216.186 70.7025C215.027 71.1301 214.085 72.4704 214.07 73.7077V73.2716C214.07 72.0257 215.017 70.6661 216.186 70.2349Z"
            fill="#FBFBFB"
          />
          <g filter="url(#filter5_d)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M215.053 74.5564V74.391C215.053 73.8189 215.489 73.1946 216.025 72.9966C216.055 72.9858 216.084 72.9761 216.112 72.9675C216.137 72.9567 216.162 72.9466 216.187 72.9373C216.813 72.7063 217.321 73.0601 217.321 73.7276V73.8914C217.321 73.8942 217.321 73.8969 217.321 73.8997L217.321 89.8408C217.321 90.5082 216.813 91.2366 216.187 91.4676C215.561 91.6986 215.053 91.3448 215.053 90.6774L215.053 77.3841C215.053 77.3841 215.053 77.384 215.053 77.3841C215.053 77.3841 215.053 77.384 215.053 77.384V74.5642C215.053 74.5616 215.053 74.559 215.053 74.5564Z"
              fill="#E8EAEE"
            />
          </g>
          <path
            d="M157.228 147.777V47.2403L210.703 77.7811L157.228 147.777Z"
            stroke="white"
            strokeWidth="0.805684"
          />
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="143.242"
            y="47.9766"
            width="7.56731"
            height="20.8464"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.757295" dy="0.757295" />
            <feGaussianBlur stdDeviation="1.13594" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter1_d"
            x="143.242"
            y="135.796"
            width="7.56731"
            height="20.8457"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.757295" dy="0.757295" />
            <feGaussianBlur stdDeviation="1.13594" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter2_d"
            x="146.168"
            y="11.7832"
            width="79.0105"
            height="154.874"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.61137" dy="1.61137" />
            <feGaussianBlur stdDeviation="1.61137" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter3_i"
            x="156.094"
            y="25.3242"
            width="56.6929"
            height="126.841"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.757295" dy="2.27188" />
            <feGaussianBlur stdDeviation="1.51459" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter4_d"
            x="212.555"
            y="68.5879"
            width="8.77673"
            height="14.6692"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.757295" dy="0.757295" />
            <feGaussianBlur stdDeviation="1.13594" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter5_d"
            x="213.539"
            y="71.3516"
            width="6.81145"
            height="23.2154"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.757295" dy="0.757295" />
            <feGaussianBlur stdDeviation="1.13594" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="149.896"
            y1="62.3462"
            x2="144.151"
            y2="62.3462"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="142.641"
            y1="52.9373"
            x2="148.384"
            y2="52.9373"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="149.895"
            y1="62.3469"
            x2="144.152"
            y2="62.3469"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="5.47722"
            y1="3.35702"
            x2="-0.644379"
            y2="3.35702"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="149.896"
            y1="150.165"
            x2="144.151"
            y2="150.165"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="142.641"
            y1="140.757"
            x2="148.384"
            y2="140.757"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="149.895"
            y1="150.165"
            x2="144.152"
            y2="150.165"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="5.47722"
            y1="3.35702"
            x2="-0.644379"
            y2="3.35702"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <clipPath id="clip0">
            <rect
              width="85.4025"
              height="127.298"
              fill="white"
              transform="matrix(0.938191 -0.346117 0 1 144 41.5596)"
            />
          </clipPath>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
