import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement33Square4TopCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-top-3-3_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M193.001 128C194.105 128 195 127.105 195 126.001C195 124.896 194.105 124.001 193.001 124.001L190.833 124.001L190.833 124.001L180 124.001L180 124L176.999 124C175.895 124 175 124.895 175 125.999C175 127.104 175.895 127.999 176.999 127.999L179.167 127.999L179.167 128L190 128L190 128L193.001 128Z"
            fill="url(#4-top-3-3_paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 190.834 128)"
          fill="#ABABAB"
        />
        <path
          d="M194.06 128C194.579 128 195 127.579 195 127.06L195 124.941C195 124.422 194.579 124.001 194.06 124.001L190 124.001L190 128L194.06 128Z"
          fill="url(#4-top-3-3_paint1_linear)"
        />
        <path
          d="M175.94 124C175.421 124 175 124.421 175 124.94L175 127.059C175 127.578 175.421 127.999 175.94 127.999L180 127.999L180 124L175.94 124Z"
          fill="url(#4-top-3-3_paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 189.167 128)"
          fill="url(#4-top-3-3_paint3_linear)"
        />
        <g filter="url(#4-top-3-3_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M271.001 128C272.105 128 273 127.105 273 126.001C273 124.896 272.105 124.001 271.001 124.001L268.833 124.001L268.833 124.001L258 124.001L258 124L254.999 124C253.895 124 253 124.895 253 125.999C253 127.104 253.895 127.999 254.999 127.999L257.167 127.999L257.167 128L268 128L268 128L271.001 128Z"
            fill="url(#4-top-3-3_paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 268.834 128)"
          fill="#ABABAB"
        />
        <path
          d="M272.06 128C272.579 128 273 127.579 273 127.06L273 124.941C273 124.422 272.579 124.001 272.06 124.001L268 124.001L268 128L272.06 128Z"
          fill="url(#4-top-3-3_paint5_linear)"
        />
        <path
          d="M253.94 124C253.421 124 253 124.421 253 124.94L253 127.059C253 127.578 253.421 127.999 253.94 127.999L258 127.999L258 124L253.94 124Z"
          fill="url(#4-top-3-3_paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 267.167 128)"
          fill="url(#4-top-3-3_paint7_linear)"
        />
        <g filter="url(#4-top-3-3_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M284 128H164V287H284V128ZM273 139H175V276H273V139Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M284 128H164V287H165V129H283V287H284V128Z"
          fill="#FBFBFB"
        />
        <rect
          x="168.53"
          y="132.53"
          width="110.94"
          height="149.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#4-top-3-3_filter3_i)">
          <rect
            x="175.47"
            y="139.47"
            width="97.0601"
            height="136.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="232"
          y="278"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 232 278)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <rect
          x="231.355"
          y="278.702"
          width="5.6"
          height="12.2399"
          rx="2.8"
          transform="rotate(90 231.355 278.702)"
          fill="#EAEAEB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M231.238 280.695C230.892 279.542 229.822 278.702 228.556 278.702L221.916 278.702C220.37 278.702 219.116 279.956 219.116 281.502C219.116 281.537 219.117 281.572 219.118 281.607C219.465 282.76 220.534 283.6 221.8 283.6L228.44 283.6C229.987 283.6 231.24 282.346 231.24 280.8C231.24 280.765 231.24 280.73 231.238 280.695Z"
          fill="#EFF1F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M229.881 279.035C229.737 279.012 229.59 279 229.44 279L222.8 279C221.254 279 220 280.254 220 281.8C220 282.867 220.597 283.795 221.475 284.267C220.138 284.056 219.116 282.898 219.116 281.502C219.116 279.956 220.37 278.702 221.916 278.702L228.556 278.702C229.035 278.702 229.486 278.822 229.881 279.035Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-top-3-3_filter4_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M228 281.5C228 280.672 227.328 280 226.5 280L226.286 280L225 280L206.5 280C205.672 280 205 280.672 205 281.5C205 282.328 205.672 283 206.5 283L223 283L226.5 283L226.714 283C227.424 283 228 282.424 228 281.714C228 281.677 227.999 281.64 227.996 281.604C227.999 281.569 228 281.535 228 281.5Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M176.708 140.5L271.292 140.5L224 273.508L176.708 140.5Z" stroke="white" />
        <defs>
          <filter
            id="4-top-3-3_filter0_d"
            x="173.12"
            y="122.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-3-3_filter1_d"
            x="251.12"
            y="122.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-3-3_filter2_d"
            x="159.3"
            y="123.3"
            width="131.279"
            height="170.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-top-3-3_filter3_i"
            x="175"
            y="139"
            width="98.9399"
            height="139.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-top-3-3_filter4_d"
            x="203.28"
            y="278.18"
            width="28.64"
            height="8.64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.1" dy="1" />
            <feGaussianBlur stdDeviation="1.41" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.54 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="4-top-3-3_paint0_linear"
            x1="177.5"
            y1="130.8"
            x2="177.5"
            y2="123.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="4-top-3-3_paint1_linear"
            x1="192.5"
            y1="121.202"
            x2="192.5"
            y2="128.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-3-3_paint2_linear"
            x1="177.5"
            y1="130.798"
            x2="177.5"
            y2="123.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-3-3_paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-3-3_paint4_linear"
            x1="255.5"
            y1="130.8"
            x2="255.5"
            y2="123.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="4-top-3-3_paint5_linear"
            x1="270.5"
            y1="121.202"
            x2="270.5"
            y2="128.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-3-3_paint6_linear"
            x1="255.5"
            y1="130.798"
            x2="255.5"
            y2="123.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="4-top-3-3_paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
