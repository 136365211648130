import styled from 'styled-components'

import { BadgeWrapper } from '../badge'
import { ComponentShadowWrapper } from '../component-shadow'

export const SubMenuAttributeTitle = styled.span`
  position: relative;

  display: flex;

  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
  color: #000000;

  margin-top: 0;
  margin-bottom: 14px;

  span {
    margin-right: 4px;
  }

  & + ${BadgeWrapper} {
    margin-top: 0;

    ${ComponentShadowWrapper} {
      top: 0;
    }
  }
`
