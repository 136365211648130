import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement36Square4VerticalCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="220"
        height="333"
        viewBox="0 0 220 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#3-6_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M191.954 170.976C191.954 169.885 191.069 169 189.977 169C188.886 169 188.001 169.885 188.001 170.976L188.001 173.167L188.001 173.167L188.001 184L188 184L188 187.024C188 188.115 188.885 189 189.976 189C191.068 189 191.953 188.115 191.953 187.024L191.953 184.833L191.954 184.833L191.954 174L191.954 174L191.954 170.976Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 191.954 173.166)"
          fill="#ABABAB"
        />
        <path
          d="M191.954 169.94C191.954 169.421 191.533 169 191.014 169L188.941 169C188.422 169 188.001 169.421 188.001 169.94L188.001 174L191.954 174L191.954 169.94Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M188 188.06C188 188.579 188.421 189 188.94 189L191.013 189C191.532 189 191.953 188.579 191.953 188.06L191.953 184L188 184L188 188.06Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 191.954 174.833)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#3-6_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M191.954 197.976C191.954 196.885 191.069 196 189.977 196C188.886 196 188.001 196.885 188.001 197.976L188.001 200.167L188.001 200.167L188.001 211L188 211L188 214.024C188 215.115 188.885 216 189.976 216C191.068 216 191.953 215.115 191.953 214.024L191.953 211.833L191.954 211.833L191.954 201L191.954 201L191.954 197.976Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.95299"
          height="11.6667"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 191.954 200.166)"
          fill="#ABABAB"
        />
        <path
          d="M191.954 196.94C191.954 196.421 191.533 196 191.014 196L188.941 196C188.422 196 188.001 196.421 188.001 196.94L188.001 201L191.954 201L191.954 196.94Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M188 215.06C188 215.579 188.421 216 188.94 216L191.013 216C191.532 216 191.953 215.579 191.953 215.06L191.953 211L188 211L188 215.06Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.95299"
          height="8.33334"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 191.954 201.833)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#3-6_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188 165H32V220H188V165ZM178 175H42V210H178V175Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 165H32V220H33V166H187V220H188V165Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-6_filter3_i)">
          <rect
            x="42.47"
            y="175.47"
            width="135.06"
            height="34.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="36.53"
          y="169.53"
          width="146.94"
          height="45.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <rect
          width="6.91954"
          height="13.5283"
          rx="3.45977"
          transform="matrix(-1 0 0 1 40.9194 186)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#3-6_filter4_d)">
          <rect
            width="5.53563"
            height="12.2399"
            rx="2.76782"
            transform="matrix(-1 0 0 1 40.2253 186.644)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.6604 197.792C39.1548 198.456 38.3562 198.884 37.4577 198.884C35.9291 198.884 34.6899 197.645 34.6899 196.116V189.412C34.6899 188.44 35.1907 187.585 35.9482 187.092C35.5936 187.557 35.383 188.138 35.383 188.768V195.472C35.383 197.001 36.6222 198.24 38.1508 198.24C38.7078 198.24 39.2262 198.075 39.6604 197.792Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.4575 186.644C38.9861 186.644 40.2253 187.883 40.2253 189.412V195.472C40.2253 195.492 40.2251 195.512 40.2247 195.533V189.991C40.2247 188.463 38.9855 187.224 37.4569 187.224C35.9485 187.224 34.722 188.43 34.6897 189.931V189.412C34.6897 187.883 35.9289 186.644 37.4575 186.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-6_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.4597 190C38.2787 190 38.9425 190.664 38.9425 191.483V193C38.9425 193.014 38.9424 193.027 38.9421 193.041L38.9421 211.517C38.9421 212.336 38.2782 213 37.4593 213V213C36.6404 213 35.9766 212.336 35.9766 211.517L35.9766 191.251C35.9766 190.56 36.5368 190 37.2278 190V190C37.2307 190 37.2335 190 37.2363 190C37.3107 190 37.3853 190 37.4597 190V190Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M176.5 208.435L47.0913 192.5L176.5 176.565L176.5 208.435Z" stroke="white" />
        <defs>
          <filter
            id="3-6_filter0_d"
            x="186.12"
            y="167.12"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-6_filter1_d"
            x="186.12"
            y="194.12"
            width="9.59374"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-6_filter2_d"
            x="27.3003"
            y="160.3"
            width="167.279"
            height="66.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-6_filter3_i"
            x="42"
            y="175"
            width="136.94"
            height="37.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-6_filter4_d"
            x="32.8098"
            y="184.764"
            width="11.1753"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-6_filter5_d"
            x="34.0967"
            y="188.12"
            width="8.60556"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="194.722"
            y1="186.5"
            x2="187.209"
            y2="186.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="185.234"
            y1="171.5"
            x2="192.744"
            y2="171.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="194.72"
            y1="186.5"
            x2="187.209"
            y2="186.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="194.722"
            y1="213.5"
            x2="187.209"
            y2="213.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="185.234"
            y1="198.5"
            x2="192.744"
            y2="198.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="194.72"
            y1="213.5"
            x2="187.209"
            y2="213.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.72008"
            y1="4.16667"
            x2="-0.790598"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
