import React, { FC } from 'react'
import { ComponentShadowWrapper } from './styles'

export const ComponentShadow: FC<{
  coord?: { top?: string; right?: string; bottom?: string; left?: string }
}> = props => {
  const { coord = { top: '0', left: '-50px', right: '-50px', bottom: '0' } } = props

  const trueCoord = {
    top: coord['top'] || '0',
    right: coord['right'] || '-50px',
    bottom: coord['bottom'] || '0',
    left: coord['left'] || '-50px',
  }

  return <ComponentShadowWrapper coord={trueCoord} />
}
