import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement4Door1LCasement2 = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="451"
        height="451"
        viewBox="0 0 451 451"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#door-1-l-casement-2-4-filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M301 271.999C301 270.895 300.105 270 299.001 270C297.896 270 297.001 270.895 297.001 271.999V274.167H297.001V285H297V288.001C297 289.105 297.895 290 298.999 290C300.104 290 300.999 289.105 300.999 288.001V285.833H301V275H301V271.999Z"
            fill="url(#door-1-l-casement-2-4-paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 301 274.167)"
          fill="#ABABAB"
        />
        <path
          d="M301 270.94C301 270.421 300.579 270 300.06 270H297.941C297.422 270 297.001 270.421 297.001 270.94V275H301V270.94Z"
          fill="url(#door-1-l-casement-2-4-paint1_linear)"
        />
        <path
          d="M297 289.06C297 289.579 297.421 290 297.94 290H300.059C300.578 290 300.999 289.579 300.999 289.06V285H297V289.06Z"
          fill="url(#door-1-l-casement-2-4-paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 301 275.833)"
          fill="url(#door-1-l-casement-2-4-paint3_linear)"
        />
        <g filter="url(#door-1-l-casement-2-4-filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M301 46.9995C301 45.8952 300.105 45 299.001 45C297.896 45 297.001 45.8952 297.001 46.9995V49.1665H297.001V60H297V63.0005C297 64.1048 297.895 65 298.999 65C300.104 65 300.999 64.1048 300.999 63.0005V60.8332H301V50H301V46.9995Z"
            fill="url(#door-1-l-casement-2-4-paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 301 49.1665)"
          fill="#ABABAB"
        />
        <path
          d="M301 45.9399C301 45.4208 300.579 45 300.06 45H297.941C297.422 45 297.001 45.4208 297.001 45.9399V50H301V45.9399Z"
          fill="url(#door-1-l-casement-2-4-paint5_linear)"
        />
        <path
          d="M297 64.0601C297 64.5792 297.421 65 297.94 65H300.059C300.578 65 300.999 64.5792 300.999 64.0601V60H297V64.0601Z"
          fill="url(#door-1-l-casement-2-4-paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 301 50.8335)"
          fill="url(#door-1-l-casement-2-4-paint7_linear)"
        />
        <g filter="url(#door-1-l-casement-2-4-filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M297 34H186V301H297V34ZM286 45H197V290H286V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M297 34H186V301H187V35H296V301H297V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="190.53"
          y="38.53"
          width="101.94"
          height="257.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#door-1-l-casement-2-4-filter3_i)">
          <rect
            x="197.47"
            y="45.47"
            width="88.0601"
            height="244.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <path d="M284.5 287.443V47.5571L198.615 167.5L284.5 287.443Z" stroke="white" />
        <rect
          x="188"
          y="161"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#door-1-l-casement-2-4-filter4_d)">
          <rect x="188.702" y="161.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M189.286 172.796C189.798 173.458 190.6 173.884 191.502 173.884C193.048 173.884 194.302 172.63 194.302 171.084V164.444C194.302 163.456 193.79 162.587 193.016 162.089C193.382 162.562 193.6 163.156 193.6 163.8V170.44C193.6 171.986 192.347 173.24 190.8 173.24C190.242 173.24 189.722 173.077 189.286 172.796Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M191.502 161.644C189.956 161.644 188.702 162.898 188.702 164.444V170.44C188.702 170.46 188.702 170.48 188.703 170.501V165.024C188.703 163.477 189.956 162.224 191.503 162.224C193.029 162.224 194.269 163.444 194.302 164.963V164.444C194.302 162.898 193.048 161.644 191.502 161.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#door-1-l-casement-2-4-filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M191.5 165C190.672 165 190 165.672 190 166.5V166.714V168V186.5C190 187.328 190.672 188 191.5 188V188C192.328 188 193 187.328 193 186.5V170V166.5L193 166.286C193 165.576 192.424 165 191.714 165V165C191.677 165 191.64 165.001 191.604 165.004C191.569 165.001 191.535 165 191.5 165Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="door-1-l-casement-2-4-filter0_d"
            x="295.12"
            y="268.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-2-4-filter1_d"
            x="295.12"
            y="43.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-2-4-filter2_d"
            x="181.3"
            y="29.3003"
            width="122.279"
            height="278.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-2-4-filter3_i"
            x="197"
            y="45"
            width="89.9399"
            height="247.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="door-1-l-casement-2-4-filter4_d"
            x="186.822"
            y="159.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="door-1-l-casement-2-4-filter5_d"
            x="188.12"
            y="163.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="door-1-l-casement-2-4-paint0_linear"
            x1="303.8"
            y1="287.5"
            x2="296.2"
            y2="287.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-2-4-paint1_linear"
            x1="294.202"
            y1="272.5"
            x2="301.8"
            y2="272.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-2-4-paint2_linear"
            x1="303.798"
            y1="287.5"
            x2="296.2"
            y2="287.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-2-4-paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-2-4-paint4_linear"
            x1="303.8"
            y1="62.5"
            x2="296.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-2-4-paint5_linear"
            x1="294.202"
            y1="47.5"
            x2="301.8"
            y2="47.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-2-4-paint6_linear"
            x1="303.798"
            y1="62.5"
            x2="296.2"
            y2="62.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="door-1-l-casement-2-4-paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
