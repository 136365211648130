import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement42Square53Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-2_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M127 145.001C127 146.105 127.895 147 128.999 147C130.104 147 130.999 146.105 130.999 145.001V142.833H130.999V132H131V128.999C131 127.895 130.105 127 129.001 127C127.896 127 127.001 127.895 127.001 128.999V131.167H127V142H127V145.001Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1 0 0 -1 127 142.834)"
          fill="#ABABAB"
        />
        <path
          d="M127 146.06C127 146.579 127.421 147 127.94 147H130.059C130.578 147 130.999 146.579 130.999 146.06V142H127V146.06Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M131 127.94C131 127.421 130.579 127 130.06 127H127.941C127.422 127 127.001 127.421 127.001 127.94V132H131V127.94Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1 0 0 -1 127 141.166)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#4-2_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M131 260C131 258.895 130.105 258 129 258C127.895 258 127 258.895 127 260L127 274.56L131 274.56L131 260ZM131 274.56L127 274.56L127 276.4L126.94 276.4C126.421 276.4 126 276.821 126 277.34L126 279.12C126 280.158 126.842 281 127.88 281L129.12 281C130.158 281 131 280.158 131 279.12L131 276.4L131 276.4L131 274.56Z"
            fill="white"
          />
        </g>
        <rect
          width="3.99998"
          height="0.95833"
          transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 131 275.25)"
          fill="#ABABAB"
        />
        <path
          d="M131 258.94C131 258.421 130.579 258 130.06 258L127.94 258C127.421 258 127 258.421 127 258.94L127 275.25L131 275.25L131 258.94Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M131 280.06C131 280.579 130.579 281 130.06 281L126.94 281C126.421 281 126 280.579 126 280.06L126 277.148C126 276.629 126.421 276.208 126.94 276.208L131 276.208L131 280.06Z"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#4-2_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M318 121H131V287H318V121ZM307 132H142V276H307V132Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#4-2_filter3_i)">
          <rect
            x="142.47"
            y="132.47"
            width="164.06"
            height="143.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="136.5" y="126.5" width="176" height="155" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M318 121H131V287H132V122H317V287H318V121Z"
          fill="#FBFBFB"
        />
        <rect
          x="309"
          y="197"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#4-2_filter4_d)">
          <rect x="309.702" y="197.645" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M310.286 208.795C310.798 209.457 311.6 209.883 312.502 209.883C314.049 209.883 315.302 208.63 315.302 207.083V200.443C315.302 199.455 314.79 198.586 314.017 198.088C314.383 198.561 314.601 199.155 314.601 199.799V206.439C314.601 207.986 313.347 209.239 311.801 209.239C311.243 209.239 310.723 209.076 310.286 208.795Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M312.502 197.645C310.956 197.645 309.702 198.898 309.702 200.445V206.44C309.702 206.461 309.702 206.481 309.703 206.501V201.024C309.703 199.478 310.956 198.224 312.503 198.224C314.029 198.224 315.27 199.445 315.302 200.963V200.445C315.302 198.898 314.049 197.645 312.502 197.645Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-2_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M312.5 201C311.672 201 311 201.672 311 202.5V202.714V204V222.5C311 223.328 311.672 224 312.5 224V224C313.328 224 314 223.328 314 222.5V206V202.5L314 202.286C314 201.576 313.424 201 312.714 201V201C312.677 201 312.64 201.001 312.604 201.004C312.569 201.001 312.535 201 312.5 201Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M143.5 274.237L143.5 133.763L304.748 204L143.5 274.237Z" stroke="white" />
        <path d="M305.137 274.5H143.863L224.5 134.004L305.137 274.5Z" stroke="white" />
        <defs>
          <filter
            id="4-2_filter0_d"
            x="125.12"
            y="125.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-2_filter1_d"
            x="124.12"
            y="256.12"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-2_filter2_d"
            x="126.3"
            y="116.3"
            width="198.279"
            height="177.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-2_filter3_i"
            x="142"
            y="132"
            width="165.94"
            height="146.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-2_filter4_d"
            x="307.822"
            y="195.765"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-2_filter5_d"
            x="309.12"
            y="199.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="124.2"
            y1="129.5"
            x2="131.8"
            y2="129.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="133.798"
            y1="144.5"
            x2="126.2"
            y2="144.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="124.202"
            y1="129.5"
            x2="131.8"
            y2="129.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="124.2"
            y1="266.625"
            x2="131.8"
            y2="266.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="122.5"
            y1="278.604"
            x2="132"
            y2="278.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
