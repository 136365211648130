import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement32Square52Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 58.0005C417 59.1048 417.895 60 418.999 60C420.104 60 420.999 59.1048 420.999 58.0005V55.8335H420.999V45H421V41.9995C421 40.8952 420.105 40 419.001 40C417.896 40 417.001 40.8952 417.001 41.9995V44.1668H417V55H417V58.0005Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1 0 0 -1 417 55.834)"
          fill="#ABABAB"
        />
        <path
          d="M417 59.0601C417 59.5792 417.421 60 417.94 60H420.059C420.578 60 420.999 59.5792 420.999 59.0601V55H417V59.0601Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M421 40.9399C421 40.4208 420.579 40 420.06 40H417.941C417.422 40 417.001 40.4208 417.001 40.9399V45H421V40.9399Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1 0 0 -1 417 54.166)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 84C417 82.8954 417.895 82 419 82C420.105 82 421 82.8954 421 84L421 98.5599L417 98.5599L417 84ZM417 98.5602L421 98.5602L421 100.4L421.06 100.4C421.579 100.4 422 100.821 422 101.34L422 103.12C422 104.158 421.158 105 420.12 105L418.88 105C417.842 105 417 104.158 417 103.12L417 100.4L417 100.4L417 98.5602Z"
            fill="white"
          />
        </g>
        <rect x="417" y="99.25" width="3.99998" height="0.95833" fill="#ABABAB" />
        <path
          d="M417 82.9399C417 82.4208 417.421 82 417.94 82L420.06 82C420.579 82 421 82.4208 421 82.9399L421 99.2499L417 99.2499L417 82.9399Z"
          fill="url(#paint4_linear)"
        />
        <path
          d="M417 104.06C417 104.579 417.421 105 417.94 105L421.06 105C421.579 105 422 104.579 422 104.06L422 101.148C422 100.629 421.579 100.208 421.06 100.208L417 100.208L417 104.06Z"
          fill="url(#paint5_linear)"
        />
        <g filter="url(#filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 34H333V111H417V34ZM406 45H344V100H406V45Z"
            fill="#E8EAEE"
          />
        </g>
        <g filter="url(#filter3_i)">
          <rect
            x="344.47"
            y="45.47"
            width="61.0601"
            height="54.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect x="338.5" y="39.5" width="73" height="66" stroke="#E2E4E8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 34H333V111H334V35H416V111H417V34Z"
          fill="#FBFBFB"
        />
        <path d="M404.142 98.5H345.858L375 47.015L404.142 98.5Z" stroke="white" />
        <rect
          x="335"
          y="66"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#filter4_d)">
          <rect x="335.702" y="66.6445" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M336.286 77.7948C336.798 78.457 337.6 78.8833 338.502 78.8833C340.049 78.8833 341.302 77.6297 341.302 76.0833V69.4434C341.302 68.455 340.79 67.5862 340.017 67.0879C340.383 67.5611 340.601 68.1548 340.601 68.7994V75.4393C340.601 76.9857 339.347 78.2393 337.801 78.2393C337.243 78.2393 336.723 78.0761 336.286 77.7948Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M338.502 66.6445C336.956 66.6445 335.702 67.8981 335.702 69.4445V75.4402C335.702 75.4606 335.702 75.4809 335.703 75.5012V70.0241C335.703 68.4777 336.956 67.2241 338.503 67.2241C340.029 67.2241 341.27 68.4449 341.302 69.9631V69.4445C341.302 67.8981 340.049 66.6445 338.502 66.6445Z"
          fill="#FBFBFB"
        />
        <g filter="url(#filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M338.5 70C337.672 70 337 70.6716 337 71.5V71.7143V73V91.5C337 92.3284 337.672 93 338.5 93C339.328 93 340 92.3284 340 91.5V75V71.5L340 71.2857C340 70.5756 339.424 70 338.714 70C338.677 70 338.64 70.0012 338.604 70.0035C338.569 70.0012 338.535 70 338.5 70Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M404.5 98.2326L404.5 46.7674L346.238 72.5L404.5 98.2326Z" stroke="white" />
        <defs>
          <filter
            id="filter0_d"
            x="415.12"
            y="38.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter1_d"
            x="415.12"
            y="80.1201"
            width="10.6396"
            height="28.6397"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter2_d"
            x="328.3"
            y="29.3003"
            width="95.2793"
            height="88.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter3_i"
            x="344"
            y="45"
            width="62.9399"
            height="57.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter4_d"
            x="333.822"
            y="64.7647"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="filter5_d"
            x="335.12"
            y="68.1201"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="414.2"
            y1="42.5"
            x2="421.8"
            y2="42.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="423.798"
            y1="57.5"
            x2="416.2"
            y2="57.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="414.202"
            y1="42.5"
            x2="421.8"
            y2="42.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="423.8"
            y1="90.625"
            x2="416.2"
            y2="90.625"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="425.5"
            y1="102.604"
            x2="416"
            y2="102.604"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
