import React, { useContext, useCallback, ReactNode } from 'react'
import ModalContext from './context'
/*
interface ICreateModal {
  node: any
  props?: any
}
*/

const stylePrev = {
  htmlStyle: {},
  bodyStyle: {}
}

export function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (symbol) => {
    const rnd = Math.random() * 16 | 0
    return ((symbol === 'x') ? rnd : (rnd & 0x3 | 0x8)).toString(16)
  })
}

function saveStylePrev() {
  const html = window?.document?.querySelector?.(':root') as HTMLElement
  const body = window?.document?.body as HTMLElement
  Object.assign(stylePrev, { 
    htmlStyle: {
      overflow: html.style.overflow || 'unset',
      width: html.style.width || 'unset'
    }, 
    bodyStyle: {
      overflow: body.style.overflow || 'unset',
      width: body.style.width || 'unset'
    }
  })
}

function setStylePrev() {
  const saveStyle = {
    htmlStyle: { ...stylePrev.htmlStyle },
    bodyStyle: { ...stylePrev.bodyStyle },
  }
  Object.assign(stylePrev, {
    htmlStyle: {},
    bodyStyle: {}
  })
  return saveStyle
}

export function getScrollbarWidth(): number {
  //@ts-ignore
  const scrollbarWidth = (window.innerWidth - (window?.visualViewport?.width || 0)) || 0
  return scrollbarWidth
}

export const scrollLock = () => {
  const html = window?.document?.querySelector?.(':root') as HTMLElement
  const body = window?.document?.body as HTMLElement
  const scrollbarWidth = getScrollbarWidth()
  const stelyWidth = scrollbarWidth === 0 ? `100%` : `calc(100% - ${scrollbarWidth}px)`
  if (html && body) {
    saveStylePrev()
    Object.assign(body.style, { overflow: 'hidden' })
    Object.assign(html.style, { overflow: 'hidden', width: stelyWidth })  
  }
}

export const scrollUnlock = () => {
  const html = window?.document?.querySelector?.(':root') as HTMLElement
  const body = window?.document?.body as HTMLElement
  if (html && body) {
    const { htmlStyle, bodyStyle } = setStylePrev()
    Object.assign(html.style, htmlStyle)
    Object.assign(body.style, bodyStyle)
  }
}

function useNextModal() {
  const { setModals } = useContext(ModalContext)
  const createModal = useCallback((node: any | ReactNode, props: any = {}) => new Promise<any>(resolve => {
      // @ts-ignore
      setModals((modals: any[]) => {
        const isExist = Boolean(props?.name && modals.find(modal => modal.props?.name === props?.name))
        modals.length || scrollLock()

        return isExist ? modals : modals.concat({ node, props, resolve, guid: uuid() })
      })
  }), [setModals])

  return { createModal }
}

export default useNextModal
