import React, { FC, useEffect, useRef, useState } from 'react'
import { IFloatBlockProps } from './interface'
import { FloatBlockWrapper } from './styles'

export const FloatBlock: FC<IFloatBlockProps> = ({ stageWrapperRef, children }) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const shiftRef = useRef(0)
  const [needShift, setNeedShift] = useState(false)
  const [stopAtBottom, setStopAtBottom] = useState(false)

  useEffect(() => {
    if (stageWrapperRef && wrapperRef.current && stageWrapperRef.current) {
      const header = document.querySelector('header.header')
      const bounding = wrapperRef.current.getBoundingClientRect()

      const checkPosition = () => {
        let targetValue = 124 + 20
        const headerBounding = header?.getBoundingClientRect()

        if (headerBounding) {
          targetValue = headerBounding.height + 20
        }

        if (stageWrapperRef && wrapperRef.current && stageWrapperRef.current) {
          const stageWrapperBounding = stageWrapperRef.current.getBoundingClientRect()

          const result = bounding.top <= targetValue

          if (shiftRef.current !== targetValue) {
            shiftRef.current = targetValue
          }

          setNeedShift(result)

          const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          const stageWrapperBoundingBottom = stageWrapperBounding.bottom + scrollTop;
          const boundingBottom = bounding.bottom + scrollTop;
          const isBeyondStageWrapper = boundingBottom >= (stageWrapperBoundingBottom - 100)
          setStopAtBottom(isBeyondStageWrapper)
        }
      }

      document.addEventListener('scroll', checkPosition)
      return () => document.removeEventListener('scroll', checkPosition)
    }
  }, [stageWrapperRef])

  return (
    <div ref={wrapperRef}>
      <FloatBlockWrapper
        shift={shiftRef.current}
        needShift={needShift && !stopAtBottom}
        stopAtBottom={stopAtBottom}
      >
        {children}
      </FloatBlockWrapper>
    </div>
  )
}

