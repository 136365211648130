import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement14Square4Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="315"
        height="333"
        viewBox="0 0 315 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#238filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34 40.9995C34 39.8952 33.1048 39 32.0005 39C30.8962 39 30.001 39.8952 30.001 40.9995V43.1665H30.001V54H30V57.0005C30 58.1048 30.8952 59 31.9995 59C33.1038 59 33.999 58.1048 33.999 57.0005V54.8332H34V44H34V40.9995Z"
            fill="url(#238paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 34 43.1665)"
          fill="#ABABAB"
        />
        <path
          d="M34 39.9399C34 39.4208 33.5792 39 33.0601 39H30.941C30.4219 39 30.001 39.4208 30.001 39.9399V44H34V39.9399Z"
          fill="url(#238paint1_linear)"
        />
        <path
          d="M30 58.0601C30 58.5792 30.4208 59 30.9399 59H33.059C33.5781 59 33.999 58.5792 33.999 58.0601V54H30V58.0601Z"
          fill="url(#238paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 34 44.8335)"
          fill="url(#238paint3_linear)"
        />
        <g filter="url(#238filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34 81.9995C34 80.8952 33.1048 80 32.0005 80C30.8962 80 30.001 80.8952 30.001 81.9995V84.1665H30.001V95H30V98.0005C30 99.1048 30.8952 100 31.9995 100C33.1038 100 33.999 99.1048 33.999 98.0005V95.8332H34V85H34V81.9995Z"
            fill="url(#238paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 34 84.1665)"
          fill="#ABABAB"
        />
        <path
          d="M34 80.9399C34 80.4208 33.5792 80 33.0601 80H30.941C30.4219 80 30.001 80.4208 30.001 80.9399V85H34V80.9399Z"
          fill="url(#238paint5_linear)"
        />
        <path
          d="M30 99.0601C30 99.5792 30.4208 100 30.9399 100H33.059C33.5781 100 33.999 99.5792 33.999 99.0601V95H30V99.0601Z"
          fill="url(#238paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 34 85.8335)"
          fill="url(#238paint7_linear)"
        />
        <g filter="url(#238filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M149 34H34V105H149V34ZM138 45H45V94H138V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M149 34H34V105H35V35H148V105H149V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="38.4868"
          y="38.53"
          width="106.026"
          height="61.9399"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#238filter3_i)">
          <rect
            x="45.47"
            y="45.47"
            width="92.0601"
            height="48.0601"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="140"
          y="63"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#238filter4_d)">
          <rect x="140.702" y="63.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M141.286 74.7956C141.798 75.4577 142.6 75.8841 143.502 75.8841C145.048 75.8841 146.302 74.6305 146.302 73.0841V66.4442C146.302 65.4557 145.79 64.5869 145.016 64.0886C145.382 64.5619 145.6 65.1556 145.6 65.8001V72.44C145.6 73.9864 144.347 75.24 142.8 75.24C142.242 75.24 141.722 75.0768 141.286 74.7956Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M143.502 63.644C141.956 63.644 140.702 64.8976 140.702 66.444V72.4397C140.702 72.4601 140.702 72.4805 140.703 72.5007V67.0236C140.703 65.4772 141.956 64.2236 143.503 64.2236C145.029 64.2236 146.269 65.4444 146.302 66.9627V66.444C146.302 64.8976 145.048 63.644 143.502 63.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#238filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M143.5 67C142.672 67 142 67.6716 142 68.5V68.7143V70V88.5C142 89.3284 142.672 90 143.5 90C144.328 90 145 89.3284 145 88.5V72V68.5L145 68.2857C145 67.5756 144.424 67 143.714 67C143.677 67 143.64 67.0012 143.604 67.0035C143.569 67.0012 143.535 67 143.5 67Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M46.5 92.3545L46.5 46.6455L135 69.5L46.5 92.3545Z" stroke="white" />
        <defs>
          <filter
            id="238filter0_d"
            x="28.1201"
            y="37.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="238filter1_d"
            x="28.1201"
            y="78.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="238filter2_d"
            x="29.3003"
            y="29.3003"
            width="126.279"
            height="82.2793"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="238filter3_i"
            x="45"
            y="45"
            width="93.9399"
            height="51.8198"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="238filter4_d"
            x="138.822"
            y="61.7642"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="238filter5_d"
            x="140.12"
            y="65.1201"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="238paint0_linear"
            x1="36.8"
            y1="56.5"
            x2="29.2"
            y2="56.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="238paint1_linear"
            x1="27.2018"
            y1="41.5"
            x2="34.7998"
            y2="41.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="238paint2_linear"
            x1="36.7982"
            y1="56.5"
            x2="29.2002"
            y2="56.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="238paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="238paint4_linear"
            x1="36.8"
            y1="97.5"
            x2="29.2"
            y2="97.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="238paint5_linear"
            x1="27.2018"
            y1="82.5"
            x2="34.7998"
            y2="82.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="238paint6_linear"
            x1="36.7982"
            y1="97.5"
            x2="29.2002"
            y2="97.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="238paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
