import React from 'react'
import { StageDescriptionBlockContentItemIcon } from 'src/react-app/views/calculator/styles'

export const SunProtection1 = () => {
  return (
    <StageDescriptionBlockContentItemIcon title="Для окон на теневой стороне">
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.6"
          d="M32.5 40H7.5C3.3575 40 0 36.6425 0 32.5V7.5C0 3.3575 3.3575 0 7.5 0H32.5C36.6425 0 40 3.3575 40 7.5V32.5C40 36.6425 36.6425 40 32.5 40Z"
          fill="#E0E3E9"
        />
        <path
          d="M20.9998 20.8462V23.5C20.9998 23.9599 20.6271 24.3337 20.166 24.3337C19.7061 24.3337 19.3323 23.9599 19.3323 23.5V20.8462C18.3649 20.5013 17.666 19.5849 17.666 18.5C17.666 17.1212 18.7872 16 20.166 16C21.5448 16 22.666 17.1212 22.666 18.5C22.666 19.5849 21.9672 20.5013 20.9998 20.8462Z"
          fill="#ECEEF2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 27.334C11 28.2529 11.748 29 12.667 29H25.333C26.252 29 27 28.2529 27 27.334V18.281V14.667C27 13.748 26.252 13 25.334 13H12.667C11.748 13 11 13.748 11 14.667V18.281V27.334ZM25.667 17.6141H12.334V14.667C12.334 14.4839 12.4829 14.334 12.667 14.334H25.334C25.5181 14.334 25.667 14.4829 25.667 14.667V17.6141ZM12.334 18.948V27.334C12.334 27.5181 12.4829 27.667 12.667 27.667H18.333V18.948H12.334ZM19.667 27.667H25.334C25.5181 27.667 25.667 27.5171 25.667 27.334V18.948H19.667V27.667Z"
          fill="#1C3775"
        />
        <mask
          id="path-5-outside-1"
          maskUnits="userSpaceOnUse"
          x="16.9004"
          y="8.89999"
          width="15"
          height="15"
          fill="black"
        >
          <rect fill="white" x="16.9004" y="8.89999" width="15" height="15" />
          <path d="M26.6998 16.5C26.6998 17.715 25.7148 18.7 24.4998 18.7C23.2848 18.7 22.2998 17.715 22.2998 16.5C22.2998 15.285 23.2848 14.3 24.4998 14.3C25.7148 14.3 26.6998 15.285 26.6998 16.5Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.4998 16.7C24.6102 16.7 24.6998 16.6105 24.6998 16.5C24.6998 16.3895 24.6102 16.3 24.4998 16.3C24.3893 16.3 24.2998 16.3895 24.2998 16.5C24.2998 16.6105 24.3893 16.7 24.4998 16.7ZM24.4998 18.7C25.7148 18.7 26.6998 17.715 26.6998 16.5C26.6998 15.285 25.7148 14.3 24.4998 14.3C23.2848 14.3 22.2998 15.285 22.2998 16.5C22.2998 17.715 23.2848 18.7 24.4998 18.7Z"
          />
          <path d="M19.0004 16.5C19.0004 16.1962 19.2466 15.95 19.5504 15.95H20.6504C20.9541 15.95 21.2004 16.1962 21.2004 16.5C21.2004 16.8037 20.9541 17.05 20.6504 17.05H19.5504C19.2466 17.05 19.0004 16.8037 19.0004 16.5Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.5504 15.85H20.6504C21.0094 15.85 21.3004 16.141 21.3004 16.5C21.3004 16.859 21.0094 17.15 20.6504 17.15H19.5504C19.1914 17.15 18.9004 16.859 18.9004 16.5C18.9004 16.141 19.1914 15.85 19.5504 15.85ZM19.5504 15.95C19.2466 15.95 19.0004 16.1962 19.0004 16.5C19.0004 16.8037 19.2466 17.05 19.5504 17.05H20.6504C20.9541 17.05 21.2004 16.8037 21.2004 16.5C21.2004 16.1962 20.9541 15.95 20.6504 15.95H19.5504Z"
          />
          <path d="M27.7998 16.5C27.7998 16.1962 28.046 15.95 28.3498 15.95H29.4498C29.7535 15.95 29.9998 16.1962 29.9998 16.5C29.9998 16.8037 29.7535 17.05 29.4498 17.05H28.3498C28.046 17.05 27.7998 16.8037 27.7998 16.5Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.3498 15.85H29.4498C29.8088 15.85 30.0998 16.141 30.0998 16.5C30.0998 16.859 29.8088 17.15 29.4498 17.15H28.3498C27.9908 17.15 27.6998 16.859 27.6998 16.5C27.6998 16.141 27.9908 15.85 28.3498 15.85ZM28.3498 15.95C28.046 15.95 27.7998 16.1962 27.7998 16.5C27.7998 16.8037 28.046 17.05 28.3498 17.05H29.4498C29.7535 17.05 29.9998 16.8037 29.9998 16.5C29.9998 16.1962 29.7535 15.95 29.4498 15.95H28.3498Z"
          />
          <path d="M20.6111 12.6109C20.8259 12.3961 21.1741 12.3961 21.3889 12.6109L22.1667 13.3887C22.3815 13.6035 22.3815 13.9518 22.1667 14.1666C21.9519 14.3813 21.6037 14.3813 21.3889 14.1666L20.6111 13.3887C20.3963 13.174 20.3963 12.8257 20.6111 12.6109Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.4596 12.5402L22.2374 13.318C22.4912 13.5719 22.4912 13.9834 22.2374 14.2373C21.9836 14.4911 21.572 14.4911 21.3182 14.2373L20.5404 13.4595C20.2865 13.2056 20.2865 12.7941 20.5404 12.5402C20.7942 12.2864 21.2058 12.2864 21.4596 12.5402ZM21.3889 12.6109C21.1741 12.3961 20.8259 12.3961 20.6111 12.6109C20.3963 12.8257 20.3963 13.174 20.6111 13.3887L21.3889 14.1666C21.6037 14.3813 21.9519 14.3813 22.1667 14.1666C22.3815 13.9518 22.3815 13.6035 22.1667 13.3887L21.3889 12.6109Z"
          />
          <path d="M26.8339 18.8335C27.0486 18.6187 27.3969 18.6187 27.6117 18.8335L28.3895 19.6113C28.6043 19.8261 28.6043 20.1743 28.3895 20.3891C28.1747 20.6039 27.8265 20.6039 27.6117 20.3891L26.8339 19.6113C26.6191 19.3965 26.6191 19.0482 26.8339 18.8335Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.6824 18.7627L28.4602 19.5406C28.714 19.7944 28.714 20.206 28.4602 20.4598C28.2064 20.7136 27.7948 20.7136 27.541 20.4598L26.7631 19.682C26.5093 19.4281 26.5093 19.0166 26.7631 18.7627C27.017 18.5089 27.4285 18.5089 27.6824 18.7627ZM27.6117 18.8335C27.3969 18.6187 27.0486 18.6187 26.8339 18.8335C26.6191 19.0482 26.6191 19.3965 26.8339 19.6113L27.6117 20.3891C27.8265 20.6039 28.1747 20.6039 28.3895 20.3891C28.6043 20.1743 28.6043 19.8261 28.3895 19.6113L27.6117 18.8335Z"
          />
          <path d="M28.3899 12.611C28.6047 12.8258 28.6047 13.174 28.3899 13.3888L27.6121 14.1666C27.3973 14.3814 27.0491 14.3814 26.8343 14.1666C26.6195 13.9518 26.6195 13.6036 26.8343 13.3888L27.6121 12.611C27.8269 12.3962 28.1752 12.3962 28.3899 12.611Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.4607 13.4595L27.6828 14.2373C27.429 14.4912 27.0174 14.4912 26.7636 14.2373C26.5098 13.9835 26.5098 13.5719 26.7636 13.3181L27.5414 12.5403C27.7953 12.2865 28.2068 12.2865 28.4607 12.5403C28.7145 12.7941 28.7145 13.2057 28.4607 13.4595ZM28.3899 13.3888C28.6047 13.174 28.6047 12.8258 28.3899 12.611C28.1752 12.3962 27.8269 12.3962 27.6121 12.611L26.8343 13.3888C26.6195 13.6036 26.6195 13.9518 26.8343 14.1666C27.0491 14.3814 27.3973 14.3814 27.6121 14.1666L28.3899 13.3888Z"
          />
          <path d="M22.1671 18.8329C22.3819 19.0477 22.3819 19.3959 22.1671 19.6107L21.3893 20.3885C21.1745 20.6033 20.8263 20.6033 20.6115 20.3885C20.3967 20.1737 20.3967 19.8255 20.6115 19.6107L21.3893 18.8329C21.6041 18.6181 21.9524 18.6181 22.1671 18.8329Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.2379 19.6814L21.46 20.4592C21.2062 20.7131 20.7946 20.7131 20.5408 20.4592C20.287 20.2054 20.287 19.7938 20.5408 19.54L21.3186 18.7622C21.5725 18.5083 21.984 18.5083 22.2379 18.7622C22.4917 19.016 22.4917 19.4276 22.2379 19.6814ZM22.1671 19.6107C22.3819 19.3959 22.3819 19.0477 22.1671 18.8329C21.9524 18.6181 21.6041 18.6181 21.3893 18.8329L20.6115 19.6107C20.3967 19.8255 20.3967 20.1737 20.6115 20.3885C20.8263 20.6033 21.1745 20.6033 21.3893 20.3885L22.1671 19.6107Z"
          />
          <path d="M24.4998 11C24.8035 11 25.0498 11.2462 25.0498 11.55V12.65C25.0498 12.9538 24.8035 13.2 24.4998 13.2C24.196 13.2 23.9498 12.9538 23.9498 12.65V11.55C23.9498 11.2462 24.196 11 24.4998 11Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.1498 11.55V12.65C25.1498 13.009 24.8588 13.3 24.4998 13.3C24.1408 13.3 23.8498 13.009 23.8498 12.65V11.55C23.8498 11.191 24.1408 10.9 24.4998 10.9C24.8588 10.9 25.1498 11.191 25.1498 11.55ZM25.0498 11.55C25.0498 11.2462 24.8035 11 24.4998 11C24.196 11 23.9498 11.2462 23.9498 11.55V12.65C23.9498 12.9538 24.196 13.2 24.4998 13.2C24.8035 13.2 25.0498 12.9538 25.0498 12.65V11.55Z"
          />
          <path d="M24.4998 19.8C24.8035 19.8 25.0498 20.0462 25.0498 20.35V21.45C25.0498 21.7538 24.8035 22 24.4998 22C24.196 22 23.9498 21.7538 23.9498 21.45V20.35C23.9498 20.0462 24.196 19.8 24.4998 19.8Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.1498 20.35V21.45C25.1498 21.809 24.8588 22.1 24.4998 22.1C24.1408 22.1 23.8498 21.809 23.8498 21.45V20.35C23.8498 19.991 24.1408 19.7 24.4998 19.7C24.8588 19.7 25.1498 19.991 25.1498 20.35ZM25.0498 20.35C25.0498 20.0462 24.8035 19.8 24.4998 19.8C24.196 19.8 23.9498 20.0462 23.9498 20.35V21.45C23.9498 21.7538 24.196 22 24.4998 22C24.8035 22 25.0498 21.7538 25.0498 21.45V20.35Z"
          />
        </mask>
        <path
          d="M26.6998 16.5C26.6998 17.715 25.7148 18.7 24.4998 18.7C23.2848 18.7 22.2998 17.715 22.2998 16.5C22.2998 15.285 23.2848 14.3 24.4998 14.3C25.7148 14.3 26.6998 15.285 26.6998 16.5Z"
          fill="#1C3775"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.4998 16.7C24.6102 16.7 24.6998 16.6105 24.6998 16.5C24.6998 16.3895 24.6102 16.3 24.4998 16.3C24.3893 16.3 24.2998 16.3895 24.2998 16.5C24.2998 16.6105 24.3893 16.7 24.4998 16.7ZM24.4998 18.7C25.7148 18.7 26.6998 17.715 26.6998 16.5C26.6998 15.285 25.7148 14.3 24.4998 14.3C23.2848 14.3 22.2998 15.285 22.2998 16.5C22.2998 17.715 23.2848 18.7 24.4998 18.7Z"
          fill="#1C3775"
        />
        <path
          d="M19.0004 16.5C19.0004 16.1962 19.2466 15.95 19.5504 15.95H20.6504C20.9541 15.95 21.2004 16.1962 21.2004 16.5C21.2004 16.8037 20.9541 17.05 20.6504 17.05H19.5504C19.2466 17.05 19.0004 16.8037 19.0004 16.5Z"
          fill="#1C3775"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.5504 15.85H20.6504C21.0094 15.85 21.3004 16.141 21.3004 16.5C21.3004 16.859 21.0094 17.15 20.6504 17.15H19.5504C19.1914 17.15 18.9004 16.859 18.9004 16.5C18.9004 16.141 19.1914 15.85 19.5504 15.85ZM19.5504 15.95C19.2466 15.95 19.0004 16.1962 19.0004 16.5C19.0004 16.8037 19.2466 17.05 19.5504 17.05H20.6504C20.9541 17.05 21.2004 16.8037 21.2004 16.5C21.2004 16.1962 20.9541 15.95 20.6504 15.95H19.5504Z"
          fill="#1C3775"
        />
        <path
          d="M27.7998 16.5C27.7998 16.1962 28.046 15.95 28.3498 15.95H29.4498C29.7535 15.95 29.9998 16.1962 29.9998 16.5C29.9998 16.8037 29.7535 17.05 29.4498 17.05H28.3498C28.046 17.05 27.7998 16.8037 27.7998 16.5Z"
          fill="#1C3775"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.3498 15.85H29.4498C29.8088 15.85 30.0998 16.141 30.0998 16.5C30.0998 16.859 29.8088 17.15 29.4498 17.15H28.3498C27.9908 17.15 27.6998 16.859 27.6998 16.5C27.6998 16.141 27.9908 15.85 28.3498 15.85ZM28.3498 15.95C28.046 15.95 27.7998 16.1962 27.7998 16.5C27.7998 16.8037 28.046 17.05 28.3498 17.05H29.4498C29.7535 17.05 29.9998 16.8037 29.9998 16.5C29.9998 16.1962 29.7535 15.95 29.4498 15.95H28.3498Z"
          fill="#1C3775"
        />
        <path
          d="M20.6111 12.6109C20.8259 12.3961 21.1741 12.3961 21.3889 12.6109L22.1667 13.3887C22.3815 13.6035 22.3815 13.9518 22.1667 14.1666C21.9519 14.3813 21.6037 14.3813 21.3889 14.1666L20.6111 13.3887C20.3963 13.174 20.3963 12.8257 20.6111 12.6109Z"
          fill="#1C3775"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.4596 12.5402L22.2374 13.318C22.4912 13.5719 22.4912 13.9834 22.2374 14.2373C21.9836 14.4911 21.572 14.4911 21.3182 14.2373L20.5404 13.4595C20.2865 13.2056 20.2865 12.7941 20.5404 12.5402C20.7942 12.2864 21.2058 12.2864 21.4596 12.5402ZM21.3889 12.6109C21.1741 12.3961 20.8259 12.3961 20.6111 12.6109C20.3963 12.8257 20.3963 13.174 20.6111 13.3887L21.3889 14.1666C21.6037 14.3813 21.9519 14.3813 22.1667 14.1666C22.3815 13.9518 22.3815 13.6035 22.1667 13.3887L21.3889 12.6109Z"
          fill="#1C3775"
        />
        <path
          d="M26.8339 18.8335C27.0486 18.6187 27.3969 18.6187 27.6117 18.8335L28.3895 19.6113C28.6043 19.8261 28.6043 20.1743 28.3895 20.3891C28.1747 20.6039 27.8265 20.6039 27.6117 20.3891L26.8339 19.6113C26.6191 19.3965 26.6191 19.0482 26.8339 18.8335Z"
          fill="#1C3775"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.6824 18.7627L28.4602 19.5406C28.714 19.7944 28.714 20.206 28.4602 20.4598C28.2064 20.7136 27.7948 20.7136 27.541 20.4598L26.7631 19.682C26.5093 19.4281 26.5093 19.0166 26.7631 18.7627C27.017 18.5089 27.4285 18.5089 27.6824 18.7627ZM27.6117 18.8335C27.3969 18.6187 27.0486 18.6187 26.8339 18.8335C26.6191 19.0482 26.6191 19.3965 26.8339 19.6113L27.6117 20.3891C27.8265 20.6039 28.1747 20.6039 28.3895 20.3891C28.6043 20.1743 28.6043 19.8261 28.3895 19.6113L27.6117 18.8335Z"
          fill="#1C3775"
        />
        <path
          d="M28.3899 12.611C28.6047 12.8258 28.6047 13.174 28.3899 13.3888L27.6121 14.1666C27.3973 14.3814 27.0491 14.3814 26.8343 14.1666C26.6195 13.9518 26.6195 13.6036 26.8343 13.3888L27.6121 12.611C27.8269 12.3962 28.1752 12.3962 28.3899 12.611Z"
          fill="#1C3775"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.4607 13.4595L27.6828 14.2373C27.429 14.4912 27.0174 14.4912 26.7636 14.2373C26.5098 13.9835 26.5098 13.5719 26.7636 13.3181L27.5414 12.5403C27.7953 12.2865 28.2068 12.2865 28.4607 12.5403C28.7145 12.7941 28.7145 13.2057 28.4607 13.4595ZM28.3899 13.3888C28.6047 13.174 28.6047 12.8258 28.3899 12.611C28.1752 12.3962 27.8269 12.3962 27.6121 12.611L26.8343 13.3888C26.6195 13.6036 26.6195 13.9518 26.8343 14.1666C27.0491 14.3814 27.3973 14.3814 27.6121 14.1666L28.3899 13.3888Z"
          fill="#1C3775"
        />
        <path
          d="M22.1671 18.8329C22.3819 19.0477 22.3819 19.3959 22.1671 19.6107L21.3893 20.3885C21.1745 20.6033 20.8263 20.6033 20.6115 20.3885C20.3967 20.1737 20.3967 19.8255 20.6115 19.6107L21.3893 18.8329C21.6041 18.6181 21.9524 18.6181 22.1671 18.8329Z"
          fill="#1C3775"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.2379 19.6814L21.46 20.4592C21.2062 20.7131 20.7946 20.7131 20.5408 20.4592C20.287 20.2054 20.287 19.7938 20.5408 19.54L21.3186 18.7622C21.5725 18.5083 21.984 18.5083 22.2379 18.7622C22.4917 19.016 22.4917 19.4276 22.2379 19.6814ZM22.1671 19.6107C22.3819 19.3959 22.3819 19.0477 22.1671 18.8329C21.9524 18.6181 21.6041 18.6181 21.3893 18.8329L20.6115 19.6107C20.3967 19.8255 20.3967 20.1737 20.6115 20.3885C20.8263 20.6033 21.1745 20.6033 21.3893 20.3885L22.1671 19.6107Z"
          fill="#1C3775"
        />
        <path
          d="M24.4998 11C24.8035 11 25.0498 11.2462 25.0498 11.55V12.65C25.0498 12.9538 24.8035 13.2 24.4998 13.2C24.196 13.2 23.9498 12.9538 23.9498 12.65V11.55C23.9498 11.2462 24.196 11 24.4998 11Z"
          fill="#1C3775"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.1498 11.55V12.65C25.1498 13.009 24.8588 13.3 24.4998 13.3C24.1408 13.3 23.8498 13.009 23.8498 12.65V11.55C23.8498 11.191 24.1408 10.9 24.4998 10.9C24.8588 10.9 25.1498 11.191 25.1498 11.55ZM25.0498 11.55C25.0498 11.2462 24.8035 11 24.4998 11C24.196 11 23.9498 11.2462 23.9498 11.55V12.65C23.9498 12.9538 24.196 13.2 24.4998 13.2C24.8035 13.2 25.0498 12.9538 25.0498 12.65V11.55Z"
          fill="#1C3775"
        />
        <path
          d="M24.4998 19.8C24.8035 19.8 25.0498 20.0462 25.0498 20.35V21.45C25.0498 21.7538 24.8035 22 24.4998 22C24.196 22 23.9498 21.7538 23.9498 21.45V20.35C23.9498 20.0462 24.196 19.8 24.4998 19.8Z"
          fill="#1C3775"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.1498 20.35V21.45C25.1498 21.809 24.8588 22.1 24.4998 22.1C24.1408 22.1 23.8498 21.809 23.8498 21.45V20.35C23.8498 19.991 24.1408 19.7 24.4998 19.7C24.8588 19.7 25.1498 19.991 25.1498 20.35ZM25.0498 20.35C25.0498 20.0462 24.8035 19.8 24.4998 19.8C24.196 19.8 23.9498 20.0462 23.9498 20.35V21.45C23.9498 21.7538 24.196 22 24.4998 22C24.8035 22 25.0498 21.7538 25.0498 21.45V20.35Z"
          fill="#1C3775"
        />
        <path
          d="M26.6998 16.5C26.6998 17.715 25.7148 18.7 24.4998 18.7C23.2848 18.7 22.2998 17.715 22.2998 16.5C22.2998 15.285 23.2848 14.3 24.4998 14.3C25.7148 14.3 26.6998 15.285 26.6998 16.5Z"
          stroke="#ECEEF2"
          strokeWidth="3"
          strokeLinecap="round"
          mask="url(#path-5-outside-1)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.4998 16.7C24.6102 16.7 24.6998 16.6105 24.6998 16.5C24.6998 16.3895 24.6102 16.3 24.4998 16.3C24.3893 16.3 24.2998 16.3895 24.2998 16.5C24.2998 16.6105 24.3893 16.7 24.4998 16.7ZM24.4998 18.7C25.7148 18.7 26.6998 17.715 26.6998 16.5C26.6998 15.285 25.7148 14.3 24.4998 14.3C23.2848 14.3 22.2998 15.285 22.2998 16.5C22.2998 17.715 23.2848 18.7 24.4998 18.7Z"
          stroke="#ECEEF2"
          strokeWidth="3"
          strokeLinecap="round"
          mask="url(#path-5-outside-1)"
        />
        <path
          d="M19.0004 16.5C19.0004 16.1962 19.2466 15.95 19.5504 15.95H20.6504C20.9541 15.95 21.2004 16.1962 21.2004 16.5C21.2004 16.8037 20.9541 17.05 20.6504 17.05H19.5504C19.2466 17.05 19.0004 16.8037 19.0004 16.5Z"
          stroke="#ECEEF2"
          strokeWidth="3"
          strokeLinecap="round"
          mask="url(#path-5-outside-1)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.5504 15.85H20.6504C21.0094 15.85 21.3004 16.141 21.3004 16.5C21.3004 16.859 21.0094 17.15 20.6504 17.15H19.5504C19.1914 17.15 18.9004 16.859 18.9004 16.5C18.9004 16.141 19.1914 15.85 19.5504 15.85ZM19.5504 15.95C19.2466 15.95 19.0004 16.1962 19.0004 16.5C19.0004 16.8037 19.2466 17.05 19.5504 17.05H20.6504C20.9541 17.05 21.2004 16.8037 21.2004 16.5C21.2004 16.1962 20.9541 15.95 20.6504 15.95H19.5504Z"
          stroke="#ECEEF2"
          strokeWidth="3"
          strokeLinecap="round"
          mask="url(#path-5-outside-1)"
        />
        <path
          d="M27.7998 16.5C27.7998 16.1962 28.046 15.95 28.3498 15.95H29.4498C29.7535 15.95 29.9998 16.1962 29.9998 16.5C29.9998 16.8037 29.7535 17.05 29.4498 17.05H28.3498C28.046 17.05 27.7998 16.8037 27.7998 16.5Z"
          stroke="#ECEEF2"
          strokeWidth="3"
          strokeLinecap="round"
          mask="url(#path-5-outside-1)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.3498 15.85H29.4498C29.8088 15.85 30.0998 16.141 30.0998 16.5C30.0998 16.859 29.8088 17.15 29.4498 17.15H28.3498C27.9908 17.15 27.6998 16.859 27.6998 16.5C27.6998 16.141 27.9908 15.85 28.3498 15.85ZM28.3498 15.95C28.046 15.95 27.7998 16.1962 27.7998 16.5C27.7998 16.8037 28.046 17.05 28.3498 17.05H29.4498C29.7535 17.05 29.9998 16.8037 29.9998 16.5C29.9998 16.1962 29.7535 15.95 29.4498 15.95H28.3498Z"
          stroke="#ECEEF2"
          strokeWidth="3"
          strokeLinecap="round"
          mask="url(#path-5-outside-1)"
        />
        <path
          d="M20.6111 12.6109C20.8259 12.3961 21.1741 12.3961 21.3889 12.6109L22.1667 13.3887C22.3815 13.6035 22.3815 13.9518 22.1667 14.1666C21.9519 14.3813 21.6037 14.3813 21.3889 14.1666L20.6111 13.3887C20.3963 13.174 20.3963 12.8257 20.6111 12.6109Z"
          stroke="#ECEEF2"
          strokeWidth="3"
          strokeLinecap="round"
          mask="url(#path-5-outside-1)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.4596 12.5402L22.2374 13.318C22.4912 13.5719 22.4912 13.9834 22.2374 14.2373C21.9836 14.4911 21.572 14.4911 21.3182 14.2373L20.5404 13.4595C20.2865 13.2056 20.2865 12.7941 20.5404 12.5402C20.7942 12.2864 21.2058 12.2864 21.4596 12.5402ZM21.3889 12.6109C21.1741 12.3961 20.8259 12.3961 20.6111 12.6109C20.3963 12.8257 20.3963 13.174 20.6111 13.3887L21.3889 14.1666C21.6037 14.3813 21.9519 14.3813 22.1667 14.1666C22.3815 13.9518 22.3815 13.6035 22.1667 13.3887L21.3889 12.6109Z"
          stroke="#ECEEF2"
          strokeWidth="3"
          strokeLinecap="round"
          mask="url(#path-5-outside-1)"
        />
        <path
          d="M26.8339 18.8335C27.0486 18.6187 27.3969 18.6187 27.6117 18.8335L28.3895 19.6113C28.6043 19.8261 28.6043 20.1743 28.3895 20.3891C28.1747 20.6039 27.8265 20.6039 27.6117 20.3891L26.8339 19.6113C26.6191 19.3965 26.6191 19.0482 26.8339 18.8335Z"
          stroke="#ECEEF2"
          strokeWidth="3"
          strokeLinecap="round"
          mask="url(#path-5-outside-1)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.6824 18.7627L28.4602 19.5406C28.714 19.7944 28.714 20.206 28.4602 20.4598C28.2064 20.7136 27.7948 20.7136 27.541 20.4598L26.7631 19.682C26.5093 19.4281 26.5093 19.0166 26.7631 18.7627C27.017 18.5089 27.4285 18.5089 27.6824 18.7627ZM27.6117 18.8335C27.3969 18.6187 27.0486 18.6187 26.8339 18.8335C26.6191 19.0482 26.6191 19.3965 26.8339 19.6113L27.6117 20.3891C27.8265 20.6039 28.1747 20.6039 28.3895 20.3891C28.6043 20.1743 28.6043 19.8261 28.3895 19.6113L27.6117 18.8335Z"
          stroke="#ECEEF2"
          strokeWidth="3"
          strokeLinecap="round"
          mask="url(#path-5-outside-1)"
        />
        <path
          d="M28.3899 12.611C28.6047 12.8258 28.6047 13.174 28.3899 13.3888L27.6121 14.1666C27.3973 14.3814 27.0491 14.3814 26.8343 14.1666C26.6195 13.9518 26.6195 13.6036 26.8343 13.3888L27.6121 12.611C27.8269 12.3962 28.1752 12.3962 28.3899 12.611Z"
          stroke="#ECEEF2"
          strokeWidth="3"
          strokeLinecap="round"
          mask="url(#path-5-outside-1)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.4607 13.4595L27.6828 14.2373C27.429 14.4912 27.0174 14.4912 26.7636 14.2373C26.5098 13.9835 26.5098 13.5719 26.7636 13.3181L27.5414 12.5403C27.7953 12.2865 28.2068 12.2865 28.4607 12.5403C28.7145 12.7941 28.7145 13.2057 28.4607 13.4595ZM28.3899 13.3888C28.6047 13.174 28.6047 12.8258 28.3899 12.611C28.1752 12.3962 27.8269 12.3962 27.6121 12.611L26.8343 13.3888C26.6195 13.6036 26.6195 13.9518 26.8343 14.1666C27.0491 14.3814 27.3973 14.3814 27.6121 14.1666L28.3899 13.3888Z"
          stroke="#ECEEF2"
          strokeWidth="3"
          strokeLinecap="round"
          mask="url(#path-5-outside-1)"
        />
        <path
          d="M22.1671 18.8329C22.3819 19.0477 22.3819 19.3959 22.1671 19.6107L21.3893 20.3885C21.1745 20.6033 20.8263 20.6033 20.6115 20.3885C20.3967 20.1737 20.3967 19.8255 20.6115 19.6107L21.3893 18.8329C21.6041 18.6181 21.9524 18.6181 22.1671 18.8329Z"
          stroke="#ECEEF2"
          strokeWidth="3"
          strokeLinecap="round"
          mask="url(#path-5-outside-1)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.2379 19.6814L21.46 20.4592C21.2062 20.7131 20.7946 20.7131 20.5408 20.4592C20.287 20.2054 20.287 19.7938 20.5408 19.54L21.3186 18.7622C21.5725 18.5083 21.984 18.5083 22.2379 18.7622C22.4917 19.016 22.4917 19.4276 22.2379 19.6814ZM22.1671 19.6107C22.3819 19.3959 22.3819 19.0477 22.1671 18.8329C21.9524 18.6181 21.6041 18.6181 21.3893 18.8329L20.6115 19.6107C20.3967 19.8255 20.3967 20.1737 20.6115 20.3885C20.8263 20.6033 21.1745 20.6033 21.3893 20.3885L22.1671 19.6107Z"
          stroke="#ECEEF2"
          strokeWidth="3"
          strokeLinecap="round"
          mask="url(#path-5-outside-1)"
        />
        <path
          d="M24.4998 11C24.8035 11 25.0498 11.2462 25.0498 11.55V12.65C25.0498 12.9538 24.8035 13.2 24.4998 13.2C24.196 13.2 23.9498 12.9538 23.9498 12.65V11.55C23.9498 11.2462 24.196 11 24.4998 11Z"
          stroke="#ECEEF2"
          strokeWidth="3"
          strokeLinecap="round"
          mask="url(#path-5-outside-1)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.1498 11.55V12.65C25.1498 13.009 24.8588 13.3 24.4998 13.3C24.1408 13.3 23.8498 13.009 23.8498 12.65V11.55C23.8498 11.191 24.1408 10.9 24.4998 10.9C24.8588 10.9 25.1498 11.191 25.1498 11.55ZM25.0498 11.55C25.0498 11.2462 24.8035 11 24.4998 11C24.196 11 23.9498 11.2462 23.9498 11.55V12.65C23.9498 12.9538 24.196 13.2 24.4998 13.2C24.8035 13.2 25.0498 12.9538 25.0498 12.65V11.55Z"
          stroke="#ECEEF2"
          strokeWidth="3"
          strokeLinecap="round"
          mask="url(#path-5-outside-1)"
        />
        <path
          d="M24.4998 19.8C24.8035 19.8 25.0498 20.0462 25.0498 20.35V21.45C25.0498 21.7538 24.8035 22 24.4998 22C24.196 22 23.9498 21.7538 23.9498 21.45V20.35C23.9498 20.0462 24.196 19.8 24.4998 19.8Z"
          stroke="#ECEEF2"
          strokeWidth="3"
          strokeLinecap="round"
          mask="url(#path-5-outside-1)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.1498 20.35V21.45C25.1498 21.809 24.8588 22.1 24.4998 22.1C24.1408 22.1 23.8498 21.809 23.8498 21.45V20.35C23.8498 19.991 24.1408 19.7 24.4998 19.7C24.8588 19.7 25.1498 19.991 25.1498 20.35ZM25.0498 20.35C25.0498 20.0462 24.8035 19.8 24.4998 19.8C24.196 19.8 23.9498 20.0462 23.9498 20.35V21.45C23.9498 21.7538 24.196 22 24.4998 22C24.8035 22 25.0498 21.7538 25.0498 21.45V20.35Z"
          stroke="#ECEEF2"
          strokeWidth="3"
          strokeLinecap="round"
          mask="url(#path-5-outside-1)"
        />
      </svg>
    </StageDescriptionBlockContentItemIcon>
  )
}
