import React, { FC, useCallback, useState } from 'react'

import { Input, ControlGroup } from '../../../components/input'
import { ButtonViews, InputTypes } from '../interface'
import { Button } from '../../../components'
import { ButtonWrapper } from './styles'
import { IRepairFormProps } from './interface'
import { validateEmail } from '../../../utilits/utilits'
import { ModalSubTitle, ModalTitle } from '../styles'

export const RepairForm: FC<IRepairFormProps> = props => {
  const { onRepair } = props
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')

  const handleEmailChange = useCallback(value => {
    setEmail(value)
  }, [])

  const handleRepair = useCallback(() => {
    const validError = validateEmail(email)

    if (validError) {
      setError(validError)
    } else {
      onRepair(email)
    }
  }, [email, onRepair])

  return (
    <>
      <ModalTitle>&nbsp;</ModalTitle>
      <ModalSubTitle>
        Введите свой e-mail и мы отправим вам информацию для восстановления пароля
      </ModalSubTitle>
      <ControlGroup>
        <Input
          placeholder="Введите e-mail"
          type={InputTypes.email}
          onChange={handleEmailChange}
          errorMessage={error}
        />
      </ControlGroup>
      <ButtonWrapper>
        <Button typeView={ButtonViews.ORANGE} onClick={handleRepair} disabled={!email}>
          Отправить
        </Button>
      </ButtonWrapper>
    </>
  )
}
