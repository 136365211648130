import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement31Square4BottomCasement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#3-1_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M421 47.9995C421 46.8952 420.105 46 419.001 46C417.896 46 417.001 46.8952 417.001 47.9995V50.1665H417.001V61H417V64.0005C417 65.1048 417.895 66 418.999 66C420.104 66 420.999 65.1048 420.999 64.0005V61.8332H421V51H421V47.9995Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 421 50.1665)"
          fill="#ABABAB"
        />
        <path
          d="M421 46.9399C421 46.4208 420.579 46 420.06 46H417.941C417.422 46 417.001 46.4208 417.001 46.9399V51H421V46.9399Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M417 65.0601C417 65.5792 417.421 66 417.94 66H420.059C420.578 66 420.999 65.5792 420.999 65.0601V61H417V65.0601Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 421 51.8335)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#3-1_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M421 162.999C421 161.895 420.105 161 419.001 161C417.896 161 417.001 161.895 417.001 162.999V165.167H417.001V176H417V179.001C417 180.105 417.895 181 418.999 181C420.104 181 420.999 180.105 420.999 179.001V176.833H421V166H421V162.999Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(-1 0 0 1 421 165.167)"
          fill="#ABABAB"
        />
        <path
          d="M421 161.94C421 161.421 420.579 161 420.06 161H417.941C417.422 161 417.001 161.421 417.001 161.94V166H421V161.94Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M417 180.06C417 180.579 417.421 181 417.94 181H420.059C420.578 181 420.999 180.579 420.999 180.06V176H417V180.06Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(-1 0 0 1 421 166.833)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#3-1_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M417 34H297V193H417V34ZM406 45H308V182H406V45Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M417 34H297V193H298V35H416V193H417V34Z"
          fill="#FBFBFB"
        />
        <rect
          x="301.53"
          y="38.53"
          width="110.94"
          height="149.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <g filter="url(#3-1_filter3_i)">
          <rect
            x="308.47"
            y="45.47"
            width="97.0601"
            height="136.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="299"
          y="108"
          width="7"
          height="13.5283"
          rx="3.5"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#3-1_filter4_d)">
          <rect x="299.702" y="108.644" width="5.6" height="12.2399" rx="2.8" fill="#E8EAEE" />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M300.286 119.795C300.798 120.457 301.6 120.884 302.502 120.884C304.049 120.884 305.302 119.63 305.302 118.084V111.444C305.302 110.455 304.79 109.587 304.017 109.088C304.383 109.562 304.601 110.155 304.601 110.8V117.44C304.601 118.986 303.347 120.24 301.801 120.24C301.243 120.24 300.723 120.077 300.286 119.795Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M302.502 108.644C300.956 108.644 299.702 109.898 299.702 111.444V117.44C299.702 117.46 299.702 117.48 299.703 117.501V112.024C299.703 110.477 300.956 109.224 302.503 109.224C304.029 109.224 305.27 110.444 305.302 111.963V111.444C305.302 109.898 304.049 108.644 302.502 108.644Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-1_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M302.5 112C301.672 112 301 112.672 301 113.5V113.714V115V133.5C301 134.328 301.672 135 302.5 135C303.328 135 304 134.328 304 133.5V117V113.5L304 113.286C304 112.576 303.424 112 302.714 112C302.677 112 302.64 112.001 302.604 112.004C302.569 112.001 302.535 112 302.5 112Z"
            fill="#E8EAEE"
          />
        </g>
        <path d="M404.5 46.9571L404.5 180.043L308.875 113.5L404.5 46.9571Z" stroke="white" />
        <defs>
          <filter
            id="3-1_filter0_d"
            x="415.12"
            y="44.1201"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-1_filter1_d"
            x="415.12"
            y="159.12"
            width="9.63962"
            height="25.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-1_filter2_d"
            x="292.3"
            y="29.3003"
            width="131.279"
            height="170.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-1_filter3_i"
            x="308"
            y="45"
            width="98.9399"
            height="139.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-1_filter4_d"
            x="297.822"
            y="106.764"
            width="11.2396"
            height="17.8795"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-1_filter5_d"
            x="299.12"
            y="110.12"
            width="8.63964"
            height="28.6396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="423.8"
            y1="63.5"
            x2="416.2"
            y2="63.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="414.202"
            y1="48.5"
            x2="421.8"
            y2="48.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="423.798"
            y1="63.5"
            x2="416.2"
            y2="63.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="423.8"
            y1="178.5"
            x2="416.2"
            y2="178.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="414.202"
            y1="163.5"
            x2="421.8"
            y2="163.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="423.798"
            y1="178.5"
            x2="416.2"
            y2="178.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
