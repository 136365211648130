import React, { FC, useCallback, useEffect, useState } from 'react'

import { IAlertProps } from '../../views/calculator/interface'
import { AlertWrapper, CloseButton } from './styles'
import { CloseIcon } from '../../assets/icons'

export const Alert: FC<IAlertProps> = props => {
  const { children, initHideState, style } = props

  const [hide, setHide] = useState(false)
  const [immediatelyHide, setImmediatelyHide] = useState(false)

  useEffect(() => {
    // eslint-disable-next-line no-undef
    /* const alreadyClose = localStorage.getItem('main-banner-close') */

    if (typeof initHideState === 'boolean') {
      setHide(initHideState)
    } /* else if (alreadyClose === 'true') {
      setHide(true)
      setImmediatelyHide(true)
    } */
  }, [initHideState])

  const handleClose = useCallback(() => {
    setHide(true)
    // eslint-disable-next-line no-undef
    localStorage.setItem('main-banner-close', 'true')
  }, [])

  return (
    <>
      {!immediatelyHide && (
        <AlertWrapper hide={hide} style={style}>
          {children}
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
        </AlertWrapper>
      )}
    </>
  )
}
