import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement43Square54Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#4-3_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M291.001 291C292.105 291 293 290.105 293 289.001C293 287.896 292.105 287.001 291.001 287.001L288.833 287.001L288.833 287.001L278 287.001L278 287L274.999 287C273.895 287 273 287.895 273 288.999C273 290.104 273.895 290.999 274.999 290.999L277.167 290.999L277.167 291L288 291L288 291L291.001 291Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 288.833 291)"
          fill="#ABABAB"
        />
        <path
          d="M292.06 291C292.579 291 293 290.579 293 290.06L293 287.941C293 287.422 292.579 287.001 292.06 287.001L288 287.001L288 291L292.06 291Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M273.94 287C273.421 287 273 287.421 273 287.94L273 290.059C273 290.578 273.421 290.999 273.94 290.999L278 290.999L278 287L273.94 287Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 287.167 291)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#4-3_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M331.001 291C332.105 291 333 290.105 333 289.001C333 287.896 332.105 287.001 331.001 287.001L328.833 287.001L328.833 287.001L318 287.001L318 287L314.999 287C313.895 287 313 287.895 313 288.999C313 290.104 313.895 290.999 314.999 290.999L317.167 290.999L317.167 291L328 291L328 291L331.001 291Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 328.833 291)"
          fill="#ABABAB"
        />
        <path
          d="M332.06 291C332.579 291 333 290.579 333 290.06L333 287.941C333 287.422 332.579 287.001 332.06 287.001L328 287.001L328 291L332.06 291Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M313.94 287C313.421 287 313 287.421 313 287.94L313 290.059C313 290.578 313.421 290.999 313.94 290.999L318 290.999L318 287L313.94 287Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 327.167 291)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#4-3_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M337 34H269V287H337V34ZM327 44H279V277H327V44Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M337 34H269V287H269.932V35H336.068V287H337V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-3_filter3_i)">
          <rect
            x="279.47"
            y="44.47"
            width="47.0601"
            height="232.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="273.53"
          y="38.53"
          width="58.9399"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M325.448 275.5L303 50.0466L280.552 275.5L325.448 275.5Z" stroke="white" />
        <rect
          x="309.528"
          y="36"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 309.528 36)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#4-3_filter4_d)">
          <rect
            x="308.884"
            y="36.7017"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 308.884 36.7017)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M308.766 38.6948C308.42 37.5418 307.35 36.7017 306.084 36.7017L299.444 36.7017C297.898 36.7017 296.644 37.9553 296.644 39.5017C296.644 39.5368 296.645 39.5718 296.646 39.6067C296.993 40.7598 298.062 41.5998 299.328 41.5998L305.968 41.5998C307.515 41.5998 308.768 40.3462 308.768 38.7998C308.768 38.7647 308.768 38.7297 308.766 38.6948Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M307.409 37.0344C307.266 37.0117 307.118 36.9998 306.968 36.9998L300.328 36.9998C298.782 36.9998 297.528 38.2534 297.528 39.7998C297.528 40.8669 298.125 41.7946 299.003 42.2671C297.666 42.0556 296.644 40.898 296.644 39.5017C296.644 37.9553 297.898 36.7017 299.444 36.7017L306.084 36.7017C306.564 36.7017 307.015 36.8221 307.409 37.0344Z"
          fill="#FBFBFB"
        />
        <g filter="url(#4-3_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M322.528 39.5C322.528 38.6716 321.857 38 321.028 38L320.814 38L319.528 38L301.028 38C300.2 38 299.528 38.6716 299.528 39.5V39.5C299.528 40.3284 300.2 41 301.028 41L317.528 41L321.028 41L321.243 41C321.953 41 322.528 40.4244 322.528 39.7143V39.7143C322.528 39.6771 322.527 39.6401 322.525 39.6035C322.527 39.5693 322.528 39.5348 322.528 39.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="4-3_filter0_d"
            x="271.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-3_filter1_d"
            x="311.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-3_filter2_d"
            x="264.3"
            y="29.3003"
            width="79.2793"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-3_filter3_i"
            x="279"
            y="44"
            width="48.9399"
            height="235.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="4-3_filter4_d"
            x="294.764"
            y="34.8218"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="4-3_filter5_d"
            x="298.649"
            y="36.1201"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="275.5"
            y1="293.8"
            x2="275.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="290.5"
            y1="284.202"
            x2="290.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="275.5"
            y1="293.798"
            x2="275.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="315.5"
            y1="293.8"
            x2="315.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="330.5"
            y1="284.202"
            x2="330.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="315.5"
            y1="293.798"
            x2="315.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
