import React from 'react'
import { ProductImageWrapper } from '../../components/product-images-generator/styles'

export const Casement33Square54Casement = () => {
  return (
    <ProductImageWrapper>
      <svg
        width="449"
        height="333"
        viewBox="0 0 449 333"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#3-3_filter0_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M212.001 291C213.105 291 214 290.105 214 289.001C214 287.896 213.105 287.001 212.001 287.001L209.833 287.001L209.833 287.001L199 287.001L199 287L195.999 287C194.895 287 194 287.895 194 288.999C194 290.104 194.895 290.999 195.999 290.999L198.167 290.999L198.167 291L209 291L209 291L212.001 291Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 209.833 291)"
          fill="#ABABAB"
        />
        <path
          d="M213.06 291C213.579 291 214 290.579 214 290.06L214 287.941C214 287.422 213.579 287.001 213.06 287.001L209 287.001L209 291L213.06 291Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M194.94 287C194.421 287 194 287.421 194 287.94L194 290.059C194 290.578 194.421 290.999 194.94 290.999L199 290.999L199 287L194.94 287Z"
          fill="url(#paint2_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 208.167 291)"
          fill="url(#paint3_linear)"
        />
        <g filter="url(#3-3_filter1_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M252.001 291C253.105 291 254 290.105 254 289.001C254 287.896 253.105 287.001 252.001 287.001L249.833 287.001L249.833 287.001L239 287.001L239 287L235.999 287C234.895 287 234 287.895 234 288.999C234 290.104 234.895 290.999 235.999 290.999L238.167 290.999L238.167 291L249 291L249 291L252.001 291Z"
            fill="url(#paint4_linear)"
          />
        </g>
        <rect
          width="3.99896"
          height="11.6667"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 249.833 291)"
          fill="#ABABAB"
        />
        <path
          d="M253.06 291C253.579 291 254 290.579 254 290.06L254 287.941C254 287.422 253.579 287.001 253.06 287.001L249 287.001L249 291L253.06 291Z"
          fill="url(#paint5_linear)"
        />
        <path
          d="M234.94 287C234.421 287 234 287.421 234 287.94L234 290.059C234 290.578 234.421 290.999 234.94 290.999L239 290.999L239 287L234.94 287Z"
          fill="url(#paint6_linear)"
        />
        <rect
          width="3.99896"
          height="8.33334"
          transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 248.167 291)"
          fill="url(#paint7_linear)"
        />
        <g filter="url(#3-3_filter2_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M258 34H190V287H258V34ZM248 44H200V277H248V44Z"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M258 34H190V287H190.932V35H257.068V287H258V34Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-3_filter3_i)">
          <rect
            x="200.47"
            y="44.47"
            width="47.0601"
            height="232.06"
            stroke="#979797"
            strokeWidth="0.93994"
          />
        </g>
        <rect
          x="194.53"
          y="38.53"
          width="58.9399"
          height="243.94"
          stroke="#E2E4E8"
          strokeWidth="0.93994"
        />
        <path d="M246.448 275.5L224 50.0466L201.552 275.5L246.448 275.5Z" stroke="white" />
        <rect
          x="230.528"
          y="36"
          width="7"
          height="13.5283"
          rx="3.5"
          transform="rotate(90 230.528 36)"
          fill="#9B9DA2"
          fillOpacity="0.19"
        />
        <g filter="url(#3-3_filter4_d)">
          <rect
            x="229.884"
            y="36.7017"
            width="5.6"
            height="12.2399"
            rx="2.8"
            transform="rotate(90 229.884 36.7017)"
            fill="#E8EAEE"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M229.766 38.6948C229.42 37.5418 228.35 36.7017 227.084 36.7017L220.444 36.7017C218.898 36.7017 217.644 37.9553 217.644 39.5017C217.644 39.5368 217.645 39.5718 217.646 39.6067C217.993 40.7598 219.062 41.5998 220.328 41.5998L226.968 41.5998C228.515 41.5998 229.768 40.3462 229.768 38.7998C229.768 38.7647 229.768 38.7297 229.766 38.6948Z"
          fill="#DFE0E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M228.409 37.0344C228.266 37.0117 228.118 36.9998 227.968 36.9998L221.328 36.9998C219.782 36.9998 218.528 38.2534 218.528 39.7998C218.528 40.8669 219.125 41.7946 220.003 42.2671C218.667 42.0556 217.644 40.898 217.644 39.5017C217.644 37.9553 218.898 36.7017 220.444 36.7017L227.084 36.7017C227.564 36.7017 228.015 36.8221 228.409 37.0344Z"
          fill="#FBFBFB"
        />
        <g filter="url(#3-3_filter5_d)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M243.528 39.5C243.528 38.6716 242.857 38 242.028 38L241.814 38L240.528 38L222.028 38C221.2 38 220.528 38.6716 220.528 39.5V39.5C220.528 40.3284 221.2 41 222.028 41L238.528 41L242.028 41L242.243 41C242.953 41 243.528 40.4244 243.528 39.7143V39.7143C243.528 39.6771 243.527 39.6401 243.525 39.6035C243.527 39.5693 243.528 39.5348 243.528 39.5Z"
            fill="#E8EAEE"
          />
        </g>
        <defs>
          <filter
            id="3-3_filter0_d"
            x="192.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-3_filter1_d"
            x="232.12"
            y="285.12"
            width="25.6396"
            height="9.63962"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-3_filter2_d"
            x="185.3"
            y="29.3003"
            width="79.2793"
            height="264.279"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="2.81982" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-3_filter3_i"
            x="200"
            y="44"
            width="48.9399"
            height="235.82"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="0.93994" dy="2.81982" />
            <feGaussianBlur stdDeviation="1.87988" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="3-3_filter4_d"
            x="215.765"
            y="34.8218"
            width="17.8795"
            height="11.2396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="0.93994" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <filter
            id="3-3_filter5_d"
            x="219.649"
            y="36.1201"
            width="28.6396"
            height="8.63964"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="1.94" dy="0.93994" />
            <feGaussianBlur stdDeviation="1.40991" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.256181 0 0 0 0 0.256898 0 0 0 0 0.258333 0 0 0 0.56 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="196.5"
            y1="293.8"
            x2="196.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="211.5"
            y1="284.202"
            x2="211.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="196.5"
            y1="293.798"
            x2="196.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint4_linear"
            x1="236.5"
            y1="293.8"
            x2="236.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0E0E1" />
            <stop offset="0.999276" stopColor="white" />
          </linearGradient>
          <linearGradient
            id="paint5_linear"
            x1="251.5"
            y1="284.202"
            x2="251.5"
            y2="291.8"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint6_linear"
            x1="236.5"
            y1="293.798"
            x2="236.5"
            y2="286.2"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
          <linearGradient
            id="paint7_linear"
            x1="6.79822"
            y1="4.16667"
            x2="-0.799791"
            y2="4.16667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.340644" stopColor="#E0E0E1" />
            <stop offset="0.630803" stopColor="white" />
            <stop offset="1" stopColor="#E0E2E6" />
          </linearGradient>
        </defs>
      </svg>
    </ProductImageWrapper>
  )
}
