import React, { FC, useCallback, useEffect, useState, createContext } from 'react'
import { ModalProvider } from 'react-modal-hook'
import { TransitionGroup } from 'react-transition-group'
import { v4 as uuidv4 } from 'uuid'

import { useOrders } from 'src/react-app/hooks'
import { CalculatorStage, CalculatorType, ICalculatorProps, IMainContext } from './interface'
import { Fonts } from './fonts'
import { AppWrapper } from './styles'
import { TopBlock, StageSelector } from './index'
import { getRegionFromHost, prepareOrderProducts, getTotalCost } from '../../utilits'
import { BottomBlock } from 'src/react-app/views/calculator/top-block/bottom-block'
import {getLocalStorageItem, setLocalStorageItem} from "../../services/local-storage";

console.log('version', '1.21')

export const MainContext = createContext<IMainContext>({
  clearView: false,
  dropSettings: () => null,
  initModel: '',
  initGlassUnit: '',
  initSize: undefined,
  quizStep: undefined,
  isDraftEdit: false,
  premisesType: null,
  onSetOrder: () => null,
  onDropDraft: () => null,
  onDeleteOrder: () => null,
  resetCurrentOrder: () => null,
  currentOrderId: null,
})

export const Calculator: FC<ICalculatorProps> = props => {
  const {
    clearView,
    quizStep,
    productType,
    premisesType,
    id: initId,
    stage: initStage,
    isDraft,
    initGlassUnit,
    initModel,
    dropSettings,
  } = props

  const [stage, setStage] = useState<CalculatorStage>(initStage)
  const [type, setType] = useState<CalculatorType | null>(productType)
  const [orders, setOrders] = useState<any[]>([])
  const [currentOrder, setCurrentOrder] = useState<any | null>(null)
  const [currentOrderIndex, setCurrentOrderIndex] = useState<number | null>(null)
  const [preFilters, setPreFilters] = useState<any[]>([])
  const [initSize, setInitSize] = useState<Record<string, number | number[]> | undefined>(undefined)
  const [currentOrderId, setCurrentOrderId] = useState<null | number>(null)
  const [isDraftEdit, setIsDraftEdit] = useState<boolean | undefined>(undefined)
  const { fetchOrderById, order } = useOrders()
  const [superKey, setSuperKey] = useState(Date.now)

  useEffect(() => {
    if (typeof isDraft !== 'undefined' && typeof isDraftEdit === 'undefined') {
      setIsDraftEdit(isDraft)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDraft])

  useEffect(() => {
    if (type === null && productType !== null) {
      setType(productType)
    }
  }, [productType, type])

  useEffect(() => {
    const region = getRegionFromHost()

    const fetchLocalStorageData = async () => {
      const cartItems = JSON.parse(await getLocalStorageItem('cartItems') || '[]')

      if (isDraftEdit) {
        const draft = JSON.parse(await getLocalStorageItem('calculationDrafts') || '[]')
        if (draft.length > 0) {
          if (draft[0]['filters']) {
            setPreFilters(draft[0]['filters'])
          }
          if (draft[0]['type']) {
            setType(draft[0]['type'])
          }
          if (draft[0]['sizes']) {
            setInitSize(draft[0]['sizes'])
          }
        }
      }

      await setLocalStorageItem('region', region)

      if (cartItems.length > 0) {
        setOrders(cartItems)
      }
    }

    fetchLocalStorageData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDraftEdit])

  useEffect(() => {
    if (initStage) {
      setStage(initStage)
    }
    if (initId) {
      setCurrentOrderId(initId)

      if (currentOrder === null) {
        fetchOrderById(initId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initId, initStage])

  useEffect(() => {
    if (currentOrder === null && order && initStage === CalculatorStage.ORDER) {
      const specificationProducts = order['specification-products'] || {}
      const source = specificationProducts['source'] || {}
      const newSource = {
        ...source,
        products: source['products'] || [],
        totalCost: source['totalCost'] || 0,
        type: source['type'] || '',
      }

      const newCurrentOrder = {
        ...newSource,
        totalPriceList: source['totalPriceList'] || [],
        'specification-products': {
          print: specificationProducts['transition-products'],
          'transition-products': specificationProducts['transition-products'],
          source: newSource,
        },
      }
      const newOrders =
        (source['products'] &&
          source['products'].length > 0 &&
          source['products'].map(({ product }) => product)) ||
        []

      setCurrentOrder(newCurrentOrder)
      setOrders(newOrders)

      if ('orderAdditionalData' in order) {
        setLocalStorageItem('mounting', JSON.stringify(order['orderAdditionalData']['mounting']))
        setLocalStorageItem('delivery', JSON.stringify(order['orderAdditionalData']['delivery']))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order])

  const handleDropDraft = useCallback(() => {
    setIsDraftEdit(false)
  }, [])

  const handleChangeStage = useCallback((selectStage: CalculatorStage) => {
    setStage(selectStage)
  }, [])

  const handleChangeType = useCallback((newType: CalculatorType) => {
    setType(newType)
  }, [])

  const handleAddOrder = useCallback(
    product => {
      let newOrders = orders.map(item => ({
        ...item,
        id: item['id'] || uuidv4(),
      }))

      if (currentOrderIndex !== null) {
        newOrders = [
          ...orders.slice(0, currentOrderIndex),
          {
            id: uuidv4(),
            ...product,
          },
          ...orders.slice(currentOrderIndex + 1, orders.length),
        ]

        setCurrentOrderIndex(null)
      } else {
        newOrders = [
          ...newOrders,
          {
            id: uuidv4(),
            ...product,
          },
        ]
      }

      setOrders(newOrders)
    },
    [currentOrderIndex, orders],
  )

  const handleUpdateOrder = useCallback(items => {
    setOrders(items)
    setSuperKey(Date.now)
  }, [])

  const handleDeleteOrder = useCallback(
    id => {

      const deletedIndex = orders.findIndex(item => item['id'] === id || item['bitrix-id'] === id)
      setOrders(prevState => [
        ...prevState.slice(0, deletedIndex),
        ...prevState.slice(deletedIndex + 1, prevState.length),
      ])

      if (currentOrder) {
        setCurrentOrder(prevState => {
          const products = [
            ...prevState['products'].slice(0, deletedIndex),
            ...prevState['products'].slice(deletedIndex + 1, prevState['products'].length),
          ]
          const totalPriceList = [
            ...prevState['totalPriceList'].slice(0, deletedIndex),
            ...prevState['totalPriceList'].slice(
              deletedIndex + 1,
              prevState['totalPriceList'].length,
            ),
          ]
          const innerProducts = products.map(item => item['product'])
          const { newProducts, transitionProducts } = prepareOrderProducts(
            innerProducts,
            totalPriceList,
            type,
            preFilters,
          )
          const totalCost = getTotalCost(totalPriceList)

          return {
            ...prevState,
            products,
            'specification-products': {
              'transition-products': transitionProducts,
              print: transitionProducts,
              source: {
                type,
                products: newProducts,
                totalCost,
              },
            },
            totalPriceList,
            totalCost,
          }
        })
      }
      setSuperKey(Date.now)
    },
    [currentOrder, orders, preFilters, type],
  )

  const handleEditOrder = useCallback(
    id => {
      const orderIndex = orders.findIndex(item => item['id'] === id)

      setCurrentOrder(orders[orderIndex])
      setCurrentOrderIndex(orderIndex)
      handleChangeStage(CalculatorStage.CALCULATE)
    },
    [handleChangeStage, orders],
  )

  const handleResetCurrentOrder = useCallback(() => {
    setCurrentOrder(null)
  }, [])

  return (
    <ModalProvider rootComponent={TransitionGroup}>
      {/* @ts-ignore */}
      <AppWrapper clearView={clearView}>
        <Fonts />
        {!clearView && <TopBlock stage={stage} order={order}  />}
        <MainContext.Provider
          value={{
            clearView,
            dropSettings,
            initModel,
            initGlassUnit,
            isDraftEdit,
            onDropDraft: handleDropDraft,
            initSize,
            quizStep,
            premisesType,
            onSetOrder: setCurrentOrder,
            onDeleteOrder: handleDeleteOrder,
            resetCurrentOrder: handleResetCurrentOrder,
            currentOrderId,
          }}
        >

          <StageSelector
            key={superKey}
            onChangeStage={handleChangeStage}
            onChangeType={handleChangeType}
            type={type}
            stage={stage}
            orders={orders}
            currentOrder={currentOrder}
            preFilters={preFilters}
            onSetPreFilters={setPreFilters}
            onAddOrder={handleAddOrder}
            onEditOrder={handleEditOrder}
            onSetCurrentOrderId={setCurrentOrderId}
            onUpdateOrder={handleUpdateOrder}
          />
        </MainContext.Provider>
        {/*<BottomBlock stage={stage} order={order} />*/}
      </AppWrapper>
    </ModalProvider>
  )
}
