import React, { useRef, useEffect, FC, useCallback, useState } from 'react'

import { VerticalThumbSizesInputNumberWrapper } from './styles'
import { IThumbSizeProps } from './interface'
import { NumberInput } from '../number-input'
import { InputTypes } from '../../../interface'

export const ThumbSizeContainer: FC<IThumbSizeProps> = props => {
  const { index, currentSize, onChange, minSize, maxSize, disabled } = props
  const [isActive, setIsActive] = useState(false)
  const thumbInputRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutsideThumbInput = event => {
      if (
        thumbInputRef &&
        thumbInputRef.current !== null &&
        event.target &&
        !thumbInputRef.current.contains(event.target as Node)
      ) {
        setIsActive(false)
      }
    }
    // eslint-disable-next-line no-undef
    document.addEventListener('click', handleClickOutsideThumbInput)

    return () => {
      // eslint-disable-next-line no-undef
      document.removeEventListener('click', handleClickOutsideThumbInput)
    }
  }, [])

  const handleClose = useCallback(() => {
    setIsActive(false)
  }, [])

  const handleActive = useCallback(() => {
    setIsActive(true)
  }, [])

  return (
    <VerticalThumbSizesInputNumberWrapper
      ref={thumbInputRef}
      show
      active={isActive}
      onClick={handleActive}
    >
      <NumberInput
        onSelect={onChange(index)}
        data={{ min: minSize, max: maxSize }}
        id={`number-input-${index}`}
        selected={currentSize}
        onEnter={handleClose}
        type={InputTypes.text}
        selectOnFocus
        disabled={disabled}
      />
    </VerticalThumbSizesInputNumberWrapper>
  )
}
